import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get, Post, Delete } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      centers: [],
      newUserCenter: {
        user_id: this.props.selectedUser.id,
        ms_center_id: 0
      }
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => {
      if( typeof error === 'string' ){
        toast.error( error )
      } else {
        let tmpError = _.reduce( 
          error, 
          ( res, val, key ) => ({ ...res, [ key ]: val.replace( /_/g, ' ' ) }),
          {}
        )
        _.map( tmpError, err => toast.error( err ) )
      }
    }

    onChangeUserCenter = ( key, val ) => this.setState({ [key]: val })

    getCenters = () => Get(
      `/api/v1/centers?query=${ convertObjToBase64({ 
        is_paginated: false, 
        filter: { $or: [ { status: 'ACTIVE' }, { status: 'TRIAL' } ] } 
      })}`,
      this.getCentersSuccess,
      this.getCentersError,
      this.load
    )
    getCentersSuccess = payload => {
      let tmp = []
      if( payload.data && payload.data.length > 0 ) {
        payload.data.map( item => {
          let tmpIndex = _.findIndex( this.props.selectedUser.centers, { id: item.id })
          if( tmpIndex === -1 ) {
            tmp.push( item )
          }
        })
      }
      this.setState({ centers: _.orderBy( payload.data, [ center => center.center_name.toLowerCase() ] ) })
    }
    getCentersError = error => this.requestError( error )

    createUserCenter = data => Post(
      `/api/v1/users_centers`,
      data,
      this.createUserCenterSuccess,
      this.createUserCenterError,
      this.load
    )
    createUserCenterSuccess = () => {
      this.requestSuccess( Lang[ 'CENTER_IS_ASSIGNED_SUCCESSFULLY' ][ this.props.data.languageReducer.lang ] )
      this.props.getSelectedUser( this.props.selectedUser.id )
      this.getCenters()
      this.setState({ newUserCenter: { ...this.state.newUserCenter, ms_center_id: 0 } })
    }
    createUserCenterError = error => this.requestError( error )

    removeUserCenter = id => Delete(
      `/api/v1/users_centers/${ this.props.selectedUser.id }/centers/${ id }`,
      this.removeUserCenterSuccess,
      this.removeUserCenterError,
      this.load
    )
    removeUserCenterSuccess = () => {
      this.requestSuccess( Lang[ 'CENTER_IS_UNASSIGNED_SUCCESSFULLY' ][ this.props.data.languageReducer.lang ] )
      this.props.getSelectedUser( this.props.selectedUser.id )
      this.getCenters()
    }
    removeUserCenterError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          getCenters={ this.getCenters }
          createUserCenter={ this.createUserCenter }
          removeUserCenter={ this.removeUserCenter }
          onChangeUserCenter={ this.onChangeUserCenter }
          onLoadCenters={ this.state.loading }
          centers={ this.state.centers }
          newUserCenter={ this.state.newUserCenter } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC