import React, { Component } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Form, FormGroup, Label, Input
} from 'reactstrap'
import Select from 'react-select'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { compose } from 'redux'
import { connect } from 'react-redux'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Preview from './View'
import TemplateSectionsHOC from './actions'
import Lang from 'Lang/General'

class SectionsPicker extends Component {
  state = {
    selectedSectionToDisplay: {},
    selectedTemplateId: '',
    selectedTemplateName: '',
    selectedSectionId: 0,
    selectedCategories: []
  }

  componentDidMount = () => {
    this.props.getTemplates()
    this.props.getCategories()
  }

  componentDidUpdate = pp => {
    if( pp.showSectionPicker && !this.props.showSectionPicker ){
      this.setState({ selectedSectionToDisplay: {} })
    }
  }

  processSelectedPage = () => {
    if( !_.isEmpty( this.state.selectedSectionToDisplay )){
      let tmpPage = _.cloneDeep( this.props.selectedPage )
      let tmpSectionsContainer = _.find( tmpPage.content.children, { variant: "sections-container" })
      let tmpIndexFoundSection = _.findIndex( tmpSectionsContainer.children, { id: this.props.selectedSectionID })
      let objToAdd = {
        id: this.state.selectedSectionToDisplay.id,
        type: this.state.selectedSectionToDisplay.type ? this.state.selectedSectionToDisplay.type : 'section',
        tag: this.state.selectedSectionToDisplay.name
      }
      if( tmpSectionsContainer.children.length === 0 ) {
        tmpSectionsContainer.children.push( objToAdd )
      } else {
        if( tmpIndexFoundSection + 1 === tmpSectionsContainer.children.length ) {
          tmpSectionsContainer.children.push( objToAdd )
        } else {
          tmpSectionsContainer.children.splice( tmpIndexFoundSection + 1, 0, objToAdd )
        }
      }
      this.props.updateSelectedPage( this.props.template_id, this.props.selectedPage.id, tmpPage, 'edit' )
      this.props.updateMainContainerState( 'showSectionPicker', false )
    } else {
      toast.error( Lang[ 'SELECT_SECTION_TOAST_MESSAGE' ][ this.props.selectedLanguageShortname ] )
    }
  }

  render = () => {
    return (
      <Modal isOpen={ this.props.showSectionPicker } size={ 'xl' }>
        <ModalHeader toggle={ () => this.props.updateMainContainerState( 'showSectionPicker', false ) }>
          { Lang[ 'SELECT_SECTION' ][ this.props.selectedLanguageShortname ] }
        </ModalHeader>
        <ModalBody style={{ maxHeight: "80vh", overflow: "scroll" }}>
          <Form onSubmit={ e => e.preventDefault() }>
            <FormGroup>
              <Label>{ Lang[ 'SELECT_A_TEMPLATE' ][ this.props.selectedLanguageShortname ] }</Label>
              <Input
                type="select"
                value={ this.state.selectedTemplateId }
                onChange={ e => {
                  this.setState({ selectedTemplateId: e.target.value, selectedSectionToDisplay: {}, selectedSectionId: "" }, () => {
                    if( this.state.selectedTemplateId !== '' ) {
                      let tmp = _.find( this.props.templates, { id: parseInt( this.state.selectedTemplateId, 10 ) })
                      let tmpCategory = []
                      this.state.selectedCategories && this.state.selectedCategories.length > 0 && this.state.selectedCategories.map( item => {
                        tmpCategory.push( item.value )
                      })
                      this.props.getSections({ 
                        name: tmp.name, 
                        section_categories: tmpCategory && tmpCategory.length > 0 ? tmpCategory : [ 0 ] 
                      })
                      this.setState({ selectedTemplateName: tmp.name })
                    }
                  })
                }}>
                <option></option>
                {
                  this.props.templates.map( item => {
                    return <option key={ item.id } value={ item.id }>{ item.name }</option>
                  })
                }
              </Input>
            </FormGroup>
            {
              this.state.selectedTemplateId !== '' && (
                <FormGroup>
                  <Label>{ Lang[ 'SELECT_CATEGORY' ][ this.props.selectedLanguageShortname ] }</Label>
                  <Select
                    isMulti
                    placeholder={ Lang[ 'SELECT' ][ this.props.selectedLanguageShortname ] }
                    options={ this.props.categories }
                    menuPortalTarget={ document.body }
                    menuPosition="fixed"
                    styles={{
                      menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                      menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    className="basic-multi-select"
                    value={ this.state.selectedCategories }
                    onChange={ val => {
                      this.setState({ selectedCategories: val }, () => {
                        let tmp = _.find( this.props.templates, { id: parseInt( this.state.selectedTemplateId, 10 ) })
                        let tmpCategory = []
                        val && val.length > 0 && val.map( item => {
                          tmpCategory.push( item.value )
                        })
                        this.props.getSections({ 
                          name: tmp.name, 
                          section_categories: tmpCategory && tmpCategory.length > 0 ? tmpCategory : [ 0 ] 
                        })
                      })
                    }} />
                </FormGroup>
              )
            }
            {
              this.state.selectedTemplateId !== '' && (
                <FormGroup>
                  <Label>{ Lang[ 'SELECT_SECTION' ][ this.props.selectedLanguageShortname ] }</Label>
                  <Input
                    type="select"
                    value={ this.state.selectedSectionId }
                    onChange={ e => {
                      this.setState({ selectedSectionId: e.target.value }, () => {
                        let tmp = _.find( this.props.sections, { id: this.state.selectedSectionId })
                        this.setState({ selectedSectionToDisplay: tmp })
                      })
                    }}>
                    <option></option>
                    {
                      this.props.sections.map( item => {
                        if( !item.is_system_section ) {
                          return <option key={ item.id } value={ item.id }>
                            {
                              this.state.selectedTemplateName.toUpperCase() + item.name.slice( this.state.selectedTemplateName.length )
                            }
                          </option>
                        }
                      })
                    }
                  </Input>
                </FormGroup>
              )
            }
            {
              !_.isEmpty( this.state.selectedSectionToDisplay ) && (
                <FormGroup>
                  <Preview
                    id={ this.state.selectedSectionToDisplay.id }
                    stylesheets={ this.props.stylesheets }
                    javascripts={ this.props.javascripts }
                    selectedSectionToDisplay={ this.state.selectedSectionToDisplay }
                    languages={ this.props.languages }
                    selectedLanguageShortname={ this.props.selectedLanguageShortname }
                    mode={ this.props.mode } />
                  <Button
                    className='mt-2 mr-2'
                    color='primary'
                    onClick={() => {
                      this.processSelectedPage()
                    }}>
                    { Lang[ 'SELECT' ][ this.props.selectedLanguageShortname ]  }
                  </Button>
                </FormGroup>
              )
            }
          </Form>
          { this.props.onLoadSections && <LoadingOverlay /> }
        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({ data: state })

export default compose(
  TemplateSectionsHOC,
  connect( mapStateToProps )
)( SectionsPicker )
