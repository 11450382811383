import React, { Component } from 'react'
import { Dialog, Button } from '@material-ui/core'
import _ from 'lodash'
import { MdClose } from 'react-icons/md'
import { compose } from 'redux'

import uniqid from 'uniqid'

import TemplateEditorCard from '../components/card'
import HeaderMenu from './HeaderMenu'
import HeaderMenuBlueprint from './HeaderMenuBlueprint'
import HeaderMenuStyling from './HeaderMenuStyling'
import LoadingModal from 'components/Indicator/LoadingOverlay'

import { reorder } from '../utility/reorderArray'
import WithSearch from './withSearch'
import WithSection from './actions'

import './index.scss'

import Lang from 'Lang/General'

const Images = [
  { id: 1, src: 'https://ark-template-staging-assets.s3.eu-central-1.amazonaws.com/MediaDatabase/paypal.png' },
  { id: 2, src: 'https://ark-template-staging-assets.s3.eu-central-1.amazonaws.com/MediaDatabase/vital02.png' }
]

class HeaderEditor extends Component {
  state = {
    headerData: {},
    headerMenuData: [],
    headerMobileMenuData: {},
    headerBlueprintDesktop: {},
    headerBlueprintMobile: {},
    mode: 'Site Logo'
  }

  componentDidUpdate = pp => {
    if( this.props.selectedPage && this.props.selectedPage.content && ( this.props.selectedPage.content !== pp.selectedPage.content ) ) {
      let header = _.find( this.props.selectedPage.content.children, { type: 'header' })
      this.props.getSelectedSection( header.id )
    }
    if( this.props.templateHeaders !== pp.templateHeaders ) {
      this.setState({ headerMenuData: this.props.templateHeaders.content })
    }
    if( !this.props.onLoadSection && pp.onLoadSection && !_.isEmpty( this.props.selectedSection ) ) {
      this.props.searchLogo( this.props.selectedSection.children )
      this.props.searchTopContactBarColor( this.props.selectedSection.children )
      this.props.searchHeaderBackgroundColor( this.props.selectedSection.children )
      this.props.searchHeaderMenu( this.props.selectedHeaderBlueprint )
      this.setState({ headerData: this.props.selectedSection })
    }
  }

  onChangeState = ( key, param ) => this.setState({ [ key ]: param })

  onChangeWebsiteLogo = ( e ) => {
    let tmpWebsiteLogo = _.cloneDeep( this.props.websiteLogo )
    let tmpHeaderData = _.cloneDeep( this.state.headerData )
    tmpWebsiteLogo.children[0].source = e.target.value
    this.props.updateWithSearchState( 'selectedSiteImageLogo', e.target.value )
    this.patchHeader( tmpWebsiteLogo, tmpHeaderData, false )
    this.setState({ headerData: tmpHeaderData })
  }

  onChangeTopContactBarColor = col => {
    let tmpTopContactBar = _.cloneDeep( this.props.topContactBar )
    let tmpHeaderData = _.cloneDeep( this.state.headerData )
    tmpTopContactBar.style.color = col
    this.props.updateWithSearchState( 'topContactBarColor', col )
    this.patchHeader( tmpTopContactBar, tmpHeaderData, false )
    this.setState({ headerData: tmpHeaderData })
  }

  onChangeHeaderBackgroundColor = col => {
    let tmpHeaderBackground = _.cloneDeep( this.props.headerBackground )
    let tmpHeaderData = _.cloneDeep( this.state.headerData )
    tmpHeaderBackground.style.background = col
    this.props.updateWithSearchState( 'headerBackgroundColor', col )
    this.patchHeader( tmpHeaderBackground, tmpHeaderData, false )
    if( tmpHeaderData.style && tmpHeaderData.style.background ){
      tmpHeaderData.style.background = col
    }
    this.setState({ headerData: tmpHeaderData })
  }

  onChangeHeaderMenuItemColor = col => {
    let tmpDesktop = {}
    let tmpMobile = {}
    this.props.updateWithSearchState( 'headerMenuItemColor', col )
    if( _.isEmpty(this.state.headerBlueprintDesktop) ){
      let tmpIndexDesktop = _.findIndex( this.props.templateHeadersBlueprints, obj => obj.name.indexOf( 'Desktop' ) > -1 ) 
      tmpDesktop = _.cloneDeep( this.props.templateHeadersBlueprints[ tmpIndexDesktop ] )
    }
    else{
      tmpDesktop = this.state.headerBlueprintDesktop
    }

    if( _.isEmpty(this.state.headerBlueprintMobile) ){
      let tmpIndexMobile = _.findIndex( this.props.templateHeadersBlueprints, obj => obj.name.indexOf( 'Mobile' ) > -1 )
      tmpMobile = _.cloneDeep( this.props.templateHeadersBlueprints[ tmpIndexMobile ] )
    }
    else{
      tmpMobile = this.state.headerBlueprintMobile
    }

    tmpDesktop.content.children[0].style.color = col
    tmpDesktop.content.children[1].children[0].style.color = col
    tmpDesktop.content.children[1].children[1].children[0].style.color = col
    tmpMobile.content.children[0].children[0].style.color = col
    tmpMobile.content.children[1].children[0].style.color = col
    tmpMobile.content.children[2].children[0].style.color = col

    this.setState({
      headerBlueprintDesktop: tmpDesktop,
      headerBlueprintMobile: tmpMobile
    })
  }

  onChangeHeaderMenuItemHoverColor = col => {
    let tmpDesktop = {}
    this.props.updateWithSearchState( 'headerMenuItemHoverColor', col )
    if( _.isEmpty(this.state.headerBlueprintDesktop) ){
      let tmpIndexDesktop = _.findIndex( this.props.templateHeadersBlueprints, obj => obj.name.indexOf( 'Desktop' ) > -1 ) 
      tmpDesktop = _.cloneDeep( this.props.templateHeadersBlueprints[ tmpIndexDesktop ] )
    }
    else{
      tmpDesktop = this.state.headerBlueprintDesktop
    }
    
    tmpDesktop.content.children[1].children[0].style[":hover"].color = col
    tmpDesktop.content.children[1].children[1].children[0].style[":hover"].color = col
    if( tmpDesktop.content.children[0].style[":hover"] ){
      tmpDesktop.content.children[0].style[":hover"].color = col
      if( tmpDesktop.content.children[0].style[":hover"].borderBottom ){
        tmpDesktop.content.children[0].style[":hover"].borderBottom = tmpDesktop.content.children[0].style[":hover"].borderBottom.replace( /#[^\b]+\b/g, col )
      }
    }
    if( 
      tmpDesktop.content.style 
      && tmpDesktop.content.style[":hover"]
      && tmpDesktop.content.style[":hover"].background 
    ){
      tmpDesktop.content.style[":hover"].background = col
    }

    this.setState({ headerBlueprintDesktop: tmpDesktop })
  }

  patchHeader = ( toPatchDOM, currentData, found ) => {
    if( currentData && currentData.children ) {
      currentData.children.map( item => {
        if( !found ) {
          if( item.id === toPatchDOM.id ) {
            found = true
            item = toPatchDOM
            let tmpIndex = _.findIndex( currentData.children, { id: item.id } )
            currentData.children.splice( tmpIndex, 1, item )
            return currentData
          } else {
            if( item.children ) {
              this.patchHeader( toPatchDOM, item, false )
            }
          }
        }
      })
    }
  }

  onDragEndHeaderMainMenu = ( result ) => {
    if( !result.destination ) {
      return;
    }
    const items = reorder(
      this.state.headerMenuData,
      result.source.index,
      result.destination.index
    )
    return this.setState({ headerMenuData: items })
  }

  findPatchHeaderMenuText = ( data, id, updatedVal ) => {
    data.map( item => {
      if( item.id === id ) {
        item.content[ this.props.selectedLanguageShortname ] = updatedVal
      } else {
        if( item.children ) {
          this.findPatchHeaderMenuText( item.children, id, updatedVal )
        }
      }
    })
  }

  findPatchHeaderMenuData = ( data, toPatchData ) => {
    data.map( item => {
      if( item.id === toPatchData.id ) {
        item = toPatchData
        let tmpIndex = _.findIndex( data, { id: item.id } )
        data.splice( tmpIndex, 1, item )
        return data
      } else {
        if( item.children ) {
          this.findPatchHeaderMenuData( item.children, toPatchData )
        }
      }
    })
  }

  findRemoveHeaderMenuData = ( data, id ) => {
    data.map( item => {
      if( item.id == id ) {
        let tmpIndex = _.findIndex( data, { id: item.id } )
        data.splice( tmpIndex, 1 )
        return data
      } else {
        if( item.children ) {
          this.findRemoveHeaderMenuData( item.children, id )
        }
      }
    })
  }

  onReorderMenu = ( parentData, sourceIndex, toIndex ) => {
    let tmpHeaderMenuData = _.cloneDeep( this.state.headerMenuData )
    const items = reorder(
      parentData.children,
      sourceIndex,
      toIndex
    )
    parentData.children = items
    this.findPatchHeaderMenuData( tmpHeaderMenuData, parentData )
    return this.setState({ headerMenuData: tmpHeaderMenuData })
  }

  onUpdateHeaderMenuText = ( id, updatedVal ) => {
    let tmpHeaderMenuData = _.cloneDeep( this.state.headerMenuData )
    this.findPatchHeaderMenuText( tmpHeaderMenuData, id, updatedVal )
    return this.setState({ headerMenuData: tmpHeaderMenuData })
  }

  onAddHeaderMenu = ( level, parentData ) => {
    let tmpHeaderMenuData = _.cloneDeep( this.state.headerMenuData )
    let tmp = {}
    this.props.languages.map( item => {
      tmp[ item.shortname ] = 'New Menu'
    })
    let toPushData = {
      "id": uniqid(),
      "content": tmp
    }
    if( level === 0 ) {
      tmpHeaderMenuData.push( toPushData )
    } else {
      if( !parentData.children ) {
        parentData.children = []
      }
      parentData.children.push( toPushData )
      this.findPatchHeaderMenuData( tmpHeaderMenuData, parentData )
    }
    return this.setState({ headerMenuData: tmpHeaderMenuData })
  }

  onRemoveHeaderMenu = ( id ) => {
    let tmpHeaderMenuData = _.cloneDeep( this.state.headerMenuData )
    this.findRemoveHeaderMenuData( tmpHeaderMenuData, id )
    return this.setState({ headerMenuData: tmpHeaderMenuData })
  }

  onUpdateHeaderMenu = ( item, key, value ) => {
    let tmpHeaderMenuData = _.cloneDeep( this.state.headerMenuData )
    item[ key ] = value
    this.findPatchHeaderMenuData( tmpHeaderMenuData, item )
    return this.setState({ headerMenuData: tmpHeaderMenuData })
  }

  renderImageSwitcher = () => {
    return (
      <div style={{ paddingTop: '15px' }}>
        <p>{ Lang[ 'CURRENT_SELECTED_IMAGE' ][ this.props.lang ] }</p>
        {
          !_.isEmpty( this.props.websiteLogo ) && (
            <img src={ this.props.websiteLogo.children[0].source } style={ this.props.websiteLogo.children[0].style } />
          )
        }
        <p style={{ marginBottom: '15px', marginTop: '15px' }}>{ Lang[ 'CURRENT_SELECTED_IMAGE' ][ this.props.lang ] }</p>
        <div className="row">
          <div className="col-md-8">
            <select
              className="form-control"
              value={ this.props.selectedSiteImageLogo }
              onChange={ e => this.onChangeWebsiteLogo( e ) }>
              {
                Images && Images.map( item => {
                  return (
                    <option key={ item.id } value={ item.src }>{ item.src }</option>
                  )
                })
              }
            </select>
          </div>
          <div className="col-md-4">
            <img src={ this.props.selectedSiteImageLogo } style={{ width: '190px' }} />
          </div>
        </div>
      </div>
    )
  }

  render = () => {
    return (
      <Dialog
        open={ this.props.showHeaderEditorDialog }
        maxWidth={ 'lg' }
        fullWidth={ true }>
        <TemplateEditorCard>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h4>{ Lang[ 'EDIT_HEADER' ][ this.props.lang ] }</h4>
            <Button
              classes={{
                root: "button-close",
                label: "button-text" }}
              onClick={ () => this.props.updateMainContainerState( 'showHeaderEditorDialog', false ) }>
              <MdClose style={{ fontSize: '20px' }}/>
            </Button>
          </div>
          <hr />
          <div className="container">
            <div className="header-editor-toggle">
              <button className="btn btn-primary btn-toggle" onClick={ () => this.onChangeState( 'mode', 'Site Logo' ) }>{ Lang[ 'SITE_LOGO' ][ this.props.lang ] }</button>
              <button className="btn btn-success btn-toggle" onClick={ () => {
                this.onChangeState( 'mode', 'Header Menu(s)' )
              }}>{ Lang[ 'HEADER_MENU' ][ this.props.lang ] }</button>
              <button className="btn btn-warning btn-toggle" onClick={ () => this.onChangeState( 'mode', 'Header Menu Blueprint' ) }>{ Lang[ 'HEADER_MENU_BLUEPRINT' ][ this.props.lang ] }</button>
              <button className="btn btn-secondary btn-toggle" onClick={ () => this.onChangeState( 'mode', 'Header Menu Styling' ) }>{ Lang[ 'HEADER_MENU_STYLING' ][ this.props.lang ] }</button>
            </div>
            { this.state.mode === 'Site Logo' && this.renderImageSwitcher() }
            {
              this.state.mode === 'Header Menu(s)' && (
                <HeaderMenu
                  onDragEndHeaderMainMenu={ this.onDragEndHeaderMainMenu }
                  headerMenuData={ this.state.headerMenuData }
                  selectedLanguageShortname={ this.props.selectedLanguageShortname }
                  pages={ this.props.pages }
                  onUpdateHeaderMenu={ this.onUpdateHeaderMenu }
                  onUpdateHeaderMenuText={ this.onUpdateHeaderMenuText }
                  onAddHeaderMenu={ this.onAddHeaderMenu }
                  onRemoveHeaderMenu={ this.onRemoveHeaderMenu }
                  onReorderMenu={ this.onReorderMenu }
                  languages={ this.props.languages }
                  lang={ this.props.lang } />
              )
            }
            {
              this.state.mode === 'Header Menu Blueprint' && (
                <HeaderMenuBlueprint
                  templateHeadersBlueprints={ this.props.templateHeadersBlueprints }
                  customHeaderBlueprints={ this.props.customHeaderBlueprints }
                  onChangeSelectedHeaderBlueprints={ this.props.onChangeSelectedHeaderBlueprints }
                  selectedHeaderBlueprint={ this.props.selectedHeaderBlueprint }
                  onSelectHeaderBlueprint={ this.props.onSelectHeaderBlueprint }
                  templateStylesheets={ this.props.templateStylesheets }
                  languages={ this.props.languages }
                  selectedLanguageShortname={ this.props.selectedLanguageShortname }
                  lang={ this.props.lang } />
              )
            }
            {
              this.state.mode === 'Header Menu Styling' && (
                <HeaderMenuStyling
                  topContactBarColor={ this.props.topContactBarColor }
                  onChangeTopContactBarColor={ this.onChangeTopContactBarColor }
                  headerBackgroundColor={ this.props.headerBackgroundColor }
                  onChangeHeaderBackgroundColor={ this.onChangeHeaderBackgroundColor }
                  headerMenuItemColor={ this.props.headerMenuItemColor }
                  onChangeHeaderMenuItemColor={ this.onChangeHeaderMenuItemColor } 
                  headerMenuItemHoverColor={ this.props.headerMenuItemHoverColor } 
                  onChangeHeaderMenuItemHoverColor={ this.onChangeHeaderMenuItemHoverColor }
                  lang={ this.props.lang } />
              )
            }
          </div>
          <hr />
          <div className="header-editor-footer">
            {
              this.state.mode === 'Site Logo' && (
                <Button
                  classes={{ root: 'btn-primary' }}
                  style={{ marginRight: '10px' }}
                  onClick={() => {
                    let tmpSelectedHeaderContent = _.cloneDeep( this.props.selectedSection )
                    this.props.patchJSONDOM( this.state.headerData, tmpSelectedHeaderContent )
                    this.props.updateSelectedSection({ 
                      id: this.state.headerData.id,
                      content: this.state.headerData
                    })
                  }}>
                  { Lang[ 'SAVE_UPDATED_HEADER' ][ this.props.lang ] }
                </Button>
              )
            }
            {
              this.state.mode === 'Header Menu(s)' && (
                <Button
                  classes={{ root: 'btn-primary' }}
                  style={{ marginRight: '10px' }}
                  onClick={() => {
                    let tmpSelectedTemplateHeader = _.cloneDeep( this.props.templateHeaders )
                    tmpSelectedTemplateHeader.content = this.state.headerMenuData
                    return this.props.updateTemplateHeader( this.props.template_id, tmpSelectedTemplateHeader )
                  }}>
                 { Lang[ 'SAVE_UPDATED_HEADER_MENU' ][ this.props.lang ] }
                </Button>
              )
            }
            {
              this.state.mode === 'Header Menu Blueprint' && (
                <Button
                  classes={{ root: 'btn-primary' }}
                  style={{ marginRight: '10px' }}
                  onClick={() => {
                    this.props.updateTemplateHeaderBlueprint( this.props.template_id )
                  }}>
                 { Lang[ 'SAVE_UPDATED_HEADER_MENU_BLUEPRINT' ][ this.props.lang ] }
                </Button>
              )
            }
            {
              this.state.mode === 'Header Menu Styling' && (
                <Button
                  classes={{ root: 'btn-primary' }}
                  style={{ marginRight: '10px' }}
                  onClick={() => {
                    let tmpSelectedHeaderContent = _.cloneDeep( this.props.selectedSection )
                    this.props.patchJSONDOM( this.state.headerData, tmpSelectedHeaderContent )
                    this.props.updateTemplateHeaderBlueprintItemColor( 
                      this.props.template_id,
                      this.state.headerBlueprintDesktop, 
                      this.state.headerBlueprintMobile )
                    this.props.updateSelectedSection({ 
                      id: this.state.headerData.id,
                      content: this.state.headerData
                    })
                  }}>
                  { Lang[ 'SAVE_UPDATED_HEADER_MENU_STYLING' ][ this.props.lang ] }
                </Button>
              )
            }
            <Button
              classes={{ root: 'btn-secondary' }}
              onClick={() => { 
                // this.props.get
                this.props.updateMainContainerState( 'showHeaderEditorDialog', false ) }}>
              { Lang[ 'CLOSE' ][ this.props.lang ] }
            </Button>
          </div>
          {
            ( this.props.onLoadHeaderBlueprintUpdate || this.props.onLoadHeaderUpdate || this.props.onLoadSectionUpdate )&& <LoadingModal />
          }
        </TemplateEditorCard>
      </Dialog>
    )
  }
}

export default compose(
  WithSearch,
  WithSection
)( HeaderEditor )
