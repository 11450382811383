import React from 'react'
import CurrencyInputField from 'react-currency-input-field'
import _ from 'lodash'
import { 
  FormFeedback,
  InputGroup, InputGroupAddon, InputGroupText 
} from 'reactstrap'


const CurrencyInput = ({
  value,
  onChangeValue,
  disabled,
  invalid
}) => {
  const currency = { name: 'EUR', decimal_separator: ',' }
  return <>
    <InputGroup invalid={ invalid }>
      <CurrencyInputField 
        className={ `form-control ${ invalid && 'is-invalid' }` }
        disabled={ disabled }
        value={ value }
        allowNegativeValue={ false }
        decimalSeparator={ currency.decimal_separator }
        groupSeparator={ currency.decimal_separator === '.' ? ',' : '.' }
        onValueChange={ onChangeValue } />
      <InputGroupAddon addonType="append">
        <InputGroupText>{ currency.name }</InputGroupText>
      </InputGroupAddon>
    </InputGroup>
    <FormFeedback className={ `${ invalid && 'd-block' }` } >{ invalid }</FormFeedback>
  </>
}

export default CurrencyInput