import React, { useState, useEffect } from 'react'
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Card, CardBody,
  Button, UncontrolledTooltip
} from 'reactstrap'
import { compose } from 'redux'
import Tabs from 'react-responsive-tabs'
import Moment from 'moment'
import _ from 'lodash'

import ARKTable from 'components/Table'
import PageTitle from 'components/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import DeleteConfirmation from 'components/Modal/delete'
import Lang from 'Lang/General'

import TipForm from './components/Form'
import EmailTemplate from './components/EmailTemplate'
import TipsHOC from './actions'
import ContentCategoriesHOC from '../ContentSettings/Categories/actions'
import CenterGroupsHOC from './actions/centerGroups'

const Tips = props => {
  const [ previewVid, updatePreviewVid ] = useState( {} )
  const [ searchColumn, updateSearchColumn ] = useState([
    {
      name: Lang[ 'TITLE' ][ props.data.languageReducer.lang ],
      type: "text",
      val: 'title'
    },
    {
      name: Lang[ 'STATUS' ][ props.data.languageReducer.lang ],
      type: 'dropdown',
      val: 'status',
      dropdownOptions: []
    },
    {
      name: Lang[ 'CATEGORIES' ][ props.data.languageReducer.lang ],
      type: 'searchableDropdown',
      val: 'category_id',
      dropdownOptions: []
    },
    {
      name: Lang[ 'CENTER_GROUP' ][ props.data.languageReducer.lang ],
      type: 'searchableDropdown',
      val: 'center_group_id',
      dropdownOptions: []
    }
  ])

  useEffect( () => {
    Promise.all([
      props.getTips(
        { 
          page: 1,
          is_paginated: true,
          filter: { has_categories: true },
          sort: [ '+injoy_week_number' ]
        },
        true
      ),
      props.getTips(
        { 
          page: 1,
          is_paginated: true,
          filter: { has_categories: false },
          sort: [ '+week_number' ]
        },
        false
      ) 
    ]).then( () => props.onChangeTipsHOC( 'currentTab', 0 ) )
    props.getCenterGroups()
    props.getCategories({
      filter: {
        type: 'TIP'
      }
    })
  }, [] )

  useEffect( () => {
    if( !props.showCreateTip ){
      props.onChangeTipsHOC( 
        'newTip', 
        { 
          calendar_year: parseInt( Moment().format( 'YYYY' ) ),
          center_groups: [],
          description: { en: '', de: '', it: '', nl: '' },
          content: { en: '', de: '', it: '', nl: '' },
          description_type: 'text',
          video_url: '',
          video_type: 'youtube',
          title: { en: '', de: '', it: '', nl: '' },
          status: 'ACTIVE',
          week_number: 1,
          category_ids: [],
          injoy_week_number: 1
        } 
      )
    }
  }, [ props.showCreateTip ] )

  useEffect( () => {
    if( !props.showUpdateTip ){
      props.onChangeTipsHOC( 'selectedTip', {} )
    }
  }, [ props.showUpdateTip ] )

  useEffect( () => {
    if( props.categories.length > 0 || props.centerGroups.length > 0 ){
      let tmpSearchColumn = _.cloneDeep( searchColumn )
      if( props.categories.length > 0 ){
        let tmpIndex = _.findIndex( tmpSearchColumn, { val: 'category_id' } )
        tmpSearchColumn[ tmpIndex ][ 'dropdownOptions' ] = props.categories.map( category => ({
          id: category.id,
          label: category.name[ props.data.languageReducer.lang ],
          value: category.name[ props.data.languageReducer.lang ],
        }) )
      }
      if( props.centerGroups.length > 0 ){
        let tmpCgIndex = _.findIndex( tmpSearchColumn, { val: 'center_group_id' } )
        tmpSearchColumn[ tmpCgIndex ][ 'dropdownOptions' ] = _.cloneDeep( props.centerGroups )
      }
      updateSearchColumn( tmpSearchColumn )
    }
  }, [ props.categories, props.centerGroups ] )

  const handleSecurityModulesCreate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Member Area' })
      return tmpModules.is_create
    }
  }

  const handleSecurityModulesUpdate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Member Area' })
      return tmpModules.is_update
    }
  }

  const handleSecurityModulesDelete = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Member Area' })
      return tmpModules.is_delete
    }
  }

  const searchTips = ( query, type, searchParams, searchVal ) => {
    let tmpQuery = _.cloneDeep( query )
    if ( type === 'Normal Search' ) {
      tmpQuery = {
        ...tmpQuery,
        filter: {
          title: {
            $like: `%${ searchVal.toLowerCase() }%`
          }
        }
      }
    } else if ( type === 'Advanced Search' ) {
      let tmpFilter = {}
      _.filter( searchParams, item => !_.isEmpty( item.searchValue ) ).map( item => {
        if ( item.val === 'title' ) {
          tmpFilter[ item.val ] = { $like: `%${ item.searchValue.toLowerCase() }%` }
        } else if ( item.val === 'status' ) {
          tmpFilter[ item.val ] = item.searchValue
        } else if ( props.currentTab === 1 || item.val === 'center_group_id' ){
          tmpFilter[ item.val ] = item.searchValue.id
        }
      })
      if( !_.isEmpty( tmpFilter ) ){
        tmpQuery = {
          ...tmpQuery,
          filter: tmpFilter
        }
      }
    }
    if( !tmpQuery.filter ){
      tmpQuery.filter = {}
    }
    if( props.currentTab === 1 ) {
      tmpQuery.filter[ 'has_categories' ] = true
      tmpQuery[ 'sort' ] = [ '+injoy_week_number' ]
    } else {
      tmpQuery.filter[ 'has_categories' ] = false
      tmpQuery[ 'sort' ] = [ '+week_number' ]
    }
    props.getTips( tmpQuery, props.currentTab === 1 ) 
  }

  const renderCreateTipModal = () => (
    <Modal size='md' isOpen={ props.showCreateTip } >
      <ModalHeader toggle={ () => props.onChangeTipsHOC( 'showCreateTip', false ) } >
        { Lang[ 'CREATE' ][ props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        <TipForm
          { ...props }
          tipType='newTip'
          currentTip={ props.newTip }
          lang={ props.data.languageReducer.lang }
        />
        { props.onLoadTipsHOC && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => props.createTip( props.newTip ) }>
          { Lang[ 'SUBMIT' ][ props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )

  const renderUpdateTipModal = () => (
    <Modal size='md' isOpen={ props.showUpdateTip } >
      <ModalHeader toggle={ () => props.onChangeTipsHOC( 'showUpdateTip', false ) } >
        { Lang[ 'UPDATE' ][ props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        {
          !_.isEmpty( props.selectedTip ) && <TipForm
            { ...props }
            tipType='selectedTip'
            currentTip={ props.selectedTip }
            lang={ props.data.languageReducer.lang }
          />
        }
        { props.onLoadTipsHOC && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => props.updateTip( props.selectedTip ) }>
          { Lang[ 'SUBMIT' ][ props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )

  const renderPreviewVideo = () => (
    <Modal 
      isOpen={ !_.isEmpty( previewVid ) }
      style={{ maxWidth: "85vw" }} >
      <ModalHeader toggle={ () => updatePreviewVid( {} ) } />
      <ModalBody className='d-flex justify-content-center' >
        <iframe
          id='tips-preview-video-url'
          src={ previewVid.video_type === 'youtube'
            ? `https://www.youtube-nocookie.com/embed/${ previewVid.video_url }?autoplay=1`
            : `https://player.vimeo.com/video/${ previewVid.video_url }`
          }
          style={{
            display: "block",
            width: "80vw",
            height: "70vh",
          }}
          allow="fullscreen"
        />
      </ModalBody>
    </Modal>
  )

  return(
    <>
      <PageTitle
        heading={ Lang[ 'TIPS_OF_THE_WEEK' ][ props.data.languageReducer.lang ] }
        icon="pe-7s-users icon-gradient bg-happy-fisher"
        buttons={[
          {
            buttonText: Lang[ 'CREATE' ][ props.data.languageReducer.lang ],
            onClick: () => props.onChangeTipsHOC( 'showCreateTip', true ),
            display: handleSecurityModulesCreate()
          },
          {
            buttonText: Lang[ 'UPDATE_EMAIL_SETTING' ][ props.data.languageReducer.lang ],
            onClick: () => props.getEmailTemplate(),
            display: handleSecurityModulesUpdate()
          },
        ]}/>
      <Card>
        <CardBody>
          { <style>{ '.RRT__container { padding: 0; } .RRT__panel { opacity: 0; height: 0; padding: 0; }' }</style> }
          <Tabs
            tabsWrapperClass="body-tabs"
            showMore={ true }
            transform={ true }
            showInkBar={ true }
            items={[ 
              { 
                key: 0,
                title: Lang[ 'FIFTY_TWO_WEEK_TIPS' ][ props.data.languageReducer.lang ]
              },
              { 
                key: 1,
                title: Lang[ 'TWELVE_WEEK_TIPS' ][ props.data.languageReducer.lang ] 
              }
            ]}
            transformWidth={ 400 }
            selectedTabKey={ props.currentTab }
            onChange={ val => props.onChangeTipsHOC( 'currentTab', val ) } 
          />
          <ARKTable
            showPagination={ false }
            data={ props.currentTab === 0 ? props.tips.data : props.specialTips.data }
            totalPages={ props.currentTab === 0 ? props.tipsPages : props.specialTipsPages }
            page={ 
              props.currentTab === 0 && props.tips.meta 
                ? props.tips.meta.current_page 
                : props.currentTab === 1 && props.specialTips.meta
                  ? props.specialTips.meta.current_page
                  : 1
            }
            onSearch={ searchTips }
            searchLabel={ Lang[ 'TITLE' ][ props.data.languageReducer.lang ] }
            searchComponent={ true }
            searchColumn={ props.currentTab === 0
              ?  _.filter( searchColumn, item => item.val !== 'category_id' ) 
              : searchColumn
            }
            columns={[
              {
                Header: Lang[ 'TITLE' ][ props.data.languageReducer.lang ],
                accessor: "title",
                Cell: ({ value }) => value[ props.data.languageReducer.lang ] 
              },
              {
                Header: Lang[ 'WEEK_TO_DISPLAY' ][ props.data.languageReducer.lang ],
                accessor: "week_number",
                Cell: row => props.currentTab === 1 ? row.original.injoy_week_number : row.original.week_number
              },
              {
                Header: Lang[ 'STATUS' ][ props.data.languageReducer.lang ],
                accessor: "status",
                Cell: ({ value }) => Lang[ value ][ props.data.languageReducer.lang ] 
              },
              ...( 
                props.currentTab === 1 ? ([{
                  Header: Lang[ 'CATEGORIES' ][ props.data.languageReducer.lang ],
                  accessor: "categories",
                  Cell: ({ value }) => <ul>
                    {
                      value.map( category => <li>{ category.name[ props.data.languageReducer.lang ] }</li> )
                    }
                  </ul>
                }]) : ( 
                  [] 
                )
              ),
              {
                Header: Lang[ 'ACTIONS' ][ props.data.languageReducer.lang ],
                accessor: "id",
                Cell: row => (
                  <div className='d-flex w-100 justify-content-center' >
                    {
                      handleSecurityModulesUpdate() && (
                        <>
                          <Button
                            id={ `Tip-${ row.original.id }-update` }
                            className="mb-2 mr-2 btn-icon btn-icon-only"
                            color="primary"
                            onClick={ () => {
                              props.getSelectedTip( row.original.id ) 
                              props.getSelectedTipFiles( row.original.id )
                            }}>
                            <i className="pe-7s-note"> </i>
                          </Button>
                          <UncontrolledTooltip
                            target={ `Tip-${ row.original.id }-update` }
                            placement="top">{ Lang[ 'EDIT' ][ props.data.languageReducer.lang ] }
                          </UncontrolledTooltip>
                        </>
                      )
                    }
                    {
                      handleSecurityModulesDelete() && (
                        <>
                          <Button
                            id={ `Tip-${ row.original.id }-delete-tip` }
                            className="mb-2 mr-2 btn-icon btn-icon-only"
                            color="danger"
                            onClick={ () => props.onChangeTipsHOC( 'showDeleteTipId', parseInt( row.original.id ) ) }>
                            <i className="pe-7s-trash"> </i>
                          </Button>
                          <UncontrolledTooltip
                            target={ `Tip-${ row.original.id }-delete-tip` }
                            placement="top">{ Lang[ 'REMOVE' ][ props.data.languageReducer.lang ] }
                          </UncontrolledTooltip>
                        </>
                      )
                    }
                    {
                      ( row.original.description_type === 'video' && !_.isEmpty( row.original.video_url ) ) && (
                        <>
                          <Button
                            id={ `Tip-${ row.original.id }-video-preview` }
                            className='mb-2 mr-2 btn-icon btn-icon-only'
                            color='primary'
                            onClick={ () => updatePreviewVid( row.original ) }>
                            <i className='pe-7s-film'/>
                          </Button>
                          <UncontrolledTooltip
                            target={ `Tip-${ row.original.id }-video-preview` }
                            placement='top'>{ Lang[ 'PREVIEW_VIDEO' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                        </>
                      )
                    }
                  </div>
                )
              }
            ]}
            onChangePage={ pg => {
              props.getTips(
                { 
                  page: pg, 
                  is_paginated: true,
                  filter: { has_categories: props.currentTab === 1 },
                  sort: [ props.currentTab === 1 ? '+injoy_week_number' : '+week_number' ]
                },
                props.currentTab === 1
              ) 
            }}
            onClickReset={ () => {
              let tmp = _.cloneDeep( props.currentTab )
              Promise.all([
                props.getTips(
                  { 
                    page: 1,
                    is_paginated: true,
                    filter: { has_categories: true },
                    sort: [ '+injoy_week_number' ]
                  },
                  true
                ),
                props.getTips(
                  { 
                    page: 1,
                    is_paginated: true,
                    filter: { has_categories: false },
                    sort: [ '+week_number' ]
                  },
                  false
                ) 
              ]).then( () => props.onChangeTipsHOC( 'currentTab', tmp ) )
            }}
          />
        </CardBody>
      </Card>
      <DeleteConfirmation
        handleModalClose={ () => props.onChangeTipsHOC( 'showDeleteTipId', 0 ) }
        deleteOpen={ props.showDeleteTipId > 0 }
        confirmAction={ () => props.removeTip( props.showDeleteTipId ) }
        content={ Lang[ 'DELETE_CONFIRMATION_TIP' ][ props.data.languageReducer.lang ] }
        lang={ props.data.languageReducer.lang } />
      <EmailTemplate 
        { ...props }
        lang={ props.data.languageReducer.lang } />
      { renderCreateTipModal() }
      { renderUpdateTipModal() }
      { renderPreviewVideo() }
      { ( props.onLoadTipsHOC || props.onLoadCenterGroups || props.onLoadCategoriesHOC ) && <LoadingOverlay/> }
    </>
  )
}

export default compose(
  TipsHOC,
  ContentCategoriesHOC,
  CenterGroupsHOC
)( Tips )