import React, { useState } from 'react'
import _ from 'lodash'
import { 
  IoMdArrowUp, 
  IoMdArrowDown, 
  IoMdBrush, 
  IoIosTrash,
  IoMdAdd
} from 'react-icons/io'
import Lang from 'Lang/General'

import { reorder } from '../../utility/reorderArray'

const checkFirstElement = ( selectedPage, currentSectionID, from ) => {
  let tmp = _.find( selectedPage.content.children, { variant: from === "header-section" ? "header-sections-container" : "sections-container" })
  let currentIndex = _.findIndex( tmp.children, { id: currentSectionID })
  let res = false
  if( currentIndex === 0 ) {
    res = true
  }
  return res
}

const checkLastElement = ( selectedPage, currentSectionID, from ) => {
  let tmp = _.find( selectedPage.content.children, { variant: from === "header-section" ? "header-sections-container" : "sections-container" })
  let currentIndex = _.findIndex( tmp.children, { id: currentSectionID })
  let res = false
  if( currentIndex === tmp.children.length -1 ) {
    res = true
  }
  return res
}

const CustomTooltip = ({children, message}) => {
  const [showTooltip, setShowToolTip] = useState(false)
  return(
    <div 
      style={{
        display:"block", 
        position:"relative"
      }} 
      onMouseEnter={()=>setShowToolTip(true)} 
      onMouseLeave={()=>setShowToolTip(false)}
    >
      <div> {children} </div>
      <div 
        style={{ 
          display : showTooltip && message ? "block" : "none",
          background : "#000", 
          position : "absolute", 
          top : "50%", 
          right : "110%", 
          transform : "translateY(-50%)",
          color : "#fff", 
          whiteSpace : "nowrap", 
          padding : "2px 10px", 
          borderRadius : "5px"
          }}
        >
          {message ? message : ""}
        </div>
    </div>
  )
}

const Utility = ({
  from,
  selectedPage,
  currentSection,
  updateMainContainerState,
  updateSelectedPage,
  selectedLanguageShortname
}) => {
  return (
    <div
      style={{
        position: 'sticky',
        top: '125px',
        width: '60px',
        background: "rgba(0,0,0,0.4)",
        padding: "10px",
        zIndex: "990",
        display: "flex",
        flexDirection: "column"
      }}>
      {
        from !== "header-section" && from !== 'empty' ?  (
          <>
            <button
            className="btn btn-primary"
            style={{ marginBottom: "5px" }}
            disabled={ checkFirstElement( selectedPage, currentSection.id, from ) }
            onClick={ () => {
              console.log( updateSelectedPage )
              if( typeof updateSelectedPage === "undefined" ) {
                let tmp = _.find( selectedPage.content.children, { variant: from === "header-section" ? "header-sections-container" : "sections-container" })
                let currentIndex = _.findIndex( tmp.children, { id: currentSection.id })
                return reorder( tmp.children, currentIndex, currentIndex - 1 )
              } else {
                let tmpPage = _.cloneDeep( selectedPage )
                let tmp = _.find( tmpPage.content.children, { variant: from === "header-section" ? "header-sections-container" : "sections-container" })
                let currentIndex = _.findIndex( tmp.children, { id: currentSection.id })
                reorder( tmp.children, currentIndex, currentIndex - 1 )
                return updateSelectedPage( tmpPage.id, tmpPage, () => {} )
              }
            }}>
            <IoMdArrowUp/>
          </button>
          <button
            className="btn btn-primary"
            style={{ marginBottom: "5px" }}
            disabled={ checkLastElement( selectedPage, currentSection.id, from ) }
            onClick={ () => {
              if( typeof updateSelectedPage === "undefined" ) {
                let tmp = _.find( selectedPage.content.children, { variant: from === "header-section" ? "header-sections-container" : "sections-container" })
                let currentIndex = _.findIndex( tmp.children, { id: currentSection.id })
                return reorder( tmp.children, currentIndex, currentIndex + 1 )
              } else {
                let tmpPage = _.cloneDeep( selectedPage )
                let tmp = _.find( tmpPage.content.children, { variant: from === "header-section" ? "header-sections-container" : "sections-container" })
                let currentIndex = _.findIndex( tmp.children, { id: currentSection.id })
                reorder( tmp.children, currentIndex, currentIndex + 1 )
                return updateSelectedPage( tmpPage.id, tmpPage, () => {})
              }
            }}>
            <IoMdArrowDown/>
          </button>
          <button
            className="btn btn-success"
            style={{ marginBottom: "5px" }}
            onClick={ () => {
              updateMainContainerState( 'showSectionPicker', true )
            }}>
            <IoMdAdd/>
          </button>
          { 
            selectedPage.isLandingPage && <button
              className="btn btn-success"
              style={{ marginBottom: "5px" }}
              onClick={ () => {
                updateMainContainerState( 'showSectionEditor', true )
              }}>
              <IoMdBrush/>
            </button>
          }
          <button
            className="btn btn-primary"
            style={{ marginBottom: "5px", background: "red", color: "#fff" }}
            disabled={ checkFirstElement( selectedPage, currentSection.id ) && checkLastElement( selectedPage, currentSection.id, from ) }
            onClick={ () => updateMainContainerState( 'showConfirmRemoveDialog', true )}>
            <IoIosTrash/>
          </button>
        </>
      ) : (
        from === 'empty' ? (
          <CustomTooltip message={ Lang[ "ADD_SECTION" ][ selectedLanguageShortname ] }>
            <button
              className="btn btn-primary d-flex align-items-center justify-content-center p-2"
              style={{ marginBottom: "5px", color: "#fff", width: "100%" }}
              onClick={() => {
                updateMainContainerState( 'showSectionPicker', true )
              }}>
              <IoMdAdd/>
            </button>
          </CustomTooltip>
        ) : (
          <button
            className="btn btn-success"
            style={{ marginBottom: "5px" }}
            onClick={ () => {
              updateMainContainerState( 'showSectionEditor', true )
            }}>
            <IoMdBrush/>
          </button>
        )
      )
    }
    </div>
  )
}

export default Utility
