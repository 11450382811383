import React from 'react'
import {
  Button, Form, FormGroup,
} from 'reactstrap'
import _ from 'lodash'

import Lang from 'Lang/General'

const TextEdit = props => {

  return (
    <Form onSubmit={ e => e.preventDefault()}>
      <FormGroup>
        <Button
          color="danger"
          onClick={() => {
            let tmpColumn = _.cloneDeep( props.selectedCol )
            tmpColumn = props.FindComponentAndPatch( tmpColumn, props.selectedComponent, true )
            props.updateSelectedCol( tmpColumn )
            let tmpContainer = _.cloneDeep( props.dom )
            tmpContainer = props.FindComponentAndPatch( tmpContainer, props.selectedComponent, true )
            props.updateDom( tmpContainer )
            props.updateSelectedComponent( {} )
          }}>
          { Lang[ 'REMOVE_COMPONENT' ][ props.selectedLanguage ] }
        </Button>
      </FormGroup>
    </Form>
  )
}

export default TextEdit
