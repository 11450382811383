import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get, Post, Put, Delete } from 'utils/axios'
import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showCreateCurrency: false,
      showUpdateCurrency: false,
      currencies: [],
      newCurrency: {
        name: '',
        decimal_separator: '.'
      },
      selectedCurrency: {},
      showDeleteCurrency: false
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    } )
    requestError = error => {
      toast.error( error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false
    } )}

    onChangeCurrenciesHOC = ( key, val ) => this.setState({ [ key ] : val })

    createCurrency = data => Post(
      `/api/v1/online_membership_currencies`,
      data,
      this.createCurrencySuccess,
      this.createCurrencyError,
      this.load
    )
    createCurrencySuccess = () => {
      this.requestSuccess( Lang[ 'CURRENCY_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getCurrencies()
      this.setState({ showCreateCurrency: false })
    }
    createCurrencyError = error => this.requestError( error )

    getCurrencies = () => Get(
      `/api/v1/online_membership_currencies`,
      this.getCurrenciesSuccess,
      this.getCurrenciesError,
      this.load
    )
    getCurrenciesSuccess = payload => this.setState({ currencies: payload })
    getCurrenciesError = error => this.requestError( error )

    getSelectedCurrency = currency_id => Get(
      `/api/v1/online_membership_currencies/${ currency_id }`,
      this.getSelectedCurrencySuccess,
      this.getSelectedCurrencyError,
      this.load
    )
    getSelectedCurrencySuccess = payload => this.setState({ selectedCurrency: payload })
    getSelectedCurrencyError = error => this.requestError( error )

    updateCurrency = ( currency_id, data ) => Put(
      `/api/v1/online_membership_currencies/${ currency_id }`,
      data,
      this.updateCurrencySuccess,
      this.updateCurrencyError,
      this.load
    )
    updateCurrencySuccess = () => {
      this.requestSuccess( Lang[ 'CURRENCY_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getCurrencies()
      this.setState({ showUpdateCurrency: false })
    }
    updateCurrencyError = error => this.requestError( error )

    removeCurrency = currency_id => Delete(
      `/api/v1/online_membership_currencies/${ currency_id }`,
      this.removeCurrencySuccess,
      this.removeCurrencyError,
      this.load
    )
    removeCurrencySuccess = () => {
      this.requestSuccess( Lang[ 'CURRENCY_REMOVE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getCurrencies()
    }
    removeCurrencyError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            onLoadCurrencies={ this.state.loading }
            onChangeCurrenciesHOC={ this.onChangeCurrenciesHOC }
            currencies={ this.state.currencies }
            newCurrency={ this.state.newCurrency }
            selectedCurrency={ this.state.selectedCurrency }
            showCreateCurrency={ this.state.showCreateCurrency }
            showUpdateCurrency={ this.state.showUpdateCurrency }
            createCurrency={ this.createCurrency }
            getCurrencies={ this.getCurrencies }
            getSelectedCurrency={ this.getSelectedCurrency }
            updateCurrency={ this.updateCurrency }
            removeCurrency={ this.removeCurrency }
            showDeleteCurrency={ this.state.showDeleteCurrency } />
        </>
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect ( mapStateToProps )( WithHOC )
}

export default HOC
