import React, { Component } from 'react'
import {
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody
} from 'reactstrap'
import _ from 'lodash'
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import uniqid from 'uniqid'
import { SketchPicker } from 'react-color'
import { connect } from 'react-redux'
import { compose } from 'redux'

import MultilingualInput from "components/Input/MultiLang"
import LoadingOverlay from "components/Indicator/LoadingOverlay"

import UpdateFormFrame from './Utility/FormFrame'
import UpdateColumnModal from './Utility/Column'
import UpdateContentModal from './Utility/Content'
import DeletePromptModal from './Utility/Delete'

import ButtonConfigModal from './ButtonConfigModal'
import RenderDOM from './RenderDOM'
import Lang from 'Lang/General'
import { toggleMediaDatabase } from 'actions/MediaDatabaseToggle'

const reorder = ( list, startIndex, endIndex ) => {
  const result = Array.from( list )
  const [ removed ] = result.splice( startIndex, 1 )
  result.splice( endIndex, 0, removed )
  return result
}

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "transparent"
})

class Update extends Component {
  state = {
    activeTab: 1,
    dom: [],
    formValues: [],
    showUpdateFormFrame: false,
    showUpdateColumnModal: false,
    showUpdateContentModal: false,
    showConfirmDelete: false,
    selectedParentComponentToEdit: {},
    selectedComponentToEdit: {},
    selectedComponentToRemove: {},
    componentsRemoved: [],
    foundObject: {
      children: []
    },
    currentLanguage: this.props.data.languageReducer.lang,
    name: '',
    status: '',
    foundObjectByType: {},
    formFrameMounted: false,
    buttonContainer: {},
    redirectInfo: {
      type: "button",
      variant: "",
      redirectType: "",
      isPublicFileUrl: false,
      navTo: "",
      href: "",
      newTab: false
    },
    bottomButtonText: Lang[ 'SUBMIT' ]
  }

  componentDidUpdate = async pp => {
    if( !pp.showUpdateForm && this.props.showUpdateForm ){
      this.setState({ formValues: [], dom: [], removedComponent: [] }, () => {
        this.mountFormFrame()
        this.mountInfoModal()
        let tmpDom = _.cloneDeep( this.state.dom )
        tmpDom.push( this.props.selectedForm.content )
        this.findSubmitButton( this.props.selectedForm.content[0] )
        this.setState({ dom: tmpDom, formValues: this.props.selectedForm.values })
      })
    }

    if( pp.showUpdateForm && !this.props.showUpdateForm ){
      this.setState({ redirectInfo: {
        type: "button",
        variant: "",
        redirectType: "",
        isPublicFileUrl: false,
        navTo: "",
        href: "",
        newTab: false
      }})
      this.props.onChangeFormsHOC( 'selectedForm', {
        name: {
          en: '',
          de: '',
          it: '',
          nl: ''
        },
        content: [],
        values: [],
        start_date_hint: {
          en: "Note: The campaign duration is always 8 weeks",
          de: "Hinweis: Die Kampagnendauer beträgt immer 8 Wochen",
          it: "Nota: la durata della campagna è sempre di 8 settimane",
          nl: "Opmerking: De duur van de campagne is altijd 8 weken"
        },
        status: 'ACTIVE',
        background_color: ''
      } )
    }

    if( this.props.selectedForm.background_color !== pp.selectedForm.background_color ){
      if( this.props.selectedForm.content && this.props.selectedForm.content.length > 0 ){
        let tmpSelectedForm = _.cloneDeep( this.props.selectedForm )
        let tmpDom = _.cloneDeep( this.state.dom )

        await this.findObjectByType( tmpDom[0].children, 'Container' )
        let tmpFound = _.cloneDeep( this.state.foundObjectByType )
        tmpFound = {
          ...this.state.foundObjectByType,
          style: { backgroundColor: this.props.selectedForm.background_color }
        }
        tmpSelectedForm = {
          ...this.props.selectedForm,
          content: tmpDom
        }

        this.props.onChangeFormsHOC( 'selectedForm', tmpSelectedForm )
        await this.findObjectAndPatch( tmpSelectedForm.content, tmpFound )
        tmpDom[0] = this.props.selectedForm.content[0]
        this.setState({ dom: tmpDom })
      }
    }
  }

  findSubmitButton = data => {
    data.children.map( child => {
      if( child.type && child.type === 'button' ){
        let tmpRedirectInfo = _.cloneDeep( this.state.redirectInfo )
        Object.keys( tmpRedirectInfo ).map( key => {
          if( child[ key ]){
            tmpRedirectInfo[ key ] = child[ key ]
          }
        })
        this.setState({ 
          buttonContainer: data, 
          redirectInfo: tmpRedirectInfo, 
          initialRedirectInfo: tmpRedirectInfo
        })
        this.props.onChangeTempComponent( 'selectedTempComponent', child )
      } else if( child.children && child.children.length > 0 ){
        this.findSubmitButton( child )
      }
    })
  } 

  mountInfoModal = () => {
    let currentForm = this.props.selectedForm
    this.setState({
      name: currentForm.name,
      status: currentForm.status
    })
  }

  mountFormFrame = async () => {
    await this.findObjectByType( this.props.selectedForm.content, 'FormFrame' )
    let tmpFormFrame = _.cloneDeep( this.state.foundObjectByType )
    let tmpSelectedForm = _.cloneDeep( this.props.selectedForm )
    let tmpDom = _.cloneDeep( this.state.dom )

    tmpFormFrame.children.map( ( formContent, index ) => {
      let tmpId = uniqid()
      tmpFormFrame.children[ index ].children.push({
        id: uniqid(),
        type: 'Button',
        mapWith: 'add-new-column-button',
        onClickEditor: async() => {
          await this.onClickAddNewColumn()
        },
        color: 'light',
        content: {
          "en": "Add new column",
          "de": "Neue Spalte hinzufügen",
          "nl": "Nieuwe kolom toevoegen",
          "it": "Aggiungi nuova colonna"
        },
        style: {
          width: '100%'
        }
      })

      tmpFormFrame.children[ index ].children[0].children.push({
        id: tmpId,
        type: 'Col',
        grid: 12,
        children: [
          {
            id: uniqid(),
            type: 'Button',
            color: 'light',
            onClickEditor: () => {
              this.setState({
                showUpdateContentModal: true,
                selectedParentComponentToEdit: {
                  id: tmpId,
                  type: 'Col'
                }
              }, () => {
                this.findObjectById( this.state.dom, this.state.selectedParentComponentToEdit.id )
              })
            },
            content: {
              'en': 'Click to select form\'s element',
              'de': 'Klicken Sie auf , um das Element des Formulars auszuwählen ',
              'nl': 'Klik om het element van het formulier te selecteren',
              'it': 'Clicca per selezionare l\'elemento del modulo'
            },
            style: {
              width: '100%'
            },
            mapWith: 'add-content-in-column-button'
          }
        ]
      })
    })

    await this.findObjectAndPatch( tmpSelectedForm.content, tmpFormFrame )
    tmpDom[0] = tmpSelectedForm.content[0]
    this.setState({ dom: tmpDom, formFrameMounted: true })
  }

  findObjectByType = ( data, type ) => {
    data.map(( item, index ) => {
      if( item.type === type ) {
        this.setState({ foundObjectByType: item })
      } else {
        if( item.children ) {
          this.findTargetObject( item.children, type )
        }
      }
    })
  }

  findObjectAndRemove = ( data, target ) => {
    data.map(( item, index ) => {
      if( item.id === target.id ) {
        data.splice( index, 1 )
      } else {
        if( item.children ) {
          this.findObjectAndRemove( item.children, target )
        }
      }
    })
  }

  findExtraButtonsAndRemove = data => {
    data.map( item  => {
      item.children = _.filter( item.children, child => child.mapWith !== 'add-content-in-column-button' && child.mapWith !== 'add-new-column-button' )
      if( item.children ) {
        this.findExtraButtonsAndRemove( item.children )
      }
    })
  }

  updateSubmitButton = data => {
    let tmpButtonContainer = _.cloneDeep( this.state.buttonContainer )
    let tmp = _.cloneDeep( this.props.selectedTempComponent )
    tmp = { ...tmp, ...this.state.redirectInfo }
    tmpButtonContainer.children.splice( 0, 1, tmp )
    this.findObjectAndPatch(data, tmpButtonContainer)
  }

  onChangeFormBuilderState = ( key, val ) => this.setState({ [key]: val })

  onPatchFormValues = ( index, key, val ) => {
    let tmp = _.cloneDeep( this.state.formValues )
    tmp[ index ][ key ] = val
    return this.setState({ formValues: tmp })
  }

  findObjectAndPatch = ( data, target ) => {
    data.map(( item, index ) => {
      if( item.id === target.id ) {
        data.splice( index, 1, target )
      } else {
        if( item.children ) {
          this.findObjectAndPatch( item.children, target )
        }
      }
    })
    return data
  }

  findObjectById = ( data, id ) => {
    data.map(( item ) => {
      if( item.id === id ) {
        this.setState({ foundObject: item })
      } else {
        if( item.children ) {
          this.findObjectById( item.children, id )
        }
      }
    })
  }

  findTargetObject = ( data, target ) => {
    data.map(( item ) => {
      if( item.mapWith === target ) {
        this.setState({ foundObject: item })
      } else {
        if( item.children ) {
          this.findTargetObject( item.children, target )
        }
      }
    })
  }

  findTargetAndPatch = ( data, target, result ) => {
    data.map(( item, index ) => {
      if( item.mapWith === target ) {
        data.splice( index, 1, result )
      } else {
        if( item.children ) {
          this.findTargetAndPatch( item.children, target, result )
        }
      }
    })
    return data
  }

  onClickAddNewColumn = async () => {
    let tmp = _.cloneDeep( this.state.dom )
    await this.findTargetObject( tmp, 'form-content' )
    let tmpFoundObject = _.cloneDeep( this.state.foundObject )
    let id = uniqid()
    tmpFoundObject.children.push({
      id: id,
      type: 'Col',
      grid: 12,
      children: [
        {
          id: uniqid(),
          type: 'Button',
          onClickEditor: () => {
            this.setState({
              showUpdateContentModal: true,
              selectedParentComponentToEdit: {
                id: id,
                type: 'Col'
              }
            }, () => {
              this.findObjectById( this.state.dom, this.state.selectedParentComponentToEdit.id )
            })
          },
          color: 'light',
          content: {
            'en': 'Click to select form\'s element',
            'de': 'Klicken Sie auf , um das Element des Formulars auszuwählen ',
            'nl': 'Klik om het element van het formulier te selecteren',
            'it': 'Clicca per selezionare l\'elemento del modulo'
          },
          style: {
            width: '100%'
          },
          mapWith: 'add-content-in-column-button'
        }
      ]
    })
    this.findObjectAndPatch( tmp, tmpFoundObject )
    this.setState({ dom: tmp })
  }

  onClickRemoveColumn = () => {
    let tmp = _.cloneDeep( this.state.dom )
    let tmpSelectComponentToRemove = _.cloneDeep( this.state.selectedComponentToRemove )
    let tmpComponentsRemoved = _.cloneDeep( this.state.componentsRemoved )
    tmpComponentsRemoved.push( tmpSelectComponentToRemove.children[0].id )
    this.findObjectAndRemove( tmp, tmpSelectComponentToRemove )
    return this.setState({
      showConfirmDelete: false,
      dom: tmp,
      componentsRemoved: tmpComponentsRemoved 
    })
  }

  onDragEnd = async result => {
    if( !result.destination ) {
      return;
    }
    let tmp = _.cloneDeep( this.state.dom )
    await this.findTargetObject( tmp, 'form-content' )
    let tmpFoundObject = _.cloneDeep( this.state.foundObject )
    let tmpFoundObjectChildren = _.cloneDeep( tmpFoundObject.children )
    tmpFoundObjectChildren = reorder( tmpFoundObjectChildren, result.source.index, result.destination.index )
    tmpFoundObject.children = tmpFoundObjectChildren
    await this.findTargetAndPatch( tmp, 'form-content', tmpFoundObject )
    this.setState({ dom: tmp })
  }

  onChangeSelectedForm = ( key, val ) => {
    let tmp = _.cloneDeep( this.props.selectedForm )
    tmp[ key ] = val
    this.props.onChangeFormsHOC( 'selectedForm', tmp )
  }

  renderGeneralTab = () => (
    <>
      {
        this.state.formFrameMounted && <>
          <Row>
            <Col md={ 12 }>
              <Form>
                <FormGroup>
                  <MultilingualInput
                    label={ Lang[ 'NAME' ][ this.props.data.languageReducer.lang ] }
                    selectedLanguage={ this.props.data.languageReducer.lang }
                    value={ this.props.selectedForm.name }
                    onChange={ val => this.onChangeSelectedForm( 'name', val ) } />
                </FormGroup>
                <Row>
                  <Col md={ 6 }>
                    <FormGroup>
                      <Label>{ Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ] }</Label>
                      <Input
                        type="select"
                        value={ this.props.selectedForm.status }
                        onChange={ e => {
                          let tmp = _.cloneDeep( this.props.selectedForm )
                          tmp[ 'status' ] = e.target.value
                          return this.props.onChangeFormsHOC( 'selectedForm', tmp )
                        }}>
                        <option value={ 'ACTIVE' }>{ Lang[ 'ACTIVE' ][ this.props.data.languageReducer.lang ] }</option>
                        <option value={ 'INACTIVE' }>{ Lang[ 'INACTIVE' ][ this.props.data.languageReducer.lang ] }</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={ 6 }>
                    <FormGroup>
                      <Label>{ Lang[ 'LANGUAGE' ][ this.props.data.languageReducer.lang ] }</Label>
                      <Input
                        type="select"
                        value={ this.state.currentLanguage }
                        onChange={ e => {
                          this.setState({ currentLanguage: e.target.value })
                        }}>
                        <option value={ 'en' }>en</option>
                        <option value={ 'de' }>de</option>
                        <option value={ 'nl' }>nl</option>
                        <option value={ 'it' }>it</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={ 6 }>
                    <FormGroup>
                      <Label>{ Lang[ 'BACKGROUND_COLOR' ][ this.props.data.languageReducer.lang ] }</Label>
                      {
                        this.props.selectedForm.content.length > 0 && this.props.selectedForm.content[ 0 ].children.length > 0 && (
                          <SketchPicker
                            color={ this.props.selectedForm.content[ 0 ].children[0].style ? this.props.selectedForm.content[ 0 ].children[0].style.backgroundColor : this.props.selectedForm.background_color }
                            onChangeComplete={ ( color ) => {
                              let tmp = _.cloneDeep( this.props.selectedForm )
                              let tmpColor = color.rgb
                              tmp[ 'background_color' ] = `rgba(${ tmpColor.r },${ tmpColor.g },${ tmpColor.b },${ tmpColor.a })`
                              return this.props.onChangeFormsHOC( 'selectedForm', tmp )
                            }}
                          />
                        )
                      }
                    </FormGroup>
                  </Col>
                  <Col md={ 6 }>
                    <Card>
                      <CardHeader>{ Lang[ 'FORM_END_BUTTON' ][ this.props.data.languageReducer.lang ] }</CardHeader>
                      <CardBody>
                        <Button 
                          color='primary' 
                          style={{ width: "100%" }} 
                          onClick={ () => { this.props.onChangeTempComponent( "showSelectButtonModal", true ) }}>
                          { Lang[ 'CLICK_HERE_TO_ENABLE_EDITOR' ][ this.props.data.languageReducer.lang ] }
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Row>
            <DragDropContext onDragEnd={ this.onDragEnd }>
              <Droppable droppableId="droppable">
                { ( provided, snapshot ) => (
                  <div
                    { ...provided.droppableProps }
                    ref={ provided.innerRef }
                    className="col-md-12"
                    style={ getListStyle( snapshot.isDraggingOver ) }>
                    {
                      this.state.dom.map( item => {
                        return RenderDOM(
                          item,
                          this.onChangeFormBuilderState,
                          'edit',
                          this.state.formValues,
                          this.onPatchFormValues,
                          () => {},
                          () => {},
                          this.state.dom,
                          this.findObjectAndPatch,
                          this.state.currentLanguage
                        )
                      })
                    }
                    { provided.placeholder }
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Row>
          <ButtonConfigModal 
            { ...this.props } 
            selectedLanguage={ this.props.data.languageReducer.lang }
            redirectInfo={ this.state.redirectInfo }
            onChangeRedirectInfo={( val ) => this.setState({ redirectInfo: val })} /> 
        </>
      }
    </>
  )

  render = () => {
    return (
      <Modal
        isOpen={ this.props.showUpdateForm }
        size={ 'xl' }
        toggle={ () => {
          this.props.onChangeFormsHOC( 'showUpdateForm', false )
        }}>
        <ModalHeader
          toggle={ () => this.props.onChangeFormsHOC( 'showUpdateForm', false ) }>
          { Lang[ 'UPDATE_FORM_SITE_CONTENT' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          { this.renderGeneralTab() }
          {
            !_.isEmpty( this.state.selectedComponentToEdit ) && (
              <UpdateFormFrame
                showUpdateFormFrame={ this.state.showUpdateFormFrame }
                selectedComponentToEdit={ this.state.selectedComponentToEdit }
                onChangeFormBuilderState={ this.onChangeFormBuilderState }
                findObjectAndPatch={ this.findObjectAndPatch }
                dom={ this.state.dom }
                currentLanguage={ this.state.currentLanguage }
                selectedLanguage={ this.props.data.languageReducer.lang } />
            )
          }
          <UpdateContentModal
            showUpdateContentModal={ this.state.showUpdateContentModal }
            selectedParentComponentToEdit={ this.state.selectedParentComponentToEdit }
            selectedComponentToEdit={ this.state.selectedComponentToEdit }
            onChangeFormBuilderState={ this.onChangeFormBuilderState }
            findObjectAndPatch={ this.findObjectAndPatch }
            findObjectById={ this.findObjectById }
            dom={ this.state.dom }
            foundObject={ this.state.foundObject }
            formValues={ this.state.formValues }
            currentLanguage={ this.state.currentLanguage }
            selectedLanguage={ this.props.data.languageReducer.lang } />
          { 
            this.state.formValues.length > 0 && 
              <UpdateColumnModal
                showUpdateColumnModal={ this.state.showUpdateColumnModal }
                selectedComponentToEdit={ this.state.selectedComponentToEdit }
                onChangeFormBuilderState={ this.onChangeFormBuilderState }
                findObjectAndPatch={ this.findObjectAndPatch }
                dom={ this.state.dom }
                formValues={ this.state.formValues }
                currentLanguage={ this.state.currentLanguage }
                selectedLanguage={ this.props.data.languageReducer.lang }
                backgroundColor={ 
                  this.props.selectedForm.content.length > 0 && this.props.selectedForm.content[ 0 ].children.length > 0 && this.props.selectedForm.content[ 0 ].children[0].style 
                    ? this.props.selectedForm.content[ 0 ].children[0].style.backgroundColor 
                    : this.props.selectedForm.background_color 
                } /> 
          }
          <DeletePromptModal
            showPromptModal={ this.state.showConfirmDelete }
            content={ 'Are you sure to remove selected component?' }
            onClickYes={ () => this.onClickRemoveColumn() }
            onClickNo={ () => this.setState({ showConfirmDelete: false, selectedComponentToRemove: {} }) }
            selectedLanguage={ this.props.data.languageReducer.lang } />
          { this.props.onLoadForms && <LoadingOverlay/> }
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={ async () => {
              let tmpDOM = _.cloneDeep( this.state.dom )
              this.findExtraButtonsAndRemove( tmpDOM )
              let tmpFormValues = _.cloneDeep( this.state.formValues )
              let tmpRearrangedFormValues = []
              let i = 0
              let j = 0

              tmpFormValues = _.filter( 
                tmpFormValues, 
                item => !_.isEmpty( item ) && _.findIndex( this.state.componentsRemoved, { id: item.id } ) === -1
              )
              tmpDOM[0].children.map( ( item, index ) => {
                if( item.children[0].children[0].children.length < 1 ){
                  tmpDOM[0].children.splice( index, 1 )
                }
              })
              tmpDOM[0].children.map( ( item, index ) => {
                tmpDOM[0].children[ index ].page = index + 1
              })

              for( i = 0; i < tmpDOM[0].children.length; i++ ){
                await this.findTargetObject( tmpDOM, 'form-content', i + 1 )
                let tmpFormContent = _.clone( this.state.foundObject )
                let tmpFormContentChildren = _.cloneDeep( this.state.foundObject.children )

                tmpFormContentChildren = _.filter( tmpFormContentChildren, child => child.children.length > 0 )
                tmpFormContentChildren.map( item => {
                  item.children[0].dataMapIndex = j
                  j = j + 1
                })

                tmpFormContentChildren.map( ( item ) => {
                  let tmpFormVal = tmpFormValues.find( x => x.id == item.children[0].id )
                  tmpRearrangedFormValues.push( tmpFormVal )
                })

                tmpFormContent.children = tmpFormContentChildren
                await this.findObjectAndPatch( tmpDOM, tmpFormContent )
              }
              this.updateSubmitButton( tmpDOM )
              if( tmpRearrangedFormValues.length > 0 ) {
                this.props.updateForm({
                  ...this.props.selectedForm,
                  content: tmpDOM,
                  values: tmpRearrangedFormValues
                })
              } else {
                alert( Lang[ 'FORM_IS_EMPTY_UNABLE_TO_SUBMIT' ][ this.props.data.languageReducer.lang ] )
              }
            }}>{ Lang[ 'SAVE' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({ data: state })

export default compose(
  connect( mapStateToProps, { toggleMediaDatabase } )
)( Update )
