import React from 'react'
import { Button } from 'reactstrap'

import RenderDOM from '../RenderDOM'

import ArkPagination from 'components/Pagination/FormFrame'
import Lang from 'Lang/General'

const FormFrame = ({
  content,
  onChangeFormBuilderState,
  mode,
  formValues,
  onPatchFormValues,
  onClickSubmitForm,
  createSubmittedFormMedia,
  currentPage,
  dom,
  findObjectAndPatch,
  data,
  currentLanguage,
  togglePageSelection,
  togglePageSelectable,
  duplicatePage,
  showPageRearrangement
}) => {
  return (
    <>
      <div
        style={{
          border: `${ content.container.borderWidth }px solid ${ content.container.borderColor }`,
          minHeight: '80vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}>
        <div 
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            flex: "1" }}>
          <div
            style={{
              ...content.title
            }}
            onClick={() => {
              if ( mode !== 'view' ) {
                onChangeFormBuilderState( 'selectedComponentToEdit', {
                  ...content
                })
                onChangeFormBuilderState( 'showUpdateFormFrame', true )
              }
            }}>
            { content.content[ currentLanguage ] }
          </div>
          {
            content.children && content.children.map( item => {
              return <RenderDOM                       
                content={ item }
                onChangeFormBuilderState={ onChangeFormBuilderState }
                mode={ mode }
                formValues={ formValues }
                onPatchFormValues={ onPatchFormValues }
                onClickSubmitForm={ onClickSubmitForm }
                createSubmittedFormMedia={ createSubmittedFormMedia }
                currentPage={ currentPage }
                dom={ dom }
                findObjectAndPatch={ findObjectAndPatch }
                data={ data }
                currentLanguage={ currentLanguage }
                togglePageSelection={ togglePageSelection }
                togglePageSelectable={ togglePageSelectable }
                duplicatePage={ duplicatePage }
                showPageRearrangement={ null }
              />
            })
          }
        </div>
        <div 
          style={{ 
            display: "flex",
            alignItems: "center",
            alignSelf: "flex-end",
            float: "right" }}>
          <ArkPagination
            dom={ dom }
            formValues={ formValues }
            onChangeFormBuilderState={ onChangeFormBuilderState }
            page={ currentPage }
            totalPages={ Array.from({length: content.totalPages}, (_, i) => i + 1) }
            onChangePage={ (page) => { onChangeFormBuilderState( 'currentPage', page ) } }
            currentLanguage={ data.languageReducer.lang }
            showPageRearrangement={ showPageRearrangement } />
        </div>
      </div>
    </>
  )
}

export default FormFrame