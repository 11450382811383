import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios'
import { getRefreshToken } from 'actions/token'
import { convertObjToBase64 } from 'utils/objToBase64'

const OlsTeamClients = ( WrappedComponent ) => {
  class OlsTeamClientsWrappedComponent extends Component {
    state = {
      loading: false,
      showViewCampaign: false,
      clients: {},
      clientTotalPages: 0,
      campaigns: {},
      campaignsTotalPages: 0,
      selectedBookedCampaign: {},
      center_name: '',
      selectedCenterName: '',
      user: {
        id: this.props.data.profileReducer.profile.id,
        label: `${ this.props.data.profileReducer.profile.first_name } ${ this.props.data.profileReducer.profile.last_name }`,
        value: `${ this.props.data.profileReducer.profile.first_name } ${ this.props.data.profileReducer.profile.last_name }`
      }
    }

    onChangeOlsTeamClients = ( key, value ) => this.setState({ [key]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    getClients = query => Get(
      `/api/v1/ols_teams/campaigns?query=${ convertObjToBase64( query ) }`,
      this.getClientsSuccess,
      this.getClientsError,
      this.load
    )
    getClientsSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      return this.setState({ clients: payload, clientTotalPages: tmpTotalPages })
    }
    getClientsError = error => this.requestError( error )

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          clients={ this.state.clients }
          clientTotalPages={ this.state.clientTotalPages }
          center_name={ this.state.center_name }
          selectedCenterName={ this.state.selectedCenterName }
          showViewCampaign={ this.state.showViewCampaign }
          user={ this.state.user }
          getClients={ this.getClients }
          onChangeOlsTeamClients={ this.onChangeOlsTeamClients }
          onLoadOlsTeamClients={ this.state.loading }
          onLoadSelectedClient={ this.state.loading }
          searchParams={ this.state.searchParams } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    getRefreshToken
  } )( OlsTeamClientsWrappedComponent )
}

export default OlsTeamClients
