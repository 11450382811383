import React, { useState, useEffect } from 'react'
import { 
  Button, Form, FormGroup, Label, Input
} from 'reactstrap'
import { MdDesktopWindows, MdTablet, MdSmartphone } from 'react-icons/md'
import { FaPencilAlt } from 'react-icons/fa'
import { connect } from 'react-redux'
import _ from 'lodash'

import { toggleMediaDatabase } from 'actions/MediaDatabaseToggle'
import MediaDatabase from 'SharedModules/MediaDatabase'
import Lang from 'Lang/General'
import LinkInput from '../components/LinkInput'

const ImageEdit = props => {
  const [ screenMode, updateScreenMode ] = useState( 'MOBILE' )
  const [ mediaDatabaseFor, setMediaDatabaseFor ] = useState( 'image' )
  const [ actualImgDimension, setActualImgDimension ] = useState({})

  useEffect( () => {
    getImgDimension( props.selectedComponent.source )
  }, [ props.selectedComponent.source ])

  useEffect( () => {
    let tmpComp = _.cloneDeep( props.selectedComponent )
    if( tmpComp.style ){
      if( !tmpComp[ 'style' ][ '@media(min-width:576px)' ] ){
        tmpComp[ 'style' ][ '@media(min-width:576px)' ] = {}
      }
      if( !tmpComp[ 'style' ][ '@media(min-width:768px)' ] ){
        tmpComp[ 'style' ][ '@media(min-width:768px)' ] = {}
      }
    } else {
      tmpComp[ 'style' ] = {
        "@media(min-width:576px)":{},
        "@media(min-width:768px)":{}
      } 
    }
    switch( screenMode ){
      case 'DESKTOP':
        tmpComp[ 'width' ] = tmpComp[ 'style' ][ '@media(min-width:768px)' ][ 'width' ] ? tmpComp[ 'style' ][ '@media(min-width:768px)' ][ 'width' ] : ''
        tmpComp[ 'height' ] = tmpComp[ 'style' ][ '@media(min-width:768px)' ][ 'height' ] ? tmpComp[ 'style' ][ '@media(min-width:768px)' ][ 'height' ] : ''
        break
      case 'TABLET':
        tmpComp[ 'width' ] = tmpComp[ 'style' ][ '@media(min-width:576px)' ][ 'width' ] ? tmpComp[ 'style' ][ '@media(min-width:576px)' ][ 'width' ] : ''
        tmpComp[ 'height' ] = tmpComp[ 'style' ][ '@media(min-width:576px)' ][ 'height' ] ? tmpComp[ 'style' ][ '@media(min-width:576px)' ][ 'height' ] : ''
        break
      case 'MOBILE':
        tmpComp[ 'width' ] = tmpComp[ 'style' ][ 'width' ] ? tmpComp[ 'style' ][ 'width' ] : ''
        tmpComp[ 'height' ] = tmpComp[ 'style' ][ 'height' ] ? tmpComp[ 'style' ][ 'height' ] : ''
        break
    }
    props.updateSelectedComponent( tmpComp )
    let tmpColumn = _.cloneDeep( props.selectedCol )
    tmpColumn = props.FindComponentAndPatch( tmpColumn, tmpComp )
    props.updateSelectedCol( tmpColumn )
    let tmpContainer = _.cloneDeep( props.dom )
    tmpContainer = props.FindComponentAndPatch( tmpContainer, tmpColumn )
    props.updateDom( tmpContainer )
  }, [ screenMode, props.selectedComponent.id ] )

  const updateImage = ( key, value ) => {
    let tmpComp = _.cloneDeep( props.selectedComponent )
    switch( key ){
      case 'source': 
        tmpComp[ key ] = value
        props.toggleMediaDatabase( false )
        break
      case 'isBackgroundImage': 
        tmpComp[ key ] = value
        if( value ) {
          tmpComp.style = { 
            ...tmpComp.style, 
            width: '100%', 
            height: tmpComp.height,
            backgroundRepeat: `no-repeat`,
            backgroundSize: 'cover'
          }
        } else {
          tmpComp.style = { 
            ...tmpComp.style, 
            width: `${ tmpComp.width }px`,
            height: tmpComp.height
          }
        }
        break
      case 'wholeComponent':
        tmpComp = value
        break
      default:
        tmpComp[ key ] = value
    }
    props.updateSelectedComponent( tmpComp )
    let tmpColumn = _.cloneDeep( props.selectedCol )
    tmpColumn = props.FindComponentAndPatch( tmpColumn, tmpComp )
    props.updateSelectedCol( tmpColumn )
    let tmpContainer = _.cloneDeep( props.dom )
    tmpContainer = props.FindComponentAndPatch( tmpContainer, tmpColumn )
    props.updateDom( tmpContainer )
  }

  const getImgDimension = ( imgSrcURL ) => {
    let img = new Image()
    img.onload = () => setActualImgDimension({
        'width': img.width,
        'height': img.height
      })
    img.src = imgSrcURL.replace(/url\(|\)$|"/ig, '')
  }

  const renderStyleSettingInput = style => (
    <FormGroup>
      <Label>
        { Lang[ style.name ][ props.selectedLanguage ] }
        { !_.isEmpty( props.selectedComponent[ 'style' ][ style.key ] ) && <MdSmartphone className='ml-2' style={{ fontSize: '15px' }} /> }
        { props.selectedComponent[ 'style' ][ '@media(min-width:576px)' ] && props.selectedComponent[ 'style' ][ '@media(min-width:576px)' ][ style.key ] && <MdTablet className='ml-2' style={{ fontSize: '15px' }} /> }
        { props.selectedComponent[ 'style' ][ '@media(min-width:768px)' ] && props.selectedComponent[ 'style' ][ '@media(min-width:768px)' ][ style.key ] && <MdDesktopWindows className='ml-2' style={{ fontSize: '15px' }} /> }
      </Label>
      <Input
        type="text"
        className="mr-1"
        value={ props.selectedComponent[ style.key ] }
        onChange={ e => {
          let tmpComp = _.cloneDeep( props.selectedComponent )
          tmpComp[ style.key ] = e.target.value
          switch( screenMode ){
            case 'MOBILE':
              if( !_.isEmpty( e.target.value ) ){
                tmpComp[ 'style' ][ style.key ] = e.target.value
              } else {
                delete tmpComp[ 'style' ][ style.key ]
              }
              break
            case 'TABLET':
              if( !_.isEmpty( e.target.value ) ){
                tmpComp[ 'style' ][ '@media(min-width:576px)' ][ style.key ] = e.target.value
              } else {
                delete tmpComp[ 'style' ][ '@media(min-width:576px)' ][ style.key ]
              }
              break
            case 'DESKTOP':
              if( !_.isEmpty( e.target.value ) ){
                tmpComp[ 'style' ][ '@media(min-width:768px)' ][ style.key ] = e.target.value
              } else {
                delete tmpComp[ 'style' ][ '@media(min-width:768px)' ][ style.key ]
              }
              break
          }
          props.updateSelectedComponent( tmpComp )
          let tmpColumn = _.cloneDeep( props.selectedCol )
          tmpColumn = props.FindComponentAndPatch( tmpColumn, tmpComp )
          props.updateSelectedCol( tmpColumn )
          let tmpContainer = _.cloneDeep( props.dom )
          tmpContainer = props.FindComponentAndPatch( tmpContainer, tmpColumn )
          props.updateDom( tmpContainer )
        }} />
    </FormGroup>
  )

  return (
    <Form onSubmit={ e => e.preventDefault()}>
      <FormGroup>
        <p>
          {`
            ${ props.selectedComponent.source.replace( /.*(?=\/)/, '').replace( '/', '' ) } ${
              actualImgDimension.width && actualImgDimension.height
              ? `(${ actualImgDimension.width } x ${ actualImgDimension.height }px)` 
              : '(-)'
            }
          `}
        </p>
        <Button 
          color="primary"
          className='d-flex justify-content-center align-items-center'
          onClick={() => {
            setMediaDatabaseFor( 'image' )
            props.toggleMediaDatabase( true )
          }}>
          <FaPencilAlt className='mr-2' />
          { Lang[ 'SELECT_IMAGE' ][ props.selectedLanguage ] }
        </Button>
      </FormGroup>
      <FormGroup>
        <Button 
          color={ screenMode === 'MOBILE' ? 'success' : 'primary' }
          className='mr-2'
          onClick={ () => updateScreenMode( 'MOBILE' ) }>
          <MdSmartphone style={{ fontSize: '24px' }} />
        </Button>
        <Button 
          color={ screenMode === 'TABLET' ? 'success' : 'primary' }
          className='mr-2'
          onClick={ () => updateScreenMode( 'TABLET' ) }>
          <MdTablet style={{ fontSize: '24px' }} />
        </Button>
        <Button 
          color={ screenMode === 'DESKTOP' ? 'success' : 'primary' }
          className='mr-2'
          onClick={ () => updateScreenMode( 'DESKTOP' ) }>
          <MdDesktopWindows style={{ fontSize: '24px' }} />
        </Button>
      </FormGroup>
      {
        mediaDatabaseFor === 'image' &&
        <MediaDatabase
          onSelectedImage={ param => updateImage( 'source', param ) }
          selectedLanguage={ props.selectedLanguage }
          allowed={[ 'jpg', 'jpeg', 'png' ]} />
      }
      { renderStyleSettingInput({ key: 'width', name: 'WIDTH' }) }
      <FormGroup>
        <Label>{ Lang[ 'TITLE' ][ props.selectedLanguage ] }</Label>
        <Input
          type="text"
          value={ !_.isEmpty( props.selectedComponent.title ) ? props.selectedComponent.title : '' }
          onChange={ e => updateImage( 'title', e.target.value ) } />
      </FormGroup>
      <FormGroup>
        <Label>{ Lang[ 'ALT' ][ props.selectedLanguage ] }</Label>
        <Input
          type="text"
          value={ !_.isEmpty( props.selectedComponent.alt ) ? props.selectedComponent.alt : '' }
          onChange={ e => updateImage( 'alt', e.target.value ) } />
      </FormGroup>
      <LinkInput 
        selectedContainer={ props.selectedComponent }
        onUpdate={ ( updatedDom ) => { updateImage( 'wholeComponent', updatedDom ) }}
        setMediaDatabase={ ( isOpen ) => { 
          setMediaDatabaseFor( 'clickable' )
          props.toggleMediaDatabase( isOpen ) 
        } }
        pages={ props.pages }
        site={ props.site }
        selectedLanguage={ props.selectedLanguage }
      />
    </Form>
  )
}

const mapStateToProps = state => ({ data: state })

export default connect( mapStateToProps, {
  toggleMediaDatabase
})( ImageEdit )