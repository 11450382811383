import React, { useEffect, useState } from 'react'
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Nav, NavItem, NavLink,
  TabContent,TabPane,
  Button, UncontrolledTooltip,
  Form
} from 'reactstrap'
import { compose } from 'redux'
import _ from 'lodash'
import Moment from 'moment'
import classnames from 'classnames'

import ARKTable from 'components/Table'
import PageTitle from 'components/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import DeleteConfirmation from 'components/Modal/delete'
import CenterGroupsHOC from 'actions/centerGroup'
import ProgramTemplatesHOC from './actions'
import EventTemplatesHOC from './actions/eventTemplates'
import GeneralForm from './components/General'
import TextsForm from './components/Texts'
import ContentForm from './components/Content'
import Lang from 'Lang/General'

const ProgramTemplates = props => {
  const [ modalActiveTab, setModalActiveTab ] = useState( 1 )
  
  useEffect( () => {
    props.getEventTemplates()
    props.getCenterGroups()
  }, [] )

  useEffect( () => {
    if( !props.showCreateProgramTemplate ){
      setModalActiveTab( 1 )
      props.onChangeProgramTemplatesHOC( 
        'newProgramTemplate',
        {
          title: {
            en: '',
            de: '',
            nl: '',
            it: '',
            fr: ''
          },
          description: {
            en: '',
            de: '',
            nl: '',
            it: '',
            fr: ''
          },
          brief_description: {
            en: '',
            de: '',
            nl: '',
            it: '',
            fr: ''
          },
          highlight_primary: {
            en: '',
            de: '',
            nl: '',
            it: '',
            fr: ''
          },
          highlight_secondary: {
            en: '',
            de: '',
            nl: '',
            it: '',
            fr: ''
          },
          content_description: {
            en: '',
            de: '',
            nl: '',
            it: '',
            fr: ''
          },
          display_from: Moment().format( 'YYYY-MM-DD' ),
          display_to: Moment().add( 1, 'month' ).format( 'YYYY-MM-DD' ),
          price: '0.00',
          price_member: '0.00',
          is_for_member: false,
          is_for_guest: false,
          is_for_program_participant: false,
          is_for_trial_member: false,
          is_free_for_member: false,
          is_free_for_campaign_participant: false,
          is_free_for_guest: false,
          is_free_for_trial_member: false,
          max_participants: 1,
          center_editable_config: [
            {
              name: 'title',
              is_editable: true
            },
            {
              name: 'description',
              is_editable: true
            },
            {
              name: 'display_from',
              is_editable: true
            },
            {
              name: 'display_to',
              is_editable: true
            },
            {
              name: 'price',
              is_editable: true
            },
            {
              name: 'price_member',
              is_editable: true
            },
            {
              name: 'is_free_for_member',
              is_editable: true
            },
            {
              name: 'max_participants',
              is_editable: true
            },
            {
              name: 'image_url',
              is_editable: true
            },
            {
              name: 'banner_image_url',
              is_editable: true
            }
          ],
          image_url:'',
          banner_image_url:'',
          center_groups: [],
          countries: [],
          status: 'ACTIVE',
          events: [],
          weekly_content: [ { week_number: 1, urls: [] } ],
          weeks: 1
        }
      )
    }
  }, [ props.showCreateProgramTemplate ] )

  useEffect( () => {
    if( !props.showUpdateProgramTemplate ){
      setModalActiveTab( 1 )
      props.onChangeProgramTemplatesHOC( 'selectedProgramTemplate', {} )
    }
  }, [ props.showUpdateProgramTemplate ] )

  useEffect( () => {
    if( !props.showArchivedProgramTemplate ){
      props.getProgramTemplates()
    }
  }, [ props.showArchivedProgramTemplate ] )

  useEffect( () => {
    if( !_.isEmpty( props.rawProgramTemplate ) ){
      let tmpProgramTemplate = _.cloneDeep( props.rawProgramTemplate )
      tmpProgramTemplate.center_groups = tmpProgramTemplate.center_groups.map( center_group => ({
        id: center_group.id, 
        label: center_group.name,
        value: center_group.name
      }))
      tmpProgramTemplate.events = tmpProgramTemplate.events.map( eventTemplate => ({ 
        id: eventTemplate.id, 
        label: eventTemplate.title[ props.data.languageReducer.lang ], 
        value: eventTemplate.title[ props.data.languageReducer.lang ], 
      }))
      props.onChangeProgramTemplatesHOC( 'selectedProgramTemplate', tmpProgramTemplate )
    }
  }, [ props.rawProgramTemplate ] )

  const handleSecurityModulesCreate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Member Area' })
      return tmpModules.is_create
    }
  }

  const handleSecurityModulesUpdate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Member Area' })
      return tmpModules.is_update
    }
  }

  const handleSecurityModulesDelete= () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Member Area' })
      return tmpModules.is_delete
    }
  }

  const renderCreateProgramTemplateModal = () => (
    <Modal size='lg' isOpen={ props.showCreateProgramTemplate } >
      <ModalHeader toggle={ () => props.onChangeProgramTemplatesHOC( 'showCreateProgramTemplate', false ) } >
        { Lang[ 'CREATE' ][ props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        <Form className='px-2' >
          <Nav tabs={ true } >
            <NavItem>
              <NavLink
                href="javascript:void(0);"
                className={ classnames({ active: modalActiveTab === 1 }) }
                onClick={ () => setModalActiveTab( 1 ) } >
                { Lang[ 'GENERAL' ][ props.data.languageReducer.lang ] }</NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="javascript:void(0);"
                className={ classnames({ active: modalActiveTab === 2 }) }
                onClick={ () => setModalActiveTab( 2 ) } >
                { Lang[ 'TEXT' ][ props.data.languageReducer.lang ] }</NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="javascript:void(0);"
                className={ classnames({ active: modalActiveTab === 3 }) }
                onClick={ () => setModalActiveTab( 3 ) } >
                { Lang[ 'CONTENT' ][ props.data.languageReducer.lang ] }</NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={ modalActiveTab } className='pt-3' >
            <TabPane tabId={ 1 } >
              <GeneralForm
                { ...props }
                programTemplateType='newProgramTemplate'
                currentProgramTemplate={ props.newProgramTemplate }
                lang={ props.data.languageReducer.lang }
              />
            </TabPane>
            <TabPane tabId={ 2 } >
              <TextsForm
                { ...props }
                programTemplateType='newProgramTemplate'
                currentProgramTemplate={ props.newProgramTemplate }
                lang={ props.data.languageReducer.lang }
              />
            </TabPane>
            <TabPane tabId={ 3 } >
              <ContentForm
                { ...props }
                programTemplateType='newProgramTemplate'
                currentProgramTemplate={ props.newProgramTemplate }
                lang={ props.data.languageReducer.lang }
              />
            </TabPane>
          </TabContent>
        </Form>
        { props.onLoadProgramTemplatesHOC && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => {
            let tmp = _.cloneDeep( props.newProgramTemplate )
            tmp.price = parseFloat( tmp.price || 0 ).toFixed( 2 )
            tmp.price_member = parseFloat( tmp.price_member || 0 ).toFixed( 2 )
            tmp.max_participants = parseInt( tmp.max_participants )
            tmp.event_template_ids = tmp.events.map( item => item.id )
            tmp.video_links = tmp.weekly_content
            props.createProgramTemplate( tmp ) 
          }}>
          { Lang[ 'SUBMIT' ][ props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )

  const renderUpdateProgramTemplateModal = () => (
    <Modal size='lg' isOpen={ props.showUpdateProgramTemplate } >
      <ModalHeader toggle={ () => props.onChangeProgramTemplatesHOC( 'showUpdateProgramTemplate', false ) } >
        { Lang[ 'UPDATE' ][ props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        {
          !_.isEmpty( props.selectedProgramTemplate ) && <Form className='px-2' >
            <Nav tabs={ true } >
              <NavItem>
                <NavLink
                  href="javascript:void(0);"
                  className={ classnames({ active: modalActiveTab === 1 }) }
                  onClick={ () => setModalActiveTab( 1 ) } >
                  { Lang[ 'GENERAL' ][ props.data.languageReducer.lang ] }</NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="javascript:void(0);"
                  className={ classnames({ active: modalActiveTab === 2 }) }
                  onClick={ () => setModalActiveTab( 2 ) } >
                  { Lang[ 'TEXT' ][ props.data.languageReducer.lang ] }</NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="javascript:void(0);"
                  className={ classnames({ active: modalActiveTab === 3 }) }
                  onClick={ () => setModalActiveTab( 3 ) } >
                  { Lang[ 'CONTENT' ][ props.data.languageReducer.lang ] }</NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={ modalActiveTab } className='pt-3' >
              <TabPane tabId={ 1 } >
                <GeneralForm
                  { ...props }
                  programTemplateType='selectedProgramTemplate'
                  currentProgramTemplate={ props.selectedProgramTemplate }
                  lang={ props.data.languageReducer.lang }
                />
              </TabPane>
              <TabPane tabId={ 2 } >
                <TextsForm
                  { ...props }
                  programTemplateType='selectedProgramTemplate'
                  currentProgramTemplate={ props.selectedProgramTemplate }
                  lang={ props.data.languageReducer.lang }
                />
              </TabPane>
              <TabPane tabId={ 3 } >
                <ContentForm
                  { ...props }
                  programTemplateType='selectedProgramTemplate'
                  currentProgramTemplate={ props.selectedProgramTemplate }
                  lang={ props.data.languageReducer.lang }
                />
              </TabPane>
            </TabContent>
          </Form>
        }
        { props.onLoadProgramTemplatesHOC && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => {
            let tmp = _.cloneDeep( props.selectedProgramTemplate )
            tmp.price = parseFloat( tmp.price || 0 ).toFixed( 2 )
            tmp.price_member = parseFloat( tmp.price_member || 0 ).toFixed( 2 )
            tmp.max_participants = parseInt( tmp.max_participants )
            tmp.event_template_ids = tmp.events.map( item => item.id )
            tmp.video_links = tmp.weekly_content
            props.updateProgramTemplate( tmp ) 
          }}>
          { Lang[ 'SUBMIT' ][ props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )

  const renderArchivedProgramTemplatesModal = () => (
    <Modal size='lg' isOpen={ props.showArchivedProgramTemplate } >
      <ModalHeader toggle={ () => props.onChangeProgramTemplatesHOC( 'showArchivedProgramTemplate', false ) } />
      <ModalBody>
        <ARKTable
          data={ props.programTemplates.data }
          columns={[
            {
              Header: Lang[ 'TITLE' ][ props.data.languageReducer.lang ],
              accessor: 'title',
              Cell: ({ value }) => value[ props.data.languageReducer.lang ]
            },
            {
              Header: Lang[ 'DISPLAY_DURATION' ][ props.data.languageReducer.lang ],
              accessor: 'start_date',
              Cell: row => `${ Moment( row.original.display_from ).format( 'DD-MM-YYYY' ) } - ${ Moment( row.original.display_to ).format( 'DD-MM-YYYY' ) }`
            },
            {
              Header: Lang[ 'ACTIONS' ][ props.data.languageReducer.lang ],
              accessor: 'id',
              Cell: ({ value }) => (
                <div className='d-flex justify-content-center' >
                  {
                    handleSecurityModulesUpdate() && (
                      <>
                        <Button
                          id={ `ProgramTemplate-${ value }-unarchive` }
                          className='mb-2 mr-2 btn-icon btn-icon-only'
                          color='warning'
                          onClick={ () => props.onChangeProgramTemplatesHOC( 'showArchiveProgramTemplateId', value ) }>
                          <i className='pe-7s-notebook'> </i>
                        </Button>
                        <UncontrolledTooltip
                          target={ `ProgramTemplate-${ value }-unarchive` }
                          placement='top'>{ Lang[ 'UNARCHIVE' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                      </>
                    )
                  }
                </div>
              )
            }
          ]}
          totalPages={ props.programTemplatesPages }
          page={ props.programTemplates.meta ? props.programTemplates.meta.current_page : 1 }
          onChangePage={ pg => props.getProgramTemplates( pg, true ) }
          showPagination={ false } 
        />
        { props.onLoadProgramTemplatesHOC && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => props.onChangeProgramTemplatesHOC( 'showArchivedProgramTemplate', false ) } >
          { Lang[ 'CLOSE' ][ props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )

  return(
    <>
      <PageTitle
        heading={ Lang[ 'PROGRAM_TEMPLATES' ][ props.data.languageReducer.lang ] }
        icon='pe-7s-users icon-gradient bg-happy-fisher'
        buttons={[
          {
            buttonText: Lang[ 'CREATE' ][ props.data.languageReducer.lang ],
            onClick: () => props.onChangeProgramTemplatesHOC( 'showCreateProgramTemplate', true ),
            display: handleSecurityModulesCreate()
          },
          {
            buttonText: Lang[ 'ARCHIVED' ][ props.data.languageReducer.lang ],
            onClick: () => props.getProgramTemplates( 1, true ),
            display: handleSecurityModulesUpdate()
          }
        ]}/>
      <ARKTable
        data={ props.programTemplates.data }
        columns={[
          {
            Header: Lang[ 'TITLE' ][ props.data.languageReducer.lang ],
            accessor: 'title',
            Cell: ({ value }) => value[ props.data.languageReducer.lang ]
          },
          {
            Header: Lang[ 'DISPLAY_DURATION' ][ props.data.languageReducer.lang ],
            accessor: 'start_date',
            Cell: row => `${ Moment( row.original.display_from ).format( 'DD-MM-YYYY' ) } - ${ Moment( row.original.display_to ).format( 'DD-MM-YYYY' ) }`
          },
          {
            Header: Lang[ 'ACTIONS' ][ props.data.languageReducer.lang ],
            accessor: 'id',
            Cell: ({ value }) => (
              <div className='d-flex justify-content-center' >
                {
                  handleSecurityModulesUpdate() && (
                    <>
                      <Button
                        id={ `ProgramTemplate-${ value }-update` }
                        className='mb-2 mr-2 btn-icon btn-icon-only'
                        color='primary'
                        onClick={ () => props.getSelectedProgramTemplate( value ) } >
                        <i className='pe-7s-pen'> </i>
                      </Button>
                      <UncontrolledTooltip
                        target={ `ProgramTemplate-${ value }-update` }
                        placement='top'>{ Lang[ 'EDIT' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                      <Button
                        id={ `ProgramTemplate-${ value }-archive` }
                        className='mb-2 mr-2 btn-icon btn-icon-only'
                        color='warning'
                        onClick={ () => props.onChangeProgramTemplatesHOC( 'showArchiveProgramTemplateId', value ) }>
                        <i className='pe-7s-notebook'> </i>
                      </Button>
                      <UncontrolledTooltip
                        target={ `ProgramTemplate-${ value }-archive` }
                        placement='top'>{ Lang[ 'ARCHIVE' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                    </>
                  )
                }
                {
                  handleSecurityModulesDelete() && (
                    <>
                      <Button
                        id={ `ProgramTemplate-${ value }-archive-programTemplate` }
                        className='mb-2 mr-2 btn-icon btn-icon-only'
                        color='danger'
                        onClick={ () => props.onChangeProgramTemplatesHOC( 'showDeleteProgramTemplateId', parseInt( value ) ) }>
                        <i className='pe-7s-trash'> </i>
                      </Button>
                      <UncontrolledTooltip
                        target={ `ProgramTemplate-${ value }-archive-programTemplate` }
                        placement='top'>{ Lang[ 'REMOVE' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                    </>
                  )
                }
              </div>
            )
          }
        ]}
        totalPages={ props.programTemplatesPages }
        page={ props.programTemplates.meta ? props.programTemplates.meta.current_page : 1 }
        onChangePage={ pg => props.getProgramTemplates( pg ) }
        showPagination={ false } 
      />
      <DeleteConfirmation
        handleModalClose={ () => props.onChangeProgramTemplatesHOC( 'showDeleteProgramTemplateId', 0 ) }
        deleteOpen={ props.showDeleteProgramTemplateId > 0 }
        confirmAction={ () => props.removeProgramTemplate( props.showDeleteProgramTemplateId ) }
        content={ Lang[ 'DELETE_CONFIRMATION_PROGRAM_TEMPLATE' ][ props.data.languageReducer.lang ] }
        lang={ props.data.languageReducer.lang } />
      <DeleteConfirmation
        handleModalClose={ () => props.onChangeProgramTemplatesHOC( 'showArchiveProgramTemplateId', 0 ) }
        deleteOpen={ props.showArchiveProgramTemplateId > 0 }
        confirmAction={ () => props.updateProgramTemplate({ 
            id: props.showArchiveProgramTemplateId,
            status: props.showArchivedProgramTemplate ? 'ACTIVE' : 'ARCHIVED' 
          }) 
        }
        content={ Lang[ 'ARCHIVE_UNARCHIVE_CONFIRMATION' ][ props.data.languageReducer.lang ] }
        lang={ props.data.languageReducer.lang } />
      { renderCreateProgramTemplateModal() }
      { renderUpdateProgramTemplateModal() }
      { renderArchivedProgramTemplatesModal() }
      { 
        ( 
          props.onLoadProgramTemplatesHOC || 
          props.onLoadEventTemplatesHOC ||
          props.onLoadCenterGroups
        ) && <LoadingOverlay/> 
      }
    </>
  )
}

export default compose(
  ProgramTemplatesHOC,
  EventTemplatesHOC,
  CenterGroupsHOC
)( ProgramTemplates )