import _ from 'lodash'
export const getBorderStyling = style => (
  _.reduce(
    style,
    ( result, value, key ) => ({
      ...result,
      ...( key.indexOf( 'border' ) > -1 && typeof value === "string" && value.indexOf( '#' ) > -1 ? { [ key ]: value } : {} )
    }),
    {}
  )
)

export const getButtonColorType = ( style, key ) => {
  let colorType = 'custom'
  if( style[ 'primaryColor' ] && style[ 'primaryColor' ].indexOf( key ) > -1 ){
    colorType = 'primaryColor'
  }
  if( style[ 'secondaryColor' ] && style[ 'secondaryColor' ].indexOf( key ) > -1 ){
    colorType = 'secondaryColor'
  }
  return colorType
}

export const getModifiedColorTypeStyling = ( type, style, key ) => {
  let tmpStyle = _.cloneDeep( style )
  switch( type ){
    case 'custom' :
      if( tmpStyle[ 'primaryColor' ] && tmpStyle[ 'primaryColor' ].indexOf( key ) > -1 ){
        tmpStyle[ 'primaryColor' ].splice( tmpStyle[ 'primaryColor' ].indexOf( key ), 1 )
      }
      if( tmpStyle[ 'secondaryColor' ] && tmpStyle[ 'secondaryColor' ].indexOf( key ) > -1 ){
        tmpStyle[ 'secondaryColor' ].splice( tmpStyle[ 'secondaryColor' ].indexOf( key ), 1 )
      }
      break
    case 'primaryColor' :
      if( !tmpStyle[ 'primaryColor' ] ){
        tmpStyle[ 'primaryColor' ] = []
      }
      if( tmpStyle[ 'primaryColor' ] && tmpStyle[ 'primaryColor' ].indexOf( key ) === -1 ){
        tmpStyle[ 'primaryColor' ].push( key )
      }
      if( tmpStyle[ 'secondaryColor' ] && tmpStyle[ 'secondaryColor' ].indexOf( key ) > -1 ){
        tmpStyle[ 'secondaryColor' ].splice( tmpStyle[ 'secondaryColor' ].indexOf( key ), 1 )
      }
      break
    case 'secondaryColor' :
      if( tmpStyle[ 'primaryColor' ] && tmpStyle[ 'primaryColor' ].indexOf( key ) > -1 ){
        tmpStyle[ 'primaryColor' ].splice( tmpStyle[ 'primaryColor' ].indexOf( key ), 1 )
      }
      if( !tmpStyle[ 'secondaryColor' ] ){
        tmpStyle[ 'secondaryColor' ] = []
      }
      if( tmpStyle[ 'secondaryColor' ] && tmpStyle[ 'secondaryColor' ].indexOf( key ) === -1 ){
        tmpStyle[ 'secondaryColor' ].push( key )
      }
      break
  }
  return tmpStyle
}
