import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {
  Col, Button, Form, FormGroup, Label,
  Input, Container, FormFeedback, Card, CardBody, Modal,
  TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap'
import { connect } from 'react-redux'
import classnames from 'classnames'
import Moment from 'moment'

import MultipleSearchSelect from 'components/MultipleSearchSelect'
import DropdawnSearchSelect from 'components/SearchSelect'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import LocationForm from './Location'
import AddLocationForm from '../components/AddLocation'

import Lang from 'Lang/General'

class CenterForm extends Component {
  state = {
    activeTab: '1',
    locationItems: [],
    newLocationData: {
      name: '',
      ext_field_1: '',
      ext_field_2: '',
      country: '',
      state: '',
      city: '',
      street: '',
      house_number: '',
      postcode: '',
      phone_number: '',
      fax_number: '',
      calling_code: '',
      area_code: '',
      number: '',
      email: '',
      school_id: 0,
      discount: '',
      studio_email_subject: '',
      studio_friend_email_subject: '',
      client_number: '',
      is_active: true
    }
  }

  componentDidUpdate = pp => {
    if( pp.addItemModal && !this.props.addItemModal ){
      this.setState({
        newLocationData: {
          name: '',
          ext_field_1: '',
          ext_field_2: '',
          country: '',
          state: '',
          city: '',
          street: '',
          house_number: '',
          postcode: '',
          phone_number: '',
          fax_number: '',
          calling_code: '',
          area_code: '',
          number: '',
          email: '',
          school_id: 0,
          discount: '',
          studio_email_subject: '',
          studio_friend_email_subject: '',
          client_number: '',
          is_active: true
        }
      })
    }
    if( this.props.selectedClient !== pp.selectedClient ) {
      let tmp = _.cloneDeep( this.props.selectedCenter )
      tmp.first_name = this.props.selectedClient.user.first_name
      tmp.last_name = this.props.selectedClient.user.last_name
      tmp.email = this.props.selectedClient.user.email
      tmp.salutation = this.props.selectedClient.salutation

      this.props.onChangeCenterHOC( 'selectedCenter', tmp )
    }
  }

  onChangeNewLocationData = ( key, value ) => {
    let tmp = _.cloneDeep( this.state.newLocationData )
    tmp[key] = value
    if( key === 'country' ){
      tmp.state = ''
    }
    this.setState({ newLocationData: tmp })
  }

  onChange = ( key, val ) => {
    let tmp = _.cloneDeep( this.props.selectedCenter )
    tmp[ key ] = val
    if( key === 'client_id' ){
      this.props.getSelectedClient( val )
    }
    return this.props.onChangeCenterHOC( 'selectedCenter', tmp )
  }

  onChangeLocationData = ( index, key, val ) => {
    let tmp = _.cloneDeep( this.props.centerLocations )
    tmp[index][ key ] = val
    if( key === 'country' ){
      tmp[ index ].state = ''
    }
    this.props.onChangeCenterHOC( 'centerLocations', tmp )
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }

  render = () => {
    const {
      selectedCenter,
      countries,
      getStates,
      states,
      onLoadCountries,
      statusValues,
      consultantsList,
      clientsList,
      leadhubCategoriesOptions,
      languages,
      centerGroupList,
      errorMessage,
      updateCenter,
      usersList
    } = this.props
    let {
      salutation,
      first_name,
      last_name,
      center_name,
      email,
      manager,
      language,
      is_female_center,
      status,
      center_groups_id,
      egym_oauth_client_id,
      email_api_service
    } = selectedCenter
    let tmp = []
    center_groups_id && center_groups_id.map( item => {
      item && item.id ? tmp.push( item.id ) : tmp.push( item )
    })

    let countriesOptions = []
    countries && countries.map( item => {
      countriesOptions.push({
        id: item.id,
        label: item.name,
        value: item.name,
        calling_code: item.calling_code[0]
      })
    })

    let statesOption = []
    states && states.map( item => {
      statesOption.push({
        id: item.id,
        label: item.name,
        value: item.name
      })
    })

    let clientOptions = []
    clientsList && clientsList.map( item => {
      clientOptions.push({
        id: item.id,
        label: `${ item.company_name } (${ item.house_number },${ item.street },${ item.city },${ item.country })`,
        value: `${ item.company_name } (${ item.house_number },${ item.street },${ item.city },${ item.country })`
      })
    })

    let consultantOptions = []
    consultantsList && consultantsList.map( item => {
      consultantOptions.push({
        id: item.id,
        label: `${ item.first_name } ${ item.last_name } ( ${ item.email } )`,
        value: `${ item.first_name } ${ item.last_name } ( ${ item.email } )`
      })
    })

    let userOptions = []
    usersList && usersList.map( item => {
      userOptions.push({
        id: item.id,
        label: `${ item.fullname } ( ${ item.email } )`,
        value: `${ item.fullname } ( ${ item.email } )`
      })
    })

    const getValueForSelect = ( type, index ) => {
      if( index > -1 ) {
        switch ( type ) {
          case 'state':
            return {
              label: this.props.centerLocations[index].state,
              value: this.props.centerLocations[index].state
            }
          case 'country':
            return {
              label: this.props.centerLocations[index].country,
              value: this.props.centerLocations[index].country
            }
          default:
            return {}
        }
      } else {
        switch ( type ) {
          case 'state':
            return {
              label: this.state.newLocationData.state,
              value: this.state.newLocationData.state
            }
          case 'country':
            return {
              label: this.state.newLocationData.country,
              value: this.state.newLocationData.country
            }
          case 'client':
            let tmpFound = _.find( clientsList, { id: selectedCenter.client_id } )
            return{
              label: `${ tmpFound ? `${ tmpFound.company_name } (${ tmpFound.house_number },${ tmpFound.street },${ tmpFound.city },${ tmpFound.country })` : '' }` ,
              value: `${ tmpFound ? `${ tmpFound.company_name } (${ tmpFound.house_number },${ tmpFound.street },${ tmpFound.city },${ tmpFound.country })` : '' }`
            }
          case 'consultant':
            let tmp = _.find( consultantsList, { id: selectedCenter.consultant_id } )
            return{
              label: `${ tmp ? `${ tmp.first_name } ${ tmp.last_name } ( ${ tmp.email } )` : '' }` ,
              value: `${ tmp ? `${ tmp.first_name } ${ tmp.last_name } ( ${ tmp.email } )` : '' }`
            }
          case 'user':
            let tmpUser = _.find( usersList, { id: selectedCenter.team_user_id } )
            return{
              label: `${ tmpUser ? `${ tmpUser.fullname } ( ${ tmpUser.email } )` : '' }` ,
              value: `${ tmpUser ? `${ tmpUser.fullname } ( ${ tmpUser.email } )` : '' }`
            }
          default:
            return {}
        }
      }


    }
    const getCallingCode = ( data ) => {
      // let tmpFound = _.find( countries, { name: data.country } )
      // if( tmpFound ) {
      //   return tmpFound.calling_code[0]
      // }
      return countries[0].calling_code[0]
    }

    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Container fluid>
            <Card className="main-card mb-3">
              <CardBody>
                <Nav tabs={ true }>
                  <NavItem>
                    <NavLink
                      href="javascript:void(0);"
                      className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => { this.toggle('1') }}>
                      { Lang[ 'GENERAL' ][ this.props.data.languageReducer.lang ] }
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '2' })}
                      onClick={() => { this.toggle('2') }}>
                      { Lang[ 'LOCATION' ][ this.props.data.languageReducer.lang ] }
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={ this.state.activeTab }>
                  <TabPane tabId='1'>
                    <Form style={{ minWidth: "400px" }} >
                      <table style={{ tableLayout: "auto", width: "100%", borderCollapse: "separate", borderSpacing: "0 10px" }}>
                        <tr>
                          <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                            <Label className='m-0' for="client_id">{ Lang[ 'CLIENT' ][ this.props.data.languageReducer.lang ] }</Label>
                          </td>
                          <td>
                            <Col className='p-0' sm={ 12 } style={{ zIndex: 999 }} >
                              <DropdawnSearchSelect
                                options={ clientOptions }
                                onChange={ v => this.onChange( 'client_id', v.id ) }
                                value={ getValueForSelect( 'client' ) }
                                placeholder={ Lang[ 'CLIENT' ][ this.props.data.languageReducer.lang ] }
                                invalid={ errorMessage & errorMessage.client_id }
                                feedbackMessage={ Lang[ 'CLIENT_IS_REQUIRED' ][ this.props.data.languageReducer.lang ] } />
                            </Col>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                            <Label className='m-0' for="consultant_id">{ Lang[ 'CONSULTANT' ][ this.props.data.languageReducer.lang ] }</Label>
                          </td>
                          <td>
                            <FormGroup className='m-0'>
                              <Col className='p-0' sm={ 12 } style={{ zIndex: 998 }} >
                                <DropdawnSearchSelect
                                  options={ consultantOptions }
                                  onChange={ v => this.onChange( 'consultant_id', v.id ) }
                                  value={ getValueForSelect( 'consultant' ) }
                                  placeholder={ Lang[ 'SELECT_CONSULTANT' ][ this.props.data.languageReducer.lang ] }
                                  invalid={ errorMessage && errorMessage.consultant_id }
                                  feedbackMessage={ Lang[ 'CONSULTANT_REQUIRED' ][ this.props.data.languageReducer.lang ] } />
                              </Col>
                            </FormGroup>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                            <Label className='m-0' for="team_user_id">{ Lang[ 'OLS_TEAM_USER' ][ this.props.data.languageReducer.lang ] }</Label>
                          </td>
                          <td>
                            <FormGroup className='m-0'>
                              <Col className='p-0' sm={ 12 } style={{ zIndex: 997 }} >
                                <DropdawnSearchSelect
                                  options={ userOptions }
                                  onChange={ v => this.onChange( 'team_user_id', v.id ) }
                                  value={ getValueForSelect( 'user' ) }
                                  placeholder={ Lang[ 'SELECT_OLS_TEAM_USER' ][ this.props.data.languageReducer.lang ] }
                                  invalid={ errorMessage && errorMessage.team_user_id }
                                  feedbackMessage={ Lang[ 'OLS_TEAM_USER_REQUIRED' ][ this.props.data.languageReducer.lang ] } />
                              </Col>
                            </FormGroup>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                            <Label className='m-0' for="status">{ Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ] }</Label>
                          </td>
                          <td>
                            <FormGroup className='m-0'>
                              <Input
                                type="select"
                                name="status"
                                id="status"
                                invalid={ errorMessage && errorMessage.status ? true : false }
                                value={ status }
                                disabled={ status === 'TRIAL' }
                                onChange={ e => this.onChange( 'status', e.target.value ) }>
                                {
                                  statusValues && Object.keys( statusValues ).map( key => {
                                    return (
                                      key !== 'archived' && <option key={ key }>{ statusValues[key] }</option>
                                    )
                                  })
                                }
                                {
                                  status === 'TRIAL' && <option value='TRIAL'>{'TRIAL'}</option>
                                }
                              </Input>
                            </FormGroup>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                            <Label className='m-0' for="salutation">{ Lang[ 'SALUTATION' ][ this.props.data.languageReducer.lang ] }</Label>
                          </td>
                          <td>
                            <FormGroup className='m-0'>
                              <Input
                                type="select"
                                name="salutation"
                                id="salutation"
                                error={ errorMessage && errorMessage.salutation ? true : false }
                                value={ salutation }
                                onChange={ e => this.onChange( 'salutation', e.target.value ) }>
                                <option value='Herr' >{ Lang[ 'MR' ][ this.props.data.languageReducer.lang ] }</option>
                                <option value='Frau'>{ Lang[ 'MRS' ][ this.props.data.languageReducer.lang ] }</option>
                              </Input>
                            </FormGroup>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                            <Label className='m-0' for="first_name">{ Lang[ 'FIRST_NAME' ][ this.props.data.languageReducer.lang ] }</Label>
                          </td>
                          <td>
                            <FormGroup className='m-0'>
                              <Input
                                name="first_name" id="first_name"
                                placeholder={ Lang[ 'ENTER_FIRST_NAME' ][ this.props.data.languageReducer.lang ] }
                                value={ first_name }
                                onChange={ e => this.onChange( 'first_name', e.target.value ) }
                                invalid={ errorMessage && errorMessage.first_name ? true : false }/>
                              <FormFeedback>{ errorMessage && errorMessage.first_name ? errorMessage.first_name : '' }</FormFeedback>
                            </FormGroup>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                            <Label className='m-0' for="last_name">{ Lang[ 'LAST_NAME' ][ this.props.data.languageReducer.lang ] }</Label>
                          </td>
                          <td>
                            <FormGroup className='m-0'>
                              <Input
                                name="last_name" id="last_name"
                                placeholder={ Lang[ 'ENTER_LAST_NAME' ][ this.props.data.languageReducer.lang ] }
                                value={ last_name }
                                onChange={ e => this.onChange( 'last_name', e.target.value ) }
                                invalid={ errorMessage && errorMessage.last_name ? true : false }/>
                              <FormFeedback>{ errorMessage && errorMessage.last_name ? errorMessage.last_name : '' }</FormFeedback>
                            </FormGroup>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                            <Label className='m-0' for="center_name">{ Lang[ 'CENTER_NAME' ][ this.props.data.languageReducer.lang ] }</Label>
                          </td>
                          <td>
                            <FormGroup className='m-0'>
                              <Input
                                name="center_name" id="center_name"
                                placeholder={ Lang[ 'ENTER_CENTER_NAME' ][ this.props.data.languageReducer.lang ] }
                                value={ center_name }
                                onChange={ e => this.onChange( 'center_name', e.target.value ) }
                                invalid={ errorMessage && errorMessage.center_name ? true : false }/>
                              <FormFeedback>{ errorMessage && errorMessage.center_name ? errorMessage.center_name : '' }</FormFeedback>
                            </FormGroup>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                            <Label className='m-0' for="email">{ Lang[ 'EMAIL' ][ this.props.data.languageReducer.lang ] }</Label>
                          </td>
                          <td>
                            <FormGroup className='m-0'>
                              <Input
                                type="email"
                                name="email" id="email"
                                placeholder={ Lang[ 'ENTER_EMAIL' ][ this.props.data.languageReducer.lang ] }
                                value={ email }
                                onChange={ e => {
                                  let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i )

                                  if( !pattern.test( e.target.value ) ){
                                    errorMessage["email"] = "Please enter valid email address."
                                  } else {
                                    errorMessage["email"] = ""
                                  }
                                  this.onChange( 'email', e.target.value )
                                }}
                                invalid={ errorMessage && errorMessage.email ? true : false }/>
                              <FormFeedback>{ errorMessage && errorMessage.email ? errorMessage.email : '' }</FormFeedback>
                            </FormGroup>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                            <Label className='m-0' for="manager">{ Lang[ 'MANAGER' ][ this.props.data.languageReducer.lang ] }</Label>
                          </td>
                          <td>
                            <FormGroup className='m-0'>
                              <Input
                                name="manager" id="manager"
                                placeholder={ Lang[ 'ENTER_MANAGER' ][ this.props.data.languageReducer.lang ] }
                                value={ manager }
                                onChange={ e => this.onChange( 'manager', e.target.value ) }
                                invalid={ errorMessage && errorMessage.manager ? true : false }/>
                              <FormFeedback>{ errorMessage && errorMessage.manager ? errorMessage.manager : '' }</FormFeedback>
                            </FormGroup>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                            <Label className='m-0' for="email">{ Lang[ 'LANGUAGE' ][ this.props.data.languageReducer.lang ] }</Label>
                          </td>
                          <td>
                            <FormGroup className='m-0'>
                              <Input
                                type="select"
                                value={ language }
                                onChange={ e => this.onChange( 'language', e.target.value )}>
                                {
                                  languages && languages.map( item => (
                                    <option value={ item.short_name }>{ item.name }</option>
                                  ))
                                }
                              </Input>
                              <FormFeedback>{ errorMessage && errorMessage.language ? errorMessage.language : '' }</FormFeedback>
                            </FormGroup>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                            <Label className='m-0' for="active">{ Lang[ 'WOMAN_ONLY' ][ this.props.data.languageReducer.lang ] }</Label>
                          </td>
                          <td>
                            <FormGroup className='m-0'>
                              <Label style={{ marginLeft: '20px', marginBottom: "0" }}>
                                <Input
                                  type="checkbox"
                                  value={ is_female_center }
                                  checked={ is_female_center ? true : false }
                                  onChange={ e => this.onChange( 'is_female_center', e.target.checked ) }/>{ Lang[ 'WOMAN_ONLY' ][ this.props.data.languageReducer.lang ] }
                              </Label>
                            </FormGroup>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ paddingRight: '20px' }}>
                            <Label>{ Lang[ 'EMAIL_SERVICE' ][ this.props.data.languageReducer.lang ] }</Label>
                          </td>
                          <td>
                            <FormGroup>
                              <Input
                                type='select'
                                value={ email_api_service }
                                onChange={ e => this.onChange( 'email_api_service', e.target.value ) }>
                                <option value='mailgun'>Mailgun</option>
                                <option value='send in blue'>Send In Blue</option>
                              </Input>
                            </FormGroup>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                            <Label className='m-0' for="active">{ 'eGym OAuth Client ID' }</Label>
                          </td>
                          <td>
                            <FormGroup className='m-0'>
                              <Input
                                name="egym_oauth_client_id" id="egym_oauth_client_id"
                                placeholder={ '' }
                                value={ egym_oauth_client_id }
                                onChange={ e => this.onChange( 'egym_oauth_client_id', e.target.value ) }
                                invalid={ errorMessage && errorMessage.egym_oauth_client_id ? true : false }/>
                              <FormFeedback>{ errorMessage && errorMessage.egym_oauth_client_id ? errorMessage.egym_oauth_client_id : '' }</FormFeedback>
                            </FormGroup>
                          </td>
                        </tr>
                      </table>
                      {/* <FormGroup row>
                        <Label className='m-0' for="site_domain">Site Domain</Label>
                        <Col sm={ 10 }>
                          <Input
                            name="site_domain" id="site_domain"
                            placeholder="Enter site domain"
                            value={ site_domain }
                            onChange={ e => this.onChange( 'site_domain', e.target.value ) }/>
                        </Col>
                      </FormGroup> */}
                      <MultipleSearchSelect
                        list={ centerGroupList }
                        onChange={ (e, data) => this.onChange( 'center_groups_id', data.value ) }
                        list_id={ tmp }
                        placeholder={ Lang[ 'CENTER_GROUP' ][ this.props.data.languageReducer.lang ] } />
                    </Form>
                    <Button
                      className="mb-2 mr-2 mt-3"
                      color="info"
                      onClick={ () => {
                        let tmpData = _.cloneDeep( selectedCenter )
                        delete tmpData.area_code
                        delete tmpData.calling_code
                        delete tmpData.number
                        updateCenter( tmpData.id, tmpData )
                      }}>{ Lang[ 'UPDATE' ][ this.props.data.languageReducer.lang ] }</Button>
                  </TabPane>
                  <TabPane tabId='2'>
                    {
                      this.props.centerLocations && this.props.centerLocations.sort(
                        ( a, b ) => Moment( a.created_at ).diff( Moment( b.created_at ) )
                      ).map( ( item, index ) => {
                        return (
                          <LocationForm
                            onLoadCountries={ onLoadCountries }
                            selectedCenter={ selectedCenter }
                            errorMessage={ errorMessage }
                            getStates={ getStates }
                            getValueForSelect={ getValueForSelect }
                            countriesOptions={ countriesOptions }
                            statesOption={ statesOption }
                            item={ item }
                            index={ index }
                            countries={ countries }
                            onChangeLocationData={ this.onChangeLocationData }
                            leadhubCategoriesOptions={ leadhubCategoriesOptions }
                            { ...this.props }
                          />
                        )
                      })
                    }
                    <Button
                      color="secondary"
                      block
                      onClick={ () => this.props.onChangeCenterHOC( 'addItemModal', true ) }>{ Lang[ 'ADD_NEW_LOCATION' ][ this.props.data.languageReducer.lang ] }
                    </Button>
                    <AddLocationForm
                      onLoadCenters={ this.props.onLoadCenters }
                      onLoadCountries={ onLoadCountries }
                      selectedCenter={ selectedCenter }
                      errorMessage={ errorMessage }
                      getStates={ getStates }
                      getValueForSelect={ getValueForSelect }
                      countriesOptions={ countriesOptions }
                      statesOption={ statesOption }
                      createCenterLocation={ this.props.createCenterLocation }
                      phoneNum={ this.props.phoneNum }
                      countries={ countries }
                      onChangeCenterHOC={ this.props.onChangeCenterHOC }
                      newLocationData={ this.state.newLocationData }
                      addItemModal={ this.props.addItemModal }
                      onChangeNewLocationData={ this.onChangeNewLocationData }
                      leadhubCategoriesOptions={ leadhubCategoriesOptions } 
                    />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Container>
          {
            this.props.onLoadCenters && <LoadingOverlay />
          }
        </ReactCSSTransitionGroup>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({ data: state })

export default connect( mapStateToProps )( CenterForm )
