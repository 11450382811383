import React from 'react'
import { 
  Modal, ModalHeader, ModalBody, ModalFooter,
  Label, Input, Col, Button,
  Form, FormGroup
} from 'reactstrap'
import ReactSelect from 'react-select'

import MultiLangInput from 'components/Input/MultiLang'
import Lang from 'Lang/General'

const InformationForm = ({
  mode,
  onChangeFormBuilderState,
  data,
  centerGroupOptions,
  categoryOptions,
  onSubmit,
  isShowInfoModal,
  toggleInfoModal,
  selectedLanguage
}) => {
  return(
    <Modal
      isOpen={ isShowInfoModal }>
      <ModalHeader toggle={ () => toggleInfoModal( false ) }>
        { Lang[ 'FORM_INFORMATION' ][ selectedLanguage ] }</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <MultiLangInput
              label={ Lang[ 'FORM_NAME' ][ selectedLanguage] }
              value={ data.name } 
              onChange={ val => {
                onChangeFormBuilderState( 'name', val )
              }}
              selectedLanguage={ selectedLanguage }
              disabled={ mode === 'view' } />
          </FormGroup>
          <FormGroup>
            <MultiLangInput
              type={ 'textarea' }
              label={ Lang[ 'DESCRIPTION' ][ selectedLanguage ] }
              value={ data.description } 
              onChange={ val => {
                onChangeFormBuilderState( 'description', val )
              }}
              selectedLanguage={ selectedLanguage }
              disabled={ mode === 'view' } />
          </FormGroup>
          <FormGroup row>
            <Label md={ 3 }>{ Lang[ 'CENTER_GROUPS' ][ selectedLanguage ] }</Label>
            <Col md={ 9 }>
              <ReactSelect
                isMulti
                menuPortalTarget={document.body} 
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                options={ centerGroupOptions }
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={ ( value, action ) => onChangeFormBuilderState( 'centerGroups', value ) }
                value={ data.centerGroups }
                isDisabled={ mode === 'view' }/>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={ 3 }>{ Lang[ 'CATEGORIES' ][ selectedLanguage ] }</Label>
            <Col md={ 9 }>
              <ReactSelect
                isMulti
                menuPortalTarget={document.body} 
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                options={ categoryOptions }
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={ ( value, action ) => onChangeFormBuilderState( 'categories', value ) }
                value={ data.categories }
                isDisabled={ mode === 'view' }/>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={ 3 }>{ Lang[ 'STATUS' ][ selectedLanguage ] }</Label>
            <Col md={ 9 }>
              <Input
                type="select"
                value={ data.status }
                onChange={ e => onChangeFormBuilderState( 'status', e.target.value ) }
                disabled={ mode === 'view' }>
                <option>{ Lang[ 'SELECT_PLACEHOLDER' ][ selectedLanguage ] }</option>
                <option value="ACTIVE">{ Lang[ 'ACTIVE' ][ selectedLanguage ] }</option>
                <option value="INACTIVE">{ Lang[ 'INACTIVE' ][ selectedLanguage ] }</option>
                <option value="ARCHIVED">{ Lang[ 'ARCHIVED' ][ selectedLanguage ] }</option>
              </Input>
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      {
        mode !== 'view' && (
          <ModalFooter>
            <Button
              color="primary"
              onClick={ () => onSubmit() }>{ Lang[ 'SUBMIT' ][ selectedLanguage ] }</Button>
          </ModalFooter>
        )
      }
    </Modal>
  )
}

export default InformationForm 