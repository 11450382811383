import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { Get, Post, Put, Delete } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      products: {
        data: [],
        meta: {
          currentPage: 1
        }
      },
      newProduct: {
        name: {
          "en": "",
          "de": "",
          "nl": "",
          "it": "",
          "fr": ""
        },
        description: {
          "en": "",
          "de": "",
          "nl": "",
          "it": "",
          "fr": ""
        },
        currency_price_1: {
          "en": "",
          "de": "",
          "nl": "",
          "it": "",
          "fr": ""
        },
        currency_price_2: {
          "en": "",
          "de": "",
          "nl": "",
          "it": "",
          "fr": ""
        },
        image_url: '',
        pdf_url: "",
        is_new: false,
        currency: "",
        price: 0.00,
        countries: [],
        center_group_ids: [],
        is_most_booked_products: false
      },
      showCreateProduct: false,
      showUpdateProduct: false,
      showViewProduct: false,
      selectedProduct: {
        name: {
          "en": "",
          "de": "",
          "nl": "",
          "it": "",
          "fr": ""
        },
        description: {
          "en": "",
          "de": "",
          "nl": "",
          "it": "",
          "fr": ""
        },
        currency_price_1: {
          "en": "",
          "de": "",
          "nl": "",
          "it": "",
          "fr": ""
        },
        currency_price_2: {
          "en": "",
          "de": "",
          "nl": "",
          "it": "",
          "fr": ""
        },
        image_url: '',
        pdf_url: "",
        is_new: false,
        currency: "",
        price: 0.00,
        countries: [],
        center_group_ids: [],
        is_most_booked_products: false
      },
      productError: {},
      imageKey: ''
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      zIndex: 1000
    })
    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      zIndex: 1000
    })

    onChangeProductsHOC = ( key, val ) => this.setState({ [key]: val })

    getProducts = ( param ) => Get(
      `/api/v1/admin_panel/products?query=${ convertObjToBase64( param ) }`,
      this.getProductsSuccess,
      this.getProductsError,
      this.load
    )
    getProductsSuccess = payload => this.setState({ products: payload })
    getProductsError = error => this.requestError( error )

    getSelectedProduct = ( id, flag ) => Get(
      `/api/v1/admin_panel/products/${ id }`,
      payload => this.getSelectedProductSuccess( payload, flag ),
      this.getSelectedProductError,
      this.load
    )
    getSelectedProductSuccess = ( payload, flag ) => {
      this.setState({ 
        selectedProduct: {
          ...payload,
          center_group_ids: payload.center_group_ids ? payload.center_group_ids : []
        },
        showUpdateProduct: flag === 'edit',
        showViewProduct: flag === 'view'
      })
    }
    getSelectedProductError = error => this.requestError( error )

    createProduct = data => Post(
      `/api/v1/admin_panel/products`,
      data,
      this.createProductSuccess,
      this.createProductError,
      this.load
    )
    createProductSuccess = payload => {
      this.setState({
        newProduct: {
          name: {
            "en": "",
            "de": "",
            "nl": "",
            "it": "",
            "fr": ""
          },
          description: {
            "en": "",
            "de": "",
            "nl": "",
            "it": "",
            "fr": ""
          },
          currency_price_1: {
            "en": "",
            "de": "",
            "nl": "",
            "it": "",
            "fr": ""
          },
          currency_price_2: {
            "en": "",
            "de": "",
            "nl": "",
            "it": "",
            "fr": ""
          },
          image_url: '',
          pdf_url: "",
          is_new: false,
          currency: "",
          price: 0.00,
          countries: [],
          center_group_ids: [],
          is_most_booked_products: false
        },
        showCreateProduct: false
      })
      this.requestSuccess( Lang[ 'PRODUCT_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getProducts({
        page: 1,
        is_paginated: true
      })
    }
    createProductError = error => this.setState({ productError: error })

    updateProduct = data => Put(
      `/api/v1/admin_panel/products/${ data.id }`,
      data,
      this.updateProductSuccess,
      this.updateProductError,
      this.load
    )
    updateProductSuccess = payload => {
      this.setState({
        newProduct: {
          name: {
            "en": "",
            "de": "",
            "nl": "",
            "it": "",
            "fr": ""
          },
          description: {
            "en": "",
            "de": "",
            "nl": "",
            "it": "",
            "fr": ""
          },
          currency_price_1: {
            "en": "",
            "de": "",
            "nl": "",
            "it": "",
            "fr": ""
          },
          currency_price_2: {
            "en": "",
            "de": "",
            "nl": "",
            "it": "",
            "fr": ""
          },
          image_url: '',
          pdf_url: "",
          is_new: false,
          currency: "",
          price: 0.00,
          countries: [],
          center_group_ids: [],
          is_most_booked_products: false
        },
        showUpdateProduct: false
      })
      this.requestSuccess( Lang[ 'PRODUCT_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getProducts({
        page: 1,
        is_paginated: true
      })
    }
    updateProductError = error => this.setState({ productError: error })

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadProducts={ this.state.loading }
          products={ this.state.products }
          showCreateProduct={ this.state.showCreateProduct }
          newProduct={ this.state.newProduct }
          showUpdateProduct={ this.state.showUpdateProduct }
          showViewProduct={ this.state.showViewProduct }
          selectedProduct={ this.state.selectedProduct }
          productError={ this.state.productError }
          onChangeProductsHOC={ this.onChangeProductsHOC }
          getProducts={ this.getProducts }
          getSelectedProduct={ this.getSelectedProduct }
          createProduct={ this.createProduct }
          updateProduct={ this.updateProduct }
          imageKey={ this.state.imageKey } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
