import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import * as courseplanHeaderText from '../utils/courseplanHeaderText'

let navigate = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
  DATE: 'DATE',
}

class CourseplanToolbar extends React.Component {
  state = { windowWidth: window.innerWidth }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() { window.removeEventListener("resize", this.updatePredicate); }

  updatePredicate = () => { 
    if(this.state.windowWidth !== window.innerWidth) { 
      this.props.onView(window.innerWidth > 1000? 'week': 'day') 
      this.setState({ windowWidth: window.innerWidth })
    }
  }

  render() {
    let {
      localizer: { messages },
      label
    } = this.props

    return (
      <div className="rbc-toolbar">
        <span className="rbc-btn-group">
          <button
            type="button"
            onClick={this.navigate.bind(null, navigate.PREVIOUS)}
          >
            <FaAngleLeft className="icon" style={{ fontSize: "14px" }}/>
          </button>
          <button
            type="button"
            onClick={this.navigate.bind(null, navigate.NEXT)}
          >
            <FaAngleRight className="icon" style={{ fontSize: "14px" }}/>
          </button>
          <button
            type="button"
            onClick={this.navigate.bind(null, navigate.TODAY)}
          >
            { courseplanHeaderText.Today[this.props.selectedLanguageShortname] }
          </button>
        </span>

        <span className="rbc-toolbar-label">{this.props.view == 'day'? label: ""}</span>

        <span className="rbc-btn-group">{this.viewNamesGroup(messages)}</span>
      </div>
    )
  }

  navigate = action => {
    this.props.onNavigate(action)
  }

  view = view => {
    this.props.onView(view)
  }

  viewNamesGroup(messages) {
    let viewNames = this.props.views
    const view = this.props.view

    if (viewNames.length > 1) {
      return viewNames.map(name => (
        <button
          type="button"
          key={name}
          className={clsx({ 'rbc-active': view === name })}
          onClick={this.view.bind(null, name)}
        >
          { messages[name] === 'Week'? courseplanHeaderText.Week[this.props.selectedLanguageShortname] : 
            courseplanHeaderText.Day[this.props.selectedLanguageShortname] }
        </button>
      ))
    }
  }
}

CourseplanToolbar.propTypes = {
  view: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.node.isRequired,
  localizer: PropTypes.object,
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
}

export default CourseplanToolbar
