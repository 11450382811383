import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { Get, Post, Put } from 'utils/axios'

import { convertObjToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

const RoleHOC = ( WrappedComponent ) => {
  class RoleWrappedComponent extends Component {
    state={
      createRoleModal: false,
      loading: false,
      roles: {},
      newRolesPermission: {
        name: '',
        platforms: []
      },
      selectedRolePermission: {},
      rolesTotalPages: [],
      rolePage: 1,
      editRoleModal: false,
      errorMessage: {}
    }

    onChangeRoleHOC = ( key, value ) => this.setState({ [key]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    getRoles = query => Get(
      `/api/v1/security_roles?query=${ query }`,
      this.getRolesSuccess,
      this.getRolesError,
      this.load
    )
    getRolesSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }else{
        this.requestError( Lang[ 'NO_RESULT_FOUND' ][ this.props.data.languageReducer.lang ] )
      }
      this.setState({ roles: payload, rolesTotalPages: tmpTotalPages })
    }
    getRolesError = error => this.requestError( error )

    getSelectedRole = id => Get(
      `/api/v1/security_roles/${ id }`,
      this.getSelectedRoleSuccess,
      this.getSelectedRoleError,
      this.load
    )
    getSelectedRoleSuccess = payload => this.setState({ selectedRolePermission: {
      ...payload,
      platforms: payload.platforms ? payload.platforms : []
    }})
    getSelectedRoleError = error => this.requestError( error )

    createRole = data => Post(
      `/api/v1/security_roles`,
      data,
      this.createRoleSuccess,
      this.createRoleError,
      this.load
    )
    createRoleSuccess = () => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'ROLE_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.setState({ 
        createRoleModal: false, 
        newRolesPermission: {
          name: '',
          platforms: []
        }
      })
      this.getRoles( convertObjToBase64( tmp ) )
    }
    createRoleError = error => {
      this.requestError( error )
      this.setState({ 
        createRoleModal: false, 
        newRolesPermission: {
          name: '',
          platforms: []
        }
      })
      error.name && this.setState({ errorMessage: error }, () => this.requestError( error.name ) )
    }

    updateSelectedRole = ( data ) => Put(
      `/api/v1/security_roles/${ this.state.selectedRolePermission.id }`,
      data,
      this.updateSelectedRoleSuccess,
      this.updateSelectedRoleError,
      this.load
    )
    updateSelectedRoleSuccess = () => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'ROLE_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.setState({ editRoleModal: false })
      this.getRoles( convertObjToBase64( tmp ) )
    }
    updateSelectedRoleError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          createRoleModal={ this.state.createRoleModal }
          onChangeRoleHOC={ this.onChangeRoleHOC }
          onLoadRole={ this.state.loading }
          getRoles={ this.getRoles }
          roles={ this.state.roles }
          rolePage={ this.state.rolePage }
          rolesTotalPages={ this.state.rolesTotalPages }
          newRolesPermission={ this.state.newRolesPermission }
          selectedRolePermission={ this.state.selectedRolePermission }
          createRole={ this.createRole }
          getSelectedRole={ this.getSelectedRole }
          editRoleModal={ this.state.editRoleModal }
          updateSelectedRole={ this.updateSelectedRole }
          errorMessage={ this.state.errorMessage }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( RoleWrappedComponent )
}
export default RoleHOC
