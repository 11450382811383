import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get } from 'utils/axios'

const PlatformHOC = ( WrappedComponent ) => {
  class PlatformWrappedComponent extends Component {
    state={
      selectedPlatform: {
        name: '',
        modules: []
      },
      platforms: {
        data: [],
        meta: {}
      },
      loading: false
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    onChangePlatformHOC = ( key, val ) => this.setState({ [ key ]: val })

    getPlatforms = query => Get(
      `/api/v1/security_platforms?query=${ query }`,
      this.getPlatformsSuccess,
      this.getPlatformsError,
      this.load
    )
    getPlatformsSuccess = payload => this.setState({ platforms: payload })
    getPlatformsError = error => this.requestError( error )

    getSelectedPlatform = id => Get(
      `/api/v1/security_platforms/${ id }`,
      this.getSelectedPlatformSuccess,
      this.getSelectedPlatformError,
      this.load
    )
    getSelectedPlatformSuccess = payload => this.setState({ selectedPlatform: payload }, () => {
      let tmpPlatforms = _.cloneDeep( this.state.platforms )
      let tmpPlatformIndex = _.findIndex( tmpPlatforms.data, { id: this.state.selectedPlatform.id })
      let tmpNewRolesPermission = _.cloneDeep( this.props.newRolesPermission )
      let tmpModules = []
      this.state.selectedPlatform.modules.map( module => {
        tmpModules.push({
          ...module,
          is_create: false,
          is_read: false,
          is_update: false,
          is_delete: false
        })
      })
      tmpNewRolesPermission.platforms.push({
        ...this.state.selectedPlatform,
        modules: tmpModules
      })
      this.props.onChangeRoleHOC( 'newRolesPermission', tmpNewRolesPermission )
      tmpPlatforms.data.splice( tmpPlatformIndex, 1 )
      this.setState({ platforms: tmpPlatforms })
    })
    getSelectedPlatformError = error => this.requestError( error )

    getEditSelectedPlatform = id => Get(
      `/api/v1/security_platforms/${ id }`,
      this.getEditSelectedPlatformSuccess,
      this.getEditSelectedPlatformError,
      this.load
    )
    getEditSelectedPlatformSuccess = payload => this.setState({ selectedPlatform: payload }, () => {
      let tmpSelectedRolesPermission = _.cloneDeep( this.props.selectedRolePermission )
      let tmpModules = []
      this.state.selectedPlatform.modules.map( module => {
        tmpModules.push({
          ...module,
          is_create: false,
          is_read: false,
          is_update: false,
          is_delete: false
        })
      })
      tmpSelectedRolesPermission.platforms.push({
        ...this.state.selectedPlatform,
        modules: tmpModules
      })
      this.props.onChangeRoleHOC( 'selectedRolePermission', tmpSelectedRolesPermission )
    })
    getEditSelectedPlatformError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          selectedPlatform={ this.state.selectedPlatform }
          platforms={ this.state.platforms }
          getPlatforms={ this.getPlatforms }
          getSelectedPlatform={ this.getSelectedPlatform }
          onChangePlatformHOC={ this.onChangePlatformHOC }
          getEditSelectedPlatform={ this.getEditSelectedPlatform } />
      )
    }
  }
  return PlatformWrappedComponent
}

export default PlatformHOC
