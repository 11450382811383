import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { Get, Put, Post } from 'utils/axios'
import Lang from 'Lang/General'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state = {
      loading: false,
      platformSettings: [],
      selectedPlatformSetting: {
        impressum: {
          en: '',
          de: '',
          it: '',
          nl: ''
        }
      }
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    onChangePlatformSettingsHOC = ( key, val ) => this.setState({ [ key ] : val })

    createPlatformEmailImprintText = data => Post(
      `/api/v1/platform_settings`,
      data,
      this.createPlatformEmailImprintTextSuccess,
      this.createPlatformEmailImprintTextError,
      this.load
    )
    createPlatformEmailImprintTextSuccess = payload => {
      this.setState({ platformSettings: payload })
      this.requestSuccess( Lang[ 'PLATFORM_SETTING_IS_SUCCESSFULLY_UPDATED' ][ this.props.data.languageReducer.lang ] )
    }
    createPlatformEmailImprintTextError = error => this.requestError( error )

    getPlatformSettings = () => Get(
      `/api/v1/platform_settings`,
      this.getPlatformSettingsSuccess,
      this.getPlatformSettingsError,
      this.load
    )
    getPlatformSettingsSuccess = payload => this.setState({ platformSettings: payload.data })
    getPlatformSettingsError = error => this.requestError( error )

    updatePlatformEmailImprintText = data => Put(
      `/api/v1/platform_settings/${ data.id }`,
      data,
      this.updatePlatformEmailImprintTextSuccess,
      this.updatePlatformEmailImprintTextError,
      this.load
    )
    updatePlatformEmailImprintTextSuccess = () => {
      this.getPlatformSettings()
      this.requestSuccess( Lang[ 'PLATFORM_SETTING_IS_SUCCESSFULLY_UPDATED' ][ this.props.data.languageReducer.lang ] )
    }
    updatePlatformEmailImprintTextError = error => this.requestError( error )

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          onLoadPlatformSettingsHOC={ this.state.loading }
          platformSettings={ this.state.platformSettings }
          selectedPlatformSetting={ this.state.selectedPlatformSetting }
          onChangePlatformSettingsHOC={ this.onChangePlatformSettingsHOC }
          getPlatformSettings={ this.getPlatformSettings }
          createPlatformEmailImprintText={ this.createPlatformEmailImprintText }
          updatePlatformEmailImprintText={ this.updatePlatformEmailImprintText } />
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}


export default HOC