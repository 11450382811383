import React, { useState } from 'react'
import _ from 'lodash'
import { Dropdown } from 'semantic-ui-react'

import 'semantic-ui-css/semantic.min.css'

const MultipleSearchSelect = ({
  placeholder,
  list,
  onChange,
  list_id
}) => {
  return(
    <Dropdown
      placeholder={ placeholder }
      fluid
      multiple
      search
      selection
      options={ list }
      value={ list_id }
      onChange={( e, data ) => {
        onChange( e, data )
      }}
      onSearchChange={ ( e, data ) => {
        console.log( e.target )
        console.log( data )
      }}>
    </Dropdown>
  )
}

export default MultipleSearchSelect
