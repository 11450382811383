 import React, { useState, useEffect, useRef } from 'react'
import {
  Form, FormGroup, FormFeedback, FormText,
  Label, Input,
  Col
} from 'reactstrap'
import ReactSelect from 'react-select'
import Image from 'react-bootstrap/Image'
import Moment from 'moment'
import _ from 'lodash'

import { CountryListDe } from 'assets/CountryLists'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import NoPicture from 'assets/images/no-pic.jpg'
import Lang from 'Lang/General'

const MemberForm = ({
  memberType,
  currentMember,
  onChangeMembersHOC,
  roles,
  memberStatuses,
  errorMessage,
  lang,
  sites,
  onLoadMembersHOC
}) => {
  const [ countryOptions, updateCountryOptions ] = useState( [] )

  useEffect( () => {
    onChangeMembersHOC( 'errorMessage', {} )
    updateCountryOptions( 
      CountryListDe.map( country => ({ 
        ...country, 
        id: country.alpha2,
        value: country.alpha2,
        label: country.name,
      })) 
    )
  }, [] )
  
  useEffect( () => {
    if ( roles.length > 0 ) {
      let tmp = _.cloneDeep( currentMember )
      let isExpert = (
        tmp.ark_member_role_id > 0 &&
        _.find( roles, role => role.id === tmp.ark_member_role_id ).name === 'Expert'
      )
      if( isExpert && Moment().diff( Moment( tmp.birthday, 'YYYY-MM-DD' ), 'years' ) < 18 ){
        onChangeMembersHOC( 
          'errorMessage', 
          { birthday: Lang[ 'EXPERT_MUST_BE_AT_LEAST' ][ lang ] } 
        )
      }
    }
  }, [ currentMember.birthday, currentMember.ark_member_role_id ] )

  let fileInput = useRef( null )
  const fileInputRef = element => fileInput = element
  const onClickFileInput = () => {
    if( fileInput )
      fileInput.click();
  }

  const updateMember = ( key, val ) => {
    let tmp = _.cloneDeep( currentMember )

    if( key === 'photo' ){
      if( val.target.files[0] ) {
        let reader = new FileReader()
        let filename = val.target.files[0].name
        reader.onload = e => {
          let tmp = _.cloneDeep( currentMember )
          tmp[ 'photo' ][ 'raw' ] = e.target.result
          onChangeMembersHOC( memberType, tmp )
        }
        reader.readAsDataURL( val.target.files[0] )
      }
    } else {
      tmp[ key ] = val
      onChangeMembersHOC( memberType, tmp )
    }
  }
  
  return(
    <>
      <Form>
        <FormGroup row >
          <Label sm={ 2 } >{ Lang[ 'FIRST_NAME' ][ lang ] }</Label>
          <Col sm={ 10 } >
            <Input
              type='text'
              value={ currentMember.first_name }
              onChange={ e => updateMember( 'first_name', e.target.value ) }
              invalid={ errorMessage.first_name } />
            <FormFeedback>{ errorMessage.first_name }</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row >
          <Label sm={ 2 } >{ Lang[ 'LAST_NAME' ][ lang ] }</Label>
          <Col sm={ 10 } >
            <Input
              type='text'
              value={ currentMember.last_name }
              onChange={ e => updateMember( 'last_name', e.target.value ) }
              invalid={ errorMessage.last_name } />
            <FormFeedback>{ errorMessage.last_name }</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row >
          <Label sm={ 2 } >{ Lang[ 'SALUTATION' ][ lang ] }</Label>
          <Col sm={ 10 } >
            <Input
              type='select'
              value={ currentMember.salutation }
              onChange={ e => updateMember( 'salutation', e.target.value ) }
              invalid={ errorMessage.salutation } >
              <option value='Mrs' >{ Lang[ 'MRS' ][ lang ] }</option>
              <option value='Mr' >{ Lang[ 'MR' ][ lang ] }</option>
              <option value='Diverse' >{ Lang[ 'DIVERSE' ][ lang ] }</option>
            </Input>
            <FormFeedback>{ errorMessage.salutation }</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="country" sm={ 2 }>{ Lang[ 'DATE_OF_BIRTH' ][ lang ] }</Label>
          <Col sm={ 10 }>
            <Input
              type='date'
              value={ currentMember.birthday }
              onChange={ e => updateMember( 'birthday', e.target.value ) }
              invalid={ errorMessage.birthday } />
            <FormFeedback>{ errorMessage.birthday }</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="country" sm={ 2 }>{ Lang[ 'COUNTRY' ][ lang ] }</Label>
          <Col sm={ 10 }>
            <ReactSelect
              isDisabled={ memberType === 'selectedMember' }
              options={ countryOptions }
              onChange={ e => updateMember( 'country', e.id ) }
              value={ !_.isEmpty( currentMember.country ) && _.find( countryOptions, { id: currentMember.country } )
                ? _.find( countryOptions, { id: currentMember.country } )
                : {} 
              } />
            <FormText color="danger">{ errorMessage && errorMessage.country ? errorMessage.country : '' }</FormText>
          </Col>
        </FormGroup>
        <FormGroup row >
          <Label sm={ 2 } >{ Lang[ 'EMAIL' ][ lang ] }</Label>
          <Col sm={ 10 } >
            <Input
              type='text'
              value={ currentMember.email }
              disabled={ memberType === 'selectedMember' }
              onChange={ e => {
                let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i )
                if( !pattern.test( e.target.value ) ){
                  errorMessage[ 'email' ] = Lang[ 'PLEASE_ENTER_A_VALID_EMAIL' ][ lang ]
                } else {
                  errorMessage[ 'email' ] = ''
                }
                onChangeMembersHOC( 'error' )
                updateMember( 'email', e.target.value.toLowerCase() ) 
              }}
              invalid={ errorMessage.email } />
            <FormFeedback>{ errorMessage.email }</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row >
          <Label sm={ 2 } >{ Lang[ 'PASSWORD' ][ lang ] }</Label>
          <Col sm={ 10 } >
            <Input
              autoComplete='new-password'
              type='password'
              value={ currentMember.password }
              onChange={ e => updateMember( 'password', e.target.value ) }
              invalid={ 
                ( currentMember.password && currentMember.password.length > 0 && currentMember.password.length < 8 ) ||
                errorMessage.password
              } 
            />
            <FormFeedback>
              { 
                (
                  currentMember.password &&
                  currentMember.password.length > 0 && 
                  currentMember.password.length < 8 && 
                  Lang[ 'PASSWORD_MUST_BE_MORE_THAN' ][ lang ]
                ) || (
                  errorMessage.password
                )
              }
            </FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row >
          <Label sm={ 2 } >{ Lang[ 'CONFIRM_PASSWORD' ][ lang ] }</Label>
          <Col sm={ 10 } >
            <Input
              type='password'
              value={ currentMember.password_confirmation }
              onChange={ e => updateMember( 'password_confirmation', e.target.value ) }
              invalid={ 
                currentMember.password &&
                currentMember.password_confirmation &&
                currentMember.password_confirmation !== currentMember.password
              } />
            <FormFeedback>
              { 
                currentMember.password &&
                currentMember.password_confirmation &&
                currentMember.password_confirmation !== currentMember.password &&
                Lang[ 'PASSWORD_IS_NOT_SAME' ][ lang ]
              }
            </FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={ 2 }>{ Lang[ 'PHOTO' ][ lang ] }</Label>
          <Col sm={ 10 } style={{ height: '200px', maxWidth: '200px' }}>
            {
              errorMessage.photo && (
                <FormFeedback className="d-block">{ errorMessage.photo }</FormFeedback>
              )
            }
            <Image
              src={ currentMember.photo && !_.isEmpty( currentMember.photo.raw ) 
                ? currentMember.photo.raw
                : !_.isEmpty( currentMember.photo.url )
                  ? currentMember.photo.url
                  : NoPicture 
              }
              onClick={ onClickFileInput }
              style={{ height: '100%' }}
              roundedCircle />
            <input
              type="file"
              ref={ fileInputRef }
              style={{ display: 'none' }}
              onChange={ event => updateMember( 'photo', event ) }
              accept={ 'image/png,image/jpg,image/jpeg' }/>
          </Col>
        </FormGroup>
        <FormGroup row >
          <Label sm={ 2 } >{ Lang[ 'CENTER' ][ lang ] }</Label>
          <Col sm={ 10 } >
            <Input
              type='select'
              value={ currentMember.ms_center_id }
              onChange={ e => updateMember( 'ms_center_id', parseInt( e.target.value ) ) } 
              invalid={ errorMessage.ms_center_id } >
              <option value={ 0 } ></option>
              { sites.map( site => <option value={ site.center_id } >{ site.name }</option> ) }
            </Input>
            <FormFeedback>{ errorMessage.ms_center_id }</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row >
          <Label sm={ 2 } >{ Lang[ 'ROLE' ][ lang ] }</Label>
          <Col sm={ 10 } >
            <Input
              type='select'
              disabled={ memberType === 'selectedMember' }
              value={ currentMember.ark_member_role_id }
              onChange={ e => updateMember( 'ark_member_role_id', parseInt( e.target.value ) ) } 
              invalid={ errorMessage.ark_member_role_id } >
              <option value={ 0 } ></option>
              { 
                roles.map( role => {
                  let tmpRole = role.name.toUpperCase().replace( / /g, '' )
                  return <option value={ role.id } >{ Lang[ tmpRole ] && Lang[ tmpRole ][ lang ] || role.name }</option> 
                }) 
              }
            </Input>
            <FormFeedback>{ errorMessage.ark_member_role_id }</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row >
          <Label sm={ 2 } >{ Lang[ 'STATUS' ][ lang ] }</Label>
          <Col sm={ 10 } >
            <Input
              type='select'
              value={ currentMember.ark_member_user_status_id }
              onChange={ e => updateMember( 'ark_member_user_status_id', parseInt( e.target.value ) ) }
              invalid={ errorMessage.ark_member_user_status_id } >
              <option value={ 0 } ></option>
              { memberStatuses.map( status => <option value={ status.id } >{ status.name[ lang ] }</option> ) }
            </Input>
            <FormFeedback>{ errorMessage.ark_member_user_status_id }</FormFeedback>
          </Col>
        </FormGroup>
        {
          memberType === 'selectedMember' && (
            <FormGroup row >
              <Label sm={ 2 } >{ Lang[ 'PLATFORM' ][ lang ] }</Label>
              <Col sm={ 10 } >
                <Input
                  disabled
                  type='select'
                  value={ currentMember.exist_platform }
                  onChange={ e => updateMember( 'exist_platform', e.target.value ) }
                  invalid={ errorMessage.exist_platform } >
                  <option value='' ></option>
                  <option value='EASY_SOLUTION' >{ Lang[ 'EASY_SOLUTION' ][ lang ] }</option>
                </Input>
                <FormFeedback>{ errorMessage.exist_platform }</FormFeedback>
              </Col>
            </FormGroup>
          )
        }
      </Form> 
      { onLoadMembersHOC && <LoadingOverlay/> }
    </>
  )
}

export default MemberForm