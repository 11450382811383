import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import Moment from 'moment'
import _ from 'lodash'

import { Post, Get, Put, Delete } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'
import { toggleMediaDatabase } from 'actions/MediaDatabaseToggle'
import Lang from 'Lang/General'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state={ 
      loading: false,
      programTemplates: [],
      programTemplatesPages: [],
      newProgramTemplate: {},
      selectedProgramTemplate: {},
      rawProgramTemplate: {},
      selectedProgramTemplateFiles: [],
      showCreateProgramTemplate: false,
      showUpdateProgramTemplate: false,
      showArchivedProgramTemplate: false,
      showDeleteProgramTemplateId: 0,
      showArchiveProgramTemplateId: 0,
      programTemplateErrorMessage: {}
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => {
      toast.success( success )
      this.setState({ programTemplateErrorMessage: {} })
    }
    requestError = error => {
      if( typeof error === 'string' ){
        toast.error( error )
      } else {
        let tmpError = _.reduce( 
          error, 
          ( res, val, key ) => ({ ...res, [ key.replace( 'am_program_template.', '' ) ]: val.replaceAll( '_', ' ' ) }),
          {}
        )
        _.map( tmpError, ( err, errKey ) => toast.error( err ) )
        this.setState({ programTemplateErrorMessage: tmpError })
      }
    }

    onChangeProgramTemplatesHOC = ( key, value ) => this.setState({ [ key ] : value })

    createProgramTemplate = data => Post(
      `/api/v1/ark_member_area/am_program_templates`,
      data,
      this.createProgramTemplateSuccess,
      this.createProgramTemplateError,
      this.load
    )
    createProgramTemplateSuccess = () => {
      this.getProgramTemplates()
      this.requestSuccess( Lang[ 'PROGRAM_TEMPLATE_IS_SUCCESSFULLY_CREATED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showCreateProgramTemplate: false })
    }
    createProgramTemplateError = error => this.requestError( error )

    getProgramTemplates = ( page, isArchived ) => {
      let tmpSearchParams = {
        page: page ? page : 1,
        is_paginated: true,
        filter: {
          status: isArchived 
            ? 'ARCHIVED' 
            : { $neq: 'ARCHIVED' }
        }
      }
      let tmpSearchString = convertObjToBase64( tmpSearchParams )
      return Get(
        `/api/v1/ark_member_area/am_program_templates?query=${ tmpSearchString }`,
        payload => this.getProgramTemplatesSuccess( payload, isArchived ),
        this.getProgramTemplatesError,
        this.load
      )
    }
    getProgramTemplatesSuccess = ( payload, isArchived ) => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      this.setState({ 
        programTemplates: payload, 
        programTemplatesPages: tmpTotalPages,
        showArchivedProgramTemplate: isArchived
      })
    }
    getProgramTemplatesError = error => this.requestError( error )

    getSelectedProgramTemplate = program_template_id => Get(
      `/api/v1/ark_member_area/am_program_templates/${ program_template_id }?timezone=${ encodeURIComponent( Moment().format() ) }`,
      this.getSelectedProgramTemplateSuccess,
      this.getSelectedProgramTemplateError,
      this.load
    )
    getSelectedProgramTemplateSuccess = async( payload ) => await this.getSelectedProgramTemplateFiles( payload.id, payload )
    getSelectedProgramTemplateError = error => this.requestError( error )

    updateProgramTemplate = data => Put(
      `/api/v1/ark_member_area/am_program_templates/${ data.id }`,
      data,
      this.updateProgramTemplateSuccess,
      this.updateProgramTemplateError,
      this.load
    )
    updateProgramTemplateSuccess = () => {
      this.getProgramTemplates( 1, this.state.showArchivedProgramTemplate )
      this.requestSuccess( Lang[ 'PROGRAM_TEMPLATE_IS_SUCCESSFULLY_UPDATED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showUpdateProgramTemplate: false, showArchiveProgramTemplateId: 0 })
    }
    updateProgramTemplateError = error => this.requestError( error )

    removeProgramTemplate = program_template_id => Delete(
      `/api/v1/ark_member_area/am_program_templates/${ program_template_id }`,
      this.removeProgramTemplateSuccess,
      this.removeProgramTemplateError,
      this.load
    )
    removeProgramTemplateSuccess = () => {
      this.getProgramTemplates()
      this.requestSuccess( Lang[ 'PROGRAM_TEMPLATE_IS_SUCCESSFULLY_REMOVED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showDeleteProgramTemplateId: 0 })
    }
    removeProgramTemplateError = error => {
      this.requestError( error )
      this.setState({ showDeleteProgramTemplateId: 0 })
    }

    createSelectedProgramTemplateFile = ( program_template_id, data ) => Post(
      `/api/v1/ark_member_area/am_program_templates/${ program_template_id }/files`,
      data,
      () => this.createSelectedProgramTemplateFileSuccess( program_template_id ),
      this.createSelectedProgramTemplateFileError,
      this.load
    )
    createSelectedProgramTemplateFileSuccess = program_template_id => {
      this.requestSuccess( Lang[ 'FILE_IS_SUCCESSFULLY_CREATED' ][ this.props.data.languageReducer.lang ] )
      this.getSelectedProgramTemplateFiles( program_template_id )
    }
    createSelectedProgramTemplateFileError = error => this.requestError( error )

    getSelectedProgramTemplateFiles = ( program_template_id, selectedProgramTemplate ) => Get(
      `/api/v1/ark_member_area/am_program_templates/${ program_template_id }/files`,
      payload => this.getSelectedProgramTemplateFilesSuccess( payload, selectedProgramTemplate ),
      this.getSelectedProgramTemplateFilesError,
      this.load
    )
    getSelectedProgramTemplateFilesSuccess = ( payload, selectedProgramTemplate ) => {
      let tmpSelectedProgramTemplate = _.cloneDeep( selectedProgramTemplate || this.state.selectedProgramTemplate )
      if( selectedProgramTemplate ){
        tmpSelectedProgramTemplate.weekly_content = tmpSelectedProgramTemplate.video_links
        
        for( let i = 0; i < selectedProgramTemplate.weeks; i++ ){
          let tmpIndex = _.findIndex( tmpSelectedProgramTemplate.weekly_content, { week_number: i+1 } )
          if ( tmpIndex === -1 ) {
            tmpSelectedProgramTemplate.weekly_content.push({ week_number: i+1, files: [], urls: [] })
          } else {
            tmpSelectedProgramTemplate.weekly_content[ tmpIndex ].files = []
          }
        }
      }
      payload.map( weeklyFiles => {
        let tmpIndex = _.findIndex( tmpSelectedProgramTemplate.weekly_content, { week_number: weeklyFiles.week_number } )
        tmpSelectedProgramTemplate.weekly_content[ tmpIndex ].files = weeklyFiles.files
      })
      tmpSelectedProgramTemplate.weekly_content = _.sortBy( tmpSelectedProgramTemplate.weekly_content, [ 'week_number' ], [ 'asc' ] )
      selectedProgramTemplate
        ? this.setState({ rawProgramTemplate: tmpSelectedProgramTemplate, showUpdateProgramTemplate: true })
        : this.setState({ selectedProgramTemplate: tmpSelectedProgramTemplate })
    }
    getSelectedProgramTemplateFilesError = error => this.requestError( error )

    removeSelectedProgramTemplateFile = ( program_template_id, file_id ) => Delete(
      `/api/v1/ark_member_area/am_program_templates/${ program_template_id }/files/${ file_id }`,
      () => this.removeSelectedProgramTemplateFileSuccess( program_template_id ),
      this.removeSelectedProgramTemplateFileError,
      this.load
    )
    removeSelectedProgramTemplateFileSuccess = async( program_template_id ) => {
      this.requestSuccess( Lang[ 'FILE_IS_SUCCESSFULLY_REMOVED' ][ this.props.data.languageReducer.lang ] )
      await this.getSelectedProgramTemplateFiles( program_template_id )
    }
    removeSelectedProgramTemplateFileError = error => this.requestError( error )

    render = () => {
      return(
        <WrappedComponent
          { ...this.props } 
          onLoadProgramTemplatesHOC={ this.state.loading }
          programTemplates={ this.state.programTemplates }
          programTemplatesPages={ this.state.programTemplatesPages }
          newProgramTemplate={ this.state.newProgramTemplate }
          rawProgramTemplate={ this.state.rawProgramTemplate }
          selectedProgramTemplate={ this.state.selectedProgramTemplate }
          showCreateProgramTemplate={ this.state.showCreateProgramTemplate }
          showUpdateProgramTemplate={ this.state.showUpdateProgramTemplate }
          showArchivedProgramTemplate={ this.state.showArchivedProgramTemplate }
          showDeleteProgramTemplateId={ this.state.showDeleteProgramTemplateId }
          showArchiveProgramTemplateId={ this.state.showArchiveProgramTemplateId }
          programTemplateErrorMessage={ this.state.programTemplateErrorMessage }
          selectedProgramTemplateFiles={ this.state.selectedProgramTemplateFiles }
          onChangeProgramTemplatesHOC={ this.onChangeProgramTemplatesHOC }
          createProgramTemplate={ this.createProgramTemplate }
          getProgramTemplates={ this.getProgramTemplates }
          getSelectedProgramTemplate={ this.getSelectedProgramTemplate }
          updateProgramTemplate={ this.updateProgramTemplate }
          removeProgramTemplate={ this.removeProgramTemplate }
          createSelectedProgramTemplateFile={ this.createSelectedProgramTemplateFile }
          getSelectedProgramTemplateFiles={ this.getSelectedProgramTemplateFiles }
          removeSelectedProgramTemplateFile={ this.removeSelectedProgramTemplateFile }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( 
    mapStateToProps, 
    { toggleMediaDatabase } 
  )( WithHOC )
}

export default HOC