import React, { Component } from "react"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
import { compose } from "redux"
import {
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
} from "reactstrap"
import ARKTable from "components/Table"
import _ from "lodash"

import PageTitle from "components/PageTitle"
import LoadingOverlay from "components/Indicator/LoadingOverlay"
import CreateForm from "./Create"
import UpdateForm from "./Update"
import DuplicateForm from "./DuplicateModal"

import Lang from "Lang/General"
import WithForms from "./actions"
import WithTemplateComponents from "actions/TemplateComponents"
import { convertObjToBase64 } from "utils/objToBase64"

class Webforms extends Component {
  componentDidMount = () => {
    let tmp = { page: 1, is_paginated: true }
    this.props.getForms( tmp )
    this.props.getComponents(
      convertObjToBase64({
        filter: { $or: [{ category: "button" }] },
      })
    )
  }

  handleSecurityModules = ( type ) => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, {
      name: "Ark Management",
    })
    let tmpModules = {}
    if ( tmp ) {
      tmpModules = _.find( tmp.modules, { name: "OLS" } )
      return tmpModules[ type ]
    }
  }

  getColumnWidth = ( data, headerText ) => {
    let max = 0
    const maxWidth = 400
    const magicSpacing = 18

    for ( var i = 0; i < data.length; i++ ) {
      if ( !_.isEmpty( data[i][ "dataLabel" ]) ) {
        if ( data[i][ "dataLabel" ].length > max ) {
          max = data[i][ "dataLabel" ].length
        }
      }
    }

    return Math.min( maxWidth, Math.max( max, headerText.length ) * magicSpacing )
  }

  render = () => {
    return (
      <>
        <PageTitle
          heading={ Lang[ "WEBFORM_TEMPLATE" ][ this.props.data.languageReducer.lang ] }
          icon="pe-7s-display2 icon-gradient bg-happy-itmeo"
          buttons={[
            {
              buttonText: Lang[ "CREATE_FORM" ][ this.props.data.languageReducer.lang ],
              onClick: () =>
                this.props.onChangeFormsHOC( "showCreateForm", true ),
              display: this.handleSecurityModules( "is_create" ),
            },
          ]}
        />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={ true }
          transitionAppearTimeout={ 0 }
          transitionEnter={ false }
          transitionLeave={ false }
        >
          <Container fluid>
            <Row>
              <Col md={ 12 }>
                <ARKTable
                  data={ this.props.forms.data }
                  columns={[
                    {
                      Header: Lang[ "NAME" ][ this.props.data.languageReducer.lang ],
                      accessor: "name",
                      Cell: ({ value }) => (
                        <span>
                          { value[ this.props.data.languageReducer.lang ]}
                        </span>
                      ),
                    },
                    {
                      Header: Lang[ "STATUS" ][ this.props.data.languageReducer.lang ],
                      accessor: "status",
                      width: 200,
                    },
                    {
                      Header: Lang[ "ACTIONS" ][ this.props.data.languageReducer.lang ],
                      Cell: ( row ) => {
                        return (
                          <div style={{ display: "block" }}>
                            <Button
                              id={ `Forms-${ row.original.id }-edit` }
                              className="mr-2 mb-2 btn-icon btn-icon-only"
                              color="primary"
                              onClick={ () => {
                                this.props.getSelectedForm( row.original.id, true )
                              }}
                              disabled={
                                !this.handleSecurityModules( "is_update" )
                              }
                            >
                              <i className="pe-7s-pen btn-icon-wrapper"> </i>
                            </Button>
                            <UncontrolledTooltip
                              target={ `Forms-${ row.original.id }-edit` }
                              placement="top"
                            >
                              { Lang[ "UPDATE_FORM" ][ this.props.data.languageReducer.lang ] }
                            </UncontrolledTooltip>
                            <Button
                              id={ `Forms-${ row.original.id }-duplicate` }
                              className="mr-2 mb-2 btn-icon btn-icon-only"
                              color="primary"
                              onClick={ () => {
                                this.props.getSelectedForm( row.original.id, false )
                              }}
                              disabled={ !this.handleSecurityModules( "is_update" ) }
                            >
                              <i className="pe-7s-copy-file btn-icon-wrapper">
                                {" "}
                              </i>
                            </Button>
                            <UncontrolledTooltip
                              target={ `Forms-${ row.original.id }-duplicate` }
                              placement="top"
                            >
                              { Lang[ "DUPLICATE_FORM" ][ this.props.data.languageReducer.lang ] }
                            </UncontrolledTooltip>
                          </div>
                        )
                      },
                    },
                  ]}
                  totalPages={
                    this.props.forms.meta
                      ? [ ...Array( this.props.forms.meta.last_page ).keys() ].map( ( i ) => i + 1 )
                      : [1]
                  }
                  page={
                    this.props.forms.meta
                      ? this.props.forms.meta.current_page
                      : 1
                  }
                  onChangePage={ ( pg ) => {
                    let tmp = { page: pg, is_paginated: true }
                    return this.props.getForms( tmp )
                  }}
                  showPagination={ false }
                />
              </Col>
            </Row>
          </Container>
        </ReactCSSTransitionGroup>
        <CreateForm { ...this.props } />
        <UpdateForm { ...this.props } />
        <DuplicateForm { ...this.props } />
        {( this.props.onLoadForms || this.props.onLoadFormLogs ) && (
          <LoadingOverlay />
        )}
      </>
    )
  }
}

export default compose(
  WithForms, 
  WithTemplateComponents
)( Webforms )
