import React, { Component } from 'react'
import { compose } from 'redux'
import moment from 'moment'
import _ from 'lodash'
import {
  TabContent, TabPane, Nav, NavItem, NavLink,
  Card, CardBody, Row, Col
} from 'reactstrap'
import classnames from 'classnames'

import MediaDBHOC from './actions'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import DeleteConfirmationDialog from 'components/Modal/delete'
import PageTitle from 'components/PageTitle'
import ArkPagination from 'components/Pagination'
import FilteringImage from './components/filter'
import ImagesListing from './components/imagesListing'
import EditImages from './components/editImages'
import UploadImage from './components/uploadImage'
import NewUploadImages from './components/newUploadImage'
import MediaTags from './MediaTags'

import { convertObjToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

class MediaDB extends Component{
  state={
    files: [],
    checkedForFilter: [],
    mode: '',
    language: {},
    searchText: '',
    tmpTag: [],
    emptyData: {
      image: '',
      short_name: {
        de: 'test',
        en: 'test',
        es: 'test',
        it: 'test',
        fr: 'test',
        nl: 'test'
      },
      tags: [],
      type: 'image',
      files: []
    },
    uploadItem: [
      {
        image: 'base64',
        short_name: {
          de: 'test',
          en: 'test',
          es: 'test',
          it: 'test',
          fr: 'test',
          nl: 'test'
        },
        tags: [],
        type: 'image',
        files: []
      }
    ],
    selectedLanguage: [{
      index: 0,
      value: 'en'
    }],
    activeTab: '1'
  }

  componentDidMount = () => {
    let tmp = {
      page: 1,
      is_paginated: true
    }
    this.props.getMedia( convertObjToBase64( tmp ) )
    this.props.getTags()
    this.props.getNewUploadMedia( moment( new Date() ).format( 'YYYY-MM-DD' ) )
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Media' })
      return tmpModules.is_create
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Media' })
      return tmpModules.is_update
    }
  }

  handleSecurityModulesDelete = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Media' })
      return tmpModules.is_delete
    }
  }

  onAddItem = () => {
    let tmp = _.cloneDeep( this.state.uploadItem )
    let tmpSelectedLanguage = _.cloneDeep( this.state.selectedLanguage )
    tmp.push( this.state.emptyData )
    tmpSelectedLanguage.push({
      index: tmp.length - 1,
      value: 'en'
    })
    return this.setState({ uploadItem: tmp, selectedLanguage: tmpSelectedLanguage })
  }

  onUploadFiles = ( index, value ) => {
    let tmp = _.cloneDeep( this.state.uploadItem )
    tmp[ index ].files = value
    if( value.length > 0 && value[ 0 ].file ) {
      const reader = new FileReader()
      reader.onload = e => {
        tmp[ index ].image = e.target.result
        this.setState({ uploadItem: tmp })
      }
      tmp[ index ].short_name = {
        en: value[ 0 ].filenameWithoutExtension
      }
      reader.readAsDataURL( value[ 0 ].file )
    }
  }

  onRemoveItem = index => {
    let tmp = _.cloneDeep( this.state.uploadItem )
    tmp.splice( index, 1 )
    return this.setState({ uploadItem: tmp })
  }

  handleImageCheck = ( id, source ) => {
    let tmp = _.cloneDeep( this.props.selectedImages )
    let tmpFoundIndex = _.findIndex( this.props.selectedImages, { id: id })
    if( tmpFoundIndex !== -1 ) {
      tmp.splice( tmpFoundIndex, 1 )
    } else {
      let tmpFound = _.find( 
        source === 'new' ? this.props.newUploadMedia.data : this.props.media.data, 
        { id: id }
      )
      tmpFound.tags = tmpFound.tags
        ? tmpFound.tags.map( item => ({ label: item, value: item }) )
        : []
      tmp.push( tmpFound )
    }
    return this.props.onChangeData( 'selectedImages', tmp )
  }

  handleMode = ( mode ) => this.setState({ mode: mode })

  handleChangeImageName = ( index, key, val ) => {
    let tmp = _.cloneDeep( this.state.uploadItem )
    tmp[index].short_name[ key ] = val
    this.setState({ uploadItem: tmp })
  }

  updateSelectedLanguage = ( index, value ) => {
    let tmp = _.cloneDeep( this.state.selectedLanguage )
    let tmpFoundIndex = _.findIndex( this.state.selectedLanguage, { index: index })
    if( tmpFoundIndex !== -1 ) {
      tmp[index].value = value
    } else {
      tmp.push({
        index: index,
        value: value
      })
    }
    this.setState({ selectedLanguage: tmp })
  }

  updateType=( index, value ) => {
    let tmp = _.cloneDeep( this.state.uploadItem )
    let tmpItem = tmp[index]
    tmpItem = {
      ...tmpItem,
      type: value
    }
    tmp[index] = tmpItem
    return this.setState({ uploadItem: tmp })
  }

  handleSelectTag = ( index, value ) => {
    let tmpTag = []
    let tmp = this.state.uploadItem
    let tmpItem = tmp[index]
    value && value.map( item => {
      tmpTag.push( item.value )
    })
    tmpItem = {
      ...tmpItem,
      tags: tmpTag
    }
    tmp[index] = tmpItem
    this.setState({ uploadItem: tmp })
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }

  render = () => {
    return(
      <>
        <PageTitle
          heading={ Lang[ 'ALL_MEDIAS' ][ this.props.data.languageReducer.lang ] }
          icon="pe-7s-albums icon-gradient bg-happy-fisher"/>
        <Card className="main-card mb-3">
          <CardBody>
            <Nav tabs={true} className='flex-wrap' >
              <NavItem>
                <NavLink href="javascript:void(0);"
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => {
                    this.toggle('1');
                  }}>{ Lang[ 'PICTURES' ][ this.props.data.languageReducer.lang ] }
                </NavLink>
              </NavItem>
              {
                this.handleSecurityModulesCreate() && (
                  <NavItem>
                    <NavLink href="javascript:void(0);"
                      className={ classnames({ active: this.state.activeTab === '2' }) }
                      onClick={ () => {
                        this.toggle('2')
                      }}>{ Lang[ 'UPLOAD_PICTURES' ][ this.props.data.languageReducer.lang ] }
                    </NavLink>
                  </NavItem>
                )
              }
              {
                this.handleSecurityModulesDelete() && (
                  <NavItem>
                    <NavLink href="javascript:void(0);"
                      className={ classnames({ active: this.state.activeTab === '2' }) }
                      onClick={ () => {
                        this.toggle('3')
                      }}>{ Lang[ 'MEDIA_TAGS' ][ this.props.data.languageReducer.lang ] }
                    </NavLink>
                  </NavItem>
                )
              }
            </Nav>
            <TabContent activeTab={ this.state.activeTab }>
              <TabPane tabId="1">
                <Row className='m-0' >
                  <Col md={ 7 }>
                    <NewUploadImages
                      newUploadMedia={ this.props.newUploadMedia.data }
                      selectedImages={ this.props.selectedImages }
                      handleImageCheck={ this.handleImageCheck }
                      onLoadMedia={ this.props.onLoadMedia } />
                    <hr />
                    <ImagesListing
                      media={ this.props.media.data }
                      selectedImages={ this.props.selectedImages }
                      handleImageCheck={ this.handleImageCheck }
                      onLoadMedia={ this.props.onLoadMedia } />
                    <ArkPagination
                      page={ this.props.media.meta ? this.props.media.meta.current_page : 1 }
                      totalPages={ this.props.totalPages }
                      onChangePage={ pg => {
                        let tmp = {
                          ...this.props.searchQuery,
                          page: pg,
                          is_paginated: true }
                        return this.props.getMedia( convertObjToBase64( tmp ) )
                      }}
                      selectedSearchColumn={ [] }
                      normalSearchValue={ '' } />
                  </Col>
                  <Col md={ 5 }>
                    <FilteringImage
                      getFilter={ this.props.getFilter }
                      selectedImages={ this.props.selectedImages }
                      handleMode={ this.handleMode }
                      mediaTags={ this.props.mediaTags }
                      getMedia={ this.props.getMedia }
                      handleSecurityModulesUpdate={ this.handleSecurityModulesUpdate }
                      handleSecurityModulesDelete={ this.handleSecurityModulesDelete } />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <UploadImage
                  onUploadFiles={ this.onUploadFiles }
                  postMedia={ this.props.postMedia }
                  mediaTags={ this.props.mediaTags }
                  uploadItem={ this.state.uploadItem }
                  onAddItem={ () => this.onAddItem() }
                  handleChangeImageName={ this.handleChangeImageName }
                  handleSelectTag={ this.handleSelectTag }
                  selectedLanguage={ this.state.selectedLanguage }
                  updateSelectedLanguage={ this.updateSelectedLanguage }
                  onRemoveItem={ this.onRemoveItem }
                  updateType={ this.updateType }
                />
              </TabPane>
              <TabPane tabId="3">
                <MediaTags { ...this.props } />
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
        {
          this.state.mode === 'edit' && (
            <EditImages
              mode={ this.state.mode }
              onClose={ () => this.setState({ mode: '' }) }
              selectedImages={ this.props.selectedImages }
              onChangeData={ this.props.onChangeData }
              updateMedia={ this.props.updateMedia }
              mediaTags={ this.props.mediaTags }
              onLoadMedia={ this.props.onLoadMedia } />
          )
        }
        <DeleteConfirmationDialog
          deleteOpen={ this.state.mode === 'delete' }
          handleModalClose={ () => this.setState({ mode: '' }) }
          confirmAction={ () => {
            let tmpImages = _.cloneDeep( this.props.selectedImages )
            this.props.deleteMedia({ ids: tmpImages.map( img => img.id ) })
            this.setState({ mode: '' })
          }}
          content={ Lang[ 'ARE_YOU_SURE_DELETE_SELECTED_IMAGE' ][ this.props.data.languageReducer.lang ] }
          lang={ this.props.data.languageReducer.lang } />
        {
          ( this.props.data.ajaxReducer.ajaxCallProgress > 0 || this.props.onLoadMedia || this.props.onLoadMediaTags ) && <LoadingOverlay />
        }
      </>
    )
  }
}
export default compose(
  MediaDBHOC
)( MediaDB )
