import React, { Component } from 'react'
import PropagateLoader from "react-spinners/PropagateLoader"
import _ from 'lodash'
import Lang from 'Lang/General'

import SectionHOC from './actions'

import CoreRenderFunction from '../../core/render'

class Section extends Component {

  componentDidMount = () => {
    if( this.props.selectedPage?.isLandingPage ){
      this.props.getSelectedLandingPageSection( this.props.selectedPage.id, this.props.id )
    } else {
      this.props.getSelectedSection( this.props.id )
    }
  }

  onModeEditSelectedSection = ( mode, id ) => {
    let tmp = ''
    if( mode === 'section-display-edit' && 
        (( this.props.selectedSectionID && 
        this.props.selectedSectionID === id ) ||
        ( this.props.hoveredSectionID &&
        this.props.hoveredSectionID === id ))) {
      tmp = '5px solid #545cd8'
    } else {
      tmp = ''
    }
    return tmp
  }

  render = () => {
    let {
      id,
      mode,
      variant,
      updateMainContainerState,
      selectedSectionID,
      languages,
      selectedLanguageShortname,
      onLoadSection,
      selectedSection,
      selectedPage,
      hoveredSectionID,
      selectedEditableComponent, // from section editor
      onChangeSectionEditorState
    } = this.props
    return (
      <div
      id={ id }
      style={{
        border: this.onModeEditSelectedSection( mode, id ),
        opacity: mode && mode === 'section-display-edit' && selectedSection.schema ? 0.5 : 1
      }}
      onMouseEnter={() => {
        if( mode && mode === 'section-display-edit' ) {
          updateMainContainerState( 'hoveredSectionID', id )
        }
      }}
      onMouseLeave={() => {
        if( mode && mode === 'section-display-edit' ) {
          updateMainContainerState( 'hoveredSectionID', '' )
        }
      }}
      onClick={() => {
        if( mode && mode === 'section-display-edit' && selectedSectionID !== id ) {
          updateMainContainerState( 'selectedSectionID', id )
          updateMainContainerState( 'selectedSectionVariant', variant )
        } else if ( mode && mode === 'section-display-edit' ) {
          updateMainContainerState( 'selectedSectionID', '' )
        }
      }}>
        {
          mode && mode === 'section-display-edit' && selectedSection.schema && 
            <div
              style={{ 
                padding: "8px 5px",
                position: "absolute",
                top: "0",
                right: "0",
                writingMode: "vertical-rl", 
                textOrientation: "upright",
                fontWeight: "bold",
                background: "#f0ad4e",
                zIndex: "99" }}>
                { Lang[ 'SMART_SECTION' ][ selectedLanguageShortname ] }
            </div>
        }
        {
          onLoadSection && (
            <div
              style={{
                height: '20vh',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <PropagateLoader size={ 20 } />
            </div>
          )
        }
        {
          selectedSection && !_.isEmpty( selectedSection ) && (
            <CoreRenderFunction
              item={ selectedSection }
              updateMainContainerState={ updateMainContainerState }
              selectedSectionID={ selectedSectionID }
              languages={ languages }
              selectedLanguageShortname={ selectedLanguageShortname } 
              selectedEditableComponent={ selectedEditableComponent } // from section editor
              onChangeSectionEditorState={ onChangeSectionEditorState }
              selectedPage={ selectedPage }
              hoveredSectionID={ hoveredSectionID }
              mode={ mode }
            />
          )
        }
      </div>
    )
  }
}

export default SectionHOC( Section )