import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Get, Post, outdatedToken, Put } from 'utils/axios'
import { getRefreshToken } from 'actions/token'
import StatusModal from 'components/Modal/status'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const OrderHOC = ( WrappedComponent ) => {
  class OrderWrappedComponent extends Component {
    state={
      expandedActivated: [],
      expandedNonActivated: [],
      newOrderData: {
        center_id: 0,
        aciso_order_num: '',
        start_date: new Date(),
        remark: '',
        activated_modules: [],
        modules: [],
        package_id: null,
        status: 'ACTIVE',
        is_show_cookie_banner: true,
        is_show_content_overlay: true,
        is_show_data_protection_agreement: true
      },
      showViewOrderModal: false,
      showNewOrderModal: false,
      showUpdateOrderModal: false,
      selectedOrder: {
        center_id: '',
        center: { name: '' },
        aciso_order_num: '',
        start_date: '',
        remark: '',
        activated_modules: [],
        modules: [],
        package_id: 0,
        logs: [],
        status: 'ACTIVE'
      },
      errorMessage: {},
      loading: false,
      onLoadCreateOrder: false,
      showStatusModal: false,
      statusModalType: '',
      orders: {},
      activatedModules: [],
      nonActivatedModules: [],
      orderTotalPages: []
    }

    handleChange = ( key, panel ) => {
      const prevState = this.state[key]
      const state = prevState.map((x, index) => panel === index ? !x : false);
      this.setState({
        [key]: state
      })
    }
    onChangeOrderHOC = ( key, value ) => this.setState({ [key]: value })

    onChangeNewOrderData = ( key, val ) => {
      let tmp = _.cloneDeep( this.state.newOrderData )
      tmp[ key ] = val
      return this.setState({ newOrderData: tmp })
    }

    onSelectCenter = ( key, value ) => {
      let tmpOrderData = this.state.newOrderData
      tmpOrderData[ key ] = value
      let tmp = {
        ...tmpOrderData,
        center_id: value
      }
      return this.setState({ newOrderData: tmp })
    }

    load = param => this.setState({ loading: param })
    loadCreateOrder = param => this.setState({ onLoadCreateOrder: param })
    requestError = error => this.setState({
      showStatusModal: true,
      statusModalType: 'negative',
      onClickStatusModalButton: () => this.setState({ showStatusModal: false }),
      statusModalMessage: error
    })
    requestSuccess = success => this.setState({
      showStatusModal: true,
      statusModalType: 'positive',
      onClickStatusModalButton: () => this.setState({
        showStatusModal: false,
        showNewOrderModal: false,
        newOrderData: {
          center_id: '',
          aciso_order_num: '',
          start_date: new Date(),
          remark: '',
          activated_modules: [],
          modules: [],
          package_id: 0,
          status: 'ACTIVE',
          is_show_cookie_banner: true,
          is_show_content_overlay: true,
          is_show_data_protection_agreement: true
        },
        errorMessage: {}
      }),
      statusModalMessage: success
    })

    ajaxError = ( error ) => {
      outdatedToken( error, this.props.history )
      this.requestError( error )
    }

    getOrders = query => {
      Get(
        `/api/v1/orders?query=${ convertObjToBase64( query ) }`,
        this.getOrdersSuccess,
        this.getOrdersError,
        this.load
      )
    }
    getOrdersSuccess = payload => {
      let tmp = []
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        payload.data.map( item => {
          tmp.push({
            ...item
          })
        })
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
        tmp = {
          data: tmp,
          meta: payload.meta
        }
      }
      return this.setState({ orders: tmp, orderTotalPages: tmpTotalPages })
    }
    getOrdersError = error => this.ajaxError( error )

    postOrder = data => Post(
      `/api/v1/orders`,
      data,
      this.postOrderSuccess,
      this.postOrderError,
      this.loadCreateOrder
    )
    postOrderSuccess = () => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.setState({
        newOrderData: {
          center_id: 0,
          aciso_order_num: '',
          start_date: new Date(),
          remark: '',
          activated_modules: [],
          modules: [],
          package_id: 0,
          status: 'ACTIVE'
        },
        showNewOrderModal: false
      })
      this.requestSuccess( Lang[ 'ORDER_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getOrders( tmp )
    }
    postOrderError = error => {
      this.setState({ errorMessage: error })
      this.requestError( Lang[ 'ORDER_CREATE_UNSUCCESS' ][ this.props.data.languageReducer.lang ] )
    }

    updateOrder = data => Put(
      `/api/v1/orders/${ data.id }`,
      data,
      this.updateOrderSuccess,
      this.updateOrderError,
      this.load
    )
    updateOrderSuccess = () => {
      this.requestSuccess( Lang[ 'ORDER_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getOrders({
        page: 1,
        is_paginated: true
      })
    }
    updateOrderError = error => {
      this.setState({ errorMessage: error })
    }

    getSelectedOrder = ( id, type ) =>  Get(
      `/api/v1/orders/${ id }`,
      ( payload ) => this.getSelectedOrderSuccess( payload, type ),
      this.getSelectedOrderError,
      this.load
    )
    getSelectedOrderSuccess = ( payload, type ) => {
      let tmpOrders = {
        ...payload,
        aciso_order_num: payload.aciso_order_num,
        center_name: payload.center.center_name,
        consultant_name: `${ payload.center.consultant.user.first_name } ${ payload.center.consultant.user.last_name }`,
        consultant_id: payload.center.consultant.id,
        activated_modules: type === 'view' ? `${ payload.activated_modules }` : payload.activated_modules,
        client_name: payload.center.client.user.first_name 
          ? `${ payload.center.client.user.first_name } ${ payload.center.client.user.last_name }`
          : '',
      }
      
      if( type === 'view' ){ 
        this.setState({ 
          showViewOrderModal: true,
          selectedOrder: tmpOrders
        })
        this.props.getSiteSettings( payload.center.site_id )
      } else {
        this.setState({ selectedOrder: {
          ...payload, logs: _.reverse( payload.logs )
        }, showUpdateOrderModal: true })
      }
    }
    getSelectedOrderError = error => this.ajaxError( error )

    getOrdersSearch = ( query, type, searchParams, searchVal ) => {
      if( type === 'Normal Search' ){
        query = {
          ...query,
          filter: {
            aciso_order_num: {
              $like: `%${ searchVal.toLowerCase() }%`
            },
          }
        }
      }
      if( type === 'Advanced Search' ){
        let tmpOrder = {}
        let tmpCenter = {}
        let tmpClient = {}
        let tmpUser = {}
        searchParams.map( item => {
          if( item.val === 'aciso_order_num' ){
            tmpOrder[ item.val ] = {
              $like: `%${ item.searchValue.toLowerCase() }%`,
            }
          }
          if( item.val === 'center_name' ){
            tmpCenter[ item.val ] = {
              $like: `%${ item.searchValue.toLowerCase() }%`
            }
          }
          if( item.val === 'client_name'){
            tmpClient[ 'client_name' ] = {
              $like: `%${ item.searchValue.toLowerCase() }%`
            }
          }
          if( item.val === 'consultant_name'){
            tmpUser[ 'consultant_name' ] = {
              $like: `%${ item.searchValue.toLowerCase() }%`
            }
          }
        })
        query = {
          ...query,
          filter: {
            ...tmpOrder,
            ...tmpCenter,
            ...tmpClient,
            ...tmpUser
          }
        }
      }
      Get(
        `/api/v1/orders?query=${ convertObjToBase64( query ) }`,
        this.getOrdersSearchSuccess,
        this.getOrdersSearchError,
        this.load
      )
    }
    getOrdersSearchSuccess = payload => {
      let tmpOrders = []
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ){
        payload.data.map( item => {
          tmpOrders.push({
            id: item.id,
            aciso_order_num: item.aciso_order_num,
            center_name: item.center_name,
            client_name: item.client_name,
            consultant_name: item.consultant_name
          })
        })
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      } else {
        this.ajaxError( Lang[ 'NO_RESULT_FOUND' ][ this.props.data.languageReducer.lang ] )
      }
      let tmp = {
        data: tmpOrders,
        meta: payload.meta
      }
      return this.setState({ orders: tmp, orderTotalPages: tmpTotalPages })
    }
    getOrdersSearchError = error => this.ajaxError( error )

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            { ...this.props }
            newOrderData={ this.state.newOrderData }
            expandedActivated={ this.state.expandedActivated }
            expandedNonActivated={ this.state.expandedNonActivated }
            handleChange={ this.handleChange }
            onChange={ this.onChangeNewOrderData }
            showNewOrderModal={ this.state.showNewOrderModal }
            showViewOrderModal={ this.state.showViewOrderModal }
            showUpdateOrderModal={ this.state.showUpdateOrderModal }
            selectedOrder={ this.state.selectedOrder }
            getOrders={ this.getOrders }
            getSelectedOrder={ this.getSelectedOrder }
            orderErrorMessage={ this.state.errorMessage }
            orders={ this.state.orders }
            onLoadOrders={ this.state.loading }
            onLoadCreateOrder={ this.state.onLoadCreateOrder }
            postOrder={ this.postOrder }
            getOrdersSearch={ this.getOrdersSearch }
            onSelectCenter={ this.onSelectCenter }
            activatedModules={ this.state.activatedModules }
            nonActivatedModules={ this.state.nonActivatedModules }
            orderTotalPages={ this.state.orderTotalPages }
            onChangeOrderHOC={ this.onChangeOrderHOC }
            updateOrder={ this.updateOrder }
            errorMessage={ this.state.errorMessage } />
          <StatusModal
            isOpen={ this.state.showStatusModal }
            type={ this.state.statusModalType }
            message={ this.state.statusModalMessage }
            onClick={ this.state.onClickStatusModalButton } />
        </Fragment>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    getRefreshToken
  })( OrderWrappedComponent )
}

export default OrderHOC
