import React, { Component } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { Get, Post, Put, Delete } from 'utils/axios'

import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showStatusModal: false,
      statusModalType: '',
      onClickStatusModalButton: () => {},
      statusModalMessage: '',
      showUpdateDomainModal: false,
      showConfirmDeleteDomainModal: false,
      selectedCenterDomains: {
        center_domains: []
      },
      toRemoveDomain: ''
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeDomainHOC = ( key, val ) => this.setState({ [ key ]: val })

    getCenterDomains = ( center_id ) => Get(
      `/api/v1/centers/${ center_id }/domains`,
      this.getCenterDomainsSuccess,
      this.getCenterDomainsError,
      this.load
    )
    getCenterDomainsSuccess = payload => this.setState({ selectedCenterDomains: {
      ...payload,
      center_domains: _.reverse( payload.center_domains )
    }, showUpdateDomainModal: true })
    getCenterDomainsError = error => this.requestError( error )

    createCenterDomain = data => Post(
      `/api/v1/centers/${ this.state.selectedCenterDomains.center_id }/domains`,
      data,
      this.createCenterDomainSuccess,
      this.createCenterDomainError,
      this.load
    )
    createCenterDomainSuccess = payload => {
      if( payload.has_config_issue ){
        this.requestError( Lang[ 'FAILED_TO_GENERATE_NGINX_CONFIG' ][ this.props.data.languageReducer.lang ] )
      } else {
        this.requestSuccess( Lang[ 'CENTER_DOMAIN_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      }
      this.getCenterDomains( this.state.selectedCenterDomains.center_id )
    }
    createCenterDomainError = error => this.requestError( Lang[ 'ERROR_HAPPENED' ][ this.props.data.languageReducer.lang ] )

    updateCenterDomain = ( data ) => Put(
      `/api/v1/centers/${ this.state.selectedCenterDomains.center_id }/domains/${ data.id }`,
      data,
      payload => this.updateCenterDomainSuccess( payload, data ),
      this.updateCenterDomainError,
      this.load
    )
    updateCenterDomainSuccess = async( payload, data ) => {
      if( payload.has_config_issue ){
        this.requestError( Lang[ 'FAILED_TO_GENERATE_NGINX_CONFIG' ][ this.props.data.languageReducer.lang ] )
      } else {
        this.requestSuccess( Lang[ 'CENTER_DOMAIN_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      }
      await this.getCenterDomains( this.state.selectedCenterDomains.center_id )
      let tmpSelectedCenterDomains = _.cloneDeep( this.state.selectedCenterDomains )
      let tmpIndex = _.findIndex( tmpSelectedCenterDomains.center_domains, { id: data.id } )
      if( tmpIndex > -1 ){
        tmpSelectedCenterDomains.center_domains[ tmpIndex ][ 'is_test_domain_needed' ] = data[ 'is_test_domain_needed' ]
      }
      this.setState({ selectedCenterDomains: tmpSelectedCenterDomains })
    }
    updateCenterDomainError = error => this.requestError( Lang[ 'ERROR_HAPPENED' ][ this.props.data.languageReducer.lang ] )

    removeCenterDomain = ( id ) => Delete(
      `/api/v1/centers/${ this.state.selectedCenterDomains.center_id }/domains/${ id }`,
      this.removeCenterDomainSuccess,
      this.removeCenterDomainError,
      this.load
    )
    removeCenterDomainSuccess = () => {
      this.requestSuccess( Lang[ 'CENTER_DOMAIN_REMOVE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getCenterDomains( this.state.selectedCenterDomains.center_id )
    }
    removeCenterDomainError = error => this.requestError( error )

    restartNginxProgress = data => Put(
      `/api/v1/centers/${ this.state.selectedCenterDomains.center_id }/domains/${ data.id }/config`,
      {},
      this.restartNginxProgressSuccess,
      this.restartNginxProgressError,
      this.load
    )
    restartNginxProgressSuccess = () => {
      this.requestSuccess( Lang[ 'NGINX_PROGRESS_COMPLETED' ][ this.props.data.languageReducer.lang ] )
      this.getCenterDomains( this.state.selectedCenterDomains.center_id )
    }
    restartNginxProgressError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onChangeDomainHOC={ this.onChangeDomainHOC }
          getCenterDomains={ this.getCenterDomains }
          createCenterDomain={ this.createCenterDomain }
          updateCenterDomain={ this.updateCenterDomain }
          removeCenterDomain={ this.removeCenterDomain }
          onLoadCenterDomains={ this.state.loading }
          showUpdateDomainModal={ this.state.showUpdateDomainModal }
          selectedCenterDomains={ this.state.selectedCenterDomains }
          showConfirmDeleteDomainModal={ this.state.showConfirmDeleteDomainModal }
          toRemoveDomain={ this.state.toRemoveDomain }
          restartNginxProgress={ this.restartNginxProgress } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC