import React, { useEffect } from 'react'
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button, UncontrolledTooltip,
  Card, CardHeader, CardBody,
  Form, FormGroup,
  Label, Input
} from 'reactstrap'
import { compose } from 'redux'
import _ from 'lodash'

import ARKTable from 'components/Table'
import PageTitle from 'components/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Lang from 'Lang/General'

import CentersTemplatesHOC from './actions'
import ProgramTemplatesHOC from './actions/programTemplates'
import CenterProgramTemplateForm from './Form'

const ProgramTemplates = props => {
  useEffect( () => {
    props.getCenters()
    props.getProgramTemplates()
  }, [] )

  useEffect( () => {
    if( !props.showUpdateCenterTemplates ){
      props.onChangeCentersTemplatesHOC( 'selectedCenter', {} )
    }
  }, [ props.showUpdateCenterTemplates ] )

  const handleSecurityModulesUpdate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Member Area' })
      return tmpModules.is_update
    }
  }

  const renderUpdateCenterProgramTemplatesModal = () => (
    <Modal size='lg' isOpen={ props.showUpdateCenterTemplates } >
      <ModalHeader toggle={ () => props.onChangeCentersTemplatesHOC( 'showUpdateCenterTemplates', false ) } >
        { Lang[ 'UPDATE' ][ props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        {
          !_.isEmpty( props.selectedCenter ) && <CenterProgramTemplateForm
            { ...props }
            lang={ props.data.languageReducer.lang } />
        }
        { props.onLoadCentersTemplatesHOC && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => props.onChangeCentersTemplatesHOC( 'showUpdateCenterTemplates', false ) }>
          { Lang[ 'CLOSE' ][ props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )

  return(
    <>
      <PageTitle
        heading={ Lang[ 'CENTER_PURCHASED_PROGRAM_TEMPLATES' ][ props.data.languageReducer.lang ] }
        icon='pe-7s-users icon-gradient bg-happy-fisher' />
      <Card className="main-card mb-3">
        <CardHeader>{ Lang[ 'SEARCH' ][ props.data.languageReducer.lang ] }</CardHeader>
        <CardBody>
          <Form onSubmit={ e => e.preventDefault() }>
            <FormGroup>
              <Label>{ Lang[ 'CENTER_NAME' ][ props.data.languageReducer.lang ] }</Label>
              <Input
                type="text"
                value={ props.centerSearchParams.center_name }
                onChange={ e => {
                  let tmp = _.cloneDeep( props.centerSearchParams )
                  tmp.center_name = e.target.value
                  props.onChangeCentersTemplatesHOC( 'centerSearchParams', tmp )
                }} />
            </FormGroup>
            <div className='d-flex flex-row flex-wrap justify-content-end' >
              <Button
                color="primary"
                className="mt-1"
                onClick={ () => props.getCenters()}>
                { Lang[ 'SEARCH' ][ props.data.languageReducer.lang ] }</Button>
              <Button
                color="danger"
                className="mt-1 mr-2"
                onClick={ () => props.getCenters( 1, true )}>
                { Lang[ 'RESET' ][ props.data.languageReducer.lang ] }</Button>
            </div>
          </Form>
        </CardBody>
      </Card>
      <ARKTable
        data={ props.centers.data }
        columns={[
          {
            Header: Lang[ 'CENTER_NAME' ][ props.data.languageReducer.lang ],
            accessor: "center_name"
          },
          {
            Header: Lang[ 'NAME' ][ props.data.languageReducer.lang ],
            accessor: "fullname"
          },
          {
            Header: Lang[ 'ACTIONS' ][ props.data.languageReducer.lang ],
            accessor: 'id',
            Cell: row => (
              <div className='d-flex justify-content-center' >
                {
                  handleSecurityModulesUpdate() && (
                    <>
                      <Button
                        id={ `ProgramTemplate-${ row.original.id }-update` }
                        className='mb-2 mr-2 btn-icon btn-icon-only'
                        color='primary'
                        onClick={ () => props.getSelectedCenterProgramTemplates( row.original ) }>
                        <i className='pe-7s-note'> </i>
                      </Button>
                      <UncontrolledTooltip
                        target={ `ProgramTemplate-${ row.original.id }-update` }
                        placement='top'>{ Lang[ 'EDIT' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                    </>
                  )
                }
              </div>
            )
          }
        ]}
        totalPages={ props.centersPages }
        page={ props.centers.meta ? props.centers.meta.current_page : 1 }
        showPagination={ false }
        onChangePage={ pg => props.getCenters( pg ) }
      />
      { renderUpdateCenterProgramTemplatesModal() }
      { ( props.onLoadCentersTemplatesHOC || props.onLoadProgramTemplatesHOC ) && <LoadingOverlay/> }
    </>
  )
}

export default compose(
  CentersTemplatesHOC,
  ProgramTemplatesHOC
)( ProgramTemplates )