import React, { useState, useEffect } from 'react'
import {
  Form, FormGroup, FormFeedback, Label, Input, Button
} from 'reactstrap'
import { connect } from 'react-redux'
import Moment from 'moment'
import DatePicker, { registerLocale } from 'react-datepicker'
import el from "date-fns/locale/el"
import de from "date-fns/locale/de"
import nl from "date-fns/locale/nl"
import it from "date-fns/locale/it"
import _ from 'lodash'

import DynamicForm from 'containers/OLS/CampaignListings/Form'
import Lang from 'Lang/General'
import LangTepe from 'Lang/TePe'

const BookedCampaignForm = props => {
  let {
    selectedBookedCampaign,
    selectedBookedCampaignError,
    onChangeBookedCampaignHOC,
    updateBookedCampaign,
    languageReducer
  } = props
  const [ bookableMonths, setBookableMonths ] = useState( [] )
  const [ currentOlsCampaign, setCurrentOlsCampaign ] = useState( {} )
  useEffect( () => {
    registerLocale( "el", el )
    registerLocale( "de", de )
    registerLocale( "nl", nl )
    registerLocale( "it", it )
    Moment.locale( 'en' )
    setCurrentOlsCampaign( {} )
  }, [] )

  useEffect( () => {
    if( !_.isEmpty( selectedBookedCampaign.campaign ) ){
      setBookableMonths(
        selectedBookedCampaign.campaign.bookable_months.map( month => Moment( month, 'MMMM' ).month() )
      )
    }
  }, [ selectedBookedCampaign.campaign.id ] )

  useEffect( () => {
    if( _.isEmpty( currentOlsCampaign ) && !_.isEmpty( selectedBookedCampaign.booked_campaign ) ){
      setCurrentOlsCampaign( selectedBookedCampaign.booked_campaign )
    }
  }, [ selectedBookedCampaign.booked_campaign.id ] )

  const onChange = ( key, value ) => {
    let tmp = _.cloneDeep( selectedBookedCampaign.booked_campaign )
    tmp[key] = value
    return onChangeBookedCampaignHOC( 'selectedBookedCampaign', {
      ...selectedBookedCampaign,
      booked_campaign: tmp
    })
  }

  const status = [
    {
      label: LangTepe[ 'NEW' ][ languageReducer.lang ],
      value: 'NEW'
    },
    {
      label: LangTepe[ 'IN_PROGRESS' ][ languageReducer.lang ],
      value: 'IN_PROGRESS'
    },
    {
      label: LangTepe[ 'ACTIVE' ][ languageReducer.lang ],
      value: 'ACTIVE'
    },
    {
      label: LangTepe[ 'COMPLETED' ][ languageReducer.lang ],
      value: 'COMPLETED'
    }
  ]

  const fbStatus = [
    {
      label: LangTepe[ 'NEW' ][ languageReducer.lang ],
      value: 'NEW'
    },
    {
      label: LangTepe[ 'IN_PROGRESS' ][ languageReducer.lang ],
      value: 'IN_PROGRESS'
    },
    {
      label: LangTepe[ 'QM_COMPLETED' ][ languageReducer.lang ],
      value: 'QM_COMPLETED'
    },
    {
      label: LangTepe[ 'ACTIVE' ][ languageReducer.lang ],
      value: 'ACTIVE'
    },
    {
      label: LangTepe[ 'COMPLETED' ][ languageReducer.lang ],
      value: 'COMPLETED'
    }
  ]

  const googleStatus = [
    {
      label: LangTepe[ 'ACTIVE' ][ languageReducer.lang ],
      value: 'ACTIVE'
    },
    {
      label: LangTepe[ 'INACTIVE' ][ languageReducer.lang ],
      value: 'INACTIVE'
    },
    {
      label: LangTepe[ 'IN_PROGRESS' ][ languageReducer.lang ],
      value: 'IN_PROGRESS'
    },
  ]

  return(
    <div className="p-3 mt-3 pb-5 border border-dark">
      <Label className="h5">{ window.location.host.indexOf( 'tennis' ) > -1 ? LangTepe[ 'CURRENT_BOOKED_CAMPAIGN' ][ languageReducer.lang ] : Lang[ 'CURRENT_BOOKED_CAMPAIGN' ][ languageReducer.lang ] }</Label>
      <Form>
        <FormGroup>
          <Label>{ window.location.host.indexOf( 'tennis' ) > -1 ? LangTepe[ 'CAMPAIGN' ][ languageReducer.lang ] : Lang[ 'CAMPAIGN' ][ languageReducer.lang ] }</Label>
          <Input
            disabled
            type="text"
            value={ selectedBookedCampaign.campaign.title }
          />
        </FormGroup>
        <FormGroup>
          <Label>{ window.location.host.indexOf( 'tennis' ) > -1 ? LangTepe[ 'STATUS' ][ languageReducer.lang ] : Lang[ 'STATUS' ][ languageReducer.lang ] }</Label>
          <Input
            type="select"
            value={ selectedBookedCampaign.booked_campaign.status }
            onChange={ e => onChange( 'status', e.target.value ) }
            invalid={ false }>
            {
              status.map( item => {
                return(
                  <option key={ item.value } value={ item.value }>{ item.label }</option>
                )
              })
            }
          </Input>
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'FB_STATUS' ][ languageReducer.lang ] }</Label>
          <Input
            type="select"
            value={ selectedBookedCampaign.booked_campaign.fb_status }
            onChange={ e => onChange( 'fb_status', e.target.value ) }
            invalid={ false }>
            {
              fbStatus.map( item => {
                return(
                  <option key={ item.value } value={ item.value }>{ item.label }</option>
                )
              })
            }
          </Input>
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'GOOGLE_STATUS' ][ languageReducer.lang ] }</Label>
          <Input
            type="select"
            value={ selectedBookedCampaign.booked_campaign.google_status }
            onChange={ e => onChange( 'google_status', e.target.value ) }
            invalid={ false }>
            {
              googleStatus.map( item => {
                return(
                  <option key={ item.value } value={ item.value }>{ item.label }</option>
                )
              })
            }
          </Input>
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'START_DATE' ][ languageReducer.lang ] }</Label>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            className="form-control"
            selected={ selectedBookedCampaign.booked_campaign.start_date }
            popperModifiers={{
              flip: { behavior: [ 'bottom' ] },
              preventOverflow: { enabled: false },
              hide: { enabled: false }
            }}
            minDate={ new Date() }
            filterDate={ date => bookableMonths.length > 0
              ? bookableMonths.indexOf( date.getMonth() ) > -1
              : true
            }
            onChange={ e => onChangeBookedCampaignHOC( 
              'selectedBookedCampaign',
              {
                ...selectedBookedCampaign,
                booked_campaign: {
                  ...selectedBookedCampaign.booked_campaign,
                  start_date: e,
                  end_date: _.cloneDeep( e ).setDate( e.getDate() + 56 )
                }
              }
            )}
          />
          { 
            (
              !selectedBookedCampaign.campaign.is_app_campaign &&
              !_.isEmpty( selectedBookedCampaign.campaign?.start_date_hint?.[ languageReducer.lang ] )
            ) && (
              <Label>{ selectedBookedCampaign.campaign.start_date_hint[ languageReducer.lang ] }</Label> 
            )
          }
        </FormGroup>
        {
          selectedBookedCampaign.campaign.is_app_campaign &&
            <FormGroup>
              <Label>{ Lang[ 'END_DATE' ][ languageReducer.lang ] }</Label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                className="form-control"
                selected={ selectedBookedCampaign.booked_campaign.end_date }
                popperModifiers={{
                  flip: { behavior: [ 'bottom' ] },
                  preventOverflow: { enabled: false },
                  hide: { enabled: false }
                }}
                minDate={ 
                  selectedBookedCampaign.booked_campaign.start_date
                    ? ( new Date( selectedBookedCampaign.booked_campaign.start_date ) ).setDate( selectedBookedCampaign.booked_campaign.start_date.getDate() + 1 )
                    : null 
                }
                onChange={ e => onChange( 'end_date', e ) }
              />
            </FormGroup>
        }
        {
          !selectedBookedCampaign.campaign.is_app_campaign && <>
            <FormGroup>
              <Label>{ Lang[ 'FACEBOOK_BUDGET' ][ languageReducer.lang ] }</Label>
              <Input
                step='1'
                type='number'
                onChange={ e => onChange( 'fa_budget', e.target.value ) }
                value={ selectedBookedCampaign.booked_campaign.fa_budget }
                invalid={ false }/>
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'GA_BUDGET' ][ languageReducer.lang ] }</Label>
              <Input
                step='1'
                type='number'
                onChange={ e => onChange( 'ga_budget', e.target.value ) }
                value={ selectedBookedCampaign.booked_campaign.ga_budget }
                invalid={ false }/>
            </FormGroup>
          </>
        }
        {
          !_.isEmpty( selectedBookedCampaign.campaign?.remark_label ) && ( 
            <FormGroup>
              <Label>{ selectedBookedCampaign.campaign.remark_label?.[ languageReducer.lang ] }</Label>
              <Input
                type="text"
                onChange={ e => onChange( 'remark', e.target.value ) }
                value={ selectedBookedCampaign.booked_campaign.remark }
                invalid={ selectedBookedCampaignError.remark }
              />
              <FormFeedback>{ selectedBookedCampaignError.remark }</FormFeedback>
            </FormGroup>
          )
        }
        <FormGroup>
          <Label>{ Lang[ 'INTERNAL_NOTE' ][ languageReducer.lang ] }</Label>
          <Input
            type="text"
            onChange={ e => onChange( 'internal_remark', e.target.value ) }
            value={ selectedBookedCampaign.booked_campaign.internal_remark }/>
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'AD_REMARK' ][ languageReducer.lang ] }</Label>
          <Input
            type="text"
            onChange={ e => onChange( 'ad_remark', e.target.value ) }
            value={ selectedBookedCampaign.booked_campaign.ad_remark  }/>
        </FormGroup>
        {
          selectedBookedCampaign.campaign.is_app_campaign && (
            <FormGroup>
              <Label>{ Lang[ 'PRIORITY' ][ languageReducer.lang ] }</Label>
              <Input
                step='1'
                type='number'
                onChange={ e => onChange( 'priority', e.target.value ) }
                value={ selectedBookedCampaign.booked_campaign.priority  }/>
            </FormGroup>
          )
        }
        <FormGroup>
          <Label>{ Lang[ 'LANDING_PAGE_COMMENT' ][ languageReducer.lang ] }</Label>
          <Input
            type="text"
            onChange={ e => onChange( 'landing_page_comment', e.target.value ) }
            value={ selectedBookedCampaign.booked_campaign.landing_page_comment }/>
        </FormGroup>
        {
          currentOlsCampaign?.content?.length > 0 && (
            <DynamicForm
              { ...props }
              mode='fill'
              olsCampaignType='selectedBookedCampaign'
              currentOlsCampaign={ currentOlsCampaign }
              data={{ languageReducer: languageReducer.lang }}
              onChangeOlsCampaign={ ( olsCampaignType, latestOlsCampaign ) => setCurrentOlsCampaign( latestOlsCampaign ) }
            />
          )
        }
      </Form>
      <Button 
        className="btn float-right" 
        color="primary" 
        onClick={ () => {
          let tmp =_.cloneDeep( selectedBookedCampaign.booked_campaign )
          let tmpFormValues = !_.isEmpty( currentOlsCampaign.values ) 
            ? _.cloneDeep( currentOlsCampaign.values )
            : []
          tmpFormValues.map( ( item, index ) => {
            if( item.type === 'inputFile' ){
              tmpFormValues[index].value = props.uploadedMedia.filter( x => x.formContentId === item.id )
            } else if ( item.type === 'textarea' ) {
              item.value = item.value.trim()
            } else if ( item.type === 'dateField' && item.value === 'Invalid date') {
              item.value = ''
            }
          })
          tmp.values = tmpFormValues
          tmp.submitted_form_content = _.cloneDeep( currentOlsCampaign.content )
          tmp.ga_budget = parseInt( tmp.ga_budget ) || 0
          tmp.fa_budget = parseInt( tmp.fa_budget ) || 0
          tmp.priority = parseInt( tmp.priority ) || 0
          tmp.start_date = tmp.start_date ? Moment( tmp.start_date ).format() : null
          tmp.end_date = tmp.end_date ? Moment( tmp.end_date ).format() : null
          updateBookedCampaign( selectedBookedCampaign.booked_campaign.id, tmp ) 
        }}>
        { Lang[ 'SUBMIT' ][ languageReducer.lang ] }</Button>
    </div>
  )
}
const mapStateToProps = state => ({
  languageReducer: state.languageReducer
})

export default connect( mapStateToProps )( BookedCampaignForm )
