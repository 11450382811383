import React, { Component } from 'react'
import { SketchPicker } from 'react-color'
import Lang from 'Lang/General'

class FooterStyling extends Component {
  state = {
    selectedColorHex: "#000000"
  }

  renderColorEditButton = param => {
    return(
      <button 
        className="btn btn-toggle" 
        style={{ 
          color: param.buttonColor === "#000000"? "#ffffff": "#000000",
          background: param.buttonColor ,
          border: "1px solid #d4d4d4" }} 
        onClick={ () => param.onChangeColor( this.state.selectedColorHex ) }>
        { param.buttonText }</button>
    )
  }

  render = () => {
    return(
        <div className="container" style={{ padding: "0" }}>
          <div className="row">
            <div className="col-md-3" style={{ paddingTop: "30px" }}>
              <SketchPicker
                width="90%"
                color={ this.state.selectedColorHex }
                onChange={ col => this.setState({ selectedColorHex: col.hex }) }/>
            </div>
            <div className="col-md-9" style={{ paddingTop: "30px" }}>
              <div 
                className="row" 
                style={{ 
                  minHeight: "140px",
                  margin: "0",
                  border: "1px solid #d4d4d4",
                  alignItems: "center" }}>
                <p style={{ width: "100%", textAlign: "center" }}>{ Lang[ 'BODY' ][ this.props.lang ] }</p>
              </div>
              <div 
                className="row" 
                style={{
                  margin: "0",
                  border: "1px solid #d4d4d4",
                  borderTop: "0",
                  background: this.props.footerBackgroundColor }}>
                <p style={{ 
                  textAlign: "left", 
                  width: "50%", 
                  padding: "10px", 
                  color: this.props.footerTextColor }}>
                  { Lang[ 'TEXT' ][ this.props.lang ] }</p>
                <p style={{ 
                  textAlign: "right", 
                  width: "50%", 
                  padding: "10px", 
                  color: this.props.footerTextColor }}>
                  { Lang[ 'TEXT' ][ this.props.lang ] }</p>
                <p style={{ 
                  textAlign: "center",
                  width: "100%",
                  padding: "10px",
                  color: this.props.footerBackgroundColor === "#000000"? "#ffffff": "#000000" }}>
                  { Lang[ 'FOOTER' ][ this.props.lang ] }</p>
                <p style={{ 
                  textAlign: "left", 
                  width: "50%", 
                  padding: "10px", 
                  color: this.props.footerTextColor }}>
                  { Lang[ 'TEXT' ][ this.props.lang ] }</p>
                <p style={{ 
                  textAlign: "right", 
                  width: "50%", 
                  padding: "10px", 
                  color: this.props.footerTextColor }}>
                  { Lang[ 'TEXT' ][ this.props.lang ] }</p>
              </div>
              <div  
                className="row footer-editor-toggle" 
                style={{ 
                  margin: "0",
                  paddingTop: "30px",
                  alignItems: "center" }}>
                <p style={{ margin: "0", paddingRight: "30px" }}>{ Lang[ 'MODIFY_COLOR_OF' ][ this.props.lang ] }</p>
                { 
                  this.renderColorEditButton({
                    buttonColor: this.props.footerBackgroundColor,
                    onChangeColor: () => this.props.onChangeFooterBackgroundColor( this.state.selectedColorHex ),
                    buttonText: Lang[ 'FOOTER_BACKGROUND' ][ this.props.lang ]
                  }) 
                }
                { 
                  this.renderColorEditButton({
                    buttonColor: this.props.footerTextColor,
                    onChangeColor: () => this.props.onChangeFooterTextColor( this.state.selectedColorHex ),
                    buttonText: Lang[ 'FOOTER_TEXT' ][ this.props.lang ]
                  }) 
                }
              </div>
            </div>
          </div>
        </div>
    )
  }
}

export default FooterStyling