import React, { Component } from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Tooltip,
  Label,
  Input,
  FormGroup,
  Container,
  Row,
  Col,
  FormFeedback
} from 'reactstrap'
import _ from 'lodash'
import { compose } from 'redux'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PageTitle from 'components/PageTitle'

import WithSystemEmails from './actions'
import CreateForm from './Create'
import Lang from 'Lang/General'

class SystemEmailsListing extends Component {
  state = {
    emailErrorMessage: []
  }
  componentDidMount = () => {
    this.props.getSystemEmails()
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'OLS' })
      return tmpModules.is_create
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'OLS' })
      return tmpModules.is_update
    }
  }

  handleSecurityModulesDelete = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'OLS' })
      return tmpModules.is_delete
    }
  }

  render = () => {
    return (
      <>
        <PageTitle
          heading={ Lang[ 'OLS_MODULE_SETTING' ][ this.props.data.languageReducer.lang ] }
          icon="pe-7s-mail icon-gradient bg-happy-fisher"
          buttons={[
            {
              buttonText: Lang[ 'ADD_EMAIL' ][ this.props.data.languageReducer.lang ],
              onClick: () => this.props.onChangeSytemEmailHOC( 'showCreate', true ),
              display: this.handleSecurityModulesCreate()
            }
          ]} />
        <Card>
          <CardBody>
            {
              this.props.systemEmails.map( (item, index) => {
                return(
                  <Row className="mt-4" key={ item.id }>
                    <Col md={ 4 }>
                      <Label>{ Lang[ 'EMAIL' ][ this.props.data.languageReducer.lang ] }</Label>
                      <Input
                        type='email'
                        value={ item.email }
                        onChange={ e => {
                          let tmp = _.cloneDeep( this.props.systemEmails )
                          let tmpIndex = _.findIndex( tmp, { id: item.id })
                          let tmpMessage = []
                          if( index > -1 ) {
                            let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i )
                            if( !pattern.test( e.target.value ) ){
                              for( let i = 0; i < tmp.length; i++ ){
                                tmpMessage.push( '' )
                              }
                              tmpMessage.splice(tmpIndex, 1, "Please enter valid email address." )
                              this.setState({ emailErrorMessage: tmpMessage })
                            } else {
                              tmpMessage.splice(0, tmpIndex, "" )
                              this.setState({ emailErrorMessage: tmpMessage })
                            }
                            tmp[tmpIndex].email = e.target.value
                          }
                          this.props.onChangeSytemEmailHOC( 'systemEmails', tmp )
                        }}
                        invalid={ this.state.emailErrorMessage[_.findIndex( this.props.systemEmails, { id: item.id })] ? true : false }/>
                      <FormFeedback>{ this.state.emailErrorMessage[_.findIndex( this.props.systemEmails, { id: item.id })] ? this.state.emailErrorMessage[_.findIndex( this.props.systemEmails, { id: item.id })] : '' }</FormFeedback>
                    </Col>
                    <Col md={ 4 }>
                      <Label>{ Lang[ 'NAME' ][ this.props.data.languageReducer.lang ] }</Label>
                      <Input
                        type='text'
                        value={ item.name }
                        onChange={ e => {
                          let tmp = _.cloneDeep( this.props.systemEmails )
                          let index = _.findIndex( tmp, { id: item.id })
                          if( index > -1 ) {
                            tmp[index].name = e.target.value
                          }
                          this.props.onChangeSytemEmailHOC( 'systemEmails', tmp )
                        } }/>
                    </Col>
                    <Col md={ 4 } className="d-flex" style={{ alignItems: 'flex-end'}}>
                      {
                        this.handleSecurityModulesUpdate() &&
                          <Button
                            className="btn float-right"
                            color="primary"
                            width={ 10 }
                            onClick={ () => {
                              this.props.updateSystemEmail( item.id, _.find( this.props.systemEmails, { id: item.id } ) )
                            }}>{ Lang[ 'SAVE' ][ this.props.data.languageReducer.lang ] }</Button>
                      }
                      {
                        this.handleSecurityModulesDelete() &&
                          <Button
                            className="btn float-right"
                            color="danger"
                            width={ 10 }
                            onClick={ () => {
                              this.props.deleteSystemEmail( item.id )
                            }}>{ Lang[ 'DELETE' ][ this.props.data.languageReducer.lang ] }</Button>
                      }
                    </Col>
                  </Row>
                )
              })
            }
            <CreateForm
              newEmailData={ this.props.newEmailData }
              onChangeSytemEmailHOC={ this.props.onChangeSytemEmailHOC }
              createSystemEmail={ this.props.createSystemEmail }
              showCreate={ this.props.showCreate }
              languageReducer={ this.props.data.languageReducer }
              errorMessage={ this.props.errorMessage } />
          </CardBody>
        </Card>
        { ( this.props.data.ajaxReducer.ajaxCallProgress > 0 || this.props.onLoadSystemEmail ) && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose(
  WithSystemEmails
)( SystemEmailsListing )
