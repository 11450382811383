import React from 'react'
import { Row, Col } from 'reactstrap'
import _ from 'lodash'

import MultiLangRTE from 'components/RTE/MultiLang'
import Lang from 'Lang/General'

const Texts = ({
  programTemplateType,
  currentProgramTemplate,
  onChangeProgramTemplatesHOC,
  lang,
  programTemplateErrorMessage
}) => {
  const updateProgramTemplate = ( key, val ) => {
    let tmp = _.cloneDeep( currentProgramTemplate )
    tmp[ key ] = val
    onChangeProgramTemplatesHOC( programTemplateType, tmp )
  }

  return(
    <Row>
      <Col md={ 8 } >
        <MultiLangRTE
          label={ Lang[ 'BRIEF_DESCRIPTION' ][ lang ] }
          value={ currentProgramTemplate.brief_description }
          onChange={ val => updateProgramTemplate( 'brief_description', val ) }
          selectedLanguage={ lang } />
        <MultiLangRTE
          label={ Lang[ 'DESCRIPTION' ][ lang ] }
          value={ currentProgramTemplate.description }
          onChange={ val => updateProgramTemplate( 'description', val ) }
          selectedLanguage={ lang }
          invalid={ programTemplateErrorMessage.description } />
        <MultiLangRTE
          label={ Lang[ 'HIGHLIGHT' ][ lang ] }
          value={ currentProgramTemplate.highlight_primary }
          onChange={ val => updateProgramTemplate( 'highlight_primary', val ) }
          selectedLanguage={ lang } />
        <MultiLangRTE
          label={ Lang[ 'SECONDARY_HIGHLIGHT' ][ lang ] }
          value={ currentProgramTemplate.highlight_secondary }
          onChange={ val => updateProgramTemplate( 'highlight_secondary', val ) }
          selectedLanguage={ lang } />
        <MultiLangRTE
          label={ Lang[ 'CONTENT_DESCRIPTION' ][ lang ] }
          value={ currentProgramTemplate.content_description }
          onChange={ val => updateProgramTemplate( 'content_description', val ) }
          selectedLanguage={ lang } />
      </Col>
    </Row>
  )
}

export default Texts