import React, { useState } from 'react'

import TypeSelectors from './components/TypeSelectors'

import Lang from 'Lang/General'

const TypeSelections = [
  {
    id: 1,
    imgSrc: 'https://s3-eu-central-1.amazonaws.com/tennis-assets-production/shared_media_drive/02129bb861061d1a052c592e2dc6b383/original/fl_1.jpeg',
    title: 'Fast-Learning I',
    description: 'Der ideale Kurs für Einsteiger'
  },
  {
    id: 2,
    imgSrc: 'https://s3-eu-central-1.amazonaws.com/tennis-assets-production/shared_media_drive/57cec4137b614c87cb4e24a3d003a3e0/original/fl_2.jpeg',
    title: 'Fast-Learning II',
    description: 'Der ideale Kurs für Ballsportler und Fast-Learner aus Kurs I'
  },
  {
    id: 3,
    imgSrc: 'https://s3-eu-central-1.amazonaws.com/tennis-assets-production/shared_media_drive/21c2e59531c8710156d34a3c30ac81d5/original/fl_3.jpeg',
    title: 'Fast-Learning III',
    description: 'Der ideale Kurs für Wiedereinsteiger und Teilnehmer aus Kurs II'
  },
  {
    id: 4,
    imgSrc: 'https://s3-eu-central-1.amazonaws.com/tennis-assets-production/shared_media_drive/7e6a2afe551e067a75fafacf47a6d981/original/top_play_technik.jpeg',
    title: 'Top-Play Technik',
    description: 'Mehr Power & Geschwindigkeit mit der richtigen Technik'
  },
  {
    id: 7,
    imgSrc: 'https://s3-eu-central-1.amazonaws.com/tennis-assets-production/shared_media_drive/5206560a306a2e085a437fd258eb57ce/original/grupperntraining_classic.jpeg',
    title: 'Grupperntraining Classic',
    description: 'Gruppenkurse für Erwachsene'
  },
  {
    id: 8,
    imgSrc: 'https://s3-eu-central-1.amazonaws.com/tennis-assets-production/shared_media_drive/b14a7b8059d9c055954c92674ce60032/original/trainingstreff.jpeg',
    title: 'Trainingstreff',
    description: 'Neue Spielpartner kennenlernen'
  },
  {
    id: 9,
    imgSrc: 'https://s3-eu-central-1.amazonaws.com/tennis-assets-production/shared_media_drive/815417267f76f6f460a4a61f9db75fdb/original/kinder_jugendliche.jpeg',
    title: 'Kinder & Jugendliche',
    description: 'Gruppenkurse für 4-18 jährige Beginner & Fortgeschrittene'
  },
  {
    id: 10,
    imgSrc: 'https://s3-eu-central-1.amazonaws.com/tennis-assets-production/shared_media_drive/815417267f76f6f460a4a61f9db75fdb/original/kinder_jugendliche.jpeg',
    title: 'Kinder & Jugendliche',
    description: 'Gruppenkurse für 4-18 jährige Beginner & Fortgeschrittene'
  },
  {
    id: 'flk',
    imgSrc: 'https://s3-eu-central-1.amazonaws.com/tennis-assets-production/shared_media_drive/61e9c06ea9a85a5088a499df6458d276/original/fl_day_kampagne.jpeg',
    title: 'Fast-Learning Day Kampagne',
    description: 'Der Schnellstart ins Tennis - ideal für Ein- und Wiedereinsteiger'
  }
]

const TypeColors = type_id => {
  let tmp = '#2a2d51'
  if( type_id === 1 ) {
    tmp = '#ea4d96'
  } else if( type_id === 2 ) {
    tmp = '#00CCD8'
  } else if( type_id === 3 ) {
    tmp = '#B6D800'
  }
  return tmp
}

const TennisShop = props => {
  const [ showTooltip, updateShowTooltip ] = useState( false )
  const [ showPremiumTooltip, updateShowPremiumTooltip ] = useState( false )
  return (
    <div className="container" style={{ paddingTop: '35px', paddingBottom: '35px' }}>
      <h1 style={{ marginBottom: '15px' }}>{ Lang[ 'FIND_TENNIS_EXP' ][ props.selectedLanguageShortname ] }</h1>
      <div className="row">
        {
          TypeSelections.map( item => {
            return (
              <TypeSelectors
                key={ item.id }
                { ...item } />
            )
          })
        }
      </div>
      <hr />
      <div className="row">
        <div className="col-sm-3">
          <div style={ Styles.DateDisplay }>
            <p style={ Styles.mb0 }>02</p>
            <p style={ Styles.mb0 }>May 2021</p>
          </div>
          <img src={ `https://www.tennis-people.com/image/school:575e7dd97df5c.jpg/230/118/8/0` } />
        </div>
        <div className="col-sm-6">
          <h3 style={ Styles.StartDateEndDate }>{ `2021-04-27 - 2021-05-25` }</h3>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h4 style={{ ...Styles.mb0, color: TypeColors( 1 ), fontSize: '18px' }}>FAST-LEARNING I</h4>
            <button 
              style={{ border: 'none', background: 'transparent', marginLeft: '10px' }}
              onClick={() => {
                updateShowTooltip( true )
              }}>
              <i className="fa fa-info-circle" />
            </button>
            {
              showTooltip && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    background: '#f3f4f9',
                    border: `2px solid ${ TypeColors( 1 ) }`,
                    padding: '12px'
                  }}>
                  <div style={{ display: 'flex' }}>
                    <h4 style={{ ...Styles.mb0, color: TypeColors( 1 ), fontSize: '18px' }}>FAST-LEARNING I</h4>
                    <button 
                      style={{ border: 'none', background: 'transparent', marginLeft: 'auto' }}
                      onClick={() => {
                        updateShowTooltip( false )
                      }}>
                      <i className="fa fa-times" />
                    </button>
                  </div>
                  <p style={{ ...Styles.mb0, color: TypeColors( 1 ) }}>{ `Fast-Learning Day` }</p>
                  <span>{ `Fast-Learning I ist der ideale Kurs für Beginner und Neueinsteiger ohne oder mit nur wenig vorheriger Tenniserfahrung. Hier lernst Du in der Gruppe, spielerisch und mit jeder Menge Spaß Tennis.` }</span>
                </div>
              )
            }
          </div>
          <div>
            <span>{ `Fast-Learning Kurs I - 5x 60min in 5 Wochen Kurs, Dienstag, 17:00 bis 18:00 Uhr, 4 Spieler auf 2 Tennisplätzen` }</span>
            <button 
              style={{ 
                ...Styles.TransparentButton, 
                display: 'inline-flex', 
                color: '#2a2d51', 
                fontWeight: '600', 
                alignItems: 'center' 
              }}
              onClick={() => {

              }}>
              Mehr
              <i className="fa fa-plus" />
            </button>
          </div>
        </div>
        <div className="col-sm-3">
          {
            showPremiumTooltip && (
              <div style={ Styles.PremiumStarsTooltip }>
                Persönlich geschult für Fast Learning
              </div>
            )
          }
          <div 
            style={ Styles.PremiumStars }
            onMouseEnter={ e => {
              updateShowPremiumTooltip( true )
            }}
            onMouseOut={ e => {
              updateShowPremiumTooltip( false )
            }}>
            <i class="fa fa-star" style={{ marginRight: '5px' }}></i>
            <i class="fa fa-star" style={{ marginRight: '5px' }}></i>
            <i class="fa fa-star" style={{ marginRight: '5px' }}></i>
            PREMIUM
          </div>
          <p style={{ ...Styles.mb0, fontWeight: '600' }}>
            Tennisschule Matthias Hessler auf der Tennisanlage Kail in Harlaching
          </p>
          <p>Oberbibergerstr. 120</p>
          <p>81547 München</p>
        </div>
        <div className="col-sm-3"></div>
        <div className="col-sm-9">
          <div className="row" style={{ width: '100%' }}>
            <div className="col-xs-3" style={{ ...Styles.ColumnDetails, borderRight: '1px solid #eaebef', fontWeight: '600', fontSize: '10px' }}>
              <span>5</span>
              <span>TEILNEHMER</span>
            </div>
            <div className="col-xs-3" style={{ ...Styles.ColumnDetails, borderRight: '1px solid #eaebef', fontWeight: '600', fontSize: '10px' }}>
              <span>5</span>
              <span>FREIE PLÄTZE</span>
            </div>
            <div className="col-xs-3" style={{ ...Styles.ColumnDetails, borderRight: '1px solid #eaebef', fontWeight: '600', fontSize: '12px' }}>
              <span>€ 199</span>
              <span>KURSPREIS (inkl. MwSt.)</span>
            </div>
            <div className="col-xs-3">
              <div style={{ display: 'flex', paddingLeft: '5px', paddingRight: '5px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginRight: '3px' }}>
                  <div>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                  </div>
                  <span>BEWERTUNGEN</span>
                </div>
                <div style={{ padding: '10px', border: '1px solid #2a2d51', borderRadius: '5px' }}>93</div>
              </div>
            </div>
            <div className="col-xs-3">
              <button style={ Styles.ReserveButton }>
                BUCHEN
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  )
}

const Styles = {
  DateDisplay: {
    background: '#2a2d51',
    padding: '15px',
    textAlign: 'center',
    color: '#fff',
    marginBottom: '15px',
    width: '100%'
  },
  mb0: {
    marginBottom: 0
  },
  StartDateEndDate: {
    fontSize: '20px',
    lineHeight: '24px',
    textTransform: 'uppercase'
  },
  TransparentButton: {
    background: 'none',
    border: 'none'
  },
  PremiumStars: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#2a2d51',
    padding: '5px',
    color: '#fff',
    marginBottom: '10px',
    marginTop: '30px'
  },
  PremiumStarsTooltip: {
    border: '1px solid #2a2d51', 
    padding: '5px', 
    position: 'absolute', 
    top: 0, 
    fontSize: '10px', 
    width: '100%',
    textAlign: 'center'
  },
  ColumnDetails: {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '10px'
  },
  ReserveButton: {
    color: '#fff',
    padding: '10px 15px 10px 15px',
    background: '#2a2d51',
    width: '190px'
  }
}

export default TennisShop