import React, { useRef, useEffect, useState } from 'react'
import { FormGroup } from 'reactstrap'

const ImageComponent = ({
  content
}) => {
  const ref = useRef( null )
  const imgRef = useRef( null )
  const [ containerWidth, setWidth ] = useState(0)
  const [ imageWidth, setImgWidth ] = useState(0)
  useEffect(() => {
    setWidth( ref.current.offsetWidth )
    setImgWidth( imgRef.current.clientWidth )
  }, [ imageWidth.current, content.width ])
  return (
    <FormGroup style={{ padding: "15px", marginBottom: "0" }} >
      <div style={{ padding: content.padding }} ref={ ref }>
        <img
          ref={ imgRef }
          src={ content.url }
          style={{ width:  imageWidth > containerWidth ? '85%' : content.width, height: content.height }} />
      </div>
    </FormGroup>
  )
}

export default ImageComponent
