import React, { Fragment, Component } from 'react'
import { connect } from 'react-redux'
import EmailEditor from 'react-email-editor'
import _ from 'lodash'
import {
  Container, Card, CardBody, Button, FormGroup, Label, Input, FormFeedback
}from 'reactstrap'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import ReactSelect from 'react-select'

import Template1JSON from './template1.json'
import Template2JSON from './template2.json'
import { countryOptions } from './asset' 

import Lang from 'Lang/General'

class EditForm extends Component {
  editor = ''

  onChangeData = ( key, value ) => {
    let tmp = _.cloneDeep( this.props.selectedNewsletterTemplate )
    tmp[key] = value

    return this.props.onChangeNewsletterHOC( 'selectedNewsletterTemplate', tmp )
  }

  saveDesign = async () => {
    let test = await this.editor.saveDesign
    let tmp = _.cloneDeep( this.props.selectedNewsletterTemplate )
    await test( async design => {
      tmp.json_content = design
      let test2 = await this.editor.exportHtml
      await test2( html => {
        tmp.html_content = html.html
        this.props.onSave( tmp )
      })
    })
  }

  onLoad = json => {
    if( this.editor && window.unlayer != undefined && !_.isEmpty( json ) ){
      this.editor.loadDesign( typeof json === 'string' ? JSON.parse( json ) : json )
    }
  }

  // onLoad = design => this.editor.loadDesign(design)

  render = () => {
    // const isEditingDesign = this.props.match && design && this.editor && window.unlayer
    return(
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Container fluid>
            <Card className="main-card mb-3">
              <CardBody>
                <FormGroup>
                  <Label>
                    { Lang[ 'TEMPLATE_NAME' ][ this.props.data.languageReducer.lang ] }
                    <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    onChange={ e => this.onChangeData( 'name', e.target.value ) }
                    value={ this.props.selectedNewsletterTemplate.name }
                    invalid={ this.props.errorMessage && this.props.errorMessage.name ? true : false } />
                  <FormFeedback>{ this.props.errorMessage.name ? this.props.errorMessage.name : '' }</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label>
                    { Lang[ 'CENTER_GROUP' ][ this.props.data.languageReducer.lang ] }
                    <span className="text-danger">*</span>
                  </Label>
                  <ReactSelect
                    isMulti
                    name="colors"
                    options={ this.props.centerGroups }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder={ Lang[ 'SELECT_PLACEHOLDER' ][ this.props.data.languageReducer.lang ] }
                    onChange={ ( value ) => this.onChangeData( 'center_groups', value || [] ) }
                    value={ this.props.selectedNewsletterTemplate.center_groups  }
                  />
                  <FormFeedback className={ 'd-block' }>{ this.props.errorMessage.center_groups ? this.props.errorMessage.center_groups : '' }</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label>
                    { Lang[ 'COUNTRY' ][ this.props.data.languageReducer.lang ] }
                    <span className="text-danger">*</span>
                  </Label>
                  <ReactSelect
                    isMulti
                    name="colors"
                    options={ countryOptions }
                    classNamePrefix="select"
                    placeholder={ Lang[ 'SELECT_PLACEHOLDER' ][ this.props.data.languageReducer.lang ] }
                    onChange={ val => this.onChangeData( 'countries', ( val && val.map( item => item.id ) ) || [] ) }
                    value={ 
                      _.filter( 
                        countryOptions, 
                        item => _.findIndex( this.props.selectedNewsletterTemplate.countries, templateCountry => templateCountry === item.id ) > -1 
                      ) 
                    } 
                  />
                  <FormFeedback className={ 'd-block' }>{ this.props.errorMessage.countries ? this.props.errorMessage.countries : '' }</FormFeedback>
                </FormGroup>
                <EmailEditor
                  ref={ editor => this.editor = editor }
                  onLoad={ this.onLoad( this.props.selectedNewsletterTemplate.json_content ) }
                  options={{
                    locale: this.props.data.languageReducer.lang
                  }}
                />
                <div className="d-flex justify-content-end mt-5">
                  <Button className="mb-2 mr-2" color="primary" onClick={ this.saveDesign }>{ Lang[ 'SAVE_DESIGN' ][ this.props.data.languageReducer.lang ] }</Button>
                </div>
              </CardBody>
            </Card>
          </Container>
        </ReactCSSTransitionGroup>
      </Fragment>
    )
  }
}
const mapStateToProps = state => ({ data: state })
export default connect( mapStateToProps )( EditForm )