import ReactHTMLParser from 'react-html-parser'
import Radium from 'proofpoint-radium'
import React from 'react'

const FooterText = props => {
  return (
    <div
      className={ props.item.className }
      style={{
        ...props.item.style, 
        order: props.item.mobileArrangement,
        '@media ( min-width: 768px )': {
          order: props.item.desktopArrangement
        }
      }}>
      {
        props.item.children.map( child => {
          return (
            <div>
              { ReactHTMLParser( child.content[ props.selectedLanguageShortname ] ) }
            </div>
          ) 
        })
      }
    </div>
  )
}

export default Radium( FooterText )