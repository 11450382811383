import Axios from 'axios'
import getDomainURL from 'utils/api'
import { clearItem } from 'utils/tokenStore'
import { getCipherHeader } from 'utils/cipher-text'

export const outdatedToken = ( error, history ) => {
  if( error === 'Token is expired' ) {
    clearItem( 'PROOFPOINT_ARK_MANAGEMENT' )
    history.push( '/' )
  }
}

export const GetWithNonce = ( url, response, error, load, lang ) => {
  load( true )
  const headers = {
    Authorization: getCipherHeader(),
    'Accept-Language': lang ? lang : ''
  }
  return Axios.get( `${ getDomainURL() }${ url }`, { headers: headers } ).then( res => {
    response( res.data )
    load( false )
  }).catch( err => {
    if( err && err.response && err.response.status ) {
      let tmp = err.response.status
      if( tmp === 422 || tmp === 401 ) {
        error( err.response.data.message )
      }
    } else if( err && err.message ) {
      error( err.message )
    } else {
      error( 'You are disconnnected from the internet, please reconnect to use Ark. If problem persists, please contact the system admin.' )
    }
    load( false )
  })
}