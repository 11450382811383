import React, { useState, useEffect } from 'react'
import {
  Form, FormGroup,
  Row, Col, 
  Modal, ModalHeader, ModalBody, ModalFooter,
  Input, CustomInput, Button, Table, UncontrolledTooltip
} from 'reactstrap'
import _ from 'lodash'

import DeleteConfirmation from 'components/Modal/delete'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Lang from 'Lang/General'

import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginPdfPreview from 'filepond-plugin-pdf-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

registerPlugin( FilePondPluginPdfPreview, FilePondPluginFileValidateType )

const Content = ({
  programTemplateType,
  currentProgramTemplate,
  onChangeProgramTemplatesHOC,
  lang,
  createSelectedProgramTemplateFile,
  removeSelectedProgramTemplateFile,
  onLoadProgramTemplatesHOC
}) => {
  const [ files, updateFiles ] = useState( [] )
  const [ newContent, updateNewContent ] = useState( {} )
  const [ deleteFileId, updateDeleteFileId ] = useState( 0 )
  const [ selectedVideoUrl, updateSelectedVideoUrl ] = useState( '' )

  useEffect( () => {
    updateFiles( [] )
    updateNewContent( {} )
    updateSelectedVideoUrl( '' )
  }, [] )

  const updateProgramTemplate = ( key, val ) => {
    let tmp = _.cloneDeep( currentProgramTemplate )
    tmp[ key ] = val
    onChangeProgramTemplatesHOC( programTemplateType, tmp )
  }

  const renderCreateContentModal = () => (
    <Modal size='sm' isOpen={ newContent.week_number } >
      <ModalHeader toggle={ () => updateNewContent( {} ) } >
        { Lang[ newContent.type === 'video' ? 'ADD_VIDEO' : 'ADD_FILE' ][ lang ] }
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Input
              type='text'
              value={ newContent.type === 'video' ? newContent.id : newContent.title }
              placeholder={ newContent.type === 'video' ? Lang[ 'LINK' ][ lang ] : Lang[ 'TITLE' ][ lang ] }
              onChange={ e => updateNewContent({
                  ...newContent,
                  ...( newContent.type === 'video' 
                    ? { id: e.target.value } 
                    : { title: e.target.value } )
                })
              }  
            />
          </FormGroup>
          {
            newContent.type === 'file' && (
              <FilePond
                files={ files }
                instantUpload={ false }
                allowFileEncode={ true }
                allowMultiple={ false }
                allowFileTypeValidation={ true }
                allowPdfPreview={ true }
                onupdatefiles={ fileItems => {
                  let tmpFiles = []
                  fileItems.map( item => tmpFiles.push( item.file ) )
                  updateFiles( tmpFiles )
                }}
              />
            )
          }
          {
            newContent.type === 'video' && (
              <FormGroup className='d-flex flex-row flex-wrap'>
                <CustomInput
                  id='video-type-youtube'
                  className='mr-2'
                  type='radio'
                  label='YouTube'
                  checked={ newContent.video_type === 'YOUTUBE' }
                  onChange={ () => updateNewContent({ ...newContent, video_type: 'YOUTUBE' }) } 
                />
                <CustomInput
                  id='video-type-vimeo'
                  type='radio'
                  label='Vimeo'
                  checked={ newContent.video_type === 'VIMEO' }
                  onChange={ () => updateNewContent({ ...newContent, video_type: 'VIMEO' }) } 
                />
              </FormGroup>
            )
          }
        </Form>
        { onLoadProgramTemplatesHOC && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => {
            if ( newContent.type === 'file' ) {
              let reader = new FileReader()
              reader.onload = () => {
                let tmp = {
                  week_number: newContent.week_number,
                  file: {
                    raw: reader.result,
                    filename: files[ 0 ].name
                  },
                  title: !_.isEmpty( newContent.title ) 
                    ? newContent.title 
                    : files[ 0 ].name.substr( 0, files[ 0 ].name.lastIndexOf( '.' ) )
                }
                createSelectedProgramTemplateFile( currentProgramTemplate.id, tmp )
              }
              reader.readAsDataURL( files[ 0 ] )
              updateFiles( [] )
            } else {
              let tmpWeeklyContent = _.cloneDeep( currentProgramTemplate.weekly_content )
              let tmpContentIndex = _.findIndex( tmpWeeklyContent, { week_number: newContent.week_number } )
              tmpWeeklyContent[ tmpContentIndex ].urls.push( newContent )
              updateProgramTemplate( 'weekly_content', tmpWeeklyContent )
            }
            updateNewContent( {} )
          }}>
          { Lang[ 'SUBMIT' ][ lang ] }</Button>
      </ModalFooter>
    </Modal>
  )

  const renderViewVideoModal = () => (
    <Modal size='lg' isOpen={ !_.isEmpty( selectedVideoUrl ) } >
      <ModalHeader toggle={ () => updateSelectedVideoUrl( '' ) } />
      <ModalBody>
        <div
          style={{
            width: "100%",
            height: "70vh"
          }} >
          <iframe
            src={ selectedVideoUrl.video_type === 'YOUTUBE'
              ? `https://www.youtube-nocookie.com/embed/${ selectedVideoUrl.id }?autoplay=1`
              : `https://player.vimeo.com/video/${ selectedVideoUrl.id }`
            }
            style={{
              position: 'absolute', 
              top: 0,
              left: 0,
              width: '100%',
              height: '100%'
            }}
            allow="fullscreen"
          />
        </div>
      </ModalBody>
    </Modal>
  )

  return(
    <Row>
      <Col md={ 12 } >
        <Table style={{ color: "#000", tableLayout: "fixed" }} >
          <thead>
            <tr>
              <td 
                className='pl-1'
                style={{ 
                  border: "1px solid rgba(0, 0, 0, 0.1)", 
                  fontWeight: "500", 
                  textTransform: "capitalize",
                  textAlign: "center", 
                  verticalAlign: "middle"
                }} >
                { Lang[ 'WEEK' ][ lang ] }
              </td>
              <td
                className='pl-2' 
                style={{ 
                  border: "1px solid rgba(0, 0, 0, 0.1)", 
                  fontWeight: "500", 
                  textTransform: "capitalize",
                  textAlign: "center", 
                  verticalAlign: "middle"
                }} >
                { Lang[ 'VIDEO_LINK' ][ lang ] }
              </td>
              {
                programTemplateType === 'selectedProgramTemplate' && (
                  <td 
                    className='pl-2'
                    style={{ 
                      border: "1px solid rgba(0, 0, 0, 0.1)", 
                      fontWeight: "500", 
                      textTransform: "capitalize",
                      textAlign: "center", 
                      verticalAlign: "middle"
                    }} >
                    { Lang[ 'FILE' ][ lang ] }
                  </td>
                )
              }
            </tr>
          </thead>
          <tbody>
            { 
              currentProgramTemplate.weekly_content.map( ( content, index ) => (
                <tr>
                  <td 
                    className='pl-1'
                    style={{ 
                      border: "1px solid rgba(0, 0, 0, 0.1)", 
                      textAlign: "center", 
                      verticalAlign: "middle"
                    }} >
                    { content.week_number }
                  </td>
                  <td
                    className='pl-2' 
                    style={{ 
                      border: "1px solid rgba(0, 0, 0, 0.1)", 
                      textAlign: "center",
                      verticalAlign: "bottom"
                    }} >
                    {
                      content.urls.length > 0 && <div>
                        { 
                          content.urls.map( ( url, urlIndex ) => {
                            return (
                              <div className='d-flex flex-nowrap justify-content-between align-items-center pb-1' > 
                                <Button
                                  color='link'
                                  id={ `week-${ content.week_number }-video-${ urlIndex }-preview` }
                                  style={{ 
                                    maxWidth: "70%", 
                                    overflow: "hidden", 
                                    textOverflow: "ellipsis", 
                                    textTransform: "capitalize",
                                    padding: "0" 
                                  }}
                                  onClick={ () => updateSelectedVideoUrl( url ) }>
                                  { `${ url.video_type.toString().toLowerCase() } - ${ url.id }` }
                                </Button> 
                                <UncontrolledTooltip
                                  target={ `week-${ content.week_number }-video-${ urlIndex }-preview` }
                                  placement='top'>
                                  { Lang[ 'VIEW' ][ lang ] }
                                </UncontrolledTooltip> 
                                <Button
                                  id={ `week-${ content.week_number }-video-${ urlIndex }` }
                                  className='btn-icon btn-icon-only'
                                  color='danger'
                                  onClick={ () => {
                                    let tmpWeeklyContent = _.cloneDeep( currentProgramTemplate.weekly_content )
                                    let tmpLinks = _.cloneDeep( content.urls )
                                    tmpLinks.splice( urlIndex, 1 )
                                    tmpWeeklyContent[ index ][ 'urls' ] = tmpLinks
                                    updateProgramTemplate( 'weekly_content', tmpWeeklyContent )
                                  }}>
                                  <i className='pe-7s-trash'/>
                                </Button>
                                <UncontrolledTooltip
                                  target={ `week-${ content.week_number }-video-${ urlIndex }` }
                                  placement='top'>
                                  { Lang[ 'DELETE' ][ lang ] }
                                </UncontrolledTooltip>
                              </div>  
                            )
                          })
                        }
                      </div>
                    }
                    <Button
                      color='primary'
                      className='w-100 mt-3'
                      onClick={ () => updateNewContent({ 
                        week_number: content.week_number, 
                        type: 'video', 
                        id: '', 
                        video_type: 'YOUTUBE'
                      }) } >
                      { Lang[ 'ADD_VIDEO' ][ lang ] }
                    </Button>
                  </td>
                  {
                    programTemplateType === 'selectedProgramTemplate' && (
                      <td 
                        className='pl-2' 
                        style={{ 
                          border: "1px solid rgba(0, 0, 0, 0.1)", 
                          textAlign: "center",
                          verticalAlign: "bottom"
                        }} >
                        {
                          content.files.length > 0 && <div>
                            { 
                              content.files.map( file => {
                                return (
                                  <div className='d-flex flex-nowrap justify-content-between align-items-center pb-1' > 
                                    <Button 
                                      color='link' 
                                      id={ `week-${ content.week_number }-file-${ file.id }-download` }
                                      style={{ maxWidth: "70%", overflow: "hidden", textOverflow: "ellipsis", padding: "0" }}
                                      onClick={ () => window.open( file.file.url ) }>
                                      { file.title }
                                    </Button>
                                    <UncontrolledTooltip
                                      target={ `week-${ content.week_number }-file-${ file.id }-download` }
                                      placement='top'>{ Lang[ 'DOWNLOAD' ][ lang ] }
                                    </UncontrolledTooltip>
                                    <Button
                                      id={ `week-${ content.week_number }-file-${ file.id }` }
                                      className='btn-icon btn-icon-only'
                                      color='danger'
                                      onClick={ () => updateDeleteFileId( file.id ) }>
                                      <i className='pe-7s-trash'/>
                                    </Button>
                                    <UncontrolledTooltip
                                      target={ `week-${ content.week_number }-file-${ file.id }` }
                                      placement='top'>{ Lang[ 'DELETE' ][ lang ] }
                                    </UncontrolledTooltip>
                                  </div>  
                                )
                              })
                            }
                          </div>
                        }
                        <Button
                          color='primary'
                          className='w-100 mt-3'
                          onClick={ () => updateNewContent({ week_number: content.week_number, type: 'file', title: '' }) } >
                          { Lang[ 'ADD_FILE' ][ lang ] }
                        </Button>
                      </td>
                    )
                  }
                </tr>
              )) 
            }
          </tbody>
        </Table>
        <DeleteConfirmation
          handleModalClose={ () => updateDeleteFileId( 0 ) }
          deleteOpen={ deleteFileId > 0 }
          confirmAction={ () => {
            removeSelectedProgramTemplateFile( currentProgramTemplate.id, deleteFileId ) 
            updateDeleteFileId( 0 )
          }}
          content={ Lang[ 'DELETE_CONFIRMATION_FILE' ][ lang ] }
          lang={ lang } />
        { renderCreateContentModal() }
        { renderViewVideoModal() }
      </Col>
    </Row>
  )
}

export default Content