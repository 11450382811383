import React, { Component } from 'react'
import { connect } from 'react-redux'

import StatusModal from 'components/Modal/status'

import { Get, Post, Put, Delete } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showCreatePackage: false,
      showUpdatePackage: false,
      packages: [],
      newPackage: {
        "name": "",
        "modules": []
      },
      selectedPackage: {
        "name": "",
        "modules": []
      },
      showStatusModal: false,
      statusModalType: '',
      onClickStatusModalButton: () => {},
      statusModalMessage: ''
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showStatusModal: true,
      statusModalType: 'negative',
      onClickStatusModalButton: () => this.setState({ showStatusModal: false }),
      statusModalMessage: error
    })
    requestSuccess = success => this.setState({
      showStatusModal: true,
      statusModalType: 'positive',
      onClickStatusModalButton: () => this.setState({
        showStatusModal: false,
        errorMessage: {}
      }),
      statusModalMessage: success
    })

    onChangePackagesHOC = ( key, val ) => this.setState({ [key]: val })

    getPackages = () => Get(
      `/api/v1/ms_packages`,
      this.getPackagesSuccess,
      this.getPackagesError,
      this.load
    )
    getPackagesSuccess = payload => this.setState({ packages: payload })
    getPackagesError = error => this.requestError( error )

    getSelectedPackage = id => Get(
      `/api/v1/ms_packages/${ id }`,
      this.getSelectedPackageSuccess,
      this.getSelectedPackageError,
      this.load
    )
    getSelectedPackageSuccess = payload => this.setState({ selectedPackage: payload, showUpdatePackage: true })
    getSelectedPackageError = error => this.requestError( error )

    createPackage = data => Post(
      `/api/v1/ms_packages`,
      data,
      this.createPackageSuccess,
      this.createPackageError,
      this.load
    )
    createPackageSuccess = payload => {
      this.requestSuccess( Lang[ 'PACKAGE_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.setState({
        showCreatePackage: false,
        newPackage: {
          "name": "",
          "modules": []
        }
      })
      this.getPackages()
    }
    createPackageError = error => this.requestError( error )

    updatePackage = data => Put(
      `/api/v1/ms_packages/${ data.id }`,
      data,
      this.updatePackageSuccess,
      this.updatePackageError,
      this.load
    )
    updatePackageSuccess = payload => {
      this.requestSuccess( Lang[ 'PACKAGE_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.setState({
        showUpdatePackage: false,
        selectedPackage: {
          "name": "",
          "modules": []
        }
      })
      this.getPackages()
    }
    updatePackageError = error => this.requestError( error )
    
    render = () => {
      return (
        <>
          <WrappedComponent 
            { ...this.props }
            packages={ this.state.packages }
            selectedPackage={ this.state.selectedPackage }
            newPackage={ this.state.newPackage }
            onLoadPackages={ this.state.loading }
            showCreatePackage={ this.state.showCreatePackage }
            showUpdatePackage={ this.state.showUpdatePackage }
            getPackages={ this.getPackages }
            getSelectedPackage={ this.getSelectedPackage }
            createPackage={ this.createPackage }
            updatePackage={ this.updatePackage }
            onChangePackagesHOC={ this.onChangePackagesHOC } />
          <StatusModal
            isOpen={ this.state.showStatusModal }
            type={ this.state.statusModalType }
            message={ this.state.statusModalMessage }
            onClick={ this.state.onClickStatusModalButton } />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC