import React, { useState, useEffect } from "react"
import Radium from "proofpoint-radium"

import CoreRenderFunction from '../core/render'

const HeaderMenu = ( props ) => {
  let {
    mode,
    selectedLanguageShortname,
    templateHeaders,
    templateHeadersBlueprints,
    templateFooterBlueprints,
    headerMenuContent,
    selectedPage
  } = props
  let isFirstTime =  true

  useEffect(()=>{
    if( headerMenuContent && headerMenuContent.section ){
      let headerContent = headerMenuContent.section
      let tmp = document.getElementById( 'editor-site-renderer' ).contentWindow.document.querySelector( `#${ headerContent.desktop[0].content.id }` )
      let observer = new IntersectionObserver( 
        ([e]) => {
          if( !isFirstTime ){
            if( e.boundingClientRect.top < 1 ){
              e.target.classList.toggle( 'isSticky', true )
            } else {
              e.target.classList.toggle( 'isSticky', false )
            }
          } else {
            isFirstTime = false 
          }
        },
        {
          root: document.getElementById( 'editor-site-renderer' ).contentWindow.document,
          rootMargin: '-1px 0px 0px 0px',
          threshold: [ 0, 1 ]
        }
      )
      observer.observe( tmp )
    }
  },[ headerMenuContent ])

  return (
    <>
      { headerMenuContent && headerMenuContent.section.desktop && (
        <CoreRenderFunction
          item={ headerMenuContent.section.desktop[0].content }
          mode={ mode }
          selectedLanguageShortname={ selectedLanguageShortname }
          headerMenuContent={ headerMenuContent }
          templateFooterBlueprints={ templateFooterBlueprints }
          selectedPage={ selectedPage }
        />
      )}
      { headerMenuContent && headerMenuContent.section.mobile && (
        <CoreRenderFunction
          item={ headerMenuContent.section.mobile[0].content }
          mode={ mode }
          selectedLanguageShortname={ selectedLanguageShortname }
          templateHeaders={ templateHeaders }
          templateHeadersBlueprints={ templateHeadersBlueprints }
          headerMenuContent={ headerMenuContent }
          templateFooterBlueprints={ templateFooterBlueprints }
          selectedPage={ selectedPage }
        />
      )}
    </>
  )
}

export default Radium( HeaderMenu )
