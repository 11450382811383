import React, { useEffect } from 'react'
import { compose } from 'redux'
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button, UncontrolledTooltip, Card
} from 'reactstrap'
import _ from 'lodash'
import ReactTable from 'react-table'

import PageTitle from 'components/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import DeleteConfirmation from 'components/Modal/delete'
import Lang from 'Lang/General'

import CategoryForm from './Form'
import CenterGroupsHOC from 'actions/centerGroup'
import WithCategoriesHOC from './actions.js'

const Categories = props => {
  useEffect( () => {
    props.getCategories()
    props.getCenterGroups()
  }, [] )

  useEffect( () => {
    if( !props.showCreateCategory ){
      props.onChangeCategoriesHOC( 
        'newCategory', 
        { 
          name: { en: '', de: '', it: '', nl: '' },
          type: 'TIP',
          description: { en: '', de: '', it: '', nl: '' },
          center_group_id: null
        } 
      )
    }
  }, [ props.showCreateCategory ] )

  useEffect( () => {
    if( !props.showUpdateCategory ){
      props.onChangeCategoriesHOC( 'selectedCategory', {} )
    }
  }, [ props.showUpdateCategory ] )

  const handleSecurityModulesCreate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Member Area' })
      return tmpModules.is_create
    }
  }

  const handleSecurityModulesUpdate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Member Area' })
      return tmpModules.is_update
    }
  }

  const handleSecurityModulesDelete= () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Member Area' })
      return tmpModules.is_delete
    }
  }

  const renderCreateCategoryModal = () => (
    <Modal size='md' isOpen={ props.showCreateCategory } >
      <ModalHeader toggle={ () => props.onChangeCategoriesHOC( 'showCreateCategory', false ) } >
        { Lang[ 'CREATE' ][ props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        <CategoryForm
          categoryType='newCategory'
          currentCategory={ props.newCategory }
          onChangeCategoriesHOC={ props.onChangeCategoriesHOC }
          lang={ props.data.languageReducer.lang }
          centerGroups={ props.centerGroups }
        />
        { props.onLoadCategoriesHOC && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => props.createCategory( props.newCategory ) }>
          { Lang[ 'SUBMIT' ][ props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )

  const renderUpdateCategoryModal = () => (
    <Modal size='md' isOpen={ props.showUpdateCategory } >
      <ModalHeader toggle={ () => props.onChangeCategoriesHOC( 'showUpdateCategory', false ) } >
        { Lang[ 'UPDATE' ][ props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        <CategoryForm
          categoryType='selectedCategory'
          currentCategory={ props.selectedCategory }
          onChangeCategoriesHOC={ props.onChangeCategoriesHOC }
          lang={ props.data.languageReducer.lang }
          centerGroups={ props.centerGroups }
        />
        { props.onLoadCategoriesHOC && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => props.updateCategory( props.selectedCategory ) }>
          { Lang[ 'SUBMIT' ][ props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )

  return(
    <>
      <PageTitle
        heading={ Lang[ 'CONTENT_CATEGORY' ][ props.data.languageReducer.lang ] }
        icon="pe-7s-users icon-gradient bg-happy-fisher"
        buttons={[
          {
            buttonText: Lang[ 'CREATE' ][ props.data.languageReducer.lang ],
            onClick: () => props.onChangeCategoriesHOC( 'showCreateCategory', true ),
            display: handleSecurityModulesCreate()
          }
        ]}/>
      <Card className='p-4'>
        <ReactTable
          showPagination
          pageSize={ 10 }
          data={ props.categories }
          columns={[
            {
              Header: Lang[ 'NAME' ][ props.data.languageReducer.lang ],
              accessor: "name",
              Cell: ({ value }) => value[ props.data.languageReducer.lang ] 
            },
            {
              Header: Lang[ 'TYPE' ][ props.data.languageReducer.lang ],
              accessor: "type",
              Cell: ({ value }) => !_.isEmpty( value )
                ? Lang[ value === 'KNOWLEDGE' ? value : 'TIPS_OF_THE_WEEK' ][ props.data.languageReducer.lang ] 
                : ''
            },
            {
              Header: Lang[ 'ACTIONS' ][ props.data.languageReducer.lang ],
              accessor: "id",
              Cell: ({ value }) => (
                <div className='d-flex w-100 justify-content-center' >
                  {
                    handleSecurityModulesUpdate() && (
                      <>
                        <Button
                          id={ `Category-${ value }-update` }
                          className="mb-2 mr-2 btn-icon btn-icon-only"
                          color="primary"
                          onClick={ () => props.getSelectedCategory( value ) }>
                          <i className="pe-7s-note"> </i>
                        </Button>
                        <UncontrolledTooltip
                          target={ `Category-${ value }-update` }
                          placement="top">{ Lang[ 'EDIT' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                      </>
                    )
                  }
                  {
                    handleSecurityModulesDelete() && (
                      <>
                        <Button
                          id={ `Category-${ value }-archive-category` }
                          className="mb-2 mr-2 btn-icon btn-icon-only"
                          color="danger"
                          onClick={ () => props.onChangeCategoriesHOC( 'showDeleteCategoryId', parseInt( value ) ) }>
                          <i className="pe-7s-trash"> </i>
                        </Button>
                        <UncontrolledTooltip
                          target={ `Category-${ value }-archive-category` }
                          placement="top">{ Lang[ 'REMOVE' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                      </>
                    )
                  }
                </div>
              )
            }
          ]}
        />
      </Card>
      <DeleteConfirmation
        handleModalClose={ () => props.onChangeCategoriesHOC( 'showDeleteCategoryId', 0 ) }
        deleteOpen={ props.showDeleteCategoryId > 0 }
        confirmAction={ () => props.removeCategory( props.showDeleteCategoryId ) }
        content={ Lang[ 'DELETE_CONFIRMATION_CATEGORY' ][ props.data.languageReducer.lang ] }
        lang={ props.data.languageReducer.lang } />
      { renderCreateCategoryModal() }
      { renderUpdateCategoryModal() }
      { ( props.onLoadCategoriesHOC || props.onLoadCenterGroups ) && <LoadingOverlay/> }
    </>
  )
}

export default compose(
  WithCategoriesHOC,
  CenterGroupsHOC
)( Categories )