import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Button,
  Label
} from 'reactstrap'
import _ from 'lodash'
import Lang from 'Lang/General'

const UpdateModule = ({
  showUpdateModule,
  onChangeModuleHOC,
  selectedModule,
  updateModule,
  lang
}) => {
  return (
    <Modal
      isOpen={ showUpdateModule }
      size={ 'md' }
      toggle={() => onChangeModuleHOC( 'showUpdateModule', false )}>
      <ModalHeader toggle={() => onChangeModuleHOC( 'showUpdateModule', false )}>{ Lang[ 'UPDATE_MODULE' ][ lang ] }</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault() }>
          <FormGroup>
            <Label>{ Lang[ 'NAME' ][ lang ] }</Label>
            <Input
              type={ 'text' }
              value={ selectedModule.name }
              onChange={ e => {
                let tmp = _.cloneDeep( selectedModule )
                tmp[ 'name' ] = e.target.value
                onChangeModuleHOC( 'selectedModule', tmp )
              }} />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary" 
          onClick={() => {
            console.log( selectedModule )
            updateModule( selectedModule )
          }}>{ Lang[ 'SUBMIT' ][ lang ] }</Button>
      </ModalFooter>
    </Modal>
  )
}

export default UpdateModule