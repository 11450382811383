import React from "react";
import {
  Switch,
  Route,
  withRouter,
  Redirect
} from "react-router-dom"

import Login from './containers/Login'
import LoginStoreToken from './containers/Login/StoreToken'
// import AdminDashboard from './containers/Dashboard'
import TemplateEditor from 'containers/TemplateEditor'
import LandingPageEditor from 'containers/LandingPageEditor'
import ForgotPassword from './containers/PasswordReset'
import ResetPassword from './containers/PasswordReset/resetPassword'
import Dashboard from './containers/Wrapper'

import { getItem } from './utils/tokenStore'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    getItem( 'PROOFPOINT_ARK_MANAGEMENT' ) !== null
      ? <Component {...props} />
      : <Redirect to='/' />
  )} />
);

const KWSMainRouter = ({ location }) => {
  return (
    <Switch>
      {/* <PrivateRoute path="/admin" component={ Admin } /> */}
      <Route exact path="/" component={ Login } />
      <Route path="/login/:id" component={ LoginStoreToken } />
      <Route exact path="/forgot-password" component={ ForgotPassword } />
      <Route exact path="/reset-password" component={ ResetPassword } />

      <PrivateRoute path="/dashboard" component={ Dashboard }/>
      <PrivateRoute path="/template-editor/:template_id/:template_name" component={ TemplateEditor } />
      <PrivateRoute path="/landing-page-editor/:landing_page_id" component={ LandingPageEditor } />
      {
        // <Route path="/dashboard" component={ AdminDashboard } />
        // <Route path="/template-editor/:template_id/:template_name" component={ TemplateEditor } />
      }
    </Switch>
  )
};

export default withRouter( KWSMainRouter );
