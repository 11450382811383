import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import CoreRenderFunction from '../../core/render'
import FooterImg from './FooterImg'
import FooterLine from './FooterLine'
import FooterMenu from './FooterMenu'
import FooterText from './FooterText'

const FooterV2 = props => {
  const [ content, updateContent ] = useState( [] )

  useEffect(() => {
    findContentArrays( props.templateFooters, 'content' )
  }, [ props.templateFooters ])

  const findContentArrays = ( data, key ) => {
    if( data.mapWith === key ) {
      updateContent( data.children )
      return data
    } else {
      if( data.children && data.children.length > 0 ) {
        data.children.map( item => {
          findContentArrays( item, key )
        })
      }
    }
  }

  return !_.isEmpty( props.templateFooters ) ? (
    <div style={{ ...props.templateFooters.style, position: 'relative', width: '100%', overflowY: 'scroll' }}>
      <style>{`.footer-content .table { color: ${ props.templateFooters.style.color };}`}</style>
      <div className="container" style={{ ...props.templateFooters.children[0].style }}>
        <div style={{ ...props.templateFooters.children[0].children[2].style }}></div>
        <div
          className="row footer-content"
          style={{
            ...props.templateFooters.children[0].children[0].style
          }}>
          { 
            content.map( item => {
              if( item.variant === 'footer-text' ) {
                return (
                  <FooterText 
                    key={ item.id }
                    item={ item } 
                    selectedLanguageShortname={ props.selectedLanguageShortname }/>
                )
              } else if( item.variant === 'footer-img' ) {
                return (
                  <FooterImg
                    key={ item.id }
                    item={ item } 
                    selectedLanguageShortname={ props.selectedLanguageShortname }
                    mode={ props.mode } />
                )
              } else if( item.variant === 'footer-line' ) {
                return (
                  <FooterLine
                    key={ item.id }
                    item={ item } 
                    selectedLanguageShortname={ props.selectedLanguageShortname }/>
                )
              } else if( item.variant === 'footer-menu' ) {
                return (
                  <FooterMenu
                    key={ item.id }
                    item={ item } 
                    selectedLanguageShortname={ props.selectedLanguageShortname }
                    dom={ props.templateFooters }
                    mode={ props.mode } />
                )
              }
            })
          }
        </div>
        <CoreRenderFunction
          item={ props.templateFooters.children[0].children[ 1 ] }
          mode={ props.mode }
          selectedLanguageShortname={ props.selectedLanguageShortname }
        />
      </div>
    </div>
  ) : (
    <></>
  )
}

export default FooterV2
