import {
  GET_DICTIONARY
} from "../actions/type";

let initialState = {
  dictionary: {}
}

export default ( state = initialState, action ) => {
  switch( action.type ) {
    case GET_DICTIONARY:
      return {
        ...state,
        dictionary: action.payload
      }
    default: return state;
  }
}
