import React from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'
import { toast } from 'react-toastify'
import _ from 'lodash'

import MediaDatabase from 'SharedModules/MediaDatabase'
import Lang from 'Lang/General'

const PdfViewerForm = props => {
  let {
    selectedSection,
    onChangeSectionHOC,
    toggleMediaDatabase,
    selectedLanguageShortname,
  } = props

  return (
    <>
      <Card className='mb-2'>
        <CardHeader>{ Lang[ 'SETTINGS' ][ selectedLanguageShortname ] }</CardHeader>
        <CardBody>
          <FormGroup>
            <Label>{ Lang[ 'ASSIGN_PDF' ][ selectedLanguageShortname ] }</Label>
            <Input 
              type='text' 
              disabled 
              value={ selectedSection.children[ 0 ].source ? selectedSection.children[ 0 ].source.replace( /.*(?=\/)/, '' ).replace( '/', '' ) : '' } />  
            <Button
              color='primary'
              style={{ marginTop: '10px' }}
              onClick={ () => toggleMediaDatabase( true ) }>
              { Lang[ 'MEDIA_DATABASE' ][ selectedLanguageShortname ] }
            </Button>
          </FormGroup>
        </CardBody>
      </Card>
      <MediaDatabase
        onSelectedImage={ param => {
          let tmp = _.cloneDeep( selectedSection )
          if ( param.indexOf( '.pdf' ) > -1 ) {
            tmp.children[ 0 ].source = param
            onChangeSectionHOC( 'selectedSection', tmp )
            toggleMediaDatabase( false )
          } else {
            toast.error( Lang[ 'PLEASE_SELECT_PDF_FILE' ][ selectedLanguageShortname ] )
          }
        }}
        selectedLanguageShortname={ selectedLanguageShortname }
        allowed={[ 'pdf' ]} />
    </>
  )
}

export default PdfViewerForm