import React, { useEffect } from 'react'
import {
  Modal, ModalHeader, ModalBody,
  Card, CardBody, CardHeader, CardFooter,
  UncontrolledTooltip, Button, FormGroup, Label, Input
} from 'reactstrap'
import { connect } from 'react-redux'
import { compose } from 'redux'
import _ from 'lodash'

import PageTitle from 'components/PageTitle'
import ARKTable from 'components/Table'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import DeleteConfirmation from 'components/Modal/delete'
import Lang from 'Lang/General'
import MailingTemplateHOC from './actions'
import CountryHOC from '../SystemFunnels/actions/country'
import CenterGroupHOC from '../SystemFunnels/actions/centerGroup'
import CreateMailingTemplate from './Create'
import UpdateMailingTemplate from './Update'

const MailingTemplates = props => {
  useEffect( () => {
    props.getCountriesEn()
    props.getCenterGroups({
      is_paginated: false,
      filter: {
        $or: [
          { status: 'ACTIVE' }
        ]
      }
    })
  },[] )

  useEffect( () => {
    props.getAllMailingTemplates()
  }, [ props.showArchivedMailingTemplates ] )

  const handleKeyPress = event => {
    if( event.key === 'Enter' ){
      props.getAllMailingTemplates()
    }
  }

  const handleSecurityModulesCreate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Marketing Funnels' })
      return tmpModules.is_create
    }
  }

  const handleSecurityModulesUpdate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Marketing Funnels' })
      return tmpModules.is_update
    }
  }

  return (
    <>
      <PageTitle
        heading={ Lang[ 'MAILING_TEMPLATES' ][ props.data.languageReducer.lang ] }
        icon="pe-7s-mail icon-gradient bg-happy-fisher"
        buttons={[
          {
            buttonText: Lang[ 'CREATE_MAILING_TEMPLATE' ][ props.data.languageReducer.lang ],
            onClick: () =>  props.onChangeSystemMailingTemplateHOC( 'showCreateMailingTemplate', true ),
            display: handleSecurityModulesCreate()
          },
          {
            buttonText: Lang[ 'ARCHIVED_MAILING_TEMPLATES' ][ props.data.languageReducer.lang ],
            onClick: () =>  props.onChangeSystemMailingTemplateHOC( 'showArchivedMailingTemplates', true ),
            display: handleSecurityModulesUpdate()
          }
        ]} />
      <Card className="mb-3">
        <CardHeader>{ Lang[ 'SEARCH' ][ props.data.languageReducer.lang ] }</CardHeader>
        <CardBody>
          <FormGroup>
            <Label>{ Lang[ 'NAME' ][ props.data.languageReducer.lang ] }</Label>
            <Input
              name="campaign-type" id="campaign-type"
              placeholder={ Lang[ 'SEARCH_WITH_NAME' ][ props.data.languageReducer.lang ] }
              value={ props.name }
              onKeyDown={ handleKeyPress }
              onChange={ e => props.onChangeSystemMailingTemplateHOC( 'name', e.target.value ) }/>
          </FormGroup>
        </CardBody>
        <CardFooter>
          <Button
            color="primary"
            className="ml-auto"
            onClick={ () => props.getAllMailingTemplates() } >
            { Lang[ 'SEARCH' ][ props.data.languageReducer.lang ] }</Button>
          <Button
            color="danger"
            onClick={ () => props.getAllMailingTemplates( 1, true ) } >
            { Lang[ 'RESET' ][ props.data.languageReducer.lang ] }</Button>
        </CardFooter>
      </Card>
      <MailingTemplatesTable 
        { ...props }
        handleSecurityModulesUpdate={ handleSecurityModulesUpdate } />
      <CreateMailingTemplate
        { ...props }
        selectedLanguage={ props.data.languageReducer.lang } />
      <UpdateMailingTemplate
        { ...props }
        selectedLanguage={ props.data.languageReducer.lang } />
      <ArchivedMailingTemplatesModal 
        { ...props }
        handleSecurityModulesUpdate={ handleSecurityModulesUpdate } />
      <DeleteConfirmation
        handleModalClose={ () => props.onChangeSystemMailingTemplateHOC( 'mailingTemplateToArchive', {} ) }
        deleteOpen={ !_.isEmpty( props.mailingTemplateToArchive ) }
        confirmAction={ () => props.updateMailingTemplate({  
          ...props.mailingTemplateToArchive,
          status: props.showArchivedMailingTemplates ? 'ACTIVE' : 'ARCHIVED'
        }) }
        content={ Lang[ 'ARCHIVE_UNARCHIVE_CONFIRMATION' ][ props.data.languageReducer.lang ] }
        lang={ props.data.languageReducer.lang } 
      />
      { 
        ( 
          props.data.ajaxReducer.ajaxCallProgress > 0 || 
          props.onLoadMailingTemplate ||
          props.onLoadCountries ||
          props.onLoadCenterGroups
        ) && <LoadingOverlay /> 
      }
    </>
  )
}

const MailingTemplatesTable = props => (
  <ARKTable
    data={ props.mailingTemplates.data }
    columns={[
      {
        Header: Lang[ 'NAME' ][ props.data.languageReducer.lang ],
        accessor: 'name'
      },
      {
        Header: Lang[ 'ACTION' ][ props.data.languageReducer.lang ],
        accessor: 'id',
        Cell: ({ value }) => (
          <div>
            {
              props.handleSecurityModulesUpdate() && (
                !props.showArchivedMailingTemplates ? (
                  <>
                    <Button
                      id={ `mailing-${ value }-edit-mailing-template` }
                      className="mb-2 mr-2 btn-icon btn-icon-only"
                      color="primary"
                      onClick={ () => {
                        props.getSelectedMailingTemplate( value )
                        props.onChangeSystemMailingTemplateHOC( 'showUpdateMailingTemplate', true )
                      } }>
                      <i className="pe-7s-note"> </i>
                    </Button>
                    <UncontrolledTooltip
                      target={ `mailing-${ value }-edit-mailing-template` }
                      placement="top">{ Lang[ 'EDIT' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                    <Button
                      id={ `mailing-${ value }-archive-mailing-template` }
                      className="mb-2 mr-2 btn-icon btn-icon-only"
                      color="warning"
                      onClick={ () => props.getSelectedMailingTemplate( value, true ) }>
                      <i className='pe-7s-notebook'/>
                    </Button>
                    <UncontrolledTooltip
                      target={ `mailing-${ value }-archive-mailing-template` }
                      placement="top">{ Lang[ 'ARCHIVE' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                  </>
                ) : (
                  <>
                    <Button
                      id={ `mailing-${ value }-unarchive-mailing-template` }
                      className="mb-2 mr-2 btn-icon btn-icon-only"
                      color="warning"
                      onClick={ () => props.getSelectedMailingTemplate( value, true ) }>
                      <i className='pe-7s-notebook'/>
                    </Button>
                    <UncontrolledTooltip
                      target={ `mailing-${ value }-unarchive-mailing-template` }
                      placement="top">{ Lang[ 'UNARCHIVE' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                  </>
                )
              )
            }
          </div>
        )
      }
    ]}
    totalPages={ props.totalPagesMailingTemplates }
    page={ props.mailingTemplates.meta?.current_page || 1 }
    showPagination={ false }
    onChangePage={ pg => props.getAllMailingTemplates( pg ) }
  />
)

const ArchivedMailingTemplatesModal = props => (
  <Modal
    isOpen={ props.showArchivedMailingTemplates }
    size='md' >
    <ModalHeader 
      toggle={ () => props.onChangeSystemMailingTemplateHOC( 'showArchivedMailingTemplates', false ) }>
      { Lang[ 'ARCHIVED_MAILING_TEMPLATES' ][ props.selectedLanguage ] }
    </ModalHeader>
    <ModalBody>
      <MailingTemplatesTable { ...props } />
      { props.onLoadMailingTemplate && <LoadingOverlay /> }
    </ModalBody>
  </Modal>
)

const mapStateToProps = state => ({ data: state })

export default compose(
  MailingTemplateHOC,
  CountryHOC,
  CenterGroupHOC,
  connect( mapStateToProps )
)( MailingTemplates )
