import React, { Component } from 'react'
import { 
  Card, CardHeader, CardBody, CardFooter,
  Row, Col, FormGroup, Label, Input,
  Button, UncontrolledTooltip, Table,
  Modal, ModalHeader, ModalBody, ModalFooter 
} from 'reactstrap'
import { compose } from 'redux'
import Moment from 'moment'
import _ from 'lodash'

import ARKTable from 'components/Table'
import LoadingModal from 'components/Indicator/LoadingOverlay'
import ContractForm from './components/contractForm'
import AVContractHOC from './actions'
import AVContractTemplateHOC from './actions/contractTemplate'

import Lang from 'Lang/General'

class Listings extends Component {

  componentDidMount = () => {
    this.props.getAllContracts({
      page: 1,
      is_paginated: true
    })
    this.props.getClients({ is_paginated: false })
    this.props.getAllContractTemplates({ is_paginated: false })
  }

  componentDidUpdate = pp => {
    if ( pp.showCreateContract !== this.props.showCreateContract ) {
      this.props.onChangeAVContractHOC( 'newContract', {
        client_id: 0,
        gdpr_template_id: 0,
        variable_fields: {
          auth_person: '',
          company_name_address: '',
          agreement_number: ''
        },
        is_new_contract: false,
        agreed_datetime: null,
        contract_pdf_base64: ''
      })
    }
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' } )
    let tmpModules = {}
    if ( tmp ) {
      tmpModules = _.find( tmp.modules, { name: 'GDPR Center' } )
      return tmpModules.is_create
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' } )
    let tmpModules = {}
    if ( tmp ) {
      tmpModules = _.find( tmp.modules, { name: 'GDPR Center' } )
      return tmpModules.is_update
    }
  }

  renderCreateModal = () => {
    return(
      <Modal isOpen={ this.props.showCreateContract } size="lg">
        <ModalHeader toggle={ () => this.props.onChangeAVContractHOC( 'showCreateContract', false ) }>
          { Lang[ 'CREATE_CONTRACT' ][ this.props.selectedLanguage ] }
        </ModalHeader>
        <ModalBody>
          <ContractForm 
            currentContract={ this.props.newContract }
            contractType={ 'newContract'  }
            onChangeAVContractHOC={ this.props.onChangeAVContractHOC }
            clients={ this.props.clients }
            clientOptions={ this.props.clientOptions }
            templates={ this.props.contractTemplates.data }
            lang={ this.props.selectedLanguage } />
          { this.props.onLoadAVContracts && <LoadingModal /> }
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={ () => {
              let tmpContract = _.cloneDeep( this.props.newContract )
              if( !this.props.newContract.is_new_contract ) {
                tmpContract.is_agreed = true
                delete tmpContract.gdpr_template_id
                delete tmpContract.variable_fields
              } else {
                delete tmpContract.agreed_datetime
              }
              this.props.postContract( tmpContract ) 
            }}>
            { Lang[ 'SUBMIT' ][ this.props.selectedLanguage ] }
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderEditModal = () => {
    return(
      <Modal isOpen={ this.props.showEditContract } size="lg">
        <ModalHeader toggle={ () => this.props.onChangeAVContractHOC( 'showEditContract', false ) }>
          { Lang[ 'EDIT_CONTRACT' ][ this.props.selectedLanguage ] }
        </ModalHeader>
        <ModalBody>
          {
            !_.isEmpty( this.props.selectedContract ) && (
              <ContractForm 
                currentContract={ this.props.selectedContract }
                contractType={ 'selectedContract'  }
                onChangeAVContractHOC={ this.props.onChangeAVContractHOC }
                clients={ this.props.clients }
                clientOptions={ this.props.clientOptions }
                templates={ this.props.contractTemplates.data }
                lang={ this.props.selectedLanguage } />
            )
          }
          { this.props.onLoadAVContracts && <LoadingModal /> }
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={ () => this.props.updateContract( this.props.selectedContract.id, this.props.selectedContract ) }>
            { Lang[ 'SUBMIT' ][ this.props.selectedLanguage ] }
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderViewModal = () => {
    return(
      <Modal isOpen={ this.props.showViewContract } size="lg">
        <ModalHeader toggle={ () => this.props.onChangeAVContractHOC( 'showViewContract', false ) }>
          { Lang[ 'VIEW_CONTRACT' ][ this.props.selectedLanguage ] }
        </ModalHeader>
        <ModalBody>
          {
            !_.isEmpty( this.props.selectedContract ) && (
              <Table className="mb-0" bordered>
                <tbody>
                  <tr>
                    <th scope="row">{ Lang[ 'CLIENT' ][ this.props.selectedLanguage ] }</th>
                    <td>{ this.props.selectedContract.client.name }</td>
                  </tr>
                  <tr>
                    <th scope="row">{ Lang[ 'TYPE' ][ this.props.selectedLanguage ] }</th>
                    <td>{ this.props.selectedContract.is_new_contract ? Lang[ 'NEW_CONTRACT' ][ this.props.selectedLanguage ] : Lang[ 'OLD_CONTRACT' ][ this.props.selectedLanguage ] }</td>
                  </tr>
                  {
                    this.props.selectedContract.is_new_contract && (
                      <>
                        <tr>
                          <th scope="row">{ Lang[ 'CONTRACT_TITLE' ][ this.props.selectedLanguage ] }</th>
                          <td>{ this.props.selectedContract.gdpr_template.name[ this.props.selectedLanguage ] }</td>
                        </tr>
                        <tr>
                          <th scope="row">{ Lang[ 'VERSION' ][ this.props.selectedLanguage ] }</th>
                          <td>{ this.props.selectedContract.gdpr_template.version }</td>
                        </tr>
                        <tr>
                          <th scope="row">{ Lang[ 'COMPANY_NAME_ADDRESS' ][ this.props.selectedLanguage ] }</th>
                          <td>{ this.props.selectedContract.variable_fields.company_name_address }</td>
                        </tr>
                        <tr>
                          <th scope="row">{ Lang[ 'AUTH_PERSON' ][ this.props.selectedLanguage ] }</th>
                          <td>{ this.props.selectedContract.variable_fields.auth_person }</td>
                        </tr>
                        <tr>
                          <th scope="row">{ Lang[ 'AGREEMENT_NUMBER' ][ this.props.selectedLanguage ] }</th>
                          <td>{ this.props.selectedContract.variable_fields.agreement_number }</td>
                        </tr>
                      </>
                    )
                  }
                  <tr>
                    <th scope="row">{ Lang[ 'IS_SIGNED_CONTRACT' ][ this.props.selectedLanguage ] }</th>
                    <td>{ this.props.selectedContract.is_agreed ? Lang[ 'YES' ][ this.props.selectedLanguage ] : Lang[ 'NO' ][ this.props.selectedLanguage ] }</td>
                  </tr>
                  <tr>
                    <th scope="row">{ Lang[ 'AGREED_AT' ][ this.props.selectedLanguage ] }</th>
                    <td>{ this.props.selectedContract.agreed_datetime !== null && Moment( this.props.selectedContract.agreed_datetime ).format( 'DD-MM-YYYY' ) }</td>
                  </tr>
                </tbody>
              </Table>
            )
          }
          { this.props.onLoadAVContracts && <LoadingModal /> }
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={ () => this.props.onChangeAVContractHOC( 'showViewContract', false ) }>
            { Lang[ 'CLOSE' ][ this.props.selectedLanguage ] }
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
  
  render = () => {
    return (
      <>
        <div>
          {
            this.handleSecurityModulesCreate() && (
              <Button
                color="primary"
                style={{ display: 'block' }}
                className="ml-auto m-3"
                onClick={ () => this.props.onChangeAVContractHOC( 'showCreateContract', true ) }>
                { Lang[ 'CREATE_CONTRACT' ][ this.props.selectedLanguage ] }
              </Button>
            )
          }
        </div>
        <Card className="main-card mb-3">
          <CardHeader>{ Lang[ 'SEARCH' ][ this.props.selectedLanguage ] }</CardHeader>
          <CardBody>
            <Row>
              <Col md={ 6 }>
                <FormGroup>
                  <Label>{ Lang[ 'CLIENT' ][ this.props.selectedLanguage ] }</Label>
                  <Input
                    placeholder={ Lang[ 'SEARCH_BY_CLIENT_NAME' ][ this.props.selectedLanguage ] }
                    value={ this.props.searchParams.company_name }
                    onChange={ e => {
                      this.props.onChangeAVContractHOC( 'searchParams', {
                        ...this.props.searchParams,
                        company_name: e.target.value
                      }) 
                    }}/>
                </FormGroup>
              </Col>
              <Col md={ 6 }>
                <FormGroup>
                <Label>{ Lang[ 'CONTRACT_AGREEMENT' ][ this.props.selectedLanguage ] }</Label>
                  <Input
                    type="select"
                    value={ this.props.searchParams.is_agreed }
                    onChange={ e => {
                      this.props.onChangeAVContractHOC( 'searchParams', {
                        ...this.props.searchParams,
                        is_agreed: e.target.value
                      })
                    }}>
                    <option value={ '' }>{ Lang[ 'SELECT_PLACEHOLDER' ][ this.props.selectedLanguage ] }</option>
                    <option value={ true }>{ Lang[ 'YES' ][ this.props.selectedLanguage ] }</option>
                    <option value={ false }>{ Lang[ 'NO' ][ this.props.selectedLanguage ] }</option>
                  </Input>
              </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button
              color="primary"
              className="ml-auto"
              onClick={ () => {
                let tmp = {
                  page: 1,
                  is_paginated: true,
                  filter: {
                    company_name: {
                      $like: `%${ this.props.searchParams.company_name ? this.props.searchParams.company_name : '' }%`
                    },
                    ...( !_.isEmpty( this.props.searchParams.is_agreed ) && { is_agreed: this.props.searchParams.is_agreed === 'true' } )
                  }
                }
                this.props.getAllContracts( tmp )
              }}>{ Lang[ 'SEARCH' ][ this.props.selectedLanguage ] }</Button>
            <Button
              color="danger"
              onClick={ () => {
                let tmp = {
                  page: 1,
                  is_paginated: true
                }
                this.props.onChangeAVContractHOC( 'searchParams', {
                  company_name: '',
                  is_agreed: ''
                })
                this.props.getAllContracts( tmp )
              }}>{ Lang[ 'RESET' ][ this.props.selectedLanguage ] }</Button>
          </CardFooter>
        </Card>
        <ARKTable
          data={ this.props.contracts.data }
          columns={[
            {
              Header: Lang[ 'CLIENT' ][ this.props.selectedLanguage ],
              accessor: 'client',
              Cell: ({ value }) => value.name
            },
            {
              Header: Lang[ 'TYPE' ][ this.props.selectedLanguage ],
              accessor: 'is_new_contract',
              Cell: ({ value }) => value ? Lang[ 'NEW_CONTRACT' ][ this.props.selectedLanguage ] : Lang[ 'OLD_CONTRACT' ][ this.props.selectedLanguage ]
            },
            {
              Header: Lang[ 'CONTRACT_TITLE' ][ this.props.selectedLanguage ],
              accessor: 'gdpr_template',
              Cell: ({ value }) => value.name[ this.props.selectedLanguage ]
            },
            {
              Header: Lang[ 'VERSION' ][ this.props.selectedLanguage ],
              accessor: 'gdpr_template',
              Cell: ({ value }) => value.version !== '0' && value.version
            },
            {
              Header: Lang[ 'AGREED_AT' ][ this.props.selectedLanguage ],
              accessor: 'agreed_datetime',
              Cell: ({ value }) => value !== null && Moment( value ).format( 'DD.MM.YYYY ' )
            },
            {
              Header: Lang[ 'ACTION' ][ this.props.selectedLanguage ],
              accessor: "id",
              Cell: ( row ) => (
                <>
                  <Button
                    id={ `Contracts-${ row.original.id }-view-contract` }
                    className="mb-2 mr-2 btn-icon btn-icon-only"
                    color="primary"
                    onClick={ () => {
                      this.props.getSelectedContract( row.original.id )
                      this.props.onChangeAVContractHOC( 'showViewContract', true )
                    }}>
                    <i className="pe-7s-look"></i>
                  </Button>
                  <UncontrolledTooltip
                    target={ `Contracts-${ row.original.id }-view-contract` }
                    placement="top">{ Lang[ 'VIEW_CONTRACT' ][ this.props.selectedLanguage ] }</UncontrolledTooltip>
                  {
                    this.handleSecurityModulesUpdate() &&
                      <>
                        <Button
                          id={ `Contracts-${ row.original.id }-edit-contract` }
                          className="mb-2 mr-2 btn-icon btn-icon-only"
                          color="primary"
                          onClick={ () => {
                            this.props.getSelectedContract( row.original.id )
                            this.props.onChangeAVContractHOC( 'showEditContract', true )
                          }}>
                          <i className="pe-7s-note"> </i>
                        </Button>
                        <UncontrolledTooltip
                          target={ `Contracts-${ row.original.id }-edit-contract` }
                          placement="top">{ Lang[ 'EDIT_CONTRACT' ][ this.props.selectedLanguage ] }</UncontrolledTooltip>
                      </>
                  }
                  <Button
                    id={ `Contracts-${ row.original.id }-download-pdf` }
                    className="mb-2 mr-2 btn-icon btn-icon-only"
                    color="success"
                    onClick={ () => {
                      if( row.original.is_new_contract ) {
                        this.props.downloadContractPDF( row.original.id, row.original ) 
                      } else {
                        window.location.href = row.original.contract_pdf_url
                      }
                    }}>
                    <i className="pe-7s-download"></i>
                  </Button>
                  <UncontrolledTooltip
                    target={ `Contracts-${ row.original.id }-download-pdf` }
                    placement="top">{ Lang[ 'DOWNLOAD_PDF' ][ this.props.selectedLanguage ] }</UncontrolledTooltip>
                </>
              )
            }
          ]}
          totalPages={ this.props.contractTotalPages }
          page={ this.props.contracts.meta ? this.props.contracts.meta.current_page : 1 }
          onChangePage={ pg => {
            let tmp = {
              page: pg,
              is_paginated: true,
              filter: {
                company_name: {
                  $like: `%${ this.props.searchParams.company_name ? this.props.searchParams.company_name : '' }%`
                },
                is_agreed: this.props.searchParams.is_agreed === 'true' ? true : this.props.searchParams.is_agreed === 'false' ? false : null
              }
            }
            return this.props.getAllContracts( tmp )
          }}
          showPagination={ false } />
          { this.renderCreateModal() }
          { this.renderEditModal() }
          { this.renderViewModal() }
          { this.props.onLoadAVContracts && <LoadingModal /> }
      </>
    )
  }
}

export default compose(
  AVContractHOC,
  AVContractTemplateHOC
)( Listings )
