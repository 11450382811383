import React from 'react'
import {
  Card, CardHeader, CardBody,
  FormGroup, Input,
  Label, Row, Col
} from 'reactstrap'
import _ from 'lodash'

import Lang from 'Lang/General'
import MediaDatabase from 'SharedModules/MediaDatabase'
import ImageDisplay from 'components/Image/ImageDisplay'
import { getButtonColorType, getModifiedColorTypeStyling } from 'components/ButtonSettings/utils'
import ColorPickerInput from 'components/ColorPicker'

const TeamForm = props => {
  let {
    onChangeSectionEditorState,
    onChangeSectionHOC,
    toggleMediaDatabase,
    lang,
    selectedSection
  } = props

  const findAndChangeTargetValue = ( data, target, value, parent, key )=> {
    if( data.mapWith === target ){
      if( key === undefined ){
        data[ parent ] = { ...data.style, ...value }
      } else {
        data[ parent ][ key ] = value
      }
    } else {
      if( data.children && data.children.length > 0 ){
        data.children.map( child => {
          findAndChangeTargetValue( child, target, value, parent, key )
        })
      }
    }
  }

  const percentToHex = value => {
    const intValue = Math.round( value * 255 )
    const hexValue = intValue.toString( 16 )
    return hexValue.padStart( 2, '0' ).toUpperCase()
  }

  return (
    <>
      <Card className="mb-2">
        <CardHeader>{ Lang[ 'TEAM_SETTINGS' ][ lang ] }</CardHeader>
        <CardBody>
          <Row>
            <Col xl={6} md={12} xs={6}>
              <Card className="p-2 mb-2">
                <Label style={{ fontWeight: 'bold' }}>{ Lang[ 'DEPARTMENT_TITLE' ][ lang ] }</Label>
                <Row>
                  <ColorPickerInput
                    id={ 'department-title-color' }
                    size={ 12 }
                    label={ Lang[ 'COLOR' ][ lang ] }
                    color={ selectedSection.attr.department.title.color || '' }
                    colorType={ getButtonColorType( selectedSection.attr.department.title, 'color' ) }
                    selectedLanguage={ lang }
                    onChange={ col => {
                      let tmp = _.cloneDeep( selectedSection )
                      tmp.attr.department.title.color = col.hex
                      findAndChangeTargetValue( tmp, "card-container-title", tmp.attr.department.title, 'style' )
                      onChangeSectionHOC( 'selectedSection', tmp )
                    }} 
                    onChangeColorType={ type => {
                      let tmp = _.cloneDeep( selectedSection )
                      tmp.attr.department.title = getModifiedColorTypeStyling( type, selectedSection.attr.department.title, 'color' ) 
                      findAndChangeTargetValue( tmp, "card-container-title", tmp.attr.department.title, 'style' )
                      onChangeSectionHOC( 'selectedSection', tmp )
                    }} 
                  />
                </Row>
              </Card>
            </Col>
            <Col xl={6} md={12} xs={6}>
              <Card className="p-2 mb-2">
                <Label style={{ fontWeight: 'bold' }}>{ Lang[ 'CONTAINER' ][ lang ] }</Label>
                <Row>
                  <ColorPickerInput
                    id={ 'container-background' }
                    size={ 12 }
                    label={ Lang[ 'BACKGROUND_COLOR' ][ lang ] }
                    color={ selectedSection.attr.container.backgroundColor || '#ffffff' }
                    colorType={ getButtonColorType( selectedSection.attr.container , 'backgroundColor' ) }
                    selectedLanguage={ lang }
                    onChange={ col => {
                      let tmp = _.cloneDeep( selectedSection )
                      tmp.attr.container.backgroundColor = col.hex + percentToHex( col.rgb.a )
                      findAndChangeTargetValue( tmp, "content-box", tmp.attr.container, 'style' )
                      onChangeSectionHOC( 'selectedSection', tmp )
                    }} 
                    onChangeColorType={ type => {
                      let tmp = _.cloneDeep( selectedSection )
                      tmp.attr.container = getModifiedColorTypeStyling( type, selectedSection.attr.container, 'backgroundColor' )
                      findAndChangeTargetValue( tmp, "content-box", tmp.attr.container, 'style' )
                      onChangeSectionHOC( 'selectedSection', tmp )
                    }} 
                  />
                </Row>
              </Card>
            </Col>
          </Row>
          <Label style={{ fontWeight: 'bold' }}>{ Lang[ 'MEMBER' ][ lang ] }</Label>
          <Row>
            <Col xl={6} md={12} xs={6}>
              <Card className="p-2 mb-2">
                <Label style={{ fontWeight: 'bold' }}>{ Lang[ 'NAME' ][ lang ] }</Label>
                <Row>
                  <ColorPickerInput
                    id={ 'name-color' }
                    size={ 7 }
                    label={ Lang[ 'COLOR' ][ lang ] }
                    color={ selectedSection.attr.card.title.color || '#ffffff' }
                    colorType={ getButtonColorType( selectedSection.attr.card.title, 'color' ) }
                    selectedLanguage={ lang }
                    onChange={ col => {
                      let tmp = _.cloneDeep( selectedSection )
                      tmp.attr.card.title.color = col.hex
                      findAndChangeTargetValue( tmp, "team-name", tmp.attr.card.title, 'style' )
                      onChangeSectionHOC( 'selectedSection', tmp )
                    }} 
                    onChangeColorType={ type => {
                      let tmp = _.cloneDeep( selectedSection )
                      tmp.attr.card.title = getModifiedColorTypeStyling( type, selectedSection.attr.card.title, 'color' ) 
                      findAndChangeTargetValue( tmp, "team-name", tmp.attr.card.title, 'style' )
                      onChangeSectionHOC( 'selectedSection', tmp )
                    }} 
                  />
                  <Col md={5}>
                    <Label>{ Lang[ 'FONT_SIZE' ][ lang ] }</Label>
                    <Input
                      type="number"
                      value={ parseInt( selectedSection.attr.card.title.fontSize ) }
                      min={0}
                      onChange={ e => {
                        let tmp = _.cloneDeep( selectedSection )
                        tmp.attr.card.title.fontSize = `${ e.target.value }px`
                        findAndChangeTargetValue( tmp, "team-name", tmp.attr.card.title, 'style' )
                        onChangeSectionHOC( 'selectedSection', tmp )
                      }}/>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xl={6} md={12} xs={6}>
              <Card className="p-2 mb-2">
                <Label style={{ fontWeight: 'bold' }}>{ Lang[ 'SLOGAN' ][ lang ] }</Label>
                <Row>
                  <ColorPickerInput
                    id={ 'slogan-color' }
                    size={ 7 }
                    label={ Lang[ 'COLOR' ][ lang ] }
                    color={ selectedSection.attr.card.text.color || '#ffffff' }
                    colorType={ getButtonColorType( selectedSection.attr.card.text, 'color' ) }
                    selectedLanguage={ lang }
                    onChange={ col => {
                      let tmp = _.cloneDeep( selectedSection )
                      tmp.attr.card.text.color = col.hex
                      findAndChangeTargetValue( tmp, "team-slogan", tmp.attr.card.text, 'style' )
                      onChangeSectionHOC( 'selectedSection', tmp )
                    }} 
                    onChangeColorType={ type => {
                      let tmp = _.cloneDeep( selectedSection )
                      tmp.attr.card.text = getModifiedColorTypeStyling( type, selectedSection.attr.card.text, 'color' ) 
                      findAndChangeTargetValue( tmp, "team-slogan", tmp.attr.card.text, 'style' )
                      onChangeSectionHOC( 'selectedSection', tmp )
                    }} 
                  />
                  <Col md={5}>
                    <Label>{ Lang[ 'FONT_SIZE' ][ lang ] }</Label>
                    <Input
                      type="number"
                      value={ parseInt( selectedSection.attr.card.text.fontSize ) }
                      min={0}
                      onChange={ e => {
                        let tmp = _.cloneDeep( selectedSection )
                        tmp.attr.card.text.fontSize = `${ e.target.value }px`
                        findAndChangeTargetValue( tmp, "team-slogan", tmp.attr.card.text, 'style' )
                        onChangeSectionHOC( 'selectedSection', tmp )
                      }}/>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={6} md={12} xs={6}>
              <Card className="p-2 mb-2">
                <Label style={{ fontWeight: 'bold' }}>{ Lang[ 'CARD' ][ lang ] }</Label>
                <Row>
                  <ColorPickerInput
                    id={ 'card-background' }
                    size={ 12 }
                    label={ Lang[ 'BACKGROUND_COLOR' ][ lang ] }
                    color={ selectedSection.attr.card.body.backgroundColor || '#ffffff' }
                    colorType={ getButtonColorType( selectedSection.attr.card.body, 'backgroundColor' ) }
                    selectedLanguage={ lang }
                    onChange={ col => {
                      let tmp = _.cloneDeep( selectedSection )
                      tmp.attr.card.body.backgroundColor = col.hex
                      findAndChangeTargetValue( tmp, "team-card", tmp.attr.card.body, 'style' )
                      onChangeSectionHOC( 'selectedSection', tmp )
                    }} 
                    onChangeColorType={ type => {
                      let tmp = _.cloneDeep( selectedSection )
                      tmp.attr.card.body = getModifiedColorTypeStyling( type, selectedSection.attr.card.body, 'backgroundColor' ) 
                      findAndChangeTargetValue( tmp, "team-card", tmp.attr.card.body, 'style' )
                      onChangeSectionHOC( 'selectedSection', tmp )
                    }} 
                  />
                </Row>
              </Card>
            </Col>
            <Col xl={6} md={12} xs={6}>
              <Card className="p-2 mb-2">
                <Label style={{ fontWeight: 'bold' }}>{ Lang[ 'CARD_BUTTON' ][ lang ] }</Label>
                <Row>
                  <ColorPickerInput
                    id={ 'card-button-background' }
                    size={ 12 }
                    label={ Lang[ 'BACKGROUND_COLOR' ][ lang ] }
                    color={ selectedSection.attr.card.footer.backgroundColor || '#ffffff' }
                    colorType={ getButtonColorType( selectedSection.attr.card.footer, 'backgroundColor' ) }
                    selectedLanguage={ lang }
                    onChange={ col => {
                      let tmp = _.cloneDeep( selectedSection )
                      tmp.attr.card.footer.backgroundColor = col.hex
                      findAndChangeTargetValue( tmp, "card-footer", tmp.attr.card.footer, 'style' )
                      onChangeSectionHOC( 'selectedSection', tmp )
                    }} 
                    onChangeColorType={ type => {
                      let tmp = _.cloneDeep( selectedSection )
                      tmp.attr.card.footer = getModifiedColorTypeStyling( type, selectedSection.attr.card.footer, 'backgroundColor' ) 
                      findAndChangeTargetValue( tmp, "card-footer", tmp.attr.card.footer, 'style' )
                      onChangeSectionHOC( 'selectedSection', tmp )
                    }} 
                  />
                </Row>
              </Card>
            </Col>
          </Row>
          <FormGroup>
            <Label style={{ fontWeight: 'bold' }}>{ Lang[ 'BACKGROUND_IMAGE' ][ lang ] }</Label>
            <ImageDisplay
              showMediaDatabase={ () => {
                onChangeSectionEditorState( 'mediaDatabaseFor', 'team' )
                toggleMediaDatabase( true )
              }}
              url={ selectedSection.attr.backgroundImage }
              lang={ lang }
              removeImage={ () => {
                let tmp = _.cloneDeep( selectedSection )
                tmp.attr.backgroundImage = ""
                findAndChangeTargetValue( tmp, "background-image", '', 'style', "background" )
                onChangeSectionHOC( 'selectedSection', tmp )
              }}
            />
          </FormGroup>
        </CardBody>
      </Card>
      <MediaDatabase
        onSelectedImage={( param ) => {
          let tmp = _.cloneDeep( selectedSection )
          tmp.attr.backgroundImage = param
          findAndChangeTargetValue( tmp, "background-image", `url(${ param }) center top / cover no-repeat fixed` , 'style', "background" )
          onChangeSectionHOC( 'selectedSection', tmp )
          toggleMediaDatabase( false )
        }}
        lang={ lang }
        allowed={[ 'jpg', 'jpeg', 'png', 'webp' ]} />
    </>
  )
}

export default TeamForm