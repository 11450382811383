import uniqid from 'uniqid'

export const patchIdsChildren = data => {
  data.id = uniqid()
  if ( data.children ) {
    data.children.map( item => {
      patchIdsChildren( item )
    })
  } else if ( data.carouselItems ) {
    data.carouselItems.map( carouselItem => {
      patchIdsChildren( carouselItem )
    })
  } else if (data.dom) {
    patchIdsChildren( data.dom )
  } else if ( data.caption ){
    patchIdsChildren( data.caption )
  }
}