import React, { useState } from 'react'
import ReactTable from 'react-table'
import { 
  Button, Input,
  Modal, ModalHeader, ModalBody, ModalFooter 
} from 'reactstrap'
import _ from 'lodash'

import DeleteConfirmation from 'components/Modal/delete'

import Lang from 'Lang/General'

const MediaTags = props => {
  const [ showDeleteConfirmation, updateShowDeleteConfirmation ] = useState( false )
  const [ showUpdateModal, updateShowUpdateModal ] = useState( false )
  const [ selectedTag, updateSelectedTag ] = useState( { id: 0 } )

  const renderUpdateTagModal = () => (
    <Modal size='sm' isOpen={ showUpdateModal } >
      <ModalHeader toggle={ () => updateShowUpdateModal( false ) } >
        { Lang[ 'UPDATE' ][ props.data.languageReducer.lang ] }</ModalHeader>
      <ModalBody>
        <Input
          type='text'
          value={ !_.isEmpty( selectedTag ) ? selectedTag.label : '' }
          onChange={ e => updateSelectedTag({ ...selectedTag, label: e.target.value }) } />
      </ModalBody>
      <ModalFooter>
        <Button 
          color='primary'
          onClick={ () => {
            props.updateTag({ ...selectedTag, name: selectedTag.label }) 
            updateShowUpdateModal( false )
          }} >
          { Lang[ 'SUBMIT' ][ props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )
  return (
    <>
      <ReactTable
        columns={[
          {
            Header: Lang[ 'NAME' ][ props.data.languageReducer.lang ],
            accessor: 'label'
          },
          {
            Header: Lang[ 'ACTION' ][ props.data.languageReducer.lang ],
            Cell: ( row ) => <>
              <Button
                color="primary"
                className="btn-icon btn-icon-only mr-2"
                onClick={() => {
                  updateSelectedTag( row.original )
                  updateShowUpdateModal( true )
                }}>
                <i className="pe-7s-pen"> </i>
              </Button>
              <Button
                color="danger"
                className="btn-icon btn-icon-only"
                onClick={() => {
                  updateSelectedTag( row.original )
                  updateShowDeleteConfirmation( true )
                }}>
                <i className="pe-7s-trash"> </i>
              </Button>
             </>
          }
        ]}
        data={ _.sortBy( props.mediaTags, [ 'value' ], [ 'asc' ] ) }
        NoDataComponent={ () => <span></span> } />
      <DeleteConfirmation
        deleteOpen={ showDeleteConfirmation }
        handleModalClose={ () => updateShowDeleteConfirmation( false ) }
        confirmAction={ () => {
          !_.isEmpty( selectedTag ) && props.removeTag( selectedTag.id )
          updateShowDeleteConfirmation( false )
        }}
        content={ Lang[ 'ARE_YOU_SURE_DELETE_SELECTED_TAG' ][ props.data.languageReducer.lang ] }
        lang={ props.data.languageReducer.lang } />
      { renderUpdateTagModal() }
    </>
  )
}

export default MediaTags