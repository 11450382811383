import React, { useEffect } from 'react'
import { IoMdCopy } from 'react-icons/io'
import {
  Button,
  Input,
  Label,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup, 
  InputGroupAddon,
  UncontrolledTooltip,
  FormFeedback
} from 'reactstrap'
import _ from 'lodash'

import LoadingOverlay from "components/Indicator/LoadingOverlay"
import Lang from 'Lang/General'

const AnchorLinkCreateModal = props => {

  useEffect( () => {
    if( !props.anchorLinkModalOpen ){
      props.onChangeAnchorLinkHOC( 'anchorError', {} )
    }
  }, [ props.anchorLinkModalOpen ] )

  const onSubmit = () => {
    if( props.isUpdate ){
      props.updateAnchorLink( props.anchorLink )
    } else {
      let tmpData = _.cloneDeep( props.anchorLink )
      tmpData = {
        ...tmpData,
        anchor_id: props.anchor_id,
        ols_landing_page_template_id: props.page_id
      }
      props.createAnchorLink( tmpData )
    }
  }
  
  return(
    <Modal
      isOpen={ props.anchorLinkModalOpen }>
      <ModalHeader toggle={ () => props.onChangeAnchorLinkHOC( 'anchorLinkModalOpen', false ) }>
        { Lang[ props.isUpdate ? 'UPDATE_ANCHOR_LINK' : 'SET_ANCHOR_LINK' ][ props.selectedLanguage ] }
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>{ Lang[ 'NAME' ][ props.selectedLanguage ] }</Label>
          <Input
            type="text"
            value={ props.anchorLink.name }
            invalid={ props.anchorError.name } 
            onChange={ e => {
              let tmpData = _.cloneDeep( props.anchorLink )
              tmpData['name'] = e.target.value
              props.onChangeAnchorLinkHOC( props.isUpdate ? 'selectedAnchorLink' : 'anchorLinkData', tmpData )
            }} />
            <FormFeedback>{ props.anchorError.name }</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'ANCHOR_PATH' ][ props.selectedLanguage ] }</Label>
          <InputGroup>
            <Input
              type="text"
              value={ props.anchorLink.path }
              invalid={ props.anchorError.path } 
              onChange={ e => {
                let tmpData = _.cloneDeep( props.anchorLink )
                tmpData['path'] = e.target.value
                props.onChangeAnchorLinkHOC( props.isUpdate ? 'selectedAnchorLink' : 'anchorLinkData', tmpData )
              }} />
            <InputGroupAddon addonType="append">
              <Button 
                disabled={ _.isEmpty( props.anchorLink.name ) }
                id="generate_anchor_path"
                onClick={ () => {
                  let tmpData = _.cloneDeep( props.anchorLink )
                  tmpData['path'] = props.anchorLink.name.replaceAll( ' ', '_' ).toLowerCase()
                  if( !/^([a-z])/.test( tmpData.path ) ){
                    tmpData['path'] = 'l' + tmpData['path']
                  }
                  props.onChangeAnchorLinkHOC( props.isUpdate ? 'selectedAnchorLink' : 'anchorLinkData', tmpData )
                }}>
                <IoMdCopy/>
              </Button>
              <UncontrolledTooltip
                target="generate_anchor_path"
                placement="bottom">
                { Lang[ 'GENERATE_ANCHOR_PATH' ][ props.selectedLanguage ] }
              </UncontrolledTooltip>
            </InputGroupAddon>
            <FormFeedback>
              { props.anchorError.path }
            </FormFeedback>
          </InputGroup>
          {
            !props.anchorError.path && ( 
              <Label style={{ fontSize: "12px" }}>
                { Lang[ 'ANCHOR_PATH_NOTE' ][ props.selectedLanguage ] }
              </Label>
            )
          }
        </FormGroup>
        {
          props.onLoadAnchorLink && <LoadingOverlay />
        }
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={ onSubmit }>
          { Lang[ 'SUBMIT' ][ props.selectedLanguage ] }
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default AnchorLinkCreateModal
