import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get } from 'utils/axios'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state={
      loading: false,
      centerGroups: []
    }

    load = param => this.setState({ loading: param })

    getCenterGroups = query => Get(
      `/api/v1/center_groups?query=${ query }`,
      this.getCenterGroupsSuccess,
      this.getCenterGroupsError,
      this.load
    )
    getCenterGroupsSuccess = payload => { 
      this.setState({ 
        centerGroups: payload.data.map( item => ({ id: item.id, label: item.name, value: item.name }) ) 
      })
    }
      getCenterGroupsError = error => toast.error( error )

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          onLoadCenterGroupsHOC={ this.state.loading }
          centerGroups={ this.state.centerGroups }
          getCenterGroups={ this.getCenterGroups } />
      )
    }
  }

  return WithHOC
}

export default HOC