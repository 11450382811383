import React, { Component } from 'react'
import { ToastContainer } from 'react-toastify'
import { Grid } from '@material-ui/core'
import { compose } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import Header from './Header/index'
import Renderer from './Renderer/index'
import SectionPickerDialog from './SectionPicker'
import PageCreator from './PageCreator'
import PageEdit from './PageEdit'
import LoadingModal from './components/loading'
import SectionEditor from './SectionEditor'
import TemplateStylesheetDialog from './TemplateStylesheetDialog'
import TemplateJSDialog from './TemplateJavascriptDialog'
import PageJSONEditorDialog from './PageJSONEditor'
import HeaderEditorDialog from './HeaderEditor'
import LanguageDialog from './LanguageDialog'
import RendererViewportAdjust from './RendererViewportAdjust'
import FooterEditorDialog from './FooterEditor'
import ConfirmDeleteDialog from './components/confirmModal'

import PageHOC from './actions/pages'
import TemplateStylesheetHOC from './actions/templateStylesheet'
import TemplateJSHOC from './actions/templateJavascript'
import LanguageHOC from './actions/languages'
import TemplateHOC from './actions/template'
import TemplateHeaderHOC from './actions/header'
import TemplateHeaderBlueprintHOC from './actions/headerBlueprint'
import TemplateFooterBlueprintHOC from './actions/footerBlueprint'
import HeaderMenuHOC from './actions/headerMenu'
import TemplateFootersHOC from './actions/footers'

import WithPatchJSON from './utility/patchJSON'

import Lang from 'Lang/General'
import './index.scss'

class TemplateEditor extends Component {
  state = {
    templateName: '',
    showSectionPicker: false,
    showTemplateStylesheetDialog: false,
    showTemplateJSDialog: false,
    showPageJSONEditorDialog: false,
    showHeaderEditorDialog: false,
    showLanguageDialog: false,
    showStylingDialog: false,
    showRendererViewportAdjust: false,
    showFooterEditorDialog: false,
    showSectionEditor: false,
    showConfirmRemoveDialog: false,
    selectedSectionID: '',
    selectedLanguageShortname: 'en',
    selectedComponent: {},
    selectedViewportRenderer: 'desktop',
    hoveredSectionID: '' 
  }

  componentDidMount = () => {
    this.props.getSelectedTemplate( this.props.match.params.template_id )
    this.props.getPages( this.props.match.params.template_id )
    this.props.getTemplateStylesheets( this.props.match.params.template_id )
    this.props.getTemplateJS( this.props.match.params.template_id )
    this.props.getLanguages( this.props.match.params.template_id )
    this.props.getTemplateHeaders( this.props.match.params.template_id )
    this.props.getTemplateHeaderBlueprints( this.props.match.params.template_id )
    this.props.getTemplateFooterBlueprints( this.props.match.params.template_id )
    this.props.getHeaderMenu( this.props.match.params.template_id )
    this.props.getFooters( this.props.match.params.template_id )
    this.setState({ templateName: this.props.match.params.template_name })
  }

  updateMainContainerState = ( key, param ) => this.setState({ [ key ]: param })

  confirmRemovePage = () => {
    this.props.removePage( this.props.match.params.template_id, this.props.selectedPage.id )
    this.props.onChangeShowConfirmRemovePage( false )
  }

  confirmRemovePageSection = () => {
    let tmpPage = _.cloneDeep( this.props.selectedPage )
    let tmpSectionContainer = _.find( tmpPage.content.children, { variant: "sections-container" } )
    tmpSectionContainer.children.splice( _.findIndex( tmpSectionContainer.children, { id: this.state.selectedSectionID } ), 1 )
    this.props.updateSelectedPage( this.props.match.params.template_id, tmpPage.id, tmpPage, 'edit' )
    this.updateMainContainerState("showConfirmRemoveDialog", false )
  }

  render = () => {
    return (
      <Grid
        classes={{ root: 'template-editor' }}
        container
        style={{ backgroundColor: '#f6f7fb' }}>
        <Header
          data={ this.props.data }
          updateMainContainerState={ this.updateMainContainerState }
          selectedTemplateName={ this.props.match.params.template_name }
          template_id={ this.props.match.params.template_id }
          onChangeShowPageCreator={ this.props.onChangeShowPageCreator }
          onChangeShowPageEdit={ this.props.onChangeShowPageEdit }
          pages={ this.props.pages }
          selectedPageID={ this.props.selectedPageID }
          updateSelectedPage={ this.props.updateSelectedPage }
          updateSelectedPageID={ this.props.updateSelectedPageID }
          selectedPage={ this.props.selectedPage }
          selectedTemplate={ this.props.selectedTemplate }
          updateSiteTemplate={ this.props.updateSiteTemplate }
          getSelectedPage={ this.props.getSelectedPage }
          onChangeShowConfirmRemovePage={ this.props.onChangeShowConfirmRemovePage }
          lang={ this.props.data.languageReducer.lang } />
        {/* <UtilityHeader /> */}
        <Renderer
          updateMainContainerState={ this.updateMainContainerState }
          selectedSectionID={ this.state.selectedSectionID }
          selectedComponent={ this.state.selectedComponent }
          selectedLanguageShortname={ this.state.selectedLanguageShortname }
          selectedViewportRenderer={ this.state.selectedViewportRenderer }
          onChangeSelectedPage={ this.props.onChangeSelectedPage }
          selectedPage={ this.props.selectedPage }
          templateStylesheets={ this.props.templateStylesheets }
          templateJavascripts={ this.props.templateJavascripts }
          languages={ this.props.languages }
          templateHeaders={ this.props.templateHeaders }
          templateHeadersBlueprints={ this.props.templateHeadersBlueprints }
          templateFooterBlueprints={ this.props.templateFooterBlueprints }
          templateFooters={ this.props.templateFooters }
          headerMenuContent={ this.props.headerMenuContent }
          lang={ this.props.data.languageReducer.lang }
          hoveredSectionID={ this.state.hoveredSectionID } />
        {
          this.state.selectedSectionID !== '' && (
            <SectionEditor
              isOpen={ this.state.showSectionEditor }
              templateStylesheets={ this.props.templateStylesheets }
              templateJavascripts={ this.props.templateJavascripts }
              selectedSectionID={ this.state.selectedSectionID }
              showSectionEditor={ this.state.showSectionEditor }
              languages={ this.props.languages }
              selectedLanguageShortname={ this.state.selectedLanguageShortname }
              updateMainContainerState={ this.updateMainContainerState }
              pages ={ this.props.pages }
              lang={ this.props.data.languageReducer.lang } />
          )
        }
        <SectionPickerDialog
          selectedPage={ this.props.selectedPage }
          selectedSectionID={ this.state.selectedSectionID }
          updateSelectedPage={ this.props.updateSelectedPage }
          template_id={ this.props.match.params.template_id }
          showSectionPicker={ this.state.showSectionPicker }
          updateMainContainerState={ this.updateMainContainerState }
          templateStylesheets={ this.props.templateStylesheets }
          templateJavascripts={ this.props.templateJavascripts }
          languages={ this.props.languages }
          selectedLanguageShortname={ this.state.selectedLanguageShortname }
          lang={ this.props.data.languageReducer.lang } />
        <PageCreator
          templateName={ this.state.templateName }
          updateMainContainerState={ this.updateMainContainerState }
          showPageCreator={ this.props.showPageCreator }
          createPage={ this.props.createPage }
          template_id={ this.props.match.params.template_id }
          onChangeNewPage={ this.props.onChangeNewPage }
          newPage={ this.props.newPage }
          onChangeShowPageCreator={ this.props.onChangeShowPageCreator }
          onLoadPagesUpdate={ this.props.onLoadPagesUpdate }
          lang={ this.props.data.languageReducer.lang } />
        <PageEdit
          showPageEdit={ this.props.showPageEdit }
          onChangeShowPageEdit={ this.props.onChangeShowPageEdit }
          template_id={ this.props.match.params.template_id }
          updateMainContainerState={ this.updateMainContainerState }
          onChangeSelectedPage={ this.props.onChangeSelectedPage }
          selectedPage={ this.props.selectedPage }
          updateSelectedPage={ this.props.updateSelectedPage }
          onLoadPagesUpdate={ this.props.onLoadPagesUpdate }
          languages={ this.props.languages }
          lang={ this.props.data.languageReducer.lang } />
        <TemplateStylesheetDialog
          showTemplateStylesheetDialog={ this.state.showTemplateStylesheetDialog }
          updateMainContainerState={ this.updateMainContainerState }
          templateStylesheets={ this.props.templateStylesheets }
          createTemplateStylesheet={ this.props.createTemplateStylesheet }
          removeTemplateStylesheet={ this.props.removeTemplateStylesheet }
          updateTemplateStylesheet={ this.props.updateTemplateStylesheet }
          onChangeTemplateStylesheets={ this.props.onChangeTemplateStylesheets }
          template_id={ this.props.match.params.template_id }
          onLoadTemplateStylesheet={ this.props.onLoadTemplateStylesheet }
          onLoadUpdate={ this.props.onLoadUpdate }
          lang={ this.props.data.languageReducer.lang } />
        <TemplateJSDialog
          showTemplateJSDialog={ this.state.showTemplateJSDialog }
          updateMainContainerState={ this.updateMainContainerState }
          templateJavascripts={ this.props.templateJavascripts }
          createTemplateJS={ this.props.createTemplateJS }
          updateTemplateJS={ this.props.updateTemplateJS }
          removeTemplateJS={ this.props.removeTemplateJS }
          onChangeTemplateJS={ this.props.onChangeTemplateJS }
          template_id={ this.props.match.params.template_id }
          onLoadTemplateJS={ this.props.onLoadTemplateJS }
          onLoadJSUpdate={ this.props.onLoadJSUpdate }
          lang={ this.props.data.languageReducer.lang } />
        <PageJSONEditorDialog
          showPageJSONEditorDialog={ this.state.showPageJSONEditorDialog }
          updateMainContainerState={ this.updateMainContainerState }
          selectedPage={ this.props.selectedPage }
          onChangeSelectedPage={ this.props.onChangeSelectedPage }
          updateSelectedPage={ this.props.updateSelectedPage }
          template_id={ this.props.match.params.template_id }
          onLoadPages={ this.props.onLoadPages }
          onLoadPagesUpdate={ this.props.onLoadPagesUpdate }
          onSearchPageJSON={ this.props.onSearchPageJSON }
          lang={ this.props.data.languageReducer.lang } />
        <HeaderEditorDialog
          showHeaderEditorDialog={ this.state.showHeaderEditorDialog }
          selectedLanguageShortname={ this.state.selectedLanguageShortname }
          updateMainContainerState={ this.updateMainContainerState }
          languages={ this.props.languages }
          pages={ this.props.pages }
          selectedPage={ this.props.selectedPage }
          patchJSONDOM={ this.props.patchJSONDOM }
          patchIDsDOM={ this.props.patchIDsDOM }
          patchJSONOnly={ this.props.patchJSONOnly }
          updateSelectedPage={ this.props.updateSelectedPage }
          template_id={ this.props.match.params.template_id }
          templateHeaders={ this.props.templateHeaders }
          templateHeadersBlueprints={ this.props.templateHeadersBlueprints }
          headerMenuContent={ this.props.headerMenuContent }
          customHeaderBlueprints={ this.props.customHeaderBlueprints }
          updateTemplateHeader={ this.props.updateTemplateHeader }
          onChangeSelectedHeaderBlueprints={ this.props.onChangeSelectedHeaderBlueprints }
          onSelectHeaderBlueprint={ this.props.onSelectHeaderBlueprint }
          selectedHeaderBlueprint={ this.props.selectedHeaderBlueprint }
          updateTemplateHeaderBlueprint={ this.props.updateTemplateHeaderBlueprint }
          updateTemplateHeaderBlueprintItemColor={ this.props.updateTemplateHeaderBlueprintItemColor }
          templateStylesheets={ this.props.templateStylesheets }
          onLoadHeaderBlueprintUpdate={ this.props.onLoadHeaderBlueprintUpdate }
          onLoadHeaderUpdate={ this.props.onLoadHeaderUpdate }
          onLoadPagesUpdate={ this.props.onLoadPagesUpdate }
          getSelectedPage={ this.props.getSelectedPage }
          lang={ this.props.data.languageReducer.lang } />
        <FooterEditorDialog
          showFooterEditorDialog={ this.state.showFooterEditorDialog }
          selectedLanguageShortname={ this.state.selectedLanguageShortname }
          updateMainContainerState={ this.updateMainContainerState }
          languages={ this.props.languages }
          patchJSONDOM={ this.props.patchJSONDOM }
          template_id={ this.props.match.params.template_id }
          templateFooterBlueprints={ this.props.templateFooterBlueprints }
          updateTemplateFooterBlueprint={ this.props.updateTemplateFooterBlueprint }
          updateTemplateFooterBlueprintStyling={ this.props.updateTemplateFooterBlueprintStyling }
          onLoadFooterBlueprintsUpdate={ this.props.onLoadFooterBlueprintsUpdate }
          lang={ this.props.data.languageReducer.lang } />
        <LanguageDialog
          showLanguageDialog={ this.state.showLanguageDialog }
          selectedLanguageShortname={ this.state.selectedLanguageShortname }
          updateMainContainerState={ this.updateMainContainerState }
          languages={ this.props.languages }
          createLanguage={ this.props.createLanguage }
          removeLanguage={ this.props.removeLanguage }
          updateLanguage={ this.props.updateLanguage }
          onChangeSelectedLanguage={ this.props.onChangeSelectedLanguage }
          template_id={ this.props.match.params.template_id }
          onLoadLanguagesUpdate={ this.props.onLoadLanguagesUpdate }
          lang={ this.props.data.languageReducer.lang } />
        <RendererViewportAdjust
          showRendererViewportAdjust={ this.state.showRendererViewportAdjust }
          updateMainContainerState={ this.updateMainContainerState }
          selectedViewportRenderer={ this.state.selectedViewportRenderer }
          lang={ this.props.data.languageReducer.lang } />
        <LoadingModal
          show={
            this.props.onLoadPages ||
            this.props.onLoadTemplateStylesheet ||
            this.props.onLoadTemplateJS ||
            this.props.onLoadLanguages ||
            this.props.onLoadSiteTemplate ||
            this.props.onLoadTemplateHeaders ||
            this.props.onLoadTemplateHeaderBlueprints ||
            this.props.onLoadTemplateFooterBlueprints
          } />
        <ConfirmDeleteDialog
          open={ this.props.showConfirmRemovePage }
          content={ 'Are you sure to remove this page?' }
          confirmAction={ () => this.confirmRemovePage() }
          handleModalClose={ () => this.props.onChangeShowConfirmRemovePage( false ) }
          lang={ this.props.data.languageReducer.lang } />
        <ConfirmDeleteDialog
            open={ this.state.showConfirmRemoveDialog }
            content={ Lang[ "DELETE_CONFIRMATION_SECTION" ][ this.props.data.languageReducer.lang ] }
            confirmAction={ () => this.confirmRemovePageSection() }
            handleModalClose={ () => this.setState({ showConfirmRemoveDialog: false }) }
            lang={ this.props.data.languageReducer.lang }
          />
        <ToastContainer/>
      </Grid>
    )
  }
}

const mapStateToProps = state => ({ data: state })

export default compose(
  TemplateHOC,
  PageHOC,
  TemplateStylesheetHOC,
  TemplateJSHOC,
  LanguageHOC,
  TemplateHeaderHOC,
  TemplateFootersHOC,
  TemplateHeaderBlueprintHOC,
  TemplateFooterBlueprintHOC,
  HeaderMenuHOC,
  connect( mapStateToProps ),
  WithPatchJSON
)( TemplateEditor )
