import React from "react"
import Radium from "proofpoint-radium"
import ReactHTMLParser from "react-html-parser"
import { FormGroup, Label } from "reactstrap"
import Lang from "Lang/General"

const Select = ({
  id,
  style,
  padding,
  marginBottom,
  label,
  className,
  selectedLanguageShortname ,
  option
}) => {
  return (
    <FormGroup style={{ padding: padding, marginBottom: marginBottom }}>
      <Label>{ label ? ReactHTMLParser( label[ selectedLanguageShortname ] ) : "" }</Label>
      <select id={ id } style={ style } className={ className }>
        <option>{ Lang[ "PLEASE_SELECT" ][ selectedLanguageShortname ] }</option>
        { option &&
          option.length > 0 &&
          option.map( ( item ) => {
            return (
              <option value={ item.value[ selectedLanguageShortname ] }>
                { ReactHTMLParser( item.value[ selectedLanguageShortname ] ) }
              </option>
            )
          })}
      </select>
    </FormGroup>
  )
}

export default Radium( Select )
