import React, { Component } from 'react'
import { Dialog, Button, Grid, Tooltip, Chip } from '@material-ui/core'
import { IoIosClose, IoMdCheckmark } from 'react-icons/io'
import { MdClose } from 'react-icons/md'

import TemplateEditorCard from '../components/card'
import LoadingModal from 'components/Indicator/LoadingOverlay'

import './index.scss'

import Lang from 'Lang/General'

class LanguageDialog extends Component {
  render = () => {
    return (
      <Dialog
        open={ this.props.showLanguageDialog }
        maxWidth={ 'lg' }
        fullWidth={ true }>
        <TemplateEditorCard>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h4>{ Lang[ 'TEMPLATE_LANGUAGE_EDITOR' ][ this.props.lang ] }</h4>
            <Button
              classes={{
                root: "button-close",
                label: "button-text" }}
              onClick={ () => this.props.updateMainContainerState( 'showLanguageDialog', false ) }>
              <MdClose style={{ fontSize: '20px' }}/>
            </Button>
          </div>
          <hr />
          <div className="section-container">
            <Grid container>
              <Grid item md={ 12 }>
                {
                  this.props.languages && this.props.languages.map(( item, index ) => {
                    return (
                      <Grid key={ item.id } item md={ 12 } style={{ paddingBottom: '15px' }}>
                        <input
                          className="form-control"
                          style={{ width: '300px', display: 'inline-block', marginRight: '15px' }}
                          value={ item.name }
                          onChange={ e => this.props.onChangeSelectedLanguage( 'name', index, e.target.value ) }
                          placeholder={ Lang[ 'INPUT_LONG_NAME' ][ this.props.lang ] }
                          disabled={ index === 0 } />
                        <input
                          className="form-control"
                          style={{ width: '300px', display: 'inline-block', marginRight: '15px' }}
                          value={ item.shortname }
                          onChange={ e => this.props.onChangeSelectedLanguage( 'shortname', index, e.target.value ) }
                          placeholder={ Lang[ 'INPUT_SHORT_NAME' ][ this.props.lang ] }
                          disabled={ index === 0 } />
                        <select
                          className="form-control"
                          style={{ width: '150px', display: 'inline-block', marginRight: '15px' }}
                          value={ item.status }
                          onChange={ e => this.props.onChangeSelectedLanguage( 'status', index, e.target.value ) }>
                          <option value="ACTIVE">{ 'ACTIVE' }</option>
                          <option value="INACTIVE">{ 'INACTIVE' }</option>
                        </select>
                        <div style={{ display: 'inline-block' }}>
                          <Tooltip title={ Lang[ 'UPDATE_STYLESHEET' ][ this.props.lang ] }>
                            <Button
                              classes={{ root: 'btn btn-primary' }}
                              variant={ 'contained' }
                              onClick={() => this.props.updateLanguage( this.props.template_id, item.id, {
                                name: item.name,
                                shortname: item.shortname,
                                status: item.status
                              })}>
                              <IoMdCheckmark className="icon" />
                            </Button>
                          </Tooltip>
                        </div>
                        <div style={{ display: 'inline-block', marginRight: '10px' }}>
                          <Tooltip title={ Lang[ 'REMOVE_STYLESHEET' ][ this.props.lang ] }>
                            <Button
                              classes={{ root: 'btn btn-danger' }}
                              variant={ 'contained' }
                              onClick={() => this.props.removeLanguage( this.props.template_id, item.id )}
                              disabled={ index === 0 }>
                              <IoIosClose className="icon" />
                            </Button>
                          </Tooltip>
                        </div>
                        {
                          this.props.selectedLanguageShortname === item.shortname
                            ? ( <Chip color="primary" label={ Lang[ 'SELECTED_LANGUAGE' ][ this.props.lang ] } /> )
                            : (
                              <Tooltip title={ Lang[ 'TOOLTIP_TITLE' ][ this.props.lang ] }>
                                <Button
                                  classes={{ root: 'btn btn-info' }}
                                  variant={ 'contained' }
                                  onClick={() => this.props.updateMainContainerState( 'selectedLanguageShortname', item.shortname )}>
                                  { Lang[ 'SELECT_LANGUAGE_TEMPLATE_EDITOR' ][ this.props.lang ] }
                                </Button>
                              </Tooltip>
                            )
                        }
                      </Grid>
                    )
                  })
                }
              </Grid>
              <Grid item md={ 12 }>
                <Button
                  style={{ marginRight: '10px', backgroundColor: '#eee', width: '100%' }}
                  onClick={() => this.props.createLanguage( this.props.template_id )}>
                  { Lang[ 'CREATE_LANGUAGE' ][ this.props.lang ] }
                </Button>
              </Grid>
            </Grid>
          </div>
          <hr />
          <div className="section-footer">
            <Button
              classes={{ root: 'btn-secondary' }}
              onClick={() => this.props.updateMainContainerState( 'showLanguageDialog', false )}>
              { Lang[ 'CLOSE' ][ this.props.lang ] }
            </Button>
          </div>
          {
            this.props.onLoadLanguagesUpdate && <LoadingModal />
          }
        </TemplateEditorCard>
      </Dialog>
    )
  }
}

export default LanguageDialog
