import React, { useState } from 'react'
import {
  Input, InputGroup, InputGroupAddon,
  FormGroup, Label, CustomInput, Button
} from 'reactstrap'
import _ from 'lodash'

import MediaDatabase from 'SharedModules/MediaDatabase' 
import Lang from 'Lang/General'


const LinkInput = ({
  selectedContainer,
  onUpdate,
  pages,
  anchorLinks,
  setMediaDatabase,
  selectedLanguage
}) => {
  const [ isMediaDatabaseOpen, setIsMediaDatabaseOpen ] = useState( false )

  const renderClickableLinkInput = () => {
    if( selectedContainer.redirectType && selectedContainer.redirectType !== '' ) {
      if( selectedContainer.redirectType === 'internal-link' ) {
        return <Input
          type='select'
          className='mb-2'
          style={{ minWidth: '50%' }}
          value={ selectedContainer.navTo ? selectedContainer.navTo : '' }
          onChange={ e => {
            let tmp = _.cloneDeep( selectedContainer )
            tmp[ 'navTo' ] = e.target.value
            if( tmp.type === 'a' ){
              tmp[ 'href' ] = e.target.value
            } else if( tmp.type === 'button' ){
              !_.isEmpty( e.target.value )
                ? tmp[ 'variant' ] = 'redirect'
                : delete tmp[ 'variant' ]
            }
            onUpdate( tmp )
          }}>
          <option key={ 0 } value={ '' }>{ '' }</option> 
          { 
            pages && _.orderBy(
              _.filter( pages, page => page.url.indexOf( ':' ) < 0 && page.url.indexOf( '404' ) < 0 ),
              pg => pg.url.toLowerCase(),
              [ 'asc' ]
            ).map( 
              item => <option key={ item.id } value={ item.url }>{ item.url }</option> 
            ) 
          }
        </Input>
      } else if( selectedContainer.redirectType === 'external-link' ) {
        return <Input
          type='text'
          className='mb-2'
          style={{ minWidth: '50%' }}
          value={ selectedContainer.navTo 
            ? selectedContainer.navTo.replace( 'mailto:', '' ).replace( 'tel:', '' ) 
            : '' }
          onChange={ ( e ) => {
            let tmp = _.cloneDeep( selectedContainer )
            if( tmp[ 'navTo' ] ){
              tmp[ 'navTo' ] = e.target.value
            }
            if( tmp.type === 'a' ){
              tmp[ 'href' ] = e.target.value
            } 
            if( tmp.type === 'button' ){
              !_.isEmpty( e.target.value )
                ? tmp[ 'variant' ] = 'redirect'
                : delete tmp[ 'variant' ]
            }
            onUpdate( tmp )
          }}
        />
      } else if( selectedContainer.redirectType === 'email-link' ) {
        return <Input
          type='text'
          className='mb-2'
          style={{ minWidth: '50%' }}
          value={ selectedContainer.navTo 
            ? selectedContainer.navTo.replace( 'mailto:', '' ).replace( 'tel:', '' ) 
            : '' }
          onChange={ ( e ) => {
            let tmp = _.cloneDeep( selectedContainer )
            if( tmp[ 'navTo' ] ){
              tmp[ 'navTo' ] = 'mailto:' + e.target.value
            }
            if( tmp.type === 'a' ){
              if( tmp[ 'href' ] ){
                tmp[ 'href' ] = 'mailto:' + e.target.value
              }
            } 
            if( tmp.type === 'button' ){
              !_.isEmpty( e.target.value )
                ? tmp[ 'variant' ] = 'redirect'
                : delete tmp[ 'variant' ]
            }
            onUpdate( tmp )
          }}
        />
      } else if( selectedContainer.redirectType === 'tel-link' ) {
        return <Input
          type='text'
          className='mb-2'
          style={{ minWidth: '50%' }}
          value={ selectedContainer.navTo 
            ? selectedContainer.navTo.replace( 'mailto:', '' ).replace( 'tel:', '' ) 
            : '' }
          onChange={ ( e ) => {
            let tmp = _.cloneDeep( selectedContainer )
            if( tmp[ 'navTo' ] ){
              tmp[ 'navTo' ] = 'tel:' + e.target.value
            }
            if( tmp.type === 'a' ){
              if( tmp[ 'href' ] ){
                tmp[ 'href' ] = 'tel:' + e.target.value
              }
            } 
            if( tmp.type === 'button' ){
              !_.isEmpty( e.target.value )
                ? tmp[ 'variant' ] = 'redirect'
                : delete tmp[ 'variant' ]
            }
            onUpdate( tmp )
          }}
        />
      } else if( selectedContainer.redirectType === 'anchor-link' ) {
        return <Input
          type='select'
          style={{ minWidth: '50%' }}
          value={ selectedContainer.navTo }
          className='mb-2'
          onChange={ e => {
            let tmp = _.cloneDeep( selectedContainer )
            tmp[ 'navTo' ] = e.target.value
            tmp[ 'href' ] = e.target.value
            onUpdate( tmp ) 
          }}>
          <option></option>
          {
            anchorLinks.map( item => {
              return (
                <option 
                  key={ `anchorlinks-${ item.id }` }
                  value={ `#${ item.anchor_id }` }>
                  { item.name }
                </option>
              )
            })
          }
        </Input>
      } else if( selectedContainer.redirectType === 'download-file' ) {
        return <Input
          type='text'
          className='mb-2'
          style={{ minWidth: '50%' }}
          value={ selectedContainer.navTo 
            ? selectedContainer.navTo.replace( 'mailto:', '' ).replace( 'tel:', '' ) 
            : '' } 
          disabled
        />
      }
    } else {
      return (
        <Input
          type='text'
          className='mb-2'
          value=''
          disabled>
        </Input>
      )
    }
  }

  return (
    <>
      <FormGroup>
        <Label>{ Lang[ 'LINK' ][ selectedLanguage ] }</Label>
        <InputGroup>
          <InputGroupAddon>
            <Input 
              type='select'
              value={ selectedContainer.redirectType ? selectedContainer.redirectType : '' }
              style={{ borderRight: "0", borderRadius: "0.25rem 0 0 0.25rem" }}
              onChange={ e => {
                let tmp = _.cloneDeep( selectedContainer )
                delete tmp.isPublicFileUrl
                switch( e.target.value ){
                  case 'internal-link':
                    tmp[ 'navTo' ] = '/'
                    tmp[ 'href' ] = '/'
                    tmp[ 'redirectType' ] = 'internal-link'
                    break
                  case 'external-link':
                    tmp[ 'navTo' ] = 'https://'
                    tmp[ 'href' ] = 'https://'
                    tmp[ 'redirectType' ] = 'external-link'
                    break
                  case 'email-link':
                    tmp[ 'navTo' ] = 'mailto:'
                    tmp[ 'href' ] = 'mailto:'
                    tmp[ 'redirectType' ] = 'email-link'
                    break
                  case 'tel-link':
                    tmp[ 'navTo' ] = 'tel:'
                    tmp[ 'href' ] = 'tel:'
                    tmp[ 'redirectType' ] = 'tel-link'
                    break
                  case 'anchor-link':
                    tmp[ 'navTo' ] = ''
                    tmp[ 'href' ] = ''
                    tmp[ 'redirectType' ] = 'anchor-link'
                    break
                  case 'download-file':
                    tmp[ 'isPublicFileUrl' ] = true
                    tmp[ 'navTo' ] = ''
                    tmp[ 'redirectType' ] = 'download-file'
                    break
                  case '':
                    delete tmp[ 'navTo' ]
                    delete tmp[ 'href' ]
                    delete tmp[ 'redirectType' ]
                    break
                }
                tmp.type === 'button'
                  ? tmp[ 'variant' ] = 'redirect'
                  : delete tmp[ 'variant' ]
                onUpdate( tmp )
              }}>
              <option value=''>{ Lang[ 'NONE' ][ selectedLanguage ] }</option>
              <option value='internal-link'>{ Lang[ 'INTERNAL' ][ selectedLanguage ] }</option>
              <option value='external-link'>{ Lang[ 'EXTERNAL' ][ selectedLanguage ] }</option>
              <option value='email-link'>{ Lang[ 'EMAIL' ][ selectedLanguage ] }</option>
              <option value='tel-link'>{ Lang[ 'TEL' ][ selectedLanguage ] }</option>
              { !_.isEmpty( anchorLinks ) && <option value='anchor-link'>{ Lang[ 'ANCHOR_LINK' ][ selectedLanguage ] }</option> }
              <option value='download-file'>{ Lang[ 'DOWNLOAD_FILE' ][ selectedLanguage ] }</option>
            </Input>
          </InputGroupAddon>
          { renderClickableLinkInput() }
        </InputGroup>
        {
          ( 
            selectedContainer.redirectType && (
              selectedContainer.redirectType === 'internal-link' || 
              selectedContainer.redirectType === 'external-link' ||
              selectedContainer.redirectType === 'anchor-link'
            )
          ) && (
            <CustomInput
              id={ 'linkInNewTab'+selectedContainer.id }
              type='checkbox'
              label={ Lang[ 'OPEN_LINK_IN_NEW_TAB' ][ selectedLanguage ] }
              checked={ selectedContainer.newTab ? selectedContainer.newTab : false }
              onChange={ ( e ) => {
                let tmp = _.cloneDeep( selectedContainer )
                tmp[ 'newTab' ] = e.target.checked
                onUpdate( tmp )
              }} />
          )
        }
        {
          selectedContainer.isPublicFileUrl && <Button 
            color="primary"
            onClick={ () => {
              setMediaDatabase( true )
              setIsMediaDatabaseOpen(true) 
            }}>
            { Lang[ 'PUBLIC_FILE' ][ selectedLanguage ] }
          </Button>
        }
      </FormGroup>
      {
        isMediaDatabaseOpen &&
        <MediaDatabase
          onSelectedImage={ ( param ) => {
            let tmp = _.cloneDeep( selectedContainer )
            tmp[ 'navTo' ] = param
            tmp[ 'href' ] = param
            tmp.type === 'button'
              ? tmp[ 'variant' ] = 'redirect'
              : delete tmp[ 'variant' ]
            Promise.all([
              onUpdate( tmp )
            ]).then(() => {
              setMediaDatabase( false )
              setIsMediaDatabaseOpen( false )
            })
          }}
          selectedLanguage={ selectedLanguage }
          allowed={[ 'pdf', 'zip']} />
      }
    </>                    
  )
}

export default LinkInput