import React, { Component, Fragment } from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import _ from 'lodash'

import Lang from 'Lang/General'

class CurrencyForm extends Component {
  state = {
    selectedNameLanguage: 'en',
    selectedDescriptionLang: 'en',
  }

  updateCurrency = ( key, value ) => {
    let tmp = _.cloneDeep( this.props.currentCurrency )
    tmp[ key ] = value
    this.props.onChangeCurrenciesHOC( this.props.currencyType, tmp )
  }

  render = () => {
    let {
      selectedLanguageShortname,
      currentCurrency
    } = this.props

    return(
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Container fluid>
            <Row>
              <Col md={ 8 } >
                <Form>
                  <FormGroup>
                    <Label>{ Lang[ 'NAME' ][ selectedLanguageShortname ] }</Label>
                    <Input
                      type='text'
                      value={ currentCurrency.name }
                      onChange={ e => this.updateCurrency( 'name', e.target.value ) } />
                  </FormGroup>
                  <FormGroup>
                    <Label>{ Lang[ 'DECIMAL_SEPARATOR' ][ selectedLanguageShortname ] }</Label>
                    <Input
                      type='select'
                      value={ currentCurrency.decimal_separator }
                      onChange={ e => this.updateCurrency( 'decimal_separator', e.target.value ) } >
                      <option value='.' >{ Lang[ 'PERIOD' ][ selectedLanguageShortname ] } ( . )</option>
                      <option value=',' >{ Lang[ 'COMMA' ][ selectedLanguageShortname ] }</option>
                    </Input>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </Container>
        </ReactCSSTransitionGroup>
      </Fragment>
    )
  }
}

export default CurrencyForm