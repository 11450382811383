import React from 'react'
import { Route } from 'react-router-dom'

import MailingTemplates from './MailingTemplates'
import SystemFunnels from './SystemFunnels'
import SystemMailings from './SystemMailings'
import FunnelScenarios from './FunnelScenarios'

const MarketingFunnels = () => {
  return (
    <>
      <Route exact path="/dashboard/marketing-funnels/mailing-templates" component={ MailingTemplates } />
      <Route exact path="/dashboard/marketing-funnels/system-funnels" component={ SystemFunnels } />
      <Route exact path="/dashboard/marketing-funnels/system-mailings" component={ SystemMailings } />
      <Route exact path="/dashboard/marketing-funnels/funnel-scenarios" component={ FunnelScenarios } />
    </>
  )
}

export default MarketingFunnels