import React from 'react'
import Radium from 'proofpoint-radium'
import ReactHTMLParser from 'react-html-parser'

import CoreRenderFunction from '../core/render'
import { ApplyColor } from 'utils/apply-color'

const Button = ({
  children,
  id,
  item,
  mode,
  style,
  content,
  onClick,
  className,
  languages,
  selectedLanguageShortname,
  updateMainContainerState,
  hoveredComponentId,
  onChangeSectionEditorState,
  selectedEditableComponent
}) => {
  let onClickTemp = typeof onClick === "string" ? new Function( onClick ) : onClick
  return (
    <button
      id={ id }
      style={ mode === 'edit' 
        ? {
          ...ApplyColor( style ),
          ...(
            ( ( selectedEditableComponent && selectedEditableComponent.id === id ) || hoveredComponentId === id  ) ? { 
              borderTop: "5px solid #45818e",
              borderRight: "5px solid #45818e",
              borderBottom: "5px solid #45818e",
              borderLeft: "5px solid #45818e"
            } : {}
          )
        } : ApplyColor( style )
      }
      className={ className }
      onMouseEnter={ () => mode === 'edit' && onChangeSectionEditorState && onChangeSectionEditorState( 'hoveredComponentId', id ) } 
      onClick={
        mode === 'edit' 
          ? (
            () => {
              onChangeSectionEditorState && onChangeSectionEditorState( 'selectedContainer', item )
            }
          ) : typeof onClickTemp !== 'undefined' && onClickTemp
      }>
      {
        children && children.length > 0 ? children.map( item => {
          return (
            <CoreRenderFunction
              item={ item }
              mode={ mode }
              updateMainContainerState={ updateMainContainerState }
              languages={ languages }
              selectedLanguageShortname={ selectedLanguageShortname }
              onChangeSectionEditorState={ onChangeSectionEditorState }
              selectedEditableComponent={ selectedEditableComponent } />
          )
        }) 
        : <span>
          { 
            content && content[ selectedLanguageShortname ] 
              ? ReactHTMLParser( ApplyColor( content[ selectedLanguageShortname ], true ) ) 
              : ReactHTMLParser( content[ 'en' ] ) 
          }
        </span>
      }
    </button>
  )
}

export default Radium( Button )