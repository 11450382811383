import React, { useState, useRef, useEffect } from "react"
import Radium from "proofpoint-radium"
import _ from 'lodash'

import CoreRenderFunction from '../../core/render'

const HeaderItem = props => {
  let {
    id,
    style,
    className,
    item,
    mode,
    selectedLanguageShortname,
    selectedPage
  } = props
  const containerRef = useRef()
  const [ isHovered, updateIsHovered ] = useState( false )
  const [ headerItem, setHeaderItem ] = useState( item )

  const isCurrentLink = () => {
    if( 
      selectedPage && 
      item.children &&
      _.findIndex( item.children, { navTo: selectedPage.url } ) > -1
    ){
      return true
    }
    return false
  }

  useEffect(()=>{
    window.addEventListener( "resize", handleSubMenuOverFlow )
    return () => window.removeEventListener( "resize", handleSubMenuOverFlow )
  }, [])

  useEffect(()=>{
    setHeaderItem(item)
  }, [item])

  useEffect(()=>{
    if( containerRef.current ){
      handleSubMenuOverFlow()
    }
  }, [ containerRef ])

  const handleSubMenuOverFlow = () => {
    let tmp = _.cloneDeep( item )
    let tmpDocument = (
      mode === 'view' 
        ? document
        : document.getElementById( 'editor-site-renderer' ).contentWindow.document
    )
    let tmpDistance = tmpDocument.body.clientWidth - containerRef.current.getBoundingClientRect().left
    if( tmp.children.length === 2 && tmpDistance < parseInt(tmp.children[1].style.width) ){
      tmp.children[1].style.left = `${ tmpDistance - parseInt(tmp.children[1].style.width) - 10 }px`
      if( tmp.children[1].style.right ){
        delete tmp.children[1].style.right
      }
      setHeaderItem( tmp )
    }
  }

  return(
    <div
      ref={ containerRef }
      id={ id }
      className={ className }
      onMouseOver={ () => updateIsHovered( true ) }
      onMouseLeave={ () => updateIsHovered( false ) }
      style={ 
        ( isHovered || isCurrentLink() ) ? ({
          ...style,
          ...style[ ':hover' ]
        }) :  style
      } >
      {
        headerItem.children && headerItem.children.map( dom => {
          if( 
            ( dom.type !== 'a' && isHovered ) ||
            ( dom.type === 'a' ) 
          ){
            return <CoreRenderFunction
              item={ dom }
              mode={ mode }
              selectedLanguageShortname={ selectedLanguageShortname }
            />
          }
        })
      }
    </div>
  )
}

export default Radium( HeaderItem )