import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get, Post, Put, Delete } from 'utils/axios'

import Lang from 'Lang/General'

const FaqCategoriesHOC = ( WrappedComponent ) => {
  class FaqCategoriesWrappedComponent extends Component {
    state = {
      loading: false,
      showCreateFaqCategory: false,
      showUpdateFaqCategory: false,
      faqCategories: {
        meta: {},
        data: []
      },
      selectedFaqCategory: {
        name: {
          en: '',
          de: '',
          nl: '',
          it: '',
          fr: ''
        },
        description: {
          en: '',
          de: '',
          nl: '',
          it: '',
          fr: ''
        }
      },
      newFaqCategory: {
        name: {
          en: '',
          de: '',
          nl: '',
          it: '',
          fr: ''
        },
        description: {
          en: '',
          de: '',
          nl: '',
          it: '',
          fr: ''
        }
      },
      showDeleteFaqCategoryId: null
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    } )
    requestError = error => {
      toast.error( error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false
    } )}

    onChangeFaqCategoriesHOC = ( key, value ) => this.setState({ [ key ]: value })

    createFaqCategory = data => Post(
      `/api/v1/ark_member_area/am_faq_categories`,
      data,
      this.createFaqCategorySuccess,
      this.createFaqCategoryError,
      this.load
    )
    createFaqCategorySuccess = () => {
      this.getFaqCategories()
      this.requestSuccess( Lang[ 'FAQ_CATEGORY_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showCreateFaqCategory: false })
    }
    createFaqCategoryError = error => this.requestError( error )

    getFaqCategories = () => Get(
      `/api/v1/ark_member_area/am_faq_categories`,
      this.getFaqCategoriesSuccess,
      this.getFaqCategoriesError,
      this.load
    )
    getFaqCategoriesSuccess = payload => this.setState({ faqCategories: payload })
    getFaqCategoriesError = error => this.requestError( error )

    getSelectedFaqCategory = category_id => Get(
      `/api/v1/ark_member_area/am_faq_categories/${ category_id }`,
      this.getSelectedFaqCategorySuccess,
      this.getSelectedFaqCategoryError,
      this.load
    )
    getSelectedFaqCategorySuccess = payload => this.setState({ selectedFaqCategory: payload })
    getSelectedFaqCategoryError = error => this.requestError( error )

    updateFaqCategory = ( category_id, data ) => Put(
      `/api/v1/ark_member_area/am_faq_categories/${ category_id }`,
      data,
      this.updateFaqCategorySuccess,
      this.updateFaqCategoryError,
      this.load
    )
    updateFaqCategorySuccess = () => {
      this.getFaqCategories()
      this.requestSuccess( Lang[ 'FAQ_CATEGORY_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showUpdateFaqCategory: false })
    }
    updateFaqCategoryError = error => this.requestError( error )

    removeFaqCategory = category_id => Delete(
      `/api/v1/ark_member_area/am_faq_categories/${ category_id }`,
      this.removeFaqCategorySuccess,
      this.removeFaqCategoryError,
      this.load
    )
    removeFaqCategorySuccess = () => {
      this.getFaqCategories()
      this.setState({ showDeleteFaqCategoryId: null })
      this.requestSuccess( Lang[ 'FAQ_CATEGORY_REMOVE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
    }
    removeFaqCategoryError = error => this.requestError( error.Error )

    render = () => {
      return(
        <>
          <WrappedComponent
            { ...this.props }
            onLoadFaqCategories={ this.state.loading }
            onChangeFaqCategoriesHOC={ this.onChangeFaqCategoriesHOC }
            showCreateFaqCategory={ this.state.showCreateFaqCategory }
            showUpdateFaqCategory={ this.state.showUpdateFaqCategory }
            faqCategories={ this.state.faqCategories }
            selectedFaqCategory={ this.state.selectedFaqCategory }
            newFaqCategory={ this.state.newFaqCategory }
            createFaqCategory={ this.createFaqCategory }
            getFaqCategories={ this.getFaqCategories }
            getSelectedFaqCategory={ this.getSelectedFaqCategory }
            updateFaqCategory={ this.updateFaqCategory }
            removeFaqCategory={ this.removeFaqCategory }
            showDeleteFaqCategoryId={ this.state.showDeleteFaqCategoryId }
          />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( FaqCategoriesWrappedComponent )
}

export default FaqCategoriesHOC
