import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get, Post, Put } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const AVContractTemplateHOC = ( WrappedComponent ) => {
  class AVContractTemplateWrappedComponent extends Component {
    state={
      loading: false,
      contractTemplates: [],
      newTemplate: {
        name: {
          en: '',
          de: '',
          nl: '',
          it: ''
        },
        html: {
          en: '',
          de: '',
          nl: '',
          it: ''
        },
        version: null,
        variable_fields: {
          auth_person: '',
          company_name_address: '',
          agreement_number: ''
        }
      },
      selectedTemplate: {},
      showViewTemplate: false,
      showCreateTemplate: false,
      showEditTemplate: false,
      contractTemplateTotalPages: [],
      duplicateTemplate: 0
    }

    onChangeAVContractTemplateHOC = ( key, value ) => this.setState({ [ key ]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( item => toast.error( item.replaceAll( '_', ' ' ) ) )

    getAllContractTemplates = query => Get(
      `/api/v1/gdpr_av_contract_templates?query=${ convertObjToBase64( query ) }`,
      this.getAllContractTemplatesSuccess,
      this.getAllContractTemplatesError,
      this.load
    )
    getAllContractTemplatesSuccess = payload => {
      let tmpTotalPages = []
      if ( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      this.setState({ contractTemplates: payload, contractTemplateTotalPages: tmpTotalPages })
    }
    getAllContractTemplatesError = error => this.requestError( error )

    postTemplate = data => Post(
      `/api/v1/gdpr_av_contract_templates`,
      data,
      this.postTemplateSuccess,
      this.postTemplateError,
      this.load
    )
    postTemplateSuccess = payload => {
      this.setState({ showCreateTemplate: false })
      this.requestSuccess( Lang[ 'TEMPLATE_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getAllContractTemplates({
        page: 1,
        is_paginated: true
      })
    }
    postTemplateError = error => this.requestError( error )

    getSelectedTemplate = id => Get(
      `/api/v1/gdpr_av_contract_templates/${ id }`,
      this.getSelectedTemplateSuccess,
      this.getSelectedTemplateError,
      this.load
    )
    getSelectedTemplateSuccess = payload => this.setState({ selectedTemplate: payload })
    getSelectedTemplateError = error => this.requestError( error )

    updateTemplate = ( id, data ) => Put(
      `/api/v1/gdpr_av_contract_templates/${ id }`,
      data,
      this.updateTemplateSuccess,
      this.updateTemplateError,
      this.load
    )
    updateTemplateSuccess = payload => {
      this.requestSuccess( Lang[ 'TEMPLATE_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showEditTemplate: false })
      this.getAllContractTemplates({
        page: 1,
        is_paginated: true
      })
    }
    updateTemplateError = error => this.requestError( error )

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            { ...this.props }
            contractTemplates={ this.state.contractTemplates }
            newTemplate={ this.state.newTemplate }
            selectedTemplate={ this.state.selectedTemplate }
            showCreateTemplate={ this.state.showCreateTemplate }
            showEditTemplate={ this.state.showEditTemplate }
            showViewTemplate={ this.state.showViewTemplate }
            onChangeAVContractTemplateHOC={ this.onChangeAVContractTemplateHOC }
            contractTemplateTotalPages={ this.state.contractTemplateTotalPages }
            getAllContractTemplates={ this.getAllContractTemplates }
            postTemplate={ this.postTemplate }
            getSelectedTemplate={ this.getSelectedTemplate }
            updateTemplate={ this.updateTemplate }
            duplicateTemplate={ this.state.duplicateTemplate }
            onLoadAVContractTemplates={ this.state.loading } />
        </Fragment>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( AVContractTemplateWrappedComponent )
}

export default AVContractTemplateHOC
