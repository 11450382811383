import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { Post, Get, Put, Delete } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state={ 
      loading: false,
      categories: [],
      newCategory: {},
      selectedCategory: {},
      showCreateCategory: false,
      showUpdateCategory: false,
      showDeleteCategoryId: 0
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( err => toast.error( err.replaceAll( '_', ' ' ) ) )

    onChangeCategoriesHOC = ( key, value ) => this.setState({ [ key ] : value })

    createCategory = data => Post(
      `/api/v1/ark_member_area/am_categories`,
      data,
      this.createCategorySuccess,
      this.createCategoryError,
      this.load
    )
    createCategorySuccess = () => {
      this.getCategories()
      this.requestSuccess( Lang[ 'CATEGORY_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showCreateCategory: false })
    }
    createCategoryError = error => {
      this.requestError( error )
      this.setState({ showCreateCategory: false })
    }

    getCategories = query => Get(
      `/api/v1/ark_member_area/am_categories${ query ? `?query=${ convertObjToBase64( query ) }` : '' }`,
      this.getCategoriesSuccess,
      this.getCategoriesError,
      this.load
    )
    getCategoriesSuccess = payload => this.setState({ categories: payload.data })
    getCategoriesError = error => this.requestError( error )

    getSelectedCategory = category_id => Get(
      `/api/v1/ark_member_area/am_categories/${ category_id }`,
      this.getSelectedCategorySuccess,
      this.getSelectedCategoryError,
      this.load
    )
    getSelectedCategorySuccess = payload => this.setState({ selectedCategory: payload, showUpdateCategory: true })
    getSelectedCategoryError = error => this.requestError( error )

    updateCategory = data => Put(
      `/api/v1/ark_member_area/am_categories/${ data.id }`,
      data,
      this.updateCategorySuccess,
      this.updateCategoryError,
      this.load
    )
    updateCategorySuccess = () => {
      this.getCategories()
      this.requestSuccess( Lang[ 'CATEGORY_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showUpdateCategory: false })
    }
    updateCategoryError = error => this.requestError( error )

    removeCategory = category_id => Delete(
      `/api/v1/ark_member_area/am_categories/${ category_id }`,
      this.removeCategorySuccess,
      this.removeCategoryError,
      this.load
    )
    removeCategorySuccess = () => {
      this.getCategories()
      this.requestSuccess( Lang[ 'CATEGORY_REMOVE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showDeleteCategoryId: 0 })
    }
    removeCategoryError = error => {
      this.requestError( error )
      this.setState({ showDeleteCategoryId: 0 })
    }

    render = () => {
      return(
        <WrappedComponent
          { ...this.props } 
          onLoadCategoriesHOC={ this.state.loading }
          categories={ this.state.categories }
          newCategory={ this.state.newCategory }
          selectedCategory={ this.state.selectedCategory }
          showCreateCategory={ this.state.showCreateCategory }
          showUpdateCategory={ this.state.showUpdateCategory }
          showDeleteCategoryId={ this.state.showDeleteCategoryId }
          onChangeCategoriesHOC={ this.onChangeCategoriesHOC }
          createCategory={ this.createCategory }
          getCategories={ this.getCategories }
          getSelectedCategory={ this.getSelectedCategory }
          updateCategory={ this.updateCategory }
          removeCategory={ this.removeCategory }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC