import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import { IoMdArrowRoundDown, IoMdArrowRoundUp, IoIosArrowDown, IoMdCreate, IoIosTrash } from 'react-icons/io'
import Lang from 'Lang/General'

const Accordion = ({
  item,
  parent,
  selectedLanguageShortname,
  children,
  index,
  onClickDown,
  onClickUp,
  itemLength,
  onUpdateHeaderMenuText,
  onUpdateHeaderMenu,
  onClickDeleteMenu,
  level,
  pages,
  lang
}) => {
  const [ expand, updateExpand ] = useState( false )
  const [ editMode, updateEditMode ] = useState( false )
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div
        className={ 'MuiPaper-root MuiPaper-elevation1 MuiExpansionPanel-root headerMenuMainContainer MuiExpansionPanel-rounded MuiPaper-rounded' }
        style={{ width: '60vw' }}>
        <div style={{ padding: '10px 10px 0 10px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {
            editMode
              ? (
                <input
                  value={ item.content[ selectedLanguageShortname ] }
                  className={ 'form-control' }
                  style={{ width: '70%' }}
                  onChange={ e => onUpdateHeaderMenuText( item.id, e.target.value ) } />
              )
              : (
                <span>
                  { item.content && item.content[ selectedLanguageShortname ] }
                </span>
              )
          }
          <div style={{ marginLeft: 'auto' }}>
            <Button
              onClick={() => onClickDeleteMenu( item.id )}
              disabled={ index === 0 && level === 1 }>
              <IoIosTrash style={{ fontSize: '24px' }} />
            </Button>
            <Button
              onClick={() => updateEditMode( !editMode )}>
              <IoMdCreate style={{ fontSize: '24px' }} />
            </Button>
            <Button
              onClick={() => updateExpand( !expand )}>
              <IoIosArrowDown style={{ fontSize: '24px' }} />
            </Button>
          </div>
        </div>
        <div style={{ padding: '0 10px 5px 10px' }}>
          <label style={{ fontSize: '10px', fontWeight: '600' }}>{ Lang[ 'LINK_TO' ][ lang ] }</label>
          <select
            className="form-control"
            style={{ width: '20%' }}
            onChange={ e => onUpdateHeaderMenu( item, 'navTo', e.target.value ) }
            value={ item.navTo }>
            <option value={ '' }>{ '' }</option>
            {
              pages && pages.length > 0 && pages.map( item => {
                return (
                  <option key={ item.id } value={ item.url }>{ item.name }</option>
                )
              })
            }
          </select>
        </div>
        <div style={{ display: expand ? 'block' : 'none' }}>
          { children }
        </div>
      </div>
      {
        level > 1 && (
          <>
            <Button
              className="btn btn-default"
              disabled={ ( parent.children.length - 1 ) === ( index ) }
              onClick={ onClickDown }>
              <IoMdArrowRoundDown />
            </Button>
            <Button
              className="btn btn-default"
              disabled={ index === 0 }
              onClick={ onClickUp }>
              <IoMdArrowRoundUp />
            </Button>
          </>
        )
      }
    </div>
  )
}

export default Accordion