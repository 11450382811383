import React, { Component } from 'react'
import { compose } from 'redux'
import{
  Modal, ModalBody, ModalFooter, ModalHeader,
  Button, UncontrolledTooltip
} from 'reactstrap'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PageTitle from 'components/PageTitle'
import ARKTable from 'components/Table'
import PlatformHOC from './actions'

import { convertObjToBase64 } from 'utils/objToBase64'

import PlatformForm from './components/form'

import Lang from 'Lang/General'

class PlatformManagement extends Component{

  componentDidMount = () => {
    let tmp = {
      page: this.props.page,
      is_paginated: true
    }
    this.props.getAllPlatform( convertObjToBase64( tmp ) )
  }

  renderCreateModal = () => {
    return(
      <Modal
        toggle={ () => this.props.onChangePlatformHOC( 'createPlatformModal', false ) }
        isOpen={ this.props.createPlatformModal }
        size="xl">
        <ModalHeader toggle={ () => this.props.onChangePlatformHOC( 'createPlatformModal', false ) }>{ Lang[ 'CREATE_PLATFORM' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <PlatformForm
            mode="create"
            language={ this.props.data.languageReducer.lang }
            onChangePlatformHOC={ this.props.onChangePlatformHOC }
            data={ this.props.newPlatformData }
            onSubmit={ () => this.props.postPlatform( this.props.newPlatformData ) }/>  
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.onChangePlatformHOC( 'createPlatformModal', false ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderEditPlatformModal = () => {
    return(
      <Modal
        toggle={ () => this.props.onChangePlatformHOC( 'editPlatformModal', false ) }
        isOpen={ this.props.editPlatformModal }
        size="xl">
        <ModalHeader toggle={ () => this.props.onChangePlatformHOC( 'editPlatformModal', false ) }>{ Lang[ 'EDIT_PLATFORM' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <PlatformForm
            mode="edit"
            language={ this.props.data.languageReducer.lang }
            onChangePlatformHOC={ this.props.onChangePlatformHOC }
            data={ this.props.selectedPlatformData }
            selectedModuleData={ this.props.selectedModuleData }
            moduleName={ this.props.moduleName }
            onCreateModule={ () => this.props.postModule( { name: this.props.moduleName }, this.props.selectedPlatformData.id ) }
            getSelectedPlatform={ this.props.getSelectedPlatform } />
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.onChangePlatformHOC( 'editPlatformModal', false ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  render = () => {
    return(
      <>
        <div>
          <PageTitle
            heading={ Lang[ 'PLATFORM' ][ this.props.data.languageReducer.lang ] }
            icon="pe-7s-drawer icon-gradient bg-happy-fisher"
            buttons={[
              {
                buttonText: Lang[ 'CREATE_PLATFORM' ][ this.props.data.languageReducer.lang ],
                onClick: () => this.props.onChangePlatformHOC( 'createPlatformModal', true ),
                display: true
              }
            ]}/>
          <ARKTable
            data={ this.props.platforms.data }
            columns={[
              {
                Header: Lang[ 'NAME' ][ this.props.data.languageReducer.lang ],
                accessor: "name"
              },
              {
                Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
                accessor: "id",
                Cell: ({value}) => (
                  <>
                    <Button
                      id={ `Platform-${ value }-edit-platform` }
                      className="mb-2 mr-2 btn-icon btn-icon-only"
                      color="primary"
                      onClick={ () => {
                        this.props.getSelectedPlatform( value )
                        this.props.onChangePlatformHOC( 'editPlatformModal', true )
                      }}>
                        <i className="pe-7s-note"> </i>
                    </Button>
                    <UncontrolledTooltip
                      target={ `Platform-${ value }-edit-platform` }
                      placement="top">{ Lang[ 'EDIT_PLATFORM' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                  </>
                )
              }
            ]}
            totalPages={ this.props.platformTotalPages }
            page={ 1 }
            onChangePage={ pg => {
              let tmp = {
                page: pg,
                is_paginated: true
              }
              return this.props.getAllPlatform( tmp )
            }}
            onClickReset={ () => {
              let tmp = {
                page: 1,
                is_paginated: true
              }
            }}
            onSearch={ () => {} }
            searchLabel={ `Search with title` }
            searchComponent={ false }
            showPagination={ false } />
            { this.renderCreateModal() }
            { this.renderEditPlatformModal() }
        </div>
        { ( this.props.data.ajaxReducer.ajaxCallProgress > 0 ) && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose(
  PlatformHOC
)( PlatformManagement )
