import React, { Component } from 'react'
import _ from 'lodash'
import {
  Button, Modal, ModalHeader,
  ModalBody, ModalFooter,
  Table, UncontrolledTooltip
} from 'reactstrap'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PageTitle from 'components/PageTitle'
import ARKTable from './components/Table'
import DeleteConfirmation from 'components/Modal/delete'
import ConfirmToArchiveModal from './components/confirmationModal'
import ConsultantForm from './components/form'
import ConsultantHOC from 'actions/consultant'

import Lang from 'Lang/General'

class ConsultantManagement extends Component{
  state= {
    searchColumn: [
      {
        name: Lang[ 'FIRST_NAME' ][ this.props.data.languageReducer.lang ],
        type: 'text',
        val: 'first_name'
      },
      {
        name: Lang[ 'LAST_NAME' ][ this.props.data.languageReducer.lang ],
        type: 'text',
        val: 'last_name'
      },
      {
        name: Lang[ 'EMAIL' ][ this.props.data.languageReducer.lang ],
        type: 'text',
        val: 'email'
      },
      {
        name: Lang[ 'MOBILE_NUMBER' ][ this.props.data.languageReducer.lang ],
        type: 'text',
        val: 'mobile_number'
      },
      {
        name: Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ],
        type: 'dropdown',
        val: 'status'
      }
    ],
    archivedSearchColumn: [
      {
        name: Lang[ 'FIRST_NAME' ][ this.props.data.languageReducer.lang ],
        type: 'text',
        val: 'first_name'
      },
      {
        name: Lang[ 'LAST_NAME' ][ this.props.data.languageReducer.lang ],
        type: 'text',
        val: 'last_name'
      },
      {
        name: Lang[ 'EMAIL' ][ this.props.data.languageReducer.lang ],
        type: 'text',
        val: 'email'
      },
      {
        name: Lang[ 'MOBILE_NUMBER' ][ this.props.data.languageReducer.lang ],
        type: 'text',
        val: 'mobile_number'
      }
    ],
    selectedSearchType: 'normalSearch',
    selectedColumn: [],
  }

  componentDidMount = () => {
    let tmp = {
      page: 1,
      is_paginated: true,
    }
    this.props.getConsultants( tmp )
  }

  handleEdit = () => this.setState({ editOpen: true })

  handleShow = () => this.setState({ showOpen: true })

  handleDelete = () => this.setState({ deleteOpen: true })

  handleModalClose = () => this.setState({ editOpen: false, showOpen: false, deleteOpen: false })

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Consultant' })
      return tmpModules.is_create
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Consultant' })
      return tmpModules.is_update
    }
  }

  handleSecurityModulesDelete= () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Consultant' })
      return tmpModules.is_delete
    }
  }

  onChangeSearchColumn = ( key, val ) => {
    if( this.props.selectedSearchColumn.length > 0 ){
      let tmp = this.props.selectedSearchColumn
      let tmpSearchParam = _.find( tmp, { val: key })
      tmpSearchParam.searchValue = val
      return this.props.onChangeConsultantHOC( 'selectedSearchColumn', tmp )
    }
  }

  renderNewConsultant = () => {
    return(
      <Modal
        isOpen={ this.props.showNewConsultantModal }
        size="lg"
        toggle={ () => {
          this.props.onChangeConsultantHOC( 'showNewConsultantModal', false )
          this.props.onChangeConsultantHOC( 'newConsultantData', {
            user: {
              first_name: '',
              last_name: '',
              username: '',
              email: '',
              role_id: 5,
              password: '',
              password_confirmation: '',
              photo_file_name: '',
              photo_raw: '',
              photo_url: ''
            },
            phone_number: '',
            mobile_number: '',
            best_contact_time: '',
            fax_number: '',
            remark: '',
            status: 'ACTIVE',
          })
        this.props.onChangeConsultantHOC( 'errorMessage', {} )
        }}>
        <ModalHeader
          toggle={ () => {
            this.props.onChangeConsultantHOC( 'showNewConsultantModal', false )
            this.props.onChangeConsultantHOC( 'newConsultantData', {
              user: {
                first_name: '',
                last_name: '',
                username: '',
                email: '',
                role_id: 5,
                password: '',
                password_confirmation: '',
                photo_file_name: '',
                photo_raw: '',
                photo_url: ''
              },
              phone_number: '',
              mobile_number: '',
              best_contact_time: '',
              fax_number: '',
              remark: '',
              status: 'ACTIVE',
            })
          this.props.onChangeConsultantHOC( 'errorMessage', {} )
          }}>{ Lang[ 'NEW_CONSULTANT' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody style={{ padding: '30px' }}>
          <ConsultantForm
            data={ this.props.newConsultantData }
            onChangeConsultantHOC={ this.props.onChangeConsultantHOC }
            statusValues={ this.props.data.dictionaryReducer.dictionary.status }
            errorMessage={ this.props.errorMessage }
            roles={ this.props.data.dictionaryReducer.dictionary.roles }
            user={ this.props.newConsultantData.user }
            mode={ 'create' }
            onLoadConsultants={ this.props.onLoadConsultants }/>
          <Button className="mb-2 mr-2" color="info" onClick={ () => this.props.postConsultants() } >{ Lang[ 'CREATE' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalBody>
        <ModalFooter>
          <Button
            className="mb-2 mr-2"
            color="primary"
            onClick={ () => {
              this.props.onChangeConsultantHOC( 'showNewConsultantModal', false )
              this.props.onChangeConsultantHOC( 'newConsultantData', {
                user: {
                  first_name: '',
                  last_name: '',
                  username: '',
                  email: '',
                  role_id: 5,
                  admin_panel_language: this.props.data.languageReducer.lang,
                  password: '',
                  password_confirmation: '',
                  photo_file_name: '',
                  photo_raw: '',
                  photo_url: ''
                },
                phone_number: '',
                mobile_number: '',
                best_contact_time: '',
                fax_number: '',
                remark: '',
                status: 'ACTIVE',
              })
            this.props.onChangeConsultantHOC( 'errorMessage', {} )
          }}>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderEditConsultant = () => {
    return (
      <Modal
        isOpen={ this.props.showEditConsultantModal }
        size="lg"
        toggle={ () => this.props.onChangeConsultantHOC( 'showEditConsultantModal', false ) }>
        <ModalHeader toggle={ () => this.props.onChangeConsultantHOC( 'showEditConsultantModal', false ) }>{ Lang[ 'EDIT_CONSULTANT' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody style={{ padding: '30px' }}>
          <ConsultantForm
            data={ this.props.selectedConsultant }
            onChangeConsultantHOC={ this.props.onChangeConsultantHOC }
            statusValues={ this.props.data.dictionaryReducer.dictionary.status }
            roles={ this.props.data.dictionaryReducer.dictionary.roles }
            errorMessage={ this.props.errorMessage }
            user={ this.props.selectedConsultant.user }
            mode={ 'edit' }
            onLoadConsultants={ this.props.onLoadConsultants }/>
          <Button className="mb-2 mr-2" color="info" onClick={ () => this.props.updateConsultant( this.props.selectedConsultant.id ) } >{ Lang[ 'UPDATE' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.onChangeConsultantHOC( 'showEditConsultantModal', false ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderViewConsultantDetails = () => {
    return(
      <Modal isOpen={ this.props.showViewConsultantModal } size="lg" toggle={ () => this.props.onChangeConsultantHOC( 'showViewConsultantModal', false ) }>
        <ModalHeader toggle={ () => this.props.onChangeConsultantHOC( 'showViewConsultantModal', false ) }>{ Lang[ 'CONSULTANT_DETAILS' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <Table className="mb-0" bordered>
            <tbody>
              <tr>
                <th scope="row">{ Lang[ 'FIRST_NAME' ][ this.props.data.languageReducer.lang ] }</th>
                <td>{ this.props.selectedConsultant.user.first_name }</td>
              </tr>
              <tr>
                <th scope="row">{ Lang[ 'LAST_NAME' ][ this.props.data.languageReducer.lang ] }</th>
                <td>{ this.props.selectedConsultant.user.last_name }</td>
              </tr>
              <tr>
                <th scope="row">{ Lang[ 'EMAIL' ][ this.props.data.languageReducer.lang ] }</th>
                <td>{ this.props.selectedConsultant.user.email }</td>
              </tr>
              <tr>
                <th scope="row">{ Lang[ 'PHONE_NUMBER' ][ this.props.data.languageReducer.lang ] }</th>
                <td>{ this.props.selectedConsultant.phone_number }</td>
              </tr>
              <tr>
                <th scope="row">{ Lang[ 'MOBILE_NUMBER' ][ this.props.data.languageReducer.lang ] }</th>
                <td>{ this.props.selectedConsultant.mobile_number }</td>
              </tr>
              <tr>
                <th scope="row">{ Lang[ 'FAX_NUMBER' ][ this.props.data.languageReducer.lang ] }</th>
                <td>{ this.props.selectedConsultant.fax_number }</td>
              </tr>
              <tr>
                <th scope="row">{ Lang[ 'BEST_CONTACT_TIME' ][ this.props.data.languageReducer.lang ] }</th>
                <td>{ this.props.selectedConsultant.best_contact_time }</td>
              </tr>
              <tr>
                <th scope="row">{ Lang[ 'REMARK' ][ this.props.data.languageReducer.lang ] }</th>
                <td>{ this.props.selectedConsultant.remark }</td>
              </tr>
              <tr>
                <th scope="row">{ Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ] }</th>
                <td>{ this.props.selectedConsultant.status }</td>
              </tr>
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={ () => this.props.onChangeConsultantHOC( 'showViewConsultantModal', false ) }>OK</Button>
          {
            this.handleSecurityModulesUpdate() && (
              <Button
                color="alternate"
                onClick={ () => {
                  this.props.onChangeConsultantHOC( 'showViewConsultantModal', false )
                  this.props.onChangeConsultantHOC( 'showEditConsultantModal', true )
                }}>{ Lang[ 'EDIT' ][ this.props.data.languageReducer.lang ] }</Button>
            )
          }
        </ModalFooter>
      </Modal>
    )
  }

  renderShowArchived = () => {
    return (
      <Modal
        isOpen={ this.props.showArchivedConsultantModal }
        size="xl"
        toggle={ () => this.props.onChangeConsultantHOC( 'showArchivedConsultantModal', false ) }>
        <ModalHeader toggle={ () => this.props.onChangeConsultantHOC( 'showArchivedConsultantModal', false ) }>{ Lang[ 'ARCHIVED_CONSULTANT' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <ARKTable
            data={ this.props.archivedConsultants.data }
            columns={[
              {
                Header: 'ID',
                accessor: 'id'
              },
              {
                Header: Lang[ 'NAME' ][ this.props.data.languageReducer.lang ],
                accessor: "fullname"
              },
              {
                Header: Lang[ 'EMAIL' ][ this.props.data.languageReducer.lang ],
                accessor: "email"
              },
              {
                Header: Lang[ 'MOBILE_NUMBER' ][ this.props.data.languageReducer.lang ],
                accessor: "mobile_number"
              },
              {
                Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
                accessor: "id",
                show: this.handleSecurityModulesUpdate(),
                Cell: ({value}) => (
                  <div>
                    <Button
                      id={ `Consultant-${ value }-unarchive-consultant` }
                      className="mb-2 mr-2 btn-icon btn-icon-only"
                      color="danger"
                      onClick={ () => {
                        this.props.onChangeConsultantHOC( 'confirmUnarchiveModal', true )
                        this.props.onChangeConsultantHOC( 'unArchiveConsultantID', value )
                      }}>
                        <i className="pe-7s-menu"> </i>
                    </Button>
                    <UncontrolledTooltip
                      target={ `Consultant-${ value }-unarchive-consultant` }
                      placement="top">{ Lang[ 'UNARCHIVE_CONSULTANT' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                  </div>
                )
              }
            ]}
            totalPages={ this.props.archivedConTotalPage }
            page={ this.props.archivedConsultants.meta ? this.props.archivedConsultants.meta.current_page : 1 }
            onChangePage={ pg => {
              let tmp = {
                page: pg,
                is_paginated: true, filter: {
                status: 'ARCHIVED'
              }}
              return this.props.getArchivedConsultant( tmp )
            }}
            onSearch={ this.props.getArchivedConsultant }
            onClickReset={ () => {
              let tmp = {
                page: this.props.archivedConsultants.meta ? this.props.archivedConsultants.meta.current_page : 1,
                is_paginated: true
              }
              this.props.getArchivedConsultant( tmp )
            }}
            searchLabel={ Lang[ 'SEARCH_WITH_FIRST_NAME' ][ this.props.data.languageReducer.lang ] }
            searchComponent={ true }
            searchColumn={ this.state.archivedSearchColumn }
            showPagination={ false }/>
            {
              this.props.confirmUnarchiveModal && (
                <DeleteConfirmation
                  handleModalClose={ () => this.props.onChangeConsultantHOC( 'confirmUnarchiveModal', false ) }
                  deleteOpen={ this.props.confirmUnarchiveModal }
                  confirmAction={ () => {
                    this.props.updateConsultantStatus( this.props.unArchiveConsultantID, 'ACTIVE' )
                    this.props.onChangeConsultantHOC( 'confirmUnarchiveModal', false )
                  }}
                  content={ Lang[ 'DELETE_CONFIRMATION_ARCHIVED_CONSULTANT' ][ this.props.data.languageReducer.lang ] }
                  lang={ this.props.data.languageReducer.lang } />
              )
            }
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.onChangeConsultantHOC( 'showArchivedConsultantModal', false ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  render = () => {
    return (
      <>
        <div>
          <PageTitle
            heading={ Lang[ 'CONSULTANTS' ][ this.props.data.languageReducer.lang ] }
            icon="pe-7s-users icon-gradient bg-happy-fisher"
            buttons={[
              {
                buttonText: Lang[ 'ARCHIVED_CONSULTANT' ][ this.props.data.languageReducer.lang ],
                onClick: () => {
                  let tmp = {
                    page: this.props.consultants.meta ? this.props.consultants.meta.current_page : 1,
                    is_paginated: true
                  }
                  this.props.onChangeConsultantHOC( 'showArchivedConsultantModal', true )
                  this.props.getArchivedConsultant( tmp )
                },
                display: true
              },
              {
                buttonText: Lang[ 'NEW_CONSULTANT' ][ this.props.data.languageReducer.lang ],
                onClick: () => this.props.onChangeConsultantHOC( 'showNewConsultantModal', true ),
                display: this.handleSecurityModulesCreate()
              }
            ]}/>
          <ARKTable
            data={ this.props.consultants.data }
            columns={[
              {
                Header: 'ID',
                accessor: 'id'
              },
              {
                Header: Lang[ 'NAME' ][ this.props.data.languageReducer.lang ],
                accessor: "fullname"
              },
              {
                Header: Lang[ 'EMAIL' ][ this.props.data.languageReducer.lang ],
                accessor: "email"
              },
              {
                Header: Lang[ 'MOBILE_NUMBER' ][ this.props.data.languageReducer.lang ],
                accessor: "mobile_number"
              },
              {
                Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
                accessor: "id",
                Cell: ({value}) => (
                  <div>
                    <>
                      <Button
                        id={ `Consultant-${ value }-view-consultant` }
                        className="mb-2 mr-2 btn-icon btn-icon-only"
                        color="primary"
                        onClick={ () => this.props.getSelectedConsultantView( value ) }>
                        <i className="pe-7s-look"> </i>
                      </Button>
                      <UncontrolledTooltip
                        target={ `Consultant-${ value }-view-consultant` }
                        placement="top">{ Lang[ 'VIEW_CONSULTANT_DETAILS' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                    </>
                    {
                      this.handleSecurityModulesUpdate() && (
                        <>
                          <Button
                            id={ `Consultant-${ value }-edit-consultant` }
                            className="mb-2 mr-2 btn-icon btn-icon-only"
                            color="primary"
                            onClick={ () => this.props.getSelectedConsultantEdit( value ) }>
                            <i className="pe-7s-note"> </i>
                          </Button>
                          <UncontrolledTooltip
                            target={ `Consultant-${ value }-edit-consultant` }
                            placement="top">{ Lang[ 'EDIT_CONSULTANT' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                        </>
                      )
                    }
                    {
                      this.handleSecurityModulesDelete() && (
                        <>
                          <Button
                            id={ `Consultant-${ value }-archive-consultant` }
                            className="mb-2 mr-2 btn-icon btn-icon-only"
                            color="danger"
                            onClick={ () => {
                              this.props.onChangeConsultantHOC( 'showDeleteConfirmation', true )
                              this.props.onChangeConsultantHOC( 'toArchiveConsultantId', value )
                              this.props.getAssignedCenters( value )
                            }}>
                              <i className="pe-7s-trash"> </i>
                          </Button>
                          <UncontrolledTooltip
                            target={ `Consultant-${ value }-archive-consultant` }
                            placement="top">{ Lang[ 'ARCHIVE_CONSULTANT' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                        </>
                      )
                    }
                  </div>
                )
              }
            ]}
            totalPages={ this.props.consultantTotalPages }
            page={ this.props.consultants.meta ? this.props.consultants.meta.current_page : 1 }
            onChangePage={ pg => {
              let tmp = {
                page: pg,
                is_paginated: true,
                filter: {
                  $or: [
                    { status: 'ACTIVE' },
                    { status: 'INACTIVE' }
                  ]
                }
              }
              this.props.getConsultants( tmp )
            }}
            onSearch={ this.props.getConsultantsSearch }
            onClickReset={ () => {
              let tmp = {
                page: this.props.consultants.meta ? this.props.consultants.meta.current_page : 1,
                is_paginated: true
              }
              this.props.getConsultants( tmp )
            }}
            searchLabel={ Lang[ 'SEARCH_WITH_FIRST_NAME' ][ this.props.data.languageReducer.lang ] }
            searchComponent={ true }
            searchColumn={ this.state.searchColumn }
            showPagination={ false }/>

            { this.renderNewConsultant() }
            { this.renderEditConsultant() }
            { this.renderViewConsultantDetails() }
            { this.renderShowArchived() }
            {
              this.props.showDeleteConfirmation && (
                <ConfirmToArchiveModal
                  handleModalClose={ () => this.props.onChangeConsultantHOC( 'showDeleteConfirmation', false ) }
                  deleteOpen={ this.props.showDeleteConfirmation }
                  confirmAction={ () => {
                    this.props.updateConsultantStatus( this.props.toArchiveConsultantId, 'ARCHIVED' )
                    this.props.onChangeConsultantHOC( 'showDeleteConfirmation', false )
                  }}
                  lang={ this.props.data.languageReducer.lang }
                  assignedCenters={ this.props.assignedCenters }
                  downloadAssignedCenters={ this.props.downloadAssignedCenters }
                  toArchiveConsultantId={ this.props.toArchiveConsultantId }
                  selectedConsultant={ this.props.selectedConsultant } />
              )
            }
        </div>
        { ( this.props.data.ajaxReducer.ajaxCallProgress > 0 || this.props.onLoadConsultants ) && <LoadingOverlay /> }
      </>
    )
  }
}

export default ConsultantHOC( ConsultantManagement )
