import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios-template'
import { production_url } from '../../config'
import { convertObjToBase64 } from 'utils/objToBase64'

const Sections = ( WrappedComponent ) => {
  class SectionsHOC extends Component {
    state = {
      templates: [],
      sections: [],
      categories: [],
      loading: false
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })
    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    getTemplates = () => Get(
      `${ production_url }/templates?filters[status]=ACTIVE`,
      this.getTemplatesSuccess,
      this.getTemplatesError,
      this.load
    )
    getTemplatesSuccess = payload => this.setState({ templates: payload })
    getTemplatesError = error => this.requestError( error )

    getSections = query => Get(
      `${ production_url }/sections?filters[query]=${ convertObjToBase64( query ) }`,
      this.getSectionsSuccess,
      this.getSectionsError,
      this.load
    )
    getSectionsSuccess = payload => this.setState({ sections: payload })
    getSectionsError = error => this.requestError( error )

    getCategories = () => Get(
      `${ production_url }/categories`,
      this.getCategoriesSuccess,
      this.getCategoriesError,
      this.load
    )
    getCategoriesSuccess = payload => {
      let data = []
      if ( payload && payload.length > 0 ) {
        payload.map( option => {
          let tmpData = {
            label: option.name[ this.props.selectedLanguageShortname ],
            value: option.id
          }
          data.push( tmpData )
        })
      } 
      this.setState({ categories: data })
    }
    getCategoriesError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            onLoadSections={ this.state.loading }
            templates = { this.state.templates }
            sections={ this.state.sections }
            categories={ this.state.categories }
            getTemplates = { this.getTemplates }
            getSections={ this.getSections }
            getCategories={ this.getCategories } />
        </>
      )
    }
  }
  return SectionsHOC
}

export default Sections