export default [
  {
    categoryName: {
      en: 'Burn',
      de: 'verbrennen',
      nl: 'Verbranding',
      it: 'Masterizzare'
    },
    color: '#cc0000'
  },
  {
    categoryName: {
      en: 'Beginners I',
      de: 'Einsteiger I',
      nl: 'Beginners I',
      it: 'Principianti I'
    },
    color: '#56ac42'
  },
  {
    categoryName: {
      en: 'Beginner II',
      de: 'Einsteiger I',
      nl: 'Beginners II',
      it: 'Principianti II'
    },
    color: '#21effc'
  },
]