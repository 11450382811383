import React from 'react'
import _ from 'lodash'

import Lang from 'Lang/General'

const TeamSearchTag = props => {
  let {
    item,
    selectedLanguageShortname
  } = props
  if( !_.isEmpty( item.searchTag) ){
    return( 
      <div>
        <span style={ item.style }>
          { Lang[ 'SEARCH_RESULT_FOR' ][ selectedLanguageShortname ] }:
        </span>
        {
          Object.keys( item.searchTag ).map( tag => (
            <span style={ item.tagStyle }>
              { item.searchTag[ tag ].label }
            </span>
          ))
        }
      </div>
    )
  } else {
    return(
      <></>
    )
  }
}

export default TeamSearchTag