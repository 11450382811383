import React, { useState, useEffect } from 'react'
import {
  Card, CardBody,
  Form, FormGroup,
  Button
} from 'reactstrap'
import _ from 'lodash'

import PageTitle from 'components/PageTitle'
import MultiLangRTE from 'components/RTE/MultiLang'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import WithPlatformSettings from './actions'
import Lang from 'Lang/General'

const PlatformSettings = props => {
  const [ platformEmailImprintText, setPlatformEmailImprintText ] = useState({
    en: '',
    de: '',
    it: '',
    nl: ''
  })

  useEffect( () => {
    props.getPlatformSettings()
  }, [] )

  useEffect( () => {
    if( props.platformSettings.length > 0 && _.find( props.platformSettings, item => 'impressum' in item ) ){
      let tmpPlatformSetting = _.find( props.platformSettings, item => 'impressum' in item )
      props.onChangePlatformSettingsHOC( 'selectedPlatformSetting', tmpPlatformSetting )
      setPlatformEmailImprintText( tmpPlatformSetting.impressum )
    }
  }, [ props.platformSettings ] )

  return (
    <>
      <PageTitle
        heading={ Lang[ 'IMPRINT' ][ props.data.languageReducer.lang ] }
        icon="pe-7s-config icon-gradient bg-happy-fisher" />
      <Card>
        <CardBody>
          <Form onSubmit={ e => e.preventDefault() }>
            <FormGroup>
              <MultiLangRTE
                label={ Lang[ 'EMAIL_IMPRINT_TEXT' ][ props.data.languageReducer.lang ] }
                value={ platformEmailImprintText } 
                onChange={ val => setPlatformEmailImprintText( val ) }
                selectedLanguage={ props.data.languageReducer.lang }
              />
              <Button
                color='primary'
                className='mr-0 float-right'
                onClick={ () => props.platformSettings.length > 0 && _.find( props.platformSettings, item => 'impressum' in item )
                  ? props.updatePlatformEmailImprintText({ ...props.selectedPlatformSetting, impressum: platformEmailImprintText })
                  : props.createPlatformEmailImprintText({ impressum: platformEmailImprintText })
                }>
                { Lang[ 'SUBMIT' ][ props.data.languageReducer.lang ] }</Button>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
      { props.onLoadPlatformSettingsHOC && <LoadingOverlay /> }
    </>
  )
}

export default WithPlatformSettings( PlatformSettings )
