import React, { useState, useEffect } from 'react'
import { FormGroup, Label, Input } from 'reactstrap'
import _ from 'lodash'
import ReactHTMLParser from 'react-html-parser'


const TextArea = ({
  content,
  formValues,
  selectedLanguageShortname 
}) => {
  const [ labelStr, setLabelStr ] = useState("")

  useEffect(() => {
    if( content ){
      let labelText = content.label[ selectedLanguageShortname  ? selectedLanguageShortname  : 'en' ]

      if ( labelText.length !== 0 ) {
        if ( typeof( ReactHTMLParser( labelText )[0] ) === 'object' ) {
          let labelTag = ReactHTMLParser( labelText )[0].type
          let closingTag = "</" + labelTag + ">"
          labelText = labelText.slice( 0, -1 * closingTag.length )
          if ( formValues[ content.dataMapIndex ][ 'isRequired' ] ){
            labelText = labelText + "<span style='color: #ff0000;'> * </span>"
          }
          labelText = labelText + closingTag
        }
        else {
          labelText = labelText + "<span style='color: #ff0000;'> * </span>"
        }
      }
      setLabelStr( labelText )
    }
  }, [ content, formValues ]);

  return (
    <FormGroup 
      id={ content?.id } 
      style={{ padding: content?.padding, marginBottom: 0 }}>
      <Label style={{ fontSize: content?.fontSize }}>{ ReactHTMLParser( labelStr ) }</Label>
      <Input
        type={ 'textarea' }
        value={ content && formValues[ content.dataMapIndex ][ 'value' ] }
        rows={ content?.rows }
        style={{ fontSize: content?.fontSize, resize: content?.resizable }} 
      />
    </FormGroup>
  )
}

export default TextArea