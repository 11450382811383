import React, { Component } from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback
} from 'reactstrap'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import _ from 'lodash'
import { connect } from 'react-redux'
import ReactSelect from 'react-select'
import CKEditor5 from 'components/RTE/ckeditor5'
import { FilePond, registerPlugin } from "react-filepond"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
import "filepond/dist/filepond.min.css"

import Lang from 'Lang/General'

registerPlugin( FilePondPluginImagePreview, FilePondPluginFileValidateType )

class CampaignToolsForm extends Component {

  onChange = ( key, value ) => {
    let tmp = _.cloneDeep( this.props.campaignToolData )
    if( key === 'shop_tool_image_column_one_raw' || key === 'shop_tool_image_column_two_raw' || key === 'fb_post_image_raw' || key === 'fb_ads_image_raw' || key === 'insta_story_image_raw' ){
      if( value.file ) {
        const reader = new FileReader()
        reader.onload = e => {
          tmp[key] = e.target.result
        }
        reader.readAsDataURL( value.file )
      }
    } else {
      tmp[ key ] = value
    }
    this.props.onChangeCampaignToolsHOC( this.props.campaignToolType, tmp )
  }
  render = () => {
    return(
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}>
        <Container fluid>
          <Row>
            <Col md={ 12 } >
              <Form>
                <FormGroup>
                  <Label>{ Lang[ 'TITLE' ][ this.props.data.languageReducer.lang ] }</Label>
                  <Input
                    value={ this.props.campaignToolData.title }
                    onChange={ e => this.onChange( 'title', e.target.value ) }
                    invalid={ this.props.errorMessage.title ? true : false }/>
                  <FormFeedback>{ this.props.errorMessage && this.props.errorMessage.title ? this.props.errorMessage.title : '' }</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label>{ Lang[ 'CENTER_GROUP' ][ this.props.data.languageReducer.lang ] }</Label>
                  <ReactSelect
                    isMulti
                    name="center-groups"
                    value={ this.props.campaignToolData.center_groups }
                    options={ this.props.centerGroups }
                    onChange={ value => {
                      let tmp = []
                      value.map( item => {
                        tmp.push({
                          id: item.id,
                          value: item.value,
                          label: item.value
                        })
                      })
                      this.onChange( 'center_groups', tmp )
                    }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    styles={{
                      menu: provided => ({ ...provided, zIndex: 999 })
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>{ Lang[ 'COUNTRY' ][ this.props.data.languageReducer.lang ] }</Label>
                  <ReactSelect
                    isMulti
                    name="countries"
                    options={ this.props.countries }
                    onChange={ value => {
                      let tmp = []
                      value.map( item => {
                        tmp.push({
                          id: item.id,
                          value: item.value,
                          label: item.value
                        })
                      })
                      this.onChange( 'countries', tmp )}}
                    value={ this.props.campaignToolData.countries }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    styles={{
                      menu: provided => ({ ...provided, zIndex: 998 })
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>{ Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ] }</Label>
                  <Input
                    type="select"
                    name="status"
                    id="status"
                    invalid={ false }
                    value={ this.props.campaignToolData.status }
                    onChange={ e => this.onChange( 'status', e.target.value ) }>
                    {
                      this.props.statusValues && Object.keys( this.props.statusValues ).map( key => {
                        return (
                          <option key={ key }>{ this.props.statusValues[key] }</option>
                        )
                      })
                    }
                  </Input>
                </FormGroup>
                <FormGroup className="border p-3">
                  <Label style={{ fontWeight: 600, fontSize: '14px' }}>{ Lang[ 'SHOP_TOOL' ][ this.props.data.languageReducer.lang ] }</Label>
                  <Row>
                    <Col md={ 6 }>
                      <span className="mb-2 mr-3">{ 'Select image column 1' }</span>
                      <span className="d-block mb-2" style={{ fontSize: '10px' }}>{ '600px x 600px' }</span>
                      {
                        this.props.errorMessage.shop_tool_image_column_one_raw && <span className="mb-2" style={{ color: 'red' }}>{ this.props.errorMessage.shop_tool_image_column_one_raw }</span>
                      }
                      <FilePond
                        ref={ ref => this.pond = ref }
                        value={ [] }
                        allowMultiple={ false }
                        allowFileTypeValidation={ true }
                        allowImagePreview={ true }
                        acceptedFileTypes='image/*'
                        allowFileEncode={ true }
                        onaddfile={ (error, file) => {
                          this.onChange( 'shop_tool_image_column_one_raw', file )
                        }}>
                      </FilePond>
                      {
                        this.props.campaignToolData.shop_tool_image_column_one !== '' &&
                        <img src={ this.props.campaignToolData.shop_tool_image_column_one } style={{ width: '70%', height: '300px'}}/>
                      }
                    </Col>
                    <Col md={ 6 }>
                      <span className="mb-2 mr-3">{ 'Select image column 2' }</span>
                      <span className="d-block mb-2" style={{ fontSize: '10px' }}>{ '800px x 240px' }</span>
                      {
                        this.props.errorMessage.shop_tool_image_column_two_raw && <span className="mb-2" style={{ color: 'red' }}>{ this.props.errorMessage.shop_tool_image_column_two_raw }</span>
                      }
                      <FilePond
                        ref={ ref => this.pond = ref }
                        value={ [] }
                        allowMultiple={ false }
                        allowFileTypeValidation={ true }
                        allowImagePreview={ true }
                        acceptedFileTypes='image/*'
                        allowFileEncode={ true }
                        onaddfile={ (error, file) => {
                          this.onChange( 'shop_tool_image_column_two_raw', file )
                        }}>
                      </FilePond>
                      {
                        this.props.campaignToolData.shop_tool_image_column_two !== '' &&
                        <img
                          src={ this.props.campaignToolData.shop_tool_image_column_two }
                          style={{ width: '70%', height: '120px'}}/>
                      }
                    </Col>
                  </Row>
                  <Row className="d-block mt-3 ml-2 pr-3">
                    <Label>{ Lang[ 'PRODUCT_TEXT' ][ this.props.data.languageReducer.lang ] }</Label>
                    <CKEditor5
                      disableAutoInline
                      data={ this.props.campaignToolData.shop_tool_product_text }
                      onChange={ ( event, editor ) => this.onChange( 'shop_tool_product_text', editor.getData() ) } 
                      toolbar={[ 
                        "heading", "bold", "italic", "|", "indent", "outdent", "numberedList", "bulletedList", "|", 
                        "selectAll", "undo", "redo", "blockQuote" 
                      ]} />
                  </Row>
                </FormGroup>
                <FormGroup className="border p-3">
                  <Label style={{ fontWeight: 600, fontSize: '14px' }}>{ Lang[ 'FB_INSTA_TOOL' ][ this.props.data.languageReducer.lang ] }</Label>
                  <Row>
                    <Col md={ 6 }>
                      <span className="mb-2 mr-3">{ Lang[ 'FB_POST_IMAGE' ][ this.props.data.languageReducer.lang ] }</span>
                      <span className="d-block mb-2" style={{ fontSize: '10px' }}>{ '940px x 788px' }</span>
                      {
                        this.props.errorMessage.fb_post_image_raw && <span className="mb-2" style={{ color: 'red' }}>{ this.props.errorMessage.fb_post_image_raw }</span>
                      }
                      <FilePond
                        ref={ ref => this.pond = ref }
                        value={ [] }
                        allowMultiple={ false }
                        allowFileTypeValidation={ true }
                        allowImagePreview={ true }
                        acceptedFileTypes='image/*'
                        allowFileEncode={ true }
                        onaddfile={ (error, file) => {
                          this.onChange( 'fb_post_image_raw', file )
                        }}>
                      </FilePond>
                      {
                        this.props.campaignToolData.fb_post_image !== '' &&
                        <img src={ this.props.campaignToolData.fb_post_image } style={{ width: '300px', height: '300px'}}/>
                      }
                    </Col>
                    <Col md={ 6 }>
                      <span className="mb-2 mr-3">{ Lang[ 'FB_ADS_IMAGE' ][ this.props.data.languageReducer.lang ] }</span>
                      <span className="d-block mb-2" style={{ fontSize: '10px' }}>{ '1200px x 630px' }</span>
                      {
                        this.props.errorMessage.fb_ads_image_raw && <span className="mb-2" style={{ color: 'red' }}>{ this.props.errorMessage.fb_ads_image_raw }</span>
                      }
                      <FilePond
                        ref={ ref => this.pond = ref }
                        value={ [] }
                        allowMultiple={ false }
                        allowFileTypeValidation={ true }
                        allowImagePreview={ true }
                        acceptedFileTypes='image/*'
                        allowFileEncode={ true }
                        onaddfile={ (error, file) => {
                          this.onChange( 'fb_ads_image_raw', file )
                        }}>
                      </FilePond>
                      {
                        this.props.campaignToolData.fb_ads_image !== '' &&
                        <img
                          src={ this.props.campaignToolData.fb_ads_image }
                          style={{ width: '70%', height: '120px'}}/>
                      }
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col md={ 6 }>
                      <span className="mb-2 mr-3">{ Lang[ 'INSTA_STORY_IMAGE' ][ this.props.data.languageReducer.lang ] }</span>
                      <span className="d-block mb-2" style={{ fontSize: '10px' }}>{ '1080px x 1920px' }</span>
                      {
                        this.props.errorMessage.insta_story_image_raw && <span className="mb-2" style={{ color: 'red' }}>{ this.props.errorMessage.insta_story_image_raw }</span>
                      }
                      <FilePond
                        ref={ ref => this.pond = ref }
                        value={ [] }
                        allowMultiple={ false }
                        allowFileTypeValidation={ true }
                        allowImagePreview={ true }
                        acceptedFileTypes='image/*'
                        allowFileEncode={ true }
                        onaddfile={ (error, file) => {
                          this.onChange( 'insta_story_image_raw', file )
                        }}>
                      </FilePond>
                      {
                        this.props.campaignToolData.insta_story_image !== '' &&
                        <img
                          src={ this.props.campaignToolData.insta_story_image }
                          style={{ width: '70%', height: '120px'}}/>
                      }
                    </Col>
                  </Row>
                  <Row className="pl-3 pr-3 mt-3">
                    <Label>{ Lang[ 'FB_ADS_TEXT' ][ this.props.data.languageReducer.lang ] }</Label>
                    <Input
                      type="textarea"
                      value={ this.props.campaignToolData.fb_ads_text }
                      onChange={ e => this.onChange( 'fb_ads_text', e.target.value ) }/>
                  </Row>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </Container>
      </ReactCSSTransitionGroup>
    )
  }
}
const mapStateToProps = state => ({ data: state })

export default connect( mapStateToProps )( CampaignToolsForm )
