import React, { Component } from 'react'
import {
  Col, Row, Card, CardBody,
  Form, FormGroup, Input, Button,
} from 'reactstrap'
import { FaFolder } from 'react-icons/fa'
import { connect } from 'react-redux'
import _ from 'lodash'

import ArkPagination from 'components/Pagination'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

class SharedFiles extends Component {
  render = () => {
    return(
      <Row>
        <Col md={ 12 }>
          <Form onSubmit={ e => e.preventDefault() } style={{ marginTop: '25px' }}>
            <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
              <Input
                type={ 'text' }
                value={ this.props.name }
                onChange={ e => this.props.onChangeSharedMediaHOC( 'name', e.target.value ) }
                placeholder={ Lang[ 'SEARCH_WITH_FILE_SHORTNAME' ][ this.props.data.languageReducer.lang ] }
                style={{ marginRight: '10px' }} />
              <Button 
                color="primary"
                style={{ width: '80px' }} 
                onClick={() => {
                  this.props.getSharedMediaDrive( convertObjToBase64({
                    page: 1,
                    is_paginated: true,
                    filter: {
                      name: {
                        $like: `%${ this.props.name.toLowerCase() }%`
                      }
                    }
                  }))
                }}>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }</Button>
              <Button 
                color="danger" 
                onClick={() => {
                  this.props.getSharedMediaDrive( convertObjToBase64({
                    page: 1,
                    is_paginated: true
                  }))
                  this.props.onChangeSharedMediaHOC( 'name', '' )
                }}>{ Lang[ 'RESET' ][ this.props.data.languageReducer.lang ] }</Button>
            </FormGroup>
          </Form>
          <div className="mb-2 p-1 d-flex">
            {
              this.props.sharedMediaPath.map( ( item, index ) => {
                return(
                  <>
                    <Button
                      color="link"
                      onClick={ item.nav }>
                      { item.label }
                    </Button>
                    {
                      index < this.props.sharedMediaPath.length - 1 && (
                        <p
                          style={{
                            margin: '0px',
                            padding: '5px 0',
                            color: '#545CD8' }} >{' >> '}</p>
                      )
                    }
                  </>
                )
              })
            }
          </div>
          {
            this.props.sharedMediaDrives.items && this.props.sharedMediaDrives.items.length > 0 && this.props.sharedMediaDrives.items.map( item => {
              if ( item.type !== 'Folder' ) {
                return (
                  <Card 
                    key={ item.id } 
                    style={{
                      display: 'inline-block',
                      marginRight: '10px',
                      marginBottom: '10px',
                      width: '150px'
                    }}>
                    <CardBody className="d-flex flex-column align-items-center justify-content-center">
                      {
                        item.thumb_url 
                        ? <img src={ item.thumb_url } style={{ width: '50px' }} />
                        : this.props.displayFileTypeImage( item.original_url )
                      }
                      <p className="mt-1">{ item.name }</p>
                    </CardBody>
                  </Card>
                )
              }
              if ( item.type === 'Folder' ) {
                return(
                  <Card 
                    key={ item.id } 
                    style={{
                      display: 'inline-block',
                      marginRight: '10px',
                      marginBottom: '10px',
                      width: '150px'
                    }}>
                    <CardBody className="d-flex flex-column align-items-center justify-content-center">
                      <FaFolder size={ '35px' } />
                      <p className="mt-1">{ item.name }</p>
                    </CardBody>
                  </Card>
                )
              }
            })
          }
          <ArkPagination
            totalPages={ this.props.mediaDrivesTotalPages }
            page={ this.props.sharedMediaDrivesPayload.meta ? this.props.sharedMediaDrivesPayload.meta.current_page : 1 }
            onChangePage={ pg => {
              this.props.getSharedMediaDrive( convertObjToBase64( {
                page: pg,
                is_paginated: true,
                filter: {
                  name: {
                    $like: `%${ this.props.name.toLowerCase() }%`
                  }
                }
              }) )
            }}
            selectedSearchColumn={ [] }
            normalSearchValue={ '' } />
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = state => ({ data: state })
export default connect( mapStateToProps )( SharedFiles )
