import React, { useEffect } from 'react'
import { compose } from 'redux'
import ReactTable from 'react-table'
import { 
  Card,
  CardBody,
  Button,
  UncontrolledTooltip
} from 'reactstrap'
import Moment from 'moment'
import _ from 'lodash'

import PageTitle from 'components/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Pagination from 'components/Pagination/New'
import CreateNews from './Create'
import UpdateNews from './Update'

import WithNews from './actions'
import WithCountries from 'actions/country'
import WithCenterGroups from 'actions/centerGroup'
import Lang from 'Lang/General'

const NewsSettings = props => {

  useEffect(() => {
    props.getNews({
      page: 1,
      is_paginated: true,
      filter: {
        status: 'ACTIVE'
      }
    })
    props.getCountries()
    props.getCenterGroupsForSelect({ is_paginated: false })
  }, [])

  const handleSecurityModulesCreate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'News and Products' })
      return tmpModules.is_create
    }
  }

  const handleSecurityModulesUpdate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'News and Products' })
      return tmpModules.is_update
    }
  }

  return (
    <>
      <PageTitle
        heading={ Lang[ 'ADMIN_PANEL_NEWS' ][ props.data.languageReducer.lang ] }
        icon="pe-7s-note icon-gradient bg-happy-fisher"
        buttons={[
          handleSecurityModulesCreate() && {
            buttonText: Lang[ 'CREATE_NEWS' ][ props.data.languageReducer.lang ],
            onClick: () => {
              props.onChangeNewsHOC( 'showCreateNews', true )
            },
            display: true
          }
        ]}/>
      <Card>
        <CardBody>
          <ReactTable
            data={ props.news.data }
            NoDataComponent={ () => <span></span> }
            columns={[
              {
                Header: Lang[ 'NAME' ][ props.data.languageReducer.lang ],
                Cell: ( row ) => (
                  <>
                    {
                      Object.keys( row.original.name ).map( key => {
                        if( key === props.data.languageReducer.lang ) {
                          return (
                            <span key={ `name-${ key }` }>{ row.original.name[ key ] }</span>
                          )
                        }
                      })
                    }
                  </>
                )
              },
              {
                Header: Lang[ 'STATUS' ][ props.data.languageReducer.lang ],
                accessor: 'status'
              },
              {
                Header: Lang[ 'PUBLISH_FROM' ][ props.data.languageReducer.lang ],
                Cell: ( row ) => (
                  <>
                    <span>{ Moment( row.original.publish_from ).format( 'DD MMM YYYY' ) }</span>
                  </>
                )
              },
              {
                Header: Lang[ 'PUBLISH_TO' ][ props.data.languageReducer.lang ],
                Cell: ( row ) => (
                  row.original.has_expiry_date ? (
                    <>
                      <span>{ Moment( row.original.publish_to ).format( 'DD MMM YYYY' ) }</span>
                    </>
                  ) : (
                    <>
                      <span>{ Lang[ 'NO_EXPIRY_DATE' ][ props.data.languageReducer.lang ] }</span>
                    </>
                  )
                )
              },
              {
                Header: Lang[ 'ACTION' ][ props.data.languageReducer.lang ],
                Cell: ( row ) => (
                  <>
                    <Button
                      id={ `News-${ row.original.id }-update-news` } 
                      color="primary"
                      disabled={ !handleSecurityModulesUpdate() }
                      onClick={() => {
                        props.getSelectedNews( row.original.id )
                      }}>
                      <i className="pe-7s-pen"> </i>
                    </Button>
                    <UncontrolledTooltip
                      target={ `News-${ row.original.id }-update-news` }
                      placement="top">{ Lang[ 'UPDATE_NEWS' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                  </>
                )
              }
            ]}
            showPagination={ false } />
          <Pagination
            meta={ props.news.meta }
            onChangePage={ pg => {

            }} />
        </CardBody>
      </Card>
      <CreateNews { ...props } />
      <UpdateNews { ...props } />
      { ( props.onLoadNews || props.onLoadCountries || props.onLoadCenterGroups ) && <LoadingOverlay /> }
    </>
  )
}

export default compose(
  WithCenterGroups,
  WithNews,
  WithCountries
)( NewsSettings )