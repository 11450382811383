import React, { Component } from 'react'
import { Button, Tooltip } from '@material-ui/core'
import Lang from 'Lang/General'

class LeftPanel extends Component {
  render = () => {
    return (
      <div className="renderer-left">
        <div className="btn-left">
          <Tooltip title={ Lang[ 'ADD_STYLESHEET' ][ this.props.lang ] } placement={ 'top' }>
            <Button
              classes={{ root: 'btn btn-primary' }}
              onClick={() => this.props.updateMainContainerState( 'showTemplateStylesheetDialog', true )}>
              <span className="icon">S</span>
            </Button>
          </Tooltip>
        </div>
        <div className="btn-left">
          <Tooltip title={ Lang[ 'ADD_JAVASCRIPT' ][ this.props.lang ] } placement={ 'top' }>
            <Button
              classes={{ root: 'btn btn-primary' }}
              onClick={() => this.props.updateMainContainerState( 'showTemplateJSDialog', true )}>
              <span className="icon">JS</span>
            </Button>
          </Tooltip>
        </div>
        <div className="btn-left">
          <Tooltip title={ Lang[ 'CHANGE_RENDERER_VIEWPORT' ][ this.props.lang ] } placement={ 'top' }>
            <Button
              classes={{ root: 'btn btn-primary' }}
              onClick={() => this.props.updateMainContainerState( 'showRendererViewportAdjust', true )}>
              <span className="icon">V</span>
            </Button>
          </Tooltip>
        </div>
        <div className="btn-left">
          <Tooltip title={ Lang[ 'HEADER_CONFIGURATIONS' ][ this.props.lang ] } placement={ 'top' }>
            <Button
              classes={{ root: 'btn btn-primary' }}
              onClick={() => this.props.updateMainContainerState( 'showHeaderEditorDialog', true )}>
              <span className="icon">H</span>
            </Button>
          </Tooltip>
        </div>
        <div className="btn-left">
          <Tooltip title={ Lang[ 'FOOTER_CONFIGURATIONS' ][ this.props.lang ] } placement={ 'top' }>
            <Button
              classes={{ root: 'btn btn-primary' }}
              onClick={() => this.props.updateMainContainerState( 'showFooterEditorDialog', true )}>
              <span className="icon">F</span>
            </Button>
          </Tooltip>
        </div>
      </div>
    )
  }
}

export default LeftPanel