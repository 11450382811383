import React from 'react'
import { Route } from 'react-router-dom'

import NewsSettings from './News'
import ProductSettings from './Products'
import ProductRequest from './ProductsRequest/Listings'
import ProductRequestStatus from './ProductsRequest/Status'

const DashboardSettings = props => {
  return (
    <>
      <Route exact path="/dashboard/settings/news" component={ NewsSettings } />
      <Route exact path="/dashboard/settings/products" component={ ProductSettings } />
      <Route exact path="/dashboard/settings/product-request" component={ ProductRequest } />
      <Route exact path="/dashboard/settings/product-request-status" component={ ProductRequestStatus } />
    </>
  )
}

export default DashboardSettings