import React, { Component } from 'react'
import Moment from 'moment'
import _, { debounce } from 'lodash'
import { compose } from 'redux'
import { 
  Button, Card, CardBody, CardHeader, CardFooter,
  Form, FormGroup, Label, Input, Col, Row,
  UncontrolledTooltip
} from 'reactstrap'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PageTitle from 'components/PageTitle'
import ARKTable from 'components/Table'

import BookedCampaignHOC from './actions'
import UsersHOC from 'containers/UsersManagement/actions'
import ClientsHOC from './actions/Clients'
import WithConsultants from './actions/Consultant'
import WithCampaigns from './actions/Campaigns'
import WithCampaignTypes from './actions/CampaignTypes'
import EditForm from './Edit'
import CreateForm from './Create'
import ExportOnetimerModal from './components/ExportOnetimer'

import { convertObjToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'
import LangTepe from 'Lang/TePe'

class BookedCampaign extends Component{
  componentDidMount = () => {
    this.getBookedCampaigns()  
    this.props.getConsultants( convertObjToBase64({
      is_paginated: false,
      consultant: {
        filter: {
          $or: [
            { status: 'ACTIVE' }
          ]
        }
      }
    }))
    this.props.getCampaignTypes( convertObjToBase64({
      is_paginated: false,
      filter: {
        $or: [
          { status: 'ACTIVE' }
        ]
      }
    }))
    this.props.getUsers( convertObjToBase64({
      filter: {
        role_id: 8
      }
    }))
  }

  componentDidUpdate = pp => {
    if( pp.showEdit && !this.props.showEdit ){
      this.getBookedCampaigns()
    }
  }

  getBookedCampaigns = ( pg ) => {
    let tmpData = _.cloneDeep( this.props.searchCampaignParams )
    let tmp = {
      is_paginated: true,
      page: pg ? pg : 1,
      filter: {}
    }
    Object.keys( tmpData ).map( key => {
      if( tmpData[ key ] !== '' ) {
        if( key === 'status' ) {
          tmp.filter[ key ] = tmpData[ key ]
        } else if( key === 'is_app_campaign' ) {
          if( tmpData[ key ] === 'true' ) {
            tmp.filter[ key ] = true
          } else if( tmpData[ key ] === 'false' ){
            tmp.filter[ key ] = false
          }
        } else if( key === 'user_id' ) {
          tmp.filter[ key ] = parseInt( tmpData[ key ] )
        } else {
          tmp.filter[ key ] = {
            $like: `%${ tmpData[ key ].toLowerCase() }%`
          }
        }
      } 
    })
    if( _.isEmpty( tmp.filter ) ) {
      delete tmp.filter
    }
    this.props.getBookedCampaigns( convertObjToBase64( tmp ) )  
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    let tmpCampaignModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'OLS' })
      tmpCampaignModules = _.find( tmp.modules, { name: 'Campaign' } )
      return tmpModules.is_create || tmpCampaignModules.is_create
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    let tmpCampaignModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'OLS' })
      tmpCampaignModules = _.find( tmp.modules, { name: 'Campaign' } )
      return tmpModules.is_update || tmpCampaignModules.is_update
    }
  }

  searchBookedCampaigns = debounce(() => {
    this.getBookedCampaigns()
  }, 500 )

  render = () => {
    const campaignStatus = [
      {
        label: LangTepe[ 'NEW' ][ this.props.data.languageReducer.lang ],
        value: 'NEW',
        color: '#E17058'
      },
      {
        label: LangTepe[ 'IN_PROGRESS' ][ this.props.data.languageReducer.lang ],
        value: 'IN_PROGRESS',
        color: '#B7F5F5'
      },
      {
        label: LangTepe[ 'ACTIVE' ][ this.props.data.languageReducer.lang ],
        value: 'ACTIVE',
        color: '#C2F892'
      },
      {
        label: LangTepe[ 'COMPLETED' ][ this.props.data.languageReducer.lang ],
        value: 'COMPLETED',
        color: '#F7FBFA'
      }
    ]

    const type = [
      {
        label: LangTepe[ 'MY_CAMPAIGNS' ][ this.props.data.languageReducer.lang ],
        value: 'Default'
      },
      {
        label: LangTepe[ 'ALL' ][ this.props.data.languageReducer.lang ],
        value: 'All'
      },
      {
        label: LangTepe[ 'OLS_CAMPAIGNS' ][ this.props.data.languageReducer.lang ],
        value: false
      },
      {
        label: LangTepe[ 'APP_CAMPAIGN' ][ this.props.data.languageReducer.lang ],
        value: true
      },
    ]

    const status = [
      {
        label: LangTepe[ 'NEW' ][ this.props.data.languageReducer.lang ],
        value: 'NEW'
      },
      {
        label: LangTepe[ 'IN_PROGRESS' ][ this.props.data.languageReducer.lang ],
        value: 'IN_PROGRESS'
      },
      {
        label: LangTepe[ 'ACTIVE' ][ this.props.data.languageReducer.lang ],
        value: 'ACTIVE'
      },
      {
        label: LangTepe[ 'COMPLETED' ][ this.props.data.languageReducer.lang ],
        value: 'COMPLETED'
      }
    ]

    const fbStatus = [
      {
        label: LangTepe[ 'NEW' ][ this.props.data.languageReducer.lang ],
        value: 'NEW'
      },
      {
        label: LangTepe[ 'IN_PROGRESS' ][ this.props.data.languageReducer.lang ],
        value: 'IN_PROGRESS'
      },
      {
        label: LangTepe[ 'QM_COMPLETED' ][ this.props.data.languageReducer.lang ],
        value: 'QM_COMPLETED'
      },
      {
        label: LangTepe[ 'ACTIVE' ][ this.props.data.languageReducer.lang ],
        value: 'ACTIVE'
      },
      {
        label: LangTepe[ 'COMPLETED' ][ this.props.data.languageReducer.lang ],
        value: 'COMPLETED'
      }
    ]

    const googleStatus = [
      {
        label: LangTepe[ 'ACTIVE' ][ this.props.data.languageReducer.lang ],
        value: 'ACTIVE'
      },
      {
        label: LangTepe[ 'INACTIVE' ][ this.props.data.languageReducer.lang ],
        value: 'INACTIVE'
      },
      {
        label: LangTepe[ 'IN_PROGRESS' ][ this.props.data.languageReducer.lang ],
        value: 'IN_PROGRESS'
      },
    ]

    const getTrProps = ( state, rowInfo, instance ) => {
      if( rowInfo ){
        let tmp = _.find( campaignStatus, { value: rowInfo.original.status })
        if( tmp ) {
          return {
            style: {
              background: tmp.color,
              color: '#000'
            }
          }
        }
      }
      return {};
    }

    return(
      <>
        <PageTitle
          heading={ Lang[ 'BOOKED_CAMPAIGN' ][ this.props.data.languageReducer.lang ] }
          icon="pe-7s-note icon-gradient bg-happy-fisher"
          buttons={[
            {
              buttonText: Lang[ 'CREATE_ONE_TIMER' ][ this.props.data.languageReducer.lang ],
              onClick: () => this.props.onChangeBookedCampaignHOC( 'showCreateBookedCampaign', true ),
              display: this.handleSecurityModulesCreate()
            }, 
            {
              buttonText: Lang[ 'EXPORT_ONETIMER' ][ this.props.data.languageReducer.lang ],
              onClick: () => this.props.onChangeBookedCampaignHOC( 'showExportOnetimer', true ),
              display: this.handleSecurityModulesCreate()
            }
          ]} 
        />
        <Card className='mb-3' >
          <CardHeader>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }</CardHeader>
          <CardBody>
            <Form>
              <Row>
                <Col md={12}>
                  <Label>{ Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ] }</Label>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
                  {
                    campaignStatus.map( item => {
                      return(
                        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginRight: '15px' }}>
                          <div style={{ width: '15px', height: '15px', background: item.color, marginRight: '5px', marginBottom: '5px', border: 'solid #000 .5px' }}>
                          </div>
                          <label>{ item.label }</label>
                        </div>
                      )
                    })
                  }
                  </div>
                </Col>
                <Col md='4'>
                  <FormGroup>
                    <Label>{ Lang[ 'TYPE' ][ this.props.data.languageReducer.lang ] }</Label>
                    <Input
                      type="select"
                      name="campaign-type"
                      width='200'
                      value={ this.props.searchCampaignParams.is_app_campaign }
                      onChange={ async e => {
                        let tmp = _.cloneDeep( this.props.searchCampaignParams )
                        tmp.is_app_campaign = e.target.value
                        if( e.target.value === 'Default' ) {
                          tmp.user_id = this.props.data.profileReducer.profile.id
                        } else {
                          tmp.user_id = ''
                        }
                        await this.props.onChangeBookedCampaignHOC( 'searchCampaignParams', tmp )
                        this.getBookedCampaigns()
                      }}>
                      {
                        type.map( item => {
                          return(
                            <option key={ item.value } value={ item.value }>{ item.label }</option>
                          )
                        })
                      }
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label>{ Lang[ 'CENTER_NAME' ][ this.props.data.languageReducer.lang ] }</Label>
                    <Input
                      name="center-name"
                      placeholder={ Lang[ 'SEARCH_BY_CENTER_NAME' ][ this.props.data.languageReducer.lang ] }
                      value={ this.props.searchCampaignParams.center_name }
                      onChange={ e => {
                        this.props.onChangeBookedCampaignHOC( 'searchCampaignParams', {
                          ...this.props.searchCampaignParams,
                          center_name: e.target.value
                        })
                        this.searchBookedCampaigns()
                      }}/>
                  </FormGroup>
                  <FormGroup>
                    <Label>{ Lang[ 'CAMPAIGN_NAME' ][ this.props.data.languageReducer.lang ] }</Label>
                    <Input
                      name="campaign-name"
                      placeholder={ Lang[ 'SEARCH_BY_CAMPAIGN_NAME' ][ this.props.data.languageReducer.lang ] }
                      value={ this.props.searchCampaignParams.title }
                      onChange={ e => {
                        this.props.onChangeBookedCampaignHOC( 'searchCampaignParams', {
                          ...this.props.searchCampaignParams,
                          title: e.target.value
                        })
                        this.searchBookedCampaigns()
                      }} />
                  </FormGroup>
                  <FormGroup>
                    <Label>{ Lang[ 'OLS_TEAM_USER' ][ this.props.data.languageReducer.lang ] }</Label>
                    <Input
                      type="select"
                      name="campaign-title"
                      value={ this.props.searchCampaignParams.user_id }
                      onChange={ async e => {
                        await this.props.onChangeBookedCampaignHOC( 'searchCampaignParams', {
                          ...this.props.searchCampaignParams,
                          user_id: e.target.value
                        })
                        this.getBookedCampaigns()
                      }}>
                      <option value={ '' }>{ Lang[ 'SELECT_PLACEHOLDER' ][ this.props.data.languageReducer.lang ] }</option>
                      {
                        this.props.users.data && this.props.users.data.length > 0 && this.props.users.data.map( item => {
                          return(
                            <option key={ item.id } value={ item.id }>{ `${ item.first_name } ${ item.last_name }` }</option>
                          )
                        })
                      }
                    </Input>
                  </FormGroup>
                </Col>
                <Col md='4'>
                  <FormGroup>
                    <Label>{ Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ] }</Label>
                    <Input
                      type="select"
                      name="campaign-title"
                      value={ this.props.searchCampaignParams.status }
                      onChange={ async e => {
                        await this.props.onChangeBookedCampaignHOC( 'searchCampaignParams', {
                          ...this.props.searchCampaignParams,
                          status: e.target.value
                        })
                        this.getBookedCampaigns()
                      }}>
                      <option value={ '' }>{ Lang[ 'SELECT_PLACEHOLDER' ][ this.props.data.languageReducer.lang ] }</option>
                      {
                        status.map( item => {
                          return(
                            <option key={ item.value } value={ item.value }>{ item.label }</option>
                          )
                        })
                      }
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label>{ Lang[ 'FB_STATUS' ][ this.props.data.languageReducer.lang ] }</Label>
                    <Input
                      type="select"
                      name="campaign-fb-status"
                      value={ this.props.searchCampaignParams.fb_status }
                      onChange={ async e => {
                        await this.props.onChangeBookedCampaignHOC( 'searchCampaignParams', {
                          ...this.props.searchCampaignParams,
                          fb_status: e.target.value
                        })
                        this.getBookedCampaigns()
                      }}>
                      <option value={ '' }>{ Lang[ 'SELECT_PLACEHOLDER' ][ this.props.data.languageReducer.lang ] }</option>
                      {
                        fbStatus.map( item => {
                          return(
                            <option key={ item.value } value={ item.value }>{ item.label }</option>
                          )
                        })
                      }
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label>{ Lang[ 'GOOGLE_STATUS' ][ this.props.data.languageReducer.lang ] }</Label>
                    <Input
                      type="select"
                      name="campaign-google-status"
                      value={ this.props.searchCampaignParams.google_status }
                      onChange={ async e => {
                        await this.props.onChangeBookedCampaignHOC( 'searchCampaignParams', {
                          ...this.props.searchCampaignParams,
                          google_status: e.target.value
                        })
                        this.getBookedCampaigns()
                      }}>
                      <option value={ '' }>{ Lang[ 'SELECT_PLACEHOLDER' ][ this.props.data.languageReducer.lang ] }</option>
                      {
                        googleStatus.map( item => {
                          return(
                            <option key={ item.value } value={ item.value }>{ item.label }</option>
                          )
                        })
                      }
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </CardBody>
          <CardFooter>
            <Button
              color="danger"
              className="ml-auto"
              onClick={ async() => {
                await this.props.onChangeBookedCampaignHOC( 'searchCampaignParams', {
                  is_app_campaign: 'false',
                  center_name: '',
                  title: '',
                  status: '',
                  fb_status: '',
                  google_status: '',
                  user_id: '',
                })
                this.getBookedCampaigns()
              }}>{ Lang[ 'RESET' ][ this.props.data.languageReducer.lang ] }</Button>
          </CardFooter>
        </Card>
        <ARKTable
          data={ this.props.bookedCampaigns.data }
          columns={[
            {
              Header: Lang[ 'TYPE' ][ this.props.data.languageReducer.lang ],
              accessor: 'is_app_campaign',
              Cell: ({value}) => {
                if( value === true ) {
                  return Lang[ 'APP' ][ this.props.data.languageReducer.lang ]
                } else {
                  return Lang[ 'STANDARD' ][ this.props.data.languageReducer.lang ]
                }
              }
            },
            {
              Header: Lang[ 'CAMPAIGN_NAME' ][ this.props.data.languageReducer.lang ],
              accessor: 'title',
              width: 200
            },
            {
              Header: Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ],
              accessor: 'status',
              width: 110,
              Cell: ({ value }) => value && Lang[ value ][ this.props.data.languageReducer.lang ]
            },
            {
              Header: Lang[ 'FB_STATUS' ][ this.props.data.languageReducer.lang ],
              accessor: 'fb_status',
              width: 125,
              Cell: ({ value }) => value && Lang[ value ][ this.props.data.languageReducer.lang ]
            },
            {
              Header: Lang[ 'GOOGLE_STATUS' ][ this.props.data.languageReducer.lang ],
              accessor: 'google_status',
              width: 110
            },
            {
              Header: Lang[ 'CREATED_ON' ][ this.props.data.languageReducer.lang ],
              accessor: 'created_at',
              Cell: ({value}) => (
                Moment( value ).format( 'DD-MM-YYYY' )
              )
            },
            {
              Header: Lang[ 'START_DATE' ][ this.props.data.languageReducer.lang ],
              accessor: 'start_date',
              Cell: ({value}) => (
                Moment( value ).format( 'DD-MM-YYYY' )
              )
            },
            {
              Header: Lang[ 'END_DATE' ][ this.props.data.languageReducer.lang ],
              accessor: 'end_date',
              Cell: ({value}) => (
                Moment( value ).format( 'DD-MM-YYYY' )
              )
            },
            {
              Header: Lang[ 'CENTER' ][ this.props.data.languageReducer.lang ],
              accessor: 'center_name',
              width: 150,
              Cell: ({ original }) => ( original.center_name || original.client_company_name )
            },
            {
              Header: Lang[ 'DOMAIN' ][ this.props.data.languageReducer.lang ],
              accessor: 'site_domain',
              width: 200
            },
            {
              Header: Lang[ 'ONLY_WOMAN' ][ this.props.data.languageReducer.lang ],
              accessor: 'only_for_woman',
              Cell: ({value}) => {
                if( value === true ) {
                  return Lang[ 'YES' ][ this.props.data.languageReducer.lang ]
                } else {
                  return Lang[ 'NO' ][ this.props.data.languageReducer.lang ]
                }
              },
              width: 100
            },
            {
              Header: Lang[ 'FB_BUDGET' ][ this.props.data.languageReducer.lang ],
              accessor: 'fa_budget',
              width: 100
            },
            {
              Header: Lang[ 'ADWORDS_BUDGET' ][ this.props.data.languageReducer.lang ],
              accessor: 'ga_budget',
              width: 100
            },
            {
              Header: Lang[ 'ONE_TIMER' ][ this.props.data.languageReducer.lang ],
              accessor: 'client_id',
              Cell: ({ value }) => value > 0
                ? Lang[ 'YES' ][ this.props.data.languageReducer.lang ] 
                : Lang[ 'NO' ][ this.props.data.languageReducer.lang ],
              width: 100
            },
            {
              Header: Lang[ 'REMARK' ][ this.props.data.languageReducer.lang ],
              accessor: 'remark',
              Cell: ({value}) => {
                if( value !== '' ) {
                  return Lang[ 'AVAILABLE' ][ this.props.data.languageReducer.lang ]
                } else {
                  return ''
                }
              } 
            },
            {
              Header: Lang[ 'INTERNAL_REMARK' ][ this.props.data.languageReducer.lang ],
              accessor: 'internal_remark',
              Cell: ({value}) => {
                if( value !== '' ) {
                  return Lang[ 'AVAILABLE' ][ this.props.data.languageReducer.lang ]
                } else {
                  return ''
                }
              }
            },
            {
              Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
              accessor: "id",
              Cell: ({value}) => (
                <div>
                  {
                    this.handleSecurityModulesUpdate() && (
                      <>
                        <Button
                          id={ `Booked-campaign-${ value }-edit-booked-campaign` }
                          className="mb-2 mr-2 btn-icon btn-icon-only"
                          color="primary"
                          onClick={ () => {
                            this.props.getSelectedBookedCampaign( value )
                            this.props.onChangeBookedCampaignHOC( 'showEdit', true )
                          }}>
                            <i className="pe-7s-note"> </i>
                        </Button>
                        <UncontrolledTooltip
                          target={ `Booked-campaign-${ value }-edit-booked-campaign` }
                          placement="top">{ Lang[ 'EDIT_BOOKED_CAMPAIGN_SEN_CASE' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                      </>
                    )
                  }
                </div>
              )
            }
          ]}
          defaultPageSize={ 10 }
          showPagination={ false }
          page={ this.props.bookedCampaigns.meta ? this.props.bookedCampaigns.meta.current_page : 1 }
          totalPages={ this.props.bookedCampaignsTotalPages }
          onChangePage={ pg => this.getBookedCampaigns( pg ) }
          getTrProps={ getTrProps } />
        <EditForm { ...this.props } />
        <CreateForm { ...this.props } />
        <ExportOnetimerModal { ...this.props } />
        { ( this.props.data.ajaxReducer.ajaxCallProgress > 0 || this.props.onLoadBookedCampaign ) && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose(
  WithCampaignTypes,
  WithCampaigns,
  WithConsultants,
  BookedCampaignHOC,
  UsersHOC,
  ClientsHOC
) ( BookedCampaign )
