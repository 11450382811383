import React, { useState, useEffect } from 'react'
import { 
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button, Row, Col
} from 'reactstrap'
import uniqid from 'uniqid'
import _ from 'lodash'
import { compose } from "redux"

import Renderer from './Renderer'
import ConfigPanel from './ConfigPanel'
import Lang from 'Lang/General'
import TemplateComponentHOC from 'actions/TemplateComponents'
import TemplateFormHOC from 'actions/TemplateForms'

const DomEditor = props => {
  const [ dom, updateDom ] = useState({
    id: uniqid(),
    type: 'div',
    className: 'container',
    children: [
      {
        id: uniqid(),
        type: 'div',
        className: 'row',
        style: { position: 'relative' },
        children: [
          {
            id: uniqid(),
            type: "div",
            className: 'col-sm-12',
            col: 12,
            children: [
              {
                id: uniqid(),
                type: 'div',
                variant: 'text',
                content: {
                  en: "Please double click to edit content",
                  de: "Bitte doppelklicken Sie, um den Inhalt zu bearbeiten",
                  it: "Si prega di fare doppio clic per modificare il contenuto",
                  nl: "Dubbelklik om de inhoud te bewerken"
                }
              }
            ],
            style: { 
              minHeight: '100px', 
              position: 'relative', 
              padding: '0px 0px 0px 0px',
              top: 0,
              left: 0,
              backgroundColor: ''
            },
            minHeight: 100,
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            top: 0,
            left: 0,
            isContentCenter: false,
            isColumnContainer: true,
            isFloat: false
          }
        ]
      }
    ],
    own_created_section: true
  })
  const [ selectedComponentId, updateSelectedComponentId ] = useState( '' )
  const [ selectedComponent, updateSelectedComponent ] = useState({})
  const [ selectedColId, updateSelectedColId ] = useState( '' )
  const [ selectedCol, updateSelectedCol ] = useState({})
  const [ selectedLang, updateSelectedLang ] = useState( props.selectedLanguage || 'de' )
  const [ isPreviewMode, setPreviewMode ] = useState( false )
  const [ showCreateColumn, toggleShowCreateColumn ] = useState( false )
  const [ isDomInitialised, setIsDomInitialised ] = useState( false )

  useEffect( () => {
    if( props.showDomEditor && props.parent[ props.childrenKey ] ){
      let tmp = !_.isEmpty( props.parent[ props.childrenKey ] )
        ? _.cloneDeep( props.parent[ props.childrenKey ] )
        : _.cloneDeep( dom )
      if( 'en' in tmp ){
        tmp = {
          id: uniqid(),
          type: 'div',
          className: 'container',
          children: [
            {
              id: uniqid(),
              type: 'div',
              className: 'row',
              style: { position: 'relative' },
              children: [
                {
                  id: uniqid(),
                  type: "div",
                  className: 'col-sm-12',
                  col: 12,
                  children: [
                    {
                      id: uniqid(),
                      type: 'div',
                      variant: 'text',
                      content: {
                        en: !_.isEmpty( tmp[ 'en' ] ) ? tmp[ 'en' ] : "Please double click to edit content",
                        de: !_.isEmpty( tmp[ 'de' ] ) ? tmp[ 'de' ] : "Bitte doppelklicken Sie, um den Inhalt zu bearbeiten",
                        it: !_.isEmpty( tmp[ 'it' ] ) ? tmp[ 'it' ] : "Si prega di fare doppio clic per modificare il contenuto",
                        nl: !_.isEmpty( tmp[ 'nl' ] ) ? tmp[ 'nl' ] : "Dubbelklik om de inhoud te bewerken"
                      }
                    }
                  ],
                  style: { 
                    minHeight: '100px', 
                    position: 'relative', 
                    padding: '0px 0px 0px 0px',
                    top: 0,
                    left: 0,
                    backgroundColor: ''
                  },
                  minHeight: 100,
                  paddingTop: 0,
                  paddingRight: 0,
                  paddingBottom: 0,
                  paddingLeft: 0,
                  top: 0,
                  left: 0,
                  isContentCenter: false,
                  isColumnContainer: true,
                  isFloat: false
                }
              ]
            }
          ],
          own_created_section: true
        }
      }
      if( tmp.id !== dom.id ){
        updateSelectedColId( tmp.children[ 0 ].children[ 0 ]?.id || '' )
        updateSelectedCol( tmp.children[ 0 ].children[ 0 ] || {} )
        updateDom( tmp )
      }
    }
  }, [ props.showDomEditor, props.parent ] )

  useEffect( () => {
    if( props.showDomEditor ){
      if ( showCreateColumn ) {
        updateSelectedComponent( selectedCol.children[ 0 ] )
        updateSelectedComponentId( selectedCol.children[ 0 ].id )
        toggleShowCreateColumn( false )
        document.getElementById( 'renderer-edit-panel' ).scrollTop = document.getElementById( 'renderer-edit-panel' ).scrollHeight
      } else if ( !_.isEmpty( selectedComponentId ) ) {
        updateSelectedComponent( {} )
        updateSelectedComponentId( '' )
      }
    }
  }, [ selectedColId ] )

  useEffect( () => {
    if( 
      !isDomInitialised &&
      props.showDomEditor && 
      !_.isEmpty( selectedColId ) &&
      dom.children[ 0 ].children[ 0 ]?.children[ 0 ]
    ){
      updateSelectedComponentId( dom.children[ 0 ].children[ 0 ].children[ 0 ].id )
      updateSelectedComponent( dom.children[ 0 ].children[ 0 ].children[ 0 ] )
      setIsDomInitialised( true )
    }
  }, [ props.showDomEditor, selectedColId ] )

  useEffect( () => {
    if ( !_.isEmpty( selectedComponent ) && props.from === "section-editor" ) {
      let tmpSelectedColumn = []
      FindNearestCol( dom, selectedComponent, {}, tmpSelectedColumn )
      if( tmpSelectedColumn.length > 0 ){
        updateSelectedCol( tmpSelectedColumn[ 0 ] )
        updateSelectedColId( tmpSelectedColumn[ 0 ].id )
      }
    }
  }, [ selectedComponent ] )

  const saveDom = () => {
    props.updateParent( props.childrenKey, _.cloneDeep( dom ) )
    props.updateShowDomEditor( false )
  }

  return (
    <Modal isOpen={ props.showDomEditor } style={{ maxWidth: '100vw' }}>
      <ModalHeader 
        toggle={ () => {
          if( props.from === "section-editor" ){
            props.updateShowDomEditor( false )
          } else {
            saveDom()
          }
        }} 
      />
      <ModalBody>
        <Row>
          <Renderer 
            content={ props.from === "section-editor" ? dom[ 'children' ] : dom[ 'children' ][ 0 ][ 'children' ] }
            dom={ dom }
            updateDom={ updateDom }
            selectedComponentId={ selectedComponentId }
            updateSelectedComponentId={ updateSelectedComponentId }
            selectedComponent={ selectedComponent }
            updateSelectedComponent={ updateSelectedComponent }
            selectedColId={ selectedColId }
            updateSelectedColId={ updateSelectedColId }
            selectedCol={ selectedCol }
            updateSelectedCol={ updateSelectedCol }
            selectedLang={ selectedLang }
            updateSelectedLang={ updateSelectedLang }
            isPreviewMode={ isPreviewMode }
            setPreviewMode={ setPreviewMode }
            showCreateColumn={ showCreateColumn }
            toggleShowCreateColumn={ toggleShowCreateColumn }
            FindComponentAndPatch={ FindComponentAndPatch }
            AddComponent={ AddComponent }
            { ...props } />
          {
            !isPreviewMode && (
              <Col 
                md={ 5 }
                className='d-flex flex-column pb-2'
                style={{ height: "70vh", overflowY: "scroll" }} >
                <ConfigPanel
                  dom={ dom }
                  updateDom={ updateDom }
                  selectedComponentId={ selectedComponentId }
                  updateSelectedComponentId={ updateSelectedComponentId }
                  selectedComponent={ selectedComponent }
                  updateSelectedComponent={ updateSelectedComponent }
                  selectedColId={ selectedColId }
                  updateSelectedColId={ updateSelectedColId }
                  selectedCol={ selectedCol }
                  updateSelectedCol={ updateSelectedCol }
                  selectedLang={ selectedLang }
                  updateSelectedLang={ updateSelectedLang }
                  FindComponentAndPatch={ FindComponentAndPatch }
                  AddComponent={ AddComponent }
                  { ...props } />
              </Col>
            )
          }
        </Row>
      </ModalBody>
      <ModalFooter>
        {
          !props.isViewOnly && (
            <Button 
              color="primary"
              disabled={ dom.children[0].children.length === 0 }
              onClick={ () => saveDom() }>
              { Lang[ 'SUBMIT' ][ props.selectedLanguage ] }
            </Button>
          )
        }
      </ModalFooter>
    </Modal>
  )
}

export default compose(
  TemplateComponentHOC,
  TemplateFormHOC
)( DomEditor )

const FindComponentAndPatch = ( dom, newComponent, isRemove ) => {
  if( dom?.id === newComponent?.id ) {
    if ( !isRemove ) { 
      dom = newComponent
    } else {
      return undefined
    }
  } else if ( dom?.children?.length > 0 ) {
    let tmpDom = _.cloneDeep( dom )
    tmpDom.children = []
    if ( isRemove && _.find( dom.children, { id: newComponent.id } ) ){
      tmpDom.children = _.filter( dom.children, item => item.id !== newComponent.id )
      if ( tmpDom.children.length === 0 ) {
        return undefined
      }
    } else {
      dom.children.map( child => {
        let tmpChild = FindComponentAndPatch( child, newComponent, isRemove )
        tmpChild && tmpDom.children.push( tmpChild ) 
      })
    }
    return tmpDom
  }
  return dom
}

const AddComponent = ( dom, sibling, newComponent ) => {
  if ( dom?.children?.length > 0 ) {
    let tmpDom = _.cloneDeep( dom )
    let tmpIndex = _.findIndex( dom.children, { id: sibling.id } )

    tmpDom.children = []
    if ( tmpIndex === -1 ) {
      dom.children.map( child => {
        let tmpChild = AddComponent( child, sibling, newComponent )
        tmpChild && tmpDom.children.push( tmpChild ) 
      })
    } else {
      tmpDom.children = _.cloneDeep( dom.children )
      tmpDom.children.splice( tmpIndex, 0, newComponent )
    }
    return tmpDom
  }
  return dom
}

const FindNearestCol = ( dom, selectedComponent, currentColumn, selectedColumn ) => {
  if( dom.id === selectedComponent.id ) {
    selectedColumn.push( currentColumn )
  } else if ( dom?.children?.length > 0 ) {
    dom.children.map( child => FindNearestCol( 
      child, 
      selectedComponent, 
      child.className?.indexOf( 'col' ) > -1 ? child: currentColumn, 
      selectedColumn 
    ))
  }
  return dom
}