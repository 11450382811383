import React, { useState, useEffect } from "react"
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import uniqid from "uniqid"
import _ from "lodash"

import Lang from "Lang/General"
import MediaDatabase from 'SharedModules/MediaDatabase'

const CarouselImageBlockForm = ({
  lang,
  selectedEditableComponent,
  selectedSection,
  findComponentAndPatch,
  onChangeSectionHOC,
  toggleMediaDatabase
}) => {
  const[ selectedComponent, setSelectedComponent ] = useState({})
  const[ selectedIndex, setSelectedIndex ] = useState(0)

  useEffect(()=>{
    if( selectedSection.type==='carousel-image-block' ){
      setSelectedComponent( selectedSection )
    } else if( selectedEditableComponent.type==='carousel-image-block' ){
      setSelectedComponent( selectedEditableComponent )
    }
  }, [ selectedSection, selectedEditableComponent ])
  
  return (
    <Card className="mb-2">
      <CardHeader>
        { Lang[ "CAROUSEL_IMAGE_BLOCK_SETTING" ][ lang ] }
      </CardHeader>
      <CardBody>
        {
          selectedComponent.carouselItems && selectedComponent.carouselItems.map(( item, index ) => {
            return (
              <FormGroup>
                <div className="d-flex">
                  <Label style={{ marginRight: "10px" }}>
                    { Lang[ "IMAGE_SOURCE" ][ lang ] + " " + ( index + 1 )}
                  </Label>
                  <Button
                    color="danger"
                    style={{
                      fontSize: "10px",
                      padding: "0 5px",
                      marginBottom: "10px",
                    }}
                    onClick={() => {
                      let tmp = _.cloneDeep( selectedComponent )
                      let tmpSec = _.cloneDeep( selectedSection )
                      tmp.carouselItems.splice( index,1 )
                      if ( selectedComponent.id === selectedSection.id ){
                        onChangeSectionHOC( "selectedSection", tmp )
                      } else {
                        findComponentAndPatch( tmp, tmpSec )
                        onChangeSectionHOC( "selectedEditableComponent", tmp )
                        onChangeSectionHOC( "selectedSection", tmpSec )
                      }
                    }}
                  >
                    { Lang[ "DELETE" ][ lang ] }
                  </Button>
                </div>
                <Input
                  type="text"
                  value={ item.source }
                  onChange={( e ) => {
                    let tmp = _.cloneDeep( selectedComponent )
                    let tmpSec = _.cloneDeep( selectedSection )
                    tmp.carouselItems[ index ].source = e.target.value
                    if ( selectedComponent.id === selectedSection.id ){
                      onChangeSectionHOC( "selectedSection", tmp )
                    } else {
                      findComponentAndPatch( tmp, tmpSec )
                      onChangeSectionHOC( "selectedEditableComponent", tmp )
                      onChangeSectionHOC( "selectedSection", tmpSec )
                    }
                  }}
                />
                <Button
                  color="primary"
                  onClick={ () => {
                    setSelectedIndex( index )
                    toggleMediaDatabase( true ) 
                  }} >
                  { Lang[ "MEDIA_DATABASE" ][ lang ] }
                </Button>
              </FormGroup>
            )
          })}
        <Button
          color="primary"
          outline
          style={{ marginTop: "10px", width: "100%" }}
          onClick={() => {
            let tmp = _.cloneDeep( selectedComponent )
            let tmpSec = _.cloneDeep( selectedSection )
            tmp.carouselItems.push({
              ...tmp.carouselItems[ tmp.carouselItems.length - 1 ],
              id: uniqid(),
              source: "",
            })
            if ( selectedComponent.id === selectedSection.id ){
              onChangeSectionHOC( "selectedSection", tmp )
            } else {
              findComponentAndPatch( tmp, tmpSec )
              onChangeSectionHOC( "selectedEditableComponent", tmp )
              onChangeSectionHOC( "selectedSection", tmpSec ) 
            }
          }}
        >
          { Lang[ "ADD_SOURCE" ][ lang ] }
        </Button>
      </CardBody>
      <MediaDatabase
        onSelectedImage={( param ) => {
          let tmp = _.cloneDeep( selectedComponent )
          let tmpSec = _.cloneDeep( selectedSection )
          tmp.carouselItems[ selectedIndex ].source = param
          if ( selectedComponent.id === selectedSection.id ){
            onChangeSectionHOC( "selectedSection", tmp )
          } else {
            findComponentAndPatch( tmp, tmpSec )
            onChangeSectionHOC( "selectedEditableComponent", tmp )
            onChangeSectionHOC( "selectedSection", tmpSec )
          }
          toggleMediaDatabase( false )
        }} />
    </Card>
  )
}

export default CarouselImageBlockForm
