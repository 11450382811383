import React, { useEffect, useState } from 'react'
import {
  Form, FormGroup,
  Label, Table, Button
} from 'reactstrap'
import ReactSelect from 'react-select';
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import WithCenters from '../actions/Centers'
import Lang from 'Lang/General'

const UpdateCenter = ({
  centers,
  selectedUser,
  onLoadCenters,
  getCenters,
  newUserCenter,
  onChangeUserCenter,
  createUserCenter,
  removeUserCenter,
  lang
}) => {
  const [ centerOptions, setCenterOptions ] = useState()
  const [ centerSelected, setCenterSelected ] = useState( {} )

  useEffect( () => {
    getCenters()
    setCenterSelected( {} )
    onChangeUserCenter( 
      'newUserCenter', 
      { user_id: selectedUser.id, ms_center_id: 0 }
    )
  }, [] )

  useEffect(() => {
    let options = [{
      id: 0,
      label:  '',
      value: ''
    }]
    centers && centers.map( item => {
      if( _.findIndex( selectedUser.centers, { id: item.id }) === -1 ) {
        options.push({
          id: item.id,
          label: item.center_name,
          value: item.center_name
        })
      }
    })
    setCenterOptions( options )
  }, [ centers ] )

  return (
    <Form onSubmit={ e => e.preventDefault()}>
      <FormGroup>
        <Label>{ Lang[ 'ASSIGN_CENTER' ][ lang ] }</Label>
        <div className="d-flex">
          <ReactSelect
            options={ centerOptions }
            onChange={ v => {
              let tmp = _.cloneDeep( newUserCenter )
              tmp[ 'ms_center_id' ] = v.id
              setCenterSelected( v )
              return onChangeUserCenter( 'newUserCenter', tmp )
            }}
            value={ centerSelected } 
            className="flex-fill mr-2" />
          <Button 
            color="primary" 
            onClick={() => {
              createUserCenter( newUserCenter )
            }}>{ Lang[ 'SUBMIT' ][ lang ] }</Button>
        </div>
      </FormGroup>
      <FormGroup>
        <Table>
          <thead>
            <tr>
              <td>
              { Lang[ 'CENTER_NAME' ][ lang ] }
              </td>
              <td>
              { Lang[ 'ACTION' ][ lang ] }
              </td>
            </tr>
          </thead>
          <tbody>
            {
              selectedUser.centers?.map( item => {
                return (
                  <tr key={ item.id }>
                    <td>
                      { item.center_name }
                    </td>
                    <td>
                      <Button 
                        color="link" 
                        onClick={() => {
                          removeUserCenter( item.id )
                        }}>{ Lang[ 'DELETE' ][ lang ] }</Button>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </FormGroup>
      { onLoadCenters && <LoadingOverlay /> }
    </Form>
  )
}

export default WithCenters( UpdateCenter )