import React, { useEffect } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {
  Container, Row, Col,
  Card, CardHeader, CardBody,
  Button, Label, Input,
  Form, FormGroup
} from 'reactstrap'
import DatePicker, { registerLocale } from 'react-datepicker'
import Moment from 'moment'
import de from 'date-fns/locale/de'
import it from 'date-fns/locale/it'
import nl from 'date-fns/locale/nl'
import _ from 'lodash'

import { convertObjToBase64 } from 'utils/objToBase64'
import ARKTable from 'components/Table'
import PageTitle from 'components/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import OmBillingsHOC from './actions'
import Lang from 'Lang/General'

import "react-datepicker/dist/react-datepicker.css"

const OmBillings = props => {
  useEffect( () => {
    searchOmBillings( 1, true )
    registerLocale( props.data.languageReducer.lang, DATEPICKER_LOCALE[ props.data.languageReducer.lang ] )
  }, [] )

  const searchOmBillings = ( page, isReset ) => {
    let tmpSearchParams = {
      page: page ? page : 1,
      is_paginated: true
    }
    if( !isReset && !_.isEmpty( props.searchParams.date ) ){
      tmpSearchParams.filter = {
        date: {
          $gte: props.searchParams.date.clone().startOf( 'month' ).add( Moment().utcOffset(), 'minutes' ).utc().format(),
          $lte: props.searchParams.date.clone().endOf( 'month' ).add( Moment().utcOffset(), 'minutes' ).utc().format()
        }
      }
    }
    let tmpSearchString = convertObjToBase64( tmpSearchParams )
    props.getOmBillings( tmpSearchString, isReset )
  }

  return(
    <>
      <PageTitle
        heading={ Lang[ 'BILLINGS' ][ props.data.languageReducer.lang ] }
        icon="pe-7s-plus icon-gradient bg-happy-itmeo" />
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={ true }
        transitionAppearTimeout={ 0 }
        transitionEnter={ false }
        transitionLeave={ false }>
        <Container fluid>
          <Row>
            <Col md={ 12 }>
              <Card className='main-card mb-3' >
                <CardHeader>{ Lang[ 'SEARCH' ][ props.data.languageReducer.lang ] }</CardHeader>
                <CardBody>
                  <Form>
                    <FormGroup>
                      <div 
                        className='d-flex flex-row flex-wrap align-items-center '
                        style={{ position: "relative", zIndex: "1" }} >
                        <Label className='mx-2 mb-0'>
                          { Lang[ 'EVALUATION_PERIOD' ][ props.data.languageReducer.lang ] } : </Label>
                        <DatePicker
                          className='form-control'
                          dateFormat='MMMM yyyy'
                          showMonthYearPicker
                          locale={ props.data.languageReducer.lang }
                          style={{ width: "fit-content", marginRight: "0.5rem" }}
                          selected={ !_.isEmpty( props.searchParams.date ) ? props.searchParams.date.toDate() : '' }
                          onChange={ e => props.onChangeOmBillingsHOC( 
                            'searchParams',  
                            { ...props.searchParams, date: Moment( e ) }
                          )} 
                        />
                        <Label className='mx-2 mb-0'>
                          { Lang[ 'SOURCE' ][ props.data.languageReducer.lang ] } : </Label>
                        <Input
                          type='select'
                          className='mr-4'
                          style={{ width: 'fit-content' }}
                          value={ props.searchParams.source }
                          onChange={ e => props.onChangeOmBillingsHOC( 
                            'searchParams',  
                            { ...props.searchParams, source: e.target.value }
                          )} >
                          <option value='ONLINE_SHOP' >{ Lang[ 'ONLINE_SHOP' ][ props.data.languageReducer.lang ] }</option>
                          <option value='MEMBER_AREA' >{ Lang[ 'MEMBER_AREA' ][ props.data.languageReducer.lang ] }</option>
                          <option value='ALL' >{ Lang[ 'ALL' ][ props.data.languageReducer.lang ] }</option>
                        </Input>
                        <div className='d-flex flex-row m-2'>
                          <Button
                            color='primary'
                            className='mr-2'
                            onClick={ () => searchOmBillings() }>
                            { Lang[ 'SEARCH' ][ props.data.languageReducer.lang ] }
                          </Button>
                          <Button
                            color='danger'
                            className='mr-2'
                            onClick={ () => {
                              searchOmBillings( 1, true ) 
                              props.onChangeOmBillingsHOC( 
                                'searchParams',  
                                { date: '', source: props.searchParams.source }
                              )
                            }}>
                            { Lang[ 'RESET' ][ props.data.languageReducer.lang ] }
                          </Button>
                          <Button
                            color='success'
                            onClick={ () => {
                              let tmpSearchParams = !_.isEmpty( props.searchParams.date ) ? ({
                                filter: {
                                  date: {
                                    $gte: props.searchParams.date.clone().startOf( 'month' ).add( Moment().utcOffset(), 'minutes' ).utc().format(),
                                    $lte: props.searchParams.date.clone().endOf( 'month' ).add( Moment().utcOffset(), 'minutes' ).utc().format()
                                  }
                                }
                              }) : ({})
                              props.getOmBillingExcel( tmpSearchParams )
                            }}>
                            { Lang[ 'EXPORT_EXCEL' ][ props.data.languageReducer.lang ] }
                          </Button>
                        </div>
                      </div>
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
              <Card className='main-card mb-3' >
                <CardHeader>
                  { `${ Lang[ 'EVALUATION_PERIOD' ][ props.data.languageReducer.lang ]} : ${ props.dateQueryString }` }
                </CardHeader>
                <CardBody>
                  <ARKTable
                    showPagination={ false }
                    defaultPageSize={ 10 }
                    NoDataComponent={ () => <span></span> }
                    data={ props.omBillings.data }
                    totalPages={ props.omBillingPages }
                    page={ props.omBillings.meta ? props.omBillings.meta.current_page : 1 }
                    onChangePage={ page => searchOmBillings( page ) }
                    columns={[
                      {
                        Header: Lang[ 'CLIENT_NUMBER' ][ props.data.languageReducer.lang ],
                        accessor: 'client_number'
                      },
                      {
                        Header: Lang[ 'CENTER_NAME' ][ props.data.languageReducer.lang ],
                        accessor: 'center_name'
                      },
                      {
                        Header: Lang[ 'COUNTRY' ][ props.data.languageReducer.lang ],
                        accessor: 'country'
                      },
                      {
                        Header: Lang[ 'POSTCODE' ][ props.data.languageReducer.lang ],
                        accessor: 'postcode'
                      },
                      {
                        Header: Lang[ 'CITY' ][ props.data.languageReducer.lang ],
                        accessor: 'city'
                      },
                      {
                        Header: Lang[ 'ORDERS' ][ props.data.languageReducer.lang ],
                        accessor: 'order'
                      },
                      {
                        Header: Lang[ 'GROSS_AMOUNT' ][ props.data.languageReducer.lang ],
                        accessor: 'gross'
                      },
                      {
                        Header: Lang[ 'TAXATION_PERCENT' ][ props.data.languageReducer.lang ],
                        accessor: 'change'
                      },
                      {
                        Header: Lang[ 'NET_SUM' ][ props.data.languageReducer.lang ],
                        accessor: 'sum'
                      },
                      {
                        Header: Lang[ 'ACISO_PERCENT' ][ props.data.languageReducer.lang ],
                        accessor: 'aciso'
                      }
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </ReactCSSTransitionGroup>
      { props.onLoadOmBillingsHOC && <LoadingOverlay/> }
    </>
  )
}

const DATEPICKER_LOCALE = {
  en: {},
  de: de,
  it: it,
  nl: nl,
}

export default OmBillingsHOC( OmBillings )

