import React from 'react'
import { 
  Button, Table,
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap'
import ReactHTMLParser from 'react-html-parser'

import Lang from 'Lang/General'


const ViewProduct = ( props ) => {
  return (
    <Modal 
      size={ 'lg' } 
      isOpen={ props.showViewProduct }>
      <ModalHeader toggle={ () => { props.onChangeProductsHOC( 'showViewProduct', false ) } }>
        { Lang[ 'VIEW_PRODUCT' ][ props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        <Table bordered>
          <tbody>
            <tr>
              <th scope="row" class="col-3" >{ Lang[ 'NAME' ][ props.data.languageReducer.lang ] }</th>
              <td>{ props.selectedProduct.name[ props.data.languageReducer.lang ] }</td>
            </tr>
            <tr>
              <th scope="row">{ Lang[ 'DESCRIPTION' ][ props.data.languageReducer.lang ] }</th>
              <td>{ ReactHTMLParser( props.selectedProduct.description[ props.data.languageReducer.lang ] ) }</td>
            </tr>
            <tr>
              <th scope="row">{ Lang[ 'IMAGE' ][ props.data.languageReducer.lang ] }</th>
              <td>
                {
                  props.selectedProduct.image_url !== '' 
                  ? <img src={ props.selectedProduct.image_url } style={{ width: '30%' }} />
                  : '-'
                }
              </td>
            </tr>
            <tr>
              <th scope="row">PDF</th>
              <td>
                {
                  props.selectedProduct.pdf_url !== '' 
                  ? <p><a href={ props.selectedProduct.pdf_url } target="_blank" className="mb-2">Click to view pdf</a></p>
                  : "-"
                }
              </td>
            </tr>
            <tr>
              <th scope="row">{ Lang[ 'IS_NEW' ][ props.data.languageReducer.lang ] }</th>
              <td>{ props.selectedProduct.is_new? 'YES': 'NO' }</td>
            </tr>
            <tr>
              <th scope="row">{ Lang[ 'PRICE_LINE_1' ][ props.data.languageReducer.lang ] }</th>
              <td>{ props.selectedProduct.currency_price_1[ props.data.languageReducer.lang ] }</td>
            </tr>
            <tr>
              <th scope="row">{ Lang[ 'PRICE_LINE_2' ][ props.data.languageReducer.lang ] }</th>
              <td>{ props.selectedProduct.currency_price_2[ props.data.languageReducer.lang ] }</td>
            </tr>
            <tr>
              <th scope="row">{ Lang[ 'IS_MOST_BOOKED_PRODUCTS' ][ props.data.languageReducer.lang ] }</th>
              <td>{ props.selectedProduct.is_most_booked_products? 'YES': 'NO'  }</td>
            </tr>
            <tr>
              <th scope="row">{ Lang[ 'COUNTRIES' ][ props.data.languageReducer.lang ] }</th>
              <td>
                {
                  props.selectedProduct.countries && props.selectedProduct.countries.length > 0 
                  ? props.selectedProduct.countries.map( country => {
                      return <li key={ country.id }>{ country.name }</li>
                    })
                  : '-'
                }
              </td>
            </tr>
            <tr>
              <th scope="row">{ Lang[ 'CENTER_GROUP' ][ props.data.languageReducer.lang ] }</th>
              <td>
                {
                  props.selectedProduct.center_groups && props.selectedProduct.center_groups.length > 0 
                  ? props.selectedProduct.center_groups.map( centerGroup => {
                      return <li key={ centerGroup.id }>{ centerGroup.name }</li>
                    })
                  : '-'
                }
              </td>
            </tr>
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={() => {
            props.onChangeProductsHOC( 'showViewProduct', false )
          }}>{ Lang[ 'CLOSE' ][ props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ViewProduct