import React, { Fragment, useRef } from 'react'
import _ from 'lodash'
import {
  Form, FormGroup, Label, Input, Col, FormFeedback, Container, Card, CardBody
} from 'reactstrap'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { connect } from 'react-redux'

import ARKUpload from 'components/UploadImage'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'
import { validate } from 'utils/validatePhoneNumber'

const ConsultantForm = ({
  handleSelectChange,
  onChangeConsultantHOC,
  data,
  statusValues,
  errorMessage,
  roles,
  imageUrl,
  mode,
  onLoadConsultants,
  dataReducer
}) => {
  const onChange = ( key, val ) => {
    let tmp = {}
    let dataKey = ''
    if( mode === 'edit' ){
      dataKey = 'selectedConsultant'
    } else{
      dataKey = 'newConsultantData'
    }
    if( key === 'status' || key === 'phone_number' || key === 'mobile_number' || key === 'fax_number' || key === 'remark' || key === 'best_contact_time' || key === 'user' ){
      tmp = _.cloneDeep( data )
      tmp[ key ] = val
      onChangeConsultantHOC( dataKey, tmp )
    } else{
      tmp = _.cloneDeep( data )
      tmp[ 'user' ][ key ] = val
      onChangeConsultantHOC( dataKey, tmp )
    }
  }
  const inputLabel = useRef(null);
  const onUploadImage = ( event ) => {
    if( event.target.files[0] ) {
      let reader = new FileReader();
      let filename = event.target.files[0].name
      let type = event.target.files[0].type
      reader.onload = e => {
        let tmpUser = _.cloneDeep( data.user )
        tmpUser[ 'photo_file_name' ] = filename
        tmpUser[ 'photo_raw' ] = e.target.result
        onChange( 'user', tmpUser )
      };
      reader.readAsDataURL( event.target.files[0] );
    }
  }
  const onDeleteImage = () => {
    let tmp = {}
    let dataKey = ''
    if( mode === 'edit' ){
      dataKey = 'selectedConsultant'
    } else{
      dataKey = 'newConsultantData'
    }
    tmp = _.cloneDeep( data )
    tmp[ 'user' ][ 'photo_raw' ] = ''
    tmp[ 'user' ][ 'photo_file_name' ] = ''
    tmp[ 'user' ][ 'photo_url' ] = ''
    onChangeConsultantHOC( dataKey, tmp )
  }
  const {
    phone_number,
    mobile_number,
    best_contact_time,
    fax_number,
    remark,
    status,
    user
  } = data
  const {
    first_name,
    last_name,
    username,
    password,
    password_confirmation,
    email,
    photo_file_name,
    role_id,
    photo_url,
    photo_raw,
    admin_panel_language
  } = user
  return(
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}>
        <Container fluid>
          <Card className="main-card mb-3">
            <CardBody>
              <ARKUpload
                onUploadImage={ onUploadImage }
                photo_url={ photo_url }
                photo_raw={ photo_raw }
                errorMessage={ errorMessage['user.photo_raw'] ? 'photo is required' : ''}
                onDeleteImage={ onDeleteImage } />
              <Form>
                <table style={{ tableLayout: "auto", width: "100%", borderCollapse: "separate", borderSpacing: "0 10px" }}>
                  <tr>
                    <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                      <Label for="first_name" sm={ 2 }>{ Lang[ 'FIRST_NAME' ][ dataReducer.languageReducer.lang ] }</Label>
                    </td>
                    <td>
                      <FormGroup className='m-0'>
                        <Input
                          name="first_name" id="first_name"
                          placeholder={ Lang[ 'ENTER_FIRST_NAME' ][ dataReducer.languageReducer.lang ] }
                          value={ first_name }
                          onChange={ e => onChange( 'first_name', e.target.value ) }
                          invalid={ errorMessage && errorMessage['user.first_name'] ? true : false }/>
                        <FormFeedback>{ errorMessage && errorMessage['user.first_name'] ? errorMessage['user.first_name'] : '' }</FormFeedback>
                      </FormGroup>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                      <Label for="last_name" sm={ 2 }>{ Lang[ 'LAST_NAME' ][ dataReducer.languageReducer.lang ] }</Label>
                    </td>
                    <td>
                      <FormGroup className='m-0'>
                        <Input
                          name="last_name" id="last_name"
                          placeholder={ Lang[ 'ENTER_LAST_NAME' ][ dataReducer.languageReducer.lang ] }
                          value={ last_name }
                          onChange={ e => onChange( 'last_name', e.target.value ) }
                          invalid={ errorMessage && errorMessage['user.last_name'] ? true : false }/>
                        <FormFeedback>{ errorMessage && errorMessage['user.last_name'] ? errorMessage['user.last_name'] : '' }</FormFeedback>
                      </FormGroup>
                    </td>
                  </tr>
                  {/*<tr>
                    <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                      <Label for="username" sm={ 2 }>{ Lang[ 'USERNAME' ][ dataReducer.languageReducer.lang ] }</Label>
                    </td>
                    <td>
                      <FormGroup className='m-0'>
                        <Input
                          type="text"
                          name="username" id="username"
                          placeholder={ Lang[ 'ENTER_USERNAME' ][ dataReducer.languageReducer.lang ] }
                          value={ username }
                          onChange={ e => onChange( 'username', e.target.value ) }
                          invalid={ errorMessage && errorMessage['user.username'] ? true : false }/>
                        <FormFeedback>{ errorMessage && errorMessage['user.username'] ? errorMessage['user.username'] : '' }</FormFeedback>
                      </FormGroup>
                    </td>
                  </tr>*/}
                  <tr>
                    <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                      <Label for="email" sm={ 2 }>{ Lang[ 'EMAIL' ][ dataReducer.languageReducer.lang ] }</Label>
                    </td>
                    <td>
                      <FormGroup className='m-0'>
                        <Input
                          type="email"
                          name="email" id="email"
                          placeholder={ Lang[ 'ENTER_EMAIL' ][ dataReducer.languageReducer.lang ] }
                          value={ email }
                          onChange={ e => {
                            let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i )

                            if( !pattern.test( e.target.value ) ){
                              errorMessage["user.email"] = "Please enter valid email address."
                            } else {
                              errorMessage["user.email"] = ""
                            }
                            onChange( 'email', e.target.value )
                          }}
                          invalid={ errorMessage && errorMessage['user.email'] ? true : false }/>
                        <FormFeedback>{ errorMessage && errorMessage['user.email'] ? errorMessage['user.email'] : '' }</FormFeedback>
                      </FormGroup>
                    </td>
                  </tr>
                  {
                    mode !== 'edit' && (
                      <>
                        <tr>
                          <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                            <Label for="password" sm={ 2 }>{ Lang[ 'PASSWORD' ][ dataReducer.languageReducer.lang ] }</Label>
                          </td>
                          <td>
                            <FormGroup className='m-0'>
                              <Input
                                type="password"
                                autoComplete='new-password'
                                name="password" id="password"
                                placeholder={ Lang[ 'ENTER_PASSWORD' ][ dataReducer.languageReducer.lang ] }
                                value={ password }
                                onChange={ e => onChange( 'password', e.target.value ) }
                                invalid={ errorMessage['user.password'] || password && password.length > 0 && password.length < 8 ? true : false }/>
                              <FormFeedback>{ errorMessage['user.password'] || password && password.length > 0 && password.length < 8 ? 'Password must more than 8 characters' : '' }</FormFeedback>
                            </FormGroup>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                            <Label for="password_confirmation" sm={ 2 }>{ Lang[ 'CONFIRM_PASSWORD' ][ dataReducer.languageReducer.lang ] }</Label>
                          </td>
                          <td>
                            <FormGroup className='m-0'>
                              <Input
                                type="password"
                                name="password_confirmation" id="password_confirmation"
                                placeholder={ Lang[ 'ENTER_CONFIRM_PASSWORD' ][ dataReducer.languageReducer.lang ] }
                                value={ password_confirmation }
                                onChange={ e => onChange( 'password_confirmation', e.target.value ) }
                                invalid={ errorMessage['user.password'] || password && password.length > 0 && password_confirmation.length > 0 && password_confirmation !== password ? true : false }/>
                              <FormFeedback>{ errorMessage['user.password'] || password && password.length > 0 && password_confirmation.length > 0 && password_confirmation !== password ? 'Password not same' : '' }</FormFeedback>
                            </FormGroup>
                          </td>
                        </tr>
                      </>
                    )
                  }
                  <tr>
                    <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                      <Label for="role" sm={ 2 }>{ Lang[ 'ROLE' ][ dataReducer.languageReducer.lang ] }</Label>
                    </td>
                    <td>
                      <FormGroup className='m-0'>
                        <Input
                          type="select"
                          name="role"
                          id="role"
                          disabled={ true }
                          value={ role_id }
                          onChange={ e => onChange( 'role_id', e.target.value ) }>
                          {
                            roles && roles.map( item => {
                              return(
                                <option value={ item.id } key={ item.id }>{ item.name }</option>
                              )
                            })
                          }
                        </Input>
                      </FormGroup>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                      <Label for="phone_number" sm={ 2 }>{ Lang[ 'PHONE_NUMBER' ][ dataReducer.languageReducer.lang ] }</Label>
                    </td>
                    <td>
                      <FormGroup className='m-0'>
                        <Input
                          name="phone_number" id="phone_number"
                          placeholder={ Lang[ 'ENTER_PHONE_NUMBER' ][ dataReducer.languageReducer.lang ] }
                          value={ phone_number }
                          onChange={ e => {
                            if( e.target.value.length > 0 ){
                              if( validate( e.target.value ) ) {
                                onChange( 'phone_number', e.target.value )
                              }
                            } else{
                              onChange( 'phone_number', e.target.value )
                            }
                          }}
                          invalid={ errorMessage && errorMessage.phone_number ? true : false }/>
                        <FormFeedback>{ errorMessage && errorMessage.phone_number ? errorMessage.phone_number : '' }</FormFeedback>
                      </FormGroup>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                      <Label for="mobile_number" sm={ 2 }>{ Lang[ 'MOBILE_NUMBER' ][ dataReducer.languageReducer.lang ] }</Label>
                    </td>
                    <td>
                      <FormGroup className='m-0'>
                        <Input
                          name="mobile_number" id="mobile_number"
                          placeholder={ Lang[ 'ENTER_MOBILE_NUMBER' ][ dataReducer.languageReducer.lang ] }
                          value={ mobile_number }
                          onChange={ e => {
                            if( e.target.value.length > 0 ){
                              if( validate( e.target.value ) ) {
                                onChange( 'mobile_number', e.target.value )
                              }
                            } else {
                              onChange( 'mobile_number', e.target.value )
                            }
                          }}
                          invalid={ errorMessage && errorMessage.mobile_number ? true : false }/>
                        <FormFeedback>{ errorMessage && errorMessage.mobile_number ? errorMessage.mobile_number : '' }</FormFeedback>
                      </FormGroup>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                      <Label for="fax_number" sm={ 2 }>{ Lang[ 'FAX_NUMBER' ][ dataReducer.languageReducer.lang ] }</Label>
                    </td>
                    <td>
                      <FormGroup className='m-0'>
                        <Input
                          name="fax_number" id="fax_number"
                          placeholder={ Lang[ 'ENTER_FAX_NUMBER' ][ dataReducer.languageReducer.lang ] }
                          value={ fax_number }
                          onChange={ e => onChange( 'fax_number', e.target.value ) }
                          invalid={ errorMessage && errorMessage.fax_number ? true : false }/>
                        <FormFeedback>{ errorMessage && errorMessage.fax_number ? errorMessage.fax_number : '' }</FormFeedback>
                      </FormGroup>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                      <Label for="best_contact_time" sm={ 2 }>{ Lang[ 'BEST_CONTACT_TIME' ][ dataReducer.languageReducer.lang ] }</Label>
                    </td>
                    <td>
                      <FormGroup className='m-0'>
                        <Input
                          name="best_contact_time" id="best_contact_time"
                          placeholder={ Lang[ 'ENTER_BEST_CONTACT_TIME' ][ dataReducer.languageReducer.lang ] }
                          value={ best_contact_time }
                          onChange={ e => onChange( 'best_contact_time', e.target.value ) }
                          invalid={ errorMessage && errorMessage.best_contact_time ? true : false }/>
                        <FormFeedback>{ errorMessage && errorMessage.best_contact_time ? errorMessage.best_contact_time : '' }</FormFeedback>
                      </FormGroup>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                      <Label for="remark" sm={ 2 }>{ Lang[ 'REMARK' ][ dataReducer.languageReducer.lang ] }</Label>
                    </td>
                    <td>
                      <FormGroup className='m-0'>
                        <Input
                          name="remark" id="remark"
                          placeholder={ Lang[ 'ENTER_REMARK' ][ dataReducer.languageReducer.lang ] }
                          value={ remark }
                          onChange={ e => onChange( 'remark', e.target.value ) }
                          invalid={ errorMessage && errorMessage.remark ? true : false }/>
                        <FormFeedback>{ errorMessage && errorMessage.remark ? errorMessage.remark : '' }</FormFeedback>
                      </FormGroup>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                      <Label for="language" sm={ 2 }>{ Lang[ 'DEFAULT_LANGUAGE' ][ dataReducer.languageReducer.lang ] }</Label>
                    </td>
                    <td>
                      <FormGroup className='m-0'>
                        <Input
                          type="select"
                          name="language"
                          id="language"
                          value={ admin_panel_language }
                          onChange={ e => onChange( 'admin_panel_language', e.target.value ) }>
                          <option value={ 'en' }>{ Lang[ 'ENGLISH' ][ dataReducer.languageReducer.lang ] }</option>
                          <option value={ 'de' }>{ Lang[ 'GERMAN' ][ dataReducer.languageReducer.lang ] }</option>
                          <option value={ 'it' }>{ Lang[ 'ITALIAN' ][ dataReducer.languageReducer.lang ] }</option>
                          <option value={ 'nl' }>{ Lang[ 'DUTCH' ][ dataReducer.languageReducer.lang ] }</option>
                        </Input>
                      </FormGroup>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                      <Label for="role" sm={ 2 }>{ Lang[ 'STATUS' ][ dataReducer.languageReducer.lang ] }</Label>
                    </td>
                    <td>
                      <FormGroup className='m-0'>
                        <Input
                          type="select"
                          name="status"
                          id="status"
                          value={ status }
                          onChange={ e => onChange( 'status', e.target.value ) }>
                          {
                            statusValues && Object.keys( statusValues ).map( key => {
                              return(
                                <option value={ statusValues[key] } key={ key }>{ statusValues[key] }</option>
                              )
                            })
                          }
                        </Input>
                      </FormGroup>
                    </td>
                  </tr>
                </table>
              </Form>
              { onLoadConsultants && <LoadingOverlay /> }
            </CardBody>
          </Card>
        </Container>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}
const mapStateToProps = state => ({ dataReducer: state })

export default connect( mapStateToProps )( ConsultantForm )
