import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import Moment from 'moment'
import _ from 'lodash'

import { Get, Post, outdatedToken, Put } from 'utils/axios'
import { getRefreshToken } from 'actions/token'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const SystemFunnelsHOC = ( WrappedComponent ) => {
  class SystemFunnelsWrappedComponent extends Component{
    state = {
      loading: false,
      systemFunnels: [],
      totalPages: [],
      showCreateFunnel: false,
      newFunnelData: {
        title: {
          en: '',
          de: '',
          it: '',
          nl: '',
          es: '',
          fr: ''
        },
        description: {
          en: '',
          de: '',
          it: '',
          nl: '',
          es: '',
          fr: ''
        },
        start_type: 'start_if_triggered',
        start_fix_date: Moment().format( 'YYYY-MM-DD' ),
        start_date: null,
        status: 'ACTIVE',
        end_date: null,
        center_editable_config: [
          {
            name: 'title',
            is_editable: true
          },
          {
            name: 'description',
            is_editable: true
          },
          {
            name: 'start_type',
            is_editable: true
          },
          {
            name: 'start_fix_date',
            is_editable: true
          },
          {
            name: 'start_date',
            is_editable: true
          },
          {
            name: 'end_date',
            is_editable: true
          },
          {
            name: 'has_expiry_date',
            is_editable: true
          },
          {
            name: 'center_groups',
            is_editable: true
          },
          {
            name: 'countries',
            is_editable: true
          },
          {
            name: 'funnel_scenario_id',
            is_editable: true
          },
          {
            name: 'system_funnel_mailings',
            is_editable: true
          }
        ],
        countries: [],
        center_groups: [],
        has_expiry_date: false,
        scenario_id: 0,
        mailings: []
      },
      totalOfMailings: 1,
      systemFunnelTotalPages: [],
      showUpdateFunnel: false,
      selectedFunnel: {
        title: {
          en: '',
          de: '',
          it: '',
          nl: '',
          es: '',
          fr: ''
        },
        description: {
          en: '',
          de: '',
          it: '',
          nl: '',
          es: '',
          fr: ''
        },
        start_type: 'start_if_triggered',
        start_fix_date: Moment().format( 'YYYY-MM-DD' ),
        start_date: new Date(),
        status: 'ACTIVE',
        end_date: '',
        center_editable_config: [
          {
            name: 'title',
            is_editable: true
          },
          {
            name: 'description',
            is_editable: true
          },
          {
            name: 'start_type',
            is_editable: true
          },
          {
            name: 'start_fix_date',
            is_editable: true
          },
          {
            name: 'start_date',
            is_editable: true
          },
          {
            name: 'end_date',
            is_editable: true
          },
          {
            name: 'has_expiry_date',
            is_editable: true
          },
          {
            name: 'center_groups',
            is_editable: true
          },
          {
            name: 'countries',
            is_editable: true
          },
          {
            name: 'funnel_scenario_id',
            is_editable: true
          },
          {
            name: 'system_funnel_mailings',
            is_editable: true
          }
        ],
        countries: [],
        center_groups: [],
        has_expiry_date: false,
        scenario_id: 0,
        mailings: []
      },
      systemFunnelErrorMsg: {},
      title: '',
      description: '',
      showArchivedFunnels: false,
      showArchiveSelectedFunnel: false
    }

    onChangeSystemFunnelsHOC = ( key, value ) => this.setState({ [key]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    ajaxError = ( error ) => {
      outdatedToken( error, this.props.history )
      this.requestError( error )
    }

    getAllSystemFunnels = (query) => Get(
      `/api/v1/system_funnels?query=${query}`,
      this.getAllSystemFunnelsSuccess,
      this.getAllSystemFunnelsError,
      this.load
    )
    getAllSystemFunnelsSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      } else {
        toast.error( Lang['NO_RESULT_FOUND'][ this.props.data.languageReducer.lang ] )
      }

      this.setState({ systemFunnels: payload, systemFunnelTotalPages: tmpTotalPages })
    }
    getAllSystemFunnelsError = error => this.requestError( error )

    postSystemFunnel = ( data ) => Post(
      `/api/v1/system_funnels`,
      data,
      this.postSystemFunnelSuccess,
      this.postSystemFunnelError,
      this.load
    )
    postSystemFunnelSuccess = () => {
      this.getAllSystemFunnels( 
        convertObjToBase64({ 
          is_paginated: true, 
          page: 1,
          filter: {
            status: { $neq: 'ARCHIVED' }
          }
        }) 
      )
      this.requestSuccess( Lang['CREATE_SUCCESS_SYSTEM_FUNNEL'][this.props.data.languageReducer.lang])
      this.setState({ showCreateFunnel: false })
    }
    postSystemFunnelError = error => {
      this.requestError( error )
      this.setState({ systemFunnelErrorMsg: error })
    }

    getSelectedSystemFunnel = ( id, isArchiving ) => Get(
      `/api/v1/system_funnels/${ id }`,
      payload => this.getSelectedSystemFunnelSuccess( payload, isArchiving ),
      this.getSelectedSystemFunnelError,
      this.load
    )
    getSelectedSystemFunnelSuccess = ( payload, isArchiving ) => {
      let tmp = {}
      let tmpCenterGroups = []

      payload.center_groups.map( item => {
        tmpCenterGroups.push(
          {
            ...item,
            label: item.name
          }
        )
        tmp = {
          ...payload,
          center_groups: tmpCenterGroups
        }
      })
      payload.countries.map( item => {
        tmp = {
          ...tmp,
          countries: [
            {
              ...item,
              label: item.name
            }
          ]
        }
      })
      tmp.mailings.map( mailing => {
        mailing.time_to_send = Moment( mailing.time_to_send ).utcOffset( Moment().utcOffset() ).format( 'HH:mm' )
      })
      tmp.start_fix_date = Moment( tmp.start_fix_date ).format( 'YYYY-MM-DD' )
      this.setState({ 
        selectedFunnel: isArchiving ? payload : tmp,
        showArchiveSelectedFunnel: isArchiving
      })
    }
    getSelectedSystemFunnelError = error => this.requestError( error )

    updateSystemFunnel = ( data, id ) => Put(
      `/api/v1/system_funnels/${ id }`,
      data,
      this.updateSystemFunnelSuccess,
      this.updateSystemFunnelError,
      this.load
    )
    updateSystemFunnelSuccess = () => {
      this.requestSuccess( Lang['UPDATE_SUCCESS_SYSTEM_FUNNEL'][this.props.data.languageReducer.lang])
      this.getAllSystemFunnels( 
        convertObjToBase64({ 
          is_paginated: true, 
          page: 1,
          filter: {
            status: this.state.showArchivedFunnels
              ? 'ARCHIVED'
              : { $neq: 'ARCHIVED' }
          }
        }) 
      )
      this.setState({ showArchiveSelectedFunnel: false })
    }
    updateSystemFunnelError = error => this.requestError( error )

    setFunnelMailings = ( funnel_id, data ) => Put(
      `/api/v1/system_funnels/${ funnel_id }/system_mailings`,
      data,
      this.setFunnelMailingsSuccess,
      this.setFunnelMailingsError,
      this.load
    )
    setFunnelMailingsSuccess = () => {
      this.requestSuccess( Lang['UPDATE_SUCCESS_SYSTEM_FUNNEL'][this.props.data.languageReducer.lang])
      this.getAllSystemFunnels( 
        convertObjToBase64({ 
          is_paginated: true, 
          page: 1,
          filter: {
            status: { $neq: 'ARCHIVED' }
          }
        }) 
      )
      this.setState({ showUpdateFunnel: false })
    }

    setFunnelMailingsError = error => {
      this.requestError( error )
    }

    render = () => {
      return(
        <>
          <WrappedComponent
            { ...this.props }
            onLoadSystemFunnels={ this.state.loading }
            systemFunnels={ this.state.systemFunnels }
            totalPages={ this.state.totalPages }
            onChangeSystemFunnelsHOC={ this.onChangeSystemFunnelsHOC }
            showCreateFunnel={ this.state.showCreateFunnel }
            newFunnelData={ this.state.newFunnelData }
            totalOfMailings={ this.state.totalOfMailings }
            postSystemFunnel={ this.postSystemFunnel }
            systemFunnelTotalPages={ this.state.systemFunnelTotalPages }
            getAllSystemFunnels={ this.getAllSystemFunnels }
            assignedMailings={[{title: 'Test abc'}, {title: 'Test def'}]}
            getSelectedSystemFunnel={ this.getSelectedSystemFunnel }
            selectedFunnel={ this.state.selectedFunnel }
            updateSystemFunnel={ this.updateSystemFunnel }
            showUpdateFunnel={ this.state.showUpdateFunnel }
            systemFunnelErrorMsg={ this.state.systemFunnelErrorMsg }
            setFunnelMailings={ this.setFunnelMailings }
            title={ this.state.title }
            description={ this.state.description }
            showArchivedFunnels={ this.state.showArchivedFunnels } 
            showArchiveSelectedFunnel={ this.state.showArchiveSelectedFunnel }
          />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    getRefreshToken
  } )( SystemFunnelsWrappedComponent )
}

export default SystemFunnelsHOC
