import React, { useState } from 'react'
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label, Input,
  Button, FormFeedback, Row,
  Col, Card, CardBody
} from 'reactstrap'
import _ from 'lodash'
import moment from 'moment'

import PackageSelect from './PackageSelect'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'

const Update = props => {
  const [ selectModuleID, updateSelectModuleID ] = useState(0)

  const onChangeOrderData = ( key, val ) => {
    let tmp = _.cloneDeep( props.selectedOrder )
    tmp[ key ] = val
    return props.onChangeOrderHOC( 'selectedOrder', tmp )
  }

  return (
    <Modal
      isOpen={ props.showUpdateOrderModal }
      size={ 'xl' }
      toggle={() => {}}>
      <ModalHeader toggle={() => props.onChangeOrderHOC( 'showUpdateOrderModal', false )}>{ Lang[ 'UPDATE_ORDER' ][ props.data.languageReducer.lang ] }</ModalHeader>
      <ModalBody>
        {
          !_.isEmpty( props.selectedOrder ) && !_.isEmpty( props.selectedOrder.center ) && <Row style={{ maxHeight: '80vh', overflowY: 'scroll' }}>
            <Col md={ 9 }>
              <Form onSubmit={ e => e.preventDefault()}>
                <FormGroup row>
                  <Label for="center" sm={ 2 }>{ Lang[ 'CENTER' ][ props.data.languageReducer.lang ] }</Label>
                  <Col sm={ 10 }>
                    <p>{ props.selectedOrder.center.center_name }</p>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="center" sm={ 2 }>{ Lang[ 'LOCATION' ][ props.data.languageReducer.lang ] }</Label>
                  <Col sm={ 10 } className='d-flex align-items-center' >
                    {
                      props.selectedOrder.center.locations?.length > 0 ? (
                        <ul className='pl-3' >
                          { props.selectedOrder.center.locations.map( item => <li key={ item.id }>{ item.name }</li> ) }
                        </ul>
                      ) : (
                        '-'
                      )
                    }
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="aciso_order_num" sm={ 2 }>
                    { 
                      window.location.host.indexOf( 'aciso' ) > -1 
                        ? Lang[ 'ACISO_ORDER_NUMBER' ][ props.data.languageReducer.lang ]
                        : Lang[ 'ORDER_NUMBER' ][ props.data.languageReducer.lang ]
                    }
                  </Label>
                  <Col sm={ 10 }>
                    <Input
                      name="aciso_order_num" id="aciso_order_num"
                      placeholder={ 
                        window.location.host.indexOf( 'aciso' ) > -1
                          ? Lang[ 'ENTER_ACISO_ORDER_NUM' ][ props.data.languageReducer.lang ]
                          : Lang[ 'ENTER_ORDER_NUM' ][ props.data.languageReducer.lang ]
                      }
                      value={ props.selectedOrder.aciso_order_num }
                      onChange={ e => {
                        onChangeOrderData( 'aciso_order_num', e.target.value )
                      }}
                      invalid={ props.errorMessage && props.errorMessage.aciso_order_num ? true : false }/>
                    <FormFeedback>{ props.errorMessage && props.errorMessage.aciso_order_num ? props.errorMessage.aciso_order_num : '' }</FormFeedback>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="start_at" sm={ 2 }>{ Lang[ 'START_DATE' ][ props.data.languageReducer.lang ] }</Label>
                  <Col sm={ 10 }>
                    <Input
                      type="date"
                      value={ moment( props.selectedOrder.start_date ).format( 'YYYY-MM-DD' ) }
                      onChange={ e => {
                        let newdate = new Date( e.target.value )
                        onChangeOrderData( 'start_date',  newdate )
                    }}/>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="remark" sm={ 2 }>{ Lang[ 'REMARK' ][ props.data.languageReducer.lang ] }</Label>
                  <Col sm={ 10 }>
                    <Input
                      name="remark" id="remark"
                      value={ props.selectedOrder.remark }
                      placeholder={ Lang[ 'ENTER_REMARK' ][ props.data.languageReducer.lang ] }
                      onChange={ e => {
                        onChangeOrderData( 'remark', e.target.value ) 
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={ 2 }>{ Lang[ 'ASSIGN_PACKAGES' ][ props.data.languageReducer.lang ]}</Label>
                  <Col sm={ 10 }>
                    {
                      props.packages.map( item => {
                        return (
                          <PackageSelect
                            key={ item.id }
                            item={ item }
                            value={ props.selectedOrder.package_id }
                            lang={ props.data.languageReducer.lang }
                            onSelect={() => {
                              let tmpOrder = _.cloneDeep( props.selectedOrder )
                              tmpOrder.package_id = item.id
                              if( _.findIndex( props.packages, { id: parseInt( item.id, 10 ) }) > -1 ) {
                                let tmp = _.find( props.packages, { id: parseInt( item.id, 10 ) } )
                                if( tmp.modules && tmp.modules.length > 0 ) {
                                  let tmpModules = _.cloneDeep( props.selectedOrder.modules )
                                  tmp.modules.map( item => {
                                    if( _.findIndex( props.selectedOrder.modules, { id: item.id } ) < 0 ) {
                                      tmpModules.push( item )
                                    } 
                                  })
                                  tmpOrder.modules = tmpModules
                                }
                              }
                              props.onChangeOrderHOC( 'selectedOrder', tmpOrder )
                            }}
                            onRemove={() => {
                              let tmpOrder = _.cloneDeep( props.selectedOrder )
                              tmpOrder.package_id = null
                              tmpOrder.modules = _.filter( tmpOrder.modules, mod => _.findIndex( item.modules, item => item.id === mod.id ) === -1 )
                              props.onChangeOrderHOC( 'selectedOrder', tmpOrder )
                            }} />
                        )
                      })
                    }
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={ 2 }>{ Lang[ 'ASSIGN_MODULES' ][ props.data.languageReducer.lang ]}</Label>
                  <Col sm={ 8 }>
                    <Input
                      type="select"
                      value={ selectModuleID }
                      onChange={ e => {
                        updateSelectModuleID( parseInt( e.target.value, 10 ) )
                      }}>
                      <option></option>
                      {
                        props.modules.data.map( item => {
                          if( _.findIndex( props.selectedOrder.modules, { id: item.id }) < 0 ) {
                            return (
                              <option key={ item.id } value={ item.id }>{ item.name }</option>
                            )
                          }
                        })
                      }
                    </Input>
                  </Col>
                  <Col sm={ 2 }>
                    <Button 
                      color="primary" 
                      onClick={() => {
                        let tmp = _.find( props.modules.data, obj => {
                          if( obj.id === selectModuleID ) {
                            return obj
                          }
                        })
                        let tmpModules = _.cloneDeep( props.selectedOrder.modules )
                        if( tmp ) {
                          tmpModules.push( tmp )
                          onChangeOrderData( 'modules', tmpModules )
                          updateSelectModuleID( '' )
                        }
                      }}>{ Lang[ 'ASSIGN' ][ props.data.languageReducer.lang ]}</Button>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={ 2 }>{ Lang[ 'ORDERED_MODULES' ][ props.data.languageReducer.lang ]}</Label>
                  <Col sm={ 10 }>
                    {
                      props.selectedOrder.modules.map( item => {
                        return (
                          <Card key={ item.id }>
                            <CardBody className="d-flex align-items-center justify-content-between flex-wrap">
                              <span className="mr-2">{ item.name }</span>
                              <Button
                                color="danger"
                                className="btn-icon btn-icon-only"
                                onClick={() => {
                                  let tmp = _.cloneDeep( props.selectedOrder.modules )
                                  _.remove( tmp, { id: item.id })
                                  onChangeOrderData( 'modules', tmp )
                                }}>
                                <i className="pe-7s-trash"/>
                              </Button>
                            </CardBody>
                          </Card>
                        )
                      })
                    }
                  </Col>
                </FormGroup>
              </Form>
            </Col>
            <Col md={ 3 }>
              {
                props.selectedOrder.logs.map( item => {
                  return (
                    <Card key={ item.id } className="mb-2">
                      <CardBody>
                        <p style={{ fontWeight: '600' }}>{ item[ props.data.languageReducer.lang ] }</p>
                        <span>{ moment( item.created_at ).format( 'DD.MM.YYYY HH:mm:ss' ) }</span>
                      </CardBody>
                    </Card>
                  )
                })
              }
            </Col>
          </Row>
        }
        { props.onLoadOrders && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            props.updateOrder( props.selectedOrder )
          }}>
          { Lang[ 'SUBMIT' ][ props.data.languageReducer.lang ] }
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default Update