import React, { useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  Form, FormGroup, Label, Input, Button,
  Modal, ModalBody, ModalHeader, ModalFooter
} from 'reactstrap'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import FilePondPluginFileEncode from 'filepond-plugin-file-encode'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import EmailEditor from 'react-email-editor'
import ReactSelect from 'react-select'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Lang from 'Lang/General'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

registerPlugin( FilePondPluginFileEncode, FilePondPluginImagePreview, FilePondPluginFileValidateType )

const countryOptions = [
  'Austria',
  'Belgium',
  'France',
  'Germany',
  'Italy',
  'Netherlands',
  'Spain',
  'Switzerland',
]

const CreateMailingTemplate = ( props ) => {
  const editor = useRef(null)

  useEffect( () => {
    props.onChangeSystemMailingTemplateHOC( 'mailTemplateErrMsg', {} )
  }, [props.showCreateMailingTemplate] )

  const saveDesign = async () => {
    let test = await editor.current.saveDesign
    let tmp = _.cloneDeep( props.newMailingTemplateData )
    await test( async design => {
      tmp.json_content = design
      let test2 = await editor.current.exportHtml
      await test2( html => {
        tmp.html_content = html.html

        if( props.files.length > 0 ){
         let reader = new FileReader()
         reader.onload = () => {
           let tmpResult = reader.result
           tmp = {
             ...tmp,
             image: {
               raw: tmpResult
             }
           }
           props.createMailingTemplate( tmp )
         }
         reader.readAsDataURL( props.files[ 0 ] )
       } else {
         props.createMailingTemplate( props.newMailingTemplateData )
       }
      })
    })
  }

  const onChange = ( key, val ) => {
    let tmp = _.cloneDeep( props.newMailingTemplateData )
    tmp[key] = val

    props.onChangeSystemMailingTemplateHOC( 'newMailingTemplateData', tmp )
  }

  return(
    <Modal
      isOpen={ props.showCreateMailingTemplate }
      size="xl">
      <ModalHeader toggle={ () => props.onChangeSystemMailingTemplateHOC('showCreateMailingTemplate', false ) }>{ Lang['CREATE_NEW_MAILING_TEMPPLATE'][ props.selectedLanguage ] }
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>{ Lang[ 'NAME' ][ props.selectedLanguage ] }<span style={{ color: 'red'}}>*</span></Label>
            <Input
              type="text"
              value={ props.newMailingTemplateData.name }
              onChange={ e => onChange('name', e.target.value ) } />
            {
              props.mailTemplateErrMsg.name && (
                <span style={{ color: 'red', marginBottom: '15px' }}>{ props.mailTemplateErrMsg.name }</span>
              )
            }
          </FormGroup>
          <FormGroup>
            <Label>{ Lang[ 'CENTER_GROUPS' ][ props.selectedLanguage ] }</Label>
            <ReactSelect
              isMulti
              name="center-groups"
              value={ props.newMailingTemplateData.center_groups }
              options={ props.centerGroups }
              onChange={ ( value, action ) => onChange( 'center_groups', value ) }
            />
          </FormGroup>
          <div className='d-flex flex-row flex-wrap w-100'>
            <FormGroup
              className='mr-2 d-flex flex-column'
              style={{ flexGrow: "2" }} >
              <Label>{ Lang[ 'COUNTRIES' ][ props.selectedLanguage ] }</Label>
              <ReactSelect
                isMulti
                name="country"
                value={ props.newMailingTemplateData.countries }
                options={ 
                  _.filter( 
                    props.countries, 
                    country => _.findIndex( countryOptions, item => item === country.name ) > -1 
                  ).map( item => ({ ...item, label: item.name, value: item.name }) )
                }
                onChange={ (value, action) => onChange('countries', value ) }
              />
            </FormGroup>
            <FormGroup
              className='d-flex flex-column'
              style={{ flexGrow: "1" }} >
              <Label>{ Lang[ 'STATUS' ][ props.selectedLanguage ] }</Label>
              <Input
                type='select'
                style={{ minHeight: "38px" }}
                value={ props.newMailingTemplateData.status }
                onChange={ e => onChange( 'status', e.target.value ) }>
                <option value='ACTIVE' >{ Lang[ 'ACTIVE' ][ props.selectedLanguage ] }</option>
                <option value='INACTIVE' >{ Lang[ 'INACTIVE' ][ props.selectedLanguage ] }</option>
                <option value='ARCHIVED' >{ Lang[ 'ARCHIVED' ][ props.selectedLanguage ] }</option>
              </Input>
            </FormGroup>
          </div>
          <FormGroup>
            <Label>{ Lang['UPLOAD_IMAGE'][ props.selectedLanguage ] }</Label>
            <FilePond
              files={ props.files }
              instantUpload={ false }
              allowFileTypeValidation={ true }
              allowImagePreview={ true }
              allowFileEncode={ true }
              acceptedFileTypes={['image/*' ]}
              onupdatefiles={ fileItems => {
                let tmpFiles = []
                let tmp = _.cloneDeep( props.newMailingTemplateData )
                fileItems.map( item => tmpFiles.push( item.file ) )
                props.onChangeSystemMailingTemplateHOC( 'files', tmpFiles )
              }}
              allowMultiple={ false }
              server=""
            />
          </FormGroup>
          <FormGroup className="mt-5">
            <EmailEditor
              ref={ editor }
              options={{
                locale: props.selectedLanguage
              }}
            />
          </FormGroup>
        </Form>
        { props.onLoadMailingTemplate && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button
          className="mb-2 mr-2"
          color="primary"
          onClick={ () => {
           saveDesign()
          }}>{ Lang[ 'SUBMIT' ][ props.selectedLanguage ] }</Button>
      </ModalFooter>
    </Modal>

  )
}
const mapStateToProps = state => ({ dataReducer: state })

export default connect( mapStateToProps )( CreateMailingTemplate )
