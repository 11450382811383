import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get, Put, Delete } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state={
      loading: false,
      productRequests: [],
      requestEditModal: false,
      selectedProductRequest: {
        created_at: '',
        client_id: 0,
        consultant_id: 0,
        from_date: '',
        product: {},
        status: {},
        to_date: '',
        user_id: 0,
        client: {}
      },
      totalPages: [],
      searchParams: {
        company_name: '',
        status_id: 0
      },
      showDeleteConfirmation: false
    }
    onChangeProductRequestHOC = ( key, value ) => this.setState({ [key]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    getAllProductRequests = query => Get(
      `/api/v1/admin_panel_product_requests?query=${ query }`,
      this.getAllProductRequestsSuccess,
      this.getAllProductRequestsError,
      this.load
    )
    getAllProductRequestsSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data.length > 0 ){
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      } else {
        this.requestError( Lang[ 'NO_RESULT_FOUND' ][ this.props.data.languageReducer.lang ] )
      }


      this.setState({ productRequests: payload, totalPages: tmpTotalPages })
    }
    getAllProductRequestsError = error => this.requestError( error )

    getSelectedRequest = ( product_id, request_id ) => Get(
      `/api/v1/admin_panel/products/${ product_id }/requests/${ request_id }`,
      this.getSelectedRequestSuccess,
      this.getSelectedRequestError,
      this.load
    )
    getSelectedRequestSuccess = payload => this.setState({ selectedProductRequest: payload })
    getSelectedRequestError = error => this.requestError( error )

    updateProductRequest = () => Put(
      `/api/v1/admin_panel/products/${ this.state.selectedProductRequest.product_id }/requests/${ this.state.selectedProductRequest.id }`,
      this.state.selectedProductRequest,
      this.updateProductRequestSuccess,
      this.updateProductRequestError,
      this.load
    )
    updateProductRequestSuccess = payload => {
      this.requestSuccess( Lang[ 'UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.setState({ requestEditModal: false })
      this.getAllProductRequests( convertObjToBase64({ is_paginated: true, page: 1 }) )
    }
    updateProductRequestError = error => this.requestError( error )

    deleteProductRequest = () => Delete(
      `/api/v1/admin_panel/products/${ this.state.selectedProductRequest.product_id }/requests/${ this.state.selectedProductRequest.id }`,
      this.deleteProductRequestSuccess,
      this.deleteProductRequestError,
      this.load
    )
    deleteProductRequestSuccess = payload => {
      this.requestSuccess( Lang[ 'DELETE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showDeleteConfirmation: false })
      this.getAllProductRequests( convertObjToBase64({ is_paginated: true, page: 1 }) )
    }
    deleteProductRequestError = error => this.requestError( error )

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            { ...this.props }
            getAllProductRequests={ this.getAllProductRequests }
            productRequests={ this.state.productRequests }
            onChangeProductRequestHOC={ this.onChangeProductRequestHOC }
            requestEditModal={ this.state.requestEditModal }
            totalPages={ this.state.totalPages }
            getSelectedRequest={ this.getSelectedRequest }
            selectedProductRequest={ this.state.selectedProductRequest }
            updateProductRequest={ this.updateProductRequest }
            onLoadProductRequest={ this.state.loading }
            searchParams={ this.state.searchParams }
            showDeleteConfirmation={ this.state.showDeleteConfirmation }
            deleteProductRequest={ this.deleteProductRequest }/>
        </Fragment>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}
export default HOC
