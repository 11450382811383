import React, { useState } from 'react'

const TypeSelectors = props => {
  const [ showDetails, updateShowDetails ] = useState( false )
  return (
    <div className="col-sm-3">
      <button 
        style={{
          "width": "100%",
          "background": "#f2f5f6",
          "border": "1px solid #d3d7d7",
          "borderRadius": "0.7em",
          "marginBottom": "30px",
          "padding": "15px",
          "textAlign": "center",
          display: showDetails ? 'none' : 'block'
        }}
        onTouchStart={ e => {
          updateShowDetails( true )
        }}
        onPointerOver={ e => {
          updateShowDetails( true )
        }}>
        <img src={ props.imgSrc } style={{ width: '80%' }} />
      </button>
      <button 
        style={{
          "width": "100%",
          "background": "#fff",
          "border": "1px solid #d3d7d7",
          "borderRadius": "0.7em",
          "marginBottom": "30px",
          "padding": "15px",
          "textAlign": "center",
          display: showDetails ? 'block' : 'none'
        }}
        onPointerLeave={ e => {
          updateShowDetails( false )
        }}>
        <p style={{ textTransform: 'uppercase', fontWeight: '600' }}>{ props.title }</p>
        <p>{ props.description }</p>
      </button>
    </div>
  )
}

export default TypeSelectors