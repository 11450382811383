import React from 'react'
import {
  Label, Form, FormGroup, Input, Button
} from 'reactstrap'
import Moment from 'moment'
import _ from 'lodash'
import { connect } from 'react-redux'

import Lang from 'Lang/General'
import LangTepe from 'Lang/TePe'

const PeriodStartContracts = [
  {
    label: 'January 1st',
    value: `${ Moment().format( 'YYYY' ) }-01-01T00:00:00+00:00`
  },
  {
    label: 'February 1st',
    value: `${ Moment().format( 'YYYY' ) }-02-01T00:00:00+00:00`
  },
  {
    label: 'March 1st',
    value: `${ Moment().format( 'YYYY' ) }-03-01T00:00:00+00:00`
  },
  {
    label: 'April 1st',
    value: `${ Moment().format( 'YYYY' ) }-04-01T00:00:00+00:00`
  },
  {
    label: 'May 1st',
    value: `${ Moment().format( 'YYYY' ) }-05-01T00:00:00+00:00`
  },
  {
    label: 'June 1st',
    value: `${ Moment().format( 'YYYY' ) }-06-01T00:00:00+00:00`
  },
  {
    label: 'July 1st',
    value: `${ Moment().format( 'YYYY' ) }-07-01T00:00:00+00:00`
  },
  {
    label: 'August 1st',
    value: `${ Moment().format( 'YYYY' ) }-08-01T00:00:00+00:00`
  },
  {
    label: 'September 1st',
    value: `${ Moment().format( 'YYYY' ) }-09-01T00:00:00+00:00`
  },
  {
    label: 'October 1st',
    value: `${ Moment().format( 'YYYY' ) }-10-01T00:00:00+00:00`
  },
  {
    label: 'November 1st',
    value: `${ Moment().format( 'YYYY' ) }-11-01T00:00:00+00:00`
  },
  {
    label: 'December 1st',
    value: `${ Moment().format( 'YYYY' ) }-12-01T00:00:00+00:00`
  }
]

const SettingsForm = ({
  selectedBookedCampaign,
  consultants,
  onChangeBookedCampaignHOC,
  updateSettings,
  languageReducer
}) => {
  const onChangeSettings = ( key, value ) => {
    let tmp = _.cloneDeep( selectedBookedCampaign.setting )
    tmp[key] = value

    return onChangeBookedCampaignHOC( 'selectedBookedCampaign', {
      ...selectedBookedCampaign,
      setting: tmp
    })
  }

  return(
    <div className="mt-3 p-3 pb-5 border border-dark">
      <Label className="h5">{ Lang[ 'OLS_INFO' ][ languageReducer.lang ] }</Label>
      <Label className="h6">{ Lang[ 'SUBTITLE' ][ languageReducer.lang ] }</Label>
      <Form>
        {
          selectedBookedCampaign.setting.is_one_timer && (
            <FormGroup>
              <Label>{ Lang[ 'CONTRACT_TERM' ][ languageReducer.lang ] }<span style={{ color: 'red' }}>*</span></Label>
              <Input
                type={ 'text' }
                value={ selectedBookedCampaign.setting.contract_term }
                onChange={ e => onChangeSettings( 'contract_term', e.target.value ) }
                required />
            </FormGroup>
          )
        }
        {
          selectedBookedCampaign.setting.is_one_timer && (
            <FormGroup>
              <Label>{ Lang[ 'START_CONTRACT' ][ languageReducer.lang ] }<span style={{ color: 'red' }}>*</span></Label>
              <Input
                type="select"
                value={ `${ Moment( selectedBookedCampaign.setting.start_of_contract ).format( 'YYYY-MM' ) }-01T00:00:00+00:00` }
                required
                onChange={ e => onChangeSettings( 'start_of_contract', e.target.value ) }>
                <option>{ 'Please select one from below' }</option>
                {
                  PeriodStartContracts.map( item => {
                    if( item.value > Moment().format() ) {
                      return (
                        <option key={ item.value } value={ item.value }>{ item.label }</option>
                      )
                    }
                  })
                }
              </Input>
            </FormGroup>
          )
        }
        <FormGroup check>
          <Input
            type="checkbox"
            checked={ selectedBookedCampaign.setting.is_pure_woman_studio }
            onChange={ e => onChangeSettings( 'is_pure_woman_studio', e.target.checked ) } />
          <Label>{ Lang[ 'IS_PURE_WOMAN_STUDIO' ][ languageReducer.lang ] }</Label>
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'STUDIO_NAME' ][ languageReducer.lang ] }</Label>
          <Input
            type={ 'text' }
            value={ selectedBookedCampaign.setting.studio_name }
            onChange={ e => onChangeSettings( 'studio_name', e.target.value ) }
            required />
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'ROAD' ][ languageReducer.lang ] }</Label>
          <Input
            type={ 'text' }
            value={ selectedBookedCampaign.setting.road }
            onChange={ e => onChangeSettings( 'road', e.target.value ) }
            required />
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'POSTCODE' ][ languageReducer.lang ] }</Label>
          <Input
            type={ 'number' }
            value={ selectedBookedCampaign.setting.postcode }
            onChange={ e => onChangeSettings( 'postcode', e.target.value ) }
            required />
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'CONTACT_PERSON' ][ languageReducer.lang ] }</Label>
          <Input
            type={ 'text' }
            value={ selectedBookedCampaign.setting.contact_person }
            onChange={ e => onChangeSettings( 'contact_person', e.target.value ) }
            required />
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'EMAIL_CONTACT_PERSON' ][ languageReducer.lang ] }</Label>
          <Input
            type={ 'email' }
            value={ selectedBookedCampaign.setting.contact_email }
            onChange={ e => onChangeSettings( 'contact_email', e.target.value ) }
            required />
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'TELEPHONE_CONTACT_PERSON' ][ languageReducer.lang ] }</Label>
          <Input
            type={ 'text' }
            value={ selectedBookedCampaign.setting.contact_telephone }
            onChange={ e => onChangeSettings( 'contact_telephone', e.target.value ) }
            required />
        </FormGroup>
        {
          selectedBookedCampaign.setting.is_one_timer && (
            <FormGroup>
              <Label>{ Lang[ 'MY_PERSONAL_ADVISOR' ][ languageReducer.lang ] }</Label>
              <Input
                type="select"
                value={ selectedBookedCampaign.setting.consultant.id }
                onChange={ e => onChangeSettings( 'consultant', _.find( consultants, { id: parseInt( e.target.value ) } ) ) }
                required>
                {
                  consultants.map( item => {
                    return (
                      <option key={ item.id } value={ item.id }>{ item.user.first_name ? item.user.first_name : '' }</option>
                    )
                  })
                }
              </Input>
            </FormGroup>
          )
        }
        <FormGroup>
          <Label>{ Lang[ 'SEND_LEAD_EMAIL_ADDRESS' ][ languageReducer.lang ] }</Label>
          <Input
            type={ 'email' }
            value={ selectedBookedCampaign.setting.lead_receive_email_address }
            onChange={ e => onChangeSettings( 'lead_receive_email_address', e.target.value ) }
            required />
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'FB_LINK' ][ languageReducer.lang ] }</Label>
          <Input
            type={ 'text' }
            value={ selectedBookedCampaign.setting.facebook_url }
            onChange={ e => onChangeSettings( 'facebook_url', e.target.value ) }
            required />
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'IMPRINT_URL' ][ languageReducer.lang ] }</Label>
          <Input
            type={ 'text' }
            value={ selectedBookedCampaign.setting.imprint_url }
            onChange={ e => onChangeSettings( 'imprint_url', e.target.value ) }
            required />
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'DATA_PROTECTION_LINK' ][ languageReducer.lang ] }</Label>
          <Input
            type={ 'text' }
            value={ selectedBookedCampaign.setting.data_protection_url }
            onChange={ e => onChangeSettings( 'data_protection_url', e.target.value ) }
            required />
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'FB_MONTHLY_CLICK_BUDGET' ][ languageReducer.lang ] }</Label>
          <Input
            type={ 'number' }
            value={ selectedBookedCampaign.setting.monthly_fb_click_through_budget }
            onChange={ e => onChangeSettings( 'monthly_fb_click_through_budget', parseInt( e.target.value ) ) }
            required />
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'BEST_PLACE_NAME' ][ languageReducer.lang ] }</Label>
          <FormGroup check>
            <Input
              type="checkbox"
              checked={ selectedBookedCampaign.setting.is_place_name_standard }
              onChange={ e => onChangeSettings( 'is_place_name_standard', e.target.checked ) } />
            { Lang[ 'STANDARD_PLACE_NAME_SURROUNDINGS' ][ languageReducer.lang ] }
          </FormGroup>
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'INDIVIDUAL_PLACE_NAME' ][ languageReducer.lang ] }</Label>
          <Input
            type={ 'text' }
            value={ selectedBookedCampaign.setting.advertising_text_place_name }
            onChange={ e => onChangeSettings( 'advertising_text_place_name', e.target.value ) } />
        </FormGroup>
        <FormGroup check>
          <Input
            type="checkbox"
            checked={ selectedBookedCampaign.setting.is_aciso_website_online }
            onChange={ e => onChangeSettings( 'is_aciso_website_online', e.target.checked ) } />
          <Label>{ window.location.host.indexOf( 'tennis' ) > -1 ? LangTepe[ 'IS_ACISO_WEBSITE_ONLINE' ][ languageReducer.lang ] : Lang[ 'IS_ACISO_WEBSITE_ONLINE' ][ languageReducer.lang ] }</Label>
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'FB_ADVERTISING_ACCOUNT_ID' ][ languageReducer.lang ] }</Label>
          <Input
            type={ 'text' }
            value={ selectedBookedCampaign.setting.fb_advertising_account_id }
            onChange={ e => onChangeSettings( 'fb_advertising_account_id', e.target.value ) } />
        </FormGroup>
        <FormGroup>
          <Label>
            { Lang[ 'MONTHLY_CLICK_GOOGLE_ADWORDS' ][ languageReducer.lang ] }
          </Label>
          <Input
            type={ 'number' }
            value={ selectedBookedCampaign.setting.monthly_google_budget }
            onChange={ e => onChangeSettings( 'monthly_google_budget', parseInt( e.target.value ) ) } />
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'GOOGLE_ADS_CUSTOMER_NUMBER' ][ languageReducer.lang ] }</Label>
          <p>{ Lang[ 'FIND_GOOGLE_ACCOUNT_TOP_RIGHT' ][ languageReducer.lang ] }</p>
          <Input
            type="text"
            value={ selectedBookedCampaign.setting.google_ads_customer_number }
            onChange={ e => onChangeSettings( 'google_ads_customer_number', e.target.value )} />
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'COMMENT' ][ languageReducer.lang ] }</Label>
          <Input
            type="text"
            value={ selectedBookedCampaign.setting.comment }
            onChange={ e => onChangeSettings( 'comment', e.target.value )} />
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'CONSENT_DATA_PROCESSING' ][ languageReducer.lang ] }</Label>
          <FormGroup check>
            <Input
              type="checkbox"
              checked={ selectedBookedCampaign.setting.is_consent_to_data_processing }
              onChange={ e => onChangeSettings( 'is_consent_to_data_processing', e.target.checked )} />
            { Lang[ 'YES_CONSENT_STATEMENT' ][ languageReducer.lang ] }
          </FormGroup>
        </FormGroup>
        <Button
          className="btn float-right"
          color="primary"
          onClick={ () => updateSettings( 
            {
              ...selectedBookedCampaign.setting,
              contract_period: '8 weeks',
              consultant_id: selectedBookedCampaign.setting.center.consultant_id,
              start_of_contract: Moment( selectedBookedCampaign.setting.start_of_contract ).format()
            },
            selectedBookedCampaign.booked_campaign.client_id
          )} >
          { Lang[ 'SUBMIT' ][ languageReducer.lang ] }
        </Button>
      </Form>

    </div>
  )
}
const mapStateToProps = state => ({
  languageReducer: state.languageReducer
})

export default connect( mapStateToProps )( SettingsForm )
