import React, { Component } from 'react'
import { compose } from 'redux'
import _ from 'lodash'
import {
  Button, UncontrolledTooltip,
  Modal
} from 'reactstrap'

import ARKTable from 'components/Table'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PageTitle from 'components/PageTitle'
import Lang from 'Lang/General'
import MailGunStats from './MailGunContent'

import CenterHOC from './actions/index'

class CenterManagement extends Component{
  state= {
    searchColumn: [
      {
        name: Lang[ 'CENTER_NAME' ][ this.props.data.languageReducer.lang ],
        type: "text",
        val: 'center_name'
      },
      {
        name: Lang[ 'CENTER_GROUP' ][ this.props.data.languageReducer.lang ],
        type: 'searchableDropdown',
        val: 'center_group_id',
        dropdownOptions: []
      },
      {
        name: Lang[ 'SITE_STATUS' ][ this.props.data.languageReducer.lang ],
        type: 'dropdown',
        val: 'is_live_site',
        dropdownOptions: [
          {id: 'LIVE', label: Lang[ 'LIVE' ][ this.props.data.languageReducer.lang ], value: true },
          {id: 'NOT_LIVE', label: Lang[ 'NOT_LIVE' ][ this.props.data.languageReducer.lang ], value: false }
        ]
      },
      {
        name: Lang[ 'COUNTRY' ][ this.props.data.languageReducer.lang ],
        type: "text",
        val: 'country'
      },
      {
        name: Lang[ 'POSTCODE' ][ this.props.data.languageReducer.lang ],
        type: "text",
        val: 'postcode'
      },
      {
        name: Lang[ 'CITY' ][ this.props.data.languageReducer.lang ],
        type: "text",
        val: 'city'
      },
      {
        name: Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ],
        type: 'dropdown',
        val: 'status'
      },
      {
        name: Lang[ 'SEARCH_BY_CONSULTANT' ][ this.props.data.languageReducer.lang ],
        type: 'searchableDropdown',
        val: 'consultant_id',
        dropdownOptions: []
      },
      {
        name: Lang[ 'SEARCH_BY_CLIENT' ][ this.props.data.languageReducer.lang ],
        type: 'searchableDropdown',
        val: 'client_id',
        dropdownOptions: []
      },
      {
        name: Lang[ 'START_AND_END_DATE' ][ this.props.data.languageReducer.lang ],
        val: 'start_and_end_date',
        fields: [
          {
            name: Lang[ 'START_DATE' ][ this.props.data.languageReducer.lang ],
            type: 'date',
            val: 'start_date'
          },
          {
            name: Lang[ 'END_DATE' ][ this.props.data.languageReducer.lang ],
            type: 'date',
            val: 'end_date'
          }
        ]
      }
    ],
    archivedSearchColumn: [
      {
        name: Lang[ 'CENTER_NAME' ][ this.props.data.languageReducer.lang ],
        type: "text",
        val: 'center_name'
      },
      {
        name: Lang[ 'FIRST_NAME' ][ this.props.data.languageReducer.lang ],
        type: "text",
        val: 'first_name'
      },
      {
        name: Lang[ 'LAST_NAME' ][ this.props.data.languageReducer.lang ],
        type: "text",
        val: 'last_name'
      },
      {
        name: Lang[ 'COUNTRY' ][ this.props.data.languageReducer.lang ],
        type: "text",
        val: 'country'
      },
      {
        name: Lang[ 'POSTCODE' ][ this.props.data.languageReducer.lang ],
        type: "text",
        val: 'postcode'
      },
      {
        name: Lang[ 'CITY' ][ this.props.data.languageReducer.lang ],
        type: "text",
        val: 'city'
      }
    ],
    selectedSearchColumn: [],
  }

  componentDidMount = () => {
    let tmp = {
      page: this.props.centers.meta ? this.props.centers.meta.current_page : 1,
      is_paginated: true,
      filter: {
        $or: [
          { status: 'ACTIVE' },
          { status: 'INACTIVE' },
          { status: 'TRIAL' },
        ]
      }
    }
    this.props.getCenters( tmp )
  }

  render = () => {
    return (
      <>
        <PageTitle
          heading={ Lang[ 'CENTER_STATISTICS' ][ this.props.data.languageReducer.lang ] }
          icon="pe-7s-home icon-gradient bg-happy-fisher" />
        <ARKTable
          data={ this.props.centers.data }
          columns={[
            {
              Header: Lang[ 'CENTER_NAME' ][ this.props.data.languageReducer.lang ],
              accessor: "center_name"
            },
            {
              Header: Lang[ 'NAME' ][ this.props.data.languageReducer.lang ],
              accessor: "fullname"
            },
            {
              Header: Lang['STATUS'][ this.props.data.languageReducer.lang ],
              accessor: 'status'
            },
            {
              Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
              accessor: "id",
              width: 160,
              Cell: row => (
                <>
                  <Button
                    id={ `center-report-${row.original.id}-mailgun-stats` }
                    className="btn-icon btn-icon-only btn-view-center-report"
                    color="primary"
                    onClick={ () => this.props.onChangeCenterHOC( 'selectedCenter', row.original )}
                  >
                    <i className="pe-7s-mail-open"></i>
                  </Button>
                  <UncontrolledTooltip
                    target={ `center-report-${row.original.id}-mailgun-stats` }
                    placement="top"
                  >
                    { Lang[ 'VIEW_MAILGUN_STAT' ][ this.props.data.languageReducer.lang ] }
                  </UncontrolledTooltip>
                </>
              )
            }
          ]}
          totalPages={ this.props.centerTotalPage }
          page={ this.props.centers.meta ? this.props.centers.meta.current_page : 1 }
          onChangePage={ pg => {
            let tmp = {
              page: pg,
              is_paginated: true,
              filter: {
                $or: [
                  { status: 'ACTIVE' },
                  { status: 'INACTIVE' },
                  { status: 'TRIAL' }
                ]
              }
            }
            this.props.getCenters( tmp )
          }}
          onClickReset={ () => {
            let tmp = {
              page: this.props.centers.meta ? this.props.centers.meta.current_page : 1,
              is_paginated: true,
              filter: {
                $or: [
                  { status: 'ACTIVE' },
                  { status: 'INACTIVE' },
                  { status: 'TRIAL' }
                ]
              }
            }
            this.props.getCenters( tmp )
            this.props.onChangeCenterHOC( 'centerReportSearchParams', '' )
          }}
          onSearch={ this.props.getCentersSearch }
          searchLabel={ Lang[ 'SEARCH_WITH_CENTER_NAME' ][ this.props.data.languageReducer.lang ] }
          searchComponent={ true }
          searchColumn={ this.state.searchColumn }
          showPagination={ false }
          modulesUsed={'Trial'}
          queryActionButtons={  
            [
              {
                content: Lang[ 'EXPORT_EXCEL' ][ this.props.data.languageReducer.lang ],
                onClick: () => this.props.getCenterReportExcel(),
                color: 'success mt-1',
                style: {
                  float: 'right', 
                  marginRight: '30px',
                  display: this.props.centerReportSearchParams.indexOf( 'start_date' ) > -1 && this.props.centerReportSearchParams.indexOf( 'end_date' ) > -1
                    ? 'block'
                    : 'none'
                }
              }
            ]
          } 
        />
        { (
            this.props.data.ajaxReducer.ajaxCallProgress > 0 ||
            this.props.onLoadCenters
          ) && <LoadingOverlay /> }
        <Modal size={ 'xl' } isOpen={ this.props.selectedCenter ? true : false }>
          <MailGunStats
            selectedCenter={ this.props.selectedCenter }
            lang={ this.props.data.languageReducer.lang }
            onClose={ () => this.props.onChangeCenterHOC( 'selectedCenter', null )}
          />
        </Modal>
      </>
    )
  }
}

export default compose(
  CenterHOC
)( CenterManagement )
