import React, { Component } from 'react'
import { Dialog, Button } from '@material-ui/core'
import { MdClose } from 'react-icons/md'
import _ from 'lodash'

import TemplateEditorCard from '../components/card'
import LoadingModal from 'components/Indicator/LoadingOverlay'

import './index.scss'

import Lang from 'Lang/General'

class PageEdit extends Component {
  state = {
    mode: 'details'
  }

  render = () => {
    return (
      <Dialog
        open={ this.props.showPageEdit }
        maxWidth={ 'lg' }
        fullWidth={ true }>
        <TemplateEditorCard>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h4>{`${ Lang[ 'EDIT_CURRENT_PAGE' ][ this.props.lang ] } ${ this.props.selectedPage.name }`}</h4>
            <Button
              classes={{
                root: "button-close",
                label: "button-text" }}
              onClick={ () => this.props.onChangeShowPageEdit( false ) }>
              <MdClose style={{ fontSize: '20px' }}/>
            </Button>
          </div>
          <hr />
          <div className="form-group">
            <label>{ Lang[ 'PAGE_NAME' ][ this.props.lang ] }</label>
            <input
              className="form-control"
              value={ this.props.selectedPage.name }
              onChange={ e => this.props.onChangeSelectedPage( e.target.value, 'name' ) } />
          </div>
          <div className="form-group">
            <label>{ Lang[ 'PAGE_URL' ][ this.props.lang ] }</label>
            <input
              className="form-control"
              value={ this.props.selectedPage.url }
              onChange={ e => this.props.onChangeSelectedPage( e.target.value, 'url' ) } />
          </div>
          <div className="form-group">
            <label>{ Lang[ 'TITLE' ][ this.props.lang ] }</label>
            {
              this.props.languages && this.props.selectedPage && this.props.selectedPage.content && this.props.languages.map( item => {
                return (
                  <div key={ item.id } className="d-flex form-group">
                    <label style={{ marginRight: '10px', marginBottom: 0 }}>{ item.shortname }</label>
                    <input
                      className="form-control"
                      value={ this.props.selectedPage.content.title[ item.shortname ] ? this.props.selectedPage.content.title[ item.shortname ] : '' }
                      onChange={ e => {
                        let tmp = _.cloneDeep( this.props.selectedPage )
                        tmp.content.title[ item.shortname ] = e.target.value
                        this.props.onChangeSelectedPage( tmp.content, 'content' )
                      }} />
                  </div>
                )
              })
            }
          </div>
          <hr />
          <div className="page-creator-footer">
            <Button
              classes={{ root: 'btn-primary' }}
              style={{ marginRight: '10px' }}
              onClick={() => {
                this.props.updateSelectedPage( this.props.template_id, this.props.selectedPage.id, this.props.selectedPage, 'edit' )
              }}>
              { Lang[ 'SAVE_UPDATED_PAGE' ][ this.props.lang ] }
            </Button>
            <Button
              classes={{ root: 'btn-secondary' }}
              onClick={() => this.props.onChangeShowPageEdit( false )}>
              { Lang[ 'CLOSE' ][ this.props.lang ] }
            </Button>
          </div>
          { this.props.onLoadPagesUpdate && <LoadingModal /> }
        </TemplateEditorCard>
      </Dialog>
    )
  }
}

export default PageEdit
