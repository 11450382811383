import React, { useState, useEffect } from 'react'
import _ from 'lodash'

import CoreRenderFunction from '../../core/render'

const Team = props => {
  let {
    item,
    mode
  } = props
  const [ dom, updateDom ] = useState( item )
  const [ containerOffset, updateContainerOffset ] = useState( 0 )

  useEffect(() => {
    let targetNode = document.getElementById( 'editor-site-renderer' )
    let config = { attributes: true, childList: true, subtree: true }
    let mutationCallback = () => {
      if( !_.isEmpty( document.querySelector( '.header-menu-container' )) && !_.isEmpty( document.querySelector( '.top-contact-info' )) ){
        let topContactBar = document.querySelector( '.top-contact-info' ).getBoundingClientRect()
        let headerMenu = document.querySelector( '.header-menu-container' ).getBoundingClientRect()
        let tmpOffset = headerMenu.bottom > topContactBar.height + headerMenu.height ? headerMenu.height : headerMenu.bottom
        updateContainerOffset( tmpOffset )
        mutationObserver.disconnect()
      }
    }
    let mutationObserver = new MutationObserver( mutationCallback )
    mutationObserver.observe( targetNode, config )

    let tmpWindow = mode !== "view" ? document.getElementById( 'editor-site-renderer' ).contentWindow : window
    let tmpDocument = tmpWindow.document
    let tmpScrollContainer = tmpWindow.getComputedStyle( tmpDocument.body.parentElement ).overflowX === "hidden" ? tmpDocument.body : tmpDocument
    const onScroll = () => {
      if( !_.isEmpty( tmpDocument.querySelector( '.header-menu-container' )) && !_.isEmpty( tmpDocument.querySelector( '.top-contact-info' )) ){
        let topContactBar = tmpDocument.querySelector( '.top-contact-info' ).getBoundingClientRect()
        let headerMenu = tmpDocument.querySelector( '.header-menu-container' ).getBoundingClientRect()
        let tmpOffset = headerMenu.bottom > topContactBar.height + headerMenu.height ? headerMenu.height : headerMenu.bottom
        updateContainerOffset( tmpOffset )
      }
    }

    tmpScrollContainer.addEventListener( "scroll", onScroll )
  }, [])

  useEffect(() => {
    let tmp = _.cloneDeep( item )
    findAndChangeTargetValue( tmp, 'background-image', `center ${ containerOffset }px`, 'style', 'backgroundPosition' )
    findAndChangeTargetValue( tmp, 'team-searchbar-button', 'unset', 'style', 'display' )
    updateDom( tmp )
  }, [ containerOffset, item ])

  const findAndChangeTargetValue = ( data, target, value, key, subKey ) => {
    if( data.mapWith === target ){
      if( value === 'remove' ){
        delete data[key]
      } else {
        if( subKey ){
          data[ key ][ subKey ] = value
        } else {
          data[ key ] = value
        }
      }
    } else {
      if( data.children?.length > 0 ){
        data.children.map( child => {
          findAndChangeTargetValue( child, target, value, key, subKey )
        })
      }
    }
  }

  return (
    <>
      {
        dom.children?.length > 0 && dom.children.map( child => {
          return (
            <CoreRenderFunction
              { ...props }
              item={ child }
            />
          )
        })
      }
    </>
  )
}

export default Team