import React, { Component } from 'react'
import _ from 'lodash'

import StatusModal from "components/Modal/status"
import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showStatusModal: false,
      statusModalType: "",
      onClickStatusModalButton: () => {},
      currentForm: {},
      currentFormValues: [],
      dom: [],
    }

    load = param => this.setState({ loading: param })
    requestError = error =>
      this.setState({
        showStatusModal: true,
        statusModalType: "negative",
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }),
        statusModalMessage: error,
      })
    requestSuccess = success =>
      this.setState({
        showStatusModal: true,
        statusModalType: "positive",
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }),
        statusModalMessage: success,
      })

    onChangeFormsHOC = ( key, val ) => this.setState({ [ key ]: val })

    getSelectedForm = id => Get(
      `/api/v1/webform_templates/${ id }`,
      this.getSelectedFormSuccess,
      this.getSelectedFormError,
      this.load
    )
    getSelectedFormSuccess = payload => 
			this.setState({
        currentForm: payload,
        dom: payload.content,
        currentFormValues: payload.values
      })
    getSelectedFormError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            onChangeFormsHOC={ this.onChangeFormsHOC }
            getSelectedForm={ this.getSelectedForm }
            onLoadForms={ this.state.loading }
            currentForm={ this.state.currentForm }
            currentFormValues={ this.state.currentFormValues }
            dom={ this.state.dom } />
          <StatusModal
            isOpen={ this.state.showStatusModal }
            type={ this.state.statusModalType }
            message={ this.state.statusModalMessage }
            onClick={ this.state.onClickStatusModalButton }
          />
        </>
      )
    }
  }

  return WithHOC 
}

export default HOC
