import React, { Component } from 'react'
import { compose } from 'redux'
import { 
  Button, Card, CardHeader, CardBody,
  Row, Col, 
  CardFooter, FormGroup, Label, Input, 
  UncontrolledTooltip
} from 'reactstrap'
import _ from 'lodash'
import Image from 'react-bootstrap/Image'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PromptModal from 'components/Indicator/Prompt'
import PageTitle from 'components/PageTitle'
import ARKTable from 'components/Table'

import UsersHOC from './actions'
import WithConsultants from 'actions/consultant'
import CreateUser from './Create'
import Update from './Update/index'
import View from './View'
import { convertObjToBase64 } from 'utils/objToBase64'
import NoPicture from 'assets/images/no-pic.jpg'

import Lang from 'Lang/General'

class UsersManagement extends Component {

  componentDidMount = () => {
    let tmp = {
      page: 1,
      is_paginated: true
    }
    Promise.all([
      this.props.getArchivedConsultant({ is_paginated: false })
    ]).then(() => {
      this.props.getUsers( convertObjToBase64( tmp ) )
      this.props.getRoles( convertObjToBase64( { is_paginated: false } ) )
    })
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Users Management' })
      return tmpModules.is_create
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Users Management' })
      return tmpModules.is_update
    }
  }

  handleSecurityModulesDelete = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Users Management' })
      return tmpModules.is_delete
    }
  }

  render = () => {
    return(
      <>
        <PageTitle
          heading={ Lang[ 'USER' ][ this.props.data.languageReducer.lang ] }
          icon="pe-7s-users icon-gradient bg-happy-fisher"
          buttons={[
            {
              buttonText: Lang[ 'CREATE' ][ this.props.data.languageReducer.lang ],
              onClick: () => this.props.onChangeUsersHOC( 'showCreate', true ),
              display: this.handleSecurityModulesCreate()
            }
          ]}/>
        <Card className="mb-3">
          <CardHeader>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }</CardHeader>
          <CardBody>
            <Row>
              <Col md={ 4 }>
                <FormGroup>
                  <Label>{ Lang[ 'NAME' ][ this.props.data.languageReducer.lang ] }</Label>
                  <Input
                    type="text"
                    value={ this.props.searchParams.name }
                    onChange={ e => {
                      let tmp = _.cloneDeep( this.props.searchParams )
                      tmp.name= e.target.value
                      return this.props.onChangeUsersHOC( 'searchParams', tmp )
                    }} />
                </FormGroup>
              </Col>
              <Col md={ 4 }>
                <FormGroup>
                  <Label>{ Lang[ 'EMAIL' ][ this.props.data.languageReducer.lang ] }</Label>
                  <Input
                    type="text"
                    value={ this.props.searchParams.email }
                    onChange={ e => {
                      let tmp = _.cloneDeep( this.props.searchParams )
                      tmp.email= e.target.value
                      return this.props.onChangeUsersHOC( 'searchParams', tmp )
                    }} />
                </FormGroup>
              </Col>
              <Col md={ 4 }>
                <FormGroup>
                  <Label>{ Lang[ 'ROLE' ][ this.props.data.languageReducer.lang ] }</Label>
                  <Input
                    type="select"
                    value={ this.props.searchParams.role_id }
                    onChange={ e => {
                      let tmp = _.cloneDeep( this.props.searchParams )
                      tmp.role_id = e.target.value
                      return this.props.onChangeUsersHOC( 'searchParams', tmp )
                    }}>
                    <option value={ -1 } key={ -1}>{ '' }</option>
                    {
                      this.props.roles.data && this.props.roles.data.map( role => {
                        return <option value={ role.id } key={ role.id }>{ role.name }</option>
                      })
                    }
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button
              color="primary"
              className="ml-auto mr-2"
              onClick={() => {
                let tmpSearchParams = {
                  is_paginated: true,
                  page: 1
                }
                const filter = this.props.processSearchFilter()
                if ( !_.isEmpty( filter ) ) {
                  tmpSearchParams.filter = filter
                }
                this.props.getUsers( convertObjToBase64( tmpSearchParams ) )
              }}>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }</Button>
            <Button
              color="danger"
              onClick={ () => {
                let tmpSearchParams = _.cloneDeep( this.props.searchParams )
                tmpSearchParams.email = ''
                tmpSearchParams.name = ''
                tmpSearchParams.role_id = -1
                let tmp = {
                  page: 1,
                  is_paginated: true
                }
                this.props.getUsers( convertObjToBase64( tmp ) )
                return this.props.onChangeUsersHOC( 'searchParams', tmpSearchParams )
              }}>{ Lang[ 'RESET' ][ this.props.data.languageReducer.lang ] }</Button>
          </CardFooter>
        </Card>
        <ARKTable
          data={ this.props.users.data }
          columns={[
            {
              Header: Lang[ 'NAME' ][ this.props.data.languageReducer.lang ],
              accessor: "fullname"
            },
            {
              Header: Lang[ 'EMAIL' ][ this.props.data.languageReducer.lang ],
              accessor: "email"
            },
            {
              Header: Lang[ 'ROLE' ][ this.props.data.languageReducer.lang ],
              accessor: 'role'
            },
            {
              Header: Lang[ 'PHOTO' ][ this.props.data.languageReducer.lang ],
              accessor: 'photo_url',
              Cell: value => (
                <Image 
                  src={ !_.isEmpty( value.original.photo_url ) ? value.original.photo_url : NoPicture } 
                  width="50px" 
                  height="50px" />
              )
            },
            {
              Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
              accessor: "id",
              width: 160,
              Cell: ({value}) => (
                <div className="w-100" style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                  <div className="text-align-center">
                    <Button
                      id={ `User-management-${ value }-view-user` }
                      className="mb-2 mr-2 btn-icon btn-icon-only"
                      color="primary"
                      onClick={ () => {
                        this.props.getSelectedUser( value )
                        this.props.onChangeUsersHOC( 'showView', true )
                      } }>
                      <i className="pe-7s-look"> </i>
                    </Button>
                    <UncontrolledTooltip
                      target={ `User-management-${ value }-view-user` }
                      placement="top">{ Lang[ 'VIEW_DETAILS' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                    {
                      this.handleSecurityModulesUpdate() && (
                        <>
                          <Button
                            id={ `User-management-${ value }-update-user` }
                            className="mb-2 mr-2 btn-icon btn-icon-only"
                            color="primary"
                            onClick={ async () => {
                              await this.props.getSelectedUser( value )
                              this.props.onChangeUsersHOC( 'showUpdate', true )
                            } }>
                            <i className="pe-7s-note"> </i>
                          </Button>
                          <UncontrolledTooltip
                            target={ `User-management-${ value }-update-user` }
                            placement="top">{ Lang[ 'UPDATE_USER' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                        </>
                      )
                    }
                    {
                      this.handleSecurityModulesDelete() && (
                        <>
                          <Button
                            id={ `User-management-${ value }-delete-user` }
                            className="mb-2 mr-2 btn-icon btn-icon-only"
                            color="danger"
                            onClick={ () => this.props.onChangeUsersHOC( 'showRemoveUserId', value ) } >
                            <i className="pe-7s-trash"> </i>
                          </Button>
                          <UncontrolledTooltip
                            target={ `User-management-${ value }-delete-user` }
                            placement="top">{ Lang[ 'REMOVE_USER' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                        </>
                      )
                    }
                  </div>
                </div>
              )
            }
          ]}
          totalPages={ this.props.usersTotalPages }
          page={ this.props.users.meta ? this.props.users.meta.current_page : 1 }
          onChangePage={ pg => {
            let tmp = {
              page: pg,
              is_paginated: true,
            }
            const filter = this.props.processSearchFilter()
            if ( !_.isEmpty( filter ) ) {
              tmp.filter = filter
            }
            this.props.getUsers( convertObjToBase64 ( tmp ) )
          }}
          onClickReset={ () => {
            let tmp = {
              page: this.props.users.meta ? this.props.users.meta.current_page : 1,
              is_paginated: true
            }
            this.props.getUsers( tmp )
          }}
          searchComponent={ false }
          showPagination={ false }/>
        {
          this.props.showCreate && (
            <CreateUser
              dataReducer={ this.props.data }
              showCreate={ this.props.showCreate }
              onChangeUsersHOC={ this.props.onChangeUsersHOC }
              newUser={ this.props.newUser }
              roles={ this.props.roles }
              createUser={ this.props.createUser }
              errorMessage={ this.props.errorMessage }
              statusValues={ this.props.data.dictionaryReducer.dictionary.status }
              onLoadUsers={ this.props.onLoadUsers }
              currentUser={ this.props.data.profileReducer.profile } />
          )
        }
        <Update { ...this.props }/>
        <View
          onChangeUsersHOC={ this.props.onChangeUsersHOC }
          selectedUser={ this.props.selectedUser }
          showView={ this.props.showView }
          lang={ this.props.data.languageReducer.lang } />
        <PromptModal
          showPromptModal={ this.props.showRemoveUserId > 0 }
          onClickYes={ () => this.props.removeUser() }
          onClickNo={ () => this.props.onChangeUsersHOC( 'showRemoveUserId', 0 ) }
          content={ Lang[ 'ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_USER' ][ this.props.data.languageReducer.lang ] }
          lang={ this.props.data.languageReducer.lang } 
        />
        { ( this.props.data.ajaxReducer.ajaxCallProgress > 0 || this.props.onLoadUsers ) && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose( 
  WithConsultants,
  UsersHOC
)( UsersManagement )
