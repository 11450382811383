import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios-template'
import { production_url } from '../config'

const Footers = ( WrappedComponent ) => {
  class FootersWrappedComponent extends Component {
    state = {
      loading: false,
      templateFooters: {}
    }

    load = param => this.setState({ loading: param })

    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    onChangeFootersHOC = ( key, val ) => this.setState({ [ key ]: val }) 
    
    getFooters = ( template_id ) => Get(
      `${production_url}/templates/${template_id}/template-footers`,
      this.getFootersSuccess,
      this.getFootersError,
      this.load
    )
    getFootersSuccess = payload => {
      let tmp = payload.content
      this.setState({ 
        templateFooters: tmp
      })
    }
    getFootersError = error => this.requestError(error)

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onChangeFootersHOC={ this.onChangeFootersHOC }
          getFooters={ this.getFooters }
          templateFooters={ this.state.templateFooters}
          onLoadFooters={ this.state.loading } />
      )
    }
  }
  return FootersWrappedComponent
}

export default Footers