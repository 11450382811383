import React, { useEffect } from 'react'
import {
  FormGroup,
  Card, CardHeader, CardBody,
  UncontrolledTooltip, Button, Label
 } from 'reactstrap'
 import ReactSelect from 'react-select'
 import _ from 'lodash'

import PageTitle from 'components/PageTitle'
import ARKTable from 'components/Table'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Lang from 'Lang/General'
import EmailSettingsHOC from './actions'
import UpdateEmailSetting from './components/Update'

const EmailQuotaSettings = props => {
  useEffect( () => {
    props.getEmailSettings()
    props.getCenters()
  }, [] )

  return(
    <>
      <PageTitle
        heading={ Lang[ 'EMAIL_SETTING' ][ props.data.languageReducer.lang ] }
        icon="pe-7s-ticket icon-gradient bg-happy-fisher"
        buttons={[]} />
      <Card className="mb-3">
        <CardHeader>{ Lang[ 'SEARCH' ][ props.data.languageReducer.lang ] }</CardHeader>
        <CardBody>
          <FormGroup className='d-flex flex-row flex-nowrap align-items-center' >
            <Label className="mb-0 mr-2" >{ Lang[ 'CENTER' ][ props.data.languageReducer.lang ] }</Label>
            <ReactSelect
              name="centers"
              options={ props.centers }
              className="basic-multi-select mr-4 w-100"
              classNamePrefix="select"
              placeholder={ Lang[ 'SELECT_PLACEHOLDER' ][ props.data.languageReducer.lang ] }
              onChange={ ( value ) => {
                let tmp = _.cloneDeep( props.searchParams )
                tmp.center = value
                return props.onChangeEmailSettingsHOC( 'searchParams', tmp )
              }}
              value={ props.searchParams.center }/>
            <Button
              color='primary'
              className='mr-2'
              onClick={ () => props.getEmailSettings() }>
              { Lang[ 'SEARCH' ][ props.data.languageReducer.lang ] }
            </Button>
            <Button
              color='danger'
              className='mr-2'
              onClick={ () => props.getEmailSettings( 1, true ) }>
              { Lang[ 'RESET' ][ props.data.languageReducer.lang ] }
            </Button>
          </FormGroup>
        </CardBody>
      </Card>
      <ARKTable
        data={ props.emailSettings.data }
        columns={[
          {
            Header: Lang[ 'DOMAIN_NAME' ][ props.data.languageReducer.lang ],
            accessor: 'site_domain',
          },
          {
            Header: Lang[ 'CENTER' ][ props.data.languageReducer.lang ],
            accessor: 'center_name'
          },
          {
            Header: Lang[ 'ACTION' ][ props.data.languageReducer.lang ],
            width: 200,
            accessor: 'id',
            Cell: row => (
              <div className='w-100 d-flex flex-row justify-content-center' >
                <Button
                  id={ `Emailsettings-${ row.original.id }-edit` }
                  className="btn-icon btn-icon-only"
                  color="primary"
                  onClick={ () => props.getSelectedEmailSetting( row.original.center_id ) }>
                  <i className="pe-7s-note" />
                </Button>
                <UncontrolledTooltip
                  target={ `Emailsettings-${ row.original.id }-edit` }
                  placement="top">{ Lang[ 'EDIT' ][ props.data.languageReducer.lang ] }
                </UncontrolledTooltip>
              </div>
            )
          }
        ]}
        page={ props.emailSettings.meta ? props.emailSettings.meta.current_page : 1 }
        totalPages={ props.emailSettingsPages }
        onChangePage={ page => props.getEmailSettings( page ) }
        showPagination={ false }
      />
      <UpdateEmailSetting { ...props } />
      { props.onLoadEmailSettingsHOC && <LoadingOverlay /> }
    </>
  )
}

export default EmailSettingsHOC( EmailQuotaSettings )
