import React, { Component } from 'react'

import CoreRenderFunction from '../../core/render'

class GalleryMainPage extends Component {
  render = () => {
    return (
      <CoreRenderFunction
        { ...this.props }
        item={ this.props.item.children[ 0 ] } />
    )
  }
}

export default GalleryMainPage