import React from 'react'
import {
  Modal, ModalBody, ModalHeader, ModalFooter, Button, Label, Input, Form, FormGroup, FormFeedback
} from 'reactstrap'
import _ from 'lodash'
import Lang from 'Lang/General'

const CreateForm = ({
  newEmailData,
  onChangeSytemEmailHOC,
  createSystemEmail,
  showCreate,
  languageReducer,
  errorMessage
}) => {
  const onChangeData = ( key, value ) => {
    let tmp = _.cloneDeep( newEmailData )
    tmp[ key ] = value
    return onChangeSytemEmailHOC( 'newEmailData', tmp )
  }
  return(
    <Modal isOpen={ showCreate } toggle={ () => onChangeSytemEmailHOC( 'showCreate', false )}>
      <ModalHeader toggle={ () => onChangeSytemEmailHOC( 'showCreate', false )}>{ Lang[ 'CREATE_EMAIL' ][ languageReducer.lang ] }</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>{ Lang[ 'EMAIL' ][ languageReducer.lang ] }</Label>
            <Input
              type="email"
              value={ newEmailData.email }
              onChange={ e => {
                let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i )

                if( !pattern.test( e.target.value ) ){
                  errorMessage["email"] = "Please enter valid email address."
                } else {
                  errorMessage["email"] = ""
                }
                onChangeData( 'email', e.target.value )
              }}
              required
              invalid={ errorMessage && errorMessage.email ? true : false }/>
            <FormFeedback>{ errorMessage && errorMessage.email ? errorMessage.email : '' }</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>{ Lang[ 'NAME' ][ languageReducer.lang ] }</Label>
            <Input
              type="text"
              value={ newEmailData.name }
              onChange={ e => onChangeData( 'name', e.target.value ) }
              required/>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button className="btn float-right" color="primary" onClick={ () => createSystemEmail( newEmailData ) }>{ Lang[ 'SUBMIT' ][ languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )
}

export default CreateForm
