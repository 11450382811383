import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Post, Get, Put, Delete } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state={ 
      loading: false,
      tips: {
        data: [],
        meta: {}
      },
      tipsPages: [],
      specialTips: {
        data: [],
        meta: {}
      },
      specialTipsPages: [],
      newTip: {},
      selectedTip: {},
      selectedTipFiles: [],
      showCreateTip: false,
      showUpdateTip: false,
      showUpdateEmailTemplate: false,
      showDeleteTipId: 0,
      currentTab: 0,
      tipsEmailTemplate: {}
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( err => toast.error( err.replaceAll( '_', ' ' ) ) )

    onChangeTipsHOC = ( key, value ) => this.setState({ [ key ] : value })

    createTip = data => Post(
      `/api/v1/ark_member_area/am_tip_of_the_weeks`,
      data,
      () => this.createTipSuccess( data ),
      this.createTipError,
      this.load
    )
    createTipSuccess = data => {
      this.getTips(
        { 
          page: 1, 
          is_paginated: true,
          filter: { has_categories: data.category_ids.length > 0 },
          sort: data.category_ids.length > 0 ? [ '+injoy_week_number' ] : [ '+week_number' ]
        },
        data.category_ids.length > 0
      )
      this.requestSuccess( Lang[ 'TIP_IS_SUCCESSFULLY_CREATED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showCreateTip: false })
    }
    createTipError = error => {
      this.requestError( error )
      this.setState({ showCreateTip: false })
    }

    getTips = ( query, hasCategories ) => Get(
      `/api/v1/ark_member_area/am_tip_of_the_weeks?query=${ convertObjToBase64( query ) }`,
      payload => this.getTipsSuccess( payload, hasCategories ),
      this.getTipsError,
      this.load,
      this.props.data.languageReducer.lang
    )
    getTipsSuccess = ( payload, hasCategories ) => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
     hasCategories
      ? this.setState({ specialTips: payload, specialTipsPages: tmpTotalPages, currentTab: 1 })
      : this.setState({ tips: payload, tipsPages: tmpTotalPages, currentTab: 0 })
    }
    getTipsError = error => this.requestError( error )

    getSelectedTip = tip_id => Get(
      `/api/v1/ark_member_area/am_tip_of_the_weeks/${ tip_id }`,
      this.getSelectedTipSuccess,
      this.getSelectedTipError,
      this.load
    )
    getSelectedTipSuccess = payload => this.setState({ 
      selectedTip: {
        ...payload,
        center_groups: payload.center_groups.map( centerGroup => ({
          ...centerGroup,
          label: centerGroup.name,
          value: centerGroup.name
        }))
      }, 
      showUpdateTip: true
    })
    getSelectedTipError = error => this.requestError( error )

    updateTip = data => Put(
      `/api/v1/ark_member_area/am_tip_of_the_weeks/${ data.id }`,
      data,
      () => this.updateTipSuccess( data ),
      this.updateTipError,
      this.load
    )
    updateTipSuccess = async data => {
      await this.getTips(
        { 
          page: 1, 
          is_paginated: true,
          filter: { has_categories: true },
          sort: [ '+injoy_week_number' ]
        },
        true
      )
      await this.getTips(
        { 
          page: 1, 
          is_paginated: true,
          filter: { has_categories: false },
          sort: [ '+week_number' ]
        },
        false
      )
      this.requestSuccess( Lang[ 'TIP_IS_SUCCESSFULLY_UPDATED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showUpdateTip: false, currentTab: data.category_ids.length > 0 ? 1 : 0 })
    }
    updateTipError = error => this.requestError( error )
    
    removeTip = tip_id => Delete(
      `/api/v1/ark_member_area/am_tip_of_the_weeks/${ tip_id }`,
      this.removeTipSuccess,
      this.removeTipError,
      this.load
    )
    removeTipSuccess = async() => {
      let tmpTab = _.cloneDeep( this.state.currentTab )
      await this.getTips(
        { 
          page: 1, 
          is_paginated: true,
          filter: { has_categories: true },
          sort: [ '+injoy_week_number' ]
        },
        true
      )
      await this.getTips(
        { 
          page: 1, 
          is_paginated: true,
          filter: { has_categories: false },
          sort: [ '+week_number' ]
        },
        false
      )
      this.requestSuccess( Lang[ 'TIP_IS_SUCCESSFULLY_REMOVED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showDeleteTipId: 0, currentTab: tmpTab })
    }
    removeTipError = error => {
      this.requestError( error )
      this.setState({ showDeleteTipId: 0 })
    }
      
    createSelectedTipFile = ( tip_id, data ) => Post(
      `/api/v1/ark_member_area/am_tip_of_the_weeks/${ tip_id }/files`,
      data,
      () => this.createSelectedTipFileSuccess( tip_id ),
      this.createSelectedTipFileError,
      this.load
    )
    createSelectedTipFileSuccess = tip_id => {
      this.requestSuccess( Lang[ 'FILE_IS_SUCCESSFULLY_CREATED' ][ this.props.data.languageReducer.lang ] )
      this.getSelectedTipFiles( tip_id )
    }
    createSelectedTipFileError = error => this.requestError( error )

    getSelectedTipFiles = tip_id => Get(
      `/api/v1/ark_member_area/am_tip_of_the_weeks/${ tip_id }/files`,
      this.getSelectedTipFilesSuccess,
      this.getSelectedTipFilesError,
      this.load
    )
    getSelectedTipFilesSuccess = payload => this.setState({ selectedTipFiles: payload })
    getSelectedTipFilesError = error => this.requestError( error )

    removeSelectedTipFile = ( tip_id, file_id ) => Delete(
      `/api/v1/ark_member_area/am_tip_of_the_weeks/${ tip_id }/files/${ file_id }`,
      () => this.removeSelectedTipFileSuccess( tip_id ),
      this.removeSelectedTipFileError,
      this.load
    )
    removeSelectedTipFileSuccess = tip_id => {
      this.requestSuccess( Lang[ 'FILE_IS_SUCCESSFULLY_REMOVED' ][ this.props.data.languageReducer.lang ] )
      this.getSelectedTipFiles( tip_id )
    }
    removeSelectedTipFileError = error => this.requestError( error )

    getEmailTemplate = () => Get(
      `/api/v1/ark_member_area/tip_of_the_week_email_templates`,
      this.getEmailTemplateSuccess,
      this.getEmailTemplateError,
      this.load
    )
    getEmailTemplateSuccess = payload => this.setState({ 
      tipsEmailTemplate: payload, 
      showUpdateEmailTemplate: true 
    })
    getEmailTemplateError = error => this.requestError( error )

    updateEmailTemplate = data => Put(
      `/api/v1/ark_member_area/tip_of_the_week_email_templates`,
      data,
      this.updateEmailTemplateSuccess,
      this.updateEmailTemplateError,
      this.load
    )
    updateEmailTemplateSuccess = () => {
      this.requestSuccess( Lang[ 'EMAIL_SETTING_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showUpdateEmailTemplate: false })
    }
    updateEmailTemplateError = error => this.requestError( error )

    render = () => {
      return(
        <WrappedComponent
          { ...this.props } 
          onLoadTipsHOC={ this.state.loading }
          tips={ this.state.tips }
          tipsPages={ this.state.tipsPages }
          specialTips={ this.state.specialTips }
          specialTipsPages={ this.state.specialTipsPages }
          newTip={ this.state.newTip }
          selectedTip={ this.state.selectedTip }
          selectedTipFiles={ this.state.selectedTipFiles }
          showCreateTip={ this.state.showCreateTip }
          showUpdateTip={ this.state.showUpdateTip }
          showUpdateEmailTemplate={ this.state.showUpdateEmailTemplate }
          showDeleteTipId={ this.state.showDeleteTipId }
          currentTab={ this.state.currentTab }
          tipsEmailTemplate={ this.state.tipsEmailTemplate }
          onChangeTipsHOC={ this.onChangeTipsHOC }
          createTip={ this.createTip }
          getTips={ this.getTips }
          getSelectedTip={ this.getSelectedTip }
          updateTip={ this.updateTip }
          removeTip={ this.removeTip }
          getEmailTemplate={ this.getEmailTemplate }
          updateEmailTemplate={ this.updateEmailTemplate }
          createSelectedTipFile={ this.createSelectedTipFile }
          getSelectedTipFiles={ this.getSelectedTipFiles }
          removeSelectedTipFile={ this.removeSelectedTipFile }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC