import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Lang from 'Lang/General'

const DeleteConfirmation = ({
  handleModalClose,
  confirmAction,
  open,
  content,
  lang
}) => {
  return (
    <span className="d-inline-block mb-2 mr-2">
      <Modal isOpen={ open } toggle={ handleModalClose }>
        <ModalHeader>{ Lang[ 'CONFIRMATION' ][ lang ] }</ModalHeader>
        <ModalBody>{ content }</ModalBody>
        <ModalFooter>
          <Button color="link" onClick={ handleModalClose }>{ Lang[ 'NO' ][ lang ] }</Button>
          <Button color="primary" onClick={ confirmAction }>{ Lang[ 'YES' ][ lang ] }</Button>
        </ModalFooter>
      </Modal>
    </span>
  )
}

export default DeleteConfirmation
