import React from 'react'
import { 
  Button, Table,
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap'
import ReactHTMLParser from 'react-html-parser'

import Lang from 'Lang/General'


const ViewCampaign = ( props ) => {
  return (
    <Modal 
      size={ 'lg' } 
      isOpen={ props.showViewCampaign }>
      <ModalHeader toggle={ () => { props.onChangeCampaignListingHOC( 'showViewCampaign', false ) } }>
        { Lang[ 'VIEW_CAMPAIGN' ][ props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        <Table bordered>
          <tbody>
            <tr>
              <th scope="row" class="col-3" >{ Lang[ 'NAME' ][ props.data.languageReducer.lang ] }</th>
              <td>{ props.selectedCampaign.title }</td>
            </tr>
            <tr>
              <th scope="row" class="col-3" >{ Lang[ 'DESCRIPTION' ][ props.data.languageReducer.lang ] }</th>
              <td>{ ReactHTMLParser( props.selectedCampaign.description )}</td>
            </tr>
            <tr>
              <th scope="row">{ Lang[ 'INFO_IMAGE' ][ props.data.languageReducer.lang ] }</th>
              <td>
                {
                  props.selectedCampaign.info_image_url !== '' 
                  ? <img src={ props.selectedCampaign.info_image_url } style={{ width: '30%', minWidth: '150px', maxWidth: '100%' }} />
                  : '-'
                }
              </td>
            </tr>
            <tr>
              <th scope="row" class="col-3" >{ Lang[ 'PREVIEW_TEXT' ][ props.data.languageReducer.lang ] }</th>
              <td>{ props.selectedCampaign.preview_text[ props.data.languageReducer.lang ] }</td>
            </tr>
            <tr>
              <th scope="row">{ Lang[ 'PREVIEW_IMAGE' ][ props.data.languageReducer.lang ] }</th>
              <td>
                {
                  props.selectedCampaign.preview_image_url !== '' 
                  ? <img src={ props.selectedCampaign.preview_image_url } style={{ width: '30%', minWidth: '150px', maxWidth: '100%' }} />
                  : '-'
                }
              </td>
            </tr>
            <tr>
              <th scope="row">{ Lang[ 'IS_NEW' ][ props.data.languageReducer.lang ] }</th>
              <td>{ props.selectedCampaign.status === 'ACTIVE' ? 'YES': 'NO' }</td>
            </tr>
            <tr>
              <th scope="row">{ Lang[ 'VIEW_CAMPAIGN_IN_THE_PUBLIC_SECTION' ][ props.data.languageReducer.lang ] }</th>
              <td>{ props.selectedCampaign.display_to_one_timer ? 'YES': 'NO' }</td>
            </tr>
            <tr>
              {
                props.selectedCampaign.is_center_based 
                ? <>
                    <th scope="row">{ Lang[ 'IS_CREATED_BY_CENTER_ADMIN' ][ props.data.languageReducer.lang ] }</th>
                    <td>{ props.selectedCampaign.is_center_based ? 'YES': 'NO' }</td>
                  </>
                : <>
                    <th scope="row">{ Lang[ 'APP_CAMPAIGN' ][ props.data.languageReducer.lang ] }</th>
                    <td>
                      { 
                        props.selectedCampaign.is_app_campaign 
                        ? <>
                            <Table bordered striped>
                              <tbody>
                                <tr>
                                  <th scope="row">{ Lang[ 'THREE_SIX_ZERO_TITLE' ][ props.data.languageReducer.lang ] }</th>
                                  <td>{ props.selectedCampaign[ '360_app_campaign' ][ 'title' ] }</td>
                                </tr>
                                <tr>
                                  <th scope="row">{ Lang[ 'THREE_SIX_ZERO_DESC' ][ props.data.languageReducer.lang ] }</th>
                                  <td>{ props.selectedCampaign[ '360_app_campaign' ][ 'description' ] }</td>
                                </tr>
                                <tr>
                                  <th scope="row">{ Lang[ 'THREE_SIX_ZERO_CLAIM' ][ props.data.languageReducer.lang ] }</th>
                                  <td>{ props.selectedCampaign[ '360_app_campaign' ][ 'claim' ] }</td>
                                </tr>
                                <tr>
                                  <th scope="row">{ Lang[ 'IS_STANDARD_APP_ACISO' ][ props.data.languageReducer.lang ] }</th>
                                  <td>{ props.selectedCampaign[ '360_app_campaign' ][ 'is_standard_app_campaign_for_aciso' ] ? 'YES': 'NO' }</td>
                                </tr>
                                <tr>
                                  <th scope="row">{ Lang[ 'IS_STANDARD_APP_INJOY' ][ props.data.languageReducer.lang ] }</th>
                                  <td>{ props.selectedCampaign[ '360_app_campaign' ][ 'is_standard_app_campaign_for_injoy' ] ? 'YES': 'NO' }</td>
                                </tr>
                                <tr>
                                  <th scope="row">{ Lang[ 'THREE_SIX_ZERO_STANDARD_IMAGE' ][ props.data.languageReducer.lang ] }</th>
                                  <td>
                                    {
                                      props.selectedCampaign[ '360_app_campaign' ][ 'image_url' ] !== '' 
                                      ? <img src={ props.selectedCampaign[ '360_app_campaign' ][ 'image_url' ] } style={{ width: '30%', minWidth: '150px', maxWidth: '100%' }} />
                                      : '-'
                                    }
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </>
                        : 'NO' 
                      }
                    </td>
                  </>
              }
            </tr>
            <tr>
              <th scope="row">{ Lang[ 'BOOKABLE_MONTHS' ][ props.data.languageReducer.lang ] }</th>
              <td>
                {
                  props.selectedCampaign.bookable_months.length === 12 
                  ? <p>{ Lang[ 'ALL_MONTHS' ][ props.data.languageReducer.lang ] }</p>
                  : props.selectedCampaign.bookable_months.map( (month, index) => {
                      return <li key={ index }>{ month }</li>
                    })
                }
              </td>
            </tr>
            <tr>
              <th scope="row">{ Lang[ 'CAMPAIGN_TYPES' ][ props.data.languageReducer.lang ] }</th>
              <td>
                {
                  props.selectedCampaign.types.map( ( campaignType, index ) => {
                    return <span key={ index }>{ campaignType.title } {' '} </span> 
                  })
                }
              </td>
            </tr>
            <tr>
              <th scope="row">{ Lang[ 'TARGET_CENTER_GROUPS' ][ props.data.languageReducer.lang ] }</th>
              <td>
                {
                  props.selectedCampaign.target_center_groups.map( ( group, index ) => {
                    return <li key={ index }>{ group.name }</li>
                  })
                }
              </td>
            </tr>
            <tr>
              <th scope="row">{ Lang[ 'CAMPAIGN_CAN_BE_BOOKED_IN' ][ props.data.languageReducer.lang ] }</th>
              <td>
                {
                  props.selectedCampaign.bookable_countries.map( ( country, index ) => {
                    return <li key={ index }>{ country }</li>
                  })
                }
              </td>
            </tr>
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={() => {
            props.onChangeCampaignListingHOC( 'showViewCampaign', false )
          }}>{ Lang[ 'CLOSE' ][ props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ViewCampaign