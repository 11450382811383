import React, { useState } from 'react'
import _ from 'lodash'
import { Button } from 'reactstrap'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'

const langList = [ 'en', 'de', 'nl', 'it' ]

const CKEditor5 = ( props ) => {
  const [ buttonLang, updateButtonLang ] = useState( props.selectedLanguage )
  return (
    <>
      <style>{ '.ck-list { overflow: scroll; max-height: 50vh }' }</style>
      <div className="ckrte">
        {
          langList.map( item => {
            if( buttonLang === item ) {
              return (
                <CKEditor
                  config={{
                    toolbar: [ 
                      "undo", "redo", "|", 'heading', 'bold', 'underline', 'italic', "|", 'link', "|", 'insertTable', "|", 
                      'bulletedList', 'numberedList', 'indent', 'outdent', 
                      'blockQuote', 'fontFamily', 'fontColor', 'fontBackgroundColor',
                      'fontSize', 'alignment'
                    ],
                    link: {
                      decorators: {
                        openInNewTab: {
                          mode: 'manual',
                          label: 'Open in a new tab',
                          attributes: {
                            target: '_blank',
                            rel: 'noopener noreferrer'
                          }
                        }
                      }
                    },
                    heading: {
                      options: [
                        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                        { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        { model: 'span', view: 'div', title: 'Span' }
                      ]
                    },
                    fontSize: {
                      options: _.filter( Array.from( Array( 49 ).keys() ), item => item >= 8 )
                    },
                    fontFamily: {
                      supportAllValues: true
                    },
                    table: {
                      contentToolbar: [
                        'tableColumn',
                        'tableRow',
                        'mergeTableCells',
                        'tableCellProperties',
                        'tableProperties'
                      ]
                    },
                    alignment: {
                      options: [ 'left', 'center', 'right' ]
                    },
                    language: props.selectedLanguage ? props.selectedLanguage : 'en',
                    startupFocus: true
                  }}
                  editor={ Editor.ClassicEditor }
                  data={ props.data[ item ] }
                  onChange={ ( event, editor ) => {
                    let tmp = _.cloneDeep( props.data )
                    tmp[ item ] = editor.getData()
                    props.onChange( tmp )
                  }}
                />
              )
            }
          })
        }
        <div>
        {
          langList.map( item => {
            return (
              <Button 
                key={ item }
                color={ buttonLang === item ? "success" : "primary" }
                className='m-0'
                onClick={() => {
                  updateButtonLang( item )
                }}>
                { item }
              </Button>
            )
          })
        }
        </div>
      </div>
    </>
  )
}
export default CKEditor5