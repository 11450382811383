import React, { Component } from 'react'
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip
} from 'reactstrap'
import { compose } from 'redux'
import _ from 'lodash'
import ReactTable from 'react-table'

import PageTitle from 'components/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'
import CampaignToolsHOC from './actions'
import CenterGroupHOC from './actions/centerGroup'
import CountriesHOC from './actions/country'
import CampaignToolsForm from './components/form'
import DeleteConfirmationDialog from 'components/Modal/delete'

class CampaignToolsListings extends Component {
  state = {
    tmpCenterGroups: []
  }

  componentDidMount = () => {
    this.props.getCenterGroups()
    this.props.getCountries()
    this.props.getCampaignTools()
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Online Shop' })
      return tmpModules.is_create
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Online Shop' })
      return tmpModules.is_update
    }
  }

  handleSecurityModulesDelete = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Online Shop' })
      return tmpModules.is_delete
    }
  }

  renderCreateForm = () => (
    <Modal isOpen={ this.props.showCreateCampaignTool } size='xl' style={{ maxWidth: '90vw' }}>
      <ModalHeader toggle={ () => this.props.onChangeCampaignToolsHOC( 'showCreateCampaignTool', false ) }>
        { Lang[ 'CREATE_CAMPAIGN_TOOL' ][ this.props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        <CampaignToolsForm
          centerGroups={ this.props.centerGroups }
          onChangeCampaignToolsHOC={ this.props.onChangeCampaignToolsHOC }
          campaignToolData={ this.props.newCampaignTool }
          campaignToolType={ 'newCampaignTool' }
          countries={ this.props.countries }
          statusValues={ this.props.data.dictionaryReducer.dictionary.status }
          errorMessage={ this.props.errorMessage }/>
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => {
            let tmp = []
            this.props.newCampaignTool.countries.map( item => {
              tmp.push( item.label )
            })
            this.props.createCampaignTool({
              ...this.props.newCampaignTool,
              countries: tmp
            })
          }}>
          { Lang[ 'SUBMIT' ][ this.props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )

  renderUpdateForm = () => (
    <Modal isOpen={ this.props.showUpdateCampaignTool } size='xl' style={{ maxWidth: '90vw' }}>
      <ModalHeader toggle={ () => this.props.onChangeCampaignToolsHOC( 'showUpdateCampaignTool', false ) }>
        { Lang[ 'UPDATE_CAMPAIGN_TOOL' ][ this.props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        <CampaignToolsForm
          centerGroups={ this.props.centerGroups }
          onChangeCampaignToolsHOC={ this.props.onChangeCampaignToolsHOC }
          campaignToolData={ this.props.selectedCampaignTool }
          campaignToolType={ 'selectedCampaignTool' }
          countries={ this.props.countries }
          statusValues={ this.props.data.dictionaryReducer.dictionary.status }
          errorMessage={ this.props.errorMessage }/>
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => {
            let tmp = []
            this.props.selectedCampaignTool.countries.map( item => {
              tmp.push( item.label )
            })
            this.props.updateCampaignTool( this.props.selectedCampaignTool.id, {
              ...this.props.selectedCampaignTool,
              countries: tmp
            })
          }} >
          { Lang[ 'SUBMIT' ][ this.props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )

  render = () => {
    return(
      <>
        <PageTitle
          heading={ Lang[ 'CAMPAIGN_TOOL_LISTING' ][ this.props.data.languageReducer.lang ] }
          icon="pe-7s-tools icon-gradient bg-happy-fisher"
          buttons={[
            {
              buttonText: Lang[ 'CREATE' ][ this.props.data.languageReducer.lang ],
              onClick: () => {
                Promise.all([
                  this.props.onChangeCampaignToolsHOC( 'showCreateCampaignTool', true )
                ]).then(
                  () => this.props.onChangeCampaignToolsHOC( 'errorMessage', {} )
                )
              },
              display: this.handleSecurityModulesCreate()
            }
        ]}/>
        <Card>
          <CardBody>
            <ReactTable
              pageSize={ 20 }
              NoDataComponent={ () => <span></span> }
              showPagination={ true }
              data={ this.props.campaignTools }
              columns={[
                {
                  Header: Lang[ 'TITLE' ][ this.props.data.languageReducer.lang ],
                  accessor: 'title'
                },
                {
                  Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
                  accessor: 'id',
                  Cell: ( row ) => (
                    <div className="d-flex flex-row align-items-center justify-content-center w-100">
                      {
                        this.handleSecurityModulesUpdate() && (
                          <>
                            <Button
                              id={ `Campaign-tools-${ row.original.id }-update-campaign-tool` }
                              className="mb-2 mr-2 btn-icon btn-icon-only"
                              color='primary'
                              onClick={ () => {
                                Promise.all([
                                  this.props.onChangeCampaignToolsHOC( 'showUpdateCampaignTool', true )
                                ]).then(
                                  () => this.props.onChangeCampaignToolsHOC( 'errorMessage', {} )
                                )
                                this.props.getSelectedCampaignTool( row.original.id )
                              } } >
                              <i className="pe-7s-pen btn-icon-wrapper" />
                            </Button>
                            <UncontrolledTooltip
                              target={ `Campaign-tools-${ row.original.id }-update-campaign-tool` }
                              placement="top">{ Lang[ 'UPDATE_CAMPAIGN_TOOL_SEN_CASE' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                          </>
                        )
                      }
                      {
                        this.handleSecurityModulesDelete() && (
                          <>
                            <Button
                              id={ `Campaign-tools-${ row.original.id }-delete-campaign-tool` }
                              className="mb-2 mr-2 btn-icon btn-icon-only"
                              color='danger'
                              onClick={ () => {
                                this.props.onChangeCampaignToolsHOC( 'showDeleteCampaignTool', true )
                                this.props.getSelectedCampaignTool( row.original.id )
                              }}>
                              <i className="pe-7s-trash btn-icon-wrapper" />
                            </Button>
                            <UncontrolledTooltip
                              target={ `Campaign-tools-${ row.original.id }-delete-campaign-tool` }
                              placement="top">{ Lang[ 'DELETE_CAMPAIGN_TOOL' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                          </>
                        )
                      }
                    </div>
                  )
                }
              ]}/>
          </CardBody>
          {
            this.props.showDeleteCampaignTool && (
              <DeleteConfirmationDialog
                deleteOpen={ this.props.showDeleteCampaignTool }
                handleModalClose={ () => this.props.onChangeCampaignToolsHOC( 'showDeleteCampaignTool', false ) }
                confirmAction={ () => {
                  if( this.props.selectedCampaignTool.id ){
                    this.props.removeCampaignTool( this.props.selectedCampaignTool.id, { status: 'ARCHIVED' } )
                  }
                  this.props.onChangeCampaignToolsHOC( 'showDeleteCampaignTool', false )
                }}
                content={ Lang[ 'DELETE_CONFIRMATION_CAMPAIGN_TOOL' ][ this.props.data.languageReducer.lang ] }
                lang={ this.props.data.languageReducer.lang }/>
            )
          }
        </Card>
        { this.renderCreateForm() }
        { this.renderUpdateForm() }
        { (this.props.data.ajaxReducer.ajaxCallProgress > 0 || this.props.onLoadCampaignTools ) && <LoadingOverlay/> }
      </>
    )
  }
}

export default compose(
  CampaignToolsHOC,
  CenterGroupHOC,
  CountriesHOC,
)( CampaignToolsListings )
