import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      components: [],
      selectedTempComponent: {},
      showSelectButtonModal: false
    }

    load = ( param ) => this.setState({ loading: param })
    requestSuccess = ( param ) => toast.success( param )
    requestError = ( param ) => toast.error( param )

    onChangeTempComponent = ( key, val ) => this.setState({ [ key ]: val })

    getComponents = ( query ) => Get(
      `/api/v1/shared_components?query=${ query }`,
      this.getComponentsSuccess,
      this.getComponentsError,
      this.load
    )
    getComponentsSuccess = ( payload ) => this.setState({ components: payload.data })
    getComponentsError = ( error ) => this.requestError( error )

    getSelectedComponent = ( id ) => Get(
      `/api/v1/shared_components/${ id }`,
      this.getSelectedComponentSuccess,
      this.getSelectedComponentError,
      this.load
    )
    getSelectedComponentSuccess = ( payload ) => {}
    getSelectedComponentError = ( error ) => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onChangeTempComponent={ this.onChangeTempComponent }
          getComponents={ this.getComponents }
          onLoadComponents={ this.state.loading }
          components={ this.state.components }
          selectedTempComponent={ this.state.selectedTempComponent }
          showSelectButtonModal={ this.state.showSelectButtonModal } />
      )
    }
  }
  return WithHOC
}

export default HOC