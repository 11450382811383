import React, { useState } from 'react'
import { SketchPicker } from 'react-color'
import {
  Form, FormGroup,
  Label, Col, Input, Button
} from "reactstrap"
import _ from 'lodash'

import Lang from 'Lang/General'

const CreateForm = ({
  language,
  newStatusData,
  onChangeRequestStatusHOC
}) => {
  const [ selectedLanguage, updateSelectedLanguage ] = useState( 'en' )
  const arrayButton = [ 'de', 'en', 'it', 'nl' ]

  return(
    <Form onSubmit={ e => e.preventDefault() }>
      <FormGroup row>
        <Label sm={ 2 }>{ Lang[ 'NAME' ][ language ] }</Label>
        <Col sm={ 10 }>
          <div className="d-flex pt-1 mb-2">
            {
              arrayButton.map( button => {
                return(
                  <Button
                    className="btn-square btn-wide" size="sm"
                    color={ `${ selectedLanguage === button ? 'primary' : 'light' }` }
                    onClick={ () => updateSelectedLanguage( button ) }>{ button }</Button>
                )
              })
            }
          </div>
          <Input
            type="text"
            value={ newStatusData.name[ selectedLanguage ] }
            onChange={ e => {
              let tmp = _.cloneDeep( newStatusData )
              tmp[ 'name' ][ selectedLanguage ] = e.target.value
              onChangeRequestStatusHOC( 'newStatusData', tmp )
            }} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={ 2 }>{ Lang[ 'COLOR' ][ language ] }</Label>
        <Col sm={ 10 }>
          <SketchPicker
            color={ newStatusData.color }
            onChange={ (color, event ) => {
              let tmp = _.cloneDeep( newStatusData )
              tmp[ 'color' ] = color.hex
              onChangeRequestStatusHOC( 'newStatusData', tmp )
            }}/>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={ 2 }>{ Lang[ 'POSITION' ][ language ] }</Label>
        <Col sm={ 10 }>
          <Input
            type="number"
            value={ newStatusData.position }
            onChange={e => {
              let tmp = _.cloneDeep( newStatusData )
              tmp[ 'position' ] = parseInt( e.target.value )
              onChangeRequestStatusHOC( 'newStatusData', tmp )
            }}/>
        </Col>
      </FormGroup>
    </Form>
  )
}
export default CreateForm
