import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get, Post, Put, Delete } from 'utils/axios'

import Lang from 'Lang/General'

const FaqsHOC = ( WrappedComponent ) => {
  class FaqsWrappedComponent extends Component {
    state = {
      loading: false,
      showCreateFaq: false,
      showUpdateFaq: false,
      faqs: {
        meta: {},
        data: []
      },
      selectedFaq: {
        title: {
          en: '',
          de: '',
          nl: '',
          it: '',
          fr: ''
        },
        description: {
          en: '',
          de: '',
          nl: '',
          it: '',
          fr: ''
        },
        categories: [],
        youtube_video_id: {
          en: '',
          de: '',
          nl: '',
          it: '',
          fr: ''
        },
      },
      newFaq: {
        title: {
          en: '',
          de: '',
          nl: '',
          it: '',
          fr: ''
        },
        description: {
          en: '',
          de: '',
          nl: '',
          it: '',
          fr: ''
        },
        category_ids: [],
        youtube_video_id: {
          en: '',
          de: '',
          nl: '',
          it: '',
          fr: ''
        },
        status: 'ACTIVE',
        is_view_faqs: false,
        is_show_help: false
      },
      showDeleteFaqListingId: null
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    } )
    requestError = error => toast.error( error, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    } )

    onChangeFaqsHOC = ( key, value ) => this.setState({ [ key ]: value })

    createFaq = data => Post(
      `/api/v1/ark_member_area/am_faq_listings`,
      data,
      this.createFaqSuccess,
      this.createFaqError,
      this.load
    )
    createFaqSuccess = () => {
      this.getFaqs()
      this.requestSuccess( Lang[ 'FAQ_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showCreateFaq: false })
    }
    createFaqError = error => this.requestError( error )

    getFaqs = () => Get(
      `/api/v1/ark_member_area/am_faq_listings`,
      this.getFaqsSuccess,
      this.getFaqsError,
      this.load
    )
    getFaqsSuccess = payload => this.setState({ faqs: payload })
    getFaqsError = error => this.requestError( error )

    getSelectedFaq = faq_id => Get(
      `/api/v1/ark_member_area/am_faq_listings/${ faq_id }`,
      this.getSelectedFaqSuccess,
      this.getSelectedFaqError,
      this.load
    )
    getSelectedFaqSuccess = payload => this.setState({ selectedFaq: payload })
    getSelectedFaqError = error => this.requestError( error )

    updateFaq = ( faq_id, data ) => Put(
      `/api/v1/ark_member_area/am_faq_listings/${ faq_id }`,
      data,
      this.updateFaqSuccess,
      this.updateFaqError,
      this.load
    )
    updateFaqSuccess = () => {
      this.getFaqs()
      this.requestSuccess( Lang[ 'FAQ_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showUpdateFaq: false })
    }
    updateFaqError = error => this.requestError( error )

    removeFaq = faq_id => Delete(
      `/api/v1/ark_member_area/am_faq_listings/${ faq_id }`,
      this.removeFaqSuccess,
      this.removeFaqError,
      this.load
    )
    removeFaqSuccess = () => {
      this.getFaqs()
      this.setState({ showDeleteFaqListingId: null })
      this.requestSuccess( Lang[ 'FAQ_REMOVE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
    }
    removeFaqError = error => this.requestError( error )

    render = () => {
      return(
        <>
          <WrappedComponent
            { ...this.props }
            onLoadFaqs={ this.state.loading }
            onChangeFaqsHOC={ this.onChangeFaqsHOC }
            showCreateFaq={ this.state.showCreateFaq }
            showUpdateFaq={ this.state.showUpdateFaq }
            faqs={ this.state.faqs }
            selectedFaq={ this.state.selectedFaq }
            newFaq={ this.state.newFaq }
            createFaq={ this.createFaq }
            getFaqs={ this.getFaqs }
            getSelectedFaq={ this.getSelectedFaq }
            updateFaq={ this.updateFaq }
            removeFaq={ this.removeFaq }
            showDeleteFaqListingId={ this.state.showDeleteFaqListingId }
          />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( FaqsWrappedComponent )
}

export default FaqsHOC
