import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'

import { Get, Post, Delete, Put } from 'utils/axios-template'
import { production_url } from '../config'

import Lang from 'Lang/General'

const TemplateStylesheetHOC = ( WrappedComponent ) => {
  class TemplateStylesheetWrappedComponent extends Component {
    state = {
      loading: false,
      updateLoading: false,
      showStatusModal: false,
      statusModalType: '',
      onClickStatusModalButton: () => {},
      statusModalMessage: '',
      templateStylesheets: [],
      selectedTemplateStylesheet: {}
    }

    load = param => this.setState({ loading: param })
    updateLoad = param => this.setState({ updateLoading: param })
    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    onChangeTemplateStylesheets = ( key, index, value ) => {
      let tmp = _.cloneDeep( this.state.templateStylesheets )
      tmp[ index ][ key ] = value
      return this.setState({ templateStylesheets: tmp })
    }

    getTemplateStylesheets = ( template_id ) => Get(
      `${ production_url }/templates/${template_id}/stylesheets`,
      this.getTemplateStylesheetsSuccess,
      this.getTemplateStylesheetsError,
      this.load
    )
    getTemplateStylesheetsSuccess = payload => {
      this.setState({ templateStylesheets: payload })
    }
    getTemplateStylesheetsError = error => this.requestError( error )

    createTemplateStylesheet = ( template_id ) => Post(
      `${ production_url }/templates/${template_id}/stylesheets`,
      {
        type: 'URL',
        content: ''
      },
      ( payload ) => this.createTemplateStylesheetSuccess( payload, template_id ),
      this.createTemplateStylesheetError,
      this.updateLoad
    )
    createTemplateStylesheetSuccess = ( payload, template_id ) => {
      this.getTemplateStylesheets( template_id )
      this.requestSuccess( Lang[ 'STYLESHEET_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
    }
    createTemplateStylesheetError = error => this.requestError( error )

    removeTemplateStylesheet = ( template_id, id ) => Delete(
      `${ production_url }/templates/${template_id}/stylesheets/${id}`,
      () => this.removeTemplateStylesheetSuccess( template_id ),
      this.removeTemplateStylesheetError,
      this.updateLoad
    )
    removeTemplateStylesheetSuccess = ( template_id ) => {
      this.getTemplateStylesheets( template_id )
      this.requestSuccess( Lang[ 'STYLESHEET_REMOVE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
    }
    removeTemplateStylesheetError = error => this.requestError( error )

    updateTemplateStylesheet = ( template_id, id, data ) => Put(
      `${ production_url }/templates/${template_id}/stylesheets/${id}`,
      data,
      ( payload ) => this.updateTemplateStylesheetSuccess( payload, template_id ),
      this.updateTemplateStylesheetError,
      this.updateLoad
    )
    updateTemplateStylesheetSuccess = ( payload, template_id ) => {
      this.getTemplateStylesheets( template_id )
      this.requestSuccess( Lang[ 'STYLESHEET_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
    }
    updateTemplateStylesheetError = error => this.requestError( error )

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            { ...this.props }
            createTemplateStylesheet={ this.createTemplateStylesheet }
            getTemplateStylesheets={ this.getTemplateStylesheets }
            removeTemplateStylesheet={ this.removeTemplateStylesheet }
            updateTemplateStylesheet={ this.updateTemplateStylesheet }
            onChangeTemplateStylesheets={ this.onChangeTemplateStylesheets }
            templateStylesheets={ this.state.templateStylesheets }
            selectedTemplateStylesheet={ this.state.selectedTemplateStylesheet }
            onLoadTemplateStylesheet={ this.state.loading }
            onLoadUpdate={ this.state.updateLoading } />
        </Fragment>
      )
    }
  }
  return TemplateStylesheetWrappedComponent
}

export default TemplateStylesheetHOC
