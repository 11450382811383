import Axios from 'axios'
import {
  GET_SELECTED_ROLE,
  CLEAR_SELECTED_ROLE
} from './type'
import {
  beginAjaxCall,
  ajaxCallSuccess,
  ajaxCallError
} from './ajax'
import { getItem } from 'utils/tokenStore'
import getDomainURL from 'utils/api'

export const getSelectedRole = ( id ) => {
  Axios.defaults.headers = {
    common: {
      Authorization: `Bearer ${ getItem( 'PROOFPOINT_ARK_MANAGEMENT' ) }`
    }
  }
  return dispatch => {
    dispatch( beginAjaxCall() )
    Axios.get(
      `${ getDomainURL() }/api/v1/security_roles/${ id }`
    ).then( response => {
      dispatch( getSelectedRoleSuccess( response.data ) )
      dispatch( ajaxCallSuccess() )
    }).catch( error => {
      dispatch( ajaxCallError( error ) )
    })
  }
}

const getSelectedRoleSuccess = payload => {
  return {
    type: GET_SELECTED_ROLE,
    payload
  }
}

export const clearSelectedRole = () => ({
  type: CLEAR_SELECTED_ROLE
})
