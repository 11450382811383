import React, { Component } from 'react'
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip
} from 'reactstrap'
import { compose } from 'redux'
import _ from 'lodash'
import ReactTable from 'react-table'

import PageTitle from 'components/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import DeleteConfirmationDialog from 'components/Modal/delete'

import Lang from 'Lang/General'
import FaqsHOC from './actions'
import FaqCategoriesHOC from '../FaqCategories/actions'
import FaqForm from './components/faqForm'

class FaqListings extends Component {
  componentDidMount = () => {
    this.props.getFaqCategories()
    this.props.getFaqs()
  }

  componentDidUpdate = pp => {
    if( pp.showCreateFaq && !this.props.showCreateFaq ){
      let tmp = {
        title: {
          en: '',
          de: '',
          nl: '',
          it: '',
          fr: ''
        },
        description: {
          en: '',
          de: '',
          nl: '',
          it: '',
          fr: ''
        },
        categories: [],
        youtube_video_id: {
          en: '',
          de: '',
          nl: '',
          it: '',
          fr: ''
        },
        status: 'ACTIVE',
        is_view_faqs: false,
        is_show_help: false
      }
      this.props.onChangeFaqsHOC( 'newFaq', tmp )
    }

    if( pp.showUpdateFaq && !this.props.showUpdateFaq ){
      this.props.onChangeFaqsHOC( 'selectedFaq', {
        title: {
          en: '',
          de: '',
          nl: '',
          it: '',
          fr: ''
        },
        description: {
          en: '',
          de: '',
          nl: '',
          it: '',
          fr: ''
        },
        categories: [],
        youtube_video_id: {
          en: '',
          de: '',
          nl: '',
          it: '',
          fr: ''
        },
        status: 'ACTIVE',
        is_view_faqs: false,
        is_show_help: false
      })
    }

    if( pp.selectedFaq.id !== this.props.selectedFaq.id && !_.isEmpty( this.props.selectedFaq ) ){
      let tmpSelectedFaq = _.cloneDeep( this.props.selectedFaq )
      tmpSelectedFaq.categories = tmpSelectedFaq.categories.map( category => (
        {
          id: category.id,
          label: category.name[ this.props.data.languageReducer.lang ],
          value: category.name[ this.props.data.languageReducer.lang ]
        }
      ))
      this.props.onChangeFaqsHOC( 'selectedFaq', tmpSelectedFaq )
    }
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Online Shop' })
      return tmpModules.is_create
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Online Shop' })
      return tmpModules.is_update
    }
  }

  handleSecurityModulesDelete = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Online Shop' })
      return tmpModules.is_delete
    }
  }

  renderCreateForm = () => (
    <Modal isOpen={ this.props.showCreateFaq } size='xl' >
      <ModalHeader toggle={ () => this.props.onChangeFaqsHOC( 'showCreateFaq', false ) }>
        { Lang[ 'CREATE_FAQ' ][ this.props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        <FaqForm
          faqType='newFaq'
          currentFaq={ this.props.newFaq }
          selectedLanguageShortname={ this.props.data.languageReducer.lang }
          onChangeFaqsHOC={ this.props.onChangeFaqsHOC }
          faqCategories={ this.props.faqCategories } />
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => {
            let tmp = _.cloneDeep( this.props.newFaq )
            tmp.categories = _.filter( this.props.faqCategories, category => _.findIndex( tmp.categories, { id: category.id } ) > -1 )
            this.props.createFaq( tmp ) }}>
          { Lang[ 'SUBMIT' ][ this.props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )

  renderUpdateForm = () => (
    <Modal isOpen={ this.props.showUpdateFaq } size='xl' >
      <ModalHeader toggle={ () => this.props.onChangeFaqsHOC( 'showUpdateFaq', false ) }>
        { Lang[ 'UPDATE_FAQ' ][ this.props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        {
          !_.isEmpty( this.props.selectedFaq ) && <FaqForm
            faqType='selectedFaq'
            currentFaq={ this.props.selectedFaq }
            selectedLanguageShortname={ this.props.data.languageReducer.lang }
            onChangeFaqsHOC={ this.props.onChangeFaqsHOC }
            faqCategories={ this.props.faqCategories } />
        }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => {
            let tmp = _.cloneDeep( this.props.selectedFaq )
            tmp.categories = _.filter( this.props.faqCategories, category => _.findIndex( tmp.categories, { id: category.id } ) > -1 )
            this.props.updateFaq( this.props.selectedFaq.id, this.props.selectedFaq ) }} >
          { Lang[ 'SUBMIT' ][ this.props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )

  render = () => {
    return(
      <>
        <PageTitle
          heading={ Lang[ 'FAQ_LISTING' ][ this.props.data.languageReducer.lang ] }
          icon="pe-7s-note2 icon-gradient bg-happy-fisher"
          buttons={[
            {
              buttonText: Lang[ 'CREATE' ][ this.props.data.languageReducer.lang ],
              onClick: () => this.props.onChangeFaqsHOC( 'showCreateFaq', true ),
              display: this.handleSecurityModulesCreate()
            }
        ]}/>
        <Card>
          <CardBody>
            <ReactTable
              pageSize={ 20 }
              NoDataComponent={ () => <span></span> }
              showPagination={ true }
              data={ this.props.faqs }
              columns={[
                {
                  Header: Lang[ 'QUESTION' ][ this.props.data.languageReducer.lang ],
                  accessor: 'title',
                  Cell: ( row ) => row.original.title[ this.props.data.languageReducer.lang ]
                },
                {
                  Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
                  accessor: 'id',
                  Cell: ( row ) => (
                    <div className="d-flex flex-row align-items-center justify-content-center w-100">
                      {
                        this.handleSecurityModulesUpdate() && (
                          <>
                            <Button
                              id={ `Faq-listings-${ row.original.id }-update-faq` }
                              className="mb-2 mr-2 btn-icon btn-icon-only"
                              color='primary'
                              onClick={ () => {
                                this.props.onChangeFaqsHOC( 'showUpdateFaq', true )
                                this.props.getSelectedFaq( row.original.id )
                              }} >
                              <i className="pe-7s-pen btn-icon-wrapper" />
                            </Button>
                            <UncontrolledTooltip
                              target={ `Faq-listings-${ row.original.id }-update-faq` }
                              placement="top">{ Lang[ 'UPDATE_FAQ' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                          </>
                        )
                      }
                      {
                        this.handleSecurityModulesDelete() && (
                          <>
                            <Button
                              id={ `Faq-listings-${ row.original.id }-remove-faq` }
                              className="mb-2 mr-2 btn-icon btn-icon-only"
                              color='danger'
                              onClick={ () => {
                                this.props.getSelectedFaq( row.original.id )
                                this.props.onChangeFaqsHOC( 'showDeleteFaqListing', true )
                              } } >
                              <i className="pe-7s-trash btn-icon-wrapper" />
                            </Button>
                            <UncontrolledTooltip
                              target={ `Faq-listings-${ row.original.id }-remove-faq` }
                              placement="top">{ Lang[ 'REMOVE_FAQ' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                          </>
                        )
                      }
                    </div>
                  )
                }
              ]}/>
          </CardBody>
          {
            this.props.showDeleteFaqListing && (
              <DeleteConfirmationDialog
                deleteOpen={ this.props.showDeleteFaqListing }
                handleModalClose={ () => this.props.onChangeFaqsHOC( 'showDeleteFaqListing', false ) }
                confirmAction={ () => {
                  if( this.props.selectedFaq.id ){
                    this.props.removeFaq( this.props.selectedFaq.id )
                  }
                  this.props.onChangeFaqsHOC( 'showDeleteFaqListing', false )
                }}
                content={ Lang[ 'DELETE_CONFIRMATION_FAQ' ][ this.props.data.languageReducer.lang ] }
                lang={ this.props.data.languageReducer.lang }/>
            )
          }
        </Card>
        { this.renderCreateForm() }
        { this.renderUpdateForm() }
        { this.props.onLoadFaqs && <LoadingOverlay/> }
      </>
    )
  }
}

export default compose(
  FaqsHOC,
  FaqCategoriesHOC
)( FaqListings )
