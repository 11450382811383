import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state={ 
      loading: false,
      programTemplates: []
    }

    load = param => this.setState({ loading: param })
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( err => toast.error( err.replaceAll( '_', ' ' ) ) )

    getProgramTemplates = () => Get(
      `/api/v1/ark_member_area/am_program_templates?query=${ convertObjToBase64({ is_paginated: false }) }`,
      this.getProgramTemplatesSuccess,
      this.getProgramTemplatesError,
      this.load
    )
    getProgramTemplatesSuccess = payload => this.setState({ 
      programTemplates: payload.data.map( item => ({ 
        id: item.id, 
        label: item.title[ this.props.data.languageReducer.lang ], 
        value: item.title[ this.props.data.languageReducer.lang ]
      }) )  
    })
    getProgramTemplatesError = error => this.requestError( error )

    render = () => {
      return(
        <WrappedComponent
          { ...this.props } 
          onLoadProgramTemplatesHOC={ this.state.loading }
          programTemplates={ this.state.programTemplates }
          getProgramTemplates={ this.getProgramTemplates }
        />
      )
    }
  }
  return WithHOC
}

export default HOC