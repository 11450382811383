import React, { Component } from 'react'
import {
  Label, Button
} from 'reactstrap'
import _ from 'lodash'

import CreateLandingPage from './Create'

import WithLandingPages from './actions'
import Lang from 'Lang/General'

class LandingPages extends Component {
  render = () => {
    return (
      <div className={ `p-3 ${ !this.props.selectedBookedCampaign?.settings_client ? 'mt-3' : '' } border border-dark` }>
        <Label className="h5">{ Lang[ 'LANDING_PAGES' ][ this.props.data.languageReducer.lang ] }</Label><br/>
        <div>
          <Button 
            className="mb-3 mr-2 btn btn-primary" 
            color="primary"
            onClick={() => this.props.sendInformationToCustomer() }>{ Lang[ 'SEND_INFORMATION_TO_CUSTOMER' ][ this.props.data.languageReducer.lang ] }</Button>
          <Button 
            className="mb-3 btn btn-primary" 
            color="primary"
            onClick={() => this.props.onChangeLandingPageHOC( 'showCreateLandingPage', true )}>{ Lang[ 'ADD_NEW_LANDING_PAGE' ][ this.props.data.languageReducer.lang ] }</Button>
        </div>
        
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>{ Lang[ 'TITLE' ][ this.props.data.languageReducer.lang ] }</th>
              <th>{ 'URL' }</th>
              <th>{ Lang[ 'ACTIONS' ][ this.props.data.languageReducer.lang ] }</th>
            </tr>
          </thead>
          <tbody>
            {
              !_.isEmpty( this.props.selectedBookedCampaign ) && this.props.selectedBookedCampaign.landing_pages && this.props.selectedBookedCampaign.landing_pages.map( item => {
                return (
                  <tr key={ item.id }>
                    <td>{ item.title }</td>
                    <td>{ item.url }</td>
                    <td>
                      <Button 
                        color="link" 
                        onClick={() => {
                          Promise.all([
                            this.props.onChangeLandingPageHOC( 'toRemoveID', item.id )
                          ]).then(() => {
                            this.props.onChangeLandingPageHOC( 'showPromptModal', true )
                          })
                        }}>{ Lang[ 'DELETE' ][ this.props.data.languageReducer.lang ] }</Button>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <CreateLandingPage { ...this.props } />
      </div>
    )
  }
}

export default WithLandingPages( LandingPages )