import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { convertObjToBase64 } from 'utils/objToBase64'

import { Get, Post } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      sharedMediaDrives: [],
      mediaDrives: [],
      mediaDrivesTotalPages: 0,
      mediaDrive: {},
      activeTab: '1',
      path: [
        {
          label: 'Home',
          nav: () => {
            this.getMediaDrive()
            let foundIndex = _.findIndex( this.state.path, { label: 'Home' } )
            let tmp = _.slice( this.state.path, 0, foundIndex + 1 )
            this.setState({ path: tmp, parentFolder: { id: 1, name: 'Home' } })
          }
        }
      ],
      parentFolder: {},
      newFolderFile: {
        file_name: '',
        type: 'File',
        original_filename: '',
        parent_folder_id: 1
      },
      files: [],
      name: '',
      showCreateModal: false
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( item => toast.error( item.replaceAll( '_', ' ' ) ) )

    onChangeMediaDriveHOC = ( key, value ) => this.setState({ [key]: value })

    getMediaDrive = () => Get(
      `/api/v1/mfiles/drive`,
      this.getMediaDriveSuccess,
      this.getMediaDriveError,
      this.load
    )
    getMediaDriveSuccess = payload => {
      let tmp = {
        id: payload.data.parent_folder_id,
        name: payload.data.parent_folder_name
      }
      this.setState({ mediaDrives: payload.data, parentFolder: tmp })
    }
    getMediaDriveError = error => this.requestError( error )

    getSelectedDriveFolder = id => Get(
      `/api/v1/mfiles/drive/folders/${ id }`,
      this.getSelectedDriveFolderSuccess,
      this.getSelectedDriveFolderError,
      this.load
    )
    getSelectedDriveFolderSuccess = payload => this.setState({ mediaDrives: payload })
    getSelectedDriveFolderError = error => this.requestError( error )

    getSelectedMedia = id => Get(
      `/api/v1/mfiles/media/${ id }`,
      this.getSelectedMediaSuccess,
      this.getSelectedMediaError,
      this.load
    )
    getSelectedMediaSuccess = payload => this.setState({ selectedFileFolder: payload })
    getSelectedMediaError = error => this.requestError( error )

    getSelectedFolder = id => Get(
      `/api/v1/mfiles/folders/${ id }`,
      this.getSelectedFolderSuccess,
      this.getSelectedFolderError,
      this.load
    )
    getSelectedFolderSuccess = payload => this.setState({ selectedFileFolder: payload })
    getSelectedFolderError = error => this.requestError( error )

    createMedia = ( data, id ) => Post(
      `/api/v1/mfiles/media`,
      data,
      payload => this.createMediaSuccess( payload, id ),
      this.createMediaError,
      this.load
    )
    createMediaSuccess = (payload, id) => {
      this.setState({ 
        showCreateModal: false,
        files: [],
        activeTab: '1',
        newFolderFile: {
          file_name: '',
          type: 'File',
          original_filename: '',
          parent_folder_id: 1
        }
      })
      this.props.onSelectedImage( payload.original_url )
      this.getSelectedDriveFolder( id )
      this.props.getSharedMediaDrive( convertObjToBase64({
        page: 1,
        is_paginated: true
      }) )
    }
    createMediaError = error => this.requestError( error )

    createMediaFolder = ( data, id ) => Post(
      `/api/v1/mfiles/folders`,
      data,
      payload => this.createMediaFolderSuccess( payload, id ),
      this.createMediaFolderError,
      this.load
    )

    createMediaFolderSuccess = (payload, id) => {
      this.getSelectedDriveFolder( id )
      this.props.getSharedMediaDrive( convertObjToBase64({
        page: 1,
        is_paginated: true
      }) )
      this.setState({ 
        showCreateModal: false,
        files: [],
        newFolderFile: {
          file_name: '',
          type: 'File',
          original_filename: '',
          parent_folder_id: 1
        }
      })
    }
    createMediaFolderError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            onLoadMediaDrive={ this.state.loading }
            onChangeMediaDriveHOC={ this.onChangeMediaDriveHOC }
            getMediaDrive={ this.getMediaDrive }
            getSelectedDriveFolder={ this.getSelectedDriveFolder }
            mediaDrives={ this.state.mediaDrives }
            path={ this.state.path }
            parentFolder={ this.state.parentFolder }
            activeTab={ this.state.activeTab }
            createMedia={ this.createMedia }
            getSelectedMedia={ this.getSelectedMedia }
            createMediaFolder={ this.createMediaFolder }
            getSelectedFolder={ this.getSelectedFolder }
            newFolderFile={ this.state.newFolderFile }
            showCreateModal={ this.state.showCreateModal }
            files={ this.state.files } />
        </>
      )
    }
  }
  return WithHOC
}

export default HOC
