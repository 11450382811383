import React from 'react'

import Lang from 'Lang/General'

const PrivacyPolicy = ({
  selectedLanguageShortname
}) => {
  return(
    <h2 className='w-100 text-center pb-4 mb-4' >
      { Lang[ 'PRIVACY_POLICY' ][ selectedLanguageShortname ] }
    </h2>
  )
}

export default PrivacyPolicy