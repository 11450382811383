import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { GetWithNonce } from 'utils/axios-nonce'
import { Post, Put, Delete } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const ArticleFourteenHOC = ( WrappedComponent ) => {
  class ArticleFourteenWrappedComponent extends Component {
    state={
      loading: false,
      articles: [],
      newArticle: {
        topic: {
          en: '',
          de: '',
          nl: '',
          it: ''
        },
        content: {
          en: '',
          de: '',
          nl: '',
          it: ''
        },
        display_order: 1
      },
      selectedArticle: {},
      showViewArticle: false,
      showCreateArticle: false,
      showEditArticle: false,
      showDeleteArticle: false,
      articleTotalPages: []
    }

    onChangeArticleFourteenHOC = ( key, value ) => this.setState({ [ key ]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( item => toast.error( item.replaceAll( '_', ' ' ) ) )

    getAllArticles = query => GetWithNonce(
      `/api/v1/gdpr/article_fourteens?query=${ convertObjToBase64( query ) }`,
      this.getAllArticlesSuccess,
      this.getAllArticlesError,
      this.load
    )
    getAllArticlesSuccess = payload => {
      let tmpTotalPages = []
      if ( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      else {
        this.requestError( Lang[ 'NO_RESULT_FOUND' ][ this.props.data.languageReducer.lang ] )
      }
      this.setState({ articles: payload, articleTotalPages: tmpTotalPages })
    }
    getAllArticlesError = error => this.requestError( error )

    postArticle = data => Post(
      `/api/v1/gdpr/article_fourteens`,
      data,
      this.postArticleSuccess,
      this.postArticleError,
      this.load
    )
    postArticleSuccess = payload => {
      this.setState({ showCreateArticle: false })
      this.requestSuccess( Lang[ 'ARTICLE_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getAllArticles({
        page: 1,
        is_paginated: true
      })
    }
    postArticleError = error => this.requestError( error )

    getSelectedArticle = ( id, type ) => GetWithNonce(
      `/api/v1/gdpr/article_fourteens/${ id }`,
      ( payload ) => this.getSelectedArticleSuccess( payload, type ),
      this.getSelectedArticleError,
      this.load
    )
    getSelectedArticleSuccess = ( payload, type ) => {
      this.setState({ selectedArticle: payload })
      if ( type === 'edit' ) {
        this.setState({ showEditArticle: true })
      }
    }
    getSelectedArticleError = error => this.requestError( error )

    updateArticle = ( id, data ) => Put(
      `/api/v1/gdpr/article_fourteens/${ id }`,
      data,
      this.updateArticleSuccess,
      this.updateArticleError,
      this.load
    )
    updateArticleSuccess = payload => {
      this.requestSuccess( Lang[ 'ARTICLE_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showEditArticle: false })
      this.getAllArticles({
        page: 1,
        is_paginated: true
      })
    }
    updateArticleError = error => this.requestError( error )

    deleteArticle = id => Delete(
      `/api/v1/gdpr/article_fourteens/${ id }`,
      this.deleteArticleSuccess,
      this.deleteArticleError,
      this.load
    )
    deleteArticleSuccess = payload => {
      this.requestSuccess( Lang[ 'ARTICLE_REMOVE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showDeleteArticle: false })
      this.getAllArticles({
        page: 1,
        is_paginated: true
      })
    }
    deleteArticleError = error => this.requestError( error )

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            { ...this.props }
            articles={ this.state.articles }
            newArticle={ this.state.newArticle }
            selectedArticle={ this.state.selectedArticle }
            showCreateArticle={ this.state.showCreateArticle }
            showEditArticle={ this.state.showEditArticle }
            showViewArticle={ this.state.showViewArticle }
            showDeleteArticle={ this.state.showDeleteArticle }
            onChangeArticleFourteenHOC={ this.onChangeArticleFourteenHOC }
            articleTotalPages={ this.state.articleTotalPages }
            getAllArticles={ this.getAllArticles }
            postArticle={ this.postArticle }
            getSelectedArticle={ this.getSelectedArticle }
            updateArticle={ this.updateArticle }
            deleteArticle={ this.deleteArticle }
            onLoadArticles={ this.state.loading } />
        </Fragment>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( ArticleFourteenWrappedComponent )
}

export default ArticleFourteenHOC
