import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get, Put, Post } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state={
      loading: false,
      orderRequests: [],
      requestEditModal: false,
      selectedOrderRequest: { 
        digital_order_form: {
          name: ''
        },
        pr_number: ''
      },
      totalPages: [],
      searchParams: {
        center_name: '',
        title: '',
        status_id: 0,
        client_number: '',
        pr_number: '',
        city: ''
      },
      showDeleteConfirmation: false,
      showSelectedOrderRequest: false,
      showMessage: false
    }
    onChangeOrderRequestHOC = ( key, value ) => this.setState({ [key]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    getAllOrderRequests = query => Get(
      `/api/v1/centers/-/submitted_digital_order_forms?query=${ query }`,
      this.getAllOrderRequestsSuccess,
      this.getAllOrderRequestsError,
      this.load
    )
    getAllOrderRequestsSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data.length > 0 ){
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      } else {
        this.requestError( Lang[ 'NO_RESULT_FOUND' ][ this.props.data.languageReducer.lang ] )
      }
      return this.setState({ orderRequests: payload, totalPages: tmpTotalPages })
    }
    getAllOrderRequestsError = error => this.requestError( error )

    getSelectedRequest = ( center_id, form_id ) => Get(
      `/api/v1/centers/${ center_id }/submitted_digital_order_forms/${ form_id }`,
      this.getSelectedRequestSuccess,
      this.getSelectedRequestError,
      this.load
    )
    getSelectedRequestSuccess = payload => this.setState({ selectedOrderRequest: payload })
    getSelectedRequestError = error => this.requestError( error )

    updateOrderRequest = () => {
      let data = _.cloneDeep( this.state.selectedOrderRequest )
      data[ 'uuid' ] = data[ 'uuid' ].trim()
      data[ 'graphic_designer_ids' ] = data[ 'graphic_designers' ]
        ? data[ 'graphic_designers' ].map( designer => designer.id )
        : []

      return Put(
        `/api/v1/centers/${ this.state.selectedOrderRequest.center_id }/submitted_digital_order_forms/${ this.state.selectedOrderRequest.id }`,
        data,
        this.updateOrderRequestSuccess,
        this.updateOrderRequestError,
        this.load
      )
    }
    updateOrderRequestSuccess = async() => {
      this.requestSuccess( Lang[ 'UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      let tmp = {
        is_paginated: true,
        page: 1,
        filter: {
          center_name: {
            $like: `%${ this.state.searchParams.center_name.toLowerCase() }%`
          },
          title: {
            $like: `%${ this.state.searchParams.title.toLowerCase() }%`
          },
          status_id: {
            $neq: 7
          }
        }
      }
      if( this.state.searchParams.status_id !== 0 ) {
        tmp.filter.status_id = this.state.searchParams.status_id
      } 
      return await this.getAllOrderRequests( convertObjToBase64( tmp ) )
    }
    updateOrderRequestError = error => this.requestError( error )

    sendEmailNotifyCenterAdmin = data => Post(
      `/api/v1/submitted_digital_order_forms/${ data.id }/notifications`,
      {},
      this.sendEmailNotifyCenterAdminSuccess,
      this.sendEmailNotifyCenterAdminError,
      this.load
    )
    sendEmailNotifyCenterAdminSuccess = payload => {
      this.requestSuccess( Lang[ 'SEND_EMAIL_TO_CENTER_ADMIN_SUCCESS' ][ this.props.data.languageReducer.lang ] )
    }
    sendEmailNotifyCenterAdminError = error => this.requestError( error )

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            { ...this.props }
            getAllOrderRequests={ this.getAllOrderRequests }
            orderRequests={ this.state.orderRequests }
            onChangeOrderRequestHOC={ this.onChangeOrderRequestHOC }
            requestEditModal={ this.state.requestEditModal }
            totalPages={ this.state.totalPages }
            getSelectedRequest={ this.getSelectedRequest }
            selectedOrderRequest={ this.state.selectedOrderRequest }
            updateOrderRequest={ this.updateOrderRequest }
            searchParams={ this.state.searchParams }
            showDeleteConfirmation={ this.state.showDeleteConfirmation }
            showSelectedOrderRequest={ this.showSelectedOrderRequest }
            showMessage={ this.state.showMessage }
            onLoadOrderRequest={ this.state.loading }
            sendEmailNotifyCenterAdmin={ this.sendEmailNotifyCenterAdmin }
          />
        </Fragment>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}
export default HOC
