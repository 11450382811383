import React, { Fragment } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {
  Container, Card, CardBody, FormGroup, Form,
  Label, Col, Input, FormFeedback
} from 'reactstrap'

import Lang from 'Lang/General'

const CampaignTypeForm = ({
  onChangeCampaignTypeData,
  data,
  statusValues,
  errorMessage,
  languageReducer
}) => {
  return(
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}>
        <Container fluid>
          <Card className="main-card mb-3">
            <CardBody>
              <Form>
                <FormGroup row>
                  <Label for="name" sm={ 2 }>{ Lang[ 'TITLE' ][ languageReducer.lang ] }</Label>
                  <Col sm={ 10 }>
                    <Input
                      name="title" id="title"
                      placeholder={ Lang[ 'ENTER_TITLE' ][ languageReducer.lang ] }
                      value={ data && data.title }
                      onChange={ e => onChangeCampaignTypeData( 'title', e.target.value ) }
                      invalid={ errorMessage && errorMessage.title ? true : false }/>
                    <FormFeedback>{ errorMessage && errorMessage.title ? errorMessage.title : '' }</FormFeedback>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="description" sm={ 2 }>{ Lang[ 'DESCRIPTION' ][ languageReducer.lang ] }</Label>
                  <Col sm={ 10 }>
                    <Input
                      name="description" id="description"
                      placeholder={ Lang[ 'ENTER_CAMPAIGN_TYPE_DESCRIPTION' ][ languageReducer.lang ] }
                      value={ data && data.description }
                      onChange={ e => onChangeCampaignTypeData( 'description', e.target.value ) }
                      invalid={ errorMessage && errorMessage.description ? true : false }/>
                    <FormFeedback>{ errorMessage && errorMessage.description ? errorMessage.description : '' }</FormFeedback>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="active" sm={ 2 }>{ Lang[ 'STATUS' ][ languageReducer.lang ] }</Label>
                  <Col sm={ 10 }>
                    <Input
                      onChange={ e => {
                        onChangeCampaignTypeData( 'status', e.target.value )
                      }}
                      value={ data && data.status }
                      type="select"
                      name="select"
                      id="exampleSelect">
                      {
                        statusValues && Object.keys( statusValues ).map( key => {
                          return (
                            <option key={ key }>{ statusValues[ key ] }</option>
                          )
                        })
                      }
                    </Input>
                  </Col>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}
const mapStateToProps = state => ({
  languageReducer: state.languageReducer
})

export default connect( mapStateToProps )( CampaignTypeForm )
