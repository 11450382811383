import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get, Post, Put, Delete, outdatedToken } from 'utils/axios'
import { getRefreshToken } from 'actions/token'
import { convertObjToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

const CampaignTypeHOC = ( WrappedComponent ) => {
  class NewsletterWrappedComponent extends Component {
    state={
      loading: false,
      campaignTypes: [],
      typeModal: false,
      newCampaignType: {
        title: '',
        description: '',
        status: 'ACTIVE'
      },
      selectedCampaignType: {},
      typeMode: '',
      deleteModal: false,
      errorMessage: {},
      searchTitle: '',
      totalPages: [],
      archivedModal: false,
      archivedCampaignTypes: [],
      archivedTotalPages: [],
      archivedEditModal: false,
      archivedSearchTitle: ''
    }

    load = param => this.setState({ loading: param })

    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    ajaxError = ( error ) => {
      outdatedToken( error, this.props.history )
      this.requestError( error )
    }

    handleTypeModal = ( param, mode ) => this.setState({ typeModal: param, typeMode: mode, errorMessage: {}, selectedCampaignType: {} })

    onChangeCampaignTypeData = ( key, value ) => {
      let tmp = {}
      if( this.state.selectedCampaignType.title )
      {
        tmp = _.cloneDeep( this.state.selectedCampaignType )
        tmp[ key ] = value
        this.setState({ selectedCampaignType: tmp })
      }
      else{
        tmp = _.cloneDeep( this.state.newCampaignType )
        tmp[ key ] = value
        this.setState({ newCampaignType: tmp })
      }
    }

    onChangeCampaignTypeHOC = ( key, value ) => this.setState({ [key]: value })

    getAllCampaignTypes = ( query ) => Get(
      `/api/v1/ols_campaign_types?query=${ query }`,
      this.getAllCampaignTypesSuccess,
      this.getAllCampaignTypesError,
      this.load
    )
    getAllCampaignTypesSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      else{
        this.requestError( Lang[ 'NO_RESULT_FOUND' ][ this.props.data.languageReducer.lang ] )
      }
      this.setState({ campaignTypes: payload, totalPages: tmpTotalPages })
    }
    getAllCampaignTypesError = error => this.ajaxError( error )

    getArchivedCampaignTypes = ( query ) => Get(
      `/api/v1/ols_campaign_types?query=${ query }`,
      this.getArchivedCampaignTypesSuccess,
      this.getArchivedCampaignTypesError,
      this.load
    )
    getArchivedCampaignTypesSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      else{
        this.requestError( Lang[ 'NO_RESULT_FOUND' ][ this.props.data.languageReducer.lang ] )
      }
      this.setState({ archivedCampaignTypes: payload, archivedTotalPages: tmpTotalPages })
    }
    getArchivedCampaignTypesError = error => this.ajaxError( error )

    getSelectedCampaignType = id => Get(
      `/api/v1/ols_campaign_types/${ id }`,
      this.getSelectedCampaignTypeSuccess,
      this.getSelectedCampaignTypeError,
      this.load
    )
    getSelectedCampaignTypeSuccess = payload => this.setState({ selectedCampaignType: payload })
    getSelectedCampaignTypeError = error => this.ajaxError( error )

    postCampaignType = data  => Post(
      `/api/v1/ols_campaign_types`,
      data,
      this.postCampaignTypeSuccess,
      this.postCampaignTypeError,
      this.load
    )
    postCampaignTypeSuccess = payload => {
      let tmp = {
        page: 1,
        is_paginated: true,
        filter: {
          $or: [
            { status: 'ACTIVE' },
            { status: 'INACTIVE' }
          ]
        }
      }
      this.requestSuccess( Lang[ 'CAMPAIGN_TYPE_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.setState({ newCampaignType: { title: '', description: '', status: 'ACTIVE' } })
      this.getAllCampaignTypes( convertObjToBase64( tmp ) )
      this.handleTypeModal( false )
    }
    postCampaignTypeError = error => this.setState({ errorMessage: error }, () => this.ajaxError( Lang[ 'CAMPAIGN_TYPE_CREATE_FAILED' ][ this.props.data.languageReducer.lang ] ) )

    updateCampaignType = ( id, data, query ) => Put(
      `/api/v1/ols_campaign_types/${ id }`,
      data,
      payload => this.updateCampaignTypeSuccess( payload, query ),
      this.updateCampaignTypeError,
      this.load
    )
    updateCampaignTypeSuccess = ( payload, query ) => {
      let tmp = {
        page: 1,
        is_paginated: true,
        filter: {
          $or: [
            { status: 'ACTIVE' },
            { status: 'INACTIVE' }
          ]
        }
      }
      this.requestSuccess( Lang[ 'CAMPAIGN_TYPE_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getAllCampaignTypes( convertObjToBase64( query ) )
      this.handleTypeModal( false )
    }
    updateCampaignTypeError = error => this.setState({ errorMessage: error }, () => this.ajaxError( Lang[ 'CAMPAIGN_TYPE_UPDATE_FAILED' ][ this.props.data.languageReducer.lang ] ) )

    removeCampaignType = ( id ) => Delete(
      `/api/v1/ols_campaign_types/${ id }`,
      this.removeCampaignTypeSuccess,
      this.removeCampaignTypeError,
      this.load
    )
    removeCampaignTypeSuccess = payload => {
      let tmp = {
        page: 1,
        is_paginated: true,
        filter: {
          $or: [
            { status: 'ACTIVE' },
            { status: 'INACTIVE' }
          ]
        }
      }
      this.requestSuccess( Lang[ 'CAMPAIGN_TYPE_REMOVE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getAllCampaignTypes( convertObjToBase64( tmp ) )
      this.setState({ deleteModal: false })
    }
    removeCampaignTypeError = error => this.setState({ errorMessage: error }, () => this.ajaxError( Lang[ 'CAMPAIGN_TYPE_REMOVE_FAILED' ][ this.props.data.languageReducer.lang ] ) )

    updateArchivedCampaignType = ( id, data, query ) => Put(
      `/api/v1/ols_campaign_types/${ id }`,
      data,
      payload => this.updateArchivedCampaignTypeSuccess( payload, query ),
      this.updateArchivedCampaignTypeError,
      this.load
    )
    updateArchivedCampaignTypeSuccess = ( payload, query ) => {
      let tmp = {
        page: 1,
        is_paginated: true,
        filter: {
          $or: [
            { status: 'ACTIVE' },
            { status: 'INACTIVE' }
          ]
        }
      }
      this.requestSuccess( Lang[ 'CAMPAIGN_TYPE_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getArchivedCampaignTypes( convertObjToBase64( query ) )
      this.getAllCampaignTypes( convertObjToBase64( tmp ) )
      this.setState({ archivedEditModal: false })
    }
    updateArchivedCampaignTypeError = error => this.setState({ errorMessage: error }, () => this.ajaxError( 'Update campaign type failed' ) )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            onLoadCampaignType={ this.state.loading }
            getAllCampaignTypes={ this.getAllCampaignTypes }
            getSelectedCampaignType={ this.getSelectedCampaignType }
            postCampaignType={ this.postCampaignType }
            updateCampaignType={ this.updateCampaignType }
            removeCampaignType={ this.removeCampaignType }
            campaignTypes={ this.state.campaignTypes }
            newCampaignType={ this.state.newCampaignType }
            selectedCampaignType={ this.state.selectedCampaignType }
            typeModal={ this.state.typeModal }
            deleteModal={ this.state.deleteModal }
            onChangeCampaignTypeData={ this.onChangeCampaignTypeData }
            onChangeCampaignTypeHOC={ this.onChangeCampaignTypeHOC }
            handleTypeModal={ this.handleTypeModal }
            typeMode={ this.state.typeMode }
            searchTitle={ this.state.searchTitle }
            totalPages={ this.state.totalPages }
            errorMessage={ this.state.errorMessage }
            archivedModal={ this.state.archivedModal }
            archivedCampaignTypes={ this.state.archivedCampaignTypes }
            archivedTotalPages={ this.state.archivedTotalPages }
            getArchivedCampaignTypes={ this.getArchivedCampaignTypes }
            updateArchivedCampaignType={ this.updateArchivedCampaignType }
            archivedEditModal={ this.state.archivedEditModal }
            archivedSearchTitle={ this.state.archivedSearchTitle } />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    getRefreshToken
  } )( NewsletterWrappedComponent )
}
export default CampaignTypeHOC
