import React, { Component } from 'react'
import { compose } from 'redux'
import{
  Modal, ModalBody, ModalFooter, ModalHeader, Form, FormGroup, Label, Input,
  Button, UncontrolledTooltip, Card, CardBody, CardFooter, CardHeader, Col,
  Container, Row
} from 'reactstrap'
import _ from 'lodash'
import Moment from 'moment'

import Wrapper from '../../../Wrapper'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PageTitle from 'components/PageTitle'
import ARKTable from 'components/Table'
import ProductRequestHOC from './actions'
import RequestStatusHOC from './actions/status'
import ProductHOC from './actions/product'
import EditForm from './Edit'
import DeleteConfirmation from 'components/Modal/delete'

import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

class ProductRequest extends Component{

  componentDidMount = () => {
    let tmp = {
      page: 1,
      is_paginated: true
    }
    this.props.getAllProductRequests( convertObjToBase64( tmp ) )
    this.props.getAllRequestStatus()
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Product Request' })
      return tmpModules.is_update
    }
  }

  getTrProps = (state, rowInfo, instance) => {
    if( rowInfo ){
      let tmp = _.find( this.props.requestStatus, { name: rowInfo.original.status })
      if( tmp ) {
        return {
          style: {
            background: tmp.color,
            color: '#000'
          }
        }
      }
    }
    return {};
  }

  renderEditProductRequestModal = () => {
    return(
      <Modal
        toggle={ () => this.props.onChangeProductRequestHOC( 'requestEditModal', false ) }
        isOpen={ this.props.requestEditModal }
        size="xl">
        <ModalHeader toggle={ () => this.props.onChangeProductRequestHOC( 'requestEditModal', false ) }>{ Lang[ 'EDIT_PRODUCT_REQUEST' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <EditForm
            mode="edit"
            language={ this.props.data.languageReducer.lang }
            selectedProductRequest={ this.props.selectedProductRequest }
            requestStatus={ this.props.requestStatus }
            onChangeProductRequestHOC={ this.props.onChangeProductRequestHOC }
            getSelectedProduct={ this.props.getSelectedProduct }
            history={ this.props.history }
            selectedProduct={ this.props.selectedProduct }
            showUpdateProduct={ this.props.showUpdateProduct } />
        </ModalBody>
        <ModalFooter>
          <Button
            className="mb-2 mr-2"
            color="primary"
            onClick={ () => {
              if( this.props.selectedProductRequest.status_id === 5 ) {
                if( this.props.selectedProductRequest.from_date === null || this.props.selectedProductRequest.to_date === null ){
                  window.alert( Lang['ENTER_BOOKED_DATE'][ this.props.data.languageReducer.lang ] )
                }
                else{
                  this.props.updateProductRequest()
                }
              } else {
                this.props.updateProductRequest()
              }
            }}>{ Lang[ 'UPDATE' ][ this.props.data.languageReducer.lang ] }</Button>
          <Button className="mb-2 mr-2" color="secondary" onClick={ () => this.props.onChangeProductRequestHOC( 'requestEditModal', false ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  render = () => {
    return(
      <>
        <div>
          <PageTitle
            heading={ Lang[ 'PRODUCT_REQUEST' ][ this.props.data.languageReducer.lang ] }
            icon="pe-7s-comment icon-gradient bg-happy-fisher"
            buttons={[]}/>
          <Card className="mb-3">
            <CardHeader>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }
            </CardHeader>
            <CardBody>
            <Container>
              <Row>
                <Col md={12}>
                  <Label>{ Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ] }</Label>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
                  {
                    this.props.requestStatus.length > 0 && this.props.requestStatus.map( item => {
                      return(
                        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginRight: '15px' }}>
                          <div style={{ width: '15px', height: '15px', background: item.color, marginRight: '5px', marginBottom: '5px', border: 'solid #000 .5px' }}>
                          </div>
                          <label>{ item.name[ this.props.data.languageReducer.lang ] }</label>
                        </div>
                      )
                    })
                  }
                  </div>
                  <Form>
                    <FormGroup>
                      <Label>{ Lang[ 'COMPANY_NAME' ][ this.props.data.languageReducer.lang ] }</Label>
                      <Input
                        type="text"
                        value={ this.props.searchParams.company_name }
                        onChange={ e => {
                          let tmp = _.cloneDeep( this.props.searchParams )
                          tmp[ 'company_name' ] = e.target.value
                          this.props.onChangeProductRequestHOC( 'searchParams', tmp )
                        }}/>
                    </FormGroup>
                    <FormGroup>
                      <Label>{ Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ] }</Label>
                      <Input
                        type="select"
                        value={ this.props.searchParams.status_id }
                        onChange={ e => {
                          let tmp = _.cloneDeep( this.props.searchParams )
                          tmp[ 'status_id' ] = parseInt( e.target.value )
                          this.props.onChangeProductRequestHOC( 'searchParams', tmp )
                        }}>
                        <option value={0}>{ Lang[ 'PLEASE_SELECT_ONE' ][ this.props.data.languageReducer.lang ] }</option>
                        {
                          this.props.requestStatus.length > 0 && this.props.requestStatus.map( item => {
                            return(
                              <option key={ item.id } value={ item.id }>{ item.name[ this.props.data.languageReducer.lang ] }</option>
                            )
                          })
                        }
                      </Input>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            </Container>
            </CardBody>
            <CardFooter>
              <Button
                color="primary"
                onClick={ () => {
                  let tmp = {}
                  if( this.props.searchParams.status_id === 0 ) {
                    tmp = {
                      is_paginated: true,
                      page:1,
                      filter: {
                        company_name: {
                          $like: `%${ this.props.searchParams.company_name.toLowerCase() }%`
                        }
                      }
                    }
                  } else {
                    tmp = {
                      is_paginated: true,
                      page:1,
                      filter: {
                        company_name: {
                          $like: `%${ this.props.searchParams.company_name }%`
                        },
                        status_id: this.props.searchParams.status_id
                      }
                    }
                  }

                  this.props.getAllProductRequests( convertObjToBase64( tmp ) )
                }}>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }</Button>
              <Button
                color="danger"
                onClick={ () => {
                  let tmp = {
                    page: 1,
                    is_paginated: true
                  }
                  this.props.getAllProductRequests( convertObjToBase64( tmp ) )
                  this.props.onChangeProductRequestHOC( 'searchParams', { company_name: '', status_id: 0 } )
                }}>{ Lang[ 'RESET' ][ this.props.data.languageReducer.lang ] }</Button>
            </CardFooter>
          </Card>
          <ARKTable
            data={ this.props.productRequests.data }
            columns={[
              {
                Header: 'Requested on',
                accessor: "created_at",
                Cell: ({value}) => (
                  Moment( value ).format( 'DD-MM-YYYY h:mm:ss a' )
                )
              },
              {
                Header: Lang[ 'COMPANY_NAME' ][ this.props.data.languageReducer.lang ],
                accessor: "company_name",
              },
              {
                Header: Lang[ 'PRODUCT' ][ this.props.data.languageReducer.lang ],
                accessor: `product_name[${ this.props.data.languageReducer.lang }]`
              },
              {
                Header: Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ],
                accessor: `status[${ this.props.data.languageReducer.lang }]`
              },
              {
                Header: Lang[ 'FROM' ][ this.props.data.languageReducer.lang ],
                accessor: 'from_date'
              },
              {
                Header: Lang[ 'TO' ][ this.props.data.languageReducer.lang ],
                accessor: 'to_date'
              },
              {
                Header: Lang[ 'CONSULTANT' ][ this.props.data.languageReducer.lang ],
                accessor: 'consultant_name'
              },
              {
                Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
                accessor: 'id',
                Cell: row => (
                  <>
                    <Button
                      id={ `product-request-${row.original.id}-update` }
                      className="mr-2 btn-icon btn-icon-only"
                      color="primary"
                      disabled={ !this.handleSecurityModulesUpdate() }
                      onClick={ () => {
                        this.props.onChangeProductRequestHOC( 'requestEditModal', true )
                        this.props.getSelectedRequest( row.original.product_id, row.original.id )
                        this.props.getSelectedProduct( row.original.product_id )
                      }}>
                        <i className="pe-7s-note"> </i>
                    </Button>
                    <UncontrolledTooltip
                      target={ `product-request-${row.original.id}-update` }
                      placement="top">{ Lang[ 'UPDATE' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                    <Button
                      id={ `product-request-${row.original.id}-delete` }
                      className="mr-2 btn-icon btn-icon-only"
                      color="danger"
                      disabled={ !this.handleSecurityModulesUpdate() }
                      onClick={ () => {
                        this.props.onChangeProductRequestHOC( 'showDeleteConfirmation', true )
                        this.props.getSelectedRequest( row.original.product_id, row.original.id )
                      }}>
                        <i className="pe-7s-trash"> </i>
                    </Button>
                    <UncontrolledTooltip
                      target={ `product-request-${row.original.id}-delete` }
                      placement="top">{ Lang[ 'DELETE' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                  </>
                )
              }
            ]}
            totalPages={ this.props.totalPages }
            page={ this.props.productRequests.meta ? this.props.productRequests.meta.current_page : 1 }
            onChangePage={ pg => {
              let tmp = {
                page: pg,
                is_paginated: true
              }
              this.props.getAllProductRequests( convertObjToBase64( tmp ) )
            }}
            onClickReset={ () => {
              let tmp = {
                page: 1,
                is_paginated: true
              }
              this.props.getAllProductRequests( convertObjToBase64( tmp ) )
            }}
            onSearch={ () => {} }
            searchLabel={ `Search with title` }
            searchComponent={ false }
            showPagination={ false }
            getTrProps={ this.getTrProps } />
            { this.renderEditProductRequestModal() }
            {
              this.props.showDeleteConfirmation && (
                <DeleteConfirmation
                  handleModalClose={ () => this.props.onChangeProductRequestHOC( 'showDeleteConfirmation', false ) }
                  deleteOpen={ this.props.showDeleteConfirmation }
                  confirmAction={ () => {
                    this.props.deleteProductRequest()
                    this.props.onChangeProductRequestHOC( 'showDeleteConfirmation', false )
                  }}
                  content={ Lang[ 'DELETE_CONFIRMATION_PRODUCT_REQUEST' ][ this.props.data.languageReducer.lang ] }
                  lang={ this.props.data.languageReducer.lang } />
              )
            }
        </div>
        { ( this.props.data.ajaxReducer.ajaxCallProgress > 0 || this.props.onLoadProductRequest ) && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose(
  ProductRequestHOC,
  RequestStatusHOC,
  ProductHOC
)( ProductRequest )
