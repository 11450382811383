import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { Post, Get, Put, Delete } from 'utils/axios'
import Lang from 'Lang/General'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state={ 
      loading: false,
      tags: [],
      newTag: {},
      selectedTag: {},
      showCreateTag: false,
      showUpdateTag: false,
      showDeleteTagId: 0
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( err => toast.error( err.replaceAll( '_', ' ' ) ) )

    onChangeTagsHOC = ( key, value ) => this.setState({ [ key ] : value })

    createTag = data => Post(
      `/api/v1/ark_member_area/am_tags`,
      data,
      this.createTagSuccess,
      this.createTagError,
      this.load
    )
    createTagSuccess = () => {
      this.getTags()
      this.requestSuccess( Lang[ 'TAG_IS_SUCCESSFULLY_CREATED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showCreateTag: false })
    }
    createTagError = error => {
      this.requestError( error )
      this.setState({ showCreateTag: false })
    }

    getTags = () => Get(
      `/api/v1/ark_member_area/am_tags`,
      this.getTagsSuccess,
      this.getTagsError,
      this.load
    )
    getTagsSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      this.setState({ tags: payload, tagsPages: tmpTotalPages })
    }
    getTagsError = error => this.requestError( error )

    getSelectedTag = tag_id => Get(
      `/api/v1/ark_member_area/am_tags/${ tag_id }`,
      this.getSelectedTagSuccess,
      this.getSelectedTagError,
      this.load
    )
    getSelectedTagSuccess = payload => this.setState({ selectedTag: payload, showUpdateTag: true })
    getSelectedTagError = error => this.requestError( error )

    updateTag = data => Put(
      `/api/v1/ark_member_area/am_tags/${ data.id }`,
      data,
      this.updateTagSuccess,
      this.updateTagError,
      this.load
    )
    updateTagSuccess = () => {
      this.getTags()
      this.requestSuccess( Lang[ 'TAG_IS_SUCCESSFULLY_UPDATED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showUpdateTag: false })
    }
    updateTagError = error => this.requestError( error )

    removeTag = tag_id => Delete(
      `/api/v1/ark_member_area/am_tags/${ tag_id }`,
      this.removeTagSuccess,
      this.removeTagError,
      this.load
    )
    removeTagSuccess = () => {
      this.getTags()
      this.requestSuccess( Lang[ 'TAG_IS_SUCCESSFULLY_REMOVED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showDeleteTagId: 0 })
    }
    removeTagError = error => {
      this.requestError( error )
      this.setState({ showDeleteTagId: 0 })
    }

    render = () => {
      return(
        <WrappedComponent
          { ...this.props } 
          onLoadTagsHOC={ this.state.loading }
          tags={ this.state.tags }
          newTag={ this.state.newTag }
          selectedTag={ this.state.selectedTag }
          showCreateTag={ this.state.showCreateTag }
          showUpdateTag={ this.state.showUpdateTag }
          showDeleteTagId={ this.state.showDeleteTagId }
          onChangeTagsHOC={ this.onChangeTagsHOC }
          createTag={ this.createTag }
          getTags={ this.getTags }
          getSelectedTag={ this.getSelectedTag }
          updateTag={ this.updateTag }
          removeTag={ this.removeTag }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC