import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'
import fileSaver from 'file-saver';

import getDomainURL from 'utils/api'
import { getItem } from 'utils/tokenStore'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error !== 'string' 
      ? Object.values( error ).map( val => toast.error( val ) )
      : toast.error( error )


    downloadUploadedData = (form_id) => fetch(
      `${ getDomainURL() }/api/v1/submitted_digital_order_forms/${ form_id }/files`, {
        headers: {
          'Authorization': `Bearer ${ getItem( 'PROOFPOINT_ARK_MANAGEMENT' ) }`
        }
      })
      .then(response => response.arrayBuffer())
      .then(data => this.downloadUploadedDataSuccess(data) )
      .catch(error => this.downloadUploadedDataError(error))

    downloadUploadedDataSuccess = payload => {
      const blob = new Blob([payload], {
        type: 'application/zip'
      })
      const filename = `${ this.props.selectedOrderRequest.uuid }-${ this.props.selectedOrderRequest.digital_order_form[ 'name' ][ 'de' ] }`
      fileSaver.saveAs(blob, filename)
    }
    downloadUploadedDataError = error => this.requestError( error )


    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            downloadUploadedData={ this.downloadUploadedData } 
          />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
