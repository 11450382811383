import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import Moment from 'moment'

import StatusModal from 'components/Modal/status'
import Lang from 'Lang/General'

import { Get, outdatedToken, DownloadFile } from 'utils/axios'
import { getRefreshToken } from 'actions/token'
import { convertObjToBase64 } from 'utils/objToBase64'

const CenterHOC = ( WrappedComponent ) => {
  class CenterWrappedComponent extends Component {
    state={
      centers: {},
      centerTotalPage: [],
      loading: false,
      selectedCenter: null,
      centerReportSearchParams: ''
    }

    onChangeCenterHOC = (key, value) => this.setState({ [key]: value })

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showStatusModal: true,
      statusModalType: 'negative',
      onClickStatusModalButton: () => this.setState({ showStatusModal: false }),
      statusModalMessage: typeof error === 'string'
        ? error
        : error
          ? Object.values( error ).map( ( item, index ) => index === 0
            ? item.replaceAll( '_', ' ' )
            : `, ${ item.replaceAll( '_', ' ' ) }` )
          : ''
    })
    ajaxError = ( error ) => {
      outdatedToken( error, this.props.history )
      this.requestError( error )
    }

    getCentersSearch = ( query, type, searchParams, searchVal ) => {
      let tmpSearchParams = ''
      let startDateParams = ''
      let endDateParams = ''
      if( type === 'Normal Search' ){
        query = {
          ...query,
          filter: {
            center_name: {
              $like: `%${ searchVal.toLowerCase() }%`
            },
            $or: [
              { status: 'ACTIVE' },
              { status: 'INACTIVE' },
              { status: 'TRIAL' }
            ]
          }
        }
      }
      if( type === 'Advanced Search' ){
        let tmpClient = {}
        let tmpCenter = {}
        searchParams.map( item => {
          if ( [ 'status', 'consultant_id', 'client_id', 'center_group_id', 'is_live_site', 'start_date', 'end_date' ].indexOf( item.val ) < 0 ) {
            tmpCenter[ item.val ] = {
              $like: `%${ item.searchValue.toLowerCase() }%`,
            }
          } else if ( item.val === 'status' ) {
            tmpCenter[ item.val ] = item.searchValue
          } else if ( ['consultant_id', 'client_id', 'center_group_id' ].indexOf( item.val ) > -1 ) {
            tmpCenter[ item.val ] = item.searchValue.id
          } else if ( item.val === 'is_live_site' && (item.searchValue === 'true' || item.searchValue === 'false') ) {
            tmpCenter[ item.val ] = ( item.searchValue === 'true' )
          } else if ( item.val === 'start_date' && item.searchValue !== '' ) {
            startDateParams = `start_date=${ Moment( item.searchValue ).format( 'YYYY-MM-DD' ) }&`
          } else if ( item.val === 'end_date' && item.searchValue !== '' ) {
            endDateParams = `end_date=${ Moment( item.searchValue ).format( 'YYYY-MM-DD' ) }&`
          }
        })

        if( tmpCenter.status ){
          query = {
            ...query,
            filter: {
              ...tmpCenter,
              ...tmpClient,
              $or: [
                { has_consultant: true },
                { has_consultant: false }
              ]
            }
          }
        } else {
          query = {
            ...query,
            filter: {
              ...tmpCenter,
              ...tmpClient,
              $or: [
                { status: 'ACTIVE' },
                { status: 'INACTIVE' },
                { has_consultant: true },
                { has_consultant: false },
                { status: 'TRIAL' }
              ]
            }
          }
        }
      }
      if ( startDateParams !== '' && endDateParams !== '' ) {
        tmpSearchParams = `${ startDateParams.concat( endDateParams ) }query=${ convertObjToBase64( query ) }`
      } else {
        tmpSearchParams = `query=${ convertObjToBase64( query ) }`
      }
      this.setState({ centerReportSearchParams: tmpSearchParams })
      Get(
        `/api/v1/email_statistics/center_report/centers?${ tmpSearchParams }`,
        this.getCentersSearchSuccess,
        this.getCentersSearchError,
        this.load
      )
    }
    getCentersSearchSuccess = payload => {
      let tmpTotalPages = []
      let tmpCenters = []
      this.setState({ centers: payload })
      if( payload.data.length > 0 ){
        payload.data.map( item => {
          tmpCenters.push({
            ...item,
            fullname: `${ item.first_name } ${ item.last_name }`
          })
        })
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
        this.setState({
          centers: { data: tmpCenters, meta: payload.meta },
          centerTotalPage: tmpTotalPages
        })
      }else{
        this.ajaxError( Lang[ 'NO_RESULT_FOUND' ][ this.props.data.languageReducer.lang ] )
      }

    }
    getCentersSearchError = error => this.ajaxError( error )

    getCenters = query => Get(
      `/api/v1/email_statistics/center_report/centers?query=${ convertObjToBase64( query ) }`,
      this.getCentersSuccess,
      this.getCentersError,
      this.load
    )
    getCentersSuccess = payload => {
      let tmpTotalPages = []
      let tmpCenters = []
      if( payload.data && payload.data.length > 0 ) {
        payload.data.map( item => {
          tmpCenters.push({
            ...item,
            fullname: `${ item.first_name } ${ item.last_name }`
          })
        })
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      let tmp = {
        data: tmpCenters,
        meta: payload.meta
      }
      this.setState({ 
        centers: tmp, 
        centerTotalPage: tmpTotalPages 
      })
    }
    getCentersError = error => this.ajaxError( error )

    getCenterReportExcel = () => DownloadFile(
      `/api/v1/email_statistics/center_report_excel?${ this.state.centerReportSearchParams }`,
      `${ Lang[ 'CENTER_STATISTICS' ][ this.props.data.languageReducer.lang ] } ${ this.state.centerReportSearchParams.split( /[=&]/ )[ 1 ] } - ${ this.state.centerReportSearchParams.split( /[=&]/ )[ 3 ] }.xlsx`,
      this.getCenterReportExcelError,
      this.load
    )
    getCenterReportExcelError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            centers={ this.state.centers }
            selectedCenter={ this.state.selectedCenter }
            centerTotalPage={ this.state.centerTotalPage }
            onLoadCenters={ this.state.loading }
            centerReportSearchParams={ this.state.centerReportSearchParams }
            
            onChangeCenterHOC={ this.onChangeCenterHOC }
            getCentersSearch={ this.getCentersSearch }
            getCenters={ this.getCenters }
            getCenterReportExcel={ this.getCenterReportExcel }
          />
          <StatusModal
            isOpen={ this.state.showStatusModal }
            type={ this.state.statusModalType }
            message={ this.state.statusModalMessage }
            onClick={ this.state.onClickStatusModalButton } />
        </>
      )
    }

  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    getRefreshToken
  } )( CenterWrappedComponent )
}

export default CenterHOC
