import React, { Component } from 'react'
import {
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody
} from 'reactstrap'
import _ from 'lodash'
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import uniqid from 'uniqid'
import { SketchPicker } from 'react-color'
import { connect } from 'react-redux'
import { compose } from 'redux'

import MultilingualInput from "components/Input/MultiLang"
import LoadingOverlay from "components/Indicator/LoadingOverlay"

import UpdateFormFrame from './Utility/FormFrame'
import UpdateColumnModal from './Utility/Column'
import UpdateContentModal from './Utility/Content'
import DeletePromptModal from './Utility/Delete'

import ButtonConfigModal from './ButtonConfigModal'
import RenderDOM from './RenderDOM'
import Lang from 'Lang/General'
import { toggleMediaDatabase } from "actions/MediaDatabaseToggle"

const reorder = ( list, startIndex, endIndex ) => {
  const result = Array.from( list )
  const [removed] = result.splice( startIndex, 1 )
  result.splice( endIndex, 0, removed )
  return result
}

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "transparent"
})

class Create extends Component {
  state = {
    dom: [
      {
        id: 'kdmm1yn6',
        type: 'FormFrame',
        content: {
          "en": "Test title form frame",
          "de": "Test Titelformular-Rahmen",
          "nl": "Testtitel kader",
          "it": "Telaio del titolo del test"
        },
        title: {
          fontSize: 20,
          color: "#ffffff",
          backgroundColor: '#515151',
          fontWeight: '300',
          textAlign: 'center',
          padding: '15px'
        },
        container: {
          borderWidth: 1,
          borderColor: '#515151'
        },
        totalPages: 1,
        children: [
          {
            id: 'kdplhieb',
            page: 1,
            type: 'Container',
            style: {
              backgroundColor: this.props.newForm.background_color
            },
            children: [
              {
                id: 'kdn9iwjz',
                type: 'Row',
                mapWith: 'form-content',
                children: [
                  {
                    id: 'kdq9jkzq',
                    type: 'Col',
                    grid: 12,
                    children: [
                      {
                        id: 'kdq9k046',
                        type: 'Button',
                        color: 'light',
                        onClickEditor: () => {
                          this.setState({
                            showUpdateContentModal: true,
                            selectedParentComponentToEdit: {
                              id: 'kdq9jkzq',
                              type: 'Col'
                            }
                          }, () => {
                            this.findObjectById( this.state.dom, this.state.selectedParentComponentToEdit.id )
                          })
                        },
                        content: {
                          'en': 'Click to select form\'s element',
                          'de': 'Klicken Sie auf , um das Element des Formulars auszuwählen ',
                          'nl': 'Klik om het element van het formulier te selecteren',
                          'it': 'Clicca per selezionare l\'elemento del modulo'
                        },
                        style: {
                          width: '100%'
                        },
                        mapWith: 'add-content-in-column-button'
                      }
                    ]
                  }
                ]
              },
              {
                id: 'kdqv9rz5',
                type: 'Button',
                mapWith: 'add-new-column-button',
                onClickEditor: async () => {
                  await this.onClickAddNewColumn()
                },
                color: 'light',
                content: {
                  "en": "Add new column",
                  "de": "Neue Spalte hinzufügen",
                  "nl": "Nieuwe kolom toevoegen",
                  "it": "Aggiungi nuova colonna"
                },
                style: {
                  width: '100%'
                }
              }
            ],
            mapWith: 'form-container'
          }
        ]
      }
    ],
    formValues: [],
    showUpdateFormFrame: false,
    showUpdateColumnModal: false,
    showUpdateContentModal: false,
    showConfirmDelete: false,
    selectedParentComponentToEdit: {},
    selectedComponentToEdit: {},
    selectedComponentToRemove: {},
    componentsRemoved: [],
    foundObject: {
      children: []
    },
    currentLanguage: this.props.data.languageReducer.lang,
    redirectInfo: {
      type: "button",
      variant: "",
      redirectType: "",
      isPublicFileUrl: false,
      navTo: "",
      href: "",
      newTab: false
    },
    bottomButtonText: Lang[ 'SUBMIT' ]
  }

  findObjectAndRemove = ( data, target ) => {
    data.map( ( item, index ) => {
      if( item.id === target.id ) {
        data.splice( index, 1 )
      } else {
        if( item.children ) {
          this.findObjectAndRemove( item.children, target )
        }
      }
    })
  }

  componentDidUpdate = async pp => {
    if( this.props.newForm.background_color !== pp.newForm.background_color ){
      let tmpNewForm = _.cloneDeep( this.props.newForm )
      let tmpDom = _.cloneDeep( this.state.dom )
      await this.findTargetObject( tmpDom[0].children, 'form-container' )
      let tmpFound = _.cloneDeep( this.state.foundObject )
      tmpFound = {
        ...this.state.foundObject,
        style: {
          backgroundColor: this.props.newForm.background_color
        }
      }
      tmpNewForm = {
        ...this.props.newForm,
        content: tmpDom
      }

      this.props.onChangeFormsHOC( 'tmpNewForm', tmpNewForm )
      await this.findObjectAndPatch( tmpNewForm.content, tmpFound )
      this.setState({ dom: tmpDom })
    }
    if( this.props.showCreateForm && !pp.showCreateForm ){
      this.props.onChangeTempComponent( 'selectedTempComponent', {} )
    }
    if( pp.showUpdateForm && !this.props.showUpdateForm ){
      this.setState({ redirectInfo: {
        type: "button",
        variant: "",
        redirectType: "",
        isPublicFileUrl: false,
        navTo: "",
        href: "",
        newTab: false
      }})
    }
  }

  onChangeFormBuilderState = ( key, val ) => this.setState({ [key]: val })

  onPatchFormValues = ( index, key, val ) => {
    let tmp = _.cloneDeep( this.state.formValues )
    tmp[ index ][ key ] = val
    return this.setState({ formValues: tmp })
  }

  findObjectAndPatch = ( data, target ) => {
    data.map( ( item, index ) => {
      if( item.id === target.id ) {
        data.splice( index, 1, target )
      } else {
        if( item.children ) {
          this.findObjectAndPatch( item.children, target )
        }
      }
    })
    return data
  }

  findObjectById = ( data, id ) => {
    data.map( ( item ) => {
      if( item.id === id ) {
        this.setState({ foundObject: item })
      } else {
        if( item.children ) {
          this.findObjectById( item.children, id )
        }
      }
    })
  }

  findTargetObject = ( data, target ) => {
    data.map( ( item ) => {
      if( item.mapWith === target ) {
        this.setState({ foundObject: item })
      } else {
        if( item.children ) {
          this.findTargetObject( item.children, target )
        }
      }
    })
  }

  findTargetAndPatch = ( data, target, result ) => {
    data.map( ( item, index ) => {
      if( item.mapWith === target ) {
        data.splice( index, 1, result )
      } else {
        if( item.children ) {
          this.findTargetAndPatch( item.children, target, result )
        }
      }
    })
    return data
  }

  findExtraButtonsAndRemove = data => {
    data.map( ( item ) => {
      item.children = _.filter( item.children, child => child.mapWith !== 'add-content-in-column-button' && child.mapWith !== 'add-new-column-button' )
      if( item.children ) {
        this.findExtraButtonsAndRemove( item.children )
      }
    })
  }

  addSubmitButton = data => {
    let tmp = []
    if( !_.isEmpty( this.props.selectedTempComponent ) ){
      tmp = _.cloneDeep( this.props.selectedTempComponent )
    } else {
      tmp = {
        id: uniqid(),
        type: "button",
        style: {
          color: "#ffffff",
          background: "#238acc",
          border: "none",
          borderRadius: ".25rem",
          outline: "none",
          padding: "5px 8px",
          transition: "0.3s linear all",
          ':hover': {
            color: "#000000",
            background: "#ffffff",
            border: "1px solid #000000"
          }
        },
        children: [{
          id: uniqid(),
          type: "div",
          variant: "text",
          style: { zIndex: 2, position: "relative" },
          content: Lang[ 'SUBMIT' ]
        }]
      }
    }
    tmp = { ...tmp, ...this.state.redirectInfo }
    data.map( child => {
      if( child.type && child.type === 'Container' ){
        child.children.push(
          {
            id: uniqid(),
            type: "div",
            className: "row",
            children: [
              {
                id: uniqid(),
                type: "div",
                className: "col-md-12",
                style: {
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginBottom: "1rem",
                  '@media ( max-width: 768px )': {
                    justifyContent: "flex-start"
                  }
                },
                children: [ tmp ]
              }
            ]
          }
        )
      } else if( child.children && child.children.length > 0 ){
        this.addSubmitButton( child.children )
      }
    })
  }

  onClickAddNewColumn = async () => {
    let tmp = _.cloneDeep( this.state.dom )
    await this.findTargetObject( tmp, 'form-content' )
    let tmpFoundObject = _.cloneDeep( this.state.foundObject )
    let id = uniqid()
    tmpFoundObject.children.push({
      id: id,
      type: 'Col',
      grid: 12,
      children: [
        {
          id: uniqid(),
          type: 'Button',
          onClickEditor: () => {
            this.setState({
              showUpdateContentModal: true,
              selectedParentComponentToEdit: {
                id: id,
                type: 'Col'
              }
            }, () => {
              this.findObjectById( this.state.dom, this.state.selectedParentComponentToEdit.id )
            })
          },
          color: 'light',
          content: {
            'en': 'Click to select form\'s element',
            'de': 'Klicken Sie auf , um das Element des Formulars auszuwählen ',
            'nl': 'Klik om het element van het formulier te selecteren',
            'it': 'Clicca per selezionare l\'elemento del modulo'
          },
          style: {
            width: '100%'
          },
          mapWith: 'add-content-in-column-button'
        }
      ]
    })
    this.findObjectAndPatch( tmp, tmpFoundObject )
    this.setState({ dom: tmp })
  }

  onClickRemoveColumn = () => {
    let tmp = _.cloneDeep( this.state.dom )
    let tmpSelectComponentToRemove = _.cloneDeep( this.state.selectedComponentToRemove )
    let tmpComponentsRemoved = _.cloneDeep( this.state.componentsRemoved )
    tmpComponentsRemoved.push( tmpSelectComponentToRemove.children[0].id )
    this.findObjectAndRemove( tmp, tmpSelectComponentToRemove )
    return this.setState({
      showConfirmDelete: false,
      dom: tmp,
      componentsRemoved: tmpComponentsRemoved })
  }

  onDragEnd = async result => {
    if( !result.destination ) {
      return;
    }
    let tmp = _.cloneDeep( this.state.dom )
    await this.findTargetObject( tmp, 'form-content' )
    let tmpFoundObject = _.cloneDeep( this.state.foundObject )
    let tmpFoundObjectChildren = _.cloneDeep( tmpFoundObject.children )
    tmpFoundObjectChildren = reorder( tmpFoundObjectChildren, result.source.index, result.destination.index )
    tmpFoundObject.children = tmpFoundObjectChildren
    await this.findTargetAndPatch( tmp, 'form-content', tmpFoundObject )
    this.setState({ dom: tmp })
  }

  onChangeNewForm = ( key, val ) => {
    let tmp = _.cloneDeep( this.props.newForm )
    tmp[ key ] = val
    this.props.onChangeFormsHOC( 'newForm', tmp )
  }

  renderGeneralTab = () => (
    <>
      <Row>
        <Col md={ 12 }>
          <Form>
            <FormGroup>
              <MultilingualInput
                label={ Lang[ 'NAME' ][ this.props.data.languageReducer.lang ] }
                selectedLanguage={ this.props.data.languageReducer.lang }
                value={ this.props.newForm.name }
                onChange={ val => this.onChangeNewForm( 'name', val ) } />
            </FormGroup>
            <Row>
              <Col md={ 6 }>
                <FormGroup>
                  <Label>{ Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ] }</Label>
                  <Input
                    type="select"
                    value={ this.props.newForm.status }
                    onChange={ e => this.onChangeNewForm( 'status', e.target.value ) }>
                    <option value={ 'ACTIVE' }>{ Lang[ 'ACTIVE' ][ this.props.data.languageReducer.lang ] }</option>
                    <option value={ 'INACTIVE' }>{ Lang[ "INACTIVE" ][ this.props.data.languageReducer.lang ] }</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={ 6 }>
                <FormGroup>
                  <Label>{ Lang[ 'LANGUAGE' ][ this.props.data.languageReducer.lang ] }</Label>
                  <Input
                    type="select"
                    value={ this.state.currentLanguage }
                    onChange={ e => {
                      this.setState({ currentLanguage: e.target.value })
                    }}>
                    <option value={ 'en' }>en</option>
                    <option value={ 'de' }>de</option>
                    <option value={ 'nl' }>nl</option>
                    <option value={ 'it' }>it</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={ 6 }>
                <FormGroup>
                  <Label>{ Lang[ 'BACKGROUND_COLOR' ][ this.props.data.languageReducer.lang ] }</Label>
                  <SketchPicker
                    color={ this.props.newForm.background_color }
                    onChangeComplete={ ( color ) => {
                      let tmpColor = color.rgb
                      this.onChangeNewForm( 'background_color', `rgba(${ tmpColor.r },${ tmpColor.g },${ tmpColor.b },${ tmpColor.a })` )
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={ 6 }>
                <Card>
                  <CardHeader>{ Lang[ 'FORM_END_BUTTON' ][ this.props.data.languageReducer.lang ]}</CardHeader>
                  <CardBody>
                    <Button 
                      color='primary' 
                      style={{ width: "100%" }} 
                      onClick={ () => this.props.onChangeTempComponent( "showSelectButtonModal", true ) }>
                      { Lang[ 'CLICK_HERE_TO_ENABLE_EDITOR' ][ this.props.data.languageReducer.lang ] }
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row>
        <DragDropContext onDragEnd={ this.onDragEnd }>
          <Droppable droppableId="droppable">
            {( provided, snapshot ) => (
              <div
                { ...provided.droppableProps }
                ref={ provided.innerRef }
                className="col-md-12"
                style={ getListStyle( snapshot.isDraggingOver )}>
                {
                  this.state.dom.map( ( item ) => {
                    return RenderDOM(
                      item,
                      this.onChangeFormBuilderState,
                      'edit',
                      this.state.formValues,
                      this.onPatchFormValues,
                      () => {},
                      () => {},
                      this.state.dom,
                      this.findObjectAndPatch,
                      this.state.currentLanguage
                    )
                  })
                }
                { provided.placeholder }
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Row>
      <ButtonConfigModal 
        { ...this.props } 
        selectedLanguage={ this.props.data.languageReducer.lang }
        redirectInfo={ this.state.redirectInfo }
        onChangeRedirectInfo={( val ) => this.setState({ redirectInfo: val })} /> 
    </>
  )

  render = () => {
    return (
      <Modal
        isOpen={ this.props.showCreateForm }
        size={ 'xl' }
        toggle={ () => {
          this.props.onChangeFormsHOC( 'showCreateForm', false )
        }}>
        <ModalHeader
          toggle={ () => this.props.onChangeFormsHOC( 'showCreateForm', false )}>
          { Lang[ 'CREATE_FORM' ][ this.props.data.languageReducer.lang ] }
        </ModalHeader>
        <ModalBody>
          { this.renderGeneralTab() }
          {
            !_.isEmpty( this.state.selectedComponentToEdit ) && (
              <UpdateFormFrame
                showUpdateFormFrame={ this.state.showUpdateFormFrame }
                selectedComponentToEdit={ this.state.selectedComponentToEdit }
                onChangeFormBuilderState={ this.onChangeFormBuilderState }
                findObjectAndPatch={ this.findObjectAndPatch }
                dom={ this.state.dom }
                currentLanguage={ this.state.currentLanguage }
                selectedLanguage={ this.props.data.languageReducer.lang } />
            )
          }
          <UpdateContentModal
            showUpdateContentModal={ this.state.showUpdateContentModal }
            selectedParentComponentToEdit={ this.state.selectedParentComponentToEdit }
            selectedComponentToEdit={ this.state.selectedComponentToEdit }
            onChangeFormBuilderState={ this.onChangeFormBuilderState }
            findObjectAndPatch={ this.findObjectAndPatch }
            findObjectById={ this.findObjectById }
            dom={ this.state.dom }
            foundObject={ this.state.foundObject }
            formValues={ this.state.formValues }
            currentLanguage={ this.state.currentLanguage }
            selectedLanguage={ this.props.data.languageReducer.lang } />
          { 
            this.state.formValues.length > 0 && 
              <UpdateColumnModal
                showUpdateColumnModal={ this.state.showUpdateColumnModal }
                selectedComponentToEdit={ this.state.selectedComponentToEdit }
                onChangeFormBuilderState={ this.onChangeFormBuilderState }
                findObjectAndPatch={ this.findObjectAndPatch }
                dom={ this.state.dom }
                formValues={ this.state.formValues }
                site={ this.props.site }
                currentLanguage={ this.state.currentLanguage }
                selectedLanguage={ this.props.data.languageReducer.lang }
                backgroundColor={ this.props.newForm.background_color } /> 
          }
          <DeletePromptModal
            showPromptModal={ this.state.showConfirmDelete }
            content={ 'Are you sure to remove selected component?' }
            onClickYes={ () => this.onClickRemoveColumn() }
            onClickNo={ () => this.setState({ showConfirmDelete: false, selectedComponentToRemove: {} }) }
            selectedLanguage={ this.props.data.languageReducer.lang } />
          { this.props.onLoadForms && <LoadingOverlay/> }
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={ async () => {
              let tmpDOM = _.cloneDeep( this.state.dom )
              this.findExtraButtonsAndRemove( tmpDOM )
              let tmpFormValues = _.cloneDeep( this.state.formValues )
              let tmpRearrangedFormValues = []
              let i = 0
              let j = 0

              tmpFormValues = _.filter( 
                tmpFormValues, 
                item => !_.isEmpty( item ) && _.findIndex( this.state.componentsRemoved, { id: item.id } ) === -1
              )
              tmpDOM[0].children.map( ( item, index ) => {
                if( item.children[0].children[0].children.length < 1 ){
                  tmpDOM[0].children.splice( index, 1 )
                }
              })
              tmpDOM[0].children.map( ( item, index ) => {
                tmpDOM[0].children[index].page = index + 1
              })

              for( i = 0; i < tmpDOM[0].children.length; i++ ){
                await this.findTargetObject( tmpDOM, 'form-content', i+1 )
                let tmpFormContent = _.clone( this.state.foundObject )
                let tmpFormContentChildren = _.cloneDeep( this.state.foundObject.children )

                tmpFormContentChildren = _.filter( tmpFormContentChildren, child => child.children.length > 0 )
                tmpFormContentChildren.map( item => {
                  item.children[0].dataMapIndex = j
                  j = j + 1
                })

                tmpFormContentChildren.map( ( item ) => {
                  let tmpFormVal = tmpFormValues.find( x => x.id == item.children[0].id )
                  tmpRearrangedFormValues.push( tmpFormVal )
                })

                tmpFormContent.children = tmpFormContentChildren
                await this.findObjectAndPatch( tmpDOM, tmpFormContent )
              }

              this.addSubmitButton( tmpDOM )
              if( Object.keys( this.props.newForm.name ).some( key => !_.isEmpty( this.props.newForm.name[ key ]))){
                if( tmpRearrangedFormValues.length > 0 ) {
                  this.props.createForm({
                    ...this.props.newForm,
                    content: tmpDOM ,
                    values: tmpRearrangedFormValues
                  })
                } else {
                  alert( Lang[ 'FORM_IS_EMPTY_UNABLE_TO_SUBMIT' ][ this.props.data.languageReducer.lang ] )
                }
              } else {
                alert( Lang[ 'NAME_IS_REQUIRED' ][ this.props.data.languageReducer.lang ] )
              }
            }}>{ Lang[ 'SAVE' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({ data: state })

export default compose(
  connect( mapStateToProps, { toggleMediaDatabase } )
)( Create )
