import React, { Component } from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  Button
} from 'reactstrap'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { FaTrash, FaRegEdit } from 'react-icons/fa' 
import { findIdAndAssign } from '../utility/ObjectPatcher'
import Lang from 'Lang/General'

class ComponentUtility extends Component {
  render = () => {
    return (
      <>
        {
          ( 
            this.props.selectedSection.type !== 'carousel' &&
            (
              !this.props.selectedEditableComponent.className ||
              ( this.props.selectedEditableComponent.className && this.props.selectedEditableComponent.className.indexOf( 'col' ) === -1 )
            )
          ) ? (
            <Card className="mb-2">
              <CardHeader>{ Lang[ 'UTILITY' ][ this.props.lang ] }</CardHeader>
              <CardBody>
                <Button
                  color="danger"
                  onClick={() => {
                    let tmp = _.cloneDeep( this.props.selectedSection )
                    this.props.findComponentAndRemove( this.props.selectedEditableComponent, tmp )
                    Promise.all([
                      this.props.onChangeSectionHOC( 'selectedSection', tmp ),
                      this.props.onChangeSectionEditorState( 'selectedEditableComponent', {} )
                    ])
                  }}>
                  <FaTrash style={{ color: '#fff' }} />
                </Button>
              </CardBody>
            </Card>
          ) : (
            <>
              {
                this.props.selectedSection.type === 'carousel' && this.props.selectedSection.isShowCarousel !== false && (
                  <Card className="mb-2">
                    <CardHeader>{ Lang[ 'UTILITY' ][ this.props.lang ] }</CardHeader>
                    <CardBody>
                      <Button
                        className='mr-2'
                        color="danger"
                        onClick={() => {
                          let tmp = _.cloneDeep( this.props.selectedSection )
                          if( tmp.carouselItems.length > 1 ) {
                            tmp.carouselItems.splice( this.props.carouselPage, 1 )
                            if( tmp.carouselItems.length < 2 ){ tmp[ 'arrows' ] = false }
                            this.props.onUpdateCarouselPage( 0 )
                            return this.props.onChangeSectionHOC( 'selectedSection', tmp )
                          } else {
                            toast.error( Lang[ 'CAROUSEL_MIN_LIMIT_ERROR_MSG' ][ this.props.lang ] )
                          }
                        }}>
                        { Lang[ 'REMOVE_SLIDE' ][ this.props.lang ] }
                      </Button>
                      <Button
                        color="success"
                        onClick={ () => {
                          let tmp = _.cloneDeep( this.props.selectedSection )
                          let tmpSlide = _.cloneDeep( tmp.carouselItems[ this.props.carouselPage ] )
                          if( !tmpSlide.dom || ( tmpSlide.dom && _.isEmpty( tmpSlide.dom ) ) ){
                            tmpSlide.caption.title = {
                              style: tmpSlide.caption.title.style,
                              en: 'Slide Title',
                              de: 'Dia titel',
                              it: 'Titolo della diapositiva',
                              nl: 'Dia titel',
                            }
                            tmpSlide.caption.description = {
                              style: tmpSlide.caption.description.style,
                              en: 'Slide Description',
                              de: 'Folie Beschreibung',
                              it: 'Descrizione della diapositiva',
                              nl: 'Dia-beschrijving',
                            }
                          }
                          findIdAndAssign( tmpSlide )
                          tmp.carouselItems.splice( this.props.carouselPage + 1 , 0, tmpSlide )
                          tmp[ 'arrows' ] = true
                          toast.success( Lang[ 'SLIDE_ADDED' ][ this.props.lang ]  )
                          this.props.onUpdateCarouselPage( 0 )
                          return this.props.onChangeSectionHOC( 'selectedSection', tmp )
                        }}>
                        { Lang[ 'ADD_SLIDE' ][ this.props.lang ] }
                      </Button>
                      {
                        this.props.selectedSection.variant === 'text' && (
                          <Button
                            color="primary"
                            onClick={() => {
                              this.props.onChangeSectionEditorState( 'showCodeTagEditor', true )
                            }}>
                            <FaRegEdit style={{ color: '#fff' }} />
                          </Button>
                        )
                      }
                    </CardBody>
                  </Card>
                )
              }
            </>
          )
        }
      </>
    )
  }
}

export default ComponentUtility