import React, { useState } from 'react'
import { 
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button, Form, FormGroup, Label, Input
} from 'reactstrap'
import uniqid from 'uniqid'
import _ from 'lodash'
import Loader from 'react-loaders'

import Lang from 'Lang/General'
import { convertObjToBase64 } from "utils/objToBase64"

import { ComponentTypes } from './data'

const CreateColumn = props => {
  const [ col, updateCol ] = useState( 12 )
  const [ selectedComponentId, updateSelectedComponentId ] = useState( "" )
  const [ outputComponent, updateOutputComponent ] = useState( {} )
  const PatchUniqueIds = data => {
    data.id = uniqid()
    if( data.children && data.children.length > 0 ) {
      data.children.map( item => {
        PatchUniqueIds( item )
      })
    }
  }
  return (
    <Modal
      isOpen={ props.showCreateColumn }
      size={ 'md' }>
      <ModalHeader 
        toggle={ () => props.toggleShowCreateColumn( false ) }>
        { Lang[ 'CREATE_CONTENT' ][ props.selectedLanguage ] }
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <FormGroup>
            <Label>{ Lang[ 'ADD_COMPONENT_TYPE' ][ props.selectedLanguage ] }</Label>
            <Input
              type="select"
              value={ selectedComponentId }
              onChange={ e => {
                updateSelectedComponentId( e.target.value )
                let tmpComponentType = _.find( ComponentTypes, { id: e.target.value })
                if( tmpComponentType.id === 'btn' ) {
                  props.getComponents( convertObjToBase64({
                    "filter": { "$or": [ { "category": "button" } ] }
                  }))
                } else if( tmpComponentType.id === 'form' ) {
                  props.getForms({ page: 1, is_paginated: false })
                } else{
                  tmpComponentType.value.id = uniqid()
                }
                updateOutputComponent( tmpComponentType )
              }}>
              <option></option>
              {
                _.filter( ComponentTypes, ( e ) => {
                  return props.availableComponent ? props.availableComponent.includes( e.id ) : [ 'text', 'img', 'mediathek' ].includes( e.id ) 
                }).map( ( item ) => {
                  return <option key={ item.id } value={ item.id }>{ item.name[ props.selectedLanguage ] }</option>
                })
              }
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>{ Lang[ 'SELECT_COLUMN_WIDTH' ][ props.selectedLanguage ] }</Label>
            <Input
              type="select"
              value={ col }
              onChange={ e => {
                updateCol( parseInt( e.target.value, 10 ) )
              }}>
              <option></option>
              <option value={ 3 }>25%</option>
              <option value={ 4 }>33%</option>
              <option value={ 6 }>50%</option>
              <option value={ 8 }>67%</option>
              <option value={ 9 }>75%</option>
              <option value={ 12 }>100%</option>
            </Input>
          </FormGroup>
            {
              ( outputComponent.id === 'btn' ) && (
                <>
                  <FormGroup>
                    <Label>{ Lang[ 'SELECT_FROM_TEMPLATE' ][ props.selectedLanguage ] }</Label>
                    <div className='row'>
                    {
                      props.onLoadComponents
                        ? (
                          <div className="text-center col-12 pt-3">
                            <Loader type="ball-pulse-rise"/>
                          </div>
                        )
                        : props.components.map( ( item ) => {
                          return (
                            <div className='col-sm-6' >
                              <button 
                                key={ item.id }
                                className='w-100'
                                style={{
                                  border: props.selectedTempComponent.id === item.id ? '2px solid green' : 'none',
                                  marginRight: '15px',
                                  height: item.category === 'button' ? "100px" : 'initial'
                                }}
                                onClick={ () => props.onChangeTempComponent( 'selectedTempComponent', item ) }>
                                <img src={ item.img } style={{ width: "100%", height: "100%" }} />
                              </button>
                            </div>
                          )
                        })
                    }
                    </div>
                  </FormGroup>
                </>
              )
            }
            {
              outputComponent.id === 'form' && (
                <FormGroup>
                  <Label>{ Lang[ 'SELECT_SITE_FORM' ][ props.selectedLanguage ] }</Label>
                  {
                    props.onLoadForms
                      ? <p>{ Lang[ 'LOADING_SITE_FORMS' ][ props.selectedLanguage ] }</p>
                      : <Input 
                        type="select" 
                        onChange={ ( e ) => {
                          props.getSelectedForm( e.target.value )
                        }}
                        value={ props.selectedForm.id }>
                        <option></option>
                        {
                          props.forms.data.map( ( item ) => {
                            return (
                              <option key={ item.id } value={ item.id }>
                                { item.name[ props.selectedLanguage ] }
                              </option>
                            )
                          })
                        }
                      </Input>
                  }
                </FormGroup>
              )
            }
        </Form>
      </ModalBody>
      <ModalFooter>
        {
          ( outputComponent.id === 'img' || outputComponent.id === 'text' ) && (
            <Button 
              color="primary"
              onClick={() => {
                let tmp = {}
                let tmpDOM = _.cloneDeep( props.dom )
                tmp = {
                  id: uniqid(),
                  type: "div",
                  className: `col-sm-${ col }`,
                  col: col,
                  children: [],
                  style: { 
                    minHeight: '100px', 
                    position: 'relative', 
                    padding: '0px 0px 0px 0px',
                    margin: '0px 0px 0px 0px',
                    top: 0,
                    left: 0,
                    backgroundColor: ''
                  },
                  minHeight: 100,
                  paddingTop: '0px',
                  paddingRight: '0px',
                  paddingBottom: '0px',
                  paddingLeft: '0px',
                  marginTop: '0px',
                  marginRight: '0px',
                  marginBottom: '0px',
                  marginLeft: '0px',
                  top: 0,
                  left: 0,
                  isContentCenter: false,
                  isColumnContainer: true,
                  isFloat: false
                }
                PatchUniqueIds( outputComponent.value )
                tmp.children.push( outputComponent.value )
                tmpDOM = props.AddComponent( tmpDOM, props.selectedCol, tmp )
                props.updateDom( tmpDOM )
                props.updateSelectedCol( tmp )
                props.updateSelectedColId( tmp.id )
              }}>
              { Lang[ 'SUBMIT' ][ props.selectedLanguage ] }
            </Button>
          )
        }
        {
          ( outputComponent.id === 'mediathek' ) && (
            <Button 
              color="primary"
              onClick={() => {
                let tmp = {}
                let tmpDOM = _.cloneDeep( props.dom )
                tmp = {
                  id: uniqid(),
                  type: "div",
                  className: `col-sm-${ col }`,
                  col: col,
                  children: [],
                  style: { 
                    minHeight: '100px', 
                    position: 'relative', 
                    padding: '0px 0px 0px 0px',
                    margin: '0px 0px 0px 0px',
                    top: 0,
                    left: 0,
                    backgroundColor: ''
                  },
                  minHeight: 100,
                  paddingTop: '0px',
                  paddingRight: '0px',
                  paddingBottom: '0px',
                  paddingLeft: '0px',
                  marginTop: '0px',
                  marginRight: '0px',
                  marginBottom: '0px',
                  marginLeft: '0px',
                  top: 0,
                  left: 0,
                  isContentCenter: false,
                  isColumnContainer: true,
                  isFloat: false
                }
                PatchUniqueIds( outputComponent.value )
                tmp.children.push( outputComponent.value )
                tmpDOM = props.AddComponent( tmpDOM, props.selectedCol, tmp )
                props.updateDom( tmpDOM )
                props.updateSelectedCol( tmp )
                props.updateSelectedColId( tmp.id )
              }}>
              { Lang[ 'SUBMIT' ][ props.selectedLanguage ] }
            </Button>
          )
        }
        {
          outputComponent.id === 'btn' && (
            <Button 
              color="primary"
              disabled={ _.isEmpty( props.selectedTempComponent ) }
              onClick={ () => {
                let tmp = {}
                let tmpDOM = _.cloneDeep( props.dom )
                tmp = {
                  id: uniqid(),
                  type: "div",
                  className: `col-sm-${ col }`,
                  col: col,
                  children: [],
                  style: { 
                    minHeight: '100px', 
                    position: 'relative', 
                    padding: '0px 0px 0px 0px',
                    margin: '0px 0px 0px 0px',
                    top: 0,
                    left: 0,
                    backgroundColor: ''
                  },
                  minHeight: 100,
                  paddingTop: '0px',
                  paddingRight: '0px',
                  paddingBottom: '0px',
                  paddingLeft: '0px',
                  marginTop: '0px',
                  marginRight: '0px',
                  marginBottom: '0px',
                  marginLeft: '0px',
                  top: 0,
                  left: 0,
                  isContentCenter: false,
                  isColumnContainer: true,
                  isFloat: false
                }
                let tmpContent = _.cloneDeep( props.selectedTempComponent.content )
                PatchUniqueIds( tmpContent )
                tmp.children.push( tmpContent )
                tmpDOM = props.AddComponent( tmpDOM, props.selectedCol, tmp )
                props.updateDom( tmpDOM )
                props.updateSelectedCol( tmp )
                props.updateSelectedColId( tmp.id )
                updateCol( 12 )
                updateSelectedComponentId('')
                updateOutputComponent({})
              }}>
              { Lang[ 'SUBMIT' ][ props.selectedLanguage ] }
            </Button>
          )
        }
        {
          outputComponent.id === 'form' && (
            <Button 
              color="primary"
              disabled={ _.isEmpty( props.selectedForm ) }
              onClick={ () => {
                let tmp = {}
                let tmpDOM = _.cloneDeep( props.dom )
                tmp = {
                  id: uniqid(),
                  type: "div",
                  className: `col-sm-${ col }`,
                  col: col,
                  children: [
                    {
                      "id": uniqid(),
                      "type": "templateForm",
                      "formId": props.selectedForm.id,
                      "name": props.selectedForm.name
                    }
                  ],
                  style: { 
                    minHeight: '100px', 
                    position: 'relative', 
                    padding: '0px 0px 0px 0px',
                    margin: '0px 0px 0px 0px',
                    top: 0,
                    left: 0,
                    backgroundColor: ''
                  },
                  minHeight: 100,
                  paddingTop: '0px',
                  paddingRight: '0px',
                  paddingBottom: '0px',
                  paddingLeft: '0px',
                  marginTop: '0px',
                  marginRight: '0px',
                  marginBottom: '0px',
                  marginLeft: '0px',
                  top: 0,
                  left: 0,
                  isContentCenter: false,
                  isColumnContainer: true,
                  isFloat: false
                }
                tmpDOM = props.AddComponent( tmpDOM, props.selectedCol, tmp )
                props.updateDom( tmpDOM )
                props.updateSelectedCol( tmp )
                props.updateSelectedColId( tmp.id )
                updateCol( 12 )
                updateSelectedComponentId('')
                updateOutputComponent({})
              }}>
              { Lang[ 'SUBMIT' ][ props.selectedLanguage ] }
            </Button>
          )
        }
      </ModalFooter>
    </Modal>
  )
}

export default CreateColumn