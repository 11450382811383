import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import StatusModal from 'components/Modal/status'

import { Get, Post, Put } from 'utils/axios-template'
import { production_url } from '../../TemplateEditor/config'

import Lang from 'Lang/General'

const SiteTemplateHOC = ( WrappedComponent ) => {
  class SiteTemplateWrappedComponent extends Component {
    state = {
      loading: false,
      showStatusModal: false,
      statusModalType: '',
      onClickStatusModalButton: () => {},
      statusModalMessage: '',
      centerGroupForSelect: [],
      errorMessage: {},
      showCreateSiteTemplateDialog: false,
      showEditSiteTemplateDialog: false,
      newTemplate: {
        name: '',
        preview: ''
      },
      templates: [],
      selectedTemplate: {
        name: '',
        preview: ''
      }
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showStatusModal: true,
      statusModalType: 'negative',
      onClickStatusModalButton: () => this.setState({ showStatusModal: false }),
      statusModalMessage: error
    })
    requestSuccess = success => this.setState({
      showStatusModal: true,
      statusModalType: 'positive',
      onClickStatusModalButton: () => this.setState({ showStatusModal: false }),
      statusModalMessage: success
    })

    updateShowCreateSiteTemplateDialog = param => this.setState({ showCreateSiteTemplateDialog: param })
    updateNewTemplate = param => this.setState({ newTemplate: param })
    updateShowEditSiteTemplateDialog = param => this.setState({ showEditSiteTemplateDialog: param })
    updateSelectedTemplate = param => this.setState({ selectedTemplate: param })

    getSiteTemplate = () => Get(
      `${production_url}/templates`,
      this.getSiteTemplateSuccess,
      this.getSiteTemplateError,
      this.load
    )
    getSiteTemplateSuccess = payload => {
      this.setState({ templates: payload })
    }
    getSiteTemplateError = error => this.requestError( error )

    createSiteTemplate = ( data ) => Post(
      `${production_url}/templates`,
      data,
      this.createSiteTemplateSuccess,
      this.createSiteTemplateError,
      this.load
    )
    createSiteTemplateSuccess = payload => {
      this.requestSuccess( Lang[ 'TEMPLATE_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getSiteTemplate()
      this.updateShowCreateSiteTemplateDialog( false )
    }
    createSiteTemplateError = error => this.requestError( error )

    getSelectedTemplateEdit = id => this.getSelectedTemplate( id )

    getSelectedTemplate = id => Get(
      `${production_url}/templates/${ id }`,
      payload => this.getSelectedTemplateSuccess( payload ),
      this.getSelectedTemplateError,
      this.load
    )
    getSelectedTemplateSuccess = payload => this.setState({ selectedTemplate: payload.template }, () => {
      this.updateShowEditSiteTemplateDialog( true )
    })
    getSelectedTemplateError = error => this.requestError( error )

    updateSiteTemplate = ( id, data ) => Put(
      `${production_url}/templates/${ id }`,
      data,
      this.updateTemplateSuccess,
      this.updateTemplateError,
      this.load
    )
    updateTemplateSuccess = payload => {
      this.getSiteTemplate()
      this.updateShowEditSiteTemplateDialog( false )
    }
    updateTemplateError = error => this.requestError( error )

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            { ...this.props }
            getSiteTemplate={ this.getSiteTemplate }
            createSiteTemplate={ this.createSiteTemplate }
            updateShowCreateSiteTemplateDialog={ this.updateShowCreateSiteTemplateDialog }
            updateNewTemplate={ this.updateNewTemplate }
            onLoadSiteTemplate={ this.state.loading }
            showCreateSiteTemplateDialog={ this.state.showCreateSiteTemplateDialog }
            newTemplate={ this.state.newTemplate }
            templates={ this.state.templates }
            selectedTemplate={ this.state.selectedTemplate }
            updateSelectedTemplate={ this.updateSelectedTemplate }
            showEditSiteTemplateDialog={ this.state.showEditSiteTemplateDialog }
            updateShowEditSiteTemplateDialog={ this.updateShowEditSiteTemplateDialog }
            getSelectedTemplateEdit={ this.getSelectedTemplateEdit }
            updateSiteTemplate={ this.updateSiteTemplate } />
          <StatusModal
            isOpen={ this.state.showStatusModal }
            type={ this.state.statusModalType }
            message={ this.state.statusModalMessage }
            onClick={ this.state.onClickStatusModalButton } />
        </Fragment>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( SiteTemplateWrappedComponent )
}

export default SiteTemplateHOC
