import React from 'react'
import { connect } from 'react-redux'
import {
  Modal, ModalHeader, ModalBody, Label, Row, Col
} from 'reactstrap'
import Moment from 'moment'

import Lang from 'Lang/General'
import SettingsForm from './components/Settings'
import BookedCampaignForm from './components/BookedCampaign'
import OnetimerBillingForm from './components/OnetimerBilling'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import LandingPages from './LandingPages'


const EditForm = props => {
  let {
    showEdit,
    onChangeBookedCampaignHOC,
    selectedBookedCampaign,
    consultants,
    updateBookedCampaign,
    updateSettings,
    onLoadSelectedBookedCampaign,
    getSelectedBookedCampaign,
    languageReducer
  } = props

  return(
    <Modal
      isOpen={ showEdit }
      toggle={ () => onChangeBookedCampaignHOC( 'showEdit', false ) }
      size="xl">
      <ModalHeader
        toggle={ () => onChangeBookedCampaignHOC( 'showEdit', false ) }>{ Lang[ 'EDIT_BOOKED_CAMPAIGN' ][ languageReducer.lang ] }</ModalHeader>
      <ModalBody>
        <Row>
          <Col md={ 6 }>
            <div className="p-3 border border-dark">
              <Label className="h5">{ 'Campaign Info' }</Label>
              <table className="table table-bordered">
                <tr>
                  <td>{ Lang[ 'TITLE' ][ languageReducer.lang ] }</td>
                  <td>{ selectedBookedCampaign.campaign.title }</td>
                </tr>
                <tr>
                  <td>{ Lang[ 'CREATED_ON' ][ languageReducer.lang ] }</td>
                  <td>{ Moment( selectedBookedCampaign.campaign.created_at ).format( 'DD-MM-YYYY' ) }</td>
                </tr>
                <tr>
                  <td>{ Lang[ 'CENTER' ][ languageReducer.lang ] }</td>
                  <td>{ selectedBookedCampaign.campaign.center }</td>
                </tr>
              </table>
            </div>
            <SettingsForm
              selectedBookedCampaign={ selectedBookedCampaign }
              onChangeBookedCampaignHOC={ onChangeBookedCampaignHOC }
              consultants={ consultants }
              updateSettings={ updateSettings } />
          </Col>
          <Col md={ 6 }>
            {
              !selectedBookedCampaign.settings_client && (
                <div className="p-3 border border-dark">
                  <Label className="h5">{ 'Consultant Info' }</Label>
                  <table className="table table-bordered">
                    <tr>
                      <td>{ Lang[ 'FULL_NAME' ][ languageReducer.lang ] }</td>
                      <td>{ `${ selectedBookedCampaign.consultant.first_name } ${ selectedBookedCampaign.consultant.last_name }` }</td>
                    </tr>
                    <tr>
                      <td>{ Lang[ 'EMAIL' ][ languageReducer.lang ] }</td>
                      <td>{ selectedBookedCampaign.consultant.email }</td>
                    </tr>
                    <tr>
                      <td>{ Lang[ 'CREATED_ON' ][ languageReducer.lang ] }</td>
                      <td>{ Moment( selectedBookedCampaign.consultant.created_at ).format( 'DD-MM-YYYY' ) }</td>
                    </tr>
                    <tr>
                      <td>{ Lang[ 'PHONE' ][ languageReducer.lang ] }</td>
                      <td>{ selectedBookedCampaign.consultant.phone_number }</td>
                    </tr>
                    <tr>
                      <td>{ Lang[ 'PHOTO' ][ languageReducer.lang ] }</td>
                      <td><img src={ selectedBookedCampaign.consultant.photo_url } style={{ width: '100%' }} /></td>
                    </tr>
                  </table>
                </div>
              )
            }
            <LandingPages
              selectedBookedCampaign={ selectedBookedCampaign }
              getSelectedBookedCampaign={ getSelectedBookedCampaign } />
            <BookedCampaignForm { ...props } />
            <OnetimerBillingForm
              selectedBookedCampaign={ selectedBookedCampaign }
              onChangeBookedCampaignHOC={ onChangeBookedCampaignHOC }
              updateBookedCampaign={ updateBookedCampaign } />
          </Col>
        </Row>
        { onLoadSelectedBookedCampaign && <LoadingOverlay /> }
      </ModalBody>
    </Modal>
  )
}
const mapStateToProps = state => ({
  languageReducer: state.languageReducer
})
export default connect( mapStateToProps )( EditForm )
