import React, { useState } from 'react'
import { 
  Card, CardHeader, CardBody, CardFooter,
  Button, UncontrolledTooltip 
} from 'reactstrap'
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import uniqid from 'uniqid'
import _ from 'lodash'

import DeleteConfirmation from 'components/Modal/delete'
import Lang from 'Lang/General'

const checkItemType = ( item, selectedLanguage ) => {
  if ( item.variant && item.variant === 'text' ) {
    return({ name: Lang[ 'TEXT' ][ selectedLanguage ], hex: '#292b2c' })
  } else if( item.type ) {
    switch( item.type ){
      case 'img':
        return({ name: Lang[ 'IMAGE' ][ selectedLanguage ], hex: '#5e458e' })
      case 'mediathek':
        return({ name: Lang[ 'VIDEO' ][ selectedLanguage ], hex: '#0275d8' })
      case 'button':
        return({ name: Lang[ 'BUTTON' ][ selectedLanguage ], hex: '#0275d8' })
      case 'templateForm':
        return({ name: Lang[ 'TEMPLATE_FORM' ][ selectedLanguage ], hex: '#0275d8' })
      case 'form':
        return({ name: Lang[ 'TEMPLATE_FORM' ][ selectedLanguage ], hex: '#0275d8' })
      case 'webForm':
        return({ name: Lang[ 'TEMPLATE_FORM' ][ selectedLanguage ], hex: '#0275d8' })
      default: 
        return({ name: Lang[ 'SECTION' ][ selectedLanguage ], hex: '#0275d8' })
    }
  } else {
    return({ name: Lang[ 'SECTION' ][ selectedLanguage ], hex: '#0275d8' })
  }
}

const SortableItem = SortableElement( props => {
  return <div 
    className={ `${ props.selectedComponent.id === props.item.id ? 'selected-container' : '' } mr-2 mb-2` }
    style={{ 
      width: 'fit-content',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      zIndex: '9999',
      background: checkItemType( props.item, props.selectedLanguage ).hex,
      color: '#ffffff',
      borderRadius: '3px',
      padding: '0.375rem 0.75rem'
    }}>
    { checkItemType( props.item, props.selectedLanguage ).name }
  </div>
})

const SortableList = SortableContainer( props => {
  return (  
    <div 
      className='col-12' 
      style={{ paddingRight: '0', position: 'relative' }} >
      { 
        props.items.map( ( item, index ) => (
          <SortableItem 
            key={ item.id } 
            index={ index } 
            item={ item } 
            selectedComponent={ props.selectedComponent }
            selectedLanguage={ props.selectedLanguage } 
          /> 
        )) 
      }
    </div>
  )
})

const DisplayChildren = props => {
  return (
    <>
      <div 
        style={{ 
          paddingBottom: '10px',
          display: 'flex',
          alignItems: 'center'
        }} >
        {
          props.selectedComponent.id === props.item.id ? (
            <Button 
              className="mr-2 selected-container"
              style={{ 
                border: "none",
                background: checkItemType( props.item, props.selectedLanguage ).hex
              }} >
              { `${ Lang[ 'CURRENTLY_SELECTED' ][ props.selectedLanguage ] }` }
            </Button>
          ) : (
            <Button 
              className="mr-2" 
              style={{ 
                border: "none",
                background: checkItemType( props.item, props.selectedLanguage ).hex
              }}
              onClick={ () => {
                props.updateSelectedComponentId( props.item.id )
                props.updateSelectedComponent( props.item )
              }}>
              { `${ Lang[ 'SELECT' ][ props.selectedLanguage ] }` }
            </Button>
          )
        }
        <span className='mr-2' >{ checkItemType( props.item, props.selectedLanguage ).name }</span>
        { 
          props.selectedComponent.id === props.item.id && <>
            <i 
              id={ `container-${ props.item.id }-duplicate` }
              className="pe-7s-copy-file btn-icon-wrapper mr-2" 
              onClick={ () => props.updateContainerToDuplicate({ index: props.index, item: props.item }) } />
            <UncontrolledTooltip
              target={ `container-${ props.item.id }-duplicate` }
              placement="top">
              { Lang[ 'DUPLICATE' ][ props.selectedLanguage ] }
            </UncontrolledTooltip>
            <i 
              id={ `container-${ props.item.id }-delete` }
              style={{ color: '#d9534f' }}
              className="pe-7s-trash btn-icon-wrapper mr-2" 
              onClick={ () => props.updateContainerToDelete({ index: props.index, item: props.item }) } />
            <UncontrolledTooltip
              target={ `container-${ props.item.id }-delete` }
              placement="top">
              { Lang[ 'DELETE' ][ props.selectedLanguage ] }
            </UncontrolledTooltip>
          </> 
        }
      </div>
    </>
  )
}

const ColumnNavigator = props => {
  const [ containerToDuplicate, updateContainerToDuplicate ] = useState({ index: 0, item: {} })
  const [ containerToDelete, updateContainerToDelete ] = useState({ index: 0, item: {} })
  const [ isRearranging, updateIsRearranging ] = useState( false )  

  const patchObjectID = param => {
    param.id = uniqid()
    if( param && param.children ) {
      param.children.map( item => {
        patchObjectID( item )
      })
    }
  }

  const duplicateComponent = containerToDuplicate => {
    let patchedItem = _.cloneDeep( containerToDuplicate.item )
    patchObjectID( patchedItem )
    let tmpColumn = _.cloneDeep( props.selectedCol )
    tmpColumn = props.AddComponent( tmpColumn, containerToDuplicate.item, patchedItem )
    props.updateSelectedCol( tmpColumn )
    let tmpContainer = _.cloneDeep( props.dom )
    tmpContainer = props.FindComponentAndPatch( tmpContainer, tmpColumn )
    props.updateDom( tmpContainer )
  }
  
  const removeComponent = containerToDuplicate => {
    let tmpColumn = _.cloneDeep( props.selectedCol )
    tmpColumn = props.FindComponentAndPatch( tmpColumn, containerToDuplicate.item, true )
    props.updateSelectedCol( tmpColumn || {} )
    let tmpContainer = _.cloneDeep( props.dom )
    tmpContainer = props.FindComponentAndPatch( 
      tmpContainer, 
      tmpColumn || props.selectedCol, 
      typeof tmpColumn === 'undefined' 
    )
    props.updateDom( tmpContainer )
    props.updateSelectedComponentId( '' )
    props.updateSelectedComponent( {} )
  }

  const reorderComponent = ( oldIndex, newIndex ) => {
    let tmp = _.find( props.selectedCol )
    const [ removed ] = tmp.children.splice( oldIndex, 1 )
    tmp.children.splice( newIndex, 0, removed )
    props.updateSelectedCol( tmp )
    let tmpContainer = _.cloneDeep( props.dom )
    tmpContainer = props.FindComponentAndPatch( tmpContainer, tmp )
    props.updateDom( tmpContainer )
  }

  return (
    <Card className='mb-3' >
      <CardHeader onClick={ () => props.updateView( props.view === 'navigator' ? '' : 'navigator' ) } >
        { Lang[ 'COLUMN_NAVIGATOR' ][ props.selectedLanguage ] }
      </CardHeader>
      {
        props.view === 'navigator' && <>
          <CardBody>
            <style>
              {
                `
                  @keyframes glowing {
                    0% {
                      background-color: ${ checkItemType( props.selectedComponent, props.selectedLanguage ).hex };
                      box-shadow: 0 0 3px ${ checkItemType( props.selectedComponent, props.selectedLanguage ).hex };
                    }
                    50% {
                      background-color: #49e819;
                      box-shadow: 0 0 10px #49e819;
                    }
                    100% {
                      background-color: ${ checkItemType( props.selectedComponent, props.selectedLanguage ).hex };
                      box-shadow: 0 0 3px ${ checkItemType( props.selectedComponent, props.selectedLanguage ).hex };
                    }
                  }

                  .selected-container { 
                    animation: glowing 3000ms infinite;
                  }
                `
              }
            </style>
            {
              props.selectedCol.children?.length > 0 && (
                !isRearranging ? (
                  props.selectedCol.children.map( ( child, index ) => {
                    return (
                      <DisplayChildren 
                        { ...props }
                        item={ child }
                        index={ index }
                        updateContainerToDuplicate={ updateContainerToDuplicate } 
                        updateContainerToDelete={ updateContainerToDelete } 
                      />
                    )
                  })
                ) : (
                  <SortableList
                    items={ props.selectedCol.children }
                    onSortEnd={ e => reorderComponent( e.oldIndex, e.newIndex ) }
                    axis='y' 
                    selectedComponent={ props.selectedComponent }
                    selectedLanguage={ props.selectedLanguage }
                  />
                )
              )
            }
            <DeleteConfirmation
              deleteOpen={ !_.isEmpty( containerToDuplicate.item ) }
              handleModalClose={ () => updateContainerToDuplicate({ index: 0, item: {} }) }
              confirmAction={ () => {
                duplicateComponent( containerToDuplicate )
                updateContainerToDuplicate({ index: 0, item: {} })
              }}
              content={ 
                `${ Lang[ 'DUPLICATE' ][ props.selectedLanguage ] } ${ 
                Lang[ 'SELECTED_COMPONENT' ][ props.selectedLanguage ].toLowerCase() }?` 
              }
              lang={ props.selectedLanguage } 
            />
            <DeleteConfirmation
              deleteOpen={ !_.isEmpty( containerToDelete.item ) }
              handleModalClose={ () => updateContainerToDelete({ index: 0, item: {} }) }
              confirmAction={ () => {
                removeComponent( containerToDelete )
                updateContainerToDelete({ index: 0, item: {} })
              }}
              content={ 
                `${ Lang[ 'DELETE' ][ props.selectedLanguage ] } ${ 
                Lang[ 'SELECTED_COMPONENT' ][ props.selectedLanguage ].toLowerCase() }?` 
              }
              lang={ props.selectedLanguage } 
            />
          </CardBody>
          <CardFooter>
            <Button
              color={ isRearranging ? 'danger' : 'primary' }
              style={{ marginLeft: 'auto' }}
              onClick={ () => updateIsRearranging( !isRearranging ) }>
              { Lang[ isRearranging ? 'STOP_CONTENT_REARRANGEMENT' : 'REARRANGE_CONTENT' ][ props.selectedLanguage ] }
            </Button>
          </CardFooter>
        </>
      }
    </Card>
  )
}

export default ColumnNavigator