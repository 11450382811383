import React from 'react'
import Radium from 'proofpoint-radium'
import ReactHTMLParser from 'react-html-parser'

import CoreRenderFunction from '../core/render'

const Link = ({
  id,
  item,
  mode,
  style,
  content,
  className,
  href,
  navTo,
  children,
  languages,
  selectedLanguageShortname,
  updateMainContainerState,
  hoveredComponentId,
  onChangeSectionEditorState,
  selectedEditableComponent
}) => {
  return (
    <>
      {
        className === 'header-website-logo' ? (
          <div
            id={ id }
            style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}
            className={ className }>
            {
              children && children.length > 0 && children.map( item => {
                return (
                  <CoreRenderFunction
                    item={ item }
                    mode={ mode }
                    selectedLanguageShortname={ selectedLanguageShortname }
                    onChangeSectionEditorState={ onChangeSectionEditorState } />
                )
              })
            }
          </div>
        ) : (
          <a
            id={ id }
            style={ mode === 'edit' 
              ? {
                ...style,
                ...(
                  ( ( selectedEditableComponent && selectedEditableComponent.id === id ) || hoveredComponentId === id  ) ? { 
                    border: "5px solid #45818e"
                  } : {}
                )
              }
              : style
            }
            className={ className }
            onMouseEnter={ () => mode === 'edit' && onChangeSectionEditorState && onChangeSectionEditorState( 'hoveredComponentId', id ) }
            onClick={() => {
              if ( mode === 'edit' ) {
                onChangeSectionEditorState( 'selectedEditableComponent', item )
              } 
            }}>
            {
              children && children.length > 0 && children.map( item => {
                return (
                  <CoreRenderFunction
                    item={ item }
                    mode={ mode }
                    updateMainContainerState={ updateMainContainerState }
                    languages={ languages }
                    selectedLanguageShortname={ selectedLanguageShortname }
                    onChangeSectionEditorState={ onChangeSectionEditorState } />
                )
              })
            }
            { 
              content && content[ selectedLanguageShortname ] 
                ? ReactHTMLParser( content[ selectedLanguageShortname ]) 
                : '' 
            }
          </a>
        )
      }
    </>
  )
}

export default Radium( Link )