import React from 'react'

import CoreRenderFunction from '../../core/render'

const FormFrame = ({
  content,
  mode,
  formValues,
  dom,
  selectedLanguageShortname 
}) => {
  return (
    <>
      <div
        style={{
          position: 'relative',
          border: `${ content.container.borderWidth }px solid ${ content.container.borderColor }`,
          minHeight: 'fit-content'
        }}>
        <div
          style={{
            ...content.title
          }} >
          { content.content[ selectedLanguageShortname  ] }
        </div>
        {
          content.children && content.children.map( item => {
            return <CoreRenderFunction
              item={ item }
              content={ item }
              mode={ mode } 
              formValues={ formValues }
              dom={ dom }
              selectedLanguageShortname ={ selectedLanguageShortname  } />
          })
        }
      </div>
    </>
  )
}

export default FormFrame