import React, { Component } from 'react'
import { toast } from 'react-toastify'
import Moment from 'moment'

import { Get } from 'utils/axios'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state = {
      loading: false,
      siteStatsSearchParams: {
        type: [ 'ACTIVE_SITES' ],
        period: 'LAST_4_WEEKS',
        year: Moment().year() - 1
      },
      siteStats: {
        active_sites: 0,
        test_sites: 0,
        cancelled_sites: 0,
        active_centers: 0,
        inactive_centers: 0,
        active_site_stats: [],
        inactive_center_stats: [],
        test_site_stats: [],
        cancelled_site_stats: [],
        active_center_stats: []
      }
    }

    load = param => this.setState({ loading: param })
    requestError = error => {
      typeof error === 'string'
        ? toast.error( error )
        : Object.values( error ).map( err => toast.error( err.replaceAll( '_', ' ' ) ) )
    }

    onChangeSiteStatsHOC = ( key, value ) => this.setState({ [ key ]: value })

    getSiteStats = () => Get(
      `/api/v1/site_stats?type=${ JSON.stringify( this.state.siteStatsSearchParams.type ) }&` + 
      `period_type=${ this.state.siteStatsSearchParams.period }&` + 
      `start_date=${ Moment( this.state.siteStatsSearchParams.year, 'YYYY' ).startOf( 'year' ).format( 'YYYY-MM-DD') }&` + 
      `end_date=${ Moment( this.state.siteStatsSearchParams.year, 'YYYY' ).endOf( 'year' ).format( 'YYYY-MM-DD') }`,
      this.getSiteStatsSuccess,
      this.getSiteStatsError,
      this.load
    )
    getSiteStatsSuccess = payload => this.setState({ siteStats: payload })
    getSiteStatsError = error => this.requestError( error )

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          onLoadSiteStatsHOC={ this.state.loading }
          siteStats={ this.state.siteStats }
          siteStatsSearchParams={ this.state.siteStatsSearchParams }
          onChangeSiteStatsHOC={ this.onChangeSiteStatsHOC }
          getSiteStats={ this.getSiteStats }
        />
      )
    }
  }
  return WithHOC
}

export default HOC