import React, { useState, useEffect } from 'react'
import { Card, CardHeader, CardBody } from 'reactstrap'
import _ from 'lodash'

import ColumnEdit from './Column'
import TextEdit from './Text'
import ImageEdit from './Image'
import Mediathek from './Mediathek'
import ButtonEdit from './Button'
import TemplateFormEdit from './TemplateForm'
import NewComponent from './NewComponent'
import ColumnNavigator from './ColumnNavigator'

import Lang from 'Lang/General'

const ConfigPanel = props => {
  const [ view, updateView ] = useState( '' )

  useEffect( () => {
    if( !props.showDomEditor ){
      updateView('')
    }
  },[ props.showDomEditor ])
  
  return !_.isEmpty( props.selectedColId ) ? (
    <>
      <NewComponent { ...props } />
      {
        ( !_.isEmpty( props.selectedComponent ) && props.selectedComponent.variant === 'text' ) && (
          <Card className='mb-3'>
            <CardHeader onClick={ () => view === 'text' ? updateView( '' ) : updateView( 'text' ) }>{ Lang[ 'TEXT' ][ props.selectedLanguage ] }</CardHeader>
            {
              view === 'text' && (
                <CardBody>
                  <TextEdit { ...props } />
                </CardBody>
              )
            }
          </Card>
        )
      }
      {
        ( !_.isEmpty( props.selectedComponent ) && props.selectedComponent.type === 'img' ) && (
          <Card className='mb-3'>
            <CardHeader onClick={() => view === 'image' ? updateView( '' ) : updateView( 'image' )}>{ Lang[ 'IMAGE' ][ props.selectedLanguage ] }</CardHeader>
            <CardBody>
              <ImageEdit { ...props } />
            </CardBody>
          </Card>
        )
      }
      {
        ( !_.isEmpty( props.selectedComponent ) && props.selectedComponent.type === 'mediathek' ) && (
          <Card className='mb-3'>
            <CardHeader onClick={() => view === 'mediathek' ? updateView( '' ) : updateView( 'mediathek' )}>{ Lang[ 'MEDIA_LIBRARY_SETTINGS' ][ props.selectedLanguage ] }</CardHeader>
            <CardBody>
              <Mediathek { ...props } />
            </CardBody>
          </Card>
        )
      }
      {
        ( !_.isEmpty( props.selectedComponent ) && props.selectedComponent.type === 'button' ) && (
          <Card className='mb-3'>
            <CardHeader onClick={() => view === 'button' ? updateView( '' ) : updateView( 'button' )}>{ Lang[ 'BUTTON' ][ props.selectedLanguage ] }</CardHeader>
            <CardBody>
              <ButtonEdit { ...props } />
            </CardBody>
          </Card>
        )
      }
      {
        ( 
          !_.isEmpty( props.selectedComponent ) && (
            props.selectedComponent.type === 'form' ||
            props.selectedComponent.type === 'webForm' ||
            props.selectedComponent.type === 'templateForm' 
          )
        ) && (
          <Card className='mb-3'>
            <CardHeader onClick={() => view === 'templateForm' ? updateView( '' ) : updateView( 'templateForm' )}>{ Lang[ 'TEMPLATE_FORM' ][ props.selectedLanguage ] }</CardHeader>
            <CardBody>
              <TemplateFormEdit { ...props } />
            </CardBody>
          </Card>
        )
      }
      <Card className="mb-3">
        <CardHeader onClick={() => view === 'column' ? updateView( '' ) : updateView( 'column' )}>{ Lang[ 'COLUMN' ][ props.selectedLanguage ] }</CardHeader>
        {
          view === 'column' && (
            <CardBody>
              <ColumnEdit { ...props } />
            </CardBody>
          )
        }
      </Card>
      <ColumnNavigator 
        { ...props }
        view={ view }
        updateView={ updateView } />
      {
        _.isEmpty( props.selectedColId ) && (
          <Card style={{ height: '100%' }} >
            <CardBody className='d-flex align-items-center text-center' >
              { Lang[ 'PLEASE_ADD_OR_SELECT_A_CONTENT_TO_START_EDITING' ][ props.selectedLanguage ] }
            </CardBody>
          </Card>
        )
      }
    </>
  ) : (
    <Card style={{ height: '100%' }} >
      <CardBody className='d-flex align-items-center text-center' >
        { Lang[ 'PLEASE_ADD_OR_SELECT_A_CONTENT_TO_START_EDITING' ][ props.selectedLanguage ] }
      </CardBody>
    </Card>
  )
}

export default ConfigPanel
