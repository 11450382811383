import React, { Component } from 'react'
import {
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  CustomInput
} from 'reactstrap'
import _ from 'lodash'
import { connect } from 'react-redux'
import ReactSelect from 'react-select'
import DatePicker from 'react-datepicker'

import Lang from 'Lang/General'

class AdvancedSearch extends Component {
  render = () => {
    return (
      <>
        <div className="d-flex flex-wrap pt-1">
          {
            this.props.searchColumn && this.props.searchColumn.length > 0 && this.props.searchColumn.map( (item, index ) => {
              return(
                <FormGroup className='mr-2' key={ index }>
                  <CustomInput
                    type='checkbox'
                    id={ item.val }
                    label={ item.name }
                    checked={ 
                      item.fields && item.fields.length > 0 
                        ? _.findIndex( this.props.selectedSearchColumn, { val: item.fields[ 0 ].val } ) > -1
                        : _.findIndex( this.props.selectedSearchColumn, { val: item.val } ) > -1 
                    }
                    onChange={ e => {
                      let tmp = _.cloneDeep( this.props.selectedSearchColumn )
                      if( item.fields && item.fields.length > 0 ) {
                        item.fields.map( field => {
                          if( _.find( tmp, { val: field.val } ) ) {
                            _.remove( tmp, { val: field.val } )
                          } else {
                            tmp && tmp.push({
                              ...field,
                              searchValue: ''
                            })
                          }
                        })
                      } else {
                        if( _.find( tmp, { val: item.val } ) ) {
                          _.remove( tmp, { val: item.val } )
                        } else {
                          tmp && tmp.push({
                            ...item,
                            searchValue: ''
                          })
                        }
                      }
                      this.props.onChange( 'selectedSearchColumn', tmp )
                    }} />
                </FormGroup>
              )
            })
          }
        </div>
        <Row>
        {
          this.props.selectedSearchColumn && this.props.selectedSearchColumn.length > 0 && this.props.selectedSearchColumn.map(( item, index ) => {
            return (
              <>
                {
                  item.type === 'text' && (
                    <Col md={ 6 }>
                      <FormGroup>
                        <Label>{ item.name }</Label>
                        <Input
                          name="search-value"
                          value={ item.searchValue }
                          onChange={ e => this.props.updateSelectedSearchColumn( item.val, e.target.value ) }/>
                      </FormGroup>
                    </Col>
                  )
                }
                {
                  item.val === 'status' && (
                    <Col md={ 6 }>
                      <FormGroup>
                        <Label for="exampleSelect">{ Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ] }</Label>
                        <Input
                          onChange={ e => {
                            this.props.updateSelectedSearchColumn( item.val, e.target.value )
                          }}
                          value={ item.searchValue }
                          type="select"
                          name="select"
                          id="exampleSelect">
                            <option>{ Lang[ 'PLEASE_SELECT_ONE' ][ this.props.data.languageReducer.lang ] }</option>
                            <option value='ACTIVE' >{ Lang[ 'ACTIVE' ][ this.props.data.languageReducer.lang ] }</option>
                            <option value='INACTIVE' >{ Lang[ 'INACTIVE' ][ this.props.data.languageReducer.lang ] }</option>
                            {
                              this.props.modulesUsed && this.props.modulesUsed === 'Trial' &&
                                <option>TRIAL</option>
                            }
                        </Input>
                      </FormGroup>
                    </Col>
                  )
                } 
                {
                  item.val === 'type' && (
                    <Col md={ 6 }>
                      <FormGroup>
                        <Label for="exampleSelect">Type</Label>
                        <Input
                          onChange={ e => {
                            this.props.updateSelectedSearchColumn( item.val, e.target.value )
                          }}
                          type="select"
                          name="select"
                          id="exampleSelect">
                          {
                            this.props.types && this.props.types.map( item => {
                              return(
                                <option value={ item.name } key={ item.id }>{ item.name }</option>
                              )
                            })
                          }
                        </Input>
                      </FormGroup>
                    </Col>
                  )
                }
                {
                  item.type === 'boolean' && (
                    <Col md={ 6 }>
                      <FormGroup>
                        <Label for="exampleSelect">{ Lang[ 'HAS_CONSULTANT' ][ this.props.data.languageReducer.lang ] }</Label>
                        <Input
                          onChange={ e => {
                            this.props.updateSelectedSearchColumn( item.val, e.target.value )
                          }}
                          value={ item.searchValue }
                          type="select"
                          name="select"
                          id="exampleSelect">
                            <option>{ Lang[ 'PLEASE_SELECT_ONE' ][ this.props.data.languageReducer.lang ] }</option>
                            <option value={true}>{ Lang[ 'YES' ][ this.props.data.languageReducer.lang ] }</option>
                            <option value={false}>{ Lang[ 'NO' ][ this.props.data.languageReducer.lang ] }</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  )
                }
                {
                  item.val !== 'status' && item.val !== 'type' && item.type === "dropdown" && (
                    <Col md={ 6 }>
                      <FormGroup>
                        <Label for="searchableDropdown">{ item.name }</Label>
                        <Input
                          type="select"
                          name="select"
                          value={ item.searchValue }
                          onChange={ e => this.props.updateSelectedSearchColumn( item.val, e.target.value ) }>
                          <option>{ Lang[ 'PLEASE_SELECT_ONE' ][ this.props.data.languageReducer.lang ] }</option>
                          {
                            item.dropdownOptions.map( option => {
                              return <option value={ option.value }>{ option.label }</option>
                            })
                          }
                        </Input>
                      </FormGroup>
                    </Col>
                  )
                }
                {
                  item.type === "searchableDropdown" && (
                    <Col md={ 6 }>
                      <FormGroup>
                        <Label for="searchableDropdown">{ item.name }</Label>
                        <ReactSelect
                          name="selectable"
                          options={ item.dropdownOptions }
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={ value => this.props.updateSelectedSearchColumn( item.val, value ) }
                          value={ item.searchValue }/>
                      </FormGroup>
                    </Col>
                  )
                }
                {
                  item.type === 'date' && (
                    <Col md={ 6 }>
                      <FormGroup>
                        <Label>{ item.name }</Label>
                        <DatePicker
                          className='form-control'
                          showMonthDropdown={ true }
                          dateFormat='yyyy-MM-dd'
                          selected={ item.searchValue }
                          onChange={ val => this.props.updateSelectedSearchColumn( item.val, val ) } />
                      </FormGroup>
                    </Col>
                  )
                }
              </>
            )
          })
        }
        </Row>
      </>
    )
  }
}
const mapStateToProps = state => ({ data: state })

export default connect( mapStateToProps )( AdvancedSearch )
