import _ from 'lodash'
import uniqid from 'uniqid'

const findCarouselObjectAndPatch = ( dom, value, section, selectedLanguageShortname ) => {
  section.carouselItems.map( item => {
    if( item.id === dom.id ) {
      if( dom.variant === 'caption-title' ) {
        item.caption.title[ selectedLanguageShortname ] = value
      } else if( dom.variant === 'caption-description' ) {
        item.caption.description[ selectedLanguageShortname ] = value
      }
    }
  })
}

const findNormalObjectAndPatch = ( dom, value, section, selectedLanguageShortname ) => {
  if( section.id === dom.id ) {
    section.content[ selectedLanguageShortname ] = value
  } else {
    if( section.children ) {
      section.children.map( item => {
        findNormalObjectAndPatch( dom, value, item, selectedLanguageShortname )
      })
    }
  }
}

export const findObjectAndPatch = ( updatedJSON, currentJSON ) => {
  currentJSON && currentJSON.children && currentJSON.children.map( item => {
    if( updatedJSON.id === item.id ) {
      item = updatedJSON
      let tmpIndex = _.findIndex( currentJSON.children, { id: item.id } )
      currentJSON.children.splice( tmpIndex, 1, item )
      return currentJSON
    } else {
      findObjectAndPatch( updatedJSON, item )
    }
  })
}

export default ( dom, value, selectedSection, selectedLanguageShortname ) => {
  let tmp = _.cloneDeep( selectedSection )
  if( dom.type === 'carousel' ) {
    findCarouselObjectAndPatch( dom, value, tmp, selectedLanguageShortname )
  } else {
    findNormalObjectAndPatch( dom, value, tmp, selectedLanguageShortname )
  }
  return tmp
}

export const findIdAndAssign = dom => {
  if ( 'id' in dom ) {
    dom.id = uniqid()
  }
  if ( dom.children ) {
    dom.children.map( item => {
      findIdAndAssign( item )
    })
  } else if ( dom.carouselItems ) {
    dom.carouselItems.map( item => {
      findIdAndAssign( item )
    })
  } else if ( dom.caption && dom.caption.children ) {
    dom.caption.children.map( item => {
      findIdAndAssign( item )
    })
  }
  if( dom.dom && dom.dom.children ){
    dom.dom.children.map( item => {
      findIdAndAssign( item )
    })
  }
}

export const patchObjectID = param => {
  param.id = uniqid()
  if( param && param.children ) {
    param.children.map( item => {
      patchObjectID( item )
    })
  }
}