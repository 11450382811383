import MultipleSearchSelect from "components/MultipleSearchSelect";
import _ from "lodash";
import React, { Component } from "react";
import ReactTable from "react-table";
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Col,
	Container,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	UncontrolledTooltip,
} from "reactstrap";
import { compose } from "redux";

import LoadingOverlay from "components/Indicator/LoadingOverlay";
import PageTitle from "components/PageTitle";
import SiteSectionHOC from "./actions";
import CategoryHOC from "./actions/category";
import AssignCategoryForm from "./components/assignCategoryForm";

import Lang from "Lang/General";
import JsonEditor from "./components/JsonEditor";

const TemplateList = [
	{
		label: "Fashion",
		value: "fashion",
	},
	{
		label: "Launch",
		value: "launch",
	},
	{
		label: "Vital",
		value: "vital",
	},
	{
		label: "INJOY New",
		value: "injoynew",
	},
	{
		label: "INJOY",
		value: "injoynew",
	},
	{
		label: "FT-CLUB",
		value: "ftclub",
	},
	{
		label: "Friendly coffee",
		value: "friendlycoffee",
	},
	{
		label: "Business casual",
		value: "businesscasual",
	},
	{
		label: "Sale",
		value: "sale",
	},
];
class SiteSectionSettings extends Component {
	state = {
		selectedSectionTemplate: "",
		selectedSectionName: "",
		hasChanges: false,
		contentJSONString: "",
	};

	componentDidMount = () => {
		let tmp = {
			name: "",
			section_categories: [0],
		};
		this.props.getSiteSection(tmp);
		this.props.getCategories();
	};

	componentDidUpdate = (pp) => {
		if (pp.showAssignCategoriesModal && !this.props.showAssignCategoriesModal) {
			this.initializeEditor();
		}

		if (pp.selectedSection !== this.props.selectedSection) {
			this.initializeEditor();
		}
	};

	onHandleSearch = () => {
		let temp = _.cloneDeep(this.props.sections);

		if (this.props.searchTemplate) {
			let tempTemplate = temp.filter((tempChild) =>
				tempChild.name
					.toLowerCase()
					.replace(/[\W_]+/g, "")
					.includes(this.props.searchTemplate)
			);
			temp = tempTemplate;
		}

		if (this.props.searchSectionName) {
			let nameFiltered = temp.filter((tempChild) =>
				tempChild.name.includes(this.props.searchSectionName)
			);
			temp = nameFiltered;
		}

		if (this.props.section_categories?.[0]) {
			let categoryFiltered = temp.filter((tempChild) => {
				let tempIndex = _.findIndex(tempChild.Categories, (categoryChild) => {
					return this.props.section_categories.includes(categoryChild.id);
				});

				return tempIndex > -1;
			});
			temp = categoryFiltered;
		}
		this.props.onChangeSectionHOC("filteredSections", temp);
	};

	handleSecurityModulesCreate = () => {
		let tmp = _.find(this.props.data.roleReducer.role.platforms, {
			name: "Ark Management",
		});
		let tmpModules = {};
		if (tmp) {
			tmpModules = _.find(tmp.modules, { name: "Site Template Config" });
			return tmpModules.is_create;
		}
	};

	handleSecurityModulesUpdate = () => {
		let tmp = _.find(this.props.data.roleReducer.role.platforms, {
			name: "Ark Management",
		});
		let tmpModules = {};
		if (tmp) {
			tmpModules = _.find(tmp.modules, { name: "Site Template Config" });
			return tmpModules.is_update;
		}
	};

	handleSecurityModulesDelete = () => {
		let tmp = _.find(this.props.data.roleReducer.role.platforms, {
			name: "Ark Management",
		});
		let tmpModules = {};
		if (tmp) {
			tmpModules = _.find(tmp.modules, { name: "Site Template Config" });
			return tmpModules.is_delete;
		}
	};

	processSectionName = () => {
		for (let index = 0; index < TEMPLATE_PREFIXES.length; index++) {
			let replacedString = this.props.selectedSection.name.replace(
				`${TEMPLATE_PREFIXES[index]} `,
				""
			);
			if (this.props.selectedSection.name !== replacedString) {
				return this.setState({
					selectedSectionTemplate: TEMPLATE_PREFIXES[index],
					selectedSectionName: replacedString,
				});
			}
		}
		return this.setState({
			selectedSectionName: this.props.selectedSection.name,
		});
	};

	changesMade = () => {
		this.setState({ hasChanges: true });
	};

	initializeEditor = () => {
		this.setState({
			contentJSONString: !_.isEmpty(this.props.selectedSection)
				? JSON.stringify(this.props.selectedSection.content, null, 2)
				: "",
		});
	};

	renderAssignCategoriesModal = () => {
		return (
			<Modal isOpen={this.props.showAssignCategoriesModal} size="lg">
				<ModalHeader>
					{Lang["EDIT_SECTION"][this.props.data.languageReducer.lang]}
				</ModalHeader>
				<ModalBody>
					<Form>
						<FormGroup className="card p-3 mb-3">
							<Label>
								{Lang["SECTION_NAME"][this.props.data.languageReducer.lang]}
							</Label>
							<Row>
								<div className="col-md-3">
									<Input
										className="mb-2"
										disabled
										value={this.state.selectedSectionTemplate.toUpperCase()}
									/>
								</div>
								<div className="col-md-9">
									<Input
										className="mb-2"
										disabled={this.props.onLoadSiteSection}
										value={this.state.selectedSectionName}
										onChange={(e) =>
											this.setState({ selectedSectionName: e.target.value })
										}
									/>
								</div>
							</Row>
							<Button
								color="primary"
								style={{ width: "fit-content" }}
								onClick={() => {
									this.props.updateSelectedSectionName(
										this.state.selectedSectionTemplate +
											this.state.selectedSectionName
									);
									this.changesMade();
								}}>
								{
									Lang["UPDATE_SECTION_NAME"][
										this.props.data.languageReducer.lang
									]
								}
							</Button>
						</FormGroup>
						<AssignCategoryForm
							selectedSection={this.props.selectedSection}
							categoryForSelect={this.props.categoryForSelect}
							assignCategoryData={this.props.assignCategoryData}
							onChangeSectionHOC={this.props.onChangeSectionHOC}
							changesMade={this.changesMade}
							postSectionCategory={this.props.postSectionCategory}
							deleteSectionCategory={this.props.deleteSectionCategory}
							errorMessage={this.props.errorMessage}
						/>
					</Form>
					<JsonEditor
						contentJSONString={this.state.contentJSONString}
						onChangeSectionHOC={this.props.onChangeSectionHOC}
						updateSelectedSection={this.props.updateSelectedSection}
						selectedLanguage={this.props.data.languageReducer.lang}
					/>
				</ModalBody>
				<ModalFooter>
					<Button
						className="mb-2 mr-2"
						color="primary"
						onClick={() => {
							this.props.onChangeSectionHOC("showAssignCategoriesModal", false);
							if (this.state.hasChanges) {
								let tmp = {
									name: "",
									section_categories: [0],
								};
								this.props.getSiteSection(tmp);
								this.setState({ hasChanges: false });
							}
						}}>
						{Lang["CLOSE"][this.props.data.languageReducer.lang]}
					</Button>
				</ModalFooter>
				{(this.props.data.ajaxReducer.ajaxCallProgress > 0 ||
					this.props.onLoadSiteSection) && <LoadingOverlay />}
			</Modal>
		);
	};

	render = () => {
		return (
			<>
				<PageTitle
					heading={Lang["ALL_SECTION"][this.props.data.languageReducer.lang]}
					icon="pe-7s-config icon-gradient bg-happy-fisher"
					buttons={[
						{
							buttonText:
								Lang["CREATE_SECTION"][this.props.data.languageReducer.lang],
							onClick: () => {},
							display: this.handleSecurityModulesCreate(),
						},
					]}
				/>
				<Card className="main-card mb-3">
					<CardHeader>
						{Lang["SEARCH"][this.props.data.languageReducer.lang]}
					</CardHeader>
					<CardBody>
						<Container style={{ margin: 0 }}>
							<Row>
								<Col md={6}>
									<FormGroup>
										<Label>
											{Lang["NAME"][this.props.data.languageReducer.lang]}
										</Label>
										<Input
											name="name"
											id="name"
											placeholder={
												Lang["SEARCH_BY_SECTION_NAME"][
													this.props.data.languageReducer.lang
												]
											}
											value={this.props.searchSectionName}
											onChange={(e) =>
												this.props.onChangeSectionHOC(
													"searchSectionName",
													e.target.value
												)
											}
										/>
									</FormGroup>
								</Col>
								<Col md={6}>
									<FormGroup>
										<Label>
											{
												Lang["SELECT_CATEGORY"][
													this.props.data.languageReducer.lang
												]
											}
										</Label>
										<MultipleSearchSelect
											list={this.props.categoryForSelect}
											onChange={(e, data) =>
												this.props.onChangeSectionHOC(
													"section_categories",
													data.value
												)
											}
											list_id={this.props.section_categories}
											placeholder={
												Lang["SECTION_CATEGORIES"][
													this.props.data.languageReducer.lang
												]
											}
										/>
									</FormGroup>
								</Col>
								<Col md={6}>
									<FormGroup>
										<Label>
											{
												Lang["SELECT_TEMPLATE"][
													this.props.data.languageReducer.lang
												]
											}
										</Label>
										<Input
											type={"select"}
											onChange={(e) =>
												this.props.onChangeSectionHOC(
													"searchTemplate",
													e.target.value
												)
											}
											value={this.props.searchTemplate}>
											<option value=""></option>
											{TemplateList.map((templateChild) => (
												<option
													key={templateChild.value}
													value={templateChild.value}>
													{templateChild.label}
												</option>
											))}
										</Input>
									</FormGroup>
								</Col>
							</Row>
						</Container>
					</CardBody>
					<CardFooter>
						<Button
							color="primary"
							className="ml-auto"
							onClick={() => {
								this.onHandleSearch();
							}}>
							{Lang["SEARCH"][this.props.data.languageReducer.lang]}
						</Button>
						<Button
							color="danger"
							onClick={() => {
								this.props.onChangeSectionHOC("searchSectionName", "");
								this.props.onChangeSectionHOC("section_categories", []);
								this.props.onChangeSectionHOC("searchTemplate", "");
								this.props.onChangeSectionHOC(
									"filteredSections",
									this.props.sections
								);
							}}>
							{Lang["RESET"][this.props.data.languageReducer.lang]}
						</Button>
					</CardFooter>
				</Card>
				<Card>
					<ReactTable
						data={this.props.filteredSections}
						NoDataComponent={() => <span></span>}
						columns={[
							{
								Header: Lang["NAME"][this.props.data.languageReducer.lang],
								accessor: "name",
								Cell: ({ value }) => {
									for (
										let index = 0;
										index < TEMPLATE_PREFIXES.length;
										index++
									) {
										let replacedString = value.replace(
											TEMPLATE_PREFIXES[index],
											""
										);
										if (value !== replacedString) {
											return `${TEMPLATE_PREFIXES[
												index
											].toUpperCase()}${replacedString}`;
										}
									}
									return value;
								},
							},
							{
								Header: Lang["TYPE"][this.props.data.languageReducer.lang],
								accessor: "type",
							},
							{
								Header:
									Lang["CATEGORIES"][this.props.data.languageReducer.lang],
								Cell: (row) => (
									<ul>
										{row.original.Categories &&
											row.original.Categories.length > 0 &&
											row.original.Categories.map((item) => {
												return (
													<li>
														{item.name[this.props.data.languageReducer.lang]}
													</li>
												);
											})}
									</ul>
								),
							},
							{
								Header: Lang["ACTION"][this.props.data.languageReducer.lang],
								accessor: "id",
								Cell: ({ value }) => (
									<div>
										<Button
											id={`Section-${value}-assign-categories`}
											className="mb-2 mr-2 btn-icon btn-icon-only"
											color="primary"
											onClick={async () => {
												this.setState({
													selectedSectionName: "",
													selectedSectionTemplate: "",
												});
												await this.props.getSelectedSiteSection(value);
												this.props.onChangeSectionHOC(
													"showAssignCategoriesModal",
													true
												);
												this.processSectionName();
											}}>
											<i className="pe-7s-note"></i>
										</Button>
										<UncontrolledTooltip
											target={`Section-${value}-assign-categories`}
											fade={false}
											placement="top">
											{
												Lang["EDIT_SECTION"][
													this.props.data.languageReducer.lang
												]
											}
										</UncontrolledTooltip>
									</div>
								),
							},
						]}
						showPagination={true}
						className="-striped -highlight"
					/>
				</Card>
				{this.renderAssignCategoriesModal()}
				{(this.props.data.ajaxReducer.ajaxCallProgress > 0 ||
					this.props.onLoadSiteSection) && <LoadingOverlay />}
			</>
		);
	};
}

const TEMPLATE_PREFIXES = [
	"FT-CLUB",
	"Fashion",
	"INJOY",
	"Launch",
	"Launch DE",
	"Tennis",
	"Vital",
	"Business casual",
	"New Injoy",
	"Friendly coffee",
];

export default compose(SiteSectionHOC, CategoryHOC)(SiteSectionSettings);
