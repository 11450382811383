import {
  STORE_TEST_CENTER
} from '../actions/type'

let initialState = {
  testCenterConfig: {
    address: "Model club Vital <br/> Valentin Linhof Str. 8 <br/> 81829 Munich",
    client_number: "0049-89-45 09 81 30",
    email: 'info@vital-de.oms-suite.de',
    opening_hours: [
      {
        type: 'gym',
        operating_times: [
          {
            day: 'Mon - Fri',
            time: '8 a.m. - 10 p.m.'
          },
          {
            day: 'Sat,Sun',
            time: '9 a.m. - 7 p.m.'
          },
          {
            day: 'Holiday',
            time: '9 a.m. - 7 p.m.'
          }
        ]
      },
      {
        type: 'spa',
        operating_times: [
          {
            day: 'Mon - Fri',
            time: '8 a.m. - 10 p.m.'
          },
          {
            day: 'Sat,Sun',
            time: '9 a.m. - 7 p.m.'
          },
          {
            day: 'Holiday',
            time: '9 a.m. - 7 p.m.'
          }
        ]
      }
    ]
  }
};

export default ( state = initialState, action ) => {
  switch( action.type ) {
    case STORE_TEST_CENTER:
      return {
        ...state,
        testCenterConfig: action.payload
      }
    default: return state
  }
}