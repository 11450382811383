import React from 'react'
import _ from 'lodash'
import { Form } from 'reactstrap'

import MultiLangInput from 'components/Input/MultiLang'
import Lang from 'Lang/General'

const TypesSettingForm = ({
  typesSettingType,
  currentTypesSetting,
  onChangeTypesSettingsHOC,
  lang
}) => {
  const updateTypesSetting = ( key, val ) => {
    let tmp = _.cloneDeep( currentTypesSetting )
    tmp[ key ] = val
    onChangeTypesSettingsHOC( typesSettingType, tmp )
  }
  return(
    <>
      <Form>
        <MultiLangInput
          label={ Lang[ 'NAME' ][ lang ] }
          value={ currentTypesSetting.name }
          onChange={ val => updateTypesSetting( 'name', val ) }
          selectedLanguage={ lang } />
      </Form>
    </>
  )
}

export default TypesSettingForm