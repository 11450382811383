import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get, Post, Put, Delete } from 'utils/axios'

import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      systemEmails: [],
      newEmailData: {
        email: '',
        name: ''
      },
      showCreate: false,
      updateEmailData: {},
      errorMessage: {
        email: ''
      }
    }

    onChangeSytemEmailHOC = ( key, val ) => this.setState({ [ key ]: val })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })
    requestError = error => toast.error( error, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    getSystemEmails = () => Get(
      `/api/v1/ols_system_emails`,
      this.getSystemEmailsSuccess,
      this.getSystemEmailsError,
      this.load
    )
    getSystemEmailsSuccess = payload => this.setState({ systemEmails: payload })
    getSystemEmailsError = error => this.requestError( error )

    createSystemEmail = data => Post(
      `/api/v1/ols_system_emails`,
      data,
      this.createSystemEmailSuccess,
      this.createSystemEmailError,
      this.load
    )
    createSystemEmailSuccess = payload => {
      this.requestSuccess( Lang[ 'SYSTEM_EMAIL_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showCreate: false })
      this.getSystemEmails()
    }
    createSystemEmailError = error => this.requestError( error )

    updateSystemEmail = ( id, data ) => Put(
      `/api/v1/ols_system_emails/${ id }`,
      data,
      this.updateSystemEmailSuccess,
      this.updateSystemEmailError,
      this.load
    )
    updateSystemEmailSuccess = () => {
      this.getSystemEmails()
      this.requestSuccess( Lang[ 'SYSTEM_EMAIL_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
    }
    updateSystemEmailError = error => this.requestError( error )

    deleteSystemEmail = ( id ) => Delete(
      `/api/v1/ols_system_emails/${ id }`,
      this.deleteSystemEmailSuccess,
      this.deleteSystemEmailError,
      this.load
    )
    deleteSystemEmailSuccess = payload => {
      this.requestSuccess( Lang[ 'SYSTEM_EMAIL_REMOVE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getSystemEmails()
    }
    deleteSystemEmailError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
             onLoadSystemEmail={ this.state.loading }
             getSystemEmails={ this.getSystemEmails }
             systemEmails={ this.state.systemEmails }
             createSystemEmail={ this.createSystemEmail }
             updateSystemEmail={ this.updateSystemEmail }
             newEmailData={ this.state.newEmailData }
             showCreate={ this.state.showCreate }
             onChangeSytemEmailHOC={ this.onChangeSytemEmailHOC }
             updateEmailData={ this.state.updateEmailData }
             deleteSystemEmail={ this.deleteSystemEmail }
             errorMessage={ this.state.errorMessage }/>
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
