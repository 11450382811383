import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import uniqid from 'uniqid'
import moment from 'moment'
import _ from 'lodash'

import { Get, Post, outdatedToken, Put } from 'utils/axios'
import { getRefreshToken } from 'actions/token'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const ContentServiceHOC = ( WrappedComponent ) => {
  class ContentServiceWrappedComponent extends Component{
    state = {
      contents: [],
      loading: false,
      searchParams: [
        {
          label: 'Title',
          value: 'title',
          param: ''
        },
        {
          label: 'Categories',
          value: 'categories',
          param: []
        }
      ],
      newContentData: {
        categories: [],
        title: {
          en: '',
          de: '',
          it: '',
          nl: ''
        },
        description: {
          en: '',
          de: '',
          it: '',
          nl: ''
        },
        mini_text: {
          en: '',
          de: '',
          it: '',
          nl: ''
        },
        teaser_text: {
          en: '',
          de: '',
          it: '',
          nl: ''
        },
        content: {
          id: uniqid(),
          type: 'div',
          className: 'container',
          children: [
            {
              id: uniqid(),
              type: 'div',
              className: 'row',
              style: { position: 'relative' },
              children: [
                {
                  id: uniqid(),
                  type: "div",
                  className: 'col-sm-12',
                  col: 12,
                  children: [
                    {
                      id: uniqid(),
                      type: 'div',
                      variant: 'text',
                      content: {
                        en: "Please double click to edit content",
                        de: "Bitte doppelklicken Sie, um den Inhalt zu bearbeiten",
                        it: "Si prega di fare doppio clic per modificare il contenuto",
                        nl: "Dubbelklik om de inhoud te bewerken"
                      }
                    }
                  ],
                  style: { 
                    minHeight: '100px', 
                    position: 'relative', 
                    padding: '0px 0px 0px 0px',
                    top: 0,
                    left: 0,
                    backgroundColor: ''
                  },
                  minHeight: 100,
                  paddingTop: 0,
                  paddingRight: 0,
                  paddingBottom: 0,
                  paddingLeft: 0,
                  top: 0,
                  left: 0,
                  isContentCenter: false,
                  isColumnContainer: true,
                  isFloat: false
                }
              ]
            }
          ],
          own_created_section: true
        },
        start_at: moment().format(),
        status: "ACTIVE",
        website_preview_img_url: '',
        website_detailed_img_url: '',
        newletter_img_url: '',
        fb_teaser_img_url: '',
        center_groups: []
      },
      files: [],
      contentMode: '',
      selectedContent: {},
      imageKey: '',
      contentModalOpen: false,
      infoModal: false,
      query: {},
      page: 1,
      contentTotalPages: [],
      errorMessage: {},
      deleteContentModal: false
    }

    load = param => this.setState({ loading: param })
    updateLoad = param => this.setState({ updateLoading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    ajaxError = ( error ) => {
      outdatedToken( error, this.props.history )
      this.requestError( error )
    }

    handleShowContentInfoTable = param => this.setState({ infoModal: param })

    handleContentModal = ( param, mode ) => this.setState({ contentModalOpen: param, contentMode: mode, errorMessage: {} })

    onChangeContentData = ( key, value ) => this.setState({ [ key ]: value })

    getAllContentService = ( query ) => {
      Get(
        `/api/v1/contents?query=${ query }`,
        this.getAllContentServiceSuccess,
        this.getAllContentServiceError,
        this.load
      )
    }
    getAllContentServiceSuccess = payload => {
      let tmpPayload = []
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        payload.data.map( item => {
          tmpPayload.push({
            ...item,
            start_at: moment( item.start_at ).format( 'DD.MM.YYYY HH:mm' )
          })
        })
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      else{
        this.requestError( Lang[ 'NO_RESULT_FOUND' ][ this.props.data.languageReducer.lang ] )
      }
      let tmp = {
        data: tmpPayload,
        meta: payload.meta
      }
      this.setState({ contents: tmp, contentTotalPages: tmpTotalPages })
    }
    getAllContentServiceError = error => this.ajaxError( error )

    postContent = data => {
      Post(
        `/api/v1/contents`,
        data,
        this.postContentSuccess,
        this.postContentError,
        this.load
      )
    }
    postContentSuccess = payload => {
      let tmp = {
        page: this.state.page,
        is_paginated: true,
        filter: {
          status: {
            $neq: 'ARCHIVED'
          }
        }
      }
      this.requestSuccess( Lang[ 'CONTENT_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getAllContentService( convertObjToBase64( tmp ) )
      this.handleContentModal( false, 'create' )
    }
    postContentError = error => this.setState({ errorMessage: error }, () => this.ajaxError( Lang[ 'CONTENT_CREATE_FAILED' ][ this.props.data.languageReducer.lang ] ) )

    updateContent = data => {
      let tmpData = _.cloneDeep( data )
      let tmp = {
        ...tmpData,
        start_at: moment( tmpData.start_at ).format()
      }
      Put(
        `/api/v1/contents/${ data.id }`,
        data ? data : tmp,
        this.updateContentSuccess,
        this.updateContentError,
        this.load
      )
    }
    updateContentSuccess = payload => {
      let tmp = {
        page: this.state.page,
        is_paginated: true,
        filter: {
          status: {
            $neq: 'ARCHIVED'
          }
        }
      }
      this.requestSuccess( Lang[ 'CONTENT_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getAllContentService( convertObjToBase64( tmp ) )
      this.handleContentModal( false, '' )
    }
    updateContentError = error => this.setState({ errorMessage: error }, () => this.ajaxError( Lang[ 'CONTENT_UPDATE_FAILED' ][ this.props.data.languageReducer.lang ] ) )

    getSelectedContent = id => {
      Get(
        `/api/v1/contents/${ id }`,
        this.getSelectedContentSuccess,
        this.getSelectedContentError,
        this.load
      )
    }
    getSelectedContentSuccess = payload => {
      let tmpCategory = []
      payload.categories && payload.categories.map( item => {
        tmpCategory.push({
          id: item.id,
          label: item.name[ this.props.data.languageReducer.lang ],
          value: item.name[ this.props.data.languageReducer.lang ]
        })
      })
      let tmp = {
        ...payload,
        categories: tmpCategory
      }
      this.setState({ selectedContent: tmp })
    }
    getSelectedContentError = error => this.ajaxError( error )

    render = () => {
      return(
        <>
          <WrappedComponent
            { ...this.props }
            onLoadContent={ this.state.loading }
            contents={ this.state.contents }
            getAllContentService={ this.getAllContentService }
            onChangeContentData={ this.onChangeContentData }
            newContentData={ this.state.newContentData }
            files={ this.state.files }
            postContent={ this.postContent }
            contentMode={ this.state.contentMode }
            handleContentModal={ this.handleContentModal }
            contentModalOpen={ this.state.contentModalOpen }
            selectedContent={ this.state.selectedContent }
            getSelectedContent={ this.getSelectedContent }
            updateContent={ this.updateContent }
            handleShowContentInfoTable={ this.handleShowContentInfoTable }
            imageKey={ this.state.imageKey }
            infoModal={ this.state.infoModal }
            page={ this.state.page }
            searchParams={ this.state.searchParams }
            contentTotalPages={ this.state.contentTotalPages }
            contentError={ this.state.errorMessage }
            deleteContentModal={ this.state.deleteContentModal } />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    getRefreshToken
  } )( ContentServiceWrappedComponent )
}

export default ContentServiceHOC
