import React, { Component } from 'react'
import { Dialog, Button, Grid, TextField } from '@material-ui/core'
import LoadingModal from 'components/Indicator/LoadingOverlay'
import uniqid from 'uniqid'
import { MdClose } from 'react-icons/md'
import { toast } from 'react-toastify'

import TemplateEditorCard from '../components/card'

import Lang from 'Lang/General'

class PageJSONEditor extends Component {
  state = {
    generatedId: '',
    search: '',
    JSONParse: false,
    contentJSONString: ""
  }

  componentDidMount = () => {
    if( this.props.selectedPage && this.props.selectedPage.content ){
      this.setState({ contentJSONString: JSON.stringify( this.props.selectedPage.content, null, 2 ) })
    }
  }

  componentDidUpdate = ( pp ) => {
    if( this.props.showPageJSONEditorDialog && !pp.showPageJSONEditorDialog ){
      if( this.props.selectedPage && this.props.selectedPage.content ){
        this.setState({ contentJSONString: JSON.stringify( this.props.selectedPage.content, null, 2 ) })
      }
    }
  }

  checkJSON = () =>{
    try{
      return JSON.parse( this.state.contentJSONString )
    }catch( e ){
      toast.error( 'Error on JSON.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false
      })
    }
  }

  onJsonInput = ( e ) =>{
    if( e.keyCode == '9' && !e.shiftKey ){
      e.preventDefault()
      document.execCommand( "insertText", false, "  " )
      this.setState({ contentJSONString: e.target.value })
    }
  }

  render = () => {
    return (
      <Dialog
        open={ this.props.showPageJSONEditorDialog }
        maxWidth={ 'lg' }
        fullWidth={ true }>
        <TemplateEditorCard>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h4>{ Lang[ 'EDIT_PAGE_JSON' ][ this.props.lang ] }</h4>
            <Button
              classes={{
                root: "button-close",
                label: "button-text" }}
              onClick={ () => this.props.updateMainContainerState( 'showPageJSONEditorDialog', false ) }>
              <MdClose style={{ fontSize: '20px' }}/>
            </Button>
          </div>
          <hr />
          <Grid container>
            <Grid item md={ 12 } style={{ width: 'inherit' }} >
              <textarea
                style={{ width: '100%' }}
                rows={ 20 }
                value={ this.state.contentJSONString }
                onChange={ ( e ) => this.setState({ contentJSONString: e.target.value }) } 
                onKeyDown= { ( e ) => this.onJsonInput( e ) }
              />
            </Grid>
          </Grid>
          <div>
            <input className="form-control" style={{ width: '300px', display: 'inline-block', marginRight: '10px' }} value={ this.state.generatedId } />
            <button className="btn btn-primary" onClick={ () => this.setState({ generatedId: uniqid() }) }>{ Lang[ 'GENERATE_ID' ][ this.props.lang ] }</button>
          </div>
          <hr />
          <div className="page-creator-footer">
            <Button
              classes={{ root: 'btn-primary' }}
              style={{ marginRight: '10px' }}
              onClick={ () => {
                this.checkJSON() && (
                  this.props.updateSelectedPage( this.props.selectedPage.id, {
                    ...this.props.selectedPage,
                    content: JSON.parse( this.state.contentJSONString )
                  }, () => this.props.updateMainContainerState( 'showPageJSONEditorDialog', false ))
                )
              }}>
              { Lang[ 'UPDATE_PAGE_JSON' ][ this.props.lang ] }
            </Button>
            <Button
              classes={{ root: 'btn-secondary' }}
              onClick={ () => this.props.updateMainContainerState( 'showPageJSONEditorDialog', false ) }>
              { Lang[ 'CLOSE' ][ this.props.lang ] }
            </Button>
          </div>
          { this.props.onLoadPagesUpdate && <LoadingModal /> }
        </TemplateEditorCard>
      </Dialog>
    )
  }
}

export default PageJSONEditor
