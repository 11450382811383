import React, { Component } from "react"
import { connect } from "react-redux"
import _ from 'lodash'

import StatusModal from "components/Modal/status"
import { Get, Post, Put, Delete } from "utils/axios"
import { toggleMediaDatabase } from "actions/MediaDatabaseToggle"
import { convertObjToBase64 } from "utils/objToBase64"
import Lang from "Lang/General"

const LandingPageTemplatesHOC = WrappedComponent => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showStatusModal: false,
      statusModalType: "",
      onClickStatusModalButton: () => {},
      statusModalMessage: "",
      selectedLandingPageSection: {},
      selectedSection: {},
      newSection: {
        name: "",
        type: "section",
        description: "",
        image: "",
        content: {},
        center_group_ids: [],
        countries: [],
        center_ids: [],
        is_under_landing_page: true
      },
    }

    load = ( param ) => this.setState({ loading: param })
    onChangeLandingPageHOC = ( key, value ) => this.setState({ [ key ]: value })
    requestError = error =>
      this.setState({
        showStatusModal: true,
        statusModalType: "negative",
        onClickStatusModalButton: () => this.setState({ showStatusModal: false }),
        statusModalMessage: error,
      })
    requestSuccess = ( success ) =>
      this.setState({
        showStatusModal: true,
        statusModalType: "positive",
        onClickStatusModalButton: () => this.setState({ showStatusModal: false }),
        statusModalMessage: success,
      })

    findHeaderCarousel = ( item ) => {
      if( item && item.type && item.type === 'carousel' ){
        this.setState({ selectedSection: _.cloneDeep( item ) })
      } else if( item && item.children && item.children.length > 0 ) {
        item.children.map( child => this.findHeaderCarousel( child ) )
      }
    }

    createLandingPageSections = ( page, data, updateMainContainerState ) =>
      Post(
        `/api/v1/pages/${ page.id }/ols_sections`,
        {
          ...data,
          uuid: data.content.id,
          page_id: page.id
        },
        () => this.createLandingPageSectionsSuccess( page, updateMainContainerState ),
        this.createLandingPageSectionsError,
        this.load
      )
    createLandingPageSectionsSuccess = ( page, updateMainContainerState ) => {
      this.requestSuccess(
        Lang[ "SECTION_CREATE_SUCCESS" ][ this.props.data.languageReducer.lang ]
      )
      this.setState({ 
        newSection: {
          name: "",
          type: "section",
          description: "",
          image: "",
          content: {},
          center_group_ids: [],
          countries: [],
          is_under_landing_page: true
        }
      })
      this.props.updateSelectedPage( page.id, page, () => updateMainContainerState( "showSectionPicker", false ) )
    }
    createLandingPageSectionsError = ( error ) => this.requestError( error )

    getSelectedLandingPageSection = ( id ) =>
      Get(
        `/api/v1/pages/${ this.props.selectedPage.id }/ols_sections/${ id }`,
        this.getSelectedLandingPageSectionSuccess,
        this.getSelectedLandingPageSectionError,
        this.load
      )
    getSelectedLandingPageSectionSuccess = ( payload ) => {
      if( payload.name.includes( "header-carousel" ) ){
        this.setState({ selectedLandingPageSection: payload })
        this.findHeaderCarousel( payload.content )
      } else {
        this.setState({ selectedLandingPageSection: payload, selectedSection: payload.content })
      }
    }
    getSelectedLandingPageSectionError = ( error ) => this.requestError( error )

    updateLandingPageSections = ( data, successAction ) =>
      Put(
        `/api/v1/pages/${ this.props.selectedPage.id }/ols_sections/${ data.uuid }`,
        data,
        payload => this.updateLandingPageSectionsSuccess( payload, successAction ),
        this.updateLandingPageSectionsError,
        this.load
      )
    updateLandingPageSectionsSuccess = ( payload, successAction ) => {
      this.requestSuccess(
        Lang[ "SECTION_UPDATE_SUCCESS" ][ this.props.data.languageReducer.lang ]
      )
      successAction()
      if( payload.name.includes( "header-carousel" ) ){
        this.setState({ selectedLandingPageSection: payload })
        this.findHeaderCarousel( payload.content )
      } else {
        this.setState({ selectedLandingPageSection: payload, selectedSection: payload.content })
      }
    }
    updateLandingPageSectionsError = ( error ) => this.requestError( error )

    deleteLandingPageSections = ( page, id, updateMainContainerState ) =>
      Delete(
        `/api/v1/pages/${ page.id }/ols_sections/${ id }`,
        () => this.deleteLandingPageSectionsSuccess( page, updateMainContainerState ),
        this.deleteLandingPageSectionsError,
        this.load
      )
    deleteLandingPageSectionsSuccess = ( page, updateMainContainerState ) => {
      this.requestSuccess( Lang[ "SECTION_DELETE_SUCCESS" ][ this.props.data.languageReducer.lang ] )
      this.props.updateSelectedPage( page.id, page, () => updateMainContainerState( "showConfirmRemoveDialog", false ) )
    }
    deleteLandingPageSectionsError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            onLoadLandingTemplate={ this.state.loading }
            onChangeLandingPageHOC={ this.onChangeLandingPageHOC }
            createLandingPageSections={ this.createLandingPageSections }
            getSelectedLandingPageSection={ this.getSelectedLandingPageSection }
            updateLandingPageSections={ this.updateLandingPageSections }
            newSection={ this.state.newSection }
            selectedLandingPageSection={ this.state.selectedLandingPageSection }
            selectedSection={ this.state.selectedSection }
            page={ this.state.page }
            deleteLandingPageSections={ this.deleteLandingPageSections }
          />
          <StatusModal
            isOpen={ this.state.showStatusModal }
            type={ this.state.statusModalType }
            message={ this.state.statusModalMessage }
            onClick={ this.state.onClickStatusModalButton }
          />
        </>
      )
    }
  }

  const mapStateToProps = state => ({ data: state })

  return connect( mapStateToProps, { toggleMediaDatabase } )( WithHOC )
}

export default LandingPageTemplatesHOC
