import React, { useState } from 'react'
import { BeatLoader } from 'react-spinners'
import _ from 'lodash'
import {
  Label, Col, Button, 
  Card, CardBody, CardFooter,
  Form, FormGroup, FormFeedback, FormText,
  Input, InputGroup, InputGroupText, InputGroupAddon
} from 'reactstrap'

import DropdawnSearchSelect from 'components/SearchSelect'
import DeleteConfirmation from 'components/Modal/delete'


import Lang from 'Lang/General'
import WithOpeningHours from './actions/OpeningHours'
import getDomainURL from 'utils/api'
import { validate } from 'utils/validatePhoneNumber'

const LocationForm = ( props ) => {
  let {
    onLoadCountries,
    selectedCenter,
    onChangeLocationData,
    data,
    errorMessage,
    getStates,
    getValueForSelect,
    countriesOptions,
    statesOption,
    item,
    index,
    deleteCenterLocations,
    updateCenterLocation,
    countries
  } = props

  const [ showDeleteConfirmation, updateShowDeleteConfirmation ] = useState( false )

  const getCallingCode = ( item ) => {
    let tmpFound = _.find( countries, { name: item.country } )
    if( tmpFound ){
      return tmpFound.calling_code[0]
    }
  }

  return(
    <div
      className='mb-3'
      style={{ position: 'relative' }}>
      <Button
        className="mb-2 mr-2 btn-icon btn-icon-only"
        style={{
          position: 'absolute',
          zIndex: 999,
          float: 'right',
          top: '-11px',
          right: '-12px',
          height: '36px',
          width: '36px',
          borderRadius: '50%',
          padding: 0
        }}
        color="danger"
        onClick={ () => updateShowDeleteConfirmation( true ) }>
        <i className="pe-7s-less btn-icon-wrapper" style={{ fontSize: '23px' }}> </i>
      </Button>
      <Card>
        <CardBody>
          <Form>
            <FormGroup row>
              <Label for="ext_field_1" sm={ 2 }>{ 'Name' }</Label>
              <Col sm={ 10 }>
                <Input
                  name="ext_field_1" id="ext_field_1"
                  placeholder="Enter location name"
                  value={ item.name }
                  onChange={ e => onChangeLocationData( index, 'name', e.target.value ) } />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="ext_field_1" sm={ 2 }>{ Lang[ 'EXTRA_FIELD_1' ][ data.languageReducer.lang ] }</Label>
              <Col sm={ 10 }>
                <Input
                  name="ext_field_1" id="ext_field_1"
                  placeholder="Enter extra field 1"
                  value={ item.ext_field_1 }
                  onChange={ e => onChangeLocationData( index, 'ext_field_1', e.target.value ) } />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="ext_field_2" sm={ 2 }>{ Lang[ 'EXTRA_FIELD_2' ][ data.languageReducer.lang ] }</Label>
              <Col sm={ 10 }>
                <Input
                  name="ext_field_2" id="ext_field_2"
                  placeholder="Enter extra field 2"
                  value={ item.ext_field_2 }
                  onChange={ e => onChangeLocationData( index, 'ext_field_2', e.target.value ) }/>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="country" sm={ 2 }>{ Lang[ 'COUNTRY' ][ data.languageReducer.lang ] }</Label>
              <Col sm={ 10 } style={{ zIndex: 999 }}>
                <DropdawnSearchSelect
                  options={ countriesOptions }
                  onChange={ v => {
                    onChangeLocationData( index, 'country', v.value )
                    let tmpCountry = _.find( countriesOptions, { value: v.value })
                    if( tmpCountry ){
                      getStates( tmpCountry.id )
                    }
                  }}
                  value={ getValueForSelect( 'country', index ) }
                  placeholder={ 'select a country' }/>
                <FormText color="danger">{ errorMessage && errorMessage.country ? errorMessage.country : '' }</FormText>
              </Col>
            </FormGroup>
            {
              onLoadCountries && <BeatLoader
                sizeUnit={"px"}
                size={30}
                color={'#123abc'}
                loading={true}
              />
            }
            {
              item.country && (
                <FormGroup row>
                  <Label for="country" sm={ 2 }>{ Lang[ 'STATE' ][ data.languageReducer.lang ] }</Label>
                  <Col sm={ 10 }>
                    <DropdawnSearchSelect
                      options={ statesOption }
                      onChange={ v => onChangeLocationData( index, 'state', v.value ) }
                      value={ getValueForSelect( 'state', index ) }
                      placeholder={ 'Select a state' }/>
                    <FormText color="danger">{ errorMessage && errorMessage.state ? errorMessage.state : '' }</FormText>
                  </Col>
                </FormGroup>
              )
            }
            <FormGroup row>
              <Label for="city" sm={ 2 }>{ Lang[ 'CITY' ][ data.languageReducer.lang ] }</Label>
              <Col sm={ 10 }>
                <Input
                  name="city" id="city"
                  placeholder={ Lang[ 'ENTER_CITY' ][ data.languageReducer.lang ] }
                  value={ item.city }
                  onChange={ e => onChangeLocationData( index, 'city', e.target.value ) }
                  invalid={ errorMessage && errorMessage.city ? true : false }/>
                <FormFeedback>{ errorMessage && errorMessage.city ? errorMessage.city : '' }</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="street" sm={ 2 }>{ Lang[ 'STREET' ][ data.languageReducer.lang ] }</Label>
              <Col sm={ 10 }>
                <Input
                  name="street" id="street"
                  placeholder={ Lang[ 'ENTER_STREET' ][ data.languageReducer.lang ] }
                  value={ item.street }
                  onChange={ e => onChangeLocationData( index, 'street', e.target.value ) }
                  invalid={ errorMessage && errorMessage.street ? true : false }/>
                <FormFeedback>{ errorMessage && errorMessage.street ? errorMessage.street : '' }</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="house_number" sm={ 2 }>{ Lang[ 'HOUSE_NUMBER' ][ data.languageReducer.lang ] }</Label>
              <Col sm={ 10 }>
                <Input
                  name="house_number" id="house_number"
                  placeholder={ Lang[ 'ENTER_HOUSE_NUMBER' ][ data.languageReducer.lang ] }
                  value={ item.house_number }
                  onChange={ e => onChangeLocationData( index, 'house_number', e.target.value ) }
                  invalid={ errorMessage && errorMessage.house_number ? true : false }/>
                <FormFeedback>{ errorMessage && errorMessage.house_number ? errorMessage.house_number : '' }</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="postcode" sm={ 2 }>{ Lang[ 'POSTCODE' ][ data.languageReducer.lang ] }</Label>
              <Col sm={ 10 }>
                <Input
                  name="postcode" id="postcode"
                  placeholder={ Lang[ 'ENTER_POSTCODE' ][ data.languageReducer.lang ] }
                  value={ item.postcode }
                  onChange={ e => onChangeLocationData( index, 'postcode', e.target.value ) }
                  invalid={ errorMessage && errorMessage.postcode ? true : false }/>
                <FormFeedback>{ errorMessage && errorMessage.postcode ? errorMessage.postcode : '' }</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="phone_number" sm={ 2 }>{ Lang[ 'PHONE_NUMBER' ][ data.languageReducer.lang ] }</Label>
              <Col sm={ 10 }>
                <InputGroup>
                  <Input
                    value={ getCallingCode( item ) }
                    disabled={ true } />
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>-</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Enter area code"
                    value={ item.area_code }
                    onChange={ e => onChangeLocationData( index, 'area_code', e.target.value ) } />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>-</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Enter phone number"
                    value={ item.number }
                    onChange={ e => {
                      if( e.target.value.length > 0 ){
                        if( validate( e.target.value ) ) {
                          onChangeLocationData( index, 'number', e.target.value )
                        }
                      } else {
                        onChangeLocationData( index, 'number', e.target.value )
                      }
                    }} />
                </InputGroup>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="email" sm={ 2 }>{ Lang[ 'EMAIL' ][ data.languageReducer.lang ] }</Label>
              <Col sm={ 10 }>
                <Input
                  name="email" id="email"
                  placeholder={ Lang[ 'ENTER_EMAIL' ][ data.languageReducer.lang ] }
                  value={ item.email }
                  onChange={ e => onChangeLocationData( index, 'email', e.target.value ) }
                  invalid={ errorMessage && errorMessage.email ? true : false }/>
                <FormFeedback>{ errorMessage && errorMessage.email ? errorMessage.email : '' }</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="fax_number" sm={ 2 }>{ Lang[ 'TELFAX' ][ data.languageReducer.lang ] }</Label>
              <Col sm={ 10 }>
                <Input
                  name="fax_number" id="fax_number"
                  placeholder={ Lang[ 'ENTER_FAX_NUMBER' ][ data.languageReducer.lang ] }
                  value={ item.fax_number }
                  onChange={ e => {
                    if( e.target.value.length > 0 ){
                      if( validate( e.target.value ) ) {
                        onChangeLocationData( index, 'fax_number', e.target.value )
                      }
                    } else {
                      onChangeLocationData( index, 'fax_number', e.target.value )
                    }
                  }}
                  invalid={ errorMessage && errorMessage.fax_number ? true : false }/>
                <FormFeedback>{ errorMessage && errorMessage.fax_number ? errorMessage.fax_number : '' }</FormFeedback>
              </Col>
            </FormGroup>
            {
              selectedCenter.site?.has_aciso_campaign && (
                <>
                  <FormGroup row>
                    <Label for="discount" sm={ 2 }>{ Lang[ 'DISCOUNT' ][ data.languageReducer.lang ] }</Label>
                    <Col sm={ 10 }>
                      <Input
                        name="discount" id="discount"
                        placeholder={ Lang[ 'DISCOUNT' ][ data.languageReducer.lang ] }
                        value={ item.discount }
                        onChange={ e => onChangeLocationData( index, 'discount', e.target.value ) } />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="studio_email_subject" sm={ 2 }>{ Lang[ 'LEADCENTER_SUBJECT_STUDIO_FINDER' ][ data.languageReducer.lang ] }</Label>
                    <Col sm={ 10 }>
                      <Input
                        name="studio_email_subject" id="studio_email_subject"
                        placeholder={ Lang[ 'LEADCENTER_SUBJECT_STUDIO_FINDER' ][ data.languageReducer.lang ] }
                        value={ item.studio_email_subject }
                        onChange={ e => onChangeLocationData( index, 'studio_email_subject', e.target.value ) } />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="studio_friend_email_subject" sm={ 2 }>{ Lang[ 'LEADCENTER_SUBJECT_FRIENDS' ][ data.languageReducer.lang ] }</Label>
                    <Col sm={ 10 }>
                      <Input
                        name="studio_friend_email_subject" id="studio_friend_email_subject"
                        placeholder={ Lang[ 'LEADCENTER_SUBJECT_FRIENDS' ][ data.languageReducer.lang ] }
                        value={ item.studio_friend_email_subject }
                        onChange={ e => onChangeLocationData( index, 'studio_friend_email_subject', e.target.value ) } />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="client_number" sm={ 2 }>{ Lang[ 'ROOT_NUMBER' ][ data.languageReducer.lang ] }</Label>
                    <Col sm={ 10 }>
                      <Input
                        name="client_number" id="client_number"
                        placeholder={ Lang[ 'ROOT_NUMBER' ][ data.languageReducer.lang ] }
                        value={ item.client_number }
                        onChange={ e => onChangeLocationData( index, 'client_number', e.target.value ) } />
                    </Col>
                  </FormGroup>
                </>
              )
            }
            <FormGroup row>
              <Label for="easy_solution_uuid" sm={ 2 }>{ 'Easy Solution ID' }</Label>
              <Col sm={ 10 }>
                <Input
                  name="easy_solution_uuid" id="easy_solution_uuid"
                  placeholder={ '' }
                  value={ item.easy_solution_uuid ? item.easy_solution_uuid : '' }
                  onChange={ e => onChangeLocationData( index, 'easy_solution_uuid', e.target.value ) } />
                <FormFeedback>{ errorMessage && errorMessage.easy_solution_uuid ? errorMessage.easy_solution_uuid : '' }</FormFeedback>
              </Col>
            </FormGroup>
            {
              ( getDomainURL().indexOf( 'tennis' ) > -1 || getDomainURL().indexOf( 'staging' ) > -1 ) && <FormGroup row>
                <Label for="school_id" sm={ 2 }>{ Lang[ 'TENNIS_SCHOOL_ID' ][ data.languageReducer.lang ] }</Label>
                <Col sm={ 10 }>
                  <Input
                    name="school_id" id="school_id"
                    placeholder={ Lang[ 'INPUT_SCHOOL_ID' ][ data.languageReducer.lang ] }
                    value={ item.school_id }
                    onChange={ e => {
                      if( _.isEmpty( e.target.value ) || /^[0-9]*$/.test( e.target.value ) ){
                        onChangeLocationData( index, 'school_id', e.target.value )
                      } else {
                        onChangeLocationData( index, 'school_id', item.school_id )
                      }
                    }}
                    invalid={ errorMessage && errorMessage.school_id ? true : false }/>
                  <FormFeedback>{ errorMessage && errorMessage.school_id ? errorMessage.school_id : '' }</FormFeedback>
                </Col>
              </FormGroup>
            }
            {
              selectedCenter.site?.has_aciso_campaign && <FormGroup row>
                <Label for="is_active" sm={ 2 }>{ Lang[ 'STATUS' ][ data.languageReducer.lang ] }</Label>
                <Col sm={ 10 }>
                  <Input
                    type="select"
                    name="is_active" id="is_active"
                    value={ item.is_active }
                    onChange={ e => onChangeLocationData( index, 'is_active', e.target.value === 'true' ) } >
                    <option value={ true } >{ Lang[ 'ACTIVE' ][ data.languageReducer.lang ] }</option>
                    <option value={ false } >{ Lang[ 'INACTIVE' ][ data.languageReducer.lang ] }</option>
                  </Input>
                </Col>
              </FormGroup>
            }
          </Form>
        </CardBody>
        <CardFooter>
          <Button
            className="mb-2 mr-2 mt-3"
            color="info"
            onClick={ () => {
              let tmpData = _.cloneDeep( item )
              delete tmpData.area_code
              delete tmpData.calling_code
              delete tmpData.number
              tmpData = {
                ...tmpData,
                phone_number: `${ getCallingCode( item ) }-${ item.area_code }-${ item.number }`,
                school_id: parseInt( tmpData.school_id )
              }
              updateCenterLocation( selectedCenter.id, item.id, tmpData )
            }}>{ 'Update' }
          </Button>
        </CardFooter>
      </Card>
      {
        showDeleteConfirmation && (
          <DeleteConfirmation
            handleModalClose={ () => updateShowDeleteConfirmation( false ) }
            deleteOpen={ showDeleteConfirmation }
            confirmAction={ () => {
              deleteCenterLocations( selectedCenter.id, item.id )
              updateShowDeleteConfirmation( false )
            }}
            content={ 'Are you sure to delete this location?' }
            lang={ data.languageReducer.lang } />
        )
      }
    </div>
  )
}

export default WithOpeningHours( LocationForm )
