import React, { useState } from 'react'
import Radium from 'proofpoint-radium'
import Draggable from 'react-draggable'

const Image = ({
  item,
  id,
  title,
  alt,
  description,
  style,
  source,
  className,
  mode,
  onClick,
  onChangeSectionEditorState,
  selectedEditableComponent,
  onStopDragComponent
}) => {
  const [ activeDrags, updateActiveDrags ] = useState( 0 )
  const [ controlledPosition, updateControlledPosition ] = useState({ x: 0, y: 0 })
  const onStart = () => {
    updateActiveDrags( activeDrags + 1 )
  }

  const onStop = () => {
    updateActiveDrags( activeDrags - 1 )
    onStopDragComponent( controlledPosition )
  }

  const onControlledDrag = (e, position) => {
    const { x, y } = position
    updateControlledPosition({ x, y })
  }

  let onClickTemp = new Function( onClick )
  const dragHandlers = { onStart: onStart, onStop: onStop }
  if( 
    selectedEditableComponent && 
    selectedEditableComponent.id && 
    selectedEditableComponent.id === id && 
    style && 
    style.position && 
    style.position === 'absolute' 
  ) {
    return (
      <Draggable 
        bounds="parent" 
        grid={[ 15, 15 ]} 
        { ...dragHandlers }
        position={ controlledPosition }
        onDrag={ onControlledDrag }>
        {
          item.isBackgroundImage
            ? (
            <div
              alt={ alt ? alt : '' }
              title={ title ? title : '' }
              style={{
                backgroundImage: `url(${ source })`,
                ...style,
              }}/>
          ) : (
            <img
              id={ id }
              style={{
                ...style,
                outline: selectedEditableComponent && mode === 'edit' ? selectedEditableComponent.id === id ? '5px solid #28a745' : 'none' : 'none',
                top: 0,
                left: 0
              }}
              className={ className }
              src={ source }
              draggable="false"
              alt={ alt ? alt : '' }
              title={ title ? title : '' }
              description={ description ? description : '' } />
          )
        }
      </Draggable>
    )
  } else {
    return (
      <>
        {
          item.isBackgroundImage
            ? (
              <div
                alt={ alt ? alt : '' }
                title={ title ? title : '' }
                style={{
                  backgroundImage: `url(${ source })`,
                  ...style,
                  cursor: style.cursor 
                    ? style.cursor 
                    : ( item.redirectType && item.redirectType !== '' ) 
                      ? "pointer" 
                      : "auto"
                }} />
            ) : (
              <img
                id={ id }
                alt={ alt ? alt : '' }
                title={ title ? title : '' }
                style={{
                  ...style,
                  outline: selectedEditableComponent && mode === 'edit' ? selectedEditableComponent.id === id ? '5px solid #28a745' : 'none' : 'none',
                  ...( item.mapWith === 'header-logo-image' ? { maxHeight: "100%" } : {} ),
                  cursor: style.cursor 
                    ? style.cursor 
                    : ( item.redirectType && item.redirectType !== '' ) || item.mapWith === 'header-logo-image' 
                      ? "pointer" 
                      : "auto"
                }}
                className={ className }
                src={ source }
                onClick={ () => {
                  mode === 'edit' 
                    ? onChangeSectionEditorState( 'selectedEditableComponent', item )
                    : onClickTemp() 
                }} />
            )
        }
      </>
    )
  }
}

export default Radium( Image )