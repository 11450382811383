import React from 'react'
import { Route } from 'react-router-dom'

import Builder from './Builder'
import OrderRequest from './OrderRequest/Listings'
import Categories from './Categories'

const DigitalOrderForm = () => {
  return (
    <>
      <Route exact path="/dashboard/digital-order-form/builder" component={ Builder } />
      <Route exact path="/dashboard/digital-order-form/order-request" component={ OrderRequest } />
      <Route exact path="/dashboard/digital-order-form/category" component={ Categories } />
    </>
  )
}

export default DigitalOrderForm