import React, { Component } from 'react'
import _ from 'lodash'

import FormBuilderModal from './FormEditor'


class ModalFormUpdate extends Component {
  render = () => {
    return (
      <FormBuilderModal
        mode={ 'update' }
        digitalOrderForm={ this.props.selectedDigitalOrderForm }
        showFormEditor={ this.props.showUpdateModal }
        toggleFormEditor={ ( isShow ) => this.props.onChangeDigitalOrderFormHOC( 'showUpdateModal', isShow ) }
        { ...this.props }
      />
    )
  }
}

export default ModalFormUpdate