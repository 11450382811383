import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get, Post, Put } from 'utils/axios'
import { getRefreshToken } from 'actions/token'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      socialMediaPlatforms: [],
      selectedSocialMediaPlatform: {
        platform_name: '',
        icon_url: ''
      },
      newSocialMediaPlatform: {
        platform_name: '',
        icon_url: ''
      },
      showCreateSocialMedia: false,
      showUpdateSocialMedia: false,
      showDeleteSocialMedia: false,
      socialMediaTotalPages: []
    }

    onChangeSocialMediaHOC = ( key, value ) => this.setState({ [key]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    getAllSocialMediaPlatform = () => Get(
      `/api/v1/social_media_platforms`,
      this.getAllSocialMediaPlatformSuccess,
      this.getAllSocialMediaPlatformError,
      this.load
    )
    getAllSocialMediaPlatformSuccess = payload => {
      this.setState({ socialMediaPlatforms: payload })
    }
    getAllSocialMediaPlatformError = error => this.requestError( error )

    getSelectedSocialMediaPlatform = id => Get(
      `/api/v1/social_media_platforms/${ id }`,
      this.getSelectedSocialMediaPlatformSuccess,
      this.getSelectedSocialMediaPlatformError,
      this.load
    )
    getSelectedSocialMediaPlatformSuccess = payload => this.setState({ selectedSocialMediaPlatform: payload })
    getSelectedSocialMediaPlatformError = error => this.requestError( error )

    createSocialMediaPlatform = () => Post(
      `/api/v1/social_media_platforms`,
      this.state.newSocialMediaPlatform,
      this.createSocialMediaPlatformSuccess,
      this.createSocialMediaPlatformError,
      this.load
    )
    createSocialMediaPlatformSuccess = payload => {
      this.getAllSocialMediaPlatform()
      this.setState({ 
        showCreateSocialMedia: false, 
        newSocialMediaPlatform: {
          platform_name: '',
          icon_url: ''
        } 
      })
      this.requestSuccess( 'create success.')
    }
    createSocialMediaPlatformError = error => {
      this.requestError( 'create failed' )
    }

    updateSocialMediaPlatform = () => Put(
      `/api/v1/social_media_platforms/${ this.state.selectedSocialMediaPlatform.id }`,
      this.state.selectedSocialMediaPlatform,
      this.updateSocialMediaPlatformSuccess,
      this.updateSocialMediaPlatformError,
      this.load
    )
    updateSocialMediaPlatformSuccess = payload => {
      this.getAllSocialMediaPlatform()
      this.setState({ 
        showUpdateSocialMedia: false,
        selectedSocialMediaPlatform: {
          platform_name: '',
          icon_url: ''
        } 
      })
      this.requestSuccess( 'update success.')
    }
    updateSocialMediaPlatformError = error => {
      this.requestError( 'update failed' )
    }

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          onLoadSocialMedia={ this.state.loading }
          socialMediaPlatforms={ this.state.socialMediaPlatforms }
          newSocialMediaPlatform={ this.state.newSocialMediaPlatform }
          selectedSocialMediaPlatform={ this.state.selectedSocialMediaPlatform }
          showCreateSocialMedia={ this.state.showCreateSocialMedia }
          showUpdateSocialMedia={ this.state.showUpdateSocialMedia }
          onChangeSocialMediaHOC={ this.onChangeSocialMediaHOC }
          getAllSocialMediaPlatform={ this.getAllSocialMediaPlatform }
          getSelectedSocialMediaPlatform={ this.getSelectedSocialMediaPlatform }
          createSocialMediaPlatform={ this.createSocialMediaPlatform }
          updateSocialMediaPlatform={ this.updateSocialMediaPlatform }
          socialMediaTotalPages={ this.state.socialMediaTotalPages }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    getRefreshToken
  } )( WithHOC )
}
export default HOC
