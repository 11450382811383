import React from 'react'

import FormFrame from './components/FormFrame'
import Container from './components/Container'
import CustomRow from './components/Row'
import Column from './components/Column'
import Image from './components/Image'
import TextField from './components/TextField'
import TextArea from './components/TextArea'
import ButtonComponent from './components/Button'
import RadioButton from './components/RadioButton'
import TextComponent from './components/Text'
import CheckboxComponent from  './components/Checkbox'
import InputFile from './components/InputFile'
import DateField from './components/DateField'
import TableOfContents from './components/TableOfContents'

const RenderDOM = ({
  content,
  onChangeFormBuilderState,
  mode,
  formValues,
  onPatchFormValues,
  onClickSubmitForm,
  createSubmittedFormMedia,
  currentPage,
  dom,
  findObjectAndPatch,
  data,
  isInputDisabled,
  togglePageSelection,
  togglePageSelectable,
  duplicatePage,
  currentLanguage,
  showPageRearrangement
}) => {
  switch( content.type ) {
    case 'FormFrame': return <FormFrame
      content={ content }
      onChangeFormBuilderState={ onChangeFormBuilderState }
      mode={ mode }
      formValues={ formValues }
      onPatchFormValues={ onPatchFormValues }
      onClickSubmitForm={ onClickSubmitForm }
      createSubmittedFormMedia={ createSubmittedFormMedia }
      currentPage={ currentPage }
      dom={ dom }
      findObjectAndPatch={ findObjectAndPatch }
      data={ data }
      currentLanguage={ currentLanguage }
      togglePageSelection={ togglePageSelection }
      togglePageSelectable={ togglePageSelectable }
      duplicatePage={ duplicatePage }
      showPageRearrangement={ showPageRearrangement } />
    case 'Container': return <Container
      content={ content }
      onChangeFormBuilderState={ onChangeFormBuilderState }
      mode={ mode }
      formValues={ formValues }
      onPatchFormValues={ onPatchFormValues }
      onClickSubmitForm={ onClickSubmitForm }
      createSubmittedFormMedia={ createSubmittedFormMedia }
      currentPage={ currentPage }
      dom={ dom }
      findObjectAndPatch={ findObjectAndPatch }
      togglePageSelection={ togglePageSelection }
      togglePageSelectable={ togglePageSelectable }
      duplicatePage={ duplicatePage }
      currentLanguage={ currentLanguage } />
    case 'Row': return <CustomRow
      content={ content }
      onChangeFormBuilderState={ onChangeFormBuilderState }
      mode={ mode }
      formValues={ formValues }
      onPatchFormValues={ onPatchFormValues }
      onClickSubmitForm={ onClickSubmitForm }
      createSubmittedFormMedia={ createSubmittedFormMedia }
      currentPage={ currentPage }
      dom={ dom }
      findObjectAndPatch={ findObjectAndPatch }
      isInputDisabled={ isInputDisabled }
      togglePageSelection={ togglePageSelection }
      togglePageSelectable={ togglePageSelectable }
      duplicatePage={ duplicatePage }
      currentLanguage={ currentLanguage } />
    case 'Col': return <Column
      content={ content }
      onChangeFormBuilderState={ onChangeFormBuilderState }
      mode={ mode }
      formValues={ formValues }
      onPatchFormValues={ onPatchFormValues }
      onClickSubmitForm={ onClickSubmitForm }
      createSubmittedFormMedia={ createSubmittedFormMedia }
      dom={ dom }
      findObjectAndPatch={ findObjectAndPatch }
      data={ data }
      isInputDisabled={ isInputDisabled }
      togglePageSelection={ togglePageSelection }
      togglePageSelectable={ togglePageSelectable }
      duplicatePage={ duplicatePage }
      currentLanguage={ currentLanguage } />
    case 'Image': return <Image
      content={ content }
      onChangeFormBuilderState={ onChangeFormBuilderState }
      mode={ mode }
      formValues={ formValues }
      onPatchFormValues={ onPatchFormValues } />
    case 'TextField': return <TextField
      content={ content }
      formValues={ formValues }
      onPatchFormValues={ onPatchFormValues }
      isInputDisabled={ isInputDisabled }
      currentLanguage={ currentLanguage } />
    case 'TextArea': return <TextArea
      content={ content }
      onChangeFormBuilderState={ onChangeFormBuilderState }
      mode={ mode }
      formValues={ formValues }
      onPatchFormValues={ onPatchFormValues } 
      isInputDisabled={ isInputDisabled }
      currentLanguage={ currentLanguage } />
    case 'Button': return <ButtonComponent
      content={ content }
      onChangeFormBuilderState={ onChangeFormBuilderState }
      mode={ mode }
      onPatchFormValues={ onPatchFormValues }
      togglePageSelection={ togglePageSelection }
      onClickSubmitForm={ onClickSubmitForm } 
      currentLanguage={ currentLanguage } />
    case 'Radio': return <RadioButton
      content={ content }
      onChangeFormBuilderState={ onChangeFormBuilderState }
      mode={ mode }
      formValues={ formValues }
      onPatchFormValues={ onPatchFormValues }
      isInputDisabled={ isInputDisabled }
      currentLanguage={ currentLanguage } />
    case 'RadioFlexible': return <RadioButton
      content={ content }
      onChangeFormBuilderState={ onChangeFormBuilderState }
      mode={ mode }
      formValues={ formValues }
      onPatchFormValues={ onPatchFormValues }
      dom={ dom }
      findObjectAndPatch={ findObjectAndPatch }
      isInputDisabled={ isInputDisabled }
      currentLanguage={ currentLanguage } />
    case 'Text': return <TextComponent
      content={ content }
      onChangeFormBuilderState={ onChangeFormBuilderState }
      mode={ mode }
      onPatchFormValues={ onPatchFormValues }
      isInputDisabled={ isInputDisabled }
      currentLanguage={ currentLanguage } />
    case 'Checkbox': return <CheckboxComponent
      content={ content }
      onChangeFormBuilderState={ onChangeFormBuilderState }
      mode={ mode }
      formValues={ formValues }
      onPatchFormValues={ onPatchFormValues }
      isInputDisabled={ isInputDisabled }
      togglePageSelection={ togglePageSelection }
      currentLanguage={ currentLanguage } />
    case 'CheckboxFlexible': return <CheckboxComponent
      content={ content }
      onChangeFormBuilderState={ onChangeFormBuilderState }
      mode={ mode }
      formValues={ formValues }
      onPatchFormValues={ onPatchFormValues }
      dom={ dom }
      findObjectAndPatch={ findObjectAndPatch }
      isInputDisabled={ isInputDisabled }
      currentLanguage={ currentLanguage } />
    case 'InputFile': return <InputFile
      content={ content }
      onChangeFormBuilderState={ onChangeFormBuilderState }
      mode={ mode }
      formValues={ formValues }
      onPatchFormValues={ onPatchFormValues }
      createSubmittedFormMedia={ createSubmittedFormMedia }
      isInputDisabled={ isInputDisabled }
      currentLanguage={ currentLanguage } />
    case 'DateField': return <DateField
      content={ content }
      formValues={ formValues }
      onPatchFormValues={ onPatchFormValues }
      isInputDisabled={ isInputDisabled }
      currentLanguage={ currentLanguage } />
    case 'TableOfContents': return <TableOfContents
      onChangeFormBuilderState={ onChangeFormBuilderState }
      dom={ dom }
      currentLanguage={ currentLanguage }
      togglePageSelectable={ togglePageSelectable }
      duplicatePage={ duplicatePage }
    />
    default: return <div></div>
  }
}

export default RenderDOM
