import React from 'react'
import { Button } from 'reactstrap'

import { getItem } from 'utils/tokenStore'

import Lang from 'Lang/General'

const TableDetails = ({
  selectedCenter,
  centerLocations,
  lang
}) => {
  return (
    <tbody>
      <tr>
        <th scope="row">
          EGYM ID
        </th>
        <td>{ selectedCenter.egym_id }</td>
      </tr>
      <tr>
        <th scope="row">{ Lang[ 'CENTER_NAME' ][ lang ] }</th>
        <td>{ selectedCenter.center_name }</td>
      </tr>
      <tr>
        <th scope="row">{ Lang[ 'CLIENT_NAME' ][ lang ] }</th>
        <td>{ selectedCenter.client_name }</td>
      </tr>
      <tr>
        <th scope="row">{ Lang[ 'CONSULTANT_NAME' ][ lang ] }</th>
        <td>{ selectedCenter.consultant_name }</td>
      </tr>
      <tr>
        <th scope="row">{ Lang[ 'FIRST_NAME' ][ lang ] }</th>
        <td>{ selectedCenter.first_name }</td>
      </tr>
      <tr>
        <th scope="row">{ Lang[ 'LAST_NAME' ][ lang ] }</th>
        <td>{ selectedCenter.last_name }</td>
      </tr>
      <tr>
        <th scope="row">{ Lang[ 'LANGUAGE' ][ lang ] }</th>
        <td>{ selectedCenter.language }</td>
      </tr>
      <tr>
        <th scope="row">{ Lang[ 'EMAIL' ][ lang ] }</th>
        <td>{ selectedCenter.email }</td>
      </tr>
      <tr>
        <th scope="row">{ Lang[ 'LOCATION' ][ lang ] }</th>
        <td>
          <ol>
          {
            centerLocations.length > 0 && centerLocations.map( item => {
              return(
                <li className="mt-2">
                  { item.name }<br />
                  { `${ item.house_number }, ${ item.street },` }<br />
                  { `${ item.city }, ${ item.state }, ${ item.country }` }<br />
                  { `${ item.calling_code }-${ item.area_code }-${ item.number }` }<br />
                  { item.fax_number }
                </li>
              )
            })
          }
          </ol>
        </td>
      </tr>
      <tr>
        <th scope="row">Manager</th>
        <td>{ selectedCenter.manager }</td>
      </tr>
      <tr>
        <th scope="row">{ Lang[ 'WOMAN_CENTER' ][ lang ] }</th>
        <td>{ selectedCenter.is_female_center }</td>
      </tr>
      <tr>
        <th scope="row">{ Lang[ 'SITE_DOMAIN' ][ lang ] }</th>
        <td>
          {
            selectedCenter.domains && selectedCenter.domains.length > 0 && selectedCenter.domains.map( item => {
              return(
                <Button
                  color="primary"
                  className="mt-2"
                  onClick={() => {
                    window.open( `https://${ item.site_domain }/admin/login/${ getItem( 'PROOFPOINT_ARK_MANAGEMENT' ) }`, '_blank' )
                  }}>
                  { item.site_domain }
                </Button>
              )
            })
          }
        </td>
      </tr>
      <tr>
        <th scope="row">{ Lang[ 'CENTER_GROUP' ][ lang ] }</th>
        <td>
          <ul>
          {
            selectedCenter.center_groups_id && selectedCenter.center_groups_id.length > 0 && selectedCenter.center_groups_id.map( item => (
              <li>{ item.name }</li>
            ))
          }
          </ul>
        </td>
      </tr>
      <tr>
        <th scope="row">{ Lang[ 'STATUS' ][ lang ] }</th>
        <td>{ selectedCenter.status }</td>
      </tr>
      <tr>
        <th scope='row'>{ Lang[ 'EMAIL_SERVICE' ][ lang ] }</th>
        <td>{ selectedCenter.email_api_service }</td>
      </tr>
    </tbody>
  )
}

export default TableDetails
