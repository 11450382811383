import React, { Component } from 'react'
import { 
  Form, FormGroup,
  Label, Input, Button, CustomInput,
  Card, CardHeader, CardBody,
  Row, Col
} from 'reactstrap'
import { MdDesktopWindows, MdTablet, MdSmartphone } from 'react-icons/md'
import _ from 'lodash'

import LinkInput from 'components/LinkInput'
import Lang from 'Lang/General'
import { findObjectAndPatch } from '../utility/ObjectPatcher'

class ImageUtility extends Component {
  state = {
    viewports: [],
    selectedParent: {},
    actualImgDimension: {}
  }

  componentDidMount = () => {
    this.configureContainer()
    let tmpSelectedContainer = _.cloneDeep( this.props.selectedSection )
    if( !_.isEmpty( this.props.selectedEditableComponent ) ) {
      this.findComponentParent( tmpSelectedContainer, this.props.selectedEditableComponent )
      this.getImgDimension( this.props.selectedEditableComponent.source || this.props.selectedEditableComponent.style.backgroundImage )
    }
  }

  componentDidUpdate = pp => {
    if (
      ( pp.selectedEditableComponent.source !== this.props.selectedEditableComponent.source ) || 
      ( pp.selectedEditableComponent.style.backgroundImage !== this.props.selectedEditableComponent.style.backgroundImage )
    ) {
      this.getImgDimension( this.props.selectedEditableComponent.source || this.props.selectedEditableComponent.style.backgroundImage )
    }

    if (
      ( pp.selectedEditableComponent !== this.props.selectedEditableComponent && !_.isEmpty( this.props.selectedEditableComponent ) ) || 
      ( pp.selectedSection !== this.props.selectedSection && !_.isEmpty( this.props.selectedSection ) )
    ) {
      let tmpSelectedContainer = _.cloneDeep( this.props.selectedSection )
      this.findComponentParent( tmpSelectedContainer, this.props.selectedEditableComponent )
    }
  }

  findComponentParent = ( data, target ) => {
    if( data.children && data.children.length > 0 ) {
      let tmpIndex = _.findIndex( data.children, { id: target.id } )
      if( tmpIndex > -1 ) {
        this.setState({ selectedParent: data })
      } else {
        data.children.map( item => {
          this.findComponentParent( item, target )
        })
      }
    }
  }

  updateViewports = ( viewport ) => {
    let tmp = _.cloneDeep( this.state.viewports )
    if( tmp.indexOf( viewport ) > -1 ) {
      tmp.splice( tmp.indexOf( viewport ), 1 )
    } else {
      tmp.push( viewport )
    }
    let tmpSelectedComponent = _.cloneDeep( this.props.selectedEditableComponent )
    tmpSelectedComponent.style = {
      ...tmpSelectedComponent.style,
      [ `@media (max-width: ${ viewport })` ]: {}
    }
    return this.setState({ viewports: tmp })
  }

  configureContainer = () => {
    let tmpSelectedContainer = _.cloneDeep( this.props.selectedSection )
    if( tmpSelectedContainer.style ) {
      tmpSelectedContainer.style = {
        ...tmpSelectedContainer.style,
        position: 'relative'
      }
    } else {
      tmpSelectedContainer = {
        ...tmpSelectedContainer,
        style: {
          position: 'relative'
        }
      }
    }
    this.props.onChangeSectionHOC( 'selectedSection', tmpSelectedContainer )
  }

  renderWidthConfig = () => {
    let { selectedEditableComponent } = this.props
    return (
      <>
        <MdDesktopWindows style={{ fontSize: '24px' }} />
        <FormGroup>
          <Label>{ Lang[ 'WIDTH' ][ this.props.lang ] }</Label>
          <Input
            type={ 'text' }
            value={ selectedEditableComponent.style ? selectedEditableComponent.style.width : '' }
            style={{ width: '100px' }}
            onChange={ e => {
              let tmp = _.cloneDeep( selectedEditableComponent )
              tmp.style.width = e.target.value
              this.props.onUpdateImage( tmp )
            }} />
        </FormGroup>
        <hr />
        <FormGroup>
          <div>
            <CustomInput 
              type="checkbox" 
              id="MediaWidth-Tablet"
              label={ <MdTablet style={{ fontSize: '24px' }} /> }
              onClick={() => this.updateViewports( '768px' )}
              inline />
            <CustomInput 
              type="checkbox" 
              id="MediaWidth-Mobile"
              label={ <MdSmartphone style={{ fontSize: '24px' }} /> }
              onClick={() => this.updateViewports( '450px' )}
              inline />
          </div>
        </FormGroup>
        <hr />
        {
          this.state.viewports.indexOf( '768px' ) > -1 && (
            <FormGroup>
              <Label><MdTablet style={{ fontSize: '24px' }} /></Label>
              <FormGroup>
                <Label>{ Lang[ 'WIDTH' ][ this.props.lang ] }</Label>
                <Input 
                  type="text" 
                  value={ 
                    selectedEditableComponent.style[ `@media (max-width: 768px)` ] 
                      ? selectedEditableComponent.style[ `@media (max-width: 768px)` ][ 'width' ]
                      : ''
                  }
                  onChange={ e => {
                    let tmp = _.cloneDeep( selectedEditableComponent )
                    if( tmp.style[ `@media (max-width: 768px)` ] ) {
                      tmp.style[ `@media (max-width: 768px)` ] = {
                        ...tmp.style[ `@media (max-width: 768px)` ],
                        width: e.target.value
                      }
                    } else {
                      tmp.style[ `@media (max-width: 768px)` ] = {
                        width: e.target.value
                      }
                    }
                    this.props.onUpdateImage( tmp )
                  }}
                  style={{ width: '100px' }} />
              </FormGroup>
            </FormGroup>
          )
        }
        <hr />
        {
          this.state.viewports.indexOf( '450px' ) > -1 && (
            <FormGroup>
              <Label><MdSmartphone style={{ fontSize: '24px' }} /></Label>
              <FormGroup>
                <Label>{ Lang[ 'WIDTH' ][ this.props.lang ] }</Label>
                <Input 
                  type="text" 
                  value={ 
                    selectedEditableComponent.style[ `@media (max-width: 450px)` ]
                      ? selectedEditableComponent.style[ `@media (max-width: 450px)` ][ 'width' ] 
                      : ''
                  }
                  onChange={ e => {
                    let tmp = _.cloneDeep( selectedEditableComponent )
                    if( tmp.style[ `@media (max-width: 450px)` ] ) {
                      tmp.style[ `@media (max-width: 450px)` ] = {
                        ...tmp.style[ `@media (max-width: 450px)` ],
                        width: e.target.value
                      }
                    } else {
                      tmp.style[ `@media (max-width: 450px)` ] = {
                        width: e.target.value
                      }
                    }
                    this.props.onUpdateImage( tmp )
                  }}
                  style={{ width: '100px' }} />
              </FormGroup>
            </FormGroup>
          )
        }
      </>
    )
  }

  getImgDimension = ( imgSrcURL ) => {
    let img = new Image()
    img.onload = () => this.setState({ 
      actualImgDimension: {
        'width': img.width,
        'height': img.height
      }})
    img.src = imgSrcURL.replace(/url\(|\)$|"/ig, '')
  }

  render = () => {
    let {
      toggleMediaDatabase,
      selectedEditableComponent,
    } = this.props
    return (
      <Card className="mb-2">
        <CardHeader>{ Lang[ 'IMAGE_EDITOR' ][ this.props.lang ] }</CardHeader>
        <CardBody>
          <Form onSubmit={ e => e.preventDefault() }>
            <Label>
              { `${ Lang[ 'ACTUAL_IMAGE_SIZE' ][ this.props.lang ] }: ` }
              { 
                this.state.actualImgDimension.width && this.state.actualImgDimension.height
                  ? `${  this.state.actualImgDimension.width }px x ${ this.state.actualImgDimension.height }px ` 
                  : '-'
              }
            </Label>
            <Row>
              <Col md={ 6 }>
                { this.renderWidthConfig() }
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label style={{width:"100%"}}>{ Lang[ 'POSITION' ][ this.props.lang ] }</Label>
                  <Button
                    color="primary"
                    disabled={ 
                      this.state.selectedParent &&
                      !_.isEmpty( this.state.selectedParent ) &&
                      this.state.selectedParent.style &&
                      ( this.state.selectedParent.style.textAlign === 'left' || this.state.selectedParent.style.justifyContent === 'flex-start' )
                    }
                    style={{ margin: '5px' }}
                    onClick={() => {
                      let tmpSection = _.cloneDeep( this.props.selectedSection )
                      let tmp = _.cloneDeep( this.state.selectedParent )
                      if( !tmp.style ){
                        tmp.style = {}
                      }
                      if( tmp.style.display === 'flex' ){
                        tmp.style.justifyContent = 'flex-start'
                      } else {
                        tmp.style.textAlign = 'left'
                      }
                      this.setState({ selectedParent: tmp }, () => {
                        findObjectAndPatch( tmp, tmpSection )
                        this.props.onChangeSectionHOC( 'selectedSection', tmpSection )
                      })
                    }}>
                    { Lang[ 'LEFT' ][ this.props.lang ] }
                  </Button>
                  <Button
                    color="primary"
                    disabled={ 
                      this.state.selectedParent &&
                      !_.isEmpty( this.state.selectedParent ) &&
                      this.state.selectedParent.style &&
                      ( this.state.selectedParent.style.textAlign === 'center' || this.state.selectedParent.style.justifyContent === 'center' )
                    }
                    style={{ margin: '5px' }}
                    onClick={() => {
                      let tmpSection = _.cloneDeep( this.props.selectedSection )
                      let tmp = _.cloneDeep( this.state.selectedParent )
                      if( !tmp.style ){
                        tmp.style = {}
                      }
                      if( tmp.style.display === 'flex' ){
                        tmp.style.justifyContent = 'center'
                      } else {
                        tmp.style.textAlign = 'center'
                      }
                      this.setState({ selectedParent: tmp }, () => {
                        findObjectAndPatch( tmp, tmpSection )
                        this.props.onChangeSectionHOC( 'selectedSection', tmpSection )
                      })
                    }}>
                      { Lang[ 'CENTER' ][ this.props.lang ] }
                  </Button>
                  <Button
                    color="primary"
                    disabled={ 
                      this.state.selectedParent &&
                      !_.isEmpty( this.state.selectedParent ) &&
                      this.state.selectedParent.style &&
                      ( this.state.selectedParent.style.textAlign === 'right' || this.state.selectedParent.style.justifyContent === 'flex-end' )
                    }
                    style={{ margin: '5px' }}
                    onClick={() => {
                      let tmpSection = _.cloneDeep( this.props.selectedSection )
                      let tmp = _.cloneDeep( this.state.selectedParent )
                      if( !tmp.style ){
                        tmp.style = {}
                      }
                      if( tmp.style.display === 'flex' ){
                        tmp.style.justifyContent = 'flex-end'
                      } else {
                        tmp.style.textAlign = 'right'
                      }
                      this.setState({ selectedParent: tmp }, () => {
                        findObjectAndPatch( tmp, tmpSection )
                        this.props.onChangeSectionHOC( 'selectedSection', tmpSection )
                      })
                    }}>
                      { Lang[ 'RIGHT' ][ this.props.lang ] }
                  </Button>
                  { 
                    this.state.selectedParent.style && this.state.selectedParent.style.display !== 'flex' && <Button
                      disabled={ 
                        this.state.selectedParent &&
                        !_.isEmpty( this.state.selectedParent ) &&
                        this.state.selectedParent.style &&
                        this.state.selectedParent.style.textAlign === 'initial'
                      }
                      color="primary"
                      style={{ margin: '5px' }}
                      onClick={() => {
                        let tmpSection = _.cloneDeep( this.props.selectedSection )
                        let tmp = _.cloneDeep( this.state.selectedParent )
                        if( !tmp.style ){
                          tmp.style = {}
                        }
                        tmp.style.textAlign = 'initial'
                        this.setState({ selectedParent: tmp }, () => {
                          findObjectAndPatch( tmp, tmpSection )
                          this.props.onChangeSectionHOC( 'selectedSection', tmpSection )
                        })
                      }}>
                        { Lang[ 'NONE' ][ this.props.lang ] }
                    </Button> 
                  }
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={ 12 }>
                <FormGroup>
                  <Label>{ Lang[ 'ASSIGN_IMAGE' ][ this.props.lang ] }</Label>
                  <Input 
                    type="text" 
                    disabled 
                    value={ 
                      this.props.selectedEditableComponent.source.replace( /.*(?=\/)/, '').replace( '/', '' ) || 
                      this.props.selectedEditableComponent.style.backgroundImage.replace( /.*(?=\/)/, '').replace( '/', '' )
                    } />
                  <Button
                    color="primary"
                    style={{ marginTop: '10px' }}
                    onClick={() => {
                      this.props.onChangeSectionEditorState( 
                        'mediaDatabaseFor', 
                        !_.isEmpty( this.props.selectedEditableComponent.style.backgroundImage ) ? 'backgroundImage' : 'source' 
                      )
                      toggleMediaDatabase( true )
                    }}>
                    { Lang[ 'MEDIA_DATABASE' ][ this.props.lang ] }
                  </Button>
                </FormGroup>
                <FormGroup>
                  <Label>{ Lang[ 'TITLE' ][ this.props.lang ] }</Label>
                  <Input
                    type="text"
                    value={ 
                      !_.isEmpty( this.props.selectedEditableComponent.title ) ? this.props.selectedEditableComponent.title : '' 
                    }
                    onChange={ e => {
                      let tmpSelectedComponent = {}
                      tmpSelectedComponent = {
                        ...selectedEditableComponent,
                        title: e.target.value
                      }
                      this.props.onUpdateImage( tmpSelectedComponent )
                    }} />
                </FormGroup>
                <FormGroup>
                  <Label>{ Lang[ 'ALT' ][ this.props.lang ] }</Label>
                  <Input
                    type="text"
                    value={ 
                      !_.isEmpty( this.props.selectedEditableComponent.alt ) ? this.props.selectedEditableComponent.alt : '' 
                    }
                    onChange={ e => {
                      let tmpSelectedComponent = {}
                      tmpSelectedComponent = {
                        ...selectedEditableComponent,
                        alt: e.target.value
                      }
                      this.props.onUpdateImage( tmpSelectedComponent )
                    }} />
                </FormGroup>
              </Col>
            </Row>
            {
              ( !selectedEditableComponent.children || selectedEditableComponent.children.length < 1 ) && // redirect link on stand-alone image only
              <Row>
                <Col md={ 12 }>
                  <LinkInput 
                    selectedContainer={ selectedEditableComponent }
                    onUpdate={ ( updatedDom ) => this.props.onUpdateImage( updatedDom ) }
                    setMediaDatabase={ ( isOpen ) => {
                      Promise.all([
                        this.props.onChangeSectionEditorState( 'mediaDatabaseFor', 'clickable' )
                      ]).then( () => {
                        this.props.toggleMediaDatabase( isOpen )
                      })
                    }}
                    pages={ this.props.pages }
                    selectedLanguage={ this.props.lang }
                  />
                </Col>
              </Row>
            }
            <Button
              color="success"
              onClick={() => {
                let tmpSelectedComponent = _.cloneDeep( selectedEditableComponent )
                tmpSelectedComponent.style = { 
                  ...tmpSelectedComponent.style, 
                  top: `${ this.props.selectedImagePosition.y + 0 }px`, 
                  left: `${ this.props.selectedImagePosition.x + 0 }px`
                }
                this.props.onUpdateImage( tmpSelectedComponent )
                this.props.onChangeSectionEditorState( 'selectedEditableComponent', {} )
                this.props.onChangeSectionEditorState( 'selectedContainer', {} )
              }}>
                { Lang[ 'SAVE_IMAGE' ][ this.props.lang ] }
            </Button>
          </Form>
        </CardBody>
      </Card>
    )
  }
}

export default ImageUtility