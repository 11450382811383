import React, { useEffect } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {
  Container, Row, Col,
  Card, CardHeader, CardBody,
  Button, Label,
  Form, FormGroup
} from 'reactstrap'
import DatePicker, { registerLocale } from 'react-datepicker'
import Moment from 'moment'
import de from 'date-fns/locale/de'
import it from 'date-fns/locale/it'
import nl from 'date-fns/locale/nl'
import _ from 'lodash'

import { convertObjToBase64 } from 'utils/objToBase64'
import ARKTable from 'components/Table'
import PageTitle from 'components/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import VoucherReportsHOC from './actions'
import Lang from 'Lang/General'

import "react-datepicker/dist/react-datepicker.css"

const VoucherReports = props => {
  useEffect( () => {
    searchVoucherReports( 1, {} )
    registerLocale( props.data.languageReducer.lang, DATEPICKER_LOCALE[ props.data.languageReducer.lang ] )
  }, [] )

  const searchVoucherReports = ( page, dateParams ) => {
    let tmpSearchParams = !_.isEmpty( dateParams ) ? {
      page: page,
      is_paginated: true,
      filter: {
        date: dateParams
      }
    } : {
      page: page,
      is_paginated: true
    }
    let tmpSearchString = convertObjToBase64( tmpSearchParams )
    props.getVoucherReports( 
      tmpSearchString, 
      !_.isEmpty( dateParams ) ? true : false 
    )
  }

  return(
    <>
      <PageTitle
        heading={ Lang[ 'VOUCHER_REPORT' ][ props.data.languageReducer.lang ] }
        icon="pe-7s-plus icon-gradient bg-happy-itmeo" />
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={ true }
        transitionAppearTimeout={ 0 }
        transitionEnter={ false }
        transitionLeave={ false }>
        <Container fluid>
          <Row>
            <Col md={ 12 }>
              <Card className='main-card mb-3' >
                <CardHeader>{ Lang[ 'SEARCH' ][ props.data.languageReducer.lang ] }</CardHeader>
                <CardBody>
                  <Form>
                    <FormGroup>
                      <div className='d-flex flex-row flex-wrap align-items-center' style={{ position: "relative", zIndex: "1" }}>
                        <div className='d-flex flex-row align-items-center mt-2 mr-2'>
                          <Label className='mr-2 mb-0'>
                            { Lang[ 'EVALUATION_PERIOD' ][ props.data.languageReducer.lang ] } : </Label>
                          <DatePicker
                            className='form-control'
                            style={{ width: "fit-content" }}
                            locale={ props.data.languageReducer.lang }
                            selected={ !_.isEmpty( props.voucherReportsDateQuery ) ? props.voucherReportsDateQuery.toDate() : '' }
                            onChange={ date => props.onChangeVoucherReportsHOC( 'voucherReportsDateQuery', Moment( date ) ) }
                            dateFormat='MMMM yyyy'
                            showMonthYearPicker />
                        </div>
                        <div className='d-flex flex-row align-items-center'>
                          <Button
                            color='primary'
                            className='mr-2 mt-2'
                            onClick={ () => searchVoucherReports( 
                              1, 
                              !_.isEmpty( props.voucherReportsDateQuery ) ? ({
                                $gte: props.voucherReportsDateQuery.clone().startOf( 'month' ).add( Moment().utcOffset(), 'minutes' ).utc().format(),
                                $lte: props.voucherReportsDateQuery.clone().endOf( 'month' ).add( Moment().utcOffset(), 'minutes' ).utc().format()
                              }) : ({})
                            )}>
                            { Lang[ 'SEARCH' ][ props.data.languageReducer.lang ] }</Button>
                          <Button
                            color='danger'
                            className='mt-2'
                            onClick={ () => searchVoucherReports( 1, {} ) }>
                            { Lang[ 'RESET' ][ props.data.languageReducer.lang ] }</Button>
                        </div>
                      </div>
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
              <Card className='main-card mb-3' >
                <CardHeader>{ `${ Lang[ 'EVALUATION_PERIOD' ][ props.data.languageReducer.lang ] } : ${ props.dateQueryString }` }</CardHeader>
                <CardBody>
                  <ARKTable
                    showPagination={ false }
                    defaultPageSize={ 10 }
                    NoDataComponent={ () => <span></span> }
                    data={ props.voucherReports.data }
                    totalPages={ props.voucherReportPages }
                    page={ props.voucherReports.meta ? props.voucherReports.meta.current_page : 1 }
                    onChangePage={ page => searchVoucherReports( 
                      page, 
                      !_.isEmpty( props.voucherReportsDateQuery ) ? ({
                        $gte: props.voucherReportsDateQuery.clone().startOf( 'month' ).add( Moment().utcOffset(), 'minutes' ).utc().format(),
                        $lte: props.voucherReportsDateQuery.clone().endOf( 'month' ).add( Moment().utcOffset(), 'minutes' ).utc().format()
                      }) : ({})
                    )}
                    columns={[
                      {
                        Header: Lang[ 'CENTER_NAME' ][ props.data.languageReducer.lang ],
                        accessor: 'center_name'
                      },
                      {
                        Header: Lang[ 'AMOUNT_OF_VOUCHER_USED' ][ props.data.languageReducer.lang ],
                        accessor: 'total_voucher'
                      },
                      {
                        Header: Lang[ 'TOTAL_DISCOUNT' ][ props.data.languageReducer.lang ],
                        accessor: 'total_voucher_cost'
                      }
                    ]}/>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </ReactCSSTransitionGroup>
      { props.onLoadVoucherReportsHOC && <LoadingOverlay/> }
    </>
  )
}

const DATEPICKER_LOCALE = {
  en: {},
  de: de,
  it: it,
  nl: nl,
}

export default VoucherReportsHOC( VoucherReports )

