import React, { Component } from 'react'
import { Dialog, Button } from '@material-ui/core'
import { MdClose } from 'react-icons/md'

import TemplateEditorCard from '../components/card'
import LoadingModal from 'components/Indicator/LoadingOverlay'

import './index.scss'

import Lang from 'Lang/General'

class PageCreator extends Component {
  state={
    errorMessage: {
      name: '',
      url: ''
    }
  }
  onClickCreatePage = () => {
    if (this.props.newPage.name.length > 0 && this.props.newPage.url.length > 0) {
      let tmp = {
        ...this.props.newPage,
        url: `/${ this.props.newPage.url }`,
        is_system_page: false,
        module: ''
      }
      this.props.createPage( this.props.template_id, tmp )
    }
    if ( this.props.newPage.name.length === 0 ){
      let tmp = this.state.errorMessage
      tmp['name'] = 'name is required'

      this.setState({ errorMessage: tmp })
    }
    if ( this.props.newPage.url.length === 0 ){
      let tmp = this.state.errorMessage
      tmp['url'] = 'url is required'

      this.setState({ errorMessage: tmp })
    }
  }

  render = () => {
    return (
      <Dialog
        open={ this.props.showPageCreator }
        maxWidth={ 'lg' }
        fullWidth={ true }>
        <TemplateEditorCard>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h4>{`${ Lang[ 'CREATE_PAGE_FOR_TEMPLATE' ][ this.props.lang ] } ${ this.props.templateName }`}</h4>
            <Button
              classes={{
                root: "button-close",
                label: "button-text" }}
              onClick={ () => this.props.onChangeShowPageCreator( false ) }>
              <MdClose style={{ fontSize: '20px' }}/>
            </Button>
          </div>
          <hr />
          <div>
            <label>{ Lang[ 'PAGE_NAME' ][ this.props.lang ] }</label>
            <input className="form-control" value={ this.props.newPage.name } onChange={ e => this.props.onChangeNewPage( e.target.value, 'name' ) } />
            {
              this.state.errorMessage.name.length !== 0 &&
              <label style={{ color: 'red', fontSize: '14px' }}>{ this.state.errorMessage.name }</label>
            }
          </div>
          <div>
            <label>{ Lang[ 'PAGE_URL' ][ this.props.lang ] }</label>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
              <label style={{ fontSize: '18px', paddingLeft: '5px', marginRight: '5px' }}> / </label>
              <input className="form-control" value={ this.props.newPage.url } onChange={ e => this.props.onChangeNewPage( e.target.value, 'url' ) } />

            </div>
            {
              this.state.errorMessage.url.length !== 0 &&
              <label style={{ color: 'red', fontSize: '14px' }}>{ this.state.errorMessage.url }</label>
            }
          </div>
          <hr />
          <div className="page-creator-footer">
            <Button
              classes={{ root: 'btn-primary' }}
              style={{ marginRight: '10px' }}
              onClick={ this.onClickCreatePage }>
              { Lang[ 'CREATE_PAGE' ][ this.props.lang ] }
            </Button>
            <Button
              classes={{ root: 'btn-secondary' }}
              onClick={() => this.props.onChangeShowPageCreator( false )}>
              { Lang[ 'CLOSE' ][ this.props.lang ] }
            </Button>
          </div>
          { this.props.onLoadPagesUpdate && <LoadingModal /> }
        </TemplateEditorCard>
      </Dialog>
    )
  }
}

export default PageCreator
