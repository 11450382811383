import React from 'react'
import { connect } from 'react-redux'
import { FormGroup, Form } from 'reactstrap'

import MultiLangInput from 'components/Input/MultiLang'
import Lang from 'Lang/General'

const CategoryForm = ({
  onChangeCategoryData,
  data,
  dataReducer
}) => {
  return(
    <Form>
      <FormGroup>
        <MultiLangInput
          label={ Lang[ 'NAME' ][ dataReducer.languageReducer.lang ] }
          value={ data && data.name }
          onChange={ val => {
            onChangeCategoryData( 'name', val )
          }}
          selectedLanguage={ dataReducer.languageReducer.lang }
       />
      </FormGroup>
      <FormGroup>
        <MultiLangInput
          type={ 'textarea' }
          label={ Lang[ 'DESCRIPTION' ][ dataReducer.languageReducer.lang ] }
          value={ data && data.description }
          onChange={ val => {
            onChangeCategoryData( 'description', val )
          }}
          selectedLanguage={ dataReducer.languageReducer.lang }
       />
      </FormGroup>
    </Form>
  )
}
const mapStateToProps = state => ({ dataReducer: state })

export default connect( mapStateToProps )( CategoryForm )
