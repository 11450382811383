import React, { useEffect } from 'react'
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button, UncontrolledTooltip
} from 'reactstrap'
import { compose } from 'redux'
import _ from 'lodash'
import Moment from 'moment'

import ARKTable from 'components/Table'
import PageTitle from 'components/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import DeleteConfirmation from 'components/Modal/delete'
import Lang from 'Lang/General'

import EventTemplatesHOC from './actions.js'
import EventTypesHOC from '../TypesSettings/actions'
import EventTemplateForm from './Form'

const EventTemplates = props => {
  useEffect( () => {
    props.getEventTemplates()
    props.getTypesSettings()
  }, [] )

  useEffect( () => {
    if( !props.showCreateEventTemplate ){
      props.onChangeEventTemplatesHOC( 
        'newEventTemplate',
        {
          am_event_type_id: 0,
          title: {
            en: '',
            de: '',
            nl: '',
            it: '',
            fr: ''
          },
          brief_description: {
            en: '',
            de: '',
            nl: '',
            it: '',
            fr: ''
          },
          description: {
            en: '',
            de: '',
            nl: '',
            it: '',
            fr: ''
          },
          image_url:'',
          banner_image_url:'',
          is_for_member: false,
          is_for_guest: false,
          is_for_program_participant: false,
          is_for_trial_member: false,
          is_show_in_courseplan: false,
          has_in_app_offer: false,
          placement: 'member_area',
          price: '0.00',
          price_member: '0.00',
          is_free_for_member: false,
          is_free_for_campaign_participant: false,
          is_free_for_guest: false,
          is_free_for_trial_member: false,
          is_repeat: false,
          repeat_day: parseInt( Moment().format( 'd' ) ),
          start_date_time: Moment().add( 30, 'minutes' ),
          end_date_time: Moment().add( 90, 'minutes' ),
          exclude_dates: [],
          youtube_links: [],
          repeat_start_date: Moment().add( 30, 'minutes' ),
          repeat_end_date: Moment().add( 1, 'week' ).add( 90, 'minutes' ),
        }
      )
    }
  }, [ props.showCreateEventTemplate ] )

  useEffect( () => {
    if( !props.showUpdateEventTemplate ){
      props.onChangeEventTemplatesHOC( 'selectedEventTemplate', {} )
    }
  }, [ props.showUpdateEventTemplate ] )

  const handleSecurityModulesCreate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Member Area' })
      return tmpModules.is_create
    }
  }

  const handleSecurityModulesUpdate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Member Area' })
      return tmpModules.is_update
    }
  }

  const handleSecurityModulesDelete= () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Member Area' })
      return tmpModules.is_delete
    }
  }

  const renderCreateEventTemplateModal = () => (
    <Modal size='lg' isOpen={ props.showCreateEventTemplate } >
      <ModalHeader toggle={ () => props.onChangeEventTemplatesHOC( 'showCreateEventTemplate', false ) } >
        { Lang[ 'CREATE' ][ props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        <EventTemplateForm
          eventTemplateType='newEventTemplate'
          currentEventTemplate={ props.newEventTemplate }
          onChangeEventTemplatesHOC={ props.onChangeEventTemplatesHOC }
          typesSettings={ props.typesSettings }
          toggleMediaDatabase={ props.toggleMediaDatabase }
          lang={ props.data.languageReducer.lang }
          eventTemplateErrorMessage={ props.eventTemplateErrorMessage }
        />
        { props.onLoadEventTemplatesHOC && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => {
            let tmp = _.cloneDeep( props.newEventTemplate )
            tmp.price = parseFloat( tmp.price || 0 ).toFixed( 2 )
            tmp.price_member = parseFloat( tmp.price_member || 0 ).toFixed( 2 )
            tmp.start_date_time = Moment( tmp.start_date_time ).utcOffset( '+0100', true ).format()
            tmp.end_date_time = Moment( tmp.end_date_time ).utcOffset( '+0100', true ).format()
            tmp.repeat_start_date = Moment( tmp.repeat_start_date ).utcOffset( '+0100', true ).format()
            tmp.repeat_end_date = Moment( tmp.repeat_end_date ).utcOffset( '+0100', true ).format()
            tmp.exclude_dates = tmp.exclude_dates.map( excludeDate => Moment( excludeDate, 'DD/MM/YYYY' ).format() )
            props.createEventTemplate( tmp ) 
          }}>
          { Lang[ 'SUBMIT' ][ props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )

  const renderUpdateEventTemplateModal = () => (
    <Modal size='lg' isOpen={ props.showUpdateEventTemplate } >
      <ModalHeader toggle={ () => props.onChangeEventTemplatesHOC( 'showUpdateEventTemplate', false ) } >
        { Lang[ 'UPDATE' ][ props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        {
          !_.isEmpty( props.selectedEventTemplate ) && <EventTemplateForm
            { ...props }
            eventTemplateType='selectedEventTemplate'
            currentEventTemplate={ props.selectedEventTemplate }
            lang={ props.data.languageReducer.lang } />
        }
        { props.onLoadEventTemplatesHOC && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => {
            let tmp = _.cloneDeep( props.selectedEventTemplate )
            tmp.price = parseFloat( tmp.price || 0 ).toFixed( 2 )
            tmp.price_member = parseFloat( tmp.price_member || 0 ).toFixed( 2 )
            tmp.start_date_time = Moment( tmp.start_date_time ).utcOffset( '+0100', true ).format()
            tmp.end_date_time = Moment( tmp.end_date_time ).utcOffset( '+0100', true ).format()
            tmp.exclude_dates = tmp.exclude_dates.map( excludeDate => Moment( excludeDate, 'DD/MM/YYYY' ).format() )
            if( tmp.repeat_start_date ){
              tmp.repeat_start_date = Moment( tmp.repeat_start_date ).utcOffset( '+0100', true ).format() 
            }
            if( tmp.repeat_end_date ){
              tmp.repeat_end_date = Moment( tmp.repeat_end_date ).utcOffset( '+0100', true ).format() 
            }
            props.updateEventTemplate( tmp ) 
          }}>
          { Lang[ 'SUBMIT' ][ props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )

  return(
    <>
      <PageTitle
        heading={ Lang[ 'EVENT_TEMPLATES' ][ props.data.languageReducer.lang ] }
        icon='pe-7s-users icon-gradient bg-happy-fisher'
        buttons={[
          {
            buttonText: Lang[ 'CREATE' ][ props.data.languageReducer.lang ],
            onClick: () => props.onChangeEventTemplatesHOC( 'showCreateEventTemplate', true ),
            display: handleSecurityModulesCreate()
          }
        ]}/>
      <ARKTable
        data={ props.eventTemplates.data }
        columns={[
          {
            Header: Lang[ 'TITLE' ][ props.data.languageReducer.lang ],
            accessor: 'title',
            Cell: ({ value }) => value[ props.data.languageReducer.lang ]
          },
          {
            Header: Lang[ 'DATE' ][ props.data.languageReducer.lang ],
            accessor: 'start_date',
            Cell: row => row.original.is_repeat ? (
              `${ Lang[ 'EVERY' ][ props.data.languageReducer.lang ] } ${ 
                Moment( row.original.repeat_day, 'd' ).locale( props.data.languageReducer.lang ).format( 'dddd' ) } ${ 
                  Moment( row.original.start_date_time ).utcOffset( '+0100' ).format( 'HH:mm' ) } - ${ 
                    Moment( row.original.end_date_time ).utcOffset( '+0100' ).format( 'HH:mm' ) }`
            ) : (
              `${ Moment( row.original.start_date_time ).utcOffset( '+0100' ).format( 'DD-MM-YYYY HH:mm' ) } - ${ 
                Moment( row.original.end_date_time ).utcOffset( '+0100' ).format( 'HH:mm' ) }`
            )
          },
          {
            Header: Lang[ 'EVENT_PLACEMENT' ][ props.data.languageReducer.lang ],
            accessor: 'placement',
            Cell: ({ value }) => Lang[ value.toUpperCase() ][ props.data.languageReducer.lang ]
          },
          {
            Header: Lang[ 'ACTIONS' ][ props.data.languageReducer.lang ],
            accessor: 'id',
            Cell: ({ value }) => (
              <div className='d-flex justify-content-center' >
                {
                  handleSecurityModulesUpdate() && (
                    <>
                      <Button
                        id={ `EventTemplate-${ value }-update` }
                        className='mb-2 mr-2 btn-icon btn-icon-only'
                        color='primary'
                        onClick={ () => {
                          props.getSelectedEventTemplate( value )
                          props.getSelectedEventTemplateFiles( value ) 
                        }}>
                        <i className='pe-7s-note'> </i>
                      </Button>
                      <UncontrolledTooltip
                        target={ `EventTemplate-${ value }-update` }
                        placement='top'>{ Lang[ 'EDIT' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                    </>
                  )
                }
                {
                  handleSecurityModulesDelete() && (
                    <>
                      <Button
                        id={ `EventTemplate-${ value }-delete` }
                        className='mb-2 mr-2 btn-icon btn-icon-only'
                        color='danger'
                        onClick={ () => props.onChangeEventTemplatesHOC( 'showDeleteEventTemplateId', parseInt( value ) ) }>
                        <i className='pe-7s-trash'> </i>
                      </Button>
                      <UncontrolledTooltip
                        target={ `EventTemplate-${ value }-delete` }
                        placement='top'>{ Lang[ 'REMOVE' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                    </>
                  )
                }
              </div>
            )
          }
        ]}
        totalPages={ props.eventTemplatesPages }
        page={ props.eventTemplates.meta ? props.eventTemplates.meta.current_page : 1 }
        onChangePage={ pg => props.getEventTemplates( pg ) }
        showPagination={ false } 
      />
      <DeleteConfirmation
        handleModalClose={ () => props.onChangeEventTemplatesHOC( 'showDeleteEventTemplateId', 0 ) }
        deleteOpen={ props.showDeleteEventTemplateId > 0 }
        confirmAction={ () => props.removeEventTemplate( props.showDeleteEventTemplateId ) }
        content={ Lang[ 'DELETE_CONFIRMATION_EVENT_TEMPLATE' ][ props.data.languageReducer.lang ] }
        lang={ props.data.languageReducer.lang } />
      { renderCreateEventTemplateModal() }
      { renderUpdateEventTemplateModal() }
      { ( props.onLoadEventTemplatesHOC || props.onLoadTypesSettingsHOC ) && <LoadingOverlay/> }
    </>
  )
}

export default compose(
  EventTemplatesHOC,
  EventTypesHOC
)( EventTemplates )