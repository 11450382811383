import React, { Component } from 'react'
import {
  Button, FormGroup, Input, Label, Container, Row, Col, Table,
  Modal, ModalBody, ModalHeader, ModalFooter, 
  CardHeader, Card, CardBody, CardFooter,
  UncontrolledTooltip
 } from 'reactstrap'
 import _ from 'lodash'
 import { toast } from 'react-toastify'

import PageTitle from 'components/PageTitle'
import ARKTable from 'components/Table'
import CategoryForm from './CategoryForm'
import CategoryHOC from './actions/index'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import DeleteConfirmationDialog from 'components/Modal/delete'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

class Categories extends Component{

  componentDidMount = () => {
    let tmp = {
      page: 1,
      is_paginated: true
    }
    this.props.getAllCategory( convertObjToBase64( tmp ) )
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Digital Order Form' })
      return tmpModules.is_update
    }
  }

  renderCreateCategory = () => {
    return(
      <Modal isOpen={ this.props.showCategoryModal } size="lg">
        <ModalHeader toggle={ () => this.props.handleShowCategoryModal( false ) }>
          { Lang[ 'NEW_CATEGORY' ][ this.props.data.languageReducer.lang ] }
        </ModalHeader>
        <ModalBody>
          <CategoryForm
            onChangeCategoryData={ this.props.onChangeCategoryData }
            data={ this.props.selectedCategory } />
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-1" color="primary" 
            onClick={ () => {
              let valid = true
              if( _.values(this.props.selectedCategory.name).every(_.isEmpty) ) {
                toast.error( Lang[ 'NAME_IS_REQUIRED' ][ this.props.data.languageReducer.lang ] )
                valid = false
              }
              if( _.values(this.props.selectedCategory.description).every(_.isEmpty) ) {
                toast.error( Lang[ 'DESCRIPTION_IS_REQUIRED' ][ this.props.data.languageReducer.lang ] )
                valid = false
              }
              if (valid) {
                this.props.createCategory( this.props.selectedCategory ) 
              }
            }}>
              { Lang[ 'CREATE' ][ this.props.data.languageReducer.lang ] }
          </Button>
            
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.handleShowCategoryModal( false ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderEditCategory = () => {
    return(
      <Modal isOpen={ this.props.showCategoryModal } size="lg">
        <ModalHeader toggle={ () => this.props.handleShowCategoryModal( false ) }>
          { Lang[ 'EDIT_CATEGORY' ][ this.props.data.languageReducer.lang ] }
        </ModalHeader>
        <ModalBody>
          <CategoryForm
            onChangeCategoryData={ this.props.onChangeCategoryData }
            data={ this.props.selectedCategory }/>
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-1" color="primary" 
            onClick={ () => {
              let valid = true
              if( _.values(this.props.selectedCategory.name).every(_.isEmpty) ) {
                toast.error( Lang[ 'NAME_IS_REQUIRED' ][ this.props.data.languageReducer.lang ] )
                valid = false
              }
              if( _.values(this.props.selectedCategory.description).every(_.isEmpty) ) {
                toast.error( Lang[ 'DESCRIPTION_IS_REQUIRED' ][ this.props.data.languageReducer.lang ] )
                valid = false
              }
              if (valid) {
                this.props.updateCategory( this.props.selectedCategory.id, this.props.selectedCategory ) }
              }
            }>
              { Lang[ 'SAVE' ][ this.props.data.languageReducer.lang ] }
          </Button>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.handleShowCategoryModal( false ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  render = () => {
    return(
      <>
        <PageTitle
          heading={ Lang[ 'FORM_CATEGORIES' ][ this.props.data.languageReducer.lang ] }
          icon="pe-7s-folder icon-gradient bg-happy-fisher"
          buttons={[
            {
              buttonText: Lang[ 'NEW_CATEGORY' ][ this.props.data.languageReducer.lang ],
              onClick: () => this.props.handleShowCategoryModal( true, 'create' ),
              display: this.handleSecurityModulesUpdate()
            }
          ]} />
        <Card className="main-card mb-3">
          <CardHeader>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }</CardHeader>
          <CardBody>
            <Container style={{ margin: 0 }}>
              <Row>
                <Col md={ 12 }>
                  <FormGroup>
                    <Label for="content" sm={ 2 }>{ Lang[ 'NAME' ][ this.props.data.languageReducer.lang ] }</Label>
                    <Input
                      name="teaser_text" id="teaser_text"
                      placeholder={ Lang[ 'SEARCH_BY_NAME' ][ this.props.data.languageReducer.lang ] }
                      value={ this.props.searchParams.name }
                      onChange={ e => {
                        let tmp = _.cloneDeep( this.props.searchParams )
                        tmp[ 'name' ] = e.target.value
                        this.props.onChangeCategoryHOC( 'searchParams', tmp )
                      }}/>
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </CardBody>
          <CardFooter>
            <Button
              color="primary"
              onClick={ () => {
                let tmp = {
                  page: 1,
                  is_paginated: true,
                  filter: {
                    name: {
                      $like: `%${ this.props.searchParams.name.toLowerCase() }%`
                    }
                  }
                }
                return this.props.getAllCategory( convertObjToBase64( tmp ) )
              }}>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }
            </Button>
            <Button
              color="danger"
              onClick={ () => {
                this.props.resetSearchParams()
                let tmp = {
                  page: 1,
                  is_paginated: true
                }
                return this.props.getAllCategory( convertObjToBase64( tmp ) )
              }}>{ Lang[ 'RESET' ][ this.props.data.languageReducer.lang ] }
            </Button>
          </CardFooter>
        </Card>
        <ARKTable
          data={ this.props.categories.data }
          columns={[
            {
              Header: Lang[ 'NAME' ][ this.props.data.languageReducer.lang ],
              accessor: 'name.' + this.props.data.languageReducer.lang
            },
            {
              Header: Lang[ 'DESCRIPTION' ][ this.props.data.languageReducer.lang ],
              accessor: 'description.' + this.props.data.languageReducer.lang
            },
            {
              Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
              width: 200,
              show: this.handleSecurityModulesUpdate(),
              Cell: ({row}) => (
                <div className="d-flex flex-row align-items-center justify-content-center w-100">
                  <>
                    <Button
                      id={ `Categories-${ row._original.id }-update-category` }
                      className="mb-2 mr-2 btn-icon btn-icon-only"
                      color="primary"
                      onClick={ () => {
                        this.props.onChangeCategoryHOC( 'selectedCategory', row._original )
                        this.props.handleShowCategoryModal( true, 'edit' )
                      }}>
                        <i className="pe-7s-note"> </i>
                    </Button>
                    <UncontrolledTooltip
                      target={ `Categories-${ row._original.id  }-update-category` }
                      placement="top">{ Lang[ 'UPDATE_CATEGORY' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                  </>
                  <>
                    <Button
                      id={ `Categories-${ row._original.id  }-delete-category` }
                      className="mb-2 mr-2 btn-icon btn-icon-only"
                      color="danger"
                      onClick={ () => {
                        this.props.onChangeCategoryHOC( 'selectedCategory', row._original )
                        this.props.onChangeCategoryHOC( 'showDeleteModal', true )
                      }}>
                        <i className="pe-7s-trash"> </i>
                    </Button>
                    <UncontrolledTooltip
                      target={ `Categories-${ row._original.id  }-delete-category` }
                      placement="top">{ Lang[ 'DELETE_CATEGORY' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                  </>
                </div>
              )
            }
          ]}
          page={ this.props.categories.meta ? this.props.categories.meta.current_page : 1 }
          totalPages={ this.props.totalPages }
          onChangePage={ pg => {
            let tmp = {
              page: pg,
              is_paginated: true,
              filter: {
                name: {
                  $like: `%${ this.props.searchParams.name.toLowerCase() }%`
                }
              }
            }
            this.props.getAllCategory( convertObjToBase64( tmp ) )
          }}
          showPagination={ false }/>
        { this.props.categoryModalMode === 'create' && this.renderCreateCategory() }
        { this.props.categoryModalMode === 'edit' && this.renderEditCategory() }
        {
          this.props.showDeleteModal && (
            <DeleteConfirmationDialog
              deleteOpen={ this.props.showDeleteModal }
              handleModalClose={ () => this.props.onChangeCategoryHOC( 'showDeleteModal', false ) }
              confirmAction={ () => {
                this.props.deleteCategory( this.props.selectedCategory.id )
                this.props.onChangeCategoryHOC( 'showDeleteModal', false )
              }}
              content={ Lang[ 'DELETE_CONFIRMATION_CATEGORY' ][ this.props.data.languageReducer.lang ] }
              lang={ this.props.data.languageReducer.lang }/>
          )
        }
        {
          ( this.props.data.ajaxReducer.ajaxCallProgress > 0 || this.props.onLoadCategory ) && <LoadingOverlay />
        }
      </>
    )
  }
}
export default CategoryHOC( Categories )
