import React, { Component } from 'react'
import _ from 'lodash'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      centers: []
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showStatusModal: true,
      statusModalType: 'negative',
      onClickStatusModalButton: () => this.setState({ showStatusModal: false }),
      statusModalMessage: error
    })
    requestSuccess = success => this.setState({
      showStatusModal: true,
      statusModalType: 'positive',
      onClickStatusModalButton: () => this.setState({
        showStatusModal: false,
        errorMessage: {}
      }),
      statusModalMessage: success
    })

    getCenters = ( tmp ) => Get(
      `/api/v1/unordered_centers`,
      this.getCenterNotLinkedOrderSuccess,
      this.getCenterNotLinkedOrderError,
      this.load
    )
    getCenterNotLinkedOrderSuccess = payload => {
      let tmp = []
      payload.map( item => {
        tmp.push({
          ...item,
          label: `${ item.center_name } ${ item.locations ? !_.isEmpty( item.locations[0] ) ? `${ item.locations[0].street }, ${ item.locations[0].postcode } ${ item.locations[0].city }` : '' : '' }`,
          value: item.center_name
        })
      })
      this.setState({ centers: tmp })
    }
    getCenterNotLinkedOrderError = error => this.requestError( error )
    
    render = () => {
      return (
        <WrappedComponent 
          { ...this.props }
          onLoadCenters={ this.state.loading }
          centers={ this.state.centers }
          getCenters={ this.getCenters } />
      )
    }
  }
  return WithHOC
}

export default HOC