import React from 'react'
import Radium from 'proofpoint-radium'

import CoreRenderFunction from '../../core/render'

const Form = ({
  item,
  children,
  id,
  style,
  languages,
  selectedLanguageShortname,
  updateMainContainerState,
  templateHeaders,
  templateHeadersBlueprints,
  mode,
  onChangeSectionEditorState,
  selectedEditableComponent,
  hoveredComponentId
}) => {
  return(
    <form
      id={ id }
      onMouseEnter={ () => mode === 'edit' && onChangeSectionEditorState && onChangeSectionEditorState( 'hoveredComponentId', id ) } 
      onClick={ () => {
        if( mode === 'edit' ){
          onChangeSectionEditorState && onChangeSectionEditorState( 'selectedEditableComponent', item )
        }
      }}
      style={{
        ...style,
        ...(
          ( ( selectedEditableComponent && selectedEditableComponent.id === id ) || hoveredComponentId === id  ) 
            ? { 
              borderTop: "5px solid #45818e",
              borderRight: "5px solid #45818e",
              borderBottom: "5px solid #45818e",
              borderLeft: "5px solid #45818e"
            } : {}
        )
      }}>
      { children && children.length > 0 && children.map( item => {
        return(
          <div>
            <CoreRenderFunction
              item={ item }
              updateMainContainerState={ updateMainContainerState }
              languages={ languages }
              selectedLanguageShortname={ selectedLanguageShortname }
              templateHeaders={ templateHeaders }
              templateHeadersBlueprints={ templateHeadersBlueprints } />
          </div>
        )
      })}
    </form>
  )
}

export default Radium( Form )
