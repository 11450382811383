import React, { Component } from 'react'
import { Card, CardHeader, CardBody, Input, Button } from 'reactstrap'
import _ from 'lodash'
import Lang from 'Lang/General'

class GoogleMapSettings extends Component {
  render = () => {
    return (
      <Card className="mb-2">
        <CardHeader>{ Lang[ 'GOOGLE_MAP_SETTING' ][ this.props.lang ] }</CardHeader>
        <CardBody>
          <Input
            type="textarea"
            rows={ 8 }
            value={ this.props.selectedEditableComponent.content }
            onChange={ e => {
              let tmp = _.cloneDeep( this.props.selectedEditableComponent )
              tmp.content = e.target.value
              this.props.onChangeSectionEditorState( 'selectedEditableComponent', tmp )
            }} />
          <Button
            color="primary"
            className="mt-3"
            onClick={() => {
              let tmp = _.cloneDeep( this.props.selectedEditableComponent )
              let tmpSelectedSection = _.cloneDeep( this.props.selectedSection )
              this.props.findComponentAndPatch( tmp, tmpSelectedSection )
              this.props.onChangeSectionHOC( 'selectedSection', tmpSelectedSection )
            }}>
              { Lang[ 'SAVE_CHANGES' ][ this.props.lang ] }
            </Button>
        </CardBody>
      </Card>
    )
  }
}

export default GoogleMapSettings
