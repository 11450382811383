import React from 'react'
import { FormGroup, Label, Input, UncontrolledTooltip } from 'reactstrap'
import _ from 'lodash'
import ReactHTMLParser from 'react-html-parser'

import { generateFieldLabelStr } from '../utils'

const TextField = ({
  content,
  formValues,
  onPatchFormValues,
  isInputDisabled,
  currentLanguage
}) => {
  return (
    <FormGroup id={ content.id } style={{ padding: content.padding, marginBottom: 0, ...content.style }}>
      <Label  style={{ fontSize: content.fontSize }}>
        { ReactHTMLParser( generateFieldLabelStr(content, formValues, currentLanguage) ) }
        {
          ( content.showQuickInfo || formValues[ content.dataMapIndex ][ 'showQuickInfo' ] ) && 
          document.getElementById(`quick-info-tooltip-${content.id}`) && (
            <UncontrolledTooltip
              target={`quick-info-tooltip-${content.id}`} 
              placement="top">
              { content.preview_text }
            </UncontrolledTooltip>
          )
        }
      </Label>
      <Input
        type={ content.type }
        disabled={ isInputDisabled }
        value={ formValues[ content.dataMapIndex ][ 'value' ] }
        onChange={ e => {
          onPatchFormValues( content.dataMapIndex, 'value', e.target.value )
        }}
        style={{ fontSize: content.fontSize }} />
    </FormGroup>
  )
}

export default TextField