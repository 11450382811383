import React, { useState, Fragment } from 'react'
import { connect } from 'react-redux'
import CreatableSelect from 'react-select/creatable'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {
  Form, Button, FormGroup, Label, Container, Row, Col, Input
} from 'reactstrap'
import Image from 'react-bootstrap/Image'
import _ from 'lodash'

import Lang from 'Lang/General'

const LanguageInput = ({
  item,
  onChangeData,
  updateMedia,
  mediaTags,
  data,
  selectedImages
}) => {
  const [ selectedLanguage, updateSelectedLanguage ] = useState( 'en' )
  const arrayButton = [ 'de', 'en', 'es', 'it', 'fr', 'nl' ]

  return(
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}>
        <Form
          style={{
            padding: '30px',
            border: '0.5px solid #c2c2c2',
            margin: '15px'
          }}>
          <Row style={{ alignItems: 'center' }}>
            <Col md={ 8 }>
              <Row>
                <Col md={ 3 } style={{ alignSelf: 'center' }}>
                  <Image src={ item.thumb_url } style={{ maxWidth: "100%" }} />
                </Col>
                <Col md={ 9 }>
                  <Label sm={ 4 }>{ Lang[ 'NAME' ][ data.languageReducer.lang ] }</Label>
                  <div className="d-flex pt-1">
                    {
                      arrayButton.map( button => {
                        return(
                          <Button
                            className="btn-square btn-wide" size="sm"
                            color={ `${ selectedLanguage === button ? 'secondary' : 'light' }` }
                            onClick={ () => updateSelectedLanguage( button ) }>{ button }</Button>
                        )
                      })
                    }
                  </div>
                  <FormGroup>
                    <Input
                      value={ item.short_name[ selectedLanguage ] }
                      onChange={ e => {
                        let tmp = _.cloneDeep( selectedImages )
                        let tmpIndex = _.findIndex( selectedImages, { id: item.id } )
                        if( tmpIndex > -1 ){
                          tmp[ tmpIndex ][ 'short_name' ][ selectedLanguage ] = e.target.value
                          onChangeData( 'selectedImages', tmp )
                        }
                      }}/>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col md={ 4 }>
              <Button
                className="btn-wide mb-2 mr-2"
                color="primary"
                style={{ float: 'right' }}
                onClick={ () => {
                  let tmpItem = _.cloneDeep( item )
                  tmpItem.tags = item.tags
                    ? item.tags.map( tag => tag.value )
                    : []
                  updateMedia( tmpItem ) 
                }}
                size="lg">{ Lang[ 'SAVE' ][ data.languageReducer.lang ] }</Button>
            </Col>
          </Row>
          <hr />
          <CreatableSelect
            isMulti
            onChange={ ( newValue, actionMeta ) => {
              let tmp = _.cloneDeep( selectedImages )
              let tmpIndex = _.findIndex( selectedImages, { id: item.id } )
              if( tmpIndex > -1 ){
                tmp[ tmpIndex ][ 'tags' ] = newValue
                onChangeData( 'selectedImages', tmp )
              }
            }}
            options={ mediaTags }
            value={ item.tags } />
        </Form>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}
const mapStateToProps = state => ({ data: state })
export default connect( mapStateToProps )( LanguageInput )
