import React, { useState, useEffect } from 'react'
import {
  Card, CardHeader, CardBody,
  Row, Col,
  Input, 
  FormGroup, Label, Button, CustomInput
} from 'reactstrap'
import { connect } from 'react-redux'
import _ from 'lodash'
import { FaAlignLeft, FaAlignCenter, FaAlignRight } from 'react-icons/fa'
import { MdDesktopWindows, MdTablet, MdSmartphone } from 'react-icons/md'

import MultilingualInput from 'components/Input/MultiLang'
import { findObjectAndPatch } from '../../utility/ObjectPatcher'
import LinkInput from 'components/LinkInput'
import { toggleMediaDatabase } from 'actions/MediaDatabaseToggle'
import ButtonSettings from 'components/ButtonSettings'
import AnchorLinkModal from './AnchorLinkModal'
import ConfirmDeleteDialog from "../../components/confirmModal"
import ColorPickerInput from 'components/ColorPicker'
import { getButtonColorType, getModifiedColorTypeStyling } from 'components/ButtonSettings/utils'

import Lang from 'Lang/General'

const ContainerSettings = props => {
  const [ screenMode, updateScreenMode ] = useState( 'MOBILE' )

  useEffect(()=>{
    let tmp = _.find( props.anchorLinks, { anchor_id: props.selectedContainer.id } )
    props.onChangeAnchorLinkHOC( 'selectedAnchorLink', tmp ? tmp : {} )
  }, [ props.selectedContainer ])

  const containerSettingComponents = [
    { key: 'paddingTop', name: 'PADDING_TOP' },
    { key: 'paddingRight', name: 'PADDING_RIGHT' },
    { key: 'paddingBottom', name: 'PADDING_BOTTOM' },
    { key: 'paddingLeft', name: 'PADDING_LEFT' }
  ]
  const containerSettingMarginComponents = [
    { key: 'marginTop', name: 'MARGIN_TOP' },
    { key: 'marginRight', name: 'MARGIN_RIGHT' },
    { key: 'marginBottom', name: 'MARGIN_BOTTOM' },
    { key: 'marginLeft', name: 'MARGIN_LEFT' }
  ]

  const onChangeStyle = ( key, val ) => {
    let tmp = _.cloneDeep( props.selectedContainer )
    if( props.selectedContainer.style ) {
      if( screenMode === 'MOBILE' || key.indexOf( 'text' ) > -1 || key.indexOf( 'background' ) > -1 ){
        if( !_.isEmpty( val ) ){
          tmp[ 'style' ][ key ] = val
        } else {
          delete tmp[ 'style' ][ key ]
        }
      } else {
        switch( screenMode ){
          case 'TABLET':
            if( !tmp[ 'style' ][ '@media(min-width:576px)' ] ){
              tmp[ 'style' ][ '@media(min-width:576px)' ] = { [ key ]: val }
            } else {
              tmp[ 'style' ][ '@media(min-width:576px)' ][ key ] = val
            }
            break
          case 'DESKTOP':
            if( !tmp[ 'style' ][ '@media(min-width:768px)' ] ){
              tmp[ 'style' ][ '@media(min-width:768px)' ] = { [ key ]: val }
            } else {
              tmp[ 'style' ][ '@media(min-width:768px)' ][ key ] = val
            }
            break
        }
      }
    } else if( !_.isEmpty( val ) ) {
      tmp.style = {}
      if( screenMode === 'MOBILE' || key.indexOf( 'text' ) > -1 || key.indexOf( 'background' ) > -1 ){
        tmp.style[ key ] = val
      } else {
        switch( screenMode ){
          case 'TABLET':
            tmp[ 'style' ][ '@media(min-width:576px)' ][ key ] = val
            break
          case 'DESKTOP':
            tmp[ 'style' ][ '@media(min-width:768px)' ][ key ] = val
            break
        }
      }
    }
    let tmpSection = _.cloneDeep( props.selectedSection )
    if( tmp.id !== tmpSection.id ){
      findObjectAndPatch( tmp, tmpSection )
    }
    else{
      tmpSection = tmp
    }
    props.onChangeSectionEditorState( 'selectedContainer', tmp )
    props.onChangeSectionHOC( 'selectedSection', tmpSection )
  }

  const patchContent = ( component, value ) => {
    if( component.children ){
      return component.children.map( item => patchContent( item, value ) )
    } else if( component.content ){
      component.content = value
      return component
    } else {
      return component
    }
  }

  const renderContainerSettingInput = style => (
    <Col md={ 4 }>
      <FormGroup>
        <Label>
          { Lang[ style.name ][ props.lang ] }
          { props.selectedContainer[ 'style' ][ style.key ] && <MdSmartphone className='ml-1' style={{ fontSize: '15px' }} /> }
          { props.selectedContainer[ 'style' ][ '@media(min-width:576px)' ] && props.selectedContainer[ 'style' ][ '@media(min-width:576px)' ][ style.key ] && <MdTablet className='ml-1' style={{ fontSize: '15px' }} /> }
          { props.selectedContainer[ 'style' ][ '@media(min-width:768px)' ] && props.selectedContainer[ 'style' ][ '@media(min-width:768px)' ][ style.key ] && <MdDesktopWindows className='ml-1' style={{ fontSize: '15px' }} /> }
        </Label>
        <Input 
          type="text" 
          value={ 
            screenMode === 'DESKTOP' && props.selectedContainer[ 'style' ][ '@media(min-width:768px)' ]
              ? props.selectedContainer[ 'style' ][ '@media(min-width:768px)' ][ style.key ] ? props.selectedContainer[ 'style' ][ '@media(min-width:768px)' ][ style.key ] : ""
              : screenMode === 'TABLET' && props.selectedContainer[ 'style' ][ '@media(min-width:576px)' ]
                ? props.selectedContainer[ 'style' ][ '@media(min-width:576px)' ][ style.key ] ? props.selectedContainer[ 'style' ][ '@media(min-width:576px)' ][ style.key ] : ""
                : screenMode === 'MOBILE' && props.selectedContainer[ 'style' ][ style.key ]
                  ? props.selectedContainer[ 'style' ][ style.key ]?props.selectedContainer[ 'style' ][ style.key ]:""
                  : ''
          }
          onChange={ e => onChangeStyle( style.key, e.target.value )} />
      </FormGroup>
    </Col>
  )

  const contentInput = () => {
    let tmpParent = (
      props.selectedContainer && !_.isEmpty( props.selectedContainer )
        ? (
          props.selectedContainer.children 
          && props.selectedContainer.children.length > 0 
          && props.selectedContainer.children[ 0 ].content
        ) ? props.selectedContainer.children[ 0 ].content
          : props.selectedContainer.content
            ? props.selectedContainer.content
            : {}
        : {}
      )
    return (
      <MultilingualInput
        isRequired
        label={ Lang[ 'CONTENT' ][ props.lang ] }
        value={ tmpParent }
        onChange={ value => {
          let tmp = _.cloneDeep( props.selectedContainer )
          patchContent( tmp, value )
          let tmpSection = _.cloneDeep( props.selectedSection )
          if( tmp.id !== tmpSection.id ){
            findObjectAndPatch( tmp, tmpSection )
          }
          else{
            tmpSection = tmp
          }
          props.onChangeSectionEditorState( 'selectedContainer', tmp )
          props.onChangeSectionHOC( 'selectedSection', tmpSection )
        }} 
        selectedLanguage={ props.lang } />
    )
  }

  return (
    <Card className="mb-2">
      <CardHeader>
        { 
          props.selectedContainer.type === 'div' || props.selectedContainer.type === 'accordion' || props.selectedContainer.type === 'heading'
            ? Lang[ 'CONTAINER_SETTINGS' ][ props.lang ]
            : props.selectedSection.type === 'countdown' 
              ? Lang[ 'BUTTON' ][ props.lang ] 
              : props.selectedContainer.mapWith === "team-searchbar-button"
                ? Lang[ 'BUTTON_SETTINGS' ][ props.lang ] 
                : Lang[ 'CLICKABLE_SETTINGS' ][ props.lang ] 
        }
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={ 12 }>
            {
              props.selectedContainer.id === props.selectedSection.id && (
                !_.isEmpty( props.selectedAnchorLink ) ? (
                  <>
                    <Button
                      color="primary"
                      className="mr-2 mb-2"
                      onClick={ () => props.onChangeAnchorLinkHOC( 'anchorLinkModalOpen', true ) }>
                      { Lang[ 'UPDATE_ANCHOR_LINK' ][ props.lang ] }
                    </Button>
                    <Button
                      color="danger"
                      className="mr-2 mb-2"
                      onClick={ () => props.onChangeAnchorLinkHOC( 'anchorLinkDeleteModalOpen', true ) }>
                      { Lang[ 'REMOVE_ANCHOR_LINK' ][ props.lang ] }
                    </Button>
                  </>
                ) : (
                  <Button
                    color="primary"
                    className="mr-2 mb-2"
                    onClick={ () => props.onChangeAnchorLinkHOC( 'anchorLinkModalOpen', true ) }>
                    { Lang[ 'SET_ANCHOR_LINK' ][ props.lang ] }
                  </Button>
                )
              )
            }
          </Col>
          {
            !props.selectedContainer.style 
              ? (
                <Col md={ 12 }>
                  <Button 
                    color="primary" 
                    onClick={() => {
                      let tmp = _.cloneDeep( props.selectedContainer )
                      let tmpSection = _.cloneDeep( props.selectedSection )
                      tmp.style = {
                        height: 'auto',
                        paddingTop: 'initial',
                        paddingLeft: 'initial',
                        paddingRight: 'initial',
                        paddingBottom: 'initial'
                      }
                      if( tmp.id !== tmpSection.id ){
                        findObjectAndPatch( tmp, tmpSection )
                      }
                      else{
                        tmpSection = tmp
                      }
                      props.onChangeSectionEditorState( 'selectedContainer', tmp )
                      props.onChangeSectionHOC( 'selectedSection', tmpSection )
                    }}>
                    { Lang[ 'CREATE_STYLE' ][ props.lang ] }
                  </Button>
                </Col>
              ) : (
                <>
                  {
                    props.selectedContainer.type === 'div' && props.selectedContainer.variant !== 'divider' 
                      ? <></>
                      : props.selectedContainer.type !== 'accordion' && props.selectedContainer.type !== 'heading'
                        ? (
                          <>
                            <Col md={ 12 } >
                              <FormGroup>
                                { contentInput() }
                              </FormGroup>
                            </Col>
                            {
                              (( props.selectedContainer.type === 'button'&& props.selectedContainer.mapWith !== "team-searchbar-button" ) || props.selectedContainer.type === 'a' ) && (
                                <Col md={ 12 }>
                                  <LinkInput 
                                    selectedContainer={ props.selectedContainer }
                                    onUpdate={ ( updatedDom ) => {
                                      let tmpSection = _.cloneDeep( props.selectedSection )
                                      if( updatedDom.id !== tmpSection.id ){
                                        findObjectAndPatch( updatedDom, tmpSection )
                                      }
                                      else{
                                        tmpSection = updatedDom
                                      }
                                      props.onChangeSectionHOC( 'selectedSection', tmpSection )
                                      props.onChangeSectionEditorState( 'selectedContainer', updatedDom ) 
                                    }}
                                    setMediaDatabase={ ( isOpen ) => {
                                      Promise.all([
                                        props.onChangeSectionEditorState( 'mediaDatabaseFor', 'clickable' )
                                      ]).then( () => {
                                        props.toggleMediaDatabase( isOpen )
                                      })
                                    }}
                                    anchorLinks={ props.anchorLinks }
                                    pages={ props.pages }
                                    selectedLanguage={ props.lang }
                                  />
                                </Col>
                              )
                            }
                          </>
                        ) : <></>
                  }
                  {
                    props.selectedSection.type !== 'countdown' 
                      ? props.selectedContainer.mapWith !== "team-searchbar-button" && (
                        <>
                          <Col md={ 12 }>
                            <FormGroup>
                              <Label className='mr-2' >{ Lang[ 'SCREEN_MODE' ][ props.lang ] }:</Label>
                              <Button 
                                color={ screenMode === 'MOBILE' ? 'success' : 'primary' }
                                className='mr-2'
                                onClick={ () => updateScreenMode( 'MOBILE' ) }>
                                <MdSmartphone style={{ fontSize: '24px' }} />
                              </Button>
                              <Button 
                                color={ screenMode === 'TABLET' ? 'success' : 'primary' }
                                className='mr-2'
                                onClick={ () => updateScreenMode( 'TABLET' ) }>
                                <MdTablet style={{ fontSize: '24px' }} />
                              </Button>
                              <Button 
                                color={ screenMode === 'DESKTOP' ? 'success' : 'primary' }
                                className='mr-2'
                                onClick={ () => updateScreenMode( 'DESKTOP' ) }>
                                <MdDesktopWindows style={{ fontSize: '24px' }} />
                              </Button>
                            </FormGroup>
                          </Col>
                          {!props.selectedSection.schema && (
                            <>
                              { renderContainerSettingInput({ key: 'width', name: 'WIDTH' }) }
                              { renderContainerSettingInput({ key: 'height', name: 'HEIGHT' }) }
                              { renderContainerSettingInput({ key: 'minHeight', name: 'MIN_HEIGHT' }) }
                            </>
                          )}
                          <Row className="p-3">
                            {
                              containerSettingComponents.map( style => renderContainerSettingInput( style ) )
                            }
                          </Row>
                          <Row className="p-3">
                            {
                              containerSettingMarginComponents.map( style => renderContainerSettingInput( style ) )
                            }
                          </Row>
                        </>
                      ) : (
                        <Col md={ 12 }>
                          <FormGroup>
                            <CustomInput
                              id={ 'showButton' }
                              type='checkbox'
                              label={ Lang[ 'SHOW_BUTTON' ][ props.lang ] }
                              checked={ props.selectedSection.children[ 3 ].showButton !== false }
                              onChange={ e => {
                                let tmp = _.cloneDeep( props.selectedSection )
                                tmp.children[ 3 ].showButton = e.target.checked
                                props.onChangeSectionHOC( 'selectedSection', tmp )
                              }}/>
                          </FormGroup>
                        </Col>
                      )
                  }
                  {
                    ( props.selectedContainer.type === 'button' || props.selectedContainer.type === 'a' ) && (
                      <ButtonSettings
                        selectedLanguage={ props.lang }
                        selectedContainer={ props.selectedContainer }
                        components={ props.components }
                        selectedParent={ props.selectedParent }
                        onChangeButton={ ( tmpButton, tmpParent ) => {
                          let tmp = tmpParent ? tmpParent : tmpButton
                          let tmpSection = _.cloneDeep( props.selectedSection )
                          if( tmp.id !== tmpSection.id ){
                            findObjectAndPatch( tmp, tmpSection )
                          }
                          else{
                            tmpSection = tmp
                          }
                          props.onChangeSectionEditorState( 'selectedContainer', tmpButton )
                          props.onChangeSectionHOC( 'selectedSection', tmpSection )
                          tmpParent && props.onChangeSectionEditorState( 'selectedParent', tmpParent )
                        }}
                      />
                    )
                  }
                  { 
                    (
                      !props.selectedSection.schema && 
                      props.selectedContainer.type !== 'button' && 
                      props.selectedContainer.type !== 'a' && 
                      props.selectedSection.type !== 'countdown' 
                    ) && (
                    <>
                      <Col md={ 6 } >
                        <FormGroup>
                          <Row>
                            <ColorPickerInput
                              id={ `container-background-color` }
                              size={ 12 }
                              label={ Lang[ 'BACKGROUND_COLOR' ][ props.lang ] }
                              color={ props.selectedContainer.style.background || '' }
                              colorType={ getButtonColorType( props.selectedContainer.style, 'background' ) }
                              selectedLanguage={ props.lang }
                              onChange={ col => onChangeStyle( 'background', col.hex ) } 
                              onChangeColorType={ type => {
                                let tmp = _.cloneDeep( props.selectedContainer )
                                let tmpSection = _.cloneDeep( props.selectedSection )
                                tmp.style = getModifiedColorTypeStyling( type, props.selectedContainer.style, 'background' )
                                if( tmp.id !== tmpSection.id ){
                                  findObjectAndPatch( tmp, tmpSection )
                                } else {
                                  tmpSection = tmp
                                }
                                props.onChangeSectionEditorState( 'selectedContainer', tmp )
                                props.onChangeSectionHOC( 'selectedSection', tmpSection )
                              }} 
                            />
                          </Row>
                        </FormGroup>
                      </Col>
                      <Col md={ 6 }>
                        <FormGroup>
                          <Label>{ Lang[ 'TEXT_ALIGN' ][ props.lang ] }</Label>
                          <FormGroup>
                            <Button 
                              className='mr-2'
                              color={ 
                                props.selectedContainer.style.textAlign
                                  ? props.selectedContainer.style.textAlign === "left" ? "primary" : 'secondary'
                                  : 'secondary'
                              }
                              onClick={() => {
                                onChangeStyle( 'textAlign', 'left' )
                              }}>
                              <FaAlignLeft />
                            </Button>
                            <Button 
                              className='mr-2'
                              color={ 
                                props.selectedContainer.style.textAlign
                                  ? props.selectedContainer.style.textAlign === "center" ? "primary" : 'secondary'
                                  : 'secondary'
                              }
                              onClick={() => {
                                onChangeStyle( 'textAlign', 'center' )
                              }}>
                              <FaAlignCenter />
                            </Button>
                            <Button 
                              color={ 
                                props.selectedContainer.style.textAlign
                                  ? props.selectedContainer.style.textAlign === "right" ? "primary" : 'secondary'
                                  : 'secondary'
                              }
                              onClick={() => {
                                onChangeStyle( 'textAlign', 'right' )
                              }}>
                              <FaAlignRight />
                            </Button>
                          </FormGroup>
                        </FormGroup>
                      </Col>
                    </>
                  )}
                  {
                    props.selectedContainer.animation && <Col md={ 12 } >
                      <FormGroup>
                        <Label>{ Lang[ 'FADE_IN_FROM' ][ props.lang ] }</Label>
                        <Input
                          type='select'
                          value={ props.selectedContainer.animation }
                          onChange={ e => {
                            let tmp = _.cloneDeep( props.selectedContainer )
                            tmp[ 'animation' ] = e.target.value
                            let tmpSection = _.cloneDeep( props.selectedSection )
                            if( tmp.id !== tmpSection.id ){
                              findObjectAndPatch( tmp, tmpSection )
                            }
                            else{
                              tmpSection = tmp
                            }
                            props.onChangeSectionEditorState( 'selectedContainer', tmp )
                            props.onChangeSectionHOC( 'selectedSection', tmpSection )
                          }}>
                          <option value='' >-</option>
                          <option value='fadeInDownBig' >{ Lang[ 'TOP' ][ props.lang ] }</option>
                          <option value='fadeInRight' >{ Lang[ 'RIGHT' ][ props.lang ] }</option>
                          <option value='fadeInUpBig' >{ Lang[ 'BOTTOM' ][ props.lang ] }</option>
                          <option value='fadeInLeft' >{ Lang[ 'LEFT' ][ props.lang ] }</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  }
                </>
              )
          }
        </Row>
        <AnchorLinkModal
          anchorLinkModalOpen={ props.anchorLinkModalOpen }
          anchor_id={ props.selectedEditableComponent.id }
          page_id={ props.selectedPage.id }
          onChangeAnchorLinkHOC={ props.onChangeAnchorLinkHOC }
          anchorLink={ !_.isEmpty( props.selectedAnchorLink ) ? props.selectedAnchorLink  : props.anchorLinkData }
          selectedLanguage={ props.lang }
          anchorError={ props.anchorError }
          createAnchorLink={ props.createAnchorLink }
          updateAnchorLink={ props.updateAnchorLink }
          isUpdate={ !_.isEmpty( props.selectedAnchorLink ) }
          onLoadAnchorLink={ props.onLoadAnchorLink}/>
        <ConfirmDeleteDialog
          open={ props.anchorLinkDeleteModalOpen }
          content={ Lang[ "REMOVE_ANCHOR_LINK" ][ props.lang ] }
          confirmAction={ () => props.removeAnchorLink() }
          handleModalClose={ () => props.onChangeAnchorLinkHOC( 'anchorLinkDeleteModalOpen', false ) }
          lang={ props.lang }/>
      </CardBody>
    </Card>
  )
}

const mapStateToProps = state => ({ data: state })

export default connect( mapStateToProps, {
  toggleMediaDatabase
})( ContainerSettings )