import React, { useState, useEffect } from 'react'
import { Button } from 'reactstrap'
import Lang from 'Lang/General'
import { connect } from 'react-redux'

const ArkPagination = ({
  meta,
  onChangePage,
  data
}) => {
  const [ interval, updateInterval ] = useState( 10 )
  const [ pages, updatePages ] = useState( [] )

  useEffect(() => {

  }, [ meta ])

  return(
    <div style={{ marginTop: '20px', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ marginRight: '20px' }}>
        { Lang[ 'PAGE' ][ data.languageReducer.lang ] }{ ' ' }
        <em>
          {meta.currentPage} { Lang[ 'OF' ][ data.languageReducer.lang ] } {pages.length}
        </em>
      </div>
      <div  className="d-flex pt-1">
        <Button
          className="mb-2 mr-2 btn-icon btn-icon-only"
          onClick={ () => onChangePage( meta.currentPage - 1 ) }
          disabled={ meta.currentPage === 1 }
          color="primary">
          <i className="pe-7s-angle-left btn-icon-wrapper" style={{ fontSize: '25px' }} />
        </Button>
        <Button
          className="mb-2 mr-2 btn-icon"
          onClick={ () => updateInterval( interval - 10 ) }
          color="light"
          style={{
          display: `${ interval <= 10 ? 'none' : '' }`
          }}>
          ...
        </Button>
        <div className="d-flex">
        {
          pages.map( pg => {
            if(( pg > interval - 10 ) && ( pg <= interval )){
              return(
                <Button
                  className="mb-2 mr-2 btn-icon"
                  color={ meta.currentPage === pg ? "secondary": "link" }
                  onClick={ () => onChangePage( pg )}>{ pg }</Button>
              )
            }
          })
        }
        </div>
        <Button
          className="mb-2 mr-2 btn-icon"
          onClick={ () => updateInterval( interval + 10 ) }
          color="light"
          style={{
          display: `${ interval > pages.length ? 'none' : '' }`
          }}>
          ...
        </Button>
        <Button
          className="mb-2 mr-2 btn-icon btn-icon-only"
          onClick={ () => onChangePage( meta.currentPage + 1 ) }
          disabled={ meta.currentPage === ( pages.length ) }
          color="primary">
          <i className="pe-7s-angle-right btn-icon-wrapper" style={{ fontSize: '25px' }}/>
        </Button>
      </div>
     </div>
  )
}

const mapStateToProps = state => ({ data: state })

const mapDispatchToProps = dispatch => ({})

export default connect( mapStateToProps, mapDispatchToProps )(ArkPagination)
