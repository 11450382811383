import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import StatusModal from 'components/Modal/status'

import { Get, Post, outdatedToken, Put, Delete } from 'utils/axios'
import { getRefreshToken } from 'actions/token'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const ClientHOC = ( WrappedComponent ) => {
  class ClientWrappedComponent extends Component {
    state = {
      clients: {},
      archivedClients: {},
      selectedClient: {
        company_name: '',
        salutation: '',
        house_number: '',
        street: '',
        postcode: '',
        city: '',
        state: '',
        country: '',
        phone_number: '',
        fax_number: '',
        client_number: '',
        tax_number: '',
        email: '',
        status: "",
        user: {
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          password_confirmation: '',
          username: ''
        }
      },
      newClientData: {
        company_name: '',
        salutation: '',
        house_number: '',
        street: '',
        postcode: '',
        city: '',
        state: '',
        country: '',
        phone_number: '',
        fax_number: '',
        client_number: '',
        tax_number: '',
        email: '',
        status: "ACTIVE",
        user: {
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          password_confirmation: '',
          photo_raw: '',
          photo_file_name: '',
          username: '',
          role_id: 7,
          admin_panel_language: this.props.data.languageReducer.lang 
        }
      },
      showNewClientModal: false,
      showViewClientModal: false,
      showEditClientModal: false,
      showArchivedClientModal: false,
      showDeleteConfirmation: false,
      loading: false,
      showStatusModal: false,
      statusModalType: '',
      onClickStatusModalButton: () => {},
      statusModalMessage: '',
      errorMessage: {},
      query: {},
      clientTotalPages: [],
      archivedClientTotalPage: [],
      toArchiveClientId: 0,
      unArchiveClientID: 0,
      confirmUnarchiveModal: false
    }

    onChangeClientHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showStatusModal: true,
      statusModalType: 'negative',
      onClickStatusModalButton: () => this.setState({ showStatusModal: false }),
      statusModalMessage: error
    })
    requestSuccess = success => this.setState({
      showStatusModal: true,
      statusModalType: 'positive',
      onClickStatusModalButton: () => this.setState({
        showStatusModal: false,
        showNewClientModal: false,
        selectedClient: {},
        newClientData: {
          company_name: '',
          salutation: '',
          house_number: '',
          street: '',
          postcode: '',
          city: '',
          state: '',
          country: '',
          phone_number: '',
          fax_number: '',
          client_number: '',
          tax_number: '',
          email: '',
          status: "ACTIVE",
          user: {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            password_confirmation: '',
            photo_raw: '',
            photo_file_name: '',
            username: '',
            role_id: 7,
            admin_panel_language: this.props.data.languageReducer.lang 
          }
        },
        errorMessage: {}
      }),
      statusModalMessage: success
    })

    ajaxError = ( error ) => {
      outdatedToken( error, this.props.history )
      this.requestError( error )
    }

    getClients = query => {
      query = {
        ...query,
        filter: {
          $or: [
            { status: 'ACTIVE' },
            { status: 'TRIAL' }
          ]
        },
      }
      return Get(
        `/api/v1/clients?query=${ convertObjToBase64( query ) }`,
        this.getClientsSuccess,
        this.getClientsError,
        this.load
      )
    }
    getClientsSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      this.setState({ clients: payload, clientTotalPages: tmpTotalPages })
    }
    getClientsError = error => this.ajaxError( error )

    getSelectedClientView = id => this.getSelectedClient( id, 'view' )
    getSelectedClientEdit = id => this.getSelectedClient( id, 'edit' )

    getSelectedClient = (id, type) => Get(
      `/api/v1/clients/${ id }`,
      ( payload ) => this.getSelectedClientSuccess( payload, type ),
      this.getSelectedClientError,
      this.load
    )
    getSelectedClientSuccess = (payload, type) => this.setState({ selectedClient: {
      ...payload,
      user: {
        ...payload.user,
        first_name: payload.user.first_name ? payload.user.first_name : '',
        last_name: payload.user.last_name ? payload.user.last_name : '',
        email: payload.user.email ? payload.user.email : '',
        password: payload.user.password ? payload.user.password : '',
        password_confirmation: payload.user.password_confirmation ? payload.user.password_confirmation : '',
        username: payload.user.username ? payload.user.username : ''
      }
    } }, () => {
      if( payload.country !== '' ) {
        let tmpCountry = _.find( this.props.countries, { name: payload.country })
        if ( tmpCountry ){
          this.props.getStates( tmpCountry.id )
        }
      }
      switch( type ){
        case 'view':
          this.onChangeClientHOC( 'showViewClientModal', true )
          break
        case 'edit':
          this.onChangeClientHOC( 'showEditClientModal', true )
          break
      }
    })
    getSelectedClientError = error => this.ajaxError( error )

    postClients = () => Post(
      `/api/v1/clients`,
      this.state.newClientData,
      this.postClientsSuccess,
      this.postClientsError,
      this.load
    )
    postClientsSuccess = payload => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'CLIENT_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getClients( tmp )
    }
    postClientsError = error => {
      this.setState({ errorMessage: error })
    }

    updateClient = id => {
      let tmp = _.cloneDeep( this.state.selectedClient )
      delete tmp.created_at
      Put(
        `/api/v1/clients/${ id }`,
        tmp,
        this.updateClientSuccess,
        this.updateClientError,
        this.load
      )
    }
    updateClientSuccess = async payload => {
      this.onChangeClientHOC( 'showEditClientModal', false )
      if ( payload.status !== 'INACTIVE' ) {
        this.getClients({
          page: 1,
          is_paginated: true
        })
        this.getArchivedClient({
          page: 1,
          is_paginated: true
        })
        this.requestSuccess( Lang[ 'CLIENT_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      } else {
        return await this.deactivateClient( payload.id )
      }
    }
    updateClientError = error => {
      this.setState({ errorMessage: error })
      this.requestError( Lang[ 'CLIENT_UPDATE_UNSUCCESS' ][ this.props.data.languageReducer.lang ] )
    }

    deleteClient = id => Delete(
      `/api/v1/clients/${ id }`,
      this.deleteClientSuccess,
      this.deleteClientError,
      this.load
    )
    deleteClientSuccess = payload => {
      this.requestSuccess( Lang[ 'CLIENT_REMOVE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.onChangeClientHOC( 'showDeleteConfirmation', false )
      this.getClients({
        page: 1,
        is_paginated: true
      })
    }
    deleteClientError = error => this.requestError( error )

    getArchivedClient = ( query, type, searchParams, searchVal ) => {
      query = {
        ...query,
        filter: {
          status: 'INACTIVE'
        }
      }
      if( type === 'Normal Search' ){
        query = {
          ...query,
          filter: {
            company_name: {
            $like: `%${ searchVal.toLowerCase() }%`
            },
            status: 'INACTIVE'
          }
        }
      }
      if( type === 'Advanced Search' ){
        let tmp = {}
        searchParams.map( item => {
          tmp[ item.val ] = {
            $like: `%${item.searchValue.toLowerCase()}%`,
          }
        })
        query = {
          ...query,
          filter: {
            ...tmp,
            status: 'INACTIVE'
          }
        }
      }
      Get(
        `/api/v1/clients?query=${ convertObjToBase64( query ) }`,
        this.getArchivedClientsSuccess,
        this.getArchivedClientsError,
        this.load
      )
    }
    getArchivedClientsSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }else{
        this.requestError( Lang[ 'NO_RESULT_FOUND' ][ this.props.data.languageReducer.lang ] )
      }
      this.setState({ archivedClients: payload, archivedClientTotalPage: tmpTotalPages })
    }
    getArchivedClientsError = error => this.ajaxError( error )

    getClientsSearch = ( query, type, searchParams, searchVal ) => {
      if( type === 'Normal Search' ){
        query = {
          ...query,
          filter: {
            company_name: {
              $like: `%${ searchVal.toLowerCase() }%`
            },
            $or: [
              { status: 'ACTIVE' },
              { status: 'TRIAL' }
            ]
          }
        }
      }
      if( type === 'Advanced Search' ){
        let tmp = {}
        searchParams.map( item => {
          tmp[ item.val ] = {
            $like: `%${item.searchValue.toLowerCase()}%`,
          }
          item.val === 'status' && (
            tmp[ item.val ] = item.searchValue
          )
        })
        query = {
          ...query,
          filter: {
            ...tmp,
            $or: [
              { status: 'ACTIVE' },
              { status: 'TRIAL'}
            ]
          }
        }
      }

      Get(
        `/api/v1/clients?query=${ convertObjToBase64( query ) }`,
        this.getClientsSearchSuccess,
        this.getClientsSearchError,
        this.load
      )
    }
    getClientsSearchSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }else{
        this.requestError( Lang[ 'NO_RESULT_FOUND' ][ this.props.data.languageReducer.lang ] )
      }
      this.setState({ clients: payload, clientTotalPages: tmpTotalPages })
    }
    getClientsSearchError = error => this.ajaxError( error )

    deactivateClient = client_id => Put(
      `/api/v1/clients/${ client_id }/deactivate`,
      {},
      this.deactivateClientSuccess,
      this.deactivateClientError,
      this.load
    )
    deactivateClientSuccess = () => {
      this.requestSuccess( `${ Lang[ 'CLIENT' ][ this.props.data.languageReducer.lang ] } ${ Lang[ 'HAS_BEEN_SET_INTO_INACTIVE' ][ this.props.data.languageReducer.lang ] }` )
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.getClients( tmp )
      this.getArchivedClient( tmp )
    }
    deactivateClientError = error => this.requestError( error )

    activateClient = client_id => Put(
      `/api/v1/clients/${ client_id }/activate`,
      {},
      this.activateClientSuccess,
      this.activateClientError,
      this.load
    )
    activateClientSuccess = () => {
      this.requestSuccess( `${ Lang[ 'CLIENT' ][ this.props.data.languageReducer.lang ] } ${ Lang[ 'WAS_ACTIVATED' ][ this.props.data.languageReducer.lang ] }` )
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.getClients( tmp )
      this.getArchivedClient( tmp )
    }
    activateClientError = error => this.requestError( error )

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            { ...this.props }
            clients={ this.state.clients }
            archivedClients={ this.state.archivedClients }
            newClientData={ this.state.newClientData }
            selectedClient={ this.state.selectedClient }
            getClients={ this.getClients }
            getSelectedClient={ this.getSelectedClient }
            getSelectedClientView={ this.getSelectedClientView }
            getSelectedClientEdit={ this.getSelectedClientEdit }
            onLoadClients={ this.state.loading }
            postClients={ this.postClients }
            updateClient={ this.updateClient }
            deleteClient={ this.deleteClient }
            showNewClientModal={ this.state.showNewClientModal }
            showViewClientModal={ this.state.showViewClientModal }
            showEditClientModal={ this.state.showEditClientModal }
            showDeleteConfirmation={ this.state.showDeleteConfirmation }
            showArchivedClientModal={ this.state.showArchivedClientModal }
            getArchivedClient={ this.getArchivedClient }
            getSelectedClientArchived={ this.getSelectedClientArchived }
            errorMessage={ this.state.errorMessage }
            getClientsSearch={ this.getClientsSearch }
            clientTotalPages={ this.state.clientTotalPages }
            onChangeClientHOC={ this.onChangeClientHOC }
            archivedClientTotalPage={ this.state.archivedClientTotalPage }
            toArchiveClientId={ this.state.toArchiveClientId }
            unArchiveClientID={ this.state.unArchiveClientID }
            confirmUnarchiveModal={ this.state.confirmUnarchiveModal }
            deactivateClient={ this.deactivateClient }
            activateClient={ this.activateClient } />
          <StatusModal
            isOpen={ this.state.showStatusModal }
            type={ this.state.statusModalType }
            message={ this.state.statusModalMessage }
            onClick={ this.state.onClickStatusModalButton } />
        </Fragment>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    getRefreshToken
  })( ClientWrappedComponent )
}

export default ClientHOC
