import React, { useEffect } from "react"
import { Form, FormGroup, Label, Input } from "reactstrap"
import _ from "lodash"

import Lang from "Lang/General"

const TemplateFormEdit = ( props ) => {

  useEffect( () => {
    props.getForms({ page: 1, is_paginated: false })
  }, [] )

  const findComponentAndPatch = ( dom, selectedSection ) => {
    let found = false
    if ( selectedSection.children && selectedSection.children.length > 0 ){
      if( !found ){
        selectedSection.children && selectedSection.children.map( ( item, index ) => {
          if( dom.id === item.id ) {
            found = true
            selectedSection.children.splice( index, 1, dom )
          }
        })
      }
      if( !found ) {
        selectedSection.children.map( ( item ) => findComponentAndPatch( dom, item ) )
      }
    }
  }

  const onSelect = ( id ) => {
    let tmp = _.find( props.forms.data, { id: parseInt( id ) })
    let tmpDom = _.cloneDeep( props.dom )
    let tmpCol = _.cloneDeep( props.selectedCol )
    let tmpChild = _.cloneDeep( props.selectedComponent )

    tmpChild.type = 'templateForm'
    tmpChild.formId = id
    tmpChild.name = tmp.name
    findComponentAndPatch( tmpChild, tmpCol )
    findComponentAndPatch( tmpChild, tmpDom )
    props.updateDom( tmpDom )
    props.updateSelectedCol( tmpCol )
    props.updateSelectedComponent( tmpChild )
  }

  return (
    <Form onSubmit={ ( e ) => e.preventDefault() }>
      <FormGroup>
        <Label>{ Lang[ "SELECT_SITE_FORM" ][ props.selectedLanguage ] }</Label>
        { 
          props.onLoadForms ? (
            <p>{ Lang[ "LOADING_SITE_FORMS" ][ props.selectedLanguage ] }</p>
          ) : (
            <Input
              type="select"
              onChange={ ( e ) => {
                onSelect( e.target.value )
              }}
              value={ props.selectedComponent.formId }
            >
              <option></option>
              { 
                props.forms.data.map( ( item ) => {
                  return (
                    <option key={ item.id } value={ item.id }>
                      { item.name[ props.selectedLanguage ] }
                    </option>
                  )
                })
              }
            </Input>
          )
        }
      </FormGroup>
    </Form>
  )
}

export default TemplateFormEdit
