import React from 'react'
import { 
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form,
  Card, CardBody,
  Button,
} from 'reactstrap'
import _ from 'lodash'

import MultiLangInput from 'components/Input/MultiLang'
import MultiLangRTE from 'components/RTE/MultiLang'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Lang from 'Lang/General'

const EmailTemplate = ({
  showUpdateEmailTemplate,
  tipsEmailTemplate,
  onChangeTipsHOC,
  lang,
  updateEmailTemplate,
  onLoadTipsHOC
}) => {
  return(
    <Modal size='xl' isOpen={ showUpdateEmailTemplate }>
      <ModalHeader toggle={ () => onChangeTipsHOC( 'showUpdateEmailTemplate', false ) } >
        { Lang[ 'UPDATE_EMAIL_SETTING' ][ lang ] }
      </ModalHeader>
      <ModalBody>
        <Form>
          <MultiLangInput
            label={ Lang[ 'EMAIL_SUBJECT' ][ lang ] }
            value={ tipsEmailTemplate.email_subject }
            type={ 'text' }
            selectedLanguage={ lang }
            onChange={ val => {
              let tmp = _.cloneDeep( tipsEmailTemplate )
              tmp.email_subject = val
              onChangeTipsHOC( 'tipsEmailTemplate', tmp )
            }}
            contentPlaceholders={{
              CENTER_NAME: '{{$.center_name}}',
              TIPS_TITLE: '{{$.tips_title}}'
            }} 
          />
          <Card className="my-4">
            <CardBody>
              <p>{ Lang[ 'EMAIL_CONTENT_PLACEHOLDER' ][ lang ] }</p>
              <ul>
                <li>{ `${ Lang[ 'MEMBER_FIRST_NAME' ][ lang ] }: {{$.first_name}}` }</li>
                <li>{ `${ Lang[ 'MEMBER_LAST_NAME' ][ lang ] }: {{$.last_name}}` }</li>
                <li>{ `${ Lang[ 'CENTER_NAME' ][ lang ] }: {{$.center_name}}` }</li>
                <li>{ `${ Lang[ 'TIPS_TITLE' ][ lang ] }: {{$.tips_title}}` }</li>
                <li>{ `${ Lang[ 'TIPS_DESCRIPTION' ][ lang ] }: {{$.tips_description}}` }</li>
                <li>{ `${ Lang[ 'TIP_URL' ][ lang ] }: {{$.tips_url}}` }</li>
              </ul>
            </CardBody>
          </Card>
          <MultiLangRTE
            label={ Lang[ 'EMAIL_CONTENT' ][ lang ] }
            selectedLanguage={ lang }
            value={ tipsEmailTemplate.email_html }
            onChange={ val => {
              let tmp = _.cloneDeep( tipsEmailTemplate )
              tmp.email_html = val
              onChangeTipsHOC( 'tipsEmailTemplate', tmp )
            }} />
        </Form>
        { onLoadTipsHOC && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => updateEmailTemplate( tipsEmailTemplate ) }>
          { Lang[ 'SUBMIT' ][ lang ] }</Button>
      </ModalFooter>
    </Modal>
  )
}

export default EmailTemplate
