import React, { useState, useEffect } from 'react'
import {
  FormGroup,
  Label,
  Input
} from 'reactstrap'
import _ from 'lodash'
import ReactHTMLParser from 'react-html-parser'
import Lang from 'Lang/General'

const Dropdown = ({
  content,
  formValues,
  onPatchFormValues,
  currentLanguage
}) => {
  const [ labelStr, setLabelStr ] = useState("")

  useEffect(() => {
    let labelText = content.label[ currentLanguage ? currentLanguage : 'en' ]

    if ( labelText.length !== 0 ) {
      if ( typeof( ReactHTMLParser( labelText )[0] ) === 'object' ) {
        let labelTag = ReactHTMLParser( labelText )[0].type
        let closingTag = "</" + labelTag + ">"
        labelText = labelText.slice( 0, -1 * closingTag.length )
        if (formValues[ content.dataMapIndex ][ 'isRequired' ]){
          labelText = labelText + "<span style='color: #ff0000;'> * </span>"
        }
        labelText = labelText + closingTag
      }
      else {
        labelText = labelText + "<span style='color: #ff0000;'> * </span>"
      }
    }
    setLabelStr( labelText )
  }, [ content, formValues, currentLanguage ])

  return (
    <FormGroup 
      tag="fieldset"
      style={{ padding: content.padding ? content.padding : '0' }}>
      <Label>{ ReactHTMLParser( labelStr ) }</Label>
      <Input 
        type="select" 
        value={ formValues[ content.dataMapIndex ][ 'value' ] }
        onChange={ ( e ) =>  {
          onPatchFormValues( content.dataMapIndex, 'value', e.target.value )
        }}>
        <option value="">{ Lang[ 'CHOOSE_ONE' ][ currentLanguage ? currentLanguage : 'en' ] }</option>
        {
          content.selections.map( ( selection, index ) => {
            return (
              <option key={ index } value={ selection.value }>
                { selection.label[ currentLanguage ? currentLanguage : 'en' ] }
              </option>
            )
          })
        }
      </Input>
    </FormGroup>
  )
}

export default Dropdown