import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Alert
} from 'reactstrap'
import _ from 'lodash'

import Lang from 'Lang/General'

const Create = ({
  showCreateLandingPage,
  newLandingPage,
  onChangeLandingPageHOC,
  addLandingPage,
  landingPageError,
  data
}) => {
  const updateNewLandingPage = ( key, val ) => {
    let tmp = _.cloneDeep( newLandingPage )
    tmp[ key ] = val
    return onChangeLandingPageHOC( 'newLandingPage', tmp )
  }
  return (
    <Modal
      isOpen={ showCreateLandingPage }
      size={ 'md' }
      toggle={() => {
        onChangeLandingPageHOC( 'showCreateLandingPage', false )
      }}>
      <ModalHeader 
        toggle={() => {
          onChangeLandingPageHOC( 'showCreateLandingPage', false )
        }}>{ Lang[ 'CREATE_LANDING_PAGE' ][ data.languageReducer.lang ] }</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          {
            typeof( landingPageError ) === 'string' && landingPageError !== '' && (
              <Alert color="danger">{ landingPageError }</Alert>
            )
          }
          <FormGroup>
            <Label>{ Lang[ 'TITLE' ][ data.languageReducer.lang ] }</Label>
            <Input 
              type="text" 
              value={ newLandingPage.title }
              onChange={ e => {
                updateNewLandingPage( 'title', e.target.value )
              }} />
            {
              typeof( landingPageError ) === 'object' && !_.isEmpty( landingPageError ) && (
                <Alert color="danger">{ landingPageError[ 'title' ] }</Alert>
              ) 
            }
          </FormGroup>
          <FormGroup>
            <Label>{ Lang[ 'DESCRIPTION' ][ data.languageReducer.lang ] }</Label>
            <Input 
              type="text" 
              value={ newLandingPage.description }
              onChange={ e => {
                updateNewLandingPage( 'description', e.target.value )
              }} />
            {
              typeof( landingPageError ) === 'object' && !_.isEmpty( landingPageError ) && (
                <Alert color="danger">{ landingPageError[ 'description' ] }</Alert>
              ) 
            }
          </FormGroup>
          <FormGroup>
            <Label>URL</Label>
            <Input 
              type="text" 
              value={ newLandingPage.url }
              onChange={ e => {
                updateNewLandingPage( 'url', e.target.value )
              }} />
            {
              typeof( landingPageError ) === 'object' && !_.isEmpty( landingPageError ) && (
                <Alert color="danger">{ landingPageError[ 'url' ] }</Alert>
              ) 
            }
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary" 
          onClick={() => {
            addLandingPage( newLandingPage )
          }}>{ Lang[ 'SUBMIT' ][ data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )
}

export default Create