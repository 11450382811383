import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import Moment from 'moment'
import _ from 'lodash'

import { Post, Get, Put, Delete } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'
import { toggleMediaDatabase } from 'actions/MediaDatabaseToggle'
import Lang from 'Lang/General'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state={ 
      loading: false,
      eventTemplates: [],
      eventTemplatesPages: [],
      newEventTemplate: {},
      selectedEventTemplate: {},
      selectedEventTemplateFiles: [],
      showCreateEventTemplate: false,
      showUpdateEventTemplate: false,
      showDeleteEventTemplateId: 0,
      eventTemplateErrorMessage: {}
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => {
      toast.success( success )
      this.setState({ eventTemplateErrorMessage: {} })
    }
    requestError = error => {
      if( typeof error === 'string' ){
        toast.error( error )
      } else {
        let tmpError = _.reduce( 
          error, 
          ( res, val, key ) => ({ ...res, [ key ]: val.replaceAll( '_', ' ' ) }),
          {}
        )
        _.map( tmpError, ( err, errKey ) => toast.error( err ) )
        this.setState({ eventTemplateErrorMessage: tmpError })
      }
    }

    onChangeEventTemplatesHOC = ( key, value ) => this.setState({ [ key ] : value })

    createEventTemplate = data => Post(
      `/api/v1/ark_member_area/am_event_templates`,
      data,
      this.createEventTemplateSuccess,
      this.createEventTemplateError,
      this.load
    )
    createEventTemplateSuccess = () => {
      this.getEventTemplates()
      this.requestSuccess( Lang[ 'EVENT_TEMPLATE_IS_SUCCESSFULLY_CREATED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showCreateEventTemplate: false })
    }
    createEventTemplateError = error => this.requestError( error )

    getEventTemplates = page => {
      let tmpSearchParams = {
        page: page ? page : 1,
        is_paginated: true
      }
      let tmpSearchString = convertObjToBase64( tmpSearchParams )
      return Get(
        `/api/v1/ark_member_area/am_event_templates?query=${ tmpSearchString }&timezone=${ encodeURIComponent( Moment().utcOffset( '+0100' ).format() ) }`,
        this.getEventTemplatesSuccess,
        this.getEventTemplatesError,
        this.load
      )
    }
    getEventTemplatesSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      this.setState({ eventTemplates: payload, eventTemplatesPages: tmpTotalPages })
    }
    getEventTemplatesError = error => this.requestError( error )

    getSelectedEventTemplate = eventTemplate_id => Get(
      `/api/v1/ark_member_area/am_event_templates/${ eventTemplate_id }?timezone=${ encodeURIComponent( Moment().utcOffset( '+0100' ).format() ) }`,
      this.getSelectedEventTemplateSuccess,
      this.getSelectedEventTemplateError,
      this.load
    )
    getSelectedEventTemplateSuccess = payload => {
      let tmp = _.cloneDeep( payload )
      tmp.start_date_time = Moment( tmp.start_date_time ).utcOffset( '+0100' )
      tmp.end_date_time = Moment( tmp.end_date_time ).utcOffset( '+0100' )
      if( tmp.repeat_start_date ){
        tmp.repeat_start_date = Moment( tmp.repeat_start_date ).utcOffset( '+0100' ) 
      }
      if( tmp.repeat_end_date ){
        tmp.repeat_end_date = Moment( tmp.repeat_end_date ).utcOffset( '+0100' ) 
      }
      tmp.exclude_dates = tmp.exclude_dates.map( excludeDate => Moment( excludeDate ).format( 'DD/MM/YYYY' ) )
      this.setState({ selectedEventTemplate: tmp, showUpdateEventTemplate: true })
    }
    getSelectedEventTemplateError = error => this.requestError( error )

    updateEventTemplate = data => Put(
      `/api/v1/ark_member_area/am_event_templates/${ data.id }`,
      data,
      this.updateEventTemplateSuccess,
      this.updateEventTemplateError,
      this.load
    )
    updateEventTemplateSuccess = () => {
      this.getEventTemplates()
      this.requestSuccess( Lang[ 'EVENT_TEMPLATE_IS_SUCCESSFULLY_CREATED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showUpdateEventTemplate: false })
    }
    updateEventTemplateError = error => this.requestError( error )

    removeEventTemplate = eventTemplate_id => Delete(
      `/api/v1/ark_member_area/am_event_templates/${ eventTemplate_id }`,
      this.removeEventTemplateSuccess,
      this.removeEventTemplateError,
      this.load
    )
    removeEventTemplateSuccess = () => {
      this.getEventTemplates()
      this.requestSuccess( Lang[ 'EVENT_TEMPLATE_IS_SUCCESSFULLY_REMOVED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showDeleteEventTemplateId: 0 })
    }
    removeEventTemplateError = error => {
      this.requestError( error )
      this.setState({ showDeleteEventTemplateId: 0 })
    }

    createSelectedEventTemplateFile = ( eventTemplate_id, data ) => Post(
      `/api/v1/ark_member_area/am_event_templates/${ eventTemplate_id }/files`,
      data,
      () => this.createSelectedEventTemplateFileSuccess( eventTemplate_id ),
      this.createSelectedEventTemplateFileError,
      this.load
    )
    createSelectedEventTemplateFileSuccess = eventTemplate_id => {
      this.requestSuccess( Lang[ 'FILE_IS_SUCCESSFULLY_CREATED' ][ this.props.data.languageReducer.lang ] )
      this.getSelectedEventTemplateFiles( eventTemplate_id )
    }
    createSelectedEventTemplateFileError = error => this.requestError( error )

    getSelectedEventTemplateFiles = eventTemplate_id => Get(
      `/api/v1/ark_member_area/am_event_templates/${ eventTemplate_id }/files`,
      this.getSelectedEventTemplateFilesSuccess,
      this.getSelectedEventTemplateFilesError,
      this.load
    )
    getSelectedEventTemplateFilesSuccess = payload => this.setState({ selectedEventTemplateFiles: payload })
    getSelectedEventTemplateFilesError = error => this.requestError( error )

    removeSelectedEventTemplateFile = ( eventTemplate_id, file_id ) => Delete(
      `/api/v1/ark_member_area/am_event_templates/${ eventTemplate_id }/files/${ file_id }`,
      () => this.removeSelectedEventTemplateFileSuccess( eventTemplate_id ),
      this.removeSelectedEventTemplateFileError,
      this.load
    )
    removeSelectedEventTemplateFileSuccess = eventTemplate_id => {
      this.requestSuccess( Lang[ 'FILE_IS_SUCCESSFULLY_REMOVED' ][ this.props.data.languageReducer.lang ] )
      this.getSelectedEventTemplateFiles( eventTemplate_id )
    }
    removeSelectedEventTemplateFileError = error => this.requestError( error )

    render = () => {
      return(
        <WrappedComponent
          { ...this.props } 
          onLoadEventTemplatesHOC={ this.state.loading }
          eventTemplates={ this.state.eventTemplates }
          eventTemplatesPages={ this.state.eventTemplatesPages }
          newEventTemplate={ this.state.newEventTemplate }
          selectedEventTemplate={ this.state.selectedEventTemplate }
          showCreateEventTemplate={ this.state.showCreateEventTemplate }
          showUpdateEventTemplate={ this.state.showUpdateEventTemplate }
          showDeleteEventTemplateId={ this.state.showDeleteEventTemplateId }
          eventTemplateErrorMessage={ this.state.eventTemplateErrorMessage }
          selectedEventTemplateFiles={ this.state.selectedEventTemplateFiles }
          onChangeEventTemplatesHOC={ this.onChangeEventTemplatesHOC }
          createEventTemplate={ this.createEventTemplate }
          getEventTemplates={ this.getEventTemplates }
          getSelectedEventTemplate={ this.getSelectedEventTemplate }
          updateEventTemplate={ this.updateEventTemplate }
          removeEventTemplate={ this.removeEventTemplate }
          createSelectedEventTemplateFile={ this.createSelectedEventTemplateFile }
          getSelectedEventTemplateFiles={ this.getSelectedEventTemplateFiles }
          removeSelectedEventTemplateFile={ this.removeSelectedEventTemplateFile }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( 
    mapStateToProps, 
    { toggleMediaDatabase } 
  )( WithHOC )
}

export default HOC