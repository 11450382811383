import React, { Component } from 'react'
import { compose } from 'redux'
import { 
  Button, Modal, ModalHeader, 
  ModalBody, ModalFooter, 
  Table, UncontrolledTooltip 
} from 'reactstrap'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PageTitle from 'components/PageTitle'

import CenterGroupForm from './components/form'
import CenterGroupHOC from 'actions/centerGroup'
import Lang from 'Lang/General'
import ARKTable from 'components/Table'
import DeleteConfirmation from 'components/Modal/delete'

class CenterGroupManagement extends Component {
  state = {
    searchColumn: [
      {
        name: Lang[ 'NAME' ][ this.props.data.languageReducer.lang ],
        type: 'text',
        val: 'name'
      },
      {
        name: Lang[ 'DESCRIPTION' ][ this.props.data.languageReducer.lang ],
        type: 'text',
        val: 'description'
      },
      {
        name: Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ],
        type: 'dropdown',
        val: 'status'
      }
    ],
    archivedSearchColumn: [
      {
        name: Lang[ 'NAME' ][ this.props.data.languageReducer.lang ],
        type: 'text',
        val: 'name'
      },
      {
        name: Lang[ 'DESCRIPTION' ][ this.props.data.languageReducer.lang ],
        type: 'text',
        val: 'description'
      }
    ]
  }

  componentDidMount = () => {
    let tmp = {
      page: 1,
      is_paginated: true,
    }
    this.props.getCenterGroups( tmp )
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Center Group' })
      return tmpModules.is_create
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Center Group' })
      return tmpModules.is_update
    }
  }

  handleSecurityModulesDelete = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Center Group' })
      return tmpModules.is_delete
    }
  }

  renderViewCenterGroupDetails = () => {
    return(
      <span className="d-inline-block mb-2 mr-2">
        <Modal isOpen={ this.props.showViewCenterGroupModal } size="lg">
          <ModalHeader 
            toggle={() => {
              this.props.onChangeCenterGroupHOC( 'showViewCenterGroupModal', false )
              this.props.onChangeCenterGroupHOC( 'showEditCenterGroupModal', true )
            }}>{ Lang[ 'CENTER_GROUP_DETAILS' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
          <ModalBody>
            <div className="scroll-area-lg">
              <Table className="mb-0" bordered>
                <tbody>
                  <tr>
                    <th scope="row">{ Lang[ 'NAME' ][ this.props.data.languageReducer.lang ] }</th>
                    <td>{ this.props.selectedCenterGroup.name }</td>
                  </tr>
                  <tr>
                    <th scope="row">{ Lang[ 'DESCRIPTION' ][ this.props.data.languageReducer.lang ] }</th>
                    <td>{ this.props.selectedCenterGroup.description }</td>
                  </tr>
                  <tr>
                    <th scope="row">{ Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ] }</th>
                    <td>{ this.props.selectedCenterGroup.status }</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={ () => this.props.onChangeCenterGroupHOC( 'showViewCenterGroupModal', false ) }>OK</Button>
            {
              this.handleSecurityModulesUpdate() && (
                <Button
                  color="alternate"
                  onClick={ () => {
                    this.props.onChangeCenterGroupHOC( 'showViewCenterGroupModal', false )
                    this.props.onChangeCenterGroupHOC( 'showEditCenterGroupModal', true )
                  }}>{ Lang[ 'EDIT' ][ this.props.data.languageReducer.lang ] }</Button>
              )
            }

          </ModalFooter>
        </Modal>
      </span>
    )
  }

  renderNewCenterGroup = () => {
    return(
      <Modal
        isOpen={ this.props.showNewCenterGroupModal }
        size="xl">
        <ModalHeader toggle={() => this.props.onChangeCenterGroupHOC( 'showNewCenterGroupModal', false )}>{ Lang[ 'NEW_CENTER_GROUP' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <CenterGroupForm
            onChangeCenterGroupHOC={ this.props.onChangeCenterGroupHOC }
            data={ this.props.newCenterGroupData }
            statusValues={ this.props.data.dictionaryReducer.dictionary.status }
            errorMessage={ this.props.errorMessage }
          />
          <Button className="mb-2 mr-2" color="info" onClick={ () => this.props.postCenterGroups() } >{ Lang[ 'CREATE' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.onChangeCenterGroupHOC( 'showNewCenterGroupModal', false ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderShowArchived = () => {
    return(
      <Modal
        isOpen={ this.props.showArchivedCenterGroupModal }
        size="xl">
        <ModalHeader toggle={() => this.props.onChangeCenterGroupHOC( 'showArchivedCenterGroupModal', false )}>{ Lang[ 'ARCHIVED_CENTER_GROUP_LIST' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <ARKTable
            data={ this.props.archivedCenterGroup.data }
            columns={[
              {
                Header: Lang[ 'NAME' ][ this.props.data.languageReducer.lang ],
                accessor: "name"
              },
              {
                Header: Lang[ 'DESCRIPTION' ][ this.props.data.languageReducer.lang ],
                accessor: "description"
              },
              {
                Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
                accessor: "id",
                show: this.handleSecurityModulesUpdate(),
                Cell: ({value}) => (
                  <div>
                    <Button
                      id={ `Center-group-${ value }-unarchive-center-group` }
                      className="mb-2 mr-2 btn-icon btn-icon-only"
                      color="danger"
                      onClick={ () => {
                        this.props.onChangeCenterGroupHOC( 'confirmUnarchiveModal', true )
                        this.props.onChangeCenterGroupHOC( 'unarchiveCenterGroupID', value )
                      }}>
                      <i className="pe-7s-menu"> </i>
                    </Button>
                    <UncontrolledTooltip
                      target={ `Center-group-${ value }-unarchive-center-group` }
                      placement="top">{ Lang[ 'UNARCHIVE_CENTER_GROUP' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                  </div>
                )
              }
            ]}
            totalPages={ this.props.archivedCenGrpTotalPage }
            page={ this.props.archivedCenterGroup.meta ? this.props.archivedCenterGroup.meta.current_page : 1 }
            onChangePage={ pg => {
              let tmp = { page: pg, is_paginated: true }
              return this.props.getArchivedCenterGroup( tmp )
            }}
            onClickReset={ () => {
              let tmp = {
                page: this.props.archivedCenterGroup.meta ? this.props.archivedCenterGroup.meta.current_page : 1,
                is_paginated: true,
              }
              this.props.getArchivedCenterGroup( tmp )
            }}
            onSearch={ this.props.getArchivedCenterGroup }
            searchLabel={ Lang[ 'SEARCH_WITH_NAME' ][ this.props.data.languageReducer.lang ] }
            searchComponent={ true }
            searchColumn={ this.state.archivedSearchColumn }
            showPagination={ false }/>
            {
              this.props.confirmUnarchiveModal && (
                <DeleteConfirmation
                  handleModalClose={ () => this.props.onChangeCenterGroupHOC( 'confirmUnarchiveModal', false ) }
                  deleteOpen={ this.props.confirmUnarchiveModal }
                  confirmAction={ () => {
                    this.props.updateCenterGroupStatus( this.props.unarchiveCenterGroupID, 'ACTIVE' )
                    this.props.onChangeCenterGroupHOC( 'confirmUnarchiveModal', false )
                  }}
                  content={ Lang[ 'DELETE_CONFIRMATION_ARCHIVED' ][ this.props.data.languageReducer.lang ] }
                  lang={ this.props.data.languageReducer.lang } />
              )
            }
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.onChangeCenterGroupHOC( 'showArchivedCenterGroupModal', false ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderEditCenterGroup = () => {
    return(
      <Modal
        isOpen={ this.props.showEditCenterGroupModal }
        size="xl">
        <ModalHeader toggle={() => this.props.onChangeCenterGroupHOC( 'showEditCenterGroupModal', false )}>{ Lang[ 'EDIT_CENTER_GROUP' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <CenterGroupForm
            onChangeCenterGroupHOC={ this.props.onChangeCenterGroupHOC }
            data={ this.props.selectedCenterGroup }
            statusValues={ this.props.data.dictionaryReducer.dictionary.status }
            errorMessage={ this.props.errorMessage }
            mode={ 'edit' }/>
          <Button className="mb-2 mr-2" color="info" onClick={ () => this.props.updateCenterGroup( this.props.selectedCenterGroup.id ) }>{ Lang[ 'UPDATE' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.onChangeCenterGroupHOC( 'showEditCenterGroupModal', false ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  render = () => {
    return(
      <>
        <div>
          <PageTitle
            heading={ Lang[ 'ALL_CENTER_GROUPS' ][ this.props.data.languageReducer.lang ] }
            icon="pe-7s-home icon-gradient bg-happy-fisher"
            buttons={[
              {
                buttonText: Lang[ 'ARCHIVED_CENTER_GROUP' ][ this.props.data.languageReducer.lang ],
                onClick: () => {
                  let tmp = {
                    page: this.props.centerGroups.meta ? this.props.centerGroups.meta.current_page : 1,
                    is_paginated: true
                  }
                  this.props.onChangeCenterGroupHOC( 'showArchivedCenterGroupModal', true )
                  this.props.getArchivedCenterGroup( tmp )
                },
                display: true
              },
              {
                buttonText: Lang[ 'NEW_CENTER_GROUP' ][ this.props.data.languageReducer.lang ],
                onClick: () => this.props.onChangeCenterGroupHOC( 'showNewCenterGroupModal', true ),
                display: this.handleSecurityModulesCreate()
              }
            ]}/>
          <ARKTable
            data={ this.props.centerGroups.data }
            columns={[
              {
                Header: Lang[ 'NAME' ][ this.props.data.languageReducer.lang ],
                accessor: "name"
              },
              {
                Header: Lang[ 'DESCRIPTION' ][ this.props.data.languageReducer.lang ],
                accessor: "description"
              },
              {
                Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
                accessor: "id",
                Cell: ({value}) => (
                  <div>
                    <Button 
                      id={ `Center-group-${ value }-view-center-group` }
                      className="mb-2 mr-2 btn-icon btn-icon-only" 
                      color="primary" 
                      onClick={ () => this.props.getSelectedCenterGroupView( value ) }>
                      <i className="pe-7s-look"> </i>
                    </Button>
                    <UncontrolledTooltip
                      target={ `Center-group-${ value }-view-center-group` }
                      placement="top">{ Lang[ 'VIEW_CENTER_GROUP' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                    {
                      this.handleSecurityModulesUpdate() && (
                        <>
                          <Button 
                            id={ `Center-group-${ value }-update-center-group` }
                            className="mb-2 mr-2 btn-icon btn-icon-only" 
                            color="primary" 
                            onClick={ () => this.props.getSelectedCenterGroupEdit( value ) }>
                            <i className="pe-7s-note"> </i>
                          </Button>
                          <UncontrolledTooltip
                          target={ `Center-group-${ value }-update-center-group` }
                          placement="top">{ Lang[ 'UPDATE_CENTER_GROUP' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                        </>
                      )
                    }
                    {
                      this.handleSecurityModulesDelete() && (
                        <Button
                          id={ `Center-group-${ value }-archive-center-group` }
                          className="mb-2 mr-2 btn-icon btn-icon-only"
                          color="danger"
                          onClick={ () => {
                            this.props.onChangeCenterGroupHOC( 'showDeleteConfirmation', true )
                            this.props.onChangeCenterGroupHOC( 'toArchiveCenterGroupId', value )
                          }}>
                          <i className="pe-7s-trash"> </i>
                          <UncontrolledTooltip
                            target={ `Center-group-${ value }-archive-center-group` }
                            placement="top">{ Lang[ 'ARCHIVE_CENTER_GROUP' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                        </Button>
                      )
                    }
                  </div>
                )
              }
            ]}
            totalPages={ this.props.centerGroupTotalPage }
            page={ this.props.centerGroups.meta ? this.props.centerGroups.meta.current_page : 1 }
            onChangePage={ pg => {
              let tmp = {
                page: pg,
                is_paginated: true
              }
              this.props.getCenterGroups( tmp )
            }}
            onClickReset={ () => {
              let tmp = {
                page: this.props.centerGroups.meta ? this.props.centerGroups.meta.current_page : 1,
                is_paginated: true
              }
              this.props.getCenterGroups( tmp )
            }}
            onSearch={ this.props.getCenterGroupsSearch }
            searchLabel={ Lang[ 'SEARCH_WITH_NAME' ][ this.props.data.languageReducer.lang ] }
            searchComponent={ true }
            searchColumn={ this.state.searchColumn }
            showPagination={ false } />
            { this.renderViewCenterGroupDetails() }
            { this.renderNewCenterGroup() }
            { this.renderShowArchived() }
            { this.renderEditCenterGroup() }
            {
              this.props.showDeleteConfirmation && (
                <DeleteConfirmation
                  handleModalClose={ () => this.props.onChangeCenterGroupHOC( 'showDeleteConfirmation', false ) }
                  deleteOpen={ this.props.showDeleteConfirmation }
                  confirmAction={ () => {
                    this.props.updateCenterGroupStatus( this.props.toArchiveCenterGroupId, 'ARCHIVED' )
                    this.props.onChangeCenterGroupHOC( 'showDeleteConfirmation', false )
                    }
                 }
                 content={ Lang[ 'DELETE_CONFIRMATION' ][ this.props.data.languageReducer.lang ] }
                 lang={ this.props.data.languageReducer.lang } />
              )
            }
        </div>
        { ( this.props.data.ajaxReducer.ajaxCallProgress > 0 || this.props.onLoadCenterGroups ) && <LoadingOverlay /> }
      </>
    )
  }

}
const mapStateToProps = state => ({ data: state })

export default compose(
  CenterGroupHOC
)( CenterGroupManagement )
