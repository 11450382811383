import Radium from 'proofpoint-radium'
import React, { useState } from 'react'
import { BsChevronDoubleDown, BsChevronDoubleUp } from 'react-icons/bs'

import CoreRenderFunction from "../../core/render"

const CollapseComponent = props => {
  const [ show, toggleShow ] = useState( false )
  return (
    <>
      <div 
        style={{ 
          display: 'flex', 
          flexDirection: 'row',
          justifyContent: 'flex-start'
        }}>
          <CoreRenderFunction
            item={ props.child.children[0] }
            mode={ props.mode }
            selectedLanguageShortname={ props.selectedLanguageShortname }
          />
        {
          props.child.children && props.child.children.length > 1 && (
            <button
              onClick={() => {
                toggleShow( !show )
              }}
              style={{ 
                marginLeft: 'auto', 
                backgroundColor: 'transparent', 
                border: 'none'
              }}>
              {
                show 
                  ? <BsChevronDoubleUp 
                    style={{ 
                      color: props.dom.mobileLineColor ? props.dom.mobileLineColor : "#6d6f76", 
                      fontSize: '30px', 
                      outline: 'none'
                    }}/>
                  : <BsChevronDoubleDown
                    style={{ 
                      color: props.dom.mobileLineColor ? props.dom.mobileLineColor : "#6d6f76", 
                      fontSize: '30px', 
                      outline: 'none'
                    }}/>
              }
            </button>
          )
        }
      </div>
      {
        show && props.child.children.map(( item, index ) => {
          if( index > 0 ) {
            return (
              <CoreRenderFunction
                item={ item }
                mode={ props.mode }
                selectedLanguageShortname={ props.selectedLanguageShortname }
              />
            )
          }
        })
      }
    </>
  )
}

const FooterMenu = props => {
  return (
    <div
      className={ props.item.className }
      style={{ 
        ...props.item.style, 
        order: props.item.mobileArrangement,
        '@media ( min-width:768px )': {
          order: props.item.desktopArrangement,
        },
      }}>
      <div className="w-100">
        {
          props.item.title && (
            <div style={ props.item.title.style }>
              { 
                props.item.title.content[ props.selectedLanguageShortname ] 
                  ? props.item.title.content[ props.selectedLanguageShortname ] 
                  : props.item.title.content[ 'de' ]
                    ? props.item.title.content[ 'de' ]
                    : props.item.title.content[ 'en' ]
              }
            </div>
          )
        }
        {
          props.item.children.map(( child, index ) => {
            if( child.type === 'footer-menu-group' ) {
              return (
                <>
                  {/* desktop */}
                  <div
                    style={{ 
                      '@media screen and (max-width:767px)': {
                        display: "none"
                      }
                    }}>
                    {
                      child.children && child.children.map( grandchild => {
                        return (
                          <CoreRenderFunction
                            item={ grandchild }
                            mode={ props.mode }
                            site={ props.site }
                            siteSettings={ props.siteSettings }
                            selectedLanguageShortname={ props.selectedLanguageShortname }
                          />
                        )
                      })
                    }
                  </div>
                  {/* mobile */}
                  <div
                    style={{
                      '@media screen and (min-width:768px)': {
                        display: "none"
                      },
                      borderBottom: `1px solid ${ props.dom.mobileLineColor ? props.dom.mobileLineColor : "#6d6f76" }`, 
                      borderTop: `${ index === 0 ? "1px" : "0" } solid ${ props.dom.mobileLineColor ? props.dom.mobileLineColor : "#6d6f76" }`
                    }}>
                    <CollapseComponent 
                      child={ child } 
                      mode={ props.mode }
                      selectedLanguageShortname={ props.selectedLanguageShortname } 
                      dom={ props.dom } />
                  </div>
                </>
              )
            }
          })
        }
      </div>
    </div>
  )
}

export default Radium( FooterMenu )