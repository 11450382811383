import React from 'react'
import ReactHTMLParser from 'react-html-parser'
import _ from 'lodash'

import CKEditorSection from 'components/RTE/section'
import Image from './Img'
import Mediathek from './Mediathek'
import Btn from './Btn'
import TemplateForm from './TemplateForm'

const Divs = props => {
  return (
    <>
      {
        ( props.children && props.children.length > 0 ) ? props.children.map(( item, index ) => {
          if( item.type === 'div' ) {
            if( item.variant === 'text' ) {
              if( props.selectedComponentId === item.id && props.mode !== 'preview' ) {
                return (
                  <div>
                    <CKEditorSection
                      data={ props.selectedComponent[ 'content' ] }
                      onChange={ ( data ) => {
                        // patch component
                        let tmpComponent = _.cloneDeep( props.selectedComponent )
                        tmpComponent[ 'content' ] = {
                          "en": data[ 'en' ], 
                          "de": data[ 'de' ],
                          "it": data[ 'it' ],
                          "nl": data[ 'nl' ]
                        }
                        props.updateSelectedComponent( tmpComponent )
                        // patch column
                        let tmpColumn = _.cloneDeep( props.selectedCol )
                        tmpColumn = props.FindComponentAndPatch( tmpColumn, tmpComponent )
                        props.updateSelectedCol( tmpColumn )
                        // patch container
                        let tmpContainer = _.cloneDeep( props.dom )
                        tmpContainer = props.FindComponentAndPatch( tmpContainer, tmpColumn )
                        props.updateDom( tmpContainer )
                      }}
                      selectedLanguage={ props.selectedLanguage } />
                  </div>
                )
              } else {
                return (
                  <div 
                    className="ck-content"
                    onClick={() => {
                      props.updateSelectedComponentId( item.id )
                      props.updateSelectedComponent( item )
                    }}
                    style={ item.style }>
                    { ReactHTMLParser( item.content[ props.selectedLang ? props.selectedLang : 'en' ] ) }
                  </div>
                )
              }
            } else {
              return <div style={ item.style }>
                {
                  item.children && item.children.length > 0 && item.children.map( child => {
                    if ( child.type === 'img' ) {
                      return (
                        <Image 
                          { ...props } 
                          { ...child }
                          onClick={() => {
                            props.updateSelectedComponentId( child.id )
                            props.updateSelectedComponent( child )
                          }}  />
                      )
                    } else if( child.type === 'button' ) {
                      return (
                        <Btn
                          { ...props } 
                          { ...child }
                          onClick={() => {
                            props.updateSelectedComponentId( child.id )
                            props.updateSelectedComponent( child )
                          }} />
                      )
                    } else if( 
                      child.type === 'form' ||
                      child.type === 'webForm' ||
                      child.type === 'templateForm'
                    ) {
                      return (
                        <TemplateForm
                          { ...props }
                          { ...child }
                          item={ child }
                          onClick={() => {
                            props.updateSelectedComponentId( child.id )
                            props.updateSelectedComponent( child )
                          }} />
                      )
                    } else if ( child.type === 'div' && child.variant && child.variant === 'text' ) {
                      if( props.selectedComponentId === child.id ) {
                        return (
                          <div>
                            <CKEditorSection
                              data={ props.selectedComponent[ 'content' ] }
                              onChange={ ( data ) => {
                                // patch component
                                let tmpComponent = _.cloneDeep( props.selectedComponent )
                                tmpComponent[ 'content' ] = {
                                  "en": data[ 'en' ], 
                                  "de": data[ 'de' ],
                                  "it": data[ 'it' ],
                                  "nl": data[ 'nl' ]
                                }
                                props.updateSelectedComponent( tmpComponent )
                                // patch column
                                let tmpColumn = _.cloneDeep( props.selectedCol )
                                tmpColumn = props.FindComponentAndPatch( tmpColumn, tmpComponent )
                                props.updateSelectedCol( tmpColumn )
                                // patch container
                                let tmpContainer = _.cloneDeep( props.dom )
                                tmpContainer = props.FindComponentAndPatch( tmpContainer, tmpColumn )
                                props.updateDom( tmpContainer )
                              }}
                              selectedLanguage={ props.selectedLanguage } />
                          </div>
                        )
                      } else {
                        return (
                          <div 
                            onClick={() => {
                              props.updateSelectedComponentId( child.id )
                              props.updateSelectedComponent( child )
                            }}
                            style={ child.style }>
                            { ReactHTMLParser( child.content[ props.selectedLang ? props.selectedLang : 'en' ] ) }
                          </div>
                        )
                      }
                    } else if ( child.type === 'div' ) {
                      return (
                        <Divs 
                          { ...props }
                          { ...child }
                          onClick={() => {
                            props.updateSelectedComponentId( child.id )
                            props.updateSelectedComponent( child )
                          }} 
                          data={ child }
                        />
                      )
                    } else {
                      return(
                        <></>
                      )
                    }
                  })
                }
              </div>
            }
          } else if( item.type === 'img' ) {
            return (
              <Image 
                { ...props } 
                { ...item }
                onClick={() => {
                  props.updateSelectedComponentId( item.id )
                  props.updateSelectedComponent( item )
                }}  />
            )
          } else if(
            item.type === "form" ||
            item.type === "webForm" ||
            item.type === "mediathek"
          ) {
            return(
              <Mediathek
                { ...props } 
                { ...item }
                onClick={() => {
                  props.updateSelectedComponentId( item.id )
                  props.updateSelectedComponent( item )
                }} />
            )
          } else if( item.type === 'button' ) {
            return (
              <Btn
                { ...props } 
                { ...item }
                onClick={() => {
                  props.updateSelectedComponentId( item.id )
                  props.updateSelectedComponent( item )
                }} />
            )
          } else if( item.type === 'templateForm' ) {
            return (
              <TemplateForm
                { ...props }
                { ...item }
                item= { item }
                onClick={() => {
                  props.updateSelectedComponentId( item.id )
                  props.updateSelectedComponent( item )
                }} />
            )
          } else if( item.type === 'div' ) {
            return (
              <Divs 
                { ...props }
                { ...item }
                item= { item }
                onClick={() => {
                  props.updateSelectedComponentId( item.id )
                  props.updateSelectedComponent( item )
                }} 
                data={ item }
              />
            )
          } else {
            return(
              <></>
            )
          }
        }) : (
          <div></div>
        )
      }
    </>
  )
}

export default Divs