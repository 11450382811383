import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'


import { Get, Post, outdatedToken, Put } from 'utils/axios'
import { getRefreshToken } from 'actions/token'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const FunnelScenarioHOC = ( WrappedComponent ) => {
  class FunnelScenarioWrappedComponent extends Component{
    state = {
      loading: false,
      totalPages: [],
      showCreateScenario: false,
      allScenarios: [],
      newScenarioData: {
        name: {
          en: '',
          de: '',
          it: '',
          nl: '',
          es: '',
          fr: ''
        }
      },
      showUpdateForm: false,
      selectedScenario: {
        name: {
          en: '',
          de: '',
          it: '',
          nl: '',
          es: '',
          fr: ''
        }
      },
      scenariosForSelect: [],
      name: '',
      scenariosErrMsg: {}
    }

    onChangeScenarioHOC = ( key, value ) => this.setState({ [key]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    ajaxError = ( error ) => {
      outdatedToken( error, this.props.history )
      this.requestError( error )
    }

    getAllScenarios = (query) => Get(
      `/api/v1/funnel_scenarios?query=${ query }`,
      this.getAllScenariosSuccess,
      this.getAllScenariosError,
      this.load
    )
    getAllScenariosSuccess = payload => {
      let tmpTotalPages = []
      let tmp = []
      if( payload.data && payload.data.length > 0 ) {
        payload.data.map( item => {
          tmp.push({
            ...item,
            label: item.name[this.props.data.languageReducer.lang],
            value: item.name[this.props.data.languageReducer.lang]
          })
        })
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      } else {
        toast.error( Lang['NO_RESULT_FOUND'][ this.props.data.languageReducer.lang ] )
      }
      this.setState({ allScenarios: payload, totalPages: tmpTotalPages, scenariosForSelect: tmp })
    }
    getAllScenariosError = error => this.requestError( error )

    createScenario = data => Post(
      `/api/v1/funnel_scenarios`,
      data,
      this.createScenarioSuccess,
      this.createScenarioError,
      this.load
    )
    createScenarioSuccess = payload => {
      this.setState({ showCreateScenario: false })
      this.requestSuccess( Lang['CREATE_SUCCESS_SCENARIO'][ this.props.data.languageReducer.lang ])
      this.getAllScenarios( convertObjToBase64({ is_paginated: true, page: 1 }) )
    }
    createScenarioError = error => {
      this.requestError( error )
      this.setState({ scenariosErrMsg: error })
    }

    getSelectedScenario = id => Get(
      `/api/v1/funnel_scenarios/${ id }`,
      this.getSelectedScenarioSuccess,
      this.getSelectedScenarioError,
      this.load
    )
    getSelectedScenarioSuccess = payload => {
      this.setState({ selectedScenario: payload })
    }
    getSelectedScenarioError = error => this.requestError( error )

    updateScenario = (id, data) => Put(
      `/api/v1/funnel_scenarios/${ id }`,
      data,
      this.updateScenarioSuccess,
      this.updateScenarioError,
      this.load
    )
    updateScenarioSuccess = payload => {
      this.requestSuccess( Lang['UPDATE_SUCCESS_SCENARIO'][this.props.data.languageReducer.lang] )
      this.setState({ showUpdateForm: false })
      this.getAllScenarios( convertObjToBase64({ is_paginated: true, page: 1 }) )
    }
    updateScenarioError = error => {
      this.requestError( error )
      this.setState({ scenariosErrMsg: error })
    }

    render = () => {
      return(
        <>
          <WrappedComponent
            { ...this.props }
            onLoadScenario={ this.state.loading }
            totalPages={ this.state.totalPages }
            onChangeScenarioHOC={ this.onChangeScenarioHOC }
            showCreateScenario={ this.state.showCreateScenario }
            newScenarioData={ this.state.newScenarioData }
            getAllScenarios={ this.getAllScenarios }
            createScenario={ this.createScenario }
            allScenarios={ this.state.allScenarios }
            getSelectedScenario={ this.getSelectedScenario }
            selectedScenario={ this.state.selectedScenario }
            updateScenario={ this.updateScenario }
            showUpdateForm={ this.state.showUpdateForm }
            scenariosForSelect={ this.state.scenariosForSelect }
            name={ this.state.name }
            scenariosErrMsg={ this.state.scenariosErrMsg }/>
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    getRefreshToken
  } )( FunnelScenarioWrappedComponent )
}

export default FunnelScenarioHOC
