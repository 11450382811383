import React, { useEffect } from 'react'
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button, UncontrolledTooltip
} from 'reactstrap'
import { compose } from 'redux'
import Image from 'react-bootstrap/Image'
import Moment from 'moment'
import _ from 'lodash'

import ARKTable from 'components/Table'
import PageTitle from 'components/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import DeleteConfirmation from 'components/Modal/delete'
import NoPicture from 'assets/images/no-pic.jpg'
import Lang from 'Lang/General'

import MembersHOC from './actions.js'
import RolesHOC from '../Roles/actions'
import MemberStatusesHOC from '../MemberStatus/actions'
import MemberForm from './Form'

const Members = props => {
  useEffect( () => {
    props.getMembers()
    props.getMemberStatuses()
    props.getRoles()
  }, [] )

  useEffect( () => {
    if( !props.showCreateMember ){
      props.onChangeMembersHOC( 
        'newMember',
        {
          birthday: Moment().format( 'YYYY-MM-DD' ),
          country: '',
          first_name: '',
          last_name: '',
          email: '',
          photo: {
            raw: ''
          },
          ms_center_id: 0, 
          ark_member_role_id: 0, 
          ark_member_user_status_id: 0, 
          exist_platform: '',
          salutation: 'Mr',
          session_token: '-'
        }
      )
    }
  }, [ props.showCreateMember ] )

  useEffect( () => {
    if( !props.showUpdateMember ){
      props.onChangeMembersHOC( 'selectedMember', {} )
    }
  }, [ props.showUpdateMember ] )

  const handleSecurityModulesCreate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Member Area' })
      return tmpModules.is_create
    }
  }

  const handleSecurityModulesUpdate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Member Area' })
      return tmpModules.is_update
    }
  }

  const handleSecurityModulesDelete= () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Member Area' })
      return tmpModules.is_delete
    }
  }

  const renderCreateMemberModal = () => (
    <Modal size='lg' isOpen={ props.showCreateMember } >
      <ModalHeader toggle={ () => props.onChangeMembersHOC( 'showCreateMember', false ) } >
        { Lang[ 'CREATE' ][ props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        <MemberForm
          memberType='newMember'
          currentMember={ props.newMember }
          onChangeMembersHOC={ props.onChangeMembersHOC }
          roles={ props.roles }
          memberStatuses={ props.memberStatuses }
          errorMessage={ props.errorMessage }
          lang={ props.data.languageReducer.lang }
          sites={ props.data.profileReducer.profile.sites } 
          onLoadMembersHOC={ props.onLoadMembersHOC }
        />
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => {
            let tmp = _.cloneDeep( props.newMember )
            let tmpCenter = _.find( props.data.profileReducer.profile.sites, { center_id: tmp.ms_center_id } )
            props.createMember({ 
              ...props.newMember,
              member_login_link: tmpCenter ? `https://${ tmpCenter.default_domain }` : ''
            }) 
          }}>
          { Lang[ 'SUBMIT' ][ props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )

  const renderUpdateMemberModal = () => (
    <Modal size='lg' isOpen={ props.showUpdateMember } >
      <ModalHeader toggle={ () => props.onChangeMembersHOC( 'showUpdateMember', false ) } >
        { Lang[ 'UPDATE' ][ props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        {
          !_.isEmpty( props.selectedMember ) && <MemberForm
            memberType='selectedMember'
            currentMember={ props.selectedMember }
            onChangeMembersHOC={ props.onChangeMembersHOC }
            roles={ props.roles }
            memberStatuses={ props.memberStatuses }
            errorMessage={ props.errorMessage }
            lang={ props.data.languageReducer.lang }
            sites={ props.data.profileReducer.profile.sites }
            onLoadMembersHOC={ props.onLoadMembersHOC } />
        }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => {
            let isExpert = (
              props.selectedMember.ark_member_role_id > 0 &&
              _.find( props.roles, role => role.id === props.selectedMember.ark_member_role_id ).name === 'Expert'
            )
            if( isExpert && Moment().diff( Moment( props.selectedMember.birthday, 'YYYY-MM-DD' ), 'years' ) < 18 ){
              props.requestError({ birthday: Lang[ 'EXPERT_MUST_BE_AT_LEAST' ][ props.data.languageReducer.lang ] } )
            } else {
              props.updateMember( props.selectedMember ) 
            }
          }}>
          { Lang[ 'SUBMIT' ][ props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )

  return(
    <>
      <PageTitle
        heading={ Lang[ 'MEMBERS' ][ props.data.languageReducer.lang ] }
        icon='pe-7s-users icon-gradient bg-happy-fisher'
        buttons={[
          {
            buttonText: Lang[ 'CREATE' ][ props.data.languageReducer.lang ],
            onClick: () => props.onChangeMembersHOC( 'showCreateMember', true ),
            display: handleSecurityModulesCreate()
          }
        ]}/>
      <ARKTable
        data={ props.members.data }
        columns={[
          {
            Header: Lang[ 'NAME' ][ props.data.languageReducer.lang ],
            accessor: "first_name",
            Cell: row => `${ row.original.first_name } ${ row.original.last_name }`
          },
          {
            Header: Lang[ 'EMAIL' ][ props.data.languageReducer.lang ],
            accessor: "email"
          },
          {
            Header: Lang[ 'PHOTO' ][ props.data.languageReducer.lang ],
            accessor: 'photo',
            Cell: ({ value }) => (
              <Image 
                src={ !_.isEmpty( value.url ) ? value.url : NoPicture } 
                width="50px" 
                height="50px" />
            )
          },
          {
            Header: Lang[ 'ACTIONS' ][ props.data.languageReducer.lang ],
            accessor: 'id',
            Cell: ({ value }) => (
              <div className='d-flex justify-content-center' >
                {
                  handleSecurityModulesUpdate() && (
                    <>
                      <Button
                        id={ `Member-${ value }-update` }
                        className='mb-2 mr-2 btn-icon btn-icon-only'
                        color='primary'
                        onClick={ () => props.getSelectedMember( value ) }>
                        <i className='pe-7s-note'> </i>
                      </Button>
                      <UncontrolledTooltip
                        target={ `Member-${ value }-update` }
                        placement='top'>{ Lang[ 'EDIT' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                    </>
                  )
                }
                {
                  handleSecurityModulesDelete() && (
                    <>
                      <Button
                        id={ `Member-${ value }-archive-member` }
                        className='mb-2 mr-2 btn-icon btn-icon-only'
                        color='danger'
                        onClick={ () => props.onChangeMembersHOC( 'showDeleteMemberId', parseInt( value ) ) }>
                        <i className='pe-7s-trash'> </i>
                      </Button>
                      <UncontrolledTooltip
                        target={ `Member-${ value }-archive-member` }
                        placement='top'>{ Lang[ 'REMOVE' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                    </>
                  )
                }
              </div>
            )
          }
        ]}
        totalPages={ props.membersPages }
        page={ props.members.meta ? props.members.meta.current_page : 1 }
        onChangePage={ pg => props.getMembers( pg ) }
        showPagination={ false } 
      />
      <DeleteConfirmation
        handleModalClose={ () => props.onChangeMembersHOC( 'showDeleteMemberId', 0 ) }
        deleteOpen={ props.showDeleteMemberId > 0 }
        confirmAction={ () => props.removeMember( props.showDeleteMemberId ) }
        content={ Lang[ 'DELETE_CONFIRMATION_MEMBER' ][ props.data.languageReducer.lang ] }
        lang={ props.data.languageReducer.lang } />
      { renderCreateMemberModal() }
      { renderUpdateMemberModal() }
      { ( props.onLoadMembersHOC || props.onLoadMemberStatusesHOC || props.onLoadRolesHOC ) && <LoadingOverlay/> }
    </>
  )
}

export default compose(
  MembersHOC,
  RolesHOC,
  MemberStatusesHOC
)( Members )