import React, { Component } from 'react'

import { Get } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      clients: []
    }

    load = param => this.setState({ loading: param })

    getClients = () => {
      let query = {
        is_paginated: false,
        filter: {
          $or: [
            { status: 'ACTIVE' },
            { status: 'TRIAL' }
          ]
        },
      }
      return Get(
        `/api/v1/clients?query=${ convertObjToBase64( query ) }`,
        this.getClientsSuccess,
        this.getClientsError,
        this.load
      )
    }
    getClientsSuccess = payload => this.setState({ 
      clients: payload.data.map( client => ({
        ...client,
        value: client.id,
        label: client.company_name
      })) 
    })
    getClientsError = () => {}

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadClientsHOC={ this.state.loading } 
          clients={ this.state.clients }
          getClients={ this.getClients }
        />
      )
    }
  }
  return WithHOC
}

export default HOC
