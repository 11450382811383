import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"
import uniqid from "uniqid"
import { Container, Row, Col, Button } from "reactstrap"
import _ from "lodash"

import Lang from "Lang/General"

const JsonEditor = (props) => {
  let {
    contentJSONString,
    onChangeSectionHOC,
    updateSelectedSection,
    selectedLanguage,
  } = props

  const [JSONString, setJSONString] = useState("")
  const [generatedId, setGeneratedId] = useState("")

  useEffect(() => {
    setJSONString(contentJSONString)
  }, [contentJSONString])

  const checkJSON = () => {
    try {
      return JSON.parse(JSONString)
    } catch (e) {
      toast.error("Error on JSON.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      })
    }
  }

  const onJsonInput = (e) => {
    if (e.keyCode == "9" && !e.shiftKey) {
      e.preventDefault()
      document.execCommand("insertText", false, "  ")
      setJSONString(e.target.value)
    }
  }

  return (
    <Container fluid className="card p-3">
      <Row>
        <Col md={12}>
          <textarea
            style={{ width: "100%" }}
            rows={20}
            value={JSONString}
            onChange={(e) => setJSONString(e.target.value)}
            onKeyDown={(e) => onJsonInput(e)}
          />
        </Col>
      </Row>
      <Button
        style={{ width: "fit-content" }}
        color="primary"
        onClick={() => {
          let tmp = checkJSON()
          if (!_.isEmpty(tmp)) {
            updateSelectedSection({ content: tmp })
          }
        }}
      >
        {Lang["SUBMIT"][selectedLanguage]}
      </Button>
      <Row>
        <Col
          md={12}
          style={{
            display: "flex",
            alignItems: "center",
            paddingTop: "10px",
          }}
        >
          <input
            className="mr-2"
            style={{ width: "20vw", height: "100%" }}
            value={generatedId}
            onChange={(e) => setGeneratedId(e.target.value)}
          />
          <Button color="primary" onClick={() => setGeneratedId(uniqid())}>
            {Lang["GENERATE_NEW_DOM_ID"][selectedLanguage]}
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default JsonEditor
