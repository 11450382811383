import React, { Component } from 'react'

const withSearch = ( WrappedComponent ) => {
  class withSearchWrappedComponent extends Component {
    state = {
      footer: {},
      footerBackgroundColor: '',
      footerItem: {},
      footerTextColor: ''
    }

    updateWithSearchState = ( key, param ) => this.setState({ [ key ]: param })

    searchFooterColor = param => {
      this.setState({
        footerBackgroundColor: param.style.background,
        footerTextColor: param.style.color
      })
    }

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          footer={ this.state.footer }
          footerBackgroundColor={ this.state.footerBackgroundColor }
          footerItem={ this.state.footerItem }
          footerTextColor={ this.state.footerTextColor }
          updateWithSearchState={ this.updateWithSearchState }
          searchFooterColor={ this.searchFooterColor } />
      )
    }
  }
  return withSearchWrappedComponent
}

export default withSearch