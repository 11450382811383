import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get, Post, Put, Delete } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const CategoryHOC = ( WrappedComponent ) => {
  class CategoryWrappedComponent extends Component{
    state = {
      loading: false,
      categories: [],
      showCategoryModal: false,
      categoryModalMode: '',
      selectedCategory: {
        name: Lang[ 'BLANK' ],
        description: Lang[ 'BLANK' ]
      },
      searchParams: {
        name: ''
      },
      totalPages: [],
      showDeleteModal: false
    }

    load = param => this.setState({ loading: param })
    updateLoad = param => this.setState({ updateLoading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error !== 'string' 
      ? Object.values( error ).map( val => toast.error( val ) )
      : toast.error( error )


    handleShowCategoryModal = ( isShow, mode ) => {
      this.setState({ showCategoryModal: isShow, categoryModalMode: mode })
      if (!isShow) {
        this.setState({ selectedCategory: {
          name: Lang[ 'BLANK' ],
          description: Lang[ 'BLANK' ]} 
        })
      }
    } 


    onChangeCategoryData = ( key, value ) => {
      let tmp = {}
      if( this.state.selectedCategory.name )
      {
        tmp = _.cloneDeep( this.state.selectedCategory )
        tmp[key] = value
        this.setState({ selectedCategory: tmp })
      }
      else{
        tmp = _.cloneDeep( this.state.selectedCategory )
        tmp[key] = value
        this.setState({ selectedCategory: tmp })
      }
    }

    onChangeCategoryHOC = ( key, value ) => this.setState({ [key]: value })

    getAllCategory = ( query ) => Get(
      `/api/v1/digital_order_forms_categories?query=${ query }`,
      this.getAllCategorySuccess,
      this.getAllCategoryError,
      this.load
    )
    getAllCategorySuccess = payload => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      else{
        this.requestError( Lang[ 'NO_RESULT_FOUND' ][ this.props.data.languageReducer.lang ] )
      }
      this.setState({ categories: payload, totalPages: tmpTotalPages })
    }
    getAllCategoryError = error => this.requestError( error )

    createCategory = data => {
      Post(
        `/api/v1/digital_order_forms_categories`,
        data,
        this.createCategorySuccess,
        this.createCategoryError,
        this.load
      )
    } 
    createCategorySuccess = payload => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'CATEGORY_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.handleShowCategoryModal( false )
      this.getAllCategory( convertObjToBase64( tmp ) )
      this.resetSearchParams()
    }
    createCategoryError = error => this.requestError( error ) 

    updateCategory = (id, data) => Put(
      `/api/v1/digital_order_forms_categories/${ id }`,
      data,
      this.updateCategorySuccess,
      this.updateCategoryError,
      this.load
    )
    updateCategorySuccess = payload => {
      let tmp = {
        page: 1,
        is_paginated: true,
        filter: { name: { $like: `%${ this.state.searchParams.name.toLowerCase() }%` } }
      }
      this.requestSuccess( Lang[ 'CATEGORY_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getAllCategory( convertObjToBase64( tmp ) )
      this.handleShowCategoryModal( false )
    }
    updateCategoryError = error => this.requestError( error ) 

    deleteCategory = id => Delete(
      `/api/v1/digital_order_forms_categories/${ id }`,
      this.deleteCategorySuccess,
      this.deleteCategoryError,
      this.load
    )
    deleteCategorySuccess = payload => {
      let tmp = {
        page: 1,
        is_paginated: true,
        filter: { name: { $like: `%${ this.state.searchParams.name.toLowerCase() }%` } }
      }
      this.requestSuccess( Lang[ 'CATEGORY_REMOVE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getAllCategory( convertObjToBase64( tmp ) )
    }
    deleteCategoryError = error => this.requestError( error )

    resetSearchParams = () => {
      this.setState({ 
        searchParams: {
          name: ''
      }})
    }

    render = () => {
      return(
        <>
          <WrappedComponent
            { ...this.props }
            onLoadCategory={ this.state.loading }
            categories={ this.state.categories }
            handleShowCategoryModal={ this.handleShowCategoryModal }
            showCategoryModal={ this.state.showCategoryModal }
            getAllCategory={ this.getAllCategory }
            createCategory={ this.createCategory }
            onChangeCategoryData={ this.onChangeCategoryData }
            selectedCategory={ this.state.selectedCategory }
            getSelectedCategory={ this.getSelectedCategory }
            updateCategory={ this.updateCategory }
            categoryModalMode={ this.state.categoryModalMode }
            searchParams={ this.state.searchParams }
            resetSearchParams={ this.resetSearchParams }
            totalPages={ this.state.totalPages }
            onChangeCategoryHOC={ this.onChangeCategoryHOC }
            deleteCategory={ this.deleteCategory }
            showDeleteModal={ this.state.showDeleteModal }/>
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps)( CategoryWrappedComponent )
}

export default CategoryHOC
