import React from 'react'
import Radium from 'proofpoint-radium'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import { toolbarPlugin } from '@react-pdf-viewer/toolbar'

import Lang from 'Lang/General'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'

const PdfViewer = props => {

  const toolbarPluginInstance = toolbarPlugin()
  const { Toolbar } = toolbarPluginInstance

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: () => (
      <Toolbar>
        { ToolbarSlot => {
          const {
            GoToPreviousPage,
            CurrentPageInput,
            NumberOfPages,
            GoToNextPage,
            ZoomOut,
            Zoom,
            ZoomIn,
            SwitchTheme,
            EnterFullScreen  
          } = ToolbarSlot
          return (
            <>
              <GoToPreviousPage />
              <CurrentPageInput />
              <NumberOfPages />
              <GoToNextPage />
              <div className='ml-auto px-2'><ZoomOut /></div>
              <Zoom />
              <ZoomIn />
              <div className='ml-auto px-2'><SwitchTheme /></div>
              <EnterFullScreen />
            </>
          )
        }}
      </Toolbar>
    ),
    sidebarTabs: defaultTabs => [ defaultTabs[ 0 ] ]
  })
  
  return (
    <div
      style={{
        maxWidth: '1340px',
        height: '80vh',
        margin: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#d1d1d1',
        display: 'flex'
      }}>
      {
        props.item.source !== '' ? (
          props.mode === 'view' ? (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.3.200/build/pdf.worker.js">
              <Viewer
                fileUrl={ props.item.source }
                plugins={ [ defaultLayoutPluginInstance, toolbarPluginInstance ] }
                defaultScale={ 1.0 } />
            </Worker>
          ) : (
            <iframe width='80%' height='100%' frameBorder='0' src={ `https://docs.google.com/gview?url=${ props.item.source }&embedded=true` } />
          )
        ) : (
          <h2>{ Lang[ 'ADD_PDF_FILE' ][ props.selectedLanguageShortname ] }</h2>
        )
      }
    </div>
  )
}

export default Radium( PdfViewer )
