import React from 'react'

const Hr = ({
  item,
  id,
  style,
  className,
  mode,

  onChangeSectionEditorState,
  selectedEditableComponent,
  hoveredComponentId
}) => {
  return (
    <hr
      id={ id }
      style={{
        ...style,
        outline: ( ( selectedEditableComponent && selectedEditableComponent.id === id ) || hoveredComponentId === id  ) 
          ? '2px solid #007bff' 
          : 'none'
      }}
      className={ className }
      onClick={
        mode === 'edit' ? ( e ) => {
          onChangeSectionEditorState( 'selectedEditableComponent', item )
        } : () => {}
      }
      onMouseEnter={ () => mode === 'edit' && onChangeSectionEditorState && onChangeSectionEditorState( 'hoveredComponentId', id ) } 
      onMouseLeave={ () => mode === 'edit' && onChangeSectionEditorState && onChangeSectionEditorState( 'hoveredComponentId', '' ) } />
  )
}

export default Hr