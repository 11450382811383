import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get, outdatedToken } from 'utils/axios'
import { getRefreshToken } from 'actions/token'

import Lang from 'Lang/General'

const DomainsHOC = ( WrappedComponent ) => {
  class DomainsWrappedComponent extends Component{
    state = {
      loading: false,
      domains: [],
      centers: [],
      totalPages: [],
      searchParams: {
        siteDomain: '',
        center: {}
      }
    }

    onChangeDomainsHOC = ( key, value ) => this.setState({ [key]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    ajaxError = ( error ) => {
      outdatedToken( error, this.props.history )
      this.requestError( error )
    }

    getAllDomains = ( query ) => Get(
      `/api/v1/domains?query=${query}`,
      this.getAllDomainsSuccess,
      this.getAllDomainsError,
      this.load
    )
    getAllDomainsSuccess = payload => {
      let tmpPayload = []
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      else{
        this.requestError( Lang[ 'NO_RESULT_FOUND' ][ this.props.data.languageReducer.lang ] )
      }
      this.setState({ domains: payload, totalPages: tmpTotalPages })
    }
    getAllDomainsError = error => this.ajaxError( error )

    getCenters = query => Get(
      `/api/v1/centers?query=${ query }`,
      this.getCentersSuccess,
      this.getCentersError,
      this.load
    )
    getCentersSuccess = payload => {
      let defaultCenter = { id: -1, label: '', value: '' } 
      let centerOptions = [ defaultCenter, ...payload.data.map( item => ({ id: item.id, label: item.center_name, value: item.center_name }) ) ]
      this.setState({ centers: centerOptions })
    }
    getCentersError = error => this.ajaxError( error )


    render = () => {
      return(
        <>
          <WrappedComponent
            { ...this.props }
            onLoadDomains={ this.state.loading }
            domains={ this.state.domains }
            totalPages={ this.state.totalPages }
            getAllDomains={ this.getAllDomains }
            centers={ this.state.centers }
            getCenters={ this.getCenters }
            onChangeDomainsHOC={ this.onChangeDomainsHOC }
            searchParams={ this.state.searchParams }/>
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    getRefreshToken
  } )( DomainsWrappedComponent )
}

export default DomainsHOC
