import React, { useState } from 'react'
import { Input } from 'reactstrap'
import _ from 'lodash'

import CoreRenderFunction from '../../core/render'
import Lang from 'Lang/General'

const TeamSearchBar = props => {
  let {
    item,
    selectedLanguageShortname
  } = props
  const [ filter, updateFilter ] = useState({})

  return (
    <div className="my-3">
      <Input
        type="select"
        className='mr-2 mb-2'
        style={{
          width: '250px',
          height: "37px",
          display: 'inline-block'
        }}
        onChange={ e => {
          if( e.target.value ){
            updateFilter({ ...filter, locations: { id: e.target.value, label: e.target.options[ e.target.selectedIndex ].text }})
          } else {
            let tmp = _.cloneDeep( filter )
            delete tmp.locations
            updateFilter( tmp )
          }
        }}>
        <option value="">{ Lang[ 'LOCATION' ][ selectedLanguageShortname ] + " (" + Lang[ 'ALL' ][ selectedLanguageShortname ] + ")" }</option>
      </Input>
      <Input
        type="select"
        className='mr-2 mb-2'
        style={{
          width: '250px',
          height: "37px",
          display: 'inline-block'
        }}
        onChange={ e => {
          if( e.target.value ){
            updateFilter({ ...filter, main_focus: { id: e.target.value, label: e.target.options[ e.target.selectedIndex ].text }})
          } else {
            let tmp = _.cloneDeep( filter )
            delete tmp.main_focus
            updateFilter( tmp )
          }
        }}>
        <option value="">{ Lang[ 'MAIN_FOCUS' ][ selectedLanguageShortname ] + " (" + Lang[ 'ALL' ][ selectedLanguageShortname ] + ")" }</option>
      </Input>
      <Input
        type="select"
        className='mr-2 mb-2'
        style={{
          width: '250px',
          height: "37px",
          display: 'inline-block'
        }}
        onChange={ e => {
          if( e.target.value ){
            updateFilter({ ...filter, sports: { id: e.target.value, label: e.target.options[ e.target.selectedIndex ].text } })
          } else {
            let tmp = _.cloneDeep( filter )
            delete tmp.sports
            updateFilter( tmp )
          }
        }}>
        <option value="">{ Lang[ 'TYPE_OF_SPORT' ][ selectedLanguageShortname ] + " (" + Lang[ 'ALL' ][ selectedLanguageShortname ] + ")" }</option>
      </Input>
      {
        item.children?.map( child => {
          return(
            <CoreRenderFunction
              { ...props }
              item={ child }
            />
          )
        })
      }
    </div>
  )
}

export default TeamSearchBar