import React, { useEffect } from 'react'
import {
  Card, CardBody,
  Button
} from 'reactstrap'
import ReactTable from 'react-table'
import { compose } from 'redux'

import PageTitle from 'components/PageTitle'
import CreatePackage from './Create'
import UpdatePackage from './Update'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'
import WithPackages from './actions'
import WithModules from './actions/Modules'

const Package = props => {

  useEffect(() => {
    props.getPackages()
    props.getModules({
      is_paginated: false
    })
  }, [])

  return (
    <>
      <PageTitle
        heading={ Lang[ 'PACKAGES' ][ props.data.languageReducer.lang ] }
        icon="pe-7s-users icon-gradient bg-happy-fisher"
        buttons={[
          {
            buttonText: Lang[ 'CREATE' ][ props.data.languageReducer.lang ],
            onClick: () => {
              props.onChangePackagesHOC( 'showCreatePackage', true )
            },
            display: true
          }
        ]} />
      <Card>
        <CardBody>
          <ReactTable
            NoDataComponent={ () => <span></span> }
            data={ props.packages }
            columns={[
              {
                Header: Lang[ 'NAME' ][ props.data.languageReducer.lang ],
                accessor: 'name'
              },
              {
                Header: Lang[ 'MODULES' ][ props.data.languageReducer.lang ],
                Cell: ( row ) => {
                  return (
                    <ul>
                      {
                        row.original.modules && row.original.modules.map( item => {
                          return <li key={ item.id }>{ item.name }</li>
                        })
                      }
                    </ul>
                  )
                }
              },
              {
                Header: Lang[ 'ACTIONS' ][ props.data.languageReducer.lang ],
                Cell: ( row ) => (
                  <>
                    <Button 
                      color="primary"
                      className="btn-icon btn-icon-only"
                      onClick={() => {
                        props.getSelectedPackage( row.original.id )
                      }}>
                      <i className="pe-7s-pen"/>
                    </Button>
                  </>
                )
              }
            ]}
            showPagination={ false }
            sortable={ false }
            showFilters={ false } />
        </CardBody>
      </Card>
      <CreatePackage { ...props } />
      <UpdatePackage { ...props } />
      { props.onLoadPackages && <LoadingOverlay /> }
    </>
  )
}

export default compose(
  WithModules,
  WithPackages
)( Package )