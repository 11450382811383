import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import DatePicker, { registerLocale } from 'react-datepicker'
import el from "date-fns/locale/el"
import de from "date-fns/locale/de"
import nl from "date-fns/locale/nl"
import it from "date-fns/locale/it"

import Lang from 'Lang/General'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import "react-datepicker/dist/react-datepicker.css"

const ExportOnetimer = props => {
  const [ monthQuery, setMonthQuery ] = useState( new Date() )

  useEffect( () => {
    registerLocale( "el", el )
    registerLocale( "de", de )
    registerLocale( "nl", nl )
    registerLocale( "it", it )
  }, [] )

  return(
    <Modal
      size="sm"
      isOpen={ props.showExportOnetimer } >
      <ModalHeader
        toggle={ () => props.onChangeBookedCampaignHOC( 'showExportOnetimer', false ) }>
        { Lang[ 'EXPORT_ONETIMER' ][ props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        <DatePicker
          className='form-control'
          style={{ width: "fit-content" }}
          selected={ monthQuery }
          onChange={ date => setMonthQuery( date ) }
          dateFormat='MMMM yyyy'
          showMonthYearPicker 
        />
        { props.onLoadBookedCampaign && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary" 
          onClick={ () => props.getOnetimerBookedCampaignsExcel( monthQuery ) }>
          { Lang[ 'SUBMIT' ][ props.data.languageReducer.lang ] }
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ExportOnetimer
