export const headingPattern = [
  {
    value: "STYLE_A",
    content: [
      {
        type: "heading-content",
        style: {
          color: "#000",
          padding: "2px 0px",
          background: "none",
          textAlign: "center"
        }
      },
      {
        id: "",
        type: "div",
        style: {
          display: "flex",
          justifyContent: "center",
          marginBottom: "15px"
        },
        children: [
          {
            id: "",
            type: "div",
            style: {
              width: "50px",
              height: "5px",
              marginTop: "5px",
              background: "#92bd46",
              primaryColor: ["background"],
            },
            content: {
              de: "",
              en: "",
              it: "",
              nl: "",
            },
          },
          {
            id: "",
            type: "div",
            style: {
              width: "50px",
              height: "5px",
              background: "#92bd46",
              primaryColor: ["background"],
            },
            content: {
              de: "",
              en: "",
              it: "",
              nl: "",
            },
          },
        ],
      },
    ],
  },
  {
    value: "STYLE_B",
    content: [
      {
        type: "heading-content",
        style: {
          color: "#000",
          padding: "2px 0px",
          background: "none",
          textAlign: "unset"
        }
      },
      {
        id: "",
        type: "div",
        style: {
          width: "fit-content",
          display: "inline-block",
          marginBottom: "15px"
        },
        children: [
          {
            id: "",
            type: "div",
            style: {
              width: "120px",
              height: "2px",
              background: "#92bd46",
              primaryColor: ["background"],
            },
            content: {
              de: "",
              en: "",
              it: "",
              nl: "",
            },
          },
        ],
      },
    ],
  },
  {
    value: "STYLE_C",
    content: [
      {
        type: "heading-content",
        style: {
          color: "#fff",
          padding: "3px 5px",
          background: "#434343",
          secondaryColor: ["background"]
        },
      },
    ],
  },
]
