import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { Post, Get, Delete } from 'utils/axios'
import Lang from 'Lang/General'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state={ 
      loading: false,
      knowledgeFiles: [],
      showDeleteFileModal: false,

      createFileValue: null, 
      createFileTitle: ''
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( err => toast.error( err.replaceAll( '_', ' ' ) ) )

    onChangeFileHOC = ( key, value ) => this.setState({ [ key ] : value })
    
    createKnowledgeFile = ( knowledge_id, data ) => Post(
      `/api/v1/ark_member_area/knowledge_templates/${ knowledge_id }/files`,
      data,
      () => this.createKnowledgeFileSuccess( knowledge_id ),
      this.createKnowledgeFileError,
      this.load
    )
    createKnowledgeFileSuccess = knowledge_id => {
      this.requestSuccess( Lang[ 'FILE_IS_SUCCESSFULLY_CREATED' ][ this.props.data.languageReducer.lang ] )
      this.getKnowledgeFiles( knowledge_id )
      this.setState({
        createFileValue: null,
        createFileTitle: ''
      })
    }
    createKnowledgeFileError = error => this.requestError( error )

    getKnowledgeFiles = knowledge_id => Get(
      `/api/v1/ark_member_area/knowledge_templates/${ knowledge_id }/files`,
      this.getKnowledgeFilesSuccess,
      this.getKnowledgeFilesError,
      this.load
    )
    getKnowledgeFilesSuccess = payload => this.setState({ knowledgeFiles: payload })
    getKnowledgeFilesError = error => this.requestError( error )

    removeKnowledgeFiles = ( knowledge_id, file_id ) => Delete(
      `/api/v1/ark_member_area/knowledge_templates/${ knowledge_id }/files/${ file_id }`,
      () => this.removeKnowledgeFilesSuccess( knowledge_id ),
      this.removeKnowledgeFilesError,
      this.load
    )
    removeKnowledgeFilesSuccess = knowledge_id => {
      this.requestSuccess( Lang[ 'FILE_IS_SUCCESSFULLY_REMOVED' ][ this.props.data.languageReducer.lang ] )
      this.getKnowledgeFiles( knowledge_id )
      this.setState({ showDeleteFileModal: false })
    }
    removeKnowledgeFilesError = error => this.requestError( error )

    render = () => {
      return(
        <WrappedComponent
          { ...this.props } 
          onLoadFile={ this.state.loading }
          knowledgeFiles={ this.state.knowledgeFiles }
          showDeleteFileModal={ this.state.showDeleteFileModal }
          createFileValue={ this.state.createFileValue }
          createFileTitle={ this.state.createFileTitle }
          
          onChangeFileHOC={ this.onChangeFileHOC }
          createKnowledgeFile={ this.createKnowledgeFile }
          getKnowledgeFiles={ this.getKnowledgeFiles }
          removeKnowledgeFiles={ this.removeKnowledgeFiles }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC