import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { omitBy, isEmpty } from 'lodash'

import { Get, Put } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state = {
      loading: false,
      sslSettings: {},
      totalPages: [],
      sslSettingSearchParams: {
        domain: '',
        status: '',
        expires_in: '',
        expired_for: '0'
      }
    }

    load = param => this.setState({ loading: param })

    onChangeSslSettingHOC = ( key, val ) => this.setState({ [ key ]: val })

    getSslSettings = ( page, isReset ) => {
      let tmpSearchParams = {
        page: !isReset && page > 0 ? page  : 1
      }
      let filter = !isReset 
        ? omitBy( this.state.sslSettingSearchParams, isEmpty )
        : { expired_for: '0' }
      
      if( !isEmpty( filter ) ){
        tmpSearchParams.filter = filter
        if( filter.expires_in || filter.expired_for ){
          tmpSearchParams.filter.expires_in_number_of_days = {}
          if( filter.expires_in ){
            tmpSearchParams.filter.expires_in_number_of_days.$lte = parseInt( filter.expires_in )
          }
          if( filter.expired_for ){
            tmpSearchParams.filter.expires_in_number_of_days.$gte = parseInt( filter.expired_for ) * -1
          }
          delete tmpSearchParams.filter.expires_in
          delete tmpSearchParams.filter.expired_for
        }
        if( filter.domain ){
          tmpSearchParams.filter.domain = { $like: `%${ filter.domain }%` }
        }
      }
      isReset && this.setState({
        sslSettingSearchParams: {
          domain: '',
          status: '',
          expires_in: '',
          expired_for: '0'
        }
      })

      return Get(
        `/api/v1/monitoring/ssl_certs?query=${ convertObjToBase64( tmpSearchParams ) }`,
        this.getSslSettingsSuccess,
        this.getSslSettingsError,
        this.load
      ) 
    }
    getSslSettingsSuccess = payload => this.setState({ 
      sslSettings: payload,
      totalPages: payload.pages.meta.last_page !== 1
        ? Array.from( Array( payload.pages.meta.last_page + 1 ), ( _, index ) => index )
        : []
    })
    getSslSettingsError = () => toast.error( 'SSL certificates not obtained' )

    updateSslSettingListing = () => Put(
      '/api/v1/monitoring/update_ssl_certs',
      {},
      this.updateSslSettingListingSuccess,
      this.updateSslSettingListingError,
      this.load
    )
    updateSslSettingListingSuccess = async() => {
      await this.getSslSettings( 1, true )
      toast.success( 'Latest listing obtained' )
    }
    updateSslSettingListingError = () => toast.error( 'Latest listing not obtained' )

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          { ...this.state }
          onLoadSslSettingHOC={ this.state.loading }
          onChangeSslSettingHOC={ this.onChangeSslSettingHOC }
          getSslSettings={ this.getSslSettings }
          updateSslSettingListing={ this.updateSslSettingListing }
        />
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC