import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { FilePond, registerPlugin } from "react-filepond"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import FilePondPluginFileEncode from 'filepond-plugin-file-encode'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import CreatableSelect from "react-select/creatable"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
import "filepond/dist/filepond.min.css"
import {
  Form, Button, Container, Row, Label, Col, FormGroup, Input
} from 'reactstrap'

import Lang from 'Lang/General'

registerPlugin( FilePondPluginFileEncode, FilePondPluginImagePreview, FilePondPluginFileValidateType )

class MediaItem extends Component{
  state={
    arrayButton: [ 'de', 'en', 'es', 'it', 'fr', 'nl' ]
  }

  pond = ''

  handleInit = () => {}

  render = () => {
    let {
      index,
      selectedLanguage,
      updateSelectedLanguage,
      item,
      handleChangeImageName,
      handleSelectTag,
      mediaTags,
      testItems,
      onRemoveItem,
      updateType
    } = this.props
    return(
      <div
        style={{
          position: 'relative',
          padding: '30px',
          border: '0.5px solid #c2c2c2',
          marginTop: '25px',
        }}>
        <Button
          className="mb-2 mr-2 btn-icon btn-icon-only"
          style={{
            position: 'absolute',
            zIndex: 999,
            float: 'right',
            top: '-11px',
            right: '-12px',
            height: '36px',
            width: '36px',
            borderRadius: '50%',
            padding: 0
          }}
          color="danger"
          onClick={ () => {
            this.pond.removeFiles()
            onRemoveItem( index )
          }}>
            <i className="pe-7s-less btn-icon-wrapper" style={{ fontSize: '23px' }}> </i>
        </Button>
        <FilePond
          ref={ ref => this.pond = ref }
          value={ this.props.item.files }
          allowMultiple={ false }
          allowFileTypeValidation={ true }
          allowImagePreview={ true }
          acceptedFileTypes='image/*'
          oninit={ () => this.handleInit() }
          allowFileEncode={ true }
          labelIdle={ Lang[ 'FILEPOND_LABEL' ][ this.props.data.languageReducer.lang ] }
          onupdatefiles={ filesItems => {
            if( filesItems && filesItems.length > 0 && filesItems[ 0 ].filename ) {
              this.props.onUploadFiles( index, filesItems )
            }
          }}>
        </FilePond>
        <Container>
          <Row>
            <Col md={ 6 }>
              <div className="">
                <Label sm={ 4 }>{ Lang[ 'NAME' ][ this.props.data.languageReducer.lang ] }</Label>
                <div className="d-flex pt-1">
                  {
                    this.state.arrayButton.map( button => {
                      return(
                        <Button
                          color={ `${ _.find( selectedLanguage, { index: index, value: button } ) ? 'secondary' : 'light' }` }
                          onClick={ () => updateSelectedLanguage( index, button ) }>{ button }</Button>
                      )
                    })
                  }
                </div>
                <FormGroup>
                  <Input
                    value={ item.short_name[ selectedLanguage[index].value ] }
                    onChange={ e => {
                      handleChangeImageName( index, selectedLanguage[index].value, e.target.value )
                    }}/>
                </FormGroup>
                <FormGroup>
                  <Label for="language" sm={ 2 }>{ Lang[ 'TYPE' ][ this.props.data.languageReducer.lang ] }</Label>
                  <Input
                    type="select"
                    value={ item.type }
                    onChange={ e => updateType( index, e.target.value ) }>
                    <option value={ 'image' }>{ 'Image' }</option>
                    <option value={ 'slider' }>{ 'Slider' }</option>
                  </Input>
                </FormGroup>
              </div>
            </Col>
            <Col md={ 6 }>
              <Label>{ Lang[ 'MEDIA_TAGS' ][ this.props.data.languageReducer.lang ] }</Label>
              <CreatableSelect
                isMulti
                onChange={ ( newValue, actionMeta ) => handleSelectTag( index, newValue ) }
                options={ mediaTags }
                placeholder={ Lang[ 'SELECT' ][ this.props.data.languageReducer.lang ] }
                value={ testItems } />
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
const mapStateToProps= state => ({ data: state })

export default connect( mapStateToProps )( MediaItem )
