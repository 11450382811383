import React, { useEffect, useRef } from 'react'
import {
  Form, FormGroup, Label, Button
} from 'reactstrap'
import EmailEditor from 'react-email-editor'
import _ from 'lodash'

import PageTitle from 'components/PageTitle'
import LoadingModal from 'components/Indicator/LoadingOverlay'
import EmailTemplateHOC from './actions'

import Lang from 'Lang/General'

const EmailTemplate = props => {
  const emailEditorRef = useRef( '' )

  useEffect( () => {
    props.getEmailTemplate()
  }, [] )

  useEffect( () => {
    onLoad( props.emailTemplate.content_jsonb )
  }, [ props.emailTemplate.content_jsonb ] )

  const handleSecurityModulesUpdate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Email Template Editor' })
      return tmpModules.is_update
    }
  }

  const onLoad = ( content ) => {
    if( emailEditorRef && window.unlayer != undefined && !_.isEmpty( content ) ) {
      emailEditorRef.current.loadDesign( content )
    }
  }

  const saveDesign = async () => {
    let test = await emailEditorRef.current.saveDesign
    let tmp = _.cloneDeep( props.emailTemplate )
    await test( async design => {
      tmp.content_jsonb = design
      let test2 = await emailEditorRef.current.exportHtml
      await test2( html => {
        tmp.content_html = html.html
        props.updateEmailTemplate( tmp )
      })
    })
  }
  
  return (
    <>
      <PageTitle
        heading={ Lang[ 'EMAIL_TEMPLATE_EDITOR' ][ props.data.languageReducer.lang ] }
        icon="pe-7s-mail icon-gradient bg-happy-fisher" />
      <Form>
        <FormGroup>
          <Label className='mb-0' ><b>{ Lang[ 'EMAIL_TEMPLATE' ][ props.data.languageReducer.lang ] }</b></Label>
          <p style={{ fontSize: '13px' }}>{ Lang[ 'EMAIL_TEMPLATE_MSG' ][ props.data.languageReducer.lang ] }</p>
          <EmailEditor
            ref={ emailEditorRef }
            options={{
              locale: props.data.languageReducer.lang,
              features: {
                stockImages: {
                  enabled: false
                }
              },
              tools: {
                image: {
                  properties: {
                    src: {
                      value: {
                        url: 'https://via.placeholder.com/500x100?text=IMAGE',
                        width: 500,
                        height: 100
                      }
                    }
                  }
                },
              }
            }}
          />
        </FormGroup>
      </Form>
      <Button 
        color="primary" 
        style={{ float: 'right', marginTop: '20px' }} 
        disabled={ !handleSecurityModulesUpdate() }
        onClick={ () => saveDesign() }>
        { Lang[ 'UPDATE' ][ props.data.languageReducer.lang ] }
      </Button>
      { props.onLoadEmailTemplate && <LoadingModal /> }
    </>
  )
}

export default EmailTemplateHOC( EmailTemplate )