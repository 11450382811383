import React, { Component } from 'react'
import { Grid } from '@material-ui/core'
import Frame from 'react-frame-component'
import _ from 'lodash'

import LeftPanel from './LeftPanel'
import RightPanel from './RightPanel'
import RendererViewer from './View'

import './index.scss'

class Renderer extends Component {
  render = () => {
    return (
      <Grid
        item
        md={ 12 }
        classes={{ root: 'renderer-container' }}>
        <LeftPanel 
          updateMainContainerState={ this.props.updateMainContainerState }
          lang={ this.props.lang } />
        <Frame
          id={ 'editor-site-renderer' }
          style={{
            width: this.props.selectedViewportRenderer === 'desktop' ? '80vw' : '40vw',
            height: '85vh'
          }}
          initialContent={ '<!DOCTYPE html><html><head><link href="/bootstrap.css" rel="stylesheet"></link><link href="/image-block-styles.css" rel="stylesheet"></link></head><body style="overflow-y:scroll;overflow-x:hidden;"><div></div></body></html>' }>
          <RendererViewer
            onChangeSelectedPage={ this.props.onChangeSelectedPage }
            templateStylesheets={ this.props.templateStylesheets }
            templateJavascripts={ this.props.templateJavascripts }
            selectedPage={ this.props.selectedPage }
            updateMainContainerState={ this.props.updateMainContainerState }
            selectedSectionID={ this.props.selectedSectionID }
            languages={ this.props.languages }
            selectedLanguageShortname={ this.props.selectedLanguageShortname }
            templateHeaders={ this.props.templateHeaders }
            templateHeadersBlueprints={ this.props.templateHeadersBlueprints }
            templateFooters={ this.props.templateFooters }
            headerMenuContent={ this.props.headerMenuContent }
            templateFooterBlueprints={ this.props.templateFooterBlueprints }
            lang={ this.props.lang } 
            hoveredSectionID={ this.props.hoveredSectionID }/>
        </Frame>
        <RightPanel 
          updateMainContainerState={ this.props.updateMainContainerState }
          lang={ this.props.lang } />
      </Grid>
    )
  }
}

export default Renderer