import React from 'react'
import Radium from 'proofpoint-radium'

const Icon = ({
  id,
  item,
  mode,
  style,
  content,
  className,
  onChangeSectionEditorState,
  selectedEditableComponent
}) => {
  return (
    <i
      id={ id }
      style={{
        ...style,
        outline: selectedEditableComponent ? selectedEditableComponent.id === id ? '2px solid #007bff' : 'none' : 'none'
      }}
      className={ className }
      onClick={
        mode === 'edit' ? ( e ) => {
          onChangeSectionEditorState( 'selectedEditableComponent', item )
        } : () => {}
      }>
      { content }
    </i>
  )
}

export default Radium( Icon )