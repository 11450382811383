import React, { useState, useEffect } from 'react'
import { Form, FormGroup, Label, Input, Col } from 'reactstrap'
import ReactSelect from 'react-select'
import _ from 'lodash'

import MultiLangInput from 'components/Input/MultiLang'
import Lang from 'Lang/General'

const CategoryForm = ({
  categoryType,
  currentCategory,
  onChangeCategoriesHOC,
  lang,
  centerGroups
}) => {
  const [ centerGroupOptions, updateCenterGroupOptions ] = useState([])

  useEffect( () => {
    if( centerGroups.data && centerGroups.data.length > 0 ){
      let tmpCenterGroups = centerGroups.data.map( item => ({ id: item.id, label: item.name, value: item.name }) )
      updateCenterGroupOptions( tmpCenterGroups )
    }
  }, [ centerGroups ] )

  const updateCategory = ( key, val ) => {
    let tmp = _.cloneDeep( currentCategory )
    tmp[ key ] = val
    onChangeCategoriesHOC( categoryType, tmp )
  }

  return(
    <>
      <Form>
        <MultiLangInput
          label={ Lang[ 'NAME' ][ lang ] }
          value={ currentCategory.name }
          onChange={ val => updateCategory( 'name', val ) }
          selectedLanguage={ lang } />
        <FormGroup>
          <Label>{ Lang[ 'TYPE' ][ lang ] }</Label>
          <Input
            type='select'
            value={ currentCategory.type }
            onChange={ e => updateCategory( 'type', e.target.value ) } >
            <option></option>
            <option value='TIP'>{ Lang[ 'TIPS_OF_THE_WEEK' ][ lang ] }</option>
            <option value='KNOWLEDGE'>{ Lang[ 'KNOWLEDGE' ][ lang ] }</option>
          </Input>
        </FormGroup>
        {
          currentCategory.type === 'TIP' && <FormGroup>
            <Label>{ Lang[ 'CENTER_GROUP' ][ lang ] }</Label>
            <Col sm={ 12 } className='pl-0 pr-0' style={{ zIndex: 998 }}>
              <ReactSelect
                isMulti={ false }
                name="colors"
                options={ centerGroupOptions }
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder={ Lang[ 'SELECT_PLACEHOLDER' ][ lang ] }
                onChange={ ( value, action ) => updateCategory( 'center_group_id', value.id || null ) }
                value={ currentCategory.center_group_id 
                  ? ( _.find( centerGroupOptions, { id: currentCategory.center_group_id } ) || {} )
                  : {}
                } />
            </Col>
          </FormGroup>
        }
        <MultiLangInput
          label={ Lang[ 'DESCRIPTION' ][ lang ] }
          value={ currentCategory.description }
          onChange={ val => updateCategory( 'description', val ) }
          selectedLanguage={ lang } />
      </Form>
    </>
  )
}

export default CategoryForm