import React, { useRef, useEffect } from 'react'
import _ from 'lodash'
import {
  Form, FormGroup, 
  Label, Input, CustomInput, Button,
  Modal, ModalBody, ModalHeader, ModalFooter
} from 'reactstrap'
import { connect } from 'react-redux'
import EmailEditor from 'react-email-editor'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Lang from 'Lang/General'

const CreateMailing = ( props ) => {
  const editor = useRef(null)

  useEffect( () => {
    props.onChangeSystemMailingHOC( 'mailingErrMsg', {} )
  }, [props.showCreateMailing] )

  const saveDesign = async () => {
    let test = await editor.current.saveDesign
    let tmp = _.cloneDeep( props.newMailingData )
    await test( async design => {
      tmp.json_content = design
      let test2 = await editor.current.exportHtml
      await test2( html => {
        tmp.content = html.html
        props.createSystemMailing( tmp )
      })
    })
  }

  const onChange = ( key, val ) => {
    let tmp = _.cloneDeep( props.newMailingData )
    tmp[key] = val

    props.onChangeSystemMailingHOC( 'newMailingData', tmp )
  }

  return(
    <Modal
      isOpen={ props.showCreateMailing }
      size="xl">
      <ModalHeader toggle={ () => props.onChangeSystemMailingHOC('showCreateMailing', false ) }>{ Lang['CREATE_NEW_MAILING'][ props.selectedLanguage ] }
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>{ Lang[ 'TITLE' ][ props.selectedLanguage ] }<span style={{ color: 'red'}}>*</span></Label>
            <Input
              type="text"
              style={{ width: '40%'}}
              value={ props.newMailingData.title }
              onChange={ e => onChange('title', e.target.value ) } />
            {
              props.mailingErrMsg.title && (
                <span style={{ color: 'red', marginBottom: '15px' }}>{ props.mailingErrMsg.title }</span>
              )
            }
          </FormGroup>
          <FormGroup>
            <Label>{ Lang[ 'SUBJECT' ][ props.selectedLanguage ] }<span style={{ color: 'red'}}>*</span></Label>
            <Input
              type="text"
              style={{ width: '40%'}}
              value={ props.newMailingData.subject }
              onChange={ e => onChange('subject', e.target.value ) } />
            {
              props.mailingErrMsg.subject && (
                <span style={{ color: 'red', marginBottom: '15px' }}>{ props.mailingErrMsg.subject }</span>
              )
            }
          </FormGroup>
          <FormGroup>
            <Label>{ Lang[ 'STATUS' ][ props.selectedLanguage ] }<span style={{ color: 'red'}}>*</span></Label>
            <Input
              type="select"
              style={{ width: '40%'}}
              value={ props.newMailingData.status }
              onChange={ e => onChange( 'status', e.target.value ) } >
              <option value='ACTIVE' >{ Lang[ 'ACTIVE' ][ props.selectedLanguage ] }</option>
              <option value='INACTIVE' >{ Lang[ 'INACTIVE' ][ props.selectedLanguage ] }</option>
              <option value='ARCHIVED' >{ Lang[ 'ARCHIVED' ][ props.selectedLanguage ] }</option>
            </Input>
          </FormGroup>
          <FormGroup className="mt-5">
            <EmailEditor
              ref={ editor }
              options={{
                locale: props.selectedLanguage
              }}
              // onLoad={ () => this.onLoad( Template2JSON ) }
            />
          </FormGroup>
        </Form>
        { props.onLoadSystemMailings && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        <Button className="mb-2 mr-2" color="primary" onClick={ () => saveDesign() }>{ Lang[ 'SAVE_DESIGN' ][ props.selectedLanguage ] }</Button>
      </ModalFooter>
    </Modal>

  )
}
const mapStateToProps = state => ({ dataReducer: state })

export default connect( mapStateToProps )( CreateMailing )
