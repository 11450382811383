import React from 'react'
import { Button, FormGroup, Label, Input } from "reactstrap"
import { FaPencilAlt } from 'react-icons/fa'
import { connect } from "react-redux"
import _ from "lodash"

import { toggleMediaDatabase } from "actions/MediaDatabaseToggle"
import MediaDatabase from "SharedModules/MediaDatabase"
import Lang from "Lang/General"

const Mediathek = (props) => {

  const updateMedia = (key, value) => {
    let tmpComp = _.cloneDeep(props.selectedComponent);

    switch (key) {
      case "source":
        tmpComp[key] = value
        props.toggleMediaDatabase(false)
        break
      case "video":
        tmpComp[key].source = value
        break
    }

    props.updateSelectedComponent( tmpComp )
    let tmpColumn = _.cloneDeep( props.selectedCol )
    tmpColumn = props.FindComponentAndPatch( tmpColumn, tmpComp )
    props.updateSelectedCol( tmpColumn )
    let tmpContainer = _.cloneDeep( props.dom )
    tmpContainer = props.FindComponentAndPatch( tmpContainer, tmpColumn )
    props.updateDom( tmpContainer )
  }

  return (
    <>
      <FormGroup>
        <Label className="w-100">{Lang["IMAGE"][props.selectedLanguage]}</Label>
        <div className='d-flex flex-row flex-wrap'>
          <Button
            className='mr-2 d-flex justify-content-center align-items-center'
            color="primary"
            onClick={ () => props.toggleMediaDatabase(true) } >
            <FaPencilAlt className='mr-2' />
            { Lang[ 'SELECT_IMAGE' ][ props.selectedLanguage ] }
          </Button>
          <Button
            color={ props.selectedComponent.source && !_.isEmpty( props.selectedComponent.source )
              ? 'primary'
              : 'success'
            }
            onClick={ () => updateMedia( 'source', '' ) } >
            {  
              props.selectedComponent.source && !_.isEmpty( props.selectedComponent.source )
                ? Lang[ 'APPLY_DEFAULT_THUMBNAIL' ][ props.selectedLanguage ]
                : Lang[ 'DEFAULT_THUMBNAIL_APPLIED' ][ props.selectedLanguage ]
            }
          </Button>
        </div>
      </FormGroup>
      <MediaDatabase
        onSelectedImage={(param) => updateMedia("source", param)}
        selectedLanguage={props.selectedLanguage}
        allowed={["jpg", "jpeg", "png"]}
      />
      <FormGroup>
        <Label>{Lang["VIDEO"][props.selectedLanguage]}</Label>
        <Input
          type="text"
          value={
            props.selectedComponent.video.source
              ? props.selectedComponent.video.source
              : ""
          }
          onChange={(e) => {
            updateMedia("video", e.target.value);
          }}
        />
      </FormGroup>
    </>
  )
}

const mapStateToProps = (state) => ({ data: state })

export default connect(mapStateToProps, {
  toggleMediaDatabase,
})(Mediathek)
