import React, { Fragment } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { connect } from 'react-redux'
import {
  Container, Card, CardBody, FormGroup, Form,
  Label, Col, Input, FormFeedback
} from 'reactstrap'
import MultiLangInput from 'components/Input/MultiLang'

import Lang from 'Lang/General'

const CategoryForm = ({
  onChangeCategoryData,
  data,
  dataReducer,
  errorMessage
}) => {
  return(
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}>
        <Container>
          <Card>
            <CardBody>
              <Form>
                <FormGroup row>
                  <Label for="name" sm={ 2 }>
                    { Lang[ 'NAME' ][ dataReducer.languageReducer.lang ] }
                    <span className="text-danger">*</span>
                  </Label>
                  <Col sm={ 10 }>
                    <MultiLangInput
                      value={ data.name }
                      onChange={ val => onChangeCategoryData( 'name', val ) }
                      selectedLanguage={ dataReducer.languageReducer.lang }
                      invalid={ errorMessage.name } />
                    <FormFeedback>{ errorMessage.name ? errorMessage.name : '' }</FormFeedback>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="description" sm={ 2 }>
                    { Lang[ 'DESCRIPTION' ][ dataReducer.languageReducer.lang ] }
                    <span className="text-danger">*</span>
                  </Label>
                  <Col sm={ 10 }>
                    <MultiLangInput
                      value={ data.description }
                      onChange={ val => onChangeCategoryData( 'description', val ) }
                      selectedLanguage={ dataReducer.languageReducer.lang }
                      invalid={ errorMessage.description } />
                    <FormFeedback>{ errorMessage.description ? errorMessage.description : '' }</FormFeedback>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="active" sm={ 2 }>{ Lang[ 'ACTIVE' ][ dataReducer.languageReducer.lang ] }</Label>
                  <Col sm={ 10 }>
                    <Label className='ml-3'>
                      <Input
                        type="checkbox"
                        checked={ data.status === "ACTIVE" }
                        onChange={ e => onChangeCategoryData( 'status', e.target.checked ? "ACTIVE" : "INACTIVE" ) }/>
                      { Lang[ 'ACTIVE' ][ dataReducer.languageReducer.lang ] }
                    </Label>
                  </Col>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}
const mapStateToProps = state => ({ dataReducer: state })

export default connect( mapStateToProps )( CategoryForm )
