export default [
  { 
    roomName: {
      en: 'All rooms',
      de: 'Alle Räume',
      nl: 'Alle kamers',
      it: 'Tutte le camere'
    } 
  },
  { 
    roomName: {
      en: 'AEROBIC SPACE I',
      de: 'AEROBER RAUM I',
      nl: 'AËROBE RUIMTE I',
      it: 'SPAZIO AEROBICO I'
    } 
  },
  { 
    roomName: {
      en: 'AEROBIC SPACE II',
      de: 'AEROBER RAUM II',
      nl: 'AËROBE RUIMTE II',
      it: 'SPAZIO AEROBICO II'
    } 
  },
  { 
    roomName: {
      en: 'FT CLUB',
      de: 'FT-CLUB',
      nl: 'FT CLUB',
      it: 'FT CLUB'
    } 
  },
  { 
    roomName: {
      en: 'Indoor cycling',
      de: 'Indoor-Radfahren',
      nl: 'Binnenshuis fietsen',
      it: 'Ciclismo al coperto'
    } 
  },
  { 
    roomName: {
      en: 'POWER PLATE',
      de: 'KRAFTSTELLE',
      nl: 'KRACHTSPLAAT',
      it: 'PIASTRA DI POTENZA'
    } 
  },
  { 
    roomName: {
      en: 'swimming pool',
      de: 'Schwimmbad',
      nl: 'zwembad',
      it: 'piscina'
    } 
  },
]