import React, { Component } from 'react'
import Radium from 'proofpoint-radium'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get, Put } from 'utils/axios-template'
import { production_url } from '../../config'

import Lang from 'Lang/General'

const SectionHOC = ( WrappedComponent ) => {
  class SectionWrappedComponent extends Component {
    state = {
      selectedSection: {},
      selectedHeaderSection: {},
      loading: false,
      showStatusModal: false,
      statusModalType: '',
      onClickStatusModalButton: () => {},
      statusModalMessage: ''
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })
    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    onChangeSectionHOC = ( key, val ) => this.setState({ [ key ]: val })

    findHeaderCarousel = item => {
      if( item && item.type && item.type === 'carousel' ){
        this.setState({ selectedSection: _.cloneDeep( item )})
      } else if( item && item.children && item.children.length > 0 ) {
        item.children.map( child => this.findHeaderCarousel( child ) )
      }
    }

    getSelectedSection = id => Get(
      `${ production_url }/sections/${ id }`,
      this.getSelectedSectionSuccess,
      this.getSelectedSectionError,
      this.load
    )
    getSelectedSectionSuccess = payload => {
      if( payload.content.variant === "header-section" ){
        this.setState({ selectedHeaderSection: payload.content })
        this.findHeaderCarousel( payload.content )
      } else {
        this.setState({ selectedSection: payload.content, selectedHeaderSection:{} })
      }
    }
    getSelectedSectionError = error => this.requestError( error )

    updateSelectedSection = data => Put(
      `${ production_url }/sections/${ data.id }`,
      data,
      () => this.updateSelectedSectionSuccess( data.id ),
      this.updateSelectedSectionError,
      this.load
    )
    updateSelectedSectionSuccess = id => {
      this.requestSuccess( Lang[ 'SECTION_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getSelectedSection( id )
      window.location.reload()
    }
    updateSelectedSectionError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadSection={ this.state.loading }
          getSelectedSection={ this.getSelectedSection }
          updateSelectedSection={ this.updateSelectedSection }
          onChangeSectionHOC={ this.onChangeSectionHOC }
          selectedSection={ this.state.selectedSection }
          selectedHeaderSection={ this.state.selectedHeaderSection }/>
      )
    }
  }
  return Radium( SectionWrappedComponent )
}

export default SectionHOC