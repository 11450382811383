import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { Post, Get, Put, Delete } from 'utils/axios'
import Lang from 'Lang/General'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state={ 
      loading: false,
      memberStatuses: [],
      newMemberStatus: {},
      selectedMemberStatus: {},
      showCreateMemberStatus: false,
      showUpdateMemberStatus: false,
      showDeleteMemberStatusId: 0
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( err => toast.error( err.replaceAll( '_', ' ' ) ) )

    onChangeMemberStatusesHOC = ( key, value ) => this.setState({ [ key ] : value })

    createMemberStatus = data => Post(
      `/api/v1/ark_member_area/ark_member_user_status`,
      data,
      this.createMemberStatusSuccess,
      this.createMemberStatusError,
      this.load
    )
    createMemberStatusSuccess = () => {
      this.getMemberStatuses()
      this.requestSuccess( Lang[ 'MEMBER_STATUS_IS_SUCCESSFULLY_CREATED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showCreateMemberStatus: false })
    }
    createMemberStatusError = error => {
      this.requestError( error )
      this.setState({ showCreateMemberStatus: false })
    }

    getMemberStatuses = () => Get(
      `/api/v1/ark_member_area/ark_member_user_status`,
      this.getMemberStatusesSuccess,
      this.getMemberStatusesError,
      this.load
    )
    getMemberStatusesSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      this.setState({ memberStatuses: payload, memberStatusesPages: tmpTotalPages })
    }
    getMemberStatusesError = error => this.requestError( error )

    getSelectedMemberStatus = memberStatus_id => Get(
      `/api/v1/ark_member_area/ark_member_user_status/${ memberStatus_id }`,
      this.getSelectedMemberStatusSuccess,
      this.getSelectedMemberStatusError,
      this.load
    )
    getSelectedMemberStatusSuccess = payload => this.setState({ selectedMemberStatus: payload, showUpdateMemberStatus: true })
    getSelectedMemberStatusError = error => this.requestError( error )

    updateMemberStatus = data => Put(
      `/api/v1/ark_member_area/ark_member_user_status/${ data.id }`,
      data,
      this.updateMemberStatusSuccess,
      this.updateMemberStatusError,
      this.load
    )
    updateMemberStatusSuccess = () => {
      this.getMemberStatuses()
      this.requestSuccess( Lang[ 'MEMBER_STATUS_IS_SUCCESSFULLY_UPDATED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showUpdateMemberStatus: false })
    }
    updateMemberStatusError = error => this.requestError( error )

    removeMemberStatus = memberStatus_id => Delete(
      `/api/v1/ark_member_area/ark_member_user_status/${ memberStatus_id }`,
      this.removeMemberStatusSuccess,
      this.removeMemberStatusError,
      this.load
    )
    removeMemberStatusSuccess = () => {
      this.getMemberStatuses()
      this.requestSuccess( Lang[ 'MEMBER_STATUS_IS_SUCCESSFULLY_REMOVED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showDeleteMemberStatusId: 0 })
    }
    removeMemberStatusError = error => {
      this.requestError( error )
      this.setState({ showDeleteMemberStatusId: 0 })
    }

    render = () => {
      return(
        <WrappedComponent
          { ...this.props } 
          onLoadMemberStatusesHOC={ this.state.loading }
          memberStatuses={ this.state.memberStatuses }
          newMemberStatus={ this.state.newMemberStatus }
          selectedMemberStatus={ this.state.selectedMemberStatus }
          showCreateMemberStatus={ this.state.showCreateMemberStatus }
          showUpdateMemberStatus={ this.state.showUpdateMemberStatus }
          showDeleteMemberStatusId={ this.state.showDeleteMemberStatusId }
          onChangeMemberStatusesHOC={ this.onChangeMemberStatusesHOC }
          createMemberStatus={ this.createMemberStatus }
          getMemberStatuses={ this.getMemberStatuses }
          getSelectedMemberStatus={ this.getSelectedMemberStatus }
          updateMemberStatus={ this.updateMemberStatus }
          removeMemberStatus={ this.removeMemberStatus }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC