import React, { useState } from "react"
import Radium from "proofpoint-radium"
import ReactHTMLParser from "react-html-parser"
import { IoIosPlayCircle } from "react-icons/io"

import Lang from "Lang/General"

const Mediathek = (props) => {
  const [showVideo, setShowVideo] = useState(false)
  const [onHover, setHover] = useState(false)
  return (
    <div
      style={{
        border:
          ( props.mode === "edit" &&
            props.selectedEditableComponent &&
            props.selectedEditableComponent.id === props.id ) ||
          ( props.mode === "edit" && 
            props.hoveredComponentId === props.id )
            ? "5px solid #28a745"
            : "none"
      }}
      onMouseEnter={() => {
        setHover(true)
        props.mode === "edit" &&
          props.onChangeSectionEditorState &&
          props.onChangeSectionEditorState("hoveredComponentId", props.id)
      }}
      onMouseLeave={() => {
        setHover(false)
        props.mode === "edit" &&
          props.onChangeSectionEditorState &&
          props.onChangeSectionEditorState("hoveredComponentId", "")
      }}
      onClick={() => {
        if (props.mode === "edit") {
          props.onChangeSectionEditorState &&
            props.onChangeSectionEditorState(
              "selectedEditableComponent",
              props.item
            )
        }
      }}
    >
      {
        props.content && (
          <div>
            { ReactHTMLParser( props.content[ props.selectedLanguageShortname ])}
          </div>
        )
      }
      <div
        style={props.style}
        className={props.className ? props.className : ""}
      >
        <div style={{ width: "100%", height: 0, paddingBottom: "56.25%" }}>
          {
            showVideo && props.mode !== "edit" ? (
              <iframe
                id={props.item.video.id}
                src={props.item.video.source + "?autoplay=1"}
                style={{
                  ...props.item.video.style,
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: 0,
                  left: 0,
                }}
                width="100%"
                height="100%"
                allow="fullscreen"
              />
            ) : (
              <>
                { onHover ? (
                  <div
                    style={{
                      position: "absolute",
                      top: "10%",
                      left: "5%",
                      right: "5%",
                      bottom: "10%",
                      width: "90%",
                      padding: "10px",
                      background: "rgba(0,0,0,0.5)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      zIndex: "1"
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "0.7rem",
                        color: "#fff",
                        "@media(max-width:992px)": { fontSize: "0.5rem" },
                        "@media(max-width:768px)": { fontSize: "1rem" }
                      }}
                    >
                      { Lang[ "ACTIVATE_MEDIA_MESSAGE" ][ props.selectedLanguageShortname ]}
                    </p>
                    <a
                      className="btn btn-primary"
                      style={{
                        cursor: "pointer",
                        color: "#fff",
                        fontSize: "0.8rem",
                        "@media(max-width:992px)": {
                          fontSize: "0.5rem",
                          padding: "0.2rem"
                        },
                        "@media(max-width:768px)": {
                          fontSize: "1rem",
                          padding: "0.5rem"
                        }
                      }}
                      onClick={ () => setShowVideo( true )}
                    >
                      { Lang[ "PLAY_VIDEO" ][ props.selectedLanguageShortname ] }
                    </a>
                  </div>
                ) : (
                  <div
                    style={{
                      position: "absolute",
                      top: "15%",
                      left: "5%",
                      right: "5%",
                      bottom: "15%",
                      width: "90%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      zIndex: "1",
                      fontSize: "10vw",
                      "@media(max-width:768px)": { fontSize: "15vw" }
                    }}
                  >
                    <IoIosPlayCircle style={{ color:"#ff0000" }} />
                  </div>
                )}
                <img
                  src={ 
                    props.item.source 
                      ? props.item.source 
                      : `https://img.youtube.com/vi/${ props.item.video.source.split( '/' )[ props.item.video.source.split( '/' ).length - 1 ] }/hqdefault.jpg`
                  }
                  style={{
                    position: "absolute",
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                    top: 0,
                    left: 0
                  }}
                />
              </>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default Radium(Mediathek)
