import React, { Component } from 'react'
import { compose } from 'redux'
import {
  Button, Modal, ModalHeader,
  ModalBody, ModalFooter,
  Table, UncontrolledTooltip
} from 'reactstrap'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PageTitle from 'components/PageTitle'

import ClientsHOC from 'actions/client'
import CountryHOC from 'actions/country'

import Lang from 'Lang/General'

import ARKTable from 'components/Table'
import ClientForm from './components/form'
import DeleteConfirmation from 'components/Modal/delete'

class ClientManagement extends Component {
  state = {
    searchColumn: [
      {
        name: Lang[ 'NAME' ][ this.props.data.languageReducer.lang ],
        type: 'text',
        val: 'company_name'
      },
      {
        name: Lang[ 'POSTCODE' ][ this.props.data.languageReducer.lang ],
        type: 'text',
        val: 'postcode'
      },
      {
        name: Lang[ 'CITY' ][ this.props.data.languageReducer.lang ],
        type: 'text',
        val: 'city'
      },
      {
        name: Lang[ 'COUNTRY' ][ this.props.data.languageReducer.lang ],
        type: 'text',
        val: 'country'
      },
      {
        name: Lang[ 'CLIENT_NUMBER' ][ this.props.data.languageReducer.lang ],
        type: 'text',
        val: 'client_number'
      },
      {
        name: Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ],
        type: 'dropdown',
        val: 'status'
      }
    ],
    archivedSearchColumn: [
      {
        name: Lang[ 'NAME' ][ this.props.data.languageReducer.lang ],
        type: 'text',
        val: 'company_name'
      },
      {
        name: Lang[ 'POSTCODE' ][ this.props.data.languageReducer.lang ],
        type: 'text',
        val: 'postcode'
      },
      {
        name: Lang[ 'CITY' ][ this.props.data.languageReducer.lang ],
        type: 'text',
        val: 'city'
      },
      {
        name: Lang[ 'COUNTRY' ][ this.props.data.languageReducer.lang ],
        type: 'text',
        val: 'country'
      }
    ]
  }

  componentDidMount = () => {
    let tmp = {
      page: 1,
      is_paginated: true,
    }
    this.props.getClients( tmp )
    this.props.getCountries()
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Client' })
      return tmpModules.is_create
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Client' })
      return tmpModules.is_update
    }
  }

  handleSecurityModulesDelete = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Client' })
      return tmpModules.is_delete
    }
  }

  renderClientDetail = () => {
    return(
      <span className="d-inline-block mb-2 mr-2">
        <Modal isOpen={ this.props.showViewClientModal } size="lg">
          <ModalHeader toggle={ () => this.props.onChangeClientHOC( 'showViewClientModal', false ) }>{ Lang[ 'CLIENT_DETAILS' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
          <ModalBody>
            <div className="scroll-area-lg">
              <Table className="mb-0" bordered>
                <tbody>
                  <tr>
                    <th scope="row">{ Lang[ 'NAME' ][ this.props.data.languageReducer.lang ] }</th>
                    <td>{ this.props.selectedClient.company_name }</td>
                  </tr>
                  <tr>
                    <th scope="row">{ Lang[ 'SALUTATION' ][ this.props.data.languageReducer.lang ] }</th>
                    <td>{ this.props.selectedClient.salutation }</td>
                  </tr>
                  <tr>
                    <th scope="row">{ Lang[ 'FIRST_NAME' ][ this.props.data.languageReducer.lang ] }</th>
                    <td>{ this.props.selectedClient.user && this.props.selectedClient.user.first_name }</td>
                  </tr>
                  <tr>
                    <th scope="row">{ Lang[ 'LAST_NAME' ][ this.props.data.languageReducer.lang ] }</th>
                    <td>{ this.props.selectedClient.user && this.props.selectedClient.user.last_name }</td>
                  </tr>
                  <tr>
                    <th scope="row">{ Lang[ 'EMAIL' ][ this.props.data.languageReducer.lang ] }</th>
                    <td>{ this.props.selectedClient.user && this.props.selectedClient.user.email }</td>
                  </tr>
                  <tr>
                    <th scope="row">House { Lang[ 'HOUSE_NUMBER' ][ this.props.data.languageReducer.lang ] }</th>
                    <td>{ this.props.selectedClient.house_number }</td>
                  </tr>
                  <tr>
                    <th scope="row">{ Lang[ 'STREET' ][ this.props.data.languageReducer.lang ] }</th>
                    <td>{ this.props.selectedClient.street }</td>
                  </tr>
                  <tr>
                    <th scope="row">{ Lang[ 'POSTCODE' ][ this.props.data.languageReducer.lang ] }</th>
                    <td>{ this.props.selectedClient.postcode }</td>
                  </tr>
                  <tr>
                    <th scope="row">{ Lang[ 'CITY' ][ this.props.data.languageReducer.lang ] }</th>
                    <td>{ this.props.selectedClient.city }</td>
                  </tr>
                  <tr>
                    <th scope="row">{ Lang[ 'STATE' ][ this.props.data.languageReducer.lang ] }</th>
                    <td>{ this.props.selectedClient.state }</td>
                  </tr>
                  <tr>
                    <th scope="row">{ Lang[ 'COUNTRY' ][ this.props.data.languageReducer.lang ] }</th>
                    <td>{ this.props.selectedClient.country }</td>
                  </tr>
                  <tr>
                    <th scope="row">{ Lang[ 'PHONE_NUMBER' ][ this.props.data.languageReducer.lang ] }</th>
                    <td>{ this.props.selectedClient.phone_number }</td>
                  </tr>
                  <tr>
                    <th scope="row">{ Lang[ 'FAX_NUMBER' ][ this.props.data.languageReducer.lang ] }</th>
                    <td>{ this.props.selectedClient.fax_number !== '' ? this.props.selectedClient.fax_number : '-' }</td>
                  </tr>
                  <tr>
                    <th scope="row">{ Lang[ 'CLIENT_NUMBER' ][ this.props.data.languageReducer.lang ] }</th>
                    <td>{ this.props.selectedClient.client_number !== '' ? this.props.selectedClient.client_number : '-' }</td>
                  </tr>
                  <tr>
                    <th scope="row">{ Lang[ 'EMAIL_FOR_INVOICE' ][ this.props.data.languageReducer.lang ] }</th>
                    <td>{ this.props.selectedClient.email !== '' ? this.props.selectedClient.email : '-' }</td>
                  </tr>
                  <tr>
                    <th scope="row">{ Lang[ 'TAX_NUMBER' ][ this.props.data.languageReducer.lang ] }</th>
                    <td>{ this.props.selectedClient.tax_number !== '' ? this.props.selectedClient.tax_number : '-' }</td>
                  </tr>
                  <tr>
                    <th scope="row">{ Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ] }</th>
                    <td>{ this.props.selectedClient.status }</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={ () => this.props.onChangeClientHOC( 'showViewClientModal', false ) }>OK</Button>
            {
              this.handleSecurityModulesUpdate() && (
                <Button
                  color="alternate"
                  onClick={ () => {
                    this.props.onChangeClientHOC( 'showViewClientModal', false )
                    this.props.onChangeClientHOC( 'showEditClientModal', true )
                  }}>{ Lang[ 'EDIT' ][ this.props.data.languageReducer.lang ] }</Button>
              )
            }
          </ModalFooter>
        </Modal>
      </span>
    )
  }

  renderNewForm = () => {
    return(
      <Modal
        isOpen={ this.props.showNewClientModal }
        size="xl">
        <ModalHeader toggle={ () => this.props.onChangeClientHOC( 'showNewClientModal', false ) }>{ Lang[ 'NEW_CLIENT' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <ClientForm
            data={ this.props.newClientData }
            countries={ this.props.countries }
            getStates={ this.props.getStates }
            states={ this.props.states }
            onChangeHOC={ this.props.onChangeClientHOC }
            onLoadCountries={ this.props.onLoadCountries }
            statusValues={ this.props.data.dictionaryReducer.dictionary.status }
            errorMessage={ this.props.errorMessage }
            mode="create"/>
          <Button className="mb-2 mr-2" color="info" onClick={ () => this.props.postClients() } >{ Lang[ 'CREATE' ][ this.props.data.languageReducer.lang ] }</Button>
          { this.props.onLoadClients && <LoadingOverlay /> }
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.onChangeClientHOC( 'showNewClientModal', false ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderArchivedClient = () => {
    return(
      <Modal
        isOpen={ this.props.showArchivedClientModal }
        size="xl">
        <ModalHeader toggle={ () => this.props.onChangeClientHOC( 'showArchivedClientModal', false ) }>{ Lang[ 'ARCHIVED_CLIENT_LIST' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <ARKTable
            data={ this.props.archivedClients.data }
            columns={[
              {
                Header: Lang[ 'NAME' ][ this.props.data.languageReducer.lang ],
                accessor: "company_name"
              },
              {
                Header: Lang[ 'POSTCODE' ][ this.props.data.languageReducer.lang ],
                accessor: "postcode"
              },
              {
                Header: Lang[ 'CITY' ][ this.props.data.languageReducer.lang ],
                accessor: "city"
              },
              {
                Header: Lang[ 'COUNTRY' ][ this.props.data.languageReducer.lang ],
                accessor: "country"
              },
              {
                Header: Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ],
                accessor: "status",
                Cell: ({ value }) => Lang[ value ][ this.props.data.languageReducer.lang ]
              },
              {
                Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
                accessor: "id",
                show: this.handleSecurityModulesUpdate(),
                Cell: ({value}) => (
                  <div>
                    <Button
                      id={ `Client-${ value }-unarchive-client` }
                      className="mb-2 mr-2 btn-icon btn-icon-only"
                      color="danger"
                      onClick={ () => {
                        this.props.onChangeClientHOC( 'confirmUnarchiveModal', true )
                        this.props.onChangeClientHOC( 'unArchiveClientID', value )
                      } }>
                      <i className="pe-7s-menu"> </i>
                    </Button>
                    <UncontrolledTooltip
                      target={ `Client-${ value }-unarchive-client` }
                      placement="top">{ Lang[ 'UNARCHIVE_CLIENT' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                  </div>
                )
              }
            ]}
            totalPages={ this.props.archivedClientTotalPage }
            page={ this.props.archivedClients.meta ? this.props.archivedClients.meta.current_page : 1 }
            onChangePage={ pg => {
              let tmp = { page: pg, is_paginated: true }
              return this.props.getArchivedClient( tmp )
            }}
            onClickReset={ () => {
              let tmp = {
                page: this.props.archivedClients.meta ? this.props.archivedClients.meta.current_page : 1,
                is_paginated: true
              }
              this.props.getArchivedClient( tmp )
            }}
            onSearch={ this.props.getArchivedClient }
            searchLabel={ Lang[ 'SEARCH_WITH_NAME' ][ this.props.data.languageReducer.lang ] }
            searchComponent={ true }
            searchColumn={ this.state.archivedSearchColumn }
            showPagination={ false } />
            {
              this.props.confirmUnarchiveModal && (
                <DeleteConfirmation
                  handleModalClose={ () => this.props.onChangeClientHOC( 'confirmUnarchiveModal', false ) }
                  deleteOpen={ this.props.confirmUnarchiveModal }
                  confirmAction={ () => {
                    this.props.activateClient( this.props.unArchiveClientID )
                    this.props.onChangeClientHOC( 'confirmUnarchiveModal', false )
                  }}
                  content={ Lang[ 'DELETE_CONFIRMATION_ARCHIVED_CLIENT' ][ this.props.data.languageReducer.lang ] }
                  lang={ this.props.data.languageReducer.lang } />
              )
            }
          { this.props.onLoadClients && <LoadingOverlay/> }
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.onChangeClientHOC( 'showArchivedClientModal', false ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderEditClientForm = () => {
    return(
      <Modal
        isOpen={ this.props.showEditClientModal }
        size="xl">
        <ModalHeader toggle={ () => this.props.onChangeClientHOC( 'showEditClientModal', false ) }>{ Lang[ 'EDIT_CLIENT' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <ClientForm
            data={ this.props.selectedClient }
            countries={ this.props.countries }
            getStates={ this.props.getStates }
            states={ this.props.states }
            onChangeHOC={ this.props.onChangeClientHOC }
            onLoadCountries={ this.props.onLoadCountries }
            statusValues={ this.props.data.dictionaryReducer.dictionary.status }
            errorMessage={ this.props.errorMessage }
            mode="edit"/>
          <Button 
            className="mb-2 mr-2" 
            color="info" 
            onClick={ () => this.props.updateClient( this.props.selectedClient.id ) }>
            { Lang[ 'UPDATE' ][ this.props.data.languageReducer.lang ] }
          </Button>
          { this.props.onLoadClients && <LoadingOverlay /> }
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.onChangeClientHOC( 'showEditClientModal', false ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  render = () => {
    return(
      <>
        <div>
          <PageTitle
            heading={ Lang[ 'CLIENTS' ][ this.props.data.languageReducer.lang ] }
            icon="pe-7s-users icon-gradient bg-happy-fisher"
            buttons={[
              {
                buttonText: Lang[ 'ARCHIVED_CLIENT_LIST' ][ this.props.data.languageReducer.lang ],
                onClick: () => {
                  let tmp = {
                    page: this.props.clients.meta ? this.props.clients.meta.current_page : 1,
                    is_paginated: true
                  }
                  this.props.onChangeClientHOC( 'showArchivedClientModal', true )
                  this.props.getArchivedClient( tmp )
                },
                display: true
              },
              {
                buttonText: Lang[ 'NEW_CLIENT' ][ this.props.data.languageReducer.lang ],
                onClick: () => this.props.onChangeClientHOC( 'showNewClientModal', true ),
                display: this.handleSecurityModulesCreate()
              }
            ]}/>
          <ARKTable
            data={ this.props.clients.data }
            columns={[
              {
                Header: Lang[ 'NAME' ][ this.props.data.languageReducer.lang ],
                accessor: "company_name"
              },
              {
                Header: Lang[ 'POSTCODE' ][ this.props.data.languageReducer.lang ],
                accessor: "postcode"
              },
              {
                Header: Lang[ 'CITY' ][ this.props.data.languageReducer.lang ],
                accessor: "city"
              },
              {
                Header: Lang[ 'COUNTRY' ][ this.props.data.languageReducer.lang ],
                accessor: "country"
              },
              {
                Header: Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ],
                accessor: "status",
                Cell: ({ value }) => Lang[ value ][ this.props.data.languageReducer.lang ]
              },
              {
                Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
                accessor: "id",
                Cell: ({ value }) => (
                  <div>
                    <>
                      <Button
                        id={ `Client-${ value }-view-client` }
                        className="mb-2 mr-2 btn-icon btn-icon-only"
                        color="primary"
                        onClick={ () => this.props.getSelectedClientView( value ) }>
                        <i className="pe-7s-look"> </i>
                      </Button>
                      <UncontrolledTooltip
                        target={ `Client-${ value }-view-client` }
                        placement="top">{ Lang[ 'VIEW_CLIENT_DETAILS' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                    </>
                    {
                      this.handleSecurityModulesUpdate() && (
                        <>
                          <Button
                            id={ `Client-${ value }-update-client` }
                            className="mb-2 mr-2 btn-icon btn-icon-only"
                            color="primary"
                            onClick={ () => this.props.getSelectedClientEdit( value ) }>
                            <i className="pe-7s-note"> </i>
                          </Button>
                          <UncontrolledTooltip
                            target={ `Client-${ value }-update-client` }
                            placement="top">{ Lang[ 'UPDATE_CLIENT' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                        </>
                      )
                    }
                    {
                      this.handleSecurityModulesDelete() && (
                        <>
                          <Button
                            id={ `Client-${ value }-archive-client` }
                            className="mb-2 mr-2 btn-icon btn-icon-only"
                            color="danger"
                            onClick={ () => {
                              this.props.onChangeClientHOC( 'showDeleteConfirmation', true )
                              this.props.onChangeClientHOC( 'toArchiveClientId', value )
                            }}>
                              <i className="pe-7s-trash"> </i>
                          </Button>
                          <UncontrolledTooltip
                          target={ `Client-${ value }-archive-client` }
                          placement="top">{ Lang[ 'ARCHIVE_CLIENT' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                        </>
                      )
                    }
                  </div>
                )
              }
            ]}
            totalPages={ this.props.clientTotalPages }
            page={ this.props.clients.meta ? this.props.clients.meta.current_page : 1 }
            onChangePage={ pg => {
              let tmp = {
                page: pg,
                is_paginated: true
              }
              this.props.getClients( tmp )
            }}
            onClickReset={ () => {
              let tmp = {
                page: this.props.clients.meta ? this.props.clients.meta.current_page : 1,
                is_paginated: true
              }
              this.props.getClients( tmp )
            }}
            onSearch={ this.props.getClientsSearch }
            searchLabel={ Lang[ 'SEARCH_WITH_NAME' ][ this.props.data.languageReducer.lang ] }
            searchComponent={ true }
            searchColumn={ this.state.searchColumn }
            showPagination={ false }
            modulesUsed={'Trial'} />
            { this.renderClientDetail() }
            { this.renderNewForm() }
            { this.renderArchivedClient() }
            { this.renderEditClientForm() }
            {
              this.props.showDeleteConfirmation && (
                <DeleteConfirmation
                  handleModalClose={ () => this.props.onChangeClientHOC( 'showDeleteConfirmation', false ) }
                  deleteOpen={ this.props.showDeleteConfirmation }
                  confirmAction={ () => {
                    this.props.deactivateClient( this.props.toArchiveClientId )
                    this.props.onChangeClientHOC( 'showDeleteConfirmation', false )
                  }}
                  content={ Lang[ 'DELETE_CLIENT_CONFIRMATION' ][ this.props.data.languageReducer.lang ] }
                  lang={ this.props.data.languageReducer.lang } />
              )
            }
        </div>
        { ( this.props.data.ajaxReducer.ajaxCallProgress > 0 || this.props.onLoadClients ) && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose(
  CountryHOC,
  ClientsHOC
)( ClientManagement )
