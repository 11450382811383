import React, { Component } from 'react'
import { compose } from "redux"
import { 
  Form, FormGroup,
  Container, Row, Col,
  Card, CardDeck, CardHeader, CardBody,
  Button, Label, UncontrolledTooltip, Input
} from 'reactstrap'
import { 
  LineChart, Line, XAxis, YAxis, 
  CartesianGrid, Tooltip, Legend, ResponsiveContainer 
} from 'recharts'
import Select from 'react-select'
import Moment from 'moment'
import _ from 'lodash'

import { convertObjToBase64 } from 'utils/objToBase64'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PageTitle from 'components/PageTitle'
import ARKTable from 'components/Table'
import Lang from 'Lang/General'
import DashboardHOC from './actions'
import SiteStatsHOC from './actions/siteStats'
import AssignShortcutMenu from './AssignShortcut'

import './index.scss'

class Dashboard extends Component{
  state= {
    shortcutmenu: [],
    selectedCenter: {},
    selectedTimePeriod: { 
      label: 'Current year', 
      value: 'Current year' 
    }
  }

  componentDidMount = () => {
    this.props.getSiteStats()
    if( this.props.data.profileReducer.profile.role === 'OLS Team' ) {
      let tmp = {
        filter: {
          start_date: {
            $gte: Moment().clone().startOf( 'year' ).format()
          },
          end_date: {
            $lte: Moment().clone().endOf( 'year' ).format()
          }
        }
      }
      this.props.getDashboard( this.state.selectedCenter.id, convertObjToBase64( tmp ))
      this.props.getMostBookedCampaigns( Moment().clone().startOf( 'year' ).format( 'YYYY-MM-DD' ), Moment().clone().endOf( 'year' ).format( 'YYYY-MM-DD' ) ) 
    }
  }

  componentDidUpdate = pp => {
    if ( 
      this.props.siteStatsSearchParams !== pp.siteStatsSearchParams ||
      this.props.data.languageReducer.lang !== pp.data.languageReducer.lang
    ) {
      this.props.getSiteStats()
    }
  }

  getValues = () => {
    let tmp = []
    this.props.data.profileReducer.profile.sites && this.props.data.profileReducer.profile.sites.map( item => {
      tmp.push({
        id: item.center.id,
        label: item.center.center_name,
        value: item.center.center_name
      })
    })
    return tmp
  }

  totalFbAdsBudget = () => {
    let tmp = []
    this.props.dashboard.length > 0 && this.props.dashboard.map( item => {
      tmp.push( item.fa_budget )
    })
    return _.sum( tmp )
  }

  totalGoogleAdsBudget = () => {
    let tmp = []
    this.props.dashboard.length > 0 && this.props.dashboard.map( item => {
      tmp.push( item.ga_budget )
    })
    return _.sum( tmp )
  }

  filterMostBookedCampaigns = ( value ) => {
    if( value === 'Current month' ) {
      this.props.getMostBookedCampaigns( Moment().clone().startOf( 'month' ).format( 'YYYY-MM-DD' ), Moment().clone().endOf( 'month' ).format( 'YYYY-MM-DD' ) ) 
    } else if( value === 'Previous month' ) {
      this.props.getMostBookedCampaigns( Moment().clone().add( -1, 'month' ).startOf( 'month' ).format( 'YYYY-MM-DD' ), Moment().clone().add( -1, 'month' ).endOf( 'month' ).format( 'YYYY-MM-DD' ) ) 
    } else if( value === 'Current year' ) {
      this.props.getMostBookedCampaigns( Moment().clone().startOf( 'year' ).format( 'YYYY-MM-DD' ), Moment().clone().endOf( 'year' ).format( 'YYYY-MM-DD' ) ) 
    } else if ( value === 'Previous year' ) {
      this.props.getMostBookedCampaigns( Moment().clone().add( -1, 'year' ).startOf( 'year' ).format( 'YYYY-MM-DD' ), Moment().clone().add( -1, 'year' ).endOf( 'year' ).format( 'YYYY-MM-DD' ) ) 
    } else {
      this.props.getMostBookedCampaigns( Moment().clone().add( -12, 'month' ).startOf( 'month' ).format( 'YYYY-MM-DD' ), Moment().clone().add( -1, 'month' ).endOf( 'month' ).format( 'YYYY-MM-DD' ) ) 
    }
  }

  filterOlsCampaigns = ( value, center ) => {
    let tmp = {}
    if( value === 'Current month' ) {
      tmp = {
        filter: {
          start_date: {
            $gte: Moment().clone().startOf( 'month' ).format()
          },
          end_date: {
            $lte: Moment().clone().endOf( 'month' ).format()
          }
        }
      }
    } else if( value === 'Previous month' ) {
      tmp = {
        filter: {
          start_date: {
            $gte: Moment().clone().add( -1, 'month' ).startOf( 'month' ).format()
          },
          end_date: {
            $lte: Moment().clone().add( -1, 'month' ).endOf( 'month' ).format()
          }
        }
      }
    } else if( value === 'Current year' ) {
      tmp = {
        filter: {
          start_date: {
            $gte: Moment().clone().startOf( 'year' ).format()
          },
          end_date: {
            $lte: Moment().clone().endOf( 'year' ).format()
          }
        }
      }
    } else if ( value === 'Previous year' ) {
      tmp = {
        filter: {
          start_date: {
            $gte: Moment().clone().add( -1, 'year' ).startOf( 'year' ).format()
          },
          end_date: {
            $lte: Moment().clone().add( -1, 'year' ).endOf( 'year' ).format()
          }
        }
      }
    } else if ( value === 'Last 12 months' ) {
      tmp = {
        filter: {
          start_date: {
            $gte: Moment().clone().add( -12, 'month' ).startOf( 'month' ).format()
          },
          end_date: {
            $lte: Moment().clone().add( -1, 'month' ).endOf( 'month' ).format()
          }
        }
      }
    }
    this.props.getDashboard( center, convertObjToBase64( tmp ) )
  }

  renderShortCutMenu = () => {
    return (
      <>
        <div
          className="d-block d-lg-flex align-items-end mb-4"
          style={{
            marginBottom: "0.5rem",
          }}>
          <div style={{ display: "inline-block", width: "9.5rem" }} className="mr-4">
            <span className="main-title">
              {
                Lang["DASHBOARD"][this.props.data.languageReducer.lang]
              }
            </span>
            <Button
              color="primary"
              style={{
                color: "#fff",
                width: "100%",
                fontSize: "10px",
                border: "none",
              }}
              onClick={() => this.props.onChangeDashboardHOC( 'showAssign', true )}>
              {
                Lang["EDIT"][this.props.data.languageReducer.lang]
              }
            </Button>
          </div>
          <div className="d-flex flex-wrap mt-2 mt-lg-0" style={{ gap: '0.5rem 0.8rem' }}>
            {
              this.props.data.sidemenuReducer.assignedSidemenu && this.props.data.sidemenuReducer.assignedSidemenu.map( ( item, index ) => {
                return(
                  <div className="main-button_title_cont">
                    <Button
                      onClick={() => {
                        this.props.history.push( item.to.substring( item.to.indexOf( '#' ) + 1, item.to.length ) )
                      }}
                      color='light'
                      id={ `shortcut-${ index }` }
                      className="main-button_title shadow">
                      <i className={ item.icon } style={{ color: '#04254e', fontSize: '15px' }}/>
                      <span className="ml-1" style={{ color: '#04254e' }}>{ item.label }</span>
                    </Button>
                  </div>
                )
              })
            }
          </div>
        </div>
        <AssignShortcutMenu
          onChangeDashboardHOC={ this.props.onChangeDashboardHOC }
          showAssign={ this.props.showAssign }
          allMenus={ this.props.data.sidemenuReducer.sidemenu }
          sidemenu={ this.props.data.sidemenuReducer.sidemenu }
          assignedSidemenu={ this.props.data.sidemenuReducer.assignedSidemenu }
          storeSidemenu={ this.props.storeSidemenu }
          storeAssignedSidemenu={ this.props.storeAssignedSidemenu }
          lang={ this.props.data.languageReducer.lang } 
        />
      </>
    )
  }

  renderDashboard = () => {
    return(
      <>
        <PageTitle
          heading="Dashboard"
          icon="pe-7s-keypad icon-gradient bg-happy-fisher" />
        <Row>
          <Col md="6">
            <FormGroup style={{ marginBottom: '40px' }}>
              <Label>{ Lang[ 'SELECT_CENTER' ][ this.props.data.languageReducer.lang ] }</Label>
              <Select
                options={ this.getValues() }
                value={ this.state.selectedCenter }
                onChange={ val => {
                  this.setState({ selectedCenter: val })
                  this.filterOlsCampaigns( this.state.selectedTimePeriod.value, val.id ) 
                }} />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup style={{ marginBottom: '40px' }}>
              <Label>{ Lang[ 'SELECT_TIME_PERIOD' ][ this.props.data.languageReducer.lang ] }</Label>
              <Select
                options={ dateRange }
                value={ this.state.selectedTimePeriod }
                onChange={ val => {
                  this.setState({ selectedTimePeriod: val })
                  this.filterOlsCampaigns( val.value, this.state.selectedCenter.id )
                }} />
            </FormGroup>
          </Col>
        </Row>
        <p style={{ fontSize: '14px' }}>{ Lang[ 'KEY_FIGURES' ][ this.props.data.languageReducer.lang ] }</p>
        <Card style={{ background: '#fff', width: '100%', padding: '15px', marginBottom: '60px' }}>
          <Container>
            <Row>
              <Col md="3">
                <div className="d-flex" style={{ marginTop: '13px', marginBottom: '16px' }}>
                  <i className="pe-7s-note2" style={{
                    fontSize: '30px',
                    color: '#EA5B0C',
                    marginRight: '10px',
                    alignSelf: 'center'
                  }}/>
                  <span style={{
                    fontSize: '10px',
                    alignSelf: 'center'
                  }}>{ Lang[ 'ACTIVE_OLS' ][ this.props.data.languageReducer.lang ] }</span>
                </div>
                <p style={{
                  fontSize: '40px',
                  color: '#EA5B0C',
                }}>{ this.props.activeCampaignsCount }</p>
                <span
                  style={{
                    color: 'rgba(2,36,78,0.53)',
                    fontSize: '10px'
                  }}>{ Lang[ 'ACTIVE_CAMPAIGNS_NUMBER' ][ this.props.data.languageReducer.lang ] }</span>
              </Col>
              <Col md="3">
                <div className="d-flex" style={{ marginTop: '13px', marginBottom: '16px' }}>
                  <i className="pe-7s-news-paper" style={{
                    fontSize: '30px',
                    color: '#EA5B0C',
                    marginRight: '10px',
                    alignSelf: 'center'
                  }}/>
                  <span style={{
                    fontSize: '10px',
                    alignSelf: 'center'
                  }}>{ Lang[ 'TOTAL_FB_BUDGET' ][ this.props.data.languageReducer.lang ] }</span>
                </div>
                <p style={{
                  fontSize: '40px',
                  color: '#EA5B0C',
                }}>{ `${ this.totalFbAdsBudget() } €` }</p>
                <span
                  style={{
                    color: 'rgba(2,36,78,0.53)',
                    fontSize: '10px'
                  }}>{ Lang[ 'TOTAL_FB_ADS_BUDGET' ][ this.props.data.languageReducer.lang ] }</span>
              </Col>
              <Col md="3">
                <div className="d-flex" style={{ marginTop: '13px', marginBottom: '16px' }}>
                  <i className="pe-7s-news-paper" style={{
                    fontSize: '30px',
                    color: '#EA5B0C',
                    marginRight: '10px',
                    alignSelf: 'center'
                  }}/>
                  <span style={{
                    fontSize: '10px',
                    alignSelf: 'center'
                  }}>{ Lang[ 'TOTAL_ADWORDS_BUDGET' ][ this.props.data.languageReducer.lang ] }</span>
                </div>
                <p style={{
                  fontSize: '40px',
                  color: '#EA5B0C',
                }}>{ `${ this.totalGoogleAdsBudget() } €` } </p>
                <span
                  style={{
                    color: 'rgba(2,36,78,0.53)',
                    fontSize: '10px'
                  }}>{ Lang[ 'TOTAL_GOOGLE_ADS_BUDGET' ][ this.props.data.languageReducer.lang ] }</span>
              </Col>
            </Row>
          </Container>
          { this.props.onLoadFigures && <LoadingOverlay /> }
        </Card>
        <div className='d-flex justify-content-between' style={{ alignItems: 'flex-start'}}>
          <p style={{ fontSize: '30px', alignSelf: 'flex-end', color: '#EA5B0C', marginBottom: '15px' }}>{ Lang[ 'TOP_10_MOST_BOOKED_CAMPAIGNS' ][ this.props.data.languageReducer.lang ] }</p>
          <div style={{ width: '300px' }}> 
            <Select
              placeholder={ Lang[ 'SELECT_TIME_PERIOD' ][ this.props.data.languageReducer.lang ] }
              options={ dateRange }
              value={ this.state.selectedTimePeriod }
              onChange={ val => {
                this.setState({ selectedTimePeriod: val })
                this.filterMostBookedCampaigns( val.value, this.state.selectedCenter.id )
              }} />
          </div>
        </div>
        <ARKTable
          data={ this.props.mostBookedCampaigns }
          showPagination={ true } 
          columns={[
            {
              Header: Lang[ 'TOTAL_BOOKED' ][ this.props.data.languageReducer.lang ],
              accessor: "total_booked"
            },
            {
              Header: Lang[ 'CAMPAIGN_NAME' ][ this.props.data.languageReducer.lang ],
              accessor: "title"
            },
            {
              Header: Lang[ 'AVERAGE_LEADS' ][ this.props.data.languageReducer.lang ],
              accessor: "average_lead"
            }
          ]} 
        />
      </>
    )
  }

  render = () => {
    return(
      <>
        {
          this.props.data.profileReducer.profile.role === 'OLS Team' 
            ? this.renderDashboard()
            : this.renderShortCutMenu()
        }
        <CardDeck>
          <Card className='main-card mb-3' >
            <CardHeader>{ Lang[ 'FILTER' ][ this.props.data.languageReducer.lang ] }</CardHeader>
            <CardBody>
              <Form>
                <FormGroup row className='align-items-center' >
                  <Col md={ 4 } lg={ 3 }  >
                    <Label className='mb-0 mr-4' >{ Lang[ 'TYPE' ][ this.props.data.languageReducer.lang ] }</Label>
                  </Col>
                  <Col md={ 8 } lg={ 9 } >
                    <Input
                      type='select'
                      className='mr-2'
                      value={ this.props.siteStatsSearchParams.type }
                      onChange={ e => this.props.onChangeSiteStatsHOC(
                        'siteStatsSearchParams',
                        {
                          ...this.props.siteStatsSearchParams,
                          type: [ e.target.value ]
                        }
                      )} >
                      {
                        _.map( STAT_TYPES, ( statTypeValue, statTypeKey ) => (
                          <option value={ statTypeKey } >
                            { Lang[ statTypeKey ][ this.props.data.languageReducer.lang ] }
                          </option>
                        ))
                      }
                    </Input>
                  </Col>
                </FormGroup>
                <FormGroup row className='align-items-center' >
                  <Col md={ 4 } lg={ 3 }  >
                    <Label className='mb-0 mr-4' >{ Lang[ 'PERIOD' ][ this.props.data.languageReducer.lang ] }</Label>
                  </Col>
                  <Col md={ 8 } lg={ 9 } >
                    <Input
                      type='select'
                      className='mr-2'
                      value={ this.props.siteStatsSearchParams.period }
                      onChange={ e => this.props.onChangeSiteStatsHOC(
                        'siteStatsSearchParams',
                        {
                          ...this.props.siteStatsSearchParams,
                          period: e.target.value
                        }
                      )} >
                      {
                        _.map( PERIOD_TYPES( 2023 ), ( periodValue, periodType ) => (
                          <option value={ periodType } >
                            { Lang[ periodType ][ this.props.data.languageReducer.lang ] }
                          </option>
                        ))
                      }
                    </Input>
                  </Col>
                </FormGroup>
                {
                  this.props.siteStatsSearchParams.period === 'PREVIOUS_YEAR' && (
                    <FormGroup row className='align-items-center' >
                      <Col md={ 4 } lg={ 2 }  >
                        <Label className='mb-0 mr-4' >{ Lang[ 'YEAR' ][ this.props.data.languageReducer.lang ] }</Label>
                      </Col>
                      <Col md={ 8 } lg={ 10 } >
                        <Input
                          type='select'
                          className='mr-2'
                          value={ this.props.siteStatsSearchParams.year }
                          onChange={ e => this.props.onChangeSiteStatsHOC(
                            'siteStatsSearchParams',
                            {
                              ...this.props.siteStatsSearchParams,
                              year: e.target.value
                            }
                          )} >
                          {
                            _.cloneDeep( Array( Moment().year() - 2023 ) ).map( ( _, index ) => (
                              <option value={ 2023 + index } >{ 2023 + index }</option>
                            ))
                          }
                        </Input>
                      </Col>
                    </FormGroup>
                  )
                }
              </Form>
            </CardBody>
          </Card>
          {
            [ 'ACTIVE_SITES', 'TEST_SITES' ].map( statType => (
              <Card 
                className="main-card mb-3 card-shadow-primary"
                style={{ 
                  border: "0px",
                  borderBottom: "4px solid #545CD8"
                }}>
                <CardBody className="d-flex flex-column justify-content-center" >
                  <Label
                    className='w-100 mb-0'
                    style={{
                      textAlign: "center",
                      fontSize: "50px",
                      fontWeight: "500" 
                    }} >
                    { this.props.siteStats[ statType.toLowerCase() ] }
                  </Label>
                  <Label
                    className='w-100'
                    style={{
                      textAlign: "center",
                      color: "#495057",
                      fontSize: "1rem",
                      opacity: ".5" 
                    }} >
                    { Lang[ statType ][ this.props.data.languageReducer.lang ] }
                  </Label>
                </CardBody>
              </Card>
            ))
          }
        </CardDeck>
        <CardDeck>
          {
            [ 'CANCELLED_SITES', 'ACTIVE_CENTERS', 'INACTIVE_CENTERS' ].map( statType => (
              <Card 
                className="main-card mb-3 card-shadow-primary"
                style={{ 
                  border: "0px",
                  borderBottom: "4px solid #545CD8"
                }}>
                <CardBody className="d-flex flex-column justify-content-center" >
                  <Label
                    className='w-100 mb-0'
                    style={{
                      textAlign: "center",
                      fontSize: "50px",
                      fontWeight: "500" 
                    }} >
                    { this.props.siteStats[ statType.toLowerCase() ] }
                  </Label>
                  <Label
                    className='w-100'
                    style={{
                      textAlign: "center",
                      color: "#495057",
                      fontSize: "1rem",
                      opacity: ".5" 
                    }} >
                    { Lang[ statType ][ this.props.data.languageReducer.lang ] }
                  </Label>
                </CardBody>
              </Card>
            ))
          }
        </CardDeck>
        {
          _.chunk( this.props.siteStatsSearchParams.type, 2 ).map( statsGroup => (
            <CardDeck>
              {
                statsGroup.map( statType => (
                  <Card>
                    <CardHeader>
                      { Lang[ statType ][ this.props.data.languageReducer.lang ] }
                      { 
                        this.props.siteStatsSearchParams.period.indexOf( 'PREVIOUS' ) > -1
                          ? this.props.siteStatsSearchParams.period .indexOf( 'MONTH' ) > -1
                            ? ` (${ Moment().subtract( 1, 'month' ).locale( this.props.data.languageReducer.lang ).format( 'MMMM' ) })`
                            : ` (${ this.props.siteStatsSearchParams.year })`
                          : '' 
                      }
                    </CardHeader>
                    <CardBody style={{ display: "flex", minHeight: "300px" }} >
                      <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                          width={ 500 }
                          height={ 10 }
                          data={ this.props.siteStats[ STAT_TYPES[ statType ] ] }
                          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                        >
                          <CartesianGrid vertical={ false } horizontal={ false } />
                          <XAxis 
                            dataKey="name" 
                            interval="preserveStartEnd"
                            padding={{ left: 40, right: 20 }}
                          />
                          <YAxis 
                            interval="preserveStartEnd" 
                            tickCount={10} 
                            domain={ ( [ dataMin, dataMax ] ) => { return [ Math.max( 0, dataMin - 20 ), dataMax + 20 ] } }
                            padding={{ top: 60, bottom: 40 }} 
                          />
                          <Tooltip />
                          <Legend />
                          <Line 
                            type="monotone" 
                            dataKey="total" 
                            name={ Lang[ "TOTAL" ][ this.props.data.languageReducer.lang ] } 
                            stroke="#04244c" 
                            strokeWidth={ 2 } 
                            activeDot={{ r: 8 }} 
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </CardBody>
                  </Card>
                ))
              }
            </CardDeck>
          ))
        }
        { ( this.props.onLoadFigures || this.props.onLoadSiteStatsHOC ) && <LoadingOverlay /> }
      </>
    )
  }
}

const dateRange = [
  {
    label: 'Last 12 months',
    value: 'Last 12 months'
  },
  {
    label: 'Current year',
    value: 'Current year'
  },
  {
    label: 'Previous year',
    value: 'Previous year'
  },
  {
    label: 'Current month',
    value: 'Current month'
  },
  {
    label: 'Previous month',
    value: 'Previous month'
  }
]

const STAT_TYPES = {
  ACTIVE_SITES: 'active_site_stats',
  TEST_SITES: 'test_site_stats',
  CANCELLED_SITES: 'cancelled_site_stats',
  ACTIVE_CENTERS: 'active_center_stats',
  INACTIVE_CENTERS: 'inactive_center_stats',
}

const PERIOD_TYPES = year => ({
  LAST_4_WEEKS: {
    start_date: Moment().subtract( 3, 'weeks' ).startOf( 'isoWeek' ).format( 'YYYY-MM-DD' ),
    end_date: Moment().endOf( 'isoWeek' ).format( 'YYYY-MM-DD' ),
    period_type: 'LAST_4_WEEKS'
  },
  CURRENT_MONTH: {
    start_date: Moment().startOf( 'month' ).format( 'YYYY-MM-DD' ),
    end_date: Moment().endOf( 'month' ).format( 'YYYY-MM-DD' ),
    period_type: 'CURRENT_MONTH'
  },
  PREVIOUS_MONTH: {
    start_date: Moment().subtract( 1, 'month' ).startOf( 'month' ).format( 'YYYY-MM-DD' ),
    end_date: Moment().subtract( 1, 'month' ).endOf( 'month' ).format( 'YYYY-MM-DD' ),
    period_type: 'PREVIOUS_MONTH'
  },
  LAST_12_MONTHS: {
    start_date: Moment().subtract( 11, 'month' ).startOf( 'month' ).format( 'YYYY-MM-DD' ),
    end_date: Moment().endOf( 'month' ).format( 'YYYY-MM-DD' ),
    period_type: 'LAST_12_MONTHS'
  },
  PREVIOUS_YEAR: {
    start_date: Moment( year ).startOf( 'year' ).format( 'YYYY-MM-DD' ),
    end_date: Moment( year ).endOf( 'year' ).format( 'YYYY-MM-DD' ),
    period_type: 'PREVIOUS_YEAR'
  }
})

export default compose( 
  DashboardHOC,
  SiteStatsHOC
)( Dashboard )
