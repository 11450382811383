import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import {
  Modal, ModalBody, ModalHeader, 
  TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap'
import { connect } from 'react-redux'
import classnames from 'classnames'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import General from './components/general'
import AddMailing from './components/addMailing'

import Lang from 'Lang/General'

const FunnelForm = ( props ) => {
  const [activeTab, setActiveTab] = useState('1');

  useEffect( () => {
    props.onChangeSystemFunnelsHOC( 'systemFunnelErrorMsg', {} )
  }, [props.showCreateFunnel] )

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  return(
    <Modal
      isOpen={ props.showCreateFunnel }
      size="lg">
      <ModalHeader toggle={ () => props.onChangeSystemFunnelsHOC('showCreateFunnel', false ) }>{ Lang['CREATE_NEW_FUNNEL'][ props.selectedLanguage ] }
      </ModalHeader>
      <ModalBody>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => { toggle('1'); }}>
              { Lang['GENERAL'][ props.selectedLanguage ] }
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <General
              {...props }/>
          </TabPane>
          <TabPane tabId="2">
            <AddMailing
              {...props }/>
          </TabPane>
        </TabContent>
        { props.onLoadSystemFunnels && <LoadingOverlay /> }
      </ModalBody>
    </Modal>

  )
}
const mapStateToProps = state => ({ dataReducer: state })

export default connect( mapStateToProps )( FunnelForm )
