import React, { Component } from 'react'
import { compose } from 'redux'
import { 
  Button, Modal, ModalHeader, 
  ModalBody, ModalFooter, 
  Table, UncontrolledTooltip 
} from 'reactstrap'
import _ from 'lodash'

import PageTitle from 'components/PageTitle'
import ARKTable from 'components/Table'
import DeleteConfirmation from 'components/Modal/delete'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import ModuleForm from './components/form'
import ModuleHOC from './actions'

import Lang from 'Lang/General'

class ModuleConfiguration extends Component{
  state = {
    searchColumn: [
      {
        name: Lang[ 'NAME' ][ this.props.data.languageReducer.lang ],
        type: 'text',
        val: 'name'
      },
      {
        name: Lang[ 'TYPE' ][ this.props.data.languageReducer.lang ],
        type: "dropdown",
        val: 'type'
      },
      {
        name: Lang[ 'DESCRIPTION' ][ this.props.data.languageReducer.lang ],
        type: 'text',
        val: 'description'
      },
      {
        name: Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ],
        type: 'dropdown',
        val: 'status'
      }
    ],
    archivedSearchColumn: [
      {
        name: Lang[ 'NAME' ][ this.props.data.languageReducer.lang ],
        type: "text",
        val: 'name'
      },
      {
        name: Lang[ 'TYPE' ][ this.props.data.languageReducer.lang ],
        type: "dropdown",
        val: 'type'
      },
      {
        name: Lang[ 'DESCRIPTION' ][ this.props.data.languageReducer.lang ],
        type: "text",
        val: 'description'
      },
    ],
    name: '',
    selectedColumn: []
  }

  componentDidMount = () => {
    let tmp = {
      page: this.props.modules.meta ? this.props.modules.meta.current_page : 1,
      is_paginated: true,
      filter: {
        $or: [
          { status: 'ACTIVE' }
        ]
      }
    }
    this.props.getModules( tmp )
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Module' })
      return tmpModules.is_create
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Module' })
      return tmpModules.is_update
    }
  }

  handleSecurityModulesDelete = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Module' })
      return tmpModules.is_delete
    }
  }

  renderNewModule = () => {
    return (
      <Modal
        isOpen={ this.props.showNewModuleModal }
        size="xl">
        <ModalHeader>{ Lang[ 'NEW_MODULE' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <ModuleForm
            data={ this.props.newModuleData }
            onChangeModuleHOC={ this.props.onChangeModuleHOC }
            errorMessage={ this.props.errorMessage }
            types={ this.props.data.dictionaryReducer.dictionary.types }
          />
          <Button className="mb-2 mr-2" color="info" onClick={ () => this.props.postModules() } >{ Lang[ 'CREATE' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.onChangeModuleHOC( 'showNewModuleModal', false ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderEditModule = () => {
    return (
      <Modal
        isOpen={ this.props.showEditModuleModal }
        size="xl">
        <ModalHeader>{ Lang[ 'EDIT_MODULE' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <ModuleForm
            data={ this.props.selectedModule }
            onChangeModuleHOC={ this.props.onChangeModuleHOC }
            types={ this.props.data.dictionaryReducer.dictionary.types }
            mode={ 'edit' }/>
          <Button className="mb-2 mr-2" color="info" onClick={ () => this.props.updateModule( this.props.selectedModule.id ) }>{ Lang[ 'UPDATE' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.onChangeModuleHOC( 'showEditModuleModal', false ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderViewModuleDetails = () => {
    return (
      <Modal isOpen={ this.props.showViewModuleModal } size="lg">
        <ModalHeader>{ Lang[ 'MODULE_DETAILS' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <div className="scroll-area-lg">
            <Table className="mb-0" bordered>
              <tbody>
                <tr>
                  <th scope="row">{ Lang[ 'NAME' ][ this.props.data.languageReducer.lang ] }</th>
                  <td>{ this.props.selectedModule.name }</td>
                </tr>
                <tr>
                  <th scope="row">{ Lang[ 'DESCRIPTION' ][ this.props.data.languageReducer.lang ] }</th>
                  <td>{ this.props.selectedModule.description }</td>
                </tr>
                <tr>
                  <th scope="row">{ Lang[ 'TYPE' ][ this.props.data.languageReducer.lang ] }</th>
                  <td>{ this.props.selectedModule.type }</td>
                </tr>
                <tr>
                  <th scope="row">{ Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ] }</th>
                  <td>{ this.props.selectedModule.status }</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={ () => this.props.onChangeModuleHOC( 'showViewModuleModal', false ) }>OK</Button>
          {
            this.handleSecurityModulesUpdate() && (
              <Button
                color="alternate"
                onClick={ () => {
                  this.props.onChangeModuleHOC( 'showViewModuleModal', false )
                  this.props.onChangeModuleHOC( 'showEditModuleModal', true )
                }}>{ Lang[ 'EDIT' ][ this.props.data.languageReducer.lang ] }</Button>
            )
          }
        </ModalFooter>
      </Modal>
    )
  }

  renderShowArchived = () => {
    return (
      <Modal
        isOpen={ this.props.showArchivedModuleModal }
        size="xl">
        <ModalHeader>{ Lang[ 'ARCHIVED_MODULES' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <ARKTable
            data={ this.props.archivedModules.data }
            columns={[
              {
                Header: Lang[ 'NAME' ][ this.props.data.languageReducer.lang ],
                accessor: "name"
              },
              {
                Header: Lang[ 'DESCRIPTION' ][ this.props.data.languageReducer.lang ],
                accessor: "description"
              },
              {
                Header: Lang[ 'TYPE' ][ this.props.data.languageReducer.lang ],
                accessor: "type"
              },
              {
                Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
                accessor: "id",
                show: this.handleSecurityModulesUpdate(),
                Cell: ( row ) => (
                  <div>
                    <Button
                      id={`Module-${ row.original.id }-unarchive-module`}
                      className=" mr-2 btn-icon btn-icon-only"
                      color="danger"
                      onClick={ () => {
                        this.props.onChangeModuleHOC( 'confirmUnarchiveModal', true )
                        this.props.onChangeModuleHOC( 'unarchiveModuleID', row.original.id )
                      }}>
                      <i className="pe-7s-menu"> </i>
                    </Button>
                    <UncontrolledTooltip
                      target={ `Module-${ row.original.id }-unarchive-module` }
                      placement="top">{ Lang[ 'UNARCHIVE_MODULE' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                  </div>
                )
              }
            ]}
            totalPages={ this.props.archivedModuleTotalPages }
            page={ this.props.archivedModules.meta ? this.props.archivedModules.meta.current_page : 1 }
            onChangePage={ pg => {
              let tmp = { page: pg, is_paginated: true, filter: {
                $or: [
                  { status: 'ARCHIVED' }
                ]
              } }
              return this.props.getArchivedModule( tmp )
            }}
            onClickReset={ () => {
              let tmp = {
                page: this.props.archivedModules.meta ? this.props.archivedModules.meta.current_page : 1,
                is_paginated: true
              }
              this.props.getArchivedModule( tmp )
            }}
            onSearch={ this.props.getArchivedModule }
            searchLabel={ Lang[ 'SEARCH_WITH_MODULE_NAME' ][ this.props.data.languageReducer.lang ] }
            searchComponent={ true }
            searchColumn={ this.state.archivedSearchColumn }
            types={ this.props.data.dictionaryReducer.dictionary.types } />
            {
              this.props.confirmUnarchiveModal && (
                <DeleteConfirmation
                  handleModalClose={ () => this.props.onChangeModuleHOC( 'confirmUnarchiveModal', false ) }
                  deleteOpen={ this.props.confirmUnarchiveModal }
                  confirmAction={ () => {
                    this.props.updateModuleStatus( this.props.unarchiveModuleID, 'ACTIVE' )
                    this.props.onChangeModuleHOC( 'confirmUnarchiveModal', false )
                  }}
                  content={ Lang[ 'DELETE_CONFIRMATION_ARCHIVED_MODULE' ][ this.props.data.languageReducer.lang ] }
                  lang={ this.props.data.languageReducer.lang } />
              )
            }
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.onChangeModuleHOC( 'showArchivedModuleModal', false ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  render = () => {
    return(
      <>
        <div>
          <PageTitle
            heading={ Lang[ 'MODULES' ][ this.props.data.languageReducer.lang ] }
            icon="pe-7s-portfolio icon-gradient bg-happy-fisher"
            buttons={[
              {
                buttonText: Lang[ 'ARCHIVED_MODULES' ][ this.props.data.languageReducer.lang ],
                onClick: () => {
                  let tmp = {
                    page: this.props.modules.meta ? this.props.modules.meta.current_page : 1,
                    is_paginated: true,
                    filter: {
                      $or: [
                        { status: 'ARCHIVED' }
                      ]
                    }
                  }
                  this.props.onChangeModuleHOC( 'showArchivedModuleModal', true )
                  this.props.getArchivedModule( tmp )
                },
                display: true,
                className: 'module-btnarchive'
              },
              {
                buttonText: Lang[ 'NEW_MODULE' ][ this.props.data.languageReducer.lang ],
                onClick: () => this.props.onChangeModuleHOC( 'showNewModuleModal', true ),
                display: this.handleSecurityModulesCreate(),
                className: 'module-btncreate'
              }
            ]}/>
          <ARKTable
            data={ this.props.modules.data }
            columns={[
              {
                Header: Lang[ 'NAME' ][ this.props.data.languageReducer.lang ],
                accessor: "name"
              },
              {
                Header: Lang[ 'DESCRIPTION' ][ this.props.data.languageReducer.lang ],
                accessor: "description"
              },
              {
                Header: Lang[ 'TYPE' ][ this.props.data.languageReducer.lang ],
                accessor: "type"
              },
              {
                Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
                accessor: "id",
                Cell: ({value}) => (
                  <div>
                    <>
                      <Button
                        id={ `Module-${ value }-view-module` } 
                        className="mb-2 mr-2 btn-icon btn-icon-only" 
                        color="primary" 
                        onClick={ () => this.props.getSelectedModuleView( value ) }>
                        <i className="pe-7s-look"> </i>
                      </Button>
                      <UncontrolledTooltip
                        target={ `Module-${ value }-view-module` }
                        placement="top">{ Lang[ 'VIEW_MODULE' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                    </>
                    {
                      this.handleSecurityModulesUpdate() && (
                        <>
                          <Button 
                            id={ `Module-${ value }-update-module` }
                            className="mb-2 mr-2 btn-icon btn-icon-only" 
                            color="primary" 
                            onClick={ () => this.props.getSelectedModuleEdit( value ) }>
                            <i className="pe-7s-note"> </i>
                          </Button>
                          <UncontrolledTooltip
                            target={ `Module-${ value }-update-module` }
                            placement="top">{ Lang[ 'UPDATE_MODULE' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                        </>
                      )
                    }
                    {
                      this.handleSecurityModulesDelete() && (
                        <>
                          <Button
                            id={ `Module-${ value }-archive-module` }
                            className="mb-2 mr-2 btn-icon btn-icon-only"
                            color="danger"
                            onClick={ () => {
                              this.props.onChangeModuleHOC( 'showDeleteConfirmation', true )
                              this.props.onChangeModuleHOC( 'toArchiveModuleId', value )
                            }}>
                              <i className="pe-7s-trash"> </i>
                          </Button>
                          <UncontrolledTooltip
                            target={ `Module-${ value }-archive-module` }
                            placement="top">{ Lang[ 'ARCHIVE_MODULE' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                        </>
                      )
                    }
                  </div>
                )
              }
            ]}
            totalPages={ this.props.moduleTotalPages }
            page={ this.props.modules.meta ? this.props.modules.meta.current_page : 1 }
            onChangePage={ pg => {
              let tmp = {
                page: pg,
                is_paginated: true,
                filter: {
                  $or: [
                    { status: 'ACTIVE' }
                  ]
                }
              }
              this.props.getModules( tmp )
            }}
            onClickReset={ () => {
              let tmp = {
                page: this.props.modules.meta ? this.props.modules.meta.current_page : 1,
                is_paginated: true
              }
              this.props.getModules( tmp )
            }}
            onSearch={ this.props.getModulesSearch }
            searchLabel={ Lang[ 'SEARCH_WITH_MODULE_NAME' ][ this.props.data.languageReducer.lang ] }
            searchComponent={ true }
            searchColumn={ this.state.searchColumn }
            types={ this.props.data.dictionaryReducer.dictionary.types }
            showPagination={ false } />
            { this.renderNewModule() }
            { this.renderEditModule() }
            { this.renderViewModuleDetails() }
            { this.renderShowArchived() }
            {
              this.props.showDeleteConfirmation && (
                <DeleteConfirmation
                  handleModalClose={ () => this.props.onChangeModuleHOC( 'showDeleteConfirmation', false ) }
                  deleteOpen={ this.props.showDeleteConfirmation }
                  confirmAction={ () => {
                  this.props.updateModuleStatus( this.props.toArchiveModuleId, 'ARCHIVED' )
                  this.props.onChangeModuleHOC( 'showDeleteConfirmation', false )
                  }
                }
                content={ Lang[ 'DELETE_CONFIRMATION_MODULE' ][ this.props.data.languageReducer.lang ] }
                lang={ this.props.data.languageReducer.lang } />
              )
            }
        </div>
        { ( this.props.data.ajaxReducer.ajaxCallProgress > 0 || this.props.onLoadModules ) && <LoadingOverlay /> }
      </>
    )
  }
}
export default compose(
  ModuleHOC
)(ModuleConfiguration)
