import React, { Component } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  UncontrolledTooltip
} from 'reactstrap'
import _ from 'lodash'
import Moment from 'moment'
import ARKTable from 'components/Table'
import { convertObjToBase64 } from 'utils/objToBase64'

import ViewIndividualResult from './ViewIndividualResult'
import PromptModal from 'components/Indicator/Prompt'
import Lang from 'Lang/General'

class ModalFormViewResult extends Component{
  state = {
    showIndividualResult: false,
    selectedIndividualResult: {},
    showConfirmArchive: false
  }

  onClickArchiveFormResult = () => {
    this.props.archiveIndividualFormResult(this.state.selectedIndividualResult)    
    this.setState({ showConfirmArchive: false })
  }


  render = () => {
    let{
      showViewModalResults,
      onChangeDigitalOrderFormHOC,
      selectedDigitalOrderFormResults,
      getSelectedDigitalOrderFormResults,
      selectedFormResultsTotalPage,
      hasArchiveRight
    } = this.props
    
    return(
      <>
        <Modal isOpen={ showViewModalResults } size='xl' >
          <ModalHeader toggle={ () => onChangeDigitalOrderFormHOC( 'showViewModalResults', false ) } >
            { Lang[ 'VIEW_RESULTS' ][ this.props.data.languageReducer.lang ] }
          </ModalHeader>
          { 
            this.state.showIndividualResult === false ? (
              <ModalBody>
                <ARKTable   
                  data={ selectedDigitalOrderFormResults.data }
                  columns={[
                    {
                      className: "d-flex flex-row align-items-center justify-content-center text-center",
                      Header: Lang[ 'CENTER' ][ this.props.data.languageReducer.lang ],
                      accessor: 'center_name',
                    },
                    {
                      className: "d-flex flex-row align-items-center justify-content-center text-center",
                      Header: Lang[ 'CONSULTANT' ][ this.props.data.languageReducer.lang ],
                      accessor: 'consultant_name',
                    },
                    {
                      className: "d-flex flex-row align-items-center justify-content-center text-center",
                      Header: Lang[ 'SUBMITTED_ON' ][ this.props.data.languageReducer.lang ],
                      accessor: 'created_at',
                      Cell: ({value}) => (
                        Moment( value ).format( 'DD-MM-YYYY h:mm:ss a' )
                      )
                    },
                    {
                      Header: Lang[ 'ACTIONS' ][ this.props.data.languageReducer.lang ],
                      Cell: ( row ) => (
                        <div className="d-flex flex-row align-items-center justify-content-center w-100">
                          {
                            <>
                              <Button 
                                color="primary" 
                                id={ `Form-result-${ row.original.id }-view-btn` }
                                className="mr-2"
                                onClick={ () => this.setState({ 
                                  showIndividualResult: true,
                                  selectedIndividualResult: row.original
                                }) }>
                                <i className="pe-7s-look"/>
                              </Button>
                              <UncontrolledTooltip
                                target={ `Form-result-${ row.original.id }-view-btn` }
                                placement="top">{ Lang[ 'VIEW' ][ this.props.data.languageReducer.lang ] }
                              </UncontrolledTooltip>
                            </>
                          }
                          {
                            <>
                              <Button 
                                color="primary"
                                id={ `Form-result-${ row.original.id }-download-btn` } 
                                className="mr-2"
                                onClick={() => {
                                  Promise.all([
                                    onChangeDigitalOrderFormHOC( 'selectedOrderRequest', row.original )
                                  ]).then(() => {
                                    this.props.downloadUploadedData( row.original.id )
                                  })
                                }}>
                                  <i className="pe-7s-download"/>
                              </Button>
                              <UncontrolledTooltip
                                target={ `Form-result-${ row.original.id }-download-btn` } 
                                placement="top">{ Lang[ 'DOWNLOAD_ALL_FILES' ][ this.props.data.languageReducer.lang ] }
                              </UncontrolledTooltip>
                            </>
                          }
                          {
                            hasArchiveRight && (
                              <>
                                <Button 
                                  color="danger" 
                                  id={ `Form-result-${ row.original.id }-archive-btn` }
                                  className="mr-2"
                                  onClick={() => this.setState({
                                    selectedIndividualResult: row.original,
                                    showConfirmArchive: true
                                  }) }>
                                    <i className="pe-7s-box1"/>
                                </Button>
                                <UncontrolledTooltip
                                  target={ `Form-result-${ row.original.id }-archive-btn` }
                                  placement="top">{ Lang[ 'ARCHIVE' ][ this.props.data.languageReducer.lang ] }
                                </UncontrolledTooltip>
                              </>
                            )
                          }
                        </div>
                      )
                    }
                  ]}
                  totalPages={ selectedFormResultsTotalPage }
                  page={ selectedDigitalOrderFormResults.meta ? selectedDigitalOrderFormResults.meta.current_page : 1 }
                  onChangePage={ pg => {
                    const tmp = {
                      page: pg, 
                      is_paginated: true,
                      filter: {
                        status_id: {
                          $neq: 7
                        }
                      }
                    }
                    getSelectedDigitalOrderFormResults( selectedDigitalOrderFormResults.data[0].digital_order_form_id, convertObjToBase64(tmp) )
                  }}
                  showPagination={ false }
                />
              </ModalBody>
            ) : (
            <ModalBody>
              <ViewIndividualResult
                individualResult={ this.state.selectedIndividualResult }
                lang={ this.props.data.languageReducer.lang }
              />
            </ModalBody>
            )
          }
          <ModalFooter>
            { this.state.showIndividualResult === false 
              ? <Button
                  color='link'
                  onClick={ () => onChangeDigitalOrderFormHOC( 'showViewModalResults', false ) }>
                  { Lang[ 'CLOSE' ][ this.props.data.languageReducer.lang ] }
                </Button>
              : <Button
                  color='link'
                  onClick={ () => this.setState({ showIndividualResult: false }) }>
                  { Lang[ 'BACK' ][ this.props.data.languageReducer.lang ] }
                </Button>
            }
          </ModalFooter>
        </Modal>
        <PromptModal
          showPromptModal={ this.state.showConfirmArchive }
          content={ Lang[ 'ARCHIVE_CONFIRMATION' ][ this.props.data.languageReducer.lang ] }
          onClickYes={() => this.onClickArchiveFormResult()}
          onClickNo={() => this.setState({ showConfirmArchive: false, selectedIndividualResult: {} })}
          lang={ this.props.data.languageReducer.lang } 
        />
      </>
    )
  }
}

export default ModalFormViewResult

