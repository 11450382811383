import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { FaBell, FaBellSlash } from 'react-icons/fa'
import { RiSpam3Line } from 'react-icons/ri'
import { IoMdMailOpen } from 'react-icons/io'
import Moment from 'moment'

import { Get } from 'utils/axios'
import Lang from 'Lang/General'
import { 
  defaultSearchParam 
} from '../asset'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state={
      loading: false, 
      currentStatOption: 'newsletters',

      gunMailStats: [],
      searchParams: defaultSearchParam,
    }

    load = param => this.setState({ loading: param })

    onChangeGunMailHOC = ( context, val ) => this.setState({ [context]: val })

    getGunMailStats = ( centerId, startDate, endDate ) => {
      Get(
        `/api/v1/email_statistics/${ centerId }?start_date=${ Moment( startDate ).format( 'YYYY-MM-DD' ) }&email_type=${ this.state.currentStatOption }&end_date=${ Moment( endDate ).format( 'YYYY-MM-DD' )  }`,
        this.getGunMailStatsSuccess,
        this.getGunMailStatsError,
        this.load
      )
    }
    getGunMailStatsSuccess = payload => {
      this.setState({ gunMailStats: this.handleDisplayContent( payload ) })
    }
    getGunMailStatsError = error => toast.error( error )

    handleDisplayContent = ({ statistics, total_sent }) => {
      const { lang } = this.props

      return [
        {
          parent_color: 'primary',
          icon: 'pe-7s-mail',
          title: Lang[ 'TOTAL_DELIVERED' ][ lang ],
          total_value: statistics.delivered + statistics.failed.permanent + statistics.failed.temporary,
          children: [
            {
              value: statistics.delivered,
              color: 'success',
              progress_label: Lang[ 'RECEIVED' ][ lang ],
            },
            {
              value: statistics.failed.permanent,
              color: 'danger',
              progress_label: Lang[ 'FAILED_TO_SEND' ][ lang ],
            }
          ],  
        },
        ... [ 'newsletters', 'funnels' ].indexOf( this.state.currentStatOption ) > -1 ? [
          {
            children: [
              {
                label: Lang[ 'OPENED_MAIL' ][ lang ],
                icon: (
                  <IoMdMailOpen 
                    style={{ 
                      width: 50, height: 50, padding: '0.9rem', opacity: 0.8, 
                      borderRadius: '0.5rem', height: 'fit-content' 
                    }} 
                    className={ `text-white bg-primary` }
                  />
                ),
                value: statistics.opened
              },
              {
                label: Lang[ 'COMPLAINED' ][ lang ],
                icon: (
                  <RiSpam3Line 
                    style={{ 
                      width: 50, height: 50, padding: '0.9rem', opacity: 0.8, 
                      borderRadius: '0.5rem', height: 'fit-content' 
                    }} 
                    className={ `text-white bg-warning` }
                  />
                ),
                value: statistics.complained
              }
            ]
          },
        ] : [],
        ... this.state.currentStatOption === 'newsletters' ? [
          {
            children: [
              {
                label: Lang[ 'SUBSCRIBED' ][ lang ],
                icon: (
                  <FaBell 
                    style={{ 
                      width: 50, height: 50, padding: '0.9rem', opacity: 0.8, 
                      borderRadius: '0.5rem', height: 'fit-content' 
                    }} 
                    className={ `text-white bg-success` }
                  />
                ),
                value: statistics.subscribed || 0
              },
              {
                label: Lang[ 'UNSUBSCRIBED' ][ lang ],
                icon: (
                  <FaBellSlash 
                    style={{ 
                      width: 50, height: 50, padding: '0.9rem', opacity: 0.8, 
                      borderRadius: '0.5rem', height: 'fit-content' 
                    }} 
                    className={ `text-white bg-danger` }
                  />
                ),
                value: statistics.unsubscribed || 0
              }
            ]
          }
        ] : []
      ]
    }

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          onLoadStats={ this.state.loading }
          searchParams={ this.state.searchParams }
          gunMailStats={ this.state.gunMailStats }
          currentStatOption={ this.state.currentStatOption }
          
          onChangeGunMailHOC={ this.onChangeGunMailHOC }
          getGunMailStats={ this.getGunMailStats }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC