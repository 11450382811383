import React, { useState } from 'react'
import {
  Card, CardHeader, CardBody,
  Row, Form, FormGroup, 
  Label, Button, Input
} from 'reactstrap'
import { SketchPicker } from 'react-color'
import Moment from 'moment'
import _ from 'lodash'
import { toast } from 'react-toastify'

import RTEUtilities from './RTEUtilities'
import Datepicker from 'components/Datepicker'
import Lang from 'Lang/General'

const CountdownForm = ({
  selectedSection,
  onChangeSectionHOC,
  onChangeSectionEditorState,
  showCodeTagEditor,
  onPatchRendererComponent,
  onUpdateRTEContent,
  selectedLanguageShortname,
  lang
}) => {
  const [ selectedEditableComponent, updateSelectedEditableComponent ] = useState( {} )
  const [ showSketchPicker, updateShowSketchPicker ] = useState( '' )

  return (
    <Card className='mb-2'>
      <CardHeader>
        <i
          className='pe-7s-settings mr-1'
          style={{ fontSize: '20px' }} />
        { Lang[ 'SETTINGS' ][ lang ] }
      </CardHeader>
      <CardBody>
        <Form>
          <FormGroup>
            <Label style={{ fontSize: '1.2rem' }}>{ Lang[ 'TEXT' ][ lang ] }</Label>
            <div>
              {
                selectedSection.children.length > 0 && _.filter( selectedSection.children, { variant: 'text' } ).map(( item, index ) => {
                  return (
                    <Button
                      color='primary'
                      className='mr-2'
                      onClick={ () => {
                        updateSelectedEditableComponent( item )
                        onChangeSectionEditorState( 'showCodeTagEditor', true )
                      }}>
                      <i className='pe-7s-pen font-weight-bold mr-2' />
                      { 
                        item.label !== 'HEADLINE'
                          ? `${ Lang[ 'TEXT' ][ lang ] } ${ index }` 
                          : Lang[ 'HEADLINE' ][ lang ] 
                      }
                    </Button>
                  )
                })
              }
            </div>
          </FormGroup>
          <RTEUtilities
            showCodeTagEditor={ showCodeTagEditor }
            selectedEditableComponent={ selectedEditableComponent }
            onChangeSectionEditorState={ onChangeSectionEditorState }
            onPatchRendererComponent={ onPatchRendererComponent }
            onUpdateRTEContent={ onUpdateRTEContent }
            selectedLanguage={ selectedLanguageShortname }
            lang={ lang } />
          <Row>
            <FormGroup className='col-md-6'>
              <Label>{ Lang[ 'START_DATE' ][ lang ] }</Label>
              <Datepicker
                showTimeInput
                selectedDate={ Moment( selectedSection.startDate ).toDate() }
                dateFormat='dd/MM/yyyy, h:mm aa'
                onChange={ e => {
                  let tmp = _.cloneDeep( selectedSection )
                  tmp.startDate = Moment( e ).utc().format()
                  onChangeSectionHOC( 'selectedSection', tmp )
                }} />
            </FormGroup>
            <FormGroup className='col-md-6'>
              <Label>{ Lang[ 'END_DATE' ][ lang ] }</Label>
              <Datepicker
                popperPlacement='bottom-end'
                showTimeInput
                selectedDate={ selectedSection.endDate ? Moment( selectedSection.endDate ).toDate() : Moment().add( 14, 'days' ).toDate() }
                dateFormat='dd/MM/yyyy, h:mm aa'
                onChange={ e => {
                  if ( Moment( e ) < Moment( selectedSection.startDate ) ) {
                    toast.error( Lang[ 'PLEASE_SELECT_A_DATE_TIME_LATER_THAN_START_DATE' ][ lang ] )
                  } else {
                    let tmp = _.cloneDeep( selectedSection )
                    tmp.endDate = Moment( e ).utc().format()
                    onChangeSectionHOC( 'selectedSection', tmp )
                  }
                }} />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup 
              className='col-md-6' 
              style={{ position: 'relative' }}
              onMouseLeave={ () => updateShowSketchPicker( '' ) }>
              <Label>{ Lang[ 'COLOR' ][ lang ] }</Label>
              <Input
                type={ 'text' }
                style={{ backgroundColor: selectedSection.style.color }}
                value={ selectedSection.style.color }
                onClick={ () => updateShowSketchPicker( 'color' ) } />
              {
                showSketchPicker === 'color' && (
                  <div 
                    style={{ 
                      position: 'absolute',
                      right: 0, 
                      zIndex: 999 
                    }}>
                    <SketchPicker
                      color={ selectedSection.style.color }
                      onChange={ col => {
                        let tmp = selectedSection
                        tmp.style.color = col.hex
                        onChangeSectionEditorState( 'selectedSection', tmp )
                      }} />
                  </div>
                )
              }
            </FormGroup>
            <FormGroup 
              className='col-md-6' 
              style={{ position: 'relative' }}
              onMouseLeave={ () => updateShowSketchPicker( '' ) }>
              <Label>{ Lang[ 'BACKGROUND_COLOR' ][ lang ] }</Label>
              <Input
                type={ 'text' }
                style={{ backgroundColor: selectedSection.style.primaryColor ? '#0d6efd' : selectedSection.style.background }}
                value={ selectedSection.style.primaryColor ? '#0d6efd' : selectedSection.style.background }
                onClick={ () => updateShowSketchPicker( 'background' ) } />
              {
                showSketchPicker === 'background' && (
                  <div 
                    style={{ 
                      position: 'absolute',
                      right: 0, 
                      zIndex: 999 
                    }}>
                    <SketchPicker
                      color={ selectedSection.style.primaryColor ? '#0d6efd' : selectedSection.style.background }
                      onChange={ col => {
                        let tmp = selectedSection
                        tmp.style.background = col.hex
                        delete tmp.style.primaryColor
                        onChangeSectionEditorState( 'selectedSection', tmp )
                      }} />
                  </div>
                )
              }
            </FormGroup>
          </Row>
          <Row>
            <FormGroup 
              className='col-md-6' 
              style={{ position: 'relative' }}
              onMouseLeave={ () => updateShowSketchPicker( '' ) }>
              <Label>{ Lang[ 'COUNTDOWN_COLOR' ][ lang ] }</Label>
              <Input
                type={ 'text' }
                style={{ backgroundColor: selectedSection.children[ 1 ].style.color }}
                value={ selectedSection.children[ 1 ].style.color }
                onClick={ () => updateShowSketchPicker( 'countdown-color' ) } />
              {
                showSketchPicker === 'countdown-color' && (
                  <div 
                    style={{ 
                      position: 'absolute',
                      right: 0, 
                      zIndex: 999 
                    }}>
                    <SketchPicker
                      color={ selectedSection.children[ 1 ].style.color }
                      onChange={ col => {
                        let tmp = selectedSection
                        tmp.children[ 1 ].style.color = col.hex
                        onChangeSectionEditorState( 'selectedSection', tmp )
                      }} />
                  </div>
                )
              }
            </FormGroup>
          </Row>
        </Form>
      </CardBody>
    </Card>
  )
}

export default CountdownForm