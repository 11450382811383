import React, { Fragment } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import ReactSelect from 'react-select'
import { connect } from 'react-redux'
import _ from 'lodash'
import { BeatLoader } from 'react-spinners'
import {
  Col, Card, CardBody,
  CardTitle, Button, Form, FormGroup, Label, Input, FormText, Container, FormFeedback
} from 'reactstrap'

import Lang from 'Lang/General'

const ClientForm = ({
  onChangeHOC,
  data,
  countries,
  getStates,
  states,
  onLoadCountries,
  statusValues,
  errorMessage,
  mode,
  dataReducer
}) => {
  const {
    company_name,
    salutation,
    first_name,
    last_name,
    email,
    house_number,
    street,
    postcode,
    city,
    state,
    country,
    phone_number,
    fax_number,
    client_number,
    tax_number,
    status
  } = data

  const onChange = ( key, val ) => {
    let tmp = _.cloneDeep( data )
    let dataKey = ''
    tmp[ key ] = val
    if( mode === 'edit' ){
      dataKey = 'selectedClient'
    } else{
      dataKey = 'newClientData'
    }
    return onChangeHOC( dataKey, tmp )
  }
  let countriesOptions = []
  countries && countries.map( item => {
    countriesOptions.push({
      id: item.id,
      label: item.name,
      value: item.name
    })
  })

  let statesOption = []
  states && states.map( item => {
    statesOption.push({
      id: item.id,
      label: item.name,
      value: item.name
    })
  })
  const getValueForSelect = ( type ) => {
    switch ( type ) {
      case 'state':
        return {
          label: state,
          value: state
        }
      case 'country':
        return {
          label: country,
          value: country
        }
      default:
        return {}
    }
  }
  return(
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}>
        <Container fluid>
          <Card className="main-card mb-3">
            <CardBody>
              <CardTitle>{ Lang[ 'FILL_IN_DETAILS' ][ dataReducer.languageReducer.lang ] }</CardTitle>
              <Form>
                <table style={{ tableLayout: "auto", width: "100%", borderCollapse: "separate", borderSpacing: "0 10px" }}>
                  <tr>
                    <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                      <Label for="company_name" sm={ 2 }>{ Lang[ 'NAME' ][ dataReducer.languageReducer.lang ] }</Label>
                    </td>
                      <td>
                        <FormGroup className='m-0'>
                          <Input
                            name="company" id="company_name"
                            placeholder={ Lang[ 'ENTER_NAME' ][ dataReducer.languageReducer.lang ] }
                            value={ company_name }
                            onChange={ e => onChange( 'company_name', e.target.value ) }
                            invalid={ errorMessage && errorMessage.company_name ? true : false }/>
                          <FormFeedback>{ errorMessage && errorMessage.company_name ? errorMessage.company_name : '' }</FormFeedback>
                        </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                        <Label for="salutation" sm={ 2 }>{ Lang[ 'SALUTATION' ][ dataReducer.languageReducer.lang ] }</Label>
                      </td>
                      <td>
                        <FormGroup className='m-0'>
                          <Input
                            type="select"
                            name="salutation"
                            id="salutation"
                            error={ errorMessage && errorMessage.salutation ? true : false }
                            value={ salutation }
                            onChange={ e => onChange( 'salutation', e.target.value ) }>
                            <option>{ Lang['SELECT_A_SALUTATION'][ dataReducer.languageReducer.lang ] }</option>
                            <option value={ 'Herr' }>{ Lang['MR'][ dataReducer.languageReducer.lang ] }</option>
                            <option value={ 'Frau' }>{ Lang['MRS'][ dataReducer.languageReducer.lang ] }</option>
                          </Input>
                          <FormFeedback>{ errorMessage && errorMessage.salutation ? errorMessage.salutation : '' }</FormFeedback>
                        </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                        <Label for="street" sm={ 2 }>{ Lang[ 'STREET' ][ dataReducer.languageReducer.lang ] }</Label>
                      </td>
                      <td>
                        <FormGroup className='m-0'>
                          <Input
                            name="street" id="street"
                            placeholder={ Lang[ 'ENTER_STREET_NAME' ][ dataReducer.languageReducer.lang ] }
                            value={ street }
                            onChange={ e => onChange( 'street', e.target.value ) }
                            invalid={ errorMessage && errorMessage.street ? true : false }/>
                          <FormFeedback>{ errorMessage && errorMessage.street ? errorMessage.street : '' }</FormFeedback>
                        </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                        <Label for="house_number" sm={ 2 }>{ Lang[ 'HOUSE_NUMBER' ][ dataReducer.languageReducer.lang ] }</Label>
                      </td>
                      <td>
                        <FormGroup className='m-0'>
                          <Input
                            name="house_number" id="house_number"
                            placeholder={ Lang[ 'ENTER_HOUSE_NUMBER' ][ dataReducer.languageReducer.lang ] }
                            value={ house_number }
                            onChange={ e => onChange( 'house_number', e.target.value ) }
                            invalid={ errorMessage && errorMessage.house_number ? true : false }/>
                          <FormFeedback>{ errorMessage && errorMessage.house_number ? errorMessage.house_number : '' }</FormFeedback>
                        </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                        <Label for="postcode" sm={ 2 }>{ Lang[ 'POSTCODE' ][ dataReducer.languageReducer.lang ] }</Label>
                      </td>
                      <td>
                        <FormGroup className='m-0'>
                          <Input
                            name="postcode" id="postcode"
                            placeholder={ Lang[ 'ENTER_POSTCODE' ][ dataReducer.languageReducer.lang ] }
                            value={ postcode }
                            onChange={ e => onChange( 'postcode', e.target.value ) }
                            invalid={ errorMessage && errorMessage.postcode ? true : false }/>
                          <FormFeedback>{ errorMessage && errorMessage.postcode ? errorMessage.postcode : '' }</FormFeedback>
                        </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                        <Label for="city" sm={ 2 }>{ Lang[ 'CITY' ][ dataReducer.languageReducer.lang ] }</Label>
                      </td>
                      <td>
                        <FormGroup className='m-0'>
                          <Input
                            name="city" id="city"
                            placeholder={ Lang[ 'ENTER_CITY' ][ dataReducer.languageReducer.lang ] }
                            value={ city }
                            onChange={ e => onChange( 'city', e.target.value ) }
                            invalid={ errorMessage && errorMessage.city ? true : false }/>
                          <FormFeedback>{ errorMessage && errorMessage.city ? errorMessage.city : '' }</FormFeedback>
                        </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                        <Label for="country" sm={ 2 }>{ Lang[ 'COUNTRY' ][ dataReducer.languageReducer.lang ] }</Label>
                      </td>
                      <td>
                        <FormGroup className='m-0'>
                          <ReactSelect
                            options={ countriesOptions }
                            onChange={ v => {
                              onChange( 'country', v.value )
                              let tmpCountry = _.find( countriesOptions, { value: v.value })
                              if( tmpCountry ){
                                getStates( tmpCountry.id )
                              }
                            }}
                            value={ getValueForSelect( 'country' ) }
                            placeholder={ Lang[ 'SELECT_COUNTRY' ][ dataReducer.languageReducer.lang ] }/>
                          <FormText color="danger">{ errorMessage && errorMessage.country ? errorMessage.country : '' }</FormText>
                        </FormGroup>
                      </td>
                    </tr>
                    {
                      onLoadCountries && <BeatLoader
                        sizeUnit={"px"}
                        size={30}
                        color={'#123abc'}
                        loading={true}
                      />
                    }
                    {
                      country && (
                        <tr>
                          <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                            <Label for="country" sm={ 2 }>{ Lang[ 'STATE' ][ dataReducer.languageReducer.lang ] }</Label>
                          </td>
                          <td>
                            <FormGroup className='m-0'>
                              <ReactSelect
                                options={ statesOption }
                                onChange={ v => onChange( 'state', v.value ) }
                                value={ getValueForSelect( 'state' ) }
                                placeholder={ Lang[ 'SELECT_STATE' ][ dataReducer.languageReducer.lang ] }/>
                              <FormText color="danger">{ errorMessage && errorMessage.state ? errorMessage.state : '' }</FormText>
                            </FormGroup>
                          </td>
                        </tr>
                      )
                    }
                    <tr>
                      <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                        <Label for="phone_number" sm={ 2 }>{ Lang[ 'PHONE_NUMBER' ][ dataReducer.languageReducer.lang ] }</Label>
                      </td>
                      <td>
                        <FormGroup className='m-0'>
                          <Input
                            name="phone_number" id="phone_number"
                            placeholder={ Lang[ 'ENTER_PHONE_NUMBER' ][ dataReducer.languageReducer.lang ] }
                            value={ phone_number }
                            onChange={ e => onChange( 'phone_number', e.target.value ) }
                            invalid={ errorMessage && errorMessage.phone_number ? true : false }/>
                          <FormFeedback>{ errorMessage && errorMessage.phone_number ? errorMessage.phone_number : '' }</FormFeedback>
                        </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                        <Label for="fax_number" sm={ 2 }>{ Lang[ 'FAX_NUMBER' ][ dataReducer.languageReducer.lang ] }</Label>
                      </td>
                      <td>
                        <FormGroup className='m-0'>
                          <Input
                            name="fax_number" id="fax_number"
                            placeholder={ Lang[ 'ENTER_FAX_NUMBER' ][ dataReducer.languageReducer.lang ] }
                            value={ fax_number }
                            onChange={ e => onChange( 'fax_number', e.target.value ) }
                            invalid={ errorMessage && errorMessage.fax_number ? true : false }/>
                          <FormFeedback>{ errorMessage && errorMessage.fax_number ? errorMessage.fax_number : '' }</FormFeedback>
                        </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                        <Label for="client_number" sm={ 2 }>{ Lang[ 'CLIENT_NUMBER' ][ dataReducer.languageReducer.lang ] }</Label>
                      </td>
                      <td>
                        <FormGroup className='m-0'>
                          <Input
                            name="client_number" id="client_number"
                            placeholder={ Lang[ 'ENTER_CLIENT_NUMBER' ][ dataReducer.languageReducer.lang ] }
                            value={ client_number }
                            onChange={ e => {
                              let pattern = new RegExp(/^[0-9a-zA-Z\b]+$/)
                              if( !pattern.test( e.target.value ) ){
                                errorMessage["client_number"] = "Client number is invalid"
                              } else {
                                errorMessage["client_number"] = ""
                              }
                              onChange( 'client_number', e.target.value )
                            }}
                            invalid={ errorMessage && errorMessage.client_number ? true : false }/>
                          <FormFeedback>{ errorMessage && errorMessage.client_number ? errorMessage.client_number : '' }</FormFeedback>
                        </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                        <Label for="email" sm={ 2 }>{ Lang[ 'EMAIL_FOR_INVOICE' ][ dataReducer.languageReducer.lang ] }</Label>
                      </td>
                      <td>
                        <FormGroup className='m-0'>
                          <Input
                            type="email"
                            name="email" id="email"
                            placeholder={ Lang[ 'ENTER_EMAIL' ][ dataReducer.languageReducer.lang ] }
                            value={ email }
                            onChange={ e => {
                              let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i )
                              if( !pattern.test( e.target.value ) ){
                                errorMessage["email"] = Lang[ 'PLEASE_ENTER_A_VALID_EMAIL' ][ dataReducer.languageReducer.lang ]
                              } else {
                                errorMessage["email"] = ""
                              }
                              onChange( 'email', e.target.value )
                            }}
                            invalid={ errorMessage && errorMessage.email ? true : false }/>
                          <FormFeedback>{ errorMessage && errorMessage.email ? errorMessage.email : '' }</FormFeedback>
                        </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                        <Label for="tax_number" sm={ 2 }>{ Lang[ 'TAX_NUMBER' ][ dataReducer.languageReducer.lang ] }</Label>
                      </td>
                      <td>
                        <FormGroup className='m-0'>
                          <Input
                            name="tax_number" id="tax_number"
                            placeholder={ Lang[ 'ENTER_TAX_NUMBER' ][ dataReducer.languageReducer.lang ] }
                            value={ tax_number }
                            onChange={ e => onChange( 'tax_number', e.target.value ) }
                            invalid={ errorMessage && errorMessage.tax_number ? true : false }/>
                          <FormFeedback>{ errorMessage && errorMessage.tax_number ? errorMessage.tax_number : '' }</FormFeedback>
                        </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                        <Label for="language" sm={ 2 }>{ Lang[ 'DEFAULT_LANGUAGE' ][ dataReducer.languageReducer.lang ] }</Label>
                      </td>
                      <td>
                        <FormGroup className='m-0'>
                          <Input
                            type="select"
                            name="language"
                            id="language"
                            value={ data.user.admin_panel_language }
                            onChange={ e => {
                              let tmp = _.cloneDeep( data.user )
                              tmp[ 'admin_panel_language' ] = e.target.value
                              onChange( 'user', tmp )
                            }}>
                            <option value={ 'en' }>{ Lang[ 'ENGLISH' ][ dataReducer.languageReducer.lang ] }</option>
                            <option value={ 'de' }>{ Lang[ 'GERMAN' ][ dataReducer.languageReducer.lang ] }</option>
                            <option value={ 'it' }>{ Lang[ 'ITALIAN' ][ dataReducer.languageReducer.lang ] }</option>
                            <option value={ 'nl' }>{ Lang[ 'DUTCH' ][ dataReducer.languageReducer.lang ] }</option>
                          </Input>
                        </FormGroup>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                        <Label for="salutation" sm={ 2 }>{ Lang[ 'STATUS' ][ dataReducer.languageReducer.lang ] }</Label>
                      </td>
                      <td>
                        <FormGroup className='m-0'>
                          <Input
                            type="select"
                            name="salutation"
                            id="salutation"
                            error={ errorMessage && errorMessage.status ? true : false }
                            value={ status }
                            onChange={ e => onChange( 'status', e.target.value ) }>
                            {
                              statusValues && Object.keys( statusValues ).map( key => {
                                return (
                                  key !== 'archived' && <option key={ key }>{ statusValues[key] }</option>
                                )
                              })
                            }
                            <option value='TRIAL'>{'TRIAL'}</option>
                          </Input>
                          <FormFeedback>{ errorMessage && errorMessage.status ? errorMessage.status : '' }</FormFeedback>
                        </FormGroup>
                      </td>
                    </tr>
                </table>
              </Form>
            </CardBody>
          </Card>
          <Card className="main-card mb-3">
            <CardBody>
              <CardTitle>{ Lang[ 'LOGIN_INFO' ][ dataReducer.languageReducer.lang ] }</CardTitle>
              <Form>
                <table style={{ tableLayout: "auto", width: "100%", borderCollapse: "separate", borderSpacing: "0 10px" }}>
                  <tr>
                    <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                      <Label for="first_name" sm={ 2 }>{ Lang[ 'FIRST_NAME' ][ dataReducer.languageReducer.lang ] }</Label>
                    </td>
                    <td>
                      <FormGroup className='m-0'>
                        <Input
                          name="first_name" id="first_name"
                          placeholder={ Lang[ 'ENTER_FIRST_NAME' ][ dataReducer.languageReducer.lang ] }
                          value={ data.user.first_name }
                          onChange={ e => {
                            let tmp = _.cloneDeep( data.user )
                            tmp[ 'first_name' ] = e.target.value
                            onChange( 'user', tmp )
                          }}
                          invalid={ errorMessage && errorMessage[ 'user.first_name' ] ? true : false }/>
                        <FormFeedback>{ errorMessage && errorMessage[ 'user.first_name' ] ? errorMessage[ 'user.first_name' ] : '' }</FormFeedback>
                      </FormGroup>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                      <Label for="last_name" sm={ 2 }>{ Lang[ 'LAST_NAME' ][ dataReducer.languageReducer.lang ] }</Label>
                    </td>
                    <td>
                      <FormGroup className='m-0'>
                        <Input
                          name="last_name" id="last_name"
                          placeholder={ Lang[ 'ENTER_LAST_NAME' ][ dataReducer.languageReducer.lang ] }
                          value={ data.user.last_name }
                          onChange={ e => {
                            let tmp = _.cloneDeep( data.user )
                            tmp[ 'last_name' ] = e.target.value
                            onChange( 'user', tmp )
                          }}
                          invalid={ errorMessage && errorMessage[ 'user.last_name' ] ? true : false }/>
                        <FormFeedback>{ errorMessage && errorMessage[ 'user.last_name' ] ? errorMessage[ 'user.last_name' ] : '' }</FormFeedback>
                      </FormGroup>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                      <Label for="email" sm={ 2 }>{ Lang[ 'EMAIL' ][ dataReducer.languageReducer.lang ] }</Label>
                    </td>
                    <td>
                      <FormGroup className='m-0'>
                        <Input
                          type="email"
                          name="email" id="email"
                          placeholder={ Lang[ 'ENTER_EMAIL' ][ dataReducer.languageReducer.lang ] }
                          value={ data.user.email }
                          onChange={ e => {
                            let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i )
                            if( !pattern.test( e.target.value ) ){
                              errorMessage["user.email"] = "Please enter valid email address."
                            } else {
                              errorMessage["user.email"] = ""
                            }
                            let tmp = _.cloneDeep( data.user )
                            tmp[ 'email' ] = e.target.value
                            onChange( 'user', tmp )
                          }}
                          invalid={ errorMessage && errorMessage[ 'user.email' ] ? true : false }/>
                        <FormFeedback>{ errorMessage && errorMessage[ 'user.email' ] ? errorMessage[ 'user.email' ] : '' }</FormFeedback>
                      </FormGroup>
                    </td>
                  </tr>
                  {/*<tr>
                    <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                      <Label sm={ 2 }>{ Lang[ 'USERNAME' ][ dataReducer.languageReducer.lang ] }</Label>
                    </td>
                    <td>
                      <FormGroup className='m-0'>
                        <Input
                          placeholder={ Lang[ 'USERNAME' ][ dataReducer.languageReducer.lang ] }
                          value={ data.user.username }
                          onChange={ e => {
                            let tmp = _.cloneDeep( data.user )
                            tmp[ 'username' ] = e.target.value
                            onChange( 'user', tmp )
                          }}
                          invalid={ errorMessage && errorMessage[ 'user.username' ] ? true : false }/>
                        <FormFeedback>{ errorMessage && errorMessage[ 'user.username' ] ? errorMessage[ 'user.username' ] : '' }</FormFeedback>
                      </FormGroup>
                    </td>
                  </tr>*/}
                  <tr>
                    <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                      <Label sm={ 2 }>{ Lang[ 'PASSWORD' ][ dataReducer.languageReducer.lang ] }</Label>
                    </td>
                    <td>
                      <FormGroup className='m-0'>
                        <Input
                          type="password"
                          autoComplete='new-password'
                          placeholder={ Lang[ 'PASSWORD' ][ dataReducer.languageReducer.lang ] }
                          value={ data.user.password }
                          onChange={ e => {
                            let tmp = _.cloneDeep( data.user )
                            tmp[ 'password' ] = e.target.value
                            onChange( 'user', tmp )
                          }}
                          invalid={ data.user.password && data.user.password.length > 0 && data.user.password.length < 8 ? true : false }/>
                        <FormFeedback>{ data.user.password.length > 0 && data.user.password.length < 8 ? 'Password must more than 8 characters' : '' }</FormFeedback>
                      </FormGroup>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                      <Label sm={ 2 }>{ Lang[ 'CONFIRM_PASSWORD' ][ dataReducer.languageReducer.lang ] }</Label>
                    </td>
                    <td>
                      <FormGroup className='m-0'>
                        <Input
                          type="password"
                          placeholder={ Lang[ 'CONFIRM_PASSWORD' ][ dataReducer.languageReducer.lang ] }
                          value={ data.user.password_confirmation }
                          onChange={ e => {
                            let tmp = _.cloneDeep( data.user )
                            tmp[ 'password_confirmation' ] = e.target.value
                            onChange( 'user', tmp )
                          }}
                          invalid={ data.user.password_confirmation.length > 0 && data.user.password_confirmation !== data.user.password ? true : false }/>
                        <FormFeedback>{ data.user.password && data.user.password.length > 0 && data.user.password_confirmation.length > 0 && data.user.password_confirmation !== data.user.password ? 'Password not same' : '' }</FormFeedback>
                      </FormGroup>
                    </td>
                  </tr>
                </table>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}

const mapStateToProps = state => ({ dataReducer: state })

export default connect( mapStateToProps )( ClientForm )
