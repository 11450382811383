import React, { useEffect } from 'react'
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button, UncontrolledTooltip, Card
} from 'reactstrap'
import _ from 'lodash'
import ReactTable from 'react-table'

import PageTitle from 'components/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import DeleteConfirmation from 'components/Modal/delete'
import Lang from 'Lang/General'

import MemberStatusForm from './Form'
import WithMemberStatusesHOC from './actions.js'

const MemberStatuses = props => {
  useEffect( () => {
    props.getMemberStatuses()
  }, [] )

  useEffect( () => {
    if( !props.showCreateMemberStatus ){
      props.onChangeMemberStatusesHOC( 
        'newMemberStatus', 
        { 
          name: { en: '', de: '', it: '', nl: '' }, 
          remark: '' 
        } 
      )
    }
  }, [ props.showCreateMemberStatus ] )

  useEffect( () => {
    if( !props.showUpdateMemberStatus ){
      props.onChangeMemberStatusesHOC( 'selectedMemberStatus', {} )
    }
  }, [ props.showUpdateMemberStatus ] )

  const handleSecurityModulesCreate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Member Area' })
      return tmpModules.is_create
    }
  }

  const handleSecurityModulesUpdate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Member Area' })
      return tmpModules.is_update
    }
  }

  const handleSecurityModulesDelete= () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Member Area' })
      return tmpModules.is_delete
    }
  }

  const renderCreateMemberStatusModal = () => (
    <Modal size='md' isOpen={ props.showCreateMemberStatus } >
      <ModalHeader toggle={ () => props.onChangeMemberStatusesHOC( 'showCreateMemberStatus', false ) } >
        { Lang[ 'CREATE' ][ props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        <MemberStatusForm
          memberStatusType='newMemberStatus'
          currentMemberStatus={ props.newMemberStatus }
          onChangeMemberStatusesHOC={ props.onChangeMemberStatusesHOC }
          lang={ props.data.languageReducer.lang }
        />
        { props.onLoadMemberStatusesHOC && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => props.createMemberStatus( props.newMemberStatus ) }>
          { Lang[ 'SUBMIT' ][ props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )

  const renderUpdateMemberStatusModal = () => (
    <Modal size='md' isOpen={ props.showUpdateMemberStatus } >
      <ModalHeader toggle={ () => props.onChangeMemberStatusesHOC( 'showUpdateMemberStatus', false ) } >
        { Lang[ 'UPDATE' ][ props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        <MemberStatusForm
          memberStatusType='selectedMemberStatus'
          currentMemberStatus={ props.selectedMemberStatus }
          onChangeMemberStatusesHOC={ props.onChangeMemberStatusesHOC }
          lang={ props.data.languageReducer.lang }
        />
        { props.onLoadMemberStatusesHOC && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => props.updateMemberStatus( props.selectedMemberStatus ) }>
          { Lang[ 'SUBMIT' ][ props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )

  return(
    <>
      <PageTitle
        heading={ Lang[ 'MEMBER_STATUS' ][ props.data.languageReducer.lang ] }
        icon="pe-7s-users icon-gradient bg-happy-fisher"
        buttons={[
          {
            buttonText: Lang[ 'CREATE' ][ props.data.languageReducer.lang ],
            onClick: () => props.onChangeMemberStatusesHOC( 'showCreateMemberStatus', true ),
            display: handleSecurityModulesCreate()
          }
        ]}/>
      <Card className='p-4'>
        <ReactTable
          showPagination
          pageSize={ 10 }
          data={ props.memberStatuses }
          columns={[
            {
              Header: Lang[ 'NAME' ][ props.data.languageReducer.lang ],
              accessor: "name",
              Cell: ({ value }) => value[ props.data.languageReducer.lang ] 
            },
            {
              Header: Lang[ 'ACTIONS' ][ props.data.languageReducer.lang ],
              accessor: "id",
              Cell: ({ value }) => (
                <div className='d-flex w-100 justify-content-center' >
                  {
                    handleSecurityModulesUpdate() && (
                      <>
                        <Button
                          id={ `MemberStatus-${ value }-update` }
                          className="mb-2 mr-2 btn-icon btn-icon-only"
                          color="primary"
                          onClick={ () => props.getSelectedMemberStatus( value ) }>
                          <i className="pe-7s-note"> </i>
                        </Button>
                        <UncontrolledTooltip
                          target={ `MemberStatus-${ value }-update` }
                          placement="top">{ Lang[ 'EDIT' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                      </>
                    )
                  }
                  {
                    handleSecurityModulesDelete() && (
                      <>
                        <Button
                          id={ `MemberStatus-${ value }-archive-memberStatus` }
                          className="mb-2 mr-2 btn-icon btn-icon-only"
                          color="danger"
                          onClick={ () => props.onChangeMemberStatusesHOC( 'showDeleteMemberStatusId', parseInt( value ) ) }>
                          <i className="pe-7s-trash"> </i>
                        </Button>
                        <UncontrolledTooltip
                          target={ `MemberStatus-${ value }-archive-memberStatus` }
                          placement="top">{ Lang[ 'REMOVE' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                      </>
                    )
                  }
                </div>
              )
            }
          ]}
        />
      </Card>
      <DeleteConfirmation
        handleModalClose={ () => props.onChangeMemberStatusesHOC( 'showDeleteMemberStatusId', 0 ) }
        deleteOpen={ props.showDeleteMemberStatusId > 0 }
        confirmAction={ () => props.removeMemberStatus( props.showDeleteMemberStatusId ) }
        content={ Lang[ 'DELETE_CONFIRMATION_STATUS' ][ props.data.languageReducer.lang ] }
        lang={ props.data.languageReducer.lang } />
      { renderCreateMemberStatusModal() }
      { renderUpdateMemberStatusModal() }
      { props.onLoadMemberStatusesHOC && <LoadingOverlay/> }
    </>
  )
}

export default WithMemberStatusesHOC( MemberStatuses )