import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      centerGroups: []
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    getCenterGroups = query => Get(
      `/api/v1/center_groups?query=${ query }`,
      this.getCenterGroupsSuccess,
      this.getCenterGroupsError,
      this.load
    )
    getCenterGroupsSuccess = payload => this.setState({ centerGroups: payload.data })
    getCenterGroupsError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadCenterGroups={ this.state.loading }
          getCenterGroups={ this.getCenterGroups }
          centerGroups={ this.state.centerGroups } />
      )
    }
  }
  return WithHOC
}

export default HOC
