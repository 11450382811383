import React, { Component } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { Get } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'

import { storeSidemenu, storeAssignedSidemenu } from 'actions/sidemenu'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      showAssign: false,
      allMenus: [
        {
          id: 1,
          name: 'Site Template',
          icon: 'pe-7s-config',
          link: '/dashboard/site-template-settings'
        },
        {
          id: 2,
          name: 'OLS campaign listings',
          icon: 'pe-7s-ticket',
          link: '/dashboard/ols-campaign-listings'
        },
        {
          id: 3,
          name: 'Campaign Tools',
          icon: 'pe-7s-tools',
          link: '/dashboard/campaign-tools'
        },
        {
          id: 4,
          name: 'User Management',
          icon: 'pe-7s-users',
          link: '/dashboard/users-management'
        },
        {
          id: 5,
          name: 'Site Template',
          icon: 'pe-7s-config',
          link: '/dashboard/site-template-settings'
        },
        {
          id: 6,
          name: 'OLS campaign listings',
          icon: 'pe-7s-ticket',
          link: '/dashboard/ols-campaign-listings'
        },
        {
          id: 7,
          name: 'Campaign Tools',
          icon: 'pe-7s-tools',
          link: '/dashboard/campaign-tools'
        },
        {
          id: 8,
          name: 'User Management',
          icon: 'pe-7s-users',
          link: '/dashboard/users-management'
        }
      ],
      dashboard: [],
      mostBookedCampaigns: [],
      activeCampaignsCount: 0
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      zIndex: 1000
    })
    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      zIndex: 1000
    })

    onChangeDashboardHOC = ( key, value ) => this.setState({ [key]: value })

    getDashboard = ( center_id, query ) => Get(
      `/api/v1/centers/${ center_id }/ols_booked_campaigns?query=${ query }`,
      this.getDashboardSuccess,
      this.getDashboardError,
      this.load
    )
    getDashboardSuccess = payload => this.setState({ dashboard: payload.data, activeCampaignsCount: payload.data.length })
    getDashboardError = error => this.requestError( error )

    getActiveCampaigns = ( center_id ) => {
      let query = {
        filter: {
          status: 'ACTIVE',
          center_id: center_id
        }
      }
      Get(
        `/api/v1/ols_campaigns?query=${ convertObjToBase64( query ) }`,
        this.getActiveCampaignsSuccess,
        this.getActiveCampaignsError,
        this.load
      )
    }
    getActiveCampaignsSuccess = payload => this.setState({ activeCampaignsCount: payload.data.length })
    getActiveCampaignsError = error => this.requestError( error )

    getMostBookedCampaigns = ( start_date, end_date ) => {
      Get(
        `/api/v1/top_ols_campaigns?start_date=${ start_date }&end_date=${ end_date }`,
        this.getMostBookedCampaignsSuccess,
        this.getMostBookedCampaignsError,
        this.load
      )
    }
    getMostBookedCampaignsSuccess = payload => this.setState({ mostBookedCampaigns: payload })
    getMostBookedCampaignsError = error => this.requestError( error )

    render = () => {
      return(
        <>
          <WrappedComponent
            { ...this.props }
            showAssign={ this.state.showAssign }
            onChangeDashboardHOC={ this.onChangeDashboardHOC }
            getDashboard={ this.getDashboard }
            getActiveCampaigns={ this.getActiveCampaigns }
            getMostBookedCampaigns={ this.getMostBookedCampaigns }
            setTimePeriod={ this.setTimePeriod }
            dashboard={ this.state.dashboard }
            activeCampaignsCount={ this.state.activeCampaignsCount }
            mostBookedCampaigns={ this.state.mostBookedCampaigns }
            allMenus={ this.state.allMenus } />
        </>
      )
    }

  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    storeSidemenu,
    storeAssignedSidemenu
  })( WithHOC )
}

export default HOC
