import React from "react"
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import _ from 'lodash'

import Lang from "Lang/General"

const MediathekForm = ({
  lang,
  toggleMediaDatabase,
  selectedEditableComponent,
  onChangeSectionEditorState,
  onChangeSectionHOC,
  selectedSection,
  findComponentAndPatch,
}) => {
  return (
    <Card className="mb-2">
      <CardHeader>{ Lang[ "MEDIA_LIBRARY_SETTINGS" ][ lang ]}</CardHeader>
      <CardBody>
        <FormGroup>
          <Label className="w-100">{ Lang[ "TITLE" ][ lang ]}</Label>
          <Button
            color="primary"
            className="mr-2"
            onClick={() => {
              onChangeSectionEditorState( "showCodeTagEditor", true )
            }}
          >
            { Lang[ "EDIT_TEXT" ][ lang ] }
          </Button>
        </FormGroup>
        <FormGroup>
          <Label className="w-100">{ Lang[ "IMAGE" ][ lang ]}</Label>
          <Button
            className="mr-2"
            color="primary"
            onClick={() => {
              onChangeSectionEditorState( "mediaDatabaseFor", "source" )
              toggleMediaDatabase( true )
            }}
          >
            { Lang[ "CHANGE_IMAGE" ][ lang ]}
          </Button>
          <Button
            color={ selectedEditableComponent.source && !_.isEmpty( selectedEditableComponent.source )
              ? 'primary'
              : 'success'
            }
            onClick={ () => {
              let tmp = _.cloneDeep( selectedEditableComponent )
              let tmpSec = _.cloneDeep( selectedSection )
              tmp.source = ''
              findComponentAndPatch( tmp, tmpSec )
              onChangeSectionHOC( "selectedSection", tmpSec )
              onChangeSectionEditorState( "selectedEditableComponent", tmp )
            }} >
            {  
              selectedEditableComponent.source && !_.isEmpty( selectedEditableComponent.source )
                ? Lang[ 'APPLY_DEFAULT_THUMBNAIL' ][ lang ]
                : Lang[ 'DEFAULT_THUMBNAIL_APPLIED' ][ lang ]
            }
          </Button>
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ "VIDEO" ][ lang ]}</Label>
          <Input
            type="text"
            value={
              selectedEditableComponent.video.source
                ? selectedEditableComponent.video.source
                : ""
            }
            onChange={ e => {
              let tmp = _.cloneDeep( selectedEditableComponent )
              let tmpSec = _.cloneDeep( selectedSection )
              tmp.video.source = e.target.value
              findComponentAndPatch( tmp, tmpSec )
              onChangeSectionHOC( "selectedSection", tmpSec )
              onChangeSectionEditorState( "selectedEditableComponent", tmp )
            }}
          />
        </FormGroup>
      </CardBody>
    </Card>
  )
}

export default MediathekForm
