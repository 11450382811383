import React, { Component } from 'react'
import { compose } from 'redux'
import {
  Card, CardBody, CardHeader, CardFooter,
  FormGroup, Label, Input,  
  Button, Modal, ModalHeader, 
  ModalBody, ModalFooter, 
  UncontrolledTooltip 
} from 'reactstrap'
import Select from 'react-select'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PageTitle from 'components/PageTitle'
import CampaignListing from './campaignListing'

import ClientsHOC from './actions'
import UsersHOC from 'containers/UsersManagement/actions'
import WithConsultants from 'containers/OLS/BookedCampaign/actions/Consultant'
import WithCampaigns from 'containers/OLS/BookedCampaign/actions/Campaigns'
import WithBookedCampaigns from 'containers/OLS/BookedCampaign/actions'
import WithCampaignTypes from 'containers/OLS/BookedCampaign/actions/CampaignTypes'

import { convertObjToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

import ARKTable from 'components/Table'

class ClientManagement extends Component {
  state={
    open: false
  }
  componentDidMount = () => {
    this.props.getClients({
      page: 1,
      is_paginated: true,
    })
    this.props.getUsers( convertObjToBase64({
      filter: {
        role_id: 8
      }
    }) )
    this.props.getCampaigns( convertObjToBase64({
      is_paginated: false
    }))
    this.props.getConsultants( convertObjToBase64({
      is_paginated: false,
      consultant: {
        filter: {
          $or: [
            { status: 'ACTIVE' }
          ]
        }
      }
    }))
    this.props.getCampaignTypes( convertObjToBase64({
      is_paginated: false,
      filter: {
        $or: [
          { status: 'ACTIVE' }
        ]
      }
    }))
  }

  handleSecurityModulesRead = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Campaign' })
      return tmpModules.is_read
    }
  }

  getValues = () => {
    let tmp = []
    this.props.users.data && this.props.users.data.length > 0 && this.props.users.data.map( item => {
      tmp.push({
        id: item.id,
        label: `${ item.first_name } ${ item.last_name }`,
        value: item.id
      })
    })
    return tmp
  }

  renderCampaignListing = () => {
    return(
      <Modal
        isOpen={ this.props.showViewCampaign }
        size="xl"
        toggle={ () => this.props.onChangeOlsTeamClients( 'showViewCampaign', false ) }>
        <ModalHeader
          toggle={ () => this.props.onChangeOlsTeamClients( 'showViewCampaign', false ) }>
          { Lang[ 'CAMPAIGN_LISTINGS' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <CampaignListing { ...this.props } />
          { this.props.onLoadSelectedClient && <LoadingOverlay /> }   
        </ModalBody>
        <ModalFooter>
          <Button
            className="mb-2 mr-2"
            color="primary"
            onClick={ () => this.props.onChangeOlsTeamClients( 'showViewCampaign', false ) }>
            { 'Cancel' }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  render = () => {
    let userOptions = []
    this.props.users.data && this.props.users.data.length > 0 && this.props.users.data.map( item => {
      userOptions.push({
        id: item.id,
        label: `${ item.fullname } ( ${ item.email } )`,
        value: `${ item.fullname } ( ${ item.email } )`
      })
    })
    return(
      <>
        <PageTitle
          heading={ Lang[ 'CLIENTS' ][ this.props.data.languageReducer.lang ] }
          icon="pe-7s-users icon-gradient bg-happy-fisher" />
        <Card className="mb-3">
          <CardHeader>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }</CardHeader>
          <CardBody>
            <FormGroup>
              <Label>{ Lang[ 'CENTER_NAME' ][ this.props.data.languageReducer.lang ] }</Label>
              <Input
                name="center-name" id="center-name"
                placeholder={ Lang[ 'SEARCH_WITH_CENTER_NAME' ][ this.props.data.languageReducer.lang ] }
                value={ this.props.center_name }
                onChange={ e => this.props.onChangeOlsTeamClients( 'center_name', e.target.value ) } />
            </FormGroup>
            <FormGroup className='m-0'>
              <Label>{ Lang[ 'SELECT_USER' ][ this.props.data.languageReducer.lang ] }</Label>
              <Select
                options={ this.getValues() }
                value={ this.props.user }
                onChange={ val => {
                  let tmpUser = _.cloneDeep( this.props.user )
                  tmpUser = val
                  this.props.onChangeOlsTeamClients( 'user', tmpUser ) 
                }} />
            </FormGroup>
          </CardBody>
          <CardFooter>
            <Button
              color="primary"
              className="ml-auto"
              onClick={ () => {
                let tmp = {
                  page: 1,
                  is_paginated: true,
                  filter: {
                    center_name: {
                      $like: `%${ this.props.center_name }%`
                    },
                    user_id: this.props.user.id
                  }
                }
                this.props.getClients( tmp )
              }}>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }</Button>
            <Button
              color="danger"
              onClick={ () => {
                let tmp = {
                  page: 1,
                  is_paginated: true
                }
                this.props.onChangeOlsTeamClients( 'center_name', '' )
                return this.props.getClients( tmp )
              }}>{ Lang[ 'RESET' ][ this.props.data.languageReducer.lang ] }</Button>
          </CardFooter>
        </Card>
        <ARKTable
          data={ this.props.clients.data }
          columns={[
            {
              Header: Lang[ 'CENTER' ][ this.props.data.languageReducer.lang ],
              accessor: "center_name"
            },
            {
              Header: Lang[ 'NUMBER_OF_ACTIVE_CAMPAIGN' ][ this.props.data.languageReducer.lang ],
              accessor: "total_campaigns"
            },
            {
              Header: Lang[ 'DOMAIN' ][ this.props.data.languageReducer.lang ],
              accessor: "center_domain"
            },
            {
              Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
              accessor: "id",
              Cell: (row) => (
                <div>
                  {
                    this.handleSecurityModulesRead() && (
                      <>
                        <Button
                          id={ `Client-${ row.original.id }-view-campaign` }
                          className="mb-2 mr-2 btn-icon btn-icon-only" 
                          color="primary" 
                          onClick={ () => {
                            let tmp = {
                              is_paginated: true,
                              page: 1,
                              filter: {
                                center_name: {
                                  $like: `%${ row.original.center_name.toLowerCase() }%`
                                }
                              }
                            }
                            this.props.getBookedCampaigns( convertObjToBase64( tmp ))
                            this.props.onChangeOlsTeamClients( 'showViewCampaign', true )
                            this.props.onChangeOlsTeamClients( 'selectedCenterName', row.original.center_name )
                          }}>
                          <i className="pe-7s-look"> </i>
                        </Button>
                        <UncontrolledTooltip
                          target={ `Client-${ row.original.id }-view-campaign` }
                          placement="top">{ Lang[ 'VIEW_CAMPAIGNS' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                      </>
                    )
                  }
                </div>   
              )}
          ]}
          totalPages={ this.props.clientTotalPages }
          page={ this.props.clients.meta ? this.props.clients.meta.current_page : 1 }
          onChangePage={ pg => {
            let tmp = {
              page: pg,
              is_paginated: true
            }
            this.props.getClients( tmp )
          }}
          showPagination={ false } />
        { this.renderCampaignListing() }
        { ( this.props.data.ajaxReducer.ajaxCallProgress > 0 || this.props.onLoadOlsTeamClients ) && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose(
  ClientsHOC,
  UsersHOC,
  WithCampaignTypes,
  WithBookedCampaigns,
  WithCampaigns,
  WithConsultants
)( ClientManagement )
