import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import Moment from 'moment'
import _ from 'lodash'
import {
  Label, Row, Col, UncontrolledTooltip, Button
} from 'reactstrap'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import ARKTable from 'components/Table'
import EditForm from 'containers/OLS/BookedCampaign/Edit'

import { convertObjToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

const CampaignListing = ({
  bookedCampaigns,
  bookedCampaignsTotalPages,
  selectedBookedCampaign,
  selectedBookedCampaignError,
  getBookedCampaigns,
  getSelectedBookedCampaign,
  showEdit,
  onChangeBookedCampaignHOC,
  selectedCenterName,
  consultants,
  campaignTypes,
  updateBookedCampaign,
  updateSettings,
  onLoadSelectedBookedCampaign,
  onLoadSelectedClient,
  data
}) => {
  const campaignStatus = [
    {
      label: Lang[ 'NEW' ][ data.languageReducer.lang ],
      value: 'NEW',
      color: '#E17058'
    },
    {
      label: Lang[ 'IN_PROGRESS' ][ data.languageReducer.lang ],
      value: 'IN_PROGRESS',
      color: '#B7F5F5'
    },
    {
      label: Lang[ 'ACTIVE' ][ data.languageReducer.lang ],
      value: 'ACTIVE',
      color: '#C2F892'
    },
    {
      label: Lang[ 'COMPLETED' ][ data.languageReducer.lang ],
      value: 'COMPLETED',
      color: '#F7FBFA'
    }
  ]

  const handleSecurityModulesUpdate = () => {
    let tmp = _.find( data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Campaign' })
      return tmpModules.is_update
    }
  }

  const getTrProps = ( state, rowInfo, instance ) => {
    if( rowInfo ){
      let tmp = _.find( campaignStatus, { value: rowInfo.original.status })
      if( tmp ) {
        return {
          style: {
            background: tmp.color,
            color: '#000'
          }
        }
      }
    }
    return {};
  }

  return(
    <Fragment>
      <>
        <h3 className='mb-4'>{ `${ Lang[ 'CAMPAIGN_OF_CENTER' ][ data.languageReducer.lang ] } ${ selectedCenterName }` }</h3>
          <Row>
            <Col md={12}>
              <Label>{ Lang[ 'STATUS' ][ data.languageReducer.lang ] }</Label>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
              {
                campaignStatus.map( item => {
                  return(
                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginRight: '15px' }}>
                      <div style={{ width: '15px', height: '15px', background: item.color, marginRight: '5px', marginBottom: '5px', border: 'solid #000 .5px' }}>
                      </div>
                      <label>{ item.label }</label>
                    </div>
                  )
                })
              }
              </div>
            </Col>
          </Row>
          <ARKTable
            data={ bookedCampaigns.data }
            columns={[
              {
                Header: Lang[ 'TITLE' ][ data.languageReducer.lang ],
                accessor: 'title',
                width: 200
              },
              {
                Header: Lang[ 'STATUS' ][ data.languageReducer.lang ],
                accessor: 'status',
                width: 110
              },
              {
                Header: Lang[ 'CREATED_ON' ][ data.languageReducer.lang ],
                accessor: 'created_at',
                Cell: ({value}) => (
                  Moment( value ).format( 'DD-MM-YYYY' )
                )
              },
              {
                Header: Lang[ 'START_DATE' ][ data.languageReducer.lang ],
                accessor: 'start_date',
                Cell: ({value}) => (
                  Moment( value ).format( 'DD-MM-YYYY' )
                )
              },
              {
                Header: Lang[ 'END_DATE' ][ data.languageReducer.lang ],
                accessor: 'end_date',
                Cell: ({value}) => (
                  Moment( value ).format( 'DD-MM-YYYY' )
                )
              },
              {
                Header: Lang[ 'ONLY_WOMAN' ][ data.languageReducer.lang ],
                accessor: 'only_for_woman',
                Cell: ({value}) => {
                  if( value === true ) {
                    return Lang[ 'YES' ][ data.languageReducer.lang ]
                  } else {
                    return Lang[ 'NO' ][ data.languageReducer.lang ]
                  }
                },
                width: 100
              },
              {
                Header: Lang[ 'FB_BUDGET' ][ data.languageReducer.lang ],
                accessor: 'fa_budget',
                width: 100
              },
              {
                Header: Lang[ 'ADWORDS_BUDGET' ][ data.languageReducer.lang ],
                accessor: 'ga_budget',
                width: 100
              },
              {
                Header: Lang[ 'ACTION' ][ data.languageReducer.lang ],
                accessor: "id",
                Cell: ({value}) => (
                  <div>
                    {
                      handleSecurityModulesUpdate() && (
                        <>
                          <Button
                            id={ `Booked-campaign-${ value }-edit-booked-campaign` }
                            className="mb-2 mr-2 btn-icon btn-icon-only"
                            color="primary"
                            onClick={ () => {
                              getSelectedBookedCampaign( value )
                              onChangeBookedCampaignHOC( 'showEdit', true )
                            }}>
                              <i className="pe-7s-note"> </i>
                          </Button>
                          <UncontrolledTooltip
                            target={ `Booked-campaign-${ value }-edit-booked-campaign` }
                            placement="top">{ Lang[ 'EDIT_BOOKED_CAMPAIGN' ][ data.languageReducer.lang ] }</UncontrolledTooltip>
                        </>
                      )
                    }
                  </div>
                )
              }
            ]}
            defaultPageSize={ 10 }
            showPagination={ false }
            page={ bookedCampaigns.meta ? bookedCampaigns.meta.current_page : 1 }
            totalPages={ bookedCampaignsTotalPages }
            onChangePage={ pg =>{ 
              getBookedCampaigns( convertObjToBase64({
                is_paginated: true,
                page: pg,
                filter: {
                  center_name: {
                    $like: `%${ selectedCenterName.toLowerCase() }%`
                  }
                }  
              }))
            }}
            getTrProps={ getTrProps } />
        <EditForm
          showEdit={ showEdit }
          onChangeBookedCampaignHOC={ onChangeBookedCampaignHOC }
          getSelectedBookedCampaign={ getSelectedBookedCampaign }
          selectedBookedCampaign={ selectedBookedCampaign }
          selectedBookedCampaignError={ selectedBookedCampaignError }
          consultants={ consultants }
          campaignTypes={ campaignTypes.data }
          bookedCampaigns={ bookedCampaigns.data }
          updateBookedCampaign={ updateBookedCampaign }
          updateSettings={ updateSettings }
          onLoadSelectedBookedCampaign={ onLoadSelectedBookedCampaign } />
        { onLoadSelectedClient && <LoadingOverlay /> }
      </>
    </Fragment>
  )
}
const mapStateToProps = state => ({ dataReducer: state })

export default connect( mapStateToProps )( CampaignListing )
