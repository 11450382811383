import React from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

const RoomsDropdown = ({
  isRoomDropdownOpen,
  handleRoomsDropdownClick,
  handleRoomHover,
  handleRoomSelection,
  currentRoom,
  hoveredRoom,
  rooms,
  selectedLanguageShortname
}) => {
  return (
    <div style={{
      minWidth: "fit-content",
      width: "154px",
      height: "38px",
      position: "relative",
      zIndex: "998" }}>
      <button 
        style={{
          position: "relative",
          display: "block",
          padding: "6px 12px",
          border: "1px solid #d4d4d4",
          borderRadius: "4px",
          background: "none",
          justifyContent: "space-between",
          alignItems: "center",
          color: "inherit"
        }}
        onClick= { () => handleRoomsDropdownClick() }>
        <span style={{ 
          paddingRight: "10px",
          fontSize: "16px" }}>
          { currentRoom.roomName[selectedLanguageShortname] }</span>
        { isRoomDropdownOpen? <FaChevronUp/> : <FaChevronDown/> }
      </button>
      { isRoomDropdownOpen && <div style={{ 
          top: "40px",
          position: "absolute",
          border: "1px solid #d4d4d4",
          borderRadius: "4px",
          zIndex: "998"
        }}>
        {
          rooms.map( item => {
            return <button 
              style={{ 
                width: "158px",
                height: "36px",
                minWidth: "100%",
                color: "inherit",
                padding: "3px 6px",
                background: hoveredRoom === item.roomName[selectedLanguageShortname]? "#efefef": "#ffffff",
                border: "none"
              }}
              onClick={ () => handleRoomSelection(item) }
              onMouseEnter={ () => handleRoomHover(item.roomName[selectedLanguageShortname]) }
              >
              { item.roomName[selectedLanguageShortname] }</button>
          })
        }
      </div> }
    </div>
  )
}

export default RoomsDropdown