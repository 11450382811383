import React, { useState } from "react"
import Radium from "proofpoint-radium"
import { Collapse } from "reactstrap"
import _ from 'lodash'

import CoreRenderFunction from '../../core/render'

const HeaderDropdown = props => {
  const [ isDropdownOpen, updateIsDropdownOpen ] = useState( false )
  let {
    id,
    style,
    className,
    item,
    mode,
    selectedLanguageShortname
  } = props

  const findIsCurrentLink = dom => {
    if( dom.navTo && dom.navTo === window.location.pathname ) {
      return true
    } else if( dom.children ) {
      return _.findIndex( dom.children.map( child => findIsCurrentLink( child ) ), item => item === true ) > -1
    } 
    return false
  }

  const findButtonAndPatch = ( dom, isCurrentLink ) => {
    if( dom.style && dom.style[ ':hover' ] && isCurrentLink ){
      dom.style = {
        ...dom.style,
        ...dom.style[ ':hover' ]
      }
    }
    if( dom.type === 'button' ) {
      dom[ 'onClick' ] = () => {
        updateIsDropdownOpen( !isDropdownOpen )
      }
      if( dom.children[ 0 ].type === "i" ){
        dom.children[ 0 ].className = isDropdownOpen ? 'ion-md-remove' : 'ion-md-add'
      } else if( dom.children[ 0 ].type === "div" && dom.children[ 0 ].children.length === 2 ){
        dom.children[ 0 ].children.map( child => {
          let tmp = parseInt(child.style.width) > parseInt(child.style.height) 
            ? 180 : 90
          child.style.transform = isDropdownOpen
            ? `translate(-50%, -50%) rotate(${tmp}deg)`
            : 'translate(-50%, -50%)'
        })
      }
    } else if( dom.children ) {
      dom.children.map( child => findButtonAndPatch( child, isCurrentLink ) )
    }
  }
  let nextLevelItem = []
  return(
    <div
      id={ id }
      style={ style }
      className={ className }>
      {
        item.children && item.children.map( dropdownComponent => {
          if( 
            dropdownComponent.type === 'a' && 
            item.children.length === 1 
          ) {
            let tmpDom = _.cloneDeep( dropdownComponent )
            if( 
              typeof window !== "undefined" &&
              tmpDom.navTo === window.location.pathname &&
              tmpDom.style 
            ){
              tmpDom.style = {
                ...tmpDom.style,
                ...tmpDom.style[ ':hover' ]
              }
            }
            return <CoreRenderFunction
              item={ tmpDom }
              mode={ mode }
              selectedLanguageShortname={ selectedLanguageShortname }
            />
          } else if( 
            dropdownComponent.type === 'a' || 
            dropdownComponent.type === 'header-dropdown' 
          ) {
            let tmpDom = _.cloneDeep( dropdownComponent )
            if(dropdownComponent.type === 'a'){
              if( 
                typeof window !== "undefined" &&
                tmpDom.navTo === window.location.pathname &&
                tmpDom.style 
              ){
                tmpDom.style = {
                  ...tmpDom.style,
                  ...tmpDom.style[ ':hover' ]
                }
              }
            }
            nextLevelItem.push(tmpDom)
          } else if( dropdownComponent.type !== 'a' ){
            let tmpDom = _.cloneDeep( dropdownComponent )
            if( typeof window !== "undefined" ){
              let isCurrentLink = findIsCurrentLink( tmpDom )
              findButtonAndPatch( tmpDom, isCurrentLink )
            }
            return <CoreRenderFunction
              item={ tmpDom }
              mode={ mode }
              selectedLanguageShortname={ selectedLanguageShortname }
            />
          }
        }) 
      }
      <Collapse isOpen={isDropdownOpen}>
        {
          nextLevelItem.map( item => {
            return <CoreRenderFunction
              item={ item }
              mode={ mode }
              selectedLanguageShortname={ selectedLanguageShortname }
            />
          })
        }
      </Collapse>
    </div>
  )
}

export default Radium( HeaderDropdown )