import React, { useState, useEffect } from 'react'
import uniqid from 'uniqid'
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Label,
  Input
} from 'reactstrap'
import _ from 'lodash'

import ButtonStylings from 'components/ButtonSettings/utils/ButtonStylings'
import Lang from 'Lang/General'

const NewComponent = ( props ) => {
  const [ mode, updateMode ] = useState( '' )
  const [ newButton, updateNewButton ] = useState({
    type: 'basic-button',
    style: 'primary_style',
    dom: {}
  })

  useEffect(() => {
    if( mode === 'button' ){
      updateNewButton({
        type: 'basic-button',
        style: 'primary_style',
        dom: {
          id: uniqid(),
          type: 'button',
          style: {
            cursor: 'pointer',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '15px',
            transition: 'all 0.3s linear',
            background: '#000000',
            color: '#ffffff',
            borderTop: '1px solid #000000',
            borderRight: '1px solid #000000',
            borderLeft: '1px solid #000000',
            borderBottom: '1px solid #000000',
            primaryColor: [ 'background', 'borderTop', 'borderRight', 'borderLeft', 'borderBottom' ],
            ':hover': {
              background: '#ffffff',
              color: '#000000',
              borderTop: '1px solid #000000',
              borderRight: '1px solid #000000',
              borderLeft: '1px solid #000000',
              borderBottom: '1px solid #000000',
              primaryColor: [ 'color', 'borderTop', 'borderRight', 'borderLeft', 'borderBottom' ]
            }
          },
          children: [
            {
              id: uniqid(),
              type: 'div',
              variant: 'text',
              style: {
                position: "relative",
                zIndex: "2",
              },
              content: {
                "en": "<div>Button text</div>",
                "de": "<div>Button text</div>",
                "it": "<div>Button text</div>",
                "nl": "<div>Button text</div>"
              }
            }
          ],
          className: 'basic-button',
          navTo: '',
          variant: 'redirect',
          isContentCenter: false,
          paddingTop: 0,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0
        }
      })
    }
  }, [ mode ])

  const onChangeNewButton = ( type, style ) => {
    let tmpNewButton = _.cloneDeep( newButton )
    tmpNewButton.type = type
    tmpNewButton.style = style
    tmpNewButton.dom.className = type
    tmpNewButton.dom.style = ButtonStylings[ type ][ style ]
    tmpNewButton.dom.children[ 0 ].style = ButtonStylings[ type ][ 'text_style' ]
    updateNewButton( tmpNewButton )
  }

  const renderColorSelectionButton = ( background, style ) => (
    <div className='col-md-3' >
      <button
        key={ style }
        className='w-100'
        style={{
          border: style === newButton.style ? '5px solid #5cb85c' : 'none',
          marginRight: '15px',
          height: "100px",
          background: background
        }}
        onClick={ () => onChangeNewButton( newButton.type, style ) }/>
    </div>
  )

  return (
    <Card className="mb-2">
      <CardHeader>{ Lang[ 'NEW_COMPONENT' ][ props.lang ] }</CardHeader>
      <CardBody>
        <Button 
          color="primary mb-2 mr-2"
          onClick={() => {
            let tmp = {
              id: uniqid(),
              type: 'div',
              variant: 'text',
              content: {
                [ props.lang ]: Lang[ 'CREATED_TEXT' ][ props.lang ] 
              }
            }
            updateMode( '' )
            return props.createComponent( tmp )
          }}>
          { Lang[ 'TEXT' ][ props.lang ] }
        </Button>
        <Button 
          color="primary mb-2 mr-2"
          onClick={() => {
            let tmp = {
              id: uniqid(),
              type: 'div',
              style: {
                display: 'flex',
                justifyContent: 'center',
              },
              children: [
                {
                  id: uniqid(),
                  type: 'img',
                  source: 'https://d3386uh5nemg6b.cloudfront.net/shared_media_drive/8bb6c17838643f9691cc6a4de6c51709/original/image.png',
                  style: {
                    width: '90%'
                  }
                }
              ]
            }
            updateMode( '' )
            return props.createComponent( tmp )
          }}>
          { Lang[ 'IMAGE_PAGE_EDITOR' ][ props.lang ] }
        </Button>
        <Button
          color="primary mb-2 mr-2"
          onClick={ () => updateMode( 'button' ) }>
          { Lang[ 'BUTTON' ][ props.lang ] }
        </Button>
        { 
          props.forms?.length > 0 && (
            <Button
              color="primary mb-2 mr-2" 
              style={{ marginRight: '10px' }}
              onClick={ () => updateMode( 'forms' ) }>
              { Lang[ 'FORMS' ][ props.lang ] }
            </Button>
          )
        }
        <Button 
          color="primary mb-2 mr-2"
          onClick={() => {
            let tmp =  {
              id: uniqid(),
              type: 'mediathek',
              style: { 
                position: 'relative'
              },
              video: {
                id: uniqid(),
                style: {},
                source: 'https://www.youtube.com/embed/'
              },
              source: '',
              content: {
                en: '<div></div>',
                de: '<div></div>',
                nl: '<div></div>',
                it: '<div></div>'
              }
            }
            updateMode( '' )
            return props.createComponent( tmp )
          }}>
          { Lang[ 'VIDEO' ][ props.lang ] }
        </Button>
        {
          mode === 'forms' && (
            <>
              <FormGroup>
                <Label>{ Lang[ 'SELECT_FORM' ][ props.lang ] }</Label>
                <Input 
                  type="select"
                  value={ props.selectedForm.id } 
                  onChange={ e => props.onChangeFormsHOC( 'selectedForm', _.find( props.forms, { id: parseInt( e.target.value ) } ) ) }>
                  <option></option>  
                  {
                    props.forms && props.forms.map( item => {
                      return (
                        <option key={ item.id } value={ item.id }>
                          { item.name[ props.lang ] }
                        </option>
                      )
                    })
                  }
                </Input>
              </FormGroup>
              <Button 
                color="primary"
                disabled={ _.isEmpty( props.selectedForm ) }
                onClick={() => {
                  let tmp = {
                    id: uniqid(),
                    type: 'div',
                    children: [
                      {
                        id: uniqid(),
                        type: 'templateForm',
                        formId: props.selectedForm.id
                      }
                    ]
                  }
                  props.createComponent( tmp )
                  props.onChangeFormsHOC( 'selectedForm', {} )
                }}>
                { Lang[ 'SUBMIT' ][ props.lang ] }
              </Button>
            </>
          )
        }
      </CardBody>
      {
        mode === 'button' && (
          <CardBody>
            <FormGroup>
              <Label>{ Lang[ 'BUTTON_TYPE' ][ props.lang ] }</Label>
              <div className='row' >
                { 
                  _.map( ButtonStylings, ( value, key ) => (
                    <div className='col-md-4' >
                      <button 
                        key={ key }
                        className='w-100'
                        style={{
                          border: key === newButton.type ? '5px solid #5cb85c' : 'none',
                          marginRight: '15px',
                          height: "100px",
                          backgroundImage: `url(${ value.img })`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center center"
                        }}
                        onClick={ () => onChangeNewButton( key, newButton.style ) }/>
                    </div>
                  ))
                }
              </div>
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'BUTTON_COLOR' ][ props.lang ] }</Label>
              <div className='row' >
                { renderColorSelectionButton( '#0d6efd', 'primary_style' ) }
                { renderColorSelectionButton( '#6c757d', 'secondary_style' ) }
                { renderColorSelectionButton( '#d9534f', 'danger_style' ) }
                { renderColorSelectionButton( '#000000', 'button_style' ) }
              </div>
            </FormGroup>
            <Button 
              color="primary"
              onClick={ () => {
                props.createComponent( newButton.dom ) 
                updateMode( '' )
              }}>
              { Lang[ 'SUBMIT' ][ props.lang ] }
              </Button>
          </CardBody>
        )
      }
    </Card>
  )
}

export default NewComponent