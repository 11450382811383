import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import ConfirmDeleteModal from 'components/Modal/delete'
import { Get, Post, Delete } from 'utils/axios'
import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      showCreateLandingPage: false,
      showPromptModal: false,
      loading: false,
      toRemoveID: '',
      newLandingPage: {
        title: '',
        url: '',
        description: ''
      },
      landingPageError: ''
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })
    requestError = error => toast.error( error )

    onChangeLandingPageHOC = ( key, val ) => this.setState({ [key]: val })

    addLandingPage = data => Post(
      `/api/v1/ols_booked_campaigns/${ this.props.selectedBookedCampaign.booked_campaign.id }/landing_pages`,
      data,
      this.addLandingPageSuccess,
      this.addLandingPageError,
      this.load
    )
    addLandingPageSuccess = payload => {
      this.setState({ 
        showCreateLandingPage: false,
        newLandingPage: {
          title: '',
          url: '',
          description: ''
        } 
      }, () => {
        this.requestSuccess( Lang[ 'LP_ADD_SUCCESS' ][ this.props.data.languageReducer.lang ] )
        this.props.getSelectedBookedCampaign( this.props.selectedBookedCampaign.booked_campaign.id )
      })
    }
    addLandingPageError = error => this.setState({ landingPageError: error })

    removeLandingPage = id => Delete(
      `/api/v1/ols_booked_campaigns/${ this.props.selectedBookedCampaign.booked_campaign.id }/landing_pages/${ id }`,
      this.removeLandingPageSuccess,
      this.removeLandingPageError,
      this.load
    )
    removeLandingPageSuccess = payload => {
      this.requestSuccess( Lang[ 'LP_REMOVE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.props.getSelectedBookedCampaign( this.props.selectedBookedCampaign.booked_campaign.id )
      this.setState({ showPromptModal: false, toRemoveID: '' })
    }
    removeLandingPageError = error => this.setState({ landingPageError: error })

    sendInformationToCustomer = () => Get(
      ``,
      this.sendInformationToCustomerSuccess,
      this.sendInformationToCustomerError,
      this.load
    )
    sendInformationToCustomerSuccess = payload => this.requestSuccess( Lang[ 'SEND_INFO_SUCCESS' ][ this.props.data.languageReducer.lang ] )
    sendInformationToCustomerError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            showCreateLandingPage={ this.state.showCreateLandingPage }
            landingPages={ this.state.landingPages }
            newLandingPage={ this.state.newLandingPage }
            landingPageError={ this.state.landingPageError }
            onChangeLandingPageHOC={ this.onChangeLandingPageHOC }
            addLandingPage={ this.addLandingPage }
            removeLandingPage={ this.removeLandingPage }
            sendInformationToCustomer={ this.sendInformationToCustomer } />
          {
            this.state.showPromptModal && (
              <ConfirmDeleteModal
                handleModalClose={() => this.setState({ showPromptModal: false })}
                confirmAction={() => {
                  this.removeLandingPage( this.state.toRemoveID )
                  this.setState({ showPromptModal: false })
                }}
                content={ 'Are you sure to remove the selected data?' }
                lang={ this.props.data.languageReducer.lang } />
            )
          }
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC