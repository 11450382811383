import React from 'react'
import { Container } from 'reactstrap'
import _ from 'lodash'

import RenderDOM from '../RenderDOM'

const ContainerComponent = ({
  content,
  mode,
  onChangeFormBuilderState,
  formValues,
  onPatchFormValues,
  onClickSubmitForm,
  createSubmittedFormMedia,
  currentPage,
  dom,
  findObjectAndPatch,
  togglePageSelection,
  togglePageSelectable,
  duplicatePage,
  currentLanguage
}) => {
  return content.page ? (
    content.page === currentPage ? (
      <Container 
        className="p-0"
        style={ 
          content.mapWith && content.mapWith === 'cover' ? ({
            display: "flex",
            flex: "1",
            alignItems: "center",
            justifyContent: "center"
          }) : ({}) 
        } >
        {
          content.children && content.children.map( item => {
            return <RenderDOM                       
              content={ item }
              onChangeFormBuilderState={ onChangeFormBuilderState }
              mode={ mode }
              formValues={ formValues }
              onPatchFormValues={ onPatchFormValues }
              onClickSubmitForm={ onClickSubmitForm }
              createSubmittedFormMedia={ createSubmittedFormMedia }
              currentPage={ currentPage }
              dom={ dom }
              findObjectAndPatch={ findObjectAndPatch }
              data={ {} }
              isInputDisabled={ content.selectable && !content.selected }
              togglePageSelection={ togglePageSelection }
              togglePageSelectable={ togglePageSelectable }
              duplicatePage={ duplicatePage }
              currentLanguage={ currentLanguage }
            />
          })
        }
      </Container>
    ) : (
      <></>
    )
  ) : (
    <Container 
      style={ 
        content.mapWith && content.mapWith === 'cover' ? ({
          display: "flex",
          flex: "1",
          alignItems: "center",
          justifyContent: "center"
        }) : ({}) 
      } >
      {
        content.children && content.children.map( item => {
          return <RenderDOM                       
            content={ item }
            onChangeFormBuilderState={ onChangeFormBuilderState }
            mode={ mode }
            formValues={ formValues }
            onPatchFormValues={ onPatchFormValues }
            onClickSubmitForm={ onClickSubmitForm }
            createSubmittedFormMedia={ createSubmittedFormMedia }
            currentPage={ currentPage }
            dom={ dom }
            findObjectAndPatch={ findObjectAndPatch }
            data={ {} }
            togglePageSelectable={ togglePageSelectable }
            duplicatePage={ duplicatePage }
            currentLanguage={ currentLanguage }
          />
        })
      }
    </Container>
  )
}

export default ContainerComponent