import React, { Component } from 'react'
import Axios from 'axios'
import { connect } from 'react-redux'

import { getUserProfile } from 'actions/profile'

class StoreToken extends Component {

  componentDidMount = () => {
    Promise.all([
      localStorage.setItem( 'PROOFPOINT_ARK_MANAGEMENT', `${ this.props.match.params.id }` )
    ]).then( async () => {
      Axios.defaults.headers = {
        common : {
          Authorization: `${ this.props.match.params.id }`
        }
      }
      await this.props.getUserProfile()
      this.props.history.push( '/dashboard/client' )
    })
  }

  render = () => {
    return (
      <div></div>
    )
  }
}

const mapStateToProps = state => ({ data: state })

export default connect( mapStateToProps, {
  getUserProfile
})( StoreToken )