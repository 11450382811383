import React from 'react'

const LoadingIndicator = () => {
  return (
    <div
      style={{ 
        alignItems: 'center',
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        width: '100vw',
        textAlign: 'center',
        lineHeight: '1.6rem',
        position: 'fixed', 
        backgroundColor: 'rgba(0,0,0,0.3)', 
        top: 0, 
        left: 0, 
        zIndex: 9999 }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '0' }}>
        <div className="text-center">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoadingIndicator