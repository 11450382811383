import React, { Component } from 'react'
import { 
  Container, 
  Row
} from 'reactstrap'
import _ from 'lodash'

import CodeTagEditor from './CodeTagEditor'

import './index.scss'

class RTEUtilities extends Component {
  state = {
    showSketchPicker: false,
    showCodeTagEditor: false
  }

  render = () => {
    return (
      <Container style={{ marginBottom: '15px' }}>
        <Row>
          {
            this.props.selectedEditableComponent 
              && !_.isEmpty( this.props.selectedEditableComponent )
              && (
              <CodeTagEditor
                showCodeTagEditor={ this.props.showCodeTagEditor }
                selectedEditableComponent={ this.props.selectedEditableComponent }
                onUpdateRTEContentWithListStyle={ this.props.onUpdateRTEContentWithListStyle }
                onChangeSectionEditorState={ this.props.onChangeSectionEditorState }
                onPatchRendererComponent={ this.props.onPatchRendererComponent }
                lang={ this.props.lang } />
            )
          }
        </Row>
      </Container>
    )
  }
}

export default RTEUtilities