import React, { Component, Fragment } from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CustomInput
} from 'reactstrap'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import _ from 'lodash'
import ReactSelect from 'react-select'

import MultiLangInput from 'components/Input/MultiLang'
import MultilingualRTE from 'components/RTE/MultiLang'

import Lang from 'Lang/General'

import './index.scss'

class FaqForm extends Component {
  state = {
    selectedYoutubeUrlLang: '',
    categoryOptions: []
  }

  componentDidMount = () => {
    let tmpCategories = []
    this.props.faqCategories.map( category => tmpCategories.push( {
      id: category.id,
      label: category.name[ this.props.selectedLanguageShortname ],
      value: category.name[ this.props.selectedLanguageShortname ]
    } ) )

    this.setState({
      categoryOptions: tmpCategories,
      selectedYoutubeUrlLang: this.props.selectedLanguageShortname,
    })
  }
  
  LangList = [ 'en', 'de', 'nl', 'it', 'fr' ]

  updateFaqWithLang = ( key, lang, value ) => {
    let tmp = _.cloneDeep( this.props.currentFaq )
    tmp[ key ][ lang ] = value
    this.props.onChangeFaqsHOC( this.props.faqType, tmp )
  }

  updateFaq = ( key, value ) => {
    let tmp = _.cloneDeep( this.props.currentFaq )
    tmp[ key ] = value
    this.props.onChangeFaqsHOC( this.props.faqType, tmp ) 
  }

  render = () => {
    let {
      selectedLanguageShortname,
      currentFaq,
    } = this.props

    return(
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Container fluid>
            <Row>
              <Col md={ 8 } >
                <Form>
                  <FormGroup>
                    <MultiLangInput
                      label={ Lang[ 'QUESTION' ][ selectedLanguageShortname ] }
                      value={ currentFaq.title }
                      onChange={ val => this.updateFaq( 'title', val )}
                      selectedLanguage={ selectedLanguageShortname }
                    />
                  </FormGroup>
                  <FormGroup>
                    <MultilingualRTE
                      label={ Lang[ 'ANSWER' ][ selectedLanguageShortname ] }
                      value={ currentFaq.description }
                      onChange={ data => this.updateFaq( 'description', data ) }
                      selectedLanguage={ selectedLanguageShortname }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Row className='mb-2' >
                      <Col className='d-flex align-items-center' sm={ 6 } >
                        <Label className='mb-0' for='name' >{ Lang[ 'YOUTUBE_URL' ][ selectedLanguageShortname ] } : </Label>
                      </Col>
                      <Col className='d-flex justify-content-end' sm={ 6 } >{ 
                        this.LangList.map( lang => <Button
                          className='mr-1'
                          color={ lang === this.state.selectedYoutubeUrlLang ? 'success' : 'primary' }
                          onClick={ () => this.setState({ selectedYoutubeUrlLang: lang }) }>{ lang }</Button> ) 
                      }</Col>
                    </Row>
                    <Row>
                      <Col className='d-flex flex-direction-row align-items-center' md={ 12 } >
                        <Label 
                          style={{ 
                            whiteSpace: "nowrap",
                            margin: "0 15px 0 0" }}>
                          https://www.youtube-nocookie.com/embed/</Label>
                        <Input
                          id='youtube_video_id'
                          name='youtube_video_id'
                          placeholder='id'
                          value={ currentFaq.youtube_video_id[ this.state.selectedYoutubeUrlLang ] }
                          onChange={ e => this.updateFaqWithLang( 'youtube_video_id', this.state.selectedYoutubeUrlLang, e.target.value ) } />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Label>{ Lang[ 'SELECT_CATEGORY' ][ selectedLanguageShortname ] }</Label>
                    <ReactSelect
                      isMulti
                      name="faqCategories"
                      options={ this.state.categoryOptions }
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={ value => this.updateFaq( 'faq_categories', value ) }
                      value={ currentFaq.faq_categories }
                      menuPosition={'fixed'} />
                  </FormGroup>
                  <FormGroup>
                    <CustomInput
                      id='is_view_faqs'
                      type='checkbox'
                      label={ Lang[ 'VIEW_FAQS' ][ selectedLanguageShortname ] }
                      checked={ currentFaq.is_view_faqs }
                      onChange={ e => this.updateFaq( 'is_view_faqs', e.target.checked ) } />
                  </FormGroup>
                  <FormGroup>
                    <CustomInput
                      id='is_show_help'
                      type='checkbox'
                      label={ Lang[ 'SHOW_HELP' ][ selectedLanguageShortname ] }
                      checked={ currentFaq.is_show_help }
                      onChange={ e => this.updateFaq( 'is_show_help', e.target.checked ) } />
                  </FormGroup>
                  <FormGroup>
                    <Label>{ Lang[ 'STATUS' ][ selectedLanguageShortname ] }</Label>
                    <Input
                      id='status'
                      name='status'
                      type='select'
                      value={ currentFaq.status }
                      onChange={ e => this.updateFaq( 'status', e.target.value ) }>
                      <option value='ACTIVE' >{ Lang[ 'ACTIVE' ][ selectedLanguageShortname ] }</option>
                      <option value='INACTIVE' >{ Lang[ 'INACTIVE' ][ selectedLanguageShortname ] }</option>
                      <option value='ARCHIVED' >{ Lang[ 'ARCHIVED' ][ selectedLanguageShortname ] }</option>
                    </Input>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </Container>
        </ReactCSSTransitionGroup>
      </Fragment>
    )
  }
}

export default FaqForm