import React, { Component } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      centerGroups: [],
      loading: false
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    getCenterGroups = () => Get(
      `/api/v1/center_groups?query=${ convertObjToBase64({ 
        is_paginated: false,
        filter: {
          $or: [
            { status: 'ACTIVE' }
          ]
        } 
      }) }`,
      this.getCenterGroupsSuccess,
      this.getCenterGroupsError,
      this.load
    )
    getCenterGroupsSuccess = payload => this.setState({ centerGroups: payload.data })
    getCenterGroupsError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          getCenterGroups={ this.getCenterGroups }
          centerGroups={ this.state.centerGroups }
          onLoadCenterGroups={ this.state.loading } />
      )
    }
  }
  return WithHOC
}

export default HOC