import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import Moment from 'moment'

import { Get } from 'utils/axios'
import Lang from 'Lang/General'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state={
      loading: false,
      voucherReports: [],
      voucherReportPages: [],
      voucherReportsDateQuery: '',
      dateQueryString: Lang[ 'TOTAL' ][ this.props.data.languageReducer.lang ]
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )

    onChangeVoucherReportsHOC = ( key, value ) => this.setState({ [ key ]: value })

    getVoucherReports = ( query, hasDateFilter ) => Get(
      `/api/v1/ols_voucher_reports?query=${ query }`,
      payload => this.getVoucherReportsSuccess( payload, hasDateFilter ),
      this.getVoucherReportsError,
      this.load
    )
    getVoucherReportsSuccess = ( payload, hasDateFilter ) => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      this.setState({ 
        voucherReports: payload,
        voucherReportPages: tmpTotalPages,
        voucherReportsDateQuery: hasDateFilter 
          ? this.state.voucherReportsDateQuery
          : '',
        dateQueryString: hasDateFilter
          ? Moment( this.state.voucherReportsDateQuery ).locale( this.props.data.languageReducer.lang ).format( 'MMMM YYYY' )
          : Lang[ 'TOTAL' ][ this.props.data.languageReducer.lang ]
      })
    }
    getVoucherReportsError = error => toast.error( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadVoucherReportsHOC={ this.state.loading }
          onChangeVoucherReportsHOC={ this.onChangeVoucherReportsHOC }
          voucherReportsDateQuery={ this.state.voucherReportsDateQuery }
          dateQueryString={ this.state.dateQueryString }
          voucherReports={ this.state.voucherReports }
          voucherReportPages={ this.state.voucherReportPages }
          getVoucherReports={ this.getVoucherReports } />
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC