import React, { Component } from 'react'
import { Button, Tooltip } from '@material-ui/core'
import Lang from 'Lang/General'

class RightPanel extends Component {
  render = () => {
    return (
      <div className="renderer-right">
        <div className="btn-right">
          <Tooltip title={ Lang[ 'EDIT_PAGE_JSON' ][ this.props.lang ] } placement={ 'left' }>
            <Button
              classes={{ root: 'btn btn-primary' }}
              onClick={() => this.props.updateMainContainerState( 'showPageJSONEditorDialog', true )}>
              <span className="icon">JSON</span>
            </Button>
          </Tooltip>
        </div>
        <div className="btn-right">
          <Tooltip title={ Lang[ 'LANGUAGE' ][ this.props.lang ] } placement={ 'left' }>
            <Button
              classes={{ root: 'btn btn-primary' }}
              onClick={() => this.props.updateMainContainerState( 'showLanguageDialog', true )}>
              <span className="icon">L</span>
            </Button>
          </Tooltip>
        </div>
        <div className="btn-right">
          <Tooltip title={ Lang[ 'ADD_SECTION' ][ this.props.lang ] } placement={ 'left' }>
            <Button
              classes={{ root: 'btn btn-primary' }}
              onClick={() => this.props.updateMainContainerState( 'showSectionPicker', true )}>
              <span className="icon">S</span>
            </Button>
          </Tooltip>
        </div>
      </div>
    )
  }
}

export default RightPanel