import React, { Component } from 'react'
import ReactHTMLParser from 'react-html-parser'

class GoogleMap extends Component {
  render = () => {
    let {
      item,
      content,
      mode,
      onChangeSectionEditorState,
      selectedEditableComponent
    } = this.props
    return(
      <div
        className="container"
        style={{
          outline: mode === 'edit' ? selectedEditableComponent.id === item.id ? '5px solid #007bff' : 'none' : 'none'
        }}
        onClick={
          mode === 'edit' ? ( e ) => {
            onChangeSectionEditorState( 'selectedEditableComponent', item )
          } : () => {}
        }>
        { ReactHTMLParser( content ) }
      </div>
    )
  }
}

export default GoogleMap
