import React from "react"
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input
} from "reactstrap"
import _ from 'lodash'

import Lang from "Lang/General"

const FormSelector = props => {
  let {
    forms,
    selectedLanguage,
    findComponentAndPatch,
    selectedEditableComponent,
    onChangeSectionEditorState,
    onChangeSectionHOC,
    selectedSection
  } = props

  return (
    <Card className="mb-2">
      <CardHeader>{ Lang[ "FORM_SELECTOR" ][ selectedLanguage ] }</CardHeader>
      <CardBody>
        <FormGroup>
          <Label>{ Lang[ "SELECT_FORM" ][ selectedLanguage ] }</Label>
          <Input
            type="select"
            value={ selectedEditableComponent.formId }
            onChange={ e => {
              let tmp = _.cloneDeep( selectedEditableComponent )
              let tmpSection = _.cloneDeep( selectedSection )
              tmp.type = 'templateForm'
              tmp.formId = parseInt( e.target.value )
              findComponentAndPatch( tmp, tmpSection )
              onChangeSectionEditorState( 'selectedEditableComponent', tmp )
              onChangeSectionHOC( 'selectedSection', tmpSection )
            }}
          >
            <option></option>
            {
              forms.length > 0 && forms.map( form => {
                return (
                  <option value={ form.id }>
                    { form.name[ selectedLanguage ] }
                  </option>
                )
              })
            }
          </Input>
        </FormGroup>
      </CardBody>
    </Card>
  )
}

export default FormSelector
