import React from 'react'
import {
  Input,
  Label,
  FormGroup,
  UncontrolledTooltip
} from 'reactstrap'
import Moment from 'moment'
import ReactHTMLParser from 'react-html-parser'

import { generateFieldLabelStr } from '../utils'

const DateField = ({
  content,
  formValues,
  onPatchFormValues,
  isInputDisabled,
  currentLanguage
}) => {
  return(
    <FormGroup id={ content.id } style={{ padding: content.padding, marginBottom: 0,  ...content.style  }}>
      <Label className="ck-content" style={{ fontSize: content.fontSize, width: "100%" }}>
        { ReactHTMLParser( generateFieldLabelStr(content, formValues, currentLanguage) ) }
        {
          content.showQuickInfo && 
          document.getElementById(`quick-info-tooltip-${content.id}`) && (
            <UncontrolledTooltip
              target={`quick-info-tooltip-${content.id}`} 
              placement="top">
              { content.preview_text }
            </UncontrolledTooltip>
          )
        }
      </Label>
      <Input
        disabled={ isInputDisabled }
        type="date"
        value={ Moment( formValues[ content.dataMapIndex ][ 'value' ] ).format( 'YYYY-MM-DD' ) }
        onChange={ e => onPatchFormValues( content.dataMapIndex, 'value', Moment( e.target.value ).utc().format() ) }/>
    </FormGroup>
  )
}

export default DateField