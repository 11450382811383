import React from 'react'
import { BeatLoader } from 'react-spinners'
import _ from 'lodash'
import {
  Label, Col, Button, 
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, FormFeedback, FormText,
  InputGroup, InputGroupText, InputGroupAddon, Input
} from 'reactstrap'
import { connect } from 'react-redux'

import DropdawnSearchSelect from 'components/SearchSelect'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'
import getDomainURL from 'utils/api'
import { validate } from 'utils/validatePhoneNumber'

const AddLocationForm = ({
  onLoadCenters,
  onLoadCountries,
  selectedCenter,
  onChangeNewLocationData,
  data,
  errorMessage,
  getStates,
  getValueForSelect,
  countriesOptions,
  statesOption,
  newLocationData,
  createCenterLocation,
  countries,
  addItemModal,
  onChangeCenterHOC,
}) => {
  const getCallingCode = ( newLocationData ) => {
    let tmpFound = _.find( countries, { name: newLocationData.country } )
    if( tmpFound ) {
      return tmpFound.calling_code[0]
    }
  }

  return(
    <Modal
      isOpen={ addItemModal }
      toggle={ () => {
        Promise.all([
          onChangeCenterHOC( 'addItemModal', false )
        ]).then(
          onChangeCenterHOC( 'errorMessage', {} )
        )
      }}
      size={ 'lg' }>
      <ModalHeader
        toggle={ () => {
          Promise.all([
            onChangeCenterHOC( 'addItemModal', false )
          ]).then(
            onChangeCenterHOC( 'errorMessage', {} )
          )
        }}>{ Lang[ 'CREATE_LOCATION' ][ data.languageReducer.lang ] }</ModalHeader>
      <ModalBody className="p-3 mb-3">
        <Form>
          <FormGroup row>
            <Label for="ext_field_1" sm={ 2 }>{ 'Name' }
              <span style={{ color: 'red' }}>{ '*' }</span>
            </Label>
            <Col sm={ 10 }>
              <Input
                placeholder={ Lang[ 'ENTER_LOCATION_NAME' ][ data.languageReducer.lang ] }
                value={ newLocationData.name }
                onChange={ e => onChangeNewLocationData( 'name', e.target.value ) } />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="ext_field_1" sm={ 2 }>{ Lang[ 'EXTRA_FIELD_1' ][ data.languageReducer.lang ] }</Label>
            <Col sm={ 10 }>
              <Input
                name="ext_field_1" id="ext_field_1"
                placeholder={ Lang[ 'ENTER_EXTRA_FIELD_1' ][ data.languageReducer.lang ] }
                value={ newLocationData.ext_field_1 }
                onChange={ e => onChangeNewLocationData( 'ext_field_1', e.target.value ) } />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="ext_field_2" sm={ 2 }>{ Lang[ 'EXTRA_FIELD_2' ][ data.languageReducer.lang ] }</Label>
            <Col sm={ 10 }>
              <Input
                name="ext_field_2" id="ext_field_2"
                placeholder={ Lang[ 'ENTER_EXTRA_FIELD_2' ][ data.languageReducer.lang ] }
                value={ newLocationData.ext_field_2 }
                onChange={ e => onChangeNewLocationData( 'ext_field_2', e.target.value ) }/>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="country" sm={ 2 }>{ Lang[ 'COUNTRY' ][ data.languageReducer.lang ] }<span style={{ color: 'red' }}>{ '*' }</span></Label>
            <Col sm={ 10 } style={{ zIndex: 999 }}>
              <DropdawnSearchSelect
                options={ countriesOptions }
                onChange={ v => {
                  onChangeNewLocationData( 'country', v.value )
                  let tmpCountry = _.find( countriesOptions, { value: v.value })
                  if( tmpCountry ){
                    getStates( tmpCountry.id )
                  }
                }}
                value={ getValueForSelect( 'country' ) }
                placeholder={ Lang[ 'SELECT_COUNTRY' ][ data.languageReducer.lang ] }/>
              <FormText color="danger">{ errorMessage && errorMessage.country ? errorMessage.country : '' }</FormText>
            </Col>
          </FormGroup>
          {
            onLoadCountries && <BeatLoader
              sizeUnit={"px"}
              size={30}
              color={'#123abc'}
              loading={true}
            />
          }
          {
            newLocationData.country && (
              <FormGroup row>
                <Label for="country" sm={ 2 }>{ Lang[ 'STATE' ][ data.languageReducer.lang ] }<span style={{ color: 'red' }}>{ '*' }</span></Label>
                <Col sm={ 10 }>
                  <DropdawnSearchSelect
                    options={ statesOption }
                    onChange={ v => onChangeNewLocationData( 'state', v.value ) }
                    value={ getValueForSelect( 'state' ) }
                    placeholder={ Lang[ 'SELECT_STATE' ][ data.languageReducer.lang ] }/>
                  <FormText color="danger">{ errorMessage && errorMessage.state ? errorMessage.state : '' }</FormText>
                </Col>
              </FormGroup>
            )
          }
          <FormGroup row>
            <Label for="city" sm={ 2 }>{ Lang[ 'CITY' ][ data.languageReducer.lang ] }<span style={{ color: 'red' }}>{ '*' }</span></Label>
            <Col sm={ 10 }>
              <Input
                name="city" id="city"
                placeholder={ Lang[ 'ENTER_CITY' ][ data.languageReducer.lang ] }
                value={ newLocationData.city }
                onChange={ e => onChangeNewLocationData( 'city', e.target.value ) }
                invalid={ errorMessage && errorMessage.city ? true : false }/>
              <FormFeedback>{ errorMessage && errorMessage.city ? errorMessage.city : '' }</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="street" sm={ 2 }>{ Lang[ 'STREET' ][ data.languageReducer.lang ] }<span style={{ color: 'red' }}>{ '*' }</span></Label>
            <Col sm={ 10 }>
              <Input
                name="street" id="street"
                placeholder={ Lang[ 'ENTER_STREET' ][ data.languageReducer.lang ] }
                value={ newLocationData.street }
                onChange={ e => onChangeNewLocationData( 'street', e.target.value ) }
                invalid={ errorMessage && errorMessage.street ? true : false }/>
              <FormFeedback>{ errorMessage && errorMessage.street ? errorMessage.street : '' }</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="house_number" sm={ 2 }>{ Lang[ 'HOUSE_NUMBER' ][ data.languageReducer.lang ] }<span style={{ color: 'red' }}>{ '*' }</span></Label>
            <Col sm={ 10 }>
              <Input
                name="house_number" id="house_number"
                placeholder={ Lang[ 'ENTER_HOUSE_NUMBER' ][ data.languageReducer.lang ] }
                value={ newLocationData.house_number }
                onChange={ e => onChangeNewLocationData( 'house_number', e.target.value ) }
                invalid={ errorMessage && errorMessage.house_number ? true : false }/>
              <FormFeedback>{ errorMessage && errorMessage.house_number ? errorMessage.house_number : '' }</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="postcode" sm={ 2 }>{ Lang[ 'POSTCODE' ][ data.languageReducer.lang ] }<span style={{ color: 'red' }}>{ '*' }</span></Label>
            <Col sm={ 10 }>
              <Input
                name="postcode" id="postcode"
                placeholder={ Lang[ 'ENTER_POSTCODE' ][ data.languageReducer.lang ] }
                value={ newLocationData.postcode }
                onChange={ e => onChangeNewLocationData( 'postcode', e.target.value ) }
                invalid={ errorMessage && errorMessage.postcode ? true : false }/>
              <FormFeedback>{ errorMessage && errorMessage.postcode ? errorMessage.postcode : '' }</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="phone_number" sm={ 2 }>{ Lang[ 'PHONE_NUMBER' ][ data.languageReducer.lang ] }<span style={{ color: 'red' }}>{ '*' }</span></Label>
            <Col sm={ 10 }>
              <InputGroup>
                <Input
                  value={ getCallingCode( newLocationData ) }
                  disabled={ true }
                  placeholder={ Lang[ 'CHOOSE_COUNTRY' ][ data.languageReducer.lang ] } />
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>-</InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="040"
                  value={ newLocationData.area_code }
                  onChange={ e => {
                    if( e.target.value.length > 0 ){
                      if( validate( e.target.value ) ) {
                        onChangeNewLocationData( 'area_code', e.target.value )
                      }
                    } else {
                      onChangeNewLocationData( 'area_code', e.target.value )
                    }
                  }} />
                <InputGroupAddon addonType="append">
                  <InputGroupText>-</InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder={ Lang[ 'ENTER_PHONE_NUMBER' ][ data.languageReducer.lang ] }
                  value={ newLocationData.number }
                  onChange={ e => {
                    if( e.target.value.length > 0 ){
                      if( validate( e.target.value ) ) {
                        onChangeNewLocationData( 'number', e.target.value )
                      }
                    } else {
                      onChangeNewLocationData( 'number', e.target.value )
                    }
                  }} />
              </InputGroup>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="email" sm={ 2 }>{ Lang[ 'EMAIL' ][ data.languageReducer.lang ] }<span style={{ color: 'red' }}>{ '*' }</span></Label>
            <Col sm={ 10 }>
              <Input
                name="email" id="email"
                placeholder={ Lang[ 'ENTER_EMAIL' ][ data.languageReducer.lang ] }
                value={ newLocationData.email }
                onChange={ e => onChangeNewLocationData( 'email', e.target.value )}
                invalid={ errorMessage && errorMessage.email ? true : false }/>
              <FormFeedback>{ errorMessage && errorMessage.email ? errorMessage.email : '' }</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="fax_number" sm={ 2 }>{ Lang[ 'TELFAX' ][ data.languageReducer.lang ] }</Label>
            <Col sm={ 10 }>
              <Input
                name="fax_number" id="fax_number"
                placeholder={ Lang[ 'ENTER_FAX_NUMBER' ][ data.languageReducer.lang ] }
                value={ newLocationData.fax_number }
                onChange={ e => {
                  if( e.target.value.length > 0 ){
                    if( validate( e.target.value ) ) {
                      onChangeNewLocationData( 'fax_number', e.target.value )
                    }
                  }else{
                    onChangeNewLocationData( 'fax_number', e.target.value )
                  }
                }}
                invalid={ errorMessage && errorMessage.fax_number ? true : false }/>
              <FormFeedback>{ errorMessage && errorMessage.fax_number ? errorMessage.fax_number : '' }</FormFeedback>
            </Col>
          </FormGroup>
          {
            selectedCenter.site?.has_aciso_campaign && (
              <>
                <FormGroup row>
                  <Label for="discount" sm={ 2 }>{ Lang[ 'DISCOUNT' ][ data.languageReducer.lang ] }</Label>
                  <Col sm={ 10 }>
                    <Input
                      name="discount" id="discount"
                      placeholder={ Lang[ 'DISCOUNT' ][ data.languageReducer.lang ] }
                      value={ newLocationData.discount }
                      onChange={ e => onChangeNewLocationData( 'discount', e.target.value ) } />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="studio_email_subject" sm={ 2 }>{ Lang[ 'LEADCENTER_SUBJECT_STUDIO_FINDER' ][ data.languageReducer.lang ] }</Label>
                  <Col sm={ 10 }>
                    <Input
                      name="studio_email_subject" id="studio_email_subject"
                      placeholder={ Lang[ 'LEADCENTER_SUBJECT_STUDIO_FINDER' ][ data.languageReducer.lang ] }
                      value={ newLocationData.studio_email_subject }
                      onChange={ e => onChangeNewLocationData( 'studio_email_subject', e.target.value ) } />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="studio_friend_email_subject" sm={ 2 }>{ Lang[ 'LEADCENTER_SUBJECT_FRIENDS' ][ data.languageReducer.lang ] }</Label>
                  <Col sm={ 10 }>
                    <Input
                      name="studio_friend_email_subject" id="studio_friend_email_subject"
                      placeholder={ Lang[ 'LEADCENTER_SUBJECT_FRIENDS' ][ data.languageReducer.lang ] }
                      value={ newLocationData.studio_friend_email_subject }
                      onChange={ e => onChangeNewLocationData( 'studio_friend_email_subject', e.target.value ) } />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="client_number" sm={ 2 }>{ Lang[ 'ROOT_NUMBER' ][ data.languageReducer.lang ] }</Label>
                  <Col sm={ 10 }>
                    <Input
                      name="client_number" id="client_number"
                      placeholder={ Lang[ 'ROOT_NUMBER' ][ data.languageReducer.lang ] }
                      value={ newLocationData.client_number }
                      onChange={ e => onChangeNewLocationData( 'client_number', e.target.value ) } />
                  </Col>
                </FormGroup>
              </>
            )
          }
          {
            ( getDomainURL().indexOf( 'tennis' ) > -1 || getDomainURL().indexOf( 'staging' ) > -1 ) && <FormGroup row>
              <Label for="school_id" sm={ 2 }>{ Lang[ 'TENNIS_SCHOOL_ID' ][ data.languageReducer.lang ] }</Label>
              <Col sm={ 10 }>
                <Input
                  name="school_id" id="school_id"
                  placeholder={ Lang[ 'INPUT_SCHOOL_ID' ][ data.languageReducer.lang ] }
                  value={ newLocationData.school_id }
                  onChange={ e => {
                    if( _.isEmpty( e.target.value ) || /^[0-9]*$/.test( e.target.value ) ){
                      onChangeNewLocationData( 'school_id', e.target.value )
                    } else {
                      onChangeNewLocationData( 'school_id', newLocationData.school_id )
                    }
                  }}
                  invalid={ errorMessage && errorMessage.school_id ? true : false }/>
                <FormFeedback>{ errorMessage && errorMessage.school_id ? errorMessage.school_id : '' }</FormFeedback>
              </Col>
            </FormGroup>
          }
          {
            selectedCenter.site?.has_aciso_campaign && <FormGroup row>
              <Label for="is_active" sm={ 2 }>{ Lang[ 'STATUS' ][ data.languageReducer.lang ] }</Label>
              <Col sm={ 10 }>
                <Input
                  type="select"
                  name="is_active" id="is_active"
                  value={ newLocationData.is_active }
                  onChange={ e => onChangeNewLocationData( 'is_active', e.target.value === 'true' ) } >
                  <option value={ true } >{ Lang[ 'ACTIVE' ][ data.languageReducer.lang ] }</option>
                  <option value={ false } >{ Lang[ 'INACTIVE' ][ data.languageReducer.lang ] }</option>
                </Input>
              </Col>
            </FormGroup>
          }
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          className="mb-2 mr-2 mt-3"
          color="info"
          onClick={ () => {
            let tmpData = _.cloneDeep( newLocationData )
            delete tmpData.area_code
            delete tmpData.calling_code
            delete tmpData.number
            tmpData = {
              ...tmpData,
              phone_number: `${ getCallingCode( newLocationData ) }-${ newLocationData.area_code }-${ newLocationData.number }`,
              school_id: parseInt( tmpData.school_id )
            }
            createCenterLocation( selectedCenter.id, tmpData )
          }}>{ 'Create' }</Button>
      </ModalFooter>
      { onLoadCenters && <LoadingOverlay/> }
    </Modal>
  )
}
const mapStateToProps = state => ({ data: state })

export default connect( mapStateToProps )( AddLocationForm )
