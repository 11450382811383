import React from 'react'
import { BeatLoader } from 'react-spinners'

const Loading = ({
  show
}) => {
  return (
    <div
      style={{
        position: 'fixed',
        display: 'flex',
        background: 'rgba( 0,0,0,0.5 )',
        height: show ? '100vh' : '0',
        width: '100vw',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'height 0.4s'
      }}>
      <BeatLoader size={ 50 } sizeUnit={ 'px' } color={ '#eee' } loading={ show } />
    </div>
  )
}

export default Loading