import React, { useState, useEffect } from 'react'
import {
  Form, FormGroup,
  Label, Button, CustomInput,
  InputGroup, InputGroupAddon, InputGroupText, Input,
  Row, Col
} from 'reactstrap'
import { toast } from 'react-toastify'
import ReactSelect from 'react-select'
import _ from 'lodash'
import Moment from 'moment'

import MultiLangInput from 'components/Input/MultiLang'
import CurrencyInput from 'components/Input/CurrencyInput'
import MediaDatabase from 'SharedModules/MediaDatabase' 
import Lang from 'Lang/General'

const CenterEditableConfigLabels = {
  title: 'TITLE',
  description: 'DESCRIPTION',
  display_from: 'DISPLAY_FROM',
  display_to: 'DISPLAY_TO',
  price: 'PRICE',
  price_member: 'MEMBER_PRICE',
  is_free_for_member: 'FREE_FOR_MEMBERS',
  max_participants: 'MAX_NO_ONLINE',
  image_url: 'THUMBNAIL_IMAGE',
  banner_image_url: 'BANNER_IMAGE',
}

const General = ({
  programTemplateType,
  currentProgramTemplate,
  onChangeProgramTemplatesHOC,
  toggleMediaDatabase,
  eventTemplates,
  centerGroups,
  lang,
  programTemplateErrorMessage
}) => {
  const [ centerGroupOptions, updateCenterGroupOptions ] = useState([])
  const countryOptions = [
    { 
      id: 'AUT',
      label: 'Austria',
      value: 'Austria'
    },
    { 
      id: 'BEL',
      label: 'Belgium',
      value: 'Belgium'
    } ,
    { 
      id: 'FRA',
      label: 'France',
      value: 'France'
    },
    { 
      id: 'DEU',
      label: 'Germany',
      value: 'Germany'
    },
    { 
      id: 'ITA',
      label: 'Italy',
      value: 'Italy'
    },
    { 
      id: 'NLD',
      label: 'Netherlands',
      value: 'Netherlands'
    } ,
    { 
      id: 'ESP',
      label: 'Spain',
      value: 'Spain'
    } ,
    { 
      id: 'CHE',
      label: 'Switzerland',
      value: 'Switzerland'
    }
  ]
  const [ editingImage, setEditingImage ] = useState( '' )

  useEffect( () => {
    if( centerGroups.data && centerGroups.data.length > 0 ){
      let tmpCenterGroups = centerGroups.data.map( item => ({ id: item.id, label: item.name, value: item.name }) )
      updateCenterGroupOptions( tmpCenterGroups )
    }
  }, [ centerGroups ] )

  const updateProgramTemplate = ( key, val ) => {
    let tmp = _.cloneDeep( currentProgramTemplate )
    tmp[ key ] = val
    if ( key === 'display_from' || key === 'display_to' ) {
      if( 
        Moment( tmp[ 'display_to' ] ).diff( Moment( tmp[ 'display_from' ] ), 'days' ) < 1 
      ){
        tmp[ 'display_to' ] = Moment( tmp[ 'display_from' ] ).add( 1, 'day' ).format( 'YYYY-MM-DD' )
      }
    } else if ( key === 'weeks' ) {
      for( let i = 0; i < val; i++ ){
        let tmpIndex = _.findIndex( tmp.weekly_content, { week_number: i+1 } )
        if ( tmpIndex === -1 ) {
          tmp.weekly_content.push({ week_number: i+1, files: [], urls: [] })
        }
      }
      tmp.weekly_content = _.filter( tmp.weekly_content, item => item.week_number <= val )
    }
    onChangeProgramTemplatesHOC( programTemplateType, tmp )
  }

  return(
    <>
      <Row>
        <Col md={ 8 } >
          <MultiLangInput
            label={ Lang[ 'TITLE' ][ lang ] }
            value={ currentProgramTemplate.title }
            onChange={ val => updateProgramTemplate( 'title', val ) }
            selectedLanguage={ lang }
            invalid={ programTemplateErrorMessage.title } />
          <FormGroup>
            <Label>{ Lang[ 'PROGRAM_DURATION' ][ lang ] }</Label>
            <InputGroup>
              <Input
                type='number'
                min={ 1 }
                step={ 1 }
                value={ currentProgramTemplate.weeks }
                onChange={ e => updateProgramTemplate( 
                  'weeks', 
                  e.target.value > 0 ? parseInt( e.target.value ) : 1
                )} 
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>{ Lang[ 'WEEK' ][ lang ] }</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label>{ Lang[ 'DISPLAY_DURATION' ][ lang ] }</Label>
            <InputGroup>
              <Input
                type='date'
                className='border-right-0'
                value={ currentProgramTemplate.display_from }
                onChange={ e => {
                  if( Moment( e.target.value ).diff( Moment().startOf( 'day' ), 'days' ) < 0 ){
                    toast.error( Lang[ 'PLEASE_SELECT_A_DATE_TIME_LATER_THAN_TODAY' ][ lang ] )
                  } else {
                    updateProgramTemplate( 'display_from', e.target.value ) 
                  }
                }} 
              />
              <InputGroupAddon addonType='prepend' >
                <InputGroupText>-</InputGroupText>
              </InputGroupAddon>
              <Input
                type='date'
                value={ currentProgramTemplate.display_to }
                onChange={ e => {
                  let tmp = Moment( _.cloneDeep( e.target.value ) )      
                  if( tmp.diff( Moment().startOf( 'day' ), 'days' ) < 0 ){
                    toast.error( Lang[ 'PLEASE_SELECT_A_DATE_TIME_LATER_THAN_TODAY' ][ lang ] )
                  } else {
                    if( tmp.diff( Moment( currentProgramTemplate[ 'display_from' ] ), 'days' ) < 1 ){
                      tmp = Moment( currentProgramTemplate[ 'display_from' ] ).add( 1, 'day' )
                    }
                    updateProgramTemplate( 'display_to', tmp.format( 'YYYY-MM-DD' ) ) 
                  }
                }} 
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label>{ Lang[ 'EVENT_TEMPLATES' ][ lang ] }</Label>
            <Col sm={ 12 } className='pl-0 pr-0' style={{ zIndex: 999 }}>
              <ReactSelect
                isMulti
                name="colors"
                options={ eventTemplates }
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder={ Lang[ 'SELECT_PLACEHOLDER' ][ lang ] }
                onChange={ ( value, action ) => updateProgramTemplate( 'events', value || [] ) }
                value={ currentProgramTemplate.events } />
            </Col>
          </FormGroup>
          <FormGroup>
            <Label>{ Lang[ 'CENTER_GROUP' ][ lang ] }</Label>
            <Col sm={ 12 } className='pl-0 pr-0' style={{ zIndex: 998 }}>
              <ReactSelect
                isMulti
                name="colors"
                options={ centerGroupOptions }
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder={ Lang[ 'SELECT_PLACEHOLDER' ][ lang ] }
                onChange={ ( value, action ) => updateProgramTemplate( 'center_groups', value || [] ) }
                value={ currentProgramTemplate.center_groups } />
            </Col>
          </FormGroup>
          <FormGroup>
            <Label>{ Lang[ 'COUNTRY' ][ lang ] }</Label>
            <Col sm={ 12 } className='pl-0 pr-0' style={{ zIndex: 997 }}>
              <ReactSelect
                isMulti
                name="colors"
                options={ countryOptions }
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder={ Lang[ 'SELECT_PLACEHOLDER' ][ lang ] }
                onChange={ ( value, action ) => {
                  updateProgramTemplate( 'countries', ( value && value.map( item => item.id ) ) || [] ) 
                }}
                value={ 
                  _.filter( 
                    countryOptions, 
                    item => _.findIndex( currentProgramTemplate.countries, templateCountry => templateCountry === item.id ) > -1 
                  ) 
                } 
              />
            </Col>
          </FormGroup>
          <FormGroup>
            <Label>{ Lang[ 'STATUS' ][ lang ] }</Label>
            <Input
              type='select'
              value={ currentProgramTemplate.status }
              onChange={ e => updateProgramTemplate( 'status', e.target.value ) }>
              <option value='ACTIVE' >{ Lang[ 'ACTIVE' ][ lang ] }</option>
              <option value='INACTIVE' >{ Lang[ 'INACTIVE' ][ lang ] }</option>
              <option value='IS_DRAFT' >{ Lang[ 'IS_DRAFT' ][ lang ] }</option>
              <option value='ARCHIVED' >{ Lang[ 'ARCHIVED' ][ lang ] }</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>{ Lang[ 'THUMBNAIL_IMAGE' ][ lang ] }</Label>
            <div className='d-flex flex-column w-100' >
              <Input 
                disabled
                type={ 'text' } 
                value={ currentProgramTemplate.image_url } 
                style={{ marginRight: '5px', borderRadius: "0.25rem 0.25rem 0 0" }} />
              <div 
                src={ currentProgramTemplate.image_url } 
                style={{
                  width: "100%",
                  height: "200px",
                  backgroundColor: _.isEmpty( currentProgramTemplate.image_url ) ? "transparant" : "#000000",
                  backgroundImage: _.isEmpty( currentProgramTemplate.image_url ) ? '' : `url(${ currentProgramTemplate.image_url })`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "center center",
                  border: _.isEmpty( currentProgramTemplate.image_url ) ? "1.7px solid #ced4da" : "0 solid #000000",
                  borderTop: "0",
                  borderRadius: "0 0 0.25rem 0.25rem",
                  marginBottom: "8px" }} />
            </div>
            <Button
              style={{ width: "max-content" }}
              color='primary'
              onClick={ () => {
                setEditingImage( 'image_url' )
                toggleMediaDatabase( true ) 
              }}>
              { Lang[ 'SELECT_IMAGE' ][ lang ] }
            </Button>
          </FormGroup>
          <FormGroup>
            <Label>{ Lang[ 'BANNER_IMAGE' ][ lang ] }</Label>
            <div className='d-flex flex-column w-100' >
              <Input 
                disabled
                type={ 'text' } 
                value={ currentProgramTemplate.banner_image_url } 
                style={{ marginRight: '5px', borderRadius: "0.25rem 0.25rem 0 0" }} />
              <div 
                src={ currentProgramTemplate.banner_image_url } 
                style={{
                  width: "100%",
                  height: "200px",
                  backgroundColor: _.isEmpty( currentProgramTemplate.banner_image_url ) ? "transparant" : "#000000",
                  backgroundImage: _.isEmpty( currentProgramTemplate.banner_image_url ) ? '' : `url(${ currentProgramTemplate.banner_image_url })`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "center center",
                  border: _.isEmpty( currentProgramTemplate.banner_image_url ) ? "1.7px solid #ced4da" : "0 solid #000000",
                  borderTop: "0",
                  borderRadius: "0 0 0.25rem 0.25rem"
                }} />
            </div>
            <div className='font-italic my-1'><small>{ Lang[ 'SUGGESTED_IMAGE_SIZE' ][ lang ] }</small></div>
            <Button
              style={{ width: "max-content" }}
              color='primary'
              onClick={ () => {
                setEditingImage( 'banner_image_url' )
                toggleMediaDatabase( true ) 
              }}>
              { Lang[ 'SELECT_IMAGE' ][ lang ] }
            </Button>
          </FormGroup>
        </Col>
        <Col md={ 4 } >
          <FormGroup>
            <Label>{ Lang[ 'FIELD_EDITABLE_BY_CENTER' ][ lang ] }</Label>
            {
              currentProgramTemplate.center_editable_config.map(( config, index ) => {
                if( CenterEditableConfigLabels[ config.name ] ){
                  return <CustomInput
                    id={ `editable_config_${ config.name }` }
                    type='checkbox'
                    checked={ config.is_editable }
                    label={ Lang[ CenterEditableConfigLabels[ config.name ] ][ lang ] }
                    onChange={ e => {
                      let tmpConfig = _.cloneDeep( currentProgramTemplate.center_editable_config )
                      tmpConfig[ index ].is_editable = e.target.checked
                      updateProgramTemplate( 'center_editable_config', tmpConfig ) 
                    }} 
                  />
                }
              })
            }
          </FormGroup>
          <FormGroup>
            <Label>{ Lang[ 'PROGRAM_FOR' ][ lang ] }</Label>
            <CustomInput
              id='is_for_member'
              type='checkbox'
              checked={ currentProgramTemplate.is_for_member }
              label={ Lang[ 'MEMBERS' ][ lang ] }
              onChange={ e => updateProgramTemplate( 'is_for_member', e.target.checked ) } />
            <CustomInput
              id='is_for_program_participant'
              type='checkbox'
              checked={ currentProgramTemplate.is_for_program_participant }
              label={ Lang[ 'PROGRAM_PARTICIPANTS' ][ lang ] }
              onChange={ e => updateProgramTemplate( 'is_for_program_participant', e.target.checked ) } />
            <CustomInput
              id='is_for_trial_member'
              type='checkbox'
              checked={ currentProgramTemplate.is_for_trial_member }
              label={ Lang[ 'TRIAL_MEMBERS' ][ lang ] }
              onChange={ e => updateProgramTemplate( 'is_for_trial_member', e.target.checked ) } />
            <CustomInput
              type='checkbox'
              checked={ true }
              label={ Lang[ 'EXPERTS' ][ lang ] } />
          </FormGroup>
          <FormGroup>
            <Label>{ Lang[ 'PRICE' ][ lang ] }</Label>
            <CurrencyInput
              value={ currentProgramTemplate.price }
              invalid={ programTemplateErrorMessage.price }
              onChangeValue={ e => {
                let tmp = _.cloneDeep( e )
                if( e ){  
                  tmp = tmp.replace( /[\.,]$/g, ',0' )
                }
                updateProgramTemplate( 'price', e ? tmp.replace( ',', '.' ) : '' )
              }} />
          </FormGroup>
          <FormGroup>
            <Label>{ Lang[ 'MEMBER_PRICE' ][ lang ] }</Label>
            <CurrencyInput
              value={ currentProgramTemplate.price_member }
              invalid={ programTemplateErrorMessage.price_member }
              onChangeValue={ e => {
                let tmp = _.cloneDeep( e )
                if( e ){  
                  tmp = tmp.replace( /[\.,]$/g, ',0' )
                }
                updateProgramTemplate( 'price_member', e ? tmp.replace( ',', '.' ) : '' )
              }} />
          </FormGroup>
          <FormGroup>
            <CustomInput
              id='is_free_for_member'
              type='checkbox'
              checked={ currentProgramTemplate.is_free_for_member }
              label={ Lang[ 'FREE_FOR_MEMBERS' ][ lang ] }
              onChange={ e => updateProgramTemplate( 'is_free_for_member', e.target.checked ) } />
            <CustomInput
              id='is_free_for_campaign_participant'
              type='checkbox'
              checked={ currentProgramTemplate.is_free_for_campaign_participant }
              label={ Lang[ 'FREE_FOR_PROGRAM_PARTICIPANTS' ][ lang ] }
              onChange={ e => updateProgramTemplate( 'is_free_for_campaign_participant', e.target.checked ) } />
            <CustomInput
              id='is_free_for_trial_member'
              type='checkbox'
              checked={ currentProgramTemplate.is_free_for_trial_member }
              label={ Lang[ 'FREE_FOR_TRIAL_MEMBERS' ][ lang ] }
              onChange={ e => updateProgramTemplate( 'is_free_for_trial_member', e.target.checked ) } />
            <CustomInput
              type='checkbox'
              checked={ true }
              label={ Lang[ 'FREE_FOR_EXPERTS' ][ lang ] } />
          </FormGroup>
          <FormGroup>
            <Label>{ Lang[ 'MAX_NO_ONLINE' ][ lang ] }</Label>
            <Input
              type='number'
              min='1'
              value={ currentProgramTemplate.max_participants }
              onChange={ e =>  updateProgramTemplate( 'max_participants', e.target.value ) } />
          </FormGroup>
        </Col>
      </Row>
      <MediaDatabase
        onSelectedImage={( param ) => {
          updateProgramTemplate( editingImage, param )
          toggleMediaDatabase( false )
        }} 
      />
    </>
  )
}

export default General