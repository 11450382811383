import React, { useState, useEffect } from 'react'
import {
  Input,
  Label,
  FormGroup
} from 'reactstrap'
import Moment from 'moment'
import ReactHTMLParser from 'react-html-parser'


const DateField = ({
  content,
  formValues,
  onPatchFormValues,
  currentLanguage
}) => {
  const [ labelStr, setLabelStr ] = useState("")

  useEffect(() => {
    let labelText = content.label[ currentLanguage ? currentLanguage : 'en' ]

    if ( labelText.length !== 0 ) {
      if ( typeof( ReactHTMLParser( labelText )[0] ) === 'object' ) {
        let labelTag = ReactHTMLParser( labelText )[0].type
        let closingTag = "</" + labelTag + ">"
        labelText = labelText.slice( 0, -1 * closingTag.length )
        if ( formValues[ content.dataMapIndex ][ 'isRequired' ] ){
          labelText = labelText + "<span style='color: #ff0000;'> * </span>"
        }
        labelText = labelText + closingTag
      }
      else {
        labelText = labelText + "<span style='color: #ff0000;'> * </span>"
      }
    }
    setLabelStr( labelText )
  }, [ content, formValues, currentLanguage ] )

  return(
    <FormGroup 
      id={ content.id } 
      style={{ padding: content.padding, marginBottom: 0 }}>
      <Label style={{ fontSize: content.fontSize }} >{ ReactHTMLParser( labelStr ) }</Label>
      <Input
        type="date"
        value={ Moment( formValues[ content.dataMapIndex ][ 'value' ] ).format( 'YYYY-MM-DD' ) }
        onChange={ e => onPatchFormValues( content.dataMapIndex, 'value', Moment( e.target.value ).utc().format() ) }/>
    </FormGroup>
  )
}

export default DateField