import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import StatusModal from 'components/Modal/status'

import { Get, outdatedToken } from 'utils/axios'

const CenterGroupHOC = ( WrappedComponent ) => {
  class CenterGroupWrappedComponent extends Component {
    state = {
      centerGroups: {},
      archivedCenterGroup: {},
      selectedCenterGroup: {
        name: '',
        description: '',
        status: ''
      },
      newCenterGroupData: {
        name: '',
        description: '',
        status: 'ACTIVE'
      },
      showNewCenterGroupModal: false,
      showViewCenterGroupModal: false,
      showEditCenterGroupModal: false,
      showDeleteConfirmation: false,
      showArchivedCenterGroupModal: false,
      loading: false,
      showStatusModal: false,
      statusModalType: '',
      onClickStatusModalButton: () => {},
      statusModalMessage: '',
      centerGroupForSelect: [],
      errorMessage: {},
      centerGroupTotalPage: [],
      archivedCenGrpTotalPage: [],
      toArchiveCenterGroupId: 0,
      unarchiveCenterGroupID: 0,
      confirmUnarchiveModal: false
    }

    // onChangeCenterGroupHOC = ( key, value ) => this.setState({ [key]: value })

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showStatusModal: true,
      statusModalType: 'negative',
      onClickStatusModalButton: () => this.setState({ showStatusModal: false }),
      statusModalMessage: error
    })
    requestSuccess = success => this.setState({
      showStatusModal: true,
      statusModalType: 'positive',
      onClickStatusModalButton: () => this.setState({
        showStatusModal: false,
        showNewCenterGroupModal: false,
        showArchivedCenterGroupModal: false,
        showEditCenterGroupModal: false,
        newCenterGroupData: {
          name: '',
          description: '',
          status: 'ACTIVE'
        },
        errorMessage: {}
      }),
      statusModalMessage: success
    })
    ajaxError = ( error ) => {
      outdatedToken( error, this.props.history )
      this.requestError( error )
    }

    getCenterGroups = query => Get(
      `/api/v1/center_groups?query=${ query }`,
      this.getCenterGroupsSuccess,
      this.getCenterGroupsError,
      this.load
    )
    getCenterGroupsSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data.length > 0 ){
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }else{
        this.ajaxError( 'No records' )
      }
      this.setState({ centerGroups: payload, centerGroupTotalPage: tmpTotalPages })
    }
    getCenterGroupsError = error => this.ajaxError( error )

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            { ...this.props }
            centerGroups={ this.state.centerGroups }
            getCenterGroups={ this.getCenterGroups }
            onLoadCenterGroups={ this.state.loading } />
          <StatusModal
            isOpen={ this.state.showStatusModal }
            type={ this.state.statusModalType }
            message={ this.state.statusModalMessage }
            onClick={ this.state.onClickStatusModalButton } />
        </Fragment>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( CenterGroupWrappedComponent )
}

export default CenterGroupHOC
