import React from 'react'
import ReactSelect from 'react-select'
import { Input, FormFeedback } from 'reactstrap'

import './index.scss'

const DropdawnSearchSelect = ({
  options,
  onChange,
  value,
  placeholder,
  isDisabled,
  invalid,
  feedbackMessage
}) => {
  return(
    <>
      <ReactSelect
        name="select"
        options={ options }
        className="search-select"
        classNamePrefix="select"
        onChange={ ( value ) => onChange( value ) }
        value={ value.label !== '' && value }
        placeholder={ placeholder }
        isDisabled={ isDisabled }
        styles={{ control: ( provided, state ) => (
          { ...provided, border: invalid ? "1px solid #dc3545" : "1px solid #cccccc" }
        ) }} />
      <Input
        invalid={ invalid }
        style={{ opacity: "0", height: "0", padding: "0", margin: "0" }} />
      <FormFeedback>{ feedbackMessage ? feedbackMessage : 'error' }</FormFeedback>
    </>
  )
}

export default DropdawnSearchSelect
