import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import FileSaver from 'file-saver'

import StatusModal from 'components/Modal/status'

import { Get, Post, outdatedToken, Put, Patch } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'
import { getItem, clearItem } from 'utils/tokenStore'
import getDomainURL from 'utils/api'
import Lang from 'Lang/General'

const ConsultantHOC = ( WrappedComponent ) => {
  class ConsultantWrappedComponent extends Component {
    state = {
      consultants: {},
      archivedConsultants: {},
      loading: false,
      showStatusModal: false,
      statusModalType: '',
      onClickStatusModalButton: () => {},
      statusModalMessage: '',
      showNewConsultantModal: false,
      showEditConsultantModal: false,
      showViewConsultantModal: false,
      showDeleteConfirmation: false,
      newConsultantData: {
        user: {
          first_name: '',
          last_name: '',
          username: '',
          email: '',
          role_id: 5,
          admin_panel_language: this.props.data.languageReducer.lang,
          password: '',
          password_confirmation: '',
          photo_file_name: '',
          photo_raw: '',
          photo_url: ''
        },
        phone_number: '',
        mobile_number: '',
        best_contact_time: '',
        fax_number: '',
        remark: '',
        status: 'ACTIVE',
      },
      selectedConsultant: {
        user: {
          first_name: '',
          last_name: '',
          username: '',
          email: '',
          role_id: 5,
          password: '',
          password_confirmation: '',
          photo_file_name: '',
          photo_raw: '',
          photo_url: ''
        },
        phone_number: '',
        mobile_number: '',
        best_contact_time: '',
        fax_number: '',
        remark: '',
        status: '',
      },
      errorMessage: {},
      showArchivedConsultantModal: false,
      consultantTotalPages: [],
      archivedConTotalPage: [],
      toArchiveConsultantId: 0,
      confirmUnarchiveModal: false,
      unArchiveConsultantID: 0,
      assignedCenters: []
    }

    handleNewConsultantModal = ( param ) => this.setState({
      showNewConsultantModal: param,
      newConsultantData: {
        user: {
          first_name: '',
          last_name: '',
          username: '',
          email: '',
          role_id: 5,
          admin_panel_language: this.props.data.languageReducer.lang,
          password: '',
          password_confirmation: '',
          photo_file_name: '',
          photo_raw: '',
          photo_url: ''
        },
        phone_number: '',
        mobile_number: '',
        best_contact_time: '',
        fax_number: '',
        remark: '',
        status: 'ACTIVE',
      },
      errorMessage: {}
    })

    onChangeConsultantHOC = ( key, value ) => this.setState({ [key]: value })

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showStatusModal: true,
      statusModalType: 'negative',
      onClickStatusModalButton: () => this.setState({ showStatusModal: false }),
      statusModalMessage: error
    })
    requestSuccess = success => this.setState({
      showStatusModal: true,
      statusModalType: 'positive',
      onClickStatusModalButton: () => this.setState({
        showStatusModal: false,
        showNewConsultantModal: false,
        newConsultantData: {
          user: {
            first_name: '',
            last_name: '',
            username: '',
            email: '',
            role_id: 5,
            admin_panel_language: this.props.data.languageReducer.lang,
            password: '',
            password_confirmation: '',
            photo_file_name: '',
            photo_raw: '',
            photo_url: ''
          },
          phone_number: '',
          mobile_number: '',
          best_contact_time: '',
          fax_number: '',
          remark: '',
          status: 'ACTIVE',
        },
        errorMessage: {},
       }),
      statusModalMessage: success
    })

    ajaxError = ( error ) => {
      outdatedToken( error, this.props.history )
      this.requestError( error )
    }

    getConsultants = query => {
      query = {
        ...query,
        filter: {
          $or: [
            { status: 'ACTIVE' },
            { status: 'INACTIVE' }
          ]
        }
      }
      return Get(
        `/api/v1/consultants?query=${ convertObjToBase64( query ) }`,
        this.getConsultantsSuccess,
        this.getConsultantsError,
        this.load
      )

    }
    getConsultantsSuccess = payload => {
      let tmpConsultants = []
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        payload.data.map( item => {
          tmpConsultants.push({
            id: item.id,
            first_name: item.user.first_name,
            last_name: item.user.last_name,
            fullname: `${ item.user.first_name } ${ item.user.last_name }`,
            email: item.user.email,
            mobile_number: item.mobile_number,
          })
        })
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      let tmp = {
        data: tmpConsultants,
        meta: payload.meta
      }
      return this.setState({ consultants: tmp, consultantTotalPages: tmpTotalPages })
    }
    getConsultantsError = error => this.ajaxError( error )

    getSelectedConsultantView = id => this.getSelectedConsultant( id, 'view' )
    getSelectedConsultantEdit = id => this.getSelectedConsultant( id, 'edit' )

    getSelectedConsultant = (id, type) => Get(
      `/api/v1/consultants/${ id }`,
      ( payload ) => this.getSelectedConsultantSuccess( payload, type ),
      this.getSelectedConsultantError,
      this.load
    )
    getSelectedConsultantSuccess = (payload, type) => this.setState({ selectedConsultant: payload }, () => {
      type === 'view'
        ? this.onChangeConsultantHOC( 'showViewConsultantModal', true )
        : this.onChangeConsultantHOC( 'showEditConsultantModal', true )
    })
    getSelectedConsultantError = error => this.ajaxError( error )

    postConsultants = () => Post(
      `/api/v1/consultants`,
      this.state.newConsultantData,
      this.postConsultantsSuccess,
      this.postConsultantsError,
      this.load
    )

    postConsultantsSuccess = payload => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'CONSULTANT_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getConsultants( tmp )
    }

    postConsultantsError = error => {
      this.setState({ errorMessage: error })
      this.requestError( Lang[ 'CONSULTANT_CREATE_UNSUCCESS' ][ this.props.data.languageReducer.lang ] )
    }

    updateConsultant = id => Put(
      `/api/v1/consultants/${ id }`,
      this.state.selectedConsultant,
      this.updateConsultantSuccess,
      this.updateConsultantError,
      this.load
    )

    updateConsultantSuccess = payload => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'CONSULTANT_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.onChangeConsultantHOC( 'showEditConsultantModal', false )
      this.getConsultants( tmp )
    }

    updateConsultantError = error => {
      this.setState({ errorMessage: error })
      this.requestError( Lang[ 'CONSULTANT_UPDATE_UNSUCCESS' ][ this.props.data.languageReducer.lang ] )
    }

    updateConsultantStatus = ( id, status ) => Patch(
      `/api/v1/consultants/${ id }`,
      { status },
      this.updateConsultantStatusSuccess,
      this.updateConsultantStatusError,
      this.load
    )
    updateConsultantStatusSuccess = payload => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'CONSULTANT_STATUS_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getConsultants( tmp )
      this.getArchivedConsultant( tmp )
    }
    updateConsultantStatusError = error => this.requestError( Lang[ 'CONSULTANT_STATUS_UPDATE_UNSUCCESS' ][ this.props.data.languageReducer.lang ] )

    getArchivedConsultant = ( query, type, searchParams, searchVal ) => {
      query = {
        ...query,
        filter: {
          status: 'ARCHIVED'
        }
      }
      if( type === 'Normal Search' ){
        query = {
          ...query,
          filter: {
            'users.first_name': {
              $like: `%${ searchVal.toLowerCase() }%`
            },
            status: 'ARCHIVED'
          }
        }
      }
      if( type === 'Advanced Search' ){
        let tmpUser = {}
        let tmpCon = {}
        searchParams.map( item => {
          if ( item.val === 'first_name' || item.val === 'last_name' || item.val === 'email' ){
            tmpUser[ item.val ] = item.searchValue
          }
          else{
            tmpCon[ item.val ] = item.searchValue
            item.val === 'status' && (
              tmpCon[ item.val ] = item.searchValue
            )
          }
        })
        if( tmpUser.first_name ) {
          query = {
            ...query,
            filter: {
              status: 'ARCHIVED',
              'users.first_name': {
                $like: `%${ tmpUser.first_name.toLowerCase()}%`
              }
            }
          }
        }
        if( tmpUser.last_name ) {
          query = {
            ...query,
            filter: {
              status: 'ARCHIVED',
              'users.last_name': {
                $like: `%${ tmpUser.last_name.toLowerCase()}%`
              }
            }
          }
        }
        if( tmpUser.email ) {
          query = {
            ...query,
            filter: {
              status: 'ARCHIVED',
              'users.email': {
                $like: `%${ tmpUser.email.toLowerCase()}%`
              }
            }
          }
        }
        if( tmpCon.mobile_number ) {
          query = {
            ...query,
            filter: {
              status: 'ARCHIVED',
              mobile_number: {
                $like: `%${ tmpCon.mobile_number }%`
              }
            }
          }
        }
        // query = {
        //   ...query,
        //   filter: {
        //     status: 'ARCHIVED',
        //     'users.first_name': {
        //       $like: `%${ tmpUser.first_name.toLowerCase()}%`
        //     },
        //     'users.last_name': {
        //       $like: `%${ tmpUser.last_name.toLowerCase()}%`
        //     },
        //     'users.email': {
        //       $like: `%${ tmpUser.email.toLowerCase()}%`
        //     },
        //     mobile_number: {
        //       $like: `%${ tmpCon.mobile_number }%`
        //     }
        //   }
        // }
      }
      return Get(
        `/api/v1/consultants?query=${ convertObjToBase64( query ) }`,
        this.getArchivedConsultantsSuccess,
        this.getArchivedConsultantsError,
        this.load
      )
    }
    getArchivedConsultantsSuccess = payload => {
      let tmpConsultants = []
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        payload.data.map( item => {
          tmpConsultants.push({
            id: item.id,
            first_name: item.user.first_name,
            last_name: item.user.last_name,
            fullname: `${ item.user.first_name } ${ item.user.last_name }`,
            email: item.user.email,
            mobile_number: item.mobile_number,
          })
        })
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }else{
        this.ajaxError( Lang[ 'NO_RESULT_FOUND' ][ this.props.data.languageReducer.lang ] )
      }
      let tmp = {
        data: tmpConsultants,
        meta: payload.meta
      }
      return this.setState({ archivedConsultants: tmp, archivedConTotalPage: tmpTotalPages })
    }
    getArchivedConsultantsError = error => this.ajaxError( error )

    getConsultantsSearch = ( query, type, searchParams, searchVal ) => {
      if( type === 'Normal Search' ){
        query = {
          ...query,
          filter: {
            'users.first_name': {
              $like: `%${ searchVal.toLowerCase() }%`
            },
            $or: [
              { status: 'ACTIVE' },
              { status: 'INACTIVE' }
            ]
          }
        }
      }
      if( type === 'Advanced Search' ){
        let tmpUser = {}
        let tmpCon = {}
        searchParams.map( item => {
          if( item.val === 'first_name' || item.val === 'last_name' || item.val === 'email' ){
            tmpUser[ item.val ] = item.searchValue
          } else {
            tmpCon[ item.val ] = item.searchValue
            item.val === 'status' && (
              tmpCon[ item.val ] = item.searchValue
            )
          }
        })
        query = {
          ...query,
          filter: {
            $or: [
              { status: 'ACTIVE' },
              { status: 'INACTIVE' }
            ]
          }
        }
        if( tmpUser.first_name ) {
          query = {
            ...query,
            filter: {
              ...query.filter,
              'users.first_name': {
                $like: `%${ tmpUser.first_name.toLowerCase()}%`
              }
            }
          }
        }
        if( tmpUser.last_name ) {
          query = {
            ...query,
            filter: {
              ...query.filter,
              'users.last_name': {
                $like: `%${ tmpUser.last_name.toLowerCase()}%`
              }
            }
          }
        }
        if( tmpUser.email ) {
          query = {
            ...query,
            filter: {
              ...query.filter,
              'users.email': {
                $like: `%${ tmpUser.email.toLowerCase()}%`
              }
            }
          }
        }
        if( tmpCon.mobile_number ) {
          query = {
            ...query,
            filter: {
              ...query.filter,
              mobile_number: {
                $like: `%${ tmpCon.mobile_number }%`
              }
            }
          }
        }
      }
      Get(
        `/api/v1/consultants?query=${ convertObjToBase64( query ) }`,
        this.getConsultantsSearchSuccess,
        this.getConsultantsSearchError,
        this.load
      )
    }
    getConsultantsSearchSuccess = payload => {
      let tmpConsultants = []
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        payload.data.map( item => {
          tmpConsultants.push({
            id: item.id,
            fullname: `${ item.user.first_name } ${ item.user.last_name }`,
            email: item.user.email,
            mobile_number: item.mobile_number
          })
        })
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
        let tmp = {
          data: tmpConsultants,
          meta: payload.meta
        }
        return this.setState({ consultants: tmp, consultantTotalPages: tmpTotalPages })
      }else{
        this.ajaxError( Lang[ 'NO_RESULT_FOUND' ][ this.props.data.languageReducer.lang ] )
      }
    }
    getConsultantsSearchError = error => this.ajaxError( error )

    onUpdateFiles = ( key, value ) => {
      let tmp = _.cloneDeep( this.state.newConsultantData )
      tmp[key] = value
      return this.state.newConsultantData = tmp
    }

    getAssignedCenters = ( id ) => Get(
      `/api/v1/consultants/${ id }/centers?download=false`,
      this.getAssignedCentersSuccess,
      this.getAssignedCentersError,
      this.load
    )
    getAssignedCentersSuccess = payload => this.setState({ assignedCenters: payload })
    getAssignedCentersError = error => this.requestError( error )

    downloadAssignedCenters = async( id ) => {
      const result = await fetch( `${ getDomainURL( window.location.origin ) }/api/v1/consultants/${ id }/centers?download=true`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${ getItem( 'PROOFPOINT_ARK_MANAGEMENT' ) }`,
        }
      } )
      const resultBlob = await result.blob()
      let fileURL = window.URL.createObjectURL( resultBlob )
      const link = document.createElement('a');
      link.href = fileURL;
      link.setAttribute(
        'download',
        '',
      );
      document.body.appendChild(link);

      link.click();
    }

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            { ...this.props }
            consultants={ this.state.consultants }
            archivedConsultants={ this.state.archivedConsultants }
            newConsultantData={ this.state.newConsultantData }
            selectedConsultant={ this.state.selectedConsultant }
            getConsultants={ this.getConsultants }
            getSelectedConsultantEdit={ this.getSelectedConsultantEdit }
            getSelectedConsultantView={ this.getSelectedConsultantView }
            onLoadConsultants={ this.state.loading }
            postConsultants={ this.postConsultants }
            updateConsultant={ this.updateConsultant }
            newConsultantModal={ this.state.newConsultantModal }
            showNewConsultantModal={ this.state.showNewConsultantModal }
            showViewConsultantModal={ this.state.showViewConsultantModal }
            showEditConsultantModal={ this.state.showEditConsultantModal }
            showDeleteConfirmation={ this.state.showDeleteConfirmation }
            showArchivedConsultantModal={ this.state.showArchivedConsultantModal }
            getArchivedConsultant={ this.getArchivedConsultant }
            updateConsultantStatus={ this.updateConsultantStatus }
            errorMessage={ this.state.errorMessage }
            getConsultantsSearch={ this.getConsultantsSearch }
            onUpdateFiles={ this.onUpdateFiles }
            consultantTotalPages={ this.state.consultantTotalPages }
            onChangeConsultantHOC={ this.onChangeConsultantHOC }
            archivedConTotalPage={ this.state.archivedConTotalPage }
            toArchiveConsultantId={ this.state.toArchiveConsultantId }
            confirmUnarchiveModal={ this.state.confirmUnarchiveModal }
            unArchiveConsultantID={ this.state.unArchiveConsultantID }
            assignedCenters={ this.state.assignedCenters }
            getAssignedCenters={ this.getAssignedCenters }
            downloadAssignedCenters={ this.downloadAssignedCenters }/>
          <StatusModal
            isOpen={ this.state.showStatusModal }
            type={ this.state.statusModalType }
            message={ this.state.statusModalMessage }
            onClick={ this.state.onClickStatusModalButton } />
        </Fragment>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( ConsultantWrappedComponent )
}

export default ConsultantHOC
