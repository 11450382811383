import React, { useState, useEffect } from 'react'
import { 
  Modal, ModalHeader, ModalBody, ModalFooter,
  Label, CustomInput, Button,
  Form, FormGroup
} from 'reactstrap'
import ReactSelect from 'react-select'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'

const AddPageModal = ({
  mode,
  isShowAddPageModal,
  toggleShowAddPageModal,
  onAddPage,
  digitalOrderForms,
  selectedDigitalOrderForm,
  selectedFormToCopy,
  getDigitalOrderForms,
  getSelectedDigitalOrderForm,
  changeComponentId,
  onLoadDigitalOrderForms,
  selectedLanguage
}) => {
  const [ pageTypeToAdd, setPageTypeToAdd ] = useState( "form" )
  const [ selectedOriginForm, setSelectedOriginForm ] = useState( )
  const [ selectedOriginPage, setSelectedOriginPage ] = useState( )
  
  useEffect( () => {
    if ( pageTypeToAdd === 'copyOfExternalPage' ) {
      getDigitalOrderForms( { 
        is_paginated: false,
        filter: {
          $or: [
            { status: 'ACTIVE' },
            { status: 'INACTIVE' }
          ]
        } 
      })
    }
  }, [ pageTypeToAdd ])

  useEffect( () => {
    if ( selectedOriginForm ) {
      getSelectedDigitalOrderForm( selectedOriginForm.id, 'selectedFormToCopy' )
      setSelectedOriginPage( null )
    }
  }, [ selectedOriginForm ])

  return (
    <Modal isOpen={ isShowAddPageModal } size='sm' >
      <ModalHeader toggle={ () => toggleShowAddPageModal( false ) } >
        { Lang[ 'ADD_PAGE' ][ selectedLanguage ] }</ModalHeader>
      <ModalBody>
        {
          <Form>
            <FormGroup>
              <Label>{ Lang[ 'TYPE_OF_PAGE' ][ selectedLanguage ] }</Label>
              <CustomInput
                id='formPage'
                type='radio'
                label={ Lang[ 'FORM_PAGE' ][ selectedLanguage ] }
                checked={ pageTypeToAdd === 'form' }
                onChange={ e => setPageTypeToAdd('form' ) }/>
              <CustomInput
                id='coverPage'
                type='radio'
                label={ Lang[ 'COVER_FIRST_PAGE' ][ selectedLanguage ] }
                checked={ pageTypeToAdd === 'cover' }
                onChange={ e => setPageTypeToAdd( 'cover' ) }/>
              <CustomInput
                id='copyExternalPage'
                type='radio'
                label={ Lang[ 'COPY_PAGE_FROM_ANOTHER_FORM' ][ selectedLanguage ] }
                checked={ pageTypeToAdd === 'copyOfExternalPage' }
                onChange={ e => setPageTypeToAdd( 'copyOfExternalPage' ) }/>
            </FormGroup>
            {
              pageTypeToAdd === 'copyOfExternalPage' && (
                <>
                  <FormGroup>
                    <Label>{ Lang[ 'SELECT_FORM' ][ selectedLanguage ] }</Label>
                    <ReactSelect
                      menuPortalTarget={document.body} 
                      styles={{ 
                        menuPortal: base => ({ ...base, zIndex: 9999 }), 
                        menu: (provided) => ({ ...provided, zIndex: 9999 })
                      }}
                      options={ digitalOrderForms.data
                        .filter( form => form.id !== selectedDigitalOrderForm.id || mode !== 'update' )
                        .map( form => {
                          return ({
                            id: form.id,
                            label: form.name[ selectedLanguage ],
                            value: form.id
                          })
                      } ) }
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={ ( option ) => setSelectedOriginForm( option ) }
                      value={ selectedOriginForm }
                    />
                  </FormGroup>
                  {
                    !_.isEmpty( selectedOriginForm ) && !_.isEmpty( selectedFormToCopy ) && (
                      <FormGroup>
                        <Label>{ Lang[ 'SELECT_PAGE_TO_COPY' ][ selectedLanguage ] }</Label>
                        <ReactSelect
                          menuPortalTarget={document.body} 
                          styles={{ 
                            menuPortal: base => ({ ...base, zIndex: 9999 }), 
                            menu: (provided) => ({ ...provided, zIndex: 9999 })
                          }}
                          options={ 
                            selectedFormToCopy.content[0].children.filter( 
                              page => page.mapWith === "cover" || page.mapWith === "form" || !page.mapWith
                            ).map( page => {
                              return ({
                                id: page.id,
                                label: page.name[ selectedLanguage ],
                                pageNo: page.page,
                                value: page
                              })
                            }) 
                          }
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={ ( option ) => setSelectedOriginPage( option ) }
                          value={ selectedOriginPage }
                        />
                      </FormGroup>
                    )
                  }
                </>
              )
            }
          </Form>
        }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          disabled={ pageTypeToAdd === 'copyOfExternalPage' && _.isEmpty( selectedOriginPage ) } 
          onClick={ () => { 
            if ( pageTypeToAdd !== 'copyOfExternalPage' ) {
              onAddPage( pageTypeToAdd ) 
            } else {
              let formToCopy = _.cloneDeep( selectedFormToCopy )
              let pageDom = _.cloneDeep( selectedOriginPage.value )
              let pageFormValues = _.cloneDeep( formToCopy.values.filter( val => val.page === pageDom.page ) )
              changeComponentId(pageDom, pageFormValues)

              // ---- this part is for backward compatability ----
              // may remove if old form using the old version of content page can be ignored
              if ( _.isEmpty( pageDom.settings ) ) {
                pageDom.settings = {
                  thumbnailImg: ""
                }
              }
              // ---- end of code for backward compatability ----

              let tmpPageData = {
                dom: pageDom,
                formValues: pageFormValues
              }
              onAddPage( pageTypeToAdd, tmpPageData ) 
            }
          }}>
          { Lang[ 'SUBMIT' ][ selectedLanguage ] }</Button>
      </ModalFooter>
      { onLoadDigitalOrderForms && <LoadingOverlay /> }
    </Modal>
  )
}

export default AddPageModal