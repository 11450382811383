import React from 'react'

import CoreRenderFunction from '../../core/render'

const ColumnComponent = ({
  content,
  mode,
  formValues,
  dom,
  selectedLanguageShortname 
}) => {
  return (
    <div 
      className={ `col-md-${ content.grid }` } 
      style={{ padding: "0", position: 'relative' }}>
      {
        content.children && content.children.map( item => {
          return <CoreRenderFunction
            item={ item }
            content={ item }
            mode={ mode } 
            formValues={ formValues }
            dom={ dom }
            selectedLanguageShortname ={ selectedLanguageShortname  }  />
        })
      }
      {
        !content.children && (
          <div>
            <span>Put something here.</span>
          </div>
        )
      }
    </div>
  )
}

export default ColumnComponent