import React, { useState } from 'react'
import {
  Card, CardBody,
  Form, FormGroup, FormFeedback,
  Label, Input, Button, CustomInput, Col,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'
import _ from 'lodash'
import moment from 'moment'

import DropdownSearchSelect from 'components/SearchSelect'
import PackageSelect from './PackageSelect'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'

const Create = props => {
  const [ selectModuleID, updateSelectModuleID ] = useState(0)

  const onChangeOrderData = ( key, val ) => {
    let tmp = _.cloneDeep( props.newOrderData )
    tmp[ key ] = val
    return props.onChangeOrderHOC( 'newOrderData', tmp )
  }

  return (
    <Modal
      isOpen={ props.showNewOrderModal }
      size={ 'xl' }
      toggle={() => {}}>
      <ModalHeader toggle={() => props.onChangeOrderHOC( 'showNewOrderModal', false )}>{ Lang[ 'NEW_ORDER' ][ props.data.languageReducer.lang ] }</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <FormGroup row>
            <Label for="center" sm={ 2 }>{ Lang[ 'CENTER' ][ props.data.languageReducer.lang ] }</Label>
            <Col sm={ 10 }>
              <DropdownSearchSelect
                options={ props.centers }
                onChange={ v => {
                  onChangeOrderData( 'center_id', v.id )
                }}
                value={ _.find( props.centers, { id: props.newOrderData.center_id } ) ? _.find( props.centers, { id: props.newOrderData.center_id } ) : {} }
                placeholder={ Lang[ 'SELECT_CENTER' ][ props.data.languageReducer.lang ] }
                isDisabled={ 'id' in props.data } />
              <FormFeedback>{ props.errorMessage && props.errorMessage.center_id ? Lang[ 'CENTER_REQUIRED' ][ props.data.languageReducer.lang ] : '' }</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="aciso_order_num" sm={ 2 }>
              { 
                window.location.host.indexOf( 'aciso' ) > -1 
                  ? Lang[ 'ACISO_ORDER_NUMBER' ][ props.data.languageReducer.lang ]
                  : Lang[ 'ORDER_NUMBER' ][ props.data.languageReducer.lang ]
              }
            </Label>
            <Col sm={ 10 }>
              <Input
                name="aciso_order_num" id="aciso_order_num"
                placeholder={ 
                  window.location.host.indexOf( 'aciso' ) > -1
                    ? Lang[ 'ENTER_ACISO_ORDER_NUM' ][ props.data.languageReducer.lang ]
                    : Lang[ 'ENTER_ORDER_NUM' ][ props.data.languageReducer.lang ]
                }
                value={ props.newOrderData.aciso_order_num }
                onChange={ e => {
                  onChangeOrderData( 'aciso_order_num', e.target.value )
                }}
                invalid={ props.errorMessage && props.errorMessage.aciso_order_num ? true : false }/>
              <FormFeedback>{ props.errorMessage && props.errorMessage.aciso_order_num ? props.errorMessage.aciso_order_num : '' }</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="start_at" sm={ 2 }>{ Lang[ 'START_DATE' ][ props.data.languageReducer.lang ] }</Label>
            <Col sm={ 10 }>
              <Input
                type="date"
                value={ moment( props.newOrderData.start_date ).format( 'YYYY-MM-DD' ) }
                onChange={ e => {
                  let newdate = new Date( e.target.value )
                  onChangeOrderData( 'start_date',  newdate )
              }}/>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="remark" sm={ 2 }>{ Lang[ 'REMARK' ][ props.data.languageReducer.lang ] }</Label>
            <Col sm={ 10 }>
              <Input
                name="remark" id="remark"
                value={ props.newOrderData.remark }
                placeholder={ Lang[ 'ENTER_REMARK' ][ props.data.languageReducer.lang ] }
                onChange={ e => {
                  onChangeOrderData( 'remark', e.target.value ) 
                }}
              /> 
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="gdpr_settings" sm={ 2 }>{ Lang[ 'GDPR_SETTINGS' ][ props.data.languageReducer.lang ] }</Label>
            <Col sm={ 3 } className="d-flex flex-column justify-content-center" >
              <CustomInput
                type="checkbox"
                id="is_show_cookie_banner"
                checked={ props.newOrderData.is_show_cookie_banner }
                label={ Lang[ 'SHOW_COOKIE_BANNER' ][ props.data.languageReducer.lang ] }
                onChange={ e => onChangeOrderData( 'is_show_cookie_banner', e.target.checked ) }
              /> 
            </Col>
            <Col sm={ 3 } className="d-flex flex-column justify-content-center" >
              <CustomInput
                type="checkbox"
                id="is_show_content_overlay"
                checked={ props.newOrderData.is_show_content_overlay }
                label={ Lang[ 'SHOW_CONTENT_OVERLAY' ][ props.data.languageReducer.lang ] }
                onChange={ e => onChangeOrderData( 'is_show_content_overlay', e.target.checked ) }
              /> 
            </Col>
            <Col sm={ 4 } className="d-flex flex-column justify-content-center" >
              <CustomInput
                type="checkbox"
                id="is_show_data_protection_agreement"
                checked={ props.newOrderData.is_show_data_protection_agreement }
                label={ Lang[ 'SHOW_DATA_PROTECTION_AGREEMENT' ][ props.data.languageReducer.lang ] }
                onChange={ e => onChangeOrderData( 'is_show_data_protection_agreement', e.target.checked ) }
              /> 
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={ 2 }>{ Lang[ 'ASSIGN_PACKAGES' ][ props.data.languageReducer.lang ]}</Label>
            <Col sm={ 10 }>
              {
                props.packages.map( item => {
                  return (
                    <PackageSelect
                      key={ item.id }
                      item={ item }
                      value={ props.newOrderData.package_id }
                      lang={ props.data.languageReducer.lang }
                      onSelect={() => {
                        let tmpOrder = _.cloneDeep( props.newOrderData )
                        tmpOrder.package_id = item.id
                        if( _.findIndex( props.packages, { id: parseInt( item.id, 10 ) }) > -1 ) {
                          let tmp = _.find( props.packages, { id: parseInt( item.id, 10 ) } )
                          if( tmp.modules && tmp.modules.length > 0 ) {
                            let tmpModules = _.cloneDeep( props.newOrderData.modules )
                            tmp.modules.map( item => {
                              if( _.findIndex( props.newOrderData.modules, { id: item.id } ) < 0 ) {
                                tmpModules.push( item )
                              } 
                            })
                            tmpOrder.modules = tmpModules
                          }
                        }
                        props.onChangeOrderHOC( 'newOrderData', tmpOrder )
                      }}
                      onRemove={() => {
                        let tmpOrder = _.cloneDeep( props.newOrderData )
                        tmpOrder.package_id = null
                        tmpOrder.modules = _.filter( tmpOrder.modules, mod => _.findIndex( item.modules, item => item.id === mod.id ) === -1 )
                        props.onChangeOrderHOC( 'newOrderData', tmpOrder )
                      }} />
                  )
                })
              }
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={ 2 }>{ Lang[ 'ASSIGN_MODULES' ][ props.data.languageReducer.lang ]}</Label>
            <Col sm={ 8 }>
              <Input
                type="select"
                value={ selectModuleID }
                onChange={ e => {
                  updateSelectModuleID( parseInt( e.target.value, 10 ) )
                }}>
                <option></option>
                {
                  props.modules.data.map( item => {
                    if( _.findIndex( props.newOrderData.modules, { id: item.id }) < 0 ) {
                      return (
                        <option key={ item.id } value={ item.id }>{ item.name }</option>
                      )
                    }
                  })
                }
              </Input>
            </Col>
            <Col sm={ 2 }>
              <Button 
                color="primary" 
                onClick={() => {
                  let tmp = _.find( props.modules.data, obj => {
                    if( obj.id === selectModuleID ) {
                      return obj
                    }
                  })
                  let tmpModules = _.cloneDeep( props.newOrderData.modules )
                  if( tmp ) {
                    tmpModules.push( tmp )
                    onChangeOrderData( 'modules', tmpModules )
                    updateSelectModuleID( '' )
                  }
                }}>{ Lang[ 'ASSIGN' ][ props.data.languageReducer.lang ]}</Button>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={ 2 }>{ Lang[ 'ORDERED_MODULES' ][ props.data.languageReducer.lang ]}</Label>
            <Col sm={ 10 }>
              {
                props.newOrderData.modules.map( item => {
                  return (
                    <Card key={ item.id }>
                      <CardBody className="d-flex align-items-center justify-content-between flex-wrap">
                        <span className="mr-2">{ item.name }</span>
                        <Button
                          color="danger"
                          className="btn-icon btn-icon-only"
                          onClick={() => {
                            let tmp = _.cloneDeep( props.newOrderData.modules )
                            _.remove( tmp, { id: item.id })
                            onChangeOrderData( 'modules', tmp )
                          }}>
                          <i className="pe-7s-trash"/>
                        </Button>
                      </CardBody>
                    </Card>
                  )
                })
              }
            </Col>
          </FormGroup>
        </Form>
        { props.onLoadOrders && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            props.postOrder( props.newOrderData )
          }}>
          { Lang[ 'CREATE' ][ props.data.languageReducer.lang ] }
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default Create