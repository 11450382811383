import React, { Component } from 'react'
import {
  Button, FormGroup, Input, Label, Container, Row, Col, Table,
  Modal, ModalBody, ModalHeader, ModalFooter, CardHeader, Card, CardBody,
  UncontrolledTooltip
 } from 'reactstrap'
 import _ from 'lodash'

import PageTitle from 'components/PageTitle'
import ARKTable from 'components/Table'
import CategoryForm from './components/categoryForm'
import CategoryHOC from './actions/category'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import DeleteConfirmationDialog from 'components/Modal/delete'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

class ContentCategories extends Component{

  componentDidMount = () => {
    let tmp = {
      page: 1,
      is_paginated: true
    }
    this.props.getAllCategory( convertObjToBase64( tmp ) )
  }

  componentDidUpdate = pp => {
    if( pp.categoryModal && !this.props.categoryModal ) {
      this.props.onChangeCategoryHOC( 
        'categoryData',
        {
          name: {
            en: '',
            de: '',
            it: '',
            nl: ''
          },
          description: {
            en: '',
            de: '',
            it: '',
            nl: ''
          },
          status: 'ACTIVE'
        }
      )
    }
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Content Service' })
      return tmpModules.is_create
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Content Service' })
      return tmpModules.is_update
    }
  }

  handleSecurityModulesDelete = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Content Service' })
      return tmpModules.is_delete
    }
  }

  renderCreateCategory = () => {
    return(
      <Modal
        isOpen={ this.props.categoryModal }
        size="xl">
        <ModalHeader toggle={ () => this.props.handleCategoryModal( false ) }>
          { Lang[ 'NEW_CATEGORY' ][ this.props.data.languageReducer.lang ] }
        </ModalHeader>
        <ModalBody>
          <CategoryForm
            onChangeCategoryData={ this.props.onChangeCategoryData }
            data={ this.props.categoryData }
            errorMessage={ this.props.errorMessage } />
          <Button 
            className="mb-2 ml-3 mt-4" 
            color="info"
            onClick={ () => this.props.postContentCategory( this.props.categoryData ) }>
            { Lang[ 'CREATE' ][ this.props.data.languageReducer.lang ] }
          </Button>
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.handleCategoryModal( false ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderViewCategoriesDetails = () => {
    return(
      <span className="d-inline-block mb-2 mr-2">
        <Modal isOpen={ this.props.categoryModal } size="lg">
          <ModalHeader toggle={ () => this.props.handleCategoryModal( false, 'view' ) }>
            { Lang[ 'VIEW_CATEGORY' ][ this.props.data.languageReducer.lang ] }
          </ModalHeader>
          <ModalBody>
            {
              !_.isEmpty( this.props.selectedCategory ) && (
                <div className="scroll-area-lg">
                  <Table className="mb-0" bordered>
                    <tbody>
                      <tr>
                        <th scope="row">{ Lang[ 'NAME' ][ this.props.data.languageReducer.lang ] }</th>
                        <td>{ this.props.selectedCategory.name[ this.props.data.languageReducer.lang ] }</td>
                      </tr>
                      <tr>
                        <th scope="row">{ Lang[ 'DESCRIPTION' ][ this.props.data.languageReducer.lang ] }</th>
                        <td>{ this.props.selectedCategory.description[ this.props.data.languageReducer.lang ] }</td>
                      </tr>
                      <tr>
                        <th scope="row">{ Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ] }</th>
                        <td>{ this.props.selectedCategory.status }</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )
            }
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={ () => this.props.handleCategoryModal( false, 'view' ) }>OK</Button>
            {
              this.handleSecurityModulesUpdate() && (
                <Button
                  color="alternate"
                  onClick={ () => {
                    this.props.getSelectedCategory( this.props.selectedCategory.id )
                    this.props.handleCategoryModal( true, 'edit' )
                  }}>{ Lang[ 'EDIT_CATEGORY' ][ this.props.data.languageReducer.lang ] }</Button>
              )
            }

          </ModalFooter>
        </Modal>
      </span>
    )
  }

  renderEditCategory = () => {
    return(
      <Modal
        isOpen={ this.props.categoryModal }
        size="xl">
        <ModalHeader toggle={ () => this.props.handleCategoryModal( false ) }>
          { Lang[ 'EDIT_CATEGORY' ][ this.props.data.languageReducer.lang ] }
        </ModalHeader>
        <ModalBody>
          {
            !_.isEmpty( this.props.selectedCategory ) && (
              <>
                <CategoryForm
                  onChangeCategoryData={ this.props.onChangeCategoryData }
                  data={ this.props.selectedCategory }
                  errorMessage={ this.props.errorMessage } />
                <Button 
                  className="mb-2 mr-2 mt-4" 
                  color="info" 
                  onClick={ () => this.props.updateContentCategory( this.props.selectedCategory.id, this.props.selectedCategory ) }>
                  { Lang[ 'SAVE' ][ this.props.data.languageReducer.lang ] }
                </Button>
              </>
            )
          }
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.handleCategoryModal( false ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  render = () => {
    return(
      <>
        <PageTitle
          heading={ Lang[ 'ALL_CONTENT_CATEGORIES' ][ this.props.data.languageReducer.lang ] }
          icon="pe-7s-ticket icon-gradient bg-happy-fisher"
          buttons={[
            {
              buttonText: Lang[ 'NEW_CATEGORY' ][ this.props.data.languageReducer.lang ],
              onClick: () => this.props.handleCategoryModal( true, 'create' ),
              display: this.handleSecurityModulesCreate()
            }
          ]} />
        <Card className="main-card mb-3">
          <CardHeader>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }</CardHeader>
          <CardBody>
            <Container style={{ margin: 0 }}>
              <Row>
                <Col md={ 6 }>
                  <FormGroup>
                    <Label for="content" sm={ 2 }>{ Lang[ 'NAME' ][ this.props.data.languageReducer.lang ] }</Label>
                    <Input
                      name="teaser_text" id="teaser_text"
                      placeholder={ Lang[ 'SEARCH_BY_NAME' ][ this.props.data.languageReducer.lang ] }
                      value={ this.props.searchName }
                      onChange={ e => this.props.onChangeCategoryHOC( 'searchName', e.target.value ) }/>
                  </FormGroup>
                </Col>
                <Col md={ 6 } className="d-flex pt-1" style={{ alignItems: 'flex-end' }}>
                  <FormGroup>
                    <Button
                      color="primary"
                      onClick={ () => {
                        let tmp = {
                          page: 1,
                          is_paginated: true,
                          name: this.props.searchName
                        }
                        return this.props.getAllCategory( convertObjToBase64( tmp ) )
                      }}>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }</Button>
                  </FormGroup>
                  <FormGroup>
                    <Button
                      color="danger"
                      style={{ marginLeft: '20px' }}
                      onClick={ () => {
                        let tmp = {
                          page: 1,
                          is_paginated: true
                        }
                        this.props.onChangeCategoryHOC( 'searchName', '' )
                        return this.props.getAllCategory( convertObjToBase64( tmp ) )
                      }}>{ Lang[ 'RESET' ][ this.props.data.languageReducer.lang ] }</Button>
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
        <ARKTable
          data={ this.props.categories.data }
          columns={[
            {
              Header: Lang[ 'NAME' ][ this.props.data.languageReducer.lang ],
              accessor: 'name',
              Cell: ({ value }) => value[ this.props.data.languageReducer.lang ]
            },
            {
              Header: Lang[ 'DESCRIPTION' ][ this.props.data.languageReducer.lang ],
              accessor: 'description',
              Cell: ({ value }) => value[ this.props.data.languageReducer.lang ]
            },
            {
              Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
              accessor: "id",
              width: 200,
              show: this.handleSecurityModulesUpdate() || this.handleSecurityModulesDelete() ,
              Cell: ({value}) => (
                <div>
                  <Button
                    id={ `Categories-${ value }-view-category` }
                    className="mb-2 mr-2 btn-icon btn-icon-only"
                    color="primary"
                    onClick={ () => {
                      this.props.getSelectedCategory( value )
                      this.props.handleCategoryModal( true, 'view' )
                    }}>
                      <i className="pe-7s-look"> </i>
                  </Button>
                  <UncontrolledTooltip
                    target={ `Categories-${ value }-view-category` }
                    placement="top">{ Lang[ 'VIEW_CATEGORY' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                  {
                    this.handleSecurityModulesUpdate() && (
                      <>
                        <Button
                          id={ `Categories-${ value }-update-category` }
                          className="mb-2 mr-2 btn-icon btn-icon-only"
                          color="primary"
                          onClick={ () => {
                            this.props.getSelectedCategory( value )
                            this.props.handleCategoryModal( true, 'edit' )
                          }}>
                            <i className="pe-7s-note"> </i>
                        </Button>
                        <UncontrolledTooltip
                          target={ `Categories-${ value }-update-category` }
                          placement="top">{ Lang[ 'UPDATE_CATEGORY' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                      </>
                    )
                  }
                  {
                    this.handleSecurityModulesDelete() && (
                      <>
                        <Button
                          id={ `Categories-${ value }-delete-category` }
                          className="mb-2 mr-2 btn-icon btn-icon-only"
                          color="danger"
                          onClick={ () => {
                            this.props.getSelectedCategory( value )
                            this.props.onChangeCategoryHOC( 'deleteModal', true )
                          }}>
                            <i className="pe-7s-trash"> </i>
                        </Button>
                        <UncontrolledTooltip
                          target={ `Categories-${ value }-delete-category` }
                          placement="top">{ Lang[ 'DELETE_CATEGORY' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                      </>
                    )
                  }
                </div>
              )
            }
          ]}
          page={ this.props.categories.meta ? this.props.categories.meta.current_page : 1 }
          totalPages={ this.props.totalPages }
          onChangePage={ pg => {
            let tmp = {}
            if( this.props.searchName !== '' ){
              tmp = { page: pg, is_paginated: true, name: this.props.searchName }
            }else{
              tmp = { page: pg, is_paginated: true }
            }
            this.props.getAllCategory( convertObjToBase64( tmp ) )
          }}
          showPagination={ false }/>
        { this.props.mode === 'create' && this.renderCreateCategory() }
        { this.props.mode === 'edit' && this.renderEditCategory() }
        { this.props.mode === 'view' && this.renderViewCategoriesDetails() }
        {
          this.props.deleteModal && (
            <DeleteConfirmationDialog
              deleteOpen={ this.props.deleteModal }
              handleModalClose={ () => this.props.onChangeCategoryHOC( 'deleteModal', false ) }
              confirmAction={ () => {
                if( this.props.selectedCategory.id ){
                  this.props.deleteCategory( this.props.selectedCategory.id )
                }
                this.props.onChangeCategoryHOC( 'deleteModal', false )
              }}
              content={ Lang[ 'DELETE_CONFIRMATION_CATEGORY' ][ this.props.data.languageReducer.lang ] }
              lang={ this.props.data.languageReducer.lang }/>
          )
        }

        {
          ( this.props.data.ajaxReducer.ajaxCallProgress > 0 || this.props.onLoadCategory ) && <LoadingOverlay />
        }
      </>
    )
  }
}
export default CategoryHOC( ContentCategories )
