import React, { Component } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Button } from '@material-ui/core'

import HeaderMenuAccordion from './components/accordion'

import Lang from 'Lang/General'

class HeaderMenu extends Component {
  render = () => {
    let {
      onDragEndHeaderMainMenu,
      headerMenuData,
      selectedLanguageShortname,
      onUpdateHeaderMenuText,
      onAddHeaderMenu,
      onRemoveHeaderMenu,
      pages,
      onUpdateHeaderMenu,
      onReorderMenu,
      languages,
      lang
    } = this.props
    return (
      <div className="row" style={{ paddingTop: '15px' }}>
        <div className="col-md-9">
          <DragDropContext
            style={{ paddingTop: '15px' }}
            onDragEnd={ onDragEndHeaderMainMenu }>
            <Droppable droppableId="header-menu-droppable">
              {( provided, snapshot ) => {
                return (
                  <div
                    ref={ provided.innerRef }
                    { ...provided.droppableProps }>
                    {
                      headerMenuData && headerMenuData.length > 0 && headerMenuData.map(( item, index ) => {
                        return (
                          <Draggable
                            key={ item.id }
                            draggableId={ item.id }
                            index={ index }>
                            {( provided, snapshot ) => {
                              return (
                                <div>
                                  <div
                                    ref={ provided.innerRef }
                                    { ...provided.dragHandleProps }
                                    { ...provided.draggableProps }>
                                    <HeaderMenuAccordion
                                      item={ item }
                                      selectedLanguageShortname={ selectedLanguageShortname }
                                      index={ index }
                                      onUpdateHeaderMenuText={ onUpdateHeaderMenuText }
                                      onUpdateHeaderMenu={ onUpdateHeaderMenu }
                                      onClickDeleteMenu={ onRemoveHeaderMenu }
                                      level={ 1 }
                                      pages={ pages }
                                      lang={ lang } >
                                      <div style={{ padding: '15px' }}>
                                        {
                                          item && item.children && item.children.map(( child, childIndex ) => {
                                            return (
                                              <HeaderMenuAccordion
                                                key={ child.id }
                                                item={ child }
                                                parent={ item }
                                                selectedLanguageShortname={ selectedLanguageShortname }
                                                index={ childIndex }
                                                onUpdateHeaderMenuText={ onUpdateHeaderMenuText }
                                                onUpdateHeaderMenu={ onUpdateHeaderMenu }
                                                onClickDeleteMenu={ onRemoveHeaderMenu }
                                                level={ 2 }
                                                itemLength={ item.children.length }
                                                pages={ pages }
                                                onClickDown={() => onReorderMenu( item, childIndex, childIndex + 1 )}
                                                onClickUp={() => onReorderMenu( item, childIndex, childIndex - 1 )}>
                                                <div style={{ padding: '15px' }}>
                                                  {
                                                    child && child.children && child.children.map(( grandchild, grandchildIndex ) => {
                                                      return (
                                                        <HeaderMenuAccordion
                                                          key={ grandchild.id }
                                                          item={ grandchild }
                                                          parent={ child }
                                                          selectedLanguageShortname={ selectedLanguageShortname }
                                                          index={ grandchildIndex }
                                                          onUpdateHeaderMenuText={ onUpdateHeaderMenuText }
                                                          onUpdateHeaderMenu={ onUpdateHeaderMenu }
                                                          onClickDeleteMenu={ onRemoveHeaderMenu }
                                                          level={ 3 }
                                                          itemLength={ child.children.length }
                                                          pages={ pages }
                                                          onClickDown={() => onReorderMenu( child, grandchildIndex, grandchildIndex + 1 )}
                                                          onClickUp={() => onReorderMenu( child, grandchildIndex, grandchildIndex - 1 )}>
                                                          {/* <div style={{ padding: '15px' }}>
                                                            <Button
                                                              classes={{ root: 'btn-add-menu' }}
                                                              onClick={() => {
                                                                onAddHeaderMenu( 3, child )
                                                              }}>
                                                              { Lang[ 'ADD_NEW_MENU' ][ lang ] }
                                                            </Button>
                                                          </div> */}
                                                        </HeaderMenuAccordion>
                                                      )
                                                    })
                                                  }
                                                  <Button
                                                    classes={{ root: 'btn-add-menu' }}
                                                    onClick={() => {
                                                      onAddHeaderMenu( 2, child )
                                                    }}>
                                                    { Lang[ 'ADD_NEW_MENU' ][ lang ] }
                                                  </Button>
                                                </div>
                                              </HeaderMenuAccordion>
                                            )
                                          })
                                        }
                                        <Button
                                          classes={{ root: 'btn-add-menu' }}
                                          onClick={() => {
                                            onAddHeaderMenu( 1, item )
                                          }}>
                                          { Lang[ 'ADD_NEW_MENU' ][ lang ] }
                                        </Button>
                                      </div>
                                    </HeaderMenuAccordion>
                                  </div>
                                  { provided.placeholder }
                                </div>
                              )
                            }}
                          </Draggable>
                        )
                      })
                    }
                    { provided.placeholder }
                  </div>
                )
              }}
            </Droppable>
          </DragDropContext>
          <Button
            classes={{ root: 'btn-add-menu' }}
            onClick={() => {
              onAddHeaderMenu( 0 )
            }}>
            { Lang[ 'ADD_NEW_MENU' ][ lang ] }
          </Button>
        </div>
        <div className="col-md-3" style={{ display: 'flex', flexDirection: 'column' }}>
          {
            languages && languages.map( item => {
              return (
                <Button
                  key={ item.id }
                  classes={{ root: 'btn btn-primary' }}
                  style={{
                    background: selectedLanguageShortname === item.shortname ? '#007bff' : 'transparent'
                  }}>
                  { `${ item.name } - ${ item.shortname }` }
                </Button>
              )
            })
          }
        </div>
      </div>
    )
  }
}

export default HeaderMenu