import React, { useRef } from 'react'
import { 
  FormGroup, FormText, 
  Label, Input, Button, UncontrolledTooltip 
} from 'reactstrap'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import _ from 'lodash'
import ReactHTMLParser from 'react-html-parser'

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
import "filepond/dist/filepond.min.css"

import Lang from 'Lang/General'
import { generateFieldLabelStr } from '../utils'

registerPlugin( FilePondPluginImagePreview )

const InputFile = ({
  content,
  formValues,
  mode,
  isInputDisabled,
  currentLanguage,
  pageErrors,
  createSubmittedFormMedia,
  uploadedMedia
}) => {
  const filePondRef = useRef( null )

  return (
    <FormGroup 
      tag="fieldset"
      id={ content.id } 
      style={{ padding: content.padding, marginBottom: 0, ...content.style }}>
      <Label className="ck-content" style={{ width: "100%" }}>
        { ReactHTMLParser( generateFieldLabelStr(content, formValues, currentLanguage) ) }
        {
          content.showQuickInfo && (
            <UncontrolledTooltip
              target={`quick-info-tooltip-${content.id}`} 
              placement="top">
              { content.preview_text }
            </UncontrolledTooltip>
          )
        }
      </Label>
      {
        _.findIndex( pageErrors, { id: content.id } ) > -1 &&
        <style>{ `#${ content.id } .filepond--panel-root { border: 1px solid #dc3545; }` }</style>
      }
      <FilePond
        ref={ filePondRef }
        disabled={ isInputDisabled }
        allowMultiple={ true }
        allowFileTypeValidation={ true }
        allowImagePreview={ true }
        acceptedFileTypes={[ 'application/pdf', 'image/png', 'image/jpeg', 'image/jpg' ]}
        onaddfile={ ( error, file ) => {
          if( mode !== 'edit' ){
            const reader = new FileReader()
            reader.onload = e => {
              let tmpMedia = {
                name: `${ formValues[ content.dataMapIndex ].dataLabel }_${ file.filename }`,
                raw: e.target.result
              }
              createSubmittedFormMedia( content.id, tmpMedia )
            }
            reader.readAsDataURL( file.file )
            filePondRef.current.removeFile( file.id )
          } 
        }}
      />
      {
        uploadedMedia.filter( x => x.formContentId === content.id ).length > 0 && <>
          <Label className="ck-content" style={{ width: "100%" }}>
            { Lang[ 'UPLOADED_FILE' ][ currentLanguage ] }
          </Label>
          {
            uploadedMedia.filter( x => x.formContentId === content.id ).map( uploadedFile => {
              return (
                <div className="d-flex flex-row mb-1">
                  <Input
                    disabled
                    className='mr-2'
                    value={ uploadedFile.filename } />
                  <Button
                    color='primary'
                    onClick={ () => window.open( uploadedFile.url ) }>
                    { Lang[ 'DOWNLOAD' ][ currentLanguage ] }</Button>
                </div>
              )
            } )
          }
        </>
      }
      <FormText color="danger">
        { 
          (
            _.find( pageErrors, { id: content.id } ) &&
            _.find( pageErrors, { id: content.id } ).validation[ currentLanguage ]
          ) || ''
        }
      </FormText>
    </FormGroup>
  )
}

export default InputFile