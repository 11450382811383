import React, { Component } from 'react'

const withSearch = ( WrappedComponent ) => {
  class withSearchWrappedComponent extends Component {
    state = {
      websiteLogo: {},
      selectedSiteImageLogo: '',
      topContactBar: {},
      topContactBarColor: '',
      headerBackground: {},
      headerBackgroundColor: '',
      headerMenu: {},
      headerMenuItemColor: '',
      headerMenuItemHoverColor: ''
    }

    updateWithSearchState = ( key, param ) => this.setState({ [ key ]: param })

    searchLogo = param => {
      param.map( item => {
        if( item.className === 'header-website-logo' ) {
          this.setState({ websiteLogo: item, selectedSiteImageLogo: item.children[0].source })
        } else {
          if( item.children && item.children.length > 0 ) {
            this.searchLogo( item.children )
          }
        }
      })
    }

    searchTopContactBarColor = param => {
      param.map( item => {
        if( item.className === 'top-contact-info' ) {
          this.setState({ topContactBar: item, topContactBarColor: item.style.color })
        } else {
          if( item.children && item.children.length > 0 ) {
            this.searchTopContactBarColor( item.children )
          }
        }
      })
    }

    searchHeaderBackgroundColor = param => {
      param.map( item => {
        if( item.mapWith === 'header-background' ) {
          this.setState({ headerBackground: item, headerBackgroundColor: item.style.background })
        } else {
          if( item.children && item.children.length > 0 ) {
            this.searchHeaderBackgroundColor( item.children )
          }
        }
      })
    }

    searchHeaderMenu = param => {
      let tmpHeaderBlueprintObj = JSON.parse( param )
      this.setState({ 
        headerMenu: tmpHeaderBlueprintObj, 
        headerMenuItemColor: tmpHeaderBlueprintObj.children[0].style.color,
        headerMenuItemHoverColor: tmpHeaderBlueprintObj.children[1].children[0].style[":hover"].color })
    }

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          searchLogo={ this.searchLogo }
          searchTopContactBarColor={ this.searchTopContactBarColor }
          searchHeaderBackgroundColor={ this.searchHeaderBackgroundColor }
          searchHeaderMenu={ this.searchHeaderMenu }
          updateWithSearchState={ this.updateWithSearchState }
          websiteLogo={ this.state.websiteLogo }
          selectedSiteImageLogo={ this.state.selectedSiteImageLogo }
          topContactBar={ this.state.topContactBar }
          topContactBarColor={ this.state.topContactBarColor }
          headerBackground={ this.state.headerBackground }
          headerBackgroundColor={ this.state.headerBackgroundColor }
          headerMenu={ this.state.headerMenu }
          headerMenuItemColor={ this.state.headerMenuItemColor }
          headerMenuItemHoverColor={ this.state.headerMenuItemHoverColor } />
      )
    }
  }
  return withSearchWrappedComponent
}

export default withSearch