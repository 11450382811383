import React from 'react'
import Radium from 'proofpoint-radium'
import { 
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'

import { convertRange, weekDays } from '../utils/dates'
import { Details } from '../utils/courseplanHeaderText'

const CourseplanEventModal = ({
  isEventModalOpen,
  event,
  handleEventClick,
  selectedLanguageShortname
}) => {
  return (
    <Modal isOpen={ isEventModalOpen } style={{ width: "55vw" }} >
      <ModalHeader toggle={ () => handleEventClick( false ) } style={{ background: "#fff" }} />
      <ModalBody style={{ padding: "1rem" }} >
        <div style={{
          width: "100%",
          height: "30vh",
          border: '1px solid #000000',
          padding: "15px" }}>
          { event.img }</div>
        <h1 style={{ 
          padding: "15px 0 0 0", 
          fontSize: "20px", 
          fontWeight: "bold" }}>
          { event.title[selectedLanguageShortname] }</h1>
        <p style={{ 
          padding: "0 0 15px 0", 
          fontSize: "16px" }}>
          { weekDays[event.start.getDay()][selectedLanguageShortname] + ', ' + convertRange(event.start, event.end) }</p>
        <p style={{ fontSize: "14px" }}>{ event.desc[selectedLanguageShortname] }</p>
        { event.trainer && <p style={{ fontSize: "14px" }}>
          <span style={{ fontWeight: "bold" }}>{"Trainer"}</span> { event.trainer }</p> 
        }
      </ModalBody>
      <ModalFooter style={{ background: "#fff" }} >
       <Button 
          style={{ 
            fontSize: "16px",
            fontWeight: "300",
            textTransform: "uppercase",
            padding: "5px 20px",
            borderRadius: "3px",
            border: "1px solid #252525",
            background: "#252525" }}
          onClick={ () => { window.location.href = `${ window.location.origin }/kursplan/549` } }
          className="float-right">{ event.title[selectedLanguageShortname] + ' ' + Details[selectedLanguageShortname] }</Button>
      </ModalFooter>
    </Modal>
  )
}

export default Radium( CourseplanEventModal )
