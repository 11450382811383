import React from 'react'

const Image = props => {
  if( props.isBackgroundImage ) {
    return (
      <div
        style={{
          backgroundImage: `url(${ props.source })`,
          ...props.style,
        }}
        onClick={ props.onClick }></div>
    )
  } else {
    return (
      <img 
        key={ props.id } 
        style={ props.style } 
        src={ props.source }
        onClick={ props.onClick } />
    )
  }
}

export default Image