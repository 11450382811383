export const CountryList = [
  {
    code: "AUT",
    alpha3: "AUT",
    alpha2: "AT",
    name: "Austria",
    calling_code: ["43"],
  },
  {
    code: "BEL",
    alpha3: "BEL",
    alpha2: "BE",
    name: "Belgium",
    calling_code: ["32"],
  },
  {
    code: "FRA",
    alpha3: "FRA",
    alpha2: "FR",
    name: "France",
    calling_code: ["33"],
  },
  {
    code: "DEU",
    alpha3: "DEU",
    alpha2: "DE",
    name: "Germany",
    calling_code: ["49"],
  },
  {
    code: "ITA",
    alpha3: "ITA",
    alpha2: "IT",
    name: "Italy",
    calling_code: ["39"],
  },
  {
    code: "NLD",
    alpha3: "NLD",
    alpha2: "NL",
    name: "Netherlands",
    calling_code: ["31"],
  },
  {
    code: "ESP",
    alpha3: "ESP",
    alpha2: "ES",
    name: "Spain",
    calling_code: ["34"],
  },
  {
    code: "CHE",
    alpha3: "CHE",
    alpha2: "CH",
    name: "Switzerland",
    calling_code: ["41"],
  },
]

export const CountryListDe = [
  {
    code: "AUT",
    alpha3: "AUT",
    alpha2: "AT",
    name: "Österreich",
    calling_code: ["43"],
  },
  {
    code: "BEL",
    alpha3: "BEL",
    alpha2: "BE",
    name: "Belgien",
    calling_code: ["32"],
  },
  {
    code: "FRA",
    alpha3: "FRA",
    alpha2: "FR",
    name: "Frankreich",
    calling_code: ["33"],
  },
  {
    code: "DEU",
    alpha3: "DEU",
    alpha2: "DE",
    name: "Deutschland",
    calling_code: ["49"],
  },
  {
    code: "ITA",
    alpha3: "ITA",
    alpha2: "IT",
    name: "Italien",
    calling_code: ["39"],
  },
  {
    code: "NLD",
    alpha3: "NLD",
    alpha2: "NL",
    name: "Niederlande",
    calling_code: ["31"],
  },
  {
    code: "ESP",
    alpha3: "ESP",
    alpha2: "ES",
    name: "Spanien",
    calling_code: ["34"],
  },
  {
    code: "CHE",
    alpha3: "CHE",
    alpha2: "CH",
    name: "Schweiz",
    calling_code: ["41"],
  },
]
