import React from 'react'
import { 
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter, 
  Form, 
  FormGroup, 
  Label, 
  Input,
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CustomInput
} from 'reactstrap'
import { SketchPicker } from 'react-color'
import _ from 'lodash'

import Lang from 'Lang/General'


const formatHexColor = ( hexcolor ) => {
  if ( hexcolor[0] === '#' ){
    hexcolor = hexcolor.slice( 1, hexcolor.length )
  }
  hexcolor = hexcolor.replace( /[^a-fA-F0-9]/g,'' )
  if ( hexcolor.length === 3 ) {
    hexcolor = hexcolor.split('').map(( hex ) => { return hex + hex } ).join('')
  } else if ( hexcolor.length < 6 ) {
    hexcolor = hexcolor + '0'.repeat( 6 - hexcolor.length )
  } else if ( hexcolor.length > 6 ) {
    hexcolor = hexcolor.slice( 0, 6 )
  }
  return "#" + hexcolor
}

const FormFrameEditor = ({
  showUpdateFormFrame,
  selectedComponentToEdit,
  onChangeFormBuilderState,
  findObjectAndPatch,
  dom,
  currentLanguage,
  selectedLanguage
}) => {
  return (
    <Modal
      isOpen={ showUpdateFormFrame }
      size={ 'xl' }>
      <ModalHeader toggle={ () => onChangeFormBuilderState( 'showUpdateFormFrame', false )}>{ Lang[ 'EDIT_FORM_FRAME' ][ selectedLanguage ] }</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault() }>
          <Row>
            <Col md={ 12 }>
              <FormGroup>
                <Label>{ Lang[ 'CONTENT' ][ selectedLanguage ] }</Label>
                <Input
                  type="text"
                  value={ selectedComponentToEdit[ 'content' ] ? selectedComponentToEdit[ 'content' ][ currentLanguage ] : '' }
                  onChange={ e => {
                    let tmpDOM = _.cloneDeep( dom )
                    let tmp = _.cloneDeep( selectedComponentToEdit )
                    tmp[ 'content' ][ currentLanguage ] = e.target.value
                    findObjectAndPatch( tmpDOM, tmp )
                    onChangeFormBuilderState( 'selectedComponentToEdit', tmp )
                  }} />
              </FormGroup>
            </Col>
          </Row>
          <Card>
            <CardHeader>{ Lang[ 'TITLE_STYLE' ][ selectedLanguage ] }</CardHeader>
            <CardBody>
              <Row>
                <Col md={ 6 }>
                  <FormGroup>
                    <Label>{ Lang[ 'FONT_SIZE' ][ selectedLanguage ] }</Label>
                    <Input
                      type="number"
                      value={ selectedComponentToEdit[ 'title' ] ? selectedComponentToEdit[ 'title' ][ 'fontSize' ] : '' }
                      onChange={ e => {
                        let tmpDOM = _.cloneDeep( dom )
                        let tmp = _.cloneDeep( selectedComponentToEdit )
                        tmp[ 'title' ][ 'fontSize' ] = parseInt( e.target.value, 10 )
                        findObjectAndPatch( tmpDOM, tmp )
                        onChangeFormBuilderState( 'selectedComponentToEdit', tmp )
                      }} />
                  </FormGroup>
                  <FormGroup>
                    <Label>{ Lang[ 'FONT_WEIGHT' ][ selectedLanguage ] }</Label>
                    <Input
                      type="text"
                      value={ selectedComponentToEdit[ 'title' ] ? selectedComponentToEdit[ 'title' ][ 'fontWeight' ] : '' }
                      onChange={ e => {
                        let tmpDOM = _.cloneDeep( dom )
                        let tmp = _.cloneDeep( selectedComponentToEdit )
                        tmp[ 'title' ][ 'fontWeight' ] = e.target.value
                        findObjectAndPatch( tmpDOM, tmp )
                        onChangeFormBuilderState( 'selectedComponentToEdit', tmp )
                      }} />
                  </FormGroup>
                  <FormGroup>
                    <Label>{ Lang[ 'TEXT_ALIGN' ][ selectedLanguage ] }</Label>
                    <Input
                      type="select"
                      value={ selectedComponentToEdit[ 'title' ] ? selectedComponentToEdit[ 'title' ][ 'textAlign' ] : '' }
                      onChange={ e => {
                        let tmpDOM = _.cloneDeep( dom )
                        let tmp = _.cloneDeep( selectedComponentToEdit )
                        tmp[ 'title' ][ 'textAlign' ] = e.target.value
                        findObjectAndPatch( tmpDOM, tmp )
                        onChangeFormBuilderState( 'selectedComponentToEdit', tmp )
                      }}>
                      <option value={ 'left' }>{ Lang[ 'ALIGN_LEFT' ][ selectedLanguage ] }</option>
                      <option value={ 'center' }>{ Lang[ 'CENTER' ][ selectedLanguage ] }</option>
                      <option value={ 'right' }>{ Lang[ 'ALIGN_RIGHT' ][ selectedLanguage ] }</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label>{ Lang[ 'PADDING' ][ selectedLanguage ] }</Label>
                    <Input
                      type="text"
                      value={ selectedComponentToEdit[ 'title' ] ? selectedComponentToEdit[ 'title' ][ 'padding' ] : '' }
                      onChange={ e => {
                        let tmpDOM = _.cloneDeep( dom )
                        let tmp = _.cloneDeep( selectedComponentToEdit )
                        tmp[ 'title' ][ 'padding' ] = e.target.value
                        findObjectAndPatch( tmpDOM, tmp )
                        onChangeFormBuilderState( 'selectedComponentToEdit', tmp )
                      }} />
                  </FormGroup>
                  <CustomInput
                    id={ 'hide_title' }
                    type='checkbox'
                    label={ Lang[ 'HIDE_TITLE' ][ selectedLanguage ] }
                    checked={ selectedComponentToEdit[ 'title' ] ? selectedComponentToEdit[ 'title' ][ 'display' ] === 'none' : false }
                    onChange={ e => {
                      let tmpDOM = _.cloneDeep( dom )
                      let tmp = _.cloneDeep( selectedComponentToEdit )
                      if( e.target.checked ){
                        tmp[ 'title' ][ 'display' ] = 'none'
                      } else {
                        if( tmp[ 'title' ][ 'display' ]){
                          delete tmp[ 'title' ][ 'display' ]
                        }
                      }
                      findObjectAndPatch( tmpDOM, tmp )
                      onChangeFormBuilderState( 'selectedComponentToEdit', tmp )
                    }} />
                </Col>
                <Col md={ 6 }>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{ Lang[ 'COLOR' ][ selectedLanguage ] }</Label>
                        <SketchPicker 
                          disableAlpha={ true }
                          width={ 185 }
                          color={ selectedComponentToEdit[ 'title' ] ? formatHexColor(selectedComponentToEdit[ 'title' ][ 'color' ]) : '' } 
                          onChange={ col => {
                            let tmpDOM = _.cloneDeep( dom )
                            let tmp = _.cloneDeep( selectedComponentToEdit )
                            tmp[ 'title' ][ 'color' ] = col.hex
                            findObjectAndPatch( tmpDOM, tmp )
                            onChangeFormBuilderState( 'selectedComponentToEdit', tmp )
                          }} />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{ Lang[ 'BACKGROUND_COLOR' ][ selectedLanguage ] }</Label>
                        <SketchPicker 
                          disableAlpha={ true }
                          width={ 185 }
                          color={ selectedComponentToEdit[ 'title' ] ? selectedComponentToEdit[ 'title' ][ 'backgroundColor' ] : '' } 
                          onChange={ col => {
                            let tmpDOM = _.cloneDeep( dom )
                            let tmp = _.cloneDeep( selectedComponentToEdit )
                            tmp[ 'title' ][ 'backgroundColor' ] = col.hex
                            findObjectAndPatch( tmpDOM, tmp )
                            onChangeFormBuilderState( 'selectedComponentToEdit', tmp )
                          }} />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>{ Lang[ 'CONTAINER' ][ selectedLanguage ] }</CardHeader>
            <CardBody>
              <Row>
                <Col md={ 6 }>
                  <FormGroup>
                    <Label>{ Lang[ 'BORDER_WIDTH' ][ selectedLanguage ] }</Label>
                    <Input
                      type="number"
                      value={ selectedComponentToEdit[ 'container' ] ? selectedComponentToEdit[ 'container' ][ 'borderWidth' ] : '' }
                      onChange={ e => {
                        let tmpDOM = _.cloneDeep( dom )
                        let tmp = _.cloneDeep( selectedComponentToEdit )
                        tmp[ 'container' ][ 'borderWidth' ] = parseInt( e.target.value, 10 )
                        findObjectAndPatch( tmpDOM, tmp )
                        onChangeFormBuilderState( 'selectedComponentToEdit', tmp )
                      }} />
                  </FormGroup>
                </Col>
                <Col md={ 6 }>
                  <FormGroup>
                    <Label>{ Lang[ 'BORDER_COLOR' ][ selectedLanguage ] }</Label>
                    <SketchPicker 
                      disableAlpha={ true }
                      width={ 185 }
                      color={ selectedComponentToEdit[ 'container' ] ? selectedComponentToEdit[ 'container' ][ 'borderColor' ] : '' } 
                      onChange={ col => {
                        let tmpDOM = _.cloneDeep( dom )
                        let tmp = _.cloneDeep( selectedComponentToEdit )
                        tmp[ 'container' ][ 'borderColor' ] = col.hex
                        findObjectAndPatch( tmpDOM, tmp )
                        onChangeFormBuilderState( 'selectedComponentToEdit', tmp )
                      }} />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary" 
          onClick={ async () => {
            let tmp = _.cloneDeep( selectedComponentToEdit )
            let tmpDOM = _.cloneDeep( dom )
            findObjectAndPatch( tmpDOM, tmp )
            await onChangeFormBuilderState( 'dom', tmpDOM )
            await onChangeFormBuilderState( 'showUpdateFormFrame', false )
          }}>{ Lang[ 'SUBMIT' ][ selectedLanguage ] }</Button>
      </ModalFooter>
    </Modal>
  )
}

export default FormFrameEditor