import React from 'react'
import {
  Button, Label,
  Form, FormGroup,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Input, InputGroup, InputGroupText, InputGroupAddon
} from 'reactstrap'
import { isEmpty, cloneDeep } from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Lang from 'Lang/General'

const Update = props => {
  return(
    <Modal size='lg' isOpen={ !isEmpty( props.selectedEmailSetting ) } >
      <ModalHeader toggle={ () => props.onChangeEmailSettingsHOC( 'selectedEmailSetting', {} ) } >
        { Lang[ 'MONTHLY_EMAIL_SUBSCRIPTION' ][ props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>{ Lang[ 'QUOTA' ][ props.data.languageReducer.lang ] }</Label>
            <InputGroup>
              <Input
                step='1'
                type='number'
                value={ props.selectedEmailSetting.monthly_quota }
                onChange={ e => {
                  let tmpEmailSetting = cloneDeep( props.selectedEmailSetting )
                  tmpEmailSetting.monthly_quota = e.target.value
                  props.onChangeEmailSettingsHOC( 'selectedEmailSetting', tmpEmailSetting ) 
                }}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>/{ Lang[ 'MONTH' ][ props.data.languageReducer.lang ] }</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label>{ Lang[ 'EMAIL_SENT' ][ props.data.languageReducer.lang ] }</Label>
            <Input
              disabled
              type='text'
              value={ props.selectedEmailSetting.used }
            />
          </FormGroup>
          <FormGroup>
            <Label>{ Lang[ 'REMAINING_EMAIL' ][ props.data.languageReducer.lang ] }</Label>
            <Input
              disabled
              type='text'
              value={ props.selectedEmailSetting.monthly_quota - props.selectedEmailSetting.used }
            />
          </FormGroup>
        </Form>
        { props.onLoadEmailSettingsHOC && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => {
            let tmpEmailSetting = cloneDeep( props.selectedEmailSetting )
            tmpEmailSetting.monthly_quota = parseInt( tmpEmailSetting.monthly_quota )
            props.updateEmailSetting( tmpEmailSetting ) 
          }}>
          { Lang[ 'SUBMIT' ][ props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )
}

export default Update