import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { FormFeedback, Label } from 'reactstrap'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import uniqid from 'uniqid'
import Lang from 'Lang/General'

const CKEditor5 = props => {
  const id = uniqid()
  const [ charsLeft, updateCharsLeft ] = useState( '' )
  const [ charCount, updateCharCount ] = useState( 0 )

  useEffect( () => {
    if( ( props.maxLength || props.showCharCount ) && props.data && !_.isEmpty( props.buttonLang ) ) {
      const noOfChar = props.data.replace( /<[^>]+>/g, '' ).replace( /&nbsp;/g, '' ).length
      updateCharsLeft( props.maxLength - noOfChar )
      updateCharCount( noOfChar )
    }
  }, [ props.maxLength, props.data, props.buttonLang ] )

  return (
    <>
      { props.invalid && <style>{ `#${ id } .ck-content { border: 1px solid #dc3545 !important; }` }</style> }
      <div className="ckrte" id={ id }>
        <CKEditor
          onReady={ editor => {
            editor.editing.view.change(( writer ) => {
              writer.setStyle( 'height', '300px', editor.editing.view.document.getRoot() )
            })
          }}
          config={{
            toolbar: {
              items: [
                'bold', 'italic', 'underline', 'alignment', 'heading', 'bulletedList', 'numberedList', '|',
                'undo', 'redo', 'link', '|',
                'fontColor', 'fontBackgroundColor', '|', 
                'horizontalLine', 'blockQuote', 'code', '|', 'sourceEditing'
              ],
              shouldNotGroupWhenFull: true
            },
            link: {
              decorators: {
                openInNewTab: {
                  mode: 'manual',
                  label: 'Open in a new tab',
                  attributes: {
                    target: '_blank',
                    rel: 'noopener noreferrer'
                  }
                }
              }
            },
            heading: {
              options: [
                { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                { model: 'span', view: 'div', title: 'Span' }
              ]
            },
            fontColor: { colors: DEFAULT_RTE_COLORS },
            fontBackgroundColor: { colors: DEFAULT_RTE_COLORS }
          }}
          editor={ Editor.ClassicEditor }
          data={ props.data }
          onChange={ props.onChange }
        />
        <FormFeedback className={ `${ props.invalid && 'd-block' }` }>{ props.invalid }</FormFeedback>
        { props.maxLength && charsLeft !== '' && <Label>{ `${ Lang[ 'REMAINING_CHARACTERS' ][ props.selectedLanguage ] } ${ charsLeft }` }</Label> }
        { props.showCharCount && <Label>{ `${ Lang[ 'NO_OF_CHARACTERS' ][ props.selectedLanguage ] }: ${ charCount }` }</Label> }
      </div>
    </>
  )
}

const DEFAULT_RTE_COLORS = [
  { color: "hsl(0, 0%, 0%)", label: "Black" }, 
  { color: "hsl(0, 0%, 30%)", label: "Dim grey" }, 
  { color: "hsl(0, 0%, 60%)", label: "Grey" }, 
  { color: "hsl(0, 0%, 90%)", label: "Light grey" }, 
  { color: "hsl(0, 0%, 96%)", label: "White smoke" }, 
  { color: "hsl(0, 0%, 100%)", label: "White", hasBorder: true }, 
  { color: "hsl(0, 75%, 60%)", label: "Red" }, 
  { color: "hsl(30, 75%, 60%)", label: "Orange" }, 
  { color: "hsl(60, 75%, 60%)", label: "Yellow" }, 
  { color: "hsl(90, 75%, 60%)", label: "Light green" }, 
  { color: "hsl(120, 75%, 60%)", label: "Green" }, 
  { color: "hsl(150, 75%, 60%)", label: "Aquamarine" }, 
  { color: "hsl(180, 75%, 60%)", label: "Turquoise" }, 
  { color: "hsl(210, 75%, 60%)", label: "Light blue" }, 
  { color: "hsl(240, 75%, 60%)", label: "Blue" }, 
  { color: "hsl(270, 75%, 60%)", label: "Purple" }
]

export default CKEditor5