import React, { Component } from 'react'
import moment from 'moment'
import {
  Container, Row, Col, Card, CardBody, CardHeader, Button, Label,
  Modal, ModalBody, ModalHeader, ModalFooter, UncontrolledTooltip
} from 'reactstrap'
import Image from 'react-bootstrap/Image'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PageTitle from 'components/PageTitle'
import SiteTemplateHOC from './actions'
import TemplateForm from './components/form'

import Lang from 'Lang/General'

class SiteTemplateSettings extends Component {
  state={
    errorMessage: '',
  }

  componentDidMount = () => {
    this.props.getSiteTemplate()
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Site Template Config' })
      return tmpModules.is_create
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Site Template Config' })
      return tmpModules.is_update
    }
  }

  onUploadImage = ( event ) => {
    if( event.target.files[0] && event.target.files[0].size <= 50000 ) {
      let reader = new FileReader();
      let filename = event.target.files[0].name
      let type = event.target.files[0].type
      reader.onload = e => {
        let tmp = this.props.newTemplate
        tmp.preview = e.target.result
        return this.props.updateNewTemplate( tmp )
      }

      reader.readAsDataURL( event.target.files[0] );
    }
    else{
      alert("File is too large! Please choose file less than 50kb")
    }
  }

  onChangeImage = ( event ) => {
    if( event.target.files[0] && event.target.files[0].size <= 50000 ) {
      let reader = new FileReader();
      let filename = event.target.files[0].name
      let type = event.target.files[0].type
      reader.onload = e => {
        let tmp = this.props.selectedTemplate
        tmp.preview = e.target.result
        return this.props.updateSelectedTemplate( tmp )
      };
      reader.readAsDataURL( event.target.files[0] );
    }
    else{
      alert("File is too large! Please choose file less than 50kb")
    }
  }

  onDeleteImage = ( data ) => {
    let tmp = data
    tmp.preview = ''
    return this.props.updateNewTemplate( tmp )
  }

  renderCreateSiteTemplateDialog = () => {
    return (
      <Modal
        isOpen={ this.props.showCreateSiteTemplateDialog }
        size="xl">
        <ModalHeader>{ Lang[ 'NEW_TEMPLATE' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <TemplateForm
            data={ this.props.newTemplate }
            errorMessage={ this.state.errorMessage }
            onUploadImage={ this.onUploadImage }
            onDeleteImage={ () => this.onDeleteImage( this.props.newTemplate ) }
            updateTemplate={ this.props.updateNewTemplate }
            onLoadSiteTemplate={ this.props.onLoadSiteTemplate }
            lang={ this.props.data.languageReducer.lang }/> 
          <Button
            className="mb-2 mr-2"
            color="info"
            onClick={ () => {
              if ( this.props.newTemplate.name && this.props.newTemplate.name.length > 0 )
                this.props.createSiteTemplate( this.props.newTemplate )
              else
                this.setState({ errorMessage: 'Template name is required' })
            }}>{ Lang[ 'CREATE_NEW_TEMPLATE' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalBody>
        <ModalFooter>
          <Button
            className="mb-2 mr-2"
            color="primary"
            onClick={ () => {
              this.setState({ errorMessage: '' })
              this.props.updateShowCreateSiteTemplateDialog( false ) }}>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderEditSiteTemplateDialog = () => {
    return (
      <Modal
        isOpen={ this.props.showEditSiteTemplateDialog }
        size="xl">
        <ModalHeader>{ Lang[ 'EDIT_TEMPLATE' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <TemplateForm
            data={ this.props.selectedTemplate }
            errorMessage={ this.state.errorMessage }
            onUploadImage={ this.onChangeImage }
            onDeleteImage={ () => this.onDeleteImage( this.props.selectedTemplate ) }
            updateTemplate={ this.props.updateSelectedTemplate }
            edit={ true }
            onLoadSiteTemplate={ this.props.onLoadSiteTemplate }
            lang={ this.props.data.languageReducer.lang }/>
          <Button
            className="mb-2 mr-2"
            color="info"
            onClick={ () => this.props.updateSiteTemplate( this.props.selectedTemplate.id, this.props.selectedTemplate ) }>
            { Lang[ 'UPDATE_TEMPLATE' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalBody>
        <ModalFooter>
          <Button
            className="mb-2 mr-2"
            color="primary"
            onClick={ () => this.props.updateShowEditSiteTemplateDialog( false ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  render = () => {
    return (
      <>
        <PageTitle
          heading={ Lang[ 'ALL_TEMPLATE' ][ this.props.data.languageReducer.lang ] }
          icon="pe-7s-config icon-gradient bg-happy-fisher"
          buttons={[
            {
              buttonText: Lang[ 'CREATE_TEMPLATE' ][ this.props.data.languageReducer.lang ],
              onClick: () => this.props.updateShowCreateSiteTemplateDialog( true ),
              display: this.handleSecurityModulesCreate()
            }
          ]}/>
        <Container>
          <Row>
          {
            this.props.templates && this.props.templates.length > 0 && this.props.templates.map( item => {
              return(
                <Col md={ 6 }>
                  <Card className="main-card mb-5">
                    <CardHeader>
                      <div
                        className={ `mb-2 mr-auto badge ${ item.status === 'ACTIVE' ? 'badge-success' : item.status === 'ARCHIVED' ? 'badge-danger' : 'badge-warning' } ` }
                        style={{
                          position: 'absolute',
                          top: '12px',
                          left: '-2%',
                          fontSize: '16px'
                        }}>{ item.status }
                      </div>
                      <div
                        className="d-flex mr-1 mb-1 justify-content-end flex-row"
                        style={{ width: '100%' }}>
                        {
                          this.handleSecurityModulesUpdate() && (
                            <>
                              <Button
                                id={ `Site-template-${ item.id }-edit-template` }
                                color="link"
                                onClick={ () => this.props.getSelectedTemplateEdit( item.id ) }>
                                <i className="pe-7s-note" style={{ fontSize: '30px' }}/>
                              </Button>
                              <UncontrolledTooltip
                                target={ `Site-template-${ item.id }-edit-template` }
                                placement="top">{ Lang[ 'EDIT_TEMPLATE' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                            </>
                          )
                        }
                        {
                          this.handleSecurityModulesUpdate() && (
                            <>
                              <Button
                                id={ `Site-template-${ item.id }-template-editor` }
                                color="link"
                                onClick={ () => window.open( `${ window.location.origin }/#/template-editor/${ item.id }/${ item.name }`, '_blank' ) }>
                                <i className="pe-7s-config" style={{ fontSize: '30px' }}/>
                              </Button>
                              <UncontrolledTooltip
                                target={ `Site-template-${ item.id }-template-editor` }
                                placement="top">{ Lang[ 'TEMPLATE_EDITOR' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                            </>
                          )
                        }
                      </div>
                    </CardHeader>
                    <CardBody className="d-flex flex-column">
                      <Image
                        className="mb-2"
                        style={{ width: '95%', height: '300px' }}
                        src={ item.preview }/>
                      <Label size="lg">{ item.name }</Label>
                      <Label size="md">{ `Created at: ${ moment( item.createdAt ).format('DD/MM/YYYY, h:mm:ss a') } `}</Label>
                    </CardBody>
                  </Card>
                </Col>
              )
            })
          }
          { this.renderCreateSiteTemplateDialog() }
          { this.renderEditSiteTemplateDialog() }
          {
            ( this.props.data.ajaxReducer.ajaxCallProgress > 0 || this.props.onLoadSiteTemplate ) && <LoadingOverlay />
          }
          </Row>
        </Container>
      </>
    )
  }
}

export default SiteTemplateHOC( SiteTemplateSettings )
