import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      consultants: []
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    getConsultants = ( query ) => Get(
      `/api/v1/consultants?query=${ query }`,
      this.getConsultantsSuccess,
      this.getConsultantsError,
      this.load
    )
    getConsultantsSuccess = payload => this.setState({ consultants: payload.data })
    getConsultantsError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          getConsultants={ this.getConsultants }
          consultants={ this.state.consultants }
          onLoadConsultants={ this.state.loading } />
      )
    }
  }
  return WithHOC
}

export default HOC
