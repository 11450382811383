import React from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  CustomInput,
  Row,
  Col,
  FormGroup
} from 'reactstrap'
import _ from 'lodash'
import Lang from 'Lang/General'

const TextUtilities = ({
  onChangeSectionEditorState,
  selectedEditableComponent,
  lang
}) => {
  return (
    <Card className="mb-2">
      <CardHeader>{ Lang[ 'TEXT_SETTING' ][ lang ] }</CardHeader>
      <CardBody>
        <Button 
          color="primary"
          onClick={() => {
            onChangeSectionEditorState( 'showCodeTagEditor', true )
          }}>
          { Lang[ 'EDIT_TEXT' ][ lang ] }
        </Button>
        <Row>
          <Col md={ 6 }>
            <FormGroup>
              <CustomInput
                type={ 'checkbox' }
                checked={  
                  selectedEditableComponent ? 
                    selectedEditableComponent.style  
                      ? selectedEditableComponent.style.position === 'absolute'
                      : false
                    : false
                } />
              { Lang[ 'FLOAT' ][ lang ] }
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default TextUtilities