import React, { useState, Fragment } from 'react'
import { connect } from 'react-redux'
import {
  Label, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap'
import _ from 'lodash'

import Lang from 'Lang/General'

const NewUploadImages = ({
  newUploadMedia,
  selectedImages,
  handleImageCheck,
  data
}) => {
  const [ imageClicked, updateClick ] = useState( false )
  const [ previewUrl, updatePreviewUrl ] = useState( '' )

  return(
    <Fragment>
      <Label>{ Lang[ 'NEW_UPLOADED' ][ data.languageReducer.lang ] }</Label>
      <div>
        {
          newUploadMedia && newUploadMedia.map( item => {
            let tmp = false
            selectedImages.map( img => {
              if( img.id === item.id ) {
                tmp = true
              }
            })
            return(
              <div
                key={ item.id }
                style={{
                  position: 'relative',
                  display: 'inline-block',
                  margin: '10px'
                }}>
                <FormGroup>
                  <Input
                    id={ item.id }
                    style={{
                      position: 'absolute',
                      left: '13px',
                      top: '5px',
                      width: '23px',
                      height: '18px',
                      zIndex: 999
                    }}
                    type="checkbox"
                    checked={ _.findIndex( selectedImages, { id: item.id } ) > -1 }
                    onChange={ e => {
                      handleImageCheck( item.id, 'new' )
                    }}/>
                </FormGroup>
                <img
                  key={ item.id }
                  src={ item.thumb_url }
                  style={{
                    width: 'auto',
                    height: 'auto',
                  }}
                  onClick={ () => {
                    updateClick( true )
                    updatePreviewUrl( item.original_url )
                  }} />
                <p
                  style={{
                    fontSize: '12px',
                    textAlign: 'center'
                  }}>{ item.short_name['en'] }</p>
              </div>
            )
          })
        }
        {
          imageClicked && (
            <Modal
              isOpen={ imageClicked }
              toggle={ () => updateClick( false ) }
              size="lg">
              <ModalHeader
                toggle={ () => updateClick( false ) }>{ Lang[ 'IMAGE_PREVIEW' ][ data.languageReducer.lang ] }
              </ModalHeader>
              <ModalBody>
                <img
                  id="previewImage"
                  src={ previewUrl }
                  style={{
                    width: '100%',
                    height: 'auto',
                    alignSelf: 'center'
                  }}/>
              </ModalBody>
              <ModalFooter>
                <Button className="mb-2 mr-2" color="primary" onClick={ () => updateClick( false ) }>OK</Button>
              </ModalFooter>
            </Modal>
          )
        }
      </div>
    </Fragment>
  )
}
const mapStateToProps = state => ({ data: state })

export default connect( mapStateToProps )( NewUploadImages )
