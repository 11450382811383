import React from 'react'
import { Form, FormGroup, Label, Input } from 'reactstrap'
import _ from 'lodash'
import MultiLangRTE from 'components/RTE/MultiLang'
import Lang from 'Lang/General'

const PrivacyPolicyForm = ({
  selectedPrivacyPolicy,
  onChangePrivacyPolicyHOC,
  lang
}) => {
  return (
    <Form onSubmit={ e => e.preventDefault() }>
      <FormGroup>
        <Label>{ Lang[ 'CENTER' ][ lang ] }</Label>
        <Input
          disabled
          type="text"
          value={ selectedPrivacyPolicy.center_name } />
      </FormGroup>
      <MultiLangRTE
        label={ Lang[ 'CONTENT' ][ lang ] }
        value={ selectedPrivacyPolicy.content } 
        onChange={ val => {
          let tmp = _.cloneDeep( selectedPrivacyPolicy )
          tmp.content = val
          onChangePrivacyPolicyHOC( 'selectedPrivacyPolicy', tmp )
        }}
        selectedLanguage={ lang } />
    </Form>
  )
}

export default PrivacyPolicyForm 
