import React, { Component } from 'react'
import { Dialog, Button } from '@material-ui/core'
import _ from 'lodash'
import { MdClose } from 'react-icons/md'
import { compose } from 'redux'

import TemplateEditorCard from '../components/card'
import FooterBlueprint from './FooterBlueprint'
import FooterStyling from './FooterStyling'
import LoadingModal from 'components/Indicator/LoadingOverlay'

import WithSearch from './withSearch'

import './index.scss'

import Lang from 'Lang/General'

class FooterEditor extends Component {
  state = {
    mode: 'Footer Blueprint',
    footerBlueprint: [],
    footerBlueprintDesktop: {},
    footerBlueprintMobile: {},
    selectedFooterBlueprint: '',
    selectedFooterBlueprintView: 'Desktop'
  }

  componentDidUpdate = pp => {
    if( this.props.templateFooterBlueprints !== pp.templateFooterBlueprints ) {
      if( !_.isEmpty( this.props.templateFooterBlueprints ) ) {
        this.onChangeState( 'footerBlueprint', this.props.templateFooterBlueprints )
        let tmp = ''
        _.find( this.props.templateFooterBlueprints, obj => {
          if( obj.name.indexOf( this.state.selectedFooterBlueprintView ) > -1 ) {
            tmp = obj.stringifyContent
          }
        })
        this.onChangeState( 'selectedFooterBlueprint', tmp )
        this.props.updateWithSearchState( 'footer', tmp )
        this.props.searchFooterColor( JSON.parse( tmp ) )
      }
    }
  }

  onChangeFooterBackgroundColor = col => {
    let tmpDesktop = {}
    let tmpMobile = {}
    this.props.updateWithSearchState( 'footerBackgroundColor', col )
    if( _.isEmpty(this.state.footerBlueprintDesktop) ){
      let tmpIndexDesktop = _.findIndex( this.props.templateFooterBlueprints, obj => obj.name.indexOf( 'Desktop' ) > -1 ) 
      tmpDesktop = _.cloneDeep( this.props.templateFooterBlueprints[ tmpIndexDesktop ] )
    }
    else{
      tmpDesktop = this.state.footerBlueprintDesktop
    }

    if( _.isEmpty(this.state.footerBlueprintMobile) ){
      let tmpIndexMobile = _.findIndex( this.props.templateFooterBlueprints, obj => obj.name.indexOf( 'Mobile' ) > -1 )
      tmpMobile = _.cloneDeep( this.props.templateFooterBlueprints[ tmpIndexMobile ] )
    }
    else{
      tmpMobile = this.state.footerBlueprintMobile
    }

    tmpDesktop.content.style.background = col
    tmpMobile.content.style.background = col

    this.setState({
      footerBlueprintDesktop: tmpDesktop,
      footerBlueprintMobile: tmpMobile
    })
  }

  onChangeFooterTextColor = col => {
    let tmpDesktop = {}
    let tmpMobile = {}
    this.props.updateWithSearchState( 'footerTextColor', col )
    if( _.isEmpty(this.state.footerBlueprintDesktop) ){
      let tmpIndexDesktop = _.findIndex( this.props.templateFooterBlueprints, obj => obj.name.indexOf( 'Desktop' ) > -1 ) 
      tmpDesktop = _.cloneDeep( this.props.templateFooterBlueprints[ tmpIndexDesktop ] )
    }
    else{
      tmpDesktop = this.state.footerBlueprintDesktop
    }

    if( _.isEmpty(this.state.footerBlueprintMobile) ){
      let tmpIndexMobile = _.findIndex( this.props.templateFooterBlueprints, obj => obj.name.indexOf( 'Mobile' ) > -1 )
      tmpMobile = _.cloneDeep( this.props.templateFooterBlueprints[ tmpIndexMobile ] )
    }
    else{
      tmpMobile = this.state.footerBlueprintMobile
    }

    tmpDesktop.content.style.color = col
    this.findPatchFooterColor( tmpDesktop.content.children, col )
    tmpMobile.content.style.color = col
    this.findPatchFooterColor( tmpMobile.content.children, col )

    this.setState({
      footerBlueprintDesktop: tmpDesktop,
      footerBlueprintMobile: tmpMobile
    })
  }

  findPatchFooterColor = ( data, newColor ) => {
    data.map( item => {
      if( 
        ( item.type === 'a' || item.variant === 'text' ) && !_.isEmpty( item.style ) ||
        ( item.type === 'div' && _.isEmpty( item.children ) && !_.isEmpty( item.style ) )
      ) {
        if( item.type === 'div' && item.variant !== 'text' && !item.mapWith ){
          item.style.background = newColor
        } else {
          item.style.color = newColor
        }
        if( !_.isEmpty( item.style.borderRight ) ) {
          item.style.borderRight = "1px solid " + newColor
        }
        
        return data
      } else {
        if( item.children ) {
          this.findPatchFooterColor( item.children, newColor )
        }
      }
    })
  }

  onChangeState = ( key, val ) => this.setState({ [ key ]: val })

  onSelectFooterBlueprint = param => {
    this.onChangeState( 'selectedFooterBlueprintView', param )
    let tmp = ''
    _.find( this.props.templateFooterBlueprints, obj => {
      if( obj.name.indexOf( param ) > -1 ) {
        tmp = obj.stringifyContent
      }
    })
    this.onChangeState( 'selectedFooterBlueprint', tmp )
  }

  onChangeSelectedFooterBlueprint = param => this.onChangeState( 'selectedFooterBlueprint', param )

  render = () => {
    return (
      <Dialog
        open={ this.props.showFooterEditorDialog }
        maxWidth={ 'lg' }
        fullWidth={ true }>
        <TemplateEditorCard>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h4>{ Lang[ 'EDIT_FOOTER' ][ this.props.lang ] }</h4>
            <Button
              classes={{
                root: "button-close",
                label: "button-text" }}
              onClick={ () => this.props.updateMainContainerState( 'showFooterEditorDialog', false ) }>
              <MdClose style={{ fontSize: '20px' }}/>
            </Button>
          </div>
          <hr />
          <div className="container">
            <div className="footer-editor-toggle">
              <button
                className="btn btn-primary btn-toggle"
                onClick={ () => this.onChangeState( 'mode', 'Footer Blueprint' ) }>{ Lang[ 'FOOTER_BLUEPRINT' ][ this.props.lang ] }</button>
              <button
                className="btn btn-success btn-toggle"
                onClick={ () => this.onChangeState( 'mode', 'Footer Styling' ) }>{ Lang[ 'FOOTER_STYLING' ][ this.props.lang ] }</button>
            </div>
            {
              this.state.mode === 'Footer Blueprint' && (
                <FooterBlueprint
                  onSelectFooterBlueprint={ this.onSelectFooterBlueprint }
                  selectedFooterBlueprint={ this.state.selectedFooterBlueprint }
                  onChangeSelectedFooterBlueprint={ this.onChangeSelectedFooterBlueprint }
                  lang={ this.props.lang } />
              )
            }
            {
              this.state.mode === 'Footer Styling' && (
                <FooterStyling
                  footerTextColor={ this.props.footerTextColor }
                  footerBackgroundColor={ this.props.footerBackgroundColor }
                  onChangeFooterBackgroundColor={ this.onChangeFooterBackgroundColor }
                  onChangeFooterTextColor={ this.onChangeFooterTextColor }
                  lang={ this.props.lang } />
              )
            }
          </div>
          <hr />
          <div className="footer-editor-footer">
            {
              this.state.mode === 'Footer Blueprint' && (
                <Button
                  classes={{ root: 'btn-primary' }}
                  style={{ marginRight: '10px' }}
                  onClick={() => {
                    let tmp = {}
                    _.find( this.props.templateFooterBlueprints, obj => {
                      if( obj.name.indexOf( this.state.selectedFooterBlueprintView ) > -1 ) {
                        tmp = obj
                      }
                    })
                    tmp.content = JSON.parse( this.state.selectedFooterBlueprint )
                    this.props.updateTemplateFooterBlueprint( this.props.template_id, tmp )
                  }}>
                  { `${ Lang[ 'SAVE_FOOTER_BLUEPRINT' ][ this.props.lang ] } (${ this.state.selectedFooterBlueprintView })` }
                </Button>
              )
            }
            {
              this.state.mode === 'Footer Styling' && (
                <Button
                  classes={{ root: 'btn-primary' }}
                  style={{ marginRight: '10px' }}
                  onClick={() => {
                    this.props.updateTemplateFooterBlueprintStyling( 
                      this.props.template_id,
                      this.state.footerBlueprintDesktop, 
                      this.state.footerBlueprintMobile )
                  }}>
                  { Lang[ 'SAVE_FOOTER_STYLING' ][ this.props.lang ] }
                </Button>
              )
            }
            <Button
              classes={{ root: 'btn-secondary' }}
              onClick={() => this.props.updateMainContainerState( 'showFooterEditorDialog', false )}>
              { Lang[ 'CLOSE' ][ this.props.lang ] }
            </Button>
          </div>
          {
            this.props.onLoadFooterBlueprintsUpdate && <LoadingModal />
          }
        </TemplateEditorCard>
      </Dialog>
    )
  }
}

export default compose(
  WithSearch
)( FooterEditor )
