import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { storeItem, clearItem } from 'utils/tokenStore';
import { Put } from 'utils/axios'
import Lang from 'Lang/General'

const ResetPasswordHOC = ( WrappedComponent ) => {
  class ResetPasswordWrappedComponent extends Component{
    state = {
      loading: false,
      forgotPasswordEmail: {
        email: ''
      },
      resetPasswordFields: {
        password: '',
        password_confirmation: '',
        password_reset_token: ''
      },
      successMessage: '',
      errorMessage: ''
    }

    load = param => this.setState({ loading: param })

    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    onChangeResetPassword = ( key, value ) => this.setState({ [key]: value })

    forgotPassword = data => Put(
      `/passwords/reset`,
      data,
      this.forgotPasswordSuccess,
      this.forgotPasswordError,
      this.load
    )
    forgotPasswordSuccess = payload => {
      storeItem( 'RESET_PASSWORD_TOKEN', payload )
      this.setState({ successMessage: payload.msg, errorMessage: '' })
    }
    forgotPasswordError = error => this.setState({ errorMessage: error, successMessage: '' })

    resetPassword = data => Put(
      `/passwords`,
      data,
      this.resetPasswordSuccess,
      this.resetPasswordError,
      this.load
    )
    resetPasswordSuccess = payload => {
      this.requestSuccess( Lang[ 'PASSWORD_RESET_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      clearItem( 'RESET_PASSWORD_TOKEN' )
      this.setState({
        resetPasswordFields: {
          password: '',
          password_confirmation: '',
          password_reset_token: ''
        }
      })
    }
    resetPasswordError = error => this.setState({ errorMessage: error.password })

    render = () => {
      return(
        <>
          <WrappedComponent
            { ...this.props }
            onLoadResetPassword={ this.state.loading }
            forgotPassword={ this.forgotPassword }
            resetPassword={ this.resetPassword }
            onChangeResetPassword={ this.onChangeResetPassword }
            forgotPasswordEmail={ this.state.forgotPasswordEmail }
            resetPasswordFields={ this.state.resetPasswordFields }
            successMessage={ this.state.successMessage }
            errorMessage={ this.state.errorMessage } />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( ResetPasswordWrappedComponent )
}

export default ResetPasswordHOC
