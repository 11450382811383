import React from 'react'
import { Container, Button } from 'reactstrap'
import Radium from 'proofpoint-radium'

import CoreRenderFunction from '../../core/render'

const ContainerComponent = ({
  content,
  mode,
  formValues,
  dom,
  selectedLanguageShortname 
}) => {
  return <>
    <Container style={ content.style }>
      {
        content.children && content.children.map( child => {
          return <CoreRenderFunction
            item={ child }
            content={ child }
            mode={ mode }
            formValues={ formValues }
            dom={ dom }
            selectedLanguageShortname ={ selectedLanguageShortname  } />
        })
      }
    </Container>
  </>
}

export default Radium( ContainerComponent )
