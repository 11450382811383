import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { toast } from 'react-toastify'

import { Get, Put, Post, Delete } from 'utils/axios-template'
import { production_url } from '../config'

import Lang from 'Lang/General'

const PagesHOC = ( WrappedComponent ) => {
  class PagesWrappedComponent extends Component {
    state = {
      loading: false,
      updateLoading: false,
      showStatusModal: false,
      statusModalType: '',
      onClickStatusModalButton: () => {},
      statusModalMessage: '',
      pages: [],
      selectedPageID: '',
      selectedPage: {},
      newPage: {
        name: '',
        url: ''
      },
      showPageCreator: false,
      showPageEdit: false,
      showConfirmRemovePage: false
    }

    load = param => this.setState({ loading: param })
    updateLoad = param => this.setState({ updateLoading: param })

    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    updateSelectedPageID = param => this.setState({ selectedPageID: param })
    onChangeSelectedPage = ( param, key ) => {
      let tmp = _.cloneDeep( this.state.selectedPage )
      tmp[ key ] = param
      return this.setState({ selectedPage: tmp })
    }
    onChangeNewPage = ( param, key ) => {
      let tmp = _.cloneDeep( this.state.newPage )
      tmp[ key ] = param
      return this.setState({ newPage: tmp })
    }
    onChangeShowPageCreator = param => this.setState({ showPageCreator: param })
    onChangeShowPageEdit = param => this.setState({ showPageEdit: param })
    onChangeShowConfirmRemovePage = param => this.setState({ showConfirmRemovePage: param })
    onSearchPageJSON = param => {
      if( param && param !== '' ) {
        this.searchPageJSON( this.state.selectedPage.content, param )
      } else {
        let tmp = _.cloneDeep( this.state.selectedPage )
        tmp.searchPageJSON = tmp.contentJSONString
        this.setState({ selectedPage: tmp })
      }
    }

    searchPageJSON = ( param, searchingID ) => {
      if( param.children ) {
        param.children.map( item => {
          if( item.id === searchingID ) {
            let tmp = _.cloneDeep( this.state.selectedPage )
            tmp.searchPageJSON = JSON.stringify( item, null, 2 )
            this.setState({ selectedPage: tmp })
          } else {
            if( item.children ) {
              this.searchPageJSON( item, searchingID )
            }
          }
        })
      }
    }

    getPages = template_id => Get(
      `${ production_url }/templates/${ template_id }/pages`,
      payload => this.getPagesSuccess( payload, template_id ),
      this.getPagesError,
      this.load
    )
    getPagesSuccess = ( payload, template_id ) => {
      this.setState({ pages: payload.pages })
      if( payload.pages.length > 0 ) {
        this.updateSelectedPageID( payload.pages[ 0 ].id )
        this.getSelectedPage( template_id, payload.pages[ 0 ].id )
      } else {
        this.setState({ showPageCreator: true })
      }
    }
    getPagesError = error => this.requestError( error )

    getSelectedPage = ( template_id, page_id ) => Get(
      `${ production_url }/templates/${ template_id }/pages/${ page_id }`,
      this.getSelectedPageSuccess,
      this.getSelectedPageError,
      this.load
    )
    getSelectedPageSuccess = payload => {
      this.setState({ selectedPage: {
        ...payload.page,
        contentJSONString: JSON.stringify( payload.page.content, null, 2 ),
        searchPageJSON: JSON.stringify( payload.page.content, null, 2 )
      }})
    }
    getSelectedPageError = error => this.requestError( error )

    getPagesAfterCreate = ( template_id, page_id ) => Get(
      `${ production_url }/templates/${ template_id }/pages`,
      payload => this.getPagesAfterCreateSuccess( page_id, template_id ),
      this.getPagesAfterCreateError,
      this.load
    )
    getPagesAfterCreateSuccess = ( page_id, template_id ) => {
      this.updateSelectedPageID( page_id )
      this.getSelectedPage( template_id, page_id )
    }
    getPagesAfterCreateError = error => this.requestError( error )

    updateSelectedPage = ( template_id, page_id, data, type ) => Put(
      `${ production_url }/templates/${ template_id }/pages/${ page_id }`,
      data,
      () => this.updateSelectedPageSuccess( template_id, page_id, type ),
      this.updateSelectedPageError,
      this.updateLoad
    )
    updateSelectedPageSuccess = ( template_id, page_id, type ) => {
      this.getSelectedPage( template_id, page_id )
      if( type === 'edit' ) {
        this.getPages( template_id )
        // this.setState({ showPageEdit: false })
      }
      this.requestSuccess( Lang[ 'PAGE_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
    }
    updateSelectedPageError = error => this.requestError( error )

    createPage = ( template_id, data ) => Post(
      `${ production_url }/templates/${ template_id }/pages`,
      data,
      payload => this.createPageSuccess( template_id, payload ),
      this.createPageError,
      this.updateLoad
    )
    createPageSuccess = ( template_id, payload ) => {
      this.getPages( template_id )
      this.setState({ showPageCreator: false })
      this.requestSuccess( Lang[ 'PAGE_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getPagesAfterCreate( template_id, payload.page.id )
    }
    createPageError = error => this.requestError( error )

    removePage = ( template_id, page_id ) => Delete(
      `${ production_url }/templates/${ template_id }/pages/${ page_id }`,
      () => this.removePageSuccess( template_id ),
      this.removePageError,
      this.load
    )
    removePageSuccess = template_id => {
      this.getPages( template_id )
      this.requestSuccess( Lang[ 'PAGE_REMOVE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
    }
    removePageError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            getPages={ this.getPages }
            updateSelectedPageID={ this.updateSelectedPageID }
            getSelectedPage={ this.getSelectedPage }
            onChangeSelectedPage={ this.onChangeSelectedPage }
            updateSelectedPage={ this.updateSelectedPage }
            createPage={ this.createPage }
            removePage={ this.removePage }
            onChangeNewPage={ this.onChangeNewPage }
            onChangeShowPageCreator={ this.onChangeShowPageCreator }
            onChangeShowPageEdit={ this.onChangeShowPageEdit }
            onChangeShowConfirmRemovePage={ this.onChangeShowConfirmRemovePage }
            onSearchPageJSON={ this.onSearchPageJSON }
            newPage={ this.state.newPage }
            pages={ this.state.pages }
            selectedPageID={ this.state.selectedPageID }
            selectedPage={ this.state.selectedPage }
            onLoadPages={ this.state.loading }
            onLoadPagesUpdate={ this.state.updateLoading }
            showPageCreator={ this.state.showPageCreator }
            showPageEdit={ this.state.showPageEdit }
            showConfirmRemovePage={ this.state.showConfirmRemovePage } />
        </>
      )
    }
  }

  const mapStateToProps = state => ({ data: state })

  return connect( mapStateToProps )( PagesWrappedComponent )
}

export default PagesHOC
