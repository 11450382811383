import React, { Component } from 'react'
import {
  FormGroup,
  Row, Col,
  Card, CardHeader, CardBody, CardFooter,
  UncontrolledTooltip, Button, Input, Label
 } from 'reactstrap'
 import Image from 'react-bootstrap/Image'
 import ReactSelect from 'react-select'
 import _ from 'lodash'

import PageTitle from 'components/PageTitle'
import ARKTable from 'components/Table'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import getDomainURL from 'utils/api'
import { convertObjToBase64 } from 'utils/objToBase64'
import { getItem } from 'utils/tokenStore'
import CockpitIcon from 'assets/images/admin-portal-icon.png'
import Lang from 'Lang/General'
import DomainsHOC from './actions'


class DomainsListings extends Component{
  componentDidMount = () => {
    let tmp = {
      page: 1,
      is_paginated: true
    }
    this.props.getAllDomains( convertObjToBase64( tmp ) )
    this.props.getCenters( convertObjToBase64( { is_paginated: false } ) )
  }

  render = () => {
    return(
      <>
        <PageTitle
          heading={ Lang[ 'DOMAIN_LISTING' ][ this.props.data.languageReducer.lang ] }
          icon="pe-7s-ticket icon-gradient bg-happy-fisher"
          buttons={[]} />
        <Card className="mb-3">
          <CardHeader>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }</CardHeader>
          <CardBody>
            <Row>
              <Col md={ 6 }>
                <FormGroup>
                  <Label>{ Lang[ 'DOMAIN_NAME' ][ this.props.data.languageReducer.lang ] }</Label>
                  <Input
                    type="text"
                    style={{ height: '38px' }}
                    value={ this.props.searchParams.siteDomain }
                    onChange={ e => {
                      let tmp = _.cloneDeep( this.props.searchParams )
                      tmp.siteDomain= e.target.value
                      return this.props.onChangeDomainsHOC( 'searchParams', tmp )
                    }} />
                </FormGroup>
              </Col>
              <Col md={ 6 }>
                <FormGroup>
                  <Label>{ Lang[ 'CENTER' ][ this.props.data.languageReducer.lang ] }</Label>
                  <ReactSelect
                    name="centers"
                    options={ this.props.centers }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder={ Lang[ 'SELECT_PLACEHOLDER' ][ this.props.data.languageReducer.lang ] }
                    onChange={ ( value ) => {
                      let tmp = _.cloneDeep( this.props.searchParams )
                      tmp.center = value
                      return this.props.onChangeDomainsHOC( 'searchParams', tmp )
                    }}
                    value={ this.props.searchParams.center }/>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button
              color="primary"
              className="ml-auto mr-2"
              onClick={() => {
                let tmp = {
                  page: 1,
                  is_paginated: true,
                  filter: {}
                }
                if ( this.props.searchParams.siteDomain.trim().length > 0 ) {
                  tmp.filter.site_domain = {
                    $like: `%${ 
                      this.props.searchParams.siteDomain.replace( 
                        /\//g, '' 
                      ).replace( 
                        /https/g, '' 
                      ).replace( 
                        /http/g, '' 
                      ).replace(
                        /:/g, ''
                      ).toLowerCase() 
                    }%`
                  }
                }
                if ( this.props.searchParams.center.id > 0 ) {
                  tmp.filter.center_id = this.props.searchParams.center.id
                }
                if ( _.isEmpty( tmp.filter ) ) {
                  delete tmp.filter
                }
                return this.props.getAllDomains( convertObjToBase64( tmp ) )
              }}>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }</Button>
            <Button
              color="danger"
              onClick={ () => {
                let tmp = {
                  page: 1,
                  is_paginated: true
                }
                this.props.onChangeDomainsHOC( 'searchParams', { siteDomain: '', center: {} } )
                return this.props.getAllDomains( convertObjToBase64( tmp ) )
              }}>{ Lang[ 'RESET' ][ this.props.data.languageReducer.lang ] }</Button>
          </CardFooter>
        </Card>
        <ARKTable
          data={ this.props.domains.data }
          columns={[
            {
              Header: Lang[ 'DOMAIN_NAME' ][ this.props.data.languageReducer.lang ],
              accessor: 'site_domain',
            },
            {
              Header: Lang[ 'CENTER' ][ this.props.data.languageReducer.lang ],
              accessor: 'center_name'
            },
            {
              Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
              width: 200,
              accessor: 'site_domain',
              Cell: row => (
                <>
                  <Button
                    id={ `Domains-${ row.original.id }-view-domain` }
                    className="mr-2 mb-1 btn-icon btn-icon-only"
                    color="primary"
                    onClick={ () => {
                      window.open( `http://${ row.original.site_domain }`, '_blank' )
                    }}>
                    <i className="pe-7s-look" />
                  </Button>
                  <UncontrolledTooltip
                    target={ `Domains-${ row.original.id  }-view-domain` }
                    placement="top">{ Lang[ 'VIEW_DOMAIN' ][ this.props.data.languageReducer.lang ] }
                  </UncontrolledTooltip>
                  <Button
                    id={ `Domains-${ row.original.id  }-login-ark-site` }
                    className="mr-2 mb-1 btn-icon btn-icon-only"
                    color="success"
                    onClick={ () => {
                      window.open( `https://${ row.original.site_domain }/admin/login/${ getItem( 'PROOFPOINT_ARK_MANAGEMENT' ) }`, '_blank' )
                    }}>
                    <i className="pe-7s-global" />
                  </Button>
                  <UncontrolledTooltip
                    target={ `Domains-${ row.original.id  }-login-ark-site` }
                    placement="top">{ Lang[ 'LOGIN_WEBSITE' ][ this.props.data.languageReducer.lang ] }
                  </UncontrolledTooltip>
                  <Button
                    id={ `Domains-${ row.original.id  }-login-cockpit` }
                    className="mr-2 mb-1 btn-icon btn-icon-only"
                    color="secondary"
                    onClick={ () => {
                      let tmpDomainUrl = getDomainURL()
                      let tmpCockpitUrl = 'https://'
                      if( tmpDomainUrl.indexOf( 'staging' ) > -1 ) {
                        tmpCockpitUrl += 'cockpit-staging.proof-point-suite.com'
                      } else if( tmpDomainUrl.indexOf( 'tennis' ) > -1 ) {
                        tmpCockpitUrl += 'adminportal.tennis-suite.com'
                      } else if( tmpDomainUrl.indexOf( 'aciso' ) > -1 ) {
                        tmpCockpitUrl += 'adminportal.aciso-suite.com'
                      } else if( tmpDomainUrl.indexOf( 'proof-point-suite' ) > -1 ) {
                        tmpCockpitUrl += 'adminportal.proof-point-suite.com'
                      }
                      tmpCockpitUrl += `/#/login/${ localStorage.getItem( 'PROOFPOINT_ARK_MANAGEMENT' ) }/${ row.original.center_id }`
                      window.open( tmpCockpitUrl )
                    }}>
                    <Image 
                      src={ CockpitIcon } 
                      style={{ 
                        position: "absolute",
                        top: "7px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        height: "16px",
                      }} 
                    />
                    <i className="pe-7s-global" style={{ opacity: "0" }} />
                  </Button>
                  <UncontrolledTooltip
                    target={ `Domains-${ row.original.id  }-login-cockpit` }
                    placement="top">{ Lang[ 'LOGIN_COCKPIT' ][ this.props.data.languageReducer.lang ] }
                  </UncontrolledTooltip>
                </>
              )
            }
          ]}
          page={ this.props.domains.meta ? this.props.domains.meta.current_page : 1 }
          totalPages={ this.props.totalPages }
          onChangePage={ pg => {
            let tmp = {
              page: pg,
              is_paginated: true,
              filter: {}
            }
            if ( this.props.searchParams.siteDomain.trim().length > 0 ) {
              tmp.filter.site_domain = {
                $like: `%${ 
                  this.props.searchParams.siteDomain.replace( 
                    /\//g, '' 
                  ).replace( 
                    /https/g, '' 
                  ).replace( 
                    /http/g, '' 
                  ).replace(
                    /:/g, ''
                  ).toLowerCase() 
                }%`
              }
            }
            if ( this.props.searchParams.center.id > 0 ) {
              tmp.filter.center_id = this.props.searchParams.center.id
            }
            if ( _.isEmpty( tmp.filter ) ) {
              delete tmp.filter
            }
            this.props.getAllDomains( convertObjToBase64( tmp ) )
          }}
          showPagination={ false }/>
        {
          ( this.props.data.ajaxReducer.ajaxCallProgress > 0 || this.props.onLoadDomains ) && <LoadingOverlay />
        }
      </>
    )
  }
}
export default DomainsHOC( DomainsListings )
