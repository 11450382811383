import React from 'react'
import {
  Form, FormGroup, Label, Input, Button
} from 'reactstrap'
import MultiLangInput from 'components/Input/MultiLang'
import _ from 'lodash'
import Moment from 'moment'
import { connect } from 'react-redux'

import Lang from 'Lang/General'
import LangTepe from 'Lang/TePe'

const OnetimerBillingForm = ({
  selectedBookedCampaign,
  onChangeBookedCampaignHOC,
  updateBookedCampaign,
  languageReducer
}) => {
  const onChange = ( key, value ) => {
    let tmp = _.cloneDeep( selectedBookedCampaign.booked_campaign )
    tmp[ key ] = value
    return onChangeBookedCampaignHOC( 'selectedBookedCampaign', {
      ...selectedBookedCampaign,
      booked_campaign: tmp
    })
  }

  return(
    <div className="p-3 mt-3 pb-5 border border-dark">
      <Label className="h5">{ Lang[ 'ONETIMER_BILLING' ][ languageReducer.lang ] }</Label>
      <Form>
        <FormGroup check className='my-2'>
          <Input
            type="checkbox"
            value={ false }
            checked={ selectedBookedCampaign.booked_campaign.is_aciso_advertising_account }
            onChange={ e => onChange( 'is_aciso_advertising_account', e.target.checked ) } />
          { 
            window.location.host.indexOf( 'tennis' ) > -1 
            ? window.location.host.indexOf( 'proof-point-suite' ) > -1
            ? LangTepe[ 'IS_TEPE_ADVERTISING_ACCOUNT' ][ languageReducer.lang ]
            : Lang[ 'IS_PP_ADVERTISING_ACCOUNT' ][ languageReducer.lang ]
            : Lang[ 'IS_ACISO_ADVERTISING_ACCOUNT' ][ languageReducer.lang ]
          }
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'INVOICE_NUMBER' ][ languageReducer.lang ] }</Label>
          <Input
            type="text"
            value={ selectedBookedCampaign.booked_campaign.invoice_number }
            onChange={ e => onChange( 'invoice_number', e.target.value ) } />
        </FormGroup>
        <FormGroup>
          <MultiLangInput
            label={ Lang[ 'ADDITIONAL_INFO' ][ languageReducer.lang ] }
            value={ selectedBookedCampaign.booked_campaign.additional_billing_info }
            selectedLanguage={ languageReducer.lang }
            type={ 'textarea' }
            onChange={ val => onChange( 'additional_billing_info', val ) } />
        </FormGroup>
      </Form>
      <Button 
        className="btn float-right" 
        color="primary" 
        onClick={ () => {
          let tmp =_.cloneDeep( selectedBookedCampaign.booked_campaign )
          tmp.start_date = Moment( tmp.start_date ).format()
          tmp.end_date = Moment( tmp.end_date ).format()
          updateBookedCampaign( selectedBookedCampaign.booked_campaign.id, tmp )
        }}>
        { Lang[ 'SUBMIT' ][ languageReducer.lang ] }</Button>
    </div>
  )
}
const mapStateToProps = state => ({
  languageReducer: state.languageReducer
})

export default connect( mapStateToProps )( OnetimerBillingForm )
