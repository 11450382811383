import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import Moment from 'moment'

import StatusModal from 'components/Modal/status'

import { Get, Post, outdatedToken, Put, Delete } from 'utils/axios'
import { getRefreshToken } from 'actions/token'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const OpeningHourHOC = ( WrappedComponent ) => {
  class OpeningHourWrappedComponent extends Component {
    state={
      showOpeningHourModal: false,
      showStatusModal: false,
      newOpeningHourData: {
        days: [
          {
            id: 0,
            day: 'Mon',
            timeIntervals: [
              {
                id: 0,
                from: Moment( new Date( Date.now() ) ).format(),
                to: Moment( new Date( Date.now() ) ).add( 'hours', 1 ).format()
              }
            ],
            openingDayType: 'singleInterval'
          },
          {
            id: 1,
            day: 'Tue',
            timeIntervals: [],
            openingDayType: 'closed'
          },
          {
            id: 2,
            day: 'Wed',
            timeIntervals: [],
            openingDayType: 'closed'
          },
          {
            id: 3,
            day: 'Thu',
            timeIntervals: [],
            openingDayType: 'closed'
          },
          {
            id: 4,
            day: 'Fri',
            timeIntervals: [],
            openingDayType: 'closed'
          },
          {
            id: 5,
            day: 'Sat',
            timeIntervals: [],
            openingDayType: 'closed'
          },
          {
            id: 6,
            day: 'Sun',
            timeIntervals: [],
            openingDayType: 'closed'
          }
        ],
        dateInterval: {
          from: "2020-08-07"
        },
        openingHoursType: "default"
      },
      loading: false,
      selectedOpeningHour: {}
    }

    onChangeOpeningHourHOC = ( key, value ) => this.setState({ [key]: value })

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showStatusModal: true,
      statusModalType: 'negative',
      onClickStatusModalButton: () => this.setState({ showStatusModal: false }),
      statusModalMessage: error
    })
    requestSuccess = success => this.setState({
      showStatusModal: true,
      statusModalType: 'positive',
      onClickStatusModalButton: () => this.setState({
        showStatusModal: false,
        showOpeningHourModal: false,
        errorMessage: {}
      }),
      statusModalMessage: success
    })
    ajaxError = ( error ) => {
      outdatedToken( error, this.props.history )
      this.requestError( error )
    }

    getSelectedOpeningHour = ( location_id, opening_hour_id ) => Get(
      `/api/v1/center_locations/${ location_id }/opening_hours/${ opening_hour_id }`,
      this.getSelectedOpeningHourSuccess,
      this.getSelectedOpeningHourError,
      this.load
    )
    getSelectedOpeningHourSuccess = payload => this.setState({ selectedOpeningHour: payload })
    getSelectedOpeningHourError = error => this.ajaxError( error )

    createOpeningHour = ( id, data ) => Post(
      `/api/v1/center_locations/${ id }/opening_hours`,
      data,
      this.createOpeningHourSuccess,
      this.createOpeningHourError,
      this.load
    )
    createOpeningHourSuccess = payload => {
      this.setState({ showOpeningHourModal: false })
      this.requestSuccess( Lang[ 'OPENING_HOUR_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
    }
    createOpeningHourError = error => this.requestError( error )

    updateOpeningHour = ( location_id, opening_hour_id, data ) => Put(
      `/api/v1/center_locations/${ location_id }/opening_hours/${ opening_hour_id }`,
      data,
      this.updateOpeningHourSuccess,
      this.updateOpeningHourError,
      this.load
    )
    updateOpeningHourSuccess = payload => {
      this.requestSuccess( Lang[ 'OPENING_HOUR_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showOpeningHourModal: false })
    }
    updateOpeningHourError = error => this.requestError( error )

    render = () => {
      return(
        <Fragment>
          <WrappedComponent
            { ...this.props }
            showOpeningHourModal={ this.state.showOpeningHourModal }
            onLoadOpeningHour={ this.state.loading }
            getSelectedOpeningHour={ this.getSelectedOpeningHour }
            newOpeningHourData={ this.state.newOpeningHourData }
            onChangeOpeningHourHOC={ this.onChangeOpeningHourHOC }
            createOpeningHour={ this.createOpeningHour }
            selectedOpeningHour={ this.state.selectedOpeningHour }
            updateOpeningHour={ this.updateOpeningHour }/>
          <StatusModal
            isOpen={ this.state.showStatusModal }
            type={ this.state.statusModalType }
            message={ this.state.statusModalMessage }
            onClick={ this.state.onClickStatusModalButton } />
        </Fragment>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    getRefreshToken
  } )( OpeningHourWrappedComponent )
}
export default OpeningHourHOC
