import React from 'react'
import ReactHTMLParser from "react-html-parser";
import CKEditorSection from "components/RTE/section";
import _ from 'lodash'

const Mediathek = (props) => {
  return (
    <>
      {props.selectedComponentId === props.id ? (
        <div style={{ zIndex: "2", position: "relative" }}>
          <CKEditorSection
            data={props.selectedComponent["content"]}
            onChange={(data) => {
              // patch component
              let tmpComponent = _.cloneDeep(props.selectedComponent);
              tmpComponent["content"] = {
                en: !_.isEmpty(data["en"])
                  ? data["en"]
                  : "",
                de: !_.isEmpty(data["de"])
                  ? data["de"]
                  : "",
                it: !_.isEmpty(data["it"])
                  ? data["it"]
                  : "",
                nl: !_.isEmpty(data["nl"])
                  ? data["nl"]
                  : "",
              };
              props.updateSelectedComponent(tmpComponent);
              // patch column
              let tmpColumn = _.cloneDeep( props.selectedCol )
              tmpColumn = props.FindComponentAndPatch( tmpColumn, tmpComponent )
              props.updateSelectedCol( tmpColumn )
              // patch container
              let tmpContainer = _.cloneDeep( props.dom )
              tmpContainer = props.FindComponentAndPatch( tmpContainer, tmpColumn )
              props.updateDom( tmpContainer )
            }}
            selectedLanguage={props.selectedLanguage}
          />
        </div>
      ) : (
        props.content && (
          <div>{ReactHTMLParser(props.content[props.selectedLanguage])}</div>
        )
      )}
      <div
        style={props.style}
      >
        <div style={{ width: "100%", height: 0, paddingBottom: "56.25%" }}>
          <div
            style={{
              position: "absolute",
              top: "15%",
              left: "5%",
              right: "5%",
              bottom: "15%",
              width: "90%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              zIndex: "1",
              fontSize: "7vw",
            }}
            onClick={props.onClick}
          >
            <i
              class="fa fa-play-circle"
              style={{
                color: "red",
              }}
            />
          </div>
          <img
            src={ 
              props.source 
                ? props.source 
                : `https://img.youtube.com/vi/${ props.video.source.split( '/' )[ props.video.source.split( '/' ).length - 1 ] }/hqdefault.jpg`
            }
            style={{
              position: "absolute",
              objectFit: "cover",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
            }}
            onClick={props.onClick}
          />
        </div>
      </div>
    </>
  );
};

export default Mediathek;
