import Axios from 'axios'

import {
  beginAjaxCall,
  ajaxCallError,
  ajaxCallSuccess
} from './ajax';
import { storeItem } from 'utils/tokenStore';
import { getCipherHeader } from 'utils/cipher-text'
import getDomainURL from 'utils/api';

export function login({ email, password, platform }) {
  return ( dispatch ) => {
    dispatch( beginAjaxCall( false ) );
    Axios.post(
      `${ getDomainURL() }/login`,
      {
        email,
        password,
        platform
      },
      {
        headers: {
          'Authorization': getCipherHeader(),
          'Accept-Language': ''
        }
      }
    ).then( response => {
      let AUTH_TOKEN = response.data.token;
      storeItem( 'PROOFPOINT_ARK_MANAGEMENT', AUTH_TOKEN );
      dispatch( ajaxCallSuccess( 'Login Success!' ) );
    }).catch( error => {
      if( error && error.response ) {
        if( error.response.data.Errors ) {
          let tmp = ''
          if ( typeof error.response.data.Errors === 'string' ) {
            tmp = error.response.data.Errors
          } else {
            Object.values( 
              error.response.data.Errors 
            ).map( errorMsg => {
              tmp += `${ errorMsg }. `
            })
          }
          dispatch( ajaxCallError( tmp ) )
        } else {
          dispatch( ajaxCallError( error.response.data.Error ) )
        }
      } else {
        dispatch( ajaxCallError( 'Disconnected from internet, please connect to the internet and retry again.' ) );
      }
    });
  };
}
