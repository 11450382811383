import React from 'react'
import {
  Modal, ModalHeader, ModalBody,
  Form, FormGroup,
  Label, Input,
} from 'reactstrap'
import _ from 'lodash'
import uniqid from 'uniqid'
import Moment from 'moment'
import Lang from 'Lang/General'

const AddContentModal = ({
  showUpdateContentModal,
  onChangeFormBuilderState,
  findObjectAndPatch,
  dom,
  foundObject,
  formValues,
  currentPage,
  lang
}) => {
  return (
    <Modal
      isOpen={ showUpdateContentModal }
      size={ 'xl' }
      toggle={() => {
        onChangeFormBuilderState( 'showUpdateContentModal', false )
      }}>
      <ModalHeader toggle={() => onChangeFormBuilderState( 'showUpdateContentModal', false )}>{ Lang[ 'UPDATE_CONTENT' ][ lang ] }</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <FormGroup>
            <Label>{ Lang[ 'SELECT_COMPONENT' ][ lang ] }</Label>
            <Input
              type="select"
              onChange={ e => {
                let tmp = _.cloneDeep( foundObject )
                let tmpFormValues = _.cloneDeep( formValues )
                let tmpDOM = _.cloneDeep( dom )
                tmp.children.pop()
                let id = uniqid()
                if( e.target.value === 'TextField' ) {
                  tmpFormValues.push({
                    id: id,
                    dataLabel: id,
                    value: '',
                    type: 'textField',
                    page: currentPage
                  })
                  tmp.children.push({
                    id: id,
                    type: 'TextField',
                    label: Lang[ 'TEXT_FIELD_LABEL' ],
                    dataMapIndex: _.findIndex( tmpFormValues, { id: id }),
                    fontSize: '12px',
                    style: {
                      paddingTop: '15px',
                      paddingBottom: '15px',
                      paddingRight: '15px',
                      paddingLeft: '15px',
                    },
                  })
                } else if ( e.target.value === 'TextArea' ) {
                  tmpFormValues.push({
                    id: id,
                    dataLabel: id,
                    value: '',
                    type: 'textarea',
                    page: currentPage
                  })
                  tmp.children.push({
                    id: id,
                    type: 'TextArea',
                    label: Lang[ 'TEXT_FIELD_LABEL' ],
                    dataMapIndex: _.findIndex( tmpFormValues, { id: id }),
                    fontSize: '12px',
                    rows: 8,
                    resizable: 'None',
                    style: {
                      paddingTop: '15px',
                      paddingBottom: '15px',
                      paddingRight: '15px',
                      paddingLeft: '15px',
                    },
                  })
                } else if( e.target.value === 'Button' ) {
                  tmpFormValues.push({
                    id: id,
                    dataLabel: id,
                    value: '',
                    type: 'button',
                    page: currentPage
                  })
                  tmp.children.push({
                    id: id,
                    type: 'Button',
                    onClick: () => {},
                    color: 'primary',
                    content: Lang[ 'SUBMIT_BUTTON_LABEL' ][ lang ],
                    dataMapIndex: _.findIndex( tmpFormValues, { id: id })
                  })
                } else if( e.target.value === 'Radio' ) {
                  tmpFormValues.push({
                    id: id,
                    dataLabel: id,
                    value: '',
                    type: 'radio',
                    page: currentPage
                  })
                  tmp.children.push({
                    id: id,
                    type: 'Radio',
                    label: Lang[ 'RADIO_BUTTON_LABEL' ],
                    dataMapIndex: _.findIndex( tmpFormValues, { id: id }),
                    selections: [
                      {
                        label: {
                          "en": "Selection 1",
                          "de": "Selection 1",
                          "nl": "Selection 1",
                          "it": "Selection 1",
                          "fr": "Selection 1"
                        },
                        value: 'selection-1',
                        dependentField: {}
                      },
                      {
                        label: {
                          "en": "Selection 2",
                          "de": "Selection 2",
                          "nl": "Selection 2",
                          "it": "Selection 2",
                          "fr": "Selection 2"
                        },
                        value: 'selection-2',
                        dependentField: {}
                      }
                    ],
                    style: {
                      paddingTop: '15px',
                      paddingBottom: '15px',
                      paddingRight: '15px',
                      paddingLeft: '15px',
                    },
                  })
                } else if( e.target.value === 'Text' ) {
                  tmpFormValues.push({
                    id: id,
                    dataLabel: id,
                    value: '',
                    type: 'text',
                    page: currentPage
                  })
                  tmp.children.push({
                    id: id,
                    type: 'Text',
                    label: Lang[ 'TEXT_LABEL' ],
                    dataMapIndex: _.findIndex( tmpFormValues, { id: id }),
                    fontSize: '12px',
                    style: {
                      fontWeight: '600',
                      marginBottom: 0,
                      paddingTop: '15px',
                      paddingBottom: '15px',
                      paddingRight: '15px',
                      paddingLeft: '15px',
                    },
                  })
                } else if( e.target.value === 'Checkbox' ) {
                  tmpFormValues.push({
                    id: id,
                    dataLabel: id,
                    value: [],
                    type: 'checkbox',
                    page: currentPage,
                    requiredSelections: []
                  })
                  tmp.children.push({
                    id: id,
                    type: 'Checkbox',
                    label: Lang[ 'CHECKBOX_LABEL' ],
                    dataMapIndex: _.findIndex( tmpFormValues, { id: id }),
                    selections: [
                      {
                        label: {
                          "en": "Selection 1",
                          "de": "Selection 1",
                          "nl": "Selection 1",
                          "it": "Selection 1"
                        },
                        value: 'selection-1',
                        nestedSelections: [],
                        dependentField: {}
                      }
                    ],
                    style: {
                      paddingTop: '15px',
                      paddingBottom: '15px',
                      paddingRight: '15px',
                      paddingLeft: '15px',
                    },
                  })
                } else if( e.target.value === 'InputFile' ) {
                  tmpFormValues.push({
                    id: id,
                    dataLabel: id,
                    value: [],
                    type: 'inputFile',
                    page: currentPage,
                    isRequired: true,
                    validation: Lang[ 'FIELD_IS_REQUIRED' ]
                  })
                  tmp.children.push({
                    id: id,
                    type: 'InputFile',
                    label: Lang[ 'INPUT_FILE_LABEL' ],
                    dataMapIndex: _.findIndex( tmpFormValues, { id: id }),
                    fontSize: '12px',
                    style: {
                      paddingTop: '15px',
                      paddingBottom: '15px',
                      paddingRight: '15px',
                      paddingLeft: '15px',
                    },
                  })
                } else if( e.target.value === 'Image' ) {
                  tmpFormValues.push({
                    id: id,
                    dataLabel: id,
                    value: '',
                    type: 'image',
                    page: currentPage
                  })
                  tmp.children.push({
                    id: id,
                    type: 'Image',
                    url: 'https://s3-eu-central-1.amazonaws.com/ark.staging.assets/shared_media_drive/8bb6c17838643f9691cc6a4de6c51709/original/image.png',
                    style: {
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      paddingRight: '5px',
                      paddingLeft: '5px',
                    },
                    width: '100px',
                    height: '100px',
                    enlarge_image_size: '50%'
                  })
                } else if( e.target.value === 'DateField' ) {
                  tmpFormValues.push({
                    id: id,
                    dataLabel: id,
                    value: '',
                    type: 'dateField',
                    page: currentPage
                  })
                  tmp.children.push({
                    id: id,
                    type: 'DateField',
                    label: Lang[ 'DATE_FIELD_LABEL' ],
                    dataMapIndex: _.findIndex( tmpFormValues, { id: id }),
                    fontSize: '12px',
                    style: {
                      paddingTop: '15px',
                      paddingBottom: '15px',
                      paddingRight: '15px',
                      paddingLeft: '15px',
                    },
                  })
                }
                findObjectAndPatch( tmpDOM, tmp )
                Promise.all([
                  onChangeFormBuilderState( 'formValues', tmpFormValues ),
                  onChangeFormBuilderState( 'dom', tmpDOM )
                ]).then(() => {
                  onChangeFormBuilderState( 'showUpdateContentModal', false )
                })
              }}>
              <option value={ '' }>{ '' }</option>
              <option value={ 'TextField' }>{ Lang[ 'TEXT_FIELD' ][ lang ] }</option>
              <option value={ 'TextArea' }>{ Lang[ 'TEXT_AREA' ][ lang ] }</option>
              <option value={ 'Radio' }>{ Lang[ 'RADIO' ][ lang ] }</option>
              <option value={ 'Text' }>{ Lang[ 'TEXT' ][ lang ] }</option>
              <option value={ 'Checkbox' }>{ Lang[ 'CHECKBOX' ][ lang ] }</option>
              <option value={ 'InputFile' }>{ Lang[ 'INPUT_FILE' ][ lang ] }</option>
              <option value={ 'Image' }>{ Lang[ 'IMAGE' ][ lang ] }</option>
              <option value={ 'DateField' }>{ Lang[ 'DATE_FIELD' ][ lang ] }</option>
            </Input>
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default AddContentModal
