import React from "react"
import Radium from 'proofpoint-radium'
import ReactHTMLParser from 'react-html-parser'

import CoreRenderFunction from '../../core/render'

const Heading = (props) => {
  let {
    id,
    item,
    style,
    children,
    languages,
    className,
    selectedLanguageShortname ,
    mode,
    selectedEditableComponent,
    onChangeSectionEditorState,
    hoveredComponentId,
    selectedContainer
  } = props
  return (
    <>
      <div
        id={ id }
        style={{
          ...style,
          outline: (
            ( selectedEditableComponent && props.mode === "edit" && selectedEditableComponent.id === id ) ||
            ( mode === "edit" && hoveredComponentId === id )
          ) ? "5px solid #0275d8"
            : "none"
        }}
        onMouseEnter={() =>
          mode === "edit" &&
          onChangeSectionEditorState &&
          onChangeSectionEditorState( "hoveredComponentId", id )
        }
        onMouseLeave={() =>
          mode === "edit" &&
          onChangeSectionEditorState &&
          onChangeSectionEditorState( "hoveredComponentId", "" )
        }
        onClick={() => {
          if ( mode === "edit" ) {
            if ( onChangeSectionEditorState ) {
              onChangeSectionEditorState( "selectedEditableComponent", item )
              onChangeSectionEditorState( "selectedContainer", item )
            }
          }
        }}
        className={ className }
      >
        { children.map( child => {
          if ( child.type && child.type === "heading-content" ) {
            return (
              <div
                id={ child.id }
                style={{
                  ...child.style,
                  outline: (
                    ( selectedEditableComponent && props.mode === "edit" && selectedEditableComponent.id === child.id ) ||
                    ( mode === "edit" && hoveredComponentId === child.id )
                  ) ? "5px solid #0275d8"
                    : "none",
                  whiteSpace: "pre-wrap"
                }}
              >
                <style>{ `#${ child.id } p { margin: 0px }` }</style>
                {
                  child.generalH1
                    ? (
                      <>
                        <style>{ `#${ child.id } h1:after {content:none;} #${ child.id } h1:before {content:none;}` }</style>
                        <h1 style={{ padding:0, margin:0, color:"unset" }}>
                          { ReactHTMLParser( child.content[ selectedLanguageShortname ] ) }
                        </h1>
                      </>
                    ) : ReactHTMLParser( child.content[ selectedLanguageShortname ] )
                }
              </div>
            )
          } else {
            return (
              <CoreRenderFunction
                item={ child }
                languages={ languages }
                selectedLanguageShortname ={ selectedLanguageShortname }
                mode={ mode }
                onChangeSectionEditorState={ onChangeSectionEditorState }
                selectedEditableComponent={ selectedEditableComponent }
                hoveredComponentId={ hoveredComponentId }
                selectedContainer={ selectedContainer }
              />
            )
          }
        })}
      </div>
    </>
  )
}

export default Radium(Heading)
