import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get, Post, Put, Delete } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

const NewsletterHOC = ( WrappedComponent ) => {
  class NewsletterWrappedComponent extends Component {
    state = {
      showCreateNewsletterTemplate: false,
      showUpdateNewsletterTemplate: false,
      newsletterTemplates: [],
      selectedNewsletterTemplate: {
        name: '',
        json_content: '',
        html_content: '',
        center_groups: [],
        countries: [],
        image: {
          url: ''
        }
      },
      loading: false,
      newNewsletterTemplate: {
        name: '',
        json_content: '',
        html_content: '',
        center_groups: [],
        countries: []
      },
      errorMessage: {},
      templateViewModal: false,
      templateDeleteModal: false,
      templateTotalPages: [],
      page: 1,
      name: ''
    }

    onChangeNewsletterHOC = ( key, value ) => this.setState({ [key]: value }, () => {
      if( key === 'errorMessage' ){
        this.setState({
          selectedNewsletterTemplate: {
            name: '',
            json_content: '',
            html_content: '',
            countries: [],
            center_groups: [],
            image: {
              url: ''
            }
          },
          newNewsletterTemplate: {
            name: '',
            json_content: '',
            html_content: '',
            countries: [],
            center_groups: []
          }
        })
      }
    })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })
    requestError = error => typeof error === 'string' 
    ? toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    }) : Object.values( error ).map( item => toast.error( item.replaceAll( '_', ' ' ) ), {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    } )

    getNewsletterTemplates = ( query ) => Get(
      `/api/v1/newsletter_templates?query=${ query }`,
      this.getNewsletterTemplatesSuccess,
      this.getNewsletterTemplatesError,
      this.load
    )
    getNewsletterTemplatesSuccess = payload => {
      let tmpTotalPages = []
      for( let i = 1; i <= payload.meta.last_page; i++ ) {
        tmpTotalPages.push( i )
      }
      this.setState({ newsletterTemplates: payload, templateTotalPages: tmpTotalPages })
    }
    getNewsletterTemplatesError = error => {
      this.requestError( error )
      this.setState({ errorMessage: error })
    }

    getSelectedNewsletterTemplate = id => Get(
      `/api/v1/newsletter_templates/${ id }`,
      this.getSelectedNewsletterTemplateSuccess,
      this.getSelectedNewsletterTemplateError,
      this.load
    )
    getSelectedNewsletterTemplateSuccess = payload => {
      let tempCenterGroups = _.map( payload.center_groups, item => ({
        ... item,
        value: item.name
      }))
      this.setState({ 
        selectedNewsletterTemplate: {
          ... payload,
          center_groups: tempCenterGroups 
        }
      })
    }
    getSelectedNewsletterTemplateError = error => {
      this.requestError( error )
      this.setState({ errorMessage: error })
    }

    createNewsletterTemplate = data => {
      let tempData = _.cloneDeep( data )
      data.countries.length < 1 && delete tempData.countries
      data.center_groups.length < 1 && delete tempData.center_groups

      Post(
        `/api/v1/newsletter_templates`,
        tempData,
        this.createNewsletterTemplateSuccess,
        this.createNewsletterTemplateError,
        this.load
      )
    }
    createNewsletterTemplateSuccess = payload => {
      let tmp = {
        page: this.state.page,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'TEMPLATE_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showCreateNewsletterTemplate: false })
      this.getNewsletterTemplates( convertObjToBase64( tmp ) )
    }
    createNewsletterTemplateError = error => {
      this.requestError( error )
      this.setState({ 
        errorMessage: {
          ... error,
          center_groups: error.center_groups.replaceAll( '_', ' ' )
        }
      })
    }

    updateNewsletterTemplate = data => {
      let tempData = _.cloneDeep( data )

      data.countries.length < 1 && delete tempData.countries
      data.center_groups.length < 1 && delete tempData.center_groups

      Put(
        `/api/v1/newsletter_templates/${ data.id }`,
        tempData,
        this.updateNewsletterTemplateSuccess,
        this.updateNewsletterTemplateError,
        this.load
      )
    }
    updateNewsletterTemplateSuccess = payload => {
      let tmp = {
        page: this.state.page,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'TEMPLATE_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showUpdateNewsletterTemplate: false })
      this.getNewsletterTemplates( convertObjToBase64( tmp ) )
    }
    updateNewsletterTemplateError = error => {
      this.requestError( error )
      this.setState({ 
        errorMessage: {
          ... error,
          center_groups: error.center_groups.replaceAll( '_', ' ' )
        }
      })
    }

    removeNewsletterTemplate = id => Delete(
      `/api/v1/newsletter_templates/${ id }`,
      this.removeNewsletterTemplateSuccess,
      this.removeNewsletterTemplateError,
      this.load
    )
    removeNewsletterTemplateSuccess = () => {
      let tmp = {
        page: this.state.page,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'TEMPLATE_REMOVE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getNewsletterTemplates( convertObjToBase64( tmp ) )
    }
    removeNewsletterTemplateError = error => {
      this.requestError( error )
      this.setState({ errorMessage: error })
    }

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            showCreateNewsletterTemplate={ this.state.showCreateNewsletterTemplate }
            newNewsletterTemplate={ this.state.newNewsletterTemplate }
            onChangeNewsletterHOC={ this.onChangeNewsletterHOC }
            newsletterTemplates={ this.state.newsletterTemplates }
            getNewsletterTemplates={ this.getNewsletterTemplates }
            getSelectedNewsletterTemplate={ this.getSelectedNewsletterTemplate }
            errorMessage={ this.state.errorMessage }
            selectedNewsletterTemplate={ this.state.selectedNewsletterTemplate }
            showUpdateNewsletterTemplate={ this.state.showUpdateNewsletterTemplate }
            templateViewModal={ this.state.templateViewModal }
            createNewsletterTemplate={ this.createNewsletterTemplate }
            updateNewsletterTemplate={ this.updateNewsletterTemplate }
            onLoadTemplate={ this.state.loading }
            removeNewsletterTemplate={ this.removeNewsletterTemplate }
            templateDeleteModal={ this.state.templateDeleteModal }
            templateTotalPages={ this.state.templateTotalPages }
            name={ this.state.name } />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( NewsletterWrappedComponent )
}
export default NewsletterHOC
