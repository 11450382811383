import React, { useState } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  Col,
  Row
} from 'reactstrap'
import _ from 'lodash'
import { connect } from 'react-redux'

import MultiLangInput from 'components/Input/MultiLang'
import MultiLangRTE from 'components/RTE/MultiLang'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import MediaDatabase from 'SharedModules/MediaDatabase'
import { toggleMediaDatabase } from 'actions/MediaDatabaseToggle'

import Lang from 'Lang/General'

const Update = props => {
  const [ countryId, updateCountryId ] = useState( 0 )
  const [ centerGroupId, updateCenterGroupId ] = useState( 0 )

  const onChangeProductsData = ( key, val ) => {
    let tmp = _.cloneDeep( props.selectedProduct )
    tmp[ key ] = val
    return props.onChangeProductsHOC( 'selectedProduct', tmp )
  }

  return (
    <Modal
      isOpen={ props.showUpdateProduct }
      toggle={() => {
        if( props.location.state && props.location.state.showUpdateProduct ) {
          props.history.push( '/dashboard/product-request' )
        }
        props.onChangeProductsHOC( 'showUpdateProduct', false )
      }}
      size={ 'lg' }>
      <ModalHeader toggle={() => {
        if( props.location.state && props.location.state.showUpdateProduct ) {
          props.history.push( '/dashboard/product-request' )
        }
        props.onChangeProductsHOC( 'showUpdateProduct', false )
      }}>{ Lang[ 'UPDATE_PRODUCT' ][ props.data.languageReducer.lang ] }</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <FormGroup>
            <MultiLangInput
              label={ Lang[ 'NAME' ][ props.data.languageReducer.lang ] }
              selectedLanguage={ props.data.languageReducer.lang }
              value={ props.selectedProduct.name }
              onChange={ val => {
                onChangeProductsData( 'name', val )
              }}/>
          </FormGroup>
          <FormGroup>
            <MultiLangRTE
              label={ Lang[ 'DESCRIPTION' ][ props.data.languageReducer.lang ] }
              selectedLanguage={ props.data.languageReducer.lang }
              value={ props.selectedProduct.description }
              onChange={ val => {
                onChangeProductsData( 'description', val )
              }} />
          </FormGroup>
          <FormGroup>
            <Label>{ Lang[ 'UPLOAD_IMAGE' ][ props.data.languageReducer.lang ] }</Label>
            <Input
              type="text"
              value={ props.selectedProduct.image_url }
              onChange={ e => {
                onChangeProductsData( 'image_url', e.target.value )
              }}
              disabled={ true } />
            <Button
              color="primary"
              className="mt-2"
              onClick={() => {
                props.toggleMediaDatabase( true )
                props.onChangeProductsHOC( 'imageKey', 'image' )
              }}>
              { Lang[ 'SELECT_IMAGE' ][ props.data.languageReducer.lang ] }</Button>
            <div className="mt-2">
              {
                props.selectedProduct.image_url !== '' && (
                  <img src={ props.selectedProduct.image_url } style={{ width: '30%' }} />
                )
              }
            </div>
          </FormGroup>
          <FormGroup>
            {
              props.selectedProduct.pdf_url !== '' && (
                <p><a href={ props.selectedProduct.pdf_url } target="_blank" className="mb-2">Click to view pdf</a></p>
              )
            }
            <Label>{ Lang[ 'UPLOAD_PDF' ][ props.data.languageReducer.lang ] }</Label>
            <Input
              type="text"
              value={ props.selectedProduct.pdf_url }
              onChange={ e => {
                onChangeProductsData( 'pdf_url', e.target.value )
              }}
              disabled={ true } />
            <Button
              color="primary"
              className="mt-2"
              onClick={() => {
                props.toggleMediaDatabase( true )
                props.onChangeProductsHOC( 'imageKey', 'pdf' )
              }}>
            { Lang[ 'SELECT_IMAGE' ][ props.data.languageReducer.lang ] }</Button>
          </FormGroup>
          <FormGroup>
            <Label>{ Lang[ 'REDIRECT_URL' ][ props.data.languageReducer.lang ] }</Label>
            <Input
              type="text"
              value={ props.selectedProduct.pdf_url }
              onChange={ e => {
                onChangeProductsData( 'pdf_url', e.target.value )
              }} />
          </FormGroup>
          <div className="d-flex align-items-center mb-3">
            <input
              type="checkbox"
              name="is_new" value=""
              className="mr-1"
              checked={ props.selectedProduct.is_new }
              onChange={ e => {
                onChangeProductsData( 'is_new', e.target.checked )
              }} />
            <label style={{ marginBottom: 0 }}>{ Lang[ 'IS_NEW' ][ props.data.languageReducer.lang ] }</label>
          </div>
          <FormGroup>
            <MultiLangInput
              label={ Lang[ 'PRICE_LINE_1' ][ props.data.languageReducer.lang ] }
              selectedLanguage={ props.data.languageReducer.lang }
              value={ props.selectedProduct.currency_price_1 }
              onChange={ val => {
                onChangeProductsData( 'currency_price_1', val )
              }}/>
          </FormGroup>
          <FormGroup>
            <MultiLangInput
              label={ Lang[ 'PRICE_LINE_2' ][ props.data.languageReducer.lang ] }
              selectedLanguage={ props.data.languageReducer.lang }
              value={ props.selectedProduct.currency_price_2 }
              onChange={ val => {
                onChangeProductsData( 'currency_price_2', val )
              }}/>
          </FormGroup>
          <FormGroup>
            <div className="d-flex align-items-center mb-3">
              <input
                type="checkbox"
                name="is_new" value=""
                className="mr-1"
                checked={ props.selectedProduct.is_most_booked_products }
                onChange={ e => {
                  onChangeProductsData( 'is_most_booked_products', e.target.checked )
                }} />
              <label style={{ marginBottom: 0 }}>{ Lang[ 'IS_MOST_BOOKED_PRODUCTS' ][ props.data.languageReducer.lang ] }</label>
            </div>
          </FormGroup>
          <div className="row">
            <div className="col-md-6">
              <FormGroup>
                <Label>{ Lang[ 'SELECT_COUNTRIES' ][ props.data.languageReducer.lang ] }</Label>
                <Input
                  type="select"
                  value={ countryId }
                  onChange={ e => {
                    updateCountryId( parseInt( e.target.value, 10 ) )
                  }}>
                  <option></option>
                  {
                    props.countries.map( item => {
                      let tmpIndex = _.findIndex( props.selectedProduct.countries, { id: item.id })
                      if( tmpIndex === -1 ) {
                        return (
                          <option key={ item.id } value={ item.id }>{ item.name }</option>
                        )
                      }
                    })
                  }
                </Input>
                <Button
                  color="primary"
                  onClick={() => {
                    if( countryId > 0 ) {
                      let tmpCountries = _.cloneDeep( props.selectedProduct.countries )
                      let tmp = _.find( props.countries, { id: countryId })
                      tmpCountries.push( tmp )
                      onChangeProductsData( 'countries', tmpCountries )
                    }
                  }}>{ Lang[ 'ADD' ][ props.data.languageReducer.lang ] }</Button>
              </FormGroup>
              <FormGroup>
                {
                  props.selectedProduct.countries.map( item => {
                    return (
                      <Card key={ item.id }>
                        <CardBody>
                          <Row>
                            <Col md={7}>
                              { item.name }
                            </Col>
                            <Col md={5}>
                              <Button
                                color="danger"
                                onClick={() => {
                                  let tmpCountries = _.cloneDeep( props.selectedProduct.countries )
                                  _.remove( tmpCountries, { id: item.id })
                                  onChangeProductsData( 'countries', tmpCountries )
                                }}
                                className="ml-3">{ Lang[ 'DELETE' ][ props.data.languageReducer.lang ] }</Button>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    )
                  })
                }
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup>
                <Label>{ Lang[ 'SELECT_CENTER_GROUPS' ][ props.data.languageReducer.lang ] }</Label>
                <Input
                  type="select"
                  value={ centerGroupId }
                  onChange={ e => {
                    updateCenterGroupId( parseInt( e.target.value, 10 ) )
                  }}>
                  <option></option>
                  {
                    props.centerGroupForSelect.map( item => {
                      let tmpIndex = props.selectedProduct.center_group_ids.indexOf( item.id )
                      if( tmpIndex === -1 ) {
                        return (
                          <option key={ item.id } value={ item.id }>{ item.text }</option>
                        )
                      }
                    })
                  }
                </Input>
                <Button
                  color="primary"
                  onClick={() => {
                    if( centerGroupId > 0 ) {
                      let tmpCenterGroups = _.cloneDeep( props.selectedProduct.center_group_ids )
                      let tmp = _.find( props.centerGroupForSelect, { id: centerGroupId })
                      tmpCenterGroups.push( tmp.id )
                      onChangeProductsData( 'center_group_ids', tmpCenterGroups )
                    }
                  }}>{ Lang[ 'ADD' ][ props.data.languageReducer.lang ] }</Button>
              </FormGroup>
              <FormGroup>
                {
                  props.selectedProduct.center_group_ids.map( item => {
                    let tmp = _.find( props.centerGroupForSelect, { id: item })
                    if( tmp ) {
                      return (
                        <Card key={ item }>
                          <CardBody>
                            <Row>
                              <Col md={7}>
                                { tmp.text }
                              </Col>
                              <Col md={5}>
                                <Button
                                  color="danger"
                                  onClick={() => {
                                    let tmpCenterGroups = _.cloneDeep( props.selectedProduct.center_group_ids )
                                    _.remove( tmpCenterGroups, val => val === item )
                                    onChangeProductsData( 'center_group_ids', tmpCenterGroups )
                                  }}
                                  className="ml-3">{ Lang[ 'DELETE' ][ props.data.languageReducer.lang ] }</Button>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      )
                    }
                  })
                }
              </FormGroup>
            </div>
          </div>
        </Form>
        <MediaDatabase
          onSelectedImage={( param ) => {
            if( props.imageKey === 'image' ) {
              onChangeProductsData( 'image_url', param )
            } else if( props.imageKey === 'pdf' ) {
              onChangeProductsData( 'pdf_url', param )
            }
            props.toggleMediaDatabase( false )
          }} />
        { props.onLoadProducts && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            props.updateProduct( props.selectedProduct )
          }}>{ Lang[ 'SUBMIT' ][ props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )
}
const mapStateToProps = state => ({ data: state })

export default connect( mapStateToProps, {
  toggleMediaDatabase
})( Update )
