import React, { Fragment, useState, useEffect } from 'react'
import _ from 'lodash'
import {
  Form, FormGroup, Label, Input, Col, FormFeedback, Container, Card, CardBody,
  Modal, ModalBody, ModalHeader, ModalFooter, Button,
} from 'reactstrap'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { connect } from 'react-redux'
import Moment from 'moment'
import classnames from 'classnames'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'

const UpdateScenarioForm = ( props ) => {
  const langButton = [ 'en', 'de', 'es', 'it', 'fr', 'nl' ]
  const [ selectedLanguageButton, updateSelectedLanguage ] = useState( 'en' )

  useEffect( () => {
    props.onChangeScenarioHOC( 'scenariosErrMsg', {} )
  }, [props.showUpdateForm] )

  return(
    <Modal
      isOpen={ props.showUpdateForm }
      size="lg">
      <ModalHeader toggle={ () => props.onChangeScenarioHOC('showUpdateForm', false ) }>{ Lang['UPDATE_SCENARIO'][ props.selectedLanguage ] }
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>{ Lang['NAME'][ props.selectedLanguage ]}<span style={{ color: 'red'}}>*</span></Label>
            <div className="d-flex pt-1">
              {
                langButton.map( button => {
                  return(
                    <Button
                      className="btn-square btn-wide mr-0" size="sm"
                      color={ `${ selectedLanguageButton === button ? 'primary' : 'light' }` }
                      onClick={ () => updateSelectedLanguage( button ) }>{ button }</Button>
                  )
                })
              }
            </div>
            <Input
              className="mt-2"
              value={ props.selectedScenario.name[selectedLanguageButton] }
              onChange={ e => {
                let tmp = _.cloneDeep( props.selectedScenario )
                tmp['name'][ selectedLanguageButton ] = e.target.value
                props.onChangeScenarioHOC( 'selectedScenario', tmp )
                }}/>
            {
              props.scenariosErrMsg.name && (
                <span style={{ color: 'red', marginBottom: '15px' }}>{ props.scenariosErrMsg.name }</span>
              )
            }
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          style={{ float: 'right' }}
          onClick={ () => {
            props.updateScenario( props.selectedScenario.id, props.selectedScenario )
          }}>{ Lang['UPDATE'][ props.selectedLanguage ] }
        </Button>
      </ModalFooter>
    </Modal>

  )
}
const mapStateToProps = state => ({ dataReducer: state })

export default connect( mapStateToProps )( UpdateScenarioForm )
