import React, { Component } from 'react'
import Radium, { StyleRoot } from 'proofpoint-radium'
import _ from 'lodash'
import Frame from 'react-frame-component'
import { Button } from 'reactstrap'
import { Helmet } from 'react-helmet'
import { MdDesktopWindows, MdTablet, MdSmartphone } from 'react-icons/md'

import CoreRenderFunction from '../core/render'

class RendererView extends Component {
  state = { width: '100%' }

  render = () => {
    let {
      templateStylesheets,
      templateJavascripts,
      selectedSection,
      languages,
      selectedLanguageShortname,
      onUpdateCarouselPage,
      carouselPage,
      selectedContainer,
      selectedEditableComponent,
      onChangeSectionEditorState,
      onStopDragComponent,
      hoveredComponentId
    } = this.props

    return (
      <>
        <div className="d-flex align-items-center">
          <Button 
            color={ this.state.width === '100%' ? 'primary' : 'light' }
            style={{ marginLeft: 'auto' }}
            onClick={() => this.setState({ width: '100%' })}>
            <MdDesktopWindows style={{ fontSize: '24px' }} />
          </Button>
          <Button 
            color={ this.state.width === '768px' ? 'primary' : 'light' }
            onClick={() => this.setState({ width: '768px' })}>
            <MdTablet style={{ fontSize: '24px' }} />
          </Button>
          <Button 
            color={ this.state.width === '450px' ? 'primary' : 'light' }
            onClick={() => this.setState({ width: '450px' })}>
            <MdSmartphone style={{ fontSize: '24px' }} />
          </Button>
        </div>
        <Frame
          id={ 'editor-section-renderer' }
          style={{
            width: this.state.width,
            height: '70vh'
          }}
          initialContent={ '<!DOCTYPE html><html><head><link href="/bootstrap.css" rel="stylesheet"></link><link href="/image-block-styles.css" rel="stylesheet"></link></head><body style="overflow-y:scroll;overflow-x:hidden;"><div></div></body></html>' }>
          <StyleRoot>
            <div
              style={{
                width: '100%',
                height: '100%'
              }}>
              {
                templateStylesheets && templateStylesheets.length > 0 && templateStylesheets.map( item => {
                  if( item.type === 'URL' ) {
                    return <link key={ `stylesheet-${ item.id }` } rel="stylesheet" href={ item.content }></link>
                  } else {
                    return <style key={ `stylesheet-${ item.id }` }>{ item.content }</style>
                  }
                })
              }
              <CoreRenderFunction
                item={ selectedSection }
                languages={ languages }
                selectedLanguageShortname={ selectedLanguageShortname }
                mode={ 'edit' }
                onUpdateCarouselPage={ onUpdateCarouselPage }
                carouselPage={ carouselPage }
                selectedContainer={ selectedContainer }
                onChangeSectionEditorState={ onChangeSectionEditorState }
                selectedEditableComponent={ selectedEditableComponent }
                onStopDragComponent={ onStopDragComponent }
                hoveredComponentId={ hoveredComponentId } />
              {
                templateJavascripts && templateJavascripts.length > 0 && templateJavascripts.map( item => {
                  if( item.type === 'URL' ) {
                    return <script key={ `template-js-${ item.id }` } src={ item.content }></script>
                  } else {
                    return <Helmet key={ `template-js-${ item.id }` }><script>{ item.content }</script></Helmet>
                  }
                })
              }
            </div>
          </StyleRoot>
        </Frame>
      </>
    )
  }
}

export default Radium( RendererView )