import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import StatusModal from 'components/Modal/status'

import { Get, Post, outdatedToken, Put, Delete } from 'utils/axios'
import { getRefreshToken } from 'actions/token'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const ModuleHOC = ( WrappedComponent ) => {
  class ModuleWrappedComponent extends Component {
    state = {
      modules: {},
      archivedModules: {},
      selectedModule: {
        name: '',
        type: '',
        description: ''
      },
      newModuleData: {
        name: '',
        type: 'SYSTEM',
        description: ''
      },
      showNewModuleModal: false,
      showViewModuleModal: false,
      showEditModuleModal: false,
      showArchivedModuleModal: false,
      showDeleteConfirmation: false,
      loading: false,
      showStatusModal: false,
      statusModalType: '',
      onClickStatusModalButton: () => {},
      statusModalMessage: '',
      errorMessage: {},
      moduleTotalPages: [],
      archivedModuleTotalPages: [],
      toArchiveModuleId: 0,
      confirmUnarchiveModal: false,
      unarchiveModuleID: 0
    }

    onChangeModuleHOC = (key, value) => this.setState({ [key]: value })

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showStatusModal: true,
      statusModalType: 'negative',
      onClickStatusModalButton: () => this.setState({ showStatusModal: false }),
      statusModalMessage: error
    })
    requestSuccess = success => this.setState({
      showStatusModal: true,
      statusModalType: 'positive',
      onClickStatusModalButton: () => this.setState({
        showStatusModal: false,
        showNewModuleModal: false,
        newModuleData: {
          module_name: '',
          type: 'SYSTEM',
          description: ''
        },
        errorMessage: {}
      }),
      statusModalMessage: success
    })

    ajaxError = ( error ) => {
      outdatedToken( error, this.props.history )
      this.requestError( error )
    }

    getModules = query => {
      Get(
        `/api/v1/modules?query=${ convertObjToBase64( query ) }`,
        this.getModulesSuccess,
        this.getModulesError,
        this.load
      )
    }
    getModulesSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
          for( let i = 1; i <= payload.meta.last_page; i++ ) {
            tmpTotalPages.push( i )
          }
      }
      this.setState({ modules: payload, moduleTotalPages: tmpTotalPages })
    }
    getModulesError = error => this.ajaxError( error )

    getSelectedModuleView = id => this.getSelectedModule( id, 'view' )
    getSelectedModuleEdit = id => this.getSelectedModule( id, 'edit' )

    getSelectedModule = (id, type) =>{
      type === 'view'
        ? this.onChangeModuleHOC( 'showViewModuleModal', true )
        : this.onChangeModuleHOC( 'showEditModuleModal', true )
      Get(
        `/api/v1/modules/${ id }`,
        ( payload ) => this.getSelectedModuleSuccess( payload, type ),
        this.getSelectedModuleError,
        this.load
      )
    }
    getSelectedModuleSuccess = (payload, type) => this.setState({ selectedModule: payload }, () => {
      type === 'view'
        ? this.onChangeModuleHOC( 'showViewModuleModal', true )
        : this.onChangeModuleHOC( 'showEditModuleModal', true )
    })
    getSelectedModuleError = error => this.ajaxError( error )

    postModules = () => Post(
      `/api/v1/modules`,
      this.state.newModuleData,
      this.postModulesSuccess,
      this.postModulesError,
      this.load
    )
    postModulesSuccess = payload => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'MODULE_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getModules( tmp )
    }
    postModulesError = error => {
      this.setState({ errorMessage: error })
    }

    updateModule = id => Put(
      `/api/v1/modules/${ id }`,
      this.state.selectedModule,
      this.updateModuleSuccess,
      this.updateModuleError,
      this.load
    )
    updateModuleSuccess = payload => {
      let tmp = {
        page: 1,
        is_paginated: true,
        filter: {
          $or: [
            { status: 'ACTIVE' }
          ]
        }
      }
      this.requestSuccess( Lang[ 'MODULE_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.onChangeModuleHOC( 'showEditModuleModal', false )
      this.getModules( tmp )
    }
    updateModuleError = error => this.requestError( error )

    updateModuleStatus = ( id, status ) => Put(
      `/api/v1/modules/${ id }`,
      { status },
      this.updateModuleStatusSuccess,
      this.updateModuleStatusError,
      this.load
    )
    updateModuleStatusSuccess = payload => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'MODULE_STATUS_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getModules( tmp )
      this.getArchivedModule( tmp )
    }
    updateModuleStatusError = error => this.requestError( error )

    deleteModule = id => {}/* Delete(
      `/api/v1/modules/${ id }`,
      this.deleteModuleSuccess,
      this.deleteModuleError,
      this.load
    )*/
    deleteModuleSuccess = payload => {
      this.requestSuccess( Lang[ 'MODULE_REMOVE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.onChangeModuleHOC( 'showDeleteConfirmation', false )
      this.getModules()
    }
    deleteModuleError = error => this.requestError( error )

    getArchivedModule = ( query, type, searchParams, searchVal ) => {
      query = {
        ...query,
        filter: {
          status: 'ARCHIVED'
        }
      }
      if( type === 'Normal Search' ){
        query = {
          ...query,
          filter: {
            name: {
            $like: `%${ searchVal.toLowerCase() }%`
            },
            status: 'ARCHIVED'
          }
        }
      }
      if( type === 'Advanced Search' ){
        let tmp = {}
        searchParams.map( item => {
          tmp[ item.val ] = {
            $like: `%${item.searchValue.toLowerCase()}%`,
          }
          item.val === 'type' && (
            tmp[ item.val ] = item.searchValue
          )
        })
        query = {
          ...query,
          filter: {
            ...tmp,
            status: 'ARCHIVED'
          }
        }
      }
      Get(
        `/api/v1/modules?query=${ convertObjToBase64( query ) }`,
        this.getArchivedModulesSuccess,
        this.getArchivedModulesError,
        this.load
      )
    }
    getArchivedModulesSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data.length > 0 ){
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }else{
        this.ajaxError( Lang[ 'NO_ARCHIVED_CENTERS' ][ this.props.data.languageReducer.lang ] )
      }
      this.setState({ archivedModules: payload, archivedModuleTotalPages: tmpTotalPages })
    }
    getArchivedModulesError = error => this.ajaxError( error )

    getModulesSearch = ( query, type, searchParams, searchVal ) => {

      if( type === 'Normal Search' ){
        query = {
          ...query,
          filter: {
            name: {
              $like: `%${ searchVal.toLowerCase() }%`
            },
            $or: [
              { status: 'ACTIVE' },
              { status: 'INACTIVE' }
            ]
          }
        }
      }
      if( type === 'Advanced Search' ){
        let tmp = {}
        searchParams.map( item => {
          if( item.val !== 'status' && item.val !== 'type'){
            tmp[ item.val ] = {
              $like: `%${item.searchValue.toLowerCase()}%`,
            }
          }
          item.val === 'status' && (
            tmp[ item.val ] = item.searchValue
          )
          item.val === 'type' && (
            tmp[ item.val ] = item.searchValue
          )
        })
        query = {
          ...query,
          filter: {
            ...tmp,
            $or: [
              { status: 'ACTIVE' },
              { status: 'INACTIVE' }
            ]
          }
        }
      }
      Get(
        `/api/v1/modules?query=${ convertObjToBase64( query ) }`,
        this.getModulesSearchSuccess,
        this.getModulesSearchError,
        this.load
      )
    }
    getModulesSearchSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data.length > 0 ){
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }else{
        this.ajaxError( Lang[ 'NO_RESULT_FOUND' ][ this.props.data.languageReducer.lang ] )
      }
      this.setState({ modules: payload, moduleTotalPages: tmpTotalPages })
    }
    getModulesSearchError = error => this.ajaxError( error )

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            { ...this.props }
            modules={ this.state.modules }
            archivedModules={ this.state.archivedModules }
            newModuleData={ this.state.newModuleData }
            selectedModule={ this.state.selectedModule }
            getModules={ this.getModules }
            getSelectedModuleView={ this.getSelectedModuleView }
            getSelectedModuleEdit={ this.getSelectedModuleEdit }
            onLoadModules={ this.state.loading }
            postModules={ this.postModules }
            updateModule={ this.updateModule }
            deleteModule={ this.deleteModule }
            showNewModuleModal={ this.state.showNewModuleModal }
            showViewModuleModal={ this.state.showViewModuleModal }
            showEditModuleModal={ this.state.showEditModuleModal }
            showDeleteConfirmation={ this.state.showDeleteConfirmation }
            showArchivedModuleModal={ this.state.showArchivedModuleModal }
            getArchivedModule={ this.getArchivedModule }
            getSelectedModuleArchived={ this.getSelectedModuleArchived }
            updateModuleStatus={ this.updateModuleStatus }
            errorMessage={ this.state.errorMessage }
            getModulesSearch={ this.getModulesSearch }
            onChangeModuleHOC={ this.onChangeModuleHOC }
            moduleTotalPages={ this.state.moduleTotalPages }
            archivedModuleTotalPages={ this.state.archivedModuleTotalPages }
            toArchiveModuleId={ this.state.toArchiveModuleId }
            confirmUnarchiveModal={ this.state.confirmUnarchiveModal }
            unarchiveModuleID={ this.state.unarchiveModuleID }/>
          <StatusModal
            isOpen={ this.state.showStatusModal }
            type={ this.state.statusModalType }
            message={ this.state.statusModalMessage }
            onClick={ this.state.onClickStatusModalButton } />
        </Fragment>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    getRefreshToken
  })( ModuleWrappedComponent )
}

export default ModuleHOC
