import React from 'react'
import { 
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter, 
  Form, 
  FormGroup, 
  Label, 
  Input, 
  InputGroup, 
  InputGroupAddon,
  InputGroupText,
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col
} from 'reactstrap'
import _ from 'lodash'
import { SketchPicker } from 'react-color'
import Lang from 'Lang/General'
import MultiLangInput from 'components/Input/MultiLang'

// import Tabs from 'react-responsive-tabs'

// const TabsConfig = {
//   activeTab: '1',
//   showMore: true,
//   transform: true,
//   showInkBar: true,
//   items: [
//     { title: 'Basic' }
//   ],
//   selectedTabKey: 0,
//   transformWidth: 400,
// }

const checkHexColor = (hexcolor) => {
  hexcolor = hexcolor.slice(1, hexcolor.length)
  if (hexcolor.length === 3) {
    hexcolor = hexcolor.split('').map((hex) => { return hex + hex }).join('')
  }
  return "#" + hexcolor
}

const FormFrameEditor = ({
  showUpdateFormFrame,
  selectedComponentToEdit,
  onChangeFormBuilderState,
  findObjectAndPatch,
  dom,
  lang
}) => {
  return (
    <Modal
      isOpen={ showUpdateFormFrame }
      size={ 'xl' } >
      <ModalHeader toggle={() => onChangeFormBuilderState( 'showUpdateFormFrame', false )}>{ Lang[ 'EDIT_FORM_FRAME' ][ lang ] }</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault() }>
          <Row>
            <Col md={ 12 }>
              <FormGroup>
                <MultiLangInput
                  label={ Lang[ 'CONTENT' ][ lang ] }
                  value={ selectedComponentToEdit[ 'content' ] }
                  onChange={ val => { 
                    let tmpDOM = _.cloneDeep( dom )
                    let tmp = _.cloneDeep( selectedComponentToEdit )
                    tmp[ 'content' ] = val
                    findObjectAndPatch( tmpDOM, tmp )
                    onChangeFormBuilderState( 'selectedComponentToEdit', tmp )
                  }}
                  selectedLanguage={ lang } />
              </FormGroup>
            </Col>
          </Row>
          <Card>
            <CardHeader>{ Lang[ 'TITLE_STYLE' ][ lang ] }</CardHeader>
            <CardBody>
              <Row>
                <Col md={ 6 }>
                  <FormGroup>
                    <Label>{ Lang[ 'FONT_SIZE' ][ lang ] }</Label>
                    <Input
                      type="number"
                      value={ selectedComponentToEdit[ 'title' ] ? selectedComponentToEdit[ 'title' ][ 'fontSize' ] : '' }
                      onChange={ e => {
                        let tmpDOM = _.cloneDeep( dom )
                        let tmp = _.cloneDeep( selectedComponentToEdit )
                        tmp[ 'title' ][ 'fontSize' ] = parseInt( e.target.value, 10 )
                        findObjectAndPatch( tmpDOM, tmp )
                        onChangeFormBuilderState( 'selectedComponentToEdit', tmp )
                      }} />
                  </FormGroup>
                  <FormGroup>
                    <Label>{ Lang[ 'FONT_WEIGHT' ][ lang ] }</Label>
                    <Input
                      type="text"
                      value={ selectedComponentToEdit[ 'title' ] ? selectedComponentToEdit[ 'title' ][ 'fontWeight' ] : '' }
                      onChange={ e => {
                        let tmpDOM = _.cloneDeep( dom )
                        let tmp = _.cloneDeep( selectedComponentToEdit )
                        tmp[ 'title' ][ 'fontWeight' ] = e.target.value
                        findObjectAndPatch( tmpDOM, tmp )
                        onChangeFormBuilderState( 'selectedComponentToEdit', tmp )
                      }} />
                  </FormGroup>
                  <FormGroup>
                    <Label>{ Lang[ 'TEXT_ALIGN' ][ lang ] }</Label>
                    <Input
                      type="select"
                      value={ selectedComponentToEdit[ 'title' ] ? selectedComponentToEdit[ 'title' ][ 'textAlign' ] : '' }
                      onChange={ e => {
                        let tmpDOM = _.cloneDeep( dom )
                        let tmp = _.cloneDeep( selectedComponentToEdit )
                        tmp[ 'title' ][ 'textAlign' ] = e.target.value
                        findObjectAndPatch( tmpDOM, tmp )
                        onChangeFormBuilderState( 'selectedComponentToEdit', tmp )
                      }}>
                      <option value={ 'left' }>{ Lang[ 'ALIGN_LEFT' ][ lang ] }</option>
                      <option value={ 'center' }>{ Lang[ 'CENTER' ][ lang ] }</option>
                      <option value={ 'right' }>{ Lang[ 'ALIGN_RIGHT' ][ lang ] }</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label>{ Lang[ 'PADDING' ][ lang ] }</Label>
                    <Input
                      type="text"
                      value={ selectedComponentToEdit[ 'title' ] ? selectedComponentToEdit[ 'title' ][ 'padding' ] : '' }
                      onChange={ e => {
                        let tmpDOM = _.cloneDeep( dom )
                        let tmp = _.cloneDeep( selectedComponentToEdit )
                        tmp[ 'title' ][ 'padding' ] = e.target.value
                        findObjectAndPatch( tmpDOM, tmp )
                        onChangeFormBuilderState( 'selectedComponentToEdit', tmp )
                      }} />
                  </FormGroup>
                </Col>
                <Col md={ 6 }>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{ Lang[ 'COLOR' ][ lang ] }</Label>
                        <SketchPicker
                          disableAlpha={ true }
                          width={ 185 }
                          color={ selectedComponentToEdit[ 'title' ] ? checkHexColor(selectedComponentToEdit[ 'title' ][ 'color' ]) : '' }
                          onChange={ (color, event ) => {
                            let tmpDOM = _.cloneDeep( dom )
                            let tmp = _.cloneDeep( selectedComponentToEdit )
                            tmp[ 'title' ][ 'color' ] = color.hex
                            findObjectAndPatch( tmpDOM, tmp )
                            onChangeFormBuilderState( 'selectedComponentToEdit', tmp )
                        }}/>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{ Lang[ 'BACKGROUND_COLOR' ][ lang ] }</Label>
                        <SketchPicker
                          disableAlpha={ true }
                          width={ 185 }
                          color={ selectedComponentToEdit[ 'title' ] ? checkHexColor(selectedComponentToEdit[ 'title' ][ 'backgroundColor' ]) : '' }
                          onChange={ (color, event ) => {
                            let tmpDOM = _.cloneDeep( dom )
                            let tmp = _.cloneDeep( selectedComponentToEdit )
                            tmp[ 'title' ][ 'backgroundColor' ] = color.hex
                            findObjectAndPatch( tmpDOM, tmp )
                            onChangeFormBuilderState( 'selectedComponentToEdit', tmp )
                        }}/>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>{ Lang[ 'CONTAINER' ][ lang ] }</CardHeader>
            <CardBody>
              <Row>
                <Col md={ 6 }>
                  <FormGroup>
                    <Label>{ Lang[ 'BORDER_WIDTH' ][ lang ] }</Label>
                    <Input
                      type="number"
                      value={ selectedComponentToEdit[ 'container' ] ? selectedComponentToEdit[ 'container' ][ 'borderWidth' ] : '' }
                      onChange={ e => {
                        let tmpDOM = _.cloneDeep( dom )
                        let tmp = _.cloneDeep( selectedComponentToEdit )
                        tmp[ 'container' ][ 'borderWidth' ] = parseInt( e.target.value, 10 )
                        findObjectAndPatch( tmpDOM, tmp )
                        onChangeFormBuilderState( 'selectedComponentToEdit', tmp )
                      }} />
                  </FormGroup>
                </Col>
                <Col md={ 6 }>
                  <FormGroup>
                    <Label>{ Lang[ 'BORDER_COLOR' ][ lang ] }</Label>
                    <SketchPicker
                      disableAlpha={ true }
                      width={ 185 }
                      color={ selectedComponentToEdit[ 'container' ] ? checkHexColor(selectedComponentToEdit[ 'container' ][ 'borderColor' ]) : '' }
                      onChange={ (color, event ) => {
                        let tmpDOM = _.cloneDeep( dom )
                        let tmp = _.cloneDeep( selectedComponentToEdit )
                        tmp[ 'container' ][ 'borderColor' ] = color.hex
                        findObjectAndPatch( tmpDOM, tmp )
                        onChangeFormBuilderState( 'selectedComponentToEdit', tmp )
                    }}/>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary" 
          onClick={ async() => {
            let tmp = _.cloneDeep( selectedComponentToEdit )
            let tmpDOM = _.cloneDeep( dom )
            findObjectAndPatch( tmpDOM, tmp )
            await onChangeFormBuilderState( 'dom', tmpDOM )
            await onChangeFormBuilderState( 'showUpdateFormFrame', false )
          }}>{ Lang[ 'SUBMIT' ][ lang ] }</Button>
      </ModalFooter>
    </Modal>
  )
}

export default FormFrameEditor