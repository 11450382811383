import React, { Component } from 'react'
import { Button, UncontrolledTooltip } from 'reactstrap'
import _ from 'lodash'
import { MdDesktopWindows, MdTablet, MdSmartphone } from 'react-icons/md'
import { TiArrowBackOutline } from 'react-icons/ti'

import './index.scss'
import LandingPageSectionHOC from "../actions/landingPageSection"
import Userbox from "components/Header/Userbox"

class Header extends Component {
  render = () => {
    return (
      <>
        <div className="header">
          <div className="container-fluid" style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
            <div>
              <Button
                color="primary"
                onClick={ () => window.open( `${ window.location.origin }/#/dashboard/ols-landing-page/page`, '_self' ) }>
                <TiArrowBackOutline fontSize="24px"/>
              </Button>
            </div>
            <div className="d-flex m-auto" >
              <img 
                src={
                  !_.isEmpty( this.props.data.platformConfigReducer.platformConfig ) 
                  && _.find( this.props.data.platformConfigReducer.platformConfig, { key: 'PLATFORM_ICON' } ) 
                    ? _.find( this.props.data.platformConfigReducer.platformConfig, { key: 'PLATFORM_ICON' } ).value
                    : ''
                }
                style={{ width: 150, marginRight: "15px" }}/>
              <select
                className="form-control"
                style={{ margin: 'auto 5px', width: "200px" }}
                value={ this.props.selectedPage.id }
                onChange={ e => window.location.href = `${ window.location.origin }/#/landing-page-editor/${ e.target.value }`}>
                {
                  this.props.pages && this.props.pages.length > 0 && this.props.pages.map( ( item ) => {
                    return (
                      <option key={ item.id } value={ item.id }>
                        { 
                          item.name[ this.props.lang ] 
                            ? item.name[ this.props.lang ] 
                            : item.name[ 'de' ] 
                              ? item.name[ 'de' ] 
                              : item.name[ 'en' ] 
                        }
                      </option>
                    )
                  })
                }
              </select>
            </div>
            <div className="m-auto">
              <Button
                id='editor-desktop-view'
                className='editor-header-button'
                color={ this.props.selectedViewportRenderer === 'desktop' ? 'secondary' : 'primary' }
                onClick={ () => this.props.updateMainContainerState( 'selectedViewportRenderer', 'desktop' ) }>
                <MdDesktopWindows fontSize="24px"/>
              </Button>
              <Button
                id='editor-tablet-view'
                className='editor-header-button'
                color={ this.props.selectedViewportRenderer === 'tablet' ? 'secondary' : 'primary' }
                onClick={ () => this.props.updateMainContainerState( 'selectedViewportRenderer', 'tablet' ) }>
                <MdTablet fontSize="24px"/>
              </Button>
              <Button
                id='editor-mobile-view'
                className='editor-header-button'
                color={ this.props.selectedViewportRenderer === 'mobile' ? 'secondary' : 'primary' }
                onClick={() => this.props.updateMainContainerState( 'selectedViewportRenderer', 'mobile' ) } >
                <MdSmartphone fontSize="24px"/>
              </Button>
            </div>
            <Userbox
              onClickToggleProfileModal={ () => {} }
              history={ this.props.history }
              profile={ this.props.data.profileReducer.profile } />
          </div>
        </div>
      </>
    )
  }
}

export default LandingPageSectionHOC( Header )
