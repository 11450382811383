import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ReactLottie from 'react-lottie'

import NegativeIcon from 'assets/lottie/warning_icon.json'
import PositiveIcon from 'assets/lottie/tick_icon.json'

const PositiveOptions = {
  loop: false,
  autoplay: true,
  animationData: PositiveIcon
}

const NegativeOptions = {
  loop: false,
  autoplay: true,
  animationData: NegativeIcon
}

const StatusModal = ({
  type,
  onClick,
  message,
  isOpen
}) => {
  return(
    <Modal isOpen={ isOpen }>
      <ModalHeader>Status</ModalHeader>
      <ModalBody>
        {
          type === 'positive' && (
            <ReactLottie
              width={ 200 }
              height={ 200 }
              style={{ overflow: 'visible' }}
              options={ PositiveOptions }
              isPaused={ false } />
          )
        }
        {
          type === 'negative' && (
            <ReactLottie
              width={ 200 }
              height={ 200 }
              style={{ overflow: 'visible' }}
              options={ NegativeOptions }
              isPaused={ false } />
          )
        }
      { message }
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={ onClick }>OK</Button>
      </ModalFooter>
    </Modal>
  )
}

export default StatusModal
