import React, { useState } from 'react'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import _ from 'lodash'

import DeleteConfirmation from 'components/Modal/delete'
import Lang from 'Lang/General'

const checkItemType = ( item, lang ) => {
  return (
    (
      item.type && 
      (
        item.type === 'a' ||
        item.type === 'img' || 
        item.type === 'button' || 
        item.type === 'form' || 
        item.type === 'webForm' || 
        item.type === 'templateForm'
      )
    ) ? (item.type === 'button' || item.type === 'a' ) 
        ? ({ name: Lang[ 'BUTTON' ][ lang ], hex: '#45818e' })
        : ( item.type === 'form' || item.type === 'webForm' || item.type === 'templateForm' )
          ? ({ name: Lang[ 'TEMPLATE_FORM' ][ lang ], hex: '#0275d8' })
          : item.type === 'img' && ({ name: Lang[ 'IMAGE' ][ lang ], hex: '#5e458e' })
      : item.className 
        ? item.className.indexOf( 'col' ) > -1
          ? ({ name: Lang[ 'COLUMN' ][ lang ], hex: '#FF0000' })
          : item.className.indexOf( 'row' ) > -1
            ? ({ name: Lang[ 'ROW' ][ lang ], hex: '#5cb85c' })
            : item.variant && item.variant === 'text'
              ? ({ name: Lang[ 'TEXT' ][ lang ], hex: '#0275d8' })
              : ({ name: Lang[ 'SECTION' ][ lang ], hex: '#0275d8' })
        : item.variant && item.variant === 'text'
          ? ({ name: Lang[ 'TEXT' ][ lang ], hex: '#292b2c' })
          : ({ name: Lang[ 'SECTION' ][ lang ], hex: '#0275d8' })
  )
}

const SortableItem = SortableElement( ({ item, lang, selectedContainer }) => {
  return <div 
    className={ `${ selectedContainer.id === item.id ? 'selected-container' : '' } mr-2 mb-2` }
    style={{ 
      width: 'fit-content',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      zIndex: '9999',
      background: [ 'div', 'button', 'img', 'ul', 'li', 'a' ].indexOf( item.type ) > -1 
        ? checkItemType( item, lang ).hex
        : '#808080',
      color: '#ffffff',
      borderRadius: '3px',
      padding: '0.375rem 0.75rem'
    }}>
    { 
      [ 'div', 'button', 'img', 'ul', 'li', 'a' ].indexOf( item.type ) > -1 
        ? checkItemType( item, lang ).name 
        : Lang[ 'OTHER' ][ lang ]
    }
  </div>
})

const SortableList = SortableContainer( ({ items, iterate, selectedContainer, lang }) => {
  return (  
    <div 
      className='col-12' 
      style={{ paddingLeft: `${ iterate * 30 }px`, paddingRight: '0', position: 'relative' }} >
      { 
        items.map( ( item, index ) => (
          <SortableItem 
            key={ item.id } 
            index={ index } 
            item={ item } 
            selectedContainer={ selectedContainer }
            lang={ lang } 
          /> 
        )) 
      }
    </div>
  )
})

const displayChildren = ( 
  item, 
  selectedContainer, 
  iterate, 
  onChangeSectionEditorState, 
  lang, 
  updateContainerToDuplicate,
  index,
  parentId,
  rearrangeTarget,
  updateRearrangeTarget,
  reorderComponent
) => {
  return (
    <>
      <div 
        style={{ 
          paddingBottom: '10px', 
          paddingLeft: `${ iterate * 30 }px`,
          display: !_.isEmpty( rearrangeTarget ) && rearrangeTarget !== parentId && rearrangeTarget !== item.id 
            ? 'none' 
            : 'flex',
          alignItems: 'center'
        }} >
        {
          selectedContainer.id === item.id 
            ? (
              <Button 
                className="mr-2 selected-container"
                style={{ 
                  border: "none",
                  background: checkItemType( item, lang ).hex
                }} >
                { `${ Lang[ 'CURRENTLY_SELECTED' ][ lang ] }` }
              </Button>
            ) : (
              <Button 
                className="mr-2" 
                style={{ 
                  border: "none",
                  background: checkItemType( item, lang ).hex
                }}
                onClick={() => {
                  onChangeSectionEditorState( 'selectedEditableComponent', item )
                  onChangeSectionEditorState( 'selectedContainer', item ) 
                }}>
                { `${ Lang[ 'SELECT' ][ lang ] }` }
              </Button>
            )
        }
        <span className='mr-2' >{ checkItemType( item, lang ).name }</span>
        { 
          selectedContainer.id === item.id && ( item.type === 'div' || item.type === 'li' ) && !item.variant && <>
            <i 
              id={ `container-${ item.id }` }
              className="pe-7s-copy-file btn-icon-wrapper mr-2" 
              onClick={ () => updateContainerToDuplicate({ index: index, item: item }) } />
            <UncontrolledTooltip
              target={ `container-${ item.id }` }
              placement="top">
              { Lang[ 'DUPLICATE_CONTAINER' ][ lang ] }
            </UncontrolledTooltip>
          </> 
        }
        {
          ( 
            ( selectedContainer.id === item.id && _.isEmpty( rearrangeTarget ) ) || 
            rearrangeTarget === item.id 
          ) && <>
            <i 
              id={ `container-rearrange-${ item.id }` }
              className={ rearrangeTarget === item.id ? "pe-7s-close btn-icon-wrapper" : "pe-7s-refresh-2 btn-icon-wrapper" }
              onClick={ () => updateRearrangeTarget( rearrangeTarget === item.id ? '' : parentId ) } />
            <UncontrolledTooltip
              target={ `container-rearrange-${ item.id }` }
              placement="top">
              { Lang[ rearrangeTarget === item.id ? 'STOP_CONTENT_REARRANGEMENT' : 'REARRANGE_CONTENT' ][ lang ] }
            </UncontrolledTooltip>
          </> 
        }
      </div>
      {
        item.children && item.id !== rearrangeTarget && item.children.map( ( child, index ) => {
          if( [ 'div', 'button', 'img', 'ul', 'li', 'a', 'heading', 'form', 'webForm', 'templateForm' ].indexOf( child.type ) > -1 ) {
            return displayChildren( 
              child, 
              selectedContainer, 
              iterate + 1, 
              onChangeSectionEditorState, 
              lang,
              updateContainerToDuplicate, 
              index,
              item.id,
              rearrangeTarget,
              updateRearrangeTarget,
              reorderComponent
            )
          }
        })
      }
      {
        item.children && item.id === rearrangeTarget && <SortableList
          items={ item.children }
          onSortEnd={ e => reorderComponent( rearrangeTarget, e.oldIndex, e.newIndex ) }
          axis='y' 
          selectedContainer={ selectedContainer }
          lang={ lang }
          iterate={ iterate + 1 }
        />
      }
    </>
  )
}

const ObjectNavigator = ({
  selectedSection,
  selectedContainer,
  onChangeSectionEditorState,
  lang,
  duplicateComponent,
  reorderComponent
}) => {
  const [ containerToDuplicate, updateContainerToDuplicate ] = useState({ index: 0, item: {} })
  const [ rearrangeTarget, updateRearrangeTarget ] = useState( '' )

  return (
    <div>
      <style>
      {
        `
          @keyframes glowing {
            0% {
              background-color: ${ checkItemType( selectedContainer, lang ).hex };
              box-shadow: 0 0 3px ${ checkItemType( selectedContainer, lang ).hex };
            }
            50% {
              background-color: #49e819;
              box-shadow: 0 0 10px #49e819;
            }
            100% {
              background-color: ${ checkItemType( selectedContainer, lang ).hex };
              box-shadow: 0 0 3px ${ checkItemType( selectedContainer, lang ).hex };
            }
          }

          .selected-container { 
            animation: glowing 3000ms infinite;
          }
        `
      }
      </style>
      <div 
        style={{ 
          display: !_.isEmpty( rearrangeTarget ) && rearrangeTarget !== selectedSection.id 
            ? 'none' 
            : 'flex',
          alignItems: 'center',
          paddingBottom: '10px' 
        }}>
        {
          selectedContainer.id === selectedSection.id 
            ? (
              <Button 
                className="mr-2 selected-container" 
                style={{ 
                  background: "#ffa500", 
                  border: "none" 
                }} >
                { `${ Lang[ 'CURRENTLY_SELECTED' ][ lang ] }` }
              </Button>
            ) : (
              <Button 
                className="mr-2" 
                style={{ background: "#ffa500", border: "none" }} 
                onClick={() => {
                  onChangeSectionEditorState( 'selectedEditableComponent', selectedSection )
                  onChangeSectionEditorState( 'selectedContainer', selectedSection ) 
                }}>
                { `${ Lang[ 'SELECT' ][ lang ] }` }
              </Button>
            )
        }
        <span className='mr-2' >{ checkItemType( selectedSection, lang ).name }</span>
        {
          rearrangeTarget === selectedSection.id && <>
            <i 
              id={ `container-rearrange-${ selectedSection.id }` }
              className="pe-7s-close btn-icon-wrapper"
              onClick={ () => updateRearrangeTarget( '' ) } />
            <UncontrolledTooltip
              target={ `container-rearrange-${ selectedSection.id }` }
              placement="top">
              { Lang[ 'STOP_CONTENT_REARRANGEMENT' ][ lang ] }
            </UncontrolledTooltip>
          </> 
        }
      </div>
      {
        selectedSection.children && 
        selectedSection.id !== rearrangeTarget && 
        selectedSection.children.map( ( item, index ) => {
          if( [ 'div', 'button', 'img', 'ul', 'li', 'a', 'heading' ].indexOf( item.type ) > -1 ) {
            return displayChildren( 
              item, 
              selectedContainer, 
              1, 
              onChangeSectionEditorState, 
              lang,
              updateContainerToDuplicate,
              index,
              selectedSection.id,
              rearrangeTarget,
              updateRearrangeTarget,
              reorderComponent
            )
          }
        })
      }
      {
        selectedSection.children && selectedSection.id === rearrangeTarget && <SortableList
          items={ selectedSection.children }
          onSortEnd={ e => reorderComponent( rearrangeTarget, e.oldIndex, e.newIndex ) }
          axis='y' 
          selectedContainer={ selectedContainer }
          lang={ lang }
          iterate={ 1 }
        />
      }
      {
        !_.isEmpty( containerToDuplicate.item ) && (
          <DeleteConfirmation
            handleModalClose={ () => updateContainerToDuplicate({ index: 0, item: {} }) }
            confirmAction={ () => {
              duplicateComponent( containerToDuplicate )
              updateContainerToDuplicate({ index: 0, item: {} })
            }}
            content={ Lang[ 'DUPLICATE_SELECTED_CONTAINER' ][ lang ] }
            lang={ lang } />
        )
      }
    </div>
  )
}

export default ObjectNavigator