import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import StatusModal from 'components/Modal/status'
import { GetWithNonce } from 'utils/axios-nonce'
import Lang from 'Lang/General'

const CountryHOC = ( WrappedComponent ) => {
  class CountryWrappedComponent extends Component {
    state = {
      countries: [],
      states: [],
      loading: false,
      showStatusModal: false,
      statusModalType: '',
      onClickStatusModalButton: () => {},
      statusModalMessage: '',
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showStatusModal: true,
      statusModalType: 'warning-custom',
      onClickStatusModalButton: () => this.setState({ showStatusModal: false }),
      statusModalMessage: error
    })
    requestSuccess = success => this.setState({
      showStatusModal: true,
      statusModalType: 'positive',
      onClickStatusModalButton: () => this.setState({ showStatusModal: false }),
      statusModalMessage: success
    })

    getCountries = () => GetWithNonce(
      `/api/v1/countries?region=Europe&language=DEU`,
      this.getCountriesSuccess,
      this.getCountriesError,
      this.load
    )
    getCountriesSuccess = payload => {
      let tmpCountries = []
      payload.map( item => {
        tmpCountries.push({
          ...item,
          label: item.name,
          value: item.name
        })
      })
      this.setState({ countries: tmpCountries })
    }
    getCountriesError = error => this.requestError( error )

    getCountriesEn = () => GetWithNonce(
      `/api/v1/countries?region=Europe`,
      this.getCountriesEnSuccess,
      this.getCountriesEnError,
      this.load
    )
    getCountriesEnSuccess = payload => this.setState({ countries: payload })
    getCountriesEnError = error => this.requestError( error )

    getStates = country_id => GetWithNonce(
      `/api/v1/countries/${ country_id }/states`,
      this.getStatesSuccess,
      this.getStatesError,
      this.load
    )
    getStatesSuccess = payload => this.setState({ states: payload })
    getStatesError = error => this.requestError( error )

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            { ...this.props }
            onLoadCountries={ this.state.loading }
            countries={ this.state.countries }
            states={ this.state.states }
            getCountries={ this.getCountries }
            getCountriesEn={ this.getCountriesEn }
            getStates={ this.getStates } />
          <StatusModal
            isOpen={ this.state.showStatusModal }
            type={ this.state.statusModalType }
            message={ this.state.statusModalMessage ? this.state.statusModalMessage : Lang[ 'STATUS_MODAL_MESSAGE' ][ this.props.data.languageReducer.lang ] }
            onClick={ this.state.onClickStatusModalButton } />
        </Fragment>
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( CountryWrappedComponent )
}

export default CountryHOC
