import React, { Component } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { Get } from 'utils/axios'

const CentersHOC = ( WrappedComponent ) => {
  class CentersWrappedComponent extends Component {
    state = {
      loading: false,
      centers: []
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( val => toast.error( val.replace( /_/g, ' ' ) ) )

    getCenters = query => Get(
      `/api/v1/centers`,
      this.getCentersSuccess,
      this.getCentersError,
      this.load
    )
    getCentersSuccess = payload => this.setState({
      centers: payload.data.map( item => ({ 
        id: item.id, 
        label: item.center_name, 
        value: item.center_name
      }) )
    })
    getCentersError = error => this.requestError( error )

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          onLoadCenters={ this.state.loading }
          centers={ this.state.centers }
          getCenters={ this.getCenters } />
      )
    }
  }
  return CentersWrappedComponent
}

export default CentersHOC
