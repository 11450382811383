import { STORE_LANG } from 'actions/type'

const initialState = {
  lang: 'en'
}

export default ( state = initialState, action ) => {
  switch( action.type ) {
    case STORE_LANG:
      return {
        ...state,
        lang: action.payload.admin_panel_language
      }
    default: return state
  }
}
