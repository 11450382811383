import React, { useEffect } from 'react'
import { Form, FormGroup, Input, Label, Col, FormFeedback, FormText } from 'reactstrap'
import _ from 'lodash'
import Image from 'react-bootstrap/Image'
import { BeatLoader } from 'react-spinners'
import ReactSelect from 'react-select'

import WithCountries from 'actions/country'

import NoPicture from 'assets/images/no-pic.jpg'
import Lang from 'Lang/General'

const UpdateUser = ({
  showUpdate,
  dataReducer,
  selectedUser,
  errorMessage,
  onChange,
  onChangeConsultant,
  onChangeUsersHOC,
  onUploadImage,
  onClickUploadImage,
  fileInputRef,
  roles,
  statusValues,
  currentUser,
  getStates,
  onLoadCountries,
  getCountries,
  countries,
  states
}) => {

  useEffect(() => {
    if( showUpdate && selectedUser.role === 'Client' ) {
      getCountries()
      getStates( selectedUser.client.country )
    }
  }, [ showUpdate ])

  const getValueForSelect = ( type ) => {
    switch ( type ) {
      case 'state':
        return {
          label: selectedUser.client.state,
          value: selectedUser.client.state
        }
      case 'country':
        return {
          label: selectedUser.client.country,
          value: selectedUser.client.country
        }
      default:
        return {}
    }
  }

  let countriesOptions = []
  countries && countries.map( item => {
    countriesOptions.push({
      id: item.id,
      label: item.name,
      value: item.name
    })
  })

  let statesOption = []
  states && states.map( item => {
    statesOption.push({
      id: item.id,
      label: item.name,
      value: item.name
    })
  })

  return (
    <Form>
      <FormGroup row>
        <Label sm={ 2 }>{ Lang[ 'FIRST_NAME' ][ dataReducer.languageReducer.lang ] }</Label>
        <Col sm={ 10 }>
          <Input
            placeholder={ Lang[ 'FIRST_NAME' ][ dataReducer.languageReducer.lang ] }
            value={ selectedUser.first_name }
            onChange={ e => onChange( 'first_name', e.target.value ) }
            invalid={ errorMessage && errorMessage.first_name ? true : false }/>
          <FormFeedback>{ errorMessage && errorMessage.first_name ? errorMessage.first_name : '' }</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={ 2 }>{ Lang[ 'LAST_NAME' ][ dataReducer.languageReducer.lang ] }</Label>
        <Col sm={ 10 }>
          <Input
            placeholder={ Lang[ 'LAST_NAME' ][ dataReducer.languageReducer.lang ] }
            value={ selectedUser.last_name }
            onChange={ e => onChange( 'last_name', e.target.value ) }
            invalid={ errorMessage && errorMessage.last_name ? true : false }/>
          <FormFeedback>{ errorMessage && errorMessage.last_name ? errorMessage.last_name : '' }</FormFeedback>
        </Col>
      </FormGroup>
      {/*<FormGroup row>
        <Label sm={ 2 }>{ Lang[ 'USERNAME' ][ dataReducer.languageReducer.lang ] }</Label>
        <Col sm={ 10 }>
          <Input
            placeholder={ Lang[ 'USERNAME' ][ dataReducer.languageReducer.lang ] }
            value={ selectedUser.username }
            onChange={ e => onChange( 'username', e.target.value ) }
            invalid={ errorMessage && errorMessage.username ? true : false }/>
          <FormFeedback>{ errorMessage && errorMessage.username ? errorMessage.username : '' }</FormFeedback>
        </Col>
      </FormGroup>*/}
      <FormGroup row>
        <Label sm={ 2 }>{ Lang[ 'EMAIL' ][ dataReducer.languageReducer.lang ] }</Label>
        <Col sm={ 10 }>
          <Input
            placeholder={ Lang[ 'EMAIL' ][ dataReducer.languageReducer.lang ] }
            value={ selectedUser.email }
            onChange={ e => {
              let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i )
              if( !pattern.test( e.target.value )){
                errorMessage["email"] = "Please enter valid email address."
              } else {
                errorMessage["email"] = ""
              }
              onChange( 'email', e.target.value )
            }}
            invalid={ errorMessage && errorMessage.email ? true : false }/>
          <FormFeedback>{ errorMessage && errorMessage.email ? errorMessage.email : '' }</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={ 2 }>{ Lang[ 'PASSWORD' ][ dataReducer.languageReducer.lang ] }</Label>
        <Col sm={ 10 }>
          <Input
            type="password"
            autoComplete='new-password'
            placeholder={ Lang[ 'PASSWORD' ][ dataReducer.languageReducer.lang ] }
            value={ selectedUser.password }
            onChange={ e => onChange( 'password', e.target.value ) }
            invalid={ selectedUser.password && selectedUser.password.length > 0 && selectedUser.password.length < 8 ? true : false }/>
          <FormFeedback>{ selectedUser.password && selectedUser.password.length > 0 && selectedUser.password.length < 8 ? 'Password must more than 8 characters' : '' }</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={ 2 }>{ Lang[ 'CONFIRM_PASSWORD' ][ dataReducer.languageReducer.lang ] }</Label>
        <Col sm={ 10 }>
          <Input
            type="password"
            placeholder={ Lang[ 'CONFIRM_PASSWORD' ][ dataReducer.languageReducer.lang ] }
            value={ selectedUser.password_confirmation }
            onChange={ e => onChange( 'password_confirmation', e.target.value ) }
            invalid={ selectedUser.password_confirmation && selectedUser.password_confirmation.length > 0 && selectedUser.password_confirmation !== selectedUser.password ? true : false }/>
          <FormFeedback>{ selectedUser.password && selectedUser.password_confirmation && selectedUser.password_confirmation.length > 0 && selectedUser.password_confirmation !== selectedUser.password ? 'Password not same' : '' }</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={ 2 }>{ Lang[ 'PHOTO' ][ dataReducer.languageReducer.lang ] }</Label>
        <Col sm={ 10 } style={{ height: '200px', maxWidth: '200px' }}>
          {
            errorMessage.photo_raw && (
              <FormFeedback className="d-block">{ 'photo is required' }</FormFeedback>
            )
          }
          <Image
            src={ selectedUser.photo_raw && selectedUser.photo_raw.length > 0
              ? selectedUser.photo_raw
              : !_.isEmpty( selectedUser.photo_url )
                ? selectedUser.photo_url
                : NoPicture }
            onClick={ onClickUploadImage }
            style={{ height: '100%' }}
            roundedCircle={ selectedUser.photo_raw !== '' ? true : false }/>
          <input
            type="file"
            ref={ fileInputRef }
            style={{ display: 'none' }}
            onChange={ event => onUploadImage( event ) }
            accept={ 'image/png,image/jpg,image/jpeg' }/>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="role" sm={ 2 }>{ Lang[ 'ROLE' ][ dataReducer.languageReducer.lang ] }</Label>
        <Col sm={ 10 }>
          <Input
            type="select"
            name="role"
            id="role"
            value={ selectedUser.role_id }
            onChange={ e => {
              let tmpRole = _.find( roles.data, { id: parseInt( e.target.value ) } )
              if( tmpRole.name === 'Consultant' ){
                let tmpUser = _.cloneDeep( selectedUser )
                tmpUser.role_id = parseInt( e.target.value )
                tmpUser.consultant = {
                  phone_number: '',
                  mobile_number: '',
                  best_contact_time: '',
                  fax_number: '',
                  remark: '',
                  status: 'ACTIVE'
                }
                onChangeUsersHOC( 'selectedUser', tmpUser )
              } else if( tmpRole.name === 'Client' ) {
                let tmpUser = _.cloneDeep( selectedUser )
                tmpUser.role_id = parseInt( e.target.value )
                tmpUser.client = {
                  city: '',
                  company_name: '',
                  country: '',
                  house_number: '',
                  phone_number: '',
                  postcode: '',
                  salutation: '',
                  state: '',
                  street: '',
                  status: 'ACTIVE'
                }
                onChangeUsersHOC( 'selectedUser', tmpUser )
              } else {
                onChange( 'role_id', parseInt ( e.target.value ) )
              }
            }}
            disabled={ true }>
            {
              roles.data && roles.data.map( item => {
                if( item.id >= currentUser.role_id ) {
                  return(
                    <option value={ item.id } key={ item.id }>{ item.name }</option>
                  )
                }
              })
            }
          </Input>
        </Col>
      </FormGroup>
      {
        selectedUser.role === 'Consultant' && <>
          <FormGroup row>
            <Label for="phone_number" sm={ 2 }>{ Lang[ 'PHONE_NUMBER' ][ dataReducer.languageReducer.lang ] }</Label>
            <Col sm={ 10 }>
              <Input
                name="phone_number" id="phone_number"
                placeholder={ Lang[ 'ENTER_PHONE_NUMBER' ][ dataReducer.languageReducer.lang ] }
                value={ selectedUser.consultant.phone_number }
                onChange={ e => onChangeConsultant( 'phone_number', e.target.value ) }
                invalid={ errorMessage && errorMessage.phone_number ? true : false }/>
              <FormFeedback>{ errorMessage && errorMessage.phone_number ? errorMessage.phone_number : '' }</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="mobile_number" sm={ 2 }>{ Lang[ 'MOBILE_NUMBER' ][ dataReducer.languageReducer.lang ] }</Label>
            <Col sm={ 10 }>
              <Input
                name="mobile_number" id="mobile_number"
                placeholder={ Lang[ 'ENTER_MOBILE_NUMBER' ][ dataReducer.languageReducer.lang ] }
                value={ selectedUser.consultant.mobile_number }
                onChange={ e => onChangeConsultant( 'mobile_number', e.target.value ) }
                invalid={ errorMessage && errorMessage.mobile_number ? true : false }/>
              <FormFeedback>{ errorMessage && errorMessage.mobile_number ? errorMessage.mobile_number : '' }</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="fax_number" sm={ 2 }>{ Lang[ 'FAX_NUMBER' ][ dataReducer.languageReducer.lang ] }</Label>
            <Col sm={ 10 }>
              <Input
                name="fax_number" id="fax_number"
                placeholder={ Lang[ 'ENTER_FAX_NUMBER' ][ dataReducer.languageReducer.lang ] }
                value={ selectedUser.consultant.fax_number }
                onChange={ e => onChangeConsultant( 'fax_number', e.target.value ) }
                invalid={ errorMessage && errorMessage.fax_number ? true : false }/>
              <FormFeedback>{ errorMessage && errorMessage.fax_number ? errorMessage.fax_number : '' }</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="best_contact_time" sm={ 2 }>{ Lang[ 'BEST_CONTACT_TIME' ][ dataReducer.languageReducer.lang ] }</Label>
            <Col sm={ 10 }>
              <Input
                name="best_contact_time" id="best_contact_time"
                placeholder={ Lang[ 'ENTER_BEST_CONTACT_TIME' ][ dataReducer.languageReducer.lang ] }
                value={ selectedUser.consultant.best_contact_time }
                onChange={ e => onChangeConsultant( 'best_contact_time', e.target.value ) }
                invalid={ errorMessage && errorMessage.best_contact_time ? true : false }/>
              <FormFeedback>{ errorMessage && errorMessage.best_contact_time ? errorMessage.best_contact_time : '' }</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="remark" sm={ 2 }>{ Lang[ 'REMARK' ][ dataReducer.languageReducer.lang ] }</Label>
            <Col sm={ 10 }>
              <Input
                name="remark" id="remark"
                placeholder={ Lang[ 'ENTER_REMARK' ][ dataReducer.languageReducer.lang ] }
                value={ selectedUser.consultant.remark }
                onChange={ e => onChangeConsultant( 'remark', e.target.value ) }
                invalid={ errorMessage && errorMessage.remark ? true : false }/>
              <FormFeedback>{ errorMessage && errorMessage.remark ? errorMessage.remark : '' }</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="role" sm={ 2 }>{ Lang[ 'STATUS' ][ dataReducer.languageReducer.lang ] }</Label>
            <Col sm={ 10 }>
              <Input
                type="select"
                name="status"
                id="status"
                value={ selectedUser.consultant.status }
                onChange={ e => onChangeConsultant( 'status', e.target.value ) }>
                {
                  statusValues && Object.keys( statusValues ).map( key => {
                    return(
                      <option value={ statusValues[key] } key={ key }>{ statusValues[key] }</option>
                    )
                  })
                }
              </Input>
            </Col>
          </FormGroup>
        </>
      }
      {
        selectedUser.role === 'Client' && (
          <>
            <FormGroup row>
              <Label for="company_name" sm={ 2 }>{ Lang[ 'NAME' ][ dataReducer.languageReducer.lang ] }</Label>
              <Col sm={ 10 }>
                <Input
                  name="company" id="company_name"
                  placeholder={ Lang[ 'ENTER_NAME' ][ dataReducer.languageReducer.lang ] }
                  value={ selectedUser.client.company_name }
                  onChange={ e => {
                    let tmp = _.cloneDeep( selectedUser )
                    tmp[ 'client' ][ 'company_name' ] = e.target.value
                    onChangeUsersHOC( 'newUser', tmp )
                  }}
                  invalid={ errorMessage && errorMessage.company_name ? true : false }/>
                <FormFeedback>{ errorMessage && errorMessage.company_name ? errorMessage.company_name : '' }</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="salutation" sm={ 2 }>{ Lang[ 'SALUTATION' ][ dataReducer.languageReducer.lang ] }</Label>
              <Col sm={ 10 }>
                <Input
                  type="select"
                  name="salutation"
                  id="salutation"
                  error={ errorMessage && errorMessage.salutation ? true : false }
                  value={ selectedUser.client.salutation }
                  onChange={ e => {
                    let tmp = _.cloneDeep( selectedUser )
                    tmp[ 'client' ][ 'salutation' ] = e.target.value
                    onChangeUsersHOC( 'newUser', tmp )
                  }}>
                  <option>{ Lang['SELECT_A_SALUTATION'][ dataReducer.languageReducer.lang ] }</option>
                  <option value={ 'Herr' }>{ Lang['MR'][ dataReducer.languageReducer.lang ] }</option>
                  <option value={ 'Frau' }>{ Lang['MRS'][ dataReducer.languageReducer.lang ] }</option>
                  <option value={ 'Herr & Frau' }>{ Lang['MR_AND_MRS'][ dataReducer.languageReducer.lang ] }</option>
                </Input>
                <FormFeedback>{ errorMessage && errorMessage.salutation ? errorMessage.salutation : '' }</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="street" sm={ 2 }>{ Lang[ 'STREET' ][ dataReducer.languageReducer.lang ] }</Label>
              <Col sm={ 10 }>
                <Input
                  name="street" id="street"
                  placeholder={ Lang[ 'ENTER_STREET_NAME' ][ dataReducer.languageReducer.lang ] }
                  value={ selectedUser.client.street }
                  onChange={ e => {
                    let tmp = _.cloneDeep( selectedUser )
                    tmp[ 'client' ][ 'street' ] = e.target.value
                    onChangeUsersHOC( 'selectedUser', tmp )
                  }}
                  invalid={ errorMessage && errorMessage.street ? true : false }/>
                <FormFeedback>{ errorMessage && errorMessage.street ? errorMessage.street : '' }</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="house_number" sm={ 2 }>{ Lang[ 'HOUSE_NUMBER' ][ dataReducer.languageReducer.lang ] }</Label>
              <Col sm={ 10 }>
                <Input
                  name="house_number" id="house_number"
                  placeholder={ Lang[ 'ENTER_HOUSE_NUMBER' ][ dataReducer.languageReducer.lang ] }
                  value={ selectedUser.client.house_number }
                  onChange={ e => {
                    let tmp = _.cloneDeep( selectedUser )
                    tmp[ 'client' ][ 'house_number' ] = e.target.value
                    onChangeUsersHOC( 'newUser', tmp )
                  }}
                  invalid={ errorMessage && errorMessage.house_number ? true : false }/>
                <FormFeedback>{ errorMessage && errorMessage.house_number ? errorMessage.house_number : '' }</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="postcode" sm={ 2 }>{ Lang[ 'POSTCODE' ][ dataReducer.languageReducer.lang ] }</Label>
              <Col sm={ 10 }>
                <Input
                  name="postcode" id="postcode"
                  placeholder={ Lang[ 'ENTER_POSTCODE' ][ dataReducer.languageReducer.lang ] }
                  value={ selectedUser.client.postcode }
                  onChange={ e => {
                    let tmp = _.cloneDeep( selectedUser )
                    tmp[ 'client' ][ 'postcode' ] = e.target.value
                    onChangeUsersHOC( 'newUser', tmp )
                  }}
                  invalid={ errorMessage && errorMessage.postcode ? true : false }/>
                <FormFeedback>{ errorMessage && errorMessage.postcode ? errorMessage.postcode : '' }</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="city" sm={ 2 }>{ Lang[ 'CITY' ][ dataReducer.languageReducer.lang ] }</Label>
              <Col sm={ 10 }>
                <Input
                  name="city" id="city"
                  placeholder={ Lang[ 'ENTER_CITY' ][ dataReducer.languageReducer.lang ] }
                  value={ selectedUser.client.city }
                  onChange={ e => {
                    let tmp = _.cloneDeep( selectedUser )
                    tmp[ 'client' ][ 'city' ] = e.target.value
                    onChangeUsersHOC( 'newUser', tmp )
                  }}
                  invalid={ errorMessage && errorMessage.city ? true : false }/>
                <FormFeedback>{ errorMessage && errorMessage.city ? errorMessage.city : '' }</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="country" sm={ 2 }>{ Lang[ 'COUNTRY' ][ dataReducer.languageReducer.lang ] }</Label>
              <Col sm={ 10 }>
                <ReactSelect
                  options={ countriesOptions }
                  onChange={ v => {
                    let tmp = _.cloneDeep( selectedUser )
                    tmp[ 'client' ][ 'country' ] = v.value
                    onChangeUsersHOC( 'newUser', tmp )
                    let tmpCountry = _.find( countriesOptions, { value: v.value })
                    if( tmpCountry ){
                      getStates( tmpCountry.id )
                    }
                  }}
                  value={ getValueForSelect( 'country' ) }
                  placeholder={ Lang[ 'SELECT_COUNTRY' ][ dataReducer.languageReducer.lang ] }/>
                <FormText color="danger">{ errorMessage && errorMessage.country ? errorMessage.country : '' }</FormText>
              </Col>
            </FormGroup>
            {
              onLoadCountries && <BeatLoader
                sizeUnit={"px"}
                size={30}
                color={'#123abc'}
                loading={true}
              />
            }
            {
              selectedUser.client.country && (
                <FormGroup row>
                  <Label for="country" sm={ 2 }>{ Lang[ 'STATE' ][ dataReducer.languageReducer.lang ] }</Label>
                  <Col sm={ 10 }>
                    <ReactSelect
                      options={ statesOption }
                      onChange={ v => {
                        let tmp = _.cloneDeep( selectedUser )
                        tmp[ 'client' ][ 'state' ] = v.value
                        onChangeUsersHOC( 'newUser', tmp )
                      }}
                      value={ getValueForSelect( 'state' ) }
                      placeholder={ Lang[ 'SELECT_STATE' ][ dataReducer.languageReducer.lang ] }/>
                    <FormText color="danger">{ errorMessage && errorMessage.state ? errorMessage.state : '' }</FormText>
                  </Col>
                </FormGroup>
              )
            }
            <FormGroup row>
              <Label for="phone_number" sm={ 2 }>{ Lang[ 'PHONE_NUMBER' ][ dataReducer.languageReducer.lang ] }</Label>
              <Col sm={ 10 }>
                <Input
                  name="phone_number" id="phone_number"
                  placeholder={ Lang[ 'ENTER_PHONE_NUMBER' ][ dataReducer.languageReducer.lang ] }
                  value={ selectedUser.client.phone_number }
                  onChange={ e => {
                    let tmp = _.cloneDeep( selectedUser )
                    tmp[ 'client' ][ 'phone_number' ] = e.target.value
                    onChangeUsersHOC( 'newUser', tmp )
                  }}
                  invalid={ errorMessage && errorMessage.phone_number ? true : false }/>
                <FormFeedback>{ errorMessage && errorMessage.phone_number ? errorMessage.phone_number : '' }</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="fax_number" sm={ 2 }>{ Lang[ 'FAX_NUMBER' ][ dataReducer.languageReducer.lang ] }</Label>
              <Col sm={ 10 }>
                <Input
                  name="fax_number" id="fax_number"
                  placeholder={ Lang[ 'ENTER_FAX_NUMBER' ][ dataReducer.languageReducer.lang ] }
                  value={ selectedUser.client.fax_number }
                  onChange={ e => {
                    let tmp = _.cloneDeep( selectedUser )
                    tmp[ 'client' ][ 'fax_number' ] = e.target.value
                    onChangeUsersHOC( 'newUser', tmp )
                  }}
                  invalid={ errorMessage && errorMessage.fax_number ? true : false }/>
                <FormFeedback>{ errorMessage && errorMessage.fax_number ? errorMessage.fax_number : '' }</FormFeedback>
              </Col>
            </FormGroup>
          </>
        )
      }
      <FormGroup row>
        <Label for="language" sm={ 2 }>{ Lang[ 'DEFAULT_LANGUAGE' ][ dataReducer.languageReducer.lang ] }</Label>
        <Col sm={ 10 }>
          <Input
            type={ 'select' }
            value={ selectedUser.admin_panel_language }
            onChange={ e => onChange( 'admin_panel_language', e.target.value ) }>
            <option value={ 'en' }>{ Lang[ 'ENGLISH' ][ dataReducer.languageReducer.lang ] }</option>
            <option value={ 'de' }>{ Lang[ 'GERMAN' ][ dataReducer.languageReducer.lang ] }</option>
            <option value={ 'it' }>{ Lang[ 'ITALIAN' ][ dataReducer.languageReducer.lang ] }</option>
            <option value={ 'nl' }>{ Lang[ 'DUTCH' ][ dataReducer.languageReducer.lang ] }</option>
          </Input>
        </Col>
      </FormGroup>
    </Form>
  )
}

export default WithCountries( UpdateUser )
