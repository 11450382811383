import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Post, Get, Put } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state={ 
      loading: false,
      centers: {
        data: [],
        meta: {}
      },
      centersPages: [],
      selectedCenter: {},
      showUpdateCenterTemplates: false,
      centerSearchParams: {
        center_name: ''
      }
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( err => toast.error( err.replaceAll( '_', ' ' ) ) )

    onChangeCentersTemplatesHOC = ( key, value ) => this.setState({ [ key ] : value })

    getCenters = ( page, isReset ) => {
      let tmpSearchParams = {
        page: page ? page : 1,
        is_paginated: true,
        ...( 
          !isReset && !_.isEmpty( this.state.centerSearchParams.center_name ) ? {
            filter: {
              center_name: {
                $like: `%${ this.state.centerSearchParams.center_name }%`
              }
            }
          } : {}
        )
      }
      let tmpSearchString = convertObjToBase64( tmpSearchParams )
      if( isReset ){
        this.setState({ centerSearchParams: { center_name: '' } })
      }
      return Get(
        `/api/v1/centers?query=${ tmpSearchString }`,
        this.getCentersSuccess,
        this.getCentersError,
        this.load
      )
    }
    getCentersSuccess = payload => {
      let tmpTotalPages = []
      let tmpCenters = []
      if( payload.data && payload.data.length > 0 ) {
        payload.data.map( item => {
          tmpCenters.push({
            ...item,
            fullname: `${ item.first_name } ${ item.last_name }`
          })
        })
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      let tmp = { data: tmpCenters, meta: payload.meta }
      this.setState({ centers: tmp, centersPages: tmpTotalPages })
    }
    getCentersError = () => {}

    getSelectedCenterProgramTemplates = center => Get(
      `/api/v1/ark_member_area/centers/${ center.id }/purchase_program_templates`,
      payload => this.getSelectedCenterProgramTemplatesSuccess( payload, center ),
      this.getSelectedCenterProgramTemplatesError,
      this.load
    )
    getSelectedCenterProgramTemplatesSuccess = ( payload, center ) => this.setState({ 
      selectedCenter: { ...center, program_templates: payload }, 
      showUpdateCenterTemplates: true 
    })
    getSelectedCenterProgramTemplatesError = () => {}

    createCenterProgramTemplate = ( center_id, am_program_template_id ) => Post(
      `/api/v1/ark_member_area/centers/${ center_id }/purchase_program_template`,
      { 
        am_program_template_id: am_program_template_id,
        status: 'ACTIVE'
      },
      this.createCenterProgramTemplateSuccess,
      this.createCenterProgramTemplateError,
      this.load
    )
    createCenterProgramTemplateSuccess = payload => {
      this.setState({ 
        selectedCenter: { 
          ...this.state.selectedCenter, 
          program_templates: [
            ...this.state.selectedCenter.program_templates,
            payload
          ]
        } 
      })
      this.requestSuccess( Lang[ 'PROGRAM_TEMPLATE_IS_SUCCESSFULLY_ASSIGNED' ][ this.props.data.languageReducer.lang ] )
    }
    createCenterProgramTemplateError = () => {}

    updateCenterProgramTemplate = ( center_id, am_program_template_id, status ) => Put(
      `/api/v1/ark_member_area/centers/${ center_id }/program_templates/${ am_program_template_id }/update`,
      { status: status },
      this.updateCenterProgramTemplateSuccess,
      this.updateCenterProgramTemplateError,
      this.load
    )
    updateCenterProgramTemplateSuccess = payload => {
      let tmpSelectedCenter = _.cloneDeep( this.state.selectedCenter )
      let tmpIndex = _.findIndex( tmpSelectedCenter.program_templates, { id: payload.id } )
      tmpSelectedCenter.program_templates[ tmpIndex ] = payload
      this.setState({ selectedCenter: tmpSelectedCenter })
      this.requestSuccess( Lang[ 'PROGRAM_TEMPLATE_IS_SUCCESSFULLY_UPDATED' ][ this.props.data.languageReducer.lang ] )
    }
    updateCenterProgramTemplateError = () => {}

    render = () => {
      return(
        <WrappedComponent
          { ...this.props } 
          onLoadCentersTemplatesHOC={ this.state.loading }
          centers={ this.state.centers }
          centersPages={ this.state.centersPages }
          selectedCenter={ this.state.selectedCenter }
          showUpdateCenterTemplates={ this.state.showUpdateCenterTemplates }
          centerSearchParams={ this.state.centerSearchParams }
          getCenters={ this.getCenters }
          getSelectedCenterProgramTemplates={ this.getSelectedCenterProgramTemplates }
          onChangeCentersTemplatesHOC={ this.onChangeCentersTemplatesHOC }
          createCenterProgramTemplate={ this.createCenterProgramTemplate }
          updateCenterProgramTemplate={ this.updateCenterProgramTemplate }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC