import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import { GetWithNonce } from 'utils/axios-nonce'
import { Get, Put } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const PrivacyPolicyHOC = ( WrappedComponent ) => {
  class PrivacyPolicyWrappedComponent extends Component {
    state = {
      loading: false,
      privacyPolicies: [],
      selectedPrivacyPolicy: {},
      privacyPolicyTotalPages: [],
      centers: [],
      showViewPrivacyPolicy: false,
      showEditPrivacyPolicy: false,
      searchCenter: {}
    }

    onChangePrivacyPolicyHOC = ( key, value ) => this.setState({ [ key ]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( item => toast.error( item.replaceAll( '_', ' ' ) ) )

    getAllPrivacyPolicies = query => GetWithNonce(
      `/api/v1/gdpr/policies?query=${ convertObjToBase64( query ) }`,
      this.getAllPrivacyPoliciesSuccess,
      this.getAllPrivacyPoliciesError,
      this.load
    )
    getAllPrivacyPoliciesSuccess = payload => {
      let tmpTotalPages = []
      if ( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      this.setState({ privacyPolicies: payload, privacyPolicyTotalPages: tmpTotalPages })
    }
    getAllPrivacyPoliciesError = error => this.requestError( error )

    getSelectedPrivacyPolicy = ( center_id, type ) => GetWithNonce(
      `/api/v1/gdpr/${ center_id }/policies`,
      ( payload ) => this.getSelectedPrivacyPolicySuccess( payload, type ),
      this.getSelectedPrivacyPolicyError,
      this.load
    )
    getSelectedPrivacyPolicySuccess = ( payload, type ) => type === 'view' 
      ? this.setState({ selectedPrivacyPolicy: payload, showViewPrivacyPolicy: true }) 
      : this.setState({ selectedPrivacyPolicy: payload, showEditPrivacyPolicy: true })
    getSelectedPrivacyPolicyError = error => this.requestError( error )

    updatePrivacyPolicy = ( center_id, data ) => Put(
      `/api/v1/gdpr/${ center_id }/policies/${ data.id }`,
      data,
      this.updatePrivacyPolicySuccess,
      this.updatePrivacyPolicyError,
      this.load
    )
    updatePrivacyPolicySuccess = payload => {
      this.requestSuccess( Lang[ 'POLICY_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showEditPrivacyPolicy: false })
    }
    updatePrivacyPolicyError = error => this.requestError( error )

    getCenters = query => Get(
      `/api/v1/centers?query=${ query }`,
      this.getCentersSuccess,
      this.getCentersError,
      this.load
    )
    getCentersSuccess = payload => {
      let centerOptions = []
      payload.data && payload.data.length > 0 && payload.data.map( item => {
        centerOptions.push({
          id: item.id, 
          label: item.center_name, 
          value: item.center_name
        })
      })
      this.setState({ centers: centerOptions })
    }
    getCentersError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            privacyPolicies={ this.state.privacyPolicies }
            selectedPrivacyPolicy={ this.state.selectedPrivacyPolicy }
            privacyPolicyTotalPages={ this.state.privacyPolicyTotalPages }
            onChangePrivacyPolicyHOC={ this.onChangePrivacyPolicyHOC }
            getAllPrivacyPolicies={ this.getAllPrivacyPolicies }
            getSelectedPrivacyPolicy={ this.getSelectedPrivacyPolicy }
            updatePrivacyPolicy={ this.updatePrivacyPolicy }
            getCenters={ this.getCenters }
            centers={ this.state.centers }
            showViewPrivacyPolicy={ this.state.showViewPrivacyPolicy }
            showEditPrivacyPolicy={ this.state.showEditPrivacyPolicy }
            searchCenter={ this.state.searchCenter }
            onLoadPrivacyPolicy={ this.state.loading } />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( PrivacyPolicyWrappedComponent )
}

export default PrivacyPolicyHOC
