import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody
} from 'reactstrap'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import View from './View'
import RTEUtilities from './RTEUtilities'
import ImageUtilities from './ImageUtilities'
import NewComponentLists from './NewComponent'
import CarouselForm from './CarouselForm'
import CarouselImageBlockForm from './CarouselImageBlockForm'
import TextUtilities from './TextUtilities'
import ComponentUtilities from './ComponentUtilities'
import GoogleMapSettings from './GoogleMapSettings'
import ContainerSettings from './ContainerSettings'
import ObjectNavigator from './ObjectNavigator'
import MediaDatabase from 'SharedModules/MediaDatabase'
import AccordionForm from './AccordionForm'
import HeadingForm from './HeadingForm'
import MediathekForm from './MediathekForm'
import CountdownForm from './CountdownForm'

import WithSections from './actions'
import WithComponents from './actions/components'

import { toggleMediaDatabase } from 'actions/MediaDatabaseToggle'
import { findObjectAndPatch, patchObjectID } from '../utility/ObjectPatcher'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

class SectionEditor extends Component {
  state = {
    showRTE: false,
    showCodeTagEditor: false,
    carouselPage: 0,
    mediaDatabaseFor: '',
    selectedEditableComponent: {},
    selectedContainer: {},
    selectedImagePosition: {},
    hoveredComponentId: "",
    selectedParent: {},
  }

  componentDidMount = () => {
    this.props.getSelectedSection( this.props.selectedSectionID )
  }

  componentDidUpdate = pp => {
    if( this.props.selectedSectionID !== pp.selectedSectionID ) {
      this.props.getSelectedSection( this.props.selectedSectionID )
    }

    if( this.props.isOpen !== pp.isOpen ) {
      this.setState({ selectedContainer: {}, selectedEditableComponent: {} })
      this.props.getSelectedSection( this.props.selectedSectionID )
      this.props.getComponents( convertObjToBase64({ "filter": { "$or": [ { "category": "button" } ] } }) )
    }
  }

  onChangeSectionEditorState = ( key, val ) => {
    if( key === 'selectedContainer' && val.type && ( val.type === 'a' || val.type === 'button' )){
      this.findComponentParent( this.props.selectedSection, val )
    }
    this.setState({ [key]: val })
  }

  onUpdateCarouselPage = index => {
    this.setState({ carouselPage: index })
  }

  onUpdateImage = ( updatedDOM ) => {
    this.findComponentAndPatch( updatedDOM, this.props.selectedSection )
    this.setState({ selectedEditableComponent: updatedDOM })
  }

  onStopDragComponent = param => this.setState({ selectedImagePosition: param })

  onUpdateRTEContent = ( dom, value ) => {
    let tmp = _.cloneDeep( this.props.selectedSection )
    if( tmp.type === 'carousel' && dom.variant && dom.variant.indexOf( 'caption' ) > -1 ) {
      tmp.carouselItems.map( item => {
        if( item.id === dom.id ) {
          if( dom.variant === 'caption-title' ) {
            item.caption.title = value
          } else if( dom.variant === 'caption-description' ) {
            item.caption.description = value
          }
        }
      })
    } else if( tmp.type === 'countdown' ) {
      tmp.children.map( item => {
        if ( item.variant === 'text' && item.id === dom.id ) {
          item.content[ this.props.lang ] = value[ this.props.lang ]
        }
      })
    } else {
      let tmpText = _.cloneDeep( this.state.selectedEditableComponent )
      tmpText.content = value
      this.findComponentAndPatch( tmpText, tmp )
      this.setState({ selectedEditableComponent: tmpText })
      if( this.state.selectedContainer.type === 'accordion' ){
        let tmpContainer = _.cloneDeep( this.state.selectedContainer )
        this.findComponentAndPatch( tmpText, tmpContainer )
        this.setState({ selectedContainer: tmpContainer })
      }
    }
    return this.props.onChangeSectionHOC( 'selectedSection', tmp )
  }

  onPatchRendererComponent = ( tmpSelectedComponent ) => {
    let tmp = _.cloneDeep( this.props.selectedSection )
    let tmpText = _.cloneDeep( tmpSelectedComponent )
    findObjectAndPatch( tmpText, tmp )
    this.props.onChangeSectionHOC( 'selectedSection', tmp )
    this.setState({ selectedEditableComponent: tmpText })
  }

  findComponentAndPatch = ( dom, selectedSection ) => {
    let found = false
    if( selectedSection.children && selectedSection.children.length > 0 ) {
      selectedSection.children.map(( item, index ) => {
        if( dom.id === item.id ) {
          found = true
          selectedSection.children.splice( index, 1, dom )
        }
      })
      if( !found ) {
        selectedSection.children.map(( item ) => {
          if( item.children && item.children.length > 0 ) {
            this.findComponentAndPatch( dom, item )
          }
        })
      }
    }
  }

  findComponentAndRemove = ( dom, selectedSection ) => {
    let found = false
    if( selectedSection && selectedSection.children.length > 0 ) {
      selectedSection.children.map( item => {
        if( item.id === dom.id ) {
          found = true
          _.remove( selectedSection.children, { id: dom.id })
        }
      })
      if( !found ) {
        selectedSection.children.map( item => {
          if( item.children ) {
            this.findComponentAndRemove( dom, item )
          }
        })
      }
    }
  }

  findContainerAndAdd = ( dom, selectedContainer, selectedSection ) => {
    if( selectedContainer.id === selectedSection.id ) {
      if( selectedContainer.children ) {
        selectedSection.children.push( dom )
      } else {
        selectedSection.children = []
        selectedSection.children.push( dom )
      }
    } else {
      if( selectedSection && selectedSection.children ) {
        selectedSection.children.map( item => {
          this.findContainerAndAdd( dom, selectedContainer, item )
        })
      }
    }
  }

  createComponent = ( tmp ) => {
    let tmpSelectedSection = _.cloneDeep( this.props.selectedSection )
    let tmpSelectedContainer = _.cloneDeep( this.state.selectedContainer )
    this.findContainerAndAdd( tmp, tmpSelectedContainer, tmpSelectedSection )
    this.setState({ selectedContainer: {} })
    return this.props.onChangeSectionHOC( 'selectedSection', tmpSelectedSection )
  }

  findComponentParent = ( data, target ) => {
    if( data.children && data.children.length > 0 ) {
      let tmpIndex = _.findIndex( data.children, { id: target.id } )
      if( tmpIndex > -1 ) {
        this.setState({ selectedParent: data })
      } else {
        data.children.map( item => {
          this.findComponentParent( item, target )
        })
      }
    }
  }

  findComponent = ( data, target ) => {
    if( data.id === target ){
      this.setState({ selectedParent: data })
    } else if ( data.children && data.children.length > 0 ) {
      data.children.map( item => {
        this.findComponent( item, target )
      })
    }
  }

  duplicateComponent = containerToDuplicate => {
    let tmpSelectedSection = _.cloneDeep( this.props.selectedSection )
    let tmpSelectedContainer = _.cloneDeep( this.state.selectedContainer )
    let patchedItem = _.cloneDeep( containerToDuplicate.item )
    patchObjectID( patchedItem )
    Promise.all([
      this.findComponentParent( tmpSelectedSection, tmpSelectedContainer )
    ]).then(() => {
      let tmpParent = _.cloneDeep( this.state.selectedParent )
      tmpParent.children.splice( containerToDuplicate.index + 1, 0, patchedItem )
      if( tmpParent.id === tmpSelectedSection.id ){
        tmpSelectedSection = tmpParent
      } else {
        this.findComponentAndPatch( tmpParent, tmpSelectedSection )
      }
      this.setState({ selectedContainer: {} })
      this.props.onChangeSectionHOC( 'selectedSection', tmpSelectedSection )
    })
  }

  reorderComponent = ( reorderTarget, oldIndex, newIndex ) => {
    let tmpSelectedSection = _.cloneDeep( this.props.selectedSection )
    Promise.all([
      this.findComponent( tmpSelectedSection, reorderTarget )
    ]).then(() => {
      let tmpParent = _.cloneDeep( this.state.selectedParent )
      const [ removed ] = tmpParent.children.splice( oldIndex, 1 )
      tmpParent.children.splice( newIndex, 0, removed )
      if( tmpParent.id === tmpSelectedSection.id ){
        tmpSelectedSection = tmpParent
      } else {
        this.findComponentAndPatch( tmpParent, tmpSelectedSection )
      }
      this.props.onChangeSectionHOC( 'selectedSection', tmpSelectedSection )
    })
  }

  showTextUtilities = () => !_.isEmpty( this.state.selectedEditableComponent ) && ( this.state.selectedEditableComponent.variant === 'text' || !_.isEmpty( this.state.selectedEditableComponent.content ) )

  render = () => {
    return (
      <Modal
        isOpen={ this.props.showSectionEditor }
        style={{ maxWidth: '100vw' }}
        toggle={() => this.props.updateMainContainerState( 'showSectionEditor', false )}>
        <ModalHeader toggle={() => this.props.updateMainContainerState( 'showSectionEditor', false )}>{ `${ Lang[ 'SECTION_EDITOR' ][ this.props.lang ] } ${ this.props.selectedLanguageShortname }` }</ModalHeader>
        <ModalBody>
          <Row>
            <Col md={ 7 }>
              {
                this.showTextUtilities() && <RTEUtilities
                showCodeTagEditor={ this.state.showCodeTagEditor }
                selectedEditableComponent={ this.state.selectedEditableComponent }
                onChangeSectionEditorState={ this.onChangeSectionEditorState }
                onPatchRendererComponent={ this.onPatchRendererComponent }
                onUpdateRTEContent={ this.onUpdateRTEContent }
                selectedLanguage={ this.props.selectedLanguageShortname }
                lang={ this.props.lang } />
              }
              {
                this.props.selectedSection && !_.isEmpty( this.props.selectedSection ) && (
                  <View
                    templateStylesheets={ this.props.templateStylesheets }
                    templateJavascripts={ this.props.templateJavascripts }
                    selectedSection={ this.props.selectedSection }
                    languages={ this.props.languages }
                    selectedLanguageShortname={ this.props.selectedLanguageShortname }
                    onUpdateCarouselPage={ this.onUpdateCarouselPage }
                    carouselPage={ this.state.carouselPage }
                    selectedContainer={ this.state.selectedContainer }
                    onChangeSectionEditorState={ this.onChangeSectionEditorState }
                    selectedEditableComponent={ this.state.selectedEditableComponent }
                    onStopDragComponent={ this.onStopDragComponent }
                    hoveredComponentId={ this.state.hoveredComponentId } />
                )
              }
            </Col>
            <Col md={ 5 } style={{ height: '75vh', overflowY: 'scroll' }}>
              {
                this.state.selectedContainer.type === 'div' && 
                this.state.selectedContainer.className && 
                this.state.selectedContainer.className.indexOf( 'col' ) > -1 
                && !this.props.selectedSection.schema && (
                  <NewComponentLists
                    selectedLanguageShortname={ this.props.selectedLanguageShortname }
                    createComponent={ this.createComponent }
                    lang={ this.props.lang } />
                )
              }
              {
                this.props.selectedSection.type === 'carousel' && (
                  <CarouselForm
                    carouselPage={ this.state.carouselPage }
                    selectedSection={ this.props.selectedSection }
                    selectedLanguageShortname={ this.props.selectedLanguageShortname }
                    onChangeSectionHOC={ this.props.onChangeSectionHOC }
                    findComponentAndPatch={this.findComponentAndPatch}
                    onChangeSectionEditorState={ this.onChangeSectionEditorState }
                    pages={ this.props.pages }
                    toggleMediaDatabase={ this.props.toggleMediaDatabase }
                    mediaDatabaseFor={ this.state.mediaDatabaseFor }
                    onUpdateCarouselPage={ this.onUpdateCarouselPage }
                    stylesheets={ this.props.stylesheets }
                    javascripts={ this.props.javascripts } />
                )
              }
              {
                (( this.state.selectedEditableComponent && this.state.selectedEditableComponent.type==='carousel-image-block' ) || 
                ( this.props.selectedSection && this.props.selectedSection.type === 'carousel-image-block' )) && (
                  <CarouselImageBlockForm
                    lang={ this.props.lang }
                    selectedEditableComponent={ this.state.selectedEditableComponent }
                    toggleMediaDatabase={ this.props.toggleMediaDatabase }
                    selectedSection={ this.props.selectedSection }
                    onChangeSectionHOC={ this.props.onChangeSectionHOC }
                    findComponentAndPatch={ this.findComponentAndPatch }
                  />
                )
              }
              {
                ( this.props.selectedSection.type ==='accordion' || ( this.state.selectedContainer && this.state.selectedContainer.type==='accordion' ))&& (
                  <AccordionForm
                    selectedLanguageShortname={ this.props.selectedLanguageShortname }
                    selectedSection={ this.props.selectedSection }
                    selectedContainer={ this.state.selectedContainer }
                    onChangeSectionHOC={ this.props.onChangeSectionHOC }
                    findComponentAndRemove={ this.findComponentAndRemove }
                    findComponentAndPatch={ this.findComponentAndPatch }
                    onChangeSectionEditorState={ this.onChangeSectionEditorState }
                  />
                )
              }
              {
                this.state.selectedEditableComponent && this.state.selectedEditableComponent.type === 'mediathek' &&
                <MediathekForm
                  lang={ this.props.lang }
                  toggleMediaDatabase={ this.props.toggleMediaDatabase }
                  selectedSection={ this.props.selectedSection }
                  selectedEditableComponent={ this.state.selectedEditableComponent }
                  onChangeSectionHOC={ this.props.onChangeSectionHOC }
                  onChangeSectionEditorState={ this.onChangeSectionEditorState }
                  findComponentAndPatch={ this.findComponentAndPatch }
                />
              }
              {
                !( this.props.selectedSection.type === 'accordion' || ( this.state.selectedContainer && this.state.selectedContainer.type==='accordion' )) &&
                this.state.selectedEditableComponent.variant === 'text' && (
                  <TextUtilities
                    onChangeSectionEditorState={ this.onChangeSectionEditorState }
                    lang={ this.props.lang } />
                )
              }
              {
                this.state.selectedEditableComponent.type === 'heading' &&
                <HeadingForm
                  onChangeSectionEditorState={ this.onChangeSectionEditorState }
                  onChangeSectionHOC={ this.props.onChangeSectionHOC }
                  selectedEditableComponent={ this.state.selectedEditableComponent }
                  findComponentAndPatch={ this.findComponentAndPatch }
                  lang={ this.props.lang }
                  selectedSection={ this.props.selectedSection } />
              }
              {
                ( this.props.selectedSection.type === 'carousel' || !_.isEmpty( this.state.selectedEditableComponent ) ) && (
                  <ComponentUtilities
                    selectedSection={ this.props.selectedSection }
                    selectedEditableComponent={ this.state.selectedEditableComponent }
                    carouselPage={ this.state.carouselPage }
                    findComponentAndRemove={ this.findComponentAndRemove }
                    onChangeSectionHOC={ this.props.onChangeSectionHOC }
                    onChangeSectionEditorState={ this.onChangeSectionEditorState }
                    onUpdateCarouselPage={ this.onUpdateCarouselPage }
                    lang={ this.props.lang } />
                )
              }
              {
                this.state.selectedEditableComponent && ( 
                  ( this.props.selectedSection.type === 'carousel' && this.props.selectedSection.carouselItems[ this.state.carouselPage ].dom ) ||
                  this.props.selectedSection.type !== 'carousel'
                ) && (
                  ( !_.isEmpty( this.state.selectedEditableComponent ) && this.state.selectedEditableComponent.type === 'img' ) || 
                  ( !_.isEmpty( this.state.selectedEditableComponent.style ) && 'backgroundImage' in this.state.selectedEditableComponent.style ) 
                ) && (
                  <ImageUtilities
                    toggleMediaDatabase={ this.props.toggleMediaDatabase }
                    selectedEditableComponent={ this.state.selectedEditableComponent }
                    onUpdateImage={ this.onUpdateImage }
                    findComponentAndPatch={ this.findComponentAndPatch }
                    selectedSection={ this.props.selectedSection}
                    onChangeSectionHOC={ this.props.onChangeSectionHOC }
                    selectedImagePosition={ this.state.selectedImagePosition }
                    onChangeSectionEditorState={ this.onChangeSectionEditorState }
                    onPatchRendererComponent={ this.onPatchRendererComponent }
                    pages={ this.props.pages }
                    mediaDatabaseFor={ this.state.mediaDatabaseFor }
                    lang={ this.props.lang } />
                )
              }
              {
                this.state.selectedEditableComponent
                  && !_.isEmpty( this.state.selectedEditableComponent ) 
                  && this.state.selectedEditableComponent.type === "google-map" && (
                    <GoogleMapSettings
                      selectedEditableComponent={ this.state.selectedEditableComponent }
                      selectedSection={ this.props.selectedSection }
                      findComponentAndPatch={ this.findComponentAndPatch }
                      onChangeSectionHOC={ this.props.onChangeSectionHOC }
                      onChangeSectionEditorState={ this.onChangeSectionEditorState }
                      lang={ this.props.lang } />
                  )
              }
              {
                this.props.selectedSection.type === 'countdown' && (
                  <CountdownForm
                    selectedSection={ this.props.selectedSection }
                    onChangeSectionHOC={ this.props.onChangeSectionHOC }
                    onChangeSectionEditorState={ this.onChangeSectionEditorState }
                    showCodeTagEditor={ this.state.showCodeTagEditor }
                    onPatchRendererComponent={ this.onPatchRendererComponent }
                    onUpdateRTEContent={ this.onUpdateRTEContent }
                    selectedLanguageShortname={ this.props.selectedLanguageShortname }
                    lang={ this.props.lang } />
                )
              }
              {
                this.props.selectedSection.type !== 'carousel' && (
                  <ContainerSettings
                    selectedSection={ this.props.selectedSection }
                    onChangeSectionHOC={ this.props.onChangeSectionHOC }
                    selectedContainer={ 
                      this.props.selectedSection.type === 'countdown'
                        ? _.find( this.props.selectedSection.children, item => item.showButton !== undefined ).children[ 0 ].children[ 0 ]
                        : this.state.selectedContainer 
                    }
                    onChangeSectionEditorState={ this.onChangeSectionEditorState }
                    findComponentAndPatch={ this.findComponentAndPatch }
                    selectedEditableComponent={ this.state.selectedEditableComponent }
                    mediaDatabaseFor={ this.state.mediaDatabaseFor }
                    selectedPage={ this.props.selectedPage }
                    pages={ this.props.pages }
                    lang={ this.props.lang }
                    components={ this.props.components }
                    selectedParent={ this.state.selectedParent }  />
                ) 
              }
              {
                this.props.selectedSection.type !== 'carousel' && this.props.selectedSection.type !== 'countdown' && (
                  <Card className="mb-2">
                    <CardHeader>{ Lang[ 'CONTAINER_NAVIGATOR' ][ this.props.lang ] }</CardHeader>
                    <CardBody style={{ height: '35vh', overflowY: 'auto' }}>
                      <ObjectNavigator
                        selectedSection={ this.props.selectedSection }
                        selectedContainer={ this.state.selectedContainer }
                        onChangeSectionEditorState={ this.onChangeSectionEditorState }
                        duplicateComponent={ this.duplicateComponent }
                        reorderComponent={ this.reorderComponent }
                        lang={ this.props.lang } />
                    </CardBody>
                  </Card>
                )
              }
            </Col>
          </Row>
          {
            ( this.state.mediaDatabaseFor === 'backgroundImage' || this.state.mediaDatabaseFor === 'source' ) && (
              <MediaDatabase
                onSelectedImage={( param ) => {
                  let tmp = _.cloneDeep( this.state.selectedEditableComponent )
                  let tmpSelectedSection = _.cloneDeep( this.props.selectedSection )
                  if( this.state.mediaDatabaseFor === 'backgroundImage' ){
                    tmp.style[ this.state.mediaDatabaseFor ] = `url(${ param })`
                  }
                  else{
                    tmp[ 'source' ] = param
                  }
                  this.findComponentAndPatch( tmp, tmpSelectedSection )
                  this.onChangeSectionEditorState( 'selectedEditableComponent', tmp )
                  this.props.onChangeSectionHOC( 'selectedSection', tmpSelectedSection )
                  this.props.toggleMediaDatabase( false )
                }}
                selectedLanguage={ this.props.lang } />
            )
          }
          { this.props.onLoadSection && this.props.onLoadComponents && <LoadingOverlay /> }
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              let tmpSection = _.cloneDeep( this.props.selectedSection )
              if( !_.isEmpty( this.props.selectedHeaderSection )){
                let tmpHeaderSection = _.cloneDeep( this.props.selectedHeaderSection )
                this.findComponentAndPatch( tmpSection, tmpHeaderSection )
                this.props.updateSelectedSection({ id: this.props.selectedSectionID, content: tmpHeaderSection })
              } else {
                this.props.updateSelectedSection({ id: this.props.selectedSectionID, content: tmpSection })
              }
            }}>
            { Lang[ 'SAVE' ][ this.props.lang ] }
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({ data: state })

export default compose(
  connect( mapStateToProps, {toggleMediaDatabase}),
  WithSections,
  WithComponents 
)( SectionEditor )