import React, { useState, useEffect } from 'react'
import {
  Modal, ModalHeader, ModalBody,
  Button, Row, Col, Container,
  UncontrolledTooltip
} from 'reactstrap'
import _ from 'lodash'
import Moment from 'moment'
import Tabs from 'react-responsive-tabs'
import { FaFileDownload } from 'react-icons/fa'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginImagePreview from "filepond-plugin-image-preview"

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'
import WithMessages from './actions'

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
import "filepond/dist/filepond.min.css"
import './index.scss'

registerPlugin( FilePondPluginImagePreview )

const TabsConfig = {
  activeTab: '0',
  showMore: true,
  transform: true,
  showInkBar: true,
  selectedTabKey: 0,
  transformWidth: 400,
}

const MessagesModal = props => {
  const [ tab, updateTab ] = useState( 0 )
  useEffect(() => {
    if( !_.isEmpty( props.selectedOrderRequest ) && props.showMessage ) {
      props.getMessages()
      props.getAttachments()
      if( props.selectedOrderRequest.is_new_message ) {
        Promise.all([
          props.onChangeOrderRequestHOC( 'selectedOrderRequest', {
            ...props.selectedOrderRequest,
            is_new_message: false,
            status_id: props.selectedOrderRequest.status.id,
            digital_order_form_id: props.selectedOrderRequest.digital_order_form.id
          })
        ]).then(() => {
          props.updateOrderRequest()
        })
      }
    }
  }, [ props.showMessage ])
  return (
    <Modal 
      size={ 'xl' }
      isOpen={ props.showMessage }>
      <ModalHeader toggle={() => props.onChangeOrderRequestHOC( 'showMessage', false )}>{ `${ Lang[ 'CHAT_FOR' ][ props.data.languageReducer.lang ] } ${ props.selectedOrderRequest[ 'digital_order_form' ][ 'name' ][ props.data.languageReducer.lang ] }` }</ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col md={ 10 }>
              <div className="chat-feed-container">
              {
                  props.messages.map( item => {
                    if( item.from_user.role === 'Center Admin' ) {
                      return (
                        <div className="chat-block">
                          <div
                            style={{
                              background: '#F2F0F0',
                              borderRadius: '20px',
                              margin: '1px auto',
                              maxWidth: '425px',
                              padding: '8px 14px',
                              width: '-webkit-fit-content',
                              float: 'left'
                            }}>
                            <p style={{ color: '#000000', fontSize: '16px', fontWeight: '300', margin: 0 }}>{ item.text }</p>
                            <p style={{ fontSize: '12px', color: '#383535', marginTop: '10px', marginLeft: 'auto', fontWeight: '600', textAlign: 'right' }}>
                              { `${ `${ item.from_user.first_name } ${ item.from_user.last_name }` } - ${ Moment( item.created_at ).format( "YYYY-MM-DD HH:mm" ).toString() }` }
                            </p>
                          </div>
                        </div>
                      )
                    } else {
                      return (
                        <div className="chat-block" key={ item.id }>
                          <div
                            style={{
                              background: 'rgb(0,132,255)',
                              borderRadius: '20px',
                              margin: '1px auto',
                              maxWidth: '425px',
                              padding: '8px 14px',
                              width: '-webkit-fit-content',
                              float: 'right'
                            }}>
                            <p style={{ color: '#fff', fontSize: '16px', fontWeight: '300', margin: 0 }}>{ item.text }</p>
                            <p style={{ fontSize: '12px', color: '#fff', marginTop: '10px', marginLeft: 'auto', fontWeight: '600', textAlign: 'right' }}>
                              { `${ `${ item.from_user.first_name } ${ item.from_user.last_name }` } - ${ Moment( item.created_at ).format( "YYYY-MM-DD HH:mm" ).toString() }` }
                            </p>
                          </div>
                        </div>
                      )
                    }
                  })
                }
              </div>
            </Col>
            <Col md={ 2 }>
              {
                props.attachments.map( item => {
                  return (
                    <Button 
                      key={ item.id }
                      color="primary"
                      onClick={() => {
                        window.open( item.attachment.url, '_blank' )
                      }}
                      className="w-100">
                      <FaFileDownload className="mr-2" />
                      { item.attachment.filename }
                    </Button>
                  )
                })
              }
            </Col>
          </Row>
          { <style>{ '.RRT__container { padding: 0; } .RRT__panel { opacity: 0; height: 0; padding: 0; }' }</style> }
          <Tabs
            tabsWrapperClass="body-tabs"
            { ...TabsConfig }
            items={[
              { title: 'Text' },
              { title: Lang[ 'UPLOAD_DATA' ][ props.data.languageReducer.lang ] }
            ]}
            selectedTabKey={ tab }
            onChange={ val => updateTab( val ) } />
          {
            tab === 0 && (
              <textarea
                className="chat-input"
                placeholder={ Lang[ 'PLEASE_INPUT_MESSAGE_HERE' ][ props.data.languageReducer.lang ] }
                rows="5"
                value={ props.newMessage.text }
                onChange={ e => {
                  let tmp = _.cloneDeep( props.newMessage )
                  tmp[ 'text' ] = e.target.value
                  props.onChangeMessagesHOC( 'newMessage', tmp )
                }} />
            )
          }
          {
            tab === 1 && (
              <div className="dof-message-upload-tab">
                <style>{ '.dof-message-upload-tab .filepond--root {  margin-bottom: 0; }' }</style>
                <FilePond
                  allowMultiple={ false }
                  allowFileTypeValidation={ true }
                  allowImagePreview={ true }
                  acceptedFileTypes={[ 'application/pdf', 'image/png', 'image/jpeg', 'image/jpg' ]}
                  onaddfile={ ( error, file ) => {
                    const reader = new FileReader()
                    reader.onload = e => {
                      let tmpMedia = {
                        raw: e.target.result,
                        filename: file.file.name
                      }
                      let tmp = _.cloneDeep( props.newAttachment )
                      tmp[ 'attachment' ] = tmpMedia
                      props.onChangeMessagesHOC( 'newAttachment', tmp )
                    }
                    reader.readAsDataURL( file.file )
                  }}
                  onremovefile={ e => {
                    let tmpMedia = {
                      raw: '',
                      filename: ''
                    }
                    let tmp = _.cloneDeep( props.newAttachment )
                    tmp[ 'attachment' ] = tmpMedia
                    props.onChangeMessagesHOC( 'newAttachment', tmp )
                  }} 
                />
                <div>
                  <small><i>{ Lang[ 'SUPPORTED_FILE_TYPES' ][ props.data.languageReducer.lang ] }: pdf, png, jpg and jpeg</i></small>
                </div>
              </div>
            )
          }
          <div className="d-flex">
            <Button 
              color="primary"
              style={{ marginLeft: 'auto' }}
              onClick={() => {
                if( tab === 0 ) {
                  props.createMessage({
                    ...props.newMessage,
                    from_user_id: props.data.profileReducer.profile.id,
                    to_user_id: null,
                    form_result_id: props.selectedOrderRequest.id
                  })
                } else {
                  props.createAttachment({
                    ...props.newAttachment,
                    form_result_id: props.selectedOrderRequest.id
                  })
                  updateTab( 0 )
                }
              }}>
              { Lang[ 'SUBMIT' ][ props.data.languageReducer.lang ] }
            </Button>
            <Button
              id={ `order-request-${props.selectedOrderRequest.id}-send-email` }
              className="mr-2 btn-icon btn-icon-only"
              color="primary"
              onClick={ async () => {
                await props.sendEmailNotifyCenterAdmin( props.selectedOrderRequest )
              }}>
              <i className="pe-7s-mail-open"></i>
            </Button>
            <UncontrolledTooltip
              target={ `order-request-${props.selectedOrderRequest.id}-send-email` }
              placement="top">{ Lang[ 'SEND_EMAIL_TO_CENTER_ADMIN' ][ props.data.languageReducer.lang ] }
            </UncontrolledTooltip>
          </div>
        </Container>
        { props.onLoadMessages && <LoadingOverlay /> }
      </ModalBody>
    </Modal>
  )
}

export default WithMessages( MessagesModal )