import React from 'react'
import { 
  Form, FormGroup, Label, Input, 
  Card, CardHeader, CardBody, CustomInput
} from 'reactstrap'
import _ from 'lodash'
import Moment from 'moment'
import DropdawnSearchSelect from 'components/SearchSelect'
import { FilePond } from 'react-filepond'
import Lang from 'Lang/General'

const ContractForm = ({
  currentContract,
  contractType,
  onChangeAVContractHOC,
  clients,
  clientOptions,
  templates,
  lang
}) => {
  
  const onChangeContractData = ( key, val ) => {
    let tmp = _.cloneDeep( currentContract )
    tmp[ key ] = val
    return onChangeAVContractHOC( contractType, tmp )
  }

  const getValue = () => {
    let tmpFound = _.find( clients, { id: currentContract.client_id } )
    return {
      label: tmpFound ? `${ tmpFound.company_name } ( ${ tmpFound.house_number }, ${ tmpFound.street }, ${ tmpFound.city }, ${ tmpFound.country } )` : '',
      value: tmpFound ? `${ tmpFound.company_name } ( ${ tmpFound.house_number }, ${ tmpFound.street }, ${ tmpFound.city }, ${ tmpFound.country } )` : ''
    }
  }

  return (
    <Form onSubmit={ e => e.preventDefault() }>
      <FormGroup>
        <Label>{ Lang[ 'CLIENTS' ][ lang ] }</Label>
        <DropdawnSearchSelect
          options={ clientOptions }
          onChange={ val => onChangeContractData( 'client_id', val.id ) }
          value={ getValue() }
          isDisabled={ contractType === 'selectedContract' }
          placeholder={ Lang[ 'CLIENT' ][ lang ] } />
      </FormGroup>
      {
        contractType === 'newContract' && (
          <FormGroup>
            <Label>{ Lang[ 'TYPE_OF_CONTRACT' ][ lang ] }</Label>
            <CustomInput
              id='old-contract'
              type='radio'
              label={ Lang[ 'OLD_CONTRACT' ][ lang ] }
              checked={ !currentContract.is_new_contract }
              onChange={ e => onChangeContractData( 'is_new_contract', !currentContract.is_new_contract ) }/>
            <CustomInput
              id='new-contract'
              type='radio'
              label={ Lang[ 'NEW_CONTRACT' ][ lang ] }
              checked={ currentContract.is_new_contract }
              onChange={ e => onChangeContractData( 'is_new_contract', !currentContract.is_new_contract ) }/>
          </FormGroup>
        )
      }
      {
        contractType === 'selectedContract' && <p>{ Lang[ 'TYPE' ][ lang ] } : { currentContract.is_new_contract ? Lang[ 'NEW_CONTRACT' ][ lang ] : Lang[ 'OLD_CONTRACT' ][ lang ] }</p>
      }
      {
        currentContract.is_new_contract !== null && currentContract.is_new_contract && (
          <>
            <FormGroup>
              <Label>{ Lang[ 'TEMPLATES' ][ lang ] }</Label>
              <Input
                type="select"
                value={ currentContract.gdpr_template_id }
                onChange={ e => onChangeContractData( 'gdpr_template_id', parseInt( e.target.value ) ) }>
                <option value={ '' }>{ Lang[ 'SELECT_PLACEHOLDER' ][ lang ] }</option>
                {
                  templates && templates.length > 0 && templates.map( item => {
                    return(
                      <option key={ item.id } value={ item.id }>{ item.name[ lang ] } - { Lang[ 'VERSION' ][ lang ] } { item.version }</option>
                    )
                  })
                }
              </Input>
            </FormGroup>
            <Card className="my-4">
              <CardHeader>{ Lang[ 'AV_CONTRACT_INFO' ][ lang ] }</CardHeader>
              <CardBody>
                <FormGroup>
                  <Label>{ Lang[ 'COMPANY_NAME_ADDRESS' ][ lang ] }</Label>
                  <Input
                    type="text"
                    value={ currentContract.variable_fields.company_name_address }
                    onChange={ e => {
                      let tmp = _.cloneDeep( currentContract.variable_fields )
                      tmp.company_name_address = e.target.value
                      onChangeContractData( 'variable_fields', tmp ) 
                    }} />
                </FormGroup>
                <FormGroup>
                  <Label>{ Lang[ 'AUTH_PERSON' ][ lang ] }</Label>
                  <Input
                    type="text"
                    value={ currentContract.variable_fields.auth_person }
                    onChange={ e => {
                      let tmp = _.cloneDeep( currentContract.variable_fields )
                      tmp.auth_person = e.target.value
                      onChangeContractData( 'variable_fields', tmp ) 
                    }} />
                </FormGroup>
                <FormGroup>
                  <Label>{ Lang[ 'AGREEMENT_NUMBER' ][ lang ] }</Label>
                  <Input
                    type="text"
                    value={ currentContract.variable_fields.agreement_number }
                    onChange={ e => {
                      let tmp = _.cloneDeep( currentContract.variable_fields )
                      tmp.agreement_number = e.target.value
                      onChangeContractData( 'variable_fields', tmp ) 
                    }} />
                </FormGroup>
              </CardBody>
            </Card>
          </>
        ) 
      }
      {
        currentContract.is_new_contract !== null && !currentContract.is_new_contract && (
          <>
            <FormGroup>
              <Label>{ Lang[ 'UPLOAD_OLD_CONTRACT_PDF' ][ lang ] }</Label>
              {
                !_.isEmpty( currentContract.contract_pdf_url ) && (
                  <div className="mb-3" >
                    <Input
                      disabled
                      value={ currentContract.contract_pdf_url } />
                  </div>
                )
              }
              <FilePond
                allowMultiple={ false }
                allowFileTypeValidation={ true }
                allowImagePreview={ true }
                acceptedFileTypes={[ 'application/pdf' ]}
                onaddfile={ ( error, file ) => {
                  const reader = new FileReader()
                  reader.onload = e => onChangeContractData( 'contract_pdf_base64', e.target.result )
                  reader.readAsDataURL( file.file )
                }}
                onremovefile={ e => onChangeContractData( 'contract_pdf_base64', '' )} />
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'SIGNED_DATE' ][ lang ] }</Label>
              <Input
                type='date'
                value={ Moment( currentContract.agreed_datetime ).format( 'YYYY-MM-DD' ) }
                onChange={ e => onChangeContractData( 'agreed_datetime', Moment( e.target.value ).format() ) } />
            </FormGroup>
          </>
        )
      }
    </Form>
  )
}

export default ContractForm 
