import React from 'react'

import Lang from 'Lang/General'

const WebForm = ({
  id,
  item,
  selectedLanguageShortname,
  mode,
  onChangeSectionEditorState,
  selectedEditableComponent,
  hoveredComponentId
}) => {
  return(
    <div
      id={ id }
      onMouseEnter={ () => mode === 'edit' && onChangeSectionEditorState && onChangeSectionEditorState( 'hoveredComponentId', id ) } 
      style={{
        display: 'flex',
        width: '100%',
        minHeight: '50vh',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        ...(
          ( ( selectedEditableComponent && selectedEditableComponent.id === id ) || hoveredComponentId === id  ) 
            ? { 
              borderTop: "5px solid #45818e",
              borderRight: "5px solid #45818e",
              borderBottom: "5px solid #45818e",
              borderLeft: "5px solid #45818e"
            } : {}
        )
      }}
      onClick={ () => {
        if( mode === 'edit' ){
          onChangeSectionEditorState && onChangeSectionEditorState( 'selectedEditableComponent', item )
        }
      }}>
      <span>{ Lang[ 'ADD_A_FORM' ][ selectedLanguageShortname ] }</span>
    </div>
  )
}

export default WebForm
