import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { Post, Get, Put, Delete } from 'utils/axios'
import Lang from 'Lang/General'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state={ 
      loading: false,
      onDemandCategories: [],
      newOnDemandCategory: {},
      selectedOnDemandCategory: {},
      showCreateOnDemandCategory: false,
      showUpdateOnDemandCategory: false,
      showDeleteOnDemandCategoryId: 0
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( err => toast.error( err.replaceAll( '_', ' ' ) ) )

    onChangeOnDemandCategoriesHOC = ( key, value ) => this.setState({ [ key ] : value })

    createOnDemandCategory = data => Post(
      `/api/v1/ark_member_area/am_ondemand_categories`,
      data,
      this.createOnDemandCategorySuccess,
      this.createOnDemandCategoryError,
      this.load
    )
    createOnDemandCategorySuccess = () => {
      this.getOnDemandCategories()
      this.requestSuccess( Lang[ 'ON_DEMAND_CATEGORY_IS_SUCCESSFULLY_CREATED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showCreateOnDemandCategory: false })
    }
    createOnDemandCategoryError = error => {
      this.requestError( error )
      this.setState({ showCreateOnDemandCategory: false })
    }

    getOnDemandCategories = () => Get(
      `/api/v1/ark_member_area/am_ondemand_categories`,
      this.getOnDemandCategoriesSuccess,
      this.getOnDemandCategoriesError,
      this.load
    )
    getOnDemandCategoriesSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      this.setState({ onDemandCategories: payload, onDemandCategoriesPages: tmpTotalPages })
    }
    getOnDemandCategoriesError = error => this.requestError( error )

    getSelectedOnDemandCategory = onDemandCategory_id => Get(
      `/api/v1/ark_member_area/am_ondemand_categories/${ onDemandCategory_id }`,
      this.getSelectedOnDemandCategorySuccess,
      this.getSelectedOnDemandCategoryError,
      this.load
    )
    getSelectedOnDemandCategorySuccess = payload => this.setState({ selectedOnDemandCategory: payload, showUpdateOnDemandCategory: true })
    getSelectedOnDemandCategoryError = error => this.requestError( error )

    updateOnDemandCategory = data => Put(
      `/api/v1/ark_member_area/am_ondemand_categories/${ data.id }`,
      data,
      this.updateOnDemandCategorySuccess,
      this.updateOnDemandCategoryError,
      this.load
    )
    updateOnDemandCategorySuccess = () => {
      this.getOnDemandCategories()
      this.requestSuccess( Lang[ 'ON_DEMAND_CATEGORY_IS_SUCCESSFULLY_UPDATED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showUpdateOnDemandCategory: false })
    }
    updateOnDemandCategoryError = error => this.requestError( error )

    removeOnDemandCategory = onDemandCategory_id => Delete(
      `/api/v1/ark_member_area/am_ondemand_categories/${ onDemandCategory_id }`,
      this.removeOnDemandCategorySuccess,
      this.removeOnDemandCategoryError,
      this.load
    )
    removeOnDemandCategorySuccess = () => {
      this.getOnDemandCategories()
      this.requestSuccess( Lang[ 'ON_DEMAND_CATEGORY_IS_SUCCESSFULLY_REMOVED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showDeleteOnDemandCategoryId: 0 })
    }
    removeOnDemandCategoryError = error => {
      this.requestError( error )
      this.setState({ showDeleteOnDemandCategoryId: 0 })
    }

    render = () => {
      return(
        <WrappedComponent
          { ...this.props } 
          onLoadOnDemandCategoriesHOC={ this.state.loading }
          onDemandCategories={ this.state.onDemandCategories }
          newOnDemandCategory={ this.state.newOnDemandCategory }
          selectedOnDemandCategory={ this.state.selectedOnDemandCategory }
          showCreateOnDemandCategory={ this.state.showCreateOnDemandCategory }
          showUpdateOnDemandCategory={ this.state.showUpdateOnDemandCategory }
          showDeleteOnDemandCategoryId={ this.state.showDeleteOnDemandCategoryId }
          onChangeOnDemandCategoriesHOC={ this.onChangeOnDemandCategoriesHOC }
          createOnDemandCategory={ this.createOnDemandCategory }
          getOnDemandCategories={ this.getOnDemandCategories }
          getSelectedOnDemandCategory={ this.getSelectedOnDemandCategory }
          updateOnDemandCategory={ this.updateOnDemandCategory }
          removeOnDemandCategory={ this.removeOnDemandCategory }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC