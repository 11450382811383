
import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get, Post, Put, Delete } from 'utils/axios-template'
import { production_url } from '../../TemplateEditor/config'

import Lang from 'Lang/General'

const CategoryHOC = ( WrappedComponent ) => {
  class CategoryWrappedComponent extends Component{
    state = {
      loading: false,
      categories: [],
      newCategory: {
        name: {
          "en": "",
          "de": "",
          "nl": "",
          "it": ""
        }
      },
      selectedCategory: {},
      categoryMode: '',
      categoryForSelect: [],
      errorMessage: '',
      showCategoryModal: false,
      showDeleteModal: false
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    handleCategoryModal = ( param, mode ) => this.setState({ 
      showCategoryModal: param, 
      categoryMode: mode,
      selectedCategory: {},
      errorMessage: ''
    })

    onChangeCategoryData = ( key, value ) => {
      let tmp = {}
      if ( this.state.selectedCategory.name )
      {
        tmp = _.cloneDeep( this.state.selectedCategory )
        tmp[ key ] = value
        this.setState({ selectedCategory: tmp })
      }
      else {
        tmp = _.cloneDeep( this.state.newCategory )
        tmp[ key ] = value
        this.setState({ newCategory: tmp })
      }
    }

    onChangeCategoryHOC = ( key, value ) => this.setState({ [ key ]: value })

    getCategories = () => Get(
      `${ production_url }/categories`,
      this.getCategoriesSuccess,
      this.getCategoriesError,
      this.load
    )
    getCategoriesSuccess = payload => {
      let data = []
      if ( payload && payload.length > 0 ) {
        payload.map( option => {
          let tmp = {
            id: option.id,
            key: option.id,
            text: option.name[ this.props.data.languageReducer.lang ],
            value: option.id
          }
          data.push( tmp )
        })
      }
      else {
        this.requestError( Lang[ 'NO_RESULT_FOUND' ][ this.props.data.languageReducer.lang ] )
      }
      this.setState({ categories: payload, categoryForSelect: data })
    }
    getCategoriesError = error => this.requestError( error )

    getSelectedCategory = id => Get(
      `${ production_url }/categories/${ id }`,
      this.getSelectedCategorySuccess,
      this.getSelectedCategoryError,
      this.load
    )
    getSelectedCategorySuccess = payload => this.setState({ selectedCategory: payload })
    getSelectedCategoryError = error => this.requestError( error )

    postCategory = data => Post(
      `${ production_url }/categories`,
      data,
      this.postCategorySuccess,
      this.postCategoryError,
      this.load
    )
    postCategorySuccess = payload => {
      this.requestSuccess( Lang[ 'CATEGORY_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.handleCategoryModal( false )
      this.setState({
        newCategory: {
          name: ''
        }
      })
      this.getCategories()
    }
    postCategoryError = error => this.setState({ errorMessage: Lang[ 'CATEGORY_NAME_VALIDATION' ][ this.props.data.languageReducer.lang ] }, () => this.requestError( 'Create category failed' ) )

    updateCategory = (id, data) => Put(
      `${ production_url }/categories/${ id }`,
      data,
      this.updateCategorySuccess,
      this.updateCategoryError,
      this.load
    )
    updateCategorySuccess = payload => {
      this.requestSuccess( Lang[ 'CATEGORY_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getCategories()
      this.handleCategoryModal( false )
    }
    updateCategoryError = error => this.setState({ errorMessage: Lang[ 'CATEGORY_NAME_VALIDATION' ][ this.props.data.languageReducer.lang ] }, () => this.requestError( 'Update category failed' ) )

    deleteCategory = id => Delete(
      `${ production_url }/categories/${ id }`,
      this.deleteCategorySuccess,
      this.deleteCategoryError,
      this.load
    )
    deleteCategorySuccess = payload => {
      this.requestSuccess( Lang[ 'CATEGORY_REMOVE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getCategories()
    }
    deleteCategoryError = error => this.requestError( error )

    render = () => {
      return(
        <>
          <WrappedComponent
            { ...this.props }
            onLoadCategory={ this.state.loading }
            categories={ this.state.categories }
            handleCategoryModal={ this.handleCategoryModal }
            getCategories={ this.getCategories }
            postCategory={ this.postCategory }
            newCategory={ this.state.newCategory }
            onChangeCategoryData={ this.onChangeCategoryData }
            selectedCategory={ this.state.selectedCategory }
            getSelectedCategory={ this.getSelectedCategory }
            updateCategory={ this.updateCategory }
            showCategoryModal={ this.state.showCategoryModal }
            mode={ this.state.categoryMode }
            onChangeCategoryHOC={ this.onChangeCategoryHOC }
            deleteCategory={ this.deleteCategory }
            showDeleteModal={ this.state.showDeleteModal }
            categoryForSelect={ this.state.categoryForSelect }
            errorMessage={ this.state.errorMessage } />
        </>
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( CategoryWrappedComponent )
}

export default CategoryHOC
