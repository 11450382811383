import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get, Post, outdatedToken, Put } from 'utils/axios'
import { getRefreshToken } from 'actions/token'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const SystemMailingHOC = ( WrappedComponent ) => {
  class SystemMailingsWrappedComponent extends Component{
    state = {
      loading: false,
      systemMailings: [],
      totalPagesSystemMailing: [],
      showCreateMailing: false,
      newMailingData: {
        title: '',
        content: '',
        json_content: {},
        subject: '',
        status: 'ACTIVE'
      },
      mailings: [],
      selectedSystemMailing: {
        title: '',
        content: '',
        json_content: {},
        subject: '',
        status: 'ACTIVE'
      },
      title: '',
      mailingErrMsg: {},
      showArchivedMailings: false,
      showArchiveSelectedMailing: false
    }

    onChangeSystemMailingHOC = ( key, value ) => this.setState({ [key]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    ajaxError = ( error ) => {
      outdatedToken( error, this.props.history )
      this.requestError( error )
    }

    getSystemMailing = ( query ) => Get(
      `/api/v1/system_mailings?query=${ query }`,
      this.getSystemMailingSuccess,
      this.getSystemMailingError,
      this.load
    )
    getSystemMailingSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      } else {
        toast.error( Lang['NO_RESULT_FOUND'][ this.props.data.languageReducer.lang ] )
      }

      this.setState({ systemMailings: payload, totalPagesSystemMailing: tmpTotalPages })
    }
    getSystemMailingError = error => this.requestError(error)

    createSystemMailing = data => Post(
      `/api/v1/system_mailings`,
      data,
      this.createSystemMailingSuccess,
      this.createSystemMailingError,
      this.load
    )
    createSystemMailingSuccess = payload => {
      this.requestSuccess( Lang['CREATE_SUCCESS_SYSTEM_MAILING'][this.props.data.languageReducer.lang])
      this.getSystemMailing(
        convertObjToBase64({ 
          is_paginated: true, 
          page: 1,
          filter: {
            status: { $neq: 'ARCHIVED' }
          }
        })
      )
      this.setState({ showCreateMailing: false })
    }
    createSystemMailingError = error => {
      this.requestError(error)
      this.setState({ mailingErrMsg: error })
    }

    getSelectedSystemMailing = ( id, isArchiving ) => Get(
      `/api/v1/system_mailings/${ id }`,
      payload => this.getSelectedSystemMailingSuccess( payload, isArchiving ),
      this.getSelectedSystemMailingError,
      this.load
    )
    getSelectedSystemMailingSuccess = ( payload, isArchiving ) => this.setState({ 
      selectedSystemMailing: payload,
      showArchiveSelectedMailing: isArchiving
    })
    getSelectedSystemMailingError = error => this.requestError( error )

    updateSystemMailing = ( data, id ) => Put(
      `/api/v1/system_mailings/${ id }`,
      data,
      this.updateSystemMailingSuccess,
      this.updateSystemMailingError,
      this.load
    )
    updateSystemMailingSuccess = () => {
      this.requestSuccess( Lang['UPDATE_SUCCESS_SYSTEM_MAILING'][ this.props.data.languageReducer.lang ])
      this.setState({ showUpdateSystemMailing: false, showArchiveSelectedMailing: false })
      this.getSystemMailing( 
        convertObjToBase64({ 
          is_paginated: true, 
          page: 1,
          filter: {
            status: this.state.showArchivedMailings
              ? 'ARCHIVED'
              : { $neq: 'ARCHIVED' }
          }
        }) 
      )
    }
    updateSystemMailingError = error => {
      this.requestError( error )
      this.setState({ mailingErrMsg: error })
    }

    render = () => {
      return(
        <>
          <WrappedComponent
            { ...this.props }
            onLoadSystemMailings={ this.state.loading }
            systemMailings={ this.state.systemMailings }
            totalPagesSystemMailing={ this.state.totalPagesSystemMailing }
            onChangeSystemMailingHOC={ this.onChangeSystemMailingHOC }
            showCreateMailing={ this.state.showCreateMailing }
            newMailingData={ this.state.newMailingData }
            getSystemMailing={ this.getSystemMailing }
            getSelectedSystemMailing={ this.getSelectedSystemMailing }
            showUpdateSystemMailing={ this.state.showUpdateSystemMailing }
            createSystemMailing={ this.createSystemMailing }
            selectedSystemMailing={ this.state.selectedSystemMailing }
            updateSystemMailing={ this.updateSystemMailing }
            title={ this.state.title }
            mailingErrMsg={ this.state.mailingErrMsg }
            showArchivedMailings={ this.state.showArchivedMailings }
            showArchiveSelectedMailing={ this.state.showArchiveSelectedMailing }
          />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    getRefreshToken
  } )( SystemMailingsWrappedComponent )
}

export default SystemMailingHOC
