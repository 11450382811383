export const countryOptions = [
  { 
    id: 'AUT',
    label: 'Austria',
    value: 'Austria'
  },
  { 
    id: 'BEL',
    label: 'Belgium',
    value: 'Belgium'
  } ,
  { 
    id: 'FRA',
    label: 'France',
    value: 'France'
  },
  { 
    id: 'DEU',
    label: 'Germany',
    value: 'Germany'
  },
  { 
    id: 'ITA',
    label: 'Italy',
    value: 'Italy'
  },
  { 
    id: 'NLD',
    label: 'Netherlands',
    value: 'Netherlands'
  } ,
  { 
    id: 'ESP',
    label: 'Spain',
    value: 'Spain'
  } ,
  { 
    id: 'CHE',
    label: 'Switzerland',
    value: 'Switzerland'
  }
]