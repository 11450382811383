import Axios from 'axios'

import { convertObjToBase64 } from 'utils/objToBase64'

const CryptoJS = require("crypto-js");
const Ciphertext = CryptoJS.AES.encrypt(convertObjToBase64({
  'system': 'project-ark'
}), 'wH_u%%P5~Sl?:J5A0e^~"4sCmgn@lD>*').toString()
// console.log( Ciphertext, 'Ciphertext' )
// const Decrypted = CryptoJS.AES.decrypt( Ciphertext, 'wH_u%%P5~Sl?:J5A0e^~"4sCmgn@lD>*' )
// const OriginalText = Decrypted.toString(CryptoJS.enc.Utf8)
// console.log(OriginalText, 'OriginalText')

export const Get = ( url, response, error, load ) => {
  load( true )
  return Axios.get( `${ url }`, {
    headers: {
      'Authorization': Ciphertext
    }
  }).then( res => {
    response( res.data )
    load( false )
  }).catch( err => {
    if( err && err.response && err.response.status ) {
      let tmp = err.response.status
      if( tmp === 422 || tmp === 401 ) {
        error( err.response.data.message )
      }
    } else if( err && err.message ) {
      error( err.message )
    } else {
      error( 'You are disconnnected from the internet, please reconnect to use Ark. If problem persists, please contact the system admin.' )
    }
    load( false )
  })
}

export const Post = ( url, data, response, error, load ) => {
  load( true )
  return Axios.post( `${ url }`, data, {
    headers: {
      'Authorization': Ciphertext
    }
  }).then( res => {
    response( res.data )
    load( false )
  }).catch( err => {
    if( err && err.response && err.response.status ) {
      let tmp = err.response.status
      if( tmp === 422 || tmp === 401 ) {
        error( err.response.data.Errors )
      }
    } else if( err ) {
      error( err.response.data.message )
    } else {
      error( 'You are disconnnected from the internet, please reconnect to use Ark. If problem persists, please contact the system admin.' )
    }
    load( false )
  })
}

export const Put = ( url, data, response, error, load ) => {
  load( true )
  return Axios.put( `${ url }`, data, {
    headers: {
      'Authorization': Ciphertext
    }
  }).then( res => {
    response( res.data )
    load( false )
  }).catch( err => {
    if( err && err.response && err.response.status ) {
      let tmp = err.response.status
      if( tmp === 422 || tmp === 401 ) {
        error( err.response.data.message )
      }
    } else if( err ) {
      error( err.message )
    } else {
      error( 'You are disconnnected from the internet, please reconnect to use Ark. If problem persists, please contact the system admin.' )
    }
    load( false )
  })
}

export const Delete = ( url, response, error, load ) => {
  load( true )
  return Axios.delete( `${ url }`, {
    headers: {
      'Authorization': Ciphertext
    }
  }).then( res => {
    response( res.data )
    load( false )
  }).catch( err => {
    if( err && err.response && err.response.status ) {
      let tmp = err.response.status
      if( tmp === 422 || tmp === 401 ) {
        error( err.response.data.message )
      }
    } else if( err ) {
      error( err.message )
    } else {
      error( 'You are disconnnected from the internet, please reconnect to use Ark. If problem persists, please contact the system admin.' )
    }
    load( false )
  })
}
