import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import StatusModal from 'components/Modal/status'

import { Get, Post, outdatedToken, Put, Delete } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

const CenterGroupHOC = ( WrappedComponent ) => {
  class CenterGroupWrappedComponent extends Component {
    state = {
      centerGroups: {},
      archivedCenterGroup: {},
      selectedCenterGroup: {
        name: '',
        description: '',
        status: ''
      },
      newCenterGroupData: {
        name: '',
        description: '',
        status: 'ACTIVE'
      },
      showNewCenterGroupModal: false,
      showViewCenterGroupModal: false,
      showEditCenterGroupModal: false,
      showDeleteConfirmation: false,
      showArchivedCenterGroupModal: false,
      loading: false,
      showStatusModal: false,
      statusModalType: '',
      onClickStatusModalButton: () => {},
      statusModalMessage: '',
      centerGroupForSelect: [],
      errorMessage: {},
      centerGroupTotalPage: [],
      archivedCenGrpTotalPage: [],
      toArchiveCenterGroupId: 0,
      unarchiveCenterGroupID: 0,
      confirmUnarchiveModal: false
    }

    onChangeCenterGroupHOC = ( key, value ) => this.setState({ [key]: value })

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showStatusModal: true,
      statusModalType: 'negative',
      onClickStatusModalButton: () => this.setState({ showStatusModal: false }),
      statusModalMessage: error
    })
    requestSuccess = success => this.setState({
      showStatusModal: true,
      statusModalType: 'positive',
      onClickStatusModalButton: () => this.setState({
        showStatusModal: false,
        showNewCenterGroupModal: false,
        showArchivedCenterGroupModal: false,
        showEditCenterGroupModal: false,
        newCenterGroupData: {
          name: '',
          description: '',
          status: 'ACTIVE'
        },
        errorMessage: {}
      }),
      statusModalMessage: success
    })
    ajaxError = ( error ) => {
      outdatedToken( error, this.props.history )
      this.requestError( error )
    }

    getCenterGroups = query => {
      query = {
        ...query,
        filter: {
          $or: [
            { status: 'ACTIVE' },
            { status: 'INACTIVE' }
          ]
        }
      }

      Get(
        `/api/v1/center_groups?query=${ convertObjToBase64( query ) }`,
        this.getCenterGroupsSuccess,
        this.getCenterGroupsError,
        this.load
      )
    }
    getCenterGroupsSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data.length > 0 ){
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }else{
        this.ajaxError( Lang[ 'NO_RESULT_FOUND' ][ this.props.data.languageReducer.lang ] )
      }
      this.setState({ centerGroups: payload, centerGroupTotalPage: tmpTotalPages })
    }
    getCenterGroupsError = error => this.ajaxError( error )

    getCenterGroupsForSelect = query => {
      query = {
        ...query,
        filter: {
          status: 'ACTIVE'
        }
      }
      Get(
        `/api/v1/center_groups?query=${ convertObjToBase64( query ) }`,
        this.getCenterGroupsForSelectSuccess,
        this.getCenterGroupsForSelectError,
        this.load
      )
    }
    getCenterGroupsForSelectSuccess = payload => {
      if( payload.data && payload.data.length > 0 ) {
        let data = []
        payload.data.map( option => {
          let tmp = {
            id: option.id,
            key: option.id,
            text: option.name,
            label: option.name,
            value: option.id,
            slug: option.slug
          }
          data.push( tmp )
        })
        return this.setState({ centerGroupForSelect: data })
      }
    }

    getCenterGroupsForSelectError = error => this.ajaxError( error )

    getSelectedCenterGroupView = id => this.getSelectedCenterGroup( id, 'view' )
    getSelectedCenterGroupEdit = id => this.getSelectedCenterGroup( id, 'edit' )
    getSelectedCenterGroup = (id, type) => Get(
      `/api/v1/center_groups/${ id }`,
      ( payload ) => this.getSelectedCenterGroupSuccess( payload, type ),
      this.getSelectedCenterGroupError,
      this.load
    )
    getSelectedCenterGroupSuccess = (payload, type) => this.setState({ selectedCenterGroup: payload }, () => {
      type === 'view'
        ? this.onChangeCenterGroupHOC( 'showViewCenterGroupModal', true )
        : this.onChangeCenterGroupHOC( 'showEditCenterGroupModal', true )
    })
    getSelectedCenterGroupError = error => this.ajaxError( error )

    postCenterGroups = () => Post(
      `/api/v1/center_groups`,
      this.state.newCenterGroupData,
      this.postCenterGroupsSuccess,
      this.postCenterGroupsError,
      this.load
    )
    postCenterGroupsSuccess = payload => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'CENTER_GROUP_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getCenterGroups( tmp )
    }
    postCenterGroupsError = error => this.setState({ errorMessage: error })

    updateCenterGroup = id => {
      let tmp = _.cloneDeep( this.state.selectedCenterGroup )
      delete tmp.created_at
      Put(
        `/api/v1/center_groups/${ id }`,
        tmp,
        this.updateCenterGroupSuccess,
        this.updateCenterGroupError,
        this.load
      )
    }
    updateCenterGroupSuccess = payload => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'CENTER_GROUP_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getCenterGroups( tmp )
    }
    updateCenterGroupError = error => this.setState({ errorMessage: error })

    updateCenterGroupStatus = ( id, status ) => Put(
      `/api/v1/center_groups/${ id }`,
      { status },
      this.updateCenterGroupStatusSuccess,
      this.updateCenterGroupStatusError,
      this.load
    )
    updateCenterGroupStatusSuccess = payload => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'CENTER_GROUP_STATUS_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getCenterGroups( tmp )
      this.getArchivedCenterGroup( tmp )
    }
    updateCenterGroupStatusError = error => {
      this.setState({ errorMessage: error })
      this.requestError( Lang[ 'CENTER_GROUP_STATUS_UPDATE_FAILED' ][ this.props.data.languageReducer.lang ] )
    }

    deleteCenterGroup = id => Delete(
      `/api/v1/center_groups/${ id }`,
      this.deleteCenterGroupSuccess,
      this.deleteCenterGroupError,
      this.load
    )
    deleteCenterGroupSuccess = payload => {
      this.requestSuccess( Lang[ 'CENTER_GROUP_REMOVE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.onChangeCenterGroupHOC( 'showDeleteConfirmation', false )
      this.getCenterGroups()
    }
    deleteCenterGroupError = error => {
      this.setState({ errorMessage: error })
      this.requestError( Lang[ 'CENTER_GROUP_REMOVE_FAILED' ][ this.props.data.languageReducer.lang ] )
    }

    getArchivedCenterGroup = ( query, type, searchParams, searchVal ) => {
      query = {
        ...query,
        filter: {
          status: 'ARCHIVED'
        }
      }
      if( type === 'Normal Search' ){
        query = {
          ...query,
          filter: {
            name: {
              $like: `%${ searchVal }%`
            },
            status: 'ARCHIVED'
          }
        }
      }
      if( type === 'Advanced Search' ){
        let tmp = {}
        searchParams.map( item => {
          tmp[ item.val ] = {
            $like: `%${item.searchValue}%`
          }
        })
        query = {
          ...query,
          filter: {
            ...tmp,
            status: 'ARCHIVED'
          }
        }
      }
      Get(
        `/api/v1/center_groups?query=${ convertObjToBase64( query ) }`,
        this.getArchivedCenterGroupsSuccess,
        this.getArchivedCenterGroupsError,
        this.load
      )
    }
    getArchivedCenterGroupsSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data.length > 0 ){
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }else{
        this.ajaxError( Lang[ 'NO_RESULT_FOUND' ][ this.props.data.languageReducer.lang ] )
      }
      this.setState({ archivedCenterGroup: payload, archivedCenGrpTotalPage: tmpTotalPages })
    }
    getArchivedCenterGroupsError = error => this.ajaxError( error )

    getCenterGroupsSearch = ( query, type, searchParams, searchVal ) => {

      if( type === 'Normal Search' ){
        query = {
          ...query,
          filter: {
            name: {
              $like: `%${ searchVal }%`
            },
            $or: [
              { status: 'ACTIVE' },
              { status: 'INACTIVE' }
            ]
          }
        }
      }
      if( type === 'Advanced Search' ){
        let tmp = {}
        searchParams.map( item => {
          tmp[ item.val ] = {
            $like: `%${item.searchValue}%`
          }
          item.val === 'status' && (
            tmp[ item.val ] = item.searchValue
          )
        })
        query = {
          ...query,
          filter: {
            ...tmp,
            $or: [
              { status: 'ACTIVE' },
              { status: 'INACTIVE' }
            ]
          }
        }
      }
      Get(
        `/api/v1/center_groups?query=${ convertObjToBase64( query ) }`,
        this.getCenterGroupsSearchSuccess,
        this.getCenterGroupsSearchError,
        this.load
      )
    }
    getCenterGroupsSearchSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }else{
        this.requestError( Lang[ 'NO_RESULT_FOUND' ][ this.props.data.languageReducer.lang ] )
      }
      this.setState({ centerGroups: payload, centerGroupTotalPage: tmpTotalPages })
    }
    getCenterGroupsSearchError = error => this.ajaxError( error )

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            { ...this.props }
            centerGroups={ this.state.centerGroups }
            newCenterGroupData={ this.state.newCenterGroupData }
            selectedCenterGroup={ this.state.selectedCenterGroup }
            getCenterGroups={ this.getCenterGroups }
            getSelectedCenterGroupView={ this.getSelectedCenterGroupView }
            getSelectedCenterGroupEdit={ this.getSelectedCenterGroupEdit }
            onLoadCenterGroups={ this.state.loading }
            postCenterGroups={ this.postCenterGroups }
            updateCenterGroup={ this.updateCenterGroup }
            deleteCenterGroup={ this.deleteCenterGroup }
            showNewCenterGroupModal={ this.state.showNewCenterGroupModal }
            showViewCenterGroupModal={ this.state.showViewCenterGroupModal }
            showEditCenterGroupModal={ this.state.showEditCenterGroupModal }
            showDeleteConfirmation={ this.state.showDeleteConfirmation }
            getCenterGroupsForSelect={ this.getCenterGroupsForSelect }
            centerGroupForSelect={ this.state.centerGroupForSelect }
            getArchivedCenterGroup={ this.getArchivedCenterGroup }
            getSelectedCenterGroupArchived={ this.getSelectedCenterGroupArchived }
            updateCenterGroupStatus={ this.updateCenterGroupStatus }
            errorMessage={ this.state.errorMessage }
            getCenterGroupsSearch={ this.getCenterGroupsSearch }
            showArchivedCenterGroupModal={ this.state.showArchivedCenterGroupModal }
            archivedCenterGroup={ this.state.archivedCenterGroup }
            onChangeCenterGroupHOC={ this.onChangeCenterGroupHOC }
            centerGroupTotalPage={ this.state.centerGroupTotalPage }
            archivedCenGrpTotalPage={ this.state.archivedCenGrpTotalPage }
            toArchiveCenterGroupId={ this.state.toArchiveCenterGroupId }
            confirmUnarchiveModal={ this.state.confirmUnarchiveModal }
            unarchiveCenterGroupID={ this.state.unarchiveCenterGroupID }/>
          <StatusModal
            isOpen={ this.state.showStatusModal }
            type={ this.state.statusModalType }
            message={ this.state.statusModalMessage }
            onClick={ this.state.onClickStatusModalButton } />
        </Fragment>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( CenterGroupWrappedComponent )
}

export default CenterGroupHOC
