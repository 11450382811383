import React from 'react'
import { FormGroup } from 'reactstrap'

const ImageComponent = ({
  content
}) => {
  return (
    <FormGroup style={{ padding: "15px", marginBottom: "0" }} >
      <div style={{ padding: content.padding }}>
        <img src={ content.url } style={{ width: content.width, height: content.height }} />
      </div>
    </FormGroup>
  )
}

export default ImageComponent