import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import PromptModal from 'components/Indicator/Prompt'

import { Get, Post, Put, Delete } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      userProfile:{},
      submittedForms: [],
      centerDigitalOrderForms: [],
      showFillSubmittedForm: false,
      showViewSubmittedForm: false,
      selectedSubmittedForm: {},
      digitalOrderForms: [],
      showFormBuilder: false,
      showInfoModal: false,
      showUpdateModal: false,
      showViewFormModal: false,
      showArchivedForms: false,
      showViewModalResults: false,
      showUpdatePageSettingsModal: false,
      selectedDigitalOrderForm: {},
      selectedDigitalOrderFormResults: {},
      selectedOrderRequest: {},
      selectedFormResultsTotalPage: [],
      selectedFormToCopy: {},
      uploadedMediaUrl: [],
      showPromptModal: false,
      toRemoveID: '',
      formsTotalPages: [],
      allFormCategories: [],
      searchParams: {
        name: '',
        categoryId: -1
      }
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error !== 'string' 
      ? Object.values( error ).map( val => toast.error( val ) )
      : toast.error( error )

    onChangeDigitalOrderFormHOC = ( key, val ) => this.setState({ [ key ]: val })

    getUserProfile = () => Get(
      `/api/v1/profiles/me`,
      this.getUserProfileSuccess,
      this.getUserProfileError,
      this.load
    )
    getUserProfileSuccess = payload => this.setState({ userProfile: payload })
    getUserProfileError = error => this.requestError( error )

    getDigitalOrderForms = query => Get(
      `/api/v1/digital_order_forms?query=${ convertObjToBase64(query) }`,
      ( payload ) => this.getDigitalOrderFormsSuccess( payload, query ),
      this.getDigitalOrderFormsError,
      this.load
    )
    getDigitalOrderFormsSuccess = ( payload, query ) => {
      if ( query.is_paginated ) {
        let tmpTotalPages = []
        if( payload.data && payload.data.length > 0 ) {
          for( let i = 1; i <= payload.meta.last_page; i++ ) {
            tmpTotalPages.push( i )
          }
        }
        this.setState({ digitalOrderForms: payload, formsTotalPages: tmpTotalPages })
      } else {
        this.setState({ digitalOrderForms: payload })
      }
    }
    getDigitalOrderFormsError = error => this.requestError( error )

    getSelectedDigitalOrderForm = ( id, destKey="selectedDigitalOrderForm" ) => Get(
      `/api/v1/digital_order_forms/${ id }`,
      payload => this.getSelectedDigitalOrderFormSuccess( payload, destKey ),
      this.getSelectedDigitalOrderFormError,
      this.load
    )
    getSelectedDigitalOrderFormSuccess = ( payload, destKey ) => this.setState({ [destKey]: payload })
    getSelectedDigitalOrderFormError = error => this.requestError( error )

    getSelectedDigitalOrderFormResults = ( id, query ) => Get(
      `/api/v1/digital_order_forms/${ id }/data?query=${ query }`,
      this.getSelectedDigitalOrderFormResultSuccess,
      this.getSelectedDigitalOrderFormResultError,
      this.load
    )
    getSelectedDigitalOrderFormResultSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      this.setState({ 
        selectedDigitalOrderFormResults: payload, 
        showViewModalResults: payload.data.length > 0, 
        selectedFormResultsTotalPage: tmpTotalPages })
    }
    getSelectedDigitalOrderFormResultError = error => this.requestError( error )

    createDigitalOrderForm = data => Post(
      `/api/v1/digital_order_forms`,
      data,
      this.createDigitalOrderFormSuccess,
      this.createDigitalOrderFormError,
      this.load
    )
    createDigitalOrderFormSuccess = () => {
      this.getDigitalOrderForms( { page: 1, is_paginated: true } )
      this.requestSuccess( Lang[ 'FORM_IS_SUCCESSFULLY_CREATED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showInfoModal: false, showFormBuilder: false })
    }
    createDigitalOrderFormError = error => this.requestError( error )

    updateDigitalOrderForm = ( id, data, isArchive ) => Put(
      `/api/v1/digital_order_forms/${ id }`,
      data,
      () => this.updateDigitalOrderFormSuccess( isArchive ),
      this.updateDigitalOrderFormError,
      this.load
    )
    updateDigitalOrderFormSuccess = isArchive => {
      this.requestSuccess( Lang[ 'FORM_IS_SUCCESSFULLY_UPDATED' ][ this.props.data.languageReducer.lang ] )
      if( isArchive ){
        this.getDigitalOrderForms( { 
          page: 1, 
          is_paginated: true,
          filter: {
            status: 'ARCHIVED'
          } 
        })
      }
      else{
        this.getDigitalOrderForms( { 
          page: 1, 
          is_paginated: true,
          filter: {
            $or: [
              { status: 'ACTIVE' },
              { status: 'INACTIVE' }
            ]
          } 
        })
      }
      this.setState({ 
        selectedDigitalOrderForm: [], 
        showUpdateModal: false, 
        showInfoModal: false })
    }
    updateDigitalOrderFormError = error => this.requestError( error )

    removeDigitalOrderForm = id => Delete(
      `/api/v1/digital_order_forms/${ id }`,
      this.removeDigitalOrderFormSuccess,
      this.removeDigitalOrderFormError,
      this.load
    )
    removeDigitalOrderFormSuccess = () => {
      this.requestSuccess( Lang[ 'FORM_IS_SUCCESSFULLY_REMOVED' ][ this.props.data.languageReducer.lang ] )
      this.getDigitalOrderForms( { page: 1, is_paginated: true } ) 
      this.setState({ showPromptModal: false })
    }
    removeDigitalOrderFormError = error => this.requestError( error )

    unarchiveDigitalOrderForm = async( id ) => {
      await this.getSelectedDigitalOrderForm( id )
      let tmpForm = _.cloneDeep( this.state.selectedDigitalOrderForm )
      tmpForm.status = 'ACTIVE'
      await this.updateDigitalOrderForm( id, tmpForm, true )
    }

    createSubmittedFormMedia = ( id, data ) => Post(
      `/api/v1/digital_order_forms_media`,
      data,
      payload => this.createSubmittedFormMediaSuccess( id, payload ),
      this.createSubmittedFormMediaError,
      this.load
    )
    createSubmittedFormMediaSuccess = ( id, payload ) => {
      let tmpMedia = {
        id: id,
        url: payload.url
      }
      let tmpUploadedMediaUrl = _.cloneDeep( this.state.uploadedMediaUrl )
      tmpUploadedMediaUrl.push( tmpMedia )
      this.setState({ uploadedMediaUrl: tmpUploadedMediaUrl })}
    createSubmittedFormMediaError = error => this.requestError( error )

    createSubmittedForm = ( center_id, data ) => Post(
      `/api/v1/centers/${ center_id }/submitted_digital_order_forms`,
      data,
      () => this.createSubmittedFormSuccess( center_id ),
      this.createSubmittedFormError,
      this.load
    )
    createSubmittedFormSuccess = center_id => {
      this.requestSuccess( Lang[ 'FORM_IS_SUCCESSFULLY_SUBMITTED' ][ this.props.data.languageReducer.lang ] )
      this.getSubmittedForms( center_id, '' )
      this.getDigitalOrderForms( { 
        page: 1, 
        is_paginated: true,
        filter: {
          $or: [
            { status: 'ACTIVE' },
            { status: 'INACTIVE' }
          ]
        } 
      })
      this.setState({ showFillSubmittedForm: false }) }
    createSubmittedFormError = error => this.requestError( error )

    getCenterDigitalOrderForms = ( center_id, query ) => Get(
      `/api/v1/centers/${ center_id }/digital_order_forms?query=${ query }`,
      this.getCenterDigitalOrderFormsSuccess,
      this.getCenterDigitalOrderFormsError,
      this.load
    )
    getCenterDigitalOrderFormsSuccess = payload => this.setState({ centerDigitalOrderForms: payload.data })
    getCenterDigitalOrderFormsError = error => this.requestError( error )

    getSubmittedForms = ( center_id, query ) => Get(
      `/api/v1/centers/${ center_id }/submitted_digital_order_forms?query=${ query }`,
      this.getSubmittedFormsSuccess,
      this.getSubmittedFormsError,
      this.load
    )
    getSubmittedFormsSuccess = payload => this.setState({ submittedForms: payload.data })
    getSubmittedFormsError = error => this.requestError( error )

    getSelectedSubmittedForm = ( center_id, id ) => Get(
      `/api/v1/centers/${ center_id }/submitted_digital_order_forms/${ id }`,
      this.getSelectedSubmittedFormSuccess,
      this.getSelectedSubmittedFormError,
      this.load
    )
    getSelectedSubmittedFormSuccess = payload => this.setState({ selectedSubmittedForm: payload })
    getSelectedSubmittedFormError = error => this.requestError( error )

    updateSubmittedForm = ( center_id, id, data ) => Put(
      `/api/v1/centers/${ center_id }/submitted_digital_order_forms/${ id }`,
      data,
      this.updateSubmittedFormSuccess,
      this.updateSubmittedFormError,
      this.load
    )
    updateSubmittedFormSuccess = () => this.requestSuccess( Lang[ 'FORM_IS_SUCCESSFULLY_UPDATED' ][ this.props.data.languageReducer.lang ] )
    updateSubmittedFormError = error => this.requestError( error )

    archiveIndividualFormResult = async (formResult) => {
      let data =  _.cloneDeep(formResult)
      data[ 'status_id' ] = 7 // status id of archived
      data[ 'uuid' ] = data[ 'uuid' ].trim()
      await this.updateSubmittedForm( formResult.center_id, formResult.id, data )
      const tmp = {
        page: 1, 
        is_paginated: true,
        filter: {
          status_id: {
            $neq: 7
          }
        }
      }
      this.getSelectedDigitalOrderFormResults( formResult.digital_order_form_id, convertObjToBase64( tmp ) )
    }

    getAllFormCategories = ( query ) => Get(
      `/api/v1/digital_order_forms_categories?query=${ query }`,
      this.getAllCategorySuccess,
      this.getAllCategoryError,
      this.load
    )
    getAllCategorySuccess = payload => {
      let categoryList = payload.data
      categoryList = _.orderBy(categoryList, [category => category.name[ this.props.data.languageReducer.lang ].toLowerCase()])
      this.setState({ allFormCategories: categoryList })
    }
    getAllCategoryError = error => this.requestError( error )

    resetSearchParams = () => {
      this.setState({ 
        searchParams: {
          name: '',
          categoryId: -1
        }
      }) 
    }


    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            onLoadDigitalOrderForms={ this.state.loading }
            userProfile={ this.state.userProfile }
            getUserProfile={ this.getUserProfile }
            submittedForms={ this.state.submittedForms }
            uploadedMediaUrl={ this.state.uploadedMediaUrl }
            centerDigitalOrderForms={ this.state.centerDigitalOrderForms }
            selectedSubmittedForm={ this.state.selectedSubmittedForm }
            showFillSubmittedForm={ this.state.showFillSubmittedForm }
            showViewSubmittedForm={ this.state.showViewSubmittedForm }
            createSubmittedFormMedia={ this.createSubmittedFormMedia }
            createSubmittedForm={ this.createSubmittedForm }
            getSubmittedForms={ this.getSubmittedForms }
            getCenterDigitalOrderForms={ this.getCenterDigitalOrderForms }
            getSelectedSubmittedForm={ this.getSelectedSubmittedForm }
            updateSubmittedForm={ this.updateSubmittedForm }
            digitalOrderForms={ this.state.digitalOrderForms }
            showFormBuilder={ this.state.showFormBuilder }
            showInfoModal={ this.state.showInfoModal }
            onChangeDigitalOrderFormHOC={ this.onChangeDigitalOrderFormHOC }
            getDigitalOrderForms={ this.getDigitalOrderForms }
            getSelectedDigitalOrderForm={ this.getSelectedDigitalOrderForm }
            getSelectedDigitalOrderFormResults={ this.getSelectedDigitalOrderFormResults }
            selectedFormResultsTotalPage={ this.state.selectedFormResultsTotalPage }
            createDigitalOrderForm={ this.createDigitalOrderForm }
            showUpdateModal={ this.state.showUpdateModal }
            showViewFormModal={ this.state.showViewFormModal }
            selectedDigitalOrderForm={ this.state.selectedDigitalOrderForm }
            selectedDigitalOrderFormResults={ this.state.selectedDigitalOrderFormResults }
            selectedOrderRequest={ this.state.selectedOrderRequest }
            archiveIndividualFormResult={ this.archiveIndividualFormResult }
            updateDigitalOrderForm={ this.updateDigitalOrderForm }
            removeDigitalOrderForm={ this.removeDigitalOrderForm }
            showArchivedForms={ this.state.showArchivedForms }
            showViewModalResults={ this.state.showViewModalResults }
            unarchiveDigitalOrderForm={ this.unarchiveDigitalOrderForm }
            formsTotalPages={ this.state.formsTotalPages }
            allFormCategories={ this.state.allFormCategories }
            getAllFormCategories={ this.getAllFormCategories }
            searchParams={ this.state.searchParams }
            resetSearchParams={ this.resetSearchParams }
            selectedFormToCopy={ this.state.selectedFormToCopy }
          />
          <PromptModal
            showPromptModal={ this.state.showPromptModal }
            onClickYes={() => this.removeDigitalOrderForm( this.state.toRemoveID )}
            onClickNo={() => this.setState({ showPromptModal: false })}
            content={ Lang[ 'DELETE_CONFIRMATION_MESSAGE' ][ this.props.data.languageReducer.lang ] }
            lang={ this.props.data.languageReducer.lang } />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
