import React, { useEffect } from 'react'
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button, UncontrolledTooltip, Card
} from 'reactstrap'
import _ from 'lodash'
import ReactTable from 'react-table'

import PageTitle from 'components/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import DeleteConfirmation from 'components/Modal/delete'
import Lang from 'Lang/General'

import RoleForm from './Form'
import WithRolesHOC from './actions.js'

const Roles = props => {
  useEffect( () => {
    props.getRoles()
  }, [] )

  useEffect( () => {
    if( !props.showCreateRole ){
      props.onChangeRolesHOC( 'newRole', { name: '', remark: '' } )
    }
  }, [ props.showCreateRole ] )

  useEffect( () => {
    if( !props.showUpdateRole ){
      props.onChangeRolesHOC( 'selectedRole', {} )
    }
  }, [ props.showUpdateRole ] )

  const handleSecurityModulesCreate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Member Area' })
      return tmpModules.is_create
    }
  }

  const handleSecurityModulesUpdate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Member Area' })
      return tmpModules.is_update
    }
  }

  const handleSecurityModulesDelete= () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Member Area' })
      return tmpModules.is_delete
    }
  }

  const renderCreateRoleModal = () => (
    <Modal size='md' isOpen={ props.showCreateRole } >
      <ModalHeader toggle={ () => props.onChangeRolesHOC( 'showCreateRole', false ) } >
        { Lang[ 'CREATE' ][ props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        <RoleForm
          roleType='newRole'
          currentRole={ props.newRole }
          onChangeRolesHOC={ props.onChangeRolesHOC }
          lang={ props.data.languageReducer.lang }
        />
        { props.onLoadRolesHOC && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => props.createRole( props.newRole ) }>
          { Lang[ 'SUBMIT' ][ props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )

  const renderUpdateRoleModal = () => (
    <Modal size='md' isOpen={ props.showUpdateRole } >
      <ModalHeader toggle={ () => props.onChangeRolesHOC( 'showUpdateRole', false ) } >
        { Lang[ 'UPDATE' ][ props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        <RoleForm
          roleType='selectedRole'
          currentRole={ props.selectedRole }
          onChangeRolesHOC={ props.onChangeRolesHOC }
          lang={ props.data.languageReducer.lang }
        />
        { props.onLoadRolesHOC && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => props.updateRole( props.selectedRole ) }>
          { Lang[ 'SUBMIT' ][ props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )

  return(
    <>
      <PageTitle
        heading={ Lang[ 'ROLES' ][ props.data.languageReducer.lang ] }
        icon="pe-7s-users icon-gradient bg-happy-fisher"
        buttons={[
          {
            buttonText: Lang[ 'CREATE' ][ props.data.languageReducer.lang ],
            onClick: () => props.onChangeRolesHOC( 'showCreateRole', true ),
            display: handleSecurityModulesCreate()
          }
        ]}/>
      <Card className='p-4'>
        <ReactTable
          showPagination
          pageSize={ 10 }
          data={ props.roles }
          columns={[
            {
              Header: Lang[ 'NAME' ][ props.data.languageReducer.lang ],
              accessor: "name"
            },
            {
              Header: Lang[ 'ACTIONS' ][ props.data.languageReducer.lang ],
              accessor: "id",
              Cell: ({ value }) => (
                <div className='d-flex w-100 justify-content-center' >
                  {
                    handleSecurityModulesUpdate() && (
                      <>
                        <Button
                          id={ `Role-${ value }-update` }
                          className="mb-2 mr-2 btn-icon btn-icon-only"
                          color="primary"
                          onClick={ () => props.getSelectedRole( value ) }>
                          <i className="pe-7s-note"> </i>
                        </Button>
                        <UncontrolledTooltip
                          target={ `Role-${ value }-update` }
                          placement="top">{ Lang[ 'EDIT' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                      </>
                    )
                  }
                  {
                    handleSecurityModulesDelete() && (
                      <>
                        <Button
                          id={ `Role-${ value }-archive-role` }
                          className="mb-2 mr-2 btn-icon btn-icon-only"
                          color="danger"
                          onClick={ () => props.onChangeRolesHOC( 'showDeleteRoleId', parseInt( value ) ) }>
                          <i className="pe-7s-trash"> </i>
                        </Button>
                        <UncontrolledTooltip
                          target={ `Role-${ value }-archive-role` }
                          placement="top">{ Lang[ 'REMOVE' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                      </>
                    )
                  }
                </div>
              )
            }
          ]}
        />
      </Card>
      <DeleteConfirmation
        handleModalClose={ () => props.onChangeRolesHOC( 'showDeleteRoleId', 0 ) }
        deleteOpen={ props.showDeleteRoleId > 0 }
        confirmAction={ () => props.removeRole( props.showDeleteRoleId ) }
        content={ Lang[ 'DELETE_CONFIRMATION_ROLE' ][ props.data.languageReducer.lang ] }
        lang={ props.data.languageReducer.lang } />
      { renderCreateRoleModal() }
      { renderUpdateRoleModal() }
      { props.onLoadRolesHOC && <LoadingOverlay/> }
    </>
  )
}

export default WithRolesHOC( Roles )