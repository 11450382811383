import React, { Component } from "react"
import { compose } from "redux"
import { connect } from 'react-redux'
import { 
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter, 
  Button
} from "reactstrap"
import _ from "lodash"
import uniqid from 'uniqid'

import LoadingModal from "../components/loading"
import SectionLists from "./SectionLists"

import CenterGroupHOC from "actions/centerGroup"
import { toggleMediaDatabase } from "actions/MediaDatabaseToggle"

import Lang from "Lang/General"

class SectionPicker extends Component {
  state = {
    selectedSectionToAdd: {},
  }

  componentDidMount = () => {
    this.props.getCenterGroupsForSelect({
      page: 1,
      is_paginated: false,
    })
  }

  onChangeState = ( key, val ) => this.setState({ [key]: val })

  addSection = () => {
    let tmp = {
      name: `${ this.state.selectedSectionToAdd.name.replace( /\[.*\]\s*/g, '' ) }`,
      type: "section",
      description: `${ this.state.selectedSectionToAdd.name }`,
      content: _.cloneDeep( this.state.selectedSectionToAdd.content ),
      is_system_section: false
    }
    this.patchIdsChildren( tmp.content )
    let tmpPage = _.cloneDeep( this.props.selectedPage )
    let tmpSectionContainer = _.find( tmpPage.content.children, { variant: "sections-container" } )
    tmpSectionContainer.children.splice(
      _.findIndex( tmpSectionContainer.children, { id: this.props.selectedSectionID } ) + 1,
      0,
      { id: tmp.content.id, type: "section" }
    )
    this.props.createLandingPageSections( tmpPage, tmp, this.props.updateMainContainerState )
  }

  patchIdsChildren = data => {
    data.id = uniqid()
    if ( data.children ) {
      data.children.map( ( item ) => {
        this.patchIdsChildren( item )
      })
    } else if ( data.carouselItems ) {
      data.carouselItems.map( ( carouselItem ) => {
        this.patchIdsChildren( carouselItem )
      })
    } else if ( data.dom ) {
      this.patchIdsChildren( data.dom )
    } else if ( data.caption ){
      this.patchIdsChildren( data.caption )
    }
  }

  updateSectionInfo = value => {
    let tmp = _.cloneDeep( this.props.newSection )
    tmp = { ...tmp, ...value }
    this.props.onChangeLandingPageHOC( "newSection", tmp )
  }

  render = () => {
    return (
      <>
        <Modal size="lg" isOpen={ this.props.showSectionPicker }>
          <ModalHeader toggle={ () => this.props.updateMainContainerState("showSectionPicker", false) }>
            { Lang[ "SELECT_A_SECTION" ][ this.props.lang ] }
          </ModalHeader>
          <ModalBody>
            <SectionLists onSelectSectionToAdd={ ( val ) => this.setState({ selectedSectionToAdd: val }) } { ...this.props } />
            { this.props.onLoadCreateSection && <LoadingModal /> }
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={ _.isEmpty( this.state.selectedSectionToAdd ) } 
              color="primary"
              style={{ marginRight: "10px" }}
              onClick={ this.addSection } >
              { Lang[ "SELECT_SECTION" ][ this.props.lang ] }
            </Button>
            <Button color="secondary" onClick={ () => this.props.updateMainContainerState( "showSectionPicker", false ) }>
              { Lang[ "CLOSE" ][ this.props.lang ] }
            </Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => ({ data: state })

export default compose(
  connect(mapStateToProps, { toggleMediaDatabase }),
  CenterGroupHOC
)( SectionPicker )
