import React from 'react'
import { 
  Form, FormGroup, Label, Input, 
  Card, CardBody 
} from 'reactstrap'
import _ from 'lodash'

import MultiLangInput from 'components/Input/MultiLang'
import MultiLangRTE from 'components/RTE/MultiLang'

import Lang from 'Lang/General'

const TemplateForm = ({
  currentTemplate,
  templateType,
  onChangeAVContractTemplateHOC,
  lang
}) => {
  
  const onChangeTemplateData = ( key, val ) => {
    let tmp = _.cloneDeep( currentTemplate )
    tmp[ key ] = val
    return onChangeAVContractTemplateHOC( templateType, tmp )
  }
  
  return (
    <Form onSubmit={ e => e.preventDefault() }>
      <MultiLangInput
        label={ Lang[ 'TITLE' ][ lang ] }
        value={ currentTemplate.name }
        type={ 'text' }
        onChange={ val => onChangeTemplateData( 'name', val ) }
        selectedLanguage={ lang } />
      <FormGroup>
        <Label>{ Lang[ 'VERSION' ][ lang ] }</Label>
        <Input
          type="number"
          min="0"
          value={ currentTemplate.version }
          onChange={ e => onChangeTemplateData( 'version', parseInt( e.target.value ) ) }/>
      </FormGroup>
      <Card className="my-4">
        <CardBody>
          <p>{ Lang[ 'PLACEHOLDER_TEXT' ][ lang ] }</p>
          <ul>
            <li>{ `${ Lang[ 'AUTHORIZED_PERSON' ][ lang ] } {{$.auth_person}}` }</li>
            <li>{ `${ Lang[ 'CONTRACT_NUMBER' ][ lang ] } {{$.agreement_number}}` }</li>
            <li>{ `${ Lang[ 'NAME_ADDRESS_OF_COMPANY' ][ lang ] } {{$.company_name_address}}` }</li>
          </ul>
        </CardBody>
      </Card>
      <MultiLangRTE
        label={ Lang[ 'CONTENT' ][ lang ] }
        value={ currentTemplate.html } 
        onChange={ val => onChangeTemplateData( 'html', val ) }
        selectedLanguage={ lang } />
    </Form>
  )
}

export default TemplateForm 
