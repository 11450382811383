import React, { Component } from 'react'
import { compose } from 'redux'
import{
  Modal, ModalBody, ModalFooter, ModalHeader, Form, FormGroup, Label, Input,
  Button, UncontrolledTooltip, Card, CardBody, CardFooter, CardHeader, Col,
  Container, Row, Badge
} from 'reactstrap'
import _ from 'lodash'
import Moment from 'moment'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PageTitle from 'components/PageTitle'
import ARKTable from 'components/Table'
import OrderRequestHOC from './actions'
import RequestStatusHOC from './actions/status'
import DownloadFormDataHOC from '../../Builder/actions/downloadFormData'
import DesignersHOC from './actions/designers'
import EditForm from './Edit'
import ModalShowMessages from './Messages'

import { convertObjToBase64 } from 'utils/objToBase64'

import ViewIndividualResult from '../../Builder/FormBuilder/ViewIndividualResult'
import Lang from 'Lang/General'

class OrderRequest extends Component{
  state = {
    showSelectedOrderRequest: false,
    showRequestEditModal: false,
    statusOptions: []
  }

  componentDidMount = () => {
    let tmp = {
      page: 1,
      is_paginated: true,
      filter: {
        status_id: {
          $neq: 7
        }
      }
    }
    this.props.getAllOrderRequests( convertObjToBase64( tmp ) )
    this.props.getAllRequestStatus()
    this.props.getDesignerRoleIds()
  }

  getTrProps = (state, rowInfo, instance) => {
    if( rowInfo ){
      let tmp = _.find( this.props.requestStatus, { name: rowInfo.original.status.name })
      if( tmp ) {
        return {
          style: {
            background: tmp.color,
            color: '#000'
          }
        }
      }
    }
    return {};
  }

  renderViewOrderRequestModal = () => {
    return (
      <Modal isOpen={ this.state.showSelectedOrderRequest } size='xl'>
        <ModalHeader toggle={ () => this.setState({showSelectedOrderRequest: false}) } >
          { Lang[ 'VIEW_RESULTS' ][ this.props.data.languageReducer.lang ] }
        </ModalHeader>
        <ModalBody>
          <ViewIndividualResult
            individualResult={ this.props.selectedOrderRequest }
            lang={ this.props.data.languageReducer.lang }
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color='link'
            onClick={ () => this.setState({showSelectedOrderRequest: false}) }>
            { Lang[ 'CLOSE' ][ this.props.data.languageReducer.lang ] }
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderEditOrderRequestModal = () => {
    return(
      <Modal
        toggle={ () => this.setState({ showRequestEditModal: false })  }
        isOpen={ this.state.showRequestEditModal }
        size="xl">
        <ModalHeader toggle={ () => this.setState({ showRequestEditModal: false }) }>
          { Lang[ 'EDIT_ORDER_STATUS' ][ this.props.data.languageReducer.lang ] }
        </ModalHeader>
        <ModalBody>
          <EditForm
            { ...this.props }
            statusOptions={ this.state.statusOptions }
            user={ this.props.data.profileReducer.profile }
            lang={ this.props.data.languageReducer.lang }
          />
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => { 
            this.props.updateOrderRequest()
            this.setState({ showRequestEditModal: false })
          }}>
            { Lang[ 'UPDATE' ][ this.props.data.languageReducer.lang ] }
          </Button>
          <Button className="mb-2 mr-2" color="secondary" onClick={ () => this.setState({ showRequestEditModal: false }) }>
            { Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Digital Order Form' })
      return tmpModules.is_update
    }
  }

  setUpdateStatusOptions = () => {
    const ROLE_ALLOWED_STATUS = {
      "Super Admin": _.map( this.props.requestStatus, 'name.en' ),
      "Management Admin": _.map( this.props.requestStatus, 'name.en' ),
      "Production": _.map( this.props.requestStatus, 'name.en' ).filter( status => status !== "Approved" ),
      "Designer": [ "Approved" ],
      "External Graphic Designer": [ "Approved" ]
    }
    let filteredStatuses = []
    const userRole = this.props.data.roleReducer.role.name
    const currentOrderRequestStatus = this.props.selectedOrderRequest.status.name.en

    if ( ROLE_ALLOWED_STATUS[ userRole ] ) {
      let allowedStatuses = ROLE_ALLOWED_STATUS[ userRole ]
      if ( 
        ( userRole === "Designer" || userRole === "External Graphic Designer" ) && 
        currentOrderRequestStatus !== "In designing" 
      ) {
        allowedStatuses = []
      }
      if (allowedStatuses.indexOf(currentOrderRequestStatus) === -1) {
        allowedStatuses.unshift(currentOrderRequestStatus)
      }
      filteredStatuses = _.filter(this.props.requestStatus,  (item) => {
        return allowedStatuses.indexOf(item.name.en)  !== -1
      })
    }
    this.setState({ statusOptions:  filteredStatuses })
  }

  render = () => {
    return(
      <>
        <div>
          <PageTitle
            heading={ Lang[ 'FORM_ORDER_REQUEST' ][ this.props.data.languageReducer.lang ] }
            icon="pe-7s-news-paper icon-gradient bg-happy-fisher"
            buttons={[]}/>
          <Card className="mb-3">
            <CardHeader>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }
            </CardHeader>
            <CardBody>
            <Container>
              <Row>
                <Col md={12}>
                  <Label>{ Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ] }</Label>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px', flexWrap: 'wrap' }}>
                  {
                    this.props.requestStatus.length > 0 && this.props.requestStatus.map( item => {
                      return(
                        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginRight: '15px' }}>
                          <div style={{ width: '15px', height: '15px', background: item.color, marginRight: '5px', marginBottom: '5px', border: 'solid #000 .5px' }}>
                          </div>
                          <label>{ item.name[ this.props.data.languageReducer.lang ] }</label>
                        </div>
                      )
                    })
                  }
                  </div>
                  <Form>
                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <Label>{ Lang[ 'CENTER_NAME' ][ this.props.data.languageReducer.lang ] }</Label>
                          <Input
                            type="text"
                            value={ this.props.searchParams.center_name }
                            onChange={ e => {
                              let tmp = _.cloneDeep( this.props.searchParams )
                              tmp[ 'center_name' ] = e.target.value
                              this.props.onChangeOrderRequestHOC( 'searchParams', tmp )
                            }}/>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label>{ Lang[ 'PR_NUMBER' ][ this.props.data.languageReducer.lang ]}</Label>
                          <Input
                            type="text"
                            value={ this.props.searchParams.pr_number }
                            onChange={ e => {
                              let tmp = _.cloneDeep( this.props.searchParams )
                              tmp[ 'pr_number' ] = e.target.value
                              this.props.onChangeOrderRequestHOC( 'searchParams', tmp )
                            }}/>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label>{ Lang[ 'CITY' ][ this.props.data.languageReducer.lang ] }</Label>
                          <Input
                            type="text"
                            value={ this.props.searchParams.city }
                            onChange={ e => {
                              let tmp = _.cloneDeep( this.props.searchParams )
                              tmp[ 'city' ] = e.target.value
                              this.props.onChangeOrderRequestHOC( 'searchParams', tmp )
                            }}/>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label>{ Lang[ 'CLIENT_NUMBER' ][ this.props.data.languageReducer.lang ] }</Label>
                          <Input
                            type="text"
                            value={ this.props.searchParams.client_number }
                            onChange={ e => {
                              let tmp = _.cloneDeep( this.props.searchParams )
                              tmp[ 'client_number' ] = e.target.value
                              this.props.onChangeOrderRequestHOC( 'searchParams', tmp )
                            }}/>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label>{ Lang[ 'TITLE' ][ this.props.data.languageReducer.lang ] }</Label>
                          <Input
                            type="text"
                            value={ this.props.searchParams.title }
                            onChange={ e => {
                              let tmp = _.cloneDeep( this.props.searchParams )
                              tmp[ 'title' ] = e.target.value
                              this.props.onChangeOrderRequestHOC( 'searchParams', tmp )
                            }}/>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label>{ Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ] }</Label>
                          <Input
                            type="select"
                            value={ this.props.searchParams.status_id }
                            onChange={ e => {
                              let tmp = _.cloneDeep( this.props.searchParams )
                              tmp[ 'status_id' ] = parseInt( e.target.value )
                              this.props.onChangeOrderRequestHOC( 'searchParams', tmp )
                            }}>
                            <option value={0}>{ Lang[ 'PLEASE_SELECT_ONE' ][ this.props.data.languageReducer.lang ] }</option>
                            {
                              this.props.requestStatus.length > 0 && this.props.requestStatus.map( item => {
                                return(
                                  <option key={ item.id } value={ item.id }>{ item.name[ this.props.data.languageReducer.lang ] }</option>
                                )
                              })
                            }
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Container>
            </CardBody>
            <CardFooter>
              <Button
                color="primary"
                className={ 'ml-auto mr-2' }
                onClick={ () => {
                  let tempFilter = {}
                  _.map( this.props.searchParams, ( seachVal, key ) => {
                    if ( key !== 'status_id' && seachVal ){
                      tempFilter[key] = { $like: `%${ seachVal.toLowerCase() }%` }
                    }
                  })

                  let tmp = {
                    is_paginated: true,
                    page:1,
                    filter: {
                      ... tempFilter,
                      status_id: this.props.searchParams.status_id === 0 
                        ? { $neq: 7 } 
                        : this.props.searchParams.status_id,
                    }
                  }
                  this.props.getAllOrderRequests( convertObjToBase64( tmp ) )
                }}>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }</Button>
              <Button
                color="danger"
                onClick={ () => {
                  let tmp = {
                    page: 1,
                    is_paginated: true,
                    status_id: {
                      $neq: 7
                    }
                  }
                  this.props.getAllOrderRequests( convertObjToBase64( tmp ) )
                  this.props.onChangeOrderRequestHOC( 'searchParams', { 
                    center_name: '', 
                    title: '', 
                    status_id: 0,
                    client_number: '',
                    city: '',
                    pr_number: ''
                  } )
                }}>{ Lang[ 'RESET' ][ this.props.data.languageReducer.lang ] }</Button>
            </CardFooter>
          </Card>
          <ARKTable
            data={ this.props.orderRequests.data }
            columns={[
              {
                Header: Lang[ 'PR_NUMBER' ][ this.props.data.languageReducer.lang ],
                accessor: 'pr_number'
              },
              {
                Header: Lang[ 'CLIENT_NUMBER' ][ this.props.data.languageReducer.lang ],
                accessor: 'client_number'
              },
              {
                Header: Lang[ 'CENTER_NAME' ][ this.props.data.languageReducer.lang ],
                accessor: "center_name",
              },
              {
                Header: Lang[ 'TITLE' ][ this.props.data.languageReducer.lang ],
                accessor: "digital_order_form.name."+ this.props.data.languageReducer.lang 
              },
              {
                Header: Lang[ 'CITY' ][ this.props.data.languageReducer.lang ],
                Cell: row => row.original.location?.city??'-'
              },
              {
                Header: Lang[ 'DESIGNER' ][ this.props.data.languageReducer.lang ],
                accessor: "graphic_designers",
                Cell: row => (
                  <ul style={{ padding: '0', listStylePosition: 'inside' }}>
                    {
                      row.original.graphic_designers.map( designer => {
                        return <li>{ `${ designer.first_name } ${ designer.last_name }` }</li>
                      }) 
                    }
                  </ul>
                )
              },
              {
                Header: Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ],
                accessor: "status.name."+ this.props.data.languageReducer.lang,
                className: "d-flex flex-row align-items-center justify-content-center" 
              },
              {
                Header: Lang[ 'UPDATED_ON' ][ this.props.data.languageReducer.lang ],
                className: "d-flex flex-row align-items-center justify-content-center",
                accessor: "updated_at",
                Cell: ({value}) => (
                  Moment( value ).format( 'DD-MM-YYYY h:mm:ss a' )
                )
              },
              {
                Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
                className: "d-flex flex-row align-items-center justify-content-center",
                accessor: 'id',
                width: 250,
                Cell: row => (
                  <>
                    <>
                      <Button 
                        color="primary" 
                        id={ `Form-result-${ row.original.id }-view-btn` }
                        className="mr-2"
                        onClick={() => {
                          this.props.onChangeOrderRequestHOC( 'selectedOrderRequest', row.original )
                          this.setState({ showSelectedOrderRequest: true })
                        }}>
                          <i className="pe-7s-look"/>
                      </Button>
                      <UncontrolledTooltip
                        target={ `Form-result-${ row.original.id }-view-btn` }
                        placement="top">{ Lang[ 'VIEW' ][ this.props.data.languageReducer.lang ] }
                      </UncontrolledTooltip>
                    </>
                    <>
                      <Button 
                        color="primary"
                        id={ `Form-result-${ row.original.id }-download-btn` } 
                        className="mr-2"
                        onClick={() => {
                          Promise.all([
                            this.props.onChangeOrderRequestHOC( 'selectedOrderRequest', row.original )
                          ]).then(() => {
                            this.props.downloadUploadedData( row.original.id )
                          })
                        }}>
                          <i className="pe-7s-download"/>
                      </Button>
                      <UncontrolledTooltip
                        target={ `Form-result-${ row.original.id }-download-btn` } 
                        placement="top">{ Lang[ 'DOWNLOAD_ALL_FILES' ][ this.props.data.languageReducer.lang ] }
                      </UncontrolledTooltip>
                    </>
                    {
                      this.handleSecurityModulesUpdate() && (
                        <>
                          <Button
                            id={ `order-request-${row.original.id}-update` }
                            className="mr-2 btn-icon btn-icon-only"
                            color="primary"
                            onClick={ async () => {
                              let tmpSelectedOrderRequest = _.cloneDeep( row.original )
                              tmpSelectedOrderRequest.graphic_designers = tmpSelectedOrderRequest.graphic_designers.map( designer => {
                                let tmpRole = Lang[ designer.role.replace( /\s/g, '_' ).toUpperCase() ] ? (
                                    Lang[ designer.role.replace( /\s/g, '_' ).toUpperCase() ][ this.props.data.languageReducer.lang ]
                                  ) : (
                                    designer.role
                                  )
                                let label = `${ designer.first_name } ${ designer.last_name } (${ tmpRole })`
                                return {
                                  ...designer,
                                  value: label,
                                  label: label
                                }
                              })

                              await this.props.onChangeOrderRequestHOC( 'selectedOrderRequest', tmpSelectedOrderRequest )
                              this.setState({ showRequestEditModal: true })
                              this.setUpdateStatusOptions()
                            }}>
                            <i className="pe-7s-note"> </i>
                          </Button>
                          <UncontrolledTooltip
                            target={ `order-request-${row.original.id}-update` }
                            placement="top">{ Lang[ 'UPDATE' ][ this.props.data.languageReducer.lang ] }
                          </UncontrolledTooltip>
                          <Button
                            id={ `order-request-${row.original.id}-message` }
                            className="mr-2 btn-icon btn-icon-only d-flex"
                            style={{ paddingTop: '9px', paddingBottom: '8px' }}
                            color="primary"
                            onClick={ async () => {
                              Promise.all([
                                this.props.getSelectedRequest( row.original.center_id,  row.original.id )
                              ]).then(() => {
                                this.props.onChangeOrderRequestHOC( 'showMessage', true )
                              })
                            }}>
                            <i className="pe-7s-chat"></i>
                            {
                              row.original.is_new_message && (
                                <Badge color="secondary">N</Badge>
                              )
                            }
                          </Button>
                          <UncontrolledTooltip
                            target={ `order-request-${row.original.id}-message` }
                            placement="top">{ Lang[ 'MESSAGES' ][ this.props.data.languageReducer.lang ] }
                          </UncontrolledTooltip>
                        </>
                      )
                    }
                  </>
                )
              }
            ]}
            totalPages={ this.props.totalPages }
            page={ this.props.orderRequests.meta ? this.props.orderRequests.meta.current_page : 1 }
            onChangePage={ pg => {
              let tempFilter = {}
                _.map( this.props.searchParams, ( seachVal, key ) => {
                  if ( key !== 'status_id' && seachVal ){
                    tempFilter[key] = { $like: `%${ seachVal.toLowerCase() }%` }
                  }
                })
                
                let tmp = {
                  is_paginated: true,
                  page: pg,
                  filter: {
                    ... tempFilter,
                    status_id: this.props.searchParams.status_id === 0 
                      ? { $neq: 7 } 
                      : this.props.searchParams.status_id,
                  }
                }
                this.props.getAllOrderRequests( convertObjToBase64( tmp ) )
            }}
            showPagination={ false }
            getTrProps={ this.getTrProps } />
            { this.renderEditOrderRequestModal() }
            { this.renderViewOrderRequestModal() }
            <ModalShowMessages { ...this.props } />
        </div>
        { 
          ( 
            this.props.data.ajaxReducer.ajaxCallProgress > 0 || 
            this.props.onLoadOrderRequest ||
            this.props.onLoadDesignersHOC
          ) && <LoadingOverlay /> 
        }
      </>
    )
  }
}

export default compose(
  OrderRequestHOC,
  DownloadFormDataHOC,
  RequestStatusHOC,
  DesignersHOC
)( OrderRequest )
