import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Post, Get, Put, Delete } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state={ 
      loading: false,
      members: [],
      membersPages: [],
      newMember: {},
      selectedMember: {},
      showCreateMember: false,
      showUpdateMember: false,
      showDeleteMemberId: 0,
      errorMessage: {}
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => {
      toast.success( success )
      this.setState({ errorMessage: {} })
    }
    requestError = error => {
      typeof error === 'string'
        ? toast.error( error )
        : Object.values( error ).map( err => toast.error( err.replaceAll( '_', ' ' ) ) )
      typeof error !== 'string' && this.setState({ 
        errorMessage: _.reduce( 
          error,  
          ( resError, errVal, errKey ) => (
            { 
              ...resError, 
              [ errKey.replace( 'ark_member_user.', '' ) ] : errVal.replaceAll( '_', ' ' )
            }
          ),
          {}
        )
      })
    }

    onChangeMembersHOC = ( key, value ) => this.setState({ [ key ] : value })

    createMember = data => Post(
      `/api/v1/ark_member_area/ark_member_users`,
      data,
      this.createMemberSuccess,
      this.createMemberError,
      this.load
    )
    createMemberSuccess = () => {
      this.getMembers()
      this.requestSuccess( Lang[ 'MEMBER_IS_SUCCESSFULLY_CREATED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showCreateMember: false })
    }
    createMemberError = error => this.requestError( error )

    getMembers = page => {
      let tmpSearchParams = {
        page: page ? page : 1,
        is_paginated: true
      }
      let tmpSearchString = convertObjToBase64( tmpSearchParams )
      return Get(
        `/api/v1/ark_member_area/ark_member_users?query=${ tmpSearchString }`,
        this.getMembersSuccess,
        this.getMembersError,
        this.load
      )
    }
    getMembersSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      this.setState({ members: payload, membersPages: tmpTotalPages })
    }
    getMembersError = error => this.requestError( error )

    getSelectedMember = member_id => Get(
      `/api/v1/ark_member_area/ark_member_users/${ member_id }`,
      this.getSelectedMemberSuccess,
      this.getSelectedMemberError,
      this.load
    )
    getSelectedMemberSuccess = payload => this.setState({ selectedMember: payload, showUpdateMember: true })
    getSelectedMemberError = error => this.requestError( error )

    updateMember = data => Put(
      `/api/v1/ark_member_area/ark_member_users/${ data.id }`,
      data,
      this.updateMemberSuccess,
      this.updateMemberError,
      this.load
    )
    updateMemberSuccess = () => {
      this.getMembers()
      this.requestSuccess( Lang[ 'MEMBER_IS_SUCCESSFULLY_UPDATED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showUpdateMember: false })
    }
    updateMemberError = error => this.requestError( error )

    removeMember = member_id => Delete(
      `/api/v1/ark_member_area/ark_member_users/${ member_id }`,
      this.removeMemberSuccess,
      this.removeMemberError,
      this.load
    )
    removeMemberSuccess = () => {
      this.getMembers()
      this.requestSuccess( Lang[ 'MEMBER_IS_SUCCESSFULLY_REMOVED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showDeleteMemberId: 0 })
    }
    removeMemberError = error => {
      this.requestError( error )
      this.setState({ showDeleteMemberId: 0 })
    }

    render = () => {
      return(
        <WrappedComponent
          { ...this.props } 
          onLoadMembersHOC={ this.state.loading }
          members={ this.state.members }
          membersPages={ this.state.membersPages }
          newMember={ this.state.newMember }
          selectedMember={ this.state.selectedMember }
          showCreateMember={ this.state.showCreateMember }
          showUpdateMember={ this.state.showUpdateMember }
          showDeleteMemberId={ this.state.showDeleteMemberId }
          errorMessage={ this.state.errorMessage }
          onChangeMembersHOC={ this.onChangeMembersHOC }
          createMember={ this.createMember }
          getMembers={ this.getMembers }
          getSelectedMember={ this.getSelectedMember }
          updateMember={ this.updateMember }
          removeMember={ this.removeMember }
          requestError={ this.requestError }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC