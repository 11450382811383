import React, { useState } from 'react'
import {
  Input, Form, Label, FormGroup, Button, Container,
  Card, CardBody, Col, Row
}from 'reactstrap'
import Moment from 'moment'
import _ from 'lodash'

import Lang from 'Lang/General'

const AddMailing = props => {
  const [ mailingID, setMailingID ] = useState( 0 )

  const onChangeMailsData = ( key, value ) => {
    let tmpData = _.cloneDeep( props.data )

    tmpData[key] = value

    if( props.data.id ){
      props.onChangeSystemFunnelsHOC( 'selectedFunnel', tmpData )
    } else {
      props.onChangeSystemFunnelsHOC( 'newFunnelData', tmpData )
    }
  }

  return(
    <Container fluid className="mt-3">
      <Card className="main-card mb-3">
        <CardBody>
          <Form>
            <FormGroup>
              <Label>{'Select a mailing'}</Label>
              <Row>
                <Col md={9}>
                  <Input
                    type='select'
                    value={ mailingID }
                    onChange={ e => {
                      setMailingID( parseInt( e.target.value ) )
                    }}>
                    <option>{ Lang['PLEASE_SELECT_ONE_FROM_BELOW'][ props.selectedLanguage ] }</option>
                    {
                      props.systemMailings && props.systemMailings.length > 0 && props.systemMailings.map( item => {
                        return(
                          <option value={ item.id }>{ item.title }</option>
                        )
                      })
                    }
                  </Input>
                </Col>
                <Col md={3}>
                  <Button
                    color="primary"
                    onClick={ () => {
                      let tmp = _.cloneDeep( props.data.mailings )
                      let tmpFound = _.find( props.systemMailings, {id: mailingID } )
                      if( tmpFound ){
                        tmp.push({
                          mail_id: mailingID,
                          title: tmpFound.title,
                          send_email_days: 0,
                          time_to_send: Moment().format( 'HH:mm' )
                        })
                      }
                      onChangeMailsData( 'mailings', tmp )
                    }}>
                  {'Add'}
                  </Button>
                </Col>
              </Row>
            </FormGroup>
            {
              props.data.mailings && props.data.mailings.length > 0 && props.data.mailings.map( (item, index) => {
                return(
                  <Card className="mt-2">
                    <CardBody>
                      <div className="d-flex align-items-center mb-2">
                        { 'Mail Sequence' }
                        <Input
                          style={{ width: '10%', marginRight: '5px', marginLeft: '5px' }}
                          type="number"
                          value={ index+1 }
                          disabled={ true }/>
                      </div>
                      <Label sm={4} style={{ background: '#c2c2c2', marginBottom: '5px' }}>{ item.title }</Label>
                      <div className="d-flex align-items-center">
                        <Input
                          style={{ width: '10%', marginRight: '5px' }}
                          type="number"
                          value={ props.data.mailings[index].send_email_days }
                          onChange={ e => {
                            let tmp = _.cloneDeep( props.data.mailings )
                            tmp[index]['send_email_days'] = parseInt( e.target.value )
                            onChangeMailsData( 'mailings', tmp )
                          }}/>
                          {
                            index === 0 ? 'Days(Sending the first mail, if 0, send immediately)' : 'Days(dispatch after previous mail)'
                          }
                      </div>
                      <div className="d-flex align-items-center">
                        <Input
                          style={{ width: '20%', marginRight: '5px', marginTop: '5px' }}
                          type="time"
                          value={ props.data.mailings[index].time_to_send }
                          onChange={ e => {
                            let tmp = _.cloneDeep( props.data.mailings )
                            tmp[index]['time_to_send'] = e.target.value
                            onChangeMailsData( 'mailings', tmp )
                          }}/>
                          Time to send the mail
                      </div>
                      <div style={{ float: 'right' }}>
                        <Button
                          color="danger"
                          onClick={ () => {
                            let tmp = _.cloneDeep( props.data.mailings )
                            tmp.splice( index, 1 )
                            onChangeMailsData( 'mailings', tmp )
                          }}>{'Remove'}</Button>
                      </div>
                    </CardBody>
                  </Card>
                )
              })
            }
          </Form>
        </CardBody>
      </Card>
      <Button
        color="primary"
        style={{ float: 'right' }}
        onClick={ () => {
          if( props.data.id ){
            let tmp = {
              mailings: props.data.mailings.map( mailing => {
                let tmpMailing = _.cloneDeep( mailing )
                tmpMailing.time_to_send = Moment( tmpMailing.time_to_send, 'HH:mm' ).utc().format()
                return tmpMailing
              })
            }
            props.setFunnelMailings( props.data.id, tmp )
          }
        }}>{ Lang['SUBMIT'][ props.selectedLanguage ] }</Button>
    </Container>
  )
}

export default AddMailing
