import React from 'react'
import {
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  Container,
  Row,
  Label,
  Col,
  Button,
  Table
} from 'reactstrap'
import Lang from 'Lang/General'

const View = ({
  showView,
  onChangeUsersHOC,
  selectedUser,
  lang
}) => {
  return(
    <Modal
      isOpen={ showView }
      toggle={ () => onChangeUsersHOC( 'showView', false ) }>
      <ModalHeader toggle={ () => onChangeUsersHOC( 'showView', false ) }>{ Lang[ 'VIEW_DETAILS' ][ lang ] }</ModalHeader>
      <ModalBody>
        <Card>
          <CardBody>
            <Table striped bordered>
              <tbody>
                <tr>
                  <th>{ Lang[ 'FIRST_NAME' ][ lang ] }</th>
                  <td>{ selectedUser.first_name }</td>
                </tr>
                <tr>
                  <th>{ Lang[ 'LAST_NAME' ][ lang ] }</th>
                  <td>{ selectedUser.last_name }</td>
                </tr>
                <tr>
                  <th>{ Lang[ 'USERNAME' ][ lang ] }</th>
                  <td>{ selectedUser.username }</td>
                </tr>
                <tr>
                  <th>{ Lang[ 'EMAIL' ][ lang ] }</th>
                  <td>{ selectedUser.email }</td>
                </tr>
                <tr>
                  <th>{ Lang[ 'PHOTO' ][ lang ] }</th>
                  <td><img src={ selectedUser.photo_url } width="50px" height="50px"/></td>
                </tr>
                <tr>
                  <th>{ Lang[ 'ROLE' ][ lang ] }</th>
                  <td>{ selectedUser.role }</td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={ () => onChangeUsersHOC( 'showView', false ) }>{ 'OK' }</Button>
      </ModalFooter>
    </Modal>
  )
}

export default View
