import React, { Component } from 'react'
import { connect } from 'react-redux'
import MetisMenu from 'react-metismenu'
import _ from 'lodash'

import { setEnableMobileMenu } from 'reducers/ThemeOptions'

import { MainNav } from './dataMainNav'
import { OlsTeamMainNav } from './OlsTeamMainNav'

import { storeSidemenu } from 'actions/sidemenu'
import Lang from 'Lang/General'
import TeLang from 'Lang/TePe'

class VerticalNavWrapper extends Component {
  state = {
    nav: []
  }

  componentDidMount = () => {
    this.initializeSideMenu()
  }

  componentDidUpdate = pp => {
    if( this.props.selectedLanguage.lang !== pp.selectedLanguage.lang ){
      this.initializeSideMenu()
    }
  }

  initializeSideMenu = () =>{
    let tmpPlatform = _.find( this.props.selectedRole.platforms, { name: 'Ark Management' })
    let tmp = []
    let tmpMainNav = _.cloneDeep( MainNav )
    let tmpOLSTeamMainNav = _.cloneDeep( OlsTeamMainNav )
    let tmpLang = window.location.host.indexOf( 'tennis' ) > -1 ? TeLang : Lang
    tmpMainNav.forEach(element => {
      element.label = tmpLang[ element.label ][ this.props.selectedLanguage.lang ]
      if( element.content != undefined ){
        element.content.forEach( element_sub1 =>{
          if( tmpLang[ element_sub1.label ] ) {
            element_sub1.label = tmpLang[ element_sub1.label ][ this.props.selectedLanguage.lang ]
            if( element_sub1.content != undefined ){
              element_sub1.content.forEach( element_sub2 =>{
                element_sub2.label = tmpLang[ element_sub2.label ][ this.props.selectedLanguage.lang ]
                if( element_sub2.content != undefined ){
                  element_sub2.content.forEach( element_sub3 =>{
                    element_sub3.label = tmpLang[ element_sub3.label ][ this.props.selectedLanguage.lang ]
                  })
                }
              })
            }
          }
        })
      }
    })
    tmpOLSTeamMainNav.forEach( element => {
      element.label = tmpLang[ element.label ][ this.props.selectedLanguage.lang ]
      if( element.content != undefined ){
        element.content.forEach( element_sub1 =>{
          element_sub1.label = tmpLang[ element_sub1.label ][ this.props.selectedLanguage.lang ]
          if( element_sub1.content != undefined ){
            element_sub1.content.forEach( element_sub2 =>{
              element_sub2.label = tmpLang[ element_sub2.label ][ this.props.selectedLanguage.lang ]
              if( element_sub2.content != undefined ){
                element_sub2.content.forEach( element_sub3 =>{
                  element_sub3.label = tmpLang[ element_sub3.label ][ this.props.selectedLanguage.lang ]
                })
              }
            })
          }
        })
      }
    })
    tmp.push(
      {
        icon: 'pe-7s-rocket',
        label: tmpLang['DASHBOARD'][this.props.selectedLanguage.lang],
        secLabel: 'Dashboard',
        to: '#/dashboard'
      }
    )
    tmpMainNav.map( item => {
      if ( 
        !item.secLabel ||
        ( 
          tmpPlatform && 
          item.secLabel &&
          _.find( tmpPlatform.modules, { name: item.secLabel } )?.is_read
        )
      ){
        if( item.content ) {
          item.content = item.content.filter( submenu => {
            if (
              !submenu.secLabel ||
              (
                submenu.secLabel &&
                _.find( tmpPlatform.modules, { name: submenu.secLabel } )?.is_read
              ) || 
              ( 
                submenu.secLabel === 'Security' && 
                this.props.selectedRole.name === "Super Admin" 
              )
            ) {
              return submenu
            }
          })
        }
        tmp.push( item )
      }
    })
    if ( this.props.selectedRole.name === "OLS Team" ) {
      tmpOLSTeamMainNav.map( item => tmp.push( item ) )
    }
    if( this.props.selectedRole.name === 'External Graphic Designer' ){
      let tmpIndex = _.findIndex( tmp, { secLabel: 'Digital Order Form' } )
      if( tmpIndex > -1 ){
        tmp[ tmpIndex ].content = _.filter( 
          tmp[ tmpIndex ].content, 
          { label: tmpLang[ 'FORM_ORDER_REQUEST' ][ this.props.selectedLanguage.lang ] } 
        )
      }
    }
    this.createShortcutMenus( tmp )
    this.setState({ nav: tmp })
  }

  createShortcutMenus = data => {
    let tmp = []
    data.map( item => {
      if( item.content ) {
        item.content.map( cont => {
          tmp.push({ ...cont, label: `${ item.label } - ${ cont.label }` })
        })
      } else {
        tmp.push( item )
      }
    })
    this.props.storeSidemenu( tmp )
  }

  render = () => {
    return (
      <>
        <br />
        <MetisMenu
          content={ this.state.nav }
          onSelected={ e => {
            let tmp = e.currentTarget.innerHTML.toString()
            if( tmp.indexOf( 'Cockpit' ) > -1 ) {
              if( window.location.host.indexOf( 'localhost' ) > -1 ) {
                window.location.href = `https://cockpit-staging.proof-point-suite.com/#/login-user/${ localStorage.getItem( 'PROOFPOINT_ARK_MANAGEMENT' ) }`
              } else if( window.location.host.indexOf( 'tennis' ) > -1 ) {
                window.location.href = `https://adminportal.tennis-suite.com/#/login-user/${ localStorage.getItem( 'PROOFPOINT_ARK_MANAGEMENT' ) }`
              } else if( window.location.host.indexOf( 'staging' ) > -1 ) {
                window.location.href = `https://cockpit-staging.proof-point-suite.com/#/login-user/${ localStorage.getItem( 'PROOFPOINT_ARK_MANAGEMENT' ) }`
              } else if( window.location.host.indexOf( 'aciso' ) > -1 ) {
                window.location.href = `https://adminportal.aciso-suite.com/#/login-user/${ localStorage.getItem( 'PROOFPOINT_ARK_MANAGEMENT' ) }`
              } else if( window.location.host.indexOf( 'proof-point-suite' ) > -1 ) {
                window.location.href = `https://adminportal.proof-point-suite.com/#/login-user/${ localStorage.getItem( 'PROOFPOINT_ARK_MANAGEMENT' ) }`
              }
            }
            this.props.setEnableMobileMenu( !this.props.enableMobileMenu )
          }}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down" />
        <div style={{ height: '100px' }}>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  selectedRole: state.roleReducer.role
})

export default connect( mapStateToProps, {
  setEnableMobileMenu,
  storeSidemenu
})( VerticalNavWrapper )
