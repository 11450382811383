import React from 'react'
import { connect } from 'react-redux'

import CoreRenderFunction from '../../core/render'

const FooterMobile = ({
  updateMainContainerState,
  languages,
  selectedLanguageShortname,
  templateFooterBlueprints
}) => {
  return (
    <CoreRenderFunction
      updateMainContainerState={ updateMainContainerState }
      languages={ languages }
      selectedLanguageShortname={ selectedLanguageShortname }
      item={ templateFooterBlueprints } />
  )
}

const mapStateToProps = state => ({ data: state })

export default connect( mapStateToProps )( FooterMobile )