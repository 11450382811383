import React, { useState } from 'react'
import {
  Row, Col, Button, Form, FormGroup, Label, Input, Container
} from 'reactstrap'
import Moment from 'moment'
import _ from 'lodash'
import ReactSelect from 'react-select'

import Lang from 'Lang/General'

const EditForm = ({
  selectedOrderRequest,
  statusOptions,
  onChangeOrderRequestHOC,
  user,
  lang,
  designers
}) => {
  const [newRemark, setNewRemark] = useState("")

  return(
    <Container>
      <Row>
        <Col md={7}>
          <Form onSubmit={ e => e.preventDefault() } col={ 9 }>
            <FormGroup row>
              <Label sm={ 3 }>{ Lang[ 'SUBMITTED_ON' ][ lang ] }</Label>
              <Col sm={ 9 }>
                <Input
                  disabled
                  value={ Moment( selectedOrderRequest.created_at ).format( 'DD-MM-YYYY h:mm:ss a' ) } />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={ 3 }>{ Lang[ 'CENTER' ][ lang ] }</Label>
              <Col sm={ 9 }>
                <Input
                  disabled
                  value={ selectedOrderRequest.center_name } />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={ 3 }>{ Lang[ 'DESIGNER' ][ lang ] }</Label>
              <Col sm={ 9 }>
                <ReactSelect
                  isMulti
                  name="designers"
                  value={ selectedOrderRequest.graphic_designers }
                  onChange={ value => {
                    onChangeOrderRequestHOC( 'selectedOrderRequest', {
                      ...selectedOrderRequest,
                      graphic_designers: value
                    })} 
                  }
                  options={ designers }
                  className="basic-multi-select"
                  classNamePrefix="select"
                  styles={{
                    menu: provided => ({ ...provided, zIndex: 998 })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={ 3 }>{ Lang[ 'TITLE' ][ lang ] }</Label>
              <Col sm={ 9 }>
                <Input
                  disabled
                  value={ selectedOrderRequest.digital_order_form.name[lang] } />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={ 3 }>{ Lang[ 'DESCRIPTION' ][ lang ] }</Label>
              <Col sm={ 9 }>
                <Input
                  disabled
                  value={ selectedOrderRequest.digital_order_form.description[lang] } />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={ 3 }>{ Lang[ 'STATUS' ][ lang ] }</Label>
              <Col sm={ 9 }>
                <Input
                  type="select"
                  value={ selectedOrderRequest.status_id }
                  onChange={ e => {
                    let tmpData = _.cloneDeep( selectedOrderRequest )
                    tmpData[ 'status_id' ] = parseInt( e.target.value )
                    onChangeOrderRequestHOC( 'selectedOrderRequest',  tmpData )
                  }}>
                  <option>{ Lang[ 'PLEASE_SELECT_ONE' ][ lang ] }</option>
                  {
                    statusOptions.length > 0 && statusOptions.map( item => {
                      return(
                        <option key={ item.id } value={ item.id }>{ item.name[ lang ] }</option>
                      )
                    })
                  }
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={ 3 }>{ 'PR Nummer' }</Label>
              <Col md={ 9 }>
                <Input
                  type="text"
                  value={ selectedOrderRequest.pr_number }
                  onChange={ e => {
                    let tmpData = _.cloneDeep( selectedOrderRequest )
                    tmpData[ 'pr_number' ] = e.target.value
                    onChangeOrderRequestHOC( 'selectedOrderRequest',  tmpData )
                  }} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={ 3 }>{ Lang[ 'REMARK' ][ lang ] }</Label>
              <Col sm={ 9 }>
                <Input
                  type='textarea'
                  rows='8'
                  resize='none'
                  value={ newRemark }
                  onChange={ e => setNewRemark(e.target.value) }
                />
                <div className="d-flex justify-content-end mt-1">
                  <Button
                    onClick={ () => {
                      const newRemarkInfo = {
                        'commenter_id': user.id,
                        'commenter_username': `${ user.first_name } ${ user.last_name }`,
                        'commented_on': Moment().toISOString(), 
                        'remark': newRemark.trim()
                      }
                      let tmpData = _.cloneDeep( selectedOrderRequest )
                      tmpData[ 'remark' ].unshift(newRemarkInfo)
                      onChangeOrderRequestHOC( 'selectedOrderRequest',  tmpData )
                      setNewRemark("")
                    }}>
                      { Lang[ 'ADD_REMARK' ][ lang ] }
                  </Button>
                </div>
              </Col>
            </FormGroup>
          </Form>
        </Col>
        <Col md={5}>
          <div>
            { Lang[ 'REMARKS' ][ lang ] }
            <div className="overflow-auto p-2 mt-2" style={{ height: "420px", backgroundColor: "#e9ecef", borderRadius: "0.25rem" }} >
              {
                selectedOrderRequest[ 'remark' ].length === 0 
                ? <span>{ Lang[ 'NO_REMARKS' ][ lang ] }</span>
                : <>
                  {
                    selectedOrderRequest[ 'remark' ].map( remark => {
                      return (
                        <div className="mb-4">
                          <strong> { remark.commenter_username } </strong> 
                          <small> { Moment( remark.commented_on ).format( 'DD-MM-YYYY h:mm a' ) } </small>
                          <div style={{ whiteSpace: "pre-line" }}> { remark.remark } </div>
                        </div>
                      )
                    })  
                  }
                </>
              }
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
export default EditForm
