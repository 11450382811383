import React from 'react'
import { 
  Modal, ModalBody, ModalHeader, ModalFooter,
  Button, UncontrolledTooltip
} from 'reactstrap'
import Moment from 'moment'

import { convertObjToBase64 } from 'utils/objToBase64'
import ARKTable from 'components/Table'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Lang from 'Lang/General'

const Archived = props => {
  return(
    <Modal size='lg' isOpen={ props.showArchivedFunnels } >
      <ModalHeader toggle={ () => props.onChangeSystemFunnelsHOC( 'showArchivedFunnels', false ) } >
        { Lang[ 'ARCHIVED_FUNNELS' ][ props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        <ARKTable
          data={ props.systemFunnels.data }
          columns={[
            {
              Header: 'Title',
              accessor: 'title',
              width: 350,
              Cell: ({value} ) => (
                <>
                  <span>
                  { value[ props.data.languageReducer.lang ] }
                  </span>
                </>
              )
            },
            {
              Header: 'Center Group',
              accessor: 'center_groups',
              Cell: ({value}) => (
                <ul>
                {
                  value && value.map( (item,index) => (
                    <li key={ item.id }>{ item.name }</li>
                  ))
                }
                </ul>
              )
            },
            {
              Header: 'Created on',
              accessor: 'created_at',
              Cell: ({value}) => (
                <span>
                  {
                    Moment( value ).format('DD-MM-YYYY HH:mm' )
                  }
                </span>
              )
            },
            {
              Header: Lang[ 'ACTION' ][ props.data.languageReducer.lang ],
              accessor: 'id',
              width: 100,
              Cell: ({ value }) => (
                <>
                  <Button
                    id={ `System-funnel-${ value }-unarchive` }
                    className='mb-2 mr-2 btn-icon btn-icon-only'
                    color='warning'
                    onClick={ () => props.getSelectedSystemFunnel( value, true ) }>
                    <i className='pe-7s-notebook'/>
                  </Button>
                  <UncontrolledTooltip
                    target={ `System-funnel-${ value }-unarchive` }
                    placement='top'>{ Lang[ 'UNARCHIVE' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                </>
              )
            }
          ]}
          totalPages={ props.systemFunnelTotalPages }
          page={ props.systemFunnels.meta ? props.systemFunnels.meta.current_page : 1 }
          onChangePage={ pg => props.getAllSystemFunnels( 
            convertObjToBase64({ 
              is_paginated: true, 
              page: pg,
              filter: {
                status: 'ARCHIVED'
              }
            }) 
          )}
          searchComponent={ false }
          showPagination={ false }
          onClickReset={ () => {} }
          onSearch={ () => {} }
          searchColumn={[]}
        />
        { props.onLoadSystemFunnels && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => props.onChangeSystemFunnelsHOC( 'showArchivedFunnels', false ) } >
          { Lang[ 'CLOSE' ][ props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )
}

export default Archived


