import React, { Component, Fragment } from 'react'
import _ from 'lodash'

import { toast } from 'react-toastify'

import { Get, Put, Post, Delete } from 'utils/axios-template'
import { production_url } from '../config'

import Lang from 'Lang/General'

const LanguageHOC = ( WrappedComponent ) => {
  class LanguageWrappedComponent extends Component {
    state = {
      loading: false,
      updateLoading: false,
      showStatusModal: false,
      statusModalType: '',
      onClickStatusModalButton: () => {},
      statusModalMessage: '',
      languages: []
    }

    load = param => this.setState({ loading: param })
    updateLoad = param => this.setState({ updateLoading: param })
    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    onChangeSelectedLanguage = ( key, index, param ) => {
      let tmp = _.cloneDeep( this.state.languages )
      tmp[ index ][ key ] = param
      return this.setState({ languages: tmp })
    }

    getLanguages = ( template_id ) => Get(
      `${production_url}/templates/${template_id}/languages`,
      this.getLanguagesSuccess,
      this.getLanguagesError,
      this.load
    )
    getLanguagesSuccess = payload => {
      this.setState({ languages: payload.languages })
    }
    getLanguagesError = error => this.requestError( error )

    createLanguage = ( template_id ) => Post(
      `${production_url}/templates/${template_id}/languages`,
      {
        name: '',
        shortname: '',
        status: 'INACTIVE'
      },
      () => this.createLanguageSuccess( template_id ),
      this.createLanguageError,
      this.updateLoad
    )
    createLanguageSuccess = ( template_id ) => {
      this.getLanguages( template_id )
      this.requestSuccess( Lang[ 'LANGUAGE_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
    }
    createLanguageError = error => this.requestError( error )

    updateLanguage = ( template_id, id, data ) => Put(
      `${production_url}/templates/${template_id}/languages/${id}`,
      data,
      () => this.updateLanguageSuccess( template_id ),
      this.updateLanguageError,
      this.updateLoad
    )
    updateLanguageSuccess = ( template_id ) => {
      this.getLanguages( template_id )
      this.requestSuccess( Lang[ 'LANGUAGE_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
    }
    updateLanguageError = error => this.requestError( error )

    removeLanguage = ( template_id, id ) => Delete(
      `${production_url}/templates/${template_id}/languages/${id}`,
      () => this.removeLanguageSuccess( template_id ),
      this.removeLanguageError,
      this.updateLoad
    )
    removeLanguageSuccess = ( template_id ) => {
      this.getLanguages( template_id )
      this.requestSuccess( Lang[ 'LANGUAGE_REMOVE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
    }
    removeLanguageError = error => this.requestError( error )

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            { ...this.props }
            getLanguages={ this.getLanguages }
            createLanguage={ this.createLanguage }
            updateLanguage={ this.updateLanguage }
            removeLanguage={ this.removeLanguage }
            onChangeSelectedLanguage={ this.onChangeSelectedLanguage }
            languages={ this.state.languages }
            onLoadLanguages={ this.state.loading }
            onLoadLanguagesUpdate={ this.state.updateLoading } />
        </Fragment>
      )
    }
  }
  return LanguageWrappedComponent
}

export default LanguageHOC
