import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Get, Put, Post, Delete } from 'utils/axios-template'
import { production_url } from '../config'

const HeaderMenu = ( WrappedComponent ) => {
  class HeadersWrappedComponent extends Component {
    state = {
      loading: false,
      headerMenuContent: {},
      showHeaderMenuEditor: false
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    onChangeHeaderMenuHOC = ( key, val ) => this.setState({ [ key ]: val }) 
    
    getHeaderMenu = ( template_id ) => Get(
      `${production_url}/templates/${template_id}/template-new-headers`,
      this.getHeaderMenuSuccess,
      this.getHeaderMenuError,
      this.load
    )
    getHeaderMenuSuccess = payload => {
      let tmp = payload.content
      this.setState({ 
        headerMenuContent: tmp
      })
    }
    getHeaderMenuError = error => console.log(error)

    // updateHeaderMenu = ( data ) => Put(
    //   `/sites/${ data.site_id }/new_headers/${ data.id }`,
    //   data,
    //   () => this.updateHeaderMenuSuccess( data.site_id ),
    //   this.updateHeaderMenuError,
    //   this.load
    // )
    // updateHeaderMenuSuccess = site_id => {
    //   this.requestSuccess( 'Data is updated successfully.' )  
    //   this.getHeaderMenu( site_id )
    //   this.setState({ showHeaderMenuEditor: false })
    // }
    // updateHeaderMenuError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onChangeHeaderMenuHOC={ this.onChangeHeaderMenuHOC }
          getHeaderMenu={ this.getHeaderMenu }
          // updateHeaderMenu={ this.updateHeaderMenu }
          headerMenuContent={ this.state.headerMenuContent}
          onLoadHeaders={ this.state.loading }
          showHeaderMenuEditor={ this.state.showHeaderMenuEditor } />
      )
    }
  }
  return HeadersWrappedComponent
}

export default HeaderMenu