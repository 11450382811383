import React, { Component } from 'react'
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip
} from 'reactstrap'
import _ from 'lodash'
import ReactTable from 'react-table'

import PageTitle from 'components/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'
import CurrenciesHOC from './actions'
import CurrencyForm from './components/currencyForm'
import DeleteConfirmationDialog from 'components/Modal/delete'

class Currencies extends Component {
  componentDidMount = () => this.props.getCurrencies()

  componentDidUpdate = pp => {
    if( pp.showCreateCurrency && !this.props.showCreateCurrency ){
      this.props.onChangeCurrenciesHOC( 'newCurrency', {
        name: '',
        decimal_separator: '.'
      } )
    }
    if( pp.showUpdateCurrency && !this.props.showUpdateCurrency ){
      this.props.onChangeCurrenciesHOC( 'selectedCurrency', {} )
    }
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Online Shop' })
      return tmpModules.is_create
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Online Shop' })
      return tmpModules.is_update
    }
  }

  handleSecurityModulesDelete = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Online Shop' })
      return tmpModules.is_delete
    }
  }

  renderCreateForm = () => (
    <Modal isOpen={ this.props.showCreateCurrency } size='xl' >
      <ModalHeader toggle={ () => this.props.onChangeCurrenciesHOC( 'showCreateCurrency', false ) }>
        { Lang[ 'CREATE_CURRENCY' ][ this.props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        <CurrencyForm
          currencyType='newCurrency'
          currentCurrency={ this.props.newCurrency }
          selectedLanguageShortname={ this.props.data.languageReducer.lang }
          onChangeCurrenciesHOC={ this.props.onChangeCurrenciesHOC } />
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => this.props.createCurrency( this.props.newCurrency ) }>
          { Lang[ 'SUBMIT' ][ this.props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )

  renderUpdateForm = () => (
    <Modal isOpen={ this.props.showUpdateCurrency } size='xl' >
      <ModalHeader toggle={ () => this.props.onChangeCurrenciesHOC( 'showUpdateCurrency', false ) }>
        { Lang[ 'UPDATE_CURRENCY' ][ this.props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        <CurrencyForm
          currencyType='selectedCurrency'
          currentCurrency={ this.props.selectedCurrency }
          selectedLanguageShortname={ this.props.data.languageReducer.lang }
          onChangeCurrenciesHOC={ this.props.onChangeCurrenciesHOC } />
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => this.props.updateCurrency( this.props.selectedCurrency.id, this.props.selectedCurrency ) }>
          { Lang[ 'SUBMIT' ][ this.props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )

  render = () => {
    return(
      <>
        <PageTitle
          heading={ Lang[ 'CURRENCIES' ][ this.props.data.languageReducer.lang ] }
          icon="pe-7s-network icon-gradient bg-happy-fisher"
          buttons={[
            {
              buttonText: Lang[ 'CREATE' ][ this.props.data.languageReducer.lang ],
              onClick: () => this.props.onChangeCurrenciesHOC( 'showCreateCurrency', true ),
              display: this.handleSecurityModulesCreate()
            }
        ]}/>
        <Card>
          <CardBody>
            <ReactTable
              pageSize={ 20 }
              NoDataComponent={ () => <span></span> }
              showPagination={ true }
              data={ this.props.currencies }
              columns={[
                {
                  Header: Lang[ 'NAME' ][ this.props.data.languageReducer.lang ],
                  accessor: 'name'
                },
                {
                  Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
                  accessor: 'id',
                  Cell: ( row ) => (
                    <div className="d-flex flex-row align-items-center justify-content-center w-100">
                      {
                        this.handleSecurityModulesUpdate() && (
                          <>
                            <Button
                              id={ `Currencies-${ row.original.id }-update-currency` }
                              className="mb-2 mr-2 btn-icon btn-icon-only"
                              color='primary'
                              onClick={ () => {
                                this.props.onChangeCurrenciesHOC( 'showUpdateCurrency', true )
                                this.props.getSelectedCurrency( row.original.id )
                              }} >
                              <i className="pe-7s-pen btn-icon-wrapper" />
                            </Button>
                            <UncontrolledTooltip
                              target={ `Currencies-${ row.original.id }-update-currency` }
                              placement="top">{ Lang[ 'UPDATE_CURRENCY_SEN_CASE' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                          </>
                        )
                      }
                      {
                        this.handleSecurityModulesDelete() && (
                          <>
                            <Button
                              id={ `Currencies-${ row.original.id }-remove-currency` }
                              className="mb-2 mr-2 btn-icon btn-icon-only"
                              color='danger'
                              onClick={ () => {
                                this.props.getSelectedCurrency( row.original.id )
                                this.props.onChangeCurrenciesHOC( 'showDeleteCurrency', true )
                              }} >
                              <i className="pe-7s-trash btn-icon-wrapper" />
                            </Button>
                            <UncontrolledTooltip
                              target={ `Currencies-${ row.original.id }-remove-currency` }
                              placement="top">{ Lang[ 'REMOVE_CURRENCY' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                          </>
                        )
                      }
                    </div>
                  )
                }
              ]}/>
          </CardBody>
          {
            this.props.showDeleteCurrency && (
              <DeleteConfirmationDialog
                deleteOpen={ this.props.showDeleteCurrency }
                handleModalClose={ () => this.props.onChangeCurrenciesHOC( 'showDeleteCurrency', false ) }
                confirmAction={ () => {
                  if( this.props.selectedCurrency.id ){
                    this.props.removeCurrency( this.props.selectedCurrency.id )
                  }
                  this.props.onChangeCurrenciesHOC( 'showDeleteCurrency', false )
                }}
                content={ Lang[ 'DELETE_CONFIRMATION_CURRENCY' ][ this.props.data.languageReducer.lang ] }
                lang={ this.props.data.languageReducer.lang }/>
            )
          }
        </Card>
        { this.renderCreateForm() }
        { !_.isEmpty( this.props.selectedCurrency ) && this.props.showUpdateCurrency && this.renderUpdateForm() }
        { this.props.onLoadCurrencies && <LoadingOverlay/> }
      </>
    )
  }
}

export default CurrenciesHOC( Currencies )
