import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { convertObjToBase64 } from 'utils/objToBase64'

import { Get, Post, Put, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      mediaDrives: [],
      selectedFileFolder: {},
      activeTab: '1',
      path: [
        {
          label: 'Home',
          nav: () => {
            this.getMediaDrive()
            let foundIndex = _.findIndex( this.state.path, { label: 'Home' } )
            let tmp = _.slice( this.state.path, 0, foundIndex + 1 )
            this.setState({ path: tmp, parentFolder: { id: 1, name: 'Home' } })
          }
        }
      ],
      parentFolder: {},
      newFolderFile: {
        file_name: '',
        type: 'File',
        original_filename: '',
        parent_folder_id: 1
      },
      files: [],
      showCreateModal: false,
      showEditModal: false,
      showDeleteModal: false
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( item => toast.error( item.replaceAll( '_', ' ' ) ) )

    onChangeArkMFilesHOC = ( key, value ) => this.setState({ [key]: value })

    getMediaDrive = () => Get(
      `/api/v1/mfiles/drive`,
      this.getMediaDriveSuccess,
      this.getMediaDriveError,
      this.load
    )
    getMediaDriveSuccess = payload => {
      let tmp = {
        id: payload.data.parent_folder_id,
        name: payload.data.parent_folder_name
      }
      this.setState({ mediaDrives: payload.data, parentFolder: tmp })
    }
    getMediaDriveError = error => this.requestError( error )

    getSelectedDriveFolder = id => Get(
      `/api/v1/mfiles/drive/folders/${ id }`,
      this.getSelectedDriveFolderSuccess,
      this.getSelectedDriveFolderError,
      this.load
    )
    getSelectedDriveFolderSuccess = payload => this.setState({ mediaDrives: payload })
    getSelectedDriveFolderError = error => this.requestError( error )

    getSelectedMedia = id => Get(
      `/api/v1/mfiles/media/${ id }`,
      this.getSelectedMediaSuccess,
      this.getSelectedMediaError,
      this.load
    )
    getSelectedMediaSuccess = payload => this.setState({ selectedFileFolder: payload })
    getSelectedMediaError = error => this.requestError( error )

    getSelectedFolder = id => Get(
      `/api/v1/mfiles/folders/${ id }`,
      this.getSelectedFolderSuccess,
      this.getSelectedFolderError,
      this.load
    )
    getSelectedFolderSuccess = payload => this.setState({ selectedFileFolder: payload })
    getSelectedFolderError = error => this.requestError( error )

    createFile = ( data, id ) => Post(
      `/api/v1/mfiles/media`,
      data,
      payload => this.createFileSuccess( payload, id ),
      this.createFileError,
      this.load
    )
    createFileSuccess = (payload, id) => {
      this.setState({ showCreateModal: false })
      this.getSelectedDriveFolder( this.state.parentFolder.id )
      this.props.getSharedMediaDrive( convertObjToBase64({
        page: 1,
        is_paginated: true
      }) )
      this.setState({
        newFolderFile: {
          file_name: '',
          type: 'File',
          original_filename: '',
          parent_folder_id: 1
        },
        files: []
      })
    }
    createFileError = error => this.requestError( error )

    createFileFolder = ( data, id ) => Post(
      `/api/v1/mfiles/folders`,
      data,
      payload => this.createFileFolderSuccess( payload, id ),
      this.createFileFolderError,
      this.load
    )

    createFileFolderSuccess = (payload, id) => {
      this.getSelectedDriveFolder( id )
      this.props.getSharedMediaDrive( convertObjToBase64({
        page: 1,
        is_paginated: true
      }) )
      this.setState({ showCreateModal: false })
      this.setState({
        newFolderFile: {
          file_name: '',
          type: 'File',
          original_filename: '',
          parent_folder_id: 1
        },
        files: []
      })
    }
    createFileFolderError = error => this.requestError( error )

    updateFile = ( id, data ) => Put(
      `/api/v1/mfiles/media/${ id }`,
      data,
      this.updateFileSuccess,
      this.updateFileError,
      this.load
    )
    updateFileSuccess = payload => this.setState({ showEditModal: false }, () => {
      this.requestSuccess( 'File updated successfully.')
      this.getSelectedDriveFolder( this.state.parentFolder.id )
      this.props.getSharedMediaDrive( convertObjToBase64({
        page: 1,
        is_paginated: true
      }) )
    })
    updateFileError = error => this.requestError( error )

    updateFolder = ( id, data ) => Put(
      `/api/v1/mfiles/folders/${ id }`,
      data,
      this.updateFolderSuccess,
      this.updateFolderError,
      this.load
    )
    updateFolderSuccess = payload => this.setState({ showEditModal: false }, () => {
      this.requestSuccess( 'Folder updated successfully.')
      this.getSelectedDriveFolder( this.state.parentFolder.id )
    })
    updateFolderError = error => this.requestError( error )

    deleteFile = id => Delete(
      `/api/v1/mfiles/media/${ id }`,
      this.deleteFileSuccess,
      this.deleteFileError,
      this.load
    )
    deleteFileSuccess = payload => this.setState({ showDeleteModal: false }, () => {
      this.requestSuccess( 'File deleted successfully.')
      this.getSelectedDriveFolder( this.state.parentFolder.id )
      this.props.getSharedMediaDrive( convertObjToBase64({
        page: 1,
        is_paginated: true
      }) )
    })
    deleteFileError = error => this.requestError( error )

    deleteFolder = id => Delete(
      `/api/v1/mfiles/folders/${ id }`,
      this.deleteFolderSuccess,
      this.deleteFolderError,
      this.load
    )
    deleteFolderSuccess = payload => this.setState({ showDeleteModal: false }, () => {
      this.requestSuccess( 'Folder deleted successfully.')
      this.getSelectedDriveFolder( this.state.parentFolder.id )
    })
    deleteFolderError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            onLoadMediaDrive={ this.state.loading }
            onChangeArkMFilesHOC={ this.onChangeArkMFilesHOC }
            getMediaDrive={ this.getMediaDrive }
            getSelectedDriveFolder={ this.getSelectedDriveFolder }
            mediaDrives={ this.state.mediaDrives }
            path={ this.state.path }
            parentFolder={ this.state.parentFolder }
            activeTab={ this.state.activeTab }
            createFile={ this.createFile }
            getSelectedMedia={ this.getSelectedMedia }
            createFileFolder={ this.createFileFolder }
            getSelectedFolder={ this.getSelectedFolder }
            updateFile={ this.updateFile }
            updateFolder={ this.updateFolder }
            deleteFile={ this.deleteFile }
            deleteFolder={ this.deleteFolder }
            newFolderFile={ this.state.newFolderFile }
            selectedFileFolder={ this.state.selectedFileFolder }
            showCreateModal={ this.state.showCreateModal }
            showEditModal={ this.state.showEditModal }
            showDeleteModal={ this.state.showDeleteModal }
            files={ this.state.files } />
        </>
      )
    }
  }
  return WithHOC
}

export default HOC
