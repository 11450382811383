import React, { Component } from 'react'
import _ from 'lodash'

import HeaderMenuViewer from './HeaderMenuViewer'

import Lang from 'Lang/General'

class HeaderMenuBlueprint extends Component {
  state = {
    selectedMenu: 'Desktop Menu',
    blueprintStyle: ''
  }

  render = () => {
    return (
      <div className="container" style={{ paddingTop: '30px' }}>
        <div className="row">
          <div className="col-md-12" style={{ paddingBottom: '15px' }}>
            <button
              className="btn btn-primary"
              onClick={() => this.setState({ selectedMenu: 'Desktop Menu' }, () => this.props.onSelectHeaderBlueprint( this.state.selectedMenu ) )}>
              { Lang[ 'DESKTOP_MENU' ][ this.props.lang ] }
            </button>
            <button
              className="btn btn-success"
              onClick={() => this.setState({ selectedMenu: 'Mobile Menu' }, () => this.props.onSelectHeaderBlueprint( this.state.selectedMenu ))}>
              { Lang[ 'MOBILE_MENU' ][ this.props.lang ] }
            </button>
          </div>
          <div className="col-md-6">
            <textarea
              style={{ width: '100%' }}
              rows={ 20 }
              value={ this.props.selectedHeaderBlueprint }
              onChange={ e => {
                this.props.onChangeSelectedHeaderBlueprints( e.target.value )
              }} />
          </div>
          <div className="col-md-6">
            <HeaderMenuViewer
              templateStylesheets={ this.props.templateStylesheets }
              selectedHeaderBlueprint={ this.props.selectedHeaderBlueprint }
              languages={ this.props.languages }
              selectedLanguageShortname={ this.props.selectedLanguageShortname } />
          </div>
        </div>
      </div>
    )
  }
}

export default HeaderMenuBlueprint