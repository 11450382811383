import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Get, Put, Post, Delete } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

const AnchorLink = ( WrappedComponent ) => {
  class AnchorLinkWrappedComponent extends Component {
    state = {
      loading: false,
      selectedAnchorLink: {},
      anchorLinkData: {
        name: "",
        path: "",
        anchor_id: "",
        ols_landing_page_template_id: 0,
      },
      anchorLinkModalOpen: false,
      anchorLinkDeleteModalOpen: false,
      anchorLinks: [],
      anchorError: {}
    }

    load = param => this.setState({ loading: param })

    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    onChangeAnchorLinkHOC = ( key, val ) => this.setState({ [ key ]: val })

    getAnchorLinks = query => Get(
      `/api/v1/ols_anchor_links?query=${ convertObjToBase64( query ) }`,
      this.getAnchorLinksSuccess,
      this.getAnchorLinksError,
      this.load
    )
    getAnchorLinksSuccess = payload => this.setState({ anchorLinks: payload.data })
    getAnchorLinksError = error => this.requestError( error )

    createAnchorLink = data => Post(
      `/api/v1/ols_anchor_links`,
      data,
      this.createAnchorLinkSuccess,
      this.createAnchorLinkError,
      this.load
    )
    createAnchorLinkSuccess = payload => {
      this.requestSuccess( Lang[ 'CREATE_ANCHOR_LINK_SUCCESFULLY' ][ this.props.lang ] )
      this.setState({ 
        selectedAnchorLink: payload, 
        anchorLinkModalOpen: false, 
        anchorLinkData: { 
          name: "",
          path: "",
          anchor_id: "",
          ols_landing_page_template_id: 0
        } 
      })
      this.getAnchorLinks({ filter: { ols_landing_page_template_id: this.props.selectedPage.id } })
    }
    createAnchorLinkError = error => {
      if( typeof error === 'string' ){
        this.requestError( error )
        this.setState({ anchorError: '' })
      } else {
        this.requestError( Lang[ 'ENSURE_ANCHOR_LINK_FULFIL_REQUIREMENT' ][ this.props.lang ] )
        this.setState({ anchorError: error })
      }
    }

    updateAnchorLink = data => Put(
      `/api/v1/ols_anchor_links/${ data.id }`,
      data,
      this.updateAnchorLinkSuccess,
      this.updateAnchorLinkError,
      this.load
    )
    updateAnchorLinkSuccess = payload => {
      this.requestSuccess( Lang[ 'UPDATE_ANCHOR_LINK_SUCCESFULLY' ][ this.props.lang ] )
      this.setState({ selectedAnchorLink: payload, anchorLinkModalOpen: false })
    }
    updateAnchorLinkError = error => {
      if( typeof error === 'string' ){
        this.requestError( error )
        this.setState({ anchorError: '' })
      } else {
        this.requestError( Lang[ 'ENSURE_ANCHOR_LINK_FULFIL_REQUIREMENT' ][ this.props.lang ] )
        this.setState({ anchorError: error })
      }
    }

    removeAnchorLink = () => Delete(
      `/api/v1/ols_anchor_links/${ this.state.selectedAnchorLink.id }`,
      this.removeAnchorLinkSuccess,
      this.removeAnchorLinkError,
      this.load
    )
    removeAnchorLinkSuccess = () => {
      this.requestSuccess( Lang[ 'REMOVE_ANCHOR_LINK_SUCCESSFULLY' ][ this.props.lang ] )
      this.setState({ selectedAnchorLink: {}, anchorLinkDeleteModalOpen: false })
      this.getAnchorLinks({ filter: { ols_landing_page_template_id: this.props.selectedPage.id } })
    }
    removeAnchorLinkError = error => this.requestError( error )

    render = () => {
      return(
        <WrappedComponent
          {...this.props}
          selectedAnchorLink={ this.state.selectedAnchorLink }
          onLoadAnchorLink={ this.state.loading }
          onChangeAnchorLinkHOC={ this.onChangeAnchorLinkHOC }
          createAnchorLink={ this.createAnchorLink }
          anchorLinkData={ this.state.anchorLinkData }
          updateAnchorLink={ this.updateAnchorLink }
          removeAnchorLink={ this.removeAnchorLink }
          anchorLinkModalOpen={ this.state.anchorLinkModalOpen } 
          anchorLinkDeleteModalOpen={ this.state.anchorLinkDeleteModalOpen}
          getAnchorLinks={ this.getAnchorLinks }
          anchorLinks={ this.state.anchorLinks }
          anchorError={ this.state.anchorError }
        />
      )
    }
  }
  return AnchorLinkWrappedComponent
}
export default AnchorLink
