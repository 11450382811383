import React, { useState } from 'react'
import Radium from 'proofpoint-radium'
import ReactHTMLParser from 'react-html-parser'

const H2 = ({
  id,
  style,
  content,
  className,
  selectedLanguageShortname,
  updateMainContainerState,
  item,
  mode,
  onChangeSectionEditorState, // from section editor
  selectedEditableComponent // from section editor
}) => {
  const [ contentEditable, updateContentEditable ] = useState( false )
  return (
    <h2
      id={ id }
      style={{
        ...style,
        outline: selectedEditableComponent ? selectedEditableComponent.id === id ? '2px solid #007bff' : 'none' : 'none'
      }}
      className={ className }
      onClick={
        mode === 'edit' ? ( e ) => {
          onChangeSectionEditorState( 'selectedEditableComponent', item )
        } : () => {}
      }>
      { content && content[ selectedLanguageShortname ] ? ReactHTMLParser( content[ selectedLanguageShortname ] ) : ReactHTMLParser( content[ 'en' ] ) }
    </h2>
  )
}

export default Radium( H2 )