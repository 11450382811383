import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Post, Get, Put, Delete } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'
import { toggleMediaDatabase } from 'actions/MediaDatabaseToggle'
import Lang from 'Lang/General'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state={ 
      loading: false,
      knowledges: {
        data: [],
        meta: {}
      },
      knowledgesPages: [],
      newKnowledge: {},
      selectedKnowledge: {},
      showCreateKnowledge: false,
      showUpdateKnowledge: false,
      showKnowledgeFilesId: null,
      showDeleteKnowledgeId: 0,
      knowledgeErrorMessage: {}
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => {
      if( typeof error === 'string' ){
        toast.error( error )
      } else {
        let tmpError = _.reduce( 
          error, 
          ( res, val, key ) => ({ ...res, [ key ]: val.replaceAll( '_', ' ' ) }),
          {}
        )
        _.map( tmpError, ( err, errKey ) => toast.error( err ) )
        this.setState({ knowledgeErrorMessage: tmpError })
      }
    }

    onChangeKnowledgesHOC = ( key, value ) => this.setState({ [ key ] : value })

    createKnowledge = data => Post(
      `/api/v1/ark_member_area/knowledge_templates`,
      data,
      this.createKnowledgeSuccess,
      this.createKnowledgeError,
      this.load
    )
    createKnowledgeSuccess = () => {
      this.getKnowledges({ 
        page: 1, 
        is_paginated: true
      })
      this.requestSuccess( Lang[ 'KNOWLEDGE_IS_SUCCESSFULLY_CREATED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showCreateKnowledge: false })
    }
    createKnowledgeError = error => this.requestError( error )

    getKnowledges = query => Get(
      `/api/v1/ark_member_area/knowledge_templates?query=${ convertObjToBase64( query ) }`,
      this.getKnowledgesSuccess,
      this.getKnowledgesError,
      this.load
    )
    getKnowledgesSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      this.setState({ knowledges: payload, knowledgesPages: tmpTotalPages })
    }
    getKnowledgesError = error => this.requestError( error )

    getSelectedKnowledge = knowledge_id => Get(
      `/api/v1/ark_member_area/knowledge_templates/${ knowledge_id }`,
      this.getSelectedKnowledgeSuccess,
      this.getSelectedKnowledgeError,
      this.load
    )
    getSelectedKnowledgeSuccess = payload => this.setState({ 
      selectedKnowledge: {
        ...payload,
        center_groups: payload.center_groups.map( centerGroup => ({
          ...centerGroup,
          label: centerGroup.name,
          value: centerGroup.name
        }))
      }, 
      showUpdateKnowledge: true 
    })
    getSelectedKnowledgeError = error => this.requestError( error )

    updateKnowledge = data => Put(
      `/api/v1/ark_member_area/knowledge_templates/${ data.id }`,
      data,
      this.updateKnowledgeSuccess,
      this.updateKnowledgeError,
      this.load
    )
    updateKnowledgeSuccess = () => {
      this.getKnowledges({ 
        page: 1, 
        is_paginated: true
      })
      this.requestSuccess( Lang[ 'KNOWLEDGE_IS_SUCCESSFULLY_UPDATED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showUpdateKnowledge: false })
    }
    updateKnowledgeError = error => this.requestError( error )

    removeKnowledge = knowledge_id => Delete(
      `/api/v1/ark_member_area/knowledge_templates/${ knowledge_id }`,
      this.removeKnowledgeSuccess,
      this.removeKnowledgeError,
      this.load
    )
    removeKnowledgeSuccess = () => {
      this.getKnowledges({ 
        page: 1, 
        is_paginated: true
      })
      this.requestSuccess( Lang[ 'KNOWLEDGE_IS_SUCCESSFULLY_REMOVED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showDeleteKnowledgeId: 0 })
    }
    removeKnowledgeError = error => {
      this.requestError( error )
      this.setState({ showDeleteKnowledgeId: 0 })
    }

    render = () => {
      return(
        <WrappedComponent
          { ...this.props } 
          onLoadKnowledgesHOC={ this.state.loading }
          knowledges={ this.state.knowledges }
          knowledgesPages={ this.state.knowledgesPages }
          newKnowledge={ this.state.newKnowledge }
          selectedKnowledge={ this.state.selectedKnowledge }
          showKnowledgeFilesId={ this.state.showKnowledgeFilesId }
          showCreateKnowledge={ this.state.showCreateKnowledge }
          showUpdateKnowledge={ this.state.showUpdateKnowledge }
          showDeleteKnowledgeId={ this.state.showDeleteKnowledgeId }
          knowledgeErrorMessage={ this.state.knowledgeErrorMessage }
          onChangeKnowledgesHOC={ this.onChangeKnowledgesHOC }
          createKnowledge={ this.createKnowledge }
          getKnowledges={ this.getKnowledges }
          getSelectedKnowledge={ this.getSelectedKnowledge }
          updateKnowledge={ this.updateKnowledge }
          removeKnowledge={ this.removeKnowledge }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( 
    mapStateToProps, 
    { toggleMediaDatabase } 
  )( WithHOC )
}

export default HOC