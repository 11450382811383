import React, { Component } from 'react'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      packages: [],
      selectedPackage: {}
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showStatusModal: true,
      statusModalType: 'negative',
      onClickStatusModalButton: () => this.setState({ showStatusModal: false }),
      statusModalMessage: error
    })
    requestSuccess = success => this.setState({
      showStatusModal: true,
      statusModalType: 'positive',
      onClickStatusModalButton: () => this.setState({
        showStatusModal: false,
        errorMessage: {}
      }),
      statusModalMessage: success
    })

    getPackages = () => Get(
      `/api/v1/ms_packages`,
      this.getPackagesSuccess,
      this.getPackagesError,
      this.load
    )
    getPackagesSuccess = payload => {
      this.setState({ packages: payload })
    }
    getPackagesError = error => this.requestError( error )

    getSelectedPackage = id => Get(
      `/api/v1/ms_packages/${ id }`,
      this.getSelectedPackageSuccess,
      this.getSelectedPackageError,
      this.load
    )
    getSelectedPackageSuccess = payload => this.setState({ selectedPackage: payload })
    getSelectedPackageError = error => this.requestError( error )
    
    render = () => {
      return (
        <WrappedComponent 
          { ...this.props }
          onLoadPackages={ this.state.loading }
          packages={ this.state.packages }
          selectedPackage={ this.state.selectedPackage }
          getPackages={ this.getPackages }
          getSelectedPackage={ this.getSelectedPackage } />
      )
    }
  }
  return WithHOC
}

export default HOC