import React, { useState } from 'react'
import Radium from 'proofpoint-radium'
import ReactHTMLParser from 'react-html-parser'
import ScrollAnimation from 'react-animate-on-scroll'
import { Button, Collapse } from "reactstrap"
import _ from 'lodash'

import Utility from './Utility'
import CustomTexts from './Text'
import Lang from 'Lang/General'
import { ApplyColor } from 'utils/apply-color'

import CoreRenderFunction from '../../core/render'

const Div = ({
  item,
  children,
  id,
  alt,
  title,
  style,
  content,
  listStyle,
  variant,
  className,
  languages,
  selectedLanguageShortname,
  updateMainContainerState,
  templateHeaders,
  templateHeadersBlueprints,
  mode,
  selectedSectionID,
  hoveredSectionID,
  selectedPage,
  animation,
  selectedContainer, // come from section editor
  onChangeSectionEditorState, // come from section editor
  selectedEditableComponent, // come from section editor
  onStopDragComponent, // come from section editor
  hoveredComponentId, // come from section editor
  updateRemainOpen,
  updateSelectedPage
}) => {
  const [ hovered, updateHovered ] = useState( false )
  const [ onEditClick, setEditClick] = useState( false )
  const [ scrollKey, updateScrollKey] = useState("")
  const [ emptySectionHovered, updateEmptySectionHovered ] = useState( false )
  const [ emptySectionSelected, updateEmptySectionSelected ] = useState( false )
  const getOutlineColor = () => {
    let item = _.cloneDeep( 
      ( selectedContainer && selectedContainer.id === id ) 
        ? selectedContainer
        : hoveredComponentId
    )
    return (
      selectedSectionID && selectedSectionID === id
        ? '#ffa500'
        : item && item.className 
          ? item.className.indexOf( 'col' ) > -1
            ? '#FF0000'
            : item.className.indexOf( 'row' ) > -1
              ? '#5cb85c'
              : '#0275d8'
          : '#0275d8'
    )
  }

  const divComponent = () => (
    <>
    {
      style && style[':hover'] && mode === 'edit' && 
      <div style={{position:"absolute", top:"10px", left:"10px", zIndex:10}}>
        <Button color={onEditClick?"danger":"primary"} onClick={()=>setEditClick(!onEditClick)}>
          {onEditClick?Lang["UNHOVER"][ selectedLanguageShortname ]: Lang["HOVER"][ selectedLanguageShortname ]}
        </Button>
      </div>
    }
    <div
      id={ id }
      style={
        animation && ( mode !== 'edit' && mode !== 'section-display-edit' )
          ? {}
          : item.variant
            ? mode === 'edit' 
              ? {
                ...ApplyColor( style ),
                border: ( selectedContainer && selectedContainer.id === id ) || ( hoveredComponentId === id ) ? `5px solid ${ getOutlineColor() }` : style?.border
              } : { ...ApplyColor( style ) }
            : mode === 'edit' 
              ? {
                ...ApplyColor( style ),
                ...(style && style[':hover'] && !onEditClick ? {":hover":{}, zIndex:-1} : "" ),
                ...(style && style['backgroundImage'] && !style['backgroundPosition'] ? { "backgroundPosition": "center top" } : "" ),
                border: ( selectedContainer && selectedContainer.id === id ) || ( hoveredComponentId === id ) ? `5px solid ${ getOutlineColor() }` : style?.border
              } : { ...ApplyColor( style ) }
      }
      className={ className }
      onClick={ () => {
        if( id && id === hoveredComponentId ){
          onChangeSectionEditorState( 'selectedContainer', item )
          onChangeSectionEditorState( 'selectedEditableComponent', item )
        }
      }}
      onMouseOver={() => {
        if( variant === 'dropdown' ) {
          updateHovered( true )
          updateRemainOpen && updateRemainOpen()
        }
      }}
      onMouseLeave={() => {
        if( variant === 'dropdown' ) {
          updateHovered( false )
        }
        mode === 'edit' && onChangeSectionEditorState && onChangeSectionEditorState( 'hoveredComponentId', '' )
      }}
      data-jarallax={ className === 'jarallax' }
      data-speed="0.4">
      {
        variant && variant === 'header-sections-container' && children && children.length > 0 && children.map(( item, index ) => {
          return (
            <div
              style={{
                position: "relative",
                transition: "all 0.3s linear",
              }}>
              {
                mode === 'section-display-edit' && selectedSectionID === item.id && (
                  <div style={{position:"absolute", height:"100%", right:"50px"}}>
                    <Utility
                      from={ 'header-section' }
                      selectedPage={ selectedPage }
                      currentSection={ item }
                      updateMainContainerState={ updateMainContainerState } />
                  </div>
                )
              }
              <CoreRenderFunction
                item={ item }
                updateMainContainerState={ updateMainContainerState }
                languages={ languages }
                selectedLanguageShortname={ selectedLanguageShortname }
                templateHeaders={ templateHeaders }
                templateHeadersBlueprints={ templateHeadersBlueprints }
                selectedSectionID={ selectedSectionID }
                hoveredSectionID={ hoveredSectionID }
                mode={ mode } />
            </div>
          )
        })
      }
      {
        variant && variant === 'sections-container' && children && ( 
          children.length > 0 ? (
            children.map( item => {
              return (
                <div
                  style={{
                    position: "relative",
                    transition: "all 0.3s linear",
                  }}>
                  {
                    mode === 'section-display-edit' && selectedSectionID === item.id && (
                      <div style={{position:"absolute", height:"100%", right:"50px"}}>
                        <Utility
                          selectedPage={ selectedPage }
                          currentSection={ item }
                          updateSelectedPage={ updateSelectedPage }
                          updateMainContainerState={ updateMainContainerState }
                          selectedLanguageShortname={ selectedLanguageShortname } />
                      </div>
                    )
                  }
                  <CoreRenderFunction
                    item={ item }
                    updateMainContainerState={ updateMainContainerState }
                    languages={ languages }
                    selectedPage={ selectedPage }
                    selectedLanguageShortname={ selectedLanguageShortname }
                    templateHeaders={ templateHeaders }
                    templateHeadersBlueprints={ templateHeadersBlueprints }
                    selectedSectionID={ selectedSectionID }
                    hoveredSectionID={ hoveredSectionID }
                    mode={ mode } />
                </div>
              )
            })
          ) : (
            <div
              style={{
                position: "relative",
                transition: "all 0.3s linear",
              }}>
              {
                mode === 'section-display-edit' && emptySectionSelected && (
                  <div style={{position:"absolute", height:"100%", right:"50px"}}>
                    <Utility
                      from={ 'empty' }
                      selectedPage={ selectedPage }
                      currentSection={ item }
                      updateSelectedPage={ updateSelectedPage }
                      updateMainContainerState={ updateMainContainerState }
                      selectedLanguageShortname={ selectedLanguageShortname } />
                  </div>
                )
              }
              <div
                style={{
                  height: "350px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: ( emptySectionHovered || emptySectionSelected ) && "5px solid #0275d8"
                }}
                onMouseEnter={ () => mode === 'section-display-edit' && updateEmptySectionHovered( true ) }
                onMouseLeave={ () => mode === 'section-display-edit' && updateEmptySectionHovered( false ) }
                onClick={ () => mode === 'section-display-edit' && updateEmptySectionSelected( !emptySectionSelected ) }>
                { Lang[ 'BLANK_SECTION_MESSAGE' ][ selectedLanguageShortname ] }
              </div>
            </div>
          )
        )
      }
      {
        variant && variant === 'dropdown' && children.map( dom => {
          if( dom.type === 'a' ) {
            return <CoreRenderFunction
              item={ dom }
              mode={ mode }
              selectedLanguageShortname={ selectedLanguageShortname }
            />
          } else if( dom.type === 'div' ) {
            return <div
              key={ dom.id }
              style={{
                ...ApplyColor( dom.style ),
                display: hovered ? dom.children.length > 0 ? 'block' : 'none' : 'none'
              }}>
              {
                dom.children && dom.children.length > 0 && dom.children.map( item => {
                  return (
                    <CoreRenderFunction
                      item={ item }
                      updateMainContainerState={ updateMainContainerState }
                      languages={ languages }
                      selectedLanguageShortname={ selectedLanguageShortname }
                      templateHeaders={ templateHeaders }
                      templateHeadersBlueprints={ templateHeadersBlueprints } />
                  )
                })
              }
            </div>
          }
        })
      }
      {
        variant && variant === 'accordion' && children.map( item => {
          return (
            <CoreRenderFunction
              item={ item }
              updateMainContainerState={ updateMainContainerState }
              languages={ languages }
              selectedLanguageShortname={ selectedLanguageShortname }
              templateHeaders={ templateHeaders }
              templateHeadersBlueprints={ templateHeadersBlueprints } />
          )
        })
      }
      {
        !variant && children && children.length > 0 && children.map( item => {
          return (
            item.showButton === false 
            ? <></> 
            : <CoreRenderFunction
              item={ item }
              updateMainContainerState={ updateMainContainerState }
              languages={ languages }
              selectedLanguageShortname={ selectedLanguageShortname }
              templateHeaders={ templateHeaders }
              templateHeadersBlueprints={ templateHeadersBlueprints }
              hoveredSectionID={ hoveredSectionID }
              mode={ mode }
              selectedPage={ selectedPage }
              onChangeSectionEditorState={ onChangeSectionEditorState } // from section editor
              selectedEditableComponent={ selectedEditableComponent } // from section editor
              selectedContainer={ selectedContainer } // from section editor
              onStopDragComponent={ onStopDragComponent } // from section editor 
              hoveredComponentId={ hoveredComponentId } // from section editor
            />
          )
        })
      }
      {
        variant === 'header-section'
        && children && children.length > 0
        && children.map( section => {
          return (
            <div
              style={{
                border: mode === 'section-display-edit' && 
                  (( selectedSectionID && selectedSectionID === section.id ) ||
                  ( hoveredSectionID && hoveredSectionID === section.id )) 
                  ? '2px solid #545cd8' : '',
              }}
              onMouseEnter={() => {
                if( mode && mode === 'section-display-edit' ) {
                  updateMainContainerState( 'hoveredSectionID', section.id )
                }
              }}
              onMouseLeave={() => {
                if( mode && mode === 'section-display-edit' ) {
                  updateMainContainerState( 'hoveredSectionID', '' )
                }
              }}
              onClick={() => {
                if( mode && mode === 'section-display-edit' && selectedSectionID === section.id ) {
                  updateMainContainerState( 'selectedSectionID', section.id )
                } else if ( mode && mode === 'section-display-edit' ) {
                  updateMainContainerState( 'selectedSectionID', '' )
                }
              }}>
              {
                section.id === selectedSectionID && (
                  <div style={{position:"absolute", height:"100%", right:"50px"}}>
                    <Utility
                      from={ 'header-section' }
                      selectedPage={ selectedPage }
                      currentSectionID={ section.id }
                      updateMainContainerState={ updateMainContainerState } />
                  </div>
                )
              }
              <CoreRenderFunction
                item={ section }
                mode={ mode }
                selectedLanguageShortname={ selectedLanguageShortname }
                templateHeaders={ templateHeaders }
                templateHeadersBlueprints={ templateHeadersBlueprints }
                selectedPage={ selectedPage }
                onChangeSectionEditorState={ onChangeSectionEditorState } // from section editor
                selectedEditableComponent={ selectedEditableComponent } // from section editor
                selectedContainer={ selectedContainer } // from section editor
                onStopDragComponent={ onStopDragComponent } // from section editor
                hoveredComponentId={ hoveredComponentId }
                updateMainContainerState={ updateMainContainerState }
                hoveredSectionID={ hoveredSectionID }
                selectedSectionID={ selectedSectionID }
              />
            </div>
          )
        })
      }
      {
        variant && variant === 'text' && (
          <CustomTexts
            mode={ mode }
            content={ content }
            listStyle={ listStyle }
            selectedLanguageShortname={ selectedLanguageShortname }
            onChangeSectionEditorState={ onChangeSectionEditorState }
            id={ id }
            item={ item }
            selectedContainer={ selectedContainer }
            hoveredComponentId={ hoveredComponentId } />
        )
      }
      {
        !variant && ( alt || title ) && (
          <div
            alt={ alt ? alt : '' }
            title={ title ? title : '' }
            onClick={() => {
              if( mode === 'edit' && item.variant === 'text' ) {
                onChangeSectionEditorState && onChangeSectionEditorState( 'selectedEditableComponent', item )
              }
            }}
            style={{
              outline: ( selectedContainer && mode === 'edit' && selectedContainer.id === id ) || ( mode === 'edit' && hoveredComponentId === id ) ? `5px solid ${ getOutlineColor() }` : 'none'
            }}>
            {
              content && content[ selectedLanguageShortname ]
                ? ReactHTMLParser(
                  item.type === 'opening_hours'
                    ? content[ 'en' ]
                    : ApplyColor( content[ selectedLanguageShortname ], true )
                ) : ''
            }
          </div>
        )
      }
      {
        !variant && !alt && !title && (
          <div
            onClick={() => {
              if( mode === 'edit' && item.variant === 'text' ) {
                onChangeSectionEditorState && onChangeSectionEditorState( 'selectedEditableComponent', item )
              }
            }}
            style={{
              outline: ( selectedContainer && mode === 'edit' && selectedContainer.id === id ) || ( mode === 'edit' && hoveredComponentId === id ) ? `5px solid ${ getOutlineColor() }` : 'none'
            }}>
            {
              content && content[ selectedLanguageShortname ]
                ? ReactHTMLParser(
                  item.type === 'opening_hours'
                    ? content[ 'en' ]
                    : ApplyColor( content[ selectedLanguageShortname ], true )
                ) : ''
            }
          </div>
        )
      }
      {
        variant && variant === 'divider' && (
          <div 
            onMouseEnter={ () => mode === 'edit' && onChangeSectionEditorState && onChangeSectionEditorState( 'hoveredComponentId', id ) }
            onMouseLeave={ () => mode === 'edit' && onChangeSectionEditorState && onChangeSectionEditorState( 'hoveredComponentId', '' ) }
            onClick={() => {
              if( mode === 'edit' && item.variant === 'divider' ) {
                onChangeSectionEditorState && onChangeSectionEditorState( 'selectedEditableComponent', item )
                onChangeSectionEditorState && onChangeSectionEditorState( 'selectedContainer', item) 
              }
            }}
            style={ item.style }>
          </div>
        )
      }
      {
        variant && variant === 'collapse' && (
          <Collapse 
            style={ item.style }
            isOpen={item.isOpen}>
              {
                children&&children.length>0&&children.map((item)=>{
                  return(
                    <CoreRenderFunction
                      item={ item }
                      mode={ mode }
                      selectedLanguageShortname={ selectedLanguageShortname }
                      templateHeaders={ templateHeaders }
                      templateHeadersBlueprints={ templateHeadersBlueprints }
                      selectedPage={ selectedPage }
                      onChangeSectionEditorState={ onChangeSectionEditorState } // from section editor
                      selectedEditableComponent={ selectedEditableComponent } // from section editor
                      selectedContainer={ selectedContainer } // from section editor
                      onStopDragComponent={ onStopDragComponent } // from section editor
                      hoveredComponentId={ hoveredComponentId }
                      updateMainContainerState={ updateMainContainerState }
                      hoveredSectionID={ hoveredSectionID }
                      selectedSectionID={ selectedSectionID }
                    />
                  )
                })
              }
          </Collapse>
        )
      }
    </div>
    </>
  )
  return animation && !_.isEmpty( animation ) && mode === 'view' ? (
    <div
      style={{
        ..._.pickBy( ApplyColor( style ), ( value, key ) => key.indexOf( '@media' ) > -1 ),
        width: "100%"
      }}>
      <ScrollAnimation
        key={scrollKey}
        animateIn={ animation }
        initiallyVisible={ false }
        scrollableParentSelector={ window.getComputedStyle(document.body.parentElement).overflowX === "hidden" ? "body" : '' }
        style={{
          ...ApplyColor( style ),
          border: ( selectedContainer && mode === 'edit' && selectedContainer.id === id ) || ( mode === 'edit' && hoveredComponentId === id ) ? `5px solid ${ getOutlineColor() }` : 'none'
        }}>
        { divComponent() }
      </ScrollAnimation>
    </div>
  ) : (
    divComponent()
  )
}

export default Radium( Div )