const Lang = {
  CLIENT: {
    en: "Client",
    de: "Kunde",
    it: "Cliente",
    nl: "Klant"
  },
  SELECT_CLIENT: {
    en: "select a client",
    de: "Wählen Sie einen Kunden",
    it: "selezionare un cliente",
    nl: "een klant selecteren"
  },
  CLIENT_IS_REQUIRED: {
    en: "Client is required.",
    de: "Kunde ist erforderlich.",
    it: "Il cliente è richiesto.",
    nl: "De klant is verplicht."
  },
  CLIENT_NUMBER: {
    en: "Client number",
    de: "Kundennummer",
    it: "Numero cliente",
    nl: "Klantennummer"
  },
  CONSULTANT: {
    en: "Consultant",
    de: "Berater",
    it: "Consulente",
    nl: "Consultant"
  },
  SELECT_CONSULTANT: {
    en: "select a consultant",
    de: "Wählen Sie einen Berater",
    it: "seleziona un consulente",
    nl: "een adviseur selecteren"
  },
  CONSULTANT_REQUIRED: {
    en: "Consultant is required",
    de: "Ein Berater ist erforderlich",
    it: "È richiesto un consulente",
    nl: "Consultant is nodig"
  },
  STATUS: {
    en: "Status",
    de: "Status",
    it: "Stato",
    nl: "Status"
  },
  EXTRA_FIELD_1: {
    en: "Extra Field 1",
    de: "Extra-Feld 1",
    it: "Campo extra 1",
    nl: "Extra veld 1"
  },
  EXTRA_FIELD_2: {
    en: "Extra Field 2",
    de: "Extra-Feld 2",
    it: "Campo extra 2",
    nl: "Extra veld 2"
  },
  SALUTATION: {
    en: "Salutation",
    de: "Anrede",
    it: "Saluto",
    nl: "Aanhef"
  },
  ENTER_CLIENT_NUMBER: {
    en: "Enter client number",
    de: "Kundennummer eingeben",
    it: "Inserire il numero del cliente",
    nl: "Voer het klantnummer in"
  },
  FIRST_NAME: {
    en: "First name",
    de: "Vorname",
    it: "Nome",
    nl: "Voornaam"
  },
  ENTER_FIRST_NAME: {
    en: "Enter first name",
    de: "Vorname eingeben",
    it: "Inserire il nome",
    nl: "Voer de voornaam in"
  },
  LAST_NAME: {
    en: "Last Name",
    de: "Nachname",
    it: "Cognome",
    nl: "Achternaam"
  },
  ENTER_LAST_NAME: {
    en: "Enter last name",
    de: "Nachname eingeben",
    it: "Inserire il cognome",
    nl: "Vul de achternaam in"
  },
  CENTER_NAME: {
    en: "Center name",
    de: "Name des Centers",
    it: "Nome del centro",
    nl: "Naam van het centrum"
  },
  ENTER_CENTER_NAME: {
    en: "Enter center name",
    de: "Centername eingeben",
    it: "Inserire il nome del centro",
    nl: "Voer de naam van het centrum in"
  },
  STREET: {
    en: "Street",
    de: "Straße",
    it: "Via",
    nl: "Straat"
  },
  HOUSE_NUMBER: {
    en: "House number",
    de: "Hausnummer",
    it: "Numero civico",
    nl: "Huisnummer"
  },
  POSTCODE: {
    en: "Postcode",
    de: "Postleitzahl",
    it: "Codice postale",
    nl: "Postcode"
  },
  CITY: {
    en: "City",
    de: "Stadt",
    it: "Città",
    nl: "Stad"
  },
  COUNTRY: {
    en: "Country",
    de: "Land",
    it: "Paese",
    nl: "Land"
  },
  STATE: {
    en: "State",
    de: "Staat",
    it: "Stato",
    nl: "Geef aan"
  },
  TELFAX: {
    en: "Telefax",
    de: "Telefax",
    it: "Telefax",
    nl: "Telefax"
  },
  EMAIL: {
    en: "Email",
    de: "E-Mail",
    it: "Invia un'e-mail a",
    nl: "E-mail"
  },
  EMAIL_FOR_INVOICE: {
    en: "Email Address for Invoice",
    de: "Email Adresse für Rechnungen",
    it: "Indirizzo e-mail per le fatture",
    nl: "Emailadres voor facturen"
  },
  TAX_NUMBER: {
    en: "Tax Number",
    de: "UstIdNr. oder Steuernummer",
    it: "UstIdNr. o codice fiscale",
    nl: "UstIdNr. of belastingnummer"
  },
  MANAGER: {
    en: "Manager",
    de: "Leiter",
    it: "Manager",
    nl: "Manager"
  },
  LANGUAGE: {
    en: "Language",
    de: "Sprache",
    it: "Lingua",
    nl: "Taal"
  },
  DEFAULT_LANGUAGE: {
    en: "Default language",
    de: "Standardsprache",
    it: "Lingua predefinita",
    nl: "Standaard taal"
  },
  ENGLISH: {
    en: "English",
    de: "Englisch",
    nl: "Engels",
    it: "Englisch"
  },
  GERMAN: {
    en: "German",
    de: "Deutsch",
    nl: "Duits",
    it: "Tedesco"
  },
  SPANISH: {
    en: "Spanish",
    de: "Spanisch",
    nl: "Spaans",
    it: "Spagnolo"
  },
  FRENCH: {
    en: "French",
    de: "Französisch",
    nl: "Frans",
    it: "Francese"
  },
  ITALIAN: {
    en: "Italian",
    de: "Italienisch",
    nl: "Italiaans",
    it: "Italian"
  },
  DUTCH: {
    en: "Dutch",
    de: "Niederländisch",
    nl: "Nederlands",
    it: "Olandese"
  },
  WOMAN_ONLY: {
    en: "Woman only",
    de: "Frauenstudio",
    it: "Solo donne",
    nl: "Alleen voor vrouwen"
  },
  ENTER_STREET: {
    en: "Enter street",
    de: "Straße eingeben",
    it: "Entra in strada",
    nl: "Ga de straat in"
  },
  ENTER_PHONE_NUMBER: {
    en: "Enter phone number",
    de: "Telefonnummer eingeben",
    it: "Inserire il numero di telefono",
    nl: "Voer het telefoonnummer in"
  },
  ENTER_FAX_NUMBER: {
    en: "Enter fax number",
    de: "Faxnummer eingeben",
    it: "Inserire il numero di fax",
    nl: "Faxnummer invoeren"
  },
  ENTER_TAX_NUMBER: {
    en: "Enter tax number",
    de: "UstIdNr. oder Steuernummer eingeben",
    it: "Inserisci il codice fiscale",
    nl: "Belastingnummer invoeren"
  },
  ENTER_EMAIL: {
    en: "Enter email",
    de: "E-Mail eingeben",
    it: "Inserisci l'e-mail",
    nl: "Voer e-mail in"
  },
  ENTER_MANAGER: {
    en: "Enter manager",
    de: "Manager eingeben",
    it: "Inserisci manager",
    nl: "Voer manager in"
  },
  MR: {
    en: "Mr",
    de: "Herr",
    it: "Sig.",
    nl: "De heer"
  },
  MRS: {
    en: "Mrs",
    de: "Frau",
    it: "Signora",
    nl: "Mevrouw"
  },
  MR_AND_MRS: {
    en: "Mr and Mrs",
    de: "Herr und Frau",
    it: "Signor e signora",
    nl: "Meneer en mevrouw"
  },
  PLEASE_SELECT_ONE: {
    en: "Please select one from below",
    de: "Bitte wählen Sie eine der folgenden Optionen",
    it: "Si prega di selezionare una delle seguenti opzioni",
    nl: "Selecteer er één van hieronder"
  },
  CATEGORIES: {
    en: "Categories",
    de: "Kategorien",
    it: "Categorie",
    nl: "Categorieën"
  },
  ENTER_LOCATION_NAME: {
    en: "Enter location name",
    de: "Standortname eingeben",
    it: "Inserisci il nome della località",
    nl: "Naam locatie invoeren"
  },
  ENTER_EXTRA_FIELD_1: {
    en: "Enter extra field 1",
    de: "Extra-Feld 1 eingeben",
    it: "Inserisci il campo extra 1",
    nl: "Voer extra veld 1 in"
  },
  ENTER_EXTRA_FIELD_2: {
    en: "Enter extra field 2",
    de: "Extra-Feld 2 eingeben",
    it: "Inserisci il campo extra 2",
    nl: "Vul extra veld 2 in"
  },
  SELECT_COUNTRY: {
    en: "Select a country",
    de: "Wählen Sie ein Land",
    it: "Seleziona un paese",
    nl: "Selecteer een land"
  },
  SELECT_STATE: {
    en: "Select a state",
    de: "Wählen Sie einen Zustand",
    it: "Seleziona uno stato",
    nl: "Selecteer een staat"
  },
  CREATE_LOCATION: {
    en: "Create Location",
    de: "Standort erstellen",
    it: "Creare la posizione",
    nl: "Creëer locatie"
  },
  CHOOSE_COUNTRY: {
    en: "Choose a country",
    de: "Wählen Sie ein Land",
    it: "Scegliere un paese",
    nl: "Kies een land"
  },
  CENTER_GROUP: {
    en: "Center Groups",
    de: "Center Gruppe",
    it: "Centro Gruppi",
    nl: "Center Groepen"
  },
  GENERAL: {
    en: "General",
    de: "Allgemein",
    it: "Generale",
    nl: "Algemeen"
  },
  LOCATION: {
    en: "Location",
    de: "Standort",
    it: "Posizione",
    nl: "Locatie"
  },
  UPDATE: {
    en: "Update",
    de: "Aktualisieren",
    it: "Aggiorna",
    nl: "Update"
  },
  UPDATED_ON: {
    en: "Updated On",
    de: "Aktualisiert am",
    it: "Aggiornato il",
    nl: "Bijgewerkt op"
  },
  ADD_NEW_LOCATION: {
    en: "Add New Location",
    de: "Neuen Standort hinzufügen",
    it: "Aggiungi nuova posizione",
    nl: "Nieuwe locatie toevoegen"
  },
  OPENING_HOUR_CREATE_SUCCESS: {
    en: "Opening hour is created successfully.",
    de: "Die Öffnungszeit wurde erfolgreich erstellt.",
    it: "L'ora di apertura viene creata con successo.",
    nl: "Openingstijd is succesvol aangemaakt."
  },
  OPENING_HOUR_UPDATE_SUCCESS: {
    en: "Opening hour is updated successfully.",
    de: "Die Öffnungszeit wurde erfolgreich aktualisiert.",
    it: "L'ora di apertura viene aggiornata con successo.",
    nl: "De openingstijd is met succes bijgewerkt."
  },
  TENNIS_SCHOOL_SETTINGS: {
    en: "Tennis School Settings",
    de: "Tennisschule Einstellungen",
    it: "Impostazioni della scuola di tennis",
    nl: "Tennisschool Instellingen"
  },
  INPUT_SCHOOL_ID: {
    en: "Input School ID",
    de: "Schul-ID eingeben",
    it: "Inserisci ID scuola",
    nl: "Voer School-ID in"
  },
  SUBMIT: {
    en: "Submit",
    de: "Senden",
    it: "Invia",
    nl: "Indienen"
  },
  SUBMITTED_ON: {
    en: "Submitted On",
    de: "Eingereicht am",
    it: "Inviato il",
    nl: "Ingediend op"
  },
  UPDATE_TEPE_SETTINGS_SUCCESS: {
    en: "Tennis school settings is updated successfully.",
    de: "Die Einstellungen der Tennisschule wurden erfolgreich aktualisiert.",
    it: "Le impostazioni della scuola di tennis sono state aggiornate correttamente.",
    nl: "De instellingen van de tennisschool zijn succesvol bijgewerkt."
  },
  OLS_TEAM_USER: {
    en: "OLS Team User",
    de: "Online Mitarbeiter",
    it: "Utente del team OLS",
    nl: "OLS Team Gebruiker"
  },
  SELECT_OLS_TEAM_USER: {
    en: "Select a OLS Team user",
    de: "Wählen Sie einen OLS-Team-Benutzer",
    it: "Selezioni un utente di OLS Team",
    nl: "Selecteer een OLS Team gebruiker"
  },
  OLS_TEAM_USER_REQUIRED: {
    en: "OLS Team User is required.",
    de: "OLS Team User ist erforderlich.",
    it: "È richiesto un utente del team OLS.",
    nl: "OLS Team Gebruiker is vereist."
  },
  NEW_CENTER: {
    en: "New Center",
    de: "Neues Center",
    it: "Nouvo Centro",
    nl: "Nieuw Centrum"
  },
  CREATE: {
    en: "Create",
    de: "Erstellen",
    it: "Crea",
    nl: "Creëer"
  },
  CANCEL: {
    en: "Cancel",
    de: "Abbrechen",
    it: "Annulla",
    nl: "Annuleer"
  },
  ACTION: {
    en: "Action",
    de: "Aktion",
    it: "Azione",
    nl: "Actie"
  },
  EDIT: {
    en: "Edit",
    de: "Bearbeiten",
    it: "Modifica",
    nl: "Bewerk"
  },
  CENTER_DETAILS: {
    en: "Center details",
    de: "Centerdetails",
    it: "Dettagli del centro",
    nl: "Gegevens van het centrum"
  },
  EDIT_CENTER: {
    en: "Edit Center",
    de: "Center barbeiten",
    it: "Modifica Centro",
    nl: "Bewerkingscentrum"
  },
  ARCHIVED_CENTER_LIST: {
    en: "Archived Center List",
    de: "Archivierte Center",
    it: "Elenco dei centri archiviati",
    nl: "Gearchiveerde Centrum Lijst"
  },
  ARCHIVED_CENTERS: {
    en: "Archived centers",
    de: "Archivierte Center",
    it: "Centri archiviati",
    nl: "Gearchiveerde centra"
  },
  SEARCH_WITH_CENTER_NAME: {
    en: "Search with center name",
    de: "Suche mit dem Namen des Centers",
    it: "Ricerca con nome del centro",
    nl: "Zoeken met centernaam"
  },
  SEARCH_BY_CONSULTANT: {
    en: "Search by consultant",
    de: "Suche nach Berater",
    it: "Ricerca per consulente",
    nl: "Zoeken op consultant"
  },
  SEARCH_BY_CLIENT: {
    en: "Search by client",
    de: "Suche nach Kunden",
    it: "Ricerca per cliente",
    nl: "Zoeken op klant"
  },
  CONFIRMATION_TEXT_ARCHIVED: {
    en: "Are you sure you want to unarchive this center? Its status will be changed to Active after this.",
    de: "Sind Sie sicher, dass Sie dieses Centers aus dem Archiv holen wollen? Der Status wird danach auf Aktiv geändert.",
    it: "Siete sicuri di voler disarmare questo centro? Il suo stato verrà poi modificato in Attivo.",
    nl: "Weet je zeker dat je dit centrum wilt ontrafelen? Zijn status zal hierna worden gewijzigd in Actief."
  },
  CONFIRMATION_TEXT: {
    en: "Are you sure you want to archive this center? Its status will be changed to Inactive after this.",
    de: "Sind Sie sicher, dass Sie dieses Center archivieren wollen? Der Status wird danach auf Inaktiv geändert.",
    it: "Sei sicuro di voler archiviare questo centro? Il suo stato verrà poi modificato in Inattivo.",
    nl: "Weet u zeker dat u dit centrum wilt archiveren? Zijn status zal hierna worden gewijzigd in Inactief."
  },
  CENTER_DOMAINS_CONFIG: {
    en: "Center domains config",
    de: "Center-Domains konfigurieren",
    it: "Configurazione dei domini centrali",
    nl: "Center domeinen configureren"
  },
  VIEW_CENTER: {
    en: "View center",
    de: "Centerdetails",
    it: "Visualizza centro",
    nl: "Bekijk centrum"
  },
  UPDATE_CENTER: {
    en: "Update center",
    de: "Center bearbeiten",
    it: "Modifica centro",
    nl: "Bewerk centrum"
  },
  UPDATE_LEAD_CONNECT: {
    en: "Setup lead center ID",
    de: "Lead Center-ID einrichten",
    it: "Configurazione lead center ID",
    nl: "Instelling lead center ID"
  },
  NAME: {
    en: "Name",
    de: "Name",
    it: "Nome",
    nl: "Naam"
  },
  UNARCHIVE_CENTER: {
    en: "Unarchive center",
    de: "Archivierung rückgangig machen",
    it: "Disarchiviare il centro",
    nl: "Unarchive centrum"
  },
  CLIENT_NAME: {
    en: "Client Name",
    de: "Kunde Name",
    it: "Nome cliente",
    nl: "Naam klant"
  },
  CONSULTANT_NAME: {
    en: "Consultant Name",
    de: "Berater Name",
    it: "Nome del consulente",
    nl: "Naam consultant"
  },
  CENTER: {
    en: "Center",
    de: "Center",
    it: "Centro",
    nl: "Centrum"
  },
  CENTER_LISTING: {
    en: "Center Listing",
    de: "Center Auflistung",
    it: "Lista del centro",
    nl: "Centrum Lijst"
  },
  WOMAN_CENTER: {
    en: "Woman Center",
    de: "Frauen Studio",
    it: "Centro Donna",
    nl: "Vrouwen Centrum"
  },
  SITE_DOMAIN: {
    en: "Site Domain",
    de: "Site Domain",
    it: "Dominio del sito",
    nl: "Site Domein"
  },
  UPDATE_LEAD_CONNECT_INFO: {
    en: "Update lead connect information",
    de: "Aktualisieren von Lead-Connect-Informationen",
    it: "Aggiornare le informazioni di connessione del piombo",
    nl: "Update lead connect informatie"
  },
  LEAD_CONNECT_ID: {
    en: "Lead Connect ID",
    de: "Leiter Connect ID",
    it: "Lead Connect ID",
    nl: "Lead Connect ID"
  },
  UPDATE_DOMAIN: {
    en: "Update domain",
    de: "Domain aktualisieren",
    it: "Aggiorna il dominio",
    nl: "Update domein"
  },
  REDIRECT_TO: {
    en: "NGINX directives",
    de: "NGINX-Direktiven",
    it: "Direttive NGINX",
    nl: "NGINX richtlijnen"
  },
  NEED_TEST_DOMAIN: {
    en: "Need test domain?",
    de: "Sie benötigen eine Test-Domain?",
    it: "Hai bisogno di un dominio di prova?",
    nl: "Test domein nodig?"
  },
  DEFAULT_DOMAIN: {
    en: "Default domain",
    de: "Standard-Domain",
    it: "Dominio predefinito",
    nl: "Default domein"
  },
  SET_DEFAULT: {
    en: "Set default",
    de: "Standard einstellen",
    it: "Imposta predefinita",
    nl: "Standaard instellen"
  },
  PROCESS_LOGS: {
    en: "Process Logs",
    de: "Prozess-Logs",
    it: "Registri di processo",
    nl: "Proceslogboeken"
  },
  CONFIRMATION_REMOVE_TEXT: {
    en: "Are you sure to remove the record?",
    de: "Sind Sie sicher, dass Sie dieses Center archivieren wollen?",
    it: "Sei sicuro di voler archiviare questo centro?",
    nl: "Weet u zeker dat u dit centrum wilt archiveren?"
  },
  CENTER_DOMAIN_CREATE_SUCCESS: {
    en: "Center domain is created successfully",
    de: "Center-Domäne wurde erfolgreich erstellt",
    it: "Il dominio centrale è stato creato con successo",
    nl: "Center domein is succesvol aangemaakt"
  },
  CENTER_DOMAIN_UPDATE_SUCCESS: {
    en: "Center domain is updated successfully",
    de: "Center-Domäne wurde erfolgreich aktualisiert",
    it: "Il dominio centrale viene aggiornato con successo",
    nl: "Centrumdomein is succesvol bijgewerkt"
  },
  CENTER_DOMAIN_REMOVE_SUCCESS: {
    en: "Center domain is removed successfully",
    de: "Center-Domäne wurde erfolgreich entfernt",
    it: "Il dominio centrale viene rimosso con successo",
    nl: "Center domein is succesvol verwijderd"
  },
  CENTER_CREATE_SUCCESS: {
    en: "Center is created successfully",
    de: "Center wurde erfolgreich erstellt",
    it: "Il centro viene creato con successo",
    nl: "Centrum is succesvol gemaakt"
  },
  CENTER_UPDATE_SUCCESS: {
    en: "Center is updated successfully",
    de: "Center wurde erfolgreich aktualisiert",
    it: "Il centro viene aggiornato con successo",
    nl: "Centrum is succesvol bijgewerkt"
  },
  CENTER_STATUS_UPDATE_SUCCESS: {
    en: "Center status is updated successfully!",
    de: "Center-Status wurde erfolgreich aktualisiert!",
    it: "Lo stato del centro è stato aggiornato con successo!",
    nl: "Center status is succesvol bijgewerkt!"
  },
  CENTER_LOCATION_CREATE_SUCCESS: {
    en: "Center location is created successfully",
    de: "Standort wurde erfolgreich angelegt",
    it: "La posizione del centro è stata creata con successo",
    nl: "Centrumlocatie is succesvol aangemaakt"
  },
  CENTER_LOCATION_UPDATE_SUCCESS: {
    en: "Center location is updated successfully",
    de: "Centerstandort wurde erfolgreich aktualisiert",
    it: "La posizione del centro viene aggiornata con successo",
    nl: "Centrumlocatie is succesvol bijgewerkt"
  },
  CENTER_LOCATION_REMOVE_SUCCESS: {
    en: "Center location is removed successfully",
    de: "Center Standort wurde erfolgreich entfernt",
    it: "La posizione centrale viene rimossa con successo",
    nl: "Centrumlocatie is succesvol verwijderd"
  },
  LEAD_CONNECT_ID_UPDATE_SUCCESS: {
    en: "Lead Connect ID is updated successfully!",
    de: "Lead Connect ID wurde erfolgreich aktualisiert!",
    it: "Lead Connect ID è stato aggiornato con successo!",
    nl: "Lead Connect ID is succesvol bijgewerkt!"
  },
  NO_ARCHIVED_CENTERS: {
    en: "No archived centers",
    de: "Keine archivierten Center",
    it: "Nessun centro archiviato",
    nl: "Geen gearchiveerde centra"
  },
  NO_RESULT_FOUND: {
    en: "No result found",
    de: "Kein Ergebnis gefunden",
    it: "Nessun risultato trovato",
    nl: "Geen resultaat gevonden"
  },
  FAILED_TO_GENERATE_NGINX_CONFIG: {
    en: "Failed to generate the Nginx config for the domain provided, please provide an alternate domain to regenerate",
    de: "Die Nginx-Konfiguration für die angegebene Domäne konnte nicht generiert werden, bitte geben Sie eine alternative Domäne zum erneuten Generieren an",
    it: "Impossibile generare la configurazione di Nginx per il dominio fornito, fornire un dominio alternativo da rigenerare",
    nl: "Het is niet gelukt om de Nginx configuratie voor het opgegeven domein te genereren, geef een alternatief domein op om te regenereren"
  },
  REGENERATE_NGINX_CONFIG: {
    en: "Regenerate Nginx config",
    de: "Nginx-Konfiguration neu generieren",
    it: "Rigenerare la configurazione di Nginx",
    nl: "Nginx configuratie opnieuw genereren"
  },
  NGINX_CONFIG_REGENERATED: {
    en: "Nginx config regenerated",
    de: "Nginx-Konfiguration neu generiert",
    it: "Configurazione Nginx rigenerata",
    nl: "Nginx config geregenereerd"
  },
  DESCRIPTION: {
    en: "Description",
    de: "Beschreibung",
    it: "Descrizione",
    nl: "Beschrijving"
  },
  ENTER_CENTER_GROUP_NAME: {
    en: "Enter center group name",
    de: "Name der Center Gruppe eingeben",
    it: "Inserire il nome del gruppo del centro",
    nl: "Voer de naam van de middengroep in"
  },
  ENTER_DESCRIPTION: {
    en: "Enter description",
    de: "Beschreibung eingeben",
    it: "Inserire la descrizione",
    nl: "Vul de beschrijving in"
  },
  ARCHIVED_CENTER_GROUP: {
    en: "Archived Center Group",
    de: "Archivierte Center Gruppe",
    it: "Gruppo Centro Archiviato",
    nl: "Gearchiveerde Centrumgroep"
  },
  NEW_CENTER_GROUP: {
    en: "New Center Group",
    de: "Neue Center Gruppe",
    it: "Nuovo Centro Gruppo",
    nl: "Nieuwe Centrumgroep"
  },
  CENTER_GROUP_DETAILS: {
    en: "Center Group Details",
    de: "Details zur Center Gruppe",
    it: "Dettagli del gruppo del centro",
    nl: "Centrum Groepsdetails"
  },
  ARCHIVED_CENTER_GROUP_LIST: {
    en: "Archived Center Group List",
    de: "Liste der archivierten Center Gruppen",
    it: "Elenco dei gruppi del Centro Archiviato",
    nl: "Gearchiveerde Centrum Groepslijst"
  },
  SEARCH_WITH_NAME: {
    en: "Search with name",
    de: "Suche mit Namen",
    it: "Ricerca con nome",
    nl: "Zoek met naam"
  },
  DELETE_CONFIRMATION_ARCHIVED: {
    en: "Are you sure you want to unarchive this center group?",
    de: "Sind Sie sicher, dass Sie diese Center Gruppe aus dem Archiv holen wollen?",
    it: "Siete sicuri di voler eliminare questo gruppo del centro?",
    nl: "Weet je zeker dat je deze centrumgroep wilt ontrafelen?"
  },
  EDIT_CENTER_GROUP: {
    en: "Edit Center Group",
    de: "Center Gruppe bearbeiten",
    it: "Modifica gruppo del centro",
    nl: "Redactiecentrum Groep"
  },
  DELETE_CONFIRMATION: {
    en: "Are you sure you want to archive this center group?",
    de: "Sind Sie sicher, dass Sie diese Center Gruppe archivieren wollen?",
    it: "Sei sicuro di voler archiviare questo gruppo del centro?",
    nl: "Weet u zeker dat u deze centrumgroep wilt archiveren?"
  },
  VIEW_CENTER_GROUP: {
    en: "View center group",
    de: "Ansicht Center-Gruppe",
    it: "Visualizza il gruppo del centro",
    nl: "Bekijk centrum groep"
  },
  UPDATE_CENTER_GROUP: {
    en: "Update center group",
    de: "Update Center-Gruppe",
    it: "Aggiorna il gruppo del centro",
    nl: "Bijwerken centrum groep"
  },
  ARCHIVE_CENTER_GROUP: {
    en: "Archive center group",
    de: "Archiv Center-Gruppe",
    it: "Gruppo del centro di archiviazione",
    nl: "Archief centrum groep"
  },
  UNARCHIVE_CENTER_GROUP: {
    en: "Unarchive center group",
    de: "Mittelgruppe unarchivieren",
    it: "Disarchivia il gruppo centrale",
    nl: "Unarchiveer centrum groep"
  },
  ALL_CENTER_GROUPS: {
    en: "All Center Groups",
    de: "Alle Center Gruppe",
    it: "Tutto il Gruppo Centro",
    nl: "All Centrumgroep"
  },
  CENTER_GROUPS: {
    en: "Center Groups",
    de: "Center Gruppe",
    it: "Gruppo Centro",
    nl: "Centrumgroep"
  },
  FILL_IN_DETAILS: {
    en: "Fill in the details",
    de: "Bitte Details ausfüllen",
    it: "Compilare i dati",
    nl: "Vul de gegevens in"
  },
  COMPANY_NAME: {
    en: "Company Name",
    de: "Name des Unternehmens",
    it: "Nome dell'azienda",
    nl: "Bedrijfsnaam"
  },
  FAX_NUMBER: {
    en: "Fax Number",
    de: "Fax-Nummer",
    it: "Numero di fax",
    nl: "Faxnummer"
  },
  ENTER_STREET_NAME: {
    en: "Enter street name",
    de: "Straßenname eingeben",
    it: "Inserire il nome della via",
    nl: "Voer de straatnaam in"
  },
  LOGIN_INFO: {
    en: "Login information",
    de: "Anmeldeinformationen",
    it: "Informazioni di accesso",
    nl: "Login Informatie"
  },
  USERNAME: {
    en: "Username",
    de: "Benutzername",
    it: "Nome utente",
    nl: "Gebruikersnaam"
  },
  PASSWORD: {
    en: "Password",
    de: "Passwort",
    it: "Password",
    nl: "Wachtwoord"
  },
  CONFIRM_PASSWORD: {
    en: "Confirm password",
    de: "Passwort bestätigen",
    it: "Confermare la password",
    nl: "Bevestig wachtwoord"
  },
  CLIENTS: {
    en: "Clients",
    de: "Kunden",
    it: "Clienti",
    nl: "Klanten"
  },
  SEARCH: {
    en: "Search",
    de: "Suche",
    it: "Ricerca",
    nl: "Zoeken op"
  },
  RESET: {
    en: "Reset",
    de: "zurücksetzen",
    it: "Reset",
    nl: "Reset"
  },
  NUMBER_OF_ACTIVE_CAMPAIGN: {
    en: "Number of Active Campaign",
    de: "Anzahl der aktiven Kampagnen",
    it: "Numero di campagne attive",
    nl: "Aantal actieve campagnes"
  },
  DOMAIN: {
    en: "Domain",
    de: "Domain",
    it: "Dominio",
    nl: "Domein"
  },
  SELECT_USER: {
    en: "Select User",
    de: "Benutzer auswählen",
    it: "Seleziona utente",
    nl: "Selecteer gebruiker"
  },
  VIEW_CAMPAIGNS: {
    en: "View Campaigns",
    de: "Kampagnen anzeigen",
    it: "Visualizza le campagne",
    nl: "Campagnes bekijken"
  },
  VIEW_CAMPAIGN: {
    en: "View Campaign",
    de: "Kampagne ansehen",
    it: "Visualizza Campagna",
    nl: "Campagne bekijken"
  },
  CAMPAIGN_LISTINGS: {
    en: "Campaign Listings",
    de: "Kampagnen-Liste",
    it: "Annunci della campagna",
    nl: "Campagnelijsten"
  },
  NEW: {
    en: "New",
    de: "Neue",
    it: "Nuovo",
    nl: "Nieuw"
  },
  IN_PROGRESS: {
    en: "In progress",
    de: "In Bearbeitung",
    it: "In corso",
    nl: "In uitvoering"
  },
  ACTIVE: {
    en: "Active",
    de: "Aktiv",
    it: "Attivo",
    nl: "Actief"
  },
  LIVE: {
    en: "Live",
    de: "Live",
    it: "Live",
    nl: "Live"
  },
  NOT_LIVE: {
    en: "Not live",
    de: "Nicht live",
    it: "Non dal vivo",
    nl: "Niet live"
  },
  COMPLETED: {
    en: "Completed",
    de: "Abgeschlossen",
    it: "Completato",
    nl: "Voltooid"
  },
  TITLE: {
    en: "Title",
    de: "Titel",
    it: "Titolo",
    nl: "Titel"
  },
  CREATED_ON: {
    en: "Create on",
    de: "Erstellen am",
    it: "Crea su",
    nl: "Creëer op"
  },
  START_DATE: {
    en: "Start date",
    de: "Startdatum",
    it: "Data di inizio",
    nl: "Startdatum"
  },
  END_DATE: {
    en: "End date",
    de: "Enddatum",
    it: "Data di fine",
    nl: "Einddatum"
  },
  ONLY_WOMAN: {
    en: "Only for woman",
    de: "Nur für Frauen",
    it: "Solo per donne",
    nl: "Alleen voor vrouwen"
  },
  FB_BUDGET: {
    en: "Fb budget",
    de: "Fb-Budget",
    it: "Fb budget",
    nl: "Fb-budget"
  },
  ADWORDS_BUDGET: {
    en: "Adwords budget",
    de: "Adwords-Budget",
    it: "Adwords budget",
    nl: "Adwordsbegroting"
  },
  NO: {
    en: "No",
    de: "Nein",
    it: "No",
    nl: "Nee"
  },
  YES: {
    en: "Yes",
    de: "Ja",
    it: "Sì",
    nl: "Ja"
  },
  EDIT_BOOKED_CAMPAIGN: {
    en: "Edit Booked Campaign",
    de: "Gebuchte Kampagne bearbeiten",
    it: "Modifica Campagna Prenotata",
    nl: "Bewerkte geboekte campagne"
  },
  CAMPAIGN_OF_CENTER: {
    en: "Campaigns of Center -",
    de: "Kampagnen von Center -",
    it: "Campagne di centro -",
    nl: "Campagnes van Center -"
  },
  CLIENT_DETAILS: {
    en: "Client Details",
    de: "Kunden Details",
    it: "Dettagli del cliente",
    nl: "Klantgegevens"
  },
  NEW_CLIENT: {
    en: "New Client",
    de: "Neuer Kunde",
    it: "Nuovo cliente",
    nl: "Nieuwe klant"
  },
  ARCHIVED_CLIENT_LIST: {
    en: "Archived Client List",
    de: "Archivierte Kundenliste",
    it: "Elenco clienti archiviati",
    nl: "Gearchiveerde klantenlijst"
  },
  DELETE_CONFIRMATION_ARCHIVED_CLIENT: {
    en: "Are you sure you want to unarchive this client? Its status will be changed to Active after this.",
    de: "Sind Sie sicher, dass Sie diesen Client aus dem Archiv entfernen möchten? Der Status wird danach auf Aktiv geändert.",
    it: "Siete sicuri di voler eliminare questo cliente? Il suo stato verrà poi modificato in Attivo.",
    nl: "Weet u zeker dat u deze klant wilt ontsluiten? Zijn status zal hierna worden gewijzigd in Actief."
  },
  EDIT_CLIENT: {
    en: "Edit Client",
    de: "Kunde bearbeiten",
    it: "Modifica Cliente",
    nl: "Klant bewerken"
  },
  DELETE_CLIENT_CONFIRMATION: {
    en: "Are you sure you want to archive this client? Its status will be changed to Inactive after this.",
    de: "Sind Sie sicher, dass Sie diesen Kunden archivieren möchten? Der Status wird danach auf Inaktiv geändert.",
    it: "Siete sicuri di voler archiviare questo cliente? Il suo stato verrà poi modificato in Inattivo.",
    nl: "Weet u zeker dat u deze klant wilt archiveren? Zijn status zal hierna worden gewijzigd in Inactief."
  },
  VIEW_CLIENT_DETAILS: {
    en: "View client details",
    de: "Kundendetails anzeigen",
    it: "Visualizza i dettagli del cliente",
    nl: "Klantgegevens bekijken"
  },
  UPDATE_CLIENT: {
    en: "Update client",
    de: "Kunde aktualisieren",
    it: "Aggiorna il cliente",
    nl: "Klant bijwerken"
  },
  ARCHIVE_CLIENT: {
    en: "Archive client",
    de: "Archiv client",
    it: "Archivio clienti",
    nl: "Archief klant"
  },
  UNARCHIVE_CLIENT: {
    en: "Unarchive client",
    de: "Unarchive client",
    it: "Unarchive client",
    nl: "Unarchive klant"
  },
  ROLE: {
    en: "Role",
    de: "Rolle",
    it: "Ruolo",
    nl: "Rol"
  },
  MOBILE_NUMBER: {
    en: "Mobile Number",
    de: "Mobiltelefon-Nummer",
    it: "Numero di cellulare",
    nl: "Mobiel nummer"
  },
  BEST_CONTACT_TIME: {
    en: "Best contact time",
    de: "Beste Kontaktzeit",
    it: "Miglior tempo di contatto",
    nl: "Beste contacttijd"
  },
  REMARK: {
    en: "Remark",
    de: "Bemerkung",
    it: "Nota:",
    nl: "Opmerking"
  },
  REMARKS: {
    en: "Remarks",
    de: "Bemerkungen",
    it: "Osservazioni",
    nl: "Opmerkingen"
  },
  ADD_REMARK: {
    en: "Add remark",
    de: "Bemerkung hinzufügen",
    it: "Aggiungere un'osservazione",
    nl: "Opmerking toevoegen"
  },
  NO_REMARKS: {
    en: "No remarks",
    de: "Keine Bemerkungen",
    it: "Nessun commento",
    nl: "Geen opmerkingen"
  },
  ENTER_USERNAME: {
    en: "Enter username",
    de: "Benutzername eingeben",
    it: "Inserisci il tuo nome utente",
    nl: "Voer uw gebruikersnaam in"
  },
  ENTER_PASSWORD: {
    en: "Enter password",
    de: "Passwort eingeben",
    it: "Inserisci la password",
    nl: "Voer het wachtwoord in"
  },
  ENTER_CONFIRM_PASSWORD: {
    en: "Enter confirm password",
    de: "Passwort bestätigen",
    it: "Inserire la password di conferma",
    nl: "Bevestig wachtwoord"
  },
  ENTER_ROLE: {
    en: "Enter role",
    de: "Rolle eingeben",
    it: "Inserisci il ruolo",
    nl: "Rol invoeren"
  },
  ENTER_MOBILE_NUMBER: {
    en: "Enter mobile number",
    de: "Handynummer eingeben",
    it: "Inserire il numero di cellulare",
    nl: "Voer het mobiele nummer in"
  },
  ENTER_BEST_CONTACT_TIME: {
    en: "Enter best contact time",
    de: "Beste Kontaktzeit eingeben",
    it: "Inserire l'orario migliore per il contatto",
    nl: "Voer de beste contacttijd in"
  },
  ENTER_REMARK: {
    en: "Enter remark",
    de: "Bemerkung eingeben",
    it: "Inserire l'osservazione",
    nl: "Opmerking invoeren"
  },
  NEW_CONSULTANT: {
    en: "New Consultant",
    de: "Neuer Berater",
    it: "Nuovo consulente",
    nl: "Nieuwe adviseur"
  },
  EDIT_CONSULTANT: {
    en: "Edit consultant",
    de: "Berater bearbeiten",
    it: "Consulente per la modifica",
    nl: "Redactieadviseur"
  },
  CONSULTANT_DETAILS: {
    en: "Consultant Details",
    de: "Einzelheiten zum Berater",
    it: "Dettagli del consulente",
    nl: "Consultantgegevens"
  },
  ARCHIVED_CONSULTANT: {
    en: "Archived Consultants",
    de: "Archivierte Berater",
    it: "Consulenti archiviati",
    nl: "Gearchiveerde Consultants"
  },
  DELETE_CONFIRMATION_ARCHIVED_CONSULTANT: {
    en: "Are you sure you want to unarchive this consultant?",
    de: "Sind Sie sicher, dass Sie diesen Berater aus dem Archiv holen möchten?",
    it: "Consulenti archiviatiSei sicuro di voler archiviare questo consulente?",
    nl: "Gearchiveerde ConsultantsWeet u zeker dat u deze consultant wilt ontsluiten?"
  },
  DELETE_CONFIRMATION_CONSULTANT: {
    en: "Are you sure you want to archive this consultant?",
    de: "Sind Sie sicher, dass Sie diesen Berater archivieren möchten?",
    it: "Sei sicuro di voler archiviare questo consulente?",
    nl: "Weet u zeker dat u deze consultant wilt archiveren?"
  },
  SEARCH_WITH_FIRST_NAME: {
    en: "Search with first name",
    de: "Suche mit Vornamen",
    it: "Ricerca con nome",
    nl: "Zoek met voornaam"
  },
  VIEW_CONSULTANT_DETAILS: {
    en: "View consultant details",
    de: "Berater-Details ansehen",
    it: "Visualizza i dettagli del consulente",
    nl: "Bekijk details adviseur"
  },
  ARCHIVE_CONSULTANT: {
    en: "Archive consultant",
    de: "Archiv Berater",
    it: "Consulente d'archivio",
    nl: "Archief consultant"
  },
  UNARCHIVE_CONSULTANT: {
    en: "Unarchive consultant",
    de: "Unarchive Berater",
    it: "Consulente Unarchive",
    nl: "Unarchive consultant"
  },
  CONSULTANTS: {
    en: "Consultants",
    de: "Berater",
    it: "Consulente",
    nl: "Consultants"
  },
  CONSULTANT_LISTING: {
    en: "Consultant Listing",
    de: "Berater Auflistung",
    it: "Elenco consulente",
    nl: "Consultant Lijst"
  },
  SELECT_LANGUAGE: {
    en: "Select a language",
    de: "Wählen Sie eine Sprache",
    it: "Seleziona una lingua",
    nl: "Selecteer een taal"
  },
  INACTIVE: {
    en: "Inactive",
    de: "Inaktiv",
    it: "Inattivo",
    nl: "Inactief"
  },
  SEARCH_BY_TITLE: {
    en: "Search by title",
    de: "Suche nach Titel",
    it: "Ricerca per titolo",
    nl: "Zoeken op titel"
  },
  MINI_TEXT: {
    en: "Mini Text",
    de: "Mini-Text",
    it: "Mini testo",
    nl: "Mini-tekst"
  },
  TEASER_TEXT: {
    en: "Teaser Text",
    de: "Teaser-Text",
    it: "Testo Teaser",
    nl: "Teasertekst"
  },
  CONTENT: {
    en: "Content",
    de: "Inhalt",
    it: "Contenuto",
    nl: "Inhoud"
  },
  WEBSITE_PREVIEW_IMG: {
    en: "Website Preview Image",
    de: "Website-Vorschau-Bild",
    it: "Anteprima del sito web",
    nl: "Voorbeeld van een website"
  },
  WEBSITE_DETAILED_IMG: {
    en: "Website Detailed Image",
    de: "Detaillierte Website-Detail-Bild",
    it: "Immagine dettagliata del sito web",
    nl: "Website Gedetailleerd beeld"
  },
  FACEBOOK_TEASER_IMG: {
    en: "Facebook Teaser Image",
    de: "Facebook-Teaser-Bild",
    it: "Immagine Teaser di Facebook",
    nl: "Facebook-teaserafbeelding"
  },
  NEWSLETTER_IMG: {
    en: "Newsletter Image",
    de: "Newsletter-Bild",
    it: "Immagine della newsletter",
    nl: "Nieuwsbrief Beeld"
  },
  PUBLISHED_FROM: {
    en: "Published from",
    de: "Veröffentlicht von",
    it: "Pubblicato da",
    nl: "Gepubliceerd van"
  },
  CURRENT_WEBSITE_IMG: {
    en: "Current Website Preview Image",
    de: "Vorschaubild der aktuellen Website",
    it: "Immagine in anteprima del sito web corrente",
    nl: "Huidige website voorbeeldafbeelding"
  },
  CURRENT_DETAILED_IMG: {
    en: "Current Detailed Website Image",
    de: "Aktuelles detailliertes Website-Bild",
    it: "Attuale immagine dettagliata del sito web",
    nl: "Huidige Gedetailleerde Website Beeld"
  },
  CURRENT_NEWSLETTER_IMG: {
    en: "Current Newsletter Image",
    de: "Aktuelles Newsletter-Bild",
    it: "Immagine della Newsletter attuale",
    nl: "Huidige Nieuwsbrief Beeld"
  },
  CURRENT_FB_TEASER_IMG: {
    en: "Current Facebook Teaser Image",
    de: "Aktuelles Facebook-Teaser-Bild",
    it: "Attuale immagine Teaser di Facebook",
    nl: "Huidige Facebook-teaserafbeelding"
  },
  ENTER_NAME: {
    en: "Enter name",
    de: "Name eingeben",
    it: "Inserire il nome",
    nl: "Voer de naam in"
  },
  ENTER_CONTENT_TITLE: {
    en: "Enter content title",
    de: "Titel des Inhalts eingeben",
    it: "Inserisci il titolo del contenuto",
    nl: "Titel van de inhoud invoeren"
  },
  ENTER_MINI_TEXT: {
    en: "Enter mini text",
    de: "Mini-Text eingeben",
    it: "Inserisci il mini testo",
    nl: "Minitekst invoeren"
  },
  ENTER_TEASER_TEXT: {
    en: "Enter teaser text",
    de: "Teaser-Text eingeben",
    it: "Inserisci il testo teaser",
    nl: "Teaser tekst invoeren"
  },
  FILEPOND_LABEL: {
    en: "Drag & Drop your files or <span class=\"filepond--label-action\">Browse</span>",
    de: "Ziehen Sie Ihre Dateien per Drag & Drop oder <span class=\"filepond--label-action\">Durchsuchen</span>",
    it: "Trascina i tuoi file o <span class=\"filepond--label-action\">Sfogliare</span>",
    nl: "Drag & Drop uw bestanden of <span class=\"filepond--label-action\">Bladeren</span>"
  },
  NEW_CONTENT_SERVICE: {
    en: "New Content Service",
    de: "Neuer Content",
    it: "Nuovo servizio di contenuti",
    nl: "Nieuwe inhoudsdienst"
  },
  EDIT_CONTENT_SERVICE: {
    en: "Edit Content Service",
    de: "Content bearbeiten",
    it: "Servizio di modifica dei contenuti",
    nl: "Bewerk de inhoudsdienst"
  },
  SAVE: {
    en: "Save",
    de: "Speichern",
    it: "Salva",
    nl: "Bespaar"
  },
  CONTENT_DETAILS: {
    en: "Content Details",
    de: "Inhalt Details",
    it: "Dettagli sul contenuto",
    nl: "Inhoud Details"
  },
  ACTIVE_STATUS: {
    en: "Active Status",
    de: "Aktiver Status",
    it: "Stato attivo",
    nl: "Actieve status"
  },
  ARCHIVE_CONFIRMATION: {
    en: "Are you sure you want to archive this content?",
    de: "Sind Sie sicher, dass Sie diesen Inhalt archivieren möchten?",
    it: "Sei sicuro di voler archiviare questo contenuto?",
    nl: "Weet u zeker dat u deze inhoud wilt archiveren?"
  },
  VIEW_CONTENT: {
    en: "View content",
    de: "Inhalt anzeigen",
    it: "Visualizza il contenuto",
    nl: "Bekijk inhoud"
  },
  UPDATE_CONTENT: {
    en: "Update content",
    de: "Inhalt aktualisieren",
    it: "Aggiorna il contenuto",
    nl: "Inhoud bijwerken"
  },
  ARCHIVE_CONTENT: {
    en: "Archive content",
    de: "Inhalt archivieren",
    it: "Contenuto dell'archivio",
    nl: "Archief inhoud"
  },
  UNARCHIVE_CONTENT: {
    en: "Unarchive content",
    de: "Inhalt dearchivieren",
    it: "Disarchivia il contenuto",
    nl: "Unarchiveer inhoud"
  },
  ALL_CONTENT: {
    en: "All Content",
    de: "Inhalte",
    it: "Tutto il contenuto",
    nl: "Alle inhoud"
  },
  SELECT: {
    en: "Select",
    de: "Bitte auswählen",
    it: "Seleziona",
    nl: "Selecteer"
  },
  VIEW_ARCHIVED_CONTENT_SERVICE: {
    en: "View archived content service",
    de: "Archivierte Contents",
    it: "Visualizza il servizio di contenuti archiviati",
    nl: "Bekijk gearchiveerde inhoud"
  },
  SHOW_ARCHIVED_CONTENT_SERVICE: {
    en: "Show archived content service",
    de: "Archivierte Contents",
    it: "Mostra il servizio di contenuti archiviati",
    nl: "Toon gearchiveerde inhoud"
  },
  CATEGORY_CREATE_SUCCESS: {
    en: "Category created successfully",
    de: "Kategorie erfolgreich erstellt",
    it: "Categoria creata con successo",
    nl: "Categorie succesvol aangemaakt"
  },
  CATEGORY_UPDATE_SUCCESS: {
    en: "Category updated successfully",
    de: "Kategorie erfolgreich aktualisiert",
    it: "Categoria aggiornata con successo",
    nl: "Categorie succesvol bijgewerkt"
  },
  CATEGORY_REMOVE_SUCCESS: {
    en: "Category removed successfully",
    de: "Kategorie erfolgreich entfernt",
    it: "Categoria rimossa con successo",
    nl: "Categorie met succes verwijderd"
  },
  CONTENT_CREATE_SUCCESS: {
    en: "Content created successfully.",
    de: "Inhalt erfolgreich erstellt.",
    it: "Contenuto creato con successo.",
    nl: "Inhoud succesvol gemaakt."
  },
  CONTENT_CREATE_FAILED: {
    en: "Content created failed.",
    de: "Die Erstellung von Inhalten ist fehlgeschlagen.",
    it: "Contenuto creato non riuscito.",
    nl: "Aangemaakte inhoud mislukt."
  },
  CONTENT_UPDATE_SUCCESS: {
    en: "Content updated successfully",
    de: "Inhalt erfolgreich aktualisiert",
    it: "Contenuto aggiornato con successo",
    nl: "Inhoud succesvol bijgewerkt"
  },
  CONTENT_UPDATE_FAILED: {
    en: "Content updated failed",
    de: "Inhalt aktualisiert fehlgeschlagen",
    it: "Aggiornamento del contenuto fallito",
    nl: "Bijgewerkte inhoud mislukt"
  },
  NEW_CATEGORY: {
    en: "New Category",
    de: "Neue Kategorie",
    it: "Nuova categoria",
    nl: "Nieuwe categorie"
  },
  EDIT_CATEGORY: {
    en: "Edit Category",
    de: "Kategorie bearbeiten",
    it: "Modifica categoria",
    nl: "Categorie bewerken"
  },
  SEARCH_BY_NAME: {
    en: "Search by category name",
    de: "Suche nach Kategorienamen",
    it: "Ricerca per nome della categoria",
    nl: "Zoeken op categorienaam"
  },
  DELETE_CONFIRMATION_CATEGORY: {
    en: "Are you sure to delete this category?",
    de: "Sind Sie sicher, dass Sie diese Kategorie löschen werden?",
    it: "Siete sicuri di voler eliminare questa categoria?",
    nl: "Weet u zeker dat u deze categorie wilt verwijderen?"
  },
  VIEW_CATEGORY: {
    en: "View category",
    de: "Kategorie anzeigen",
    it: "Visualizza la categoria",
    nl: "Categorie bekijken"
  },
  UPDATE_CATEGORY: {
    en: "Update category",
    de: "Kategorie aktualisieren",
    it: "Aggiorna la categoria",
    nl: "Categorie bijwerken"
  },
  DELETE_CATEGORY: {
    en: "Delete category",
    de: "Kategorie löschen",
    it: "Cancellare la categoria",
    nl: "Categorie verwijderen"
  },
  ALL_CONTENT_CATEGORIES: {
    en: "All Content Categories",
    de: "Content-Kategorien",
    it: "Tutte le categorie di contenuto",
    nl: "Alle Inhoud Categorie"
  },
  CONTENT_CATEGORY: {
    en: "Content Category",
    de: "Inhalt Kategorie",
    it: "Categoria di contenuto",
    nl: "Inhoud Categorie"
  },
  ASSIGN_SHORTCUT_MENU: {
    en: "Assign Shortcut Menu",
    de: "Kontextmenü zuweisen",
    it: "Assegnazione del menu di scelta rapida",
    nl: "Snelmenu toewijzen"
  },
  LIST_OF_SHORTCUT_MENU: {
    en: "List of shortcut menu",
    de: "Liste der Kontextmenüs",
    it: "Elenco del menu di scelta rapida",
    nl: "Lijst van snelmenu"
  },
  INSTRUCTION: {
    en: "Assign your shortcut menu by dragging the items from left to right column",
    de: "Weisen Sie Ihr Kontextmenü zu, indem Sie die Elemente von der linken zur rechten Spalte ziehen",
    it: "Assegna il tuo menu di scelta rapida trascinando le voci da sinistra a destra della colonna",
    nl: "Wijs uw snelmenu toe door de items van de linker naar de rechter kolom te slepen"
  },
  ASSIGN: {
    en: "Assign",
    de: "Zuweisen",
    it: "Assegnare",
    nl: "Toewijzen"
  },
  TOTAL_BOOKED: {
    en: "Total Booked",
    de: "Total Gebucht",
    it: "Totale prenotato",
    nl: "Totaal Geboekt"
  },
  CAMPAIGN_NAME: {
    en: "Campaign Name",
    de: "Kampagne Name",
    it: "Nome della campagna",
    nl: "Campagne Naam"
  },
  AVERAGE_LEADS: {
    en: "Average Leads",
    de: "Durchschnittliche Leads",
    it: "Media dei Lead",
    nl: "Gemiddelde Leads"
  },
  SEARCH_CENTER: {
    en: "Search Center",
    de: "Center Suche",
    it: "Centro di ricerca",
    nl: "Zoekcentrum"
  },
  SELECT_CENTER: {
    en: "Please select a center",
    de: "Bitte wählen Sie ein Center",
    it: "Seleziona un centro",
    nl: "Selecteer een centrum"
  },
  ACTIVE_OLS: {
    en: "Currently active OLS",
    de: "Derzeit aktive OLS",
    it: "Attualmente attivo OLS",
    nl: "Momenteel actief OLS"
  },
  TOTAL_FB_BUDGET: {
    en: "Total FB Budget",
    de: "FB-Budget insgesamt",
    it: "Totale budget FB",
    nl: "Totaal FB-budget"
  },
  TOTAL_ADWORDS_BUDGET: {
    en: "Total Adwords Budget",
    de: "Gesamtes Adwords-Budget",
    it: "Budget totale di Adwords",
    nl: "Totaal Adwords Budget"
  },
  ACTIVE_CAMPAIGNS_NUMBER: {
    en: "Number of Active Campaigns",
    de: "Anzahl der aktiven Kampagnen",
    it: "Numero di campagne attive",
    nl: "Aantal actieve campagnes"
  },
  TOTAL_FB_ADS_BUDGET: {
    en: "Total of All Advertising Budgets Facebook",
    de: "Summe aller Werbebudgets Facebook",
    it: "Totale di tutti i budget pubblicitari Facebook",
    nl: "Totaal van alle reclamebudgetten Facebook"
  },
  TOTAL_GOOGLE_ADS_BUDGET: {
    en: "Total of All Advertising Budgets Google",
    de: "Summe aller Werbebudgets Google",
    it: "Totale di tutti i budget pubblicitari Google",
    nl: "Totaal van alle reclamebudgetten Google"
  },
  KEY_FIGURES: {
    en: "Key Figures",
    de: "Kennziffern",
    it: "Cifre chiave",
    nl: "Kerncijfers"
  },
  TOP_10_MOST_BOOKED_CAMPAIGNS: {
    en: "TOP 10 Most Booked OLS Campaigns",
    de: "TOP 10 meistgebuchte OLS-Kampagnen",
    it: "Le 10 campagne OLS più prenotate",
    nl: "TOP 10 meest geboekte OLS-campagnes"
  },
  SELECT_TIME_PERIOD: {
    en: "Please select a time period",
    de: "Bitte wählen Sie eine Zeitspanne",
    it: "Seleziona un periodo di tempo",
    nl: "Selecteer een periode"
  },
  ADMIN_PANEL_NEWS: {
    en: "Admin Panel News",
    de: "News",
    it: "Notizie del pannello amministrativo",
    nl: "Admin Panel Nieuws"
  },
  SETUP_NEWS_COCKPIT_DASHBOARD: {
    en: "Setup news in the Cockpit dashboard.",
    de: "Setup-Nachrichten im Cockpit-Dashboard.",
    it: "Impostare le notizie nel cruscotto di Cockpit.",
    nl: "Setup nieuws in het Cockpit dashboard."
  },
  CREATE_NEWS: {
    en: "Create news",
    de: "News erstellen",
    it: "Creare notizie",
    nl: "Nieuws maken"
  },
  SELECT_STATUS: {
    en: "Select a status",
    de: "Wählen Sie einen Status",
    nl: "Kies een status",
    it: "Seleziona uno stato"
  },
  PUBLISH_FROM: {
    en: "Publish from",
    de: "Veröffentlichen am",
    it: "Pubblicare da",
    nl: "Publiceer van"
  },
  PUBLISH_TO: {
    en: "Publish to",
    de: "Veröffentlichen am",
    it: "Pubblicare da",
    nl: "Publiceer van"
  },
  UPLOAD_IMAGE: {
    en: "Upload Image",
    de: "Bild hochladen",
    it: "Carica immagine",
    nl: "Afbeelding uploaden"
  },
  SELECTED_IMAGE: {
    en: "Selected Image",
    de: "Ausgewähltes Bild",
    it: "Immagine selezionata",
    nl: "Geselecteerde afbeelding"
  },
  REDIRECT_URL: {
    en: "Redirect URL",
    de: "Ausgewähltes Bild",
    it: "Immagine selezionata",
    nl: "Geselecteerde afbeelding"
  },
  SELECT_CENTER_GROUPS: {
    en: "Select center groups",
    de: "Centergruppen auswählen",
    nl: "Selecteer centrum groepen",
    it: "Seleziona i gruppi del centro"
  },
  ADD: {
    en: "Add",
    de: "hinzufügen",
    nl: "Toevoegen",
    it: "Aggiungi"
  },
  DELETE: {
    en: "Delete",
    de: "Löschen",
    nl: "Delete",
    it: "Cancellare"
  },
  SELECT_COUNTRIES: {
    en: "Select countries",
    de: "Länder auswählen",
    nl: "Selecteer landen",
    it: "Seleziona i paesi"
  },
  UPDATE_NEWS: {
    en: "Update news",
    de: "Update Nachrichten",
    nl: "Nieuws bijwerken",
    it: "Aggiornare le notizie"
  },
  ADMIN_PANEL_PRODUCTS: {
    en: "Admin Panel Products",
    de: "Produkte",
    nl: "Admin Panel Producten",
    it: "Prodotti del pannello amministrativo"
  },
  SETUP_PRODUCTS_COCKPIT_DASHBOARD: {
    en: "Setup products in the Cockpit dashboard.",
    de: "Produkte im Cockpit-Dashboard einstellen.",
    nl: "Producten instellen in het Cockpit dashboard.",
    it: "Impostare i prodotti nella dashboard di Cockpit."
  },
  CREATE_PRODUCTS: {
    en: "Create products",
    de: "Produkte erstellen",
    it: "Creare prodotti",
    nl: "Creëer producten"
  },
  CURRENCY: {
    en: "Currency",
    de: "Währungen",
    it: "Valute",
    nl: "Valuta's"
  },
  PRICE: {
    en: "Price",
    de: "Preis",
    it: "Prezzo",
    nl: "Prijs"
  },
  IS_NEW: {
    en: "Is new",
    de: "Ist neu",
    it: "È nuovo",
    nl: "Is nieuw"
  },
  IS_MOST_BOOKED_PRODUCTS: {
    en: "Is most booked products",
    de: "Ist das meistgebuchte Produkt",
    it: "È la maggior parte dei prodotti prenotati",
    nl: "Is de meeste geboekte producten"
  },
  UPDATE_PRODUCT: {
    en: "Update product",
    de: "Produkt aktualisieren",
    it: "Aggiornare il prodotto",
    nl: "Product bijwerken"
  },
  VIEW_PRODUCT: {
    en: "View Product",
    de: "Produkt ansehen",
    it: "Visualizza il prodotto",
    nl: "Bekijk product"
  },
  COUNTRIES: {
    en: "Countries",
    de: "Länder",
    nl: "Paesi",
    it: "Landen"
  },
  PRICE_LINE_1: {
    en: "Price Line 1",
    de: "Preis Zeile 1",
    nl: "Prezzo Linea 1",
    it: "Prijs Lijn 1"
  },
  PRICE_LINE_2: {
    en: "Price Line 2",
    de: "Preis Zeile 2",
    nl: "Prezzo Linea 2",
    it: "Prijs Lijn 2"
  },
  UPLOAD_PDF: {
    en: "Upload PDF",
    de: "PDF hochladen",
    nl: "PDF uploaden",
    it: "caricare PDF"
  },
  UPDATE_SUCCESS: {
    en: "Product request is updated successfully.",
    de: "Produktanforderung wurde erfolgreich aktualisiert.",
    it: "La richiesta del prodotto è stata aggiornata correttamente.",
    nl: "Het productverzoek is succesvol bijgewerkt."
  },
  DELETE_SUCCESS: {
    en: "Product request is deleted successfully.",
    de: "Produktanfrage wurde erfolgreich gelöscht.",
    it: "La richiesta del prodotto è stata eliminata correttamente.",
    nl: "Het productverzoek is succesvol verwijderd."
  },
  EDIT_PRODUCT_REQUEST: {
    en: "Edit product request",
    de: "Produktanfrage bearbeiten",
    it: "Modifica la richiesta del prodotto",
    nl: "Productverzoek bewerken"
  },
  PRODUCT_REQUEST: {
    en: "Product Request",
    de: "Produktanfrage",
    it: "Richiesta di prodotto",
    nl: "Productverzoek"
  },
  PRODUCT: {
    en: "Product",
    de: "Produkt",
    it: "Prodotto",
    nl: "Product"
  },
  FROM: {
    en: "From",
    de: "Von",
    it: "A partire dal",
    nl: "Van"
  },
  TO: {
    en: "To",
    de: "Bis",
    it: "Per",
    nl: "Naar"
  },
  REQUESTED_ON: {
    en: "Requested on",
    de: "Angefordert am",
    it: "Richiesto il",
    nl: "Aangevraagd op"
  },
  TO_THE_PRODUCT: {
    en: "to the product",
    de: "zum Produkt",
    it: "al prodotto",
    nl: "naar het product"
  },
  BOOKED_FROM: {
    en: "Booked From",
    de: "Gebucht von",
    it: "Prenotato da",
    nl: "Geboekt vanaf"
  },
  BOOKED_UNTIL: {
    en: "Booked Until",
    de: "Gebucht bis",
    it: "Prenotato fino al",
    nl: "Geboekt tot"
  },
  DELETE_CONFIRMATION_PRODUCT_REQUEST: {
    en: "Are you sure to delete this product request?",
    de: "Sind Sie sicher, diese Produktanfrage zu löschen?",
    it: "Sei sicuro di eliminare questa richiesta di prodotto?",
    nl: "Weet u zeker dat u dit productverzoek wilt verwijderen?"
  },
  CREATE_STATUS: {
    en: "Create Status",
    de: "Status erstellen",
    it: "Crea stato",
    nl: "Status creëren"
  },
  COLOR: {
    en: "Color",
    de: "Farbe",
    it: "Colore",
    nl: "Kleur"
  },
  EDIT_STATUS: {
    en: "Edit Status",
    de: "Status bearbeiten",
    it: "Modifica stato",
    nl: "Status bewerken"
  },
  SUCCESS_CREATE: {
    en: "The status is created successfully.",
    de: "Der Status wurde erfolgreich erstellt.",
    it: "Lo stato è stato creato con successo.",
    nl: "De status is succesvol aangemaakt."
  },
  SUCCESS_UPDATE: {
    en: "The status is updated successfully.",
    de: "Der Status wurde erfolgreich aktualisiert.",
    it: "Lo stato viene aggiornato correttamente.",
    nl: "De status is succesvol bijgewerkt."
  },
  PRODUCT_REQUEST_STATUS: {
    en: "Product Request Status",
    de: "Status Produktanfragen",
    it: "Stato richiesta prodotto",
    nl: "Status productverzoek"
  },
  POSITION: {
    en: "Position",
    de: "Position",
    it: "Posizione",
    nl: "Positie"
  },
  NEWS_CREATE_SUCCESS: {
    en: "News is created successfully.",
    de: "Nachrichten werden erfolgreich erstellt.",
    it: "Le notizie vengono create con successo.",
    nl: "Nieuws is succesvol gemaakt."
  },
  NEWS_UPDATE_SUCCESS: {
    en: "News is updated successfully.",
    de: "Nachrichten werden erfolgreich aktualisiert.",
    it: "Le notizie vengono aggiornate con successo.",
    nl: "Nieuws is succesvol bijgewerkt."
  },
  PRODUCT_CREATE_SUCCESS: {
    en: "Product is created successfully.",
    de: "Produkt wurde erfolgreich erstellt.",
    it: "Il prodotto viene creato con successo.",
    nl: "Product is succesvol aangemaakt."
  },
  PRODUCT_UPDATE_SUCCESS: {
    en: "Product is updated successfully.",
    de: "Produkt wurde erfolgreich aktualisiert.",
    it: "Il prodotto viene aggiornato con successo.",
    nl: "Product is succesvol bijgewerkt."
  },
  PUT_SOMETHING_HERE: {
    en: "Put something here.",
    de: "Stellen Sie hier etwas ein.",
    it: "Metti qualcosa qui.",
    nl: "Zet hier iets neer."
  },
  PREV: {
    en: "Prev",
    de: "Vorherige",
    it: "Precedente",
    nl: "Vorige"
  },
  NEXT: {
    en: "Next",
    de: "Weiter",
    it: "Prossimo",
    nl: "Volgende"
  },
  UPDATE_COLUMN: {
    en: "Update column",
    de: "Spalte aktualisieren",
    it: "Colonna di aggiornamento",
    nl: "Kolom bijwerken"
  },
  COLUMN_WIDTH: {
    en: "Column width",
    de: "Säulenbreite",
    it: "Larghezza della colonna",
    nl: "Kolombreedte"
  },
  SELECTED_COMPONENT: {
    en: "Selected component",
    de: "Ausgewählte Komponente",
    it: "Componente selezionato",
    nl: "Geselecteerde component"
  },
  TEXT_FIELD: {
    en: "TextField",
    de: "TextFeld",
    it: "TextField",
    nl: "TextField"
  },
  RADIO: {
    en: "Radio",
    de: "Radio",
    it: "Radio",
    nl: "Radio"
  },
  TEXT: {
    en: "Text",
    de: "Text",
    it: "Testo",
    nl: "Tekst"
  },
  CHECKBOX: {
    en: "Checkbox",
    de: "Checkbox",
    it: "Checkbox",
    nl: "Checkbox"
  },
  INPUT_FILE: {
    en: "InputFile",
    de: "EingabeDatei",
    it: "InputFile",
    nl: "InputFile"
  },
  IMAGE: {
    en: "Image",
    de: "Bild",
    it: "Immagine",
    nl: "Afbeelding"
  },
  PADDING: {
    en: "Padding",
    de: "Polsterung",
    it: "Imbottitura",
    nl: "Opvulling"
  },
  FIELD_LABEL: {
    en: "Field label",
    de: "Feldbezeichnung",
    it: "Etichetta del campo",
    nl: "Veld label"
  },
  SELECTED_IMAGE_URL: {
    en: "Selected image URL",
    de: "Ausgewählte Bild-URL",
    it: "URL dell'immagine selezionata",
    nl: "Geselecteerde afbeelding URL"
  },
  SELECT_IMAGE: {
    en: "Select image",
    de: "Bild auswählen",
    it: "Selezionare l'immagine",
    nl: "Selecteer afbeelding"
  },
  WIDTH: {
    en: "Width",
    de: "Breite",
    it: "Larghezza",
    nl: "Breedte"
  },
  HEIGHT: {
    en: "Height",
    de: "Höhe",
    it: "Altezza",
    nl: "Hoogte"
  },
  FIELD_NAME: {
    en: "Field name",
    de: "Feldname",
    it: "Nome del campo",
    nl: "Naam van het veld"
  },
  FIELD_DESCRIPTION: {
    en: "this field needs to be filled in and no space is allowed here.",
    de: "dieses Feld muss ausgefüllt werden, hier ist kein Leerzeichen erlaubt.",
    it: "questo campo deve essere compilato e non sono ammessi spazi.",
    nl: "dit veld moet worden ingevuld en hier is geen spatie toegestaan."
  },
  SELECTION: {
    en: "Selection",
    de: "Auswahl",
    it: "Selezione",
    nl: "Selectie"
  },
  LABEL: {
    en: "Label",
    de: "Feld",
    it: "Etichetta",
    nl: "Etiket"
  },
  ADD_SELECTION: {
    en: "Add selection",
    de: "Auswahl hinzufügen",
    it: "Aggiungi la selezione",
    nl: "Selectie toevoegen"
  },
  ENABLE_FLEXIBLE_OPTION: {
    en: "Enable flexible option",
    de: "Flexible Option einschalten",
    it: "Abilita l'opzione flessibile",
    nl: "Flexibele optie inschakelen"
  },
  DISABLE_FLEXIBLE_OPTION: {
    en: "Disable flexible option",
    de: "Flexible Option deaktivieren",
    it: "Disabilita l'opzione flessibile",
    nl: "Flexibele optie uitschakelen"
  },
  NO_CONTENT_FOUND: {
    en: "No content found, please select a component to edit first.",
    de: "Kein Inhalt gefunden, bitte wählen Sie zuerst eine Komponente zum Bearbeiten aus.",
    it: "Nessun contenuto trovato, seleziona prima un componente da modificare.",
    nl: "Geen inhoud gevonden, kies eerst een onderdeel om te bewerken."
  },
  FONT_SIZE: {
    en: "Font size",
    de: "Schriftgröße",
    it: "Dimensione del carattere",
    nl: "Lettergrootte"
  },
  DATE_FIELD: {
    en: "DateField",
    de: "DateFeld",
    it: "Campo data",
    nl: "Datumveld"
  },
  SELECT_COMPONENT: {
    en: "Select component",
    de: "Komponente auswählen",
    it: "Seleziona il componente",
    nl: "Selecteer component"
  },
  TEXT_FIELD_LABEL: {
    en: "Your new textfield here",
    de: "Ihr neues Textfeld hier",
    it: "Il tuo nuovo campo di testo qui",
    nl: "Uw nieuwe tekstveld hier"
  },
  SUBMIT_BUTTON: {
    en: "Your submit button here",
    de: "Ihr Sendebutton hier",
    it: "Il tuo pulsante di invio qui",
    nl: "Uw verzendknop hier"
  },
  RADIO_BUTTON_LABEL: {
    en: "Your new radio button here",
    de: "Ihr neuer Radiobutton hier",
    it: "Il tuo nuovo pulsante radio qui",
    nl: "Uw nieuwe radio knop hier"
  },
  TEXT_LABEL: {
    en: "Your new text here.",
    de: "Ihr neuer Text hier.",
    it: "Il tuo nuovo testo qui.",
    nl: "Uw nieuwe tekst hier."
  },
  CHECKBOX_LABEL: {
    en: "Your new checkbox here",
    de: "Ihr neues Checkbox hier",
    it: "La tua nuova casella di controllo qui",
    nl: "Uw nieuwe selectievakje hier"
  },
  INPUT_FILE_LABEL: {
    en: "Your new input file here",
    de: "Ihre neue Eingabedatei hier",
    it: "Il tuo nuovo file di input qui",
    nl: "Uw nieuwe invoerbestand hier"
  },
  DATE_FIELD_LABEL: {
    en: "Your new datefield here",
    de: "Ihr neues Datumsfeld hier",
    it: "Il tuo nuovo campo data qui",
    nl: "Je nieuwe datefield hier"
  },
  EDIT_FORM_FRAME: {
    en: "Edit form frame",
    de: "Formularrahmen bearbeiten",
    it: "Modifica cornice modulo",
    nl: "Formulier bewerken"
  },
  TITLE_STYLE: {
    en: "Title style",
    de: "Titel Stil",
    it: "Stile del titolo",
    nl: "Titel stijl"
  },
  BACKGROUND_COLOR: {
    en: "Background color",
    de: "Hintergrundfarbe",
    it: "Colore di sfondo",
    nl: "Achtergrond kleur"
  },
  FONT_WEIGHT: {
    en: "Font weight",
    de: "Schriftweite",
    it: "Peso del carattere",
    nl: "Gewicht lettertype"
  },
  TEXT_ALIGN: {
    en: "Text align",
    de: "Text ausrichten",
    it: "Allineare il testo",
    nl: "Tekst uitlijnen"
  },
  ALIGN_LEFT: {
    en: "Align left",
    de: "Links ausrichten",
    it: "Allinea a sinistra",
    nl: "Links uitlijnen"
  },
  ALIGN_RIGHT: {
    en: "Align right",
    de: "Rechts ausrichten",
    it: "Allinea a destra",
    nl: "Rechts uitlijnen"
  },
  CONTAINER: {
    en: "Container",
    de: "Container",
    it: "Contenitore",
    nl: "Container"
  },
  BORDER_WIDTH: {
    en: "Border width",
    de: "Breite des Randes",
    it: "Larghezza del confine",
    nl: "Breedte van de rand"
  },
  BORDER_COLOR: {
    en: "Border color",
    de: "Farbe der Umrandung",
    it: "Colore del bordo",
    nl: "Kleur rand"
  },
  FIELD_SETTINGS: {
    en: "Field Settings",
    de: "Feldeinstellungen",
    it: "Impostazioni del campo",
    nl: "Veld Instellingen"
  },
  FIELD_VALUES: {
    en: "Field Values",
    de: "Feldwerte",
    it: "Valori di campo",
    nl: "Veld Waarden"
  },
  SELECTION_LABEL: {
    en: "Your new selection here",
    de: "Ihre neue Auswahl hier",
    it: "La tua nuova selezione qui",
    nl: "Uw nieuwe selectie hier"
  },
  HEADING: {
    en: "Digital order form builder",
    de: "Digitaler Bestellscheinersteller",
    it: "Costruttore di moduli d'ordine digitali",
    nl: "Digitaal bestelformulier maken"
  },
  SUBHEADING: {
    en: "Builder for digital order form under Aciso.",
    de: "Builder für digitales Bestellformular unter Aciso.",
    it: "Costruttore per il modulo d'ordine digitale sotto Aciso.",
    nl: "Bouwer voor digitale bestelbon onder Aciso."
  },
  CREATE_NEW_FORM: {
    en: "Create new form",
    de: "Neues Formular erstellen",
    it: "Creare un nuovo modulo",
    nl: "Nieuw formulier maken"
  },
  ARCHIVED_FORM: {
    en: "Archived form",
    de: "Archivierte Form",
    it: "Forma archiviata",
    nl: "Gearchiveerde vorm"
  },
  VIEW_FORM: {
    en: "View Form",
    de: "Formulier bekijken",
    it: "Visualizza il modulo",
    nl: "Formulier bekijken"
  },
  ACTIONS: {
    en: "Actions",
    de: "Aktionen",
    it: "Azioni",
    nl: "Acties"
  },
  COPY: {
    en: "Copy",
    de: "Kopieren",
    it: "Copia",
    nl: "Kopiëren"
  },
  COPY_URL: {
    en: "Copy URL",
    de: "Kopieren URL",
    it: "Copia URL",
    nl: "Kopiëren URL"
  },
  COPY_URL_SUCCESS: {
    en: "Copy URL success.",
    de: "URL erfolgreich kopieren",
    it: "Copiare l'URL di successo",
    nl: "URL kopiëren succes"
  },
  VIEW_SUBMITTED_DATA: {
    en: "View submitted data",
    de: "Eingesendete Daten anzeigen",
    it: "Visualizza i dati inviati",
    nl: "Ingediende gegevens bekijken"
  },
  ARCHIVED_FORMS: {
    en: "Archived Form(s)",
    de: "Archivierte Formulare",
    it: "Moduli archiviati",
    nl: "Gearchiveerde formulieren"
  },
  UNARCHIVED: {
    en: "Unarchive",
    de: "Unarchivieren",
    it: "Unarchive",
    nl: "Unarchive"
  },
  CLOSE: {
    en: "Close",
    de: "Schließen",
    it: "Chiudere",
    nl: "Sluiten"
  },
  ADD_CONTENT: {
    en: "Click to select form's element",
    de: "Klicken Sie auf , um das Element des Formulars auszuwählen ",
    nl: "Klik om het element van het formulier te selecteren",
    it: "Clicca per selezionare l'elemento del modulo"
  },
  ADD_NEW_COLUMN: {
    en: "Add new column",
    de: "Neue Spalte hinzufügen",
    it: "Aggiungi una nuova colonna",
    nl: "Nieuwe kolom toevoegen"
  },
  FORM_INFORMATION: {
    en: "Form Information",
    de: "Formular Informationen",
    it: "Informazioni sul modulo",
    nl: "Formulier Informatie"
  },
  FORM_NAME: {
    en: "Form Name",
    de: "Formular Name",
    it: "Nome del modulo",
    nl: "Vorm Naam"
  },
  ARCHIVED: {
    en: "Archived",
    de: "Archiviert",
    it: "Archiviato",
    nl: "Gearchiveerd"
  },
  NAME_IS_REQUIRED: {
    en: "Name is required",
    de: "Name ist erforderlich",
    it: "Il nome è richiesto",
    nl: "Naam is verplicht"
  },
  DESCRIPTION_IS_REQUIRED: {
    en: "Description is required",
    de: "Beschreibung ist erforderlich",
    it: "La descrizione è richiesta",
    nl: "Beschrijving is vereist"
  },
  CATEGORY_IS_REQUIRED: {
    en: "Category is required",
    de: "Kategorie ist erforderlich",
    it: "La categoria è richiesta",
    nl: "Categorie is vereist"
  },
  VALUE_IS_REQUIRED: {
    en: "Form value(s) is required",
    de: "Formularwert ist erforderlich",
    it: "Il valore del modulo è richiesto",
    nl: "Formulier waarde is vereist"
  },
  FORM_BUILDER: {
    en: "Form builder",
    de: "Formular-Ersteller",
    it: "Costruttore di moduli",
    nl: "Formulierenbouwer"
  },
  DELETE_CONFIRMATION_SELECTED_COMPONENT: {
    en: "Are you sure to remove selected component?",
    de: "Sind Sie sicher, dass Sie die ausgewählte Komponente entfernen wollen?",
    it: "Sei sicuro di rimuovere il componente selezionato?",
    nl: "Weet u zeker dat u het geselecteerde onderdeel wilt verwijderen?"
  },
  ADD_PAGE: {
    en: "Add Page",
    de: "Seite hinzufügen",
    it: "Aggiungi pagina",
    nl: "Pagina toevoegen"
  },
  SET_PAGE_SELECTABLE: {
    en: "Set page as selectable",
    de: "Seite als auswählbar festlegen",
    nl: "Pagina als kiesbaar instellen",
    it: "Imposta la pagina come selezionabile"
  },
  SET_PAGE_UNSELECTABLE: {
    en: "Set page as unselectable",
    de: "Seite als nicht auswählbar festlegen",
    nl: "Pagina instellen als niet-selecteerbaar",
    it: "Imposta la pagina come non selezionabile"
  },
  SELECT_THIS_PAGE: {
    en: "Select this page",
    de: "Wählen Sie diese Seite",
    nl: "Kies deze pagina",
    it: "Seleziona questa pagina"
  },
  REMOVE_PAGE: {
    en: "Remove Page",
    de: "Seite entfernen",
    it: "Rimuovi pagina",
    nl: "Pagina verwijderen"
  },
  SUCCESS_MESSAGE: {
    en: "Page is added successfully.",
    de: "Seite wurde erfolgreich hinzugefügt.",
    it: "La pagina viene aggiunta con successo.",
    nl: "Pagina is succesvol toegevoegd."
  },
  SELECT_PLACEHOLDER: {
    en: "Please select one from below",
    de: "Bitte wählen Sie eine der folgenden Optionen aus",
    it: "Si prega di selezionare uno dei seguenti",
    nl: "Kies er een van hieronder"
  },
  FORM: {
    en: "Form",
    de: "Formular",
    it: "Modulo",
    nl: "Formulier"
  },
  VIEW_RESULTS: {
    en: "View Results",
    de: "Ergebnisse anzeigen",
    it: "Visualizza i risultati",
    nl: "Bekijk resultaten"
  },
  SUBMITTED_FROM: {
    en: "Submitted from : ",
    de: "Bestellt von : ",
    it: "Inviato da : ",
    nl: "Ingezonden van : "
  },
  VALUE: {
    en: "Value(s)",
    de: "Wert",
    it: "Valore",
    nl: "Waarde"
  },
  TEST_FORM_TITLE: {
    en: "Test form title",
    de: "Titel der Testform",
    it: "Titolo del modulo di test",
    nl: "Titel testformulier"
  },
  DELETE_CONFIRMATION_MESSAGE: {
    en: "Are you sure you want to delete the form?",
    de: "Sind Sie sicher, dass Sie das Formular löschen wollen?",
    it: "Sei sicuro di voler cancellare il modulo?",
    nl: "Weet u zeker dat u het formulier wilt verwijderen?"
  },
  DOMAIN_NAME: {
    en: "Domain Name",
    de: "Domainenname",
    it: "Nome a dominio",
    nl: "Domeinnaam"
  },
  SEARCH_BY_DOMAIN_NAME: {
    en: "Search by domain name",
    de: "Suche nach Domainnamen",
    it: "Ricerca per nome di dominio",
    nl: "Zoeken op domeinnaam"
  },
  VIEW_DOMAIN: {
    en: "View domain",
    de: "Webseite anzeigen",
    it: "Visualizza il dominio",
    nl: "Bekijk domein"
  },
  DOMAIN_LISTING: {
    en: "Domain Listing",
    de: "Domain-Auflistung",
    it: "Elenco del dominio",
    nl: "Domein Lijst"
  },
  DOMAIN_LISTING_DESCRIPTION: {
    en: "All domains are listed here.",
    de: "Alle Domains sind hier aufgelistet.",
    it: "Tutti i domini sono elencati qui.",
    nl: "Alle domeinen zijn hier opgesomd."
  },
  EDIT_IMAGES: {
    en: "Edit Images",
    de: "Bilder bearbeiten",
    it: "Modifica immagini",
    nl: "Afbeeldingen bewerken"
  },
  NEW_UPLOADED: {
    en: "New uploaded images",
    de: "Neu hochgeladene Bilder",
    it: "Nuove immagini caricate",
    nl: "Nieuwe geüploade beelden"
  },
  TYPE: {
    en: "Type",
    de: "Typ",
    it: "Tipo",
    nl: "Type"
  },
  ADD_MORE_PICTURES: {
    en: "Add More Pictures",
    de: "Weitere Bilder hinzufügen",
    it: "Aggiungi altre immagini",
    nl: "Meer foto's toevoegen"
  },
  UPLOAD_ALL: {
    en: "Upload All",
    de: "Alle hochladen",
    it: "Carica tutto",
    nl: "Upload alle"
  },
  IMAGE_PREVIEW: {
    en: "Image Preview",
    de: "Bildvorschau",
    it: "Anteprima immagine",
    nl: "Beeldweergave"
  },
  SEARCH_BY_IMG_NAME: {
    en: "Search by image name",
    de: "Suche nach Bildnamen",
    it: "Ricerca per nome dell'immagine",
    nl: "Zoeken op beeldnaam"
  },
  EDIT_PICTURES: {
    en: "Edit Picture(s)",
    de: "Bild(er) bearbeiten",
    it: "Modificare l'immagine (o le immagini)",
    nl: "Foto('s) bewerken"
  },
  DELETE_PICTURES: {
    en: "Delete Picture(s)",
    de: "Bild(er) löschen",
    it: "Cancellare l'immagine (o le immagini)",
    nl: "Foto('s) verwijderen"
  },
  EXISTING_MEDIA: {
    en: "Existing Media",
    de: "Vorhandene Medien",
    it: "Media esistenti",
    nl: "Bestaande media"
  },
  MEDIA_TAGS: {
    en: "Media Tags",
    de: "Medien-Tags",
    it: "Media Tags",
    nl: "Media Tags"
  },
  PICTURES: {
    en: "Pictures",
    de: "Bilder",
    it: "Immagini",
    nl: "Foto's"
  },
  UPLOAD_PICTURES: {
    en: "Upload Pictures",
    de: "Bilder hochladen",
    it: "Carica Immagini",
    nl: "Foto's uploaden"
  },
  ALL_MEDIAS: {
    en: "All Medias",
    de: "Alle Medien",
    it: "Tutti i media",
    nl: "Alle media"
  },
  IMAGES_CREATE_SUCCESS: {
    en: "Images created successfully",
    de: "Bilder erfolgreich erstellt",
    it: "Immagini create con successo",
    nl: "Afbeeldingen succesvol gemaakt"
  },
  IMAGES_UPDATE_SUCCESS: {
    en: "Images updated successfully",
    de: "Bilder erfolgreich aktualisiert",
    it: "Immagini aggiornate con successo",
    nl: "Afbeeldingen succesvol bijgewerkt"
  },
  IMAGES_REMOVE_SUCCESS: {
    en: "Images removed successfully",
    de: "Bilder erfolgreich entfernt",
    it: "Immagini rimosse con successo",
    nl: "Afbeeldingen succesvol verwijderd"
  },
  TAG_REMOVED_SUCCESS: {
    en: "Tag is removed successfully.",
    de: "Tag wurde erfolgreich entfernt.",
    it: "Il tag è stato rimosso con successo.",
    nl: "Tag is succesvol verwijderd."
  },
  ARE_YOU_SURE_DELETE_SELECTED_IMAGE: {
    en: "Are you sure you want to delete selected image(s)?",
    de: "Möchten Sie wirklich ausgewählte Bilder löschen?",
    it: "Sei sicuro di voler eliminare le immagini selezionate?",
    nl: "Weet u zeker dat u de geselecteerde afbeelding (en) wilt verwijderen?"
  },
  ARE_YOU_SURE_DELETE_SELECTED_TAG: {
    en: "Are you sure you want to delete selected tag?",
    de: "Möchten Sie das ausgewählte Tag wirklich löschen?",
    it: "Sei sicuro di voler eliminare il tag selezionato?",
    nl: "Weet u zeker dat u de geselecteerde tag wilt verwijderen?"
  },
  MODULE_NAME: {
    en: "Module name",
    de: "Name des Moduls",
    it: "Nome del modulo",
    nl: "Naam van de module"
  },
  ENTER_MODULE_NAME: {
    en: "Enter module name",
    de: "Modulname eingeben",
    it: "Inserire il nome del modulo",
    nl: "Voer de naam van de module in"
  },
  NEW_MODULE: {
    en: "New Module",
    de: "Neues Modul",
    it: "Nuovo modulo",
    nl: "Nieuwe module"
  },
  EDIT_MODULE: {
    en: "Edit Module",
    de: "Modul bearbeiten",
    it: "Modifica modulo",
    nl: "Module bewerken"
  },
  MODULE_DETAILS: {
    en: "Module Details",
    de: "Modul-Details",
    it: "Dettagli del modulo",
    nl: "Module details"
  },
  ARCHIVED_MODULES: {
    en: "Archived Modules",
    de: "Archivierte Module",
    it: "Moduli archiviati",
    nl: "Gearchiveerde modules"
  },
  SEARCH_WITH_MODULE_NAME: {
    en: "Search with module name",
    de: "Suche mit Modulname",
    it: "Ricerca con il nome del modulo",
    nl: "Zoek met de naam van de module"
  },
  DELETE_CONFIRMATION_ARCHIVED_MODULE: {
    en: "Are you sure you want to unarchive this module?",
    de: "Sind Sie sicher, dass Sie dieses Modul aus dem Archiv hollen möchten?",
    it: "Siete sicuri di voler eliminare questo modulo?",
    nl: "Weet u zeker dat u deze module wilt ontrafelen?"
  },
  DELETE_CONFIRMATION_MODULE: {
    en: "Are you sure you want to archive this module?",
    de: "Sind Sie sicher, dass Sie dieses Modul archivieren möchten?",
    it: "Siete sicuri di voler archiviare questo modulo?",
    nl: "Weet u zeker dat u deze module wilt archiveren?"
  },
  VIEW_MODULE: {
    en: "View module",
    de: "Ansicht Modul",
    it: "Visualizza modulo",
    nl: "Module bekijken"
  },
  UPDATE_MODULE: {
    en: "Update module",
    de: "Update Modul",
    it: "Modulo di aggiornamento",
    nl: "Module bijwerken"
  },
  ARCHIVE_MODULE: {
    en: "Archive module",
    de: "Archiv-Modul",
    it: "Modulo archivio",
    nl: "Archief module"
  },
  UNARCHIVE_MODULE: {
    en: "Unarchive module",
    de: "Modul unarchivieren",
    it: "Modulo Unarchive",
    nl: "Module Unarchive"
  },
  MODULES: {
    en: "Modules",
    de: "Module",
    it: "Moduli",
    nl: "Modules"
  },
  ALL_MODULES: {
    en: "All modules",
    de: "Alle Module",
    it: "Tutti i moduli",
    nl: "Alle modules"
  },
  MODULE_CREATE_SUCCESS: {
    en: "Module is created successfully!",
    de: "Modul ist erfolgreich erstellt!",
    it: "Il modulo è stato creato con successo!",
    nl: "Module is succesvol aangemaakt!"
  },
  MODULE_UPDATE_SUCCESS: {
    en: "Module is updated successfully!",
    de: "Modul wurde erfolgreich aktualisiert!",
    it: "Il modulo è aggiornato con successo!",
    nl: "Module is succesvol bijgewerkt!"
  },
  MODULE_STATUS_UPDATE_SUCCESS: {
    en: "Module status is updated successfully!",
    de: "Modulstatus wurde erfolgreich aktualisiert!",
    it: "Lo stato del modulo è aggiornato con successo!",
    nl: "Module status is succesvol bijgewerkt!"
  },
  MODULE_REMOVE_SUCCESS: {
    en: "Module removed successfully!",
    de: "Modul erfolgreich entfernt!",
    it: "Modulo rimosso con successo!",
    nl: "Module succesvol verwijderd!"
  },
  CREATE_NEWSLETTER: {
    en: "Create newsletter",
    de: "Newsletter erstellen",
    it: "Crea newsletter",
    nl: "Creëer een nieuwsbrief"
  },
  EDIT_NEWSLETTER: {
    en: "Edit Newsletter",
    de: "Newsletter bearbeiten",
    it: "Modifica newsletter",
    nl: "Bewerk de nieuwsbrief"
  },
  VIEW_NEWSLETTER: {
    en: "View Newsletter",
    de: "Newsletter ansehen",
    it: "Visualizza la newsletter",
    nl: "Bekijk de nieuwsbrief"
  },
  VIEW_NEWSLETTER_SEN_CASE: {
    en: "View newsletter",
    de: "Newsletter ansehen",
    it: "Visualizza la newsletter",
    nl: "Bekijk de nieuwsbrief"
  },
  TEMPLATE_NAME: {
    en: "Template Name",
    de: "Name der Vorlage",
    it: "Nome del modello",
    nl: "Sjabloonnaam"
  },
  UPDATE_NEWSLETTER: {
    en: "Update newsletter",
    de: "Newsletter aktualisieren",
    it: "Aggiornare la newsletter",
    nl: "Nieuwsbrief bijwerken"
  },
  DELETE_NEWSLETTER: {
    en: "Delete newsletter",
    de: "Newsletter löschen",
    it: "Cancellare la newsletter",
    nl: "Nieuwsbrief verwijderen"
  },
  NEWSLETTER_TEMPLATES: {
    en: "Newsletter Templates",
    de: "Newsletter Vorlagen",
    it: "Modelli di newsletter",
    nl: "Nieuwsbrief sjablonen"
  },
  SAVE_DESIGN: {
    en: "Save Design",
    de: "Design speichern",
    it: "Salva il design",
    nl: "Ontwerp opslaan"
  },
  DELETE_CONFIRMATION_NEWSLETTER: {
    en: "Are you sure you want to delete this newsletter template?",
    de: "Sind Sie sicher, dass Sie diese Newsletter-Vorlage löschen möchten?",
    it: "Sei sicuro di voler cancellare questo modello di newsletter?",
    nl: "Weet u zeker dat u deze nieuwsbriefsjabloon wilt verwijderen?"
  },
  TEMPLATE_CREATE_SUCCESS: {
    en: "Template created successfully",
    de: "Vorlage erfolgreich erstellt",
    it: "Template creato con successo",
    nl: "Sjabloon succesvol aangemaakt"
  },
  TEMPLATE_UPDATE_SUCCESS: {
    en: "Template updated successfully",
    de: "Vorlage erfolgreich aktualisiert",
    it: "Template aggiornato con successo",
    nl: "Sjabloon succesvol bijgewerkt"
  },
  TEMPLATE_REMOVE_SUCCESS: {
    en: "Template removed successfully",
    de: "Vorlage erfolgreich entfernt",
    it: "Template rimosso con successo",
    nl: "Sjabloon succesvol verwijderd"
  },
  CURRENT_IMAGE: {
    en: "Current image",
    de: "Aktuelles Bild",
    it: "Immagine attuale",
    nl: "Huidige afbeelding"
  },
  OLS_INFO: {
    en: "OLS info",
    de: "OLS-Informationen",
    it: "Informazioni OLS",
    nl: "OLS-info"
  },
  SUBTITLE: {
    en: "Here you will find the onetimer/center admin information that the customer has given in the form",
    de: "Hier finden Sie die Onetimer/Center Admininformation, die der Kunde im Formular eingegebenen hat",
    it: "Qui troverete le informazioni di amministrazione di un timer/centro che il cliente ha fornito nel modulo",
    nl: "Hier vindt u de één-timer/center admin informatie die de klant heeft gegeven in het formulier"
  },
  CONTRACT_PERIOD: {
    en: "Contract period",
    de: "Vertragslaufzeit",
    it: "Periodo del contratto",
    nl: "Contractperiode"
  },
  START_CONTRACT: {
    en: "Start of contract",
    de: "Beginn des Vertrags",
    it: "Inizio del contratto",
    nl: "Begin van het contract"
  },
  IS_PURE_WOMAN_STUDIO: {
    en: "Is this pure women's studio",
    de: "Ist dies ein reines Frauenstudio",
    it: "Questo è uno studio di pura femminilità",
    nl: "Is dit pure vrouwenstudio"
  },
  STUDIO_NAME: {
    en: "Studio name",
    de: "Name des Studios",
    it: "Nome dello studio",
    nl: "Naam van de studio"
  },
  ROAD: {
    en: "Road",
    de: "Straße",
    it: "Strada",
    nl: "Weg"
  },
  CONTACT_PERSON: {
    en: "Contact person",
    de: "Kontaktperson",
    it: "Persona di contatto",
    nl: "Contactpersoon"
  },
  EMAIL_CONTACT_PERSON: {
    en: "Email contact person",
    de: "E-Mail-Kontaktperson",
    it: "Contatto via e-mail",
    nl: "Contactpersoon voor e-mail"
  },
  TELEPHONE_CONTACT_PERSON: {
    en: "Telephone contact person",
    de: "Telefonische Kontaktperson",
    it: "Contatto telefonico",
    nl: "Telefonisch contactpersoon"
  },
  MY_PERSONAL_ADVISOR: {
    en: "My personal advisor",
    de: "Mein persönlicher Berater",
    it: "Il mio consulente personale",
    nl: "Mijn persoonlijke adviseur"
  },
  SEND_LEAD_EMAIL_ADDRESS: {
    en: "Email address to which the leads should be sent. Pay attention to correct spelling!",
    de: "E-Mail-Adresse, an die die Leads gesendet werden sollen. Achten Sie auf die korrekte Schreibweise!",
    it: "Indirizzo e-mail a cui inviare i cavi. Attenzione all'ortografia corretta!",
    nl: "E-mailadres waarnaar de leads moeten worden gestuurd. Let op de juiste spelling!"
  },
  FB_LINK: {
    en: "Link to the Facebook fan page",
    de: "Link zur Facebook-Fanseite",
    it: "Link alla pagina fan di Facebook",
    nl: "Link naar de Facebook-fanpagina"
  },
  CAMPAIGN_TO_RUN: {
    en: "Which campaign do you want to run?",
    de: "Welche Kampagne möchten Sie starten?",
    it: "Quale campagna vuoi condurre?",
    nl: "Welke campagne wil je voeren?"
  },
  IMPRINT_URL: {
    en: "Link to the imprint of your website",
    de: "Link zum Impressum Ihrer Website",
    it: "Link all'impronta del vostro sito web",
    nl: "Link naar het impressum van uw website"
  },
  DATA_PROTECTION_LINK: {
    en: "Link to the data protection of your website",
    de: "Link zum Datenschutz Ihrer Website",
    it: "Link alla protezione dei dati del vostro sito web",
    nl: "Link naar de gegevensbescherming van uw website"
  },
  FB_MONTHLY_CLICK_BUDGET: {
    en: "Monthly click-through budget Facebook campaign in euros. Enter the monthly amount that you want to use as a click budget (recommendation: 350 euros). This amount can be increased at any time and / or changed after the first campaign.",
    de: "Monatliches Klickbudget für die Facebook-Kampagne in Euro. Geben Sie einen monatlichen Betrag ein, den Sie als Klick-Budget verwenden möchten (Empfehlung: 350 Euro). Dieser Betrag kann jederzeit erhöht und / oder nach der ersten Kampagne geändert werden.",
    it: "Mensile click-through budget della campagna Facebook in euro. Inserite l'importo mensile che volete utilizzare come budget per il click (raccomandazione: 350 euro). Questo importo può essere aumentato in qualsiasi momento e/o modificato dopo la prima campagna.",
    nl: "Maandelijkse doorklikbudget Facebook-campagne in euro's. Voer het maandelijkse bedrag in dat u wilt gebruiken als klikbudget (aanbeveling: 350 euro). Dit bedrag kan op elk moment worden verhoogd en/of gewijzigd na de eerste campagne."
  },
  BEST_PLACE_NAME: {
    en: "Best place name for text in the advertisement (People from model city and surroundings wanted)",
    de: "Bester Ortsname für Text in der Anzeige (Menschen aus Modellstadt und Umgebung gesucht)",
    it: "Il miglior nome di luogo per il testo nell'annuncio (Cercasi persone di città modello e dintorni)",
    nl: "Beste plaatsnaam voor tekst in de advertentie (Mensen uit modelstad en omgeving gezocht)"
  },
  STANDARD_PLACE_NAME_SURROUNDINGS: {
    en: "Standard (recommended): Your place name and surroundings (example: \"pastures and surroundings\")",
    de: "Standard (empfohlen): Ihr Ortsname und Umgebung (Beispiel: \"Weiden und Umgebung\")",
    it: "Standard (consigliato): Il vostro nome del luogo e dintorni (esempio: \"pascoli e dintorni\")",
    nl: "Standaard (aanbevolen): Uw plaatsnaam en omgeving (voorbeeld: \"weiden en omgeving\")"
  },
  INDIVIDUAL_PLACE_NAME: {
    en: "Individual place name for text in advertising",
    de: "Individueller Ortsname für Text in der Werbung",
    it: "Nome del luogo individuale per il testo nella pubblicità",
    nl: "Individuele plaatsnaam voor tekst in reclame"
  },
  IS_ACISO_WEBSITE_ONLINE: {
    en: "Is your ACISO website already online?",
    de: "Ist Ihre ACISO-Website bereits online?",
    it: "Il vostro sito web ACISO è già online?",
    nl: "Is uw ACISO-website al online?"
  },
  FB_ADVERTISING_ACCOUNT_ID: {
    en: "Facebook advertising account ID",
    de: "Facebook-Advertising-Account-ID",
    it: "ID dell'account pubblicitario di Facebook",
    nl: "Facebook-reclameaccount-ID"
  },
  MONTHLY_CLICK_GOOGLE_ADWORDS: {
    en: "Monthly click-through budget Google Adwords campaign in euros Enter the monthly amount that you want to use as a click budget (recommendation: 150 euros). This amount represents a margin and it is not certain that everything will be spent. This amount can also be changed at any time.",
    de: "Monatliches Klick-Budget Google Adwords-Kampagne in Euro. Geben Sie den monatlichen Betrag ein, den Sie als Klick-Budget verwenden möchten (Empfehlung: 150 Euro). Dieser Betrag stellt eine Marge dar und es ist nicht sicher, dass alles ausgegeben wird. Dieser Betrag kann auch jederzeit geändert werden.",
    it: "Budget mensile per la campagna Google Adwords in euro Inserire l'importo mensile che si desidera utilizzare come budget per i clic (raccomandazione: 150 euro). Questo importo rappresenta un margine e non è certo che tutto verrà speso. Anche questo importo può essere modificato in qualsiasi momento.",
    nl: "Maandelijks doorklikbudget Google Adwords-campagne in euro's Voer het maandelijkse bedrag in dat u als klikbudget wilt gebruiken (aanbeveling: 150 euro). Dit bedrag vertegenwoordigt een marge en het is niet zeker dat alles wordt uitgegeven. Dit bedrag kan ook op elk moment worden gewijzigd."
  },
  GOOGLE_ADS_CUSTOMER_NUMBER: {
    en: "Google Ads customer number",
    de: "Google Ads-Kundennummer",
    it: "Numero cliente di Google Ads",
    nl: "Google Advertentie klantnummer"
  },
  FIND_GOOGLE_ACCOUNT_TOP_RIGHT: {
    en: "You can find this in your Google Account at the top right under the user name.",
    de: "Sie finden diese in Ihrem Google-Konto oben direkt unter dem Benutzernamen.",
    it: "Potete trovarlo nel vostro account Google in alto a destra sotto il nome utente.",
    nl: "U vindt dit in uw Google-account rechtsboven onder de gebruikersnaam."
  },
  COMMENT: {
    en: "Comment",
    de: "Kommentar",
    it: "Commento",
    nl: "Commentaar"
  },
  CONSENT_DATA_PROCESSING: {
    en: "Consent to data processing",
    de: "Zustimmung zur Datenverarbeitung",
    it: "Consenso al trattamento dei dati",
    nl: "Toestemming voor gegevensverwerking"
  },
  YES_CONSENT_STATEMENT: {
    en: "Yes, I consent to the data I have entered being processed to contact me.",
    de: "Ja, ich bin einverstanden, dass die von mir eingegebenen Daten genützt werden, um mich zu kontaktieren.",
    it: "Sì, acconsento al trattamento dei dati che ho inserito per contattarmi.",
    nl: "Ja, ik geef toestemming om de gegevens die ik heb ingevoerd te verwerken om contact met mij op te nemen."
  },
  CONTRACT_TERM: {
    en: "Contract Term",
    de: "Vertragslaufzeit",
    it: "Durata del contratto",
    nl: "Contractduur"
  },
  CAMPAIGN: {
    en: "Campaign",
    de: "Kampagne",
    it: "Campagna",
    nl: "Campagne"
  },
  FACEBOOK_BUDGET: {
    en: "Facebook Budget",
    de: "Facebook-Budget",
    it: "Facebook Budget",
    nl: "Facebook Begroting"
  },
  GA_BUDGET: {
    en: "Google Adwords Budget",
    de: "Google Adwords-Budget",
    it: "Google Adwords Budget",
    nl: "Google Adwords Begroting"
  },
  INTERNAL_REMARK: {
    en: "Internal Remark",
    de: "Interne Bemerkung",
    it: "Nota interna",
    nl: "Interne opmerking"
  },
  CURRENT_BOOKED_CAMPAIGN: {
    en: "Current Booked Campaign",
    de: "Aktuell gebuchte Kampagne",
    it: "Campagna in corso",
    nl: "Huidige geboekte campagne"
  },
  AD_REMARK: {
    en: "Ad Remark",
    de: "Zusätzliche Bemerkung",
    it: "Osservazione supplementare",
    nl: "Aanvullende opmerking"
  },
  LANDING_PAGE_COMMENT: {
    en: "Landing page comment",
    de: "Kommentar zur Landingpage",
    it: "Commento alla pagina di atterraggio",
    nl: "Commentaar landingspagina"
  },
  PRIORITY: {
    en: "Priority",
    de: "Priorität",
    it: "Priorità",
    nl: "Prioriteit"
  },
  FB_STATUS: {
    en: "Facebook Status",
    de: "Facebook Status",
    it: "Stato Facebook",
    nl: "Facebook Status"
  },
  GOOGLE_STATUS: {
    en: "Google Status",
    de: "Google Status",
    it: "Stato Google",
    nl: "Google Status"
  },
  INTERNAL_NOTE: {
    en: "Internal Note",
    de: "Interne Bemerkung",
    it: "Commento interno",
    nl: "Interne opmerking"
  },
  LANDING_PAGES: {
    en: "Landing pages",
    de: "Landing Pages",
    it: "Pagine di atterraggio",
    nl: "Landingspagina's"
  },
  CREATE_LANDING_PAGE: {
    en: "Create landing page",
    de: "Landing Page erstellen",
    it: "Creare la pagina di destinazione",
    nl: "Landingspagina maken"
  },
  ADD_NEW_LANDING_PAGE: {
    en: "Add new landing page",
    de: "Neue Landing Page hinzufügen",
    it: "Aggiungi una nuova pagina di destinazione",
    nl: "Nieuwe landingspagina toevoegen"
  },
  LP_ADD_SUCCESS: {
    en: "Landing page is added successfully.",
    de: "Die Landing Page wurde erfolgreich hinzugefügt.",
    it: "La pagina di destinazione è stata aggiunta con successo.",
    nl: "Landingspagina is succesvol toegevoegd."
  },
  LP_REMOVE_SUCCESS: {
    en: "Landing page is removed successfully.",
    de: "Die Landing Page wurde erfolgreich entfernt.",
    it: "La pagina di destinazione è stata rimossa con successo.",
    nl: "Landingspagina is succesvol verwijderd."
  },
  SEND_INFORMATION_TO_CUSTOMER: {
    en: "Send information to customer",
    de: "Informationen an den Kunden senden",
    it: "Inviare informazioni al cliente",
    nl: "Stuur informatie naar klant"
  },
  SEND_INFO_SUCCESS: {
    en: "Information sent successfully.",
    de: "Informationen erfolgreich gesendet.",
    it: "Informazioni inviate con successo.",
    nl: "Informatie succesvol verzonden."
  },
  IS_ONE_TIMER: {
    en: "Is onetimer",
    de: "Onetimer",
    it: "È un timer",
    nl: "Is een timer"
  },
  CAMPAIGN_INFO: {
    en: "Campaign Info",
    de: "Informationen zur Kampagne",
    it: "Informazioni sulla campagna",
    nl: "Campagne-informatie"
  },
  EDIT_BOOKED_CAMPAIGN_SEN_CASE: {
    en: "Edit booked campaign",
    de: "Gebuchte Kampagne bearbeiten",
    it: "Modifica campagna prenotata",
    nl: "Bewerkte geboekte campagne"
  },
  CONSULTANT_INFO: {
    en: "Consultant",
    de: "Berater",
    it: "Consulente",
    nl: "Consultant"
  },
  FULL_NAME: {
    en: "Full Name",
    de: "Vollständiger Name",
    it: "Nome e cognome",
    nl: "Volledige naam"
  },
  PHONE: {
    en: "Phone",
    de: "Telefon",
    it: "Telefono",
    nl: "Telefoon"
  },
  PHOTO: {
    en: "Photo",
    de: "Foto",
    it: "Foto",
    nl: "Foto"
  },
  BOOKED_CAMPAIGN: {
    en: "Booked Campaigns",
    de: "Gebuchte Kampagnen",
    it: "Campagne prenotate",
    nl: "Geboekte campagnes"
  },
  SEARCH_BY_CAMPAIGN_TITLE: {
    en: "Search by category",
    de: "Suche Kategorien",
    it: "Cerca per titolo della campagna",
    nl: "Zoeken op campagnetitel"
  },
  ONE_TIMER: {
    en: "Onetimer",
    de: "Onetimer",
    it: "Un timer",
    nl: "Een timer"
  },
  CENTER_ADMIN: {
    en: "Center admin",
    de: "Center-Verwaltung",
    it: "Amministratore del centro",
    nl: "Centrum admin"
  },
  BOOKED_CAMPAIGN_UPDATE_SUCCESS: {
    en: "Booked campaign updated successfully",
    de: "Gebuchte Kampagne erfolgreich aktualisiert",
    it: "Campagna prenotata aggiornata con successo",
    nl: "Geboekte campagne succesvol bijgewerkt"
  },
  SETTINGS_UPDATE_SUCCESS: {
    en: "Settings updated successfully",
    de: "Einstellungen erfolgreich aktualisiert",
    it: "Impostazioni aggiornate con successo",
    nl: "Instellingen succesvol bijgewerkt"
  },
  AVAILABLE: {
    en: "Available",
    de: "Verfügbar",
    it: "Disponibile",
    nl: "Beschikbaar"
  },
  SEARCH_BY_CENTER_NAME: {
    en: "Search by center name",
    de: "Suche nach Center-Name",
    it: "Cerca per nome del centro",
    nl: "Zoeken op centrumnaam"
  },
  SEARCH_BY_CAMPAIGN_NAME: {
    en: "Search by campaign name",
    de: "Suche nach Kampagnenname",
    it: "Cerca per nome della campagna",
    nl: "Zoeken op campagnenaam"
  },
  CREATE_CAMPAIGN: {
    en: "Create campaign",
    de: "Kampagne erstellen",
    it: "Crea campagna",
    nl: "Creëer campagne"
  },
  LANDINGPAGE_MAN: {
    en: "Landing page URL (Man)",
    de: "URL der Landingpage (Männer)",
    it: "URL della pagina di atterraggio (Uomo)",
    nl: "Landingspagina URL (Man)"
  },
  LANDINGPAGE_WOMAN: {
    en: "Landing page URL (Woman)",
    de: "URL der Landingpage (Frauen)",
    it: "URL della pagina di atterraggio (Donna)",
    nl: "Landingspagina URL (Vrouw)"
  },
  BOOKABLE_MONTHS: {
    en: "Bookable Months",
    de: "Buchbare Monate",
    it: "Mesi prenotabili",
    nl: "Boekbare maanden"
  },
  CAMPAIGN_TYPES: {
    en: "Campaign Types",
    de: "Arten von Kampagnen",
    it: "Tipi di campagna",
    nl: "Campagne typen"
  },
  TARGET_CENTER_GROUPS: {
    en: "Target center groups",
    de: "Center Gruppen",
    it: "Gruppi target del centro",
    nl: "Doelgroep van het centrum"
  },
  CAMPAIGN_CAN_BE_BOOKED_IN: {
    en: "Campaign can be booked in",
    de: "Die Kampagne kann gebucht werden in",
    it: "La campagna può essere prenotata in",
    nl: "Campagne kan worden geboekt in"
  },
  UPLOAD_PREVIEW_IMAGE: {
    en: "Upload preview image",
    de: "Vorschaubild hochladen",
    it: "Carica l'immagine di anteprima",
    nl: "Upload voorbeeldafbeelding"
  },
  PREVIEW_IMAGE: {
    en: "Preview Image",
    de: "Voorbeeldafbeelding",
    it: "Immagine di anteprima",
    nl: "Voorbeeldafbeelding"
  },
  NEW_CAMPAIGN: {
    en: "New Campaign",
    de: "Neue Kampagne",
    it: "Nuova campagna",
    nl: "Nieuwe Campagne"
  },
  APP_CAMPAIGN: {
    en: "App Campaign",
    de: "App Kampagne",
    it: "App Campagna",
    nl: "App Campagne"
  },
  THREE_SIX_ZERO_APP_INFO: {
    en: "360 degree app information",
    de: "Information zur App",
    it: "Informazioni sulle app a 360 gradi",
    nl: "360 graden app informatie"
  },
  THREE_SIX_ZERO_TITLE: {
    en: "Title",
    de: "Titel",
    it: "Titolo",
    nl: "Titel"
  },
  THREE_SIX_ZERO_DESC: {
    en: "Description",
    de: "Beschreibung",
    it: "Descrizione",
    nl: "Beschrijving"
  },
  THREE_SIX_ZERO_CLAIM: {
    en: "Claim",
    de: "App Claim",
    it: "Reclamo",
    nl: "Bewering"
  },
  IS_ONE_TIME: {
    en: "Is this a onetimer campaign?",
    de: "Onetimer",
    it: "È una campagna di un anno?",
    nl: "Is dit een onetimer campagne?"
  },
  IS_STANDARD_APP_ACISO: {
    en: "Is this standard app campaign for Aciso?",
    de: "Ist diese Standard-App-Kampagne für Aciso?",
    it: "Questa campagna di applicazioni standard è per Aciso?",
    nl: "Is deze standaard app-campagne voor Aciso?"
  },
  IS_STANDARD_APP_INJOY: {
    en: "Is this standard app campaign for Injoy?",
    de: "Ist diese Standard-App-Kampagne für Injoy?",
    it: "Questa campagna di app standard è per Injoy?",
    nl: "Is deze standaard app-campagne voor Injoy?"
  },
  THREE_SIX_ZERO_STANDARD_IMAGE: {
    en: "Standard image",
    de: "Standard-Bild",
    it: "Immagine standard",
    nl: "Standaard afbeelding"
  },
  ALL_MONTHS: {
    en: "All months",
    de: "Alle Monate",
    it: "Tutti i mesi",
    nl: "Alle maanden"
  },
  SELECT_CENTER_GROUP: {
    en: "Select Center Group",
    de: "Center-Gruppe auswählen",
    it: "Seleziona il gruppo del centro",
    nl: "Selecteer Centrumgroep"
  },
  UPDATE_CAMPAIGN: {
    en: "Update campaign",
    de: "Kampagne aktualisieren",
    it: "Campagna di aggiornamento",
    nl: "Update campagne"
  },
  IS_CREATED_BY_CENTER_ADMIN: {
    en: "Is this campaign created by center admin?",
    de: "Wurde diese Kampagne vom Center-Admin erstellt?",
    it: "Questa campagna è creata dall'amministratore del centro?",
    nl: "Is deze campagne gemaakt door een centrum admin?"
  },
  CAMPAIGN_LISTING_DESCRIPTION: {
    en: "Listing for all the campaigns presence in the system.",
    de: "Auflistung aller im System vorhandenen Kampagnen.",
    it: "Elenco per tutte le campagne presenti nel sistema.",
    nl: "Lijst van alle campagnes die in het systeem aanwezig zijn."
  },
  ARCHIVED_CAMPAIGN: {
    en: "Archived Campaigns",
    de: "Archivierte Kampagnen",
    it: "Campagne archiviate",
    nl: "Gearchiveerde campagnes"
  },
  IS_FOR_CENTER: {
    en: "Is For Center",
    de: "Ist für Center",
    it: "È per il centro",
    nl: "Is voor centrum"
  },
  SET_INTO_INACTIVE: {
    en: "Set into inactive",
    de: "Inaktiv setzen",
    it: "Impostare inattivo",
    nl: "Inactief gezet"
  },
  OLS_CAMPAIGN_CREATE_SUCCESS: {
    en: "OLS campaign is created successfully.",
    de: "OLS-Kampagne wurde erfolgreich erstellt.",
    it: "La campagna OLS viene creata con successo.",
    nl: "OLS campagne is succesvol aangemaakt."
  },
  OLS_CAMPAIGN_CREATE_UNSUCCESS: {
    en: "OLS campaign is created unsuccessfully.",
    de: "OLS-Kampagne wurde erfolglos erstellt.",
    it: "La campagna OLS viene creata senza successo.",
    nl: "OLS campagne is zonder succes opgezet."
  },
  OLS_CAMPAIGN_UPDATE_SUCCESS: {
    en: "OLS campaign is updated successfully.",
    de: "OLS-Kampagne wurde erfolgreich aktualisiert.",
    it: "La campagna OLS viene aggiornata con successo.",
    nl: "OLS campagne is succesvol bijgewerkt."
  },
  SET_INACTIVE_CONFIRMATION_MESSAGE: {
    en: "Are you sure you want to set this record into inactive?",
    de: "Sind sie sicher, dass sie dieses Center inaktiv schalten wollen?",
    it: "Sei sicuro di voler rendere inattivo questo record?",
    nl: "Weet u zeker dat u dit record op inactief wilt zetten?"
  },
  ENTER_TITLE: {
    en: "Enter title",
    de: "Titel eingeben",
    it: "Inserisci il titolo",
    nl: "Titel invoeren"
  },
  ENTER_CAMPAIGN_TYPE_DESCRIPTION: {
    en: "Enter campaign type description",
    de: "Beschreibung des Kampagnentyps eingeben",
    it: "Inserisci la descrizione del tipo di campagna",
    nl: "Voer de beschrijving van het type campagne in"
  },
  CREATE_CAMPAIGN_TYPE: {
    en: "Create Campaign Type",
    de: "Kampagnentyp erstellen",
    it: "Crea tipo di campagna",
    nl: "Campagnetype aanmaken"
  },
  UPDATE_CAMPAIGN_TYPE: {
    en: "Update Campaign Type",
    de: "Kampagnentyp aktualisieren",
    it: "Tipo di campagna di aggiornamento",
    nl: "Update Campagnetype"
  },
  UPDATE_CAMPAIGN_TYPE_SEN_CASE: {
    en: "Update campaign type",
    de: "Kampagnentyp aktualisieren",
    it: "Tipo di campagna di aggiornamento",
    nl: "Update Campagnetype"
  },
  ARCHIVED_CAMPAIGN_TYPE: {
    en: "Archived Campaign Types",
    de: "Archivierte Kampagnentypen",
    it: "Tipi di campagna archiviati",
    nl: "Gearchiveerde campagnetypes"
  },
  DEL_CONFIRMATION: {
    en: "Are you sure you want to delete this campaign type?",
    de: "Sind Sie sicher, dass Sie diese Kampagnentyp löschen möchten?",
    it: "Sei sicuro di voler eliminare questo tipo di campagna?",
    nl: "Weet u zeker dat u dit type campagne wilt verwijderen?"
  },
  DELETE_CAMPAIGN_TYPE: {
    en: "Delete campaign type",
    de: "Kampagnentyp löschen",
    it: "Elimina il tipo di campagna",
    nl: "Campagnetype verwijderen"
  },
  UPDATE_ARCHIVED_CAMPAIGN_TYPE: {
    en: "Update Archived Campaign Type",
    de: "Archivierten Kampagnentyp aktualisieren",
    it: "Aggiornare il tipo di campagna archiviata",
    nl: "Gearchiveerd campagnetype bijwerken"
  },
  CAMPAIGN_TYPE: {
    en: "Campaign Types",
    de: "Kampagnen-Typen",
    it: "Tipi di campagne",
    nl: "Campagne Types"
  },
  CAMPAIGN_TYPE_LISTING: {
    en: "Campaign type listing",
    de: "Auflistung der Kampagnentypen",
    it: "Elenco dei tipi di campagna",
    nl: "Campagne type lijst"
  },
  CAMPAIGN_TYPE_CREATE_SUCCESS: {
    en: "Campaign type created successfully",
    de: "Kampagnentyp erfolgreich erstellt",
    it: "Tipo di campagna creato con successo",
    nl: "Campagnetype succesvol aangemaakt"
  },
  CAMPAIGN_TYPE_CREATE_FAILED: {
    en: "Campaign type create failed",
    de: "Kampagnentyp erstellen fehlgeschlagen",
    it: "Creazione del tipo di campagna fallita",
    nl: "Campagnetype aanmaken mislukt"
  },
  CAMPAIGN_TYPE_UPDATE_SUCCESS: {
    en: "Campaign type updated successfully",
    de: "Kampagnentyp erfolgreich aktualisiert",
    it: "Tipo di campagna aggiornato con successo",
    nl: "Campagnetype succesvol bijgewerkt"
  },
  CAMPAIGN_TYPE_UPDATE_FAILED: {
    en: "Campaign type update failed",
    de: "Aktualisierung des Kampagnentyps fehlgeschlagen",
    it: "Aggiornamento del tipo di campagna non riuscito",
    nl: "Bijwerken campagnetype mislukt"
  },
  CAMPAIGN_TYPE_REMOVE_SUCCESS: {
    en: "Campaign type removed successfully",
    de: "Kampagnentyp erfolgreich entfernt",
    it: "Tipo di campagna rimosso con successo",
    nl: "Campagne type succesvol verwijderd"
  },
  CAMPAIGN_TYPE_REMOVE_FAILED: {
    en: "Campaign type remove failed",
    de: "Entfernen des Kampagnentyps fehlgeschlagen",
    it: "La rimozione del tipo di campagna non è riuscita",
    nl: "Campagne type verwijderen mislukt"
  },
  ADD_EMAIL: {
    en: "Add New Email",
    de: "Neue E-Mail hinzufügen",
    it: "Aggiungi nuova e-mail",
    nl: "Nieuwe e-mail toevoegen"
  },
  CREATE_EMAIL: {
    en: "Create Email",
    de: "E-Mail erstellen",
    it: "Creare e-mail",
    nl: "Maak een e-mail aan"
  },
  OLS_MODULE_SETTING: {
    en: "OLS Module Settings",
    de: "OLS-Modul-Einstellungen",
    it: "Impostazioni del modulo OLS",
    nl: "Instellingen OLS-module"
  },
  SYSTEM_EMAIL_CREATE_SUCCESS: {
    en: "System email created successfully",
    de: "System-E-Mail erfolgreich erstellt",
    it: "Email di sistema creata con successo",
    nl: "Systeem e-mail succesvol gemaakt"
  },
  SYSTEM_EMAIL_UPDATE_SUCCESS: {
    en: "System email updated successfully",
    de: "System-E-Mail erfolgreich aktualisiert",
    it: "Email di sistema aggiornata con successo",
    nl: "Systeem e-mail met succes bijgewerkt"
  },
  SYSTEM_EMAIL_REMOVE_SUCCESS: {
    en: "System email removed successfully",
    de: "System-E-Mail erfolgreich entfernt",
    it: "Email di sistema rimossa con successo",
    nl: "Systeem e-mail met succes verwijderd"
  },
  SHOP_TOOL: {
    en: "Shop Tool",
    de: "Shop-Tool",
    it: "Strumento del negozio",
    nl: "Winkelgereedschap"
  },
  PRODUCT_TEXT: {
    en: "Product Text",
    de: "Produkt-Text",
    it: "Testo del prodotto",
    nl: "Producttekst"
  },
  FB_INSTA_TOOL: {
    en: "Facebook and Instagram Tool",
    de: "Facebook und Instagram-Tool",
    it: "Facebook e Instagram Tool",
    nl: "Facebook en Instagram Tool"
  },
  FB_POST_IMAGE: {
    en: "Facebook post image",
    de: "Bild auf Facebook posten",
    it: "Immagine del post su Facebook",
    nl: "Facebook-postafbeelding"
  },
  FB_ADS_IMAGE: {
    en: "Facebook ads image",
    de: "Bild der Facebook-Werbung",
    it: "Immagine degli annunci su Facebook",
    nl: "Facebook-advertentiebeeld"
  },
  INSTA_STORY_IMAGE: {
    en: "Instagram story image",
    de: "Instagramm-Story-Bild",
    it: "Immagine della storia di Instagram",
    nl: "Instagramverhaalbeeld"
  },
  FB_ADS_TEXT: {
    en: "Text for Facebook ads or post",
    de: "Text für Facebook-Anzeigen oder Posts",
    it: "Testo per annunci o post su Facebook",
    nl: "Tekst voor Facebook advertenties of post"
  },
  CREATE_CAMPAIGN_TOOL: {
    en: "Create Campaign Tools",
    de: "Kampagnen-Tool erstellen",
    it: "Creare strumenti per la campagna",
    nl: "Campagnegereedschap maken"
  },
  UPDATE_CAMPAIGN_TOOL: {
    en: "Update Campaign Tool",
    de: "Kampagnen-Tool aktualisieren",
    it: "Strumento per la campagna di aggiornamento",
    nl: "Campagnemiddel bijwerken"
  },
  UPDATE_CAMPAIGN_TOOL_SEN_CASE: {
    en: "Update campaign tool",
    de: "Kampagnen-Tool aktualisieren",
    it: "Strumento per la campagna di aggiornamento",
    nl: "Campagnemiddel bijwerken"
  },
  DELETE_CONFIRMATION_CAMPAIGN_TOOL: {
    en: "Are you sure to delete this campaign tool?",
    de: "Sind Sie sicher, dass Sie dieses Kampagnen-Tool löschen werden?",
    it: "Sei sicuro di voler eliminare questo strumento di campagna?",
    nl: "Weet u zeker dat u deze campagnetool wilt verwijderen?"
  },
  DELETE_CAMPAIGN_TOOL: {
    en: "Delete campaign tool",
    de: "Kampagnen-Tool löschen",
    it: "Elimina lo strumento della campagna",
    nl: "Campagne verwijderen"
  },
  CAMPAIGN_TOOL_LISTING: {
    en: "Campaign Tool Listings",
    de: "Auflistung der Kampagnen-Tool",
    it: "Elenchi di strumenti della campagna",
    nl: "Campagne Tool Listings"
  },
  CAMPAIGN_TOOL_CREATE_SUCCESS: {
    en: "Campaign tool is created successfully",
    de: "Kampagnen-Tool wurde erfolgreich erstellt",
    it: "Lo strumento della campagna viene creato con successo",
    nl: "Campagne tool is succesvol aangemaakt"
  },
  CAMPAIGN_TOOL_CREATE_UNSUCCESS: {
    en: "Campaign tool is created unsuccessfully",
    de: "Kampagnen-Tool wurde erfolglos erstellt",
    it: "Lo strumento della campagna viene creato senza successo",
    nl: "Campagne tool is niet succesvol aangemaakt"
  },
  CAMPAIGN_TOOL_UPDATE_SUCCESS: {
    en: "Campaign tool is updated successfully",
    de: "Kampagnen-Tool wurde erfolgreich aktualisiert",
    it: "Lo strumento della campagna viene aggiornato con successo",
    nl: "Campagne tool is succesvol bijgewerkt"
  },
  CAMPAIGN_TOOL_UPDATE_UNSUCCESS: {
    en: "Campaign tool is updated unsuccessfully",
    de: "Kampagnen-Tool wurde erfolglos aktualisiert",
    it: "Lo strumento della campagna viene aggiornato senza successo",
    nl: "Campagne-instrument wordt zonder succes bijgewerkt"
  },
  CAMPAIGN_TOOL_REMOVE_SUCCESS: {
    en: "Campaign tool is removed successfully",
    de: "Kampagnen-Tool wurde erfolgreich entfernt",
    it: "Lo strumento della campagna viene rimosso con successo",
    nl: "Campagne tool is succesvol verwijderd"
  },
  CURRENCIES: {
    en: "Currencies",
    de: "Währungen",
    it: "Valute",
    nl: "Valuta's"
  },
  SETUP_ONLINE_SHOP_CURRENCIES: {
    en: "Setup online shop currencies here",
    de: "Währungen im Online-Shop hier einstellen",
    it: "Imposta qui le valute del negozio online",
    nl: "Opstellen van online shop valuta's hier"
  },
  CREATE_CURRENCY: {
    en: "Create Currency",
    de: "Währung erstellen",
    it: "Crea Valuta",
    nl: "Creëer Valuta"
  },
  UPDATE_CURRENCY: {
    en: "Update Currency",
    de: "Währung aktualisieren",
    it: "Aggiorna la Valuta",
    nl: "Update Valuta"
  },
  UPDATE_CURRENCY_SEN_CASE: {
    en: "Update currency",
    de: "Währung aktualisieren",
    it: "Aggiorna la valuta",
    nl: "Valuta bijwerken"
  },
  REMOVE_CURRENCY: {
    en: "Remove currency",
    de: "Währung entfernen",
    it: "Rimuovere la valuta",
    nl: "Valuta verwijderen"
  },
  DECIMAL_SEPARATOR: {
    en: "Decimal separator",
    de: "Dezimaltrennzeichen",
    it: "Separatore decimale",
    nl: "Decimaal scheidingsteken"
  },
  COMMA: {
    en: "Comma ( , )",
    de: "Komma ( , )",
    it: "Virgola ( , )",
    nl: "Komma ( , )"
  },
  PERIOD: {
    en: "Period",
    de: "Zeitraum",
    it: "Periodo",
    nl: "Periode"
  },
  DELETE_CONFIRMATION_CURRENCY: {
    en: "Are you sure to delete this currency?",
    de: "Sind Sie sicher, dass Sie diese Währung löschen wollen?",
    it: "Sei sicuro di voler cancellare questa moneta?",
    nl: "Weet je zeker dat je deze valuta wilt verwijderen?"
  },
  CURRENCY_CREATE_SUCCESS: {
    en: "Currency is created successfully",
    de: "Währung wurde erfolgreich erstellt",
    it: "La valuta viene creata con successo",
    nl: "Valuta is succesvol gecreëerd"
  },
  CURRENCY_UPDATE_SUCCESS: {
    en: "Currency is updated successfully",
    de: "Währung wurde erfolgreich aktualisiert",
    it: "La valuta viene aggiornata con successo",
    nl: "Valuta is succesvol bijgewerkt"
  },
  CURRENCY_REMOVE_SUCCESS: {
    en: "Currency is removed successfully",
    de: "Währung wurde erfolgreich entfernt",
    it: "La valuta viene rimossa con successo",
    nl: "Valuta is succesvol verwijderd"
  },
  CREATE_FAQ_CATEGORY: {
    en: "Create FAQ Category",
    de: "FAQ-Kategorie erstellen",
    it: "Crea categoria FAQ",
    nl: "Creëer FAQ-categorie"
  },
  UPDATE_FAQ_CATEGORY: {
    en: "Update FAQ Category",
    de: "Update FAQ-Kategorie",
    it: "Aggiorna la categoria FAQ",
    nl: "Update FAQ-categorie"
  },
  UPDATE_FAQ_CATEGORY_SEN_CASE: {
    en: "Update FAQ category",
    de: "FAQ-Kategorie aktualisieren",
    it: "Aggiorna la categoria FAQ",
    nl: "FAQ categorie bijwerken"
  },
  REMOVE_FAQ_CATEGORY: {
    en: "Remove FAQ category",
    de: "FAQ-Kategorie entfernen",
    it: "Rimuovere la categoria FAQ",
    nl: "FAQ categorie verwijderen"
  },
  FAQ_CATEGORIES: {
    en: "FAQ Categories",
    de: "FAQ-Kategorien",
    it: "Categorie FAQ",
    nl: "FAQ Categorieën"
  },
  DELETE_CONFIRMATION_FAQ_CATEGORY: {
    en: "Are you sure to delete this FAQ category?",
    de: "Sind Sie sicher, dass Sie diese FAQ-Kategorie löschen möchten?",
    it: "Sei sicuro di voler cancellare questa categoria di FAQ?",
    nl: "Weet u zeker dat u deze FAQ categorie wilt verwijderen?"
  },
  FAQ_CATEGORY_CREATE_SUCCESS: {
    en: "FAQ Category is successfully created",
    de: "FAQ-Kategorie wurde erfolgreich erstellt",
    it: "La categoria FAQ è stata creata con successo",
    nl: "FAQ categorie is succesvol aangemaakt"
  },
  FAQ_CATEGORY_UPDATE_SUCCESS: {
    en: "FAQ Category is successfully updated",
    de: "FAQ-Kategorie wurde erfolgreich aktualisiert",
    it: "La categoria FAQ è stata aggiornata con successo",
    nl: "FAQ categorie is succesvol bijgewerkt"
  },
  FAQ_CATEGORY_REMOVE_SUCCESS: {
    en: "FAQ Category is successfully removed",
    de: "FAQ Kategorie wurde erfolgreich entfernt",
    it: "La categoria FAQ è stata rimossa con successo",
    nl: "FAQ Categorie is succesvol verwijderd"
  },
  CREATE_FAQ: {
    en: "Create FAQ",
    de: "FAQ erstellen",
    it: "Crea FAQ",
    nl: "Creëer FAQ"
  },
  UPDATE_FAQ: {
    en: "Update FAQ",
    de: "Update FAQ",
    it: "Aggiornamento FAQ",
    nl: "Update FAQ"
  },
  REMOVE_FAQ: {
    en: "Remove FAQ",
    de: "FAQ entfernen",
    it: "Rimuovere le FAQ",
    nl: "FAQ verwijderen"
  },
  QUESTION: {
    en: "Question",
    de: "Frage",
    it: "Domanda",
    nl: "Vraag"
  },
  ANSWER: {
    en: "Answer",
    de: "Antwort",
    it: "Rispondi a",
    nl: "Antwoord"
  },
  YOUTUBE_URL: {
    en: "Youtube URL",
    de: "Youtube URL",
    it: "Youtube URL",
    nl: "Youtube URL"
  },
  CATEGORY: {
    en: "Category",
    de: "Kategorie",
    it: "Categoria",
    nl: "Categorie"
  },
  SELECT_CATEGORY: {
    en: "Select Category",
    de: "Kategorie auswählen",
    it: "Seleziona la categoria",
    nl: "Selecteer Categorie"
  },
  VIEW_FAQS: {
    en: "View FAQs",
    de: "FAQs anzeigen",
    it: "Visualizza le FAQ",
    nl: "Bekijk FAQ's"
  },
  SHOW_HELP: {
    en: "Show Help",
    de: "Hilfe anzeigen",
    it: "Mostra Aiuto",
    nl: "Toon hulp"
  },
  FAQ_LISTING: {
    en: "FAQ Listings",
    de: "FAQ Auflistungen",
    it: "Annunci FAQ",
    nl: "FAQ vermeldingen"
  },
  DELETE_CONFIRMATION_FAQ: {
    en: "Are you sure to delete this FAQ listing?",
    de: "Sind Sie sicher, dass Sie diesen FAQ-Eintrag löschen möchten?",
    it: "Sei sicuro di voler cancellare questo elenco di FAQ?",
    nl: "Weet u zeker dat u deze FAQ categorie wilt verwijderen?"
  },
  FAQ_CREATE_SUCCESS: {
    en: "FAQ is successfully created",
    de: "FAQ wurde erfolgreich erstellt",
    it: "Le FAQ sono state create con successo",
    nl: "FAQ is succesvol aangemaakt"
  },
  FAQ_UPDATE_SUCCESS: {
    en: "FAQ is successfully updated",
    de: "FAQ wurde erfolgreich aktualisiert",
    it: "Le FAQ sono state aggiornate con successo",
    nl: "FAQ is succesvol bijgewerkt"
  },
  FAQ_REMOVE_SUCCESS: {
    en: "FAQ is successfully removed",
    de: "FAQ wurde erfolgreich entfernt",
    it: "Le FAQ sono state rimosse con successo",
    nl: "FAQ is succesvol verwijderd"
  },
  ACISO_ORDER_NUMBER: {
    en: "Aciso Order Number",
    de: "Aciso-Bestellnummer",
    it: "Numero d'ordine Aciso",
    nl: "Aciso Bestelnummer"
  },
  ENTER_ACISO_ORDER_NUM: {
    en: "Enter Aciso order number",
    de: "Aciso-Bestellnummer eingeben",
    it: "Inserire il numero d'ordine Aciso",
    nl: "Voer het Aciso-bestelnummer in"
  },
  ACTIVATED_MODULES: {
    en: "Activated Modules",
    de: "Aktivierte Module",
    it: "Moduli attivati",
    nl: "Geactiveerde modules"
  },
  NON_ACTIVATED_MODULES: {
    en: "Non Activated Modules",
    de: "Nicht aktivierte Module",
    it: "Moduli non attivati",
    nl: "Niet-geactiveerde modules"
  },
  CENTER_REQUIRED: {
    en: "Center is required",
    de: "Center ist erforderlich",
    it: "Il centro è richiesto",
    nl: "Centrum is vereist"
  },
  NEW_ORDER: {
    en: "New Order",
    de: "Neue Bestellung",
    it: "Nuovo ordine",
    nl: "Nieuwe bestelling"
  },
  RECREATE: {
    en: "Recreate",
    de: "Neu erstellen",
    it: "Ricreare",
    nl: "Recreate"
  },
  ORDER_DETAILS: {
    en: "Order Details",
    de: "Details zur Bestellung",
    it: "Dettagli dell'ordine",
    nl: "Bestelgegevens"
  },
  SEARCH_WITH_ORDER_NUMBER: {
    en: "Search with order number",
    de: "Suche mit Bestellnummer",
    it: "Ricerca con numero d'ordine",
    nl: "Zoeken met ordernummer"
  },
  CREATE_ORDER_LOADING: {
    en: "System is working hard to generate your test site. Please wait up to 6 to 10 seconds for this.",
    de: "Die Testseite wird erstellt, der Vorgang kann einen Moment dauern.",
    it: "Il sistema sta lavorando sodo per generare il vostro sito di prova. Si prega di attendere fino a 6-10 secondi per questo.",
    nl: "Het systeem werkt hard om uw testlocatie te genereren. Wacht hier maximaal 6 tot 10 seconden op."
  },
  CREATED_AT: {
    en: "Created at",
    de: "Erstellt am",
    it: "Creato a",
    nl: "Gemaakt op"
  },
  VIEW_ORDER: {
    en: "View order",
    de: "Ansicht Bestellung",
    it: "Visualizza l'ordine",
    nl: "Bekijk bestelling"
  },
  RECREATE_ORDER: {
    en: "Recreate order",
    de: "Auftrag neu erstellen",
    it: "Ricreare l'ordine",
    nl: "Opnieuw bestellen"
  },
  ORDERS: {
    en: "Orders",
    de: "Bestellungen",
    it: "Ordini",
    nl: "Bestellingen"
  },
  ALL_ORDERS: {
    en: "All orders",
    de: "Alle Bestellungen",
    it: "Tutti gli ordini",
    nl: "Alle bestellingen"
  },
  ASSIGN_PACKAGES: {
    en: "Assign packages",
    de: "Pakete zuweisen",
    it: "Assegna pacchetti",
    nl: "Wijs pakketten toe"
  },
  ASSIGN_MODULES: {
    en: "Assign modules",
    de: "Module zuweisen",
    it: "Assegna moduli",
    nl: "Wijs modules toe"
  },
  ORDERED_MODULES: {
    en: "Ordered modules",
    de: "Bestellte Module",
    it: "Moduli ordinati",
    nl: "Bestelde modules"
  },
  SELECTED: {
    en: "Selected",
    de: "Ausgewählte",
    it: "Selezionato",
    nl: "Geselecteerd"
  },
  SELECT_BUTTON: {
    en: "Select",
    de: "Wählen Sie",
    it: "Seleziona",
    nl: "Selecteer"
  },
  REMOVE: {
    en: "Remove",
    de: "Entfernen",
    it: "Rimuovi",
    nl: "Verwijder"
  },
  UPDATE_ORDER: {
    en: "Update Order",
    de: "Update-Bestellung",
    it: "Ordine di aggiornamento",
    nl: "Bestelling bijwerken"
  },
  ORDER_CREATE_SUCCESS: {
    en: "Order is created successfully!",
    de: "Auftrag wurde erfolgreich angelegt!",
    it: "L'ordine è stato creato con successo!",
    nl: "Bestelling is succesvol aangemaakt!"
  },
  ORDER_CREATE_UNSUCCESS: {
    en: "Order is created unsuccessfully!",
    de: "Auftrag wurde erfolglos angelegt!",
    it: "L'ordine viene creato senza successo!",
    nl: "Bestelling is mislukt!"
  },
  ORDER_UPDATE_SUCCESS: {
    en: "Order is updated successfully!",
    de: "Auftrag wurde erfolgreich aktualisiert!",
    it: "L'ordine viene aggiornato con successo!",
    nl: "Bestelling is succesvol bijgewerkt!"
  },
  PACKAGES: {
    en: "Packages",
    de: "Pakete",
    it: "Pacchetti",
    nl: "Pakketjes"
  },
  ALL_PACKAGES: {
    en: "List of all packages in the system",
    de: "Liste aller Pakete im System",
    it: "Elenco di tutti i pacchetti nel sistema",
    nl: "Lijst met alle pakketten in het systeem"
  },
  CREATE_PACKAGE: {
    en: "Create package",
    de: "Paket erstellen",
    it: "Crea pacchetto",
    nl: "Pakket maken"
  },
  ASSIGN_PACKAGE: {
    en: "Select module and assign into package*",
    de: "Modul auswählen und in Paket zuordnen*",
    it: "Selezionare il modulo e assegnarlo al pacchetto*",
    nl: "Selecteer module en wijs toe aan pakket*"
  },
  ASSIGNED_MODULES: {
    en: "Assigned modules",
    de: "Zugewiesene Module",
    it: "Moduli assegnati",
    nl: "Toegewezen modules"
  },
  UPDATE_PACKAGE: {
    en: "Update package",
    de: "Paket aktualisieren",
    it: "Pacchetto di aggiornamento",
    nl: "Pakket bijwerken"
  },
  PACKAGE_CREATE_SUCCESS: {
    en: "Package is created successfully.",
    de: "Das Paket wurde erfolgreich erstellt.",
    it: "Il pacchetto viene creato con successo.",
    nl: "Pakket is succesvol aangemaakt."
  },
  PACKAGE_UPDATE_SUCCESS: {
    en: "Package is updated successfully.",
    de: "Paket wurde erfolgreich aktualisiert.",
    it: "Il pacchetto viene aggiornato con successo.",
    nl: "Pakket is succesvol bijgewerkt."
  },
  PASSWORD_RESET_SUCCESS: {
    en: "Password reset successfully",
    de: "Passwort erfolgreich zurückgesetzt",
    it: "Ripristino della password con successo",
    nl: "Wachtwoord succesvol gereset"
  },
  ENTER_PLATFORM_NAME: {
    en: "Enter platform name",
    de: "Plattformname eingeben",
    it: "Inserisci il nome della piattaforma",
    nl: "Voer de naam van het platform in"
  },
  CREATE_MODULE: {
    en: "Create Module",
    de: "Modul erstellen",
    it: "Creare un modulo",
    nl: "Module maken"
  },
  EDIT_PLATFORM: {
    en: "Edit platform ",
    de: "Plattform bearbeiten",
    it: "Modifica della piattaforma",
    nl: "Platform bewerken"
  },
  CREATE_PLATFORM: {
    en: "Create Platform",
    de: "Plattform erstellen",
    it: "Creare una piattaforma",
    nl: "Platform maken"
  },
  PLATFORM: {
    en: "Platform",
    de: "Plattform",
    it: "Piattaforma",
    nl: "Platform"
  },
  PLATFORM_LISTING: {
    en: "Platform listing",
    de: "Plattform-Auflistung",
    it: "Elenco delle piattaforme",
    nl: "Platform lijst"
  },
  EDIT_ROLE: {
    en: "Edit Role",
    de: "Rolle bearbeiten",
    it: "Modifica ruolo",
    nl: "Wijzig rol"
  },
  CREATE_ROLE: {
    en: "Create Role",
    de: "Rolle erstellen",
    it: "Creare un ruolo",
    nl: "Creëer rol"
  },
  ASSIGN_PLATFORM: {
    en: "Assign Platform",
    de: "Plattform zuweisen",
    it: "Assegnare la piattaforma",
    nl: "Platform toewijzen"
  },
  SELECTED_PLATFORM: {
    en: "Selected Platform(s): ",
    de: "Ausgewählte Plattform: ",
    it: "Piattaforma selezionata: ",
    nl: "Geselecteerd platform: "
  },
  PERMISSION_SETTING: {
    en: "Module and Permissions settings: ",
    de: "Modul- und Berechtigungseinstellungen: ",
    it: "Impostazioni del modulo e dei permessi: ",
    nl: "Module en Machtigingen instellingen: "
  },
  READ: {
    en: "Read",
    de: "Erstellen",
    it: "Crea",
    nl: "Creëer"
  },
  ROLE_AND_PERMISSIONS: {
    en: "Role and Permissions",
    de: "Rolle und Berechtigungen",
    it: "Ruolo e permessi",
    nl: "Rol en machtigingen"
  },
  SETUP_ROLE_AND_PERMISSIONS: {
    en: "Setup role and permissions",
    de: "Rolle und Berechtigungen einrichten",
    it: "Impostare ruolo e permessi",
    nl: "Rol en rechten instellen"
  },
  ROLE_CREATE_SUCCESS: {
    en: "Roles created successfully",
    de: "Rollen erfolgreich erstellt",
    it: "Ruoli creati con successo",
    nl: "Rollen succesvol aangemaakt"
  },
  ROLE_UPDATE_SUCCESS: {
    en: "Roles updated successfully",
    de: "Rollen erfolgreich aktualisiert",
    it: "Ruoli aggiornati con successo",
    nl: "Rollen met succes bijgewerkt"
  },
  VIEW_SECTION: {
    en: "View section",
    de: "Abschnitt anzeigen",
    it: "Visualizza sezione",
    nl: "Bekijk sectie"
  },
  UPDATE_SECTION: {
    en: "Update section",
    de: "Update-Bereich",
    it: "Sezione di aggiornamento",
    nl: "Sectie bijwerken"
  },
  ARCHIVE_SECTION: {
    en: "Archive section",
    de: "Archiv-Bereich",
    it: "Sezione archivio",
    nl: "Archief sectie"
  },
  ALL_SECTION: {
    en: "All Sections",
    de: "Alle Sections",
    it: "Tutte le sezioni",
    nl: "Alle secties"
  },
  SECTION_LISTING: {
    en: "Section Listing",
    de: "Sections Auflistung",
    it: "Elenco delle sezioni",
    nl: "Sectie Lijst"
  },
  CREATE_SECTION: {
    en: "Create Section",
    de: "Section erstellen",
    it: "Creare una sezione",
    nl: "Sectie maken"
  },
  TEMPLATE_IMAGE: {
    en: "Template Image",
    de: "Vorlage Bild",
    it: "Immagine del modello",
    nl: "Sjabloon afbeelding"
  },
  ENTER_TEMPLATE_NAME: {
    en: "Enter template name",
    de: "Name der Vorlage eingeben",
    it: "Inserisci il nome del modello",
    nl: "Naam sjabloon invoeren"
  },
  IMAGE_LABEL: {
    en: "Click image to upload",
    de: "Bild zum Hochladen anklicken",
    it: "Clicca sull'immagine per caricarla",
    nl: "Klik op de afbeelding om te uploaden"
  },
  EDIT_TEMPLATE: {
    en: "Edit template",
    de: "Vorlage bearbeiten",
    it: "Modifica modello",
    nl: "Sjabloon bewerken"
  },
  TEMPLATE_EDITOR: {
    en: "Go to template editor",
    de: "Zum Vorlagen-Editor gehen",
    it: "Vai all'editor di modelli",
    nl: "Ga naar sjabloon editor"
  },
  ALL_TEMPLATE: {
    en: "All templates",
    de: "Alle Vorlagen",
    it: "Tutti i modelli",
    nl: "Alle sjablonen"
  },
  TEMPLATE_LISTING: {
    en: "Templates listing",
    de: "Auflistung der Schablonen",
    it: "Elenco dei modelli",
    nl: "Lijst sjablonen"
  },
  NEW_TEMPLATE: {
    en: "New Template",
    de: "Neue Vorlage",
    it: "Nuovo modello",
    nl: "Nieuw sjabloon"
  },
  CREATE_NEW_TEMPLATE: {
    en: "Create new template",
    de: "Neue Vorlage erstellen",
    it: "Creare un nuovo modello",
    nl: "Maak nieuw sjabloon"
  },
  UPDATE_TEMPLATE: {
    en: "Update template",
    de: "Vorlage aktualisieren",
    it: "Aggiornare il modello",
    nl: "Sjabloon bijwerken"
  },
  CREATE_TEMPLATE: {
    en: "Create Template",
    de: "Vorlage erstellen",
    it: "Crea modello",
    nl: "Sjabloon maken"
  },
  SOCIAL_MEDIA: {
    en: "Social media platform",
    de: "Social Media Icons",
    it: "Piattaforma di social media",
    nl: "Social media-platform"
  },
  UPDATE_SOCIAL_MEDIA: {
    en: "Update social media",
    de: "Soziale Medien aktualisieren",
    it: "Aggiorna i social media",
    nl: "Update sociale media"
  },
  SYSTEM_SETTING: {
    en: "System Settings",
    de: "Systemeinstellungen",
    it: "Impostazioni di sistema",
    nl: "Systeem Instellingen"
  },
  VIEW_DETAILS: {
    en: "View details",
    de: "Details anzeigen",
    it: "Visualizza i dettagli",
    nl: "Details bekijken"
  },
  UPDATE_USER: {
    en: "Update user",
    de: "Benutzer aktualisieren",
    it: "Aggiorna l'utente",
    nl: "Gebruiker bijwerken"
  },
  USER: {
    en: "User",
    de: "Benutzer",
    it: "Utente",
    nl: "Gebruiker"
  },
  USER_MANAGEMENT: {
    en: "Users management here",
    de: "Benutzerverwaltung hier",
    it: "Gestione degli utenti qui",
    nl: "Gebruikersbeheer hier"
  },
  CREATE_USER: {
    en: "Create User",
    de: "Benutzer erstellen",
    it: "Crea utente",
    nl: "Gebruiker aanmaken"
  },
  ENTER_HOUSE_NUMBER: {
    en: "Enter house number",
    de: "Hausnummer eingeben",
    it: "Inserisci il numero civico",
    nl: "Voer huisnummer in"
  },
  ENTER_POSTCODE: {
    en: "Enter postcode",
    de: "Postleitzahl eingeben",
    it: "Inserire il codice postale",
    nl: "Vul de postcode in"
  },
  ENTER_CITY: {
    en: "Enter city",
    de: "Stadt eingeben",
    it: "Entra in città",
    nl: "Ga de stad in"
  },
  PHONE_NUMBER: {
    en: "Phone Number",
    de: "Telefonnummer",
    it: "Numero di telefono",
    nl: "Telefoonnummer"
  },
  COUNTRY_NOT_FOUND: {
    en: "Country not found.",
    de: "Land nicht gefunden.",
    it: "Paese non trovato.",
    nl: "Land niet gevonden."
  },
  USER_CREATE_SUCCESS: {
    en: "User is created successfully",
    de: "Benutzer wurde erfolgreich angelegt",
    it: "L'utente è stato creato con successo",
    nl: "Gebruiker is succesvol aangemaakt"
  },
  USER_UPDATE_SUCCESS: {
    en: "User is updated successfully",
    de: "Benutzer wurde erfolgreich aktualisiert",
    it: "L'utente viene aggiornato con successo",
    nl: "Gebruiker is succesvol bijgewerkt"
  },
  SITE_STATUS: {
    en: "Site status",
    de: "Status der Website",
    it: "Stato del sito",
    nl: "Status van de site"
  },
  LOGOUT: {
    en: "Logout",
    de: "Abmelden",
    it: "Logout",
    nl: "Uitloggen"
  },
  CONFIRMATION_MODAL: {
    en: "Confirmation modal",
    de: "Bestätigungsmodal",
    it: "Modalità di conferma",
    nl: "Bevestiging modaal"
  },
  COCKPIT: {
    en: "Cockpit",
    de: "Cockpit",
    it: "Cockpit",
    nl: "Cockpit"
  },
  DASHBOARD: {
    en: "Dashboard",
    de: "Dashboard",
    it: "Dashboard",
    nl: "DashBoard"
  },
  CENTER_GROUP_MENU: {
    en: "Center Group",
    de: "Center Gruppen",
    it: "Gruppo Centro",
    nl: "Centrumgroep"
  },
  MODULE: {
    en: "Module",
    de: "Module",
    it: "Modulo",
    nl: "Module"
  },
  PACKAGE: {
    en: "Package",
    de: "Pakete",
    it: "Pacchetto",
    nl: "Pakket"
  },
  ORDER: {
    en: "Order",
    de: "Bestellungen",
    it: "Ordina",
    nl: "Bestel"
  },
  SITE_TEMPLATE_CONFIG: {
    en: "Site Template Config",
    de: "Website Vorlagen",
    it: "Configurazione del modello del sito",
    nl: "Site Sjabloon Config"
  },
  TEMPLATE_LISTINGS: {
    en: "Template Listings",
    de: "Website Layouts",
    it: "Elenchi di modelli",
    nl: "Sjabloon lijsten"
  },
  SECTION_LISTINGS: {
    en: "Section Listings",
    de: "Content Vorlagen",
    it: "Elenchi delle sezioni",
    nl: "Sectielijsten"
  },
  MEDIA: {
    en: "Media",
    de: "Bilder Datenbank",
    it: "Media",
    nl: "Media"
  },
  CONTENT_SERVICE: {
    en: "Content Service",
    de: "Content Service",
    it: "Servizio contenuti",
    nl: "Inhoud Service"
  },
  ALL_CATEGORIES: {
    en: "All Categories",
    de: "Kategorien",
    it: "Tutte le categorie",
    nl: "Alle Categorieën"
  },
  OLS: {
    en: "OLS",
    de: "OLS",
    it: "OLS",
    nl: "OLS"
  },
  TYPES: {
    en: "Types",
    de: "Kategorien",
    it: "Tipi",
    nl: "Soorten"
  },
  LISTINGS: {
    en: "Listings",
    de: "Kampagnen",
    it: "Annunci",
    nl: "Lijsten"
  },
  MODULE_SETTINGS: {
    en: "Module Settings",
    de: "Einstellungen",
    it: "Impostazioni del modulo",
    nl: "Module-instellingen"
  },
  BOOKED_CAMPAIGN_MENU: {
    en: "Book Campaign",
    de: "Gebuchte Kampagnen",
    it: "Campagna del libro",
    nl: "Boek Campagne"
  },
  DIGITAL_ORDER_FORM: {
    en: "Digital Order Form",
    de: "Digitaler Bestellschein",
    it: "Modulo d'ordine digitale",
    nl: "Digitaal bestelformulier"
  },
  FORM_CATEGORIES: {
    en: "Form Categories",
    de: "Formular-Kategorien",
    nl: "Vorm Categorieën",
    it: "Categorie di forme"
  },
  EDIT_ORDER_STATUS: {
    en: "Edit Order Status",
    de: "Auftragsstatus bearbeiten",
    it: "Modifica stato dell'ordine",
    nl: "Bestelstatus wijzigen"
  },
  FORM_ORDER_REQUEST: {
    en: "Order Request",
    de: "Aufträge",
    it: "Richiesta d'ordine",
    nl: "Bestelaanvraag"
  },
  FORM_BUILDER_MENU: {
    en: "Form Builder",
    de: "Formular Designer",
    it: "Costruttore di moduli",
    nl: "Formulierenbouwer"
  },
  USER_SETTINGS: {
    en: "User Settings",
    de: "Benutzereinstellungen",
    it: "Impostazioni utente",
    nl: "Gebruikers Instellingen"
  },
  NEWS_AND_PRODUCTS: {
    en: "News and Products",
    de: "News & Produkte",
    it: "Notizie e prodotti",
    nl: "Nieuws en Producten"
  },
  NEWS: {
    en: "News",
    de: "News",
    it: "Notizie",
    nl: "Nieuws"
  },
  PRODUCTS: {
    en: "Products",
    de: "Produkte",
    it: "Prodotti",
    nl: "Producten"
  },
  SECURITY: {
    en: "Security",
    de: "Sicherheit",
    it: "Sicurezza",
    nl: "Beveiliging"
  },
  ROLES_AND_PERMISSIONS: {
    en: "Roles and Permissions",
    de: "Rollen und Berechtigungen",
    it: "Ruoli e permessi",
    nl: "Rollen en Rechten"
  },
  USERS_MANAGEMENT: {
    en: "Users Management",
    de: "Benutzerverwaltung",
    it: "Gestione utenti",
    nl: "Gebruikersbeheer"
  },
  DOMAINS_LISTINGS: {
    en: "Domain Listings",
    de: "Domains",
    it: "Annunci di dominio",
    nl: "Domein Lijsten"
  },
  ONLINE_SHOP: {
    en: "Online Shops",
    de: "Online Shop",
    it: "Negozi online",
    nl: "Online Winkels"
  },
  FAQ_LISTINGS: {
    en: "FAQ Listings",
    de: "FAQs",
    it: "Annunci FAQ",
    nl: "FAQ vermeldingen"
  },
  CAMPAIGN_TOOLS: {
    en: "Campaign Tools",
    de: "Kampagnen Tools",
    it: "Strumenti della campagna",
    nl: "Campagne Gereedschap"
  },
  PRODUCT_REQUEST_LISTING: {
    en: "Listings",
    de: "Auflistungen",
    it: "Elenchi",
    nl: "Advertenties"
  },
  SOCIAL_MEDIA_MENU: {
    en: "Social Media",
    de: "Sozialen Medien",
    it: "Social media",
    nl: "Sociale media"
  },
  CAMPAIGNS: {
    en: "Campaigns",
    de: "Kampagnen",
    it: "Campagne",
    nl: "Campagnes"
  },
  CENTER_MENU: {
    en: "Center",
    de: "Center",
    it: "Centro",
    nl: "Centrum"
  },
  NOTIFICATION: {
    en: "Notification",
    de: "Benachrichtigung",
    it: "Notifica",
    nl: "Kennisgeving"
  },
  PAGE: {
    en: "Page",
    de: "Seite",
    it: "Pagina",
    nl: "Pagina"
  },
  OF: {
    en: "of",
    de: "von",
    it: "di",
    nl: "van"
  },
  NORMAL_SEARCH: {
    en: "Normal Search",
    de: "Normale Suche",
    it: "Ricerca normale",
    nl: "Normaal zoeken"
  },
  ADVANCED_SEARCH: {
    en: "Advanced Search",
    de: "Erweiterte Suche",
    it: "Ricerca avanzata",
    nl: "Uitgebreid zoeken"
  },
  CLICK_TO_UPLOAD: {
    en: "Click on image to start upload an image",
    de: "Klicken Sie auf das Bild, um das Hochladen eines Bildes zu starten",
    it: "Clicca sull'immagine per iniziare a caricare un'immagine",
    nl: "Klik op de afbeelding om te beginnen met het uploaden van een afbeelding"
  },
  SEARCH_WITH_IMAGE_SHORTNAME: {
    en: "Search with image shortname",
    de: "Suche mit Bildkurzname",
    it: "Ricerca con nome breve dell'immagine",
    nl: "Zoeken met afbeeldingsnaam"
  },
  ORIGINAL: {
    en: "Original",
    de: "Original",
    it: "Originale",
    nl: "Original"
  },
  SMALL: {
    en: "Small",
    de: "Klein",
    it: "Piccolo",
    nl: "Small"
  },
  CONSULTANT_CREATE_SUCCESS: {
    en: "Consultant is created successfully!",
    de: "Berater ist erfolgreich angelegt!",
    it: "Il consulente è stato creato con successo!",
    nl: "Adviseur is succesvol aangemaakt!"
  },
  CONSULTANT_CREATE_UNSUCCESS: {
    en: "Consultant is created unsuccessfully!",
    de: "Berater konnte nicht angelegt werden!",
    it: "Il consulente è creato senza successo!",
    nl: "Adviseur is zonder succes gemaakt!"
  },
  CONSULTANT_UPDATE_SUCCESS: {
    en: "Consultant is updated successfully!",
    de: "Berater wurde erfolgreich aktualisiert!",
    it: "Il consulente è aggiornato con successo!",
    nl: "Adviseur is met succes bijgewerkt!"
  },
  CONSULTANT_UPDATE_UNSUCCESS: {
    en: "Consultant is updated unsuccessfully!",
    de: "Berater konnte nicht angelegt werden!",
    it: "Il consulente è aggiornato senza successo!",
    nl: "Adviseur wordt zonder succes bijgewerkt!"
  },
  CONSULTANT_STATUS_UPDATE_SUCCESS: {
    en: "Consultant status is updated successfully!",
    de: "Beraterstatus wurde erfolgreich aktualisiert!",
    it: "Lo stato del consulente è stato aggiornato con successo!",
    nl: "De status van de adviseur is met succes bijgewerkt!"
  },
  CONSULTANT_STATUS_UPDATE_UNSUCCESS: {
    en: "Consultant status is updated unsuccessfully!",
    de: "Beraterstatus konnte nicht aaktualisiert werden!",
    it: "Lo stato del consulente viene aggiornato senza successo!",
    nl: "De status van de adviseur is zonder succes bijgewerkt!"
  },
  CENTER_GROUP_CREATE_SUCCESS: {
    en: "Center Group is created successfully!",
    de: "Center Group ist erfolgreich erstellt!",
    it: "Il gruppo centrale è stato creato con successo!",
    nl: "Centrumgroep is succesvol aangemaakt!"
  },
  CENTER_GROUP_UPDATE_SUCCESS: {
    en: "Center Group is updated successfully!",
    de: "Center Group wurde erfolgreich aktualisiert!",
    it: "Center Group è aggiornato con successo!",
    nl: "Centrumgroep is succesvol bijgewerkt!"
  },
  CENTER_GROUP_STATUS_UPDATE_SUCCESS: {
    en: "Center Group status is updated successfully!",
    de: "Der Status der Center Group wurde erfolgreich aktualisiert!",
    it: "Lo stato del gruppo centrale è stato aggiornato con successo!",
    nl: "Center Groep status is succesvol bijgewerkt!"
  },
  CENTER_GROUP_STATUS_UPDATE_FAILED: {
    en: "Center Group status update failed.",
    de: "Die Statusaktualisierung der Center Group ist fehlgeschlagen.",
    it: "L'aggiornamento dello stato del gruppo centrale non è riuscito.",
    nl: "Center Groep status update mislukt."
  },
  CENTER_GROUP_REMOVE_SUCCESS: {
    en: "Center Group removed successfully!",
    de: "Center Group erfolgreich entfernt!",
    it: "Gruppo centrale rimosso con successo!",
    nl: "Centrumgroep succesvol verwijderd!"
  },
  CENTER_GROUP_REMOVE_FAILED: {
    en: "Center Group remove failed",
    de: "Entfernen der Center-Gruppe fehlgeschlagen",
    it: "Rimozione del gruppo centrale non riuscita",
    nl: "Center Groep verwijderen mislukt"
  },
  CLIENT_CREATE_SUCCESS: {
    en: "Client is created successfully!",
    de: "Kunde wurde erfolgreich angelegt!",
    it: "Il cliente è stato creato con successo!",
    nl: "Klant is succesvol aangemaakt!"
  },
  CLIENT_UPDATE_SUCCESS: {
    en: "Client is updated successfully!",
    de: "Kunde wurde erfolgreich aktualisiert!",
    it: "Il cliente viene aggiornato con successo!",
    nl: "Client is succesvol bijgewerkt!"
  },
  CLIENT_UPDATE_UNSUCCESS: {
    en: "Client is updated unsuccessfully!",
    de: "Kunde wurde erfolglos aktualisiert!",
    it: "Il cliente viene aggiornato senza successo!",
    nl: "Client is zonder succes bijgewerkt!"
  },
  CLIENT_STATUS_UPDATE_SUCCESS: {
    en: "Client status is updated successfully!",
    de: "Kunden-Status wurde erfolgreich aktualisiert!",
    it: "Lo stato del cliente è stato aggiornato con successo!",
    nl: "Klant status is succesvol bijgewerkt!"
  },
  CLIENT_REMOVE_SUCCESS: {
    en: "Client removed successfully!",
    de: "Kunde erfolgreich entfernt!",
    it: "Cliente rimosso con successo!",
    nl: "Klant succesvol verwijderd!"
  },
  STATUS_MODAL_MESSAGE: {
    en: "You might be disconnected from the Internet, please reconnect and refresh the page to use Ark again.",
    de: "Möglicherweise ist die Verbindung zum Internet unterbrochen. Bitte stellen Sie die Verbindung wieder her und aktualisieren Sie die Seite, um Ark erneut zu verwenden.",
    it: "Potresti essere disconnesso da Internet, per favore riconnettiti e aggiorna la pagina per usare di nuovo l'Arca.",
    nl: "De verbinding met internet is misschien verbroken, maak opnieuw verbinding en vernieuw de pagina om Ark opnieuw te gebruiken."
  },
  FIND_TENNIS_EXP: {
    en: "Find your tennis experience",
    de: "Finde dein Tenniserlebnis",
    nl: "Finde dein Tenniserlebnis",
    it: "Finde dein Tenniserlebnis"
  },
  EDIT_FOOTER: {
    en: "Edit Footer",
    de: "Fußzeile bearbeiten",
    it: "Modifica piè di pagina",
    nl: "Footer bewerken"
  },
  FOOTER_BLUEPRINT: {
    en: "Footer Blueprint",
    de: "Fußzeile Blueprint",
    it: "Modello di piè di pagina",
    nl: "Footer Blauwdruk"
  },
  FOOTER_STYLING: {
    en: "Footer Styling",
    de: "Fußzeilen-Styling",
    it: "Stilizzazione del piè di pagina",
    nl: "Footer Styling"
  },
  SAVE_FOOTER_BLUEPRINT: {
    en: "Save Footer Blueprint",
    de: "Fußzeilen-Blaupause speichern",
    it: "Salva il modello del piè di pagina",
    nl: "Footer blauwdruk opslaan"
  },
  SAVE_FOOTER_STYLING: {
    en: "Save Footer Styling",
    de: "Fußzeilenstyling speichern",
    it: "Salva lo stile del piè di pagina",
    nl: "Footer Styling opslaan"
  },
  DESKTOP_VERSION: {
    en: "Desktop Version",
    de: "Desktop-Version",
    it: "Versione desktop",
    nl: "Desktop-versie"
  },
  MOBILE_VERSION: {
    en: "Mobile Version",
    de: "Mobile Version",
    it: "Versione mobile",
    nl: "Mobiele Versie"
  },
  BODY: {
    en: "Body",
    de: "Body",
    it: "Corpo",
    nl: "Lichaam"
  },
  FOOTER: {
    en: "Footer",
    de: "Fußzeile",
    it: "Piè di pagina",
    nl: "Voettekst"
  },
  MODIFY_COLOR_OF: {
    en: "Modify color of :",
    de: "Ändern Sie die Farbe von :",
    it: "Modificare il colore di :",
    nl: "Kleur wijzigen van :"
  },
  FOOTER_BACKGROUND: {
    en: "Footer Background",
    de: "Footer Hintergrund",
    it: "Sfondo del piè di pagina",
    nl: "Footer achtergrond"
  },
  FOOTER_TEXT: {
    en: "Footer Text",
    de: "Footertext",
    it: "Testo a piè di pagina",
    nl: "Tekst voettekst"
  },
  EDIT_PAGE: {
    en: "Edit Page",
    de: "Seite bearbeiten",
    it: "Modifica pagina",
    nl: "Pagina bewerken"
  },
  SAVE_PAGE: {
    en: "Save Page",
    de: "Seite speichern",
    it: "Salvare la pagina",
    nl: "Pagina opslaan"
  },
  PUBLISH_PAGE: {
    en: "Publish Page",
    de: "Seite veröffentlichen",
    it: "Pubblicare la pagina",
    nl: "Pagina publiceren"
  },
  EDIT_HEADER: {
    en: "Edit Header",
    de: "Header bearbeiten",
    it: "Modifica dell'intestazione",
    nl: "Koptekst bewerken"
  },
  HEADER_MENU: {
    en: "Header Menu",
    de: "Header Menü",
    it: "Menu d'intestazione",
    nl: "Menu Koptekst"
  },
  SAVE_UPDATED_HEADER: {
    en: "Save Updated Header",
    de: "Aktualisierte Header speichern",
    it: "Salva l'intestazione aggiornata",
    nl: "Bijgewerkte koptekst opslaan"
  },
  SAVE_UPDATED_HEADER_MENU: {
    en: "Save Updated Header Menu",
    de: "Aktualisiertes Header speichern",
    it: "Salva il menu d'intestazione aggiornato",
    nl: "Bijgewerkt kopmenu opslaan"
  },
  SAVE_UPDATED_HEADER_MENU_BLUEPRINT: {
    en: "Save Updated Header Menu Blueprint",
    de: "Aktualisierten Entwurf für das Headermenü speichern",
    it: "Salvare il blueprint aggiornato del menu dell'intestazione",
    nl: "Bijgewerkte blauwdruk kopmenu opslaan"
  },
  SAVE_UPDATED_HEADER_MENU_STYLING: {
    en: "Save Updated Header Menu Styling",
    de: "Aktualisiertes Headermenü-Styling speichern",
    it: "Salva lo stile del menu dell'intestazione aggiornato",
    nl: "Bijgewerkte kop-menu styling opslaan"
  },
  CURRENT_SELECTED_IMAGE: {
    en: "Current selected image : ",
    de: "Aktuell ausgewähltes Bild : ",
    it: "Immagine corrente selezionata : ",
    nl: "Huidig geselecteerd beeld : "
  },
  CHOOSE_ANOTHER_IMAGE: {
    en: "Choose another image from media database.",
    de: "Wählen Sie ein anderes Bild aus der Mediendatenbank.",
    it: "Scegli un'altra immagine dal database dei media.",
    nl: "Kies een andere afbeelding uit de mediadatabank."
  },
  ADD_NEW_MENU: {
    en: "Add New Menu",
    de: "Neues Menü hinzufügen",
    it: "Aggiungi un nuovo menu",
    nl: "Nieuw menu toevoegen"
  },
  DESKTOP_MENU: {
    en: "Desktop Menu",
    de: "Desktop-Menü",
    it: "Menu del desktop",
    nl: "Desktop Menu"
  },
  MOBILE_MENU: {
    en: "Mobile Menu",
    de: "Mobiles Menü",
    it: "Menu mobile",
    nl: "Mobiel menu"
  },
  CONTACT_BAR_ITEM: {
    en: "Contact Bar Item",
    de: "Kontaktleiste Artikel",
    it: "Contatto Bar Voce",
    nl: "Contact Bar Punt"
  },
  HEADER: {
    en: "Header",
    de: "Hedaer",
    it: "Intestazione",
    nl: "Koptekst"
  },
  MENU_ITEM: {
    en: "Menu Item",
    de: "Menüpunkt",
    it: "Voce di menu",
    nl: "Menu-item"
  },
  HOVERED_MENU_ITEM: {
    en: "Hovered Menu Item",
    de: "Hovered Menüpunkt",
    it: "Voce di menu sospesa",
    nl: "Verplaatst Menu-item"
  },
  MENU_ITEM_HOVER: {
    en: "Menu Item Hover",
    de: "Menüpunkt Hover",
    it: "Voce di menu Hover",
    nl: "Menu item Hover"
  },
  HEADER_BACKGROUND: {
    en: "Header Background",
    de: "Header Hintergrund",
    it: "Sfondo dell'intestazione",
    nl: "Koptekst Achtergrond"
  },
  SITE_LOGO: {
    en: "Site Logo",
    de: "Standort-Logo",
    it: "Logo del sito",
    nl: "Site-logo"
  },
  HEADER_MENU_BLUEPRINT: {
    en: "Header Menu Blueprint",
    de: "Blaupause für das Headernmenü",
    it: "Modello di menu d'intestazione",
    nl: "Header Menu Blauwdruk"
  },
  HEADER_MENU_STYLING: {
    en: "Header Menu Styling",
    de: "Headermenü-Styling",
    it: "Stile del menu dell'intestazione",
    nl: "Header Menu Styling"
  },
  LINK_TO: {
    en: "Link to",
    de: "Link zu",
    it: "Collegamento a",
    nl: "Link naar"
  },
  HEADER_UPDATE_SUCCESS: {
    en: "Header is updated successfully.",
    de: "Header wurde erfolgreich aktualisiert.",
    it: "L'intestazione viene aggiornata con successo.",
    nl: "Koptekst is met succes bijgewerkt."
  },
  TEMPLATE_LANGUAGE_EDITOR: {
    en: "Template Languege Editor",
    de: "Vorlage Languege Editor",
    it: "Template Languege Editor",
    nl: "Sjabloon Languege Editor"
  },
  UPDATE_STYLESHEET: {
    en: "Update Stylesheet",
    de: "Stylesheet aktualisieren",
    it: "Aggiornare il foglio di stile",
    nl: "Stylesheet bijwerken"
  },
  REMOVE_STYLESHEET: {
    en: "Remove Stylesheet",
    de: "Stylesheet entfernen",
    it: "Rimuovere il foglio di stile",
    nl: "Stylesheet verwijderen"
  },
  SELECTED_LANGUAGE: {
    en: "Selected Language",
    de: "Ausgewählte Sprache",
    it: "Lingua selezionata",
    nl: "Geselecteerde Taal"
  },
  TOOLTIP_TITLE: {
    en: "Save Preview website in selected language",
    de: "Vorschau der Website in der gewählten Sprache speichern",
    it: "Salva l'anteprima del sito web nella lingua selezionata",
    nl: "Preview website opslaan in geselecteerde taal"
  },
  SELECT_LANGUAGE_TEMPLATE_EDITOR: {
    en: "Select language",
    de: "Sprache auswählen",
    it: "Selezionare la lingua",
    nl: "Selecteer taal"
  },
  CREATE_LANGUAGE: {
    en: "Create Language",
    de: "Sprache erstellen",
    it: "Creare la lingua",
    nl: "Creëer Taal"
  },
  INPUT_LONG_NAME: {
    en: "Input the long name, eg English",
    de: "Eingabe des langen Namens, z. B. Englisch",
    it: "Inserisci il nome lungo, ad esempio inglese",
    nl: "Voer de lange naam in, bijvoorbeeld Engels"
  },
  INPUT_SHORT_NAME: {
    en: "Input the short name, eg en",
    de: "Eingabe des Kurznamens, z. B. en",
    it: "Inserire il nome breve, ad esempio en",
    nl: "Voer de korte naam in, bijv. en"
  },
  CREATE_PAGE_FOR_TEMPLATE: {
    en: "Create a page for template",
    de: "Eine Seite für die Vorlage erstellen",
    it: "Creare una pagina per il modello",
    nl: "Maak een pagina voor sjabloon"
  },
  PAGE_NAME: {
    en: "Page Name",
    de: "Seite Name",
    it: "Nome della pagina",
    nl: "Naam van de pagina"
  },
  PAGE_URL: {
    en: "Page URL",
    de: "Seite URL",
    it: "URL della pagina",
    nl: "URL van de pagina"
  },
  CREATE_PAGE: {
    en: "Create Page",
    de: "Seite erstellen",
    it: "Creare una pagina",
    nl: "Pagina maken"
  },
  EDIT_CURRENT_PAGE: {
    en: "Edit current selected page:",
    de: "Aktuell ausgewählte Seite bearbeiten:",
    it: "Modifica la pagina correntemente selezionata:",
    nl: "Bewerk de huidige geselecteerde pagina:"
  },
  SAVE_UPDATED_PAGE: {
    en: "Save Updated Page",
    de: "Aktualisierte Seite speichern",
    it: "Salva la pagina aggiornata",
    nl: "Bijgewerkte pagina opslaan"
  },
  EDIT_PAGE_JSON: {
    en: "Edit page JSON",
    de: "JSON-Seite bearbeiten",
    it: "Modifica pagina JSON",
    nl: "JSON-pagina bewerken"
  },
  GENERATE_ID: {
    en: "Generate ID for new DOM",
    de: "ID für neues DOM generieren",
    it: "Generare l'ID per il nuovo DOM",
    nl: "Genereer ID voor nieuwe DOM"
  },
  UPDATE_PAGE_JSON: {
    en: "Update Page JSON",
    de: "Seite aktualisieren JSON",
    it: "Aggiornare la pagina JSON",
    nl: "Pagina JSON bijwerken"
  },
  BLANK_TEMPLATE_PAGE: {
    en: "You have blank template page.",
    de: "Sie haben eine leere Vorlagenseite.",
    it: "Hai una pagina modello vuota.",
    nl: "Je hebt een lege sjabloon pagina."
  },
  ADD_SOME_SECTION: {
    en: "Try add some section and start editing.",
    de: "Versuchen Sie, einen Abschnitt hinzuzufügen und mit der Bearbeitung zu beginnen.",
    it: "Prova ad aggiungere qualche sezione e inizia a modificare.",
    nl: "Probeer wat sectie toe te voegen en begin met bewerken."
  },
  ADD_STYLESHEET: {
    en: "Add Stylesheet",
    de: "Stylesheet hinzufügen",
    it: "Aggiungere il foglio di stile",
    nl: "stylesheet toevoegen"
  },
  ADD_JAVASCRIPT: {
    en: "Add Javascript",
    de: "Javascript hinzufügen",
    it: "Aggiungi Javascript",
    nl: "Javascript toevoegen"
  },
  CHANGE_RENDERER_VIEWPORT: {
    en: "Change Renderer Viewport",
    de: "Renderer-Ansichtsfenster ändern",
    it: "Cambiare la finestra del renderizzatore",
    nl: "Verander Renderer Viewport"
  },
  HEADER_CONFIGURATIONS: {
    en: "Header Configurations",
    de: "Kopfzeilen-Konfigurationen",
    it: "Configurazioni dell'intestazione",
    nl: "Kop Configuraties"
  },
  FOOTER_CONFIGURATIONS: {
    en: "Footer Configurations",
    de: "Fußzeilen-Konfigurationen",
    it: "Configurazioni del piè di pagina",
    nl: "Voetregel configuraties"
  },
  ADD_SECTION: {
    en: "Add Section",
    de: "Abschnitt hinzufügen",
    it: "Aggiungi sezione",
    nl: "Sectie toevoegen"
  },
  SELECT_VIEWPORT: {
    en: "Select a viewport",
    de: "Wählen Sie ein Ansichtsfenster",
    it: "Selezionare una finestra",
    nl: "Selecteer een viewport"
  },
  DESKTOP_VIEW: {
    en: "Desktop View",
    de: "Desktop-Ansicht",
    it: "Vista sul desktop",
    nl: "Desktop weergave"
  },
  MOBILE_VIEW: {
    en: "Mobile View",
    de: "Mobile Ansicht",
    it: "Vista mobile",
    nl: "Mobiel bekijken"
  },
  CODE_EDITOR: {
    en: "Code Editor for Selected Component",
    de: "Code-Editor für ausgewählte Komponente",
    it: "Editor di codice per il componente selezionato",
    nl: "Code-editor voor geselecteerd onderdeel"
  },
  CONTENT_SELECTED_LANGUAGE: {
    en: "Content - Selected Language:",
    de: "Inhalt - Ausgewählte Sprache:",
    it: "Contenuto - Lingua selezionata:",
    nl: "Inhoud - Geselecteerde taal:"
  },
  SECTION_EDITOR: {
    en: "Section Editor - Selected Language:",
    de: "Sektionseditor - Ausgewählte Sprache:",
    it: "Editor di sezione - Lingua selezionata:",
    nl: "Section Editor - Selected Language:"
  },
  CAROUSEL_SETTING: {
    en: "Carousel Settings",
    de: "Karussell-Einstellungen",
    it: "Impostazioni del carosello",
    nl: "Carrousel Instellingen"
  },
  CURRENT_PAGE: {
    en: "Current selected page:",
    de: "Aktuell gewählte Seite:",
    it: "Pagina correntemente selezionata:",
    nl: "Huidige geselecteerde pagina:"
  },
  BACKGROUND_IMAGE: {
    en: "Background Image",
    de: "Hintergrundbild",
    it: "Immagine di sfondo",
    nl: "Achtergrondbeeld"
  },
  MEDIA_DATABASE: {
    en: "Media Database",
    de: "Medien-Datenbank",
    it: "Banca dati dei media",
    nl: "Media Databank"
  },
  CAPTION: {
    en: "Caption",
    de: "Beschriftung",
    it: "Didascalia",
    nl: "Onderschrift"
  },
  BUTTON_TEXT: {
    en: "Button Text",
    de: "Schaltfläche Text",
    it: "Testo del pulsante",
    nl: "Knop Tekst"
  },
  BUTTON_LINK: {
    en: "Button Link",
    de: "Schaltfläche Link",
    it: "Collegamento del pulsante",
    nl: "Knop Link"
  },
  REMOVE_THIS_SLIDE: {
    en: "Remove this slide",
    de: "Diese Folie entfernen",
    it: "Rimuovere questa diapositiva",
    nl: "Verwijder deze dia"
  },
  CONTAINER_SETTING: {
    en: "Container Settings",
    de: "Container-Einstellungen",
    it: "Impostazioni del contenitore",
    nl: "Container Instellingen"
  },
  CREATE_STYLE: {
    en: "Create Style",
    de: "Stil erstellen",
    it: "Creare stile",
    nl: "Creëer stijl"
  },
  MINIMUM_HEIGHT: {
    en: "Minimum Height",
    de: "Minimale Höhe",
    it: "Altezza minima",
    nl: "Minimum Hoogte"
  },
  MARGIN_TOP: {
    en: "Margin Top",
    de: "Margin oben",
    it: "Margine Top",
    nl: "Marge Top"
  },
  MARGIN_LEFT: {
    en: "Margin Left",
    de: "Margin links",
    it: "Margine sinistro",
    nl: "Marge Links"
  },
  MARGIN_RIGHT: {
    en: "Margin Right",
    de: "Margin rechts",
    it: "Margine destro",
    nl: "Marge Rechts"
  },
  MARGIN_BOTTOM: {
    en: "Margin Bottom",
    de: "Margin Unten",
    it: "Margine inferiore",
    nl: "Marge Onderkant"
  },
  PADDING_TOP: {
    en: "Padding Top",
    de: "Padding oben",
    it: "Imbottitura Top",
    nl: "Vulling Top"
  },
  PADDING_RIGHT: {
    en: "Padding Right",
    de: "Padding rechts",
    it: "Imbottitura a destra",
    nl: "Opvulling Rechts"
  },
  PADDING_BOTTOM: {
    en: "Padding Bottom",
    de: "Padding Unten",
    it: "Imbottitura inferiore",
    nl: "Vulling Bodem"
  },
  PADDING_LEFT: {
    en: "Padding Left",
    de: "Padding links",
    it: "Imbottitura a sinistra",
    nl: "Vulling Links"
  },
  APPLY: {
    en: "Apply",
    de: "Anwenden",
    it: "Applica",
    nl: "Solliciteer"
  },
  GOOGLE_MAP_SETTING: {
    en: "Google Map Settings",
    de: "Google Map-Einstellungen",
    it: "Impostazioni di Google Map",
    nl: "Google Kaart Instellingen"
  },
  SAVE_CHANGES: {
    en: "Save Changes",
    de: "Änderungen speichern",
    it: "Salvare le modifiche",
    nl: "Wijzigingen opslaan"
  },
  IMAGE_EDITOR: {
    en: "Image Editor",
    de: "Bild-Editor",
    it: "Editor di immagini",
    nl: "Beeldredacteur"
  },
  FLOAT: {
    en: "Float",
    de: "Float",
    it: "Galleggiante",
    nl: "Vlotter"
  },
  BRING_FORWARD: {
    en: "Bring forward",
    de: "Vorziehen",
    it: "Portare avanti",
    nl: "Breng naar voren"
  },
  SEND_BACKWARD: {
    en: "Send backward",
    de: "Zurück bringen",
    it: "Invia all'indietro",
    nl: "Achteruit sturen"
  },
  CHANGE_IMAGE: {
    en: "Change Image with Media Database",
    de: "Bild mit Mediendatenbank ändern",
    it: "Cambiare immagine con il database dei media",
    nl: "Beeld wijzigen met mediadatabase"
  },
  SAVE_CURRENT_IMAGE: {
    en: "Save Current Editing Image",
    de: "Aktuelles Bearbeitungsbild speichern",
    it: "Salvare l'immagine corrente di modifica",
    nl: "Huidige bewerkingsafbeelding opslaan"
  },
  NEW_COMPONENT: {
    en: "New Component",
    de: "Neue Komponente",
    it: "Nuovo componente",
    nl: "Nieuw onderdeel"
  },
  CONTENT_TEMPLATE_EDITOR: {
    en: "This is your created text.",
    de: "Dies ist der von Ihnen erstellte Text.",
    it: "Questo è il tuo testo creato.",
    nl: "Dit is je gemaakte tekst."
  },
  CURRENTLY_SELECTED: {
    en: "Currently Selected",
    de: "Derzeit ausgewählt",
    it: "Attualmente selezionato",
    nl: "Momenteel Geselecteerd"
  },
  TEXT_SETTING: {
    en: "Text Settings",
    de: "Text-Einstellungen",
    it: "Impostazioni del testo",
    nl: "Tekst Instellingen"
  },
  EDIT_TEXT: {
    en: "Edit the text",
    de: "Bearbeiten des Textes",
    it: "Modificare il testo",
    nl: "De tekst bewerken"
  },
  CONTAINER_NAVIGATOR: {
    en: "Container Navigator",
    de: "Container-Navigator",
    it: "Navigatore di contenitori",
    nl: "Container Navigator"
  },
  SECTION_UPDATE_SUCCESS: {
    en: "Section is updated successfully.",
    de: "Abschnitt wurde erfolgreich aktualisiert.",
    it: "La sezione è aggiornata con successo.",
    nl: "Sectie is succesvol bijgewerkt."
  },
  SELECT_A_SECTION: {
    en: "Select a section",
    de: "Wählen Sie einen Abschnitt",
    it: "Seleziona una sezione",
    nl: "Selecteer een sectie"
  },
  SELECT_FROM_EXISTING_SECTION: {
    en: "Select from existing section",
    de: "Aus bestehendem Abschnitt auswählen",
    it: "Seleziona dalla sezione esistente",
    nl: "Selecteer uit bestaande sectie"
  },
  SELECT_SECTION: {
    en: "Select Section",
    de: "Abschnitt auswählen",
    it: "Seleziona la sezione",
    nl: "Selecteer Sectie"
  },
  SELECT_A_SECTION_TEMPLATE: {
    en: "Please select a Section Template",
    de: "Bitte wählen Sie eine Sektionsvorlage",
    it: "Seleziona un modello di sezione",
    nl: "Selecteer een Sectie Sjabloon"
  },
  PREVIEW: {
    en: "Preview :",
    de: "Vorschau :",
    it: "Anteprima :",
    nl: "Voorproefje:"
  },
  SECTION_CREATE_SUCCESS: {
    en: "Section is created successfully.",
    de: "Abschnitt ist erfolgreich erstellt.",
    it: "La sezione viene creata con successo.",
    nl: "Sectie is succesvol aangemaakt."
  },
  EDIT_COMPONENT_STYLE: {
    en: "Edit Component Style",
    de: "Komponentenstil bearbeiten",
    it: "Modifica stile componente",
    nl: "Componentstijl bewerken"
  },
  UPDATE_STYLES: {
    en: "Update Styles",
    de: "Update Stile",
    it: "Aggiornare gli stili",
    nl: "Stijlen bijwerken"
  },
  CREATE_JAVASCRIPT: {
    en: "Create Javascript",
    de: "Javascript erstellen",
    it: "Creare Javascript",
    nl: "Javascript maken"
  },
  UPDATE_JS: {
    en: "Update JS",
    de: "Update JS",
    it: "Aggiornamento JS",
    nl: "Update JS"
  },
  REMOVE_JS: {
    en: "Remove JS",
    de: "JS entfernen",
    it: "Rimuovere JS",
    nl: "JS verwijderen"
  },
  CREATE_STYLESHEET: {
    en: "Create Stylesheet",
    de: "Stylesheet erstellen",
    it: "Creare foglio di stile",
    nl: "Stylesheet maken"
  },
  FOOTER_BLUEPRINT_UPDATE_SUCCESS: {
    en: "Footer blueprint is updated successfully.",
    de: "Der Fußzeilen-Blaupause wurde erfolgreich aktualisiert.",
    it: "Il blueprint del piè di pagina viene aggiornato con successo.",
    nl: "Footer blauwdruk is succesvol bijgewerkt."
  },
  TEMPLATE_HEADER_UPDATE_SUCCESS: {
    en: "Template header updated successfully.",
    de: "Vorlagenkopf erfolgreich aktualisiert.",
    it: "L'intestazione del template è stata aggiornata con successo.",
    nl: "Sjabloon header succesvol bijgewerkt."
  },
  HEADER_BLUEPRINT_UPDATE_SUCCESS: {
    en: "Header blueprint is updated successfully.",
    de: "Header-Blaupause wurde erfolgreich aktualisiert.",
    it: "Il blueprint dell'intestazione viene aggiornato con successo.",
    nl: "Header blauwdruk is succesvol bijgewerkt."
  },
  LANGUAGE_CREATE_SUCCESS: {
    en: "Language created successfully",
    de: "Sprache erfolgreich erstellt",
    it: "Lingua creata con successo",
    nl: "Taal succesvol aangemaakt"
  },
  LANGUAGE_UPDATE_SUCCESS: {
    en: "Language updated successfully",
    de: "Sprache erfolgreich aktualisiert",
    it: "Lingua aggiornata con successo",
    nl: "Taal succesvol bijgewerkt"
  },
  LANGUAGE_REMOVE_SUCCESS: {
    en: "Language removed successfully",
    de: "Sprache erfolgreich entfernt",
    it: "Lingua rimossa con successo",
    nl: "Taal succesvol verwijderd"
  },
  PAGE_UPDATE_SUCCESS: {
    en: "Page is updated successfully.",
    de: "Seite wurde erfolgreich aktualisiert.",
    it: "La pagina viene aggiornata con successo.",
    nl: "Pagina is succesvol bijgewerkt."
  },
  PAGE_CREATE_SUCCESS: {
    en: "Page is created successfully. Bringing you to that page...",
    de: "Seite wurde erfolgreich erstellt. Bringt Sie zu dieser Seite...",
    it: "La pagina è stata creata con successo. Portandoti a quella pagina...",
    nl: "Pagina is succesvol aangemaakt. Ik breng je naar die pagina..."
  },
  PAGE_REMOVE_SUCCESS: {
    en: "Page is removed successfully.",
    de: "Seite wurde erfolgreich entfernt.",
    it: "La pagina viene rimossa con successo.",
    nl: "Pagina is succesvol verwijderd."
  },
  STYLESHEET_CREATE_SUCCESS: {
    en: "Stylesheet created successfully",
    de: "Stylesheet erfolgreich erstellt",
    it: "Foglio di stile creato con successo",
    nl: "Stylesheet succesvol gemaakt"
  },
  STYLESHEET_REMOVE_SUCCESS: {
    en: "Stylesheet removed successfully",
    de: "Stylesheet erfolgreich entfernt",
    it: "Foglio di stile rimosso con successo",
    nl: "Stylesheet succesvol verwijderd"
  },
  STYLESHEET_UPDATE_SUCCESS: {
    en: "Stylesheet updated successfully",
    de: "Stylesheet erfolgreich aktualisiert",
    it: "Foglio di stile aggiornato con successo",
    nl: "Stylesheet succesvol bijgewerkt"
  },
  DELETE_PAGE_CONFIRMATION: {
    en: "Are you sure to delete this page?",
    de: "Sind Sie sicher, dass Sie diese Seite löschen wollen?",
    it: "Sei sicuro di voler cancellare questa pagina?",
    nl: "Weet je zeker dat je deze pagina wilt verwijderen?"
  },
  IS_A_REQUIRED_FIELD: {
    en: "Is a required field",
    de: "Ist ein Pflichtfeld",
    it: "È un campo obbligatorio",
    nl: "Is een verplicht veld"
  },
  SUBMISSION_DATE: {
    en: "Submission Date",
    de: "Einreichungsdatum",
    it: "Data di presentazione",
    nl: "Datum indiening"
  },
  NO_SUBMISSION_SELECTED_FORM: {
    en: "No submission for selected form",
    de: "Keine Übermittlung für ausgewähltes Formular",
    it: "Nessun invio per il modulo selezionato",
    nl: "Geen indiening voor geselecteerd formulier"
  },
  VIEW: {
    en: "View",
    de: "Ansicht",
    it: "Vedi",
    nl: "Bekijk"
  },
  DOWNLOAD_ALL_FILES: {
    en: "Download all files",
    de: "Alle Dateien herunterladen",
    it: "Scaricare tutti i file",
    nl: "Alle bestanden downloaden"
  },
  ARCHIVE: {
    en: "Archive",
    de: "Archivieren",
    it: "Archivio",
    nl: "Archief"
  },
  BACK: {
    en: "Back",
    de: "Zurück",
    it: "Indietro",
    nl: "Terug"
  },
  TEXT_AREA: {
    en: "Text Area",
    de: "Textbereich",
    it: "Area di testo",
    nl: "Tekstgebied"
  },
  FIELD_IS_REQUIRED: {
    en: "Field is required",
    de: "Feld ist erforderlich",
    nl: "Veld is verplicht",
    it: "Il campo è richiesto"
  },
  HAS_DEPENDENT_FIELD: {
    en: "Has dependent field",
    de: "Hat abhängiges Feld",
    nl: "Heeft afhankelijk veld",
    it: "Ha campo dipendente"
  },
  DEPENDENT_FIELD_SETTINGS: {
    en: "Dependent Field Settings",
    de: "Abhängige Feldeinstellungen",
    nl: "Afhankelijke veldinstellingen",
    it: "Impostazioni del campo dipendente"
  },
  BLANK: {
    en: "",
    de: "",
    nl: "",
    it: ""
  },
  ERROR_MESSAGE: {
    en: "Error message ( show if user did not fill in anything for this field )",
    de: "Fehlermeldung ( wird angezeigt, wenn der Benutzer für dieses Feld nichts ausgefüllt hat )",
    it: "Messaggio di errore ( mostra se l'utente non ha compilato nulla per questo campo )",
    nl: "Foutmelding ( laat zien of de gebruiker niets heeft ingevuld voor dit veld)"
  },
  NO_OF_ROWS_IN_TEXT_AREA: {
    en: "Number of rows in text area",
    de: "Anzahl der Zeilen im Textbereich",
    it: "Numero di righe nell'area di testo",
    nl: "Aantal rijen in tekstgebied"
  },
  ARK_M_FILES: {
    en: "Files",
    de: "Globale Dateien",
    it: "File Ark-M",
    nl: "Ark-M Bestanden"
  },
  SHARED_FILES: {
    en: "Shared Files",
    de: "Freigegebene Dateien",
    it: "File condivisi",
    nl: "Gedeelde bestanden"
  },
  UPLOAD_FILES: {
    en: "Upload Files",
    de: "Dateien hochladen",
    it: "Caricare file",
    nl: "Upload Bestanden"
  },
  CURRENT_FOLDER: {
    en: "Current Folder",
    de: "Aktueller Folder",
    it: "Cartella corrente",
    nl: "Huidige map"
  },
  SELECT_FILE: {
    en: "Select File",
    de: "Datei auswählen",
    it: "Selezionare File",
    nl: "Selecteer Bestand"
  },
  UPDATE_FOLDER_FILE: {
    en: "Update folder / file",
    de: "Ordner / Datei aktualisieren",
    it: "Aggiorna cartella / file",
    nl: "Update map / bestand"
  },
  CURRENT_UPLOADED_FILES: {
    en: "Current uploaded files",
    de: "Aktuelle hochgeladene Dateien",
    it: "File caricati correnti",
    nl: "Huidige geüploade bestanden"
  },
  MEDIA_INSIDE_FOLDER: {
    en: "Medias inside selected folder: ",
    de: "Medien im ausgewählten Ordner: ",
    it: "Media all'interno della cartella selezionata: ",
    nl: "Media in geselecteerde map: "
  },
  ADD_FILE_OR_FOLDER: {
    en: "Add File/Folder",
    de: "Datei/Ordner hinzufügen",
    it: "Aggiungi file/cartella",
    nl: "Bestand/map toevoegen"
  },
  DELETE_FILE_CONFIRMATION: {
    en: "Are you sure to delete the selected file / folder ?",
    de: "Sind Sie sicher, dass Sie die ausgewählte Datei / den ausgewählten Ordner löschen wollen?",
    it: "Sei sicuro di voler eliminare il file/cartella selezionato?",
    nl: "Weet u zeker dat u het geselecteerde bestand / de geselecteerde map wilt verwijderen ?"
  },
  CREATE_FILE_OR_FOLDER: {
    en: "Create File/Folder",
    de: "Datei/Ordner erstellen",
    it: "Crea file/cartella",
    nl: "Bestand/map maken"
  },
  FILE: {
    en: "File",
    de: "Datei",
    it: "File",
    nl: "Bestand"
  },
  FOLDER: {
    en: "Folder",
    de: "Ordner",
    it: "Cartella",
    nl: "Map"
  },
  SELECT_FOLDER: {
    en: "Select Folder",
    de: "Ordner auswählen",
    it: "Seleziona cartella",
    nl: "Selecteer map"
  },
  SELECTED_FILE_URL: {
    en: "Selected file URL",
    de: "Ausgewählte Datei-URL",
    it: "URL del file selezionato",
    nl: "Geselecteerd bestand URL"
  },
  SEARCH_WITH_FILE_SHORTNAME: {
    en: "Search with file/folder shortname",
    de: "Suche mit Datei-/Ordnerkurzname",
    it: "Ricerca con nome breve di file/cartella",
    nl: "Zoeken met bestand/map korte naam"
  },
  SHOW_QUICK_INFO: {
    en: "Show quick info",
    de: "Kurzinfo anzeigen",
    it: "Mostra info rapide",
    nl: "Toon snelle info"
  },
  PREVIEW_TEXT: {
    en: "Preview Text",
    de: "Vorschau Text",
    it: "Anteprima testo",
    nl: "Voorbeeld Tekst"
  },
  PREVIEW_TEXT_PLACEHOLDER: {
    en: "Type here your preview text",
    de: "Geben Sie hier Ihren Vorschautext ein",
    it: "Scrivi qui il tuo testo di anteprima",
    nl: "Typ hier uw voorbeeldtekst"
  },
  ENLARGE_IMAGE_ON_HOVER: {
    en: "Enlarge image on hover",
    de: "Bild bei Hover vergrößern",
    it: "Ingrandisci l'immagine al passaggio del mouse",
    nl: "Vergroot beeld op hover"
  },
  ENLARGE_WIDTH: {
    en: "Enlarge Width",
    de: "Breite vergrößern",
    it: "Ingrandisci la larghezza",
    nl: "Vergroot Breedte"
  },
  ENLARGE_HEIGHT: {
    en: "Enlarge Height",
    de: "Höhe vergrößern",
    it: "Ingrandisci l'altezza",
    nl: "Vergroten Hoogte"
  },
  ADD_NESTED_SELECTION: {
    en: "Add nested selection",
    de: "Verschachtelte Auswahlfelder hinzufügen",
    it: "Aggiungere la selezione annidata",
    nl: "Geneste selectie toevoegen"
  },
  NESTED_LABEL: {
    en: "Nested label",
    de: "Beschriftung für verschachteltes Feld",
    it: "Etichetta annidata",
    nl: "Geneste label"
  },
  NESTED_FIELD_NAME: {
    en: "Nested field name",
    de: "Feldname",
    it: "Nome del campo annidato",
    nl: "Naam van het geneste veld"
  },
  CENTER_ASSIGNED: {
    en: "This consultant is current assigned to the following centers",
    de: "Dieser Berater ist derzeit den folgenden Center zugeordnet",
    it: "Questo consulente è attualmente assegnato ai seguenti centri",
    nl: "Deze consultant is momenteel werkzaam in de volgende centra"
  },
  DOWNLOAD_PDF: {
    en: "Download PDF",
    de: "PDF herunterladen",
    it: "Scaricare PDF",
    nl: "Download PDF"
  },
  REARRANGE_PAGE: {
    en: "Rearrange page",
    de: "Seite neu anordnen",
    it: "Riorganizzare la pagina",
    nl: "Pagina herschikken"
  },
  STOP_PAGE_REARRANGEMENT: {
    en: "Stop page rearrangement",
    de: "Seitenumbruch stoppen",
    it: "Fermare la riorganizzazione delle pagine",
    nl: "Stop het herschikken van pagina's"
  },
  PLATFORM_SETTINGS: {
    en: "Platform Settings",
    de: "Plattform-Einstellungen",
    it: "Impostazioni della piattaforma",
    nl: "Platform Instellingen"
  },
  EMAIL_IMPRINT_TEXT: {
    en: "Email imprint text",
    de: "E-Mail-Impressum-Text",
    it: "Testo da stampare via e-mail",
    nl: "E-mail opdruk tekst"
  },
  PLATFORM_SETTING_IS_SUCCESSFULLY_UPDATED: {
    en: "Platform setting is successfully updated",
    de: "Die Plattformeinstellung wurde erfolgreich aktualisiert",
    it: "L'impostazione della piattaforma è stata aggiornata con successo",
    nl: "Platform instelling is succesvol bijgewerkt"
  },
  IS_THIS_NEW: {
    en: "Is this new campaign?",
    de: "Neue Kampagne",
    it: "È una nuova campagna?",
    nl: "Is dit een nieuwe campagne?"
  },
  HAS_CONSULTANT: {
    en: "Assigned Consultant",
    de: "Beauftragter Berater",
    it: "Consulente assegnato",
    nl: "Toegewezen adviseur"
  },
  ENTER_BOOKED_DATE: {
    en: "Please enter the date",
    de: "Bitte geben Sie das Datum ein",
    it: "Inserisci la data",
    nl: "Voer de datum in"
  },
  ENLARGE_IMAGE_SIZE: {
    en: "Enlarge Image Size",
    de: "Bild vergrößern",
    it: "Ingrandisci l'immagine",
    nl: "Vergroot beeldformaat"
  },
  MESSAGES: {
    en: "Messages",
    de: "Nachrichten",
    it: "Messaggi",
    nl: "Berichten"
  },
  TYPE_OF_PAGE: {
    en: "Type of page",
    de: "Art der Seite",
    it: "Tipo di pagina",
    nl: "Soort pagina"
  },
  COVER_FIRST_PAGE: {
    en: "Cover (first page)",
    de: "Cover (erste Seite)",
    it: "Copertina (prima pagina)",
    nl: "Omslag (eerste pagina)"
  },
  COVER: {
    en: "Cover",
    de: "Cover",
    it: "Copertina",
    nl: "Omslag"
  },
  TABLE_OF_CONTENT: {
    en: "Table of content",
    de: "Inhaltsübersicht",
    it: "Tabella dei contenuti",
    nl: "Inhoudsopgave"
  },
  SUMMARY: {
    en: "Summary",
    de: "Zusammenfassung",
    it: "Riassunto",
    nl: "Samenvatting"
  },
  IS_PREFILLED: {
    en: "Is pre-filled",
    de: "ist vorausgefüllt",
    it: "È precompilato",
    nl: "Is voorgevuld"
  },
  PREFILLED_WITH: {
    en: "Pre-filled with",
    de: "Vorausgefüllt mit",
    it: "Pre-riempita con",
    nl: "Voorgevuld met"
  },
  FORM_PAGE: {
    en: "Form page",
    de: "Formular Seite",
    it: "Pagina del modulo",
    nl: "Formulier pagina"
  },
  CHAT_FOR: {
    en: "Chat for",
    de: "Chat zum Bestellschein",
    nl: "Chat voor",
    it: "Chat per"
  },
  UPLOAD_DATA: {
    en: "Upload Data",
    de: "Upload Datei",
    nl: "Upload Datei",
    it: "Upload Datei"
  },
  PLEASE_INPUT_MESSAGE_HERE: {
    en: "Please input your message here",
    de: "Hier Text eingeben",
    nl: "Hier Text eingeben",
    it: "Hier Text eingeben"
  },
  PREVIOUS: {
    en: "Previous",
    de: "Zurück",
    it: "Precedente",
    nl: "Vorige"
  },
  NEXT_PAGE: {
    en: "Next",
    de: "Vorwärts",
    it: "Prossimo",
    nl: "Volgende"
  },
  ROWS: {
    en: "Rows",
    de: "Zeilen",
    it: "Righe",
    nl: "Rijen"
  },
  MY_CAMPAIGNS: {
    en: "My Campaigns",
    de: "Meine Kampagnen",
    it: "Le mie campagne",
    nl: "Mijn campagnes"
  },
  OLS_CAMPAIGNS: {
    en: "OLS Campaigns",
    de: "OLS Kampagnen",
    it: "Campagne OLS",
    nl: "OLS Campagnes"
  },
  SEND_EMAIL_TO_CENTER_ADMIN: {
    en: "Send email to center admin",
    de: "E-Mail an den Center-Administrator senden",
    it: "Invia un'e-mail all'amministratore del centro",
    nl: "Stuur e-mail naar centrum admin"
  },
  SEND_EMAIL_TO_CENTER_ADMIN_SUCCESS: {
    en: "Successfully send email to center admin.",
    de: "E-Mail erfolgreich an den Center-Administrator gesendet.",
    it: "Invia con successo un'e-mail all'amministratore del centro.",
    nl: "Succesvol verzonden e-mail naar centrum admin."
  },
  NOTE: {
    en: "Note: The campaign duration is always 8 weeks",
    de: "Hinweis: Die Kampagnendauer beträgt immer 8 Wochen",
    it: "Nota: la durata della campagna è sempre di 8 settimane",
    nl: "Opmerking: De duur van de campagne is altijd 8 weken"
  },
  BILLINGS: {
    en: "Billings",
    de: "Abrechnung",
    it: "Billings",
    nl: "Billings"
  },
  VOUCHER_REPORT: {
    en: "Voucher report",
    de: "Gutschein Auswertung",
    it: "Rapporto dei buoni",
    nl: "Verslag van de tegoedbon"
  },
  GROSS_AMOUNT: {
    en: "Gross amount",
    de: "Bruttobetrag",
    it: "Importo lordo",
    nl: "Bruto bedrag"
  },
  TAXATION_PERCENT: {
    en: "Taxation (%)",
    de: "Besteuerung (%)",
    it: "Tassazione (%)",
    nl: "Belastingen (%)"
  },
  NET_SUM: {
    en: "Net sum",
    de: "Netto Summe",
    it: "Somma netta",
    nl: "Netto bedrag"
  },
  ACISO_PERCENT: {
    en: "Aciso (3%)",
    de: "Aciso (3%)",
    it: "Aciso (3%)",
    nl: "Aciso (3%)"
  },
  AMOUNT_OF_VOUCHER_USED: {
    en: "Amount of voucher used",
    de: "Betrag des verwendeten Gutscheins",
    it: "Importo del buono utilizzato",
    nl: "Bedrag van de gebruikte voucher"
  },
  EVALUATION_PERIOD: {
    en: "Evaluation period",
    de: "Auswertezeitraum",
    it: "Periodo di valutazione",
    nl: "Evaluatieperiode"
  },
  EXPORT_EXCEL: {
    en: "Export Excel",
    de: "Excel exportieren",
    it: "Esportare Excel",
    nl: "Excel exporteren"
  },
  ADD_SUBCHECKBOX: {
    en: "Add Sub Checkbox",
    de: "Checkbox hinzufügen",
    it: "Aggiungi la casella di controllo Sub",
    nl: "Subvakje toevoegen"
  },
  SUBCHECKBOX_FIELD: {
    en: "Sub Checkbox Field",
    de: "Unter-Checkbox-Feld",
    it: "Campo Sub Checkbox",
    nl: "Sub Selectievakje Veld"
  },
  TOTAL_DISCOUNT: {
    en: "Total discount",
    de: "Rabatt insgesamt",
    it: "Sconto totale",
    nl: "Totale korting"
  },
  SECTION_CATEGORIES: {
    en: "Section Categories",
    de: "Section Kategorien",
    it: "Categorie delle sezioni",
    nl: "Sectie Categorieën"
  },
  CREATE_CATEGORY: {
    en: "Create Category",
    de: "Kategorie erstellen",
    it: "Crea categoria",
    nl: "Creëer categorie"
  },
  ASSIGN_CATEGORIES: {
    en: "Assign Categories",
    de: "Kategorien zuweisen",
    it: "Assegnare categorie",
    nl: "Categorieën toewijzen"
  },
  SECTION_CATEGORY_ASSIGN_SUCCESS: {
    en: "Section Category assigned successfully",
    de: "Abschnitt Kategorie erfolgreich zugewiesen",
    it: "Categoria di sezione assegnata con successo",
    nl: "Sectie Categorie succesvol toegewezen"
  },
  SECTION_CATEGORY_REMOVE_SUCCESS: {
    en: "Section Category removed successfully",
    de: "Abschnitt Kategorie erfolgreich entfernt",
    it: "Sezione Categoria rimossa con successo",
    nl: "Sectie Categorie succesvol verwijderd"
  },
  CATEGORY_NAME_VALIDATION: {
    en: "Name of the category cannot be empty.",
    de: "Der Name der Kategorie darf nicht leer sein.",
    it: "Il nome della categoria non può essere vuoto.",
    nl: "De naam van de categorie mag niet leeg zijn."
  },
  SEARCH_BY_SECTION_NAME: {
    en: "Search by section name",
    de: "Suche nach Abschnittsnamen",
    it: "Cerca per nome della sezione",
    nl: "Zoeken op rubrieksnaam"
  },
  HAS_EXPIRY_DATE: {
    en: "Has Expiry Date",
    de: "Enddatum festlegen ",
    it: "Ha una data di scadenza",
    nl: "Heeft Vervaldatum"
  },
  NO_EXPIRY_DATE: {
    en: "No Expiry Date",
    de: "Kein Enddatum",
    it: "Nessuna data di scadenza",
    nl: "Geen Vervaldatum"
  },
  ADDITIONAL_TEXT: {
    en: "* Format 16:11 - Min. resolution 400px275px",
    de: "* Format 16:11 - Min. Auflösung 400px275px",
    it: "* Formato 16:11 - Risoluzione minima 400px275px",
    nl: "* Formaat 16:11 - Min. resolutie 400px275px"
  },
  ERROR_HAPPENED: {
    en: "Error happened, please notify your friendly admin or wait for awhile before trying again.",
    de: "Es ist ein Fehler aufgetreten, bitte benachrichtigen Sie Ihren freundlichen Administrator oder warten Sie eine Weile, bevor Sie es erneut versuchen.",
    it: "Si è verificato un errore, per favore avvisate il vostro amichevole amministratore o aspettate un po' prima di riprovare.",
    nl: "Fout gebeurd, gelieve uw vriendelijke admin te verwittigen of een tijdje te wachten alvorens opnieuw te proberen."
  },
  APP: {
    en: "App",
    de: "App",
    it: "App",
    nl: "App"
  },
  STANDARD: {
    en: "Standard",
    de: "Standard",
    it: "Standard",
    nl: "Standaard"
  },
  TENNIS_SCHOOL_ID: {
    en: "Tennis school ID",
    de: "Tennisschule ID",
    it: "ID della scuola di tennis",
    nl: "ID tennisschool"
  },
  ONETIMER_BILLING: {
    en: "Trial/Onetimer Billing",
    de: "Trial / Onetimer Abrechnung",
    it: "Fatturazione di prove/netimer",
    nl: "Trial/Onetimer facturering"
  },
  INVOICE_NUMBER: {
    en: "Invoice Number",
    de: "Rechnungsnummer",
    it: "Numero di fattura",
    nl: "Factuurnummer"
  },
  ADDITIONAL_INFO: {
    en: "Additional Billing Informations",
    de: "Zusatzinformation Abrechnung",
    it: "Informazioni aggiuntive sulla fatturazione",
    nl: "Extra informatie over facturering"
  },
  IS_ACISO_ADVERTISING_ACCOUNT: {
    en: "Is ACISO Advertising Account",
    de: "Im ACISO Werbekonto aufsetzen",
    it: "È l'account pubblicitario Aciso",
    nl: "Is Aciso Advertising Account"
  },
  IS_PP_ADVERTISING_ACCOUNT: {
    en: "Is Proof Point Advertising Account",
    de: "Ist Proof Point Werbekonto",
    it: "È l'account pubblicitario Proof Point",
    nl: "Is Proof Point Advertising Account"
  },
  MEMBER_AREA: {
    en: "Member Area",
    de: "Mitgliederbereich",
    it: "Area membri",
    nl: "Leden"
  },
  ROLES: {
    en: "Roles",
    de: "Rollen",
    it: "Ruoli",
    nl: "Rollen"
  },
  MEMBER_STATUS: {
    en: "Member Status",
    de: "Mitgliedsstatus",
    it: "Stato del membro",
    nl: "Lidmaatschapsstatus"
  },
  EVENT: {
    en: "Event",
    de: "Veranstaltung",
    it: "Evento",
    nl: "Evenement"
  },
  EVENTS: {
    en: "Events",
    de: "Veranstaltungen",
    it: "Eventi",
    nl: "Evenementen"
  },
  SYSTEM: {
    en: "System",
    de: "System",
    it: "Sistema",
    nl: "Systeem"
  },
  TYPES_SETTINGS: {
    en: "Types Settings",
    de: "Typen Einstellungen",
    it: "Tipi di impostazioni",
    nl: "Types Instellingen"
  },
  TEMPLATES: {
    en: "Templates",
    de: "Vorlagen",
    it: "Modelli",
    nl: "Sjablonen"
  },
  PROGRAM: {
    en: "Program",
    de: "Programm",
    it: "Programma",
    nl: "Programma"
  },
  MODULES_SETTINGS: {
    en: "Modules Settings",
    de: "Module Einstellungen",
    it: "Impostazioni dei moduli",
    nl: "Modules Instellingen"
  },
  CONTENT_SETTINGS: {
    en: "Content Settings",
    de: "Inhaltliche Einstellungen",
    it: "Impostazioni del contenuto",
    nl: "Inhoud Instellingen"
  },
  CONTENT_TAGS: {
    en: "Content Tags",
    de: "Inhalt Tags",
    it: "Tag del contenuto",
    nl: "Inhoud Tags"
  },
  ON_DEMAND_CATEGORIES: {
    en: "On Demand Categories",
    de: "On-Demand-Kategorien",
    it: "Categorie su richiesta",
    nl: "Categorieën op aanvraag"
  },
  ROLE_IS_SUCCESSFULLY_CREATED: {
    en: "Role is successfully created",
    de: "Rolle wurde erfolgreich erstellt",
    it: "Il ruolo è stato creato con successo",
    nl: "Rol is succesvol aangemaakt"
  },
  ROLE_IS_SUCCESSFULLY_UPDATED: {
    en: "Role is successfully updated",
    de: "Rolle wurde erfolgreich aktualisiert",
    it: "Il ruolo viene aggiornato con successo",
    nl: "Rol is succesvol bijgewerkt"
  },
  ROLE_IS_SUCCESSFULLY_REMOVED: {
    en: "Role is successfully removed",
    de: "Die Rolle wurde erfolgreich entfernt",
    it: "Il ruolo è stato rimosso con successo",
    nl: "Rol is succesvol verwijderd"
  },
  DELETE_CONFIRMATION_ROLE: {
    en: "Are you sure to remove the selected role?",
    de: "Sind Sie sicher, dass Sie die ausgewählte Rolle entfernen wollen?",
    it: "Sei sicuro di voler rimuovere il ruolo selezionato?",
    nl: "Weet u zeker dat u de geselecteerde rol wilt verwijderen?"
  },
  MEMBER_STATUS_IS_SUCCESSFULLY_CREATED: {
    en: "Status is successfully created",
    de: "Status wird erfolgreich erstellt",
    it: "Lo stato viene creato con successo",
    nl: "Status is succesvol aangemaakt"
  },
  MEMBER_STATUS_IS_SUCCESSFULLY_UPDATED: {
    en: "Status is successfully updated",
    de: "Status wird erfolgreich aktualisiert",
    it: "Lo stato viene aggiornato con successo",
    nl: "Status is succesvol bijgewerkt"
  },
  MEMBER_STATUS_IS_SUCCESSFULLY_REMOVED: {
    en: "Status is successfully removed",
    de: "Status wird erfolgreich entfernt",
    it: "Lo stato viene rimosso con successo",
    nl: "Status is succesvol verwijderd"
  },
  DELETE_CONFIRMATION_STATUS: {
    en: "Are you sure to remove the selected status?",
    de: "Sind Sie sicher, dass Sie den ausgewählten Status entfernen wollen?",
    it: "Sei sicuro di rimuovere lo stato selezionato?",
    nl: "Weet u zeker dat u de geselecteerde status wilt verwijderen?"
  },
  MARKETING_FUNNELS: {
    en: "Marketing Funnel",
    de: "Marketing Funnel",
    nl: "Marketing trechters",
    it: "Imbuti di marketing"
  },
  MAILING_TEMPLATES: {
    en: "Mailing Templates",
    de: "Mailing Templates",
    nl: "Mailing sjablonen",
    it: "Modelli di mailing"
  },
  SYSTEM_FUNNELS: {
    en: "System Funnels",
    de: "Funnel",
    nl: "Systeem trechters",
    it: "Imbuti di sistema"
  },
  SYSTEM_MAILINGS: {
    en: "System Mailings",
    de: "Mailings",
    it: "Postazioni del sistema",
    nl: "Systeemmailings"
  },
  FUNNEL_SCENARIOS: {
    en: "Funnel Scenarios",
    de: "Funnel Templates",
    it: "Scenari di imbuto",
    nl: "Trechter Scenario's"
  },
  PROGRAM_MODULE_IS_SUCCESSFULLY_CREATED: {
    en: "Program module is successfully created",
    de: "Programmmodul wurde erfolgreich erstellt",
    it: "Il modulo del programma è stato creato con successo",
    nl: "Programma module is succesvol gemaakt"
  },
  PROGRAM_MODULE_IS_SUCCESSFULLY_UPDATED: {
    en: "Program module is successfully updated",
    de: "Programmmodul wird erfolgreich aktualisiert",
    it: "Il modulo del programma viene aggiornato con successo",
    nl: "Programmamodule is met succes bijgewerkt"
  },
  PROGRAM_MODULE_IS_SUCCESSFULLY_REMOVED: {
    en: "Program module is successfully removed",
    de: "Programmmodul wird erfolgreich entfernt",
    it: "Il modulo del programma è stato rimosso con successo",
    nl: "Programma module is succesvol verwijderd"
  },
  DELETE_CONFIRMATION_PROGRAM_MODULE: {
    en: "Are you sure to remove the selected program module?",
    de: "Sind Sie sicher, dass Sie das ausgewählte Programmmodul entfernen wollen?",
    it: "Sei sicuro di rimuovere il modulo del programma selezionato?",
    nl: "Weet u zeker dat u de geselecteerde programmamodule wilt verwijderen?"
  },
  ON_DEMAND_CATEGORY_IS_SUCCESSFULLY_CREATED: {
    en: "On demand category is successfully created",
    de: "Die Kategorie \"Auf Anfrage\" wurde erfolgreich erstellt.",
    it: "La categoria su richiesta è stata creata con successo",
    nl: "On demand categorie is succesvol aangemaakt"
  },
  ON_DEMAND_CATEGORY_IS_SUCCESSFULLY_UPDATED: {
    en: "On demand category is successfully updated",
    de: "Die Kategorie \"Auf Anfrage\" wurde erfolgreich aktualisiert.",
    it: "La categoria su richiesta è stata aggiornata con successo",
    nl: "On demand categorie is succesvol bijgewerkt"
  },
  ON_DEMAND_CATEGORY_IS_SUCCESSFULLY_REMOVED: {
    en: "On demand category is successfully removed",
    de: "Die Kategorie \"Auf Anfrage\" wurde erfolgreich entfernt.",
    it: "La categoria su richiesta è stata rimossa con successo",
    nl: "On demand categorie is succesvol verwijderd"
  },
  DELETE_CONFIRMATION_ON_DEMAND_CATEGORY: {
    en: "Are you sure to remove the selected on demand category?",
    de: "Sind Sie sicher, dass Sie die ausgewählte On-Demand-Kategorie entfernen wollen?",
    it: "Sei sicuro di rimuovere la categoria selezionata su richiesta?",
    nl: "Weet u zeker dat u de geselecteerde on demand categorie wilt verwijderen?"
  },
  TAG_IS_SUCCESSFULLY_CREATED: {
    en: "Tag is successfully created",
    de: "Tag wurde erfolgreich erstellt",
    it: "Il tag è stato creato con successo",
    nl: "Tag is succesvol aangemaakt"
  },
  TAG_IS_SUCCESSFULLY_UPDATED: {
    en: "Tag is successfully updated",
    de: "Tag is succesvol bijgewerkt",
    it: "Il tag viene aggiornato con successo",
    nl: "Tag is succesvol bijgewerkt"
  },
  TAG_IS_SUCCESSFULLY_REMOVED: {
    en: "Tag is successfully removed",
    de: "Tag wurde erfolgreich entfernt",
    it: "Il tag è stato rimosso con successo",
    nl: "Tag is succesvol verwijderd"
  },
  DELETE_CONFIRMATION_TAG: {
    en: "Are you sure to remove the selected tag?",
    de: "Sind Sie sicher, dass Sie das ausgewählte Tag entfernen wollen?",
    it: "Sei sicuro di rimuovere il tag selezionato?",
    nl: "Weet u zeker dat u de geselecteerde tag wilt verwijderen?"
  },
  EVENT_TYPES: {
    en: "Event types",
    de: "Veranstaltungstypen",
    it: "Tipi di eventi",
    nl: "Soorten gebeurtenissen"
  },
  EVENT_TYPE_IS_SUCCESSFULLY_CREATED: {
    en: "Event type is successfully created",
    de: "Ereignistyp wurde erfolgreich erstellt",
    it: "Il tipo di evento è stato creato con successo",
    nl: "Gebeurtenistype is succesvol aangemaakt"
  },
  EVENT_TYPE_IS_SUCCESSFULLY_UPDATED: {
    en: "Event type is successfully updated",
    de: "Ereignistyp wird erfolgreich aktualisiert",
    it: "Il tipo di evento è stato aggiornato con successo",
    nl: "Gebeurtenistype is met succes bijgewerkt"
  },
  EVENT_TYPE_IS_SUCCESSFULLY_REMOVED: {
    en: "Event type is successfully removed",
    de: "Ereignistyp wird erfolgreich entfernt",
    it: "Il tipo di evento è stato rimosso con successo",
    nl: "Gebeurtenistype is succesvol verwijderd"
  },
  DELETE_CONFIRMATION_EVENT_TYPE: {
    en: "Are you sure to remove the selected event type?",
    de: "Sind Sie sicher, dass Sie den ausgewählten Ereignistyp entfernen wollen?",
    it: "Sei sicuro di rimuovere il tipo di evento selezionato?",
    nl: "Weet u zeker dat u het geselecteerde gebeurtenistype wilt verwijderen?"
  },
  EVENT_TEMPLATE_IS_SUCCESSFULLY_CREATED: {
    en: "Event template is successfully created",
    de: "Ereignisvorlage wurde erfolgreich erstellt",
    it: "Il modello di evento è stato creato con successo",
    nl: "Gebeurtenissjabloon is succesvol aangemaakt"
  },
  EVENT_TEMPLATE_IS_SUCCESSFULLY_UPDATED: {
    en: "Event template is successfully updated",
    de: "Ereignisvorlage wurde erfolgreich aktualisiert",
    it: "Il modello dell'evento è stato aggiornato con successo",
    nl: "Gebeurtenissjabloon is met succes bijgewerkt"
  },
  EVENT_TEMPLATE_IS_SUCCESSFULLY_REMOVED: {
    en: "Event template is successfully removed",
    de: "Ereignisvorlage wurde erfolgreich entfernt",
    it: "Il modello dell'evento è stato rimosso con successo",
    nl: "Gebeurtenissjabloon is succesvol verwijderd"
  },
  DELETE_CONFIRMATION_EVENT_TEMPLATE: {
    en: "Are you sure to remove the selected event template?",
    de: "Sind Sie sicher, dass Sie die ausgewählte Ereignisvorlage entfernen möchten?",
    it: "Sei sicuro di rimuovere il modello di evento selezionato?",
    nl: "Weet u zeker dat u de geselecteerde gebeurtenissjabloon wilt verwijderen?"
  },
  PROGRAM_TEMPLATE_IS_SUCCESSFULLY_CREATED: {
    en: "Program template is successfully created",
    de: "Programmvorlage wurde erfolgreich erstellt",
    it: "Il modello del programma è stato creato con successo",
    nl: "Programmasjabloon is succesvol aangemaakt"
  },
  PROGRAM_TEMPLATE_IS_SUCCESSFULLY_UPDATED: {
    en: "Program template is successfully updated",
    de: "Programmvorlage wird erfolgreich aktualisiert",
    it: "Il modello del programma viene aggiornato con successo",
    nl: "Programmasjabloon is met succes bijgewerkt"
  },
  PROGRAM_TEMPLATE_IS_SUCCESSFULLY_REMOVED: {
    en: "Program template is successfully removed",
    de: "Programmvorlage wird erfolgreich entfernt",
    it: "Il modello del programma è stato rimosso con successo",
    nl: "Programmasjabloon is succesvol verwijderd"
  },
  DELETE_CONFIRMATION_PROGRAM_TEMPLATE: {
    en: "Are you sure to remove the selected Program template?",
    de: "Sind Sie sicher, dass Sie die ausgewählte Programmvorlage entfernen möchten?",
    it: "Sei sicuro di rimuovere il modello di programma selezionato?",
    nl: "Weet u zeker dat u het geselecteerde programmasjabloon wilt verwijderen?"
  },
  ON_DEMAND_CATEGORY: {
    en: "On demand category",
    de: "Kategorie \"Auf Anfrage",
    it: "Categoria su richiesta",
    nl: "Categorie op aanvraag"
  },
  EVENT_TEMPLATES: {
    en: "Event Templates",
    de: "Ereignis-Vorlagen",
    it: "Modelli di eventi",
    nl: "Evenement sjablonen"
  },
  PROGRAM_MODULES: {
    en: "Program Modules",
    de: "Programm-Module",
    it: "Moduli del programma",
    nl: "Programma modules"
  },
  PROGRAM_TEMPLATES: {
    en: "Program Templates",
    de: "Programmvorlagen",
    it: "Modelli di programma",
    nl: "Programma sjablonen"
  },
  EVENT_PLACEMENT: {
    en: "Event placement",
    de: "Platzierung der Veranstaltung",
    it: "Posizionamento dell'evento",
    nl: "Plaatsing evenement"
  },
  PUBLIC: {
    en: "Public",
    de: "Öffentlich",
    it: "Pubblico",
    nl: "Publiek"
  },
  EVENT_FOR: {
    en: "Event for",
    de: "Veranstaltung für",
    it: "Evento per",
    nl: "Evenement voor"
  },
  MEMBERS: {
    en: "Members",
    de: "Mitglieder",
    it: "Membri",
    nl: "Leden"
  },
  GUEST: {
    en: "Guest",
    de: "Gast",
    it: "Ospite",
    nl: "Gast"
  },
  EVENT_PROPERTIES: {
    en: "Event properties",
    de: "Ereignis-Eigenschaften",
    it: "Proprietà dell'evento",
    nl: "Gebeurteniseigenschappen"
  },
  SHOW_IN_COURSEPLAN: {
    en: "Show in courseplan",
    de: "Im Kursplan anzeigen",
    it: "Mostra in courseplan",
    nl: "Toon in lessenplan"
  },
  HAS_IN_APP_OFFER: {
    en: "Has in App offer",
    de: "Hat in App Angebot",
    it: "Ha in offerta App",
    nl: "Heeft in App aanbod"
  },
  MEMBER_PRICE: {
    en: "Member price",
    de: "Mitgliedspreis",
    it: "Prezzo del membro",
    nl: "Prijs voor leden"
  },
  FREE_FOR_MEMBERS: {
    en: "Free for members",
    de: "Kostenlos für Mitglieder",
    it: "Gratuito per i membri",
    nl: "Gratis voor leden"
  },
  FREE_FOR_PROGRAM_PARTICIPANTS: {
    en: "Free for program participants",
    de: "Kostenlos für Programmteilnehmer",
    it: "Gratuito per i partecipanti al programma",
    nl: "Gratis voor deelnemers aan het programma"
  },
  IS_REPEAT: {
    en: "Is repeat",
    de: "Wiederholend",
    it: "Si ripete",
    nl: "Is herhaalbaar"
  },
  EXCLUDED_DATES: {
    en: "Excluded dates",
    de: "Ausgeschlossene Daten",
    it: "Date escluse",
    nl: "Uitgesloten data"
  },
  SUNDAY: {
    en: "Sunday",
    de: "Sonntag",
    nl: "Zondag",
    it: "Domenica"
  },
  MONDAY: {
    en: "Monday",
    de: "Montag",
    nl: "Maandag",
    it: "Lunedì"
  },
  TUESDAY: {
    en: "Tuesday",
    de: "Dienstag",
    nl: "Dinsdag",
    it: "Martedì"
  },
  WEDNESDAY: {
    en: "Wednesday",
    de: "Mittwoch",
    nl: "Woensdag",
    it: "Mercoledì"
  },
  THURSDAY: {
    en: "Thursday",
    de: "Donnerstag",
    nl: "Donderdag",
    it: "Giovedì"
  },
  FRIDAY: {
    en: "Friday",
    de: "Freitag",
    nl: "Vrijdag",
    it: "Venerdì"
  },
  SATURDAY: {
    en: "Saturday",
    de: "Samstag",
    nl: "Zaterdag",
    it: "Sabato"
  },
  LANDING_PAGE_URL: {
    en: "Landing page URL",
    de: "Landing Page URL",
    nl: "URL di pagina di destinazione",
    it: "URL van landingspagina"
  },
  GDPR_CENTER: {
    en: "GDPR Center",
    de: "DSGVO Center",
    it: "Centro GDPR",
    nl: "GDPR Centrum"
  },
  AV_CONTRACT: {
    en: "AV Contract",
    de: "AV Vertrag",
    it: "Contratto AV",
    nl: "AV Contract"
  },
  ARTICLE_14: {
    en: "Article 14",
    de: "Artikel 14",
    it: "Articolo 14",
    nl: "Artikel 14"
  },
  CREATE_ARTICLE: {
    en: "Create Article",
    de: "Artikel erstellen",
    it: "Crea articolo",
    nl: "Artikel maken"
  },
  VIEW_ARTICLE: {
    en: "View Article",
    de: "Artikel ansehen",
    it: "Vedi articolo",
    nl: "Bekijk artikel"
  },
  EDIT_ARTICLE: {
    en: "Edit Article",
    de: "Artikel bearbeiten",
    it: "Modifica articolo",
    nl: "Artikel bewerken"
  },
  DELETE_ARTICLE: {
    en: "Delete Article",
    de: "Artikel entfernen",
    it: "Cancella articolo",
    nl: "Verwijder artikel"
  },
  TOPIC: {
    en: "Topic",
    de: "Thema",
    it: "Tema",
    nl: "Thema"
  },
  ARTICLE_CREATE_SUCCESS: {
    en: "Article 14 is created successfully.",
    de: "Artikel 14 wird erfolgreich erstellt.",
    it: "L'articolo 14 è creato con successo.",
    nl: "Artikel 14 is met succes gecreëerd."
  },
  ARTICLE_UPDATE_SUCCESS: {
    en: "Article 14 is updated successfully.",
    de: "Artikel 14 wurde erfolgreich aktualisiert.",
    it: "L'articolo 14 è aggiornato con successo.",
    nl: "Artikel 14 is met succes bijgewerkt."
  },
  ARTICLE_REMOVE_SUCCESS: {
    en: "Article 14 is removed successfully.",
    de: "Artikel 14 wird erfolgreich entfernt.",
    it: "L'articolo 14 viene rimosso con successo.",
    nl: "Artikel 14 is met succes geschrapt."
  },
  DELETE_CONFIRMATION_ARTICLE: {
    en: "Are you sure to delete this article?",
    de: "Sind Sie sicher, dass Sie diesen Artikel löschen wollen?",
    it: "Sei sicuro di voler cancellare questo articolo?",
    nl: "Weet je zeker dat je dit artikel wilt verwijderen?"
  },
  CREATE_CONTRACT: {
    en: "Create Contract",
    de: "Vertrag erstellen",
    it: "Creare un contratto",
    nl: "Contract maken"
  },
  AV_CONTRACT_TEMPLATE: {
    en: "AV Contract Template",
    de: "AV Vertragsvorlage",
    it: "Modello di contratto AV",
    nl: "AV contract sjabloon"
  },
  CREATE_CONTRACT_TEMPLATE: {
    en: "Create Contract Template",
    de: "Vertragsvorlage erstellen",
    it: "Creare modello di contratto",
    nl: "Contractsjabloon maken"
  },
  VIEW_CONTRACT_TEMPLATE: {
    en: "View Contract Template",
    de: "Vertragsvorlage ansehen",
    it: "Vedi il modello di contratto",
    nl: "Bekijk Contractsjabloon"
  },
  EDIT_CONTRACT_TEMPLATE: {
    en: "Edit Contract Template",
    de: "Vertragsvorlage bearbeiten",
    it: "Modifica modello di contratto",
    nl: "Bewerk Contract Sjabloon"
  },
  VERSION: {
    en: "Version",
    de: "Version",
    it: "Versione",
    nl: "Versie"
  },
  DUPLICATE: {
    en: "Duplicate",
    de: "Duplizieren",
    it: "Duplicato",
    nl: "Duplicaat"
  },
  AUTH_PERSON: {
    en: "Persons authorized to issue instructions to the client",
    de: "Weisungsberechtigte Personen des Auftraggebers",
    it: "Persone autorizzate a impartire istruzioni al cliente",
    nl: "Personen die bevoegd zijn om instructies te geven aan de cliënt"
  },
  COMPANY_NAME_ADDRESS: {
    en: "Company name and address",
    de: "Firma und Adresse",
    it: "Nome e indirizzo dell'azienda",
    nl: "Naam en adres van het bedrijf"
  },
  AGREEMENT_NUMBER: {
    en: "Consulting agreement/service agreement No.",
    de: "Beratungsvertrag/Leistungsvertrag Nr.",
    it: "Accordo di consulenza/contratto di servizio No.",
    nl: "Raadplegingsovereenkomst/dienstverleningsovereenkomst nr."
  },
  AV_CONTRACT_INFO: {
    en: "AV Contract Information",
    de: "AV-Vertrags-Informationen",
    it: "Informazioni sul contratto AV",
    nl: "AV Contract Informatie"
  },
  EDIT_CONTRACT: {
    en: "Edit Contract",
    de: "Vertrag bearbeiten",
    it: "Modifica del contratto",
    nl: "Contract bewerken"
  },
  VIEW_CONTRACT: {
    en: "View Contract",
    de: "Vertrag ansehen",
    it: "Visualizza il contratto",
    nl: "Contract bekijken"
  },
  AGREED_AT: {
    en: "Agreed at",
    de: "Akzeptiert am",
    it: "Concordato a",
    nl: "Overeengekomen op"
  },
  CONTRACT_TITLE: {
    en: "Contract Title",
    de: "Vertrag Titel",
    it: "Titolo del contratto",
    nl: "Contract Titel"
  },
  CONTRACT_CREATE_SUCCESS: {
    en: "Contract is created successfully.",
    de: "Der Vertrag wurde erfolgreich erstellt.",
    it: "Il contratto viene creato con successo.",
    nl: "Contract is succesvol aangemaakt."
  },
  CONTRACT_UPDATE_SUCCESS: {
    en: "Contract is updated successfully.",
    de: "Der Vertrag wurde erfolgreich aktualisiert.",
    it: "Il contratto viene aggiornato con successo.",
    nl: "Contract is succesvol bijgewerkt."
  },
  PDF_DOWNLOAD_SUCCESS: {
    en: "Contract PDF is downloaded successfully.",
    de: "Das Vertrags-PDF wurde erfolgreich heruntergeladen.",
    it: "Il PDF del contratto è stato scaricato con successo.",
    nl: "Contract PDF is succesvol gedownload."
  },
  SEARCH_BY_CLIENT_NAME: {
    en: "Search by client name",
    de: "Suche nach Kundennamen",
    it: "Cerca per nome del cliente",
    nl: "Zoeken op klantnaam"
  },
  CONTRACT_AGREEMENT: {
    en: "Show only agreed contracts",
    de: "Nur abgeschloßene Verträge anzeigen",
    it: "Mostra solo i contratti concordati",
    nl: "Toon alleen overeengekomen contracten"
  },
  TAG_UPDATED_SUCCESS: {
    en: "Tag is updated successfully.",
    de: "Tag wird erfolgreich aktualisiert.",
    it: "Il tag viene aggiornato con successo.",
    nl: "Tag is succesvol bijgewerkt."
  },
  DATE: {
    en: "Date",
    de: "Datum",
    it: "Data",
    nl: "Datum"
  },
  SESSION_TOKEN: {
    en: "Session token",
    de: "Sitzungs-Token",
    it: "Token di sessione",
    nl: "Session token"
  },
  IS_CENTER_MEMBER: {
    en: "Is center member?",
    de: "Ist Mitglied des Zentrums",
    it: "È membro del centro",
    nl: "Is lid van het centrum"
  },
  EASY_SOLUTION: {
    en: "Easy Solution",
    de: "Easy Solution",
    nl: "Easy Solution",
    it: "Easy Solution"
  },
  PASSWORD_MUST_BE_MORE_THAN: {
    en: "Password must more than 8 characters",
    de: "Passwort muss mehr als 8 Zeichen enthalten",
    it: "La password deve avere più di 8 caratteri",
    nl: "Wachtwoord moet meer dan 8 tekens"
  },
  PASSWORD_IS_NOT_SAME: {
    en: "Password is not same",
    de: "Passwort ist nicht gleich",
    it: "La password non è la stessa",
    nl: "Wachtwoord is niet hetzelfde"
  },
  PLEASE_ENTER_A_VALID_EMAIL: {
    en: "Please enter a valid e-mail address",
    de: "Bitte geben Sie eine gültige E-Mail Adresse ein",
    it: "Inserisci un indirizzo e-mail valido",
    nl: "Voer een geldig e-mail adres in"
  },
  MEMBER_IS_SUCCESSFULLY_CREATED: {
    en: "Member is successfully created",
    de: "Mitglied wurde erfolgreich erstellt",
    it: "Il membro è stato creato con successo",
    nl: "Lid is succesvol aangemaakt"
  },
  MEMBER_IS_SUCCESSFULLY_UPDATED: {
    en: "Member is successfully updated",
    de: "Mitglied wurde erfolgreich aktualisiert",
    it: "Il membro è stato aggiornato con successo",
    nl: "Lid is succesvol bijgewerkt"
  },
  MEMBER_IS_SUCCESSFULLY_REMOVED: {
    en: "Member is successfully removed",
    de: "Mitglied wurde erfolgreich entfernt",
    it: "Il membro è stato rimosso con successo",
    nl: "Lid is succesvol verwijderd"
  },
  DELETE_CONFIRMATION_MEMBER: {
    en: "Are you sure to remove the selected member?",
    de: "Sind Sie sicher, dass Sie das ausgewählte Mitglied entfernen möchten?",
    it: "Sei sicuro di rimuovere il membro selezionato?",
    nl: "Weet u zeker dat u het geselecteerde lid wilt verwijderen?"
  },
  TIPS_OF_THE_WEEK: {
    en: "Tips of the week",
    de: "Tipps der Woche",
    it: "Consigli della settimana",
    nl: "Tips van de week"
  },
  TIP_IS_SUCCESSFULLY_CREATED: {
    en: "Tip is successfully created",
    de: "Tipp wurde erfolgreich erstellt",
    it: "Il suggerimento è stato creato con successo",
    nl: "Tip is succesvol gemaakt"
  },
  TIP_IS_SUCCESSFULLY_UPDATED: {
    en: "Tip is successfully updated",
    de: "Tipp wird erfolgreich aktualisiert",
    it: "Il suggerimento è stato aggiornato con successo",
    nl: "Tip is succesvol bijgewerkt"
  },
  TIP_IS_SUCCESSFULLY_REMOVED: {
    en: "Tip is successfully removed",
    de: "Spitze wird erfolgreich entfernt",
    it: "La punta viene rimossa con successo",
    nl: "Tip is succesvol verwijderd"
  },
  DELETE_CONFIRMATION_TIP: {
    en: "Are you sure to remove the selected tip?",
    de: "Sind Sie sicher, dass Sie die ausgewählte Spitze entfernen wollen?",
    it: "Sei sicuro di rimuovere la punta selezionata?",
    nl: "Weet je zeker dat je de geselecteerde tip moet verwijderen?"
  },
  DESCRIPTION_TYPE: {
    en: "Description type",
    de: "Art der Beschreibung",
    it: "Tipo di descrizione",
    nl: "Beschrijving type"
  },
  VIDEO: {
    en: "Video",
    de: "Video",
    it: "Video",
    nl: "Video"
  },
  VIDEO_TYPE: {
    en: "Video type",
    de: "Video-Typ",
    it: "Tipo di video",
    nl: "Video type"
  },
  VIDEO_URL: {
    en: "Video URL",
    de: "Video-URL",
    it: "URL del video",
    nl: "Video URL"
  },
  VIMEO: {
    en: "Vimeo",
    de: "Vimeo",
    it: "Vimeo",
    nl: "Vimeo"
  },
  YOUTUBE: {
    en: "Youtube",
    de: "Youtube",
    it: "Youtube",
    nl: "Youtube"
  },
  DATE_OF_BIRTH: {
    en: "Date of birth",
    de: "Geburtsdatum",
    it: "Data di nascita",
    nl: "Geboortedatum"
  },
  CREATE_FUNNEL: {
    en: "Create Funnel",
    de: "Trichter erstellen",
    it: "Crea imbuto",
    nl: "Creëer trechter"
  },
  ENTER_FUNNEL_TITLE: {
    en: "Enter funnel title",
    de: "Titel des Trichters eingeben",
    it: "Inserisci il titolo del funnel",
    nl: "Titel van trechter invoeren"
  },
  ENTER_FUNNEL_DESC: {
    en: "Enter funnel description",
    de: "Beschreibung des Trichters eingeben",
    it: "Inserisci la descrizione dell'imbuto",
    nl: "Voer trechterbeschrijving in"
  },
  CREATE_NEW_FUNNEL: {
    en: "Create new funnel",
    de: "Neuen Trichter erstellen",
    it: "Creare un nuovo imbuto",
    nl: "Creëer nieuwe trechter"
  },
  EDIT_MAILING: {
    en: "Edit Mailing",
    de: "Mailing bearbeiten",
    it: "Modifica dell'invio",
    nl: "Mailing bewerken"
  },
  CREATE_MAILING: {
    en: "Create Mailing",
    de: "Mailing erstellen",
    it: "Creare mailing",
    nl: "Maak mailing"
  },
  CREATE_NEW_MAILING: {
    en: "Create New Mailing",
    de: "Neues Mailing erstellen",
    it: "Creare un nuovo mailing",
    nl: "Nieuwe mailing aanmaken"
  },
  SUBJECT: {
    en: "Subject",
    de: "Betreff",
    it: "Soggetto",
    nl: "Betreft"
  },
  CREATE_MAILING_TEMPLATE: {
    en: "Create Mailing Template",
    de: "Mailingvorlage erstellen",
    it: "Creare modello di mailing",
    nl: "Mailingsjabloon maken"
  },
  CREATE_NEW_MAILING_TEMPPLATE: {
    en: "Create New Mailing Template",
    de: "Neue Mailingvorlage erstellen",
    it: "Creare un nuovo modello di mailing",
    nl: "Maak een nieuw mailing sjabloon"
  },
  SCENARIO: {
    en: "Scenario",
    de: "Szenario",
    nl: "Scenario",
    it: "Scenario"
  },
  CREATE_SCENARIO: {
    en: "Create Scenario",
    de: "Szenario erstellen",
    it: "Creare uno scenario",
    nl: "Scenario maken"
  },
  UPDATE_SCENARIO: {
    en: "Update Scenario",
    de: "Update-Szenario",
    it: "Scenario di aggiornamento",
    nl: "Scenario bijwerken"
  },
  SEARCH_WITH_TITLE: {
    en: "Search with title",
    de: "Suche mit Titel",
    nl: "Zoeken op titel",
    it: "Ricerca con titolo"
  },
  EDIT_SYSTEM_MAILING: {
    en: "Edit System Mailing",
    de: "System Mailing bearbeiten",
    it: "Modifica del sistema Mailing",
    nl: "Mailing systeem bewerken"
  },
  EDIT_MAILING_TEMPLATE: {
    en: "Edit Mailing Template",
    de: "Mailingvorlage bearbeiten",
    it: "Modifica del modello di mailing",
    nl: "Mailing sjabloon bewerken"
  },
  CREATE_SUCCESS_SCENARIO: {
    en: "Scenario created successfully.",
    de: "Szenario erfolgreich erstellt.",
    it: "Scenario creato con successo.",
    nl: "Scenario succesvol gemaakt."
  },
  UPDATE_SUCCESS_SCENARIO: {
    en: "Scenario updated successfully.",
    de: "Das Szenario wurde erfolgreich aktualisiert.",
    it: "Scenario aggiornato con successo.",
    nl: "Scenario succesvol bijgewerkt."
  },
  CREATE_SUCCESS_MAILING_TEMPLATE: {
    en: "Mailing template created successfully.",
    de: "Mailingvorlage erfolgreich erstellt.",
    it: "Modello di mailing creato con successo.",
    nl: "Mailing sjabloon succesvol aangemaakt."
  },
  UPDATE_SUCCESS_MAILING_TEMPLATE: {
    en: "Mailing template updated successfully.",
    de: "Mailingvorlage erfolgreich aktualisiert.",
    it: "Modello di mailing aggiornato con successo.",
    nl: "Mailing sjabloon succesvol bijgewerkt."
  },
  CREATE_SUCCESS_SYSTEM_FUNNEL: {
    en: "System funnel created successfully.",
    de: "Systemtrichter erfolgreich erstellt.",
    it: "Funnel di sistema creato con successo.",
    nl: "Systeem trechter succesvol gemaakt."
  },
  UPDATE_SUCCESS_SYSTEM_FUNNEL: {
    en: "System funnel updated successfully.",
    de: "Systemtrichter erfolgreich aktualisiert.",
    it: "Funnel di sistema aggiornato con successo.",
    nl: "Systeem trechter succesvol bijgewerkt."
  },
  EDITABLE_BY_CENTER_ADMIN: {
    en: "Editable by center admin",
    de: "Bearbeitbar durch den Center-Administrator",
    it: "Modificabile dall'amministratore del centro",
    nl: "Bewerkbaar door centrum admin"
  },
  START_IF_TRIGGERED: {
    en: "Start if triggered",
    de: "Start bei Auslösung",
    it: "Iniziare se attivato",
    nl: "Start indien geactiveerd"
  },
  START_ON_FIX_DAY: {
    en: "Start on a fix day",
    de: "Start an einem festen Tag",
    it: "Iniziare in un giorno fisso",
    nl: "Begin op een vaste dag"
  },
  CREATE_SUCCESS_SYSTEM_MAILING: {
    en: "System mailing created successfully.",
    de: "System-Mailing erfolgreich erstellt.",
    it: "Mailing di sistema creato con successo.",
    nl: "Systeem mailing succesvol gemaakt."
  },
  UPDATE_SUCCESS_SYSTEM_MAILING: {
    en: "System mailing updated successfully.",
    de: "System-Mailing erfolgreich aktualisiert.",
    it: "Mailing di sistema aggiornato con successo.",
    nl: "Systeem mailing succesvol bijgewerkt."
  },
  UPDATE_FUNNEL: {
    en: "Update Funnel",
    de: "Update-Trichter",
    it: "Aggiornamento dell'imbuto",
    nl: "Trechter bijwerken"
  },
  PLEASE_SELECT_ONE_FROM_BELOW: {
    en: "Please select one from below",
    de: "Bitte wählen Sie eine der folgenden Optionen aus",
    it: "Si prega di selezionare uno dei seguenti",
    nl: "Kies er een van hieronder"
  },
  CAN_CENTER_ADMIN_EDIT: {
    en: "Can center admin assign mailing to this funnel?",
    de: "Kann der Center-Administrator diesem Trichter Mailings zuweisen?",
    it: "L'amministratore del centro può assegnare il mailing a questo funnel?",
    nl: "Kan een beheerder een mailing toewijzen aan deze trechter?"
  },
  SECTION_NAME: {
    en: "Section Name",
    de: "Name der Sektion",
    it: "Nome della sezione",
    nl: "Sectie Naam"
  },
  UPDATE_SECTION_NAME: {
    en: "Update Section Name",
    de: "Name der Sektion aktualisieren",
    it: "Aggiorna il nome della sezione",
    nl: "Sectienaam bijwerken"
  },
  EDIT_SECTION: {
    en: "Edit Section",
    de: "Abschnitt bearbeiten",
    it: "Modifica sezione",
    nl: "Bewerk Sectie"
  },
  SORT_ORDER: {
    en: "Sort order",
    de: "Reihenfolge",
    it: "Ordinamento",
    nl: "Sorteer volgorde"
  },
  PRIVACY_POLICY: {
    en: "Privacy Policy",
    de: "Datenschutz",
    it: "Politica sulla privacy",
    nl: "Privacybeleid"
  },
  IMPRINT: {
    en: "Imprint",
    de: "Impressum",
    it: "Impronta",
    nl: "Afdruk"
  },
  IMPRINT_UPDATE_SUCCESS: {
    en: "Imprint is updated successfully.",
    de: "Das Impressum wurde erfolgreich aktualisiert.",
    it: "L'impronta viene aggiornata con successo.",
    nl: "Afdruk is succesvol bijgewerkt."
  },
  VIEW_IMPRINT: {
    en: "View Imprint",
    de: "Ansicht Impressum",
    it: "Visualizza l'impronta",
    nl: "Afdruk bekijken"
  },
  EDIT_IMPRINT: {
    en: "Edit Imprint",
    de: "Impressum bearbeiten",
    it: "Modifica dell'impronta",
    nl: "Afdruk bewerken"
  },
  CONTENT_VALIDATION_MESSAGE: {
    en: "Content cannot be empty",
    de: "Der Inhalt darf nicht leer sein",
    it: "Il contenuto non può essere vuoto",
    nl: "Inhoud kan niet leeg zijn"
  },
  POLICY_UPDATE_SUCCESS: {
    en: "Privacy policy is updated successfully.",
    de: "Die Datenschutzrichtlinie wurde erfolgreich aktualisiert.",
    it: "L'informativa sulla privacy è stata aggiornata con successo.",
    nl: "Privacybeleid is succesvol bijgewerkt."
  },
  VIEW_PRIVACY_POLICY: {
    en: "View Privacy Policy",
    de: "Datenschutz anzeigen",
    it: "Visualizza l'informativa sulla privacy",
    nl: "Privacybeleid bekijken"
  },
  EDIT_PRIVACY_POLICY: {
    en: "Edit Privacy Policy",
    de: "Datenschutz bearbeiten",
    it: "Modifica l'informativa sulla privacy",
    nl: "Privacybeleid bewerken"
  },
  DISPLAY_DURATION: {
    en: "Display duration",
    de: "Dauer der Anzeige",
    it: "Durata del display",
    nl: "Weergaveduur"
  },
  DISPLAY_FROM: {
    en: "Display from",
    de: "Anzeige von",
    it: "Visualizza da",
    nl: "Weergave van"
  },
  DISPLAY_TO: {
    en: "Display to",
    de: "Anzeige an",
    it: "Mostra a",
    nl: "Toon aan"
  },
  FIELD_EDITABLE_BY_CENTER: {
    en: "Field editable by center",
    de: "Vom Zentrum bearbeitbares Feld",
    it: "Campo modificabile dal centro",
    nl: "Veld wijzigbaar door centrum"
  },
  IS_DRAFT: {
    en: "Is draft",
    de: "Ist Entwurf",
    it: "È una bozza",
    nl: "Is ontwerp"
  },
  WEEK_OF_THE_YEAR_TO_DISPLAY: {
    en: "Week of the year to display",
    de: "Anzuzeigende Woche des Jahres",
    it: "Settimana dell'anno da visualizzare",
    nl: "Week van het jaar om weer te geven"
  },
  CONTRACTS: {
    en: "Contracts",
    de: "Verträge",
    it: "Contratti",
    nl: "Contracten"
  },
  PLACEHOLDER_TEXT: {
    en: "You can use the following placeholders in the AV contract:",
    de: "Sie können die folgenden Platzhalter im AV Vertrag verwenden:",
    it: "Puoi usare i seguenti segnaposti nel contratto:",
    nl: "U kunt de volgende plaatshouders in het contract gebruiken:"
  },
  AUTHORIZED_PERSON: {
    en: "Authorized person:",
    de: "Autorisierte person:",
    it: "Persona autorizzata:",
    nl: "Bevoegd persoon:"
  },
  CONTRACT_NUMBER: {
    en: "Contract number of the main contract:",
    de: "Vertragsnummer des Hauptvertrages:",
    it: "Numero del contratto principale:",
    nl: "Contractnummer van het hoofdcontract:"
  },
  NAME_ADDRESS_OF_COMPANY: {
    en: "Name and address of the company:",
    de: "Name und Anschrift der Firma:",
    it: "Nome e indirizzo dell'azienda:",
    nl: "Naam en adres van het bedrijf:"
  },
  SELECT_A_SALUTATION: {
    en: "Select a salutation",
    de: "Bitte Anrede auswählen",
    it: "Si prega di selezionare il saluto",
    nl: "Selecteer een aanhef"
  },
  EXPERT_MUST_BE_AT_LEAST: {
    en: "Expert must be at least 18 years old",
    de: "Der Experte muss mindestens 18 Jahre alt sein",
    it: "L'esperto deve avere almeno 18 anni",
    nl: "De deskundige moet ten minste 18 jaar oud zijn"
  },
  START_TIME: {
    en: "Start time",
    de: "Startzeit",
    it: "Ora di inizio",
    nl: "Begintijd"
  },
  END_TIME: {
    en: "End time",
    de: "Endzeit",
    it: "Tempo di fine",
    nl: "Eindtijd"
  },
  PLEASE_SELECT_A_DATE_TIME_LATER_THAN_TODAY: {
    en: "Please select a date time later than today",
    de: "Bitte wählen Sie ein Datum, das später als heute liegt",
    it: "Si prega di selezionare una data successiva ad oggi",
    nl: "Gelieve een datum te kiezen die later valt dan vandaag"
  },
  WEEK_TO_DISPLAY: {
    en: "Week to display",
    de: "Anzuzeigende Woche",
    it: "Settimana da visualizzare",
    nl: "Week om weer te geven"
  },
  FREE_FOR_TRIAL_MEMBERS: {
    en: "Free for trial members",
    de: "Kostenlos für Testmitglieder",
    it: "Gratuito per i membri di prova",
    nl: "Gratis voor proefleden"
  },
  FREE_FOR_GUESTS: {
    en: "Free for guests",
    de: "Frei für Gäste",
    it: "Gratuito per gli ospiti",
    nl: "Gratis voor gasten"
  },
  PROGRAM_PARTICIPANTS: {
    en: "Program participants",
    de: "Programmteilnehmer",
    it: "Partecipanti al programma",
    nl: "Deelnemers aan het programma"
  },
  TRIAL_MEMBERS: {
    en: "Trial members",
    de: "Mitglieder auf Probe",
    it: "Membri di prova",
    nl: "Proefleden"
  },
  PROGRAM_FOR: {
    en: "Program for",
    de: "Programm für",
    it: "Programma per",
    nl: "Programma voor"
  },
  TYPE_OF_CONTRACT: {
    en: "Type of contract",
    de: "Art des Vertrags",
    it: "Tipo di contratto",
    nl: "Type contract"
  },
  OLD_CONTRACT: {
    en: "Old Contract",
    de: "Bestehender PDF Vertrag",
    it: "Vecchio Contratto",
    nl: "Oud Contract"
  },
  NEW_CONTRACT: {
    en: "New Contract",
    de: "Digitaler AV Vertrag",
    it: "Nuovo Contratto",
    nl: "Nieuw Contract"
  },
  UPLOAD_OLD_CONTRACT_PDF: {
    en: "Upload Old Contract PDF",
    de: "Bestehenden PDF Vertrag hochladen",
    it: "Carica il vecchio contratto PDF",
    nl: "Upload oude contract PDF"
  },
  IS_SIGNED_CONTRACT: {
    en: "Is signed contract",
    de: "Ist unterzeichneter Vertrag",
    it: "È un contratto firmato",
    nl: "Is ondertekend contract"
  },
  UPLOADED_PDF: {
    en: "Uploaded PDF",
    de: "Hochgeladene PDF",
    it: "PDF caricato",
    nl: "Geüploade PDF"
  },
  START_END_DATE_TITLE: {
    en: "Useable for Center in this time range",
    de: "Verwendbar für Center in diesem Zeitbereich",
    it: "Utilizzabile per il centro in questo intervallo di tempo",
    nl: "Bruikbaar voor Center in deze tijdspanne"
  },
  END_DATE_REQUIRED: {
    en: "End date and start date is required as the another",
    de: "Enddatum und Startdatum sind erforderlich.",
    it: "La data di fine e la data di inizio sono richieste.",
    nl: "Einddatum en begindatum zijn vereist."
  },
  SIGNED_DATE: {
    en: "Signed Date",
    de: "Datum der Unterschrift auf dem PDF Vertrag",
    it: "Firmato Data",
    nl: "Ondertekend Datum"
  },
  CENTER_PURCHASED_PROGRAM_TEMPLATES: {
    en: "Center purchased program templates",
    de: "Center erwarb Programmvorlagen",
    it: "Centro ha acquistato modelli di programma",
    nl: "Center gekochte programma sjablonen"
  },
  ASSIGN_PURCHASED_PROGRAM_TEMPLATE: {
    en: "Assign purchased program template",
    de: "Gekaufte Programmvorlage zuordnen",
    it: "Assegnare il modello di programma acquistato",
    nl: "Aangekocht programmasjabloon toewijzen"
  },
  PROGRAM_TEMPLATE_IS_SUCCESSFULLY_ASSIGNED: {
    en: "Program template is successfully assigned",
    de: "Programmvorlage wird erfolgreich zugewiesen",
    it: "Il modello del programma è stato assegnato con successo",
    nl: "Programmasjabloon is met succes toegewezen"
  },
  UNARCHIVE: {
    en: "Unarchive",
    de: "Nicht archivieren",
    it: "Unarchive",
    nl: "Unarchive"
  },
  BRIEF_DESCRIPTION: {
    en: "Brief description",
    de: "Kurzbeschreibung",
    it: "Breve descrizione",
    nl: "Korte beschrijving"
  },
  HIGHLIGHT: {
    en: "Highlight",
    de: "Highlight",
    it: "Evidenziare",
    nl: "Markeer"
  },
  SECONDARY_HIGHLIGHT: {
    en: "Secondary highlight",
    de: "Sekundäres Highlight",
    it: "Punto culminante secondario",
    nl: "Secundair hoogtepunt"
  },
  CONTENT_DESCRIPTION: {
    en: "Content description",
    de: "Beschreibung des Inhalts",
    it: "Descrizione del contenuto",
    nl: "Beschrijving van de inhoud"
  },
  ARCHIVE_UNARCHIVE_CONFIRMATION: {
    en: "Are you sure you want to archive/unarchive this content?",
    de: "Sind Sie sicher, dass Sie diesen Inhalt archivieren/unarchivieren wollen?",
    it: "Sei sicuro di voler archiviare/disarchiviare questo contenuto?",
    nl: "Weet u zeker dat u deze inhoud wilt archiveren/unarchiveren?"
  },
  GENERATE_NEW_DOM_ID: {
    en: "Generate ID for New DOM",
    de: "ID für neues DOM generieren",
    it: "Generare l'ID per il nuovo DOM",
    nl: "Genereer ID voor nieuwe DOM"
  },
  DELETE_SECTION_CATEGORY_FAIL: {
    en: "Delete section category failed",
    de: "Löschen der Abschnittskategorie fehlgeschlagen",
    it: "Cancellazione della categoria di sezione fallita",
    nl: "Verwijderen sectie categorie mislukt"
  },
  ASSIGN_SECTION_CATEGORY_FAILED: {
    en: "Assign section category failed",
    de: "Abschnittskategorie zuweisen fehlgeschlagen",
    it: "Assegnazione della categoria di sezione fallita",
    nl: "Sectie categorie toewijzen mislukt"
  },
  KNOWLEDGE: {
    en: "Knowledge",
    de: "Wissen kompakt",
    it: "Conoscenza",
    nl: "Kennis"
  },
  KNOWLEDGE_FILES: {
    en: "Knowledge Files",
    de: "Wissensdateien",
    it: "File della conoscenza",
    nl: "Kennisbestanden"
  },
  KNOWLEDGE_IS_SUCCESSFULLY_CREATED: {
    en: "Knowledge is successfully created",
    de: "Wissen wird erfolgreich geschaffen",
    it: "La conoscenza viene creata con successo",
    nl: "Kennis wordt met succes gecreëerd"
  },
  KNOWLEDGE_IS_SUCCESSFULLY_UPDATED: {
    en: "Knowledge is successfully updated",
    de: "Wissen wird erfolgreich aktualisiert",
    it: "La conoscenza è aggiornata con successo",
    nl: "Kennis wordt met succes bijgewerkt"
  },
  KNOWLEDGE_IS_SUCCESSFULLY_REMOVED: {
    en: "Knowledge is successfully removed",
    de: "Wissen wird erfolgreich entfernt",
    it: "La conoscenza viene rimossa con successo",
    nl: "Kennis is succesvol verwijderd"
  },
  DELETE_CONFIRMATION_KNOWLEDGE: {
    en: "Are you sure to removed the selected knowledge?",
    de: "Sind Sie sicher, dass Sie das ausgewählte Wissen entfernen wollen?",
    it: "Sei sicuro di aver rimosso la conoscenza selezionata?",
    nl: "Ben je zeker dat je de geselecteerde kennis hebt verwijderd?"
  },
  PREVIEW_MODE: {
    en: "Preview mode",
    de: "Vorschau Modus",
    it: "Modalità anteprima",
    nl: "Voorbeeldmodus"
  },
  EDITING_MODE: {
    en: "Editing mode",
    de: "Bearbeitungsmodus",
    it: "Modalità di modifica",
    nl: "Bewerkingsmodus"
  },
  CREATE_CONTENT: {
    en: "Create content",
    de: "Inhalte erstellen",
    it: "Crea contenuto",
    nl: "Inhoud maken"
  },
  ADD_COMPONENT_TYPE: {
    en: "Add component type",
    de: "Komponententyp hinzufügen",
    it: "Aggiungi tipo di componente",
    nl: "Componenttype toevoegen"
  },
  SELECT_COLUMN_WIDTH: {
    en: "Select column width",
    de: "Spaltenbreite auswählen",
    it: "Seleziona la larghezza della colonna",
    nl: "Selecteer kolombreedte"
  },
  COLUMN_MIN_HEIGHT: {
    en: "Column min height",
    de: "Mindesthöhe der Spalte",
    it: "Altezza minima colonna Column",
    nl: "Kolom min. hoogte"
  },
  MIN_HEIGHT_CANNOT_BE_ZERO: {
    en: "Min height cannot be less than or equal to 0.",
    de: "Die Mindesthöhe darf nicht kleiner oder gleich 0 sein.",
    it: "L'altezza minima non può essere minore o uguale a 0.",
    nl: "Minimale hoogte kan niet kleiner zijn dan of gelijk zijn aan 0."
  },
  ADJUST_PADDING: {
    en: "Adjust padding",
    de: "Abstand zum Rand anpassen",
    it: "Regola l'imbottitura",
    nl: "Pas opvulling aan"
  },
  DO_NOT_SELECT_ANYTHING: {
    en: "Preview",
    de: "Vorschau",
    it: "Anteprima",
    nl: "Voorbeeld"
  },
  CLEAR_SELECTION: {
    en: "Close editing of the selected element",
    de: "Bearbeitung des ausgewählten Elements schließen",
    it: "Cancella selezione",
    nl: "Selectie wissen"
  },
  ASSIGN_IMAGE: {
    en: "Assign image",
    de: "Bild zuweisen",
    it: "Assegna immagine",
    nl: "Afbeelding toewijzen"
  },
  ACTUAL_IMAGE_SIZE: {
    en: "Actual image size",
    de: "Tatsächliche Bildgröße",
    it: "Dimensione reale dell'immagine",
    nl: "Werkelijke beeldgrootte"
  },
  MAKE_THINGS_CENTER: {
    en: "Make things center",
    de: "Zentrieren",
    it: "Metti le cose al centro",
    nl: "Zet dingen centraal"
  },
  MAKE_THINGS_NORMAL: {
    en: "Make things normal",
    de: "Zurücksetzen",
    it: "Rendi le cose normali",
    nl: "Maak dingen normaal"
  },
  REMOVE_COMPONENT: {
    en: "Remove component",
    de: "Komponente entfernen",
    it: "Rimuovi componente",
    nl: "Component verwijderen"
  },
  SET_BG_IMG: {
    en: "Set background image",
    de: "Hintergrundbild festlegen",
    it: "Imposta immagine di sfondo",
    nl: "Achtergrondafbeelding instellen"
  },
  SET_COMPONENT_DRAGGABLE: {
    en: "Set component draggable",
    de: "Komponente verschiebbar",
    it: "Imposta componente trascinabile",
    nl: "Component versleepbaar instellen"
  },
  BUTTON: {
    en: "Button",
    de: "Button",
    it: "Pulsante",
    nl: "Knop"
  },
  UPDATE_TEXT: {
    en: "Update text",
    de: "Text aktualisieren",
    it: "Aggiorna testo",
    nl: "Tekst bijwerken"
  },
  COLUMN: {
    en: "Column",
    de: "Spalte",
    it: "Colonna",
    nl: "Kolom"
  },
  COLUMNS: {
    en: "Columns",
    de: "Säulen",
    it: "Colonne",
    nl: "Kolommen"
  },
  MIN_HEIGHT: {
    en: "Minimum Height",
    de: "Minimale Höhe",
    it: "Altezza minima",
    nl: "Minimum Hoogte"
  },
  TEXT_TRANSFORM: {
    en: "Text Transform",
    de: "Text umwandeln",
    it: "Trasformazione del testo",
    nl: "Tekst omzetten"
  },
  UPPERCASE: {
    en: "Uppercase",
    de: "Großbuchstaben",
    it: "Maiuscolo",
    nl: "Hoofdletters"
  },
  LOWERCASE: {
    en: "Lowercase",
    de: "Kleinbuchstaben",
    it: "Minuscolo",
    nl: "Kleine letters"
  },
  CLICKABLE_SETTINGS: {
    en: "Clickable settings",
    de: "Anklickbare Einstellungen",
    it: "Impostazioni cliccabili",
    nl: "Klikbare instellingen"
  },
  LINK: {
    en: "Link",
    de: "Link",
    it: "Link",
    nl: "Koppeling"
  },
  OPEN_LINK_IN_NEW_TAB: {
    en: "open link in new tab",
    de: "Link in neuem Tab öffnen",
    it: "apri il link in una nuova scheda",
    nl: "Open link in nieuw tabblad"
  },
  TOP: {
    en: "Top",
    de: "oben",
    it: "Superiore",
    nl: "Top"
  },
  RIGHT: {
    en: "Right",
    de: "Rechts",
    it: "Giusto",
    nl: "Rechtsaf"
  },
  BOTTOM: {
    en: "Bottom",
    de: "Unterseite",
    it: "Parte inferiore",
    nl: "Bodem"
  },
  LEFT: {
    en: "Left",
    de: "Links",
    it: "Sinistra",
    nl: "Links"
  },
  LINK_TO_PDF_URL: {
    en: "link to PDF",
    de: "link zur PDF",
    it: "link all' PDF",
    nl: "link naar pdf"
  },
  UPLOAD: {
    en: "Upload",
    de: "Hochladen",
    it: "Caricare",
    nl: "Uploaden"
  },
  INTERNAL: {
    en: "Internal",
    de: "Intern",
    it: "Interno",
    nl: "intern"
  },
  EXTERNAL: {
    en: "External",
    de: "Extern",
    it: "Esterno",
    nl: "Extern"
  },
  PUBLIC_FILE: {
    en: "Select Public file",
    de: "Öffentliche Datei wählen",
    it: "Seleziona file pubblico",
    nl: "Selecteer Openbaar bestand"
  },
  IS_FOR_SITE_VISITOR_DOWNLOAD_FILE: {
    en: "Is this a button for site visitor to download file?",
    de: "Ist dies ein Button für den Besucher, um eine Datei herunterzuladen?",
    it: "Questo è un pulsante per il visitatore del sito per scaricare il file?",
    nl: "Is dit een knop waarmee bezoekers van de site een bestand kunnen downloaden?"
  },
  TEL: {
    en: "Phone number",
    de: "Telefonnummer",
    it: "Numero di telefono",
    nl: "Telefoonnummer"
  },
  COLUMN_NAVIGATOR: {
    en: "Column navigator",
    de: "Säulen-Navigator",
    it: "Navigatore a colonne",
    nl: "Kolom navigator"
  },
  IMAGE_PAGE_EDITOR: {
    en: "Image",
    de: "Bild",
    it: "Immagine",
    nl: "Afbeelding"
  },
  SCREEN_MODE: {
    en: "Screen mode",
    de: "Bildschirm-Modus",
    it: "Modalità schermo",
    nl: "Schermmodus"
  },
  COLUMN_MAX_WIDTH: {
    en: "Column maximum width",
    de: "Maximale Breite der Spalte",
    it: "Larghezza massima della colonna",
    nl: "Kolom maximale breedte"
  },
  REARRANGE_CONTENT: {
    en: "Rearrange content",
    de: "Inhalt neu anordnen",
    it: "Riorganizzare il contenuto",
    nl: "Herschik inhoud"
  },
  STOP_CONTENT_REARRANGEMENT: {
    en: "Stop content rearrangement",
    de: "Anhalten der Umordnung des Inhalts",
    it: "Fermare la riorganizzazione del contenuto",
    nl: "Stop het herschikken van inhoud"
  },
  ADJUST_MARGIN: {
    en: "Adjust margin",
    de: "Margin anpassen",
    it: "Regolare il margine",
    nl: "Aanpassen marge"
  },
  MAKE_THINGS_ALIGN_LEFT: {
    en: "Make Things Align Left",
    de: "Linksbündig ausrichten",
    it: "Allineare le cose a sinistra",
    nl: "Dingen links uitlijnen"
  },
  MAKE_THINGS_ALIGN_RIGHT: {
    en: "Make Things Align Right",
    de: "Rechtwsbündig ausrichten",
    it: "Allineare bene le cose",
    nl: "Dingen goed uitlijnen"
  },
  ACTIVATE_MEDIA_MESSAGE: {
    en: "Only when the embedded video is activated will a connection to the YouTube server be established to play the content, personal data (e.g. your IP address) will be transmitted and cookies will be set. Please read Google's data protection information before accessing the site.",
    de: "Erst durch das Aktivieren des eingebetteten Videos wird zum Ausspielen des Inhaltes eine Verbindung zum YouTube-Server aufgebaut, personenbezogene Daten (bspw. Ihre IP-Adresse) übertragen sowie Cookies gesetzt. Bitte beachten Sie vor Aufruf die Datenschutzhinweise von Google.",
    it: "Solo quando il video incorporato viene attivato, viene stabilita una connessione con il server di YouTube per riprodurre il contenuto, vengono trasmessi dati personali (ad esempio l'indirizzo IP) e vengono impostati dei cookie. Si prega di leggere le informazioni sulla protezione dei dati di Google prima di accedere al sito.",
    nl: "Alleen wanneer de ingesloten video wordt geactiveerd, wordt een verbinding met de YouTube-server tot stand gebracht om de inhoud af te spelen, worden persoonlijke gegevens (bv. uw IP-adres) doorgegeven en worden cookies geplaatst. Lees de informatie over gegevensbescherming van Google voordat u de site bezoekt."
  },
  PLAY_VIDEO: {
    en: "Play video",
    de: "Video Abspielen",
    it: "Riproduci video",
    nl: "Video afspelen"
  },
  MEDIA_LIBRARY: {
    en: "Media Library",
    de: "Mediathek",
    it: "Biblioteca multimediale",
    nl: "Mediatheek"
  },
  MEDIA_LIBRARY_SETTINGS: {
    en: "Media Library Settings",
    de: "Einstellungen der Medienbibliothek",
    it: "Impostazioni della libreria multimediale",
    nl: "Mediabibliotheek Instellingen"
  },
  SHOW_TITLE: {
    en: "Show Title",
    de: "Titel anzeigen",
    it: "Mostra titolo",
    nl: "Toon Titel"
  },
  HIDE_TITLE: {
    en: "Hide Title",
    de: "Titel ausblenden",
    it: "Nascondi titolo",
    nl: "Titel verbergen"
  },
  APPLY_DEFAULT_THUMBNAIL: {
    en: "Apply default thumbnail",
    de: "Standard-Miniaturansicht anwenden",
    it: "Applica la miniatura predefinita",
    nl: "Standaard thumbnail toepassen"
  },
  DEFAULT_THUMBNAIL_APPLIED: {
    en: "Default thumbnail applied",
    de: "Standard-Miniaturansicht angewendet",
    it: "Miniatura predefinita applicata",
    nl: "Standaard thumbnail toegepast"
  },
  ALT: {
    en: "Alt",
    de: "Alt",
    it: "Alt",
    nl: "Alt"
  },
  NONE: {
    en: "None",
    de: "Keine",
    it: "Nessuno",
    nl: "Geen"
  },
  DOWNLOAD_FILE: {
    en: "Download file",
    de: "Datei herunterladen",
    it: "Scaricare il file",
    nl: "Bestand downloaden"
  },
  EXPERTS: {
    en: "Experts",
    de: "Experten",
    it: "Esperti",
    nl: "Experts"
  },
  FREE_FOR_EXPERTS: {
    en: "Free for experts",
    de: "Frei für Experten",
    it: "Gratuito per gli esperti",
    nl: "Gratis voor deskundigen"
  },
  SECTION: {
    en: "Section",
    de: "Abschnitt",
    it: "Sezione",
    nl: "Sectie"
  },
  CONFIGURE_NOW: {
    en: "Configure now",
    de: "Jetzt ausfüllen",
    nl: "Jetzt ausfüllen",
    it: "Jetzt ausfüllen"
  },
  NESTED_SELECTION: {
    en: "Nested selection",
    de: "Verschachtelte Auswahl",
    nl: "Selezione annidata",
    it: "Geneste selectie"
  },
  CONTENT_TYPE: {
    en: "Content type",
    de: "Art des Inhalts",
    it: "Tipo di contenuto",
    nl: "Type inhoud"
  },
  EMAIL_SUBJECT: {
    en: "Email Subject",
    de: "E-Mail Betreff",
    it: "Oggetto dell'e-mail",
    nl: "E-mail Onderwerp"
  },
  EMAIL_CONTENT: {
    en: "Email Content",
    de: "E-Mail-Inhalt",
    it: "Contenuto delle e-mail",
    nl: "E-mail inhoud"
  },
  EMAIL_CONTENT_PLACEHOLDER: {
    en: "You can use the following placeholders in email content:",
    de: "Sie können die folgenden Platzhalter in E-Mail-Inhalten verwenden:",
    it: "Puoi usare i seguenti segnaposti nel contenuto delle email:",
    nl: "U kunt de volgende plaatshouders gebruiken in e-mailinhoud:"
  },
  MEMBER_NAME: {
    en: "Member name",
    de: "Name des mitglieds",
    it: "Nome del membro",
    nl: "Naam lid"
  },
  TIPS_TITLE: {
    en: "Tips of the week title",
    de: "Titel der Tipps der Woche",
    it: "Suggerimenti del titolo della settimana",
    nl: "Tips van de week titel"
  },
  TIPS_DESCRIPTION: {
    en: "Tips of the week description",
    de: "Tipps der Woche Beschreibung",
    it: "Descrizione dei consigli della settimana",
    nl: "Tips van de week beschrijving"
  },
  UPDATE_EMAIL_SETTING: {
    en: "Update Email Setting",
    de: "E-Mail-Einstellungen aktualisieren",
    it: "Aggiornare le impostazioni dell'e-mail",
    nl: "E-mail instelling bijwerken"
  },
  EMAIL_SETTING_UPDATE_SUCCESS: {
    en: "Email setting is updated successfully.",
    de: "Die E-Mail-Einstellung wurde erfolgreich aktualisiert.",
    it: "L'impostazione della posta elettronica è stata aggiornata con successo.",
    nl: "E-mail instelling is succesvol bijgewerkt."
  },
  FILE_IS_SUCCESSFULLY_CREATED: {
    en: "File is successfully created",
    de: "Datei wurde erfolgreich erstellt",
    it: "Il file è stato creato con successo",
    nl: "Bestand is succesvol aangemaakt"
  },
  FILE_IS_SUCCESSFULLY_REMOVED: {
    en: "File is successfully removed",
    de: "Datei wurde erfolgreich entfernt",
    it: "Il file è stato rimosso con successo",
    nl: "Bestand is succesvol verwijderd"
  },
  DELETE_CONFIRMATION_FILE: {
    en: "Are you sure to remove the selected file?",
    de: "Sind Sie sicher, dass Sie die ausgewählte Datei entfernen wollen?",
    it: "Sei sicuro di rimuovere il file selezionato?",
    nl: "Weet u zeker dat u het geselecteerde bestand wilt verwijderen?"
  },
  FILE_NAME: {
    en: "File name",
    de: "Name der Datei",
    it: "Nome del file",
    nl: "Bestandsnaam"
  },
  FIFTY_TWO_WEEK_TIPS: {
    en: "52-week tips",
    de: "52-Wochen-Tipps",
    it: "Suggerimenti per 52 settimane",
    nl: "52-weken tips"
  },
  TWELVE_WEEK_TIPS: {
    en: "12-week tips for new members, with categories",
    de: "12-Wochen-Tipps für Neumitglieder, mit Kategorien",
    it: "Consigli per 12 settimane per i nuovi membri, con categorie",
    nl: "12-weken tips voor nieuwe leden, met categorieën"
  },
  REMOVE_PDF: {
    en: "Remove PDF",
    de: "PDF entfernen",
    it: "Rimuovi PDF",
    nl: "PDF verwijderen"
  },
  RENAME_YOUR_FILE: {
    en: "Rename your file",
    de: "Benennen Sie Ihre Datei um",
    it: "Rinomina il tuo file",
    nl: "Hernoem uw bestand"
  },
  DIVERSE: {
    en: "Diverse",
    de: "Divers",
    it: "Divers",
    nl: "Duikers"
  },
  TIP_URL: {
    en: "Tip URL",
    de: "Tipp URL",
    it: "Suggerimento URL",
    nl: "Tip URL"
  },
  YOUTUBE_LINKS: {
    en: "Youtube links",
    de: "Youtube-Links",
    it: "Link di Youtube",
    nl: "Youtube-links"
  },
  ARCHIVED_FUNNELS: {
    en: "Archived funnels",
    de: "Archivierte Trichter",
    it: "Imbuti archiviati",
    nl: "Gearchiveerde trechters"
  },
  ARCHIVED_MAILINGS: {
    en: "Archived mailings",
    de: "Archivierte Sendungen",
    it: "Invii archiviati",
    nl: "Gearchiveerde mailings"
  },
  PREVIEW_VIDEO: {
    en: "Preview video",
    de: "Vorschau Video",
    it: "Anteprima video",
    nl: "Voorbeeld video"
  },
  FOR_EMAIL: {
    en: "for email",
    de: "für E-Mail",
    it: "per e-mail",
    nl: "voor e-mail"
  },
  MEMBER_FIRST_NAME: {
    en: "Member's first name",
    de: "Vorname des Mitglieds",
    it: "Nome del membro",
    nl: "Voornaam van het lid"
  },
  MEMBER_LAST_NAME: {
    en: "Member's last name",
    de: "Nachname des Mitglieds",
    it: "Cognome del membro",
    nl: "Achternaam van het lid"
  },
  THUMBNAIL_IMAGE: {
    en: "Thumbnail image",
    de: "Thumbnail-Bild",
    it: "Immagine in miniatura",
    nl: "Thumbnail afbeelding"
  },
  BANNER_IMAGE: {
    en: "Banner image",
    de: "Banner-Bild",
    it: "Immagine banner",
    nl: "Banner afbeelding"
  },
  SMART_SECTION: {
    en: "Smart section",
    de: "Smart-Abschnitt",
    it: "La sezione",
    nl: "Slimme sectie"
  },
  SUGGESTED_IMAGE_SIZE: {
    en: "* Suggested image size : 1800px (W) * 460px (H)",
    de: "* Empfohlene Bildgröße : 1800px (B) * 460px (H)",
    it: "* Dimensione suggerita dell'immagine : 1800px (W) * 460px (H)",
    nl: "* Aanbevolen afbeeldingsgrootte: 1800px (B) * 460px (H)"
  },
  TEXTS: {
    en: "Texts",
    de: "Texte",
    it: "Testi",
    nl: "Teksten"
  },
  WEEK: {
    en: "week",
    de: "Woche",
    it: "settimana",
    nl: "week"
  },
  VIDEO_LINK: {
    en: "Video link",
    de: "Video-Link",
    it: "Collegamento video",
    nl: "Video link"
  },
  ADD_VIDEO: {
    en: "Add video",
    de: "Video hinzufügen",
    it: "Aggiungi video",
    nl: "Video toevoegen"
  },
  ADD_FILE: {
    en: "Add file",
    de: "Datei hinzufügen",
    it: "Aggiungi file",
    nl: "Bestand toevoegen"
  },
  DOWNLOAD: {
    en: "Download",
    de: "Herunterladen",
    it: "Scaricare",
    nl: "Download"
  },
  MAIL_GUN_STATISTICS: {
    en: "Mailgun Statistics",
    de: "Mailgun Statistiken",
    it: "Mailgun Statistiches",
    nl: "Mailgun Statistieken"
  },
  VIEW_MAILGUN_STAT: {
    en: "View Mail Gun Statistics",
    de: "Mailgun-Statistiken anzeigen",
    it: "Visualizza le statistiche sulle armi da posta",
    nl: "Mail Gun-statistieken bekijken"
  },
  NEWSLETTER: {
    en: "Newsletter",
    de: "Newsletter",
    it: "Notiziario",
    nl: "Nieuwsbrief"
  },
  DURATION: {
    en: "Duration",
    de: "Dauer",
    it: "Durata",
    nl: "Looptijd"
  },
  DURATION_UNIT: {
    en: "Duration Unit",
    de: "Dauer Einheit",
    it: "Unità di durata",
    nl: "Duur Eenheid"
  },
  TOTAL_DELIVERED: {
    en: "Total Delivered Mail",
    de: "Zugestellte E-Mails insgesamt",
    it: "Posta consegnata totale",
    nl: "Totaal bezorgde post"
  },
  PERCENTAGE: {
    en: "Percentage",
    de: "Prozentsatz",
    it: "Percentuale",
    nl: "Percentage"
  },
  OPENED_MAIL: {
    en: "Opened Mail",
    de: "Mail geöffnet",
    it: "Posta aperta",
    nl: "geopende e-mail"
  },
  SUBSCRIBED: {
    en: "Subscribed",
    de: "Angemeldet",
    it: "Abbonato",
    nl: "Geabonneerd"
  },
  UNSUBSCRIBED: {
    en: "Unsubscribed",
    de: "Abgemeldet",
    it: "Iscrizione annullata",
    nl: "Uitgeschreven"
  },
  COMPLAINED: {
    en: "Complained",
    de: "Beschwerte sich",
    it: "Lamentato",
    nl: "Geklaagd"
  },
  OPENED_LINK: {
    en: "Opened Link",
    de: "Link geöffnet",
    it: "Collegamento aperto",
    nl: "Geopende link"
  },
  RECEIVED: {
    en: "Received",
    de: "Erhalten",
    it: "Ricevuto",
    nl: "Hebben ontvangen"
  },
  FAILED_TO_SEND: {
    en: "Failed To Send",
    de: "Senden fehlgeschlagen",
    it: "Impossibile inviare",
    nl: "Kan niet verzenden"
  },
  EDIT_DURATION: {
    en: "Edit Duration",
    de: "Bearbeiten Dauer",
    it: "Modifica Durata",
    nl: "Duur bewerken"
  },
  FUNNEL_MARKETING: {
    en: "Funnel Marketing",
    de: "Funnelmarketing",
    it: "Marketing a imbuto",
    nl: "Trechter Marketing"
  },
  NO_MAILGUN_STAT: {
    en: "No Stats available at the moment.",
    de: "Momentan sind keine Statistiken verfügbar.",
    it: "Nessuna statistica disponibile al momento.",
    nl: "Er zijn momenteel geen statistieken beschikbaar."
  },
  CENTER_STATISTICS: {
    en: "Center Statistics",
    de: "Center-Statistik",
    it: "Centro Statistiche",
    nl: "Centrum Statistieken"
  },
  OTHERS: {
    en: "Others",
    de: "Andere",
    it: "Altri",
    nl: "Anderen"
  },
  MEMBER: {
    en: "Member",
    de: "Mitglied",
    it: "Membro",
    nl: "Lid"
  },
  EXPERT: {
    en: "Expert",
    de: "Experte",
    it: "Esperto",
    nl: "Expert"
  },
  TRIALMEMBER: {
    en: "Trial member",
    de: "Mitglied auf Probe",
    it: "Membro di prova",
    nl: "Proeflid"
  },
  PROGRAMPARTICIPANT: {
    en: "Program participant",
    de: "Programmteilnehmer",
    it: "Partecipante al programma",
    nl: "Deelnemer aan het programma"
  },
  NEW_SECTION: {
    en: "New Section",
    de: "Neuer Abschnitt",
    it: "Nuova sezione",
    nl: "Nieuwe Sectie"
  },
  SECTION_DELETE_SUCCESS: {
    en: "Section is deleted successfully.",
    de: "Der Abschnitt wurde erfolgreich gelöscht.",
    it: "La sezione è stata cancellata con successo.",
    nl: "Sectie is succesvol verwijderd."
  },
  LANDING_PAGE_SECTION_LISTING: {
    en: "Landing Page Section Listing",
    de: "Auflistung der Abschnitte der Landing Page",
    it: "Elenco delle sezioni della pagina di atterraggio",
    nl: "Landingspagina Sectie Lijst"
  },
  LANDING_PAGE_LISTING: {
    en: "Landing Page Listing",
    de: "Auflistung der Landing Page",
    it: "Elenco delle pagine di destinazione",
    nl: "Landing Page Lijsting"
  },
  DELETE_CONFIRMATION_SECTION: {
    en: "Are you sure to remove the selected section?",
    de: "Sind Sie sicher, dass Sie den ausgewählten Abschnitt entfernen wollen?",
    it: "Sei sicuro di rimuovere la sezione selezionata?",
    nl: "Weet u zeker dat u de geselecteerde sectie wilt verwijderen?"
  },
  DELETE_SECTION: {
    en: "Delete section",
    de: "Abschnitt löschen",
    it: "Cancellare la sezione",
    nl: "Verwijder sectie"
  },
  DELETE_PAGE: {
    en: "Delete page",
    de: "Seite löschen",
    it: "Cancellare la pagina",
    nl: "Verwijder pagina"
  },
  WEBFORM_TEMPLATE: {
    en: "Webform Template",
    de: "Webform-Vorlage",
    it: "Modello Webform",
    nl: "Webformulier Sjabloon"
  },
  CREATE_FORM: {
    en: "Create form",
    de: "Formular erstellen",
    it: "Creare il modulo",
    nl: "Creëer vorm"
  },
  CONFIRMATION_MESSAGE: {
    en: "Confirmation message",
    de: "Bestätigungsmeldung",
    it: "Messaggio di conferma",
    nl: "Bevestigingsbericht"
  },
  FORM_END_BUTTON: {
    en: "Button at the end of the form",
    de: "Button am Ende des Formulars",
    it: "Pulsante alla fine del modulo",
    nl: "Knop aan het eind van het formulier"
  },
  BUTTON_STYLE: {
    en: "Button Style",
    de: "Taste Stil",
    it: "Stile del pulsante",
    nl: "Knoop Stijl"
  },
  EMAIL_RECIPIENTS: {
    en: "Email recipients ( enter \",\" to separate emails )",
    de: "E-Mail-Empfänger ( geben Sie \",\" ein, um E-Mails zu trennen )",
    it: "Destinatari di e-mail ( inserire \",\" per separare le e-mail )",
    nl: "Email ontvangers ( vul \",\" in om de emails te scheiden )"
  },
  BCC_EMAIL_ADDRESS: {
    en: "BCC email address ( enter \";\" to separate emails )",
    de: "BCC-E-Mail-Adresse ( geben Sie \";\" ein, um E-Mails zu trennen )",
    it: "Indirizzo e-mail BCC ( inserire \";\" per separare le e-mail )",
    nl: "BCC-e-mailadres ( voer \";\" in om e-mails te scheiden)"
  },
  EMAIL_SUBJECTS: {
    en: "Email subjects",
    de: "E-Mail-Betreff",
    it: "Soggetti dell'e-mail",
    nl: "E-mail onderwerpen"
  },
  EMAIL_CONTENT_FOR_FORM_SENDER: {
    en: "Email content for form sender",
    de: "E-Mail-Inhalt für Formularabsender",
    it: "Contenuto dell'e-mail per il mittente del modulo",
    nl: "Inhoud e-mail voor afzender formulier"
  },
  NOTE_EVERY_USER_WHO_PROVIDES: {
    en: "Note: every user who provides his/her email address will receive a copy of their submission.",
    de: "Hinweis: Jeder Benutzer, der seine E-Mail-Adresse angibt, erhält eine Kopie seines Beitrags.",
    it: "Nota: ogni utente che fornisce il suo indirizzo e-mail riceverà una copia della sua presentazione.",
    nl: "Opmerking: elke gebruiker die zijn e-mailadres opgeeft, ontvangt een kopie van zijn inzending."
  },
  TEXTFIELD: {
    en: "Textfield",
    de: "Textfeld",
    it: "Campo di testo",
    nl: "Tekstveld"
  },
  TEXTAREA: {
    en: "Text Area",
    de: "Textbereich",
    it: "Area di testo",
    nl: "Tekstgebied"
  },
  IMAGE_SITE_CONTENT: {
    en: "Image",
    de: "Bild",
    it: "Immagine",
    nl: "Afbeelding"
  },
  DATEFIELD: {
    en: "Datefield",
    de: "Datumsfeld",
    it: "Datefield",
    nl: "Dataveld"
  },
  DROPDOWN: {
    en: "Dropdown",
    de: "Auswahlliste",
    it: "Dropdown",
    nl: "Dropdown"
  },
  DEFAULT: {
    en: "Default",
    de: "Standard",
    it: "Predefinito",
    nl: "Standaard"
  },
  FORM_IS_EMPTY_UNABLE_TO_SUBMIT: {
    en: "Form is empty, unable to submit.",
    de: "Formular ist leer, kann nicht gesendet werden.",
    it: "Il modulo è vuoto, non può essere inviato.",
    nl: "Het formulier is leeg, niet in staat om in te dienen."
  },
  NO_CONTENT_FOUND_PLEASE_SELECT_A_COMPONENT: {
    en: "No content found, please select a component to edit first.",
    de: "Kein Inhalt gefunden, bitte wählen Sie zuerst eine Komponente zum Bearbeiten aus.",
    it: "Nessun contenuto trovato, selezionare prima un componente da modificare.",
    nl: "Geen inhoud gevonden, kies eerst een component om te bewerken."
  },
  THIS_BAR_IS_HIDDEN: {
    en: "This Bar is hidden and will not be shown in the site pages",
    de: "Diese Leiste ist versteckt und wird auf den Seiten der Website nicht angezeigt.",
    it: "Questa barra è nascosta e non sarà mostrata nelle pagine del sito",
    nl: "Deze balk is verborgen en wordt niet getoond op de site pagina's"
  },
  CHOOSE_ONE: {
    en: "Choose one",
    de: "Bitte eine Option auswählen",
    it: "Scegline uno",
    nl: "Kies er een"
  },
  HIDE_IN_LANGUAGE: {
    en: "Hide in language",
    de: "Verstecken in der Sprache",
    it: "Nascondersi nella lingua",
    nl: "Verbergen in taal"
  },
  ERROR_MESSAGE_SITE_CONTENT: {
    en: "Error message ( show if user did not fill in anything for this field )",
    de: "Fehlermeldung ( wird angezeigt, wenn der Benutzer für dieses Feld nichts ausgefüllt hat )",
    it: "Messaggio di errore ( mostra se l'utente non ha compilato nulla per questo campo )",
    nl: "Foutmelding ( laat zien of de gebruiker niets heeft ingevuld voor dit veld)"
  },
  IS_AN_EMAIL_TEXT_FIELD: {
    en: "Is an e-mail text field ( only one )",
    de: "Ist ein E-Mail-Textfeld ( nur eines )",
    it: "È un campo di testo e-mail (solo uno)",
    nl: "Is een e-mail tekstveld (slechts één)"
  },
  THIS_FIELD_NEEDS_TO_BE_FILLED: {
    en: "this field needs to be filled in and no space is allowed here.",
    de: "dieses Feld muss ausgefüllt werden, hier ist kein Leerzeichen erlaubt.",
    it: "questo campo deve essere compilato e qui non è consentito alcuno spazio.",
    nl: "dit veld moet worden ingevuld en er is hier geen ruimte toegestaan."
  },
  UPDATE_FORM: {
    en: "Update form",
    de: "Formular aktualisieren",
    it: "Modulo di aggiornamento",
    nl: "Update formulier"
  },
  DUPLICATE_FORM: {
    en: "Duplicate This Form",
    de: "Dieses Formular duplizieren",
    it: "Duplicare questo modulo",
    nl: "Dupliceer dit formulier"
  },
  DUPLICATE_FORM_CONFIRMATION: {
    en: "Do you want to duplicate this form?",
    de: "Möchten Sie dieses Formular vervielfältigen?",
    it: "Vuoi duplicare questo modulo?",
    nl: "Wilt u dit formulier dupliceren?"
  },
  UPDATE_FORM_SITE_CONTENT: {
    en: "Update Form",
    de: "Update-Formular",
    nl: "Formulier bijwerken",
    it: "Modulo di aggiornamento"
  },
  SELECT_FROM_TEMPLATE: {
    en: "Select from template",
    de: "Aus Vorlage auswählen",
    it: "Seleziona dal modello",
    nl: "Kies uit sjabloon"
  },
  BUTTON_TYPE: {
    en: "Button type",
    de: "Button Typ",
    it: "Tipo di pulsante",
    nl: "Type knop"
  },
  BUTTON_COLOR: {
    en: "Button color",
    de: "Farbe des Buttons",
    it: "Colore del pulsante",
    nl: "Knop kleur"
  },
  SELECT_FORM: {
    en: "Select Form",
    de: "Formular auswählen",
    it: "Selezionare il modulo",
    nl: "Selecteer formulier"
  },
  FORMS: {
    en: "Forms",
    de: "Formulare",
    it: "Moduli",
    nl: "Formulieren"
  },
  SELECT_SITE_FORM: {
    en: "Select site form",
    de: "Formular Standort auswählen",
    it: "Seleziona il modulo del sito",
    nl: "Selecteer site formulier"
  },
  LOADING_SITE_FORMS: {
    en: "Loading site forms...",
    de: "Laden von Seitenformularen...",
    nl: "Website formulieren laden...",
    it: "Caricamento moduli del sito..."
  },
  TEMPLATE_FORM: {
    en: "Template Form",
    de: "Vorlage Formular",
    it: "Forma del modello",
    nl: "Sjabloon formulier"
  },
  BASIC: {
    en: "Basic",
    de: "Grundlegend",
    it: "Base",
    nl: "Basis"
  },
  SLANTED: {
    en: "Slanted",
    de: "Schräg",
    it: "Inclinato",
    nl: "Schuin"
  },
  OUTLINED: {
    en: "Outlined",
    de: "Skizziert",
    it: "Delineato",
    nl: "Geschetst"
  },
  BORDER_TO_TOP_ANIMATION: {
    en: "Border to top animation",
    de: "Animation vom Rand nach oben",
    it: "Animazione dal bordo all'alto",
    nl: "Grens naar boven animatie"
  },
  BACKGROUND_SHRINKS_ANIMATION: {
    en: "Background shrinks animation",
    de: "Hintergrund schrumpft Animation",
    it: "L'animazione dello sfondo si restringe",
    nl: "Achtergrond krimpt animatie"
  },
  BACKGROUND_SPREAD_ANIMATION: {
    en: "Background spread animation",
    de: "Animation der Hintergrundausbreitung",
    it: "Animazione di diffusione dello sfondo",
    nl: "Achtergrond spreidingsanimatie"
  },
  OUTLINE_ANIMATION: {
    en: "Outline animation",
    de: "Animation skizzieren",
    it: "Animazione dei contorni",
    nl: "Schets animatie"
  },
  SLANTED_ANIMATION: {
    en: "Slanted animation",
    de: "Schräge Animation",
    it: "Animazione inclinata",
    nl: "Schuine animatie"
  },
  OUTLINE: {
    en: "Outline",
    de: "Gliederung",
    it: "Contorno",
    nl: "Schets"
  },
  BACKGROUND: {
    en: "Background",
    de: "Hintergrund",
    it: "Sfondo",
    nl: "Achtergrond"
  },
  BORDER: {
    en: "Border",
    de: "Umrandung",
    it: "Confine",
    nl: "Grens"
  },
  BUTTON_HOVER_COLOR: {
    en: "Button hover color",
    de: "Hover-Farbe des Buttons",
    it: "Colore del pulsante hover",
    nl: "Knop zweef kleur"
  },
  APPLY_PRIMARY_COLOR: {
    en: "Apply primary color",
    de: "Primärfarbe auftragen",
    it: "Applicare il colore primario",
    nl: "Breng primaire kleur aan"
  },
  APPLY_SECONDARY_COLOR: {
    en: "Apply secondary color",
    de: "Sekundärfarbe auftragen",
    it: "Applicare il colore secondario",
    nl: "Breng secundaire kleur aan"
  },
  APPLY_CUSTOM_COLOR: {
    en: "Apply custom color",
    de: "Benutzerdefinierte Farbe anwenden",
    it: "Applicare il colore personalizzato",
    nl: "Pas aangepaste kleur toe"
  },
  PLEASE_SELECT: {
    en: "Please select",
    de: "Bitte wählen Sie",
    it: "Si prega di selezionare",
    nl: "Selecteer a.u.b."
  },
  URL: {
    en: "URL",
    de: "URL",
    it: "URL",
    nl: "URL"
  },
  PAGE_TITLE: {
    en: "Page Title",
    de: "Seitentitel",
    it: "Titolo della pagina",
    nl: "Pagina titel"
  },
  MAXIMUM: {
    en: "maximum",
    de: "maximal",
    it: "massimo",
    nl: "maximum"
  },
  CHARACTERS: {
    en: "characters",
    de: "Zeichen",
    it: "personaggi",
    nl: "tekens"
  },
  SELECT_TEMPLATE: {
    en: "Select Template",
    de: "Vorlage auswählen",
    it: "Selezionare il modello",
    nl: "Selecteer Sjabloon"
  },
  SELECT_PAGE_TO_COPY: {
    en: "Select page to copy",
    de: "Zu kopierende Seite auswählen",
    it: "Seleziona la pagina da copiare",
    nl: "Selecteer pagina om te kopiëren"
  },
  CREATE_NEW_EMPTY_PAGE: {
    en: "Create New Empty Page",
    de: "Neue, leere Seite erstellen",
    it: "Creare una nuova pagina vuota",
    nl: "Nieuwe lege pagina maken"
  },
  SEARCH_BY_PAGE_NAME: {
    en: "Search By Page Name",
    de: "Suche nach Seitennamen",
    it: "Cerca per nome della pagina",
    nl: "Zoeken op paginanaam"
  },
  EDIT_PAGE_INFORMATION: {
    en: "Edit Page Information",
    de: "Seiteninformationen bearbeiten",
    it: "Modifica le informazioni della pagina",
    nl: "Pagina-informatie bewerken"
  },
  TABLET_VIEW: {
    en: "Tablet View",
    de: "Tablet-Ansicht",
    it: "Vista del tablet",
    nl: "Tabletweergave"
  },
  LANDING_PAGE_COUNTRIES_VALIDATION: {
    en: "At least one of this page's country needs to be included",
    de: "Mindestens eine dieser Länderseiten muss enthalten sein",
    it: "Almeno uno dei paesi di questa pagina deve essere incluso",
    nl: "Minstens één van de landen van deze pagina moet worden opgenomen"
  },
  LANDING_PAGE_CENTER_GROUP_VALIDATION: {
    en: "At least one of this page's center group needs to be included",
    de: "Mindestens eine der zentralen Gruppen dieser Seite muss enthalten sein.",
    it: "Almeno uno dei gruppi centrali di questa pagina deve essere incluso",
    nl: "Tenminste één van de middelste groep van deze pagina moet worden opgenomen"
  },
  CONFIRMATION: {
    en: "Confirmation",
    de: "Konfirmation",
    it: "Conferma",
    nl: "Bevestiging"
  },
  CAROUSEL_SETTINGS: {
    en: "Carousel Settings",
    de: "Karussell-Einstellungen",
    it: "Impostazioni del carosello",
    nl: "Carrousel Instellingen"
  },
  SHOW_CAROUSEL: {
    en: "Show carousel",
    de: "Karussell anzeigen",
    nl: "Toon carrousel",
    it: "Mostra carosello"
  },
  HIDE_CAROUSEL: {
    en: "Hide carousel",
    de: "Karussell ausblenden",
    nl: "Verberg carrousel",
    it: "Nascondi carosello"
  },
  EDITING_LANGUAGE: {
    en: "Current editing language",
    de: "Aktuelle Bearbeitungssprache",
    it: "Lingua di editing corrente",
    nl: "Huidige bewerkingstaal"
  },
  BACKGROUND_CONFIGURATION: {
    en: "Background Configuration",
    de: "Hintergrund Konfiguration",
    it: "Configurazione dello sfondo",
    nl: "Achtergrond Configuratie"
  },
  BACKGROUND_IMAGE_TITLE: {
    en: "Background Image Title",
    de: "Hintergrundbild Titel",
    it: "Titolo dell'immagine di sfondo",
    nl: "Titel achtergrondafbeelding"
  },
  BACKGROUND_IMAGE_ALT: {
    en: "Background Image Alt",
    de: "Hintergrundbild Alt",
    it: "Alt dell'immagine di sfondo",
    nl: "Alt achtergrondafbeelding"
  },
  HEADER_IMAGE_CONFIGURATION: {
    en: "Header Image Configuration",
    de: "Konfiguration des Kopfzeilenbildes",
    it: "Configurazione dell'immagine dell'intestazione",
    nl: "Koptekst afbeelding configuratie"
  },
  IMAGE_URL: {
    en: "Image URL",
    de: "Bild-URL",
    it: "URL dell'immagine",
    nl: "URL afbeelding"
  },
  SHOW_IMAGE: {
    en: "Show Image",
    de: "Bild anzeigen",
    it: "Mostra immagine",
    nl: "Toon afbeelding"
  },
  TEXT_CONFIGURATION: {
    en: "Text configuration",
    de: "Text-Konfiguration",
    it: "Configurazione del testo",
    nl: "Tekst configuratie"
  },
  SHOW_CAPTION: {
    en: "Show Caption",
    de: "Bildunterschrift anzeigen",
    it: "Mostra Didascalia",
    nl: "Toon bijschrift"
  },
  HIDE_CAPTION: {
    en: "Hide Caption",
    de: "Bildunterschrift ausblenden",
    it: "Nascondi Didascalia",
    nl: "Verberg Caption"
  },
  APPLY_SITE_FONT_FAMILY: {
    en: "Apply site font family",
    de: "Website-Schriftfamilie anwenden",
    it: "Applica la famiglia di caratteri del sito",
    nl: "Lettertypefamilie van site toepassen"
  },
  BUTTON_CONFIGURATION: {
    en: "Button Configuration",
    de: "Button Konfiguration",
    it: "Configurazione dei pulsanti",
    nl: "Knop Configuratie"
  },
  SHOW_BUTTON: {
    en: "Show button",
    de: "Button anzeigen",
    it: "Mostra pulsante",
    nl: "Toon knop"
  },
  ADD_BUTTON: {
    en: "Add Button",
    de: "Button hinzufügen",
    it: "Aggiungi pulsante",
    nl: "Knop toevoegen"
  },
  ADD_SLIDE: {
    en: "Add slide",
    de: "Folie hinzufügen",
    it: "Aggiungere la diapositiva",
    nl: "Dia toevoegen"
  },
  CAROUSEL_MIN_LIMIT_ERROR_MSG: {
    en: "There has to be at least 1 slide in carousel",
    de: "Es muss mindestens 1 Slide im Karussell vorhanden sein",
    it: "Ci deve essere almeno 1 diapositiva in carosello",
    nl: "Er moet minstens 1 dia in carrousel zijn"
  },
  SLIDE_ADDED: {
    en: "Slide added",
    de: "Slide hinzugefügt",
    it: "Slide aggiunte",
    nl: "Dia toegevoegd"
  },
  CONFIRM_REMOVE_HEADER_CAROUSEL: {
    en: "Are you sure to remove the header carousel from this page? This action cannot be undone.",
    de: "Sind Sie sicher, dass Sie das Kopfzeilenkarussell von dieser Seite entfernen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
    it: "Sei sicuro di voler rimuovere il carosello dell'intestazione da questa pagina? Questa azione non può essere annullata.",
    nl: "Weet u zeker dat u de headercarrousel van deze pagina wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt."
  },
  CREATED_TEXT: {
    en: "Double click here to edit the text",
    de: "Doppelclick hier zum editieren des Textes",
    it: "Doppio clic qui per modificare il testo",
    nl: "Dubbelklik hier om de tekst te bewerken"
  },
  SAMPLE_TITLE_PRODUCT: {
    en: "Sample title product",
    de: "Mustertitel Produkt",
    it: "Prodotto campione del titolo",
    nl: "Voorbeeld titel product"
  },
  EDIT_ORDER_DATA: {
    en: "Edit order data",
    de: "Bestelldaten bearbeiten",
    it: "Modifica dei dati dell'ordine",
    nl: "Wijzig ordergegevens"
  },
  EDIT_CONTENT_HERE: {
    en: "Edit content here",
    de: "Inhalt hier bearbeiten",
    it: "Modifica il contenuto qui",
    nl: "Wijzig hier de inhoud"
  },
  UPDATE_PAGE: {
    en: "Update Page",
    de: "Seite aktualisieren",
    it: "Pagina di aggiornamento",
    nl: "Pagina bijwerken"
  },
  FOLLOWING_ARE_PLACEHOLDERS_AVAILABLE_FOR: {
    en: "Following are placeholders for ",
    de: "Nachfolgend finden Sie Platzhalter für ",
    it: "Di seguito sono riportati i segnaposto per ",
    nl: "Hieronder staan plaatshouders voor "
  },
  TITLE_MAX_CHARACTERS: {
    en: "( maximum 40 characters )",
    de: "( maximal 40 Zeichen )",
    it: "( massimo 40 caratteri )",
    nl: "( maximaal 40 tekens )"
  },
  DESCRIPTION_MAX_CHARACTERS: {
    en: "( maximum 110 characters )",
    de: "( maximal 110 Zeichen )",
    it: "( massimo 110 caratteri )",
    nl: "( maximaal 110 tekens )"
  },
  REMAINING_CHARACTERS: {
    en: "Remaining Characters:",
    de: "Verbleibende Zeichen:",
    it: "Personaggi rimanenti:",
    nl: "Overblijvende tekens:"
  },
  LANDING_PAGE_FOR: {
    en: "This landing page is created for : ",
    de: "Diese Landing Page ist erstellt für : ",
    it: "Questa landing page è stata creata per : ",
    nl: "Deze landingspagina is gemaakt voor : "
  },
  FORM_IS_SUCCESSFULLY_CREATED: {
    en: "Form is successfully created",
    de: "Das Formular wurde erfolgreich erstellt",
    it: "Il modulo è stato creato con successo",
    nl: "Formulier is succesvol aangemaakt"
  },
  FORM_IS_SUCCESSFULLY_UPDATED: {
    en: "Form is successfully updated",
    de: "Das Formular wurde erfolgreich aktualisiert",
    it: "Il modulo è stato aggiornato con successo",
    nl: "Formulier is succesvol bijgewerkt"
  },
  FORM_IS_SUCCESSFULLY_REMOVED: {
    en: "Form is successfully removed",
    de: "Das Formular wurde erfolgreich entfernt",
    it: "Il modulo è stato rimosso con successo",
    nl: "Formulier is succesvol verwijderd"
  },
  FORM_IS_SUCCESSFULLY_SUBMITTED: {
    en: "Form is successfully submitted",
    de: "Das Formular wurde erfolgreich übermittelt",
    it: "Il modulo è stato inviato con successo",
    nl: "Formulier is succesvol ingediend"
  },
  DESIGNER: {
    en: "Designer",
    de: "Designer",
    it: "Designer",
    nl: "Designer"
  },
  PRODUCTION: {
    en: "Production",
    de: "Production",
    it: "Production",
    nl: "Production"
  },
  EXTERNAL_GRAPHIC_DESIGNER: {
    en: "External Graphic Designer",
    de: "externer Grafiker",
    it: "Designer grafico esterno",
    nl: "Externe grafisch ontwerper"
  },
  BLANK_SECTION: {
    en: "Blank Section",
    de: "Leerer Abschnitt",
    it: "Sezione vuota",
    nl: "Blanco Sectie"
  },
  CAROUSEL: {
    en: "Carousel",
    de: "Karussell",
    it: "Carosello",
    nl: "Carrousel"
  },
  EDIT_BLANK_SECTION: {
    en: "Edit Blank Section",
    de: "Leeren Abschnitt bearbeiten",
    it: "Modifica della sezione vuota",
    nl: "Bewerk Blanco Sectie"
  },
  AT_LEAST_ONE_COMPONENT_NEEDED: {
    en: "At least one component is needed for the section",
    de: "Für den Abschnitt wird mindestens eine Komponente benötigt",
    it: "Almeno un componente è necessario per la sezione",
    nl: "Ten minste één onderdeel is nodig voor de sectie"
  },
  DUPLICATE_PAGE: {
    en: "Duplicate Page",
    de: "Seite duplizieren",
    it: "Pagina duplicata",
    nl: "Dubbele pagina"
  },
  ACCORDION_SETTING: {
    en: "Accordion Setting",
    de: "Akkordeon Einstellung",
    it: "Impostazione della fisarmonica",
    nl: "Accordeon Instelling"
  },
  ROW: {
    en: "Row",
    de: "Zeile",
    it: "Fila",
    nl: "Rij"
  },
  STOP_ROW_REARRANGEMENT: {
    en: "Stop Row Rearrangement",
    de: "Umsortierung stoppen",
    it: "Fermare il riordino delle righe",
    nl: "Stop Rij herschikking"
  },
  REARRANGE_ROW: {
    en: "Rearrange Row",
    de: "Zeilen neu anordnen",
    it: "Riorganizzare la fila",
    nl: "Rij herschikken"
  },
  REMOVE_CURRENT_ROW: {
    en: "Remove Current Row",
    de: "Aktuelle Zeile entfernen",
    it: "Rimuovi la riga corrente",
    nl: "Huidige rij verwijderen"
  },
  DEFAULT_OPEN: {
    en: "Default Open",
    de: "Standardmäßig geöffnet",
    nl: "Standaard open",
    it: "Aperto di default"
  },
  DEFAULT_CLOSE: {
    en: "Default Close",
    de: "Standardmäßig schließen",
    nl: "Standaard sluiten",
    it: "Chiusura predefinita"
  },
  SELECT_A_TEMPLATE: {
    en: "Select a template",
    de: "Wählen Sie eine Vorlage",
    it: "Seleziona un modello",
    nl: "Kies een sjabloon"
  },
  SELECT_SECTION_TOAST_MESSAGE: {
    en: "Please select 1 section to add",
    de: "Bitte wählen Sie 1 Abschnitt zum Hinzufügen",
    it: "Seleziona 1 sezione da aggiungere",
    nl: "Selecteer 1 sectie om toe te voegen"
  },
  BACKGROUND_IMAGE_POSITION: {
    en: "Background Image Position",
    de: "Hintergrund Positionieren",
    it: "Posizione dell'immagine di sfondo",
    nl: "Positie achtergrondafbeelding"
  },
  HEADER_LOGO_IMAGE: {
    en: "Header logo image",
    de: "Header-Logo-Bild",
    it: "Immagine del logo dell'intestazione",
    nl: "Header logo afbeelding"
  },
  MAKE_CAPTION_CENTER: {
    en: "Make Caption Center",
    de: "Beschriftungs in die Mitte rücken",
    it: "Crea un centro didascalie",
    nl: "Maak bijschrift centrum"
  },
  IMAGE_POSITION_ADJUSTMENT: {
    en: "Image Position Adjustment",
    de: "Einstellung der Bildposition",
    it: "Regolazione della posizione dell'immagine",
    nl: "Aanpassing beeldpositie"
  },
  VERTICAL: {
    en: "Vertical",
    de: "Vertikal",
    it: "Verticale",
    nl: "Verticaal"
  },
  HORIZONTAL: {
    en: "Horizontal",
    de: "Horizontal",
    it: "Orizzontale",
    nl: "Horizontaal"
  },
  IMAGE_SIZE: {
    en: "Image Size",
    de: "Bildgröße",
    it: "Dimensione dell'immagine",
    nl: "Beeldgrootte"
  },
  NOT_SET: {
    en: "Not Set",
    de: "Nicht festgelegt",
    it: "Non impostato",
    nl: "Niet ingesteld"
  },
  COVER_WHOLE_CONTAINER: {
    en: "Cover Whole Container",
    de: "Abdeckung ganzer Container",
    it: "Coprire l'intero contenitore",
    nl: "Bedek de hele container"
  },
  SHOW_WHOLE_IMAGE: {
    en: "Show Whole Image",
    de: "Ganzes Bild anzeigen",
    it: "Mostra l'intera immagine",
    nl: "Het hele beeld tonen"
  },
  CUSTOM_SIZE: {
    en: "Custom Size",
    de: "Benutzerdefinierte Größe",
    it: "Dimensione personalizzata",
    nl: "Aangepaste grootte"
  },
  UTILITY: {
    en: "Utility",
    de: "Folie",
    it: "Utilità",
    nl: "Nutsvoorziening"
  },
  REMOVE_SLIDE: {
    en: "Remove this slide",
    de: "Diese Folie entfernen",
    it: "Rimuovere questa diapositiva",
    nl: "Verwijder deze dia"
  },
  CAROUSEL_IMAGE_BLOCK_SETTING: {
    en: "Carousel Image Block Setting",
    de: "Karussellbild-Blockeinstellung",
    it: "Impostazione del blocco immagine del carosello",
    nl: "Carrousel afbeeldingsblok instellen"
  },
  IMAGE_SOURCE: {
    en: "Image Source",
    de: "Bildquelle",
    it: "Fonte dell'immagine",
    nl: "Beeld Bron"
  },
  ADD_SOURCE: {
    en: "Add Source",
    de: "Quelle hinzufügen",
    it: "Aggiungi fonte",
    nl: "Bron toevoegen"
  },
  CONTAINER_SETTINGS: {
    en: "Container Settings",
    de: "Container-Einstellungen",
    it: "Impostazioni del contenitore",
    nl: "Container Instellingen"
  },
  FADE_IN_FROM: {
    en: "Fade in from",
    de: "Animation von",
    it: "Dissolvenza da",
    nl: "Infaden van"
  },
  HEADING_SETTING: {
    en: "Heading Setting",
    de: "Einstellung der Überschrift",
    it: "Impostazione dell'intestazione",
    nl: "Rubriek Instelling"
  },
  USE_GENERAL_H1: {
    en: "Use General H1 FontSize",
    de: "General H1 FontSize verwenden",
    it: "Usare H1 FontSize generale",
    nl: "Gebruik Algemeen H1 Lettergrootte"
  },
  HEADING_PATTERN: {
    en: "Heading Pattern",
    de: "Überschrift Muster",
    it: "Modello di intestazione",
    nl: "Rubriek Patroon"
  },
  STYLE_A: {
    en: "Style A",
    de: "Stil A",
    it: "Stile A",
    nl: "Stijl A"
  },
  STYLE_B: {
    en: "Style B",
    de: "Stil B",
    it: "Stile B",
    nl: "Stijl B"
  },
  STYLE_C: {
    en: "Style C",
    de: "Stil C",
    it: "Stile C",
    nl: "Stijl C"
  },
  SAVE_IMAGE: {
    en: "Save Current Editing Image",
    de: "Aktuelles Bearbeitungsbild speichern",
    it: "Salvare l'immagine corrente di modifica",
    nl: "Huidige bewerkingsafbeelding opslaan"
  },
  OTHER: {
    en: "Other",
    de: "Andere",
    it: "Altro",
    nl: "Andere"
  },
  DUPLICATE_CONTAINER: {
    en: "Duplicate container",
    de: "Abschnitt duplizieren",
    it: "Contenitore duplicato",
    nl: "Dubbele container"
  },
  DUPLICATE_SELECTED_CONTAINER: {
    en: "Duplicate selected container?",
    de: "Ausgewählten Abschnitt duplizieren?",
    it: "Duplicare il contenitore selezionato?",
    nl: "Geselecteerde container dupliceren?"
  },
  EVERY: {
    en: "Every",
    de: "Jeden",
    nl: "Ogni",
    it: "Elke"
  },
  HAS_BEEN_SET_INTO_INACTIVE: {
    en: "was cancelled/set to inactive",
    de: "wurde gekündigt/auf inaktive gesetzt",
    it: "è stato chiuso/impostato su inattivo",
    nl: "werd beëindigd/op inactief gezet"
  },
  WAS_ACTIVATED: {
    en: "was activated",
    de: "wurde aktiviert",
    it: "è stato attivato",
    nl: "werd geactiveerd"
  },
  LOGIN_WEBSITE: {
    en: "Login website",
    de: "Login Website",
    it: "Sito web di accesso",
    nl: "Inloggen website"
  },
  LOGIN_COCKPIT: {
    en: "Login Cockpit",
    de: "Login Cockpit",
    it: "Accesso Cockpit",
    nl: "Inloggen Cockpit"
  },
  COPY_PAGE_FROM_ANOTHER_FORM: {
    en: "Copy page from another form",
    de: "Seite aus einem anderen Formular kopieren",
    it: "Copiare la pagina da un altro modulo",
    nl: "Kopieer pagina van een ander formulier"
  },
  START_AND_END_DATE: {
    en: "Start/End Date (Export Excel)",
    de: "Start/Enddatum (Excel exportieren)",
    it: "Data di inizio/fine (Esportazione Excel)",
    nl: "Start/Eind Datum (Excel exporteren)"
  },
  OLS_CAMPAIGN_UPDATE_UNSUCCESS: {
    en: "OLS campaign is updated unsuccessfully.",
    de: "Die OLS-Kampagne wird erfolglos aktualisiert.",
    it: "La campagna OLS viene aggiornata senza successo.",
    nl: "OLS campagne is zonder succes bijgewerkt."
  },
  MAX_NO_ONLINE: {
    en: "Max No. Online",
    de: "Maximalzahl online",
    it: "Numero massimo online",
    nl: "Maximum aantal online"
  },
  HEADLINE: {
    en: "Headline",
    de: "Überschrift",
    it: "Titolo",
    nl: "Kop"
  },
  DAYS: {
    en: "days",
    de: "Tage",
    it: "giorni",
    nl: "dagen"
  },
  HOURS: {
    en: "hours",
    de: "Stunden",
    it: "orari",
    nl: "uren"
  },
  MINUTES: {
    en: "minutes",
    de: "Minuten",
    it: "minuti",
    nl: "notulen"
  },
  SECONDS: {
    en: "seconds",
    de: "Sekunden",
    it: "secondi",
    nl: "seconden"
  },
  TEXT_BEFORE_COUNTDOWN: {
    en: "Section is hidden until countdown starts",
    de: "Abschnitt ist ausgeblendet, bis der Countdown beginnt",
    it: "La sezione è nascosta fino all'inizio del conto alla rovescia",
    nl: "Sectie is verborgen totdat het aftellen begint"
  },
  TEXT_AFTER_COUNTDOWN: {
    en: "The countdown has already expired",
    de: "Der Countdown ist bereits abgelaufen",
    it: "Il conto alla rovescia è già scaduto",
    nl: "Het aftellen is al voorbij"
  },
  PLEASE_SELECT_A_DATE_TIME_LATER_THAN_START_DATE: {
    en: "Please select a date time later than start date",
    de: "Bitte wählen Sie ein Datum, das später als das Startdatum liegt",
    it: "Selezionare una data successiva alla data di inizio",
    nl: "Gelieve een datum te kiezen die later valt dan de startdatum"
  },
  SETTINGS: {
    en: "Settings",
    de: "Einstellungen",
    it: "Impostazioni",
    nl: "Instellingen"
  },
  COUNTDOWN_COLOR: {
    en: "Countdown Color",
    de: "Countdown Farbe",
    it: "Conto alla rovescia a colori",
    nl: "Aftel Kleur"
  },
  NO_OF_CHARACTERS: {
    en: "Number of characters",
    de: "Anzahl der Zeichen",
    it: "Numero di caratteri",
    nl: "Aantal tekens"
  },
  ROUND_BUTTON: {
    en: "Round Button",
    de: "Runder Knopf",
    it: "Pulsante rotondo",
    nl: "Ronde knop"
  },
  CUSTOM: {
    en: "Custom",
    de: "Benutzerdefiniert",
    it: "Personalizzato",
    nl: "Custom"
  },
  CLICK_HERE_TO_ENABLE_EDITOR: {
    en: "Click Here To Enable Editor",
    de: "Hier klicken, um den Editor zu aktivieren",
    it: "Fare clic qui per attivare l'editor",
    nl: "Klik hier om de editor in te schakelen"
  },
  STYLE: {
    en: "Style",
    de: "Style",
    nl: "Stijl",
    it: "Stile"
  },
  SOURCE: {
    en: "Source",
    de: "Quelle",
    it: "Fonte",
    nl: "Bron"
  },
  ALL: {
    en: "All",
    de: "Alle",
    it: "Tutti",
    nl: "Alle"
  },
  ORDER_NUMBER: {
    en: "Order Number",
    de: "Bestellnummer",
    it: "Numero d'ordine",
    nl: "Bestelnummer"
  },
  ENTER_ORDER_NUM: {
    en: "Enter order number",
    de: "Bestellnummer eingeben",
    it: "Inserire il numero d'ordine",
    nl: "Voer bestelnummer in"
  },
  FORM_SELECTOR: {
    en: "Form Selector",
    de: "Formular-Selektor",
    it: "Selettore di forme",
    nl: "Formulier kiezer"
  },
  ANCHOR_PATH: {
    en: "Anchor Path",
    de: "Ankerpfad",
    it: "Percorso di ancoraggio",
    nl: "Ankerpad"
  },
  GENERATE_ANCHOR_PATH: {
    en: "Generate Anchor Path based on name provided",
    de: "Ankerpfad anhand des angegebenen Namens generieren",
    it: "Genera il percorso di ancoraggio in base al nome fornito",
    nl: "Genereer Ankerpad gebaseerd op opgegeven naam"
  },
  ANCHOR_PATH_NOTE: {
    en: "NOTE: Anchor Path should be unique, in lower case, do not contain space between text, and start with alphabetical character",
    de: "HINWEIS: Der Ankerpfad sollte eindeutig sein, in Kleinbuchstaben geschrieben werden, keine Leerzeichen zwischen dem Text enthalten und mit einem alphabetischen Zeichen beginnen.",
    it: "NOTA: Il percorso di ancoraggio deve essere unico, in minuscolo, non deve contenere spazi tra i testi e deve iniziare con un carattere alfabetico.",
    nl: "OPMERKING: Het ankerpad moet uniek zijn, in kleine letters, zonder spaties tussen de tekst, en beginnen met een alfabetisch teken."
  },
  UPDATE_ANCHOR_LINK: {
    en: "Update Anchor Link",
    de: "Anker-Link aktualisieren",
    it: "Aggiornare il link di ancoraggio",
    nl: "Ankerlink bijwerken"
  },
  SET_ANCHOR_LINK: {
    en: "Set Anchor Link",
    de: "Anker-Link setzen",
    it: "Impostare il link di ancoraggio",
    nl: "Ankerlink instellen"
  },
  REMOVE_ANCHOR_LINK: {
    en: "Remove Anchor Link",
    de: "Anker-Link entfernen",
    it: "Rimuovere il collegamento di ancoraggio",
    nl: "Ankerlink verwijderen"
  },
  ANCHOR_PATH_FIRST_CHAR_ERROR: {
    en: "Anchor Path must start with alphabetical characters",
    de: "Der Ankerpfad muss mit alphabetischen Zeichen beginnen",
    it: "Il percorso di ancoraggio deve iniziare con caratteri alfabetici.",
    nl: "Ankerpad moet beginnen met alfabetische tekens"
  },
  ANCHOR_PATH_SYMBOL_ERROR: {
    en: "Anchor Path must not contain any symbols or space except for underscore",
    de: "Der Ankerpfad darf keine Symbole oder Leerzeichen außer einem Unterstrich enthalten",
    it: "Il percorso di ancoraggio non deve contenere simboli o spazi, tranne il trattino basso.",
    nl: "Ankerpad mag geen symbolen of spaties bevatten, behalve underscore"
  },
  ANCHOR_PATH_UPPERCASE_ERROR: {
    en: "Anchor Path should contains only lower case alphabetical characters",
    de: "Der Ankerpfad sollte nur klein geschriebene Buchstaben enthalten.",
    it: "Il percorso di ancoraggio deve contenere solo caratteri alfabetici minuscoli.",
    nl: "Het ankerpad mag alleen kleine letters bevatten."
  },
  CREATE_ANCHOR_LINK_SUCCESFULLY: {
    en: "Create Anchor Link Successfully",
    de: "Anchor Link erfolgreich erstellen",
    it: "Creare un link di ancoraggio di successo",
    nl: "Succesvol ankerlink maken"
  },
  UPDATE_ANCHOR_LINK_SUCCESFULLY: {
    en: "Update Anchor Link Successfully",
    de: "Anchor Link erfolgreich aktualisieren",
    it: "Aggiornare il link di ancoraggio con successo",
    nl: "Ankerlink succesvol bijwerken"
  },
  REMOVE_ANCHOR_LINK_SUCCESSFULLY: {
    en: "Remove Anchor Link Successfully",
    de: "Erfolgreich Ankerlink entfernen",
    it: "Rimuovere con successo il link di ancoraggio",
    nl: "Ankerlink succesvol verwijderen"
  },
  ENSURE_ANCHOR_LINK_FULFIL_REQUIREMENT: {
    en: "Please ensure all information of Anchor Link is filled correctly and fulfil the requirement",
    de: "Bitte stellen Sie sicher, dass alle Informationen von Anchor Link korrekt ausgefüllt sind und die Anforderungen erfüllen",
    it: "Assicuratevi che tutte le informazioni dell'Anchor Link siano compilate correttamente e che soddisfino i requisiti richiesti.",
    nl: "Zorg ervoor dat alle informatie van Anchor Link correct is ingevuld en aan de vereisten voldoet."
  },
  ANCHOR_LINK: {
    en: "Anchor link",
    de: "Anker-Link",
    nl: "Ankerlink",
    it: "Ancoraggio"
  },
  PR_NUMBER: {
    en: "PR Number",
    de: "PR-Nummer",
    it: "Numero PR",
    nl: "PR Nummer"
  },
  MAIN_FOCUS: {
    en: "Main Focus",
    de: "Schwerpunkte",
    nl: "Belangrijkste focus",
    it: "Focus principale"
  },
  TYPE_OF_SPORT: {
    en: "Type of Sport",
    de: "Sportarten",
    nl: "Type sport",
    it: "Tipo di sport"
  },
  SEARCH_RESULT_FOR: {
    en: "Search result for",
    de: "Suchergebnis für",
    nl: "Zoekresultaat voor",
    it: "Risultato della ricerca per"
  },
  TEAM_SETTINGS: {
    en: "Team Settings",
    de: "Team-Einstellungen",
    nl: "Team Instellingen",
    it: "Impostazioni del team"
  },
  DEPARTMENT_TITLE: {
    en: "Department Title",
    de: "Abteilung Titel",
    it: "Titolo del dipartimento",
    nl: "Afdeling Titel"
  },
  SLOGAN: {
    en: "Slogan",
    de: "Slogan",
    nl: "Slogan",
    it: "Slogan"
  },
  CARD: {
    en: "Card",
    de: "Karte",
    nl: "Kaart",
    it: "Scheda"
  },
  CARD_BUTTON: {
    en: "Card Button",
    de: "Karte Taste",
    nl: "Kaart knop",
    it: "Pulsante della scheda"
  },
  REMOVE_IMAGE: {
    en: "Remove Image",
    de: "Bild entfernen",
    nl: "Afbeelding verwijderen",
    it: "Rimuovi immagine"
  },
  BUTTON_SETTINGS: {
    en: "Button settings",
    de: "Button Einstellungen",
    it: "Button Impostazioni",
    nl: "Button Instellingen"
  },
  THE_SLIDER_IS_HIDDEN_YOU_CAN_REACTIVATE_IT_IN_THIS_SECTION_OPTIONS: {
    en: "This Slider is hidden, you can reactivate it in this sections options",
    de: "Dieser Slieder wird nicht angezeigt, Sie können ihn in den Optionen dieser Sektion reaktivieren",
    it: "Questo cursore è nascosto, si può riattivare nelle opzioni di questa sezione",
    nl: "Deze schuifregelaar is verborgen, u kunt hem reactiveren in deze secties opties"
  },
  BULLET_POINT_COLOR: {
    en: "Bullet Point's Color",
    de: "Farbe des Aufzählungspunktes",
    it: "Colore del bullet point",
    nl: "Bullet Point's Kleur"
  },
  BULLET_POINT_ICON: {
    en: "Bullet Point's Icon",
    de: "Bullet Point's Symbol",
    it: "Icona di Bullet Point",
    nl: "Bullet Point's Icoon"
  },
  SELECT_ONE: {
    en: "Please select one from below",
    de: "Wählen Sie eine von unten",
    it: "Per favore uno dal basso",
    nl: "Selecteer een van de onderstaande opties"
  },
  WHITE: {
    en: "White",
    de: "Weiß",
    it: "Colore bianco",
    nl: "Wit"
  },
  BLACK: {
    en: "Black",
    de: "Schwarz",
    it: "Colore nero",
    nl: "Zwart"
  },
  BULLET_POINT: {
    en: "Bullet Point",
    de: "Aufzählungspunktes",
    it: "Punto di riferimento",
    nl: "Kogel punt"
  },
  ARROW_RIGHT_LONG: {
    en: "Arrow Right Long",
    de: "Pfeil rechts lang",
    it: "Freccia destra lunga",
    nl: "Pijl rechts lang"
  },
  RIGHT_LONG: {
    en: "Right Long",
    de: "Rechts Lang",
    it: "Lungo a destra",
    nl: "Right Long"
  },
  PRIMARY_COLOR: {
    en: "Primary Color",
    de: "Primärfarbe",
    it: "Colore primario",
    nl: "Primaire kleur"
  },
  SECONDARY_COLOR: {
    en: "Secondary Color",
    de: "Sekundäre Farbe",
    it: "Colore secondario",
    nl: "Secundaire kleur"
  },
  ASSIGN_PDF: {
    en: "Assign PDF",
    de: "PDF zuweisen",
    it: "Assegnare PDF",
    nl: "PDF toewijzen"
  },
  ADD_PDF_FILE: {
    en: "Please add PDF file in section editor",
    de: "Bitte fügen Sie eine PDF-Datei im Editor hinzu",
    it: "Aggiungere il file PDF nell'editor di sezione",
    nl: "Voeg PDF bestand toe in de sectie editor"
  },
  PLEASE_SELECT_PDF_FILE: {
    en: "Please select a pdf file",
    de: "Bitte wählen Sie eine pdf-Datei",
    it: "Selezionare un file pdf",
    nl: "Selecteer een pdf-bestand"
  },
  SUPPORTED_FILE_TYPES: {
    en: "Supported file types",
    de: "Unterstützter Dateityp",
    it: "Tipo di file supportato",
    nl: "Ondersteund bestandstype"
  },
  QM_COMPLETED: {
    en: "QM Completed",
    de: "QM Abgeschlossen",
    it: "QM completato",
    nl: "QM Voltooid"
  },
  EMAIL_SERVICE: {
    en: "Email Service",
    de: "E-Mail-Service",
    it: "Servizio e-mail",
    nl: "Email service"
  },
  EMAIL_TEMPLATE_EDITOR: {
    en: "Email Template Editor",
    de: "E-Mail-Vorlagen-Editor",
    it: "Editor di modelli di e-mail",
    nl: "Email Template Editor"
  },
  EMAIL_TEMPLATE: {
    en: "Email Template",
    de: "E-Mail-Vorlage",
    it: "Modello di posta elettronica",
    nl: "Email sjabloon"
  },
  EMAIL_TEMPLATE_MSG: {
    en: "Please put the text {{template \"content\" .}} in order for the system to embed the content of the system email.",
    de: "Bitte geben Sie den Text {{template \"content\" .}} ein, damit das System den Inhalt der System-E-Mail einbetten kann.",
    it: "Per favore, metti il testo {{template \"content\" .}} affinché il sistema incorpori il contenuto dell'email del sistema.",
    nl: "Zet a.u.b. de tekst {{template \"content\" .}} om het systeem de inhoud van de systeem e-mail te laten insluiten."
  },
  EMAIL_TEMPLATE_UPDATE_SUCCESS: {
    en: "Email template is updated successfully.",
    de: "Aktualisieren der E-Mail-Vorlage erfolgreich.",
    it: "Aggiorna correttamente il modello di email.",
    nl: "Update e-mailsjabloon succesvol."
  },
  PROGRAM_DURATION: {
    en: "Program duration",
    de: "Dauer des Programms",
    it: "Durata del programma",
    nl: "Duur van het programma"
  },
  HIDE_FOOTER_FOR_LANDING_PAGE_CREATED: {
    en: "Hide footer for landing page created",
    de: "Fußzeile für Landing Page ausblenden erstellt",
    it: "Nascondi piè di pagina per la pagina di destinazione creata",
    nl: "Hide footer voor landingspagina gemaakt"
  },
  EDIT_CONTENT: {
    en: "Edit content",
    de: "Inhalt bearbeiten",
    it: "Modifica del contenuto",
    nl: "Inhoud bewerken"
  },
  PLEASE_ADD_OR_SELECT_A_CONTENT_TO_START_EDITING: {
    en: "Please add or select a content to start editing",
    de: "Bitte fügen Sie einen Inhalt hinzu oder wählen Sie einen aus, um mit der Bearbeitung zu beginnen",
    it: "Aggiungere o selezionare un contenuto per iniziare a modificarlo",
    nl: "Voeg een inhoud toe of selecteer een inhoud om te beginnen met bewerken"
  },
  TOTAL: {
    en: "Total",
    de: "Insgesamt",
    it: "Totale",
    nl: "Totaal"
  },
  BLANK_SECTION_MESSAGE: {
    en: "This blank section is here so that you can create new content section.",
    de: "Dieser leere Bereich dient dazu, einen neuen Inhaltsbereich zu erstellen.",
    it: "Questa sezione vuota è qui in modo da poter creare nuove sezioni di contenuto.",
    nl: "Deze lege sectie is hier zodat u een nieuwe inhoud sectie kunt maken."
  },
  ARCHIVED_MAILING_TEMPLATES: {
    en: "Archived mailing templates",
    de: "Archivierte Mailingvorlagen",
    it: "Modelli di mailing archiviati",
    nl: "Gearchiveerde mailing templates"
  },
  VALIDATION: {
    en: "Validation",
    de: "Plichtfeld",
    it: "Validazione",
    nl: "Validatie"
  },
  ERROR_MESSAGE_LABEL: {
    en: "Error message",
    de: "Fehlermeldung",
    it: "Messaggio di errore",
    nl: "Foutmelding"
  },
  DISCOUNT: {
    en: "Discount",
    de: "Ermäßigung",
    it: "Sconto",
    nl: "Korting"
  },
  USERS: {
    en: "Users",
    de: "Benutzer",
    it: "Utenti",
    nl: "Gebruikers"
  },
  CLIENT_SETTING: {
    en: "Client Setting",
    de: "Client-Einstellung",
    it: "Impostazione del cliente",
    nl: "Klantinstelling"
  },
  ADMIN_SETTING: {
    en: "Admin Setting",
    de: "Einstellung der Verwaltung",
    it: "Impostazione dell'amministratore",
    nl: "Admin-instelling"
  },
  LEADCENTER_SUBJECT_STUDIO_FINDER: {
    en: "Leadcenter Subject Studio Finder",
    de: "Leadcenter Betreff Studiofinder",
    it: "Leadcenter Trova Studio Soggetto",
    nl: "Leadcenter Onderwerp Studio Finder"
  },
  LEADCENTER_SUBJECT_FRIENDS: {
    en: "Leadcenter Subject Friends",
    de: "Leadcenter Betreff Freunde",
    it: "Leadcenter Trova Studio Soggetto",
    nl: "Leadcenter Soggetto Amici"
  },
  ROOT_NUMBER: {
    en: "Client number",
    de: "Stammnummer",
    it: "Numero cliente",
    nl: "Klantennummer"
  },
  SHOW_COOKIE_BANNER: {
    en: "Show cookie banner",
    de: "Cookie-Banner anzeigen",
    it: "Mostra banner dei cookie",
    nl: "Toon cookiebanner"
  },
  SHOW_CONTENT_OVERLAY: {
    en: "Show content overlay",
    de: "Inhaltsüberlagerung anzeigen",
    it: "Mostra sovrapposizione contenuto",
    nl: "Toon inhoudsoverlay"
  },
  SHOW_DATA_PROTECTION_AGREEMENT: {
    en: "Show data protection agreement",
    de: "Datenschutzvereinbarung anzeigen",
    it: "Mostra accordo sulla protezione dei dati",
    nl: "Toon privacyovereenkomst"
  },
  GDPR_SETTINGS: {
    en: "GDPR settings",
    de: "GDPR-Einstellungen",
    it: "Impostazioni GDPR",
    nl: "GDPR-instellingen"
  },
  ACTIVE_SITES: {
    en: "Active sites",
    de: "Aktive Seiten",
    it: "Siti attivi",
    nl: "Actieve sites"
  },
  TEST_SITES: {
    en: "Test sites",
    de: "Test Seiten",
    it: "Siti di prova",
    nl: "Testlocaties"
  },
  CANCELLED_SITES: {
    en: "Cancelled sites",
    de: "Gekündigte Seiten",
    it: "Siti cancellati",
    nl: "Geannuleerde locaties"
  },
  ACTIVE_CENTERS: {
    en: "Active centers",
    de: "Aktive Center",
    it: "Centri attivi",
    nl: "Actieve centra"
  },
  INACTIVE_CENTERS: {
    en: "Inactive centers",
    de: "Inaktive Center",
    it: "Centri inattivi",
    nl: "Inactieve centra"
  },
  LAST_4_WEEKS: {
    en: 'Last 4 weeks',
    de: 'Letzte 4 Wochen',
    it: 'Ultime 4 settimane',
    nl: 'Laatste 4 weken'
  },
  CURRENT_MONTH: {
    en: 'Current month',
    de: 'Aktueller Monat',
    it: 'Mese corrente',
    nl: 'Huidige maand'
  },
  PREVIOUS_MONTH: {
    en: 'Previous month',
    de: 'Vormonat',
    it: 'Mese precedente',
    nl: 'Vorige maand'
  },
  LAST_12_MONTHS: {
    en: 'Last 12 months',
    de: 'Letzte 12 Monate',
    it: 'Ultimi 12 mesi',
    nl: 'Laatste 12 maanden'
  },
  PREVIOUS_YEAR: {
    en: 'Previous year',
    de: 'Vorheriges Jahr',
    it: 'Anno precedente',
    nl: 'Vorig jaar'
  },
  FILTER: {
    en: 'Filter',
    de: 'Filter',
    it: 'Filtro',
    nl: 'Filter'
  },
  YEAR: {
    en: 'Year',
    de: 'Jahr',
    it: 'Anno',
    nl: 'jaar'
  },
  EMAIL_SETTING: {
    en: "Email setting",
    de: "E-Mail-Einstellung",
    it: "Impostazione email",
    nl: "E-mail instelling"
  },
  EMAIL_SETTING_IS_SUCCESSFULLY_UPDATED: {
    en: "Email setting is successfully updated",
    de: "E-Mail-Einstellung erfolgreich aktualisiert",
    it: "Impostazione email aggiornata con successo",
    nl: "E-mail instelling succesvol bijgewerkt"
  },
  MONTHLY_EMAIL_SUBSCRIPTION: {
    en: "Monthly email subscription",
    de: "Monatliches E-Mail-Abonnement",
    it: "Abbonamento mensile via email",
    nl: "Maandelijkse e-mailabonnement"
  },
  QUOTA: {
    en: "Quota",
    de: "Quota",
    it: "Quota",
    nl: "Quota"
  },
  EMAIL_SENT: {
    en: "Email sent",
    de: "E-Mail gesendet",
    it: "Email inviata",
    nl: "E-mail verzonden"
  },
  REMAINING_EMAIL: {
    en: "Remaining email",
    de: "Verbleibende E-Mails",
    it: "Email rimanenti",
    nl: "Resterende e-mail"
  },
  MONTH: {
    en: "month",
    de: "monat",
    it: "mese",
    nl: "maand"
  },
  MAXIMUM_CHARACTERS: {
    en: "Maximum characters",
    de: "Maximale Zeichenanzahl",
    it: "Caratteri massimi",
    nl: "Maximale tekens"
  },
  SSL_SETTING: {
    en: "SSL Setting",
    de: "SSL-Einstellung",
    it: "Impostazione SSL",
    nl: "SSL-instelling"
  },
  CENTER_IS_ASSIGNED_SUCCESSFULLY: {
    en: "Center is assigned successfully",
    de: "Zentrum wurde erfolgreich zugewiesen",
    it: "Il centro è stato assegnato con successo",
    nl: "Het centrum is succesvol toegewezen"
  },
  CENTER_IS_UNASSIGNED_SUCCESSFULLY: {
    en: "Center is unassigned successfully",
    de: "Zentrum wurde erfolgreich abgewiesen",
    it: "Il centro è stato revocato con successo",
    nl: "Het centrum is succesvol niet meer toegewezen"
  },
  ASSIGN_CENTER: {
    en: "Assign a center",
    de: "Ein Center zuweisen",
    it: "Assegna centro",
    nl: "Wijs centrum toe"
  },
  CENTER_CONFIGURATION: {
    en: "Center config",
    de: "Center Konfiguration",
    it: "Configurazione del centro",
    nl: "Centrumconfiguratie"
  },
  SSL_SETTING: {
    en: "SSL Setting",
    de: "SSL-Einstellung",
    it: "Impostazione SSL",
    nl: "SSL-instelling"
  },
  USER_IS_SUCCESSFULLY_REMOVED: {
    en: "User is successfully removed",
    de: "Benutzer wurde erfolgreich entfernt",
    it: "L'utente è stato rimosso con successo",
    nl: "Gebruiker is succesvol verwijderd"
  },
  REMOVE_USER: {
    en: "Remove user",
    de: "Benutzer entfernen",
    it: "Rimuovi utente",
    nl: "Verwijder gebruiker"
  },
  ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_USER: {
    en: "Are you sure you want to remove this user?",
    de: "Sind Sie sicher, dass Sie diesen Benutzer entfernen möchten?",
    it: "Sei sicuro di voler rimuovere questo utente?",
    nl: "Weet u zeker dat u deze gebruiker wilt verwijderen?"
  },
  UPLOAD_INFO_IMAGE: {
    en: "Upload info image (Format 9:16 - Min. resolution 450px800px)",
    de: "Informationsbild hochladen (Format 9:16 - Min. Auflösung 450px800px)",
    it: "Carica immagine informativa (Formato 9:16 - Risoluzione minima 450px800px)",
    nl: "Upload informatieafbeelding (Formaat 9:16 - Minimale resolutie 450px800px)"
  },
  INFO_IMAGE: {
    en: "Info image",
    de: "Infobild",
    it: "Immagine informativa",
    nl: "Info afbeelding"
  },
  CREATE_ONE_TIMER: {
    en: "Create Onetimer",
    de: "Onetimer hinzufügen",
    it: "Crea un evento singolo",
    nl: "Maak eenmalig"
  },
  START_ON: {
    en: 'Start on',
    de: 'Start am',
    it: 'Inizia su',
    nl: 'Starten'
  },
  CAMPAIGN_IS_SUCCESSFULLY_BOOKED: {
    en: "Campaign is successfully booked",
    de: "Die Kampagne wurde erfolgreich gebucht",
    it: "La campagna è stata prenotata con successo",
    nl: "De campagne is succesvol geboekt"
  },
  PRIORITY_NOTE: {
    en: 'Here you can specify the priority of a campaign, the higher the number the higher the priority',
    de: 'Hier können sie die Priorität einer Kampagne angeben, um so höher die Zahl um so höher die Priorität.',
    it: 'Qui è possibile specificare la priorità di una campagna, più alto è il numero più alta è la priorità',
    nl: 'Hier kunt u de prioriteit van een campagne aangeven, hoe hoger het getal hoe hoger de prioriteit.'
  },
  REMARK_LABEL: {
    en: "Remark label",
    de: "Label Bemerkung",
    it: "Etichetta osservazioni",
    nl: "Opmerkingslabel"
  },
  IS_REMARK_A_MANDATORY_FIELD: {
    en: "Is remark a mandatory field?",
    de: "Bemerkung Pflichtfeld",
    it: "La nota è un campo obbligatorio?",
    nl: "Is opmerking een verplicht veld?"
  },
  IS_REQUIRED: {
    en: "is required",
    de: "ist erforderlich",
    it: "è richiesto",
    nl: "is vereist"
  },
  FIELD: {
    en: "Field",
    de: "Feld",
    it: "Campo",
    nl: "Veld"
  },
  REMARK_OR_INDIVIDUAL_WISHES: {
    en: "Remark/individual wishes",
    de: "Bemerkung/individuelle Wünsche",
    it: "Nota/desideri individuali",
    nl: "Opmerking/individuele wensen"
  },
  VIEW_CAMPAIGN_IN_THE_PUBLIC_SECTION: {
    en: "View campaign in the public section",
    de: "Kampagne in der öffentlichen Übersicht anzeigen",
    it: "Visualizza la campagna nella sezione pubblica",
    nl: "Bekijk campagne in het openbare gedeelte"
  },
  UPLOAD_TEASER_IMAGE: {
    en: "Upload teaser image (Format 1:1 - Min. resolution 400px400px)",
    de: "Informationsvorschaubild hochladen (Format 1:1 - Min. Auflösung 400px400px)",
    it: "Carica immagine teaser (Formato 1:1 - Risoluzione minima 400px x 400px)",
    nl: "Upload teaser afbeelding (Formaat 1:1 - Minimale resolutie 400px x 400px)"
  },
  EXPORT_ONETIMER: {
    en: "Export onetimer",
    de: "Onetimer exportieren",
    it: "Esporta una tantum",
    nl: "Eenmalig exporteren"
  },
  ADD_A_FORM: {
    "en": "Add a form",
    "de": "Formular hinzufügen",
    "it": "Aggiungi un modulo",
    "nl": "Voeg een formulier toe",
  },
  BOOKING_FORM: {
    "en": "Booking form",
    "de": "Buchungsformular",
    "it": "Modulo di prenotazione",
    "nl": "Boekingsformulier"
  },
  UPLOADED_FILE: {
    en: 'Uploaded file',
    de: 'Hochgeladene Datei',
    it: 'File caricato',
    nl: 'Geüpload bestand'
  },
  DELETE_CENTER: {
    "en": "Delete center",
    "de": "Zentrum löschen",
    "it": "Elimina centro",
    "nl": "Centrum verwijderen"
  },
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_CENTER: {
    "en": "Are you sure you want to delete this center?",
    "de": "Möchten Sie dieses Zentrum wirklich löschen?",
    "it": "Sei sicuro di voler eliminare questo centro?",
    "nl": "Weet je zeker dat je dit centrum wilt verwijderen?"
  },
  CENTER_IS_SUCCESSFULLY_DELETED: {
    "en": "Center is successfully deleted",
    "de": "Das Zentrum wurde erfolgreich gelöscht",
    "it": "Il centro è stato eliminato con successo",
    "nl": "Centrum is succesvol verwijderd"
  }
}

export default Lang
