import React, { useState } from 'react'
import { FormGroup, Label, Input, Col, UncontrolledTooltip } from 'reactstrap'
import SketchPicker from 'react-color'

import Lang from 'Lang/General'

const ColorPickerInput = ({
  id,
  size,
  label,
  color,
  colorType,
  selectedLanguage,
  onChange,
  onChangeColorType,
}) => {
  const [ showColorPicker, updateShowColorPicker ] = useState( false )
  const getTextColor = () =>{
    let bgColor = colorType === 'primaryColor'
      ? '#0d6efd'
      : colorType === 'secondaryColor'
        ? '#6c757d'
        : color

    bgColor = bgColor.replace( '#', '' )
    if ( bgColor.length === 3) {
      bgColor = bgColor.repeat( 2 )
    }

    const r = parseInt( bgColor.substr( 0, 2 ), 16 )
    const g = parseInt( bgColor.substr( 2, 2 ), 16 )
    const b = parseInt( bgColor.substr( 4, 2 ), 16 )
    const yiq = (( r * 299 ) + ( g * 587 ) + ( b * 114 )) / 1000

    return yiq >= 128 ? 'black' : 'white'
  }

  return(
    <Col 
      md={ size } 
      onMouseLeave={ () => updateShowColorPicker( false ) }
      style={{ position: "relative", zIndex: showColorPicker ? "1" : "0" }} >
      <FormGroup style={{ position: "relative" }} >
        <Label>{ label }</Label>
        <Input 
          type="text" 
          style={{ 
            color: getTextColor(),
            background: colorType === 'primaryColor' 
              ? '#0d6efd'
              : colorType === 'secondaryColor'
                ? '#6c757d'
                : color 
          }}
          value={ color }
          disabled={ colorType !== 'custom' }
          onClick={ () => updateShowColorPicker( true ) } />
        <div className='d-flex flex-row flex-wrap justify-content-end' >
          <div 
            id={ `${ id }-primary-hover` }
            className='mt-1 mr-2' 
            style={{ 
              width: "10px", 
              height: "10px", 
              borderRadius: "50%", 
              background: "#0d6efd",
              border: `2px solid ${ colorType === 'primaryColor' ? '#5cb85c' : '#ffffff' }`,
              outline: `2px solid #0d6efd`
            }}
            onClick={ () => onChangeColorType( 'primaryColor' ) }
          />
          <UncontrolledTooltip
            target={ `${ id }-primary-hover` }
            placement="top">
            { Lang[ 'APPLY_PRIMARY_COLOR' ][ selectedLanguage ] }
          </UncontrolledTooltip>
          <div 
            id={ `${ id }-custom-hover` }
            className='mt-1 mr-2' 
            style={{ 
              width: "10px", 
              height: "10px", 
              borderRadius: "50%", 
              background: "#000000",
              border: `2px solid ${ colorType === 'custom' ? '#5cb85c' : '#ffffff' }`,
              outline: "2px solid #000000"
            }}
            onClick={ () => onChangeColorType( 'custom' ) }
          />
          <UncontrolledTooltip
            target={ `${ id }-custom-hover` }
            placement="top">
            { Lang[ 'APPLY_CUSTOM_COLOR' ][ selectedLanguage ] }
          </UncontrolledTooltip>
          <div 
            id={ `${ id }-secondary-hover` }
            className='mt-1 mr-1' 
            style={{ 
              width: "10px", 
              height: "10px", 
              borderRadius: "50%", 
              background: "#6c757d",
              border: `2px solid ${ colorType === 'secondaryColor' ? '#5cb85c' : '#ffffff' }`,
              outline: `2px solid #6c757d`
            }}
            onClick={ () => onChangeColorType( 'secondaryColor' ) }
          />
          <UncontrolledTooltip
            target={ `${ id }-secondary-hover` }
            placement="top">
            { Lang[ 'APPLY_SECONDARY_COLOR' ][ selectedLanguage ] }
          </UncontrolledTooltip>
        </div>
        {
          showColorPicker && <div 
            style={{ 
              position: "absolute", 
              top: "calc( 100% + 5px )", 
              right: "0", 
              zIndex: "1" 
            }}>
            <SketchPicker
              color={ color }
              onChange={ col => onChange( col ) } />
          </div>
        }
      </FormGroup>
    </Col>
  )
}

export default ColorPickerInput