import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { Get, Post, Put, Delete } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      products: {
        data: [],
        meta: {
          currentPage: 1
        }
      },
      selectedProduct: {
        name: {
          "en": "",
          "de": "",
          "nl": "",
          "it": "",
          "fr": ""
        },
        description: {
          "en": "",
          "de": "",
          "nl": "",
          "it": "",
          "fr": ""
        },
        image: {
          "raw": "",
          "name": ""
        },
        pdf_url: "",
        is_new: false,
        currency: "",
        price: 0.00,
        countries: [],
        center_group_ids: [],
        is_most_booked_products: false
      },
      productError: {}
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      zIndex: 1000
    })
    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      zIndex: 1000
    })

    onChangeProductsHOC = ( key, val ) => this.setState({ [key]: val })

    getSelectedProduct = id => Get(
      `/api/v1/admin_panel/products/${ id }`,
      this.getSelectedProductSuccess,
      this.getSelectedProductError,
      this.load
    )
    getSelectedProductSuccess = payload => this.setState({ selectedProduct: {
      ...payload,
      center_group_ids: payload.center_group_ids ? payload.center_group_ids : []
    } })
    getSelectedProductError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadProducts={ this.state.loading }
          showUpdateProduct={ this.state.showUpdateProduct }
          selectedProduct={ this.state.selectedProduct }
          productError={ this.state.productError }
          getSelectedProduct={ this.getSelectedProduct }/>
      )
    }
  }
  return WithHOC
}

export default HOC
