import React, { useState, useEffect } from 'react'
import { Form, FormGroup, FormText, Label, Input } from 'reactstrap'
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import uniqid from 'uniqid'
import _ from 'lodash'

import Lang from 'Lang/General'
import LanguageSelector from 'components/LanguageSelector'
import RenderDOM from './RenderDOM'
import UpdateColumnModal from './Column'
import UpdateContentModal from './Content'
import ConfirmDeleteModal from 'components/Indicator/Prompt'
import MediaDatabase from 'SharedModules/MediaDatabase' 
import { toggleMediaDatabase } from 'actions/MediaDatabaseToggle'


import "react-datepicker/dist/react-datepicker.css"

const reorder = ( list, startIndex, endIndex ) => {
  const result = Array.from( list )
  const [removed] = result.splice( startIndex, 1 )
  result.splice( endIndex, 0, removed )
  return result
}

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "transparent"
})

const FormEditor = props => {
  const [ dom, setDom ] = useState( [] )
  const [ formValues, setFormValues ] = useState( [] )
  const [ componentsRemoved, setComponentsRemoved ] = useState( [] )
  const [ showUpdateColumnModal, setShowUpdateColumnModal ] = useState( false )
  const [ showUpdateContentModal, setShowUpdateContentModal ] = useState( false )
  const [ showConfirmDelete, setShowConfirmDelete ] = useState( false )
  const [ selectedParentComponentToEdit, setSelectedParentComponentToEdit ] = useState( {} )
  const [ selectedComponentToEdit, setSelectedComponentToEdit ] = useState( {} )
  const [ selectedComponentToRemove, setSelectedComponentToRemove ] = useState( {} )
  const [ foundObject, setFoundObject ] = useState( {} )
  const [ editingImageType, setEditingImageType ] = useState( '' )
  const [ currentLanguage, setCurrentLanguage ] = useState( 'de' )

  useEffect( () => {
    let tmpDom = !_.isEmpty( props.currentOlsCampaign.content ) 
      ? _.cloneDeep( props.currentOlsCampaign.content )
      : []
    
    if( props.mode === 'edit' ){
      tmpDom.push( createAddContentInColumnBtnDom() )
      tmpDom.push({
        id: uniqid(),
        type: 'Col',
        grid: 12,
        children: [
          {
            id: uniqid(),
            type: 'Button',
            mapWith: 'add-new-column-button',
            onClickEditor: onClickAddNewColumn,
            color: 'light',
            content: Lang[ 'ADD_NEW_COLUMN' ],
            style: {
              width: '100%',
              marginTop: '1rem'
            }
          }
        ]
      })
    }

    setDom( tmpDom )
    setCurrentLanguage( props.data.languageReducer.lang )
    !_.isEmpty( props.currentOlsCampaign.values ) && setFormValues( _.cloneDeep( props.currentOlsCampaign.values ) )
  }, [] )

  const onChangeFormBuilderState = ( key, val ) => {
    switch( key ) {
      case 'dom': 
        setDom( val )
        break
      case 'formValues': 
        setFormValues( val )
        break
      case 'componentsRemoved': 
        setComponentsRemoved( val )
        break
      case 'showUpdateColumnModal': 
        setShowUpdateColumnModal( val )
        break
      case 'showUpdateContentModal': 
        setShowUpdateContentModal( val )
        break
      case 'showConfirmDelete': 
        setShowConfirmDelete( val )
        break
      case 'selectedParentComponentToEdit': 
        setSelectedParentComponentToEdit( val )
        break
      case 'selectedComponentToEdit': 
        setSelectedComponentToEdit( val )
        break
      case 'selectedComponentToRemove': 
        setSelectedComponentToRemove( val )
        break
      case 'foundObject': 
        setFoundObject( val )
        break
      case 'editingImageType': 
        setEditingImageType( val )
        break
      case 'currentLanguage': 
        setCurrentLanguage( val )
        break
    }
  }

  const createAddContentInColumnBtnDom = () => {
    const tmpId = uniqid()
    return  {
      id: tmpId,
      type: 'Col',
      grid: 12,
      children: [
        {
          id: uniqid(),
          type: 'Button',
          color: 'light',
          onClickEditor: () => {
            setShowUpdateContentModal( true )
            setFoundObject({ id: tmpId, type: 'Col', children: [], grid: 12 })
            setSelectedParentComponentToEdit({ id: tmpId, type: 'Col', children: [], grid: 12 })
          },
          content: Lang[ 'ADD_CONTENT' ],
          style: {
            width: '100%'
          },
          mapWith: 'add-content-in-column-button'
        }
      ]
    }
  }

  const findObjectAndPatch = ( data, target ) => {
    data.map(( item, index ) => {
      if( item.id === target.id ) {
        data.splice( index, 1, target )
      } else {
        if( item.children ) {
          findObjectAndPatch( item.children, target )
        }
      }
    })
    return data
  }

  const findObjectById = ( data, id ) => {
    data.map( item => {
      if( item.id === id ) {
        setFoundObject( item )
      } else {
        if( item.children ) {
          findObjectById( item.children, id )
        }
      }
    })
  }

  const findTargetObject = ( data, target, page ) => {
    data.map( item => {
      if( item.mapWith === target ) {
        setFoundObject( item )
      } else {
        if( item.children ) {
          findTargetObject( item.children, target, page )
        }
      }
    })
  }

  const findObjectAndRemove = ( data, target ) => {
    data.map(( item, index ) => {
      if( item.id === target.id ) {
        data.splice( index, 1 )
      } else {
        if( item.children ) {
          findObjectAndRemove( item.children, target )
        }
      }
    })
  }

  const findExtraButtonsAndRemove = data => {
    if( data?.mapWith === 'add-content-in-column-button' || data?.mapWith === 'add-new-column-button' ){
      return undefined
    }
    if( data.children?.length > 0 ){
      let tmpData = _.cloneDeep( data )
      tmpData.children = data.children.map( item => findExtraButtonsAndRemove( item ) ).filter( item => typeof item !== 'undefined' )
      return tmpData
    }
    if( Array.isArray( data ) ) {
      let tmpData = data.map( item => findExtraButtonsAndRemove( item ) ).filter( item => typeof item !== 'undefined' )
      return tmpData
    }
    return data
  }

  const onPatchFormValues = ( 
    index, 
    key, 
    val, 
    type,
    currentSelection, 
    previousSelection
  ) => {
    let tmp = _.cloneDeep( formValues )

    if ( type?.indexOf( 'Radio' ) > -1 ) {
      if( 
        val !== tmp[ index ][ 'value' ] &&
        currentSelection.dependentField &&
        currentSelection.dependentField.id &&
        tmp[ currentSelection.dependentField.dataMapIndex ][ 'isRequired' ]
      ){
        tmp[ currentSelection.dependentField.dataMapIndex ][ 'validation' ] = tmp[ currentSelection.dependentField.dataMapIndex ].validation
        tmp[ currentSelection.dependentField.dataMapIndex ][ 'isRequired' ] = true
        tmp[ currentSelection.dependentField.dataMapIndex ][ 'isDependentFieldRequired' ] = true
      }
  
      if( previousSelection && previousSelection.dependentField && previousSelection.dependentField.id ) {
        tmp[ previousSelection.dependentField.dataMapIndex ][ 'value' ] = ''
        tmp[ previousSelection.dependentField.dataMapIndex ][ 'isDependentFieldRequired' ] = false
      }
    } else if ( type?.indexOf( 'Checkbox' ) > -1 ) {
      let tmpFormValue = _.cloneDeep( tmp[ index ][ 'value' ] )
      if( tmpFormValue.indexOf( currentSelection.value ) > -1 ) {
        if (currentSelection.dependentField && currentSelection.dependentField.id) {
          tmp[ currentSelection.dependentField.dataMapIndex ][ 'value' ] = '' 
          tmp[ currentSelection.dependentField.dataMapIndex ][ 'isDependentFieldRequired' ] = false 
        }
      } else {     
        if( 
          currentSelection.dependentField &&
          currentSelection.dependentField.id && 
          tmp[ currentSelection.dependentField.dataMapIndex ][ 'isRequired' ]
        ){
          tmp[ currentSelection.dependentField.dataMapIndex ][ 'validation' ] = formValues[ currentSelection.dependentField.dataMapIndex ].validation
          tmp[ currentSelection.dependentField.dataMapIndex ][ 'isRequired' ] = true
          tmp[ currentSelection.dependentField.dataMapIndex ][ 'isDependentFieldRequired' ] = true
        }
      }
    }
    tmp[ index ][ key ] = val

    return setFormValues( tmp )
  }

  const onClickAddNewColumn = dom => {
    let tmp = _.cloneDeep( dom )
    tmp.splice( tmp.length - 1, 0, createAddContentInColumnBtnDom() )
    setDom( tmp )
  }

  const onClickRemoveColumn = () => {
    let tmp = _.cloneDeep( dom )
    let tmpSelectComponentToRemove = _.cloneDeep( selectedComponentToRemove )
    let tmpComponentsRemoved = _.cloneDeep( componentsRemoved )
    tmpComponentsRemoved.push( tmpSelectComponentToRemove.children[0].id )
    findObjectAndRemove( tmp, tmpSelectComponentToRemove )

    setDom( tmp )
    setShowConfirmDelete( false )
    return setComponentsRemoved( tmpComponentsRemoved )
  }

  const onDragEnd = result => {
    if( !result.destination ) {
      return
    }
    let tmp = _.cloneDeep( dom )
    tmp = reorder( tmp, result.source.index, result.destination.index )
    setDom( tmp )
  }

  const updateColumn = ( domCache, formValuesCache ) => {
    let tmpDOM = _.cloneDeep( domCache )
    let tmpFormValues = _.cloneDeep( formValuesCache )
    let tmpRearrangedFormValues = []
    let j = 0
    
    tmpFormValues = _.filter( 
      tmpFormValues, 
      item => !_.isEmpty( item ) && _.findIndex( componentsRemoved, { id: item.id } ) === -1 
    )

    let tmpFormContentChildren = _.cloneDeep( tmpDOM )
    tmpFormContentChildren.map( ( item ) => {
      if( 
        item.children && 
        item.children[ 0 ] &&
        (
          !item.children[ 0 ].mapWith ||
          ( 
            item.children[ 0 ].mapWith && 
            item.children[ 0 ].mapWith !== 'add-content-in-column-button' && 
            item.children[ 0 ].mapWith.mapWith !== 'add-new-column-button' )
        )
      ){
        let tmpFormVal = tmpFormValues.find( x => x.id == item.children[0].id )
        tmpRearrangedFormValues.push( tmpFormVal )
        item.children[0].dataMapIndex = j
        j = j + 1

        if ( ["Radio", "RadioFlexible", "Checkbox", "CheckboxFlexible"].indexOf( item.children[0].type ) !== -1 ) {
          item.children[0].selections.map( selection => {
            if ( selection.dependentField && selection.dependentField.id ) {
              let tmpFormVal = tmpFormValues.find( x => x.id == selection.dependentField.id )
              tmpRearrangedFormValues.push( tmpFormVal )
              selection.dependentField.dataMapIndex = j
              j = j + 1
            }
          })
        }
      }
    })

    tmpDOM = tmpFormContentChildren
    setDom( tmpDOM )
    setFormValues( tmpRearrangedFormValues )
    setShowUpdateColumnModal( false )
    setComponentsRemoved( [] )
    setSelectedComponentToEdit( {} )
  }

  const submitForm = () => {
    let tmpDOM = findExtraButtonsAndRemove( _.cloneDeep( dom ) )
    let tmpFormValues = _.cloneDeep( formValues )
    let tmpRearrangedFormValues = []
    let j = 0
    
    tmpFormValues = _.filter( 
      tmpFormValues, 
      item => !_.isEmpty( item ) && _.findIndex( componentsRemoved, { id: item.id } ) === -1 
    )

    let tmpFormContentChildren = _.cloneDeep( tmpDOM )

    tmpFormContentChildren = _.filter( tmpFormContentChildren, item => item.children?.length > 0 )
    tmpFormContentChildren.map( ( item ) => {
      let tmpFormVal = tmpFormValues.find( x => x.id == item.children[0].id )
      tmpRearrangedFormValues.push( tmpFormVal )
      item.children[0].dataMapIndex = j
      j = j + 1

      if ( ["Radio", "RadioFlexible", "Checkbox", "CheckboxFlexible"].indexOf( item.children[0].type ) !== -1 ) {
        item.children[0].selections.map( selection => {
          if ( selection.dependentField && selection.dependentField.id ) {
            let tmpFormVal = tmpFormValues.find( x => x.id == selection.dependentField.id )
            tmpRearrangedFormValues.push( tmpFormVal )
            selection.dependentField.dataMapIndex = j
            j = j + 1
          }
        })
      }
    })

    tmpDOM = tmpFormContentChildren
    props.onChangeCampaignListingHOC(
      props.olsCampaignType,
      {
        ...props.currentOlsCampaign,
        content: tmpDOM,
        values: tmpRearrangedFormValues,
      }
    )
  }

  return(
    <div 
      onMouseOut={ () => props.mode === 'edit' ? (
        submitForm() 
      ) : (
        props.onChangeOlsCampaign(
          props.olsCampaignType,
          {
            ...props.currentOlsCampaign,
            content: dom,
            values: formValues,
          }
        )
      )} >
      {
        props.mode === 'edit' ? (
          <>
            <div className='row mb-2' >
              <LanguageSelector 
                currentLang={ currentLanguage }
                updateLang={ e => setCurrentLanguage( e ) }
              />
            </div>
            <Form>
              <FormGroup>
                <Label>{ Lang[ 'CAMPAIGN_NAME' ][ currentLanguage ] }</Label>
                <Input type="text" readOnly value={ props.currentOlsCampaign.title } />
              </FormGroup>
              {
                !props.currentOlsCampaign.is_app_campaign && (
                  <FormGroup>
                    <Label>{ Lang[ 'FACEBOOK_BUDGET' ][ currentLanguage ] }</Label>
                    <Input step='1' type='number' readOnly />
                  </FormGroup>
                )
              }
              <FormGroup>
                <Label>{ Lang[ 'START_ON' ][ currentLanguage ] }</Label>
                <DatePicker
                  disabled
                  dateFormat="dd/MM/yyyy"
                  className="form-control mb-1"
                  popperModifiers={{
                    flip: { behavior: [ 'bottom' ] },
                    preventOverflow: { enabled: false },
                    hide: { enabled: false }
                  }}
                />
                {
                  !props.currentOlsCampaign.is_app_campaign && (
                    <Input
                      type='text'
                      value={ props.currentOlsCampaign.start_date_hint?.[ currentLanguage ] || '' }
                      placeholder={ Lang[ 'NOTE' ][ currentLanguage ] }
                      onChange={ e => props.onChangeCampaignListingHOC(
                        props.olsCampaignType,
                        { 
                          ...props.currentOlsCampaign, 
                          start_date_hint: {
                            ...props.currentOlsCampaign.start_date_hint,
                            [ currentLanguage ]: e.target.value 
                          }
                        }
                      )}
                    />
                  )
                }
              </FormGroup>
              {
                props.currentOlsCampaign.is_app_campaign && (
                  <FormGroup>
                    <Label>{ Lang[ 'END_DATE' ][ currentLanguage ] }</Label>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      popperModifiers={{
                        flip: { behavior: [ 'bottom' ] },
                        preventOverflow: { enabled: false },
                        hide: { enabled: false }
                      }}
                    />
                  </FormGroup>
                )
              }
              {
                props.currentOlsCampaign.is_app_campaign && (
                  <FormGroup>
                    <Label>{ Lang[ 'PRIORITY' ][ currentLanguage ] }</Label>
                    <Input step='1' type='number' readOnly />
                    <FormText>{ Lang[ 'PRIORITY_NOTE' ][ currentLanguage ] }</FormText>
                  </FormGroup>
                )
              }
              {
                !_.isEmpty( props.currentOlsCampaign.remark_label ) && (
                  <FormGroup>
                    <Label>{ props.currentOlsCampaign.remark_label?.[ currentLanguage ] }</Label>
                    <Input rows="3" type="textarea" readOnly />
                  </FormGroup>
                )
              }
              <DragDropContext onDragEnd={ onDragEnd }>
                <Droppable droppableId="droppable">
                {( provided, snapshot ) => (
                    <div 
                      { ...provided.droppableProps }
                      ref={ provided.innerRef } 
                      className="row d-flex flex-row flex-wrap"
                      style={ getListStyle( snapshot.isDraggingOver )}>
                      {
                        dom.map( ( item, index ) => (
                          <RenderDOM        
                            { ...props }
                            pageErrors={ props.pageErrors || [] }          
                            uploadedMedia={ props.uploadedMedia || [] }
                            content={{ ...item, rowComponentIndex: index }}
                            onChangeFormBuilderState={ onChangeFormBuilderState }
                            formValues={ formValues }
                            onPatchFormValues={ onPatchFormValues }
                            dom={ dom }
                            findObjectAndPatch={ findObjectAndPatch }
                            currentLanguage={ currentLanguage }
                          />
                        ))
                      }
                      { provided.placeholder }
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Form>
            <UpdateColumnModal
              showUpdateColumnModal={ showUpdateColumnModal }
              selectedComponentToEdit={ selectedComponentToEdit }
              onChangeFormBuilderState={ onChangeFormBuilderState }
              findObjectAndPatch={ findObjectAndPatch }
              updateColumn={ updateColumn }
              dom={ dom }
              formValues={ formValues }
              lang={ props.data.languageReducer.lang } />
            <UpdateContentModal
              showUpdateContentModal={ showUpdateContentModal }
              selectedParentComponentToEdit={ selectedParentComponentToEdit }
              selectedComponentToEdit={ selectedComponentToEdit }
              onChangeFormBuilderState={ onChangeFormBuilderState }
              findObjectAndPatch={ findObjectAndPatch }
              findObjectById={ findObjectById }
              dom={ dom }
              foundObject={ foundObject }
              formValues={ formValues }
              lang={ props.data.languageReducer.lang } />
            <ConfirmDeleteModal
              showPromptModal={ showConfirmDelete }
              content={ Lang[ 'DELETE_CONFIRMATION_SELECTED_COMPONENT' ][ props.data.languageReducer.lang ] }
              onClickYes={() => onClickRemoveColumn()}
              onClickNo={() => {
                setShowConfirmDelete( false )
                setSelectedComponentToRemove( {} )
              }}
              lang={ props.data.languageReducer.lang } />
            <MediaDatabase
              onSelectedImage={( selectedImage ) => {
                let tmpDOM = _.cloneDeep( dom )
                let tmp = _.cloneDeep( selectedComponentToEdit )

                if ( editingImageType === "PAGE_THUMBNAIL" ) {
                  tmp[ 'settings' ] = {
                    ...tmp[ 'settings' ],
                    'thumbnailImg': selectedImage
                  }
                } else {
                  tmp.children[0].url = selectedImage
                }
                findObjectAndPatch( tmpDOM, tmp )
                onChangeFormBuilderState( 'selectedComponentToEdit', tmp )
                props.toggleMediaDatabase( false )
              }} 
            />
          </>
        ) : (
          dom.map( ( item, index ) => (
            <RenderDOM   
              { ...props }                    
              content={{ ...item, rowComponentIndex: index }}
              mode='fill'
              formValues={ formValues }
              onPatchFormValues={ onPatchFormValues }
              dom={ dom }
              currentLanguage={ currentLanguage }
              findObjectAndPatch={ findObjectAndPatch }
              onChangeFormBuilderState={ onChangeFormBuilderState }
            />
          ))
        )
      }
    </div>
  )
}

const mapStateToProps = state => ({ data: state })

export default connect( mapStateToProps, {
  toggleMediaDatabase
}) ( FormEditor )