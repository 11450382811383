import React from 'react'
import Radium from 'proofpoint-radium'

const Input = ({
  id,
  style,
  placeholder,
  inputType,
  ... props
}) => {
  return(
    <input key={ id } type={ inputType } style={ style } placeholder={ placeholder?.[ props.selectedLanguageShortname ]??'' }/>
  )
}

export default Radium( Input )
