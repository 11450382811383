import React, { Component } from 'react';
import { TextField } from '@material-ui/core'
import _ from 'lodash'
import { Button, Alert } from 'reactstrap'

import LoadingModal from 'components/Indicator/LoadingOverlay'
import { ApplyBranding } from 'utils/apply-color'
import BackdropImage from 'assets/images/backdrop-image.jpg';
import ResetPasswordHOC from './actions';

import './index.scss';

class ForgotPassword extends Component {

  handleKeyPress = event => {
    if( event.key === 'Enter' ){
      this.props.forgotPassword( this.props.forgotPasswordEmail )
    }
  }

  handleResetPassword = () => this.props.forgotPassword( this.props.forgotPasswordEmail )

  render = () => {
    return (
      <>
        {
          !_.isEmpty( this.props.data.platformConfigReducer.platformConfig ) 
          && _.find( this.props.data.platformConfigReducer.platformConfig, { key: 'PLATFORM_COLOR' } )
          && <ApplyBranding colors={{ primary: _.find( this.props.data.platformConfigReducer.platformConfig, { key: 'PLATFORM_COLOR' } ).value }} />
        }
        <div className="reset-password">
          <img src={ BackdropImage } className="bg-image" />
          <div className="reset-password-overlay">
            <div className="reset-password-panel">
              {  
                this.props.errorMessage !== '' && (
                  <Alert color="danger">
                    { this.props.errorMessage }
                  </Alert>
                )
              }
              {
                this.props.successMessage !== '' && (
                  <Alert color="success">
                    { this.props.successMessage }
                    <Button color="success" onClick={() => this.props.history.push( '/' )} style={{ marginLeft: '10px' }}>Zurück zur Anmeldung</Button>
                  </Alert>
                )
              }
              <h1 className="reset-password-panel-title">Passwort vergessen</h1>
              <TextField
                label={ 'Email' }
                value={ this.props.forgotPasswordEmail.email }
                onChange={ e => {
                  let tmp = _.cloneDeep( this.props.forgotPasswordEmail )
                  tmp[ 'email' ] = e.target.value
                  this.props.onChangeResetPassword( 'forgotPasswordEmail', tmp )
                }}
                type={ 'email' }
                className={ 'kws-input mb-30' }
                onKeyPress={ this.handleKeyPress } />
              <div className="d-flex">
                <Button
                  color={ 'link' }
                  onClick={() => this.props.history.push( '/' )}
                  style={{ marginLeft: 'auto' }}
                  classes={{ root: 'primary-button-forgot-password', label: 'primary-button-text text-capitalize' }}>
                  Zurück zur Anmeldung
                </Button>
                <Button
                  variant={ 'contained' }
                  color={ 'primary' }
                  onClick={ this.handleResetPassword }
                  classes={{ root: 'reset-button', label: 'primary-button-text text-capitalize' }}>
                  { 'Speichern' }
                </Button>
              </div>
            </div>
          </div>
          { this.props.data.ajaxReducer.ajaxCallProgress > 0 || this.props.onLoadResetPassword && <LoadingModal /> }
        </div>
      </>
    )
  }
}

export default ResetPasswordHOC( ForgotPassword );