import React, { Component } from "react"
import Frame from "react-frame-component"
import { compose } from "redux"
import { StyleRoot } from "proofpoint-radium"
import { Form, FormGroup, Label } from "reactstrap"
import { Helmet } from "react-helmet"
import Loader from "react-loaders"
import Select from 'react-select'
import _ from "lodash"

import SectionsHOC from "./actions/sections"
import TemplateHOC from "../actions/template"

import CoreRenderFunction from "containers/TemplateEditor/core/render"

import Lang from "Lang/General"

class SectionLists extends Component {
  state = {
    dropdownValue: "-1",
    selectedSection: {},
    selectedTemplate: "",
    selectedCategories: []
  }

  componentDidMount = () => {
    this.props.getSiteTemplate()
    this.props.getCategories()
  }

  componentDidUpdate = ( pp, ps ) => {
    if( this.state.selectedTemplate !== ps.selectedTemplate || this.state.selectedCategories !== ps.selectedCategories ){
      this.props.getSections({
        name: this.state.selectedTemplate,
        section_categories: this.state.selectedCategories.length > 0 ? _.map( this.state.selectedCategories, 'value' ) : [0]
      })
      this.setState({ selectedSection: {}, dropdownValue: "-1" })
    }
  }

  render = () => {
    return (
      <div>
        <Form>
          <Label>{ Lang[ "SELECT_TEMPLATE" ][ this.props.lang ] }</Label>
          <FormGroup>
            <select
              className="form-control"
              value={ this.state.selectedTemplate }
              onChange={ e => {
                this.setState({ selectedTemplate: e.target.value }) 
                this.props.onSelectSectionToAdd( {} )
              }} >
              <option></option>
              {
                this.props.templates && this.props.templates.map( ( item ) => {
                  return (
                    <option key={ item.id } value={ item.name }>
                      { item.name }
                    </option>
                  )
                })
              }
            </select>
          </FormGroup>
          {
            this.state.selectedTemplate && (
              <>
                <FormGroup>
                  <Label>{ Lang[ 'SELECT_CATEGORY' ][ this.props.lang ] }</Label>
                  <Select
                    isMulti
                    placeholder={ Lang[ 'SELECT' ][ this.props.lang ] }
                    options={ this.props.categories }
                    className="basic-multi-select"
                    value={ this.state.selectedCategories }
                    onChange={ val => {
                      this.setState({ selectedCategories: val ? val : [] }) 
                      this.props.onSelectSectionToAdd( {} )
                    }} />
                </FormGroup>
                <FormGroup>
                  <Label>{ Lang[ "SELECT_A_SECTION_TEMPLATE" ][ this.props.lang ] }</Label>
                  {
                    this.props.onLoadSections ? (
                      <div className="text-center col-12 pt-3">
                        <Loader type="ball-pulse-rise" />
                      </div>
                    ) : (
                      <select
                        className="form-control"
                        value={ this.state.dropdownValue }
                        onChange={ ( e ) => {
                          this.props.onSelectSectionToAdd( _.find( this.props.sections, { id: e.target.value } ) )
                          this.setState({
                            dropdownValue: e.target.value,
                            selectedSection: _.find( this.props.sections, { id: e.target.value } ),
                          })
                        }}
                      >
                        <option></option>
                        {
                          this.props.sections &&
                          this.props.sections.map( ( item ) => {
                            if( !item.is_system_section ) {
                              return (
                                <option key={ item.id } value={ item.id }>
                                  { item.name }
                                </option>
                              )
                            }
                          })
                        }
                      </select>
                    )
                  }
                </FormGroup>
              </>
            )
          }
        </Form>
        <p style={{ marginTop: "15px", marginBottom: "15px" }}>{ Lang[ "PREVIEW" ][ this.props.lang ] }</p>
        <Frame
          id={ "editor-site-renderer" }
          style={{
            height: "500px",
            width: "100%",
          }}
          initialContent={
            '<!DOCTYPE html><html><head><link href="/bootstrap.css" rel="stylesheet"></link><link href="/image-block-styles.css" rel="stylesheet"></link></head><body style="overflow-y:scroll;overflow-x:hidden;"><div></div></body></html>'
          }
        >
          <StyleRoot>
            {
              this.props.templateStylesheets &&
              this.props.templateStylesheets.map( ( item ) => {
                if ( item.type === "URL" ) {
                  return <link key={ `stylesheet-${ item.id }` } rel="stylesheet" href={ item.content }></link>
                } else {
                  return <style key={ `stylesheet-${ item.id }` }>{ item.content }</style>
                }
              })
            }
            {
              this.state.selectedSection && this.state.selectedSection.content && (
                <CoreRenderFunction
                  item={ this.state.selectedSection.content }
                  updateMainContainerState={ () => {} }
                  languages={ this.props.languages }
                  selectedLanguageShortname={ this.props.lang }
                  mode={ "view" }
                />
              )
            }
            {
              this.props.templateJavascripts &&
              this.props.templateJavascripts.map( ( item ) => {
                if ( item.type === "URL" ) {
                  return <script key={ `template-js-${ item.id }` } src={ item.content }></script>
                } else {
                  return (
                    <Helmet key={ `template-js-${ item.id }` }>
                      <script>{ item.content }</script>
                    </Helmet>
                  )
                }
              })
            }
          </StyleRoot>
        </Frame>
      </div>
    )
  }
}

export default compose(
  SectionsHOC, 
  TemplateHOC
)( SectionLists )
