import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import ReactSelect from 'react-select'
import {
  Form, FormGroup, 
  Row, Col, Label, Input, Button
} from 'reactstrap'
import Moment from 'moment'
import ReactTable from 'react-table'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginImagePreview from "filepond-plugin-image-preview"  
import FilePondPluginPdfPreview from 'filepond-plugin-pdf-preview'

import MultiLangInput from 'components/Input/MultiLang'
import MultiLangRTE from 'components/RTE/MultiLang'
import DeleteConfirmation from 'components/Modal/delete'
import Lang from 'Lang/General'

registerPlugin( FilePondPluginImagePreview, FilePondPluginPdfPreview )

const TipForm = ({
  tipType,
  currentTip,
  onChangeTipsHOC,
  centerGroups,
  lang,
  categories,
  selectedTipFiles,
  createSelectedTipFile,
  removeSelectedTipFile
}) => {
  const [ categoryOptions, updateCategoryOptions ] = useState( [] )
  const [ showDeleteFileId, updateShowDeleteFileId ] = useState( 0 )
  const [ files, updateFiles ] = useState( [] )
  const [ fileTitle, updateFileTitle ] = useState( '' )

  useEffect( () => {
    updateFiles( [] )
    updateFileTitle( '' )
  }, [] )
    
  useEffect( () => {
    if( categories.length > 0 ){
      let tmpCategories = categories.map( item => ({ id: item.id, label: item.name[ lang ], value: item.name[ lang ] }) )
      updateCategoryOptions( tmpCategories )
    }
  }, [ categories ] )

  const updateCurrentTip = ( key, val ) => {
    let tmp = _.cloneDeep( currentTip )
    tmp[ key ] = val
    if( key === 'description_type' ){
      tmp[ 'content' ] = { en: '', de: '', it: '', nl: '' }
    } else if ( key === 'week_number' ){
      tmp[ key ] = parseInt( val.replace( /\d{4}-W/g, '' ) )
      tmp[ 'calendar_year' ] = parseInt( val.replace( /d/g, '' ).replace( /-W.*/g, '' ) )
    }
    onChangeTipsHOC( tipType, tmp )
  }

  return(
    <>
      <Form>
        <MultiLangInput
          label={ Lang[ 'TITLE' ][ lang ] }
          value={ currentTip.title }
          onChange={ val => updateCurrentTip( 'title', val ) }
          selectedLanguage={ lang } />
        <MultiLangRTE
          label={ `${ Lang[ 'DESCRIPTION' ][ lang ] } (${ Lang[ 'FOR_EMAIL' ][ lang ] })` }
          value={ currentTip.description }
          onChange={ val => updateCurrentTip( 'description', val ) }
          selectedLanguage={ lang } />
        <FormGroup>
          <Label>{ Lang[ 'CENTER_GROUP' ][ lang ] }</Label>
          <Col sm={ 12 } className='pl-0 pr-0' style={{ zIndex: 999 }}>
            <ReactSelect
              isMulti
              name="colors"
              options={ centerGroups }
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder={ Lang[ 'SELECT_PLACEHOLDER' ][ lang ] }
              onChange={ ( value, action ) => updateCurrentTip( 'center_groups', value ) }
              value={ currentTip.center_groups } />
          </Col>
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'CONTENT_TYPE' ][ lang ] }</Label>
          <Input
            type='select'
            value={ currentTip.description_type }
            onChange={ e => updateCurrentTip( 'description_type', e.target.value ) }>
            <option value='text' >{ Lang[ 'TEXT' ][ lang ] }</option>
            <option value='video' >{ Lang[ 'VIDEO' ][ lang ] }</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'CATEGORIES' ][ lang ] }</Label>
          <Col sm={ 12 } className='pl-0 pr-0' style={{ zIndex: 998 }}>
            <ReactSelect
              isMulti
              name="colors"
              options={ categoryOptions }
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder={ Lang[ 'SELECT_PLACEHOLDER' ][ lang ] }
              onChange={ ( value, action ) => updateCurrentTip( 
                'category_ids', 
                value ? value.map( item => item.id ) : []
              )}
              value={ 
                _.filter( 
                  categoryOptions, 
                  category => _.findIndex( currentTip.category_ids, currentCategoryId => currentCategoryId === category.id  ) > -1 
                ) 
              } 
            />
          </Col>
        </FormGroup>
        {
          currentTip.category_ids.length === 0 ? (
            <FormGroup>
              <Label>{ Lang[ 'WEEK_OF_THE_YEAR_TO_DISPLAY' ][ lang ] }</Label>
              <Input
                type='week'
                value={ `${ currentTip.calendar_year }-W${ currentTip.week_number.toString().padStart( 2, '0' ) }` }
                onChange={ e => updateCurrentTip( 'week_number', e.target.value ) }
              />
            </FormGroup>
          ) : (
            <FormGroup>
              <Label>{ Lang[ 'WEEK_TO_DISPLAY' ][ lang ] }</Label>
              <Input
                type='number'
                value={ currentTip.injoy_week_number }
                onChange={ e => updateCurrentTip( 
                  'injoy_week_number', 
                  parseInt( e.target.value ) > 0 && parseInt( e.target.value ) < 13
                    ? parseInt( e.target.value )
                    : ''
                )}
              />
            </FormGroup>
          )
        }
        <FormGroup>
          <Label>{ Lang[ 'STATUS' ][ lang ] }</Label>
          <Input
            type='select'
            value={ currentTip.status }
            onChange={ e => updateCurrentTip( 'status', e.target.value ) }>
            <option value='ACTIVE' >{ Lang[ 'ACTIVE' ][ lang ] }</option>
            <option value='INACTIVE' >{ Lang[ 'INACTIVE' ][ lang ] }</option>
          </Input>
        </FormGroup>
        {
          currentTip.description_type === 'text' ? (
            <FormGroup>
              <MultiLangRTE
                label={ Lang[ 'CONTENT' ][ lang ] }
                value={ currentTip.content }
                onChange={ val => updateCurrentTip( 'content', val ) }
                selectedLanguage={ lang } />
            </FormGroup>
          ) : (
            <>
              <FormGroup>
                <Label>{ Lang[ 'VIDEO_TYPE' ][ lang ] }</Label>
                <Input
                  type='select'
                  value={ currentTip.video_type }
                  onChange={ e => updateCurrentTip( 'video_type', e.target.value ) } >
                  <option value='youtube' >Youtube</option>
                  <option value='vimeo' >Vimeo</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>{ Lang[ 'VIDEO_URL' ][ lang ] }</Label>
                <Input
                  type='text'
                  value={ currentTip.video_url }
                  onChange={ e => updateCurrentTip( 'video_url', e.target.value ) } />
              </FormGroup>
            </>
          )
        }
        {
          tipType === 'selectedTip' && <>
            <FormGroup>
              <Label>{ Lang[ 'UPLOAD_FILES' ][ lang ] }</Label>
              <FilePond
                server=""
                files={ files }
                instantUpload={ false }
                allowFileEncode={ true }
                allowMultiple={ false }
                allowFileTypeValidation={ true }
                allowImagePreview={ true }
                allowPdfPreview={ true }
                onupdatefiles={ fileItems => {
                  let tmpFiles = []
                  fileItems.map( item => tmpFiles.push( item.file ) )
                  updateFiles( tmpFiles )
                }}
              />
              <Row style={{ display: 'flex', flexWrap: 'nowrap' }} >
                <Col sm={ 9 } className='pr-0' >
                  <Input
                    type='text'
                    value={ fileTitle }
                    className='w-100'
                    placeholder={ Lang[ 'TITLE' ][ lang ] }
                    onChange={ e => updateFileTitle( e.target.value ) } />
                </Col>
                <Col sm={ 3 } >
                  <Button
                    color='primary'
                    className='w-100'
                    disabled={ files.length === 0 }
                    onClick={ () => { 
                      let reader = new FileReader()
                      reader.onload = () => {
                        let tmp = {
                          file: {
                            raw: reader.result,
                            filename: files[ 0 ].name
                          },
                          title: fileTitle || files[ 0 ].name.split( '.' )[ 0 ]
                        }
                        createSelectedTipFile( currentTip.id, tmp )
                      }
                      reader.readAsDataURL( files[ 0 ] )
                      updateFiles( [] )
                      updateFileTitle( '' )
                    }}>
                    { Lang[ 'SUBMIT' ][ lang ] }
                  </Button>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'CURRENT_UPLOADED_FILES' ][ lang ] }</Label>
              <ReactTable
                data={ selectedTipFiles }
                columns={[
                  {
                    Header: Lang[ 'TITLE' ][ lang ],
                    accessor: 'id',
                    Cell: row => row.original.title
                  },
                  {
                    Header: Lang[ 'ACTIONS' ][ lang ],
                    accessor: 'id',
                    style:{
                      "display": "flex",
                      "justify-content": "center"
                    },
                    Cell: row => (
                      <>
                        {
                          row.original.file.url && <Button
                            className='mr-2'
                            color='primary'
                            onClick={ () => window.open( row.original.file.url ) }>
                            { Lang[ 'DOWNLOAD_FILE' ][ lang ] }
                          </Button>
                        }
                        <Button
                          color='danger'
                          onClick={ () => updateShowDeleteFileId( row.original.id ) }>
                          { Lang[ 'DELETE' ][ lang ] }
                        </Button>
                      </>
                    )
                  }
                ]}
                pageSize={ 5 }
                showPagination={ true } 
              />
            </FormGroup>
            <DeleteConfirmation
              handleModalClose={ () => updateShowDeleteFileId( 0 ) }
              deleteOpen={ showDeleteFileId > 0 }
              confirmAction={ () => {
                removeSelectedTipFile( currentTip.id, showDeleteFileId ) 
                updateShowDeleteFileId( 0 )
              }}
              content={ Lang[ 'DELETE_CONFIRMATION_FILE' ][ lang ] }
              lang={ lang } />
          </>
        }
      </Form>
    </>
  )
}

export default TipForm