import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      centerGroups: [],
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    } )
    requestError = error => {
      toast.error( error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false
    } )}

    getCenterGroups = query => Get(
      `/api/v1/center_groups`,
      this.getCenterGroupsSuccess,
      this.getCenterGroupsError,
      this.load
    )
    getCenterGroupsSuccess = payload => {
      let tmp = _.cloneDeep( this.state.centerGroups )
      payload.data.length > 0 && payload.data.map( item => {
        tmp.push({
          id: item.id,
          label: item.name,
          value: item.name
        })
      })
      return this.setState({ centerGroups: tmp })
    }
    getCenterGroupsError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            onLoadCenterGroups={ this.state.loading }
            centerGroups={ this.state.centerGroups }
            getCenterGroups={ this.getCenterGroups } />
        </>
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect ( mapStateToProps )( WithHOC )
}

export default HOC
