import React from 'react'
import _ from 'lodash'
import {
  Form, FormGroup,
  Label, Input
} from 'reactstrap'

import Lang from 'Lang/General'

const RoleForm = ({
  roleType,
  currentRole,
  onChangeRolesHOC,
  lang
}) => {
  const updateRole = ( key, val ) => {
    let tmp = _.cloneDeep( currentRole )
    tmp[ key ] = val
    onChangeRolesHOC( roleType, tmp )
  }
  return(
    <>
      <Form>
        <FormGroup>
          <Label>{ Lang[ 'NAME' ][ lang ] }</Label>
          <Input
            type='text'
            value={ currentRole.name }
            onChange={ e => updateRole( 'name', e.target.value ) }/>
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'REMARK' ][ lang ] }</Label>
          <Input
            type='text'
            value={ currentRole.remark }
            onChange={ e => updateRole( 'remark', e.target.value ) }/>
        </FormGroup>
      </Form>
    </>
  )
}

export default RoleForm