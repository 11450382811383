import React from 'react'
import _ from 'lodash'
import {
  Form, FormGroup,
  Label, Input
} from 'reactstrap'

import MultiLangInput from 'components/Input/MultiLang'
import Lang from 'Lang/General'

const OnDemandCategoryForm = ({
  onDemandCategoryType,
  currentOnDemandCategory,
  onChangeOnDemandCategoriesHOC,
  lang
}) => {
  const updateOnDemandCategory = ( key, val ) => {
    let tmp = _.cloneDeep( currentOnDemandCategory )
    tmp[ key ] = val
    onChangeOnDemandCategoriesHOC( onDemandCategoryType, tmp )
  }
  return(
    <>
      <Form>
        <MultiLangInput
          label={ Lang[ 'NAME' ][ lang ] }
          value={ currentOnDemandCategory.name }
          onChange={ val => updateOnDemandCategory( 'name', val ) }
          selectedLanguage={ lang } />
        <FormGroup>
          <Label>{ Lang[ 'STATUS' ][ lang ] }</Label>
          <Input
            type='select'
            value={ currentOnDemandCategory.status }
            onChange={ e => updateOnDemandCategory( 'status', e.target.value ) } >
            <option value='ACTIVE' >{ Lang[ 'ACTIVE' ][ lang ] }</option>
            <option value='INACTIVE' >{ Lang[ 'INACTIVE' ][ lang ] }</option>
          </Input>
        </FormGroup>
      </Form>
    </>
  )
}

export default OnDemandCategoryForm