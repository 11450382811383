import React, { Component } from 'react'
import _ from 'lodash'

import StatusModal from "components/Modal/status"
import { Get } from 'utils/axios'
import { convertObjToBase64 } from "utils/objToBase64"

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showStatusModal: false,
      statusModalType: "",
      onClickStatusModalButton: () => {},
      selectedForm: {},
      forms: {
        data: [],
        meta: {}
      }
    }

    load = ( param ) => this.setState({ loading: param })
    requestError = ( error ) =>
      this.setState({
        showStatusModal: true,
        statusModalType: "negative",
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }),
        statusModalMessage: error,
      })
    requestSuccess = ( success ) =>
      this.setState({
        showStatusModal: true,
        statusModalType: "positive",
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }),
        statusModalMessage: success,
      })

    onChangeFormsHOC = ( key, val ) => this.setState({ [ key ]: val })

    getForms = ( query ) => Get(
      `/api/v1/webform_templates?query=${ convertObjToBase64(
        query
      )}`,
      this.getFormsSuccess,
      this.getFormsError,
      this.load
    )
    getFormsSuccess = ( payload ) => this.setState({ forms: payload })
    getFormsError = ( error ) => this.requestError( error )

    getSelectedForm = ( id ) => Get(
      `/api/v1/webform_templates/${ id }`,
      this.getSelectedFormSuccess,
      this.getSelectedFormError,
      this.load
    )
    getSelectedFormSuccess = ( payload ) => this.setState({ selectedForm: payload })
    getSelectedFormError = ( error ) => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            onChangeFormsHOC={ this.onChangeFormsHOC }
            getForms={ this.getForms }
            getSelectedForm={ this.getSelectedForm }
            onLoadForms={ this.state.loading }
            selectedForm={ this.state.selectedForm }
            forms={ this.state.forms } />
          <StatusModal
            isOpen={ this.state.showStatusModal }
            type={ this.state.statusModalType }
            message={ this.state.statusModalMessage }
            onClick={ this.state.onClickStatusModalButton }
          />
        </>
      )
    }
  }

  return WithHOC 
}

export default HOC
