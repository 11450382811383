import React from 'react'
import { MdDesktopWindows, MdTablet, MdSmartphone } from 'react-icons/md'
import { Button } from 'reactstrap'

const ViewportSizeSelector = ({
  currentWidth,
  setWidth
}) => {
  return (
    <div className="d-flex align-items-center">
      <Button 
        color={ currentWidth == '100%' ? 'primary' : 'light' }
        style={{ marginLeft: 'auto' }}
        onClick={() => setWidth( '100%' )}>
        <MdDesktopWindows style={{ fontSize: '24px' }} />
      </Button>
      <Button 
        color={ currentWidth === '768px' ? 'primary' : 'light' }
        onClick={() => setWidth( '768px' )}>
        <MdTablet style={{ fontSize: '24px' }} />
      </Button>
      <Button 
        color={ currentWidth === '450px' ? 'primary' : 'light' }
        onClick={() => setWidth( '450px' )}>
        <MdSmartphone style={{ fontSize: '24px' }} />
      </Button>
    </div>
  )
}

export default ViewportSizeSelector