import React, { useState, useEffect } from 'react'
import { 
  Button, Form, FormGroup, Label, Input
} from 'reactstrap'
import { MdDesktopWindows, MdTablet, MdSmartphone } from 'react-icons/md'
import { SketchPicker } from 'react-color';
import _ from 'lodash'

import Lang from 'Lang/General'

const ColumnEdit = props => {
  const [ screenMode, updateScreenMode ] = useState( 'MOBILE' )
  const paddingComponents = [
    { key: 'paddingTop', name: 'TOP' },
    { key: 'paddingRight', name: 'RIGHT' },
    { key: 'paddingBottom', name: 'BOTTOM' },
    { key: 'paddingLeft', name: 'LEFT' },
  ]
  const marginComponents = [
    { key: 'marginTop', name: 'TOP' },
    { key: 'marginRight', name: 'RIGHT' },
    { key: 'marginBottom', name: 'BOTTOM' },
    { key: 'marginLeft', name: 'LEFT' }
  ]
  
  useEffect( () => {
    let tmpCol = _.cloneDeep( props.selectedCol )
    if( tmpCol.style ){
      findAndSpreadMarginPadding( tmpCol.style )
      if( !tmpCol[ 'style' ][ '@media(min-width:576px)' ] ){
        tmpCol[ 'style' ][ '@media(min-width:576px)' ] = {}
      }
      if( !tmpCol[ 'style' ][ '@media(min-width:768px)' ] ){
        tmpCol[ 'style' ][ '@media(min-width:768px)' ] = {}
      }
    } else {
      tmpCol[ 'style' ] = {
        "@media(min-width:576px)":{},
        "@media(min-width:768px)":{}
      } 
    }
    switch( screenMode ){
      case 'DESKTOP':
        tmpCol[ 'maxWidth' ] = tmpCol[ 'style' ][ '@media(min-width:768px)' ][ 'maxWidth' ] ? tmpCol[ 'style' ][ '@media(min-width:768px)' ][ 'maxWidth' ] : ''
        tmpCol[ 'minHeight' ] = tmpCol[ 'style' ][ '@media(min-width:768px)' ][ 'minHeight' ] ? tmpCol[ 'style' ][ '@media(min-width:768px)' ][ 'minHeight' ] : ''
        tmpCol[ 'paddingTop' ] = tmpCol[ 'style' ][ '@media(min-width:768px)' ][ 'paddingTop' ] ? tmpCol[ 'style' ][ '@media(min-width:768px)' ][ 'paddingTop' ] : ''
        tmpCol[ 'paddingRight' ] = tmpCol[ 'style' ][ '@media(min-width:768px)' ][ 'paddingRight' ] ? tmpCol[ 'style' ][ '@media(min-width:768px)' ][ 'paddingRight' ] : ''
        tmpCol[ 'paddingBottom' ] = tmpCol[ 'style' ][ '@media(min-width:768px)' ][ 'paddingBottom' ] ? tmpCol[ 'style' ][ '@media(min-width:768px)' ][ 'paddingBottom' ] : ''
        tmpCol[ 'paddingLeft' ] = tmpCol[ 'style' ][ '@media(min-width:768px)' ][ 'paddingLeft' ] ? tmpCol[ 'style' ][ '@media(min-width:768px)' ][ 'paddingLeft' ] : ''
        tmpCol[ 'marginTop' ] = tmpCol[ 'style' ][ '@media(min-width:768px)' ][ 'marginTop' ] ? tmpCol[ 'style' ][ '@media(min-width:768px)' ][ 'marginTop' ] : ''
        tmpCol[ 'marginRight' ] = tmpCol[ 'style' ][ '@media(min-width:768px)' ][ 'marginRight' ] ? tmpCol[ 'style' ][ '@media(min-width:768px)' ][ 'marginRight' ] : ''
        tmpCol[ 'marginBottom' ] = tmpCol[ 'style' ][ '@media(min-width:768px)' ][ 'marginBottom' ] ? tmpCol[ 'style' ][ '@media(min-width:768px)' ][ 'marginBottom' ] : ''
        tmpCol[ 'marginLeft' ] = tmpCol[ 'style' ][ '@media(min-width:768px)' ][ 'marginLeft' ] ? tmpCol[ 'style' ][ '@media(min-width:768px)' ][ 'marginLeft' ] : ''
        break
      case 'TABLET':
        tmpCol[ 'maxWidth' ] = tmpCol[ 'style' ][ '@media(min-width:576px)' ][ 'maxWidth' ] ? tmpCol[ 'style' ][ '@media(min-width:576px)' ][ 'maxWidth' ] : ''
        tmpCol[ 'minHeight' ] = tmpCol[ 'style' ][ '@media(min-width:576px)' ][ 'minHeight' ] ? tmpCol[ 'style' ][ '@media(min-width:576px)' ][ 'minHeight' ] : ''
        tmpCol[ 'paddingTop' ]  = tmpCol[ 'style' ][ '@media(min-width:576px)' ][ 'paddingTop' ] ? tmpCol[ 'style' ][ '@media(min-width:576px)' ][ 'paddingTop' ] : ''
        tmpCol[ 'paddingRight' ]  = tmpCol[ 'style' ][ '@media(min-width:576px)' ][ 'paddingRight' ] ? tmpCol[ 'style' ][ '@media(min-width:576px)' ][ 'paddingRight' ] : ''
        tmpCol[ 'paddingBottom' ]  = tmpCol[ 'style' ][ '@media(min-width:576px)' ][ 'paddingBottom' ] ? tmpCol[ 'style' ][ '@media(min-width:576px)' ][ 'paddingBottom' ] : ''
        tmpCol[ 'paddingLeft' ]  = tmpCol[ 'style' ][ '@media(min-width:576px)' ][ 'paddingLeft' ] ? tmpCol[ 'style' ][ '@media(min-width:576px)' ][ 'paddingLeft' ] : ''
        tmpCol[ 'marginTop' ]  = tmpCol[ 'style' ][ '@media(min-width:576px)' ][ 'marginTop' ] ? tmpCol[ 'style' ][ '@media(min-width:576px)' ][ 'marginTop' ] : ''
        tmpCol[ 'marginRight' ]  = tmpCol[ 'style' ][ '@media(min-width:576px)' ][ 'marginRight' ] ? tmpCol[ 'style' ][ '@media(min-width:576px)' ][ 'marginRight' ] : ''
        tmpCol[ 'marginBottom' ]  = tmpCol[ 'style' ][ '@media(min-width:576px)' ][ 'marginBottom' ] ? tmpCol[ 'style' ][ '@media(min-width:576px)' ][ 'marginBottom' ] : ''
        tmpCol[ 'marginLeft' ]  = tmpCol[ 'style' ][ '@media(min-width:576px)' ][ 'marginLeft' ] ? tmpCol[ 'style' ][ '@media(min-width:576px)' ][ 'marginLeft' ] : ''
        break
      case 'MOBILE':
        tmpCol[ 'maxWidth' ] = tmpCol[ 'style' ][ 'maxWidth' ] ? tmpCol[ 'style' ][ 'maxWidth' ] : ''
        tmpCol[ 'minHeight' ] = tmpCol[ 'style' ][ 'minHeight' ] ? tmpCol[ 'style' ][ 'minHeight' ] : ''
        tmpCol[ 'paddingTop' ]  = tmpCol[ 'style' ][ 'paddingTop' ] ? tmpCol[ 'style' ][ 'paddingTop' ] : ''
        tmpCol[ 'paddingRight' ]  = tmpCol[ 'style' ][ 'paddingRight' ] ? tmpCol[ 'style' ][ 'paddingRight' ] : ''
        tmpCol[ 'paddingBottom' ]  = tmpCol[ 'style' ][ 'paddingBottom' ] ? tmpCol[ 'style' ][ 'paddingBottom' ] : ''
        tmpCol[ 'paddingLeft' ]  = tmpCol[ 'style' ][ 'paddingLeft' ] ? tmpCol[ 'style' ][ 'paddingLeft' ] : ''
        tmpCol[ 'marginTop' ]  = tmpCol[ 'style' ][ 'marginTop' ] ? tmpCol[ 'style' ][ 'marginTop' ] : ''
        tmpCol[ 'marginRight' ]  = tmpCol[ 'style' ][ 'marginRight' ] ? tmpCol[ 'style' ][ 'marginRight' ] : ''
        tmpCol[ 'marginBottom' ]  = tmpCol[ 'style' ][ 'marginBottom' ] ? tmpCol[ 'style' ][ 'marginBottom' ] : ''
        tmpCol[ 'marginLeft' ]  = tmpCol[ 'style' ][ 'marginLeft' ] ? tmpCol[ 'style' ][ 'marginLeft' ] : ''
        break
    }
    props.updateSelectedCol( tmpCol )
    let tmpContainer = _.cloneDeep( props.dom )
    tmpContainer = props.FindComponentAndPatch( tmpContainer, tmpCol )
    props.updateDom( tmpContainer )
  }, [ screenMode, props.selectedColId ] )

  const findAndSpreadMarginPadding = style => {
    _.map( style, ( val, key ) => {
      let tmpKey = _.cloneDeep( key )
      if( tmpKey.indexOf( ' ' ) > -1 ){
        delete style[ tmpKey ]
        tmpKey = tmpKey.replaceAll( ' ', '' )
        style[ tmpKey ] = val
      }
      if( tmpKey === 'padding' || tmpKey === 'margin' ){
        let tmpValArr = val.split( ' ' )
        switch( tmpValArr.length ){
          case 1: 
            style[ `${ tmpKey }Top` ] = tmpValArr[ 0 ]
            style[ `${ tmpKey }Right` ] = tmpValArr[ 0 ]
            style[ `${ tmpKey }Bottom` ] = tmpValArr[ 0 ]
            style[ `${ tmpKey }Left` ] = tmpValArr[ 0 ]
            delete style[ tmpKey ]
            break
          case 2: 
            style[ `${ tmpKey }Top` ] = tmpValArr[ 0 ]
            style[ `${ tmpKey }Right` ] = tmpValArr[ 1 ]
            style[ `${ tmpKey }Bottom` ] = tmpValArr[ 0 ]
            style[ `${ tmpKey }Left` ] = tmpValArr[ 1 ]
            delete style[ tmpKey ]
            break
          case 4:
            style[ `${ tmpKey }Top` ] = tmpValArr[ 0 ]
            style[ `${ tmpKey }Right` ] = tmpValArr[ 1 ]
            style[ `${ tmpKey }Bottom` ] = tmpValArr[ 2 ]
            style[ `${ tmpKey }Left` ] = tmpValArr[ 3 ]
            delete style[ tmpKey ]
            break
        }
      }
      if ( typeof val === 'object' && !val[ 0 ] ){
        findAndSpreadMarginPadding( style[ tmpKey ] )
      }
    })
  }

  const renderStyleSettingInput = style => (
    <>
      <span>
        { Lang[ style.name ][ props.selectedLanguage ] }
        { !_.isEmpty( props.selectedCol[ 'style' ][ style.key ] ) && <MdSmartphone className='ml-2' style={{ fontSize: '15px' }} /> }
        { props.selectedCol[ 'style' ][ '@media(min-width:576px)' ] && props.selectedCol[ 'style' ][ '@media(min-width:576px)' ][ style.key ] && <MdTablet className='ml-2' style={{ fontSize: '15px' }} /> }
        { props.selectedCol[ 'style' ][ '@media(min-width:768px)' ] && props.selectedCol[ 'style' ][ '@media(min-width:768px)' ][ style.key ] && <MdDesktopWindows className='ml-2' style={{ fontSize: '15px' }} /> }
      </span>
      <Input
        type="text"
        className="mr-1"
        value={ props.selectedCol[ style.key ] }
        onChange={ e => {
          let tmp = _.cloneDeep( props.selectedCol )
          tmp[ style.key ] = e.target.value
          switch( screenMode ){
            case 'MOBILE':
              if( !_.isEmpty( e.target.value ) ){
                tmp[ 'style' ][ style.key ] = e.target.value
              } else {
                delete tmp[ 'style' ][ style.key ]
              }
              break
            case 'TABLET':
              if( !_.isEmpty( e.target.value ) ){
                tmp[ 'style' ][ '@media(min-width:576px)' ][ style.key ] = e.target.value
              } else {
                delete tmp[ 'style' ][ '@media(min-width:576px)' ][ style.key ]
              }
              break
            case 'DESKTOP':
              if( !_.isEmpty( e.target.value ) ){
                tmp[ 'style' ][ '@media(min-width:768px)' ][ style.key ] = e.target.value
              } else {
                delete tmp[ 'style' ][ '@media(min-width:768px)' ][ style.key ]
              }
              break
          }
          props.updateSelectedCol( tmp )
          let tmpContainer = _.cloneDeep( props.dom )
          tmpContainer = props.FindComponentAndPatch( tmpContainer, tmp )
          props.updateDom( tmpContainer )
        }} />
    </>
  )

  return (
    <Form onSubmit={ e => e.preventDefault()}>
      <FormGroup>
        <Label>{ Lang[ 'SELECT_COLUMN_WIDTH' ][ props.selectedLanguage ] }</Label>
        <Input
          type="select"
          value={ props.selectedCol.col }
          onChange={ e => {
            let tmp = _.cloneDeep( props.selectedCol )
            tmp.col = parseInt( e.target.value, 10 )
            if( tmp.col === 12 ) {
              tmp.className = `col-sm-12`
            } else if( tmp.col === 6 ) {
              tmp.className = `col-sm-6`
            } else if( tmp.col === 4 ) {
              tmp.className = `col-sm-4`
            } else if( tmp.col === 3 ) {
              tmp.className = `col-sm-3`
            }
            props.updateSelectedCol( tmp )
            let tmpContainer = _.cloneDeep( props.dom )
            tmpContainer = props.FindComponentAndPatch( tmpContainer, tmp )
            props.updateDom( tmpContainer )
          }}>
          <option></option>
          <option value={ 12 }>{ '100%' }</option>
          <option value={ 6 }>{ '50%' }</option>
          <option value={ 4 }>{ '33%' }</option>
          <option value={ 3 }>{ '25%' }</option>
        </Input>
      </FormGroup>
      <FormGroup>
        <Label className='mr-2' >{ Lang[ 'SCREEN_MODE' ][ props.selectedLanguage ] }:</Label>
        <Button 
          color={ screenMode === 'MOBILE' ? 'success' : 'primary' }
          className='mr-2'
          onClick={ () => updateScreenMode( 'MOBILE' ) }>
          <MdSmartphone style={{ fontSize: '24px' }} />
        </Button>
        <Button 
          color={ screenMode === 'TABLET' ? 'success' : 'primary' }
          className='mr-2'
          onClick={ () => updateScreenMode( 'TABLET' ) }>
          <MdTablet style={{ fontSize: '24px' }} />
        </Button>
        <Button 
          color={ screenMode === 'DESKTOP' ? 'success' : 'primary' }
          className='mr-2'
          onClick={ () => updateScreenMode( 'DESKTOP' ) }>
          <MdDesktopWindows style={{ fontSize: '24px' }} />
        </Button>
      </FormGroup>
      <FormGroup>
        { renderStyleSettingInput( { key: 'minHeight', name: 'COLUMN_MIN_HEIGHT' } ) }
      </FormGroup>
      <FormGroup>
        { renderStyleSettingInput( { key: 'maxWidth', name: 'COLUMN_MAX_WIDTH' } ) }
      </FormGroup>
      <FormGroup>
        <Label>{ Lang[ 'ADJUST_PADDING' ][ props.selectedLanguage ] }</Label><br/>
        { paddingComponents.map( style => renderStyleSettingInput( style ) ) }
      </FormGroup>
      <FormGroup>
        <Label>{ Lang[ 'ADJUST_MARGIN' ][ props.selectedLanguage ] }</Label><br/>
        { marginComponents.map( style => renderStyleSettingInput( style ) ) }
      </FormGroup>
      {
        props.selectedCol.component === 'divider' && (
          <FormGroup>
            <Label>{ Lang[ 'DIVIDER_COLOR' ][ props.selectedLanguage ] }</Label>
            <SketchPicker 
              disableAlpha={ true }
              color={ props.selectedCol.children[ 0 ].style.borderBottomColor ? props.selectedCol.children[ 0 ].style.borderBottomColor : '' } 
              onChange={ col => {
                let tmp = _.cloneDeep( props.selectedCol )
                tmp.children[ 0 ].style.borderBottomColor = col.hex
                props.updateSelectedCol( tmp )
                let tmpContainer = _.cloneDeep( props.dom )
                tmpContainer = props.FindComponentAndPatch( tmpContainer, tmp )
                props.updateDom( tmpContainer )
              }} />
          </FormGroup>
        )
      }
      <FormGroup>
        <Label>{ Lang[ 'BACKGROUND_COLOR' ][ props.selectedLanguage ] }</Label>
        <SketchPicker 
          disableAlpha={true}
          color={ props.selectedCol.style.backgroundColor ? props.selectedCol.style.backgroundColor : '' } 
          onChange={ col => {
            let tmp = _.cloneDeep( props.selectedCol )
            tmp.style.backgroundColor = col.hex
            props.updateSelectedCol( tmp )
            let tmpContainer = _.cloneDeep( props.dom )
            tmpContainer = props.FindComponentAndPatch( tmpContainer, tmp )
            props.updateDom( tmpContainer )
          }} />
      </FormGroup>
      <FormGroup>
        <Button
          disabled={ 
            _.find( props.dom.children[ 0 ].children, { id: props.selectedCol.id }).style.display === "flex" &&
            _.find( props.dom.children[ 0 ].children, { id: props.selectedCol.id }).style.flexDirection === "column" &&
            _.find( props.dom.children[ 0 ].children, { id: props.selectedCol.id }).style.alignItems === "flex-start" &&
            _.find( props.dom.children[ 0 ].children, { id: props.selectedCol.id }).style.justifyContent === "center"
          }
          color='primary'
          style={{
            marginBottom:"5px",
            marginRight:"5px"
          }}
          onClick={() => {
            let tmp = _.cloneDeep( props.selectedCol )
            tmp.style = { ...tmp.style, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }
            props.updateSelectedCol( tmp )
            let tmpContainer = _.cloneDeep( props.dom )
            tmpContainer = props.FindComponentAndPatch( tmpContainer, tmp )
            props.updateDom( tmpContainer )
          }}>
          {
            Lang[ 'MAKE_THINGS_ALIGN_LEFT' ][ props.selectedLanguage ]
          }
        </Button>
        <Button
          disabled={ 
            _.find( props.dom.children[ 0 ].children, { id: props.selectedCol.id }).style.display === "flex" &&
            _.find( props.dom.children[ 0 ].children, { id: props.selectedCol.id }).style.flexDirection === "column" &&
            _.find( props.dom.children[ 0 ].children, { id: props.selectedCol.id }).style.alignItems === "center" &&
            _.find( props.dom.children[ 0 ].children, { id: props.selectedCol.id }).style.justifyContent === "center"
          }
          color='primary'
          style={{
            marginBottom:"5px",
            marginRight:"5px"
          }}
          onClick={() => {
            let tmp = _.cloneDeep( props.selectedCol )
            tmp.style = { ...tmp.style, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }
            props.updateSelectedCol( tmp )
            let tmpContainer = _.cloneDeep( props.dom )
            tmpContainer = props.FindComponentAndPatch( tmpContainer, tmp )
            props.updateDom( tmpContainer )
          }}>
          {
            Lang[ 'MAKE_THINGS_CENTER' ][ props.selectedLanguage ]
          }
        </Button>
        <Button
          disabled={ 
            _.find( props.dom.children[ 0 ].children, { id: props.selectedCol.id }).style.display === "flex" &&
            _.find( props.dom.children[ 0 ].children, { id: props.selectedCol.id }).style.flexDirection === "column" &&
            _.find( props.dom.children[ 0 ].children, { id: props.selectedCol.id }).style.alignItems === "flex-end" &&
            _.find( props.dom.children[ 0 ].children, { id: props.selectedCol.id }).style.justifyContent === "center"
          }
          color='primary'
          style={{
            marginBottom:"5px",
            marginRight:"5px"
          }}
          onClick={() => {
            let tmp = _.cloneDeep( props.selectedCol )
            tmp.style = { ...tmp.style, display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center' }
            props.updateSelectedCol( tmp )
            let tmpContainer = _.cloneDeep( props.dom )
            tmpContainer = props.FindComponentAndPatch( tmpContainer, tmp )
            props.updateDom( tmpContainer )
          }}>
          {
            Lang[ 'MAKE_THINGS_ALIGN_RIGHT' ][ props.selectedLanguage ]
          }
        </Button>
        <Button
          disabled={ 
            _.find( props.dom.children[ 0 ].children, { id: props.selectedCol.id }).style.display === "block" &&
            _.find( props.dom.children[ 0 ].children, { id: props.selectedCol.id }).style.alignItems === "center" &&
            _.find( props.dom.children[ 0 ].children, { id: props.selectedCol.id }).style.justifyContent === "center"
          }
          color='primary'
          style={{
            marginBottom:"5px",
            marginRight:"5px"
          }}
          onClick={() => {
            let tmp = _.cloneDeep( props.selectedCol )
            tmp.style = { ...tmp.style, display: 'block', alignItems: 'center', justifyContent: 'center' }
            props.updateSelectedCol( tmp )
            let tmpContainer = _.cloneDeep( props.dom )
            tmpContainer = props.FindComponentAndPatch( tmpContainer, tmp )
            props.updateDom( tmpContainer )
          }}>
          {
            Lang[ 'MAKE_THINGS_NORMAL' ][ props.selectedLanguage ]
          }
        </Button>
      </FormGroup>
      <FormGroup>
        <Button 
          color="danger"
          onClick={() => {
            props.updateSelectedColId( '' )
            props.updateSelectedCol( {} )
            let tmpContainer = _.cloneDeep( props.dom )
            tmpContainer = props.FindComponentAndPatch( tmpContainer, props.selectedCol, true )
            props.updateDom( tmpContainer )
          }}>
          { Lang[ 'REMOVE_COMPONENT' ][ props.selectedLanguage ] }
        </Button>
      </FormGroup>
    </Form>
  )
}

export default ColumnEdit