import React, { Component } from "react"
import { connect } from "react-redux"

import StatusModal from "components/Modal/status"
import { Get, Post, Put, Delete } from "utils/axios"
import { toggleMediaDatabase } from "actions/MediaDatabaseToggle"
import { convertObjToBase64 } from "utils/objToBase64"
import Lang from "Lang/General"

const LandingPageTemplatesHOC = WrappedComponent => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showStatusModal: false,
      statusModalType: "",
      onClickStatusModalButton: () => {},
      statusModalMessage: "",
      showCreateModal: false,
      showUpdateModal: false,
      showDeleteModal: false,
      landingPage: [],
      selectedTemplatePage: {},
      newPage: {
        name: Lang[ "BLANK" ],
        description: Lang[ "BLANK" ],
        url: "",
        status: "inactive",
        content: {},
        center_group_ids: [],
        countries: [],
        center_ids: [],
        is_hide_footer: false
      },
      allLandingPage: [],
    }

    load = param => this.setState({ loading: param })
    onChangeLandingPageHOC = ( key, value ) => this.setState({ [ key ]: value })
    requestError = error =>
      this.setState({
        showStatusModal: true,
        statusModalType: "negative",
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }),
        statusModalMessage: error,
      })
    requestSuccess = success =>
      this.setState({
        showStatusModal: true,
        statusModalType: "positive",
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }),
        statusModalMessage: success,
      })

    createLandingPage = data =>
      Post(
        `/api/v1/ols_landing_page_templates`,
        data,
        this.createLandingPageSuccess,
        this.createLandingPageError,
        this.load
      )
    createLandingPageSuccess = payload => {
      let tmp = {
        page: this.state.page,
        is_paginated: true,
      }
      this.requestSuccess(
        Lang[ "SECTION_CREATE_SUCCESS" ][ this.props.data.languageReducer.lang ]
      )
      this.getLandingPage( tmp )
      this.setState({ showCreateModal: false })
    }
    createLandingPageError = error => this.requestError( error )

    getLandingPage = query =>
      Get(
        `/api/v1/ols_landing_page_templates?query=${ convertObjToBase64(
          query
        )}`,
        this.getLandingPageSuccess,
        this.getLandingPageError,
        this.load
      )
    getLandingPageSuccess = payload => {
      this.setState({ landingPage: payload })
    }
    getLandingPageError = error => this.requestError( error )

    getAllLandingPage = () =>
      Get(
        `/api/v1/ols_landing_page_templates?query=${ convertObjToBase64({
          is_paginated: false,
          page: 1,
        })}`,
        this.getAllLandingPageSuccess,
        this.getAllLandingPageError,
        this.load
      )
    getAllLandingPageSuccess = payload => {
      this.setState({ allLandingPage: payload.data })
    }
    getAllLandingPageError = error => this.requestError( error )

    updateLandingPage = data =>
      Put(
        `/api/v1/ols_landing_page_templates/${ data.id }`,
        data,
        this.updateLandingPageSuccess,
        this.updateLandingPageError,
        this.load
      )
    updateLandingPageSuccess = payload => {
      let tmp = {
        page: this.state.page,
        is_paginated: true,
      }
      this.requestSuccess(
        Lang[ "SECTION_UPDATE_SUCCESS" ][ this.props.data.languageReducer.lang ]
      )
      this.getLandingPage( tmp )
      this.setState({ showUpdateModal: false, selectedTemplatePage: {} })
    }
    updateLandingPageError = error => this.requestError( error )

    deleteLandingPage = id =>
      Delete(
        `/api/v1/ols_landing_page_templates/${ id }`,
        this.deleteLandingPageSuccess,
        this.deleteLandingPageError,
        this.load
      )
    deleteLandingPageSuccess = payload => {
      let tmp = {
        page: this.state.page,
        is_paginated: true,
      }
      this.requestSuccess(
        Lang[ "SECTION_DELETE_SUCCESS" ][ this.props.data.languageReducer.lang ]
      )
      this.getLandingPage( tmp )
      this.setState({ showDeleteModal: false, selectedTemplatePage: {} })
    }
    deleteLandingPageError = error => {
      this.requestError( error.Error )
      this.setState({ showDeleteModal: false })
    }

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            onLoadLandingTemplate={ this.state.loading }
            showCreateModal={ this.state.showCreateModal }
            showUpdateModal={ this.state.showUpdateModal }
            showDeleteModal={ this.state.showDeleteModal }
            onChangeLandingPageHOC={ this.onChangeLandingPageHOC }
            createLandingPage={ this.createLandingPage }
            getLandingPage={ this.getLandingPage }
            getAllLandingPage={ this.getAllLandingPage }
            updateLandingPage={ this.updateLandingPage }
            deleteLandingPage={ this.deleteLandingPage }
            newPage={ this.state.newPage }
            selectedTemplatePage={ this.state.selectedTemplatePage }
            landingPage={ this.state.landingPage }
            allLandingPage={ this.state.allLandingPage }
            page={ this.state.page }
          />
          <StatusModal
            isOpen={ this.state.showStatusModal }
            type={ this.state.statusModalType }
            message={ this.state.statusModalMessage }
            onClick={ this.state.onClickStatusModalButton }
          />
        </>
      )
    }
  }

  const mapStateToProps = state => ({ data: state })

  return connect(mapStateToProps, { toggleMediaDatabase })( WithHOC )
}

export default LandingPageTemplatesHOC
