import React from 'react'
import Radium from 'proofpoint-radium'

const FooterLine = props => {
  return (
    <div
      className={ props.item.className }
      style={{
        ...props.item.style,
        order: props.item.mobileArrangement,
        '@media ( min-width: 768px )': {
          order: props.item.desktopArrangement
        }
      }}>
      {
        props.item.children.map( child => {
          return (
            <div 
              id={ child.id } 
              className={ child.className }
              style={ child.style }></div>
          ) 
        })
      }
    </div>
  )
}

export default Radium( FooterLine )