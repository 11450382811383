import React from 'react'
import {
  Modal, ModalBody, ModalFooter, ModalHeader,
  Form, FormGroup, FormFeedback, Label, Input, Button,
  Col
} from 'reactstrap'
import _ from 'lodash'

import MediaDatabase from 'SharedModules/MediaDatabase'
import NoPicture from 'assets/images/imagePreview.png'

import Lang from 'Lang/General'

const Create = props => {
  return(
    <Modal
      size="xl"
      isOpen={ props.showCreateSocialMedia }
      toggle={ () => props.onChangeSocialMediaHOC( 'showCreateSocialMedia', false ) }>
      <ModalHeader toggle={ () => props.onChangeSocialMediaHOC( 'showCreateSocialMedia', false ) }>{ 'Create social media platform' }</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row>
            <Label sm={2}>{'Name'}</Label>
            <Col sm={ 10 }>
              <Input
                type="text"
                value={ props.newSocialMediaPlatform.platform_name }
                onChange={ e => {
                  let tmp = _.cloneDeep( props.newSocialMediaPlatform )
                  tmp[ 'platform_name' ] = e.target.value
                  props.onChangeSocialMediaHOC( 'newSocialMediaPlatform', tmp )
                }} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2}>{ 'Icon' }</Label>
            <Col sm={ 10 } className="d-flex flex-column">
              <img src={ props.newSocialMediaPlatform.icon_url === '' ? NoPicture : props.newSocialMediaPlatform.icon_url } style={{ width: '150px', height: '150px'}} />
              <Button
                color="primary"
                style={{ marginTop: '10px', width: '150px' }}
                onClick={() => {
                  props.toggleMediaDatabase( true )
                }}>{ 'Media database' }</Button>
            </Col>
          </FormGroup>
        </Form>
        {

        }
        <MediaDatabase
          onSelectedImage={( param ) => {
            let tmp = _.cloneDeep( props.newSocialMediaPlatform )
            tmp['icon_url'] = param
            props.onChangeSocialMediaHOC( 'newSocialMediaPlatform', tmp )
            props.toggleMediaDatabase( false )
          }} />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={ () => props.createSocialMediaPlatform() }>{ Lang[ 'SUBMIT' ][ props.data.languageReducer.lang ] }</Button>
        <Button color="secondary" onClick={ () => props.onChangeSocialMediaHOC( 'showCreateSocialMedia', false ) }>{'Cancel'}</Button>
      </ModalFooter>
    </Modal>
  )
}

export default Create
