import React, { Fragment, useState, useEffect } from 'react'
import _ from 'lodash'
import {
  Form, FormGroup, Label, Input, Col, FormFeedback, Container, Card, CardBody,
  Modal, ModalBody, ModalHeader, ModalFooter, Button,
} from 'reactstrap'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { connect } from 'react-redux'
import Moment from 'moment'
import classnames from 'classnames'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'
import { validate } from 'utils/validatePhoneNumber'

const CreateScenarioForm = ( props ) => {
  const langButton = [ 'en', 'de', 'es', 'it', 'fr', 'nl' ]
  const [ selectedLanguageButton, updateSelectedLanguage ] = useState( 'en' )

  useEffect( () => {
    props.onChangeScenarioHOC( 'scenariosErrMsg', {} )
  }, [props.showCreateScenario] )

  return(
    <Modal
      isOpen={ props.showCreateScenario }
      size="lg">
      <ModalHeader toggle={ () => props.onChangeScenarioHOC('showCreateScenario', false ) }>{ Lang['CREATE_SCENARIO'][ props.selectedLanguage ] }
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>{ Lang['NAME'][ props.selectedLanguage ] }<span style={{ color: 'red'}}>*</span></Label>
            <div className="d-flex pt-1">
              {
                langButton.map( button => {
                  return(
                    <Button
                      className="btn-square btn-wide mr-0" size="sm"
                      color={ `${ selectedLanguageButton === button ? 'primary' : 'light' }` }
                      onClick={ () => updateSelectedLanguage( button ) }>{ button }</Button>
                  )
                })
              }
            </div>
            <Input
              className="mt-2"
              value={ props.newScenarioData.name[selectedLanguageButton] }
              onChange={ e => {
                let tmp = _.cloneDeep( props.newScenarioData )
                tmp['name'][ selectedLanguageButton ] = e.target.value
                props.onChangeScenarioHOC( 'newScenarioData', tmp )
                }}/>
            {
              props.scenariosErrMsg.name && (
                <span style={{ color: 'red', marginBottom: '15px' }}>{ props.scenariosErrMsg.name }</span>
              )
            }
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          style={{ float: 'right' }}
          onClick={ () => {
            props.createScenario( props.newScenarioData )
          }}>{ Lang['CREATE'][ props.selectedLanguage ] }
        </Button>
      </ModalFooter>
    </Modal>

  )
}
const mapStateToProps = state => ({ dataReducer: state })

export default connect( mapStateToProps )( CreateScenarioForm )
