import React, { useState } from 'react'
import { Button } from 'reactstrap'
import Lang from 'Lang/General'
import { connect } from 'react-redux'

const ArkPagination = ({
  page,
  totalPages,
  onChangePage,
  onSearch,
  selectedSearchColumn,
  data,
  searchType,
  normalSearchValue
}) => {
  const [ interval, updateInterval ] = useState( 10 )
  return(
    <div style={{ marginTop: '20px', alignItems: 'center', justifyContent: 'center' }}>
      <div  className="d-flex pt-1">
        <Button
          className="mb-2 mr-2 btn-icon btn-icon-only"
          onClick={ () => {
            if( selectedSearchColumn.length > 0 || normalSearchValue !== '' ){
              onSearch( { page: page - 1, is_paginated: true }, searchType, selectedSearchColumn, normalSearchValue )
            } else {
              onChangePage( page = page - 1 )
            }
          }}
          disabled={ page === 1 }
          color="primary">
          <i className="pe-7s-angle-left btn-icon-wrapper" style={{ fontSize: '25px' }} />
        </Button>
        <Button
          className="mb-2 mr-2 btn-icon"
          onClick={ () => updateInterval( interval - 10 ) }
          color="light"
          style={{
          display: `${ interval <= 10 ? 'none' : '' }`
          }}>
          ...
        </Button>
        <div className="d-flex">
        {
          totalPages && totalPages.map( pg => {
            if(( pg > interval - 10 ) && ( pg <= interval )){
              return(
                <Button
                key={ pg }
                className="mb-2 mr-2 btn-icon"
                color={ page === pg ? "secondary": "link" }
                onClick={ () => {
                  if( selectedSearchColumn.length > 0 || normalSearchValue !== ''){
                    onSearch( { page: pg, is_paginated: true }, searchType, selectedSearchColumn, normalSearchValue )
                  } else {
                    onChangePage( page = pg )
                  }
                }}>
                { pg }</Button>
              )
            }
          })
        }
        </div>
        <Button
          className="mb-2 mr-2 btn-icon"
          onClick={ () => updateInterval( interval + 10 ) }
          color="light"
          style={{
          display: `${ interval > totalPages.length ? 'none' : '' }`
          }}>
          ...
        </Button>
        <Button
          className="mb-2 mr-2 btn-icon btn-icon-only"
          onClick={ () => {
            if( selectedSearchColumn.length > 0 || normalSearchValue !== '' ) {
              onSearch( { page: page + 1, is_paginated: true }, searchType, selectedSearchColumn, normalSearchValue )
            } else {
              onChangePage( page = page + 1 )
            }
          }}
          disabled={ page === ( totalPages.length ) }
          color="primary">
          <i className="pe-7s-angle-right btn-icon-wrapper" style={{ fontSize: '25px' }}/>
        </Button>
      </div>
     </div>
  )
}

const mapStateToProps = state => ({ data: state })

const mapDispatchToProps = dispatch => ({})

export default connect( mapStateToProps, mapDispatchToProps )(ArkPagination)
