import React from 'react'
import ReactHTMLParser from 'react-html-parser'

import { ApplyColor } from 'utils/apply-color'

const Texts = props => {
  return (
    <div
      onMouseEnter={ () => props.mode === 'edit' && props.onChangeSectionEditorState && props.onChangeSectionEditorState( 'hoveredComponentId', props.id ) }
      onMouseLeave={ () => props.mode === 'edit' && props.onChangeSectionEditorState && props.onChangeSectionEditorState( 'hoveredComponentId', '' ) }
      onClick={() => {
        if( props.mode === 'edit' && props.item.variant === 'text' ) {
          props.onChangeSectionEditorState && props.onChangeSectionEditorState( 'selectedEditableComponent', props.item )
          props.onChangeSectionEditorState && props.onChangeSectionEditorState( 'selectedContainer', props.item) 
        }
      }}
      className="ck-content"
      style={{
        position: 'relative'
      }}>
      {
        props.listStyle ? (
          <style>
            {
              props.listStyle.bulletPointColor ? (
                `#${ props.id } li::marker { color: ${ props.listStyle.bulletPointColor };}`
              ) : (
                ''
              )
            }
            {
              props.listStyle.bulletPointIcon ? (
                `#${ props.id } li::marker {
                  content: "${ props.listStyle.bulletPointIcon }";
                  font-family: FontAwesome;
                }
                #${ props.id } li { margin-bottom: 10px; padding-left: 10px; }`
              ) : (
                ''
              )
            }
          </style>
        ) : (
          <></>
        )
      }
      {
        ReactHTMLParser( ApplyColor( props.content[ props.selectedLanguageShortname ] ? props.content[ props.selectedLanguageShortname ] : '' ))
      }
    </div>
  )
} 

export default Texts