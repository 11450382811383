import React, { useEffect } from 'react'
import {
  Card, CardBody, CardHeader, CardFooter,
  FormGroup,
  Label, Input, Button, UncontrolledTooltip
} from 'reactstrap'
import { connect } from 'react-redux'
import { compose } from 'redux'
import _ from 'lodash'
import Moment from 'moment'

import PageTitle from 'components/PageTitle'
import ARKTable from 'components/Table'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import DeleteConfirmation from 'components/Modal/delete'
import SystemFunnelsHOC from './actions'
import CountryHOC from './actions/country'
import CenterGroupHOC from './actions/centerGroup'
import FunnelScenarioHOC from '../FunnelScenarios/actions'
import MailingsHOC from './actions/mailings'
import CreateForm from './Create'
import UpdateForm from './Update'
import ArchivedModal from './Archived'

import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const SystemFunnels = props => {

  useEffect( () => {
    props.getCountriesEn()
    props.getCenterGroups({
      is_paginated: false,
      filter: {
        $or: [
          { status: 'ACTIVE' }
        ]
      }
    })
    props.getAllScenarios()
    props.getSystemMailing()
  }, [])

  useEffect( () => {
    if( !props.showArchivedFunnels ){
      props.getAllSystemFunnels( 
        convertObjToBase64({ 
          is_paginated: true, 
          page: 1,
          filter: {
            status: { $neq: 'ARCHIVED' }
          }
        }) 
      )
    }
  }, [ props.showArchivedFunnels ] )

  return (
    <>
      <PageTitle
        heading={ Lang[ 'SYSTEM_FUNNELS' ][ props.data.languageReducer.lang ] }
        icon="pe-7s-lintern icon-gradient bg-happy-fisher"
        buttons={[
          {
            buttonText: Lang[ 'CREATE_FUNNEL' ][ props.data.languageReducer.lang ],
            onClick: () => props.onChangeSystemFunnelsHOC( 'showCreateFunnel', true ),
            display: true
          },
          {
            buttonText: Lang[ 'ARCHIVED_FUNNELS' ][ props.data.languageReducer.lang ],
            onClick: () => {
              props.getAllSystemFunnels( 
                convertObjToBase64({ 
                  is_paginated: true, 
                  page: 1,
                  filter: {
                    status: 'ARCHIVED'
                  }
                }) 
              )
              props.onChangeSystemFunnelsHOC( 'showArchivedFunnels', true )
            },
            display: true
          }
        ]} />
      <Card className="mb-3">
        <CardHeader>{ Lang[ 'SEARCH' ][ props.data.languageReducer.lang ] }</CardHeader>
        <CardBody>
          <FormGroup>
            <Label>{ Lang[ 'TITLE' ][ props.data.languageReducer.lang ] }</Label>
            <Input
              name="campaign-type" id="campaign-type"
              placeholder={ Lang[ 'SEARCH_WITH_TITLE' ][ props.data.languageReducer.lang ] }
              value={ props.title }
              onChange={ e => props.onChangeSystemFunnelsHOC( 'title', e.target.value ) }/>
          </FormGroup>
        </CardBody>
        <CardFooter>
          <Button
            color="primary"
            className="ml-auto"
            onClick={ () => {
              let tmp = {
                page: 1,
                is_paginated: true,
                filter: {
                  title: {
                    $like: `%${ props.title }%`
                  }
                }
              }
              props.getAllSystemFunnels( convertObjToBase64( tmp ) )
            }}>{ Lang[ 'SEARCH' ][ props.data.languageReducer.lang ] }</Button>
          <Button
            color="danger"
            onClick={ () => {
              let tmp = {
                page: 1,
                is_paginated: true
              }
              props.onChangeSystemFunnelsHOC( 'title', '' )
              return props.getAllSystemFunnels( convertObjToBase64( tmp ) )
            }}>{ Lang[ 'RESET' ][ props.data.languageReducer.lang ] }</Button>
        </CardFooter>
      </Card>
      <ARKTable
        data={ props.systemFunnels.data }
        columns={[
          {
            Header: 'Title',
            accessor: 'title',
            width: 350,
            Cell: ({value} ) => (
              <>
                <span>
                { value[ props.data.languageReducer.lang ] }
                </span>
              </>
            )
          },
          {
            Header: 'Center Group',
            accessor: 'center_groups',
            Cell: ({value}) => (
              <ul>
              {
                value && value.map( (item,index) => (
                  <li key={ item.id }>{ item.name }</li>
                ))
              }
              </ul>
            )
          },
          {
            Header: 'Created on',
            accessor: 'created_at',
            Cell: ({value}) => (
              <span>
                {
                  Moment( value ).format('DD-MM-YYYY HH:mm' )
                }
              </span>
            )
          },
          {
            Header: Lang[ 'ACTION' ][ props.data.languageReducer.lang ],
            accessor: 'id',
            width: 100,
            Cell: ({ value }) => (
              <div>
                <>
                  <Button
                    id={ `System-funnel-${ value }-edit` }
                    className="mb-2 mr-2 btn-icon btn-icon-only"
                    color="primary"
                    onClick={ () => {
                      props.onChangeSystemFunnelsHOC( 'showUpdateFunnel', true )
                      props.getSelectedSystemFunnel( value )
                    }} >
                    <i className="pe-7s-note"> </i>
                  </Button>
                  <UncontrolledTooltip
                    target={ `System-funnel-${ value }-edit` }
                    placement="top">{ Lang[ 'EDIT' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                  <Button
                    id={ `System-funnel-${ value }-archive` }
                    className='mb-2 mr-2 btn-icon btn-icon-only'
                    color='warning'
                    onClick={ () => props.getSelectedSystemFunnel( value, true ) }>
                    <i className='pe-7s-notebook'> </i>
                  </Button>
                  <UncontrolledTooltip
                    target={ `System-funnel-${ value }-archive` }
                    placement='top'>{ Lang[ 'ARCHIVE' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                </>
              </div>
            )
          }
        ]}
        totalPages={ props.systemFunnelTotalPages }
        page={ props.systemFunnels.meta ? props.systemFunnels.meta.current_page : 1 }
        onChangePage={ pg => props.getAllSystemFunnels( 
          convertObjToBase64({ 
            is_paginated: true, 
            page: pg,
            filter: {
              status: { $neq: 'ARCHIVED' }
            }
          }) 
        )}
        searchComponent={ false }
        searchLabel={ 'Search with funnel title ' }
        showPagination={ false }
        onClickReset={ () => {} }
        onSearch={ () => {} }
        searchColumn={[]}
      />
      <DeleteConfirmation
        handleModalClose={ () => props.onChangeSystemFunnelsHOC( 'showArchiveSelectedFunnel', false ) }
        deleteOpen={ props.showArchiveSelectedFunnel }
        confirmAction={ () => props.updateSystemFunnel(
          { 
            ...props.selectedFunnel,
            status: props.showArchivedFunnels ? 'ACTIVE' : 'ARCHIVED' 
          },
          props.selectedFunnel.id
        )}
        content={ Lang[ 'ARCHIVE_UNARCHIVE_CONFIRMATION' ][ props.data.languageReducer.lang ] }
        lang={ props.data.languageReducer.lang } />
      <ArchivedModal { ...props } />
      {
        props.showCreateFunnel &&
          <CreateForm
            showCreateFunnel={ props.showCreateFunnel }
            onChangeSystemFunnelsHOC={ props.onChangeSystemFunnelsHOC }
            selectedLanguage={ props.data.languageReducer.lang }
            data={ props.newFunnelData }
            totalOfMailings={ props.totalOfMailings }
            mailings={ props.mailings }
            assignedMailings={props.assignedMailings}
            postSystemFunnel={ props.postSystemFunnel }
            countries={ props.countries }
            centerGroups={ props.centerGroups }
            scenariosForSelect={ props.scenariosForSelect }
            systemMailings={ props.systemMailings.data }
            systemFunnelErrorMsg={ props.systemFunnelErrorMsg }
            onLoadSystemFunnels={ props.onLoadSystemFunnels }/>
      }
      {
        props.showUpdateFunnel &&
          <UpdateForm
            showUpdateFunnel={ props.showUpdateFunnel }
            onChangeSystemFunnelsHOC={ props.onChangeSystemFunnelsHOC }
            selectedLanguage={ props.data.languageReducer.lang }
            data={ props.selectedFunnel }
            totalOfMailings={ props.totalOfMailings }
            mailings={ props.mailings }
            assignedMailings={props.assignedMailings}
            updateSystemFunnel={ props.updateSystemFunnel }
            countries={ props.countries }
            centerGroups={ props.centerGroups }
            scenariosForSelect={ props.scenariosForSelect }
            systemMailings={ props.systemMailings.data }
            systemFunnelErrorMsg={ props.systemFunnelErrorMsg }
            setFunnelMailings={ props.setFunnelMailings }
            onLoadSystemFunnels={ props.onLoadSystemFunnels } />
      }
      { 
        ( 
          props.data.ajaxReducer.ajaxCallProgress > 0 || 
          props.onLoadSystemFunnels ||
          props.onLoadCountries ||
          props.onLoadCenterGroups
        ) && <LoadingOverlay /> 
      }
    </>
  )
}

const mapStateToProps = state => ({ data: state })

export default compose(
  SystemFunnelsHOC,
  connect( mapStateToProps ),
  CountryHOC,
  CenterGroupHOC,
  FunnelScenarioHOC,
  MailingsHOC
)( SystemFunnels )
