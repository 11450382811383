import React, { Component } from 'react'
import Radium from 'proofpoint-radium'

import CoreRenderFunction from '../../core/render'

import WithSection from './actions'

class Header extends Component {

  componentDidMount = () => this.props.getSelectedSection( this.props.id )

  render = () => {
    let {
      id,
      style,
      className,
      updateMainContainerState,
      selectedSectionID,
      languages,
      selectedLanguageShortname,
      templateHeaders,
      templateHeadersBlueprints,
      selectedEditableComponent // from section editor
    } = this.props
    return (
      // <div
      //   id={ id }
      //   style={{
      //     ...style,
      //     outline: selectedSectionID === id ? '2px solid #03a9f3' : 'none'
      //   }}
      //   className={ className }
      //   onClick={() => updateMainContainerState( 'selectedSectionID', id )}>
      <>
        {
          this.props.selectedSection && (
            <CoreRenderFunction
              item={ this.props.selectedSection }
              updateMainContainerState={ updateMainContainerState }
              selectedSectionID={ selectedSectionID }
              languages={ languages }
              selectedLanguageShortname={ selectedLanguageShortname }
              templateHeaders={ templateHeaders }
              templateHeadersBlueprints={ templateHeadersBlueprints }
              selectedEditableComponent={ selectedEditableComponent } // from section editor 
            />
          )
        }
      </>
    )
  }
}

export default WithSection( Header )