import React, { Component } from 'react'

import { convertObjToBase64 } from "utils/objToBase64"
import { Get } from "utils/axios"

const FormsHOC = WrappedComponent => {
  class FormsWrappedComponent extends Component {
    state = {
      loading: false,
      forms: [],
      selectedForm: {}
    }

    load = param => this.setState({ loading: param })

    onChangeFormsHOC = ( key, value ) => this.setState({ [ key ]: value })

    getForms = query => Get(
      `/api/v1/webform_templates?query=${ convertObjToBase64( query ) }`,
      this.getFormsSuccess,
      this.getFormsError,
      this.load
    )
    getFormsSuccess = payload => this.setState({ forms: payload.data })
    getFormsError = () => {}

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          getForms={ this.getForms }
          forms={ this.state.forms }
          selectedForm={ this.state.selectedForm }
          onLoadForms={ this.state.loading }
          onChangeFormsHOC={ this.onChangeFormsHOC }
        />
      )
    }
  }

  return FormsWrappedComponent
}

export default FormsHOC
