import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import StatusModal from 'components/Modal/status'

import { Get } from 'utils/axios-template'
import { production_url } from '../../../../TemplateEditor/config'

const SiteTemplateHOC = ( WrappedComponent ) => {
  class SiteTemplateWrappedComponent extends Component {
    state = {
      loading: false,
      showStatusModal: false,
      statusModalType: '',
      onClickStatusModalButton: () => {},
      statusModalMessage: '',
      templates: []
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showStatusModal: true,
      statusModalType: 'negative',
      onClickStatusModalButton: () => this.setState({ showStatusModal: false }),
      statusModalMessage: error
    })
    requestSuccess = success => this.setState({
      showStatusModal: true,
      statusModalType: 'positive',
      onClickStatusModalButton: () => this.setState({ showStatusModal: false }),
      statusModalMessage: success
    })

    getSiteTemplate = () => Get(
      `${production_url}/templates?filters[status]=ACTIVE`,
      this.getSiteTemplateSuccess,
      this.getSiteTemplateError,
      this.load
    )
    getSiteTemplateSuccess = payload => {
      this.setState({ templates: payload })
    }
    getSiteTemplateError = error => this.requestError( error )

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            { ...this.props }
            getSiteTemplate={ this.getSiteTemplate }
            onLoadSiteTemplate={ this.state.loading }
            templates={ this.state.templates } />
          <StatusModal
            isOpen={ this.state.showStatusModal }
            type={ this.state.statusModalType }
            message={ this.state.statusModalMessage }
            onClick={ this.state.onClickStatusModalButton } />
        </Fragment>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( SiteTemplateWrappedComponent )
}

export default SiteTemplateHOC
