import React, { useEffect } from 'react'
import {
  Card, CardBody, CardHeader, CardFooter, 
  Label, FormGroup, Input, Button, UncontrolledTooltip
} from 'reactstrap'
import { connect } from 'react-redux'
import { compose } from 'redux'
import _ from 'lodash'

import { convertObjToBase64 } from 'utils/objToBase64'
import PageTitle from 'components/PageTitle'
import ARKTable from 'components/Table'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import DeleteConfirmation from 'components/Modal/delete'
import SystemMailingHOC from './actions'
import CreateMailing from './Create'
import UpdateMailing from './Update'
import ArchivedMailings from './Archived'
import Lang from 'Lang/General'

const SystemMailings = props => {
  useEffect( () => {
    if( !props.showArchivedMailings ){
      props.getSystemMailing( 
        convertObjToBase64({ 
          is_paginated: true, 
          page: 1,
          filter: {
            status: { $neq: 'ARCHIVED' }
          }
        }) 
      )
    }
  }, [ props.showArchivedMailings ] )

  const handleSecurityModulesCreate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Marketing Funnels' })
      return tmpModules.is_create
    }
  }

  const handleSecurityModulesUpdate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Marketing Funnels' })
      return tmpModules.is_update
    }
  }

  return (
    <>
      <PageTitle
        heading={ Lang[ 'SYSTEM_MAILINGS' ][ props.data.languageReducer.lang ] }
        icon="pe-7s-mail icon-gradient bg-happy-fisher"
        buttons={[
          {
            buttonText: Lang[ 'CREATE_MAILING' ][ props.data.languageReducer.lang ],
            onClick: () => {
              // let tmp = {
              //   page: props.consultants.meta ? props.consultants.meta.current_page : 1,
              //   is_paginated: true
              // }
              props.onChangeSystemMailingHOC( 'showCreateMailing', true )
            },
            display: handleSecurityModulesCreate()
          },
          {
            buttonText: Lang[ 'ARCHIVED_MAILINGS' ][ props.data.languageReducer.lang ],
            onClick: () => {
              props.getSystemMailing( 
                convertObjToBase64({ 
                  is_paginated: true, 
                  page: 1,
                  filter: {
                    status: 'ARCHIVED'
                  }
                }) 
              )
              props.onChangeSystemMailingHOC( 'showArchivedMailings', true )
            },
            display: handleSecurityModulesUpdate()
          }
        ]} />
      <Card className="mb-3">
        <CardHeader>{ Lang[ 'SEARCH' ][ props.data.languageReducer.lang ] }</CardHeader>
        <CardBody>
          <FormGroup>
            <Label>{ Lang[ 'TITLE' ][ props.data.languageReducer.lang ] }</Label>
            <Input
              name="campaign-type" id="campaign-type"
              placeholder={ Lang[ 'SEARCH_WITH_TITLE' ][ props.data.languageReducer.lang ] }
              value={ props.title }
              onChange={ e => props.onChangeSystemMailingHOC( 'title', e.target.value ) }/>
          </FormGroup>
        </CardBody>
        <CardFooter>
          <Button
            color="primary"
            className="ml-auto"
            onClick={ () => {
              let tmp = {
                page: 1,
                is_paginated: true,
                filter: {
                  title: {
                    $like: `%${ props.title }%`
                  }
                }
              }
              props.getSystemMailing( convertObjToBase64( tmp ) )
            }}>{ Lang[ 'SEARCH' ][ props.data.languageReducer.lang ] }</Button>
          <Button
            color="danger"
            onClick={ () => {
              let tmp = {
                page: 1,
                is_paginated: true
              }
              props.onChangeSystemMailingHOC( 'title', '' )
              return props.getSystemMailing( convertObjToBase64( tmp ) )
            }}>{ Lang[ 'RESET' ][ props.data.languageReducer.lang ] }</Button>
        </CardFooter>
      </Card>
      <ARKTable
        data={ props.systemMailings.data }
        columns={[
          {
            Header: Lang[ 'TITLE' ][ props.data.languageReducer.lang ],
            accessor: 'title'
          },
          {
            Header: Lang[ 'SUBJECT' ][ props.data.languageReducer.lang ],
            accessor: 'subject'
          },
          {
            Header: Lang[ 'ACTION' ][ props.data.languageReducer.lang ],
            accessor: 'id',
            width: 200,
            Cell: ({ value }) => (
              <div>
                {
                  handleSecurityModulesUpdate() && (
                    <>
                      <Button
                        id={ `mailing-${ value }-edit-mailing` }
                        className="mb-2 mr-2 btn-icon btn-icon-only"
                        color="primary"
                        onClick={ () => {
                          props.getSelectedSystemMailing( value )
                          props.onChangeSystemMailingHOC( 'showUpdateSystemMailing', true )
                        } }>
                        <i className="pe-7s-note"> </i>
                      </Button>
                      <UncontrolledTooltip
                        target={ `mailing-${ value }-edit-mailing` }
                        placement="top">{ Lang[ 'EDIT_MAILING' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                      <Button
                        id={ `mailing-${ value }-archive` }
                        className='mb-2 mr-2 btn-icon btn-icon-only'
                        color='warning'
                        onClick={ () => props.getSelectedSystemMailing( value, true ) }>
                        <i className='pe-7s-notebook'> </i>
                      </Button>
                      <UncontrolledTooltip
                        target={ `mailing-${ value }-archive` }
                        placement='top'>{ Lang[ 'ARCHIVE' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                    </>
                  )
                }
              </div>
            )
          }
        ]}
        totalPages={ props.totalPagesSystemMailing }
        page={ props.systemMailings.meta ? props.systemMailings.meta.current_page : 1 }
        onChangePage={ pg => props.getSystemMailing( 
          convertObjToBase64({ 
            is_paginated: true, 
            page: pg,
            filter: {
              status: { $neq: 'ARCHIVED' }
            }
          }) 
        )}
        searchComponent={ false }
        searchLabel={ 'Search with mailing title ' }
        showPagination={ false }
        onClickReset={ () => {} }
        onSearch={ () => {} }
        searchColumn={[]} />
      <CreateMailing
        { ...props }
        selectedLanguage={ props.data.languageReducer.lang } />
      <UpdateMailing
        { ...props }
        selectedLanguage={ props.data.languageReducer.lang } />
      <ArchivedMailings { ...props } />
      <DeleteConfirmation
        handleModalClose={ () => props.onChangeSystemMailingHOC( 'showArchiveSelectedMailing', false ) }
        deleteOpen={ props.showArchiveSelectedMailing }
        confirmAction={ () => props.updateSystemMailing(
          { 
            ...props.selectedSystemMailing,
            status: props.showArchivedMailings ? 'ACTIVE' : 'ARCHIVED' 
          },
          props.selectedSystemMailing.id
        )}
        content={ Lang[ 'ARCHIVE_UNARCHIVE_CONFIRMATION' ][ props.data.languageReducer.lang ] }
        lang={ props.data.languageReducer.lang } />
      { ( props.data.ajaxReducer.ajaxCallProgress > 0 || props.onLoadSystemMailings ) && <LoadingOverlay /> }
    </>
  )
}

const mapStateToProps = state => ({ data: state })

export default compose(
  SystemMailingHOC,
  connect( mapStateToProps )
)( SystemMailings )
