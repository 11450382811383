import React from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

const responsive = {
  lg: {
    breakpoint: { max: 4000, min: 1200 },
    items: 3,
  },
  md: {
    breakpoint: { max: 1200, min: 992 },
    items: 2,
  },
  sm: {
    breakpoint: { max: 992, min: 0 },
    items: 1,
  },
}

const CarouselImageBlock = (props) => {
  let {
    id,
    item,
    style,
    carouselItems,
    mode,
    selectedEditableComponent,
    onChangeSectionEditorState,
    hoveredComponentId,
  } = props
  return (
    <div
      id={id}
      style={{
        ...style,
        border: ( selectedEditableComponent && props.mode === 'edit' && selectedEditableComponent.id === id ) || ( mode === 'edit' && hoveredComponentId === id ) ? '5px solid #28a745' : 'none'
      }}
      onMouseEnter={() =>
        mode === "edit" &&
        onChangeSectionEditorState &&
        onChangeSectionEditorState( "hoveredComponentId", id )
      }
      onMouseLeave={() =>
        mode === "edit" &&
        onChangeSectionEditorState &&
        onChangeSectionEditorState( "hoveredComponentId", "" )
      }
      onClick={() => {
        if (mode === "edit") {
          onChangeSectionEditorState &&
            onChangeSectionEditorState( "selectedEditableComponent", item )
        }
      }}
    >
      <style>{`.carousel-image-block-item{ padding: 0 5px; } .carousel-image-block-item:before{padding-top:100%;content:\"\";float:left;}`}</style>
      <Carousel
        responsive={ responsive }
        autoPlay={ true }
        autoPlaySpeed={ 3000 }
        customTransition="all 1s"
        transitionDuration={ 1000 }
        containerClass="carousel-container"
        removeArrowOnDeviceType={[ "tablet", "mobile" ]}
        deviceType={ "mobile" }
        infinite={ true }
        itemClass="carousel-image-block-item"
      >
        {carouselItems &&
          carouselItems.map( item => {
            return <img 
              id={ item.id } 
              alt="" 
              src={ item.source } 
              style={ item.style } 
              draggable="false" />
          })}
      </Carousel>
    </div>
  )
}

export default CarouselImageBlock
