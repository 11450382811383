import React, { Component } from 'react'
import _ from 'lodash'
import uniqid from 'uniqid'

import FormEditorModal from './FormEditor'
import Lang from 'Lang/General'


const createNewForm = () => {
  let tmpId = uniqid()
  let tmpTableOfContentsId = uniqid()
  let tmpFormPageId = uniqid()
  let tmpUniqIdText = uniqid()
  let tmpUniqIdButton = uniqid()

  let tmpFormValues = [
    {
      id: tmpId,
      dataLabel: tmpId,
      value: '',
      type: 'text'
    },
    {
      id: tmpTableOfContentsId,
      dataLabel: tmpTableOfContentsId,
      value: '',
      type: 'tableOfContents'
    },
    {
      id: tmpUniqIdText,
      dataLabel: tmpUniqIdText,
      value: '',
      type: 'text'
    },
    {
      id: tmpUniqIdButton,
      dataLabel: tmpUniqIdButton,
      value: '',
      type: 'button'
    },
  ]
  let tmpDOM = [
    {
      id: 'kdmm1yn6',
      type: 'FormFrame',
      content: Lang[ 'SAMPLE_TITLE_PRODUCT' ],
      title: {
        fontSize: 20,
        color: '#e95d0f',
        backgroundColor: '#002350',
        fontWeight: '900',
        textAlign: 'center',
        padding: '15px'
      },
      container: {
        borderWidth: 1,
        borderColor: '#e95d0f55'
      },
      totalPages: 2,
      children: [
        {
          id: uniqid(),
          page: 1,
          type: 'Container',
          mapWith: 'content',
          name: Lang[ 'TABLE_OF_CONTENT' ],
          settings: {
            thumbnailImg: ""
          },
          children: [
            {
              id: uniqid(),
              type: 'Row',
              mapWith: 'form-content',
              children: [
                {
                  id: uniqid(),
                  type: 'Col',
                  grid: 12,
                  children: [
                    {
                      id: tmpId,
                      type: 'Text',
                      style: {
                        fontWeight: '600',
                        marginBottom: 0
                      },
                      label: Lang[ 'TABLE_OF_CONTENT' ],
                      mapWith: 'pageContentTitle',
                      dataMapIndex: 0,
                      fontSize: '20px',
                      padding: '15px'
                    }
                  ]
                },
                {
                  id: uniqid(),
                  type: 'Col',
                  grid: 12,
                  mapWith: 'TableOfContentsContainer',
                  children: [
                    {
                      id: tmpTableOfContentsId,
                      type: 'TableOfContents',
                      mapWith: 'TableOfContents'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          id: tmpFormPageId,
          page: 2,
          type: 'Container',
          selectable: false,
          selected: false,
          name: Lang[ 'BLANK' ],
          settings: {
            thumbnailImg: ""
          },
          mapWith: 'form',
          children: [
            {
              id: 'kdn9iwjz',
              type: 'Row',
              mapWith: 'form-content',
              children: [ ]
            }
          ]
        }
      ]
    }
  ]

  const newForm = {
    'id': 'temp_'+uniqid(),
    'name': Lang[ 'BLANK' ],
    'description': Lang[ 'BLANK' ],
    'status': 'ACTIVE',
    'categories': [],
    'centerGroups': [],    
    'content': tmpDOM,
    'values': tmpFormValues
  }
  return newForm
}


class ModalFormBuilder extends Component {
  state = {
    digitalOrderForm: {},
    formMounted: false
  }

  componentDidMount = () => this.mountForm()

  componentDidUpdate = pp => {
    if( pp.showFormBuilder && !this.props.showFormBuilder ){
      this.mountForm()
    }
  }

  mountForm = () => {
    let newForm = createNewForm()
    this.setState({ 
      digitalOrderForm: newForm,
    }, () => this.setState({ formMounted: true }) )
  }

  render = () => {
    return (
      this.state.formMounted &&
      <FormEditorModal
        mode={ 'create' }
        digitalOrderForm={ this.state.digitalOrderForm }
        showFormEditor={ this.props.showFormBuilder }
        toggleFormEditor={ ( isShow ) => this.props.onChangeDigitalOrderFormHOC( 'showFormBuilder', isShow ) }
        { ...this.props }
      />
    )
  }
}

export default ModalFormBuilder