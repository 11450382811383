import Axios from 'axios'

import {
  beginAjaxCall,
  ajaxCallError,
  ajaxCallSuccess
} from './ajax';
import { storeItem } from 'utils/tokenStore';
import getDomainURL from 'utils/api';

export const getRefreshToken = () => dispatch => {
  dispatch( beginAjaxCall() )
  Axios.get(
    `${ getDomainURL() }/auth/refresh_token`
  ).then( res => {
    console.log( res )
  }).catch( error => {
    if( error && error.response ) {
      dispatch( ajaxCallError( error.response.data.message ) )
    } else {
      dispatch( ajaxCallError( 'Disconnected from internet, please connect to the internet and retry again.' ) );
    }
  })
}