import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get } from "utils/axios"

const ComponentHOC = ( WrappedComponent ) => {
  class ComponentsWrappedComponent extends Component {
    state = {
      loading: false,
      components: []
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    getComponents = query => Get(
      `/api/v1/shared_components?query=${ query }`,
      this.getComponentsSuccess,
      this.getComponentsError,
      this.load
    )
    getComponentsSuccess = payload => this.setState({ components: payload.data })
    getComponentsError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadComponents={ this.state.loading }
          getComponents={ this.getComponents }
          components={ this.state.components } />
      )
    }
  }
  return ComponentsWrappedComponent
}

export default ComponentHOC