import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get } from 'utils/axios'

const statusColorMap = {
  'Form submitted': '#D9BDC5',
  'In processing': '#E8C7DE',
  'In designing': '#3581B8',
  'Approved': '#C7F0BD',
  'In production': '#119DA4',
  'Completion': '#CBDF90',
  'Cancelled': '#DC3545',
  'Archived': '#BBBBBB'
}

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state={
      loading: false,
      requestStatus: []
    }
    onChangeRequestStatusHOC = ( key, value ) => this.setState({ [key]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    getAllRequestStatus = () => Get(
      `/api/v1/digital_order_form_status`,
      this.getAllRequestStatusStatus,
      this.getAllRequestStatusError,
      this.load
    )
    getAllRequestStatusStatus = payload => {
      payload.map(status => {
        status.color = statusColorMap[status['name']['en']]
      })
      this.setState({ requestStatus: payload })
    }
    getAllRequestStatusError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          getAllRequestStatus={ this.getAllRequestStatus }
          requestStatus={ this.state.requestStatus }
          onChangeRequestStatusHOC={ this.onChangeRequestStatusHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}
export default HOC
