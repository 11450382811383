import React from 'react'
import _ from 'lodash'
import {
  Form, FormGroup,
  Label, Input
} from 'reactstrap'

import MultiLangInput from 'components/Input/MultiLang'
import Lang from 'Lang/General'

const MemberStatusForm = ({
  memberStatusType,
  currentMemberStatus,
  onChangeMemberStatusesHOC,
  lang
}) => {
  const updateMemberStatus = ( key, val ) => {
    let tmp = _.cloneDeep( currentMemberStatus )
    tmp[ key ] = val
    onChangeMemberStatusesHOC( memberStatusType, tmp )
  }
  return(
    <>
      <Form>
        <MultiLangInput
          label={ Lang[ 'NAME' ][ lang ] }
          value={ currentMemberStatus.name }
          onChange={ val => updateMemberStatus( 'name', val ) }
          selectedLanguage={ lang } />
        <FormGroup>
          <Label>{ Lang[ 'REMARK' ][ lang ] }</Label>
          <Input
            type='text'
            value={ currentMemberStatus.remark }
            onChange={ e => updateMemberStatus( 'remark', e.target.value ) }/>
        </FormGroup>
      </Form>
    </>
  )
}

export default MemberStatusForm