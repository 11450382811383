import React, { Component } from 'react'
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button, Row
} from 'reactstrap'
import _ from 'lodash'
import uniqid from 'uniqid'
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import RenderDOM from '../RenderDOM'
import UpdateFormFrame from '../Update/FormFrame'
import UpdateColumnModal from '../Update/Column'
import UpdateContentModal from '../Update/Content'
import UpdatePageSettingsModal from '../Update/PageSettings'
import ConfirmDeleteModal from 'components/Indicator/Prompt'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import FormInfoModal from './FormInfoModal'
import AddPageModal from './AddPageModal'
import MediaDatabase from 'SharedModules/MediaDatabase' 
import { toggleMediaDatabase } from 'actions/MediaDatabaseToggle'

import Lang from 'Lang/General'
import LanguageSelector from 'components/LanguageSelector'

const reorder = ( list, startIndex, endIndex ) => {
  const result = Array.from( list )
  const [removed] = result.splice( startIndex, 1 )
  result.splice( endIndex, 0, removed )
  return result
}

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "transparent"
})

class FormEditor extends Component {
  state={
    dom: [],
    formValues: [],
    name: Lang[ 'BLANK' ],
    description: Lang[ 'BLANK' ],
    status: 'ACTIVE',
    centerGroups: [],
    categories: [],
    errorMessage: '',
    componentsRemoved: [],
    currentPage: 1,
    showUpdateFormFrame: false,
    showUpdateColumnModal: false,
    showUpdateContentModal: false,
    showUpdatePageSettingsModal: false,
    showConfirmDelete: false,
    showConfirmDeletePage: false,
    showPageRearrangement: false,
    showAddPageModal: false,
    selectedParentComponentToEdit: {},
    selectedComponentToEdit: {},
    selectedComponentToRemove: {},
    selectedPageToRemove: {},
    foundObject: {},
    foundObjectByType: {},
    formContent: {},  
    formFrameMounted: false,
    showRemovePageButton: false,
    editingImageType: '', 
    currentLanguage: 'de'
  }

  componentDidMount = () => Promise.all([
    this.mountFormFrame(),
    this.mountInfoModal()
  ])

  componentDidUpdate = pp => {
    if( pp.digitalOrderForm.id !== this.props.digitalOrderForm.id || _.isEmpty( pp.digitalOrderForm ) ) {
      this.setState({ 
        formValues: [], 
        dom: [], 
        name: Lang[ 'BLANK' ],
        description: Lang[ 'BLANK' ],
        status: 'ACTIVE',
        centerGroups: [],
        categories: [],
        removedComponent: [],
      }, () => {
        Promise.all([
          this.mountFormFrame(),
          this.mountInfoModal()
        ])
      })
    }
  }

  onChangeFormBuilderState = ( key, val ) => this.setState({ [key]: val })

  mountInfoModal = () => this.setState({
    name: this.props.digitalOrderForm.name,
    description: this.props.digitalOrderForm.description,
    status: this.props.digitalOrderForm.status,
    centerGroups: Array.isArray( this.props.digitalOrderForm.center_groups ) 
      ? this.props.digitalOrderForm.center_groups.map( group => {
          let displayGroup = {
            id: group.id,
            label: group.name,
            value: group.name
          }
          return displayGroup
        })
      : [],
    categories: Array.isArray( this.props.digitalOrderForm.categories ) 
      ? this.props.digitalOrderForm.categories.map( category => {
          let displayCategory = {
            id: category.id,
            label: category.name[ this.props.data.languageReducer.lang ],
            value: category.name[ this.props.data.languageReducer.lang ]
          }
          return displayCategory
        })
      : []
  })

  mountFormFrame = async () => {
    await this.findObjectByType( this.props.digitalOrderForm.content, 'FormFrame' )
    let tmpFormFrame = _.cloneDeep( this.state.foundObjectByType )
    let tmpSelectedForm = _.cloneDeep( this.props.digitalOrderForm )
    let tmpDom = _.cloneDeep( this.state.dom )
    let tmpContentPgIndex = _.findIndex( tmpFormFrame.children, { mapWith: 'content' } )
    let tmpSummaryPgIndex = _.findIndex( tmpFormFrame.children, { mapWith: 'summary' } )
    let tmpFormValues = tmpSelectedForm.values

    if ( this.props.mode !== 'view' ) {
      tmpFormFrame.children.map( ( formContent, index ) => {
        if( 
          !formContent.mapWith || 
          ( formContent.mapWith && _.isEmpty( formContent.mapWith ) ) ||
          ( formContent.mapWith && formContent.mapWith === 'form' )
        ){
          const newAddColumnBtn = this.createAddColumnBtnDom()
          const newAddContentInColumnBtn = this.createAddContentInColumnBtnDom()
          tmpFormFrame.children[ index ].children.push( newAddColumnBtn )
          tmpFormFrame.children[ index ].children[0].children.push( newAddContentInColumnBtn )
        } 
      }) 
    }

    if( tmpSummaryPgIndex > -1 ){
      tmpFormFrame.children.splice( tmpSummaryPgIndex, 1 )
      tmpFormFrame.totalPages = tmpFormFrame.totalPages - 1 
      if( 
        this.props.digitalOrderForm.values.length > 0 && 
        this.props.digitalOrderForm.values[ this.props.digitalOrderForm.values.length - 1 ].id === 'DELIVERY_REMARK' 
      ){
        tmpFormValues.pop()
      }
    }

    // ---- this part is for backward compatability ----
    // may remove if old form using the old version of content page can be ignored
    let contentPageItems = tmpFormFrame.children[ tmpContentPgIndex ].children[0].children
    if ( contentPageItems[1].children[0].type !== "TableOfContents" ) {
      let tmpTableOfContentsId = uniqid()
      contentPageItems = [
        contentPageItems[0],
        {
          id: uniqid(),
          type: 'Col',
          grid: 12,
          mapWith: 'TableOfContentsContainer',
          children: [
            {
              id: tmpTableOfContentsId,
              type: 'TableOfContents',
              mapWith: 'TableOfContents'
            }
          ]
        }
      ]
      tmpFormValues.push({
        id: tmpTableOfContentsId,
        dataLabel: tmpTableOfContentsId,
        value: '',
        type: 'tableOfContents'
      })
      tmpFormFrame.children[ tmpContentPgIndex ].children[0].children = contentPageItems
    }

    tmpFormFrame.children.map( ( formContent, index ) => {
      if( 
        !formContent.mapWith || 
        ( formContent.mapWith && _.isEmpty( formContent.mapWith ) ) ||
        ( formContent.mapWith && formContent.mapWith === 'form' )
      ){
        if ( _.isEqual( formContent.name, Lang[ 'PAGE' ] ) ) {
          tmpFormFrame.children[ index ].name = Lang[ 'BLANK' ]
        }

        if ( _.isEmpty( tmpFormFrame.children[ index ][ 'settings' ] ) ) {
          tmpFormFrame.children[ index ][ 'settings' ] = {
            thumbnailImg: ""
          }
        }
      } 
    }) 
    // ---- end of code for backward compatability ----
    
    await this.findObjectAndPatch( tmpSelectedForm.content, tmpFormFrame )
    tmpDom[0] = tmpSelectedForm.content[0]
    return this.setState({ 
      dom: tmpDom, 
      currentPage: 1,
      formValues: tmpFormValues,
      showRemovePageButton: tmpDom[ 0 ].totalPages > 0 ? true : false,
      formFrameMounted: true,
      currentLanguage: this.props.data.languageReducer.lang
    })
  }
  
  createAddColumnBtnDom = () => {
    return {
      id: uniqid(),
      type: 'Button',
      mapWith: 'add-new-column-button',
      onClickEditor: async() => {
        await this.onClickAddNewColumn()
      },
      color: 'light',
      content: Lang[ 'ADD_NEW_COLUMN' ],
      style: {
        width: '100%',
        marginTop: '1rem'
      }
    }
  }

  createAddContentInColumnBtnDom = () => {
    const tmpId = uniqid()
    return  {
      id: tmpId,
      type: 'Col',
      grid: 12,
      children: [
        {
          id: uniqid(),
          type: 'Button',
          color: 'light',
          onClickEditor: () => {
            this.setState({
              showUpdateContentModal: true,
              selectedParentComponentToEdit: {
                id: tmpId,
                type: 'Col'
              }
            }, () => {
              this.findObjectById( this.state.dom, this.state.selectedParentComponentToEdit.id )
            })
          },
          content: Lang[ 'ADD_CONTENT' ],
          style: {
            width: '100%'
          },
          mapWith: 'add-content-in-column-button'
        }
      ]
    }
  }

  createPageSelectorCheckbox = ( pageToEdit ) => {
    const id = uniqid()
    const value = {
      id: id,
      dataLabel: id,
      value: [],
      type: 'checkbox',
      page: pageToEdit
    }
    const dom = {
      id: uniqid(),
      type: 'Col',
      grid: 12,
      children: [
        {
          id: id,
          type: 'Checkbox',
          label: Lang[ 'BLANK' ],
          dataMapIndex: -1, 
          selections: [
            {
              label: Lang[ 'SELECT_THIS_PAGE' ],
              value: 'page-selection',
              nestedSelections: [],
              dependentField: {}
            }
          ],
          padding: '15px',
          mapWith: 'page-selector'
        }
      ]
    }
    return {
      value: value,
      dom: dom
    }
  }

  findObjectAndPatch = ( data, target ) => {
    data.map(( item, index ) => {
      if( item.id === target.id ) {
        data.splice( index, 1, target )
      } else {
        if( item.children ) {
          this.findObjectAndPatch( item.children, target )
        }
      }
    })
    return data
  }

  findObjectById = ( data, id ) => {
    data.map(( item, index ) => {
      if( item.id === id ) {
        this.setState({ foundObject: item })
      } else {
        if( item.children ) {
          this.findObjectById( item.children, id )
        }
      }
    })
  }

  findTargetAndPatch = ( data, target, result ) => {
    data.map(( item, index ) => {
      if( item.page && item.page !== this.state.currentPage ){
        return
      }
      if( item.mapWith === target ) {
        data.splice( index, 1, result )
      } else {
        if( item.children ) {
          this.findTargetAndPatch( item.children, target, result )
        }
      }
    })
    return data
  }

  findTargetObject = ( data, target, page ) => {
    data.map(( item, index ) => {
      if( item.page && item.page !== page ){
        return
      }
      if( item.mapWith === target ) {
        this.setState({ foundObject: item })
      } else {
        if( item.children ) {
          this.findTargetObject( item.children, target, page )
        }
      }
    })
  }

  findObjectByType = ( data, type ) => {
    data.map(( item, index ) => {
      if( item.type === type ) {
        this.setState({ foundObjectByType: item })
      } else {
        if( item.children ) {
          this.findTargetObject( item.children, type )
        }
      }
    })
  }

  findObjectByMapWithProp = ( data, mapWith ) => {
    data.map(( item, index ) => {
      if( item.mapWith === mapWith ) {
        this.setState({ foundObject: item })
      } else {
        if( item.children ) {
          this.findObjectByMapWithProp( item.children, mapWith )
        }
      }
    })
  }

  findObjectAndRemove = ( data, target ) => {
    data.map(( item, index ) => {
      if( item.id === target.id ) {
        data.splice( index, 1 )
      } else {
        if( item.children ) {
          this.findObjectAndRemove( item.children, target )
        }
      }
    })
  }

  findExtraButtonsAndRemove = data => {
    if( data.children && data.children.length > 0 ){
      data.children = _.filter( 
        data.children, 
        item => ( 
          !item.mapWith ||
          ( item.mapWith && item.mapWith !== 'add-content-in-column-button' && item.mapWith !== 'add-new-column-button' )
        )
      )
      data.children.map( item => this.findExtraButtonsAndRemove( item ) )
    }
  }

  onPatchFormValues = ( index, key, val ) => {
    let tmp = _.cloneDeep( this.state.formValues )
    tmp[ index ][ key ] = val
    return this.setState({ formValues: tmp })
  }

  onClickAddNewColumn = async() => {
    let tmp = _.cloneDeep( this.state.dom )
    await this.findTargetObject( tmp, 'form-content', this.state.currentPage )
    let tmpFoundObject = _.cloneDeep( this.state.foundObject )
    let newAddContentInColumnBtn = this.createAddContentInColumnBtnDom()
    tmpFoundObject.children.push( newAddContentInColumnBtn )
    this.findObjectAndPatch( tmp, tmpFoundObject )
    this.setState({ dom: tmp })
  }

  onClickRemoveColumn = () => {
    let tmp = _.cloneDeep( this.state.dom )
    let tmpSelectComponentToRemove = _.cloneDeep( this.state.selectedComponentToRemove )
    let tmpComponentsRemoved = _.cloneDeep( this.state.componentsRemoved )
    tmpComponentsRemoved.push( tmpSelectComponentToRemove.children[0].id )
    this.findObjectAndRemove( tmp, tmpSelectComponentToRemove )
    return this.setState({ 
      showConfirmDelete: false, 
      dom: tmp,
      componentsRemoved: tmpComponentsRemoved })
  }

  onAddPage = ( pageTypeToAdd, pageData ) => {
    let tmpId = uniqid()
    let tmpDOM = _.cloneDeep( this.state.dom )
    let tmpFormValues = _.cloneDeep( this.state.formValues )
    let tmpNewPageDOM = {}

    if ( pageTypeToAdd !== 'copyOfExternalPage' ) {
      let newAddColumnBtn = this.createAddColumnBtnDom()
      let newAddContentInColumnBtn = this.createAddContentInColumnBtnDom()
  
      tmpNewPageDOM = {
        id: uniqid(),
        page: 0,
        type: 'Container',
        selectable: false,
        selected: false,
        mapWith: pageTypeToAdd,
        children: pageTypeToAdd === 'form' ? (
          [
            {
              id: uniqid(),
              type: 'Row',
              mapWith: 'form-content',
              children: [ newAddContentInColumnBtn ]
            },
            newAddColumnBtn
          ]
        ) : (
          [
            {
              id: uniqid(),
              type: 'Row',
              mapWith: 'form-content',
              children: []
            }
          ]
        )
      }
      tmpNewPageDOM.id = uniqid()
    } else {
      tmpNewPageDOM = pageData.dom
    }

    tmpDOM[0].totalPages++

    switch( pageTypeToAdd ){
      case 'cover':
        tmpFormValues.push({
          id: tmpId,
          dataLabel: tmpId,
          value: '',
          type: 'image',
          page: 1
        })
        tmpNewPageDOM[ 'name' ] = Lang[ 'COVER' ]
        tmpNewPageDOM.children[ 0 ].children.push({
          id: uniqid(),
          type: 'Col',
          grid: 12,
          children: [
            {
              id: tmpId,
              type: 'Image',
              mapWith: `page_${ tmpNewPageDOM.id }`,
              url: 'https://s3-eu-central-1.amazonaws.com/ark.staging.assets/shared_media_drive/8bb6c17838643f9691cc6a4de6c51709/original/image.png',
              padding: '0',
              width: '100%',
              height: '100%',
              dataMapIndex: tmpFormValues.length - 1
            }
          ]
        })
        tmpDOM[0].children.splice( 0, 0, tmpNewPageDOM )
        tmpDOM[0].children.map( ( item, index ) => { 
          item.page = index + 1 
          return item
        })
        break
      case 'form':
        tmpNewPageDOM.page = tmpDOM[0].totalPages
        tmpNewPageDOM[ 'name' ] = Lang[ 'BLANK' ]
        tmpNewPageDOM[ 'settings'] = {
          thumbnailImg: ""
        }
        tmpDOM[0].children.push( tmpNewPageDOM )
        break
      case 'copyOfExternalPage': 
        let tmpNewPageFormValues = pageData.formValues
        
        let newPageNum = tmpDOM[0].totalPages
        if ( pageData.dom.mapWith === 'cover' ) {
          newPageNum = 1
        }
        tmpNewPageDOM.page = newPageNum
        tmpNewPageFormValues.map((formVal) => {
          return formVal.page = tmpNewPageDOM.page
        })
        tmpDOM[0].children.map( ( item, index ) => { 
          if ( item.page >= newPageNum ) {
            item.page = item.page + 1
          }
          return item
        })
        tmpFormValues.map( (formVal) => {
          if ( formVal.page >= newPageNum ) {
            formVal.page = formVal.page + 1
          }
          return formVal
        })

        tmpFormValues = tmpFormValues.concat( tmpNewPageFormValues )
        this.patchDomDataMapIndex( tmpNewPageDOM, tmpFormValues )
        tmpDOM[0].children.splice( tmpNewPageDOM.page-1, 0, tmpNewPageDOM )
        break
    }

    this.setState({ 
      dom: tmpDOM, 
      showRemovePageButton: true, 
      showAddPageModal: false, 
      formValues: tmpFormValues 
    }, () => {
      toast.success( Lang[ 'SUCCESS_MESSAGE' ][ [ this.props.data.languageReducer.lang ] ] ) 
    })
  }

  onClickRemovePage = () => {
    let tmpDOM = _.cloneDeep( this.state.dom )
    let tmpPgId = tmpDOM[0].children[ this.state.currentPage - 1 ].id
    tmpDOM[0].totalPages = tmpDOM[0].totalPages - 1
    tmpDOM[0].children.splice( this.state.currentPage - 1, 1 )
    tmpDOM[0].children.map( ( item, index ) => tmpDOM[0].children[ index ].page = index + 1 )
    
    let tmpFormVals = _.cloneDeep( this.state.formValues )
    tmpFormVals.map((formVal) => {
      if (formVal.page > this.state.currentPage)
        return formVal.page -= 1
      else if (formVal.page < this.state.currentPage)
        return formVal.page 
      else
        return formVal.page = -1
    })

    this.setState({ 
      dom: tmpDOM, 
      formValues: tmpFormVals,
      currentPage: this.state.currentPage > 1 ? this.state.currentPage - 1 : 1,
      showRemovePageButton: tmpDOM[0].totalPages > 1 ? true : false,
      showConfirmDeletePage: false })
  }

  processCenterGroup = () => {
    let centerGroupsList = []
    this.props.centerGroups && this.props.centerGroups.map( item => {
      centerGroupsList.push({
        id: item.id,
        label: item.name,
        value: item.name
      })
    })
    return centerGroupsList
  }

  processCategory = () => {
    let categoryList = []
    this.props.allFormCategories && this.props.allFormCategories.map( item => {
      categoryList.push({
        id: item.id,
        label: item.name[ this.props.data.languageReducer.lang ],
        value: item.name[ this.props.data.languageReducer.lang ]
      })
    })
    categoryList = _.orderBy(categoryList, [category => category.label.toLowerCase()]) 
    return categoryList
  }

  onDragEnd = async result => {
    if( !result.destination ) {
      return;
    }
    let tmp = _.cloneDeep( this.state.dom )
    await this.findTargetObject( tmp, 'form-content', this.state.currentPage )
    let tmpFoundObject = _.cloneDeep( this.state.foundObject )
    let tmpFoundObjectChildren = _.cloneDeep( tmpFoundObject.children )
    tmpFoundObjectChildren = reorder( tmpFoundObjectChildren, result.source.index, result.destination.index )
    tmpFoundObject.children = tmpFoundObjectChildren
    await this.findTargetAndPatch( tmp, 'form-content', tmpFoundObject )
    await this.setState({ dom: tmp })
  }

  updateColumn = async( domCache, formValuesCache ) => {
    let tmpDOM = _.cloneDeep( domCache )
    let tmpFormValues = _.cloneDeep( formValuesCache )
    let tmpRearrangedFormValues = []
    let i = 0
    let j = 0
    
    tmpFormValues = _.filter( 
      tmpFormValues, 
      item => !_.isEmpty( item ) && _.findIndex( this.state.componentsRemoved, { id: item.id } ) === -1 
    )

    for( i = 0; i < tmpDOM[0].children.length; i++ ){
      await this.findTargetObject( tmpDOM, 'form-content', i+1 )
      let tmpFormContent = _.clone( this.state.foundObject )
      let tmpFormContentChildren = _.cloneDeep( this.state.foundObject.children )

      tmpFormContentChildren = _.filter( tmpFormContentChildren, item => item.children.length > 0 )
      tmpFormContentChildren.map( ( item ) => {
        if( 
          item.children && 
          item.children[ 0 ] &&
          (
            !item.children[ 0 ].mapWith ||
            ( 
              item.children[ 0 ].mapWith && 
              item.children[ 0 ].mapWith !== 'add-content-in-column-button' && 
              item.children[ 0 ].mapWith.mapWith !== 'add-new-column-button' )
          )
        ){
          let tmpFormVal = tmpFormValues.find( x => x.id == item.children[0].id )
          tmpRearrangedFormValues.push( tmpFormVal )
          item.children[0].dataMapIndex = j
          j = j + 1

          if ( ["Radio", "RadioFlexible", "Checkbox", "CheckboxFlexible"].indexOf( item.children[0].type ) !== -1 ) {
            item.children[0].selections.map( selection => {
              if ( selection.dependentField && selection.dependentField.id ) {
                let tmpFormVal = tmpFormValues.find( x => x.id == selection.dependentField.id )
                tmpRearrangedFormValues.push( tmpFormVal )
                selection.dependentField.dataMapIndex = j
                j = j + 1
              }
            })
          }
        }
      })

      tmpFormContent.children = tmpFormContentChildren
      this.findObjectAndPatch( tmpDOM, tmpFormContent )
    }

    this.setState({
      dom: tmpDOM,
      formValues: tmpRearrangedFormValues,
      showUpdateColumnModal: false,
      componentsRemoved: [],
      selectedComponentToEdit: {}
    })
  }

  renderFormInfoModal = () => {
    return (
      <FormInfoModal
        mode={ this.props.mode }
        onChangeFormBuilderState={ this.onChangeFormBuilderState }
        data={{
          name: this.state.name,
          description: this.state.description,
          centerGroups: this.state.centerGroups,
          categories: this.state.categories,
          status: this.state.status
        }}
        centerGroupOptions={ this.processCenterGroup() }
        categoryOptions={ this.processCategory() }
        isShowInfoModal={ this.props.showInfoModal }
        toggleInfoModal={ ( isShow ) => this.props.onChangeDigitalOrderFormHOC( 'showInfoModal', isShow ) }
        onSubmit={ this.submitForm }
        selectedLanguage={ this.props.data.languageReducer.lang }
      />
    )
  }

  renderAddPageModal = () => (
    <AddPageModal 
      mode={ this.props.mode }
      isShowAddPageModal={ this.state.showAddPageModal }
      toggleShowAddPageModal={ ( isShow ) => this.setState({ showAddPageModal: isShow }) }
      onAddPage={ ( pageTypeToAdd, pageData ) => this.onAddPage( pageTypeToAdd, pageData ) }
      digitalOrderForms={ this.props.digitalOrderForms }
      selectedDigitalOrderForm={ this.props.selectedDigitalOrderForm }
      selectedFormToCopy={ this.props.selectedFormToCopy }
      getDigitalOrderForms={ this.props.getDigitalOrderForms }
      getSelectedDigitalOrderForm={ this.props.getSelectedDigitalOrderForm }
      changeComponentId={ this.changeComponentId }
      onLoadDigitalOrderForms={ this.props.onLoadDigitalOrderForms }
      selectedLanguage={ this.props.data.languageReducer.lang }
    />
  )

  togglePageSelectable = async( pageToEdit ) => {
    if ( !pageToEdit ) {
      pageToEdit = this.state.currentPage
    }

    let tmpDOM = _.cloneDeep( this.state.dom )
    let tmpFormValues = _.cloneDeep( this.state.formValues )
    await this.findTargetObject( tmpDOM, 'form-content', pageToEdit )
    let pageDOM = _.cloneDeep( this.state.foundObject )
    let isPageSelectable = tmpDOM[ 0 ].children[ pageToEdit- 1 ].selectable

    if ( !isPageSelectable ) {
      tmpDOM[ 0 ].children[ pageToEdit - 1 ].selectable = true
      let pageSelector = this.createPageSelectorCheckbox( pageToEdit )
      tmpFormValues.push( pageSelector.value )
      pageSelector.dom.children[0].dataMapIndex = _.findIndex( tmpFormValues, { id: pageSelector.dom.children[0].id })
      pageDOM.children.unshift( pageSelector.dom )
      this.findObjectAndPatch( tmpDOM, pageDOM )

      this.setState({ 
        dom: tmpDOM, 
        formValues: tmpFormValues 
      })
    } 
    else {
      tmpDOM[ 0 ].children[ pageToEdit - 1 ].selectable = false
      tmpDOM[ 0 ].children[ pageToEdit - 1 ].selected = false
      let componentToRemove
      for (let index = 0; index < pageDOM.children.length; index++) {
        if( pageDOM.children[index].children[0].mapWith === 'page-selector' ) {
          componentToRemove = pageDOM.children[index]
          break
        } 
      }
      let tmpSelectComponentToRemove = _.cloneDeep( componentToRemove )
      let tmpComponentsRemoved = _.cloneDeep( this.state.componentsRemoved )
      tmpComponentsRemoved.push( tmpSelectComponentToRemove.children[0].id )
      this.findObjectAndRemove( tmpDOM, tmpSelectComponentToRemove )

      this.setState({ 
        dom: tmpDOM, 
        componentsRemoved: tmpComponentsRemoved 
      })
    }
  }

  togglePageSelection = async ( pageToEdit ) => {
    let tmpDOM = _.cloneDeep( this.state.dom )
    let tmpFormValues = _.cloneDeep( this.state.formValues )
    let pageSelectorCheckbox
    
    if ( !pageToEdit ) {
      pageToEdit = this.state.currentPage
    }

    let pageDOM = tmpDOM[0].children[ pageToEdit - 1 ]
    pageDOM.selected = !pageDOM.selected
    tmpDOM[0].children[ pageToEdit - 1 ] = pageDOM
    
    await this.findObjectByMapWithProp( pageDOM.children[0].children, 'page-selector' )
    pageSelectorCheckbox = this.state.foundObject
    if ( pageDOM.selected ) {
      tmpFormValues[ pageSelectorCheckbox.dataMapIndex ][ 'value' ].push( pageSelectorCheckbox.selections[0].value )
    } else {
      tmpFormValues[ pageSelectorCheckbox.dataMapIndex ][ 'value' ] = []
    }

    this.setState({
      dom: tmpDOM,
      formValues: tmpFormValues
    })
  }

  duplicatePage = async ( pageToCopy ) => {
    let goToNewPage = false
    if ( !pageToCopy ) {
      pageToCopy = this.state.currentPage
      goToNewPage = true
    }
    let tmpDOM = _.cloneDeep( this.state.dom )
    let tmpFormValues = _.cloneDeep( this.state.formValues )
    let newPageDOM = _.cloneDeep( tmpDOM[0].children[ pageToCopy - 1 ] )
    let newPageFormValues = _.cloneDeep( this.state.formValues.filter( val => val.page === pageToCopy ) )

    this.changeComponentId(newPageDOM, newPageFormValues)
    Object.keys( newPageDOM[ 'name' ] ).forEach( langKey => {
      newPageDOM[ 'name' ][ langKey ] = `${ newPageDOM[ 'name' ][ langKey ] } (${ Lang[ 'COPY' ][ langKey ] })`
    })

    tmpDOM[0].totalPages++
    const newPageNum = pageToCopy + 1
    newPageDOM.page = newPageNum
    newPageFormValues.map((formVal) => {
      return formVal.page = newPageDOM.page
    })
    tmpDOM[0].children.map( ( item, index ) => { 
      if ( item.page >= newPageNum ) {
        item.page = item.page + 1
      }
      return item
    })
    tmpFormValues.map( (formVal) => {
      if ( formVal.page >= newPageNum ) {
        formVal.page = formVal.page + 1
      }
      return formVal
    })
    
    tmpFormValues = tmpFormValues.concat( newPageFormValues )
    this.patchDomDataMapIndex( newPageDOM, tmpFormValues )
    tmpDOM[0].children.splice( newPageDOM.page-1, 0, newPageDOM )

    this.setState({ 
      dom: tmpDOM, 
      formValues: tmpFormValues,
      currentPage: goToNewPage ? newPageDOM.page : this.state.currentPage
    })
  }

  changeComponentId = ( dom, formValues ) => {
    let oldId = dom.id
    let newId = uniqid()
    dom.id = newId
    let index = _.findIndex( formValues, { id: oldId })
    if ( index >= 0 ) {
      formValues[ index ][ 'id' ] = newId
    }

    if ( dom.selections ) {
      dom.selections.map( selection => {
        if ( !_.isEmpty( selection.dependentField ) ) {
          let oldId = selection.dependentField.id
          let newId = uniqid()
          selection.dependentField.id = newId
          let index = _.findIndex( formValues, { id: oldId })
          if ( index >= 0 ) {
            formValues[ index ][ 'id' ] = newId
          }
        }
      })
    }

    if ( dom.children ) {
      dom.children.map(( item ) => {
        this.changeComponentId( item, formValues )
      })
    }
  }

  patchDomDataMapIndex = ( dom, formValues ) => {
    if ( dom.dataMapIndex ) {
      dom.dataMapIndex = _.findIndex( formValues, { id: dom.id })
      if ( dom.selections ) {
        dom.selections.map( selection => {
          if ( !_.isEmpty( selection.dependentField ) ) {
            selection.dependentField.dataMapIndex = _.findIndex( formValues, { id:  selection.dependentField.id })
          }
        })
      }
    }
    if ( dom.children ) {
      dom.children.map(( item ) => {
        this.patchDomDataMapIndex( item, formValues )
      })
    }
  }

  submitForm = async () => {
    let tmpDOM = _.cloneDeep( this.state.dom )
    this.findExtraButtonsAndRemove( tmpDOM[ 0 ] )
    let tmpFormValues = _.cloneDeep( this.state.formValues )
    let tmpRearrangedFormValues = []
    let i = 0
    let j = 0
    let tmpContentPgIndex = _.findIndex( tmpDOM[ 0 ].children, { mapWith: 'content' } )
    
    tmpFormValues = _.filter( 
      tmpFormValues, 
      item => !_.isEmpty( item ) && _.findIndex( this.state.componentsRemoved, { id: item.id } ) === -1 
    )
    
    tmpDOM[0].children = _.filter( tmpDOM[0].children, item => item.children[0].children[0].children.length > 0 )
    tmpDOM[0].children.map( ( item, index ) => {
      tmpDOM[0].children[index].page = index + 1
    })
    tmpDOM[0].totalPages = tmpDOM[0].children.length

    for( i = 0; i < tmpDOM[0].children.length; i++ ){
      await this.findTargetObject( tmpDOM, 'form-content', i+1 )
      let tmpFormContent = _.clone( this.state.foundObject )
      let tmpFormContentChildren = _.cloneDeep( this.state.foundObject.children )

      tmpFormContentChildren = _.filter( tmpFormContentChildren, item => item.children.length > 0 )
      tmpFormContentChildren.map( ( item ) => {
        let tmpFormVal = tmpFormValues.find( x => x.id == item.children[0].id )
        tmpFormVal[ 'page' ] = tmpDOM[0].children[ i ].page
        tmpRearrangedFormValues.push( tmpFormVal )
        item.children[0].dataMapIndex = j
        j = j + 1

        if ( ["Radio", "RadioFlexible", "Checkbox", "CheckboxFlexible"].indexOf( item.children[0].type ) !== -1 ) {
          item.children[0].selections.map( selection => {
            if ( selection.dependentField && selection.dependentField.id ) {
              let tmpFormVal = tmpFormValues.find( x => x.id == selection.dependentField.id )
              tmpFormVal[ 'page' ] = tmpDOM[0].children[ i ].page
              tmpRearrangedFormValues.push( tmpFormVal )
              selection.dependentField.dataMapIndex = j
              j = j + 1
            }
          })
        }
      })

      tmpFormContent.children = tmpFormContentChildren
      this.findObjectAndPatch( tmpDOM, tmpFormContent )
    }
    
    if( tmpContentPgIndex > -1 ){
      let tmpTableOfContentList = tmpDOM[ 0 ].children[ tmpContentPgIndex ].children[ 0 ].children 
      let tmpSummaryPageContentList = _.cloneDeep( tmpTableOfContentList )
      tmpSummaryPageContentList.map( ( item, index ) => {
        if( index === 0 ) {
          item.children[ 0 ].label = Lang[ 'SUMMARY' ]
        } else if ( item.children[ 0 ].type === 'Text' ) {
          item.children[ 0 ].mapWith = item.children[ 0 ].mapWith.replace( 'page_', 'summary_' )
        } 
      })

      tmpRearrangedFormValues.push({
        id: 'DELIVERY_REMARK',
        dataLabel: 'DELIVERY_REMARK',
        value: '',
        type: 'textarea'
      })
      tmpSummaryPageContentList.push({
        id: uniqid(),
        type: 'Col',
        grid: 12,
        children: [
          {
            id: 'DELIVERY_REMARK',
            type: 'TextArea',
            label: Lang[ 'REMARK' ],
            dataMapIndex: _.findIndex( tmpRearrangedFormValues, { id: 'DELIVERY_REMARK' }),
            fontSize: '12px',
            padding: '15px',
            rows: 8,
            resizable: 'None'
          }
        ]
      })
      let tmpSummaryPageDOM = {
        id: uniqid(),
        page: tmpDOM[0].children.length + 1,
        name: Lang[ 'SUMMARY' ],
        type: 'Container',
        mapWith: 'summary',
        children: [
          {
            id: uniqid(),
            type: 'Row',
            mapWith: 'form-content',
            children: tmpSummaryPageContentList
          }
        ]
      }
      tmpDOM[0].children.push( tmpSummaryPageDOM )
      tmpDOM[0].totalPages = tmpDOM[0].children.length
    }

    if( _.values(this.state.name).every(_.isEmpty) ) {
      toast.error( Lang[ 'NAME_IS_REQUIRED' ][ this.props.data.languageReducer.lang ] )
    } else if ( !this.state.categories || this.state.categories.length <= 0 ) {
      toast.error( Lang[ 'CATEGORY_IS_REQUIRED' ][ this.props.data.languageReducer.lang ] )
    } else {
      if( 
        tmpRearrangedFormValues.length < 1 ||
        _.filter( tmpDOM[0].children, item => !item.mapWith || ( item.mapWith && item.mapWith === 'form' ) ).length < 1
      ) {
        toast.error( Lang[ 'VALUE_IS_REQUIRED' ][ this.props.data.languageReducer.lang ] )
      } else {
        let descriptions = _.cloneDeep( this.state.description )
        Object.keys(descriptions).forEach((language, i) => {
          descriptions[language] = descriptions[language].trim()
        })
        let formToSubmit = {
          name: this.state.name,
          description: descriptions,
          content: tmpDOM,
          values: tmpRearrangedFormValues,
          status: this.state.status,
          center_groups: this.state.centerGroups,
          categories: this.state.categories
        }
        if( this.props.mode === 'update' ) {
          this.props.updateDigitalOrderForm( this.props.digitalOrderForm.id, formToSubmit, false )
        } else if ( this.props.mode === 'copy' || this.props.mode === 'create' ){
          this.props.createDigitalOrderForm( formToSubmit )
        }
        this.props.toggleFormEditor( false ) 
        this.props.onChangeDigitalOrderFormHOC( 'showInfoModal', false )
      }
    }
  }


  render = () => {
    return(
      <Modal isOpen={ this.props.toggleFormEditor } size='xl' >
        <ModalHeader toggle={ () => this.props.toggleFormEditor( false ) }>
          { this.props.mode === 'view' ? Lang[ 'VIEW' ][ this.props.data.languageReducer.lang ] : 
            this.props.mode === 'update' ? Lang[ 'UPDATE' ][ this.props.data.languageReducer.lang ] :
            Lang[ 'CREATE' ][ this.props.data.languageReducer.lang ] 
          } 
          {' '}{ Lang[ 'FORM' ][ this.props.data.languageReducer.lang ] }
        </ModalHeader>
        <ModalBody>
          <LanguageSelector 
            currentLang={ this.state.currentLanguage }
            updateLang={ e => { this.setState({ currentLanguage: e }) }}
          />
          {
            this.state.formFrameMounted && <Row>
              <DragDropContext onDragEnd={ this.onDragEnd }>
                <Droppable droppableId="droppable">
                {( provided, snapshot ) => (
                    <div 
                      { ...provided.droppableProps }
                      ref={ provided.innerRef } 
                      className="col-md-12"
                      style={ getListStyle( snapshot.isDraggingOver )}>
                      {
                        this.state.dom.map( item => {
                          return <RenderDOM                       
                            content={ item }
                            onChangeFormBuilderState={ this.onChangeFormBuilderState }
                            mode={ this.props.mode === 'view'? 'view': 'edit' }
                            formValues={ this.state.formValues }
                            onPatchFormValues={ this.onPatchFormValues }
                            onClickSubmitForm={ () => {} }
                            createSubmittedFormMedia={ () => {} }
                            currentPage={ this.state.currentPage }
                            dom={ this.state.dom }
                            findObjectAndPatch={ this.findObjectAndPatch }
                            data={ this.props.data }
                            currentLanguage={ this.state.currentLanguage }
                            showPageRearrangement={ this.state.showPageRearrangement }
                            togglePageSelectable={ this.togglePageSelectable }
                            togglePageSelection={ this.togglePageSelection }
                            duplicatePage={ this.duplicatePage }
                          />
                        })
                      }
                      { provided.placeholder }
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Row>
          }
          <UpdateFormFrame
            showUpdateFormFrame={ this.state.showUpdateFormFrame }
            selectedComponentToEdit={ this.state.selectedComponentToEdit }
            onChangeFormBuilderState={ this.onChangeFormBuilderState }
            findObjectAndPatch={ this.findObjectAndPatch }
            dom={ this.state.dom }
            lang={ this.props.data.languageReducer.lang } />
          <UpdateColumnModal
            showUpdateColumnModal={ this.state.showUpdateColumnModal }
            selectedComponentToEdit={ this.state.selectedComponentToEdit }
            onChangeFormBuilderState={ this.onChangeFormBuilderState }
            findObjectAndPatch={ this.findObjectAndPatch }
            updateColumn={ this.updateColumn }
            dom={ this.state.dom }
            formValues={ this.state.formValues }
            currentPage={ this.state.currentPage }
            lang={ this.props.data.languageReducer.lang } />
          <UpdateContentModal
            showUpdateContentModal={ this.state.showUpdateContentModal }
            selectedParentComponentToEdit={ this.state.selectedParentComponentToEdit }
            selectedComponentToEdit={ this.state.selectedComponentToEdit }
            onChangeFormBuilderState={ this.onChangeFormBuilderState }
            findObjectAndPatch={ this.findObjectAndPatch }
            findObjectById={ this.findObjectById }
            dom={ this.state.dom }
            foundObject={ this.state.foundObject }
            formValues={ this.state.formValues }
            currentPage={ this.state.currentPage }
            lang={ this.props.data.languageReducer.lang } />
          <UpdatePageSettingsModal
            showUpdatePageSettingsModal={ this.state.showUpdatePageSettingsModal }
            selectedComponentToEdit={ this.state.selectedComponentToEdit }
            onChangeFormBuilderState={ this.onChangeFormBuilderState }
            findObjectAndPatch={ this.findObjectAndPatch }
            dom={ this.state.dom }
            lang={ this.props.data.languageReducer.lang }
          />
          <ConfirmDeleteModal
            showPromptModal={ this.state.showConfirmDelete }
            content={ Lang[ 'DELETE_CONFIRMATION_SELECTED_COMPONENT' ][ this.props.data.languageReducer.lang ] }
            onClickYes={() => this.onClickRemoveColumn()}
            onClickNo={() => this.setState({ showConfirmDelete: false, selectedComponentToRemove: {} })}
            lang={ this.props.data.languageReducer.lang } />
          <ConfirmDeleteModal
            showPromptModal={ this.state.showConfirmDeletePage }
            content={ Lang[ 'DELETE_PAGE_CONFIRMATION' ][ this.props.data.languageReducer.lang ] }
            onClickYes={() => this.onClickRemovePage()}
            onClickNo={() => this.setState({ showConfirmDeletePage: false })}
            lang={ this.props.data.languageReducer.lang } />
          <MediaDatabase
            onSelectedImage={( selectedImage ) => {
              let tmpDOM = _.cloneDeep( this.state.dom )
              let tmp = _.cloneDeep( this.state.selectedComponentToEdit )

              if ( this.state.editingImageType === "PAGE_THUMBNAIL" ) {
                tmp[ 'settings' ] = {
                  ...tmp[ 'settings' ],
                  'thumbnailImg': selectedImage
                }
              } else {
                tmp.children[0].url = selectedImage
              }
              this.findObjectAndPatch( tmpDOM, tmp )
              this.onChangeFormBuilderState( 'selectedComponentToEdit', tmp )
              this.props.toggleMediaDatabase( false )
          }} />
        </ModalBody>
        <ModalFooter style={{ justifyContent: "space-between" }} >
          {
            this.props.mode !== 'view' 
            ? <div style={{ display: "flex" }}>
                <div className="border p-2 m-1" style={{ display: "flex" }}>
                  <Button className='mr-2' color="success" onClick={ () => this.setState({ showAddPageModal: true }) }>
                    { Lang[ 'ADD_PAGE' ][ this.props.data.languageReducer.lang ] }
                  </Button>
                  {
                    this.state.dom.length > 0 && this.state.dom[ 0 ].totalPages > 1 && (
                      <Button 
                        color={ this.state.showPageRearrangement ? "secondary" : "primary" } 
                        onClick={ () => this.setState({ showPageRearrangement: !this.state.showPageRearrangement }) }>
                        { 
                          this.state.showPageRearrangement 
                            ? Lang[ 'STOP_PAGE_REARRANGEMENT' ][ this.props.data.languageReducer.lang ] 
                            : Lang[ 'REARRANGE_PAGE' ][ this.props.data.languageReducer.lang ] 
                        }
                      </Button>
                    )
                  }
                </div>
                <div className="border p-2 m-1" style={{ display: "flex" }}>
                  <Button 
                    className='mr-2' 
                    disabled={ 
                      !_.isEmpty( this.state.dom ) && 
                      this.state.dom[ 0 ].children[ this.state.currentPage - 1 ].mapWith && 
                      ( this.state.dom[ 0 ].children[ this.state.currentPage - 1 ].mapWith === 'content' || 
                        this.state.dom[ 0 ].children[ this.state.currentPage - 1 ].mapWith === 'cover' )
                    }
                    color="info" 
                    onClick={ () => this.togglePageSelectable() }>
                      { !_.isEmpty( this.state.dom ) && this.state.dom[ 0 ].children[ this.state.currentPage - 1 ].selectable
                        ? Lang[ 'SET_PAGE_UNSELECTABLE' ][ this.props.data.languageReducer.lang ]
                        : Lang[ 'SET_PAGE_SELECTABLE' ][ this.props.data.languageReducer.lang ]
                      } 
                  </Button>
                  <Button 
                    className='mr-2' 
                    disabled={ 
                      !_.isEmpty( this.state.dom ) && 
                      this.state.dom[ 0 ].children[ this.state.currentPage - 1 ].mapWith && 
                      this.state.dom[ 0 ].children[ this.state.currentPage - 1 ].mapWith === 'content'
                    }
                    color="primary" 
                    onClick={ () => this.duplicatePage() }>
                      { Lang[ 'DUPLICATE_PAGE' ][ this.props.data.languageReducer.lang ] }
                  </Button>
                  {
                    this.state.showRemovePageButton && (
                      <Button 
                        color="danger" 
                        className='mr-2' 
                        disabled={ 
                          !_.isEmpty( this.state.dom ) && 
                          this.state.dom[ 0 ].children[ this.state.currentPage - 1 ].mapWith && 
                          this.state.dom[ 0 ].children[ this.state.currentPage - 1 ].mapWith === 'content'
                        }
                        onClick={ () => this.setState({ showConfirmDeletePage: true }) }>
                        { Lang[ 'REMOVE_PAGE' ][ this.props.data.languageReducer.lang ] }</Button>
                    )
                  }
                </div>
              </div>
            : <div></div>  
          }
          <Button 
            color="primary" 
            onClick={ () => { this.props.onChangeDigitalOrderFormHOC( 'showInfoModal', true ) }}>
            { Lang[ 'NEXT' ][ this.props.data.languageReducer.lang ] }
          </Button>
        </ModalFooter>
        { this.renderFormInfoModal() }
        { this.renderAddPageModal() }
        { ( this.props.data.ajaxReducer.ajaxCallProgress > 0 || this.props.onLoadDigitalOrderForms ) && <LoadingOverlay /> }
      </Modal>
    )
  }
}

const mapStateToProps = state => ({ data: state })

export default connect( mapStateToProps, {
  toggleMediaDatabase
}) (FormEditor)