import React, { Component } from 'react'
import { compose } from 'redux'
import {
  Button, Modal, ModalHeader,
  ModalBody, ModalFooter,
  Table, UncontrolledTooltip
} from 'reactstrap'
import Image from 'react-bootstrap/Image'
import _ from 'lodash'

import ARKTable from 'components/Table'
import DeleteConfirmation from 'components/Modal/delete'
import CenterForm from './components/form'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PageTitle from 'components/PageTitle'
import getDomainURL from 'utils/api'
import CockpitIcon from 'assets/images/admin-portal-icon.png'
import CenterDetails from './CenterDetails'
import UpdateDomain from './UpdateDomain'
import UpdateLeadConnect from './UpdateLeadConnect'
import UpdateForm from './Update/index'

import CenterHOC from './actions'
import OpeningHourHOC from './actions/openingHour'
import ClientsHOC from 'actions/client'
import CountryHOC from 'actions/country'
import ConsultantHOC from 'actions/consultant'
import CenterGroupHOC from 'actions/centerGroup'
import WithDomains from './actions/Domains'
import LeadhubCategoriesHOC from './actions/LeadhubCategories'
import UsersHOC from 'containers/UsersManagement/actions'

import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

class CenterManagement extends Component{
  state= {
    searchColumn: [
      {
        name: Lang[ 'CENTER_NAME' ][ this.props.data.languageReducer.lang ],
        type: "text",
        val: 'center_name'
      },
      {
        name: Lang[ 'CENTER_GROUP' ][ this.props.data.languageReducer.lang ],
        type: 'searchableDropdown',
        val: 'center_group_id',
        dropdownOptions: []
      },
      {
        name: Lang[ 'SITE_STATUS' ][ this.props.data.languageReducer.lang ],
        type: 'dropdown',
        val: 'is_live_site',
        dropdownOptions: [
          {id: 'LIVE', label: Lang[ 'LIVE' ][ this.props.data.languageReducer.lang ], value: true },
          {id: 'NOT_LIVE', label: Lang[ 'NOT_LIVE' ][ this.props.data.languageReducer.lang ], value: false }
        ]
      },
      {
        name: Lang[ 'COUNTRY' ][ this.props.data.languageReducer.lang ],
        type: "text",
        val: 'country'
      },
      {
        name: Lang[ 'POSTCODE' ][ this.props.data.languageReducer.lang ],
        type: "text",
        val: 'postcode'
      },
      {
        name: Lang[ 'CITY' ][ this.props.data.languageReducer.lang ],
        type: "text",
        val: 'city'
      },
      {
        name: Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ],
        type: 'dropdown',
        val: 'status'
      },
      {
        name: Lang[ 'SEARCH_BY_CONSULTANT' ][ this.props.data.languageReducer.lang ],
        type: 'searchableDropdown',
        val: 'consultant_id',
        dropdownOptions: []
      },
      {
        name: Lang[ 'SEARCH_BY_CLIENT' ][ this.props.data.languageReducer.lang ],
        type: 'searchableDropdown',
        val: 'client_id',
        dropdownOptions: []
      },
    ],
    archivedSearchColumn: [
      {
        name: Lang[ 'CENTER_NAME' ][ this.props.data.languageReducer.lang ],
        type: "text",
        val: 'center_name'
      },
      {
        name: Lang[ 'FIRST_NAME' ][ this.props.data.languageReducer.lang ],
        type: "text",
        val: 'first_name'
      },
      {
        name: Lang[ 'LAST_NAME' ][ this.props.data.languageReducer.lang ],
        type: "text",
        val: 'last_name'
      },
      {
        name: Lang[ 'COUNTRY' ][ this.props.data.languageReducer.lang ],
        type: "text",
        val: 'country'
      },
      {
        name: Lang[ 'POSTCODE' ][ this.props.data.languageReducer.lang ],
        type: "text",
        val: 'postcode'
      },
      {
        name: Lang[ 'CITY' ][ this.props.data.languageReducer.lang ],
        type: "text",
        val: 'city'
      }
    ],
    selectedSearchColumn: []
  }

  componentDidMount = async() => {
    let tmp = {
      page: this.props.centers.meta ? this.props.centers.meta.current_page : 1,
      is_paginated: true,
      filter: {
        $or: [
          { status: 'ACTIVE' },
          { status: 'TRIAL' }
        ]
      }
    }
    let tmpQ = {
      page: 1,
      is_paginated: false
    }
    let tmpUser = {
      filter: {
        role_id: 8
      }
    }
    this.props.getCountriesEn()
    this.props.getConsultants( tmpQ )
    this.props.getCenterGroupsForSelect( tmpQ )
    this.props.getCenters( tmp )
    this.props.getLeadhubCategories()
    this.props.getUsers( convertObjToBase64( tmpUser ) )
    await this.props.getClients( tmpQ )
    this.loadCenterData()
  }

  componentDidUpdate = pp => {
    if( pp.showNewCenterModal && !this.props.showNewCenterModal ){
      this.loadCenterData()
    }
    if( pp.showEditCenterModal && !this.props.showEditCenterModal ){
      this.props.onChangeCenterHOC( 'errorMessage', {} )
      this.props.onChangeCenterHOC( 'centerLocations', [] )
      this.props.onChangeCenterHOC( 'selectedCenter', {} )
    }
    if( pp.consultants !== this.props.consultants ){
      if ( this.props.consultants ) {
        let consultantOptions = []
        this.props.consultants.data.map( item => {
          consultantOptions.push({
            id: item.id,
            label: `${ item.first_name } ${ item.last_name } ( ${ item.email } )`,
            value: `${ item.first_name } ${ item.last_name } ( ${ item.email } )`
          })
        })

        let tmp = _.cloneDeep( this.state.searchColumn )
        let consultantColumnIndex = _.findIndex( this.state.searchColumn, { val: 'consultant_id' })
        tmp[ consultantColumnIndex ].dropdownOptions = consultantOptions
        this.setState({ searchColumn: tmp })
      }
    }
    if( pp.clients !== this.props.clients ){
      if ( this.props.clients ) {
        let clientOptions = []
        this.props.clients.data.map( item => {
          clientOptions.push({
            id: item.id,
            label: `${ item.company_name } (${ item.house_number },${ item.street },${ item.city },${ item.country })`,
            value: `${ item.company_name } (${ item.house_number },${ item.street },${ item.city },${ item.country })`
          })
        })

        let tmp = _.cloneDeep( this.state.searchColumn )
        let clientColumnIndex = _.findIndex( this.state.searchColumn, { val: 'client_id' })
        tmp[ clientColumnIndex ].dropdownOptions = clientOptions
        this.setState({ searchColumn: tmp })
      }
    }
    if( pp.centerGroupForSelect !== this.props.centerGroupForSelect ) {
      if ( this.props.centerGroupForSelect ) {
        let centerGroupOptions = _.cloneDeep( this.props.centerGroupForSelect )
        centerGroupOptions = centerGroupOptions.filter( (centerGroup) => {
          return centerGroup.slug !== "all-centers"
        })
        let tmp = _.cloneDeep( this.state.searchColumn )
        let centerGroupColumnIndex = _.findIndex( this.state.searchColumn, { val: 'center_group_id' })
        tmp[ centerGroupColumnIndex ].dropdownOptions = centerGroupOptions
        this.setState({ searchColumn: tmp })
      }
    }
  }

  loadCenterData = () => {
    let tmpNewCenterData = {
      client_id: 0,
      consultant_id: 0,
      salutation: 'Herr',
      first_name: '',
      last_name: '',
      center_name: '',
      email: '',
      manager: '',
      language: 'de',
      is_female_center: false,
      status: 'ACTIVE',
      center_groups_id: [],
      email_api_service: 'mailgun'
    }
    this.props.onChangeCenterHOC( 'newCenterData', tmpNewCenterData )
  }

  updateSelectedSearchColumn = ( key, val ) => {
    if( this.state.selectedSearchColumn.length > 0 ){
      let tmp = _.cloneDeep( this.state.selectedSearchColumn )
      let tmpSearchParam = _.find( tmp, { val: key })

      if( key === 'has_consultant' ){
        tmpSearchParam.searchValue = val === 'true'
      } else {
        tmpSearchParam.searchValue = val
      }
      return this.setState({ selectedSearchColumn: tmp })
    }
  }

  handleChange = ( e, val ) => this.setState({ [val]: e.target.value})

  handleCheck = ( e ) => this.setState({ femaleChecked: e.target.checked })

  handleModalClose = () => this.setState({ newCenterModal: false, editOpen: false, deleteOpen: false, showOpen: false })

  handleDelete = () => this.setState({ deleteOpen: true })

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Center' })
      return tmpModules.is_create
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Center' })
      return tmpModules.is_update
    }
  }

  handleSecurityModulesDelete = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Center' })
      return tmpModules.is_delete
    }
  }

  renderNewCenter = () => {
    return (
      <Modal
        isOpen={ this.props.showNewCenterModal }
        size="xl"
        toggle={ () => {
          Promise.all([
            this.props.onChangeCenterHOC( 'showNewCenterModal', false )
          ]).then(
            this.props.onChangeCenterHOC( 'errorMessage', {} )
          )
        }}>
        <ModalHeader
          toggle={ () => {
            Promise.all([
              this.props.onChangeCenterHOC( 'showNewCenterModal', false )
            ]).then(
              this.props.onChangeCenterHOC( 'errorMessage', {} )
            )
          }}>{ Lang[ 'NEW_CENTER' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <CenterForm
            centerData={ this.props.newCenterData }
            countries={ this.props.countries }
            clientsList={ this.props.clients.data }
            consultantsList={ this.props.consultants.data }
            getStates={ this.props.getStates }
            states={ this.props.states }
            onChangeCenterHOC={ this.props.onChangeCenterHOC }
            onLoadCountries={ this.props.onLoadCountries }
            statusValues={ this.props.data.dictionaryReducer.dictionary.status }
            centerGroupList={ this.props.centerGroupForSelect }
            languages={ this.props.data.dictionaryReducer.dictionary.languages }
            errorMessage={ this.props.errorMessage }
            getSelectedClient={ this.props.getSelectedClient }
            selectedClient={ this.props.selectedClient }/>
          <Button className="mb-2 mr-2" color="info" onClick={ () => this.props.postCenter() }>{ Lang[ 'CREATE' ][ this.props.data.languageReducer.lang ] }</Button>
          { this.props.onLoadCenters && <LoadingOverlay/> }
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.onChangeCenterHOC( 'showNewCenterModal', false ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderEditCenter = () => {
    return(
      <Modal
        isOpen={ this.props.showEditCenterModal }
        size="xl"
        toggle={ () => this.props.onChangeCenterHOC( 'showEditCenterModal', false ) }>
        <ModalHeader
          toggle={ () => this.props.onChangeCenterHOC( 'showEditCenterModal', false ) }>
          { Lang[ 'EDIT_CENTER' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <UpdateForm
            selectedCenter={ this.props.selectedCenter }
            countries={ this.props.countries }
            clientsList={ this.props.clients.data }
            consultantsList={ this.props.consultants.data }
            usersList={ this.props.users.data }
            leadhubCategoriesOptions={ this.props.leadhubCategoriesOptions }
            getStates={ this.props.getStates }
            states={ this.props.states }
            onChangeCenterHOC={ this.props.onChangeCenterHOC }
            onLoadCountries={ this.props.onLoadCountries }
            statusValues={ this.props.data.dictionaryReducer.dictionary.status }
            centerGroupList={ this.props.centerGroupForSelect }
            errorMessage={ this.props.errorMessage }
            languages={ this.props.data.dictionaryReducer.dictionary.languages }
            mode={ 'edit' }
            updateCenter={ this.props.updateCenter }
            centerLocations={ this.props.centerLocations }
            updateCenterLocation={ this.props.updateCenterLocation }
            phoneNum={ this.props.phoneNum }
            deleteCenterLocations={ this.props.deleteCenterLocations }
            createCenterLocation={ this.props.createCenterLocation }
            deleteLocationConfirmation={ this.props.deleteLocationConfirmation }
            addItemModal={ this.props.addItemModal }
            onLoadCenters={ this.props.onLoadCenters }
            openingHours={ this.props.openingHours }
            getSelectedClient={ this.props.getSelectedClient }
            selectedClient={ this.props.selectedClient }
          />
          { this.props.onLoadCenters && <LoadingOverlay/> }
        </ModalBody>
        <ModalFooter>
          <Button
            className="mb-2 mr-2"
            color="primary"
            onClick={ () => this.props.onChangeCenterHOC( 'showEditCenterModal', false ) }>
            { Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderViewCenterDetails = () => {
    return(
      <Modal
        isOpen={ this.props.showViewCenterModal }
        size="lg"
        toggle={ () => this.props.onChangeCenterHOC( 'showViewCenterModal', false ) }>
        <ModalHeader toggle={ () => this.props.onChangeCenterHOC( 'showViewCenterModal', false ) }>{ Lang[ 'CENTER_DETAILS' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <div className="scroll-area-lg">
            <Table className="mb-0" bordered>
              <CenterDetails
                selectedCenter={ this.props.selectedCenter }
                centerLocations={ this.props.centerLocations }
                lang={ this.props.data.languageReducer.lang } />
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={ () => {
            Promise.all([
              this.props.onChangeCenterHOC( 'showViewCenterModal', false )
            ]).then(
              this.props.onChangeCenterHOC( 'centerLocations', [] )
            )
          }}>OK</Button>
          {
            this.handleSecurityModulesUpdate() && (
              <Button
                color="alternate"
                onClick={ () => {
                  this.props.onChangeCenterHOC( 'showViewCenterModal', false )
                  this.props.onChangeCenterHOC( 'showEditCenterModal', true )
                }}>{ Lang[ 'EDIT' ][ this.props.data.languageReducer.lang ] }</Button>
            )
          }
        </ModalFooter>
      </Modal>
    )
  }

  renderShowArchived = () => {
    return (
      <Modal
        isOpen={ this.props.showArchivedCenterModal }
        size="xl"
        toggle={ () => this.props.onChangeCenterHOC( 'showArchivedCenterModal', false ) }>
        <ModalHeader toggle={ () => this.props.onChangeCenterHOC( 'showArchivedCenterModal', false ) }>{ Lang[ 'ARCHIVED_CENTER_LIST' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <ARKTable
            data={ this.props.archivedCenters.data }
            columns={[
              {
                Header: Lang[ 'CENTER_NAME' ][ this.props.data.languageReducer.lang ],
                accessor: "center_name"
              },
              {
                Header: Lang[ 'NAME' ][ this.props.data.languageReducer.lang ],
                accessor: "fullname"
              },
              {
                Header: 'Location',
                accessor: 'locations',
                Cell: ({ value }) => (
                  <ul>
                    {
                      value.map( item => {
                        return (
                          <li>{ `${ item.name }`}</li>
                        )
                      })
                    }
                  </ul>
                )
              },
              {
                Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
                accessor: "id",
                show: this.handleSecurityModulesUpdate(),
                Cell: row => (
                  <div>
                    <Button
                      id={ `Center-${ row.original.id }-unarchive-center` }
                      className="mb-2 mr-2 btn-icon btn-icon-only"
                      color="danger"
                      onClick={ () => {
                        this.props.onChangeCenterHOC( 'confirmUnarchiveModal', true )
                        this.props.onChangeCenterHOC( 'unarchiveCenterID', row.original.id )
                      }}>
                      <i className="pe-7s-menu"> </i>
                    </Button>
                    <UncontrolledTooltip
                      target={ `Center-${ row.original.id }-unarchive-center` }
                      placement="top">{ Lang[ 'UNARCHIVE_CENTER' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                    {
                      this.props.data.profileReducer?.profile?.role === 'Super Admin' && (
                        <>
                          <Button
                            id={ `Center-${ row.original.id }-delete-center` }
                            className="mb-2 mr-2 btn-icon btn-icon-only"
                            color="danger"
                            onClick={ () => this.props.onChangeCenterHOC( 'deleteCenterId', parseInt( row.original.id ) ) }>
                            <i className="pe-7s-trash"> </i>
                          </Button>
                          <UncontrolledTooltip
                            target={ `Center-${ row.original.id }-delete-center` }
                            placement="top">{ Lang[ 'DELETE_CENTER' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                        </>
                      )
                    }
                  </div>
                )
              }
            ]}
            totalPages={ this.props.archivedCenterTotalPage }
            page={ this.props.archivedCenters.meta ? this.props.archivedCenters.meta.current_page : 1 }
            onChangePage={ pg => {
              let tmp = {
                page: pg,
                is_paginated: true,
                filter: {
                  status: 'INACTIVE'
                }
              }
              return this.props.getArchivedCenter( tmp )
            }}
            onClickReset={ () => {
              let tmp = {
                page: this.props.archivedCenters.meta ? this.props.archivedCenters.meta.current_page : 1,
                is_paginated: true,
                filter: {
                  status: 'INACTIVE'
                }
              }
              this.props.getArchivedCenter( tmp )
            }}
            onSearch={ this.props.getArchivedCenter }
            searchLabel={ Lang[ 'SEARCH_WITH_CENTER_NAME' ][ this.props.data.languageReducer.lang ] }
            searchComponent={ true }
            searchColumn={ this.state.archivedSearchColumn }
            showPagination={ false }
            updateSelectedSearchColumn={ this.updateSelectedSearchColumn } />
            {
              this.props.confirmUnarchiveModal && (
                <DeleteConfirmation
                  handleModalClose={ () => this.props.onChangeCenterHOC( 'confirmUnarchiveModal', false ) }
                  deleteOpen={ this.props.confirmUnarchiveModal }
                  confirmAction={ () => {
                    this.props.activateCenter( this.props.unarchiveCenterID )
                    this.props.onChangeCenterHOC( 'confirmUnarchiveModal', false )
                  }}
                  content={ Lang[ 'CONFIRMATION_TEXT_ARCHIVED' ][ this.props.data.languageReducer.lang ] }
                  lang={ this.props.data.languageReducer.lang } />
              )
            }
            {
              this.props.deleteCenterId > 0 && (
                <DeleteConfirmation
                  handleModalClose={ () => this.props.onChangeCenterHOC( 'deleteCenterId', 0 ) }
                  deleteOpen={ this.props.deleteCenterId > 0 }
                  confirmAction={ () => this.props.deleteCenter() }
                  content={ Lang[ 'ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_CENTER' ][ this.props.data.languageReducer.lang ] }
                  lang={ this.props.data.languageReducer.lang } />
              )
            }
          { this.props.onLoadCenters && <LoadingOverlay/> }
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.onChangeCenterHOC( 'showArchivedCenterModal', false ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  render = () => {
    return (
      <>
        <div>
          <PageTitle
            heading={ Lang[ 'CENTER' ][ this.props.data.languageReducer.lang ] }
            icon="pe-7s-home icon-gradient bg-happy-fisher"
            buttons={[
              {
                buttonText: Lang[ 'ARCHIVED_CENTERS' ][ this.props.data.languageReducer.lang ],
                onClick: () => {
                  let tmp = {
                    page: this.props.archivedCenters.meta ? this.props.archivedCenters.meta.current_page : 1,
                    is_paginated: true
                  }
                  this.props.onChangeCenterHOC( 'showArchivedCenterModal', true )
                  this.props.getArchivedCenter( tmp )
                },
                display: true
              },
              {
                buttonText: Lang[ 'NEW_CENTER' ][ this.props.data.languageReducer.lang ],
                onClick: () => {
                  Promise.all([
                    this.props.onChangeCenterHOC( 'showNewCenterModal', true )
                  ]).then(
                    () => this.props.onChangeCenterHOC( 'errorMessage', {} )
                  )
                },
                display: this.handleSecurityModulesCreate()
              }
            ]}/>
          <ARKTable
            data={ this.props.centers.data }
            columns={[
              {
                Header: Lang[ 'CENTER_NAME' ][ this.props.data.languageReducer.lang ],
                accessor: "center_name"
              },
              {
                Header: Lang[ 'NAME' ][ this.props.data.languageReducer.lang ],
                accessor: "fullname"
              },
              {
                Header: Lang[ 'LOCATION' ][ this.props.data.languageReducer.lang ],
                accessor: 'locations',
                Cell: ({ value }) => (
                  <ul>
                    {
                      value.map( item => {
                        return (
                          <li>{ `${ item.name }`}</li>
                        )
                      })
                    }
                  </ul>
                )
              },
              {
                Header: Lang['STATUS'][ this.props.data.languageReducer.lang ],
                accessor: 'status'
              },
              {
                Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
                accessor: "id",
                width: 160,
                Cell: row => (
                  <div className="w-100" style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                    <div className="text-align-center">
                      {
                        this.handleSecurityModulesUpdate() && (
                          <>
                            <Button
                              id={ `Center-${ row.original.id }-center-domains` }
                              className="mb-2 mr-2 btn-icon btn-icon-only"
                              color="primary"
                              onClick={ () => this.props.getCenterDomains( row.original.id ) }>
                              <i className="pe-7s-tools"> </i>
                            </Button>
                            <UncontrolledTooltip
                              target={ `Center-${ row.original.id }-center-domains` }
                              placement="top">{ Lang[ 'CENTER_DOMAINS_CONFIG' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                          </>
                        )
                      }
                      <Button
                        id={ `Center-${ row.original.id }-view-center` }
                        className="mb-2 mr-2 btn-icon btn-icon-only"
                        color="primary"
                        onClick={ () => this.props.getSelectedCenterView( row.original.id ) }>
                        <i className="pe-7s-look"> </i>
                      </Button>
                      <UncontrolledTooltip
                        target={ `Center-${ row.original.id }-view-center` }
                        placement="top">{ Lang[ 'VIEW_CENTER' ][ this.props.data.languageReducer.lang ] }
                      </UncontrolledTooltip>
                      {
                        this.handleSecurityModulesUpdate() && (
                          <>
                            <Button
                              id={ `Center-${ row.original.id }-update-center` }
                              className="mb-2 mr-2 btn-icon btn-icon-only"
                              color="primary"
                              onClick={ () => {
                                this.props.getSelectedCenterEdit( row.original.id )
                                this.props.onChangeCenterHOC( 'errorMessage', {} )
                              }}>
                              <i className="pe-7s-note"> </i>
                            </Button>
                            <UncontrolledTooltip
                              target={ `Center-${ row.original.id }-update-center` }
                              placement="top">{ Lang[ 'UPDATE_CENTER' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                          </>
                        )
                      }
                    </div>
                    <div className="text-align-center">
                      {
                        this.handleSecurityModulesUpdate() && (
                          <>
                            <Button
                              id={ `Center-${ row.original.id }-update-lead-connect` }
                              className="mb-2 mr-2 btn-icon btn-icon-only"
                              color="primary"
                              onClick={() => {
                                this.props.onChangeCenterHOC( 'showUpdateLeadConnect', true )
                                this.props.getCenterLeadConnectID( row.original.id )
                              }}>
                              <span 
                                style={{ 
                                  position: "absolute",
                                  top: "6px",
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  height: "16px",
                                }} >
                                L
                              </span>
                              <i className="pe-7s-global" style={{ opacity: "0" }} />
                            </Button>
                            <UncontrolledTooltip
                              target={ `Center-${ row.original.id }-update-lead-connect` }
                              placement="top">{ Lang[ 'UPDATE_LEAD_CONNECT' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                          </>
                        )
                      }
                      {
                        this.handleSecurityModulesDelete() && (
                          <>
                            <Button
                              id={ `Center-${ row.original.id }-archive-center` }
                              className="mb-2 mr-2 btn-icon btn-icon-only"
                              color="danger"
                              onClick={ () => {
                                this.props.onChangeCenterHOC( 'showDeleteConfirmation', true )
                                this.props.onChangeCenterHOC( 'toArchiveCenterid', row.original.id )
                              }}>
                              <i className="pe-7s-trash"> </i>
                            </Button>
                            <UncontrolledTooltip
                              target={ `Center-${ row.original.id }-archive-center` }
                              placement="top">{ Lang[ 'ARCHIVED_CENTERS' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                          </>
                        )
                      }
                      <Button
                        id={ `Center-${ row.original.id  }-login-cockpit` }
                        className="mr-2 mb-2 btn-icon btn-icon-only"
                        color="secondary"
                        onClick={ () => {
                          let tmpDomainUrl = getDomainURL()
                          let tmpCockpitUrl = 'https://'
                          if( tmpDomainUrl.indexOf( 'staging' ) > -1 ) {
                            tmpCockpitUrl += 'cockpit-staging.proof-point-suite.com'
                          } else if( tmpDomainUrl.indexOf( 'tennis' ) > -1 ) {
                            tmpCockpitUrl += 'adminportal.tennis-suite.com'
                          } else if( tmpDomainUrl.indexOf( 'aciso' ) > -1 ) {
                            tmpCockpitUrl += 'adminportal.aciso-suite.com'
                          } else if( tmpDomainUrl.indexOf( 'proof-point-suite' ) > -1 ) {
                            tmpCockpitUrl += 'adminportal.proof-point-suite.com'
                          }
                          tmpCockpitUrl += `/#/login/${ localStorage.getItem( 'PROOFPOINT_ARK_MANAGEMENT' ) }/${ row.original.id }`
                          window.open( tmpCockpitUrl )
                        }}>
                        <Image 
                          src={ CockpitIcon } 
                          style={{ 
                            position: "absolute",
                            top: "7px",
                            left: "50%",
                            transform: "translateX(-50%)",
                            height: "16px",
                          }} 
                        />
                        <i className="pe-7s-global" style={{ opacity: "0" }} />
                      </Button>
                      <UncontrolledTooltip
                        target={ `Center-${ row.original.id  }-login-cockpit` }
                        placement="top">{ Lang[ 'LOGIN_COCKPIT' ][ this.props.data.languageReducer.lang ] }
                      </UncontrolledTooltip>
                    </div>
                  </div>
                )
              }
            ]}
            totalPages={ this.props.centerTotalPage }
            page={ this.props.centers.meta ? this.props.centers.meta.current_page : 1 }
            onChangePage={ pg => {
              let tmp = {
                page: pg,
                is_paginated: true,
                filter: {
                  $or: [
                    { status: 'ACTIVE' },
                    { status: 'TRIAL' }
                  ]
                }
              }
              this.props.getCenters( tmp )
            }}
            onClickReset={ () => {
              let tmp = {
                page: this.props.centers.meta ? this.props.centers.meta.current_page : 1,
                is_paginated: true,
                filter: {
                  $or: [
                    { status: 'ACTIVE' },
                    { status: 'TRIAL' }
                  ]
                }
              }
              this.props.getCenters( tmp )
            }}
            onSearch={ this.props.getCentersSearch }
            searchLabel={ Lang[ 'SEARCH_WITH_CENTER_NAME' ][ this.props.data.languageReducer.lang ] }
            searchComponent={ true }
            searchColumn={ this.state.searchColumn }
            showPagination={ false }
            updateSelectedSearchColumn={ this.updateSelectedSearchColumn }
            modulesUsed={'Trial'} />

            { this.renderNewCenter() }
            { this.renderEditCenter() }
            { this.renderViewCenterDetails() }
            { this.renderShowArchived() }

            <UpdateDomain { ...this.props } />
            <UpdateLeadConnect { ...this.props } />

            {
              this.props.showDeleteConfirmation && (
                <DeleteConfirmation
                  handleModalClose={ () => this.props.onChangeCenterHOC( 'showDeleteConfirmation', false ) }
                  deleteOpen={ this.props.showDeleteConfirmation }
                  confirmAction={ () => {
                    this.props.deactivateCenter( this.props.toArchiveCenterid )
                    this.props.onChangeCenterHOC( 'showDeleteConfirmation', false )
                  }}
                  content={ Lang[ 'CONFIRMATION_TEXT' ][ this.props.data.languageReducer.lang ] }
                  lang={ this.props.data.languageReducer.lang }/>
              )
            }
        </div>
        { (
          this.props.data.ajaxReducer.ajaxCallProgress > 0 ||
          this.props.onLoadClients ||
          this.props.onLoadConsultants ||
          this.props.onLoadCenters ||
          this.props.onLoadCenterDomains ||
          this.props.onLoadLeadhubCategories
          ) && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose(
  ClientsHOC,
  CountryHOC,
  ConsultantHOC,
  CenterGroupHOC,
  CenterHOC,
  OpeningHourHOC,
  WithDomains,
  LeadhubCategoriesHOC,
  UsersHOC
)( CenterManagement )
