import React, { useState, useEffect } from 'react'
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button, UncontrolledTooltip,
  Card, CardHeader, CardBody, Form, FormGroup, Label
} from 'reactstrap'
import { compose } from 'redux'
import _ from 'lodash'
import ReactSelect from 'react-select'

import ARKTable from 'components/Table'
import PageTitle from 'components/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import DeleteConfirmation from 'components/Modal/delete'
import Lang from 'Lang/General'

import KnowledgeForm from './Form'
import KnowledgeFileContent from './Files'
import KnowledgesHOC from './actions'
import ContentCategoriesHOC from '../ContentSettings/Categories/actions'
import CenterGroupsHOC from './actions/centerGroups'

const Knowledges = props => {
  // const [ isInjoyKnowledgesSearch, updateIsInjoyKnowledgesSearch ] = useState( false )
  const [ searchColumn, updateSearchColumn ] = useState([
    {
      name: Lang[ 'TITLE' ][ props.data.languageReducer.lang ],
      type: "text",
      val: 'title'
    },
    {
      name: Lang[ 'STATUS' ][ props.data.languageReducer.lang ],
      type: 'dropdown',
      val: 'status',
      dropdownOptions: []
    },
    {
      name: Lang[ 'CATEGORIES' ][ props.data.languageReducer.lang ],
      type: 'searchableDropdown',
      val: 'category_id',
      dropdownOptions: []
    },
    {
      name: Lang[ 'CENTER_GROUP' ][ props.data.languageReducer.lang ],
      type: 'searchableDropdown',
      val: 'center_group_id',
      dropdownOptions: []
    }
  ])
  const [ selectedCategory, updateSelectedCategory ] = useState( [] )

  useEffect( () => {
    props.getKnowledges({ 
      page: 1, 
      is_paginated: true
    })
    props.getCenterGroups()
    props.getCategories({
      filter: {
        type: 'KNOWLEDGE'
      }
    })
  }, [] )

  useEffect( () => {
    if( !props.showCreateKnowledge ){
      props.onChangeKnowledgesHOC( 
        'newKnowledge', 
        { 
          category_id: 0,
          center_groups: [],
          content: {},
          description: { en: '', de: '', it: '', nl: '' },
          status: 'ACTIVE',
          thumbnail_image_url: '',
          title: { en: '', de: '', it: '', nl: '' }
        } 
      )
    }
  }, [ props.showCreateKnowledge ] )

  useEffect( () => {
    if( !props.showUpdateKnowledge ){
      props.onChangeKnowledgesHOC( 'selectedKnowledge', {} )
    }
  }, [ props.showUpdateKnowledge ] )

  useEffect( () => {
    if( props.categories.length > 0 || props.centerGroups.length > 0 ){
      let tmpSearchColumn = _.cloneDeep( searchColumn )
      if( props.categories.length > 0 ){
        let tmpIndex = _.findIndex( tmpSearchColumn, { val: 'category_id' } )
        tmpSearchColumn[ tmpIndex ][ 'dropdownOptions' ] = props.categories.map( category => ({
          id: category.id,
          label: category.name[ props.data.languageReducer.lang ],
          value: category.name[ props.data.languageReducer.lang ],
        }) )
      }
      if( props.centerGroups.length > 0 ){
        let tmpCgIndex = _.findIndex( tmpSearchColumn, { val: 'center_group_id' } )
        tmpSearchColumn[ tmpCgIndex ][ 'dropdownOptions' ] = _.cloneDeep( props.centerGroups )
      }
      updateSearchColumn( tmpSearchColumn )
    }
  }, [ props.categories, props.centerGroups ] )

  const handleSecurityModulesCreate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Member Area' })
      return tmpModules.is_create
    }
  }

  const handleSecurityModulesUpdate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Member Area' })
      return tmpModules.is_update
    }
  }

  const handleSecurityModulesDelete= () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Member Area' })
      return tmpModules.is_delete
    }
  }

  // const searchKnowledges = ( query, type, searchParams, searchVal ) => {
  //   let tmpQuery = _.cloneDeep( query )
  //   if ( type === 'Normal Search' ) {
  //     tmpQuery = {
  //       ...tmpQuery,
  //       filter: {
  //         title: {
  //           $like: `%${ searchVal.toLowerCase() }%`
  //         }
  //       }
  //     }
  //     updateIsInjoyKnowledgesSearch( false )
  //   } else if ( type === 'Advanced Search' ) {
  //     let tmpFilter = {}
  //     _.filter( searchParams, item => !_.isEmpty( item.searchValue ) ).map( item => {
  //       if ( item.val === 'title' ) {
  //         tmpFilter[ item.val ] = { $like: `%${ item.searchValue.toLowerCase() }%` }
  //       } else if ( item.val === 'status' ) {
  //         tmpFilter[ item.val ] = item.searchValue
  //       } else {
  //         tmpFilter[ item.val ] = item.searchValue.id
  //       }
  //     })
  //     if( !_.isEmpty( tmpFilter ) ){
  //       tmpQuery = {
  //         ...tmpQuery,
  //         filter: tmpFilter
  //       }
  //       if( tmpFilter.category_id && tmpFilter.category_id > 0 ) {
  //         tmpQuery.filter[ 'has_categories' ] = true
  //         tmpQuery[ 'sort' ] = [ '+injoy_week_number' ]
  //       } else {
  //         tmpQuery.filter[ 'has_categories' ] = false
  //         tmpQuery[ 'sort' ] = [ '+week_number' ]
  //       }
  //     }
  //     updateIsInjoyKnowledgesSearch( tmpFilter.category_id && tmpFilter.category_id > 0 )
  //   }
  //   props.getKnowledges( tmpQuery ) 
  // }

  const renderCreateKnowledgeModal = () => (
    <Modal size='md' isOpen={ props.showCreateKnowledge } >
      <ModalHeader toggle={ () => props.onChangeKnowledgesHOC( 'showCreateKnowledge', false ) } >
        { Lang[ 'CREATE' ][ props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        <KnowledgeForm
          knowledgeType='newKnowledge'
          currentKnowledge={ props.newKnowledge }
          onChangeKnowledgesHOC={ props.onChangeKnowledgesHOC }
          centerGroups={ props.centerGroups }
          lang={ props.data.languageReducer.lang }
          categories={ searchColumn[ 2 ].dropdownOptions }
          toggleMediaDatabase={ props.toggleMediaDatabase }
          knowledgeErrorMessage={ props.knowledgeErrorMessage }
        />
        { props.onLoadKnowledgesHOC && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => props.createKnowledge( props.newKnowledge ) }>
          { Lang[ 'SUBMIT' ][ props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )

  const renderUpdateKnowledgeModal = () => (
    <Modal size='md' isOpen={ props.showUpdateKnowledge } >
      <ModalHeader toggle={ () => props.onChangeKnowledgesHOC( 'showUpdateKnowledge', false ) } >
        { Lang[ 'UPDATE' ][ props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        {
          !_.isEmpty( props.selectedKnowledge ) && <KnowledgeForm
            knowledgeType='selectedKnowledge'
            currentKnowledge={ props.selectedKnowledge }
            onChangeKnowledgesHOC={ props.onChangeKnowledgesHOC }
            centerGroups={ props.centerGroups }
            lang={ props.data.languageReducer.lang }
            categories={ searchColumn[ 2 ].dropdownOptions }
            toggleMediaDatabase={ props.toggleMediaDatabase }
            knowledgeErrorMessage={ props.knowledgeErrorMessage }
          />
        }
        { props.onLoadKnowledgesHOC && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => props.updateKnowledge( props.selectedKnowledge ) }>
          { Lang[ 'SUBMIT' ][ props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )

  const renderKnowledgeFilesModal = () => (
    <Modal size='lg' isOpen={ props.showKnowledgeFilesId } >
      <ModalHeader toggle={ () => props.onChangeKnowledgesHOC( 'showKnowledgeFilesId', null ) } >
        { Lang[ 'KNOWLEDGE_FILES' ][ props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        <KnowledgeFileContent
          site={ props.site }
          lang={ props.data.languageReducer.lang }
          showKnowledgeFilesId={ props.showKnowledgeFilesId }
          handleSecurityModulesCreate={ handleSecurityModulesCreate() }
          handleSecurityModulesDelete={ handleSecurityModulesDelete() }
        />
      </ModalBody>
    </Modal>
  )

  return(
    <>
      <PageTitle
        heading={ Lang[ 'KNOWLEDGE' ][ props.data.languageReducer.lang ] }
        icon="pe-7s-users icon-gradient bg-happy-fisher"
        buttons={[
          {
            buttonText: Lang[ 'CREATE' ][ props.data.languageReducer.lang ],
            onClick: () => props.onChangeKnowledgesHOC( 'showCreateKnowledge', true ),
            display: handleSecurityModulesCreate()
          }
        ]}/>
      <Card className='main-card mb-3'>
        <CardHeader>{ Lang[ 'SEARCH' ][ props.data.languageReducer.lang ] }</CardHeader>
        <CardBody>
          <Form onSubmit={ e => e.preventDefault() }>
            <FormGroup>
              <Label>{ Lang[ 'CATEGORY' ][ props.data.languageReducer.lang ] }</Label>
              <ReactSelect
                isMulti
                options={ searchColumn[ 2 ].dropdownOptions }
                className='basic-multi-select'
                classNamePrefix='select'
                onChange={ value => updateSelectedCategory( value ) }
                value={ selectedCategory }
              />
            </FormGroup>
            <div className='d-flex justify-content-end'>
              <Button
                color='primary'
                onClick={ () => props.getKnowledges({ 
                  page: 1, 
                  is_paginated: true,
                  ...( 
                    selectedCategory.length > 0 && (
                      {
                        filter: {
                          $or: selectedCategory.map( category => ({ category_id: category.id } ))
                        }
                      }
                    )
                  )
                }) }>
                { Lang[ 'SEARCH' ][ props.data.languageReducer.lang ] }
              </Button>
              <Button
                color='danger ml-3'
                onClick={ () => {
                  updateSelectedCategory( [] )
                  props.getKnowledges({
                    page: 1,
                    is_paginated: true
                  })
                }}>{ Lang[ 'RESET' ][ props.data.languageReducer.lang ] }</Button>
            </div>
          </Form>
        </CardBody>
      </Card>
      <ARKTable
        showPagination={ false }
        data={ props.knowledges.data }
        totalPages={ props.knowledgesPages }
        page={ props.knowledges.meta ? props.knowledges.meta.current_page : 1 }
        // onSearch={ searchKnowledges }
        // searchLabel={ Lang[ 'TITLE' ][ props.data.languageReducer.lang ] }
        // searchComponent={ true }
        // searchColumn={ searchColumn }
        columns={[
          {
            Header: Lang[ 'TITLE' ][ props.data.languageReducer.lang ],
            accessor: "title",
            Cell: ({ value }) => value[ props.data.languageReducer.lang ] 
          },
          {
            Header: Lang[ 'CATEGORY' ][ props.data.languageReducer.lang ],
            accessor: "category_id",
            Cell: ({ value }) => value > 0 && props.categories.length > 0
              ? _.find( props.categories, { id: value } ).name[ props.data.languageReducer.lang ]
              : ''
          },
          {
            Header: Lang[ 'STATUS' ][ props.data.languageReducer.lang ],
            accessor: "status",
            Cell: ({ value }) => Lang[ value ][ props.data.languageReducer.lang ]
          },
          {
            Header: Lang[ 'ACTIONS' ][ props.data.languageReducer.lang ],
            accessor: "id",
            Cell: ({ value }) => (
              <div className='d-flex w-100 justify-content-center' >
                {
                  handleSecurityModulesUpdate() && (
                    <>
                      <Button
                        id={ `Knowledge-${ value }-update` }
                        className="mb-2 mr-2 btn-icon btn-icon-only"
                        color="primary"
                        onClick={ () => props.getSelectedKnowledge( value ) }>
                        <i className="pe-7s-note"> </i>
                      </Button>
                      <UncontrolledTooltip
                        target={ `Knowledge-${ value }-update` }
                        placement="top">{ Lang[ 'EDIT' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                    </>
                  )
                }
                <Button
                  id={ `Knowledge-${ value }-files` }
                  className="mb-2 mr-2 btn-icon btn-icon-only"
                  color="primary"
                  onClick={ () => {
                    props.onChangeKnowledgesHOC( 'showKnowledgeFilesId', value )
                  }}>
                  <i className="pe-7s-file"> </i>
                </Button>
                <UncontrolledTooltip
                  target={ `Knowledge-${ value }-files` }
                  placement="top">{ Lang[ 'KNOWLEDGE_FILES' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                {
                  handleSecurityModulesDelete() && (
                    <>
                      <Button
                        id={ `Knowledge-${ value }-archive-knowledge` }
                        className="mb-2 mr-2 btn-icon btn-icon-only"
                        color="danger"
                        onClick={ () => props.onChangeKnowledgesHOC( 'showDeleteKnowledgeId', parseInt( value ) ) }>
                        <i className="pe-7s-trash"> </i>
                      </Button>
                      <UncontrolledTooltip
                        target={ `Knowledge-${ value }-archive-knowledge` }
                        placement="top">{ Lang[ 'REMOVE' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                    </>
                  )
                }
              </div>
            )
          }
        ]}
        onChangePage={ pg => {
          // updateIsInjoyKnowledgesSearch( false )
          props.getKnowledges({ 
            page: pg, 
            is_paginated: true,
            ...( 
              selectedCategory.length > 0 && (
                {
                  filter: {
                    $or: selectedCategory.map( category => ({ category_id: category.id } ))
                  }
                }
              )
            )
          }) 
        }}
        // onClickReset={ () => {
        //     updateIsInjoyKnowledgesSearch( false )
        //     props.getKnowledges({ 
        //       page: 1,
        //       is_paginated: true
        //     }) 
        //   }
        // }
      />
      <DeleteConfirmation
        handleModalClose={ () => props.onChangeKnowledgesHOC( 'showDeleteKnowledgeId', 0 ) }
        deleteOpen={ props.showDeleteKnowledgeId > 0 }
        confirmAction={ () => props.removeKnowledge( props.showDeleteKnowledgeId ) }
        content={ Lang[ 'DELETE_CONFIRMATION_KNOWLEDGE' ][ props.data.languageReducer.lang ] }
        lang={ props.data.languageReducer.lang } />
      { renderCreateKnowledgeModal() }
      { renderUpdateKnowledgeModal() }
      { renderKnowledgeFilesModal() }
      { ( props.onLoadKnowledgesHOC || props.onLoadCenterGroups || props.onLoadCategoriesHOC ) && <LoadingOverlay/> }
    </>
  )
}

export default compose(
  KnowledgesHOC,
  ContentCategoriesHOC,
  CenterGroupsHOC
)( Knowledges )