import React, { Component } from 'react'
import { connect } from "react-redux"
import _ from 'lodash'

import StatusModal from "components/Modal/status"
import { Get, Put, Post } from 'utils/axios'
import { toggleMediaDatabase } from "actions/MediaDatabaseToggle"
import { convertObjToBase64 } from "utils/objToBase64"

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showStatusModal: false,
      statusModalType: "",
      onClickStatusModalButton: () => {},
      showCreateForm: false,
      showUpdateForm: false,
      newForm: {
        name: {
          en: '',
          de: '',
          it: '',
          nl: ''
        },
        content: [],
        values: [],
        status: 'ACTIVE',
        background_color: ''
      },
      selectedForm: {},
      selectedFormOriginal: {},
      forms: {
        data: [],
        meta: {}
      },
      showDuplicateModal: false
    }

    load = param => this.setState({ loading: param })
    requestError = error =>
      this.setState({
        showStatusModal: true,
        statusModalType: "negative",
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }),
        statusModalMessage: error,
      })
    requestSuccess = success =>
      this.setState({
        showStatusModal: true,
        statusModalType: "positive",
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }),
        statusModalMessage: success,
      })

    onChangeFormsHOC = ( key, val ) => this.setState({ [ key ]: val })

    getForms = ( query ) => Get(
      `/api/v1/webform_templates?query=${ convertObjToBase64(
        query
      )}`,
      this.getFormsSuccess,
      this.getFormsError,
      this.load
    )
    getFormsSuccess = payload => this.setState({ forms: payload })
    getFormsError = error => this.requestError( error )

    getSelectedForm = ( id, isUpdate ) => Get(
      `/api/v1/webform_templates/${ id }`,
      payload => this.getSelectedFormSuccess( payload, isUpdate ),
      this.getSelectedFormError,
      this.load
    )
    getSelectedFormSuccess = ( payload, isUpdate ) => {
      let tmpSelectedForm = _.cloneDeep( payload )
      tmpSelectedForm.content = typeof tmpSelectedForm.content === 'string'
        ? JSON.parse( tmpSelectedForm.content )
        : tmpSelectedForm.content
      tmpSelectedForm.values = typeof tmpSelectedForm.values === 'string'
        ? JSON.parse( tmpSelectedForm.values )
        : tmpSelectedForm.values
      this.setState({ selectedFormOriginal: payload, selectedForm: tmpSelectedForm, showUpdateForm: isUpdate, showDuplicateModal: !isUpdate })
    }
    getSelectedFormError = error => this.requestError( error )

    createForm = data => Post(
      `/api/v1/webform_templates`,
      data,
      this.createFormSuccess,
      this.createFormError,
      this.load
    )
    createFormSuccess = () => {
      this.requestSuccess( 'Form is created successfully.' )
      this.setState({
        showCreateForm: false,
        newForm: {
          name: '',
          content: [],
          values: [],
          status: 'ACTIVE'
        }
      })
      let tmp = { page: 1, is_paginated: true };
      this.getForms( tmp )
    }
    createFormError = error => this.requestError( error )

    updateForm = data => Put(
      `/api/v1/webform_templates/${ data.id }`,
      data,
      this.updateFormSuccess,
      this.updateFormError,
      this.load
    )
    updateFormSuccess = payload => {
      this.requestSuccess( 'Form is updated successfully.' )
      this.setState({ showUpdateForm: false })
      let tmp = { page: 1, is_paginated: true };
      this.getForms( tmp )
    }
    updateFormError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            onChangeFormsHOC={ this.onChangeFormsHOC }
            getForms={ this.getForms }
            getSelectedForm={ this.getSelectedForm }
            createForm={ this.createForm }
            updateForm={ this.updateForm }
            onLoadForms={ this.state.loading }
            newForm={ this.state.newForm }
            showCreateForm={ this.state.showCreateForm }
            showUpdateForm={ this.state.showUpdateForm }
            selectedForm={ this.state.selectedForm }
            forms={ this.state.forms }
            showDuplicateModal={ this.state.showDuplicateModal}
            selectedFormOriginal={ this.state.selectedFormOriginal } />
          <StatusModal
            isOpen={ this.state.showStatusModal }
            type={ this.state.statusModalType }
            message={ this.state.statusModalMessage }
            onClick={ this.state.onClickStatusModalButton }
          />
        </>
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, { toggleMediaDatabase })( WithHOC )
}

export default HOC
