import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {
  Form, FormGroup, Input, Label, Col, FormFeedback, Card, CardBody, Container
} from 'reactstrap'
import _ from 'lodash'

import Lang from 'Lang/General'

const ModuleForm = ({
  data,
  onChangeModuleHOC,
  errorMessage,
  types,
  mode,
  dataReducer
}) => {
  const onChange = ( key, val ) => {
    let tmp = _.cloneDeep( data )
    let dataKey = ''
    tmp[ key ] = val
    if( mode === 'edit' ){
      dataKey = 'selectedModule'
    }else{
      dataKey = 'newModuleData'
    }
    return onChangeModuleHOC( dataKey, tmp )
  }
  const {
    name,
    type,
    description
  } = data
  return(
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}>
        <Container fluid>
          <Card className="main-card mb-3">
            <CardBody>
              <Form>
                <FormGroup row>
                  <Label for="name" sm={ 2 }>{ Lang[ 'MODULE_NAME' ][ dataReducer.languageReducer.lang ] }</Label>
                  <Col sm={ 10 }>
                    <Input
                      className="test-module-modalcreatemodule-inputname"
                      name="name" id="name"
                      placeholder={ Lang[ 'ENTER_MODULE_NAME' ][ dataReducer.languageReducer.lang ] }
                      value={ name }
                      onChange={ e => onChange( 'name', e.target.value ) }
                      invalid={ errorMessage && errorMessage.name ? true : false }/>
                    <FormFeedback>{ errorMessage && errorMessage.name ? errorMessage.name : '' }</FormFeedback>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="salutation" sm={ 2 }>{ Lang[ 'TYPE' ][ dataReducer.languageReducer.lang ] }</Label>
                  <Col sm={ 10 }>
                    <Input
                      type="select"
                      className="test-module-modalcreatemodule-selecttype"
                      name="type"
                      id="type"
                      error={ errorMessage && errorMessage.type ? true : false }
                      value={ type }
                      onChange={ e => onChange( 'type', e.target.value ) }>
                      {
                        types && types.map( item => {
                          return(
                            <option value={ item.name } key={ item.id }>{ item.name }</option>
                          )
                        })
                      }
                    </Input>
                    <FormFeedback>{ errorMessage && errorMessage.type ? errorMessage.type : '' }</FormFeedback>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="name" sm={ 2 }>{ Lang[ 'DESCRIPTION' ][ dataReducer.languageReducer.lang ] }</Label>
                  <Col sm={ 10 }>
                    <Input
                      name="description" id="description"
                      className="test-module-modalcreatemodule-inputdesc"
                      placeholder={ Lang[ 'ENTER_DESCRIPTION' ][ dataReducer.languageReducer.lang ] }
                      value={ description }
                      onChange={ e => onChange( 'description', e.target.value ) }/>
                  </Col>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}
const mapStateToProps = state => ({ dataReducer: state })

export default connect( mapStateToProps )( ModuleForm )
