import React, { useState, useEffect } from 'react'
import { FormGroup, Label } from 'reactstrap'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import _ from 'lodash'
import ReactHTMLParser from 'react-html-parser'
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
import "filepond/dist/filepond.min.css"


registerPlugin( FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginFileValidateType )

const InputFile = ({
  content,
  formValues,
  onPatchFormValues,
  mode,
  createSubmittedFormMedia,
  currentLanguage
}) => {
  const [ labelStr, setLabelStr ] = useState("")

  useEffect(() => {
    let labelText = content.label[ currentLanguage ? currentLanguage : 'en' ]

    if (labelText.length !== 0) {
      if ( typeof( ReactHTMLParser( labelText )[0] ) === 'object' ) {
        let labelTag = ReactHTMLParser( labelText )[0].type
        let closingTag = "</" + labelTag + ">"
        labelText = labelText.slice( 0, -1 * closingTag.length )
        if ( formValues[ content.dataMapIndex ][ 'isRequired' ] ){
          labelText = labelText + "<span style='color: #ff0000;'> * </span>"
        }
        labelText = labelText + closingTag
      }
      else {
        labelText = labelText + "<span style='color: #ff0000;'> * </span>"
      }
    }
    setLabelStr( labelText )
  }, [ content, formValues, currentLanguage ])

  return (
    <FormGroup 
      tag="fieldset"
      id={ content.id } 
      style={{ padding: content.padding, marginBottom: 0 }}>
      <Label style={{ fontSize: content.fontSize }}>{ ReactHTMLParser( labelStr ) }</Label>
      <FilePond
        allowMultiple={ false }
        allowFileTypeValidation={ true }
        allowImagePreview={ true }
        acceptedFileTypes={[ 'application/pdf', 'image/png', 'image/jpeg', 'image/jpg' ]}
        allowFileSizeValidation={ true }
        maxFileSize={ '5MB' }
        onaddfile={ ( error, file ) => {
          if( mode !== 'edit' ){
            const reader = new FileReader()
            reader.onload = e => {
              let tmpMedia = {
                raw: e.target.result
              }
              createSubmittedFormMedia( content.id, tmpMedia )
            }
            reader.readAsDataURL( file.file )
          } 
        }}
        onremovefile={ e => {
          if( mode !== 'edit' ){
            onPatchFormValues( content.dataMapIndex, 'value', '' ) 
          }
        }}/>
    </FormGroup>
  )
}

export default InputFile