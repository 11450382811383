import React, { Component } from 'react'
import { Dialog, Button, Grid, Tooltip } from '@material-ui/core'
import { IoIosClose, IoMdCheckmark } from 'react-icons/io'
import { MdClose } from 'react-icons/md'

import TemplateEditorCard from '../components/card'
import LoadingModal from 'components/Indicator/LoadingOverlay'

import './index.scss'

import Lang from 'Lang/General'

class TemplateJSDialog extends Component {
  render = () => {
    return (
      <Dialog
        open={ this.props.showTemplateJSDialog }
        maxWidth={ 'lg' }
        fullWidth={ true }>
        <TemplateEditorCard>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h4>{ Lang[ 'ADD_JAVASCRIPT' ][ this.props.lang ] }</h4>
            <Button
              classes={{
                root: "button-close",
                label: "button-text" }}
              onClick={ () => this.props.updateMainContainerState( 'showTemplateJSDialog', false ) }>
              <MdClose style={{ fontSize: '20px' }}/>
            </Button>
          </div>
          <hr />
          <div className="container" >
            <Grid container>
            {
                this.props.templateJavascripts && this.props.templateJavascripts.length > 0 && this.props.templateJavascripts.map(( item, index ) => {
                  return (
                    <Grid
                      key={ item.id }
                      item
                      md={ 12 }
                      style={{
                        paddingBottom: '15px',
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }}>
                      {
                        item.type === 'URL' && (
                          <input
                            className="form-control"
                            style={{ width: '600px', display: 'inline-block', marginRight: '15px' }}
                            value={ item.content }
                            onChange={ e => this.props.onChangeTemplateJS( 'content', index, e.target.value ) } />
                        )
                      }
                      {
                        item.type === 'codes' && (
                          <textarea
                            className="form-control"
                            style={{ width: '600px', display: 'inline-block', marginRight: '15px' }}
                            row={ 10 }
                            value={ item.content }
                            onChange={ e => this.props.onChangeTemplateJS( 'content', index, e.target.value ) } />
                        )
                      }
                      <select
                        value={ item.type }
                        className="form-control"
                        style={{ width: '300px', display: 'inline-block', marginRight: '15px' }}
                        onChange={ e => this.props.onChangeTemplateJS( 'type', index, e.target.value ) }>
                        <option value="URL">{ 'URL' }</option>
                        <option value="codes">{ 'codes' }</option>
                      </select>
                      <Tooltip title={ Lang[ 'UPDATE_JS' ][ this.props.lang ] }>
                        <Button
                          classes={{ root: 'btn btn-primary' }}
                          variant={ 'contained' }
                          onClick={() => this.props.updateTemplateJS( this.props.template_id, item.id, {
                            content: item.content,
                            type: item.type
                          })}>
                          <IoMdCheckmark className="icon" />
                        </Button>
                      </Tooltip>
                      <Tooltip title={ Lang[ 'REMOVE_JS' ][ this.props.lang ] }>
                        <Button
                          classes={{ root: 'btn btn-danger' }}
                          variant={ 'contained' }
                          onClick={() => this.props.removeTemplateJS( this.props.template_id, item.id )}>
                          <IoIosClose className="icon" />
                        </Button>
                      </Tooltip>
                    </Grid>
                  )
                })
              }
              <Grid item md={ 12 }>
                <Button
                  style={{ marginRight: '10px', backgroundColor: '#eee', width: '100%' }}
                  onClick={() => this.props.createTemplateJS( this.props.template_id )}>
                  { Lang[ 'CREATE_JAVASCRIPT' ][ this.props.lang ] }
                </Button>
              </Grid>
            </Grid>
          </div>
          <hr />
          <div className="section-picker-footer">
            <Button
              classes={{ root: 'btn-secondary' }}
              onClick={() => this.props.updateMainContainerState( 'showTemplateJSDialog', false )}>
              { Lang[ 'CLOSE' ][ this.props.lang ] }
            </Button>
          </div>
          {
            this.props.onLoadJSUpdate && <LoadingModal />
          }
        </TemplateEditorCard>
      </Dialog>
    )
  }
}

export default TemplateJSDialog
