import React, { Component } from 'react'
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button, Table, Card, CardBody, CardHeader,
  Form, Input, FormGroup, Label, UncontrolledTooltip
} from 'reactstrap'
import { compose } from 'redux'
import Image from 'react-bootstrap/Image'
import Select from 'react-select'
import moment from 'moment'
import uniqid from 'uniqid'
import _ from 'lodash'

import DeleteConfirmationDialog from 'components/Modal/delete'
import ContentForm from './components/contentForm'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PageTitle from 'components/PageTitle'
import DomEditor from 'components/DomEditor'
import ARKTable from 'components/Table'
import ArchivedModal from './Archived'

import WithContentServices from './actions'
import WithArchivedContentServices from './actions/Archived'
import WithCategories from './actions/category'
import WithCenterGroup from './actions/centerGroups'
import { convertObjToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

class AllContent extends Component {
  state = {
    categoriesList: [],
    centerGroupsList: [],
    showContentDom: false
  }

  componentDidMount = () => {
    this.props.getAllContentService( convertObjToBase64({
      page: this.props.page,
      is_paginated: true,
      filter: {
        $or: [
          { status: 'INACTIVE' },
          { status: 'ACTIVE' }
        ]
      }
    }) )
    this.props.getAllCategory( convertObjToBase64({ page: this.props.page, is_paginated: false }) )
    this.props.getCenterGroups( convertObjToBase64({ is_paginated: false, filter: { status: 'ACTIVE' } }) )
  }

  componentDidUpdate = pp => {
    if( this.props.categories.data !== pp.categories.data ){
      let tmp = _.cloneDeep( this.state.categoriesList )
      this.props.categories.data && this.props.categories.data.map( item => {
        tmp.push({
          id: item.id,
          label: item.name[ this.props.data.languageReducer.lang ],
          value: item.name[ this.props.data.languageReducer.lang ]
        })
        this.setState({ categoriesList: tmp })
      })
    }
    if( this.props.centerGroups.data !== pp.centerGroups.data ){
      let tmp = _.cloneDeep( this.state.centerGroupsList )
      this.props.centerGroups.data && this.props.centerGroups.data.map( item => {
        tmp.push({
          id: item.id,
          label: item.name,
          value: item.name
        })
        this.setState({ centerGroupsList: tmp })
      })
    }
    if( pp.contentModalOpen && !this.props.contentModalOpen ) {
      this.props.onChangeContentData(
        'newContentData',
        {
          categories: [],
          title: {
            en: '',
            de: '',
            it: '',
            nl: ''
          },
          description: {
            en: '',
            de: '',
            it: '',
            nl: ''
          },
          mini_text: {
            en: '',
            de: '',
            it: '',
            nl: ''
          },
          teaser_text: {
            en: '',
            de: '',
            it: '',
            nl: ''
          },
          content: {
            id: uniqid(),
            type: 'div',
            className: 'container',
            children: [
              {
                id: uniqid(),
                type: 'div',
                className: 'row',
                style: { position: 'relative' },
                children: [
                  {
                    id: uniqid(),
                    type: "div",
                    className: 'col-sm-12',
                    col: 12,
                    children: [
                      {
                        id: uniqid(),
                        type: 'div',
                        variant: 'text',
                        content: {
                          en: "Please double click to edit content",
                          de: "Bitte doppelklicken Sie, um den Inhalt zu bearbeiten",
                          it: "Si prega di fare doppio clic per modificare il contenuto",
                          nl: "Dubbelklik om de inhoud te bewerken"
                        }
                      }
                    ],
                    style: { 
                      minHeight: '100px', 
                      position: 'relative', 
                      padding: '0px 0px 0px 0px',
                      top: 0,
                      left: 0,
                      backgroundColor: ''
                    },
                    minHeight: 100,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    top: 0,
                    left: 0,
                    isContentCenter: false,
                    isColumnContainer: true,
                    isFloat: false
                  }
                ]
              }
            ],
            own_created_section: true
          },
          start_at: moment().format(),
          status: "ACTIVE",
          website_preview_img_url: '',
          website_detailed_img_url: '',
          newletter_img_url: '',
          fb_teaser_img_url: '',
          center_groups: []
        }
      )
    }
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Content Service' })
      return tmpModules.is_create
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Content Service' })
      return tmpModules.is_update
    }
  }

  handleSecurityModulesDelete = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Content Service' })
      return tmpModules.is_delete
    }
  }

  renderContentServiceListing = () => {
    return(
      <ARKTable
        data={ this.props.contents.data }
        columns={[
          {
            Header: Lang[ 'TITLE' ][ this.props.data.languageReducer.lang ],
            accessor: 'title',
            width: 200,
            Cell: ({ value }) => value[ this.props.data.languageReducer.lang ]
          },
          {
            Header: Lang[ 'PUBLISHED_FROM' ][ this.props.data.languageReducer.lang ],
            accessor: 'start_at'
          },
          {
            Header: Lang[ 'CATEGORIES' ][ this.props.data.languageReducer.lang ],
            accessor: 'categories',
            Cell: ({value}) => (
              <ul>
              {
                value && value.map( item => (
                  <li key={ item }>{ item.name[ this.props.data.languageReducer.lang ] }</li>
                ))
              }
              </ul>
            )
          },
          {
            Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
            accessor: "id",
            Cell: ({value}) => (
              <div>
                <Button
                  id={ `Content-${ value }-view-content` }
                  className="mb-2 mr-2 btn-icon btn-icon-only"
                  color="primary"
                  onClick={ () => {
                    this.props.getSelectedContent( value )
                    this.props.handleShowContentInfoTable( true ) }}>
                    <i className="pe-7s-look"> </i>
                </Button>
                <UncontrolledTooltip
                  target={ `Content-${ value }-view-content` }
                  placement="top">{ Lang[ 'VIEW_CONTENT' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                {
                  this.handleSecurityModulesUpdate() && (
                    <>
                      <Button
                        id={ `Content-${ value }-update-content` }
                        className="mb-2 mr-2 btn-icon btn-icon-only"
                        color="primary"
                        onClick={ () => {
                          this.props.getSelectedContent( value )
                          this.props.handleContentModal( true, 'edit' )
                        }}>
                        <i className="pe-7s-note"> </i>
                      </Button>
                      <UncontrolledTooltip
                        target={ `Content-${ value }-update-content` }
                        placement="top">{ Lang[ 'UPDATE_CONTENT' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                    </>
                  )
                }
                {
                  this.handleSecurityModulesDelete() && (
                    <>
                      <Button
                        id={ `Content-${ value }-archive-content` }
                        className="mb-2 mr-2 btn-icon btn-icon-only"
                        color="warning"
                        onClick={ () => {
                          this.props.getSelectedContent( value )
                          this.props.onChangeContentData( 'deleteContentModal', true )
                        }}>
                          <i className="pe-7s-notebook"> </i>
                      </Button>
                      <UncontrolledTooltip
                        target={ `Content-${ value }-archive-content` }
                        placement="top">{ Lang[ 'ARCHIVE_CONTENT' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                    </>
                  )
                }
              </div>
            )
          }
        ]}
        page={ this.props.contents.meta ? this.props.contents.meta.current_page : 1 }
        totalPages={ this.props.contentTotalPages }
        onChangePage={ pg => {
          let tmp = {
            is_paginated: true,
            page: pg,
            filter: {
              title: {
                $like: `%${ this.props.searchParams[ 0 ].param }%`
              },
              status: {
                $neq: 'ARCHIVED'
              },
              ...( 
                this.props.searchParams[ 1 ].param && this.props.searchParams[ 1 ].param.length > 0 && (
                  {
                    $or: this.props.searchParams[ 1 ].param.map( category => ( { content_category_id: category.id } ))
                  }
                )
              )
            }
          }
          this.props.getAllContentService( convertObjToBase64( tmp ) )
        }}
        showPagination={ false } />
    )
  }
  renderNewContentForm = () => {
    return(
      <Modal
        isOpen={ this.props.contentModalOpen }
        size="xl">
        <ModalHeader toggle={ () => this.props.handleContentModal( false, '' ) }>
          { Lang[ 'NEW_CONTENT_SERVICE' ][ this.props.data.languageReducer.lang ] }
        </ModalHeader>
        <ModalBody>
          <ContentForm
            data={ this.props.newContentData }
            onChangeContentData={ this.props.onChangeContentData }
            contentMode={ this.props.contentMode }
            handleContentModal={ this.props.handleContentModal }
            languages={ this.props.data.dictionaryReducer.dictionary.languages }
            categoriesList={ this.state.categoriesList }
            onUpdateFiles={ this.props.onUpdateFiles }
            files={ this.props.files }
            contentErrorMessage={ this.props.contentError }
            imageKey={ this.props.imageKey }
            centerGroupsList={ this.state.centerGroupsList }/>
          <Button 
            className="mb-2 ml-3"
            color="info" 
            onClick={ () => {
              let tmp = _.cloneDeep( this.props.newContentData )
              tmp.categories.length === 0 && delete tmp.categories
              tmp.center_groups.length === 0 && delete tmp.center_groups
              this.props.postContent( tmp )
            }}>
            { Lang[ 'CREATE' ][ this.props.data.languageReducer.lang ] }
          </Button>
          { this.props.onLoadContent && <LoadingOverlay /> }
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.handleContentModal( false, '' ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderEditContentForm = () => {
    return(
      <Modal
        isOpen={ this.props.contentModalOpen }
        size="xl">
        <ModalHeader toggle={ () => this.props.handleContentModal( false, '' ) }>
          { Lang[ 'EDIT_CONTENT_SERVICE' ][ this.props.data.languageReducer.lang ] }
        </ModalHeader>
        <ModalBody>
          {
            !_.isEmpty( this.props.selectedContent ) && (
              <>
                <ContentForm
                  data={ this.props.selectedContent }
                  onChangeContentData={ this.props.onChangeContentData }
                  contentMode={ this.props.contentMode }
                  handleContentModal={ this.props.handleContentModal }
                  languages={ this.props.data.dictionaryReducer.dictionary.languages }
                  categoriesList={ this.state.categoriesList }
                  onUpdateFiles={ this.props.onUpdateFiles }
                  files={ this.props.files }
                  imageKey={ this.props.imageKey }
                  centerGroupsList={ this.state.centerGroupsList }
                  contentErrorMessage={ this.props.contentError }/>
                <Button
                  className="mb-2 ml-3"
                  color="info"
                  onClick={ () => {
                    let tmp = _.cloneDeep( this.props.selectedContent )
                    tmp.categories.length === 0 && delete tmp.categories
                    tmp.center_groups.length === 0 && delete tmp.center_groups
                    this.props.updateContent( tmp )
                  }}>
                  { Lang[ 'SAVE' ][ this.props.data.languageReducer.lang ] }</Button>
                { this.props.onLoadContent && <LoadingOverlay /> }
              </>
            )
          }
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.handleContentModal( false, '' ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderContentDetails = () => {
    return(
      <Modal isOpen={ this.props.infoModal } size="xl">
        <ModalHeader toggle={ () => this.props.handleShowContentInfoTable( false ) }>
          { Lang[ 'CONTENT_DETAILS' ][ this.props.data.languageReducer.lang ] }
        </ModalHeader>
        <ModalBody>
          {
            !_.isEmpty( this.props.selectedContent ) && (
              <>
                <div className="scroll-area-xl">
                  <Table className="mb-0" bordered style={{ tableLayout: 'fixed' }}>
                    <tbody>
                      <tr>
                        <th scope="row">{ Lang[ 'TITLE' ][ this.props.data.languageReducer.lang ] }</th>
                        <td>{ this.props.selectedContent.title[ this.props.data.languageReducer.lang ] }</td>
                      </tr>
                      <tr>
                        <th scope="row">{ Lang[ 'DESCRIPTION' ][ this.props.data.languageReducer.lang ] }</th>
                        <td>{ this.props.selectedContent.description[ this.props.data.languageReducer.lang ] }</td>
                      </tr>
                      <tr>
                        <th scope="row">{ Lang[ 'MINI_TEXT' ][ this.props.data.languageReducer.lang ] }</th>
                        <td>{ this.props.selectedContent.mini_text[ this.props.data.languageReducer.lang ] }</td>
                      </tr>
                      <tr>
                        <th scope="row">{ Lang[ 'TEASER_TEXT' ][ this.props.data.languageReducer.lang ] }</th>
                        <td>{ this.props.selectedContent.teaser_text[ this.props.data.languageReducer.lang ] }</td>
                      </tr>
                      <tr>
                        <th scope="row">{ Lang[ 'CONTENT' ][ this.props.data.languageReducer.lang ] }</th>
                        <td>
                          <Button
                            color='primary'
                            onClick={ () => this.setState({ showContentDom: true }) }>
                            { Lang[ 'VIEW_CONTENT' ][ this.props.data.languageReducer.lang ] }
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">{ Lang[ 'CATEGORIES' ][ this.props.data.languageReducer.lang ] }</th>
                        <td>
                          <ul>
                          {
                            this.props.selectedContent.categories.length > 0 && this.props.selectedContent.categories.map( item => (
                              <li>{ item.label }</li>
                            ))
                          }
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">{ Lang[ 'CENTER_GROUP' ][ this.props.data.languageReducer.lang ] }</th>
                        <td>
                          <ul>
                          {
                            this.props.selectedContent.center_groups.length > 0 && this.props.selectedContent.center_groups.map( item => (
                              <li>{ item.name }</li>
                            ))
                          }
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">{ Lang[ 'PUBLISHED_FROM' ][ this.props.data.languageReducer.lang ] }</th>
                        <td>{ moment( this.props.selectedContent.start_at ).format( 'DD.MM.YYYY hh:mm a' ) }</td>
                      </tr>
                      <tr>
                        <th scope="row">{ Lang[ 'WEBSITE_PREVIEW_IMG' ][ this.props.data.languageReducer.lang ] }</th>
                        <td>
                        {
                          this.props.selectedContent.website_preview_img_url !== '' ? <Image src={ this.props.selectedContent.website_preview_img_url } style={{ maxWidth: '100%', height: 'auto' }} /> : 'No data'
                        }
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">{ Lang[ 'WEBSITE_DETAILED_IMG' ][ this.props.data.languageReducer.lang ] }</th>
                        <td>
                        {
                          this.props.selectedContent.website_detailed_img_url !== '' ? <Image src={ this.props.selectedContent.website_detailed_img_url } style={{ maxWidth: '100%', height: 'auto' }} /> : 'No data'
                        }
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">{ Lang[ 'FACEBOOK_TEASER_IMG' ][ this.props.data.languageReducer.lang ] }</th>
                        <td>
                          {
                            this.props.selectedContent.fb_teaser_img_url !== '' ? <Image src={ this.props.selectedContent.fb_teaser_img_url } style={{ maxWidth: '100%', height: 'auto' }} /> : 'No data'
                          }
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">{ Lang[ 'NEWSLETTER_IMG' ][ this.props.data.languageReducer.lang ] }</th>
                        <td>
                          {
                            this.props.selectedContent.newletter_img_url !== '' ? <Image src={ this.props.selectedContent.newletter_img_url } style={{ maxWidth: '100%', height: 'auto' }} /> : 'No data'
                          }
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">{ Lang[ 'ACTIVE_STATUS' ][ this.props.data.languageReducer.lang ] }</th>
                        <td>{ this.props.selectedContent.status }</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <DomEditor 
                  isViewOnly
                  selectedLanguage={ this.props.data.languageReducer.lang }
                  showDomEditor={ this.state.showContentDom }
                  updateShowDomEditor={ e => this.setState({ showContentDom: e }) }
                  parent={ this.props.selectedContent }
                  updateParent={ () => {} } 
                  childrenKey='content' 
                /> 
              </>
            )
          }
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={ () => this.props.handleShowContentInfoTable( false ) }>OK</Button>
          <Button color="alternate" onClick={ () => {
            this.props.getSelectedContent( this.props.selectedContent.id )
            this.props.handleContentModal( true, 'edit' )
            this.props.handleShowContentInfoTable( false )
          }}>{ Lang[ 'EDIT' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  render = () => {
    return(
      <>
        <PageTitle
          heading={ Lang[ 'NEWS' ][ this.props.data.languageReducer.lang ] }
          icon="pe-7s-note icon-gradient bg-happy-fisher"
          buttons={[
            {
              buttonText: Lang[ 'VIEW_ARCHIVED_CONTENT_SERVICE' ][ this.props.data.languageReducer.lang ],
              onClick: () => {
                this.props.getArchivedContents({
                  page: 1,
                  is_paginated: true,
                  filter: {
                    status: 'ARCHIVED' 
                  }
                })
              },
              display: true
            },
            {
              buttonText: Lang[ 'NEW_CONTENT_SERVICE' ][ this.props.data.languageReducer.lang ],
              onClick: () => this.props.handleContentModal( true, 'create' ),
              display: this.handleSecurityModulesCreate()
            }
          ]}/>
          <Card className="main-card mb-3">
            <CardHeader>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }</CardHeader>
            <CardBody>
              <Form onSubmit={ e => e.preventDefault()}>
                {
                  this.props.searchParams.map(( item, index ) => {
                    if( item.value === 'title' ) {
                      return (
                        <FormGroup key={ item.label }>
                          <Label>{ Lang[ 'TITLE' ][ this.props.data.languageReducer.lang ] }</Label>
                          <Input
                            type={ 'text' }
                            value={ item.param }
                            onChange={ e => {
                              let tmp = _.cloneDeep( this.props.searchParams )
                              tmp[ index ][ 'param' ] = e.target.value
                              return this.props.onChangeContentData( 'searchParams', tmp )
                            }} />
                        </FormGroup>
                      )
                    }
                  })
                }
                <FormGroup>
                  <Label>{ Lang[ 'CATEGORIES' ][ this.props.data.languageReducer.lang ] }</Label>
                  <Select
                    isMulti
                    options={ this.state.categoriesList }
                    className="basic-multi-select"
                    value={ this.props.searchParams[ 1 ].param }
                    placeholder={ Lang[ 'SELECT' ][ this.props.data.languageReducer.lang ] }
                    onChange={ val => {
                      let tmp = _.cloneDeep( this.props.searchParams )
                      tmp[ 1 ][ 'param' ] = val
                      return this.props.onChangeContentData( 'searchParams', tmp )
                    }} />
                </FormGroup>
                <div className="d-flex">
                  <Button
                    color="primary"
                    style={{ marginLeft: 'auto' }}
                    onClick={() => {
                      let tmpSearchParams = {
                        is_paginated: true,
                        page: 1,
                        filter: {
                          title: {
                            $like: `%${ this.props.searchParams[ 0 ].param }%`
                          },
                          status: {
                            $neq: 'ARCHIVED'
                          },
                          ...( 
                            this.props.searchParams[ 1 ].param && this.props.searchParams[ 1 ].param.length > 0 && (
                              {
                                $or: this.props.searchParams[ 1 ].param.map( category => ( { content_category_id: category.id } ))
                              }
                            )
                          )
                        }
                      }
                      let tmpSearchString = convertObjToBase64( tmpSearchParams )
                      this.props.getAllContentService( tmpSearchString )
                    }}>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }</Button>
                  <Button
                    color="danger"
                    style={{ marginLeft: '10px' }}
                    onClick={() => {
                      let tmpSearchParams = {
                        is_paginated: true,
                        page: 1,
                        filter: {
                          status: {
                            $neq: 'ARCHIVED'
                          }
                        }
                      }
                      let tmpSearchString = convertObjToBase64( tmpSearchParams )
                      this.props.onChangeContentData( 'searchParams', [
                        {
                          label: 'Title',
                          value: 'title',
                          param: ''
                        },
                        {
                          label: 'Categories',
                          value: 'categories',
                          param: []
                        }
                      ])
                      this.props.getAllContentService( tmpSearchString )
                    }}>{ Lang[ 'RESET' ][ this.props.data.languageReducer.lang ] }</Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          { this.renderContentServiceListing() }
          {
            this.props.deleteContentModal && (
              <DeleteConfirmationDialog
                deleteOpen={ this.props.deleteContentModal }
                handleModalClose={ () => this.props.onChangeContentData( 'deleteContentModal', false ) }
                confirmAction={ () => {
                  if( this.props.selectedContent.id ){
                    this.props.updateContent({
                      ...this.props.selectedContent,
                      status: 'ARCHIVED'
                    })
                  }
                  this.props.onChangeContentData( 'deleteContentModal', false )
                }}
                content={ Lang[ 'ARCHIVE_CONFIRMATION' ][ this.props.data.languageReducer.lang ] }
                lang={ this.props.data.languageReducer.lang }/>
            )
          }
          <ArchivedModal { ...this.props } handleSecurityModulesUpdate={ this.handleSecurityModulesUpdate } />
          { this.props.contentMode === 'create' && this.renderNewContentForm() }
          { this.props.contentMode === 'edit' && this.renderEditContentForm() }
          { this.renderContentDetails() }
          { ( this.props.data.ajaxReducer.ajaxCallProgress > 0 || this.props.onLoadContent || this.props.onLoadArchivedContents ) && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose(
  WithContentServices,
  WithArchivedContentServices,
  WithCategories,
  WithCenterGroup
) ( AllContent )
