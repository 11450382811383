import React from 'react'
import { connect } from 'react-redux'
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap'

import LanguageInput from './languageInput'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Lang from 'Lang/General'

const EditImages = ({
  mode,
  onClose,
  selectedImages,
  onChangeData,
  updateMedia,
  mediaTags,
  data,
  onLoadMedia
}) => {
  return(
    <Modal
      isOpen={ mode === 'edit' }
      size="xl">
      <ModalHeader>{ Lang[ 'EDIT_IMAGES' ][ data.languageReducer.lang ] }</ModalHeader>
      <ModalBody>
      {
          selectedImages && selectedImages.map( item => {
            return(
              <LanguageInput
                key={ item.id }
                item= { item }
                onChangeData={ onChangeData }
                updateMedia={ updateMedia }
                mediaTags={ mediaTags }
                selectedImages={ selectedImages } />
            )
          })
        }
        { onLoadMedia && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button className="mb-2 mr-2" color="primary" onClick={ onClose }>{ Lang[ 'CANCEL' ][ data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )
}
const mapStateToProps = state => ({ data: state })
export default connect( mapStateToProps )( EditImages )
