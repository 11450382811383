import React, { Component } from 'react'
import { Dialog, Button, Grid, Tooltip, Chip } from '@material-ui/core'
import { MdClose } from 'react-icons/md'

import TemplateEditorCard from '../components/card'
import LoadingModal from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'

class LanguageDialog extends Component {
  state = {
    languages: [
      {
        name: 'English',
        shortname: 'en'      
      },
      {
        name: 'German',
        shortname: 'de'      
      },
      {
        name: 'Italian',
        shortname: 'it'      
      },
      {
        name: 'Dutch',
        shortname: 'nl'      
      }
    ]
  }
  render = () => {
    return (
      <Dialog
        open={ this.props.showLanguageDialog }
        maxWidth={ 'lg' }
        fullWidth={ true }>
        <TemplateEditorCard>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h4>{ Lang[ 'TEMPLATE_LANGUAGE_EDITOR' ][ this.props.lang ] }</h4>
            <Button
              classes={{
                root: "button-close",
                label: "button-text" }}
              onClick={ () => this.props.updateMainContainerState( 'showLanguageDialog', false ) }>
              <MdClose style={{ fontSize: '20px' }}/>
            </Button>
          </div>
          <hr />
          <div className="section-container">
            <Grid container>
              <Grid item md={ 12 }>
                {
                  this.state.languages && this.state.languages.map( ( item ) => {
                    return (
                      <Grid key={ item.id } item md={ 12 } style={{ paddingBottom: '15px' }}>
                        <input
                          className="form-control"
                          style={{ width: '300px', display: 'inline-block', marginRight: '15px' }}
                          value={ item.name }
                          placeholder={ Lang[ 'INPUT_LONG_NAME' ][ this.props.lang ] }
                          disabled />
                        <input
                          className="form-control"
                          style={{ width: '300px', display: 'inline-block', marginRight: '15px' }}
                          value={ item.shortname }
                          placeholder={ Lang[ 'INPUT_SHORT_NAME' ][ this.props.lang ] }
                          disabled />
                        {
                          this.props.selectedLanguageShortname === item.shortname
                            ? ( <Chip color="primary" label={ Lang[ 'SELECTED_LANGUAGE' ][ this.props.lang ] } /> )
                            : (
                              <Tooltip title={ Lang[ 'TOOLTIP_TITLE' ][ this.props.lang ] }>
                                <Button
                                  classes={{ root: 'btn btn-info' }}
                                  variant={ 'contained' }
                                  onClick={ () => this.props.updateMainContainerState( 'selectedLanguageShortname', item.shortname ) }>
                                  { Lang[ 'SELECT_LANGUAGE_TEMPLATE_EDITOR' ][ this.props.lang ] }
                                </Button>
                              </Tooltip>
                            )
                        }
                      </Grid>
                    )
                  })
                }
              </Grid>
            </Grid>
          </div>
          <hr />
          <div className="section-footer">
            <Button
              classes={{ root: 'btn-secondary' }}
              onClick={ () => this.props.updateMainContainerState( 'showLanguageDialog', false ) }>
              { Lang[ 'CLOSE' ][ this.props.lang ] }
            </Button>
          </div>
          {
            this.props.onLoadLanguagesUpdate && <LoadingModal />
          }
        </TemplateEditorCard>
      </Dialog>
    )
  }
}

export default LanguageDialog
