import React, { Component } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'

import StatusModal from 'components/Modal/status'

import { Get, Post, Delete, Put } from 'utils/axios-template'
import { production_url } from '../config'

const TemplateJS = ( WrappedComponent ) => {
  class TemplateJSWrappedComponent extends Component {
    state = {
      loading: false,
      updateLoading: false,
      showStatusModal: false,
      statusModalType: '',
      onClickStatusModalButton: () => {},
      statusModalMessage: '',
      templateJavascripts: []
    }

    load = param => this.setState({ loading: param })
    updateLoad = param => this.setState({ updateLoading: param })
    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    onChangeTemplateJS = ( key, index, value ) => {
      let tmp = _.cloneDeep( this.state.templateJavascripts )
      tmp[ index ][ key ] = value
      return this.setState({ templateJavascripts: tmp })
    }

    getTemplateJS = ( template_id ) => Get(
      `${ production_url }/templates/${template_id}/javascripts`,
      this.getTemplateJSSuccess,
      this.getTemplateJSError,
      this.load
    )
    getTemplateJSSuccess = payload => this.setState({ templateJavascripts: payload })
    getTemplateJSError = error => this.requestError( error )

    createTemplateJS = ( template_id ) => Post(
      `${ production_url }/templates/${ template_id }/javascripts`,
      {
        type: 'URL',
        content: ''
      },
      ( payload ) => this.createTemplateJSSuccess( payload, template_id ),
      this.createTemplateJavascriptError,
      this.updateLoad
    )
    createTemplateJSSuccess = ( payload, template_id ) => this.getTemplateJS( template_id )
    createTemplateJSError = error => this.requestError( error )

    updateTemplateJS = ( template_id, id, data ) => Put(
      `${ production_url }/templates/${ template_id }/javascripts/${ id }`,
      data,
      ( payload ) => this.updateTemplateJSSuccess( payload, template_id ),
      this.updateTemplateJSError,
      this.updateLoad
    )
    updateTemplateJSSuccess = ( payload, template_id ) => this.getTemplateJS( template_id )
    updateTemplateJSError = error => this.requestError( error )

    removeTemplateJS = ( template_id, id ) => Delete(
      `${ production_url }/templates/${ template_id }/javascripts/${ id }`,
      () => this.removeTemplateJSSuccess( template_id ),
      this.removeTemplateJSError,
      this.updateLoad
    )
    removeTemplateJSSuccess = template_id => this.getTemplateJS( template_id )
    removeTemplateJSError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            getTemplateJS={ this.getTemplateJS }
            createTemplateJS={ this.createTemplateJS }
            updateTemplateJS={ this.updateTemplateJS }
            removeTemplateJS={ this.removeTemplateJS }
            onChangeTemplateJS={ this.onChangeTemplateJS }
            onLoadTemplateJS={ this.state.loading }
            onLoadJSUpdate={ this.state.updateLoading }
            templateJavascripts={ this.state.templateJavascripts } />
          <StatusModal
            isOpen={ this.state.showStatusModal }
            type={ this.state.statusModalType }
            message={ this.state.statusModalMessage ? this.state.statusModalMessage : 'You might be disconnected from the Internet, please reconnect and refresh the page to use Ark again.' }
            onClick={ this.state.onClickStatusModalButton } />
        </>
      )
    }
  }
  return TemplateJSWrappedComponent
}

export default TemplateJS
