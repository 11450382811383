import React from 'react'
import { Dialog, Button, Typography } from '@material-ui/core'
import { MdClose, MdPhoneIphone, MdDesktopWindows } from 'react-icons/md'
import { IoIosTabletLandscape } from 'react-icons/io'

import TemplateEditorCard from '../components/card'

import './index.scss'

import Lang from 'Lang/General'

const RendererViewportAdjust = ({
  showRendererViewportAdjust,
  updateMainContainerState,
  lang
}) => {
  return (
    <Dialog
      open={ showRendererViewportAdjust }
      maxWidth={ 'lg' }
      fullWidth={ true }>
      <TemplateEditorCard>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <h4>{ Lang[ 'SELECT_VIEWPORT' ][ lang ] }</h4>
          <Button
            classes={{
              root: "button-close",
              label: "button-text" }}
            onClick={ () => updateMainContainerState( 'showRendererViewportAdjust', false ) }>
            <MdClose style={{ fontSize: '20px' }}/>
          </Button>
        </div>
        <hr />
        <div className="renderer-viewport-container">
          <Button
            classes={{ root: 'btn btn-primary' }}
            onClick={ () => {
              updateMainContainerState( 'selectedViewportRenderer', 'desktop' )
              updateMainContainerState( 'showRendererViewportAdjust', false )
            }}>
            <div style={{ flexDirection: 'column', alignItems: 'center' }}>
              <MdDesktopWindows style={{ fontSize: '40px', marginBottom: '10px' }}/>
              <Typography>{ Lang[ 'DESKTOP_VIEW' ][ lang ] }</Typography>
            </div>
          </Button>
          <Button
            classes={{ root: 'btn btn-primary' }}
            onClick={ () => {
              updateMainContainerState( 'selectedViewportRenderer', 'tablet' )
              updateMainContainerState( 'showRendererViewportAdjust', false )
            }}>
            <div style={{ flexDirection: 'column', alignItems: 'center' }}>
            <IoIosTabletLandscape style={{ fontSize: '40px', marginBottom: '10px' }} />
              <Typography>{ Lang[ 'TABLET_VIEW' ][ lang ] }</Typography>
            </div>
          </Button>
          <Button
            classes={{ root: 'btn btn-primary' }}
            onClick={ () => {
              updateMainContainerState( 'selectedViewportRenderer', 'mobile' )
              updateMainContainerState( 'showRendererViewportAdjust', false )
            }}>
            <div style={{ flexDirection: 'column', alignItems: 'center' }}>
            <MdPhoneIphone style={{ fontSize: '40px', marginBottom: '10px' }}/>
              <Typography>{ Lang[ 'MOBILE_VIEW' ][ lang ] }</Typography>
            </div>
          </Button>
        </div>
        <hr />
        <div className="renderer-viewport-footer">
          <Button
            classes={{ root: 'btn-secondary' }}
            onClick={ () => {
              updateMainContainerState( 'showRendererViewportAdjust', false )
            }}>
            { Lang[ 'CLOSE' ][ lang ] }
          </Button>
        </div>
      </TemplateEditorCard>
    </Dialog>
  )
}

export default RendererViewportAdjust
