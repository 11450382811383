import React, { useState, useEffect } from 'react'
import {
  FormGroup,
  Label,
  Input
} from 'reactstrap'
import _ from 'lodash'
import ReactHTMLParser from 'react-html-parser'


const RadioButton = ({
  content,
  onChangeFormBuilderState,
  formValues,
  onPatchFormValues,
  dom,
  findObjectAndPatch,
  currentLanguage
}) => {
  const [ labelStr, setLabelStr ] = useState("")

  useEffect(() => {
    let labelText = content.label[ currentLanguage ? currentLanguage : 'en' ]

    if ( labelText.length !== 0 ) {
      if ( typeof( ReactHTMLParser( labelText )[0] ) === 'object' ) {
        let labelTag = ReactHTMLParser( labelText )[0].type
        let closingTag = "</" + labelTag + ">"
        labelText = labelText.slice( 0, -1 * closingTag.length )
        if ( formValues[ content.dataMapIndex ][ 'isRequired' ] ){
          labelText = labelText + "<span style='color: #ff0000;'> * </span>"
        }
        labelText = labelText + closingTag
      }
      else {
        labelText = labelText + "<span style='color: #ff0000;'> * </span>"
      }
    }
    setLabelStr( labelText )
  }, [ content, formValues, currentLanguage ])

  return (
    <FormGroup 
      tag="fieldset"
      style={{ padding: content.padding ? content.padding : '0' }}>
      <Label>{ ReactHTMLParser( labelStr ) }</Label>
      {
        content.selections.map( ( item, index ) => {
          return (
            <FormGroup 
              key={ item.id }
              check
              onChange={ e => {
                onPatchFormValues( content.dataMapIndex, 'value', e.target.value )
              }}
              value={ formValues[ content.dataMapIndex ][ 'value' ] }>
              <Label check>
                <Input 
                  type="radio" 
                  name={ content.id } 
                  value={ item.value }
                  checked={ item.value === formValues[ content.dataMapIndex ][ 'value' ] } />
                {' '}
                { content.type.indexOf( 'Flexible' ) !== -1 && index === content.selections.length - 1
                  ? <Input
                      type='text'
                      value={ item.value === formValues[ content.dataMapIndex ][ 'value' ] 
                        ? ( new DOMParser ).parseFromString( item.label[ currentLanguage ], "text/html" ).documentElement.textContent 
                        : '' }
                      readOnly= { formValues[ content.dataMapIndex ][ 'value' ].indexOf( item.value ) > -1 ? false : true }
                      style={{ 
                        height: "auto",
                        border: "none",
                        borderRadius: "0",
                        padding: "0",
                        fontSize: "14px",
                        borderBottom: "1px dotted #000000" }}
                      onChange={ async e => {
                        let tmpFormValues = _.cloneDeep( formValues )
                        let tmpDOM = _.cloneDeep( dom )
                        let tmpContent = _.cloneDeep( content )
                        let tmpValue = _.cloneDeep( formValues[ content.dataMapIndex ][ 'value' ] )

                        tmpValue = e.target.value.replaceAll( ' ', '-' )
                        tmpFormValues[ content.dataMapIndex ][ 'value' ] = tmpValue

                        tmpContent.selections[ index ][ 'value' ] = e.target.value.replaceAll( ' ', '-' )
                        tmpContent.selections[ index ][ 'label' ][ currentLanguage ] = e.target.value

                        await findObjectAndPatch( tmpDOM, tmpContent )
                        Promise.all([
                          onChangeFormBuilderState( 'dom', tmpDOM ),
                          onChangeFormBuilderState( 'formValues', tmpFormValues )
                        ])
                      }}/>
                  : ReactHTMLParser( item.label[ currentLanguage ? currentLanguage : 'en' ] ) } 
              </Label>
            </FormGroup>
          )
        })
      }
      
    </FormGroup>
  )
}

export default RadioButton