import React from 'react'
import { Col, InputGroup, InputGroupAddon } from 'reactstrap'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

const TemplateDatepicker = ({
  selectedDate,
  onChange,
  showTimeSelect,
  showTimeSelectOnly,
  dateFormat,
  timeFormat,
  zIndex,
  popperPlacement,
  showTimeInput
}) => {
  return (
    <Col className='p-0' md={ 12 } style={{ zIndex: zIndex }}  >
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <div className="input-group-text">
            <FontAwesomeIcon icon={ faCalendarAlt } />
          </div>
        </InputGroupAddon>
        <DatePicker
          className="form-control"
          popperPlacement={ `${ popperPlacement || 'bottom' }-start` }
          popperModifiers={{
            flip: { behavior: [ popperPlacement || 'bottom' ] },
            preventOverflow: { enabled: false },
            hide: { enabled: false }
          }}
          dateFormat={ dateFormat }
          timeFormat={ timeFormat }
          selected={ selectedDate }
          onChange={ onChange }
          showTimeSelect={ showTimeSelect }
          showTimeSelectOnly={ showTimeSelectOnly }
          showTimeInput={ showTimeInput }
        />
      </InputGroup>
    </Col>
  )
}

export default TemplateDatepicker