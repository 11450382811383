import React, { useEffect } from 'react'
import {
  FormGroup,
  Row, Col,
  Card, CardHeader, CardBody, CardFooter,
  Button, Input, Label
 } from 'reactstrap'
 import Moment from 'moment'

import PageTitle from 'components/PageTitle'
import ARKTable from 'components/Table'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Lang from 'Lang/General'
import SslSettingHOC from './actions'

const SslSetting = props => {
  useEffect( () => {
    props.getSslSettings()
  }, [] )

  return(
    <>
        <PageTitle
          heading='SSL Setting'
          icon="pe-7s-ticket icon-gradient bg-happy-fisher"
          buttons={[]} 
        />
        <Card className="mb-3">
          <CardHeader>{ Lang[ 'SEARCH' ][ props.data.languageReducer.lang ] }</CardHeader>
          <CardBody>
            <Row>
              <Col md={ 3 }>
                <FormGroup>
                  <Label>{ Lang[ 'DOMAIN_NAME' ][ props.data.languageReducer.lang ] }</Label>
                  <Input
                    type="text"
                    value={ props.sslSettingSearchParams.domain }
                    onChange={ e => setSearchParams( 'domain', e.target.value, props ) }
                  />
                </FormGroup>
              </Col>
              <Col md={ 3 }>
                <FormGroup>
                  <Label>{ Lang[ 'STATUS' ][ props.data.languageReducer.lang ] }</Label>
                  <Input
                    type="select"
                    value={ props.sslSettingSearchParams.status }
                    onChange={ e => setSearchParams( 'status', e.target.value, props ) } >
                    <option value=''></option>
                    <option value='success'>success</option>
                    <option value='error'>error</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={ 3 }>
                <FormGroup>
                  <Label>Expired for less than (days)</Label>
                  <Input
                    type="number"
                    step={ 1 }
                    value={ props.sslSettingSearchParams.expired_for }
                    onChange={ e => setSearchParams( 'expired_for', e.target.value, props ) } 
                  />
                </FormGroup>
              </Col>
              <Col md={ 3 }>
                <FormGroup>
                  <Label>Expire in (days)</Label>
                  <Input
                    type="number"
                    step={ 1 }
                    value={ props.sslSettingSearchParams.expires_in }
                    onChange={ e => setSearchParams( 'expires_in', e.target.value, props ) } 
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="d-flex flex-row flex-wrap justify-content-between" >
            <Button
              color="link"
              className="pl-0"
              onClick={ () => props.updateSslSettingListing() }>
              { `Get latest listing ( last update on ${ Moment( props.sslSettings.last_updated ).format( 'DD.MM.YYYY HH:mm' ) } )` }
            </Button>
            <div className="d-flex flex-row flex-nowrap" >
              <Button
                color="primary"
                className="mr-2"
                onClick={ () => props.getSslSettings() }>
                { Lang[ 'SEARCH' ][ props.data.languageReducer.lang ] }
              </Button>
              <Button
                color="danger"
                onClick={ () => props.getSslSettings( 1, true ) }>
                { Lang[ 'RESET' ][ props.data.languageReducer.lang ] }
              </Button>
            </div>
          </CardFooter>
        </Card>
        <ARKTable
          data={ props.sslSettings.pages?.data || [] }
          columns={[
            {
              Header: Lang[ 'DOMAIN_NAME' ][ props.data.languageReducer.lang ],
              accessor: 'domain',
            },
            {
              Header: Lang[ 'STATUS' ][ props.data.languageReducer.lang ],
              accessor: 'status'
            },
            {
              Header: 'Expiry date',
              accessor: 'ssl_expiry_date',
              Cell: ({ value }) => value ? Moment( value ).format( 'DD.MM.YYYY HH:mm' ) : ''
            },
            {
              Header: 'Expiry',
              accessor: 'expires_in_number_of_days',
              Cell: ({ value }) => value > 0 
                ? `Expires in ${ value } day(s)` 
                : `Expired for ${ value * -1 } day(s)` 
            },

          ]}
          page={ props.sslSettings.pages?.meta?.current_page || 1 }
          totalPages={ props.totalPages }
          onChangePage={ page => props.getSslSettings( page ) }
          showPagination={ false }
        />
        { props.onLoadSslSettingHOC && <LoadingOverlay/> }
      </>
  )
}

const setSearchParams = ( key, value, props ) => props.onChangeSslSettingHOC( 
  'sslSettingSearchParams', 
  { ...props.sslSettingSearchParams, [ key ]: value }
)

export default SslSettingHOC( SslSetting )