import React, { Component } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      campaignTypes: [],
      loading: false
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    getCampaignTypes = () => Get(
      `/api/v1/ols_campaign_types?query=${ convertObjToBase64({ 
        is_paginated: false,
        filter: {
          $or: [
            { status: 'ACTIVE' }
          ]
        } 
      }) }`,
      this.getCampaignTypesSuccess,
      this.getCampaignTypesError,
      this.load
    )
    getCampaignTypesSuccess = payload => this.setState({ campaignTypes: payload.data })
    getCampaignTypesError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          getCampaignTypes={ this.getCampaignTypes }
          campaignTypes={ this.state.campaignTypes }
          onLoadCampaignTypes={ this.state.loading } />
      )
    }
  }
  return WithHOC
}

export default HOC