import React, { Fragment, useRef } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {
  Container, Card, CardBody, Form, FormGroup, Label, Input, Col, FormFeedback, FormText, Button, Row
}from 'reactstrap'
import Image from 'react-bootstrap/Image'

import ARKUpload from 'components/UploadImage'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import DefaultImage from 'assets/images/imagePreview.png'
import Lang from 'Lang/General'

const TemplateForm = ({
  data,
  errorMessage,
  onUploadImage,
  onDeleteImage,
  updateTemplate,
  edit,
  onLoadSiteTemplate,
  lang
}) => {
  let fileInput = useRef( null )
  const fileInputRef = element => {
    fileInput = element
  }
  const onClickUploadImage = () => {
    focusFileInput();
  }

  const focusFileInput = () => {
    if( fileInput )
      fileInput.click();
  }
  const onChangeTemplate = ( key , e ) => {
    let tmp = data
    tmp[key] = e.target.value
    return updateTemplate( tmp )
  }
  return(
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}>
        <Container fluid>
          <Card className="main-card mb-3">
            <CardBody>
              <Form>
                <FormGroup row>
                  <Label for="company_name" sm={ 2 }>{ Lang[ 'TEMPLATE_NAME' ][ lang ] }</Label>
                  <Col sm={ 10 }>
                    <Input
                      name="company" id="template_name"
                      placeholder={ Lang[ 'ENTER_TEMPLATE_NAME' ][ lang ] }
                      value={ data.name }
                      onChange={ e => onChangeTemplate( 'name', e ) }
                      invalid={ errorMessage && errorMessage.length > 0 ? true : false }/>
                    <FormFeedback>{ errorMessage }</FormFeedback>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="company_name" sm={ 2 }>{ Lang[ 'TEMPLATE_IMAGE' ][ lang ] }</Label>
                  <Col sm={ 10 }>
                    <Form>
                      <Label>{ Lang[ 'IMAGE_LABEL' ][ lang ] }</Label>
                      <FormText color="danger">{ errorMessage && errorMessage.length > 0 ? errorMessage : '' }</FormText>
                      <Row>
                        <Col md={ 3 }>
                          <Image
                            src={ data.preview !== '' ? data.preview : DefaultImage }
                            onClick={ onClickUploadImage }
                            style={{ width: '100%', height: '100%', cursor: 'pointer' }}/>
                          <Button
                            className="mb-2 mr-2 btn-icon btn-icon-only"
                            style={{
                              borderRadius: '50%',
                              position: 'absolute',
                              bottom: '0%',
                              right: '8%',
                              minHeight: '30px',
                              minWidth: '30px',
                              padding: '5px'
                            }}
                            color="danger"
                            onClick={ onDeleteImage }>
                            <i className="pe-7s-trash btn-icon-wrapper" style={{ fontSize: '23px' }}> </i>
                          </Button>
                        </Col>
                      </Row>
                      <FormGroup>
                        <input
                          type="file"
                          ref={ fileInputRef }
                          style={{ display: 'none' }}
                          onChange={ event => onUploadImage( event ) }
                          accept={ 'image/png,image/jpg,image/jpeg' }/>
                      </FormGroup>
                    </Form>
                    {/*<ARKUpload
                      onUploadImage={ onUploadImage }
                      photo_url={ data.preview }
                      onDeleteImage={ onDeleteImage }
                       />*/}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="salutation" sm={ 2 }>{ Lang[ 'STATUS' ][ lang ] }</Label>
                  <Col sm={ 10 }>
                    <Input
                      type="select"
                      name="status"
                      id="status"
                      value={ data.status }
                      onChange={ e => onChangeTemplate( 'status', e ) }>
                      <option value={ 'ACTIVE' }>{ Lang[ 'ACTIVE' ][ lang ] }</option>
                      <option value={ 'INACTIVE' }>{ Lang[ 'INACTIVE' ][ lang ] }</option>
                      <option value={ 'ARCHIVED' }>{ Lang[ 'ARCHIVED' ][ lang ] }</option>
                    </Input>
                  </Col>
                </FormGroup>
              </Form>
              {
                onLoadSiteTemplate && <LoadingOverlay />
              }
            </CardBody>
          </Card>
        </Container>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}

export default TemplateForm
