import React, { Component } from 'react';
import { connect } from 'react-redux'
import { TextField } from '@material-ui/core'
import { Button } from 'reactstrap'
import _ from 'lodash'
import Cookies from 'js-cookie'
import Axios from 'axios'

import LoadingModal from 'components/Indicator/LoadingOverlay'
import StatusModal from 'components/Modal/status'

import { getItem } from 'utils/tokenStore'
import { ApplyBranding } from 'utils/apply-color'
import { login } from 'actions/login'
import { getUserProfile } from 'actions/profile'
import { getDictionary } from 'actions/dictionary'
import { getSelectedRole } from 'actions/role'
import { getPlatformConfig } from 'actions/platformConfig'

import BackdropImage from 'assets/images/backdrop-conida.svg';
import TennisBackdropImage from 'assets/images/tennis.jpg'

import './index.scss';

class Login extends Component {
  state = {
    email: '',
    password: '',
    statusModal: false
  }

  componentDidMount = async() => {
    if( Cookies.get( 'SHOW_OUTDATED_TOKEN' ) && Cookies.get( 'SHOW_OUTDATED_TOKEN' ) === 'true' ) {
      Cookies.set( 'SHOW_OUTDATED_TOKEN', 'false' )
    }
    localStorage.getItem( 'IS_TOKEN_EXPIRED' ) && this.setState({ statusModal: true })
    await this.props.getPlatformConfig()
  }

  componentDidUpdate = pp => {
    if( this.props.data.ajaxReducer.ajaxCallProgress !== pp.data.ajaxReducer.ajaxCallProgress ) {
      if( !this.props.data.ajaxReducer.ajaxCallProgress && !_.isEmpty( getItem( 'PROOFPOINT_ARK_MANAGEMENT' ) ) ) {
        Axios.defaults.headers = {
          common : {
            Authorization: `${ getItem( 'PROOFPOINT_ARK_MANAGEMENT' ) }`
          }
        }
        if( !this.props.data.ajaxReducer.ajaxErrorMessage && _.isEmpty( this.props.data.profileReducer.profile ) ) {
          this.props.getUserProfile()
        } else {
          this.props.history.push( '/dashboard' )
        }
      }
    }

    if( 
      pp.data.ajaxReducer !== this.props.data.ajaxReducer &&
      this.props.data.ajaxReducer.ajaxCallProgress !== 1 &&
      this.props.data.ajaxReducer.ajaxErrorMessage && 
      this.props.data.ajaxReducer.ajaxErrorMessage.length > 0 
    ){
      this.setState({ statusModal: true })
    }
  }

  onClickLogin = ( email, password ) => this.props.login({ email, password, platform: 'ark-management' })

  handleForgotPassword = () =>  this.props.history.push( '/forgot-password' )

  handleKeyPress = ( event, email, password ) => {
    if( event.key === 'Enter' ){
      this.props.login({ email, password, platform: 'ark-management' })
    }
  }

  render = () => {
    return (
      <>
        {
          !_.isEmpty( this.props.data.platformConfigReducer.platformConfig ) 
          && _.find( this.props.data.platformConfigReducer.platformConfig, { key: 'PLATFORM_COLOR' } )
          && <ApplyBranding colors={{ primary: _.find( this.props.data.platformConfigReducer.platformConfig, { key: 'PLATFORM_COLOR' } ).value }} />
        }
        <div className="login">
          <div className="login-form">
            <div className="login-panel">
              <h1 className="login-panel-title">Willkommen, Loggen Sie sich ein</h1>
              <TextField
                error={ this.props.data.ajaxReducer.ajaxErrorMessage && this.props.data.ajaxReducer.ajaxErrorMessage.email ? true : false }
                helperText={ this.props.data.ajaxReducer.ajaxErrorMessage && this.props.data.ajaxReducer.ajaxErrorMessage.email ? this.props.data.ajaxReducer.ajaxErrorMessage.email : '' }
                label={ 'Email' }
                value={ this.state.email }
                onChange={ e => this.setState({ email: e.target.value }) }
                type={ 'email' }
                className={ 'kws-input mb-15' }
                onKeyPress={ this.handleKeyPress } />
              <TextField
                error={ this.props.data.ajaxReducer.ajaxErrorMessage && this.props.data.ajaxReducer.ajaxErrorMessage.password ? true : false }
                helperText={ this.props.data.ajaxReducer.ajaxErrorMessage && this.props.data.ajaxReducer.ajaxErrorMessage.password ? this.props.data.ajaxReducer.ajaxErrorMessage.password : '' }
                label={ 'Passwort' }
                value={ this.state.password }
                onChange={ e => this.setState({ password: e.target.value }) }
                type={ 'password' }
                className={ 'kws-input mb-30' }
                onKeyPress={ event => this.handleKeyPress( event, this.state.email, this.state.password ) } />
              <div className="d-flex">
                <Button
                  color={ 'link' }
                  onClick={ this.handleForgotPassword }
                  style={{ marginLeft: 'auto' }}
                  classes={{ root: 'primary-button-forgot-password', label: 'primary-button-text text-capitalize' }}>
                  { 'Passwort vergessen' }
                </Button>
                <Button
                  color={ 'primary' }
                  onClick={ () => this.onClickLogin( this.state.email, this.state.password ) }
                  classes={{ root: 'primary-button', label: 'primary-button-text text-capitalize' }}>
                  { 'Anmeldung' }
                </Button>
              </div>
            </div>
            <StatusModal
              isOpen={ this.state.statusModal }
              type={ 'negative' }
              message={ localStorage.getItem( 'IS_TOKEN_EXPIRED' )
                ? 'Ihre Sitzung ist abgelaufen'
                : this.props.data.ajaxReducer
                  ? this.props.data.ajaxReducer.ajaxErrorMessage 
                  : ''
              }
              onClick={ () => {
                this.setState({ statusModal: false }) 
                localStorage.getItem( 'IS_TOKEN_EXPIRED' ) && window.localStorage.removeItem( 'IS_TOKEN_EXPIRED' )
              }} 
            />
          </div>
          <img src={ window.location.host.indexOf( 'tennis' ) > -1 ? TennisBackdropImage : BackdropImage } className="login-backdrop" />
          { this.props.data.ajaxReducer.ajaxCallProgress > 0 && <LoadingModal /> }
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({ data: state })

export default connect( mapStateToProps, {
  login,
  getUserProfile,
  getDictionary,
  getSelectedRole,
  getPlatformConfig
})( Login );
