import React, { useState, useEffect } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap'
import _ from 'lodash'
import uniqid from 'uniqid'
import Moment from 'moment'

import Lang from 'Lang/General'

const AddContentModal = ({
  showUpdateContentModal,
  onChangeFormBuilderState,
  findObjectAndPatch,
  dom,
  foundObject,
  formValues,
  selectedLanguage
}) => {
  const [ control, updateControl ] = useState( '' )
  return (
    <Modal
      isOpen={ showUpdateContentModal }
      size={ 'xl' }
      toggle={ () => {
        onChangeFormBuilderState( 'showUpdateContentModal', false )
      }}>
      <ModalHeader toggle={ () => onChangeFormBuilderState( 'showUpdateContentModal', false ) }>Update content</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault() }>
          <FormGroup>
            <Label>{ Lang[ 'SELECT_COMPONENT' ][ selectedLanguage ] }</Label>
            <Input
              type="select"
              value={ control }
              onChange={ e => {
                let tmp = _.cloneDeep( foundObject )
                let tmpFormValues = _.cloneDeep( formValues )
                let tmpDOM = _.cloneDeep( dom )
                tmp.children.pop()
                let id = uniqid()
                if( e.target.value === 'TextField' ) {
                  tmpFormValues.push({
                    id: id,
                    dataLabel: id,
                    value: '',
                    type: 'textField',
                    isEmailTextField: false,
                    validation: {
                      "en" : "",
                      "de" : "",
                      "nl" : "",
                      "it" : ""
                    }
                  })
                  tmp.children.push({
                    id: id,
                    type: 'TextField',
                    label: {
                      "en": "<p>Your text here</p>",
                      "de": "<p>Ihr Text hier</p>",
                      "nl": "<p>Uw tekst hier</p>",
                      "it": "<p>Il tuo testo qui</p>"
                    },
                    dataMapIndex: _.findIndex( tmpFormValues, { id: id } ),
                    padding: '15px'
                  })
                } else if ( e.target.value === 'TextArea' ) {
                  tmpFormValues.push({
                    id: id,
                    dataLabel: id,
                    value: '',
                    type: 'textarea',
                    isEmailTextField: false,
                    validation: {
                      "en" : "",
                      "de" : "",
                      "nl" : "",
                      "it" : ""
                    }
                  })
                  tmp.children.push({
                    id: id,
                    type: 'TextArea',
                    label: {
                      "en": "<p>Your text here</p>",
                      "de": "<p>Ihr Text hier</p>",
                      "nl": "<p>Uw tekst hier</p>",
                      "it": "<p>Il tuo testo qui</p>"
                    },
                    dataMapIndex: _.findIndex( tmpFormValues, { id: id } ),
                    padding: '15px',
                    rows: 8,
                    resizable: 'None'
                  })
                } else if( e.target.value === 'Button' ) {
                  tmpFormValues.push({
                    id: id,
                    dataLabel: id,
                    value: '',
                    type: 'button'
                  })
                  tmp.children.push({
                    id: id,
                    type: 'Button',
                    onClick: () => {},
                    color: 'primary',
                    content: {
                      "en": "<p>Your submit button here</p>",
                      "de": "<p>Ihr Absenden-Button hier</p>",
                      "nl": "<p>Uw verzendbutton hier</p>",
                      "it": "<p>Il tuo pulsante di invio qui</p>"
                    },
                    dataMapIndex: _.findIndex( tmpFormValues, { id: id } )
                  })
                } else if( e.target.value === 'Radio' ) {
                  tmpFormValues.push({
                    id: id,
                    dataLabel: id,
                    value: '',
                    type: 'radio',
                    validation: {
                      "en" : "",
                      "de" : "",
                      "nl" : "",
                      "it" : ""
                    }
                  })
                  tmp.children.push({
                    id: id,
                    type: 'Radio',
                    label: {
                      "en": '<p>Your new radio button here</p>',
                      "de": '<p>Ihr neuer Auswahlknopf hier</p>',
                      "nl": '<p>Uw nieuwe radioknop hier</p>',
                      "it": '<p>Il vostro nuovo pulsante radio qui</p>'
                    },
                    dataMapIndex: _.findIndex( tmpFormValues, { id: id } ),
                    selections: [
                      {
                        label: {
                          "en": "<p>Selection 1</p>",
                          "de": "<p>Selection 1</p>",
                          "nl": "<p>Selection 1</p>",
                          "it": "<p>Selection 1</p>"
                        },
                        value: 'selection-1'
                      },
                      {
                        label: {
                          "en": "<p>Selection 2</p>",
                          "de": "<p>Selection 2</p>",
                          "nl": "<p>Selection 2</p>",
                          "it": "<p>Selection 2</p>"
                        },
                        value: 'selection-2'
                      }
                    ],
                    padding: '15px'
                  })
                } else if( e.target.value === 'Text' ) {
                  tmpFormValues.push({
                    id: id,
                    dataLabel: id,
                    value: '',
                    type: 'text'
                  })
                  tmp.children.push({
                    id: id,
                    type: 'Text',
                    style: {
                      marginBottom: 0
                    },
                    label: {
                      "en": "<p>Your text here</p>",
                      "de": "<p>Ihr Text hier</p>",
                      "nl": "<p>Uw tekst hier</p>",
                      "it": "<p>Il tuo testo qui</p>"
                    },
                    dataMapIndex: _.findIndex( tmpFormValues, { id: id } ),
                    padding: '15px'
                  })
                } else if( e.target.value === 'Checkbox' ) {
                  tmpFormValues.push({
                    id: id,
                    dataLabel: id,
                    value: [],
                    type: 'checkbox',
                    validation: {
                      "en" : "",
                      "de" : "",
                      "nl" : "",
                      "it" : ""
                    }
                  })
                  tmp.children.push({
                    id: id,
                    type: 'Checkbox',
                    label: {
                      "en": "<p>Your new checkbox here</p>",
                      "de": "<p>Ihre neue Checkbox hier</p>",
                      "nl": "<p>Uw nieuwe selectievakje hier</p>",
                      "it": "<p>La tua nuova casella di controllo qui</p>"
                    },
                    dataMapIndex: _.findIndex( tmpFormValues, { id: id } ),
                    selections: [
                      {
                        label: {
                          "en": "<p>Selection 1</p>",
                          "de": "<p>Selection 1</p>",
                          "nl": "<p>Selection 1</p>",
                          "it": "<p>Selection 1</p>"
                        },
                        value: 'selection-1',
                        nestedSelections: []
                      }
                    ],
                    padding: '15px'
                  })
                } else if( e.target.value === 'InputFile' ) {
                  tmpFormValues.push({
                    id: id,
                    dataLabel: id,
                    value: '',
                    type: 'inputFile',
                    validation: {
                      "en" : "",
                      "de" : "",
                      "nl" : "",
                      "it" : ""
                    }
                  })
                  tmp.children.push({
                    id: id,
                    type: 'InputFile',
                    label: {
                      "en": "<p>Your new input file here.</p>",
                      "de": "<p>Your new input file here.</p>",
                      "nl": "<p>Your new input file here.</p>",
                      "it": "<p>Your new input file here.</p>"
                    },
                    dataMapIndex: _.findIndex( tmpFormValues, { id: id } ),
                    padding: '15px'
                  })
                } else if( e.target.value === 'Image' ) {
                  tmpFormValues.push({
                    id: id,
                    dataLabel: id,
                    value: '',
                    type: 'image'
                  })
                  tmp.children.push({
                    id: id,
                    type: 'Image',
                    url: 'https://s3-eu-central-1.amazonaws.com/ark.staging.assets/shared_media_drive/8bb6c17838643f9691cc6a4de6c51709/original/image.png',
                    padding: '5px',
                    width: '100px',
                    height: '100px'
                  })
                } else if( e.target.value === 'DateField' ) {
                  tmpFormValues.push({
                    id: id,
                    dataLabel: id,
                    value: Moment().utc().format(),
                    type: 'dateField',
                    validation: {
                      "en" : "",
                      "de" : "",
                      "nl" : "",
                      "it" : ""
                    }
                  })
                  tmp.children.push({
                    id: id,
                    type: 'DateField',
                    label: {
                      "en": "<p>Your date field here</p>",
                      "de": "<p>Your date field here</p>",
                      "nl": "<p>Your date field here</p>",
                      "it": "<p>Your date field here</p>"
                    },
                    dataMapIndex: _.findIndex( tmpFormValues, { id: id } ),
                    padding: '15px'
                  })
                } else if( e.target.value === 'Dropdown' ) {
                  tmpFormValues.push({
                    id: id,
                    dataLabel: id,
                    value: '',
                    type: 'dropdown',
                    validation: {
                      "en" : "",
                      "de" : "",
                      "nl" : "",
                      "it" : ""
                    }
                  })
                  tmp.children.push({
                    id: id,
                    type: 'Dropdown',
                    label: {
                      "en": '<p>Your new dropdown here</p>',
                      "de": '<p>Ihr neues Dropdown hier</p>',
                      "nl": '<p>Je nieuwe dropdown hier</p>',
                      "it": '<p>Il tuo nuovo menu a tendina qui</p>'
                    },
                    dataMapIndex: _.findIndex( tmpFormValues, { id: id } ),
                    selections: [
                      {
                        label: {
                          "en": "Selection 1",
                          "de": "Selection 1",
                          "nl": "Selection 1",
                          "it": "Selection 1"
                        },
                        value: 'selection-1'
                      },
                      {
                        label: {
                          "en": "Selection 2",
                          "de": "Selection 2",
                          "nl": "Selection 2",
                          "it": "Selection 2"
                        },
                        value: 'selection-2'
                      }
                    ],
                    padding: '15px'
                  })
                } 
                findObjectAndPatch( tmpDOM, tmp )
                Promise.all([
                  onChangeFormBuilderState( 'formValues', tmpFormValues ),
                  onChangeFormBuilderState( 'dom', tmpDOM )
                ]).then(() => {
                  onChangeFormBuilderState( 'showUpdateContentModal', false )
                })
              }}>
              <option value={ '' }>{ '' }</option>
              <option value={ 'TextField' }>{ Lang[ 'TEXTFIELD' ][ selectedLanguage ] }</option>
              <option value={ 'TextArea' }>{ Lang[ 'TEXTAREA' ][ selectedLanguage ] }</option>
              <option value={ 'Radio' }>{ Lang[ 'RADIO' ][ selectedLanguage ] }</option>
              <option value={ 'Text' }>{ Lang[ 'TEXT' ][ selectedLanguage ] }</option>
              <option value={ 'Checkbox' }>{ Lang[ 'CHECKBOX' ][ selectedLanguage ] }</option>
              <option value={ 'InputFile' }>{ Lang[ 'INPUT_FILE' ][ selectedLanguage ] }</option>
              <option value={ 'Image' }>{ Lang[ 'IMAGE_SITE_CONTENT' ][ selectedLanguage ] }</option>
              <option value={ 'DateField' }>{ Lang[ 'DATEFIELD' ][ selectedLanguage ] }</option>
              <option value={ 'Dropdown' }>{ Lang[ 'DROPDOWN' ][ selectedLanguage ] }</option>
            </Input>
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default AddContentModal