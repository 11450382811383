export const MainNav = [
  {
    icon: 'pe-7s-global',
    label: 'COCKPIT',
    secLabel: 'Cockpit'
  },
  {
    icon: 'pe-7s-rocket',
    label: 'DASHBOARD',
    secLabel: 'Dashboard',
    to: '#/dashboard'
  },
  {
    icon: 'pe-7s-users',
    label: 'CONSULTANT',
    secLabel: 'Consultant',
    to: '#/dashboard/consultant'
  },
  {
    icon: 'pe-7s-users',
    label: 'CLIENT',
    content: [
      {
        icon: 'pe-7s-users',
        label: 'CLIENT',
        secLabel: 'Client',
        to: '#/dashboard/client',
      },
      {
        icon: 'pe-7s-home',
        label: 'CENTER_MENU',
        secLabel: 'Center',
        to: '#/dashboard/center'
      },
      {
        icon: 'pe-7s-home',
        label: 'CENTER_GROUP_MENU',
        secLabel: 'Center Group',
        to: '#/dashboard/center-group'
      },
      {
        icon: 'pe-7s-news-paper',
        label: 'CENTER_STATISTICS',
        secLabel: 'Center Report',
        to: '#/dashboard/center-report'
      }
    ]
  },
  {
    icon: 'pe-7s-paper-plane',
    label: 'DOMAINS_LISTINGS',
    secLabel: 'Domains',
    to: '#/dashboard/domains'
  },
  {
    icon: 'pe-7s-cart',
    label: 'ORDER',
    content: [
      {
        icon: 'pe-7s-cart',
        label: 'ORDER',
        secLabel: 'Order',
        to: '#/dashboard/order'
      },
      {
        icon: 'pe-7s-portfolio',
        label: 'MODULE',
        secLabel: 'Module',
        to: '#/dashboard/module'
      },
      {
        icon: 'pe-7s-portfolio',
        label: 'PACKAGE',
        secLabel: 'Package',
        to: '#/dashboard/package'
      }
    ]
  },
  {
    icon: 'pe-7s-display2',
    label: 'CONTENT_SERVICE',
    content: [
      {
        icon: 'pe-7s-note',
        label: 'NEWS',
        secLabel: 'Content Service',
        content: [

          {
            icon: 'pe-7s-note',
            label: 'NEWS',
            to: '#/dashboard/content-service'
          },
          {
            icon: 'pe-7s-ticket',
            label: 'CATEGORY',
            to: '#/dashboard/content/category'
          },
        ]
      },
      {
        icon: 'pe-7s-news-paper',
        label: 'NEWSLETTER',
        secLabel: 'Newsletter',
        to: '#/dashboard/newsletter-template'
      },
      {
        icon: 'pe-7s-display2',
        label: 'LANDING_PAGES',
        secLabel: 'OLS',
        content: [
          {
            icon: 'pe-7s-display2',
            label: 'PAGE',
            to: '#/dashboard/ols-landing-page/page'
          },
          {
            icon: 'pe-7s-news-paper',
            label: 'FORM',
            to: '#/dashboard/ols-landing-page/form'
          }
        ]
      },
      {
        icon: 'pe-7s-speaker',
        label: 'OLS',
        secLabel: 'OLS',
        content: [
          {
            icon: 'pe-7s-ticket',
            label: 'TYPES',
            to: '#/dashboard/ols-campaign-types'
          },
          {
            icon: 'pe-7s-ticket',
            label: 'LISTINGS',
            to: '#/dashboard/ols-campaign-listings'
          },
          {
            icon: 'pe-7s-mail',
            label: 'MODULE_SETTINGS',
            to: '#/dashboard/ols-settings'
          },
          {
            icon: 'pe-7s-ticket',
            label: 'BOOKED_CAMPAIGN_MENU',
            to: '#/dashboard/ols-booked-campaign'
          }
        ]
      },
      {
        icon: 'pe-7s-news-paper',
        label: 'DIGITAL_ORDER_FORM',
        secLabel: 'Digital Order Form',
        content: [
          {
            icon: 'pe-7s-news-paper',
            label: 'FORM_BUILDER_MENU',
            to: '#/dashboard/digital-order-form/builder'
          },
          {
            icon: 'pe-7s-news-paper',
            label: 'FORM_ORDER_REQUEST',
            to: '#/dashboard/digital-order-form/order-request'
          },
          {
            icon: 'pe-7s-folder',
            label: 'FORM_CATEGORIES',
            to: '#/dashboard/digital-order-form/category'
          }
        ]
      },
      {
        icon: 'pe-7s-lintern',
        label: 'MARKETING_FUNNELS',
        secLabel: 'Marketing Funnels',
        content: [
          {
            icon: 'pe-7s-lintern',
            label: 'SYSTEM_FUNNELS',
            to: '#/dashboard/marketing-funnels/system-funnels'
          },
          {
            icon: 'pe-7s-mail',
            label: 'SYSTEM_MAILINGS',
            to: '#/dashboard/marketing-funnels/system-mailings'
          },
          {
            icon: 'pe-7s-note2',
            label: 'MAILING_TEMPLATES',
            to: '#/dashboard/marketing-funnels/mailing-templates'
          },
          {
            icon: 'pe-7s-lintern',
            label: 'FUNNEL_SCENARIOS',
            to: '#/dashboard/marketing-funnels/funnel-scenarios'
          }
        ]
      },
      {
        icon: 'pe-7s-users',
        label: 'MEMBER_AREA',
        secLabel: 'Member Area',
        content: [
          {
            icon: 'pe-7s-users',
            label: 'MEMBERS',
            to: '#/dashboard/member-area/members'
          },
          {
            icon: 'pe-7s-network',
            label: 'ROLES',
            to: '#/dashboard/member-area/roles'
          },
          {
            icon: 'pe-7s-signal',
            label: 'MEMBER_STATUS',
            to: '#/dashboard/member-area/member-status'
          },
          {
            icon: 'pe-7s-config',
            label: 'EVENT_TYPES',
            to: '#/dashboard/member-area/event-types-settings'
          },
          {
            icon: 'pe-7s-diskette',
            label: 'EVENT_TEMPLATES',
            to: '#/dashboard/member-area/event-templates'
          },
          {
            icon: 'pe-7s-config',
            label: 'PROGRAM_MODULES',
            to: '#/dashboard/member-area/program-modules-settings'
          },
          {
            icon: 'pe-7s-diskette',
            label: 'PROGRAM_TEMPLATES',
            to: '#/dashboard/member-area/program-templates'
          },
          {
            icon: 'pe-7s-diskette',
            label: 'CENTER_PURCHASED_PROGRAM_TEMPLATES',
            to: '#/dashboard/member-area/centers-program-templates'
          },
          {
            icon: 'pe-7s-map-marker',
            label: 'CONTENT_TAGS',
            to: '#/dashboard/member-area/content-tags-settings'
          },
          {
            icon: 'pe-7s-plane',
            label: 'ON_DEMAND_CATEGORY',
            to: '#/dashboard/member-area/content-demand-categories-settings'
          }, 
          {
            icon: 'pe-7s-albums',
            label: 'CONTENT_CATEGORY',
            to: '#/dashboard/member-area/content-categories'
          }, 
          {
            icon: 'pe-7s-magic-wand',
            label: 'TIPS_OF_THE_WEEK',
            to: '#/dashboard/member-area/tips'
          },
          {
            icon: 'pe-7s-study',
            label: 'KNOWLEDGE',
            to: '#/dashboard/member-area/knowledge'
          },
          {
            icon: 'pe-7s-network',
            label: 'FAQ_CATEGORIES',
            to: '#/dashboard/member-area/faq-categories'
          },
          {
            icon: 'pe-7s-note2',
            label: 'FAQ_LISTINGS',
            to: '#/dashboard/member-area/faq-listings'
          },
        ]
      },
      {
        icon: 'pe-7s-config',
        label: 'NEWS_AND_PRODUCTS',
        content: [
          {
            icon: 'pe-7s-home',
            label: 'NEWS',
            secLabel: 'News and Products',
            to: '#/dashboard/settings/news'
          },
          {
            icon: 'pe-7s-cart',
            label: 'PRODUCTS',
            secLabel: 'News and Products',
            to: '#/dashboard/settings/products'
          },
          {
            icon: 'pe-7s-comment',
            label: 'PRODUCT_REQUEST',
            secLabel: 'Product Request',
            content: [
              {
                icon: 'pe-7s-comment',
                label: 'PRODUCT_REQUEST_LISTING',
                to: '#/dashboard/settings/product-request'
              },
              {
                icon: 'pe-7s-paint-bucket',
                label: 'STATUS',
                to: '#/dashboard/settings/product-request-status'
              }
            ]
          },
        ]
      },
      {
        icon: 'pe-7s-albums',
        label: 'MEDIA',
        secLabel: 'Media',
        to: '#/dashboard/media'
      },
      {
        icon: 'pe-7s-file',
        label: 'ARK_M_FILES',
        secLabel: 'Ark-M Files',
        to: '#/dashboard/ark-m-files'
      }
    ]
  },
  {
    icon: 'pe-7s-users',
    label: 'USERS',
    secLabel: 'Users Management',
    to: '#/dashboard/users-management'
  },
  {
    icon: 'pe-7s-config',
    label: 'SITE_TEMPLATE_CONFIG',
    secLabel: 'Site Template Config',
    content: [
      {
        icon: 'pe-7s-config',
        label: 'TEMPLATE_LISTINGS',
        to: '#/dashboard/site-template-settings'
      },
      {
        icon: 'pe-7s-config',
        label: 'SECTION_LISTINGS',
        to: '#/dashboard/site-section-settings'
      },
      {
        icon: 'pe-7s-network',
        label: 'SECTION_CATEGORIES',
        to: '#/dashboard/section-categories'
      }
    ]
  },
  {
    icon: 'pe-7s-config',
    label: 'CLIENT_SETTING',
    content: [
      {
        icon: 'pe-7s-mail',
        label: 'EMAIL_SETTING',
        secLabel: 'Email Quota Settings',
        to: '#/dashboard/email-quota-settings'
      },
      {
        icon: 'pe-7s-shopbag',
        label: 'ONLINE_SHOP',
        secLabel: 'Online Shop',
        content: [
          {
            icon: 'pe-7s-network',
            label: 'FAQ_CATEGORIES',
            to: '#/dashboard/online-shop/faq-categories'
          },
          {
            icon: 'pe-7s-note2',
            label: 'FAQ_LISTINGS',
            to: '#/dashboard/online-shop/faq-listings'
          },
          {
            icon: 'pe-7s-tools',
            label: 'CAMPAIGN_TOOLS',
            to: '#/dashboard/campaign-tools'
          },
          {
            icon: 'pe-7s-notebook',
            label: 'BILLINGS',
            to: '#/dashboard/billings'
          },
          {
            icon: 'pe-7s-display2',
            label: 'VOUCHER_REPORT',
            to: '#/dashboard/voucher-report'
          }
        ]
      },
      {
        icon: 'pe-7s-shield',
        label: 'GDPR_CENTER',
        secLabel: 'GDPR Center',
        content: [
          {
            icon: 'pe-7s-copy-file',
            label: 'AV_CONTRACT',
            to: '#/dashboard/av-contract'
          },
          {
            icon: 'pe-7s-note',
            label: 'ARTICLE_14',
            to: '#/dashboard/article-14'
          },
          {
            icon: 'pe-7s-menu',
            label: 'PRIVACY_POLICY',
            to: '#/dashboard/privacy-policy'
          },
          {
            icon: 'pe-7s-file',
            label: 'IMPRINT',
            to: '#/dashboard/imprint'
          }
        ]
      },
      {
        icon: 'pe-7s-world',
        label: 'SOCIAL_MEDIA_MENU',
        secLabel: 'Social Media',
        to: '#/dashboard/social-media'
      }
    ]
  },
  {
    icon: 'pe-7s-config',
    label: 'ADMIN_SETTING',
    content: [
      {
        icon: 'pe-7s-mail',
        label: 'TEMPLATES',
        secLabel: 'Email Template Editor',
        to: '#/dashboard/email-template-editor'
      },
      {
        icon: 'pe-7s-cash',
        label: 'CURRENCIES',
        to: '#/dashboard/currencies'
      },
      {
        icon: 'pe-7s-lock',
        label: 'SECURITY',
        secLabel: 'Security',
        content: [
          {
            icon: 'pe-7s-drawer',
            label: 'PLATFORM',
            to: '#/dashboard/platform'
          },
          {
            icon: 'pe-7s-flag',
            label: 'ROLES_AND_PERMISSIONS',
            to: '#/dashboard/role-permissions'
          }
        ]
      },
      {
        icon: 'pe-7s-lock',
        label: 'SSL_SETTING',
        secLabel: 'SSL Setting',
        to: '#/dashboard/ssl-setting'
      }
    ]
  },
  {
    icon: 'pe-7s-config',
    label: 'IMPRINT',
    secLabel: 'Platform Settings',
    to: '#/dashboard/platform-settings'
  }
]
