import React, { Component } from 'react'
import _ from 'lodash'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Button
} from 'reactstrap'
import CKEditor5 from 'components/RTE/section'
import ListStyle from 'components/RTE/listStyle'
import Lang from 'Lang/General'

class CodeTagEditor extends Component {
  state = {
    contentText: '',
    listStyle: {}
  }

  componentDidMount = () => {
    this.setState({
      contentText: this.props.selectedEditableComponent.content,
      listStyle: this.props.selectedEditableComponent.listStyle
    })
  }

  componentDidUpdate = pp => {
    if( this.props.selectedEditableComponent ) {
      if( this.props.selectedEditableComponent.id !== pp.selectedEditableComponent.id ) {
        this.setState({
          contentText: this.props.selectedEditableComponent.content,
          listStyle: this.props.selectedEditableComponent.listStyle
        })
      }
    }
  }

  render = () => {
    let {
      lang,
      showCodeTagEditor,
      onChangeSectionEditorState,
      selectedEditableComponent,
      onUpdateRTEContentWithListStyle
    } = this.props
    return (
      <Modal 
        size={ 'lg' }
        isOpen={ showCodeTagEditor }
        toggle={() => onChangeSectionEditorState( 'showCodeTagEditor', !showCodeTagEditor )}>
        <ModalHeader 
          toggle={() => onChangeSectionEditorState( 'showCodeTagEditor', !showCodeTagEditor )}>
          { Lang[ 'CODE_EDITOR' ][ lang ] }
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={ e => e.preventDefault() }>
            <FormGroup>
              <ListStyle
                lang={ lang }
                isShowListStyle={
                  this.state.contentText[ lang ] &&
                  this.state.contentText[ lang ].includes( '<ul' )
                }
                listStyle={ this.state.listStyle }
                updateListStyle={ val => {
                  this.setState({ listStyle: val })
                }}
              />
              <CKEditor5
                disableAutoInline
                data={ this.state.contentText }
                onChange={ ( data ) => {
                  this.setState({ contentText: data })
                }}
                selectedLanguage={ lang }
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              let tmp = _.cloneDeep( this.props.selectedEditableComponent )
              tmp.content = this.state.contentText
              onUpdateRTEContentWithListStyle(
                {
                  id: selectedEditableComponent.id,
                  type: selectedEditableComponent.type
                },
                {
                  contentText: this.state.contentText,
                  listStyle: this.state.listStyle
                }
              )
              onChangeSectionEditorState( 'showCodeTagEditor', false )
            }}>
            { Lang[ 'SAVE' ][ lang ] }
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default CodeTagEditor