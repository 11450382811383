import React from 'react'
import { Paper } from '@material-ui/core'

const Card = ({
  children
}) => {
  return (
    <Paper style={{ width: 'fit-content', minWidth: '100%', padding: '15px', textAlign: 'left' }}>
      { children }
    </Paper>
  )
}

export default Card