import React, { Component } from 'react'
import {
  Button, UncontrolledTooltip, Table,
  Modal, ModalBody, ModalHeader, ModalFooter,
  Card, CardBody
} from 'reactstrap'
import ReactTable from "react-table"
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PageTitle from 'components/PageTitle'
import CategoryForm from './components/categoryForm'
import CategoryHOC from './actions/category'
import DeleteConfirmationDialog from 'components/Modal/delete'

import Lang from 'Lang/General'

class SectionCategories extends Component {

  componentDidMount = () => this.props.getCategories()

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Site Template Config' })
      return tmpModules.is_create
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Site Template Config' })
      return tmpModules.is_update
    }
  }

  handleSecurityModulesDelete = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Site Template Config' })
      return tmpModules.is_delete
    }
  }

  renderCreateCategory = () => {
    return (
      <Modal
        isOpen={ this.props.showCategoryModal }
        size="xl">
        <ModalHeader>{ Lang[ 'CREATE_CATEGORY' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <CategoryForm
            onChangeCategoryData={ this.props.onChangeCategoryData }
            data={ this.props.newCategory }
            errorMessage={ this.props.errorMessage } />
          <Button className="mb-2 mr-2 mt-4" color="info" onClick={ () => this.props.postCategory( this.props.newCategory ) } >
            { Lang[ 'CREATE' ][ this.props.data.languageReducer.lang ] }
          </Button>
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.handleCategoryModal( false ) }>
            { Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderEditCategory = () => {
    return (
      <Modal
        isOpen={ this.props.showCategoryModal }
        size="xl">
        <ModalHeader>{ Lang[ 'EDIT_CATEGORY' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <CategoryForm
            onChangeCategoryData={ this.props.onChangeCategoryData }
            data={ this.props.selectedCategory }
            errorMessage={ this.props.errorMessage } />
          <Button className="mb-2 mr-2 mt-4" color="info" onClick={ () => this.props.updateCategory( this.props.selectedCategory.id, this.props.selectedCategory ) }>
            { Lang[ 'SAVE' ][ this.props.data.languageReducer.lang ] }
          </Button>
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.handleCategoryModal( false ) }>
            { Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderViewCategory = () => {
    return (
      <span className="d-inline-block mb-2 mr-2">
        <Modal isOpen={ this.props.showCategoryModal } size="lg">
          <ModalHeader>{ Lang[ 'VIEW_CATEGORY' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
          <ModalBody>
            <div className="scroll-area-lg">
              <Table className="mb-0" bordered>
                <tbody>
                  <tr>
                    <th scope="row">{ Lang[ 'NAME' ][ this.props.data.languageReducer.lang ] }</th>
                    <td>
                      {
                        !_.isEmpty( this.props.selectedCategory ) && this.props.selectedCategory.name[ this.props.data.languageReducer.lang ]
                      }
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={ () => this.props.handleCategoryModal( false, 'view' ) }>OK</Button>
            {
              this.handleSecurityModulesUpdate() && (
                <Button
                  color="alternate"
                  onClick={ () => {
                    this.props.getSelectedCategory( this.props.selectedCategory.id )
                    this.props.handleCategoryModal( true, 'edit' )
                  }}>{ Lang[ 'EDIT_CATEGORY' ][ this.props.data.languageReducer.lang ] }</Button>
              )
            }
          </ModalFooter>
        </Modal>
      </span>
    )
  }

  render = () => {
    return(
      <>
        <PageTitle
          heading={ Lang[ 'SECTION_CATEGORIES' ][ this.props.data.languageReducer.lang ] }
          icon="pe-7s-network icon-gradient bg-happy-fisher"
          buttons={[
            {
              buttonText: Lang[ 'CREATE_CATEGORY' ][ this.props.data.languageReducer.lang ],
              onClick: () => this.props.handleCategoryModal( true, 'create' ),
              display: this.handleSecurityModulesCreate()
            }
          ]}/>
        <Card>
          <CardBody>
            <ReactTable
              data={ this.props.categories }
              NoDataComponent={ () => <span></span> }
              columns={[
                {
                  Header: Lang[ 'NAME' ][ this.props.data.languageReducer.lang ],
                  Cell: ( row ) => row.original.name[ this.props.data.languageReducer.lang ]
                },
                {
                  Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
                  accessor: "id",
                  Cell: ({value}) => (
                    <div>
                      <Button
                        id={ `Category-${ value }-view-category` } 
                        className="mb-2 mr-2 btn-icon btn-icon-only" 
                        color="primary" 
                        onClick={ () => {
                          this.props.getSelectedCategory( value )
                          this.props.handleCategoryModal( true, 'view' )
                        }}>
                        <i className="pe-7s-look"> </i>                      
                      </Button>
                      <UncontrolledTooltip
                        target={ `Category-${ value }-view-category` } fade={false}
                        placement="top">{ Lang[ 'VIEW_CATEGORY' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                      {
                        this.handleSecurityModulesUpdate() && (
                          <>
                            <Button
                              id={ `Category-${ value }-update-category` } 
                              className="mb-2 mr-2 btn-icon btn-icon-only" 
                              color="primary" 
                              onClick={ () => {
                                this.props.getSelectedCategory( value )
                                this.props.handleCategoryModal( true, 'edit' )
                              }}>
                              <i className="pe-7s-note"> </i>
                            </Button>
                            <UncontrolledTooltip
                              target={ `Category-${ value }-update-category` } fade={false}
                              placement="top">{ Lang[ 'UPDATE_CATEGORY' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                          </>
                        )
                      }
                      {
                        this.handleSecurityModulesDelete() && (
                          <>
                            <Button 
                              id={ `Category-${ value }-archive-category` }
                              className="mb-2 mr-2 btn-icon btn-icon-only" 
                              color="danger" 
                              onClick={ () => {
                                this.props.getSelectedCategory( value )
                                this.props.onChangeCategoryHOC( 'showDeleteModal', true )
                              }}>
                              <i className="pe-7s-trash"> </i>
                            </Button>
                            <UncontrolledTooltip
                              target={ `Category-${ value }-archive-category` } fade={false} 
                              placement="top">{ Lang[ 'DELETE_CATEGORY' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                          </>
                        )
                      }
                    </div>
                  )
                }
              ]}
              showPagination={ true }
              className="-striped -highlight" 
            />
          </CardBody>
        </Card>
        { this.props.mode === 'create' && this.renderCreateCategory() }
        { this.props.mode === 'edit' && this.renderEditCategory() }
        { this.props.mode === 'view' && this.renderViewCategory() }
        {
          this.props.showDeleteModal && (
            <DeleteConfirmationDialog
              deleteOpen={ this.props.showDeleteModal }
              handleModalClose={ () => this.props.onChangeCategoryHOC( 'showDeleteModal', false ) }
              confirmAction={ () => {
                if( this.props.selectedCategory.id ){
                  this.props.deleteCategory( this.props.selectedCategory.id )
                }
                this.props.onChangeCategoryHOC( 'showDeleteModal', false )
              }}
              content={ Lang[ 'DELETE_CONFIRMATION_CATEGORY' ][ this.props.data.languageReducer.lang ] }
              lang={ this.props.data.languageReducer.lang }/>
          )
        }
        {
          ( this.props.data.ajaxReducer.ajaxCallProgress > 0 || this.props.onLoadCategory ) && <LoadingOverlay />
        }
      </>
    )
  }
}

export default CategoryHOC( SectionCategories )
