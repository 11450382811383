import React from 'react'
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input
} from 'reactstrap'
import uniqid from 'uniqid'
import _ from 'lodash'

import ColorPickerInput from 'components/ColorPicker'
import ButtonStylings from './utils/ButtonStylings'

import { getBorderStyling, getButtonColorType, getModifiedColorTypeStyling } from './utils'
import { patchIdsChildren } from 'utils/uniqueIds'
import Lang from 'Lang/General'

const ButtonType = [
  {
    value: 'basic-button',
    label: Lang[ 'BASIC' ],
  },
  {
    value: 'outlined-button',
    label: Lang[ 'OUTLINED' ],
  },
  {
    value: 'slanted-button',
    label: Lang[ 'SLANTED' ],
  },
  {
    value: 'border-top-animated-button',
    label: Lang[ 'BORDER_TO_TOP_ANIMATION' ],
  },
  {
    value: 'left-right-reverse-animated-button-v2',
    label: Lang[ 'BACKGROUND_SHRINKS_ANIMATION' ],
  },
  {
    value: 'left-right-animated-button-v2',
    label: Lang[ 'BACKGROUND_SPREAD_ANIMATION' ],
  },
  {
    value: 'outline-animated-button',
    label: Lang[ 'OUTLINE_ANIMATION' ],
  },
  {
    value: 'slant-animated-button',
    label: Lang[ 'SLANTED_ANIMATION' ],
  },
  {
    value: 'round-button',
    label: Lang[ 'ROUND_BUTTON' ]
  }
]

const ButtonSettings = props => {
  const {
    selectedLanguage,
    selectedContainer,
    components,
    onChangeButton,
    selectedParent
  } = props

  const onChangeButtonStyle = ( key, val, isHover, isParent ) => {
    let tmp = _.cloneDeep( isParent ? selectedParent : selectedContainer )
    if( isHover ){
      tmp[ 'style' ][ ':hover' ][ key ] = val
    } else {
      tmp[ 'style' ][ key ] = val
    }
    isParent ? onChangeButton( selectedContainer, tmp ) : onChangeButton( tmp )
  }
  
  const onChangeButtonColorType = ( type, key, isHover, isParent ) => {
    let tmp = _.cloneDeep( isParent ? selectedParent : selectedContainer )
    let tmpStyle = _.cloneDeep( isHover ? tmp[ 'style' ][ ':hover' ] : tmp[ 'style' ] )
    tmpStyle = getModifiedColorTypeStyling( type, tmpStyle, key )
    if( isHover ){
      tmp[ 'style' ][ ':hover' ] = tmpStyle
    } else {
      tmp[ 'style' ] = tmpStyle
    }
    isParent ? onChangeButton( selectedContainer, tmp ) : onChangeButton( tmp )
  }

  const onChangeCustomAnimationStyle = ( key, value ) => {
    let tmp = _.cloneDeep( selectedContainer )
    if( !tmp.customAnimationProps ){
      tmp.customAnimationProps = {}
    }
    tmp.customAnimationProps[ key ] = value
    tmp.customAnimationStyle = `#${ selectedContainer.id }:${ selectedContainer.className?.indexOf('vital') > -1 ? 'after' : 'before' }${ JSON.stringify( tmp.customAnimationProps ).replace( /"|[|]/g,'' ).replaceAll( ',', ';' )}`
    onChangeButton( tmp )
  }

  const onChangeCustomAnimationColorType = ( type, key ) => {
    let tmp = _.cloneDeep( selectedContainer )
    if( !tmp.customAnimationProps ){
      tmp.customAnimationProps = {}
    }
    if( !tmp.customAnimationProps[ "background-color" ] ){
      tmp.customAnimationProps[ "background-color" ] = "#ffffff"
    }
    tmp.customAnimationProps = getModifiedColorTypeStyling( type, tmp.customAnimationProps, key )
    tmp.customAnimationProps = _.omitBy( tmp.customAnimationProps, _.isEmpty )
    tmp.customAnimationStyle = `#${ selectedContainer.id }:${ selectedContainer.className?.indexOf('vital') > -1 ? 'after' : 'before' }${ JSON.stringify( tmp.customAnimationProps ).replace( /"|[|]/g,'' ).replaceAll( ',', ';' )}`
    onChangeButton( tmp )
  }

  return (
    <>
      <Col md={ 12 }>
        <FormGroup>
          <Label>{ Lang[ 'BUTTON_TYPE' ][ selectedLanguage ] }</Label>
          <Input  
            type='select'
            value={ selectedContainer.className || 'custom' }
            onChange={ e => {
              if( !_.isEmpty( e.target.value ) ){
                let tmpParent = _.cloneDeep( selectedParent )
                let tmpButton = _.cloneDeep( selectedContainer )
                let isCustom = _.findIndex( ButtonType, { value: selectedContainer.className } ) === -1
                if( e.target.value === "custom" ){
                  let tmpIndex = _.findIndex( tmpParent.children, { id: selectedContainer.id } )
                  let tmpDefaultType = tmpParent.children[ tmpIndex + 1 ].type
                  if( tmpDefaultType.indexOf( 'default-' ) > -1 ){
                    tmpParent.children[ tmpIndex + 1 ].type = tmpParent.children[ tmpIndex + 1 ].type.slice( 8 )
                    tmpButton = _.cloneDeep( tmpParent.children[ tmpIndex + 1 ] )
                    tmpParent.children.splice( tmpIndex, 1 )
                    if( tmpParent.style ){
                      _.map( tmpParent.style, ( value, key ) => {
                        if( [ 'defaultbackground', 'defaultprimaryColor', 'defaultsecondaryColor' ].includes( key ) ){
                          tmpParent.style[ key.slice( 7 ) ] = value
                          delete tmpParent.style[ key ]
                        }
                      })
                    }
                  }
                } else {
                  if( [ 'basic-button', 'outlined-button', 'slanted-button' ].indexOf( e.target.value ) > -1 ){
                    tmpButton.className = e.target.value
                    tmpButton.style = _.cloneDeep( ButtonStylings[ e.target.value ][ 'button_style' ] )
                    if( tmpButton.type === 'a' ){
                      tmpButton.style[ 'textDecoration' ] = 'none'
                      tmpButton.style[ 'cursor' ] = 'pointer'
                    }
                    if( tmpButton.children && tmpButton.children[ 0 ] ) {
                      tmpButton.children[ 0 ].style = _.cloneDeep( ButtonStylings[ e.target.value ][ 'text_style' ] )
                    } else {
                      tmpButton.children = []
                      tmpButton.children.push({
                        id: uniqid(),
                        type: 'div',
                        variant: 'text',
                        style: _.cloneDeep( ButtonStylings[ e.target.value ][ 'text_style' ] ),
                        content: tmpButton.content || ({
                          en: "<div>Button text</div>",
                          de: "<div>Button text</div>",
                          it: "<div>Button text</div>",
                          nl: "<div>Button text</div>"
                        })
                      })
                    }
                  } else {
                    let buttonTemplate = _.find( components, item => item.content.className === e.target.value )
                    if( buttonTemplate ){
                      tmpButton.className = buttonTemplate.content.className
                      tmpButton.style = buttonTemplate.content.style
                      if( tmpButton.type === 'a' ){
                        tmpButton.style[ 'textDecoration' ] = 'none'
                        tmpButton.style[ 'cursor' ] = 'pointer'
                      }
                      if( tmpButton.children && tmpButton.children[ 0 ] ){
                        tmpButton.children[ 0 ].style = buttonTemplate.content.children[ 0 ].style
                      } else{
                        tmpButton.children = []
                        tmpButton.children.push({
                          ...buttonTemplate.content.children[ 0 ],
                          id: uniqid()
                        })
                      }
                    }
                  }
                  if( selectedContainer.style?.position === 'absolute' ){
                    tmpButton.style.position = 'absolute'
                    Object.keys( selectedContainer.style ).map( key => {
                      if([ 'top', 'bottom', 'left', 'right' ].includes( key )){
                        tmpButton.style[ key ] = selectedContainer.style[ key ]
                      }
                    })
                  }
                  if( isCustom ){
                    let tmpIndex = _.findIndex( tmpParent.children, { id: selectedContainer.id } )
                    tmpParent.children[ tmpIndex ].type = 'default-' + tmpParent.children[ tmpIndex ].type
                    patchIdsChildren( tmpButton )
                    tmpParent.children.splice( tmpIndex, 0, tmpButton )
                    if( selectedContainer.style?.background === 'inherit' ){
                      Object.keys( tmpParent.style ).map( key => {
                        if( [ 'background', 'primaryColor', 'secondaryColor' ].includes( key ) ){
                          tmpParent.style[ 'default' + key ] = tmpParent.style[ key ]
                          delete tmpParent.style[ key ]
                        }
                      })
                    }
                  }
                }
                if( tmpButton.content ){
                  delete tmpButton[ 'content' ]
                }
                if( isCustom || e.target.value === 'custom' ){
                  onChangeButton( tmpButton, tmpParent )
                } else {
                  onChangeButton( tmpButton )
                }
              }
            }}>
            { 
              ( selectedParent && selectedParent.children && selectedParent.children[ _.findIndex( selectedParent.children, { id: selectedContainer.id } ) + 1 ]?.type.indexOf( 'default-' ) > -1 ||
                _.findIndex( ButtonType, { value: selectedContainer.className } ) === -1 ) && (
                <option value='custom'>{ Lang[ 'CUSTOM' ][ selectedLanguage ] }</option> 
              )
            }
            {
              ButtonType.map( button => (
                <option value={ button.value }>{ button.label[ selectedLanguage ] }</option>
              ))
            }
          </Input>
        </FormGroup>
      </Col>
      <Col md={ 12 }>
        <FormGroup>
          <Row>
            {
              selectedContainer.style && _.map(
                _.reduce( 
                  selectedContainer.style, 
                  ( result, value, key ) => ({ 
                    ...result,
                    ...( 
                      typeof value === 'string' && value.indexOf( '#' ) > -1 && key.indexOf( 'border' ) === -1
                        ? { [ key ] : value } 
                        : {} 
                    )
                  }),
                  {}
                ), 
                ( value, key ) => {
                  return <ColorPickerInput
                    id={ `${ selectedContainer.id }-${ key }-` }
                    size={ 4 }
                    label={ Lang[ key.toUpperCase() ][ selectedLanguage ] }
                    color={ value.match( /#[^ ]*\b/g )[ 0 ] || '#ffffff' }
                    colorType={ getButtonColorType( selectedContainer.style, key ) }
                    selectedLanguage={ selectedLanguage }
                    onChange={ col => onChangeButtonStyle( key, value.replace( /#[^ ]*\b/g, col.hex ), false ) } 
                    onChangeColorType={ type => onChangeButtonColorType( type, key, false ) } 
                  />
                }
              )
            }
            {
              selectedContainer.style?.background === 'inherit' && selectedParent.style?.background && (
                <ColorPickerInput
                  id={ `${ selectedParent.id }-background-` }
                  size={ 4 }
                  label={ Lang[ 'BACKGROUND' ][ selectedLanguage ] }
                  color={ selectedParent.style.background.match( /#[^ ]*\b/g )[ 0 ] || '#ffffff' }
                  colorType={ getButtonColorType( selectedParent.style, 'background' ) }
                  selectedLanguage={ selectedLanguage }
                  onChange={ col => onChangeButtonStyle( 'background', selectedParent.style.background.replace( /#[^ ]*\b/g, col.hex ), false, true ) } 
                  onChangeColorType={ type => onChangeButtonColorType( type, 'background', false, true ) } 
                />
              )
            }
            {
              selectedContainer.style &&
              !_.isEmpty( getBorderStyling( selectedContainer.style ) ) && (
                <ColorPickerInput
                  id={ `${ selectedContainer.id }-border-` }
                  size={ 4 }
                  label={ Lang[ 'BORDER' ][ selectedLanguage ] }
                  color={ 
                    Object.values( getBorderStyling( selectedContainer.style ) )[ 0 ].match( /#[^ ]*\b/g )[ 0 ] || 
                    '#ffffff' 
                  }
                  colorType={ 
                    getButtonColorType( 
                      selectedContainer.style, 
                      Object.keys( getBorderStyling( selectedContainer.style ) )[ 0 ] 
                    ) 
                  }
                  selectedLanguage={ selectedLanguage }
                  onChange={ col => {
                    let tmp = _.cloneDeep( selectedContainer )
                    _.map( getBorderStyling( tmp.style ), ( value, key ) => {
                      tmp[ 'style' ][ key ] = value.replace( /#[^ ]*\b/g, col.hex )
                    })
                    onChangeButton( tmp )
                  }}
                  onChangeColorType={ type => {
                    let tmp = _.cloneDeep( selectedContainer )
                    let tmpStyle = _.cloneDeep( tmp[ 'style' ] )
                    _.map( getBorderStyling( tmp[ 'style' ] ), ( value, key ) => {
                      tmpStyle = getModifiedColorTypeStyling( type, tmpStyle, key )
                    })
                    tmp[ 'style' ] = tmpStyle
                    onChangeButton( tmp )
                  }}  
                />
              )
            }
          </Row>
        </FormGroup>
      </Col>
      <Col md={ 12 }>
        <FormGroup>
          <Label>{ Lang[ 'BUTTON_HOVER_COLOR' ][ selectedLanguage ] }</Label>
          <Row>
            {
              selectedContainer.style && selectedContainer.style[ ':hover' ] && _.map(
                _.reduce( 
                  selectedContainer.style[ ':hover' ], 
                  ( result, value, key ) => ({ 
                    ...result,
                    ...( 
                      typeof value === 'string' && value.indexOf( '#' ) > -1 && key.indexOf( 'border' ) === -1
                        ? { [ key ] : value } 
                        : {} 
                    )
                  }),
                  {}
                ), 
                ( value, key ) => {
                  return <ColorPickerInput
                    id={ `${ selectedContainer.id }-${ key }-hover-` }
                    size={ 4 }
                    label={ Lang[ key.toUpperCase() ][ selectedLanguage ] }
                    color={ value.match( /#[^ ]*\b/g )[ 0 ] || '#ffffff' }
                    colorType={ getButtonColorType( selectedContainer.style[ ':hover' ], key ) }
                    selectedLanguage={ selectedLanguage }
                    onChange={ col => onChangeButtonStyle( key, value.replace( /#[^ ]*\b/g, col.hex ), true ) }
                    onChangeColorType={ type => onChangeButtonColorType( type, key, true ) } 
                  />
                }
              )
            }
            {
              selectedContainer.className?.indexOf( 'button-hover-animation' ) > -1 && (
                <ColorPickerInput
                  id={ `${ selectedContainer.id }-background-hover-` }
                  size={ 4 }
                  label={ Lang[ 'BACKGROUND' ][ selectedLanguage ] }
                  color={ ( selectedContainer.customAnimationProps && selectedContainer.customAnimationProps[ 'background-color' ] ) || '#ffffff' }
                  colorType={ selectedContainer.customAnimationProps ? getButtonColorType( selectedContainer.customAnimationProps, 'background-color' ) : 'custom' }
                  selectedLanguage={ selectedLanguage }
                  onChange={ col => onChangeCustomAnimationStyle( 'background-color', col.hex ) }
                  onChangeColorType={ type => onChangeCustomAnimationColorType( type, 'background-color' ) } 
                />
              )
            }
            {
              selectedContainer.style &&
              selectedContainer.style[ ':hover' ] &&
              !_.isEmpty( getBorderStyling( selectedContainer.style[ ':hover' ] ) ) && (
                <ColorPickerInput
                  id={ `${ selectedContainer.id }-border-hover-` }
                  size={ 4 }
                  label={ Lang[ 'BORDER' ][ selectedLanguage ] }
                  color={ 
                    Object.values( getBorderStyling( selectedContainer.style[ ':hover' ] ) )[ 0 ].match( /#[^ ]*\b/g )[ 0 ] || 
                    '#ffffff' 
                  }
                  colorType={ 
                    getButtonColorType( 
                      selectedContainer.style[ ':hover' ], 
                      Object.keys( getBorderStyling( selectedContainer.style[ ':hover' ] ) )[ 0 ] 
                    ) 
                  }
                  selectedLanguage={ selectedLanguage }
                  onChange={ col => {
                    let tmp = _.cloneDeep( selectedContainer )
                    _.map( getBorderStyling( tmp.style[ ':hover' ] ), ( value, key ) => {
                      tmp[ 'style' ][ ':hover' ][ key ] = value.replace( /#[^ ]*\b/g, col.hex )
                    })
                    onChangeButton( tmp )
                  }} 
                  onChangeColorType={ type => {
                    let tmp = _.cloneDeep( selectedContainer )
                    let tmpStyle = _.cloneDeep( tmp[ 'style' ][ ':hover' ] )
                    _.map( getBorderStyling( tmp[ 'style' ][ ':hover' ] ), ( value, key ) => {
                      tmpStyle = getModifiedColorTypeStyling( type, tmpStyle, key )
                    })
                    tmp[ 'style' ][ ':hover' ] = tmpStyle
                    onChangeButton( tmp )
                  }} 
                />
              )
            }
          </Row>
        </FormGroup>
      </Col>
    </>
  )
}

export default ButtonSettings
