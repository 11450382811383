import React, { Component } from 'react'
import { Route, Switch } from "react-router-dom"
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import ResizeDetector from 'react-resize-detector'
import cx from 'classnames'
import _ from 'lodash'
import 'moment/locale/de'
import 'moment/locale/nl'
import 'moment/locale/it'

import Client from 'containers/Client'
import Consultant from 'containers/Consultant'
import Center from 'containers/Center'
import CenterGroup from 'containers/CenterGroup'
import CenterReport from 'containers/CenterReport'
import Module from 'containers/Module'
import Package from 'containers/Package'
import Order from 'containers/Order'
import SiteTemplateSettings from 'containers/SiteTemplateSettings'
import SiteSectionSettings from 'containers/SiteSectionSettings'
import SiteSectionCategories from 'containers/SiteSectionSettings/categoriesListing'
import MediaDB from 'containers/Media'
import AllContent from 'containers/ContentService'
import ContentCategories from 'containers/ContentService/categoriesListing'
import NewsletterTemplate from 'containers/NewsletterTemplate'
import Platform from 'containers/Platform'
import RolePermission from 'containers/RolePermission'
import OLSCampaignTypes from 'containers/OLS/CampaignTypes'
import OLSCampaignListings from 'containers/OLS/CampaignListings'
import OLSSettings from 'containers/OLS/Settings'
import OLSBookedCampaigns from 'containers/OLS/BookedCampaign'
import OLSLandingPageList from 'containers/OLS/LandingPage/Page'
import OLSLandingPageForm from 'containers/OLS/LandingPage/Form'
import UsersManagement from 'containers/UsersManagement'
import DomainsListings from 'containers/Domains'
import FaqCategories from 'containers/OnlineShop/FaqCategories'
import FaqListings from 'containers/OnlineShop/FaqListings'
import Currencies from 'containers/OnlineShop/Currencies'
import CampaignTools from 'containers/OnlineShop/CampaignTools'
import OmBillings from 'containers/OnlineShop/Billings'
import VoucherReport from 'containers/OnlineShop/VoucherReport'
import DashboardSettings from 'containers/DashboardSettings'
import Dashboard from 'containers/Dashboard'
import SocialMedia from 'containers/SocialMedia'
import DigitalOrderForm from 'containers/DigitalOrderForm'
import OLSTeamClient from 'containers/Client/OlsTeamClients'
import ArkMFiles from 'containers/ArkMFiles'
import PlatformSettings from 'containers/PlatformSettings'
import MemberAreaMembers from 'containers/MemberArea/Members'
import MemberAreaRoles from 'containers/MemberArea/Roles'
import MemberAreaStatus from 'containers/MemberArea/MemberStatus'
import MemberAreaEventTypesSettings from 'containers/MemberArea/Event/TypesSettings'
import MemberAreaEventTemplates from 'containers/MemberArea/Event/Templates'
import MemberAreaProgramModulesSettings from 'containers/MemberArea/Program/ModulesSettings'
import MemberAreaProgramTemplates from 'containers/MemberArea/Program/Templates'
import MemberAreaCentersProgramTemplates from 'containers/MemberArea/Program/CenterPurchasedTemplates'
import MemberAreaTags from 'containers/MemberArea/ContentSettings/Tags'
import MemberAreaCategories from 'containers/MemberArea/ContentSettings/Categories'
import MemberAreaOnDemandCategories from 'containers/MemberArea/ContentSettings/OnDemandCategories'
import MemberAreaTips from 'containers/MemberArea/Tips'
import MemberAreaKnowledge from 'containers/MemberArea/Knowledge'
import MarketingFunnels from 'containers/MarketingFunnels'
import MemberAreaFaqListing from 'containers/MemberArea/FaqListings'
import MemberAreaFaqCategories from 'containers/MemberArea/FaqCategories'
import EmailTemplateEditor from 'containers/EmailTemplateEditor'
import ArticleFourteen from 'containers/GDPRCenter/ArticleFourteen'
import AVContract from 'containers/GDPRCenter/AVContract'
import PrivacyPolicy from 'containers/GDPRCenter/PrivacyPolicy'
import Imprint from 'containers/GDPRCenter/Imprint'
import EmailQuotaSettings from 'containers/EmailQuotaSettings'
import SslSetting from 'containers/SslSetting'

import TemplateHeader from 'components/Header'
import TemplateSidemenu from 'components/Menu/Sidemenu'
import { ApplyBranding } from 'utils/apply-color'

import { getUserProfile } from 'actions/profile'
import { getDictionary } from 'actions/dictionary'
import { getSelectedRole } from 'actions/role'
import { getUserSetting } from 'actions/language'

import AxiosInterceptors from 'Sec/AxiosInterceptors' // do not remove this

import 'stylesheets/base.scss'

class AdminPanel extends Component {
  state = {
    closedSmallerSidebar: false
  }

  componentDidMount = () => {
    this.props.getDictionary()
    this.props.getSelectedRole( this.props.data.profileReducer.profile.role_id )
    this.props.getUserSetting()
  }

  onClickToggleProfileModal = () => {}

  render = () => {
    let {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
      enablePageTabsAlt,
    } = this.props
    return (
      <>
        {
          !_.isEmpty( this.props.data.platformConfigReducer.platformConfig )
          && _.find( this.props.data.platformConfigReducer.platformConfig, { key: 'PLATFORM_COLOR' } )
          && <ApplyBranding colors={{ primary: _.find( this.props.data.platformConfigReducer.platformConfig, { key: 'PLATFORM_COLOR' } ).value }} />
        }
        <ResizeDetector
          handleWidth
          render={({ width }) => (
            <>
              <div
                className={cx(
                  "app-container app-theme-" + colorScheme,
                  {'fixed-header': enableFixedHeader},
                  {'fixed-sidebar': enableFixedSidebar || width < 1250},
                  {'fixed-footer': enableFixedFooter},
                  {'closed-sidebar': enableClosedSidebar || width < 1250},
                  {'closed-sidebar-mobile': closedSmallerSidebar || width < 1250},
                  {'sidebar-mobile-open': enableMobileMenu},
                  {'body-tabs-shadow-btn': enablePageTabsAlt},
                )}>
                <TemplateHeader
                  history={ this.props.history }
                  data={ this.props.data }
                  onClickToggleProfileModal={ this.onClickToggleProfileModal }
                  profile={ this.props.data.profileReducer.profile } />
                <div className="app-main">
                  {
                    !_.isEmpty( this.props.data.roleReducer.role ) && (
                      <TemplateSidemenu role={ this.props.data.roleReducer.role } selectedLanguage={this.props.data.languageReducer}/>
                    )
                  }
                  <div className="app-main__outer">
                    <div className="app-main__inner">
                      <Switch>
                        <Route exact path="/dashboard" component={ Dashboard } />
                        <Route exact path="/dashboard/client" component={ Client } />
                        <Route exact path="/dashboard/consultant" component={ Consultant }/>
                        <Route exact path="/dashboard/center" component={ Center }/>
                        <Route exact path="/dashboard/center-group" component={ CenterGroup }/>
                        <Route exact path="/dashboard/center-report" component={ CenterReport }/>
                        <Route exact path="/dashboard/module" component={ Module }/>
                        <Route exact path="/dashboard/site-template-settings" component={ SiteTemplateSettings } />
                        <Route exact path="/dashboard/site-section-settings" component={ SiteSectionSettings } />
                        <Route exact path="/dashboard/section-categories" component={ SiteSectionCategories } />
                        <Route exact path="/dashboard/package" component={ Package }/>
                        <Route exact path="/dashboard/order" component={ Order }/>
                        <Route exact path="/dashboard/media" component={ MediaDB } />
                        <Route exact path="/dashboard/content-service" component={ AllContent }/>
                        <Route exact path="/dashboard/content/category" component={ ContentCategories } />
                        <Route exact path="/dashboard/newsletter-template" component={ NewsletterTemplate } />
                        <Route exact path="/dashboard/platform" component={ Platform } />
                        <Route exact path="/dashboard/role-permissions" component={ RolePermission } />
                        <Route exact path="/dashboard/ols-campaign-types" component={ OLSCampaignTypes } />
                        <Route exact path="/dashboard/ols-campaign-listings" component={ OLSCampaignListings } />
                        <Route exact path="/dashboard/ols-settings" component={ OLSSettings } />
                        <Route exact path="/dashboard/ols-booked-campaign" component={ OLSBookedCampaigns } />
                        <Route exact path="/dashboard/ols-landing-page/form" component={ OLSLandingPageForm } />
                        <Route exact path="/dashboard/ols-landing-page/page" component={ OLSLandingPageList } />
                        <Route exact path="/dashboard/users-management" component={ UsersManagement } />
                        <Route exact path="/dashboard/domains" component={ DomainsListings } />
                        <Route exact path="/dashboard/online-shop/faq-categories" component={ FaqCategories } />
                        <Route exact path="/dashboard/online-shop/faq-listings" component={ FaqListings } />
                        <Route exact path="/dashboard/currencies" component={ Currencies } />
                        <Route exact path="/dashboard/campaign-tools" component={ CampaignTools } />
                        <Route exact path="/dashboard/billings" component={ OmBillings } />
                        <Route exact path="/dashboard/voucher-report" component={ VoucherReport } />
                        <Route exact path="/dashboard/social-media" component={ SocialMedia } />
                        <Route exact path="/dashboard/ols-team-client" component={ OLSTeamClient } />
                        <Route exact path="/dashboard/ark-m-files" component={ ArkMFiles } />
                        <Route exact path="/dashboard/platform-settings" component={ PlatformSettings } />
                        <Route exact path="/dashboard/member-area/faq-listings" component={ MemberAreaFaqListing }/>
                        <Route exact path="/dashboard/member-area/faq-categories" component={ MemberAreaFaqCategories }/>
                        <Route exact path="/dashboard/member-area/members" component={ MemberAreaMembers } />
                        <Route exact path="/dashboard/member-area/roles" component={ MemberAreaRoles } />
                        <Route exact path="/dashboard/member-area/member-status" component={ MemberAreaStatus } />
                        <Route exact path="/dashboard/member-area/event-types-settings" component={ MemberAreaEventTypesSettings } />
                        <Route exact path="/dashboard/member-area/event-templates" component={ MemberAreaEventTemplates } />
                        <Route exact path="/dashboard/member-area/program-modules-settings" component={ MemberAreaProgramModulesSettings } />
                        <Route exact path="/dashboard/member-area/program-templates" component={ MemberAreaProgramTemplates } />
                        <Route exact path="/dashboard/member-area/centers-program-templates" component={ MemberAreaCentersProgramTemplates } />
                        <Route exact path="/dashboard/member-area/tips" component={ MemberAreaTips } />
                        <Route exact path="/dashboard/member-area/knowledge" component={ MemberAreaKnowledge } />
                        <Route exact path="/dashboard/member-area/content-tags-settings" component={ MemberAreaTags } />
                        <Route exact path="/dashboard/member-area/content-demand-categories-settings" component={ MemberAreaOnDemandCategories } />
                        <Route exact path="/dashboard/member-area/content-categories" component={ MemberAreaCategories } />
                        <Route exact path="/dashboard/article-14" component={ ArticleFourteen } />
                        <Route exact path="/dashboard/av-contract" component={ AVContract } />
                        <Route exact path="/dashboard/privacy-policy" component={ PrivacyPolicy } />
                        <Route exact path="/dashboard/imprint" component={ Imprint } />
                        <Route exact path="/dashboard/email-template-editor" component={ EmailTemplateEditor } />
                        <Route path="/dashboard/digital-order-form" component={ DigitalOrderForm } />
                        <Route path="/dashboard/marketing-funnels" component={ MarketingFunnels } />
                        <Route path="/dashboard/settings" component={ DashboardSettings } />
                        <Route path="/dashboard/email-quota-settings" component={ EmailQuotaSettings } />
                        <Route path="/dashboard/ssl-setting" component={ SslSetting } />
                      </Switch>
                    </div>
                  </div>
                </div>
              </div>
              <ToastContainer/>
            </>
          )}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
  data: state
})

export default connect( mapStateToProps,{
  getUserProfile,
  getDictionary,
  getSelectedRole,
  getUserSetting
} )( AdminPanel )
