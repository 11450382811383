import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { Post, Get, Put, Delete } from 'utils/axios'
import Lang from 'Lang/General'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state={ 
      loading: false,
      programModules: [],
      newProgramModule: {},
      selectedProgramModule: {},
      showCreateProgramModule: false,
      showUpdateProgramModule: false,
      showDeleteProgramModuleId: 0
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( err => toast.error( err.replaceAll( '_', ' ' ) ) )

    onChangeProgramModulesHOC = ( key, value ) => this.setState({ [ key ] : value })

    createProgramModule = data => Post(
      `/api/v1/ark_member_area/am_program_modules`,
      data,
      this.createProgramModuleSuccess,
      this.createProgramModuleError,
      this.load
    )
    createProgramModuleSuccess = () => {
      this.getProgramModules()
      this.requestSuccess( Lang[ 'PROGRAM_MODULE_IS_SUCCESSFULLY_CREATED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showCreateProgramModule: false })
    }
    createProgramModuleError = error => {
      this.requestError( error )
      this.setState({ showCreateProgramModule: false })
    }

    getProgramModules = () => Get(
      `/api/v1/ark_member_area/am_program_modules`,
      this.getProgramModulesSuccess,
      this.getProgramModulesError,
      this.load
    )
    getProgramModulesSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      this.setState({ programModules: payload, programModulesPages: tmpTotalPages })
    }
    getProgramModulesError = error => this.requestError( error )

    getSelectedProgramModule = programModule_id => Get(
      `/api/v1/ark_member_area/am_program_modules/${ programModule_id }`,
      this.getSelectedProgramModuleSuccess,
      this.getSelectedProgramModuleError,
      this.load
    )
    getSelectedProgramModuleSuccess = payload => this.setState({ selectedProgramModule: payload, showUpdateProgramModule: true })
    getSelectedProgramModuleError = error => this.requestError( error )

    updateProgramModule = data => Put(
      `/api/v1/ark_member_area/am_program_modules/${ data.id }`,
      data,
      this.updateProgramModuleSuccess,
      this.updateProgramModuleError,
      this.load
    )
    updateProgramModuleSuccess = () => {
      this.getProgramModules()
      this.requestSuccess( Lang[ 'PROGRAM_MODULE_IS_SUCCESSFULLY_UPDATED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showUpdateProgramModule: false })
    }
    updateProgramModuleError = error => this.requestError( error )

    removeProgramModule = programModule_id => Delete(
      `/api/v1/ark_member_area/am_program_modules/${ programModule_id }`,
      this.removeProgramModuleSuccess,
      this.removeProgramModuleError,
      this.load
    )
    removeProgramModuleSuccess = () => {
      this.getProgramModules()
      this.requestSuccess( Lang[ 'PROGRAM_MODULE_IS_SUCCESSFULLY_REMOVED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showDeleteProgramModuleId: 0 })
    }
    removeProgramModuleError = error => {
      this.requestError( error )
      this.setState({ showDeleteProgramModuleId: 0 })
    }

    render = () => {
      return(
        <WrappedComponent
          { ...this.props } 
          onLoadProgramModulesHOC={ this.state.loading }
          programModules={ this.state.programModules }
          newProgramModule={ this.state.newProgramModule }
          selectedProgramModule={ this.state.selectedProgramModule }
          showCreateProgramModule={ this.state.showCreateProgramModule }
          showUpdateProgramModule={ this.state.showUpdateProgramModule }
          showDeleteProgramModuleId={ this.state.showDeleteProgramModuleId }
          onChangeProgramModulesHOC={ this.onChangeProgramModulesHOC }
          createProgramModule={ this.createProgramModule }
          getProgramModules={ this.getProgramModules }
          getSelectedProgramModule={ this.getSelectedProgramModule }
          updateProgramModule={ this.updateProgramModule }
          removeProgramModule={ this.removeProgramModule }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC