import React from 'react'
import { StyleRoot } from 'proofpoint-radium'

import CoreRenderFunction from 'containers/TemplateEditor/core/render'

const TemplateForm = props => {
  return (
    <div 
      onClick={ props.onClick }
      style={{ 
        fontWeight: '600', 
        padding: '15px' ,
        position: 'relative'
      }}>
      <div style={{ 
        position: "absolute", 
        top: "0", 
        left: "0", 
        width: "100%", 
        height: "100%", 
        background: "#ffffff80", 
        zIndex: "1" 
      }} />
      <StyleRoot>
        <CoreRenderFunction
          { ...props.item }
          item={ props.item }
          mode={ 'view' }
          selectedLanguageShortname={ props.selectedLanguage } />
      </StyleRoot>
    </div>
  )
}

export default TemplateForm