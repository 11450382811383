import React from 'react'
import Radium, { StyleRoot } from 'proofpoint-radium'
import _ from 'lodash'
import { Helmet } from 'react-helmet'

import CoreRenderFunction from '../core/render'

import Lang from 'Lang/General'

const RendererView = ({
  onChangeSelectedPage,
  templateStylesheets,
  templateJavascripts,
  selectedPage,
  updateMainContainerState,
  selectedSectionID,
  languages,
  selectedLanguageShortname,
  templateHeaders,
  templateHeadersBlueprints,
  templateFooterBlueprints,
  templateFooters,
  headerMenuContent,
  selectedContainer,
  lang,
  hoveredSectionID
}) => {
  return (
    <>
    <link href="https://unpkg.com/ionicons@4.5.10-0/dist/css/ionicons.min.css" rel="stylesheet"/>
      <StyleRoot>
        <div
          style={{
            width: '100%',
            height: '100%'
          }}>
          {
            templateStylesheets && templateStylesheets.length > 0 && templateStylesheets.map( item => {
              if( item.type === 'URL' ) {
                return <link key={ `stylesheet-${ item.id }` } rel="stylesheet" href={ item.content }></link>
              } else {
                return <style key={ `stylesheet-${ item.id }` }>{ item.content }</style>
              }
            })
          }
          {
            selectedPage &&
            !_.isEmpty( selectedPage.content ) &&
            selectedPage.content.children.length > 0 && selectedPage.content.children.map( item => {
              if( item.type !== 'footer-desktop' && item.type !== 'footer-mobile' && item.type !== 'footer' ) {
                return (
                  <CoreRenderFunction
                    item={ item }
                    updateMainContainerState={ updateMainContainerState }
                    selectedSectionID={ selectedSectionID }
                    languages={ languages }
                    selectedLanguageShortname={ selectedLanguageShortname }
                    templateHeaders={ templateHeaders }
                    templateHeadersBlueprints={ templateHeadersBlueprints }
                    templateFooterBlueprints={ templateFooterBlueprints }
                    templateFooters={ templateFooters }
                    headerMenuContent={ headerMenuContent }
                    selectedPage={ selectedPage }
                    onChangeSelectedPage={ onChangeSelectedPage }
                    mode={ 'section-display-edit' }
                    selectedContainer={ selectedContainer }
                    hoveredSectionID={ hoveredSectionID } />
                )
              }
            })
          }
          {
            selectedPage &&
            !_.isEmpty( selectedPage.content ) &&
            selectedPage.content.children.length < 3 && (
              <div style={{ paddingTop: '30px', paddingBottom: '30px' }}>
                <div style={{ textAlign: 'center' }}>
                  <h3 style={{ fontSize: '24px', fontWeight: '300' }}>{ Lang[ 'BLANK_TEMPLATE_PAGE' ][ lang ] }</h3>
                  <h4 style={{ fontWeight: '300' }}>{ Lang[ 'ADD_SOME_SECTION' ][ lang ] }</h4>
                </div>
              </div>
            )
          }
          {
            selectedPage &&
            !_.isEmpty( selectedPage.content ) &&
            selectedPage.content.children.length > 0 && selectedPage.content.children.map( item => {
              if( item.type === 'footer-mobile' || item.type === 'footer-desktop' || item.type === 'footer' ) {
                return (
                  <CoreRenderFunction
                    item={ item }
                    updateMainContainerState={ updateMainContainerState }
                    selectedSectionID={ selectedSectionID }
                    languages={ languages }
                    selectedLanguageShortname={ selectedLanguageShortname }
                    templateHeaders={ templateHeaders }
                    templateHeadersBlueprints={ templateHeadersBlueprints }
                    templateFooterBlueprints={ templateFooterBlueprints }
                    headerMenuContent={ headerMenuContent }
                    selectedPage={ selectedPage }
                    onChangeSelectedPage={ onChangeSelectedPage }
                    mode={ 'section-display-edit' } />
                )
              }
            })
          }
          {
            templateJavascripts && templateJavascripts.length > 0 && templateJavascripts.map( item => {
              if( item.type === 'URL' ) {
                return <script key={ `template-js-${ item.id }` } src={ item.content }></script>
              } else {
                return <Helmet key={ `template-js-${ item.id }` }><script>{ item.content }</script></Helmet>
              }
            })
          }
        </div>
      </StyleRoot>
    </>
  )
}

export default Radium( RendererView )