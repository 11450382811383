import React from 'react'

import CoreRenderFunction from '../../core/render'

const RowComponent = ({
  content,
  mode,
  formValues,
  dom,
  selectedLanguageShortname ,
}) => {
  return (
    <div className="row">
      {
        content.children && content.children.map(( item, index ) => {
          return <CoreRenderFunction
            { ...item }
            rowComponentIndex={ index } 
            item={ item }
            content={ item }
            mode={ mode } 
            formValues={ formValues }
            dom={ dom }
            selectedLanguageShortname ={ selectedLanguageShortname  } />
        })
      }
    </div>
  )
}

export default RowComponent