import React from 'react'
import {
  Row, Col, Card, CardBody, Button
} from 'reactstrap'
import { FaFolder } from 'react-icons/fa'
import _ from 'lodash'
import { connect } from 'react-redux'

import CreateFile from './createFile'
import UpdateFile from './updateFile'
import DeleteConfirmation from 'components/Modal/delete'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'

const UploadFiles = ({
  path,
  getSelectedDriveFolder,
  getSelectedFolder,
  getSelectedMedia,
  mediaDrives,
  onChangeArkMFilesHOC,
  parentFolder,
  showCreateModal,
  showEditModal,
  showDeleteModal,
  newFolderFile,
  selectedFileFolder,
  files,
  createFile,
  createFileFolder,
  updateFile,
  updateFolder,
  deleteFile,
  deleteFolder,
  displayFileTypeImage,
  onLoadMediaDrive,
  selectedLanguage
}) => {
  return(
    <>
      <div className="mt-2 mb-2 p-1 d-flex">
        {
          path.map( ( item, index ) => {
            return(
              <>
                <Button
                  color="link"
                  onClick={ item.nav }>
                  { item.label }
                </Button>
                {
                  index < path.length - 1 && (
                    <p
                      style={{
                        margin: '0px',
                        padding: '5px 0',
                        color: '#545CD8' }} >{' >> '}</p>
                  )
                }
              </>
            )
          })
        }
      </div>
      <Col md={ 12 }>
        <Card>
          <CardBody>
            <Row>
              <Col md={ 4 } style={{ border: '1px solid #eee', padding: '15px' }}>
                <h4 style={{ fontWeight: '300' }}>{ Lang[ 'CURRENT_FOLDER' ][ selectedLanguage ] }</h4>
                <Button color="light" className="d-flex w-100 align-items-center mb-2">
                  <FaFolder size={ '24px' } className="mr-2" />
                  { parentFolder.name }
                </Button>
              </Col>
              <Col md={ 8 } style={{ border: '1px solid #eee', padding: '15px' }}>
                <h4 style={{ fontWeight: '300' }}>{ Lang[ 'MEDIA_INSIDE_FOLDER' ][ selectedLanguage ] }</h4>
                <Row>
                  <Col md={ 12 }>
                    <Button
                      color='primary'
                      className="d-flex w-100 align-items-center justify-content-center mb-2"
                      onClick={ () => onChangeArkMFilesHOC( 'showCreateModal', true ) }>
                      <div className="ml-3">
                        <p style={{ marginBottom: '1px', textAlign: 'left' }}>{ Lang[ 'ADD_FILE_OR_FOLDER' ][ selectedLanguage ] }</p>
                      </div>
                    </Button>
                  </Col>
                  {
                    mediaDrives.items && mediaDrives.items.length > 0 && mediaDrives.items.map( item => {
                      if( item.type !== 'Folder' ) {
                        return(
                          <Col md={ 12 } className="mb-1">
                            <Button color="light" className="d-flex w-100 align-items-center">
                              {
                                item.thumb_url 
                                ? <img src={ item.thumb_url } style={{ width: '50px' }} />
                                : displayFileTypeImage( item.original_url )
                              }
                              <div className="ml-2">
                                <p style={{ marginBottom: '1px', textAlign: 'left' }}>{ item.name }</p>
                              </div>
                              <div className="ml-auto pl-2 d-flex">
                                <Button
                                  color="primary"
                                  className="btn-icon mr-1"
                                  onClick={ () => {
                                    onChangeArkMFilesHOC( 'showEditModal', true )
                                    getSelectedMedia( item.id )
                                  }}>
                                  <i className="pe-7s-note"> </i>
                                </Button>
                                <Button
                                  color="danger"
                                  className="btn-icon mr-1"
                                  onClick={ () => {
                                    onChangeArkMFilesHOC( 'showDeleteModal', true )
                                    getSelectedMedia( item.id )
                                  }}>
                                  <i className="pe-7s-trash"> </i>
                                </Button>
                              </div>
                            </Button>
                          </Col>
                        )
                      }
                      if( item.type === 'Folder' ){
                        return(
                          <Col md={ 12 } className="mb-1"> 
                            <Button color="light" className="d-flex w-100 align-items-center">
                              <div className="pl-2 d-flex"
                                onClick={ () => {
                                  let tmp = _.cloneDeep( path )
                                  getSelectedDriveFolder( item.id )
                                  tmp.push({
                                    label: item.name,
                                    nav: () => {
                                      getSelectedDriveFolder( item.id )
                                      let foundIndex = _.findIndex( path, { label: item.name } )
                                      let tmp = _.slice( path, 0, foundIndex + 1 )
                                      onChangeArkMFilesHOC( 'path', tmp )
                                      onChangeArkMFilesHOC( 'parentFolder', { id: item.id, name: item.name } )
                                    }
                                  })
                                  onChangeArkMFilesHOC( 'path', tmp )
                                  onChangeArkMFilesHOC( 'parentFolder', { id: item.id, name: item.name } )
                                }}>
                                <FaFolder size={ '35px' } />
                                <div className="ml-3">
                                  <p style={{ marginTop: '10px', textAlign: 'left' }}>{ item.name }</p>
                                </div>
                              </div>
                              <div className="ml-auto pl-2 d-flex">
                                <Button
                                  color="primary"
                                  className="btn-icon mr-1"
                                  onClick={ () => {
                                    onChangeArkMFilesHOC( 'showEditModal', true )
                                    getSelectedFolder( item.id )
                                  }}>
                                  <i className="pe-7s-note"></i>
                                </Button>
                                <Button
                                  color="danger"
                                  className="btn-icon mr-1"
                                  onClick={ () => {
                                    onChangeArkMFilesHOC( 'showDeleteModal', true )
                                    getSelectedFolder( item.id )
                                  }}>
                                  <i className="pe-7s-trash"></i>
                                </Button>
                              </div>
                            </Button>
                          </Col>
                        )
                      }
                    })
                  }
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <CreateFile
        showCreateModal={ showCreateModal }
        newFolderFile={ newFolderFile }
        onChangeArkMFilesHOC={ onChangeArkMFilesHOC }
        files={ files }
        parentFolder={ parentFolder }
        createFile={ createFile }
        createFileFolder={ createFileFolder }
        onLoadMediaDrive={ onLoadMediaDrive }
        selectedLanguage={ selectedLanguage } />
      <UpdateFile
        showEditModal={ showEditModal }
        selectedFileFolder={ selectedFileFolder }
        onChangeArkMFilesHOC={ onChangeArkMFilesHOC }
        updateFile={ updateFile }
        updateFolder={ updateFolder }
        onLoadMediaDrive={ onLoadMediaDrive }
        selectedLanguage={ selectedLanguage } />
      {
        showDeleteModal && (
          <DeleteConfirmation
            deleteOpen={ showDeleteModal }
            handleModalClose={ () => onChangeArkMFilesHOC( 'showDeleteModal', false ) }
            confirmAction={ () => {
              if( selectedFileFolder.folder_name ) {
                return deleteFolder( selectedFileFolder.id )
              } else {
                return deleteFile( selectedFileFolder.id )
              }
            }}
            content={ Lang[ 'DELETE_FILE_CONFIRMATION' ][ selectedLanguage ] }
            lang={ selectedLanguage } />
        )
      }
      { onLoadMediaDrive && <LoadingOverlay /> }
    </>
  )
}
const mapStateToProps = state => ({
  languageReducer: state.languageReducer
})

export default connect( mapStateToProps )( UploadFiles )
