import React, { Component } from 'react'
import { compose } from 'redux'
import{
  Modal, ModalBody, ModalFooter, ModalHeader, Button, Table, Container,
  CardHeader, Card, CardBody, FormGroup, Label, Input, CardFooter,
  UncontrolledTooltip
} from 'reactstrap'
import Frame from 'react-frame-component'
import ReactHtmlParser from 'react-html-parser'
import _ from 'lodash'

import Wrapper from '../Wrapper'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PageTitle from 'components/PageTitle'
import ARKTable from 'components/Table'
import CreateNewsletterTemplate from './Create'
import EditForm from './Edit'
import DeleteConfirmation from 'components/Modal/delete'

import NewsletterHOC from './actions'
import CenterGroupsHOC from './actions/centerGroups'
import Lang from 'Lang/General'
import { convertObjToBase64 } from 'utils/objToBase64'

class NewsletterManagement extends Component{
  componentDidMount = () => {
    this.props.getNewsletterTemplates( convertObjToBase64({ page: 1, is_paginated: true }) )
    this.props.getCenterGroups( convertObjToBase64({ is_paginated: false }) )
  }

  componentDidUpdate = pp => {
    if( 
      ( pp.showCreateNewsletterTemplate && !this.props.showCreateNewsletterTemplate ) ||
      ( pp.showUpdateNewsletterTemplate && !this.props.showUpdateNewsletterTemplate ) 
    ){
      this.props.onChangeNewsletterHOC( 'errorMessage', {} )
    }
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Newsletter' })
      return tmpModules.is_create
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Newsletter' })
      return tmpModules.is_update
    }
  }

  handleSecurityModulesDelete = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Newsletter' })
      return tmpModules.is_delete
    }
  }

  renderCreateModal = () => {
    return(
      <Modal
        toggle={ () => this.props.onChangeNewsletterHOC( 'showCreateNewsletterTemplate', false ) }
        isOpen={ this.props.showCreateNewsletterTemplate }
        size="xl">
        <ModalHeader toggle={ () => this.props.onChangeNewsletterHOC( 'showCreateNewsletterTemplate', false ) }>{ Lang[ 'CREATE_NEWSLETTER' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <CreateNewsletterTemplate
            onChangeNewsletterHOC={ this.props.onChangeNewsletterHOC }
            newNewsletterTemplate={ this.props.newNewsletterTemplate }
            onSave={ this.props.createNewsletterTemplate }
            errorMessage={ this.props.errorMessage }
            centerGroups={ this.props.centerGroups } />
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.onChangeNewsletterHOC( 'showCreateNewsletterTemplate', false ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
        { this.props.onLoadTemplate && <LoadingOverlay/> }
      </Modal>
    )
  }

  renderEditModal = () => {
    return(
      <Modal
        toggle={ () => this.props.onChangeNewsletterHOC( 'showUpdateNewsletterTemplate', false ) }
        isOpen={ this.props.showUpdateNewsletterTemplate }
        size="xl">
        <ModalHeader toggle={ () => this.props.onChangeNewsletterHOC( 'showUpdateNewsletterTemplate', false ) }>{ Lang[ 'EDIT_NEWSLETTER' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <EditForm
            onChangeNewsletterHOC={ this.props.onChangeNewsletterHOC }
            selectedNewsletterTemplate={ this.props.selectedNewsletterTemplate }
            onSave={ this.props.updateNewsletterTemplate }
            errorMessage={ this.props.errorMessage }
            centerGroups={ this.props.centerGroups } />
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.onChangeNewsletterHOC( 'showUpdateNewsletterTemplate', false ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
        { this.props.onLoadTemplate && <LoadingOverlay/> }
      </Modal>
    )
  }

  renderViewModal = () => {
    return(
      <Modal
        toggle={ () => this.props.onChangeNewsletterHOC( 'templateViewModal', false ) }
        isOpen={ this.props.templateViewModal }
        size="xl">
        <ModalHeader toggle={ () => this.props.onChangeNewsletterHOC( 'templateViewModal', false ) }>{ Lang[ 'VIEW_NEWSLETTER' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <Container>
            <Table className="mb-0" bordered style={{ minHeight: '500px' }}>
              <tbody>
                <tr>
                  <th scope="row">{ Lang[ 'NAME' ][ this.props.data.languageReducer.lang ] }</th>
                  <td>{ this.props.selectedNewsletterTemplate.name }</td>
                </tr>
                <tr>
                  <th scope="row">{ Lang[ 'CONTENT' ][ this.props.data.languageReducer.lang ] }</th>
                  <td>
                    <Frame
                      id={ 'editor-site-renderer' }
                      style={{
                        width: '100%',
                        height: '550px',
                        border: 'none'
                      }}>
                      { ReactHtmlParser( this.props.selectedNewsletterTemplate.html_content ) }
                    </Frame>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.onChangeNewsletterHOC( 'templateViewModal', false ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
        { this.props.onLoadTemplate && <LoadingOverlay/> }
      </Modal>
    )
  }

  render = () => {
    return(
      <>
        <div>
          <PageTitle
            heading={ Lang[ 'NEWSLETTER_TEMPLATES' ][ this.props.data.languageReducer.lang ] }
            icon="pe-7s-news-paper icon-gradient bg-happy-fisher"
            buttons={[
              {
                buttonText: Lang[ 'CREATE_NEWSLETTER' ][ this.props.data.languageReducer.lang ],
                onClick: () => this.props.onChangeNewsletterHOC( 'showCreateNewsletterTemplate', true ),
                display: this.handleSecurityModulesCreate()
              }
            ]}/>
          <Card className="mb-3">
            <CardHeader>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }</CardHeader>
            <CardBody>
              <FormGroup>
                <Label>{ Lang[ 'NAME' ][ this.props.data.languageReducer.lang ] }</Label>
                <Input
                  name="campaign-type" id="campaign-type"
                  placeholder={ Lang[ 'SEARCH_WITH_NAME' ][ this.props.data.languageReducer.lang ] }
                  value={ this.props.name }
                  onChange={ e => this.props.onChangeNewsletterHOC( 'name', e.target.value ) }/>
              </FormGroup>
            </CardBody>
            <CardFooter>
              <Button
                color="primary"
                className="ml-auto"
                onClick={ () => {
                  let tmp = {
                    page: 1,
                    is_paginated: true,
                    filter: {
                      name: {
                        $like: `%${ this.props.name }%`
                      }
                    }
                  }
                  this.props.getNewsletterTemplates( convertObjToBase64( tmp ) )
                }}>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }</Button>
              <Button
                color="danger"
                onClick={ () => {
                  let tmp = {
                    page: 1,
                    is_paginated: true
                  }
                  this.props.onChangeNewsletterHOC( 'name', '' )
                  return this.props.getNewsletterTemplates( convertObjToBase64( tmp ) )
                }}>{ Lang[ 'RESET' ][ this.props.data.languageReducer.lang ] }</Button>
            </CardFooter>
          </Card>
          <ARKTable
            data={ this.props.newsletterTemplates.data }
            columns={[
              {
                Header: Lang[ 'NAME' ][ this.props.data.languageReducer.lang ],
                accessor: "name"
              },
              {
                Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
                accessor: "id",
                Cell: ({value}) => (
                  <>
                    <Button
                      id={ `Newsletter-${ value }-view-newsletter` }
                      className="mb-2 mr-2 btn-icon btn-icon-only"
                      color="primary"
                      onClick={ () => {
                        this.props.onChangeNewsletterHOC( 'templateViewModal', true )
                        this.props.getSelectedNewsletterTemplate( value )
                      }}>
                        <i className="pe-7s-look"/>
                    </Button>
                    <UncontrolledTooltip
                      target={ `Newsletter-${ value }-view-newsletter` }
                      placement="top">{ Lang[ 'VIEW_NEWSLETTER_SEN_CASE' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                    {
                      this.handleSecurityModulesUpdate() && (
                        <>
                          <Button
                            id={ `Newsletter-${ value }-update-newsletter` }
                            className="mb-2 mr-2 btn-icon btn-icon-only"
                            color="primary"
                            onClick={ () => {
                              this.props.onChangeNewsletterHOC( 'showUpdateNewsletterTemplate', true )
                              this.props.getSelectedNewsletterTemplate( value )
                            }}>
                              <i className="pe-7s-note" />
                          </Button>
                          <UncontrolledTooltip
                            target={ `Newsletter-${ value }-update-newsletter` }
                            placement="top">{ Lang[ 'UPDATE_NEWSLETTER' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                        </>
                      )
                    }
                    {
                      this.handleSecurityModulesDelete() && (
                        <>
                          <Button
                            id={ `Newsletter-${ value }-delete-newsletter` }
                            className="mb-2 mr-2 btn-icon btn-icon-only"
                            color="danger"
                            onClick={ () => {
                              this.props.onChangeNewsletterHOC( 'templateDeleteModal', true )
                              this.props.getSelectedNewsletterTemplate( value )
                            }}>
                              <i className="pe-7s-trash"/>
                          </Button>
                          <UncontrolledTooltip
                            target={ `Newsletter-${ value }-delete-newsletter` }
                            placement="top">{ Lang[ 'DELETE_NEWSLETTER' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                        </>
                      )
                    }
                  </>
                )
              }
            ]}
            totalPages={ this.props.templateTotalPages }
            page={ this.props.newsletterTemplates.meta ? this.props.newsletterTemplates.meta.current_page : 1 }
            onChangePage={ pg => {
              let tmp = {
                page: pg,
                is_paginated: true
              }
              return this.props.getNewsletterTemplates( convertObjToBase64( tmp ) )
            }}
            onClickReset={ () => {
              let tmp = {
                page: 1,
                is_paginated: true
              }
            }}
            onSearch={ () => {} }
            searchLabel={ Lang[ 'SEARCH_WITH_NAME' ][ this.props.data.languageReducer.lang ] }
            searchComponent={ false }
            showPagination={ false } />
        </div>
        { this.props.showCreateNewsletterTemplate && this.renderCreateModal() }
        { this.props.showUpdateNewsletterTemplate && this.renderEditModal() }
        { this.renderViewModal() }
        {
          this.props.templateDeleteModal && (
            <DeleteConfirmation
              handleModalClose={ () => this.props.onChangeNewsletterHOC( 'templateDeleteModal', false ) }
              deleteOpen={ this.props.templateDeleteModal }
              confirmAction={ () => {
                this.props.removeNewsletterTemplate( this.props.selectedNewsletterTemplate.id )
                this.props.onChangeNewsletterHOC( 'templateDeleteModal', false )
              }}
              content={ Lang[ 'DELETE_CONFIRMATION_NEWSLETTER' ][ this.props.data.languageReducer.lang ] }
              lang={ this.props.data.languageReducer.lang } />
          )
        }
        { ( this.props.data.ajaxReducer.ajaxCallProgress > 0 || this.props.onLoadTemplate ) && <LoadingOverlay /> }
      </>
    )
  }
}
export default compose(
  NewsletterHOC,
  CenterGroupsHOC
)( NewsletterManagement )
