import React from 'react'
import {
  Modal, ModalBody, ModalHeader, ModalFooter,
  Form, FormGroup, Label, Input, Button
} from 'reactstrap'
import { FilePond, File, registerPlugin } from 'react-filepond'
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import FilePondPluginFileEncode from 'filepond-plugin-file-encode'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import _ from 'lodash'

import LoadingModal from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'

import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

registerPlugin( FilePondPluginFileEncode, FilePondPluginImagePreview, FilePondPluginFileValidateType )

const CreateMedia = ({
  showCreateModal,
  newFolderFile,
  onChangeMediaDriveHOC,
  files,
  parentFolder,
  createMedia,
  createMediaFolder,
  onLoadMediaDrive,
  selectedLanguage
}) => {
  return (
    <Modal
      size={ 'md' }
      toggle={ () => onChangeMediaDriveHOC( 'showCreateModal', false ) }
      isOpen={ showCreateModal }>
      <ModalHeader toggle={ () => onChangeMediaDriveHOC( 'showCreateModal', false ) }>{ Lang[ 'CREATE_FILE_OR_FOLDER' ][ selectedLanguage ] }</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <FormGroup>
            <Label>{ Lang[ 'NAME' ][ selectedLanguage ] }</Label>
            <Input
              type="text"
              value={ newFolderFile.file_name }
              onChange={ e => {
                let tmp = _.cloneDeep( newFolderFile )
                tmp[ 'file_name' ] = e.target.value
                return onChangeMediaDriveHOC( 'newFolderFile', tmp )
              }} />
          </FormGroup>
          <FormGroup>
            <Label>{ Lang[ 'TYPE' ][ selectedLanguage ] }</Label>
            <Input
              type="select"
              value={ newFolderFile.type }
              onChange={ e => {
                let tmp = _.cloneDeep( newFolderFile )
                tmp[ 'type' ] = e.target.value
                return onChangeMediaDriveHOC( 'newFolderFile', tmp )
              }}>
              <option value="Folder">{ Lang[ 'FOLDER' ][ selectedLanguage ] }</option>
              <option value="File">{ Lang[ 'FILE' ][ selectedLanguage ] }</option>
            </Input>
          </FormGroup>
          {
            newFolderFile.type === 'File' && (
              <FormGroup>
                <FilePond
                  files={files}
                  instantUpload={ false }
                  allowFileTypeValidation={ true }
                  allowImagePreview={ true }
                  allowFileEncode={ true }
                  acceptedFileTypes={['image/*', 'application/pdf', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'applications/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ]}
                  onupdatefiles={ fileItems => {
                    let tmpFiles = []
                    fileItems.map( item => tmpFiles.push( item.file ) )
                    onChangeMediaDriveHOC( 'files', tmpFiles )
                  }}
                  allowMultiple={false}
                  server=""
                />
              </FormGroup>
            )
          }
        </Form>
        { onLoadMediaDrive && <LoadingModal/> }
      </ModalBody>
      <ModalFooter>
      {
        newFolderFile.type === 'File' && (
          <Button
            color="primary"
            onClick={() => {
              let tmp = _.cloneDeep( newFolderFile )
              if( files.length > 0 ){
                let reader = new FileReader()
                reader.onload = () => {
                  let tmpResult = reader.result
                  tmp[ 'media_raw' ] = tmpResult
                  tmp[ 'original_filename' ] = files[ 0 ].name
                  createMedia({
                    ...tmp,
                    file_name: newFolderFile.file_name !== '' ? newFolderFile.file_name : files[ 0 ].name,
                    parent_folder_id: parentFolder.id,
                  }, parentFolder.id)
                }
                reader.readAsDataURL( files[ 0 ] )
              } else {
                createMedia({
                  ...tmp,
                  file_name: newFolderFile.file_name !== '' ? newFolderFile.file_name : files[ 0 ].name,
                  parent_folder_id: parentFolder.id
                })
              }
            }}>{ Lang[ 'SUBMIT' ][ selectedLanguage ] }</Button>
        )
      }
      {
        newFolderFile.type === 'Folder' && (
          <Button
            color="primary"
            onClick={() => {
              let tmp = _.cloneDeep( newFolderFile )
              delete tmp['media_raw']
              delete tmp['tags']

              createMediaFolder({
                ...tmp,
                parent_folder_id: parentFolder.id,
                folder_name: tmp.file_name
              }, parentFolder.id )
            }}>{ Lang[ 'SUBMIT' ][ selectedLanguage ] }</Button>
        )
      }
      </ModalFooter>
    </Modal>
  )
}

export default CreateMedia
