import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      campaignTypes: [],
      loading: false
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    getCampaignTypes = ( query ) => Get(
      `/api/v1/ols_campaign_types?query=${ query }`,
      this.getCampaignTypesSuccess,
      this.getCampaignTypesError,
      this.load
    )
    getCampaignTypesSuccess = payload => this.setState({ campaignTypes: payload })
    getCampaignTypesError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadCampaignTypes={ this.state.loading }
          campaignTypes={ this.state.campaignTypes }
          getCampaignTypes={ this.getCampaignTypes } />
      )
    }
  }
  return WithHOC
}

export default HOC
