import React from 'react'
import ReactHTMLParser from 'react-html-parser'
import { FormGroup } from 'reactstrap'

const TextComponent = ({
  content,
  currentLanguage
}) => {
  return (
    <FormGroup style={{ padding: content.padding || "15px" , marginBottom: "0" }} >
      <p 
        id={ content.id }
        style={{
          ...content.style,
          padding: "0",
          fontSize: content.fontSize || "16px"
        }}>
        { ReactHTMLParser( content.label[ currentLanguage ? currentLanguage : 'en' ] ) }
      </p>
    </FormGroup>
  )
}

export default TextComponent