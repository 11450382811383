import React, { Fragment, useState, useEffect } from 'react'
import _ from 'lodash'
import {
  Form, FormGroup, Label, Input, Col, FormFeedback, Container, Card, CardBody,
  Modal, ModalBody, ModalHeader, ModalFooter, Button, TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { connect } from 'react-redux'
import Moment from 'moment'
import classnames from 'classnames'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import General from './components/general'
import AddMailing from './components/addMailing'

import Lang from 'Lang/General'
import { validate } from 'utils/validatePhoneNumber'

const Update = ( props ) => {
  const [activeTab, setActiveTab] = useState('1');

  useEffect( () => {
    props.onChangeSystemFunnelsHOC( 'systemFunnelErrorMsg', {} )
  }, [props.showUpdateFunnel] )

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  return(
    <Modal
      isOpen={ props.showUpdateFunnel }
      size="lg">
      <ModalHeader toggle={ () => props.onChangeSystemFunnelsHOC('showUpdateFunnel', false ) }>{ Lang['UPDATE_FUNNEL'][ props.selectedLanguage ] }
      </ModalHeader>
      <ModalBody>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => { toggle('1'); }}>
              { Lang['GENERAL'][ props.selectedLanguage ] }
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => { toggle('2'); }}>
              Add Mailing
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <General
              {...props }/>
          </TabPane>
          <TabPane tabId="2">
            <AddMailing
              {...props }/>
          </TabPane>
        </TabContent>
      </ModalBody>
      { props.onLoadSystemFunnels && <LoadingOverlay /> }
    </Modal>

  )
}
const mapStateToProps = state => ({ dataReducer: state })

export default connect( mapStateToProps )( Update )
