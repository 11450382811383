import React, { useState, useEffect } from 'react'
import {
  Card, CardHeader, CardBody,
  Form, FormGroup,
  Input, Label, Button, CustomInput, Col, Row
} from 'reactstrap'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { SketchPicker } from "react-color"
import uniqid from 'uniqid'
import _ from 'lodash'
import { MdDesktopWindows, MdTablet, MdSmartphone, MdZoomOutMap } from 'react-icons/md'

import ImagePosition from 'components/Image/ImagePosition'
import LinkInput from 'components/LinkInput'
import MediaDatabase from 'SharedModules/MediaDatabase'
import Lang from 'Lang/General'

const reorder = ( list, startIndex, endIndex ) => {
  const result = Array.from( list )
  const [ removed ] = result.splice( startIndex, 1 )
  result.splice( endIndex, 0, removed )
  return result
}

const CarouselForm = ({
  carouselPage,
  selectedSection,
  selectedLanguageShortname,
  onChangeSectionHOC,
  pages,
  toggleMediaDatabase,
  findComponentAndPatch,
  onChangeSectionEditorState,
  mediaDatabaseFor,
  onUpdateCarouselPage,
  stylesheets,
  javascripts
}) => {
  const [ contentLang, updateContentLang ] = useState( selectedLanguageShortname )
  const [ showPageArrangement, updateShowPageArrangement ] = useState( false )
  const [ activeColor, setActiveColor ] = useState( "#ffffff" )
  const [ captionHeader, setCaptionHeader ] = useState({})
  const [ actualImgDimension, setActualImgDimension ] = useState({})
  const [ showImagePositionModal, setShowImagePositionModal ] = useState( false )
  const [ selectedDOM, setSelectedDOM ] = useState({})

  useEffect(() => {
    updateContentLang( selectedLanguageShortname )
    getImgDimension( selectedSection.carouselItems[ carouselPage ].bgImg )
  }, [ carouselPage ] )

  useEffect(() => {
    setCaptionHeader( selectedSection.carouselItems[ carouselPage ].caption.header )
    if( showImagePositionModal ){
      let tmp = _.cloneDeep( selectedSection )
      tmp.carouselItems = [ _.cloneDeep( selectedSection.carouselItems[ carouselPage ] ) ]
      tmp.arrows = false
      setSelectedDOM( tmp )
    }
  }, [ selectedSection, carouselPage, showImagePositionModal ])

  useEffect(() => {
    selectedSection.carouselItems[ carouselPage ].caption.style &&
    selectedSection.carouselItems[ carouselPage ].caption.style.color &&
    setActiveColor( selectedSection.carouselItems[ carouselPage ].caption.style.color )
  },[])

  useEffect( () => {
    getImgDimension( selectedSection.carouselItems[ carouselPage ].bgImg )
  }, [ selectedSection.carouselItems[ carouselPage ].bgImg ])

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let tmp = _.cloneDeep( selectedSection )
    tmp.carouselItems = reorder( tmp.carouselItems, oldIndex, newIndex )
    Promise.all([
      onChangeSectionHOC( 'selectedSection', tmp )
    ]).then( () => onUpdateCarouselPage( newIndex ) )
  }

  const changeButtonCenterStyleWithRow = ( data ) => {
    data.className = "row m-0"
    data.style = {
      ...data.style,
      width: "100%",
      justifyContent: "center"
    }
    data.children && data.children.length > 0 &&  data.children.map( child => {
      if( !_.isEmpty( child.style )){
        child.style = _.reduce(
          child.style,
          ( res, val, key ) => key.indexOf( 'margin' ) > -1
            ? res
            : ({ ...res, [ key]: val }),
          {}
        )
      }
      child.style.margin = "10px"
    })
  }

  const changeButtonCenterStyleWithoutRow = ( data ) => {
    let tmp = {
      id: uniqid(),
      type:'div',
      className: "row m-0",
      style: {
        width: "100%",
        justifyContent: "center"
      },
      children: _.cloneDeep( data.children )
    }
    tmp.children.map( child => {
      if( !_.isEmpty( child.style )){
        child.style = _.reduce(
          child.style,
          ( res, val, key ) => key.indexOf( 'margin' ) > -1
            ? res
            : ({ ...res, [ key]: val }),
          {}
        )
      }
      child.style.margin = "10px"
    })
    data.children = [ tmp ]
  }

  const SortableItem = SortableElement( ({ pg }) => {
    return <div 
      className='mr-2'
      style={{ 
        display: "block",
        cursor: "pointer",
        border: carouselPage === pg ? "#6c757d" : "1px solid #6c757d",
        borderRadius: "0.25rem",
        textAlign: "center",
        alignItems: "center",
        width: "50px",
        height: "fit-content",
        background: carouselPage === pg ? "#6c757d": "none",
        color: carouselPage === pg ? "#ffffff" : "#000000",
        zIndex: "9999" }}>
      { pg + 1 }
    </div>
  })

  const SortableList = SortableContainer( ({ items }) => {
    return (  
      <div className='row m-0'>
        { items.map( ( pg, index ) => <SortableItem key={ `page-${ index }` } index={ index } pg={ index } /> ) }
      </div>
    )
  })

  const getImgDimension = ( imgSrcURL ) => {
    if( imgSrcURL ){
      let img = new Image()
      img.onload = () => setActualImgDimension({
          'width': img.width,
          'height': img.height
        })
      img.src = imgSrcURL.replace(/url\(|\)$|"/ig, '')
    }
  }

  const onEdit = ( type, value, screenView ) => {
    let tmp = _.cloneDeep( selectedSection )
    let currentObject = tmp.carouselItems[ carouselPage ]
    if( !currentObject.style ){
      currentObject.style = {}
    }
    if( screenView === "mobile" ){
      currentObject.style[ type ] = value
    } else {
      let mediaQuery = screenView === "desktop" ? "@media (min-width: 768px)" : "@media (min-width: 576px)"
      if( !currentObject.style[ mediaQuery ]){
        currentObject.style[ mediaQuery ] = {
          [ type ]: value
        }
      } else {
        currentObject.style[ mediaQuery ][ type ] = value
      }
    }
    if( currentObject.style[ "@media (min-width: 768px)" ] ){
      currentObject.style[ "@media (min-width: 768px)" ].dummy = `${ currentObject.style[ "@media (min-width: 768px)" ].dummy && parseInt( currentObject.style[ "@media (min-width: 768px)" ].dummy ) < 1000
        ? parseInt( currentObject.style[ "@media (min-width: 768px)" ].dummy ) + 1 
        : 0 }px`
    }
    onChangeSectionHOC( 'selectedSection', tmp )
  }

  return (
    <Card className="mb-2">
      <CardHeader>{ Lang[ 'CAROUSEL_SETTINGS' ][ selectedLanguageShortname ] }</CardHeader>
      <CardBody>
        <Form>
          <FormGroup>
            {
              selectedSection.isShowCarousel !== false
                ? (
                  <Button
                    color="primary"
                    style={{ marginTop:"10px"}}
                    onClick={() => {
                      let tmp = _.cloneDeep( selectedSection )
                      tmp.isShowCarousel = false
                      if( tmp.style ) {
                        tmp.style = { ...tmp.style, display: 'none' }
                      } else {
                        tmp.style = {}
                        tmp.style = { ...tmp.style, display: 'none' }
                      }
                      return onChangeSectionHOC( 'selectedSection', tmp )
                    }}>
                    { Lang[ 'HIDE_CAROUSEL' ][ selectedLanguageShortname ] }
                  </Button>
                ) : (
                  <Button
                    color="success"
                    style={{ marginLeft:"10px", marginTop:"10px" }}
                    onClick={() => {
                      let tmp = _.cloneDeep( selectedSection )
                      tmp.isShowCarousel = true
                      if( tmp.style ) {
                        tmp.style = { ...tmp.style, display: 'block' }
                      } else {
                        tmp.style = {}
                        tmp.style = { ...tmp.style, display: 'block' }
                      }
                      return onChangeSectionHOC( 'selectedSection', tmp )
                    }}>
                    { Lang[ 'SHOW_CAROUSEL' ][ selectedLanguageShortname ] }
                  </Button>
                )
            }
          </FormGroup>
          {
            selectedSection.isShowCarousel !== false && (
              <>
                <FormGroup className='d-flex align-items-center'>
                  <span className='mr-2' >{ Lang[ 'PAGE' ][ selectedLanguageShortname ] }: </span>
                  {
                    showPageArrangement
                      ? (
                        <SortableList
                          items={ selectedSection.carouselItems }
                          onSortEnd={ onSortEnd }
                          axis='xy' />
                      ) : (
                        selectedSection.carouselItems.map( ( item, index ) => (
                          <Button
                            className="mr-2"
                            color={ parseInt( carouselPage ) === index ? "success": "primary" }
                            onClick={ () => onUpdateCarouselPage( index ) } >
                            { index + 1 }
                          </Button>
                        ))
                      )
                  }
                </FormGroup>
                <FormGroup>
                  <Button
                    color={ showPageArrangement ? 'danger' : 'primary' }
                    onClick={ () => updateShowPageArrangement( !showPageArrangement ) }>
                    { showPageArrangement ? Lang[ 'STOP_PAGE_REARRANGEMENT' ][ selectedLanguageShortname ] : Lang[ 'REARRANGE_PAGE' ][ selectedLanguageShortname ] }
                  </Button>
                </FormGroup>
              </>
            )
          }
          {
            selectedSection.isShowCarousel !== false &&
            selectedSection.carouselItems[ carouselPage ] &&
            _.isEmpty( selectedSection.carouselItems[ carouselPage ][ 'dom' ] ) && (
              <>
                <FormGroup>
                  <span className='mr-2' >{ Lang[ 'EDITING_LANGUAGE' ][ selectedLanguageShortname ] }{ ': ' }</span>
                  {
                    Object.keys( selectedSection.carouselItems[ carouselPage ].caption.title ).map( lang => lang !== 'style' && lang !=='useSiteFontFamily'
                      ? (
                        <Button
                          className='mr-2'
                          color={ lang === contentLang ? 'success' : 'primary' }
                          onClick={ () => updateContentLang( lang ) } >
                          { lang }
                        </Button>
                      ) : <></>
                    )
                  }
                </FormGroup>
                <Label style={{ fontWeight:600, fontSize:"1.2rem" }}>{ Lang[ 'BACKGROUND_CONFIGURATION' ][ selectedLanguageShortname ] }</Label>
                <FormGroup>
                  <Label>{ Lang[ 'BACKGROUND_IMAGE' ][ selectedLanguageShortname ] }</Label>
                  <Input 
                    disabled
                    type="text" 
                    value={ 
                      selectedSection.carouselItems.length > 0 
                        ? selectedSection.carouselItems[ carouselPage ].bgImg.replace( /.*(?=\/)/, '').replace( '/', '' )
                        : '' 
                    } />
                  <Button
                    color="primary"
                    style={{ marginTop: '10px' }}
                    onClick={() => {
                      onChangeSectionEditorState( 'mediaDatabaseFor', 'carousel' )
                      toggleMediaDatabase( true )
                    }}>
                      { Lang[ 'MEDIA_DATABASE' ][ selectedLanguageShortname ] }
                  </Button>
                </FormGroup>
                <Label>
                  { `${ Lang[ 'ACTUAL_IMAGE_SIZE' ][ selectedLanguageShortname ] }: ` }
                  { 
                    actualImgDimension.width && actualImgDimension.height
                      ? `${ actualImgDimension.width }px x ${ actualImgDimension.height }px ` 
                      : '-'
                  }
                </Label>
                <FormGroup className='my-3'>
                  <Label>
                    <MdDesktopWindows style={{ fontSize: '25px' }} />
                    <MdTablet style={{ fontSize: '25px' }} className='ml-1' />
                    <MdSmartphone style={{ fontSize: '25px' }} className='mr-2' />
                    { Lang[ 'BACKGROUND_IMAGE_POSITION' ][ selectedLanguageShortname ] }
                  </Label>
                  <div>
                    <MdZoomOutMap style={{ fontSize: '25px' }} className='mr-2' />
                    <Button 
                      color="primary"
                      onClick={ () => {
                        let tmp = _.cloneDeep( selectedSection )
                        tmp.carouselItems = [ selectedSection.carouselItems[ carouselPage ]]
                        tmp.arrows = false
                        setSelectedDOM( tmp )
                        setShowImagePositionModal( true )
                      }}>
                      { Lang[ 'EDIT' ][ selectedLanguageShortname ] }
                    </Button>
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label>{ Lang[ 'BACKGROUND_IMAGE_TITLE' ][ selectedLanguageShortname ] }</Label>
                  <Input 
                    type="text" 
                    value={ 
                      selectedSection.carouselItems.length > 0 && selectedSection.carouselItems[ carouselPage ].bgImgTitle
                        ? selectedSection.carouselItems[ carouselPage ].bgImgTitle 
                        : '' 
                    } 
                    onChange={ e => {
                      let tmp = _.cloneDeep( selectedSection )
                      tmp.carouselItems[ carouselPage ].bgImgTitle = e.target.value
                      return onChangeSectionHOC( 'selectedSection', tmp )
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>{ Lang[ 'BACKGROUND_IMAGE_ALT' ][ selectedLanguageShortname ] }</Label>
                  <Input 
                    type="text" 
                    value={ 
                      selectedSection.carouselItems.length > 0 && selectedSection.carouselItems[ carouselPage ].bgImgAlt
                        ? selectedSection.carouselItems[ carouselPage ].bgImgAlt 
                        : '' 
                    } 
                    onChange={ e => {
                      let tmp = _.cloneDeep( selectedSection )
                      tmp.carouselItems[ carouselPage ].bgImgAlt = e.target.value
                      return onChangeSectionHOC( 'selectedSection', tmp )
                    }}
                  />
                </FormGroup>
                {
                  selectedSection.carouselItems[ carouselPage ].caption.header && <>
                    <Label style={{fontWeight:600, fontSize:"1.2rem"}}>{ Lang[ 'HEADER_IMAGE_CONFIGURATION' ][ selectedLanguageShortname ] }</Label>
                    <FormGroup>
                      <Label>{ Lang[ 'HEADER_LOGO_IMAGE' ][ selectedLanguageShortname ] }</Label>
                      <Input 
                        disabled 
                        type="text" 
                        value={
                          captionHeader && captionHeader.children && captionHeader.children.length > 0 
                            ? _.find(captionHeader.children, {type: "img"}).source.replace( /.*(?=\/)/, '').replace( '/', '' )
                            : ''
                        }
                      />
                      <Button
                        color="primary"
                        style={{ marginTop: '10px' }}
                        onClick={() => {
                          onChangeSectionEditorState( 'mediaDatabaseFor', 'carousel-header' )
                          toggleMediaDatabase( true )
                        }}>
                          { Lang[ 'MEDIA_DATABASE' ][ selectedLanguageShortname ] }
                      </Button>
                    </FormGroup>
                    <FormGroup>
                      <CustomInput
                        id="showImage"
                        type='checkbox'
                        label={ Lang[ 'SHOW_IMAGE' ][ selectedLanguageShortname ] }
                        checked={ selectedSection.carouselItems[ carouselPage ].caption.header.style.display !== "none" } 
                        onChange={ e => {
                          let tmp = _.cloneDeep( selectedSection )
                          tmp.carouselItems[ carouselPage ].caption.header.style.display = e.target.checked ? "block" : "none"
                          return onChangeSectionHOC( 'selectedSection', tmp )
                        }}
                      />
                    </FormGroup>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{ Lang[ 'WIDTH' ][ selectedLanguageShortname ] }</Label>
                          <Input 
                            type="text" 
                            value={ selectedSection.carouselItems[ carouselPage ].caption.header.style.width } 
                            onChange={ e => {
                              let tmp = _.cloneDeep( selectedSection )
                              let tmpStyle = tmp.carouselItems[ carouselPage ].caption.header.style
                              tmpStyle.width = e.target.value
                              if(tmpStyle.maxWidth){
                                delete tmpStyle.maxWidth
                              }
                              Object.keys( tmpStyle ).map( key => {
                                if( typeof tmpStyle[ key ] === 'object' && tmpStyle[ key ].maxWidth ){
                                  delete tmpStyle[ key ].maxWidth
                                }
                              })
                              return onChangeSectionHOC( 'selectedSection', tmp )
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>{ Lang[ 'HEIGHT' ][ selectedLanguageShortname ] }</Label>
                          <Input 
                            type="text" 
                            value={ selectedSection.carouselItems[ carouselPage ].caption.header.style.height } 
                            onChange={ e => {
                              let tmp = _.cloneDeep( selectedSection )
                              tmp.carouselItems[ carouselPage ].caption.header.style.height = e.target.value
                              return onChangeSectionHOC( 'selectedSection', tmp )
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </>
                }
                <Label style={{ fontWeight:600, fontSize:"1.2rem" }}>{ Lang[ 'TEXT_CONFIGURATION' ][ selectedLanguageShortname ] }</Label>
                <FormGroup>
                  <Button
                    color="primary"
                    style={{ marginBottom: "10px", marginRight: "10px" }}
                    onClick={()=>{
                      let tmp = _.cloneDeep( selectedSection )
                      let tmpDisplay = tmp.carouselItems[ carouselPage ].caption.style.display
                      if( tmpDisplay && tmpDisplay === "none" ){
                        if( tmp.carouselItems[ carouselPage ].caption.display ){
                          tmp.carouselItems[ carouselPage ].caption.style.display = _.cloneDeep( tmp.carouselItems[ carouselPage ].caption.display )
                        } else {
                          tmp.carouselItems[ carouselPage ].caption.style.display = "block"
                        }
                      } else {
                        if( tmpDisplay && tmpDisplay !=="block" ){
                          tmp.carouselItems[ carouselPage ].caption.display = _.cloneDeep( tmpDisplay )
                        }
                        tmp.carouselItems[ carouselPage ].caption.style.display = "none"
                      }
                      onChangeSectionHOC( 'selectedSection', tmp )
                    }}
                  >
                    {
                      selectedSection.carouselItems[ carouselPage ].caption.style.display && selectedSection.carouselItems[ carouselPage ].caption.style.display === "none" 
                      ? Lang[ 'SHOW_CAPTION' ][ selectedLanguageShortname ]
                      : Lang[ 'HIDE_CAPTION' ][ selectedLanguageShortname ]
                    }
                  </Button>
                  {
                    !( selectedSection.carouselItems[ carouselPage ].caption.children?.length > 1 &&
                      selectedSection.carouselItems[ carouselPage ].caption.children[1].type === 'div' ) && (
                      <Button 
                        color={ selectedSection.carouselItems[ carouselPage ].caption.isCaptionCentered ? "danger" : "primary" }
                        style={{ marginBottom: "10px", marginRight: "10px" }}
                        onClick={() => {
                          let tmp = _.cloneDeep( selectedSection )
                          tmp.carouselItems[ carouselPage ].caption.isCaptionCentered = !selectedSection.carouselItems[ carouselPage ].caption.isCaptionCentered 
                          return onChangeSectionHOC( 'selectedSection', tmp )
                        }}>
                        { 
                          selectedSection.carouselItems[ carouselPage ].caption.isCaptionCentered 
                            ? Lang[ 'SET_DEFAULT' ][ selectedLanguageShortname ] 
                            : Lang[ 'MAKE_CAPTION_CENTER' ][ selectedLanguageShortname ] 
                        }
                      </Button>
                    )
                  }
                </FormGroup>
                <FormGroup>
                  <div className="row">
                    <div className="col-md-9">
                      <Label style={{ fontWeight:600 }}>{ Lang[ 'TITLE' ][ selectedLanguageShortname ] }</Label>
                      <Input
                        type="text"
                        value={ selectedSection.carouselItems.length > 0 ? selectedSection.carouselItems[ carouselPage ].caption.title[ contentLang ]: '' }
                        onChange={ e => {
                          let tmp = _.cloneDeep( selectedSection )
                          tmp.carouselItems[ carouselPage ].caption.title[ contentLang ] = e.target.value
                          return onChangeSectionHOC( 'selectedSection', tmp )
                        }} />
                    </div>
                    <div className="col-md-3">
                      <Label style={{ fontSize:"0.8rem" }}>{ Lang[ 'FONT_SIZE' ][ selectedLanguageShortname ] }</Label>
                        <Input
                          type="text"
                          value={ selectedSection.carouselItems.length > 0 && selectedSection.carouselItems[ carouselPage ].caption.description.style ? selectedSection.carouselItems[ carouselPage ].caption.title.style.fontSize: '' }
                          onChange={ e => {
                            let tmp = _.cloneDeep( selectedSection )
                            tmp.carouselItems[ carouselPage ].caption.title.style.fontSize = e.target.value
                            return onChangeSectionHOC( 'selectedSection', tmp )
                          }} />
                    </div>
                  </div>
                  <CustomInput
                    id={ `titleFontFamily` }
                    type='checkbox'
                    label={ `${ Lang[ 'APPLY_SITE_FONT_FAMILY' ][ selectedLanguageShortname ] }` }
                    checked={ selectedSection.carouselItems[ carouselPage ].caption.title.useSiteFontFamily !== false } 
                    onChange={ e => {
                      let tmp = _.cloneDeep( selectedSection )
                      tmp.carouselItems[ carouselPage ].caption.title.useSiteFontFamily = e.target.checked
                      return onChangeSectionHOC( 'selectedSection', tmp )
                    }}/>
                </FormGroup>
                <FormGroup>
                  <div className="row">
                    <div className="col-md-9">
                      <Label  style={{ fontWeight:600 }}>{ Lang[ 'CAPTION' ][ selectedLanguageShortname ] }</Label>
                      <Input
                        type="text"
                        value={ selectedSection.carouselItems.length > 0 ? selectedSection.carouselItems[ carouselPage ].caption.description[ contentLang ] : '' }
                        onChange={ e => {
                          let tmp = _.cloneDeep( selectedSection )
                          tmp.carouselItems[ carouselPage ].caption.description[ contentLang ] = e.target.value
                          return onChangeSectionHOC( 'selectedSection', tmp )
                        }} />
                      </div>
                      <div className="col-md-3">
                        <Label style={{ fontSize:"0.8rem" }}>{ Lang[ 'FONT_SIZE' ][ selectedLanguageShortname ] }</Label>
                        <Input
                          type="text"
                          value={ selectedSection.carouselItems.length > 0 && selectedSection.carouselItems[ carouselPage ].caption.description.style ? selectedSection.carouselItems[ carouselPage ].caption.description.style.fontSize : '' }
                          onChange={ e => {
                            let tmp = _.cloneDeep( selectedSection )
                            tmp.carouselItems[ carouselPage ].caption.description.style.fontSize = e.target.value
                            return onChangeSectionHOC( 'selectedSection', tmp )
                          }} /> 
                      </div>
                    </div>
                    <CustomInput
                      id={ `captionFontFamily` }
                      type='checkbox'
                      label={ `${ Lang[ 'APPLY_SITE_FONT_FAMILY' ][ selectedLanguageShortname ] }` }
                      checked={ selectedSection.carouselItems[ carouselPage ].caption.description.useSiteFontFamily !== false } 
                      onChange={ e => {
                        let tmp = _.cloneDeep( selectedSection )
                        tmp.carouselItems[ carouselPage ].caption.description.useSiteFontFamily = e.target.checked
                        return onChangeSectionHOC( 'selectedSection', tmp )
                      }}/>
                </FormGroup>
                <FormGroup>
                  <Label style={{ fontWeight: 600 }} >{ Lang[ "HEIGHT" ][ selectedLanguageShortname ] }</Label>
                  <Input
                    type='text'
                    value={ 
                      selectedSection.carouselItems[ 0 ].style &&
                      selectedSection.carouselItems[ 0 ].style.height 
                        ? selectedSection.carouselItems[ 0 ].style.height 
                        : ''
                    }
                    onChange={ e => {
                      let tmp = _.cloneDeep( selectedSection )
                      tmp.carouselItems.map( carouselItem => {
                        carouselItem.style.height = e.target.value
                      })
                      return onChangeSectionHOC( 'selectedSection', tmp )
                    }} />
                </FormGroup>
                <FormGroup>
                  <Label style={{ fontWeight:600 }}>{ Lang[ "COLOR" ][ selectedLanguageShortname ]}</Label>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <SketchPicker
                      width="40%"
                      color={ activeColor }
                      onChange={( col ) => {
                        setActiveColor( col )
                        let tmp = _.cloneDeep( selectedSection )
                        tmp.carouselItems[ carouselPage ].caption.style.color = col.hex

                        if ( tmp.carouselItems[ carouselPage ].caption.title.style?.color !== 'inherit' ){
                          tmp.carouselItems[ carouselPage ].caption.title.style.color = 'inherit'
                        }
                        if ( tmp.carouselItems[ carouselPage ].caption.description.style?.color !== 'inherit' ){
                          tmp.carouselItems[ carouselPage ].caption.description.style.color = 'inherit'
                        }

                        onChangeSectionHOC( 'selectedSection', tmp )
                      }}
                    />
                  </div>
                </FormGroup>
                <Label style={{ fontWeight:600, fontSize:"1.2rem" }}>{ Lang[ 'BUTTON_CONFIGURATION' ][ selectedLanguageShortname ] }</Label>
                {
                  selectedSection.carouselItems[ carouselPage ].caption.children && selectedSection.carouselItems[ carouselPage ].caption.children.length > 0 ? <>
                    {
                      selectedSection.carouselItems.length > 0 && ( 'children' in selectedSection.carouselItems[ carouselPage ].caption ) && <>
                        {
                          selectedSection.carouselItems[ carouselPage ].caption.children[ 0 ].children ? selectedSection.carouselItems[ carouselPage ].caption.children[ 0 ].children.map( ( item, index ) => {
                            return(
                              <>
                                <div>
                                  <Label style={{ fontWeight:600, fontSize:"1.1rem" }}>{ `${ Lang[ 'BUTTON' ][ selectedLanguageShortname ]} ${ index + 1 }` }</Label>
                                  {
                                    selectedSection.carouselItems[ carouselPage ].caption.children[0].children.length > 1 && (
                                      <Button color="danger" 
                                        style={{marginLeft:"15px", fontSize:"0.8rem", padding:"3px 5px", marginBottom:"0.25rem"}}
                                        onClick={ ()=>{
                                          let tmp = _.cloneDeep( selectedSection )
                                          tmp.carouselItems[ carouselPage ].caption.children[0].children.splice( index,1 )
                                          changeButtonCenterStyleWithRow( tmp.carouselItems[ carouselPage ].caption.children[0] )
                                          return onChangeSectionHOC( 'selectedSection', tmp )
                                        }}
                                      >
                                        { Lang[ 'DELETE' ][ selectedLanguageShortname ]}
                                      </Button>
                                    )
                                  }
                                </div>
                                <FormGroup>
                                  <Label>{ Lang[ 'BUTTON_TEXT' ][ selectedLanguageShortname ] }</Label>
                                  <Input
                                    type="text"
                                    value={ item.content[ contentLang ] ? item.content[ contentLang ] : '' }
                                    onChange={ e => {
                                      let tmp = _.cloneDeep( selectedSection )
                                      tmp.carouselItems[ carouselPage ].caption.children[ 0 ].children[ index ].content[ contentLang ] = e.target.value
                                      return onChangeSectionHOC( 'selectedSection', tmp )
                                    }} />
                                </FormGroup>
                                <LinkInput 
                                  selectedContainer={ item }
                                  onUpdate={ ( updatedDom ) => {
                                    let tmp = _.cloneDeep( selectedSection )
                                    tmp.carouselItems[ carouselPage ].caption.children[ 0 ].children[ index ] = updatedDom
                                    onChangeSectionHOC( 'selectedSection', tmp )
                                  }}
                                  setMediaDatabase={ ( isOpen ) => { 
                                    if( isOpen ){
                                      onChangeSectionEditorState( 'mediaDatabaseFor', '' )
                                    } else {
                                      onChangeSectionEditorState( 'mediaDatabaseFor', 'carousel' )
                                    }
                                    toggleMediaDatabase( isOpen ) 
                                  }}
                                  pages={ pages }
                                  selectedLanguage={ selectedLanguageShortname }
                                />
                                <FormGroup>
                                  <CustomInput
                                    id={ `showCarouselButton-${ index }` }
                                    type='checkbox'
                                    label={ `${ Lang[ 'SHOW_BUTTON' ][ selectedLanguageShortname ] } ${ index + 1 }` }
                                    checked={ item.showButton !== false } 
                                    onChange={ e => {
                                      let tmp = _.cloneDeep( selectedSection )
                                      tmp.carouselItems[ carouselPage ].caption.children[ 0 ].children[ index ].showButton = e.target.checked
                                      return onChangeSectionHOC( 'selectedSection', tmp )
                                    }}/>
                                </FormGroup>
                                {
                                  selectedSection.carouselItems[ carouselPage ].caption.children[0].children.length === index + 1 &&
                                  <Button color="primary" 
                                    onClick={()=>{
                                      let tmp = _.cloneDeep( selectedSection )
                                      tmp.carouselItems[ carouselPage ].caption.children[0].children.push({ ...selectedSection.carouselItems[ carouselPage ].caption.children[0].children[index], id: uniqid() })
                                      changeButtonCenterStyleWithRow( tmp.carouselItems[ carouselPage ].caption.children[0] )
                                      return onChangeSectionHOC( 'selectedSection', tmp )
                                    }}
                                  >
                                    { Lang[ 'ADD_BUTTON' ][ selectedLanguageShortname ] }
                                  </Button>
                                }
                              </>
                            )
                          }) : (
                            selectedSection.carouselItems[ carouselPage ].caption.children && _.filter( selectedSection.carouselItems[ carouselPage ].caption.children, { type: 'button' } ).map( ( item, index ) => {
                              return(
                                <>
                                  <div>
                                    <Label style={{ fontWeight:600, fontSize:"1.1rem" }}>{ `${Lang[ 'BUTTON' ][ selectedLanguageShortname ]} ${ index + 1 }` }</Label>
                                    { 
                                      selectedSection.carouselItems[ carouselPage ].caption.children.length > 1 && (
                                        <Button color="danger" 
                                          style={{ marginLeft:"15px", fontSize:"0.8rem", padding:"3px 5px", marginBottom:"0.25rem" }}
                                          onClick={()=>{
                                            let tmp = _.cloneDeep( selectedSection )
                                            tmp.carouselItems[ carouselPage ].caption.children.splice( index,1 )
                                            changeButtonCenterStyleWithoutRow( tmp.carouselItems[ carouselPage ].caption )
                                            return onChangeSectionHOC( 'selectedSection', tmp )
                                          }}
                                        >
                                          { Lang[ 'DELETE' ][ selectedLanguageShortname ]}
                                        </Button>
                                      )
                                    }
                                  </div>
                                  <FormGroup>
                                    <Label>{ Lang[ 'BUTTON_TEXT' ][ selectedLanguageShortname ] }</Label>
                                    <Input
                                      type="text"
                                      value={ item.content[ contentLang ] ? item.content[ contentLang ] : '' }
                                      onChange={ e => {
                                        let tmp = _.cloneDeep( selectedSection )
                                        tmp.carouselItems[ carouselPage ].caption.children[ index ].content[ contentLang ] = e.target.value
                                        return onChangeSectionHOC( 'selectedSection', tmp )
                                      }} />
                                  </FormGroup>
                                  <LinkInput
                                    selectedContainer={ item }
                                    onUpdate={ ( updatedDom ) => {
                                      let tmp = _.cloneDeep( selectedSection.carouselItems[ carouselPage ].caption )
                                      let tmpSelectedSection = _.cloneDeep( selectedSection )
                                      findComponentAndPatch( updatedDom, tmp )
                                      tmpSelectedSection.carouselItems[ carouselPage ].caption = tmp
                                      onChangeSectionHOC( 'selectedSection', tmpSelectedSection )
                                    }}
                                    setMediaDatabase={ isOpen => { 
                                      if( isOpen ){
                                        onChangeSectionEditorState( 'mediaDatabaseFor', '' )
                                      } else {
                                        onChangeSectionEditorState( 'mediaDatabaseFor', 'carousel' )
                                      }
                                      toggleMediaDatabase( isOpen )
                                    }}
                                    pages={ pages }
                                    selectedLanguage={ selectedLanguageShortname }
                                  />
                                  <FormGroup>
                                    <CustomInput
                                      id={ `showCarouselButton-${ index }` }
                                      type='checkbox'
                                      label={ `${ Lang[ 'SHOW_BUTTON' ][ selectedLanguageShortname ] } ${ index + 1 }` }
                                      checked={ item.showButton !== false }
                                      onChange={ e => {
                                        let tmp = _.cloneDeep( selectedSection )
                                        tmp.carouselItems[ carouselPage ].caption.children[ index ].showButton = e.target.checked
                                        return onChangeSectionHOC( 'selectedSection', tmp )
                                      }}/>
                                  </FormGroup>
                                  {
                                    selectedSection.carouselItems[ carouselPage ].caption.children.length === index + 1 && (
                                      <Button color="primary"
                                        onClick={()=>{
                                          let tmp = _.cloneDeep( selectedSection )
                                          tmp.carouselItems[ carouselPage ].caption.children.push({ ...selectedSection.carouselItems[ carouselPage ].caption.children[ index ], id: uniqid() })
                                          changeButtonCenterStyleWithoutRow( tmp.carouselItems[ carouselPage ].caption )
                                          return onChangeSectionHOC( 'selectedSection', tmp )
                                        }}
                                      >
                                        { Lang[ 'ADD_BUTTON' ][ selectedLanguageShortname ]}
                                      </Button>
                                    )
                                  }
                                </>
                              )
                            })
                          )
                        }
                      </>
                    }
                  </>
                  :
                  <div style={{ display:"block" }}>
                    <Button color="primary" 
                      style={{ marginBottom:"10px", marginRight:"10px" }}
                      onClick={ ()=>{
                        let tmp = _.cloneDeep( selectedSection )
                        if( tmp.carouselItems[ carouselPage ].caption.children === undefined ){
                          tmp.carouselItems[ carouselPage ].caption.children = []
                        }
                        tmp.carouselItems[ carouselPage ].caption.children.push(
                          {
                            id: uniqid(),
                            type:'div',
                            className: "row m-0",
                            style: {
                              width: "100%",
                              justifyContent: "center"
                            },
                            children:[
                              {
                                id: uniqid(),
                                type: "button",
                                style: {
                                  padding: "8px 20px",
                                  color: "#ffffff",
                                  background: "#000000",
                                  border: "1px solid #ffffff",
                                  outline: "2px solid #000000",
                                  fontWeight: "500",
                                  lineHeight: "1em",
                                  margin: "10px",
                                  letterSpacing: "-0.025em",
                                  textTransform: "uppercase",
                                  "@media (min-width: 768px)": {
                                    fontSize: "1rem"
                                  }
                                },
                                content: {
                                  en: "<span style=\"display: inline-block; text-transform: uppercase;\">Try it now</span>",
                                  de: "<span style=\"display: inline-block; text-transform: uppercase;\">Versuchen Sie es jetzt</span>",
                                  it: "<span style=\"display: inline-block; text-transform: uppercase;\">Provalo ora</span>",
                                  nl: "<span style=\"display: inline-block; text-transform: uppercase;\">Probeer het nu</span>"
                                }
                              }
                            ]
                          }
                        )
                        return onChangeSectionHOC( 'selectedSection', tmp )
                      }}
                    >
                      { Lang[ 'ADD_BUTTON' ][ selectedLanguageShortname ] + " (INJOY)" }
                    </Button>
                    <Button color="primary"
                      style={{ marginBottom:"10px", marginRight:"10px" }} 
                      onClick={()=>{
                        let tmp = _.cloneDeep( selectedSection )
                        if( tmp.carouselItems[ carouselPage ].caption.children === undefined ){
                          tmp.carouselItems[ carouselPage ].caption.children = []
                        }
                        tmp.carouselItems[ carouselPage ].caption.children.push(
                          {
                            id: uniqid(),
                            type:'div',
                            className: "row m-0",
                            style: {
                              width: "100%",
                              justifyContent: "center"
                            },
                            children:[
                              {
                                id: uniqid(),
                                type: "button",
                                style: {
                                  color: "#fff",
                                  ":hover": {
                                    color: "#000",
                                    background: "#fff"
                                  },
                                  border: "1px solid #fff",
                                  padding: "10px 20px",
                                  fontSize: "0.875rem",
                                  position: "relative",
                                  margin: "10px",
                                  background: "transparent",
                                  transition: "all 0.4s linear",
                                  "@media (min-width: 768px)": {
                                    fontSize: "1rem"
                                  }
                                },
                                content: {
                                  de: "<span class=\"vital-carousel-button-text\" style=\"position: relative; z-index: 2; color: #ffffff;\">MEHR INFO</span>",
                                  en: "<span class=\"vital-carousel-button-text\" style=\"position: relative; z-index: 2; color: #ffffff;\">MORE INFO</span>",
                                  it: "<span class=\"vital-carousel-button-text\" style=\"position: relative; z-index: 2; color: #ffffff;\">MAGGIORI INFORMAZIONI</span>",
                                  nl: "<span class=\"vital-carousel-button-text\" style=\"position: relative; z-index: 2; color: #ffffff;\">MEER INFO</span>"
                                },
                                className: "vital-carousel-button-hover-animation"
                              }
                            ]
                          }
                        )
                        return onChangeSectionHOC( 'selectedSection', tmp )
                      }}
                    >
                      { Lang[ 'ADD_BUTTON' ][ selectedLanguageShortname ] + " (VITAL)" }
                    </Button>
                  </div>
                }
              </>
            )
          }
        </Form>
      </CardBody>
      {
        ( mediaDatabaseFor === 'carousel' ||  mediaDatabaseFor === 'carousel-header' ) && (
          <MediaDatabase
            onSelectedImage={( param ) => {
              let tmp = _.cloneDeep( selectedSection )
              if( mediaDatabaseFor === 'carousel' ){
                tmp.carouselItems[ carouselPage ].bgImg = param
              } else {
                _.find( tmp.carouselItems[ carouselPage ].caption.header.children, { type: "img" } ).source = param
              }
              onChangeSectionHOC( 'selectedSection', tmp )
              toggleMediaDatabase( false )
            }} />
        )
      }
      <ImagePosition
        selectedDom={ selectedDOM }
        onEdit={ onEdit }
        showModal={ showImagePositionModal }
        setShowModal={ val => setShowImagePositionModal( val ) }
        stylesheets={ stylesheets }
        javascripts={ javascripts }
        selectedLanguageShortname={ selectedLanguageShortname }
        style={ !_.isEmpty( selectedDOM ) && selectedDOM.carouselItems[0].style } />
    </Card>
  )
}

export default CarouselForm