import React from 'react'
import { Container } from 'reactstrap'

import RenderDOM from '../RenderDOM'

const ContainerComponent = ({
  content,
  mode,
  onChangeFormBuilderState,
  formValues,
  onPatchFormValues,
  onClickSubmitForm,
  createSubmittedFormMedia,
  dom,
  findObjectAndPatch,
  currentLanguage
}) => {
  return (
    <Container
      style={ content.style }>
      {
        content.children && content.children.map( item => {
          return RenderDOM(
            item,
            onChangeFormBuilderState,
            mode,
            formValues,
            onPatchFormValues,
            onClickSubmitForm,
            createSubmittedFormMedia,
            dom,
            findObjectAndPatch,
            currentLanguage
          )
        })
      }
    </Container>
  )
}

export default ContainerComponent
