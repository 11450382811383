import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { MdEdit } from 'react-icons/md'
import { FaRegTrashAlt } from 'react-icons/fa'
import { Draggable } from "react-beautiful-dnd"

import RenderDOM from '../RenderDOM'

const getItemStyle = ( isDragging, draggableStyle ) => ({
  userSelect: "none",
  background: isDragging ? "lightgreen" : "transparent",
  ...draggableStyle
})

const ColumnComponent = ({
  content,
  formValues,
  mode,
  onChangeFormBuilderState,
  onPatchFormValues,
  onClickSubmitForm,
  createSubmittedFormMedia,
  dom,
  findObjectAndPatch,
  currentLanguage
}) => {
  const [ isHovered, setHovered ] = useState( false )

  return (
    <>
      {
        mode === 'edit' ? (
          <Draggable key={ content.id } draggableId={ content.id } index={ content.rowComponentIndex }>
            {( provided, snapshot ) => (
              <div
                ref={ provided.innerRef }
                { ...provided.draggableProps }
                { ...provided.dragHandleProps }
                className={ `col-md-${ content.grid }` }
                style={{
                  padding: '0',
                  position: 'relative',
                  border: isHovered? 'solid 2.5px lightgreen': 'none',
                  borderRadius: '5px',
                  ...getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                  )
                }}
                onMouseEnter={ () => setHovered( true ) }
                onMouseLeave={ () => setHovered( false ) }>
                {
                  content.children && content.children.map( item => {
                    return RenderDOM(
                      item,
                      onChangeFormBuilderState,
                      mode,
                      formValues,
                      onPatchFormValues,
                      onClickSubmitForm,
                      createSubmittedFormMedia,
                      dom,
                      findObjectAndPatch,
                      currentLanguage
                    )
                  })
                }
                {
                  !content.children && (
                    <div>
                      <span>Put something here.</span>
                    </div>
                  )
                }
                <div style={{ position: 'absolute', top: 5, right: 5 }}>
                  {
                    content.children[ 0 ].type !== 'Button' && (
                      <Button
                        style={{ float: 'right', marginLeft: '3px' }}
                        disabled={ content.children[ 0 ].type === 'Button' }
                        onClick={ async() => {
                          await onChangeFormBuilderState( 'selectedComponentToEdit', {
                            ...content
                          })
                          await onChangeFormBuilderState( 'showUpdateColumnModal', true )
                        }}>
                        <MdEdit color="#fff" size={ 16 } />
                      </Button>
                    )
                  }
                  <Button
                    color="danger"
                    style={{ float: 'right' }}
                    onClick={ async() => {
                      await onChangeFormBuilderState( 'selectedComponentToRemove', content )
                      await onChangeFormBuilderState( 'showConfirmDelete', true )
                    }}>
                    <FaRegTrashAlt color="#fff" size={ 16 } />
                  </Button>
                </div>
              </div>
            )}
          </Draggable>
        ) : (
          <div
            className={ `col-md-${ content.grid }` }
            style={{ padding: '5px', position: 'relative' }}>
            {
              content.children && content.children.map( item => {
                return RenderDOM(
                  item,
                  onChangeFormBuilderState,
                  mode,
                  formValues,
                  onPatchFormValues,
                  onClickSubmitForm,
                  createSubmittedFormMedia,
                  dom,
                  findObjectAndPatch,
                  currentLanguage
                )
              })
            }
            {
              !content.children && (
                <div>
                  <span>Put something here.</span>
                </div>
              )
            }
            {
              mode === 'edit' && <Button
                style={{ position: 'absolute', top: 5, right: 5 }}
                onClick={ async() => {
                  await onChangeFormBuilderState( 'showUpdateColumnModal', true )
                  await onChangeFormBuilderState( 'selectedComponentToEdit', {
                    ...content
                  })
                }}>
                <MdEdit color="#fff" size={ 16 } />
              </Button>
            }
            {
              mode === 'edit' && <Button
                color="danger"
                style={{ position: 'absolute', top: 5, right: 50 }}
                onClick={ async() => {
                  await onChangeFormBuilderState( 'selectedComponentToRemove', content )
                  await onChangeFormBuilderState( 'showConfirmDelete', true )
                }}>
                <FaRegTrashAlt color="#fff" size={ 16 } />
              </Button>
            }
          </div>
        )
      }
    </>
  )
}

export default ColumnComponent
