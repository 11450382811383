import React, { Component } from 'react'
import Moment from 'moment'

import { Get } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state={ 
      loading: false,
      eventTemplates: []
    }

    load = param => this.setState({ loading: param })

    getEventTemplates = () => Get(
      `/api/v1/ark_member_area/am_event_templates?query=${ convertObjToBase64({ is_paginated: false }) }&timezone=${ encodeURIComponent( Moment().format() ) }`,
      this.getEventTemplatesSuccess,
      this.getEventTemplatesError,
      this.load
    )
    getEventTemplatesSuccess = payload => this.setState({ 
      eventTemplates: payload.data.map( item => ({ 
        id: item.id, 
        label: item.title[ this.props.data.languageReducer.lang ], 
        value: item.title[ this.props.data.languageReducer.lang ]
      }) )  
    })
    getEventTemplatesError = () => {}

    render = () => {
      return(
        <WrappedComponent
          { ...this.props } 
          onLoadEventTemplatesHOC={ this.state.loading }
          eventTemplates={ this.state.eventTemplates }
          getEventTemplates={ this.getEventTemplates }
        />
      )
    }
  }
  return WithHOC
}

export default HOC