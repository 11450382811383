import React from 'react'
import { 
  Modal, ModalBody, ModalHeader, ModalFooter,
  Button, UncontrolledTooltip
} from 'reactstrap'

import { convertObjToBase64 } from 'utils/objToBase64'
import ARKTable from 'components/Table'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Lang from 'Lang/General'

const Archived = props => {
  return(
    <Modal size='lg' isOpen={ props.showArchivedMailings } >
      <ModalHeader toggle={ () => props.onChangeSystemMailingHOC( 'showArchivedMailings', false ) } >
        { Lang[ 'ARCHIVED_MAILINGS' ][ props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        <ARKTable
          data={ props.systemMailings.data }
          columns={[
            {
              Header: Lang[ 'TITLE' ][ props.data.languageReducer.lang ],
              accessor: 'title'
            },
            {
              Header: Lang[ 'SUBJECT' ][ props.data.languageReducer.lang ],
              accessor: 'subject'
            },
            {
              Header: Lang[ 'ACTION' ][ props.data.languageReducer.lang ],
              accessor: 'id',
              width: 100,
              Cell: ({ value }) => (
                <>
                  <Button
                    id={ `System-mailing-${ value }-unarchive` }
                    className='mb-2 mr-2 btn-icon btn-icon-only'
                    color='warning'
                    onClick={ () => props.getSelectedSystemMailing( value, true ) }>
                    <i className='pe-7s-notebook'/>
                  </Button>
                  <UncontrolledTooltip
                    target={ `System-mailing-${ value }-unarchive` }
                    placement='top'>{ Lang[ 'UNARCHIVE' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                </>
              )
            }
          ]}
          totalPages={ props.totalPagesSystemMailing }
          page={ props.systemMailings.meta ? props.systemMailings.meta.current_page : 1 }
          onChangePage={ pg => props.getSystemMailing( 
            convertObjToBase64({ 
              is_paginated: true, 
              page: pg,
              filter: {
                status: 'ARCHIVED'
              }
            }) 
          )}
          searchComponent={ false }
          showPagination={ false }
          onClickReset={ () => {} }
          onSearch={ () => {} }
          searchColumn={ [] }
        />
        { props.onLoadSystemMailings && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => props.onChangeSystemMailingHOC( 'showArchivedMailings', false ) } >
          { Lang[ 'CLOSE' ][ props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )
}

export default Archived


