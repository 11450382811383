import React, { useState, useEffect } from 'react'
import Radium from 'proofpoint-radium'
import Moment from 'moment'
import _ from 'lodash'

import CoreRenderFunction from '../core/render'
import Lang from 'Lang/General'

const Countdown = props => {
  const [ countdownDate, setCountdownDate ] = useState({
    DAYS: 0,
    HOURS: 0,
    MINUTES: 0,
    SECONDS: 0
  })
  const [ isExpired, setIsExpired ] = useState( false )

  useEffect( () => {
    let endDate = props.endDate ? Moment( props.endDate ) : Moment( Moment().add( 14, 'days' ).format( 'YYYY-MM-DD HH:mm' ) )
    const countdown = setInterval( () => {
      let distance = Moment( endDate ).valueOf() - Moment().valueOf()
      let duration = Moment.duration( distance , 'milliseconds' )
      setCountdownDate({
        DAYS: duration.days(),
        HOURS: duration.hours(),
        MINUTES: duration.minutes(),
        SECONDS: duration.seconds()
      })
      if ( distance < 0 ) {
        clearInterval( countdown )
        setIsExpired( true )
      }
    }, 1000 )
  }, [] )

  useEffect( () => {
    let endDate = props.endDate ? Moment( props.endDate ) : Moment( Moment().add( 14, 'days' ).format( 'YYYY-MM-DD HH:mm' ) )
    if( Moment( endDate ) > Moment() ) {
      setIsExpired( false )
    } else {
      setIsExpired( true )
    }
  }, [ props.endDate ] )

  const getDisplayValue = () => {
    return isExpired || Moment( props.startDate ) > Moment()  
      ? props.mode === 'section-display-edit' || props.mode === 'edit' 
        ? 'block'
        : 'none'
      : 'block'
  }

  return (
    <div
      id={ props.id }
      style={{ ...props.style, display: getDisplayValue() }}>
      <div>
        {
          ( isExpired || Moment( props.startDate ) > Moment() ) && (
            <div
              style={{
                background: 'rgb(4, 37, 78, 0.7 )',
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: '1'
              }}>
              <h2
                style={{
                  textAlign: 'center',
                  padding: '150px',
                  wordBreak: 'break-word',
                  color: '#fffff'
                }}>
                { isExpired ? Lang[ 'TEXT_AFTER_COUNTDOWN' ][ props.selectedLanguageShortname ]  :  Lang[ 'TEXT_BEFORE_COUNTDOWN' ][ props.selectedLanguageShortname ] }  
              </h2>
            </div>
          )
        }
        {
          _.find( props.children, { label: 'HEADLINE' } ) && (
            <CoreRenderFunction
              item={ _.find( props.children, { label: 'HEADLINE' } ) }
              selectedLanguageShortname={ props.selectedLanguageShortname } />
          ) 
        }
        <div className='d-flex flex-wrap justify-content-center w-100 my-4' style={{ color: props.children[ 1 ].style.color }}>
          {
            _.map( countdownDate, ( value, key ) => {
              return (
                <div style={{ width: '150px' }}>
                  <p style={{ fontSize: '32px', margin: '15px 0' }}>{ value }</p>
                  <div style={{ fontSize: '16px' }}>{ Lang[ key ][ props.selectedLanguageShortname ] }</div>
                </div>
              )
            })
          }
        </div>
        { 
          _.filter( props.children, item => item.label !== 'HEADLINE' && item.showButton !== false ).map( item => {
            return (
              <CoreRenderFunction
                item={ item }
                mode={ 'view' }
                selectedLanguageShortname={ props.selectedLanguageShortname } />
            )
          })
        }
      </div>
    </div>
  )
}

export default Radium( Countdown )