import React, { Component } from 'react'
import { compose } from 'redux'
import {
  Button, Card, CardHeader, CardBody,
  CardFooter, FormGroup, Label, Input,
  UncontrolledTooltip
} from 'reactstrap'
import _ from 'lodash'
import { connect } from 'react-redux'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PageTitle from 'components/PageTitle'
import ARKTable from 'components/Table'

import { toggleMediaDatabase } from 'actions/MediaDatabaseToggle'

import SocialMediaHOC from './actions'
import Create from './Create'
import Update from './Update'

import Lang from 'Lang/General'

class SocialMediaManagement extends Component{

  componentDidMount = () => {
    this.props.getAllSocialMediaPlatform()
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Social Media' })
      return tmpModules.is_create
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Social Media' })
      return tmpModules.is_update
    }
  }

  render = () => {
    return(
      <>
        <PageTitle
          heading={ Lang[ 'SOCIAL_MEDIA' ][ this.props.data.languageReducer.lang ] }
          icon="pe-7s-world icon-gradient bg-happy-fisher"
          buttons={[
            {
              buttonText: Lang[ 'CREATE' ][ this.props.data.languageReducer.lang ],
              onClick: () => this.props.onChangeSocialMediaHOC( 'showCreateSocialMedia', true ),
              display: this.handleSecurityModulesCreate()
            }
          ]}/>
        <ARKTable
          data={ this.props.socialMediaPlatforms }
          columns={[
            {
              Header: 'Name',
              accessor: 'platform_name'
            },
            {
              Header: 'Icon',
              accessor: 'icon_url',
              width: 150,
              Cell: ({ value }) => (
                <img src={ value } style={{ width: '100px', height: '100px' }} />
              )
            },
            {
              Header: 'Action',
              accessor: 'id',
              Cell: ({value}) => (
                <div className="w-100" style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                  <div className="text-align-center">
                    {
                      this.handleSecurityModulesUpdate() && (
                        <>
                          <Button
                            id={ `social-media-${ value }-update-user` }
                            className="mb-2 mr-2 btn-icon btn-icon-only"
                            color="primary"
                            onClick={ () => {
                              this.props.getSelectedSocialMediaPlatform( value )
                              this.props.onChangeSocialMediaHOC( 'showUpdateSocialMedia', true )
                            } }>
                            <i className="pe-7s-note"> </i>
                          </Button>
                          <UncontrolledTooltip
                            target={ `social-media-${ value }-update-user` }
                            placement="top">{ Lang[ 'UPDATE_SOCIAL_MEDIA' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                        </>
                      )
                    }
                  </div>
                </div>
              )
            }
          ]}
          totalPages={ [] }
          page={ 1 }
          showPagination={ false }/>
        <Create
          { ...this.props }
          toggleMediaDatabase={ this.props.toggleMediaDatabase } />
        <Update
          { ...this.props }
          toggleMediaDatabase={ this.props.toggleMediaDatabase } />
          { ( this.props.data.ajaxReducer.ajaxCallProgress > 0 || this.props.onLoadSocialMedia ) && <LoadingOverlay /> }
      </>
    )
  }
}
const mapStateToProps = state => ({ data: state })

export default connect ( mapStateToProps, {
  toggleMediaDatabase
})( SocialMediaHOC( SocialMediaManagement ) )
