import React from 'react'
import { FormGroup, Label, UncontrolledTooltip } from 'reactstrap'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import _ from 'lodash'
import ReactHTMLParser from 'react-html-parser'
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
import "filepond/dist/filepond.min.css"

import { generateFieldLabelStr } from '../utils'

registerPlugin( FilePondPluginImagePreview )

const InputFile = ({
  content,
  onPatchFormValues,
  formValues,
  mode,
  createSubmittedFormMedia,
  isInputDisabled,
  currentLanguage
}) => {
  return (
    <FormGroup 
      tag="fieldset"
      id={ content.id } 
      style={{ padding: content.padding, marginBottom: 0, ...content.style }}>
      <Label className="ck-content" style={{ fontSize: content.fontSize, width: "100%" }}>
        { ReactHTMLParser( generateFieldLabelStr(content, formValues, currentLanguage) ) }
        {
          content.showQuickInfo && 
          document.getElementById(`quick-info-tooltip-${content.id}`) && (
            <UncontrolledTooltip
              target={`quick-info-tooltip-${content.id}`} 
              placement="top">
              { content.preview_text }
            </UncontrolledTooltip>
          )
        }
      </Label>
      <FilePond
        disabled={ isInputDisabled }
        allowMultiple
        allowFileTypeValidation={ true }
        allowImagePreview={ true }
        acceptedFileTypes={[ 'application/pdf', 'image/png', 'image/jpeg', 'image/jpg' ]}/>
    </FormGroup>
  )
}

export default InputFile