import React, { useEffect, useState } from 'react'
import {
  Form, FormGroup, FormFeedback,
  Label, Input, CustomInput, Button,
  Row, Col
} from 'reactstrap'
import ReactTable from 'react-table'
import { toast } from 'react-toastify'
import MultiDatePicker from 'react-multi-date-picker'
import Moment from 'moment'
import TimePicker from 'rc-time-picker'
import _ from 'lodash'

import MultiLangInput from 'components/Input/MultiLang'
import MultiLangRTE from 'components/RTE/MultiLang'
import CurrencyInput from 'components/Input/CurrencyInput'
import Datepicker from 'components/Datepicker'
import MediaDatabase from 'SharedModules/MediaDatabase'
import DeleteConfirmation from 'components/Modal/delete'

import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginPdfPreview from 'filepond-plugin-pdf-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

import Lang from 'Lang/General'

import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'rc-time-picker/assets/index.css'

registerPlugin( FilePondPluginPdfPreview, FilePondPluginFileValidateType )

const WeekDays = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY'
]

const EventTemplateForm = ({
  eventTemplateType,
  currentEventTemplate,
  onChangeEventTemplatesHOC,
  typesSettings,
  toggleMediaDatabase,
  lang,
  eventTemplateErrorMessage,
  selectedEventTemplateFiles,
  createSelectedEventTemplateFile,
  removeSelectedEventTemplateFile
}) => {
  const [ files, updateFiles ] = useState( [] )
  const [ title, updateTitle ] = useState( '' )
  const [ youtubeLink, updateYoutubeLink ] = useState( '' )
  const [ deleteFileId, updateDeleteFileId ] = useState( 0 )
  const [ editingImage, setEditingImage ] = useState( '' )

  useEffect( () => {
    updateFiles( [] )
    updateTitle( '' )
    updateYoutubeLink( '' )
  }, [] )

  const updateEventTemplate = ( key, val ) => {
    let tmp = _.cloneDeep( currentEventTemplate )
    tmp[ key ] = val
    if ( key === 'repeat_day' ) {
      tmp[ 'exclude_dates' ] = []
    } else if ( key === 'is_repeat' && val ) {
      tmp.repeat_start_date = tmp.repeat_start_date 
        ? Moment( tmp.repeat_start_date ) 
        : Moment( new Date( Moment().year(), Moment().month(), Moment().date(), tmp.start_date_time.hours(), tmp.start_date_time.minutes() ) )
      tmp.repeat_end_date = tmp.repeat_end_date 
        ? Moment( tmp.repeat_end_date ) 
        : Moment( new Date( Moment().year(), Moment().month(), Moment().date(), tmp.end_date_time.hours(), tmp.end_date_time.minutes() ) ).add( 1, 'month' )
    } else if ( key === 'placement' && val === 'public' ) {
      let eventType = _.find( typesSettings, item => item.name[ lang ].indexOf( 'Livestream' ) > -1 )
      if ( eventType ) {
        tmp.am_event_type_id = eventType.id
      } else {
        tmp.placement = 'member_area'
      }
    }
    onChangeEventTemplatesHOC( eventTemplateType, tmp )
  }

  useEffect( () => {
    if( currentEventTemplate.exclude_dates.length > 0 ){
      let tmp = _.cloneDeep( currentEventTemplate )
      tmp[ 'exclude_dates' ] = _.filter( 
        tmp[ 'exclude_dates' ], 
        item => (
          Moment( item, 'DD/MM/YYYY' ).isSameOrAfter( currentEventTemplate.repeat_start_date, 'days' ) &&
          Moment( item, 'DD/MM/YYYY' ).isSameOrBefore( currentEventTemplate.repeat_end_date, 'days' )
        )
      )
      if( tmp[ 'exclude_dates' ].length !== currentEventTemplate.exclude_dates.length ) {
        onChangeEventTemplatesHOC( eventTemplateType, tmp )
      }
    }
  }, [ currentEventTemplate.repeat_start_date, currentEventTemplate.repeat_end_date ] )

  return(
    <>
      <style>
        {`
          .rc-time-picker { overflow: hidden; }
          .rc-time-picker-input { border: 0 !important; outline: none; }
        `}
      </style>
      <Form style={{ paddingLeft: "15px", paddingRight: "15px" }} >
        <Row>
          <Col md={ 8 } style={{ zIndex: "1" }} >
            <MultiLangInput
              label={ Lang[ 'TITLE' ][ lang ] }
              value={ currentEventTemplate.title }
              onChange={ val => updateEventTemplate( 'title', val ) }
              selectedLanguage={ lang }
              invalid={ eventTemplateErrorMessage.title } />
            <FormGroup style={{ position: "relative", zIndex: "11" }} >
              <Row>
                <Col md={ 6 } >
                  <Label>
                    { Lang[ 'START_DATE' ][ lang ] }
                  </Label>
                  <Datepicker
                    popperPlacement='bottom'
                    selectedDate={ currentEventTemplate.is_repeat
                      ? currentEventTemplate.repeat_start_date.toDate()
                      : currentEventTemplate.start_date_time.toDate() 
                    }
                    dateFormat='dd/MM/yyyy'
                    onChange={ e => {
                      let tmpStart = _.cloneDeep( currentEventTemplate.start_date_time )
                      let tmpNew = Moment( e )
                      if( tmpNew._isValid ){
                        tmpStart = Moment( new Date( tmpNew.year(), tmpNew.month(), tmpNew.date(), tmpStart.hours(), tmpStart.minutes() ) )
                        if( tmpStart <= Moment() ){
                          toast.error( Lang[ 'PLEASE_SELECT_A_DATE_TIME_LATER_THAN_TODAY' ][ lang ] )
                        } else {
                          let tmpEventTemplate = _.cloneDeep( currentEventTemplate )
                          tmpEventTemplate.repeat_start_date = _.cloneDeep( tmpStart )
                          tmpEventTemplate.start_date_time = _.cloneDeep( tmpStart )
                          if( currentEventTemplate.repeat_end_date <= tmpStart ){
                            tmpEventTemplate.repeat_end_date = _.cloneDeep( tmpStart ).add( 1, 'week' )
                          }
                          if ( currentEventTemplate.end_date_time <= tmpStart ) {
                            tmpEventTemplate.end_date_time = _.cloneDeep( tmpStart ).add( 30, 'minutes' )
                          } else if ( currentEventTemplate.end_date_time.diff( tmpStart, 'minutes' ) > 480 ){
                            tmpEventTemplate.end_date_time = _.cloneDeep( tmpStart ).add( 8, 'hours' ) 
                          }
                          onChangeEventTemplatesHOC( eventTemplateType, tmpEventTemplate )
                        }
                      }
                    }} 
                  />
                </Col>
                {
                  currentEventTemplate.is_repeat && (
                    <Col md={ 6 } >
                      <Label>{ Lang[ 'END_DATE' ][ lang ] }</Label>
                      <Datepicker
                        popperPlacement='bottom'
                        selectedDate={ currentEventTemplate.repeat_end_date.toDate() }
                        dateFormat='dd/MM/yyyy'
                        onChange={ e => {
                          let tmpEnd = _.cloneDeep( currentEventTemplate.end_date_time )
                          let tmpNew = Moment( e )
                          if( tmpNew._isValid ){
                            tmpEnd = Moment( new Date( tmpNew.year(), tmpNew.month(), tmpNew.date(), tmpEnd.hours(), tmpEnd.minutes() ) )
                            if( tmpEnd <= Moment() ){
                              toast.error( Lang[ 'PLEASE_SELECT_A_DATE_TIME_LATER_THAN_TODAY' ][ lang ] )
                            } else {
                              let tmpEventTemplate = _.cloneDeep( currentEventTemplate )
                              tmpEventTemplate.repeat_end_date = tmpEnd
                              if( tmpEnd <= currentEventTemplate.repeat_start_date ){
                                tmpEventTemplate.repeat_end_date = _.cloneDeep( currentEventTemplate.repeat_start_date ).add( 1, 'week' )
                              }
                              onChangeEventTemplatesHOC( eventTemplateType, tmpEventTemplate )
                            }
                          }
                        }} 
                      />
                    </Col>
                  )
                }
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={ 6 } >
                  <CustomInput
                    id='is_repeat'
                    type='checkbox'
                    className='mr-2'
                    checked={ currentEventTemplate.is_repeat }
                    label={ Lang[ 'IS_REPEAT' ][ lang ] }
                    onChange={ e => updateEventTemplate( 'is_repeat', e.target.checked ) } />
                </Col>
                {
                  currentEventTemplate.is_repeat && <Col md={ 6 } >
                    <Input
                      type='select'
                      value={ currentEventTemplate.repeat_day }
                      onChange={ e => updateEventTemplate( 'repeat_day', parseInt( e.target.value ) ) } >
                      { WeekDays.map( ( day, index ) => <option value={ index } >{ Lang[ day ][ lang ] }</option> ) }
                    </Input>
                  </Col>
                }
              </Row>
            </FormGroup>
            {
              currentEventTemplate.is_repeat && <FormGroup style={{ position: "relative", zIndex: "10" }} >
                <Label>{ Lang[ 'EXCLUDED_DATES' ][ lang ] }</Label>
                <MultiDatePicker
                  inputClass='form-control'
                  format='DD/MM/YYYY'
                  containerStyle={{ display: "block" }}
                  value={ currentEventTemplate.exclude_dates }
                  onChange={ e => updateEventTemplate( 'exclude_dates', e.map( item => item.format( 'DD/MM/YYYY' ) ) ) }
                  mapDays={ ({ date }) => {
                    let tmpMoment = Moment( new Date( date ) )
                    return( 
                      date.weekDay.index === currentEventTemplate.repeat_day &&
                      tmpMoment.isSameOrAfter( currentEventTemplate.repeat_start_date, 'days' ) &&
                      tmpMoment.isSameOrBefore( currentEventTemplate.repeat_end_date, 'days' )
                        ? {} 
                        : { hidden: true } 
                    )
                  }}
                />
              </FormGroup>
            }
            <FormGroup style={{ position: "relative", zIndex: "2" }} >
              <Label>{ Lang[ 'START_TIME' ][ lang ] }</Label>
              <TimePicker
                format='HH:mm'
                className='form-control w-100'
                value={ currentEventTemplate.is_repeat ? currentEventTemplate.repeat_start_date : currentEventTemplate.start_date_time }
                showSecond={ false }
                onChange={ val => {
                  if( val ){
                    let tmp = _.cloneDeep( currentEventTemplate.start_date_time )
                    let tmpRepeatStart = _.cloneDeep( currentEventTemplate.repeat_start_date || currentEventTemplate.start_date_time )
                    tmp = Moment( new Date( tmp.year(), tmp.month(), tmp.date(), val.hours(), val.minutes() ) )
                    tmpRepeatStart = Moment( new Date( tmpRepeatStart.year(), tmpRepeatStart.month(), tmpRepeatStart.date(), val.hours(), val.minutes() ) )
                    if( tmp.utcOffset( '+0100', true ) <= Moment() ){
                      toast.error( Lang[ 'PLEASE_SELECT_A_DATE_TIME_LATER_THAN_TODAY' ][ lang ] )
                    } else {
                      let tmpEventTemplate = _.cloneDeep( currentEventTemplate )
                      tmpEventTemplate.start_date_time = tmp
                      tmpEventTemplate.repeat_start_date = tmpRepeatStart
                      if( currentEventTemplate.repeat_end_date <= tmpRepeatStart ){
                        tmpEventTemplate.repeat_end_date = _.cloneDeep( tmpRepeatStart ).add( 1, 'week' )
                      }
                      if( currentEventTemplate.end_date_time.diff( tmp, 'minutes' ) < 1 ){
                        tmpEventTemplate.end_date_time = _.cloneDeep( tmp ).add( 30, 'minutes' )
                      } else if ( currentEventTemplate.end_date_time.diff( tmp, 'minutes' ) > 480 ){
                        tmpEventTemplate.end_date_time = _.cloneDeep( tmp ).add( 8, 'hours' ) 
                      }
                      onChangeEventTemplatesHOC( eventTemplateType, tmpEventTemplate ) 
                    }
                  }
                }} 
              />
            </FormGroup>
            <FormGroup style={{ position: "relative", zIndex: "1" }} >
              <Label>{ Lang[ 'END_TIME' ][ lang ] }</Label>
              <TimePicker
                format='HH:mm'
                className='form-control w-100'
                value={ currentEventTemplate.end_date_time }
                showSecond={ false }
                onChange={ val => {
                  if( val ){
                    let tmp = _.cloneDeep( currentEventTemplate.end_date_time )
                    let tmpRepeatEnd = _.cloneDeep( currentEventTemplate.repeat_end_date || currentEventTemplate.end_date_time )
                    tmp = Moment( new Date( tmp.year(), tmp.month(), tmp.date(), val.hours(), val.minutes() ) )
                    tmpRepeatEnd = Moment( new Date( tmpRepeatEnd.year(), tmpRepeatEnd.month(), tmpRepeatEnd.date(), val.hours(), val.minutes() ) )
                    if( tmp.utcOffset( '+0100', true ) <= Moment() ){
                      toast.error( Lang[ 'PLEASE_SELECT_A_DATE_TIME_LATER_THAN_TODAY' ][ lang ] )
                    } else {
                      let tmpEventTemplate = _.cloneDeep( currentEventTemplate )
                      tmpEventTemplate.end_date_time = tmp
                      tmpEventTemplate.repeat_end_date = tmpRepeatEnd
                      if( tmpRepeatEnd <= currentEventTemplate.repeat_start_date ){
                        tmpEventTemplate.repeat_end_date = _.cloneDeep( currentEventTemplate.repeat_start_date ).add( 1, 'week' )
                      } 
                      if( tmp <= currentEventTemplate.start_date_time ){
                        tmpEventTemplate.end_date_time = _.cloneDeep( currentEventTemplate.start_date_time ).add( 30, 'minutes' )
                      } else if ( tmp.diff( currentEventTemplate.start_date_time, 'minutes' ) > 480 ){
                        tmpEventTemplate.end_date_time = _.cloneDeep( currentEventTemplate.start_date_time ).add( 8, 'hours' )
                      }
                      onChangeEventTemplatesHOC( eventTemplateType, tmpEventTemplate )
                    }
                  }
                }} 
              />
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'THUMBNAIL_IMAGE' ][ lang ] }</Label>
              <div className='d-flex flex-column w-100' >
                <Input 
                  disabled
                  type={ 'text' } 
                  value={ currentEventTemplate.image_url } 
                  style={{ marginRight: '5px', borderRadius: "0.25rem 0.25rem 0 0" }} />
                <div 
                  src={ currentEventTemplate.image_url } 
                  style={{
                    width: "100%",
                    height: "200px",
                    backgroundColor: _.isEmpty( currentEventTemplate.image_url ) ? "transparant" : "#000000",
                    backgroundImage: _.isEmpty( currentEventTemplate.image_url ) ? '' : `url(${ currentEventTemplate.image_url })`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center center",
                    border: _.isEmpty( currentEventTemplate.image_url ) ? "1.7px solid #ced4da" : "0 solid #000000",
                    borderTop: "0",
                    borderRadius: "0 0 0.25rem 0.25rem",
                    marginBottom: "8px" 
                  }} />
              </div>
              <Button
                style={{ width: "max-content" }}
                color='primary'
                onClick={ () => {
                  setEditingImage( 'image_url' )
                  toggleMediaDatabase( true ) 
                }}>
                { Lang[ 'SELECT_IMAGE' ][ lang ] }
              </Button>
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'BANNER_IMAGE' ][ lang ] }</Label>
              <div className='d-flex flex-column w-100' >
                <Input 
                  disabled
                  type={ 'text' } 
                  value={ currentEventTemplate.banner_image_url } 
                  style={{ marginRight: '5px', borderRadius: "0.25rem 0.25rem 0 0" }} />
                <div 
                  src={ currentEventTemplate.banner_image_url } 
                  style={{
                    width: "100%",
                    height: "200px",
                    backgroundColor: _.isEmpty( currentEventTemplate.banner_image_url ) ? "transparant" : "#000000",
                    backgroundImage: _.isEmpty( currentEventTemplate.banner_image_url ) ? '' : `url(${ currentEventTemplate.banner_image_url })`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center center",
                    border: _.isEmpty( currentEventTemplate.banner_image_url ) ? "1.7px solid #ced4da" : "0 solid #000000",
                    borderTop: "0",
                    borderRadius: "0 0 0.25rem 0.25rem"
                  }} />
              </div>
              <div className='font-italic my-1'><small>{ Lang[ 'SUGGESTED_IMAGE_SIZE' ][ lang ] }</small></div>
              <Button
                style={{ width: "max-content" }}
                color='primary'
                onClick={ () => {
                  setEditingImage( 'banner_image_url' )
                  toggleMediaDatabase( true ) 
                }}>
                { Lang[ 'SELECT_IMAGE' ][ lang ] }
              </Button>
            </FormGroup>
            <MultiLangRTE
              label={ Lang[ 'BRIEF_DESCRIPTION' ][ lang ] }
              value={ currentEventTemplate.brief_description }
              onChange={ val => updateEventTemplate( 'brief_description', val ) }
              selectedLanguage={ lang }
              invalid={ eventTemplateErrorMessage.brief_description } />
            <MultiLangRTE
              label={ Lang[ 'DESCRIPTION' ][ lang ] }
              value={ currentEventTemplate.description }
              onChange={ val => updateEventTemplate( 'description', val ) }
              selectedLanguage={ lang }
              invalid={ eventTemplateErrorMessage.description } />
            <FormGroup>
              <Label>{ Lang[ 'YOUTUBE_LINKS' ][ lang ] }</Label>
              <Row className='mb-3' style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <Col sm={ 9 } className='pr-0'>
                  <Input
                    type='text'
                    value={ youtubeLink }
                    className='w-100'
                    placeholder={ Lang[ 'LINK' ][ lang ] }
                    onChange={ e => updateYoutubeLink( e.target.value ) } />
                </Col>
                <Col sm={ 3 }>
                  <Button
                    color='primary'
                    className='w-100'
                    disabled={ _.isEmpty( youtubeLink ) }
                    onClick={ () => { 
                      if( _.findIndex( currentEventTemplate.youtube_links, { url: youtubeLink } ) === -1 ){
                        let tmpLinks = _.cloneDeep( currentEventTemplate.youtube_links )
                        tmpLinks.push({ url: youtubeLink })
                        updateEventTemplate( 'youtube_links', tmpLinks )
                        updateYoutubeLink( '' )
                      }
                    }}>
                    { Lang[ 'SUBMIT' ][ lang ] }
                  </Button>
                </Col>
              </Row>
              <ReactTable
                data={ currentEventTemplate.youtube_links }
                columns={[
                  {
                    Header: Lang[ 'LINK' ][ lang ],
                    accessor: 'url',
                    style: { lineBreak: 'anywhere' }
                  },
                  {
                    Header: Lang[ 'ACTIONS' ][ lang ],
                    accessor: 'url',
                    style:{
                      display: 'flex',
                      justifyContent: 'center'
                    },
                    Cell: ({ value }) => (
                      <Button
                        color='danger'
                        onClick={ () => {
                          let tmpLinks = _.cloneDeep( currentEventTemplate.youtube_links )
                          tmpLinks.splice( _.findIndex( currentEventTemplate.youtube_links, { url: value } ), 1 )
                          updateEventTemplate( 'youtube_links', tmpLinks )
                          updateYoutubeLink( '' )
                        }}>
                        { Lang[ 'DELETE' ][ lang ] }
                      </Button>
                    )
                  }
                ]}
                pageSize={ 5 }
                showPagination={ true } 
              />
            </FormGroup>
            {
              eventTemplateType === 'selectedEventTemplate' && <>
                <FormGroup>
                  <Label>{ Lang[ 'UPLOAD_FILES' ][ lang ] }</Label>
                  <FilePond
                    files={ files }
                    instantUpload={ false }
                    allowFileEncode={ true }
                    allowMultiple={ false }
                    allowFileTypeValidation={ true }
                    allowPdfPreview={ true }
                    onupdatefiles={ fileItems => {
                      let tmpFiles = []
                      fileItems.map( item => tmpFiles.push( item.file ) )
                      updateFiles( tmpFiles )
                    }}
                  />
                  <Row style={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <Col sm={ 9 } className='pr-0'>
                      <Input
                        type='text'
                        value={ title }
                        className='w-100'
                        placeholder={ Lang[ 'TITLE' ][ lang ] }
                        onChange={ e => updateTitle( e.target.value ) } />
                    </Col>
                    <Col sm={ 3 }>
                      <Button
                        color='primary'
                        className='w-100'
                        disabled={ files.length === 0 }
                        onClick={ () => { 
                          let reader = new FileReader()
                          reader.onload = () => {
                            let tmp = {
                              file: {
                                raw: reader.result,
                                filename: files[ 0 ].name
                              },
                              title: !_.isEmpty( title ) ? title : files[ 0 ].name.substr( 0, files[ 0 ].name.lastIndexOf( '.' ) )
                            }
                            createSelectedEventTemplateFile( currentEventTemplate.id, tmp )
                          }
                          reader.readAsDataURL( files[ 0 ] )
                          updateFiles( [] )
                          updateTitle( '' )
                        }}>
                        { Lang[ 'SUBMIT' ][ lang ] }
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label>{ Lang[ 'CURRENT_UPLOADED_FILES' ][ lang ] }</Label>
                  <ReactTable
                    data={ selectedEventTemplateFiles }
                    columns={[
                      {
                        Header: Lang[ 'TITLE' ][ lang ],
                        accessor: 'title',
                        Cell: row => row.original.title
                      },
                      {
                        Header: Lang[ 'ACTIONS' ][ lang ],
                        accessor: 'id',
                        style:{
                          display: 'flex',
                          justifyContent: 'center'
                        },
                        Cell: row => (
                          <>
                            {
                              row.original.file.url && <Button
                                className='mr-2'
                                color='primary'
                                onClick={ () => window.open( row.original.file.url ) }>
                                { Lang[ 'DOWNLOAD_FILE' ][ lang ] }
                              </Button>
                            }
                            <Button
                              color='danger'
                              onClick={ () => updateDeleteFileId( row.original.id ) }>
                              { Lang[ 'DELETE' ][ lang ] }
                            </Button>
                          </>
                        )
                      }
                    ]}
                    pageSize={ 5 }
                    showPagination={ true } 
                  />
                </FormGroup>
                <DeleteConfirmation
                  handleModalClose={ () => updateDeleteFileId( 0 ) }
                  deleteOpen={ deleteFileId > 0 }
                  confirmAction={ () => {
                    removeSelectedEventTemplateFile( currentEventTemplate.id, deleteFileId ) 
                    updateDeleteFileId( 0 )
                  }}
                  content={ Lang[ 'DELETE_CONFIRMATION_FILE' ][ lang ] }
                  lang={ lang } />
              </>
            }
          </Col>
          <Col md={ 4 } style={{ zIndex: "0" }} >
            <FormGroup>
              <Label>{ Lang[ 'EVENT_PLACEMENT' ][ lang ] }</Label>
              <div className='d-flex flex-row flex-nowrap'>
                <CustomInput
                  id='public'
                  type='radio'
                  className='mr-2'
                  checked={ currentEventTemplate.placement === 'public' }
                  label={ Lang[ 'PUBLIC' ][ lang ] }
                  onChange={ () => updateEventTemplate( 'placement', 'public' ) } />
                <CustomInput
                  id='member_area'
                  type='radio'
                  checked={ currentEventTemplate.placement === 'member_area' }
                  label={ Lang[ 'MEMBER_AREA' ][ lang ] }
                  onChange={ () => updateEventTemplate( 'placement', 'member_area' ) } />
              </div>
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'TYPE' ][ lang ] }</Label>
              <Input
                type='select'
                disabled={ currentEventTemplate.placement === 'public' }
                value={ currentEventTemplate.am_event_type_id } 
                onChange={ e => updateEventTemplate( 'am_event_type_id', parseInt( e.target.value ) ) }
                invalid={ eventTemplateErrorMessage.am_event_type_id } >
                <option value={ 0 } ></option>
                { typesSettings.map( eventType => <option value={ eventType.id } >{ eventType.name[ lang ] }</option> ) } 
              </Input>
              <FormFeedback>{ eventTemplateErrorMessage.am_event_type_id }</FormFeedback>
            </FormGroup>
            {
              currentEventTemplate.placement !== 'public' && (
                <>
                  <FormGroup>
                    <Label>{ Lang[ 'EVENT_FOR' ][ lang ] }</Label>
                    <CustomInput
                      id='is_for_member'
                      type='checkbox'
                      checked={ currentEventTemplate.is_for_member }
                      label={ Lang[ 'MEMBERS' ][ lang ] }
                      onChange={ e => updateEventTemplate( 'is_for_member', e.target.checked ) } />
                    <CustomInput
                      id='is_for_program_participant'
                      type='checkbox'
                      checked={ currentEventTemplate.is_for_program_participant }
                      label={ Lang[ 'PROGRAM_PARTICIPANTS' ][ lang ] }
                      onChange={ e => updateEventTemplate( 'is_for_program_participant', e.target.checked ) } />
                    <CustomInput
                      id='is_for_trial_member'
                      type='checkbox'
                      checked={ currentEventTemplate.is_for_trial_member }
                      label={ Lang[ 'TRIAL_MEMBERS' ][ lang ] }
                      onChange={ e => updateEventTemplate( 'is_for_trial_member', e.target.checked ) } />
                    <CustomInput
                      type='checkbox'
                      checked={ true }
                      label={ Lang[ 'EXPERTS' ][ lang ] } />
                  </FormGroup>
                  <FormGroup>
                    <Label>{ Lang[ 'EVENT_PROPERTIES' ][ lang ] }</Label>
                    <CustomInput
                      id='has_in_app_offer'
                      type='checkbox'
                      checked={ currentEventTemplate.has_in_app_offer }
                      label={ Lang[ 'HAS_IN_APP_OFFER' ][ lang ] }
                      onChange={ e => updateEventTemplate( 'has_in_app_offer', e.target.checked ) } />
                  </FormGroup>
                  <FormGroup>
                    <Label>{ Lang[ 'PRICE' ][ lang ] }</Label>
                    <CurrencyInput
                      value={ currentEventTemplate.price }
                      invalid={ eventTemplateErrorMessage.price }
                      onChangeValue={ e => {
                        let tmp = _.cloneDeep( e )
                        if( e ){  
                          tmp = tmp.replace( /[\.,]$/g, ',0' )
                        }
                        updateEventTemplate( 'price', e ? tmp.replace( ',', '.' ) : '' )
                      }} />
                  </FormGroup>
                  {/* <FormGroup>
                    <Label>{ Lang[ 'UPSELL_PRICE' ][ lang ] }</Label>
                    <CurrencyInput
                      value={ currentEventTemplate.upsell_price }
                      onChangeValue={ e => {
                        let tmp = _.cloneDeep( e )
                        if( e ){  
                          tmp = tmp.replace( /[\.,]$/g, ',0' )
                        }
                        updateEventTemplate( 'upsell_price', e ? tmp.replace( ',', '.' ) : '' )
                      }} />
                  </FormGroup> */}
                  <FormGroup>
                    <Label>{ Lang[ 'MEMBER_PRICE' ][ lang ] }</Label>
                    <CurrencyInput
                      value={ currentEventTemplate.price_member }
                      invalid={ eventTemplateErrorMessage.price_member }
                      onChangeValue={ e => {
                        let tmp = _.cloneDeep( e )
                        if( e ){  
                          tmp = tmp.replace( /[\.,]$/g, ',0' )
                        }
                        updateEventTemplate( 'price_member', e ? tmp.replace( ',', '.' ) : '' )
                      }} />
                  </FormGroup>
                  <FormGroup>
                    <CustomInput
                      id='is_free_for_member'
                      type='checkbox'
                      checked={ currentEventTemplate.is_free_for_member }
                      label={ Lang[ 'FREE_FOR_MEMBERS' ][ lang ] }
                      onChange={ e => updateEventTemplate( 'is_free_for_member', e.target.checked ) } />
                    <CustomInput
                      id='is_free_for_campaign_participant'
                      type='checkbox'
                      checked={ currentEventTemplate.is_free_for_campaign_participant }
                      label={ Lang[ 'FREE_FOR_PROGRAM_PARTICIPANTS' ][ lang ] }
                      onChange={ e => updateEventTemplate( 'is_free_for_campaign_participant', e.target.checked ) } />
                    <CustomInput
                      id='is_free_for_trial_member'
                      type='checkbox'
                      checked={ currentEventTemplate.is_free_for_trial_member }
                      label={ Lang[ 'FREE_FOR_TRIAL_MEMBERS' ][ lang ] }
                      onChange={ e => updateEventTemplate( 'is_free_for_trial_member', e.target.checked ) } />
                    <CustomInput
                      type='checkbox'
                      checked={ true }
                      label={ Lang[ 'FREE_FOR_EXPERTS' ][ lang ] } />
                  </FormGroup>
                </>
              )
            }
          </Col>
        </Row>
      </Form>
      <MediaDatabase
        onSelectedImage={( param ) => {
          updateEventTemplate( editingImage, param )
          toggleMediaDatabase( false )
        }} 
      />
    </>
  )
}

export default EventTemplateForm