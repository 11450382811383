import React from 'react'
import { Form, FormGroup, Label, Input } from 'reactstrap'
import _ from 'lodash'

import MultiLangInput from 'components/Input/MultiLang'
import MultiLangRTE from 'components/RTE/MultiLang'

import Lang from 'Lang/General'

const ArticleForm = ({
  currentArticle,
  articleType,
  onChangeArticleFourteenHOC,
  lang
}) => {
  
  const onChangeArticleData = ( key, val ) => {
    let tmp = _.cloneDeep( currentArticle )
    tmp[ key ] = val
    return onChangeArticleFourteenHOC( articleType, tmp )
  }
  
  return (
    <Form onSubmit={ e => e.preventDefault() }>
      <MultiLangInput
        label={ Lang[ 'TOPIC' ][ lang ] }
        value={ currentArticle.topic }
        type={ 'text' }
        onChange={ val => onChangeArticleData( 'topic', val ) }
        selectedLanguage={ lang } />
      <MultiLangRTE
        label={ Lang[ 'CONTENT' ][ lang ] }
        value={ currentArticle.content } 
        onChange={ val => onChangeArticleData( 'content', val ) }
        selectedLanguage={ lang } />
      <FormGroup>
        <Label>{ Lang[ 'SORT_ORDER' ][ lang ] }</Label>
        <Input
          type='number'
          min='1'
          value={ currentArticle.display_order }
          onChange={ e => onChangeArticleData( 'display_order', parseInt( e.target.value ) ) }
          onKeyDown= { e => e.target.value.length === 0 && e.which === 48 && e.preventDefault() } />
      </FormGroup>
    </Form>
  )
}

export default ArticleForm 
