import React, { Component } from 'react';
import { TextField, Button } from '@material-ui/core'
import _ from 'lodash'

import LoadingModal from 'components/Indicator/LoadingOverlay'
import BackdropImage from 'assets/images/backdrop-image.jpg';
import ResetPasswordHOC from './actions';

import './index.scss';
import { getItem } from 'utils/tokenStore'

class ResetPassword extends Component {
  
  handleKeyPress = event => {
    if( event.key === 'Enter' ){
      let tmp = _.cloneDeep( this.props.resetPasswordFields )
      tmp[ 'password_reset_token' ] = getItem( 'RESET_PASSWORD_TOKEN' )
      this.props.resetPassword( tmp )
    }
  }

  handleResetPassword = () => {
    let tmp = _.cloneDeep( this.props.resetPasswordFields )
    tmp[ 'password_reset_token' ] = getItem( 'RESET_PASSWORD_TOKEN' )
    this.props.resetPassword( tmp )
  }

  updatePassword = ( key, val ) => {
    let tmp = _.cloneDeep( this.props.resetPasswordFields )
    tmp[ key ] = val
    return this.props.onChangeResetPassword( 'resetPasswordFields', tmp )
  }

  render = () => {
    return (
      <div className="reset-password">
        <img src={ BackdropImage } className="bg-image" />
        <div className="reset-password-overlay">
          <div className="reset-password-panel">
            <h1 className="reset-password-panel-title">Passwort vergessen</h1>
            <div className="error-message">{ this.props.errorMessage }</div>
            <TextField
              label={ 'Neues Passwort' }
              value={ this.props.resetPasswordFields.password }
              onChange={ e => this.updatePassword( 'password', e.target.value ) }
              type={ 'password' }
              className={ 'kws-input mb-15' }
              onKeyPress={ this.handleKeyPress } />
            <TextField
              label={ 'Passwort bestätigen' }
              value={ this.props.resetPasswordFields.password_confirmation }
              onChange={ e => this.updatePassword( 'password_confirmation', e.target.value ) }
              type={ 'password' }
              className={ 'kws-input mb-30' }
              onKeyPress={ this.handleKeyPress } />
            <Button
              variant={ 'contained' }
              color={ 'primary' }
              onClick={ this.handleResetPassword }
              classes={{ root: 'reset-button', label: 'primary-button-text text-capitalize' }}>
              { 'Speichern' }
            </Button>
          </div>
        </div>
        { ( this.props.data.ajaxReducer.ajaxCallProgress > 0 || this.props.onLoadResetPassword ) && <LoadingModal /> }
      </div>
    )
  }
}

export default ResetPasswordHOC( ResetPassword );
