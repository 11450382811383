import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      centerCountries: [],
      loading: false
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    getCenterCountries = () => Get(
      `/api/v1/center_countries?query=${ convertObjToBase64({ is_paginated: false }) }`,
      this.getCenterCountriesSuccess,
      this.getCenterCountriesError,
      this.load
    )
    getCenterCountriesSuccess = payload => this.setState({ centerCountries: payload })
    getCenterCountriesError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          getCenterCountries={ this.getCenterCountries }
          centerCountries={ this.state.centerCountries }
          onLoadCenterCountries={ this.state.loading } />
      )
    }
  }
  return WithHOC
}

export default HOC