import React from 'react'
import ReactHTMLParser from 'react-html-parser'
import Radium from 'proofpoint-radium'
import { ApplyColor } from 'utils/apply-color'

const Btn = props => {
  return (  
    <>
      {
        props.style && 'outline' in props.style && props.style[ 'outline' ] !== 'none' && <style>
          {
            `#${ props.id } { outline: ${ ApplyColor( props.style, false )[ 'outline' ] } !important; }
            ${ ':hover' in props.style & 'outline' in props.style[ ':hover' ] && `#${ props.id }:hover { outline: ${ ApplyColor( props.style, false )[ ':hover' ][ 'outline' ] } !important; }` }`
          }
        </style>
      }
      <button 
        id={ props.id }
        className={ props.className }
        onClick={ props.onClick }
        style={ props.selectedComponent.id === props.id
          ? {
            ...ApplyColor( props.style, false ),
            borderTop: "2px solid green",
            borderRight: "2px solid green",
            borderBottom: "2px solid green",
            borderLeft: "2px solid green"
          }
          : ApplyColor( props.style, false )
        }>
        {
          ( props.children && props.children.length > 0 ) && props.children.map( item => {
            return (
              <div 
                style={
                  item.style 
                    ? ApplyColor( item.style, false )
                    : {}
                } >
                {
                  item.content && item.content[ props.selectedLanguage ]
                    ? ReactHTMLParser( item.content[ props.selectedLanguage ] )
                    : ReactHTMLParser( item.content[ 'en' ] )
                }
              </div>
            )
          })
        }
      </button>
    </>
  )
}

export default Radium( Btn )