import React, { useState, useEffect } from 'react'
import { FormGroup, Label, Input, UncontrolledTooltip } from 'reactstrap'
import _ from 'lodash'
import ReactHTMLParser from 'react-html-parser'


const TextField = ({
  content,
  formValues,
  onPatchFormValues,
  currentLanguage
}) => {
  const [ labelStr, setLabelStr ] = useState("")

  useEffect(() => {
    let labelText = content.label[ currentLanguage ? currentLanguage : 'en' ]

    if ( labelText.length !== 0 ) {
      if ( typeof( ReactHTMLParser( labelText )[0] ) === 'object' ) {
        let labelTag = ReactHTMLParser( labelText )[0].type
        let closingTag = "</" + labelTag + ">"
        labelText = labelText.slice( 0, -1 * closingTag.length )
        if ( formValues[ content.dataMapIndex ][ 'isRequired' ] ){
          labelText = labelText + "<span style='color: #ff0000;'> * </span>"
        }
        labelText = labelText + closingTag
      }
      else {
        labelText = labelText + "<span style='color: #ff0000;'> * </span>"
      }
    }
    setLabelStr( labelText )
  }, [ content, formValues, currentLanguage ])

  return (
    <FormGroup 
      id={ content.id } 
      style={{ padding: content.padding, marginBottom: 0, display: _.findIndex( formValues[ content.dataMapIndex ][ 'hideInLanguage' ], item => item === currentLanguage ) > -1 ? 'none' : 'block' }}>
      <Label style={{ fontSize: content.fontSize }}>{ ReactHTMLParser( labelStr ) }</Label>
      {
        formValues[ content.dataMapIndex ][ 'showQuickInfo' ] && (
          <>
            <i id="quick-info-tooltip" className="pe-7s-info ml-2" style={{ fontSize: '20px', cursor: 'pointer' }} />
            <UncontrolledTooltip
              target="quick-info-tooltip"
              placement="top">
              { content.preview_text }
            </UncontrolledTooltip>
          </>
        )
      }
      <Input
        type={ content.type }
        value={ formValues[ content.dataMapIndex ][ 'value' ] }
        onChange={ e => {
          onPatchFormValues( content.dataMapIndex, 'value', e.target.value )
        }}
        style={{ fontSize: content.fontSize }} />
    </FormGroup>
  )
}

export default TextField