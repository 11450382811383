import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get, Post, Put, Delete } from 'utils/axios'
import { getRefreshToken } from 'actions/token'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const UsersHOC = ( WrappedComponent ) => {
  class UsersWrappedComponent extends Component {
    state = {
      showCreate: false,
      showUpdate: false,
      showView: false,
      users: [],
      usersTotalPages: 0,
      newUser: {
        "username": "",
      	"first_name": "",
      	"last_name": "",
      	"email": "",
      	"role_id": 4,
      	"password": "",
      	"password_confirmation": "",
        "admin_panel_language": this.props.data.languageReducer.lang,
        photo_raw: '',
        photo_file_name: ''
      },
      roles: {},
      selectedUser: {
        created_at: "",
        email: "",
        first_name: "",
        id: 0,
        last_login_at: null,
        last_name: "",
        photo_file_name: "",
        photo_url: "",
        role: "",
        role_id: 0,
        username: "",
        photo_raw: '',
        admin_panel_language: this.props.data.languageReducer.lang
      },
      errorMessage: {},
      searchParams: {
        email: '',
        name: '',
        role_id: -1,
      },
      showRemoveUserId: 0
    }

    onChangeUsersHOC = ( key, value ) => this.setState({ [key]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    getUsers = query => Get(
      `/api/v1/users?query=${ query }`,
      this.getUsersSuccess,
      this.getUsersError,
      this.load
    )
    getUsersSuccess = payload => {
      let tmpTotalPages = []
      let tmpUsers = []
      if( payload.data && payload.data.length > 0 ) {
        payload.data.map( item => {
          // filter out archived consultants
          if( item.role_id === 5 ) {
            if( _.findIndex( this.props.archivedConsultants.data, { email: item.email } ) === -1 ) {
              tmpUsers.push({
                ...item,
                fullname: `${ item.first_name } ${ item.last_name }`
              })
            }
          } else {
            tmpUsers.push({
              ...item,
              fullname: `${ item.first_name } ${ item.last_name }`
            })
          }
        })
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      let tmp = {
        data: tmpUsers,
        meta: payload.meta
      }
      return this.setState({ users: tmp, usersTotalPages: tmpTotalPages })
    }
    getUsersError = error => this.requestError( error )

    getRoles = query => Get(
      `/api/v1/security_roles?query=${ query }`,
      this.getRolesSuccess,
      this.getRolesError,
      this.load
    )
    getRolesSuccess = payload => this.setState({ roles: payload })
    getRolesError = error => this.requestError( error )

    getSelectedUser = id => Get(
      `/api/v1/users/${ id }`,
      this.getSelectedUserSuccess,
      this.getSelectedUserError,
      this.load
    )
    getSelectedUserSuccess = payload => this.setState({ 
      selectedUser: {
        ...payload,
        sites: _.orderBy( 
          ( payload.sites || [] ), 
          site => site.name.toLowerCase(),
          [ 'asc' ]
        )
      }
    })
    getSelectedUserError = error => this.requestError( error )

    createUser = () => Post(
      `/api/v1/users`,
      this.state.newUser,
      this.createUserSuccess,
      this.createUserError,
      this.load
    )
    createUserSuccess = () => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'USER_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getUsers( convertObjToBase64( tmp ) )
      this.setState({ 
        showCreate: false,
        newUser: {
          "username": "",
          "first_name": "",
          "last_name": "",
          "email": "",
          "role_id": 4,
          "password": "",
          "password_confirmation": "",
          "admin_panel_language": this.props.data.languageReducer.lang,
          photo_raw: '',
          photo_file_name: ''
        },
        searchParams: {
          email: '',
          name: '',
          role_id: -1,
        },
      })
    }
    createUserError = error => {
      this.setState({ errorMessage: error })
      this.requestError( 'Errors' )
    }

    updateUser = () => Put(
      `/api/v1/users/${ this.state.selectedUser.id }`,
      this.state.selectedUser,
      this.updateUserSuccess,
      this.updateUserError,
      this.load
    )
    updateUserSuccess = () => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      const filter = this.processSearchFilter()
      if ( !_.isEmpty( filter ) ) {
        tmp.filter = filter
      }
      this.requestSuccess( Lang[ 'USER_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getUsers( convertObjToBase64( tmp ) )
      this.setState({ showUpdate: false })
    }
    updateUserError = error => this.requestError( error )

    removeUser = () => Delete(
      `/api/v1/users/${ this.state.showRemoveUserId }`,
      this.removeUserSuccess,
      this.removeUserError,
      this.load
    )
    removeUserSuccess = () => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      const filter = this.processSearchFilter()
      if ( !_.isEmpty( filter ) ) {
        tmp.filter = filter
      }
      
      this.requestSuccess( Lang[ 'USER_IS_SUCCESSFULLY_REMOVED' ][ this.props.data.languageReducer.lang ] )
      this.getUsers( convertObjToBase64( tmp ) )
      this.setState({ showRemoveUserId: 0 })
    }
    removeUserError = error => {
      this.requestError( error )
      this.setState({ showRemoveUserId: 0 })
    }

    processSearchFilter = () => {
      let searchFilter = {}  

      if ( this.state.searchParams.email.length > 0 ) {
        searchFilter.email = {
          "$like": `%${ this.state.searchParams.email }%`
        }
      }
      
      if ( this.state.searchParams.name.length > 0 ) {
        let nameFilter = [
          { first_name: {"$like": `%${ this.state.searchParams.name }%` }},
          { last_name: {"$like": `%${ this.state.searchParams.name }%` }}
        ]
        let nameArr = this.state.searchParams.name.split( ' ' )
        for ( let i = 1; i < nameArr.length; i++ ) {
          nameFilter.push({
            $and: [
              { first_name: {"$like": `%${ nameArr.slice( 0, i ).join( ' ' )  }%` }},
              { last_name: {"$like": `%${ nameArr.slice( i, nameArr.length ).join( ' ' )  }%` }}
            ]
          })
          nameFilter.push({
            $and: [
              { first_name: {"$like": `%${ nameArr.slice( i, nameArr.length ).join( ' ' )  }%` }},
              { last_name: {"$like": `%${ nameArr.slice( 0, i ).join( ' ' )  }%` }}
            ]
          })
        }
        searchFilter.$or = nameFilter
      }
  
      if (this.state.searchParams.role_id > 0) {
        searchFilter.role_id = parseInt( this.state.searchParams.role_id )
      }      
      return searchFilter
    }

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          { ...this.state }
          onLoadUsers={ this.state.loading }
          getUsers={ this.getUsers }
          onChangeUsersHOC={ this.onChangeUsersHOC }
          getRoles={ this.getRoles }
          createUser={ this.createUser }
          getSelectedUser={ this.getSelectedUser }
          updateUser={ this.updateUser }
          removeUser={ this.removeUser }
          processSearchFilter={ this.processSearchFilter }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    getRefreshToken
  } )( UsersWrappedComponent )
}

export default UsersHOC
