import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import Moment from 'moment'

import { Post, Get, Put, DownloadFile } from 'utils/axios'
import { getRefreshToken } from 'actions/token'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const BookedCampaignHOC = ( WrappedComponent ) => {
  class BookedCampaignWrappedComponent extends Component{
    state = {
      bookedCampaigns: [],
      loading: false,
      bookedCampaignsTotalPages: [],
      selectedBookedCampaign: {
        booked_campaign: {},
        campaign: {},
        setting: {},
        consultant: {}
      },
      showEdit: false,
      searchCampaignParams: {
        is_app_campaign: 'false'
      },
      showCreateBookedCampaign: false,
      newBookedCampaign: {},
      newBookedCampaignError: {},
      selectedBookedCampaignError: {},
      showExportOnetimer: false,
      uploadedMedia: []
    }

    onChangeBookedCampaignHOC = ( key, value ) => this.setState({ [ key ]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( item => toast.error( item.replaceAll( '_', ' ' ) ) )

    getBookedCampaigns = query => Get(
      `/api/v1/ols_team_campaigns?query=${ query }`,
      this.getBookedCampaignsSuccess,
      this.getBookedCampaignsError,
      this.load
    )
    getBookedCampaignsSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      this.setState({ bookedCampaigns: payload, bookedCampaignsTotalPages: tmpTotalPages })
    }
    getBookedCampaignsError = error => this.requestError( error )

    createBookedCampaign = data => Post(
      `/api/v1/ols_booked_campaigns`,
      data,
      () => this.createBookedCampaignSuccess( data ),
      this.createBookedCampaignError,
      this.load
    )
    createBookedCampaignSuccess = data => {
      this.requestSuccess( Lang[ 'CAMPAIGN_IS_SUCCESSFULLY_BOOKED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showCreateBookedCampaign: false })
      this.getBookedCampaigns( convertObjToBase64({
        is_paginated: true,
        page: 1,
        filter: {
          title: {
            $like: `%${ data.campaign.title }%`
          }
        }  
      }))
    }
    createBookedCampaignError = error => this.requestError( error )

    getSelectedBookedCampaign = id => Get(
      `/api/v1/ols_booked_campaigns/${ id }`,
      this.getSelectedBookedCampaignSuccess,
      this.getSelectedBookedCampaignError,
      this.load
    )
    getSelectedBookedCampaignSuccess = payload => {
      let tmp = payload
      let tmpUploadedMedia = []
      tmp = {
        ...payload,
        setting: payload.setting || payload.settings_client,
        consultant: {
          ...payload.consultant,
          first_name: payload.consultant.user.first_name,
          last_name: payload.consultant.user.last_name,
          email: payload.consultant.user.email,
          photo_url: payload.consultant.user.photo_url
        },
        booked_campaign: {
          ...payload.booked_campaign,
          content: payload.booked_campaign.submitted_form_content,
          start_date: payload.booked_campaign.start_date ? new Date( payload.booked_campaign.start_date ) : null,
          end_date: payload.booked_campaign.end_date ? new Date( payload.booked_campaign.end_date ) : null
        }
      }
      if( payload.booked_campaign?.values?.length > 0 ){
        tmpUploadedMedia = _.reduce( 
          payload.booked_campaign.values,
          ( res, item ) => item.type === 'inputFile'
            ? [ ...res, ...item.value ]
            : res,
          []
        )
      } 
      this.setState({ 
        selectedBookedCampaign: tmp,
        selectedBookedCampaignError: {},
        uploadedMedia: tmpUploadedMedia
      })
    }
    getSelectedBookedCampaignError = error => this.requestError( error )

    updateBookedCampaign = ( ols_campaign_id, data ) => Put(
      `/api/v1/ols_booked_campaigns/${ ols_campaign_id }`,
      data,
      this.updateBookedCampaignSuccess,
      this.updateBookedCampaignError,
      this.load
    )
    updateBookedCampaignSuccess = () => {
      this.requestSuccess( Lang[ 'BOOKED_CAMPAIGN_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getBookedCampaigns( convertObjToBase64({
        is_paginated: true,
        page: 1,
        filter: {
          center_name: {
            $like: `%${ this.state.selectedBookedCampaign.campaign.center_name.toLowerCase() }%`
          }
        }  
      }))
    }
    updateBookedCampaignError = error => this.requestError( error )

    updateSettings = ( data, isOneTimer ) => Put(
      isOneTimer
        ? `/api/v1/ols_settings_clients/${ data.id }`
        : `/api/v1/ols_settings/${ data.id }`,
      data,
      this.updateSettingsSuccess,
      this.updateSettingsError,
      this.load
    )
    updateSettingsSuccess = () => {
      this.requestSuccess( Lang[ 'SETTINGS_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
    }
    updateSettingsError = error => this.requestError( error )

    getOnetimerBookedCampaignsExcel = monthQuery => {
      let startMoment = Moment( _.cloneDeep( monthQuery ) ).startOf( 'month' )
      let endMoment = Moment( _.cloneDeep( monthQuery ) ).endOf( 'month' )
      return DownloadFile(
        '/api/v1/ols_campaigns/export_one_timer_campaigns?' +
        `from=${ startMoment.format( 'YYYY-MM-DD' ) }&` +
        `to=${ endMoment.format( 'YYYY-MM-DD' ) }&` +
        `locale=${ this.props.data.languageReducer.lang }`,
        `${ Lang[ 'ONE_TIMER' ][ this.props.data.languageReducer.lang ] }_${ startMoment.format( 'DD-MM-YYYY' ) }_${ endMoment.format( 'DD-MM-YYYY' ) }.xlsx`,
        this.getOnetimerBookedCampaignsExcelError,
        this.load
      )
    }
    getOnetimerBookedCampaignsExcelError = error => this.requestError( error )

    createSubmittedFormMedia = ( formContentId, data ) => Post(
      `/api/v1/digital_order_forms_media`,
      data,
      payload => this.createSubmittedFormMediaSuccess( formContentId, data, payload ),
      this.createSubmittedFormMediaError,
      this.load
    )
    createSubmittedFormMediaSuccess = ( formContentId, data, payload ) => {
      let tmpUploadedMedia = _.cloneDeep( this.state.uploadedMedia )
      tmpUploadedMedia.push( {
        id: payload.id,
        formContentId: formContentId,
        url: payload.url,
        filename: data.name
      })
      this.setState({ uploadedMedia: tmpUploadedMedia })
    }
    createSubmittedFormMediaError = error => this.requestError( error )

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          { ...this.state }
          onLoadBookedCampaign={ this.state.loading }
          onLoadSelectedBookedCampaign={ this.state.loading }
          createBookedCampaign={ this.createBookedCampaign }
          getBookedCampaigns={ this.getBookedCampaigns }
          onChangeBookedCampaignHOC={ this.onChangeBookedCampaignHOC }
          getSelectedBookedCampaign={ this.getSelectedBookedCampaign }
          updateBookedCampaign={ this.updateBookedCampaign }
          updateSettings={ this.updateSettings }
          getOnetimerBookedCampaignsExcel={ this.getOnetimerBookedCampaignsExcel }
          uploadedMedia={ this.state.uploadedMedia }
          createSubmittedFormMedia={ this.createSubmittedFormMedia }
        />
      )
    }

  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    getRefreshToken
  } )( BookedCampaignWrappedComponent )
}
export default BookedCampaignHOC
