import React, { useState, useEffect } from 'react'
import {
  Row, Col,
  Input, Label,
  FormGroup, FormFeedback,
  Card, CardBody
} from 'reactstrap'
import _ from 'lodash'

import Lang from 'Lang/General'
import LanguageSelector from 'components/LanguageSelector'

const MultilingualInput = ({
  label,
  value,
  type,
  rows,
  disabled,
  onChange,
  selectedLanguage,
  invalid,
  contentPlaceholders,
  maxLength,
  showCharCount
}) => {
  const [ buttonLang, updateButtonLang ] = useState( selectedLanguage ? selectedLanguage : 'en' )
  const [ showLangBar, updateShowLangBar ] = useState( false )
  const [ charsLeft, updateCharsLeft ] = useState( {} )

  useEffect( () => {
    if( maxLength ) {
      updateCharsLeft({
        en: maxLength - value[ 'en' ].length,
        de: maxLength - value[ 'de' ].length,
        it: maxLength - value[ 'it' ].length,
        nl: maxLength - value[ 'nl' ].length
      })
    }
  }, [ maxLength ] )

  const updateContent = val => {
    let tmpValue = _.cloneDeep( value )
    tmpValue[ buttonLang ] = val
    return onChange( tmpValue )
  }

  return(
    <FormGroup>
      <Row 
        className='mb-2'
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: label ? "space-between" : "flex-end" }} >
        {
          label && <Col>
          <Label className='mb-0'>{ label }</Label>
        </Col>
        }
        <LanguageSelector 
          currentLang = { buttonLang }
          updateLang = { updateButtonLang }
        />
        {
          contentPlaceholders && (
            <Col sm={ 12 } >
              <Card className="my-2">
                <CardBody>
                  <p>{ `${ Lang[ 'FOLLOWING_ARE_PLACEHOLDERS_AVAILABLE_FOR' ][ selectedLanguage ] }` }{ label.toLowerCase() } :</p>
                  <ul>
                    {
                      _.map( contentPlaceholders, ( value, key ) => (
                        <li>{ `${ Lang[ key ][ selectedLanguage ] }: ${ value }` }</li>
                      ))
                    }
                  </ul>
                </CardBody>
              </Card>
            </Col>
          )
        }
      </Row>
      <Input
        type={ type? type: 'text' }
        rows={ rows || 4 } 
        style={{ resize: "none" }}
        disabled={ disabled }
        value={ value ? value[ buttonLang ] : '' }
        onChange={ e => {
          updateContent( e.target.value )
          if( maxLength ) {
            let tmp = _.cloneDeep( charsLeft )
            tmp[ buttonLang ] = maxLength - e.target.value.length
            updateCharsLeft( tmp )
          }
        }}
        onClick={ () => updateShowLangBar( true ) }
        onBlur={ () => updateShowLangBar( false ) }
        invalid={ invalid }
        maxlength={ maxLength } />
      <FormFeedback>{ invalid }</FormFeedback>
      { maxLength && <Label>{ `${ Lang[ 'REMAINING_CHARACTERS' ][ selectedLanguage ] } ${ charsLeft[ buttonLang ] }` }</Label> }
      { showCharCount && <Label>{ `${ Lang[ 'NO_OF_CHARACTERS' ][ selectedLanguage ] }: ${ value[ buttonLang ].length }` }</Label> }
    </FormGroup>
  )
}

export default MultilingualInput

export { MultilingualInput }