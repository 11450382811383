import Axios from 'axios'
import { purgeStoredState } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import Cookies from 'js-cookie'
import { clearItem } from 'utils/token'

import getDomainURL from 'utils/api'

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  })
  failedQueue = [];
}

Axios.interceptors.response.use( response => {
  return response
}, error => {
  const originalRequest = error.config
  if( error.config.url.indexOf( '/auth/refresh_token' ) > -1 ) {
    if( error.response.status === 401 ) {
      window.localStorage.removeItem( 'PROOFPOINT_ARK_MANAGEMENT' )
      window.localStorage.setItem( 'IS_TOKEN_EXPIRED', true )
      window.location.reload()
    }
  }
  if (error.response.status === 401 && !originalRequest._retry) {
    if (isRefreshing) {
      return new Promise(function(resolve, reject) {
        failedQueue.push({resolve, reject})
      }).then(token => {
        originalRequest.headers['Authorization'] = 'JWT ' + token;
        return Axios(originalRequest);
      }).catch(err => {
        return Promise.reject(err);
      })
    }
    originalRequest._retry = true;
    isRefreshing = true;
    return new Promise(( resolve, reject ) => {
      Axios.get(  
        `${ getDomainURL() }/auth/refresh_token`
      ).then( response => {
        window.localStorage.setItem( 'PROOFPOINT_ARK_MANAGEMENT', response.data.token )
        Axios.defaults.headers = {
          common: {
            'Authorization': `Bearer ${ response.data.token }`
          }
        }
        originalRequest.headers[ 'Authorization' ] = 'Bearer ' + response.data.token
        processQueue( null, response.data.token )
        resolve( Axios(originalRequest) )
      }).catch((err) => {
        processQueue(err, null);
        reject(err);
      }).finally(() => { 
        isRefreshing = false
      })
    })
  }
  return Promise.reject(error);
})