import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { connect } from 'react-redux'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import _ from 'lodash'

import Lang from 'Lang/General'

const reorder = ( list, startIndex, endIndex ) => {
  const result = Array.from( list )
  const [removed] = result.splice( startIndex, 1 )
  result.splice( endIndex, 0, removed )
  return result
}

const ArkPagination = ({
  dom,
  formValues,
  onChangeFormBuilderState,
  page,
  totalPages,
  onChangePage,
  currentLanguage,
  showPageRearrangement
}) => {
  const [ interval, updateInterval ] = useState( 10 )

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let tmpDom = dom
    const totalPages = tmpDom[ 0 ].children.length
    let pageSequence = Array.from({length: totalPages}, (_, i) => i + 1)

    tmpDom[ 0 ].children = reorder( tmpDom[ 0 ].children, oldIndex, newIndex )
    pageSequence = reorder( pageSequence, oldIndex, newIndex )

    let tmpCoverPages = _.reverse( _.filter( tmpDom[ 0 ].children, item => item.mapWith && item.mapWith === 'cover' ) )
    tmpCoverPages.map( item => {
      let tmpIndex = _.findIndex( tmpDom[ 0 ].children, { id: item.id } )
      tmpDom[ 0 ].children = reorder( tmpDom[ 0 ].children, tmpIndex, 0 )
      pageSequence = reorder( pageSequence, tmpIndex, 0 )
    })
    let tmpContentPages = _.filter( tmpDom[ 0 ].children, item => item.mapWith && item.mapWith === 'content' )
    tmpContentPages.map( item => {
      let tmpIndex = _.findIndex( tmpDom[ 0 ].children, { id: item.id } )
      let tmpNewIndex = _.findIndex( tmpDom[ 0 ].children, item => !item.mapWith || ( item.mapWith && item.mapWith !== 'cover' ) )
      tmpDom[ 0 ].children = reorder( tmpDom[ 0 ].children, tmpIndex, tmpNewIndex > -1 ? tmpNewIndex : 0 )
      pageSequence = reorder( pageSequence, tmpIndex, tmpNewIndex > -1 ? tmpNewIndex : 0 )
    })
    tmpDom[ 0 ].children.map( ( child, index ) => {
      child.page = index + 1
      return child
    })

    let tmpformValues = _.cloneDeep( formValues )
    tmpformValues.map( formVal => {
      formVal.page = pageSequence.indexOf(formVal.page) + 1
    })
    
    Promise.all([
      onChangeFormBuilderState( 'formValues', tmpformValues ),
      onChangeFormBuilderState( 'dom', tmpDom )
    ]).then( () => onChangePage( page = newIndex + 1 ))
  }

  const SortableItem = SortableElement( ({ pg }) => {
    return <div 
      className='mb-2 mr-2'
      style={{ 
        display: "block",
        cursor: "pointer",
        border: page === pg ? "#6c757d" : "1px solid #6c757d",
        borderRadius: "0.25rem",
        textAlign: "center",
        alignItems: "center",
        width: "50px",
        height: "fit-content",
        background: page === pg ? "#6c757d": "none",
        color: page === pg ? "#ffffff" : "#000000",
        zIndex: "9999" }}>
      { pg }
    </div>
  })

  const SortableList = SortableContainer( ({ items }) => {
    return (  
      <div className='row w-100 m-0' style={{ position: "relative", padding: "15px" }} >
        { items.map( ( pg, index ) => <SortableItem key={ `page-${pg}` } index={ index } pg={ pg } /> ) }
      </div>
    )
  })

  return(
    !showPageRearrangement ? (
      <div style={{ marginTop: '20px', alignItems: 'center', justifyContent: 'center' }}>
        <div  className="d-flex pt-1">
          <Button
            className="mb-2 mr-2 btn-icon btn-icon-only"
            onClick={ () => onChangePage( page = page - 1 ) }
            disabled={ page === 1 }
            color="primary">
            <i className="pe-7s-angle-left btn-icon-wrapper" style={{ fontSize: '25px' }} />
          </Button>
          <Button
            className="mb-2 mr-2 btn-icon"
            onClick={ () => updateInterval( interval - 10 ) }
            color="light"
            style={{
            display: `${ interval <= 10 ? 'none' : '' }`
            }}>
            ...
          </Button>
          <div className="d-flex">
          {
            totalPages && totalPages.map( pg => {
              if(( pg > interval - 10 ) && ( pg <= interval )){
                return(
                  <Button
                  className="mb-2 mr-2 btn-icon"
                  color={ page === pg ? "secondary": "link" }
                  onClick={ () =>  onChangePage( page = pg ) }>
                  { pg }</Button>
                )
              }
            })
          }
          </div>
          <Button
            className="mb-2 mr-2 btn-icon"
            onClick={ () => updateInterval( interval + 10 ) }
            color="light"
            style={{
            display: `${ interval > totalPages.length ? 'none' : '' }`
            }}>
            ...
          </Button>
          <Button
            className="mb-2 mr-2 btn-icon btn-icon-only"
            onClick={ () => onChangePage( page = page + 1 ) }
            disabled={ page === ( totalPages.length ) }
            color="primary">
            <i className="pe-7s-angle-right btn-icon-wrapper" style={{ fontSize: '25px' }}/>
          </Button>
        </div>
      </div>
    ) : (
      <SortableList 
        items={ totalPages } 
        onSortEnd={ onSortEnd } 
        axis='xy' />
    )
  )
}

const mapStateToProps = state => ({ data: state })

const mapDispatchToProps = dispatch => ({})

export default connect( mapStateToProps, mapDispatchToProps )(ArkPagination)
