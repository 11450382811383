import React, { useState, useEffect, Component } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  Card,
  CardBody,
  CustomInput
} from 'reactstrap'
import _ from 'lodash'
import Tabs from 'react-responsive-tabs'
import uniqid from 'uniqid'
import { connect } from 'react-redux'

import CKEditor5 from 'components/RTE/ckeditor5'
import MediaDatabase from 'SharedModules/MediaDatabase'
import DropdownEditor from './Editor/Dropdown'

import { toggleMediaDatabase } from 'actions/MediaDatabaseToggle'
import Lang from 'Lang/General'

import 'react-responsive-tabs/styles.css'

const UpdateColumnModal = ({
  showUpdateColumnModal,
  selectedComponentToEdit,
  onChangeFormBuilderState,
  findObjectAndPatch,
  dom,
  formValues,
  toggleMediaDatabase,
  site,
  currentLanguage,
  selectedLanguage,
  backgroundColor
}) => {
  const [ tab, updateTab ] = useState( 0 )

  const updateSelectedComponentToEdit = async ( key, val ) => {
    let tmpDOM = _.cloneDeep( dom )
    let tmp = _.cloneDeep( selectedComponentToEdit )
    tmp[ key ] = val
    findObjectAndPatch( tmpDOM, tmp )
    await onChangeFormBuilderState( 'selectedComponentToEdit', tmp )
  }

  const languages = [ 'en', 'de', 'it', 'nl' ]

  const renderColumnTab = () => (
    <Form onSubmit={ e => e.preventDefault() }>
      {
        tab === 0 && (
          <Row>
            <Col md={ 6 }>
              <FormGroup>
                <Label>{ Lang[ 'COLUMN_WIDTH' ][ selectedLanguage ] }</Label>
                <Input
                  type="select"
                  value={ selectedComponentToEdit.grid }
                  onChange={ async e => {
                    await updateSelectedComponentToEdit( 'grid', parseInt( e.target.value, 10 ) )
                  }}>
                  <option value={ 3 }>25%</option>
                  <option value={ 4 }>33%</option>
                  <option value={ 6 }>50%</option>
                  <option value={ 8 }>67%</option>
                  <option value={ 9 }>75%</option>
                  <option value={ 12 }>100%</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>{ Lang[ 'SELECTED_COMPONENT' ][ selectedLanguage ] }</Label>
                <Input
                  type="select"
                  value={ !_.isEmpty( selectedComponentToEdit ) ? selectedComponentToEdit.children[0].type : '' }
                  disabled={ true }>
                  <option value={ '' }>{ '' }</option>
                  <option value={ 'TextField' }>{ Lang[ 'TEXTFIELD' ][ selectedLanguage ] }</option>
                  <option value={ 'TextArea' }>{ Lang[ 'TEXTAREA' ][ selectedLanguage ] }</option>
                  <option value={ 'Radio' }>{ Lang[ 'RADIO' ][ selectedLanguage ] }</option>
                  <option value={ 'Text' }>{ Lang[ 'TEXT' ][ selectedLanguage ] }</option>
                  <option value={ 'Checkbox' }>{ Lang[ 'CHECKBOX' ][ selectedLanguage ] }</option>
                  <option value={ 'InputFile' }>{ Lang[ 'INPUT_FILE' ][ selectedLanguage ] }</option>
                  <option value={ 'Image' }>{ Lang[ 'IMAGE_SITE_CONTENT' ][ selectedLanguage ] }</option>
                  <option value={ 'Dropdown' }>{ Lang[ 'DROPDOWN' ][ selectedLanguage ] }</option>
                  <option value={ 'DateField' }>{ Lang[ 'DATE_FIELD' ][ selectedLanguage ] }</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>{ Lang[ 'PADDING' ][ selectedLanguage ] }</Label>
                <Input
                  type="text"
                  value={ selectedComponentToEdit.children[0].padding }
                  onChange={ e => {
                    let tmp = _.cloneDeep( selectedComponentToEdit )
                    tmp.children[0].padding = e.target.value
                    return updateSelectedComponentToEdit( 'children', tmp.children )
                  }} />
              </FormGroup>
            </Col>
            {
              selectedComponentToEdit.children[0].type === 'Text' && (
                <Col md={ 6 }>
                  <FormGroup>
                    <Label>{ Lang[ 'FONT_SIZE' ][ selectedLanguage ] }</Label>
                    <Input
                      type="text"
                      value={ selectedComponentToEdit.children[0].fontSize || "16px" }
                      onChange={ e => {
                        let tmp = _.cloneDeep( selectedComponentToEdit )
                        tmp.children[0].fontSize = e.target.value || "16px"
                        return updateSelectedComponentToEdit( 'children', tmp.children )
                      }} />
                  </FormGroup>
                </Col>
              )
            }
            {
              selectedComponentToEdit.children[0].type !== 'Image' && (
                <Col md={ 12 }>
                  <FormGroup>
                    <Label>{ Lang[ 'FIELD_LABEL' ][ selectedLanguage ] }</Label>
                    <CKEditor5
                      data={
                        selectedComponentToEdit.children[0].type === 'Button'
                          ? selectedComponentToEdit.children[0].content[ currentLanguage ]
                          : selectedComponentToEdit.children[0].label[ currentLanguage ]
                      }
                      onChange={( event, editor ) => {
                        let tmp = _.cloneDeep( selectedComponentToEdit )
                        if( tmp.children[0].type === 'Button' ) {
                          tmp.children[0].content[ currentLanguage ] = editor.getData()
                        } else {
                          tmp.children[0].label[ currentLanguage ] = editor.getData()
                        }
                        return updateSelectedComponentToEdit( 'children', tmp.children )
                      }}
                      site={ site }
                      backgroundColor={ backgroundColor } />
                  </FormGroup>
                </Col>
              )
            }            
            {
              selectedComponentToEdit.children[0].type === 'TextArea' && (
                <Col md={ 6 }>
                  <FormGroup>
                    <Label>{ Lang[ 'NO_OF_ROWS_IN_TEXT_AREA' ][ selectedLanguage ] }</Label>
                    <Input
                      type="text"
                      value={ selectedComponentToEdit.children[0].rows }
                      onChange={ e => {
                        let tmp = _.cloneDeep( selectedComponentToEdit )
                        tmp.children[0].rows = e.target.value
                        return updateSelectedComponentToEdit( 'children', tmp.children )
                      }} />
                  </FormGroup>
                </Col>
              )
            }
            {
              selectedComponentToEdit.children[0].type !== 'Image' &&
              selectedComponentToEdit.children[0].type !== 'Text' && (
                <Col md={ 12 } >
                  <FormGroup>
                    <CustomInput
                      id='isRequiredField'
                      type="checkbox"
                      label={ Lang[ 'IS_A_REQUIRED_FIELD' ][ selectedLanguage ] }
                      checked={ formValues[ selectedComponentToEdit.children[0].dataMapIndex ][ 'isRequired' ]
                        ? formValues[ selectedComponentToEdit.children[0].dataMapIndex ][ 'isRequired' ]
                        : false }
                      onChange={ e => {
                        let tmpFormValues = _.cloneDeep( formValues )
                        tmpFormValues[ selectedComponentToEdit.children[0].dataMapIndex ][ 'isRequired' ] = e.target.checked
                        if (e.target.checked == false) {
                          tmpFormValues[ selectedComponentToEdit.children[0].dataMapIndex ][ 'validation' ] = {
                            "en" : "",
                            "de" : "",
                            "nl" : "",
                            "it" : ""
                          }
                        }
                        else {
                          tmpFormValues[ selectedComponentToEdit.children[0].dataMapIndex ][ 'validation' ] = {
                            "en" : "Field is required",
                            "de" : "Feld ist erforderlich",
                            "nl" : "Veld is verplicht",
                            "it" : "Il campo è richiesto",
                          }
                        }
                        return onChangeFormBuilderState( 'formValues', tmpFormValues )
                      }} />
                  </FormGroup>
                  {formValues[ selectedComponentToEdit.children[0].dataMapIndex ][ 'isRequired' ]
                    ?<FormGroup>
                      <Label>{ Lang[ 'ERROR_MESSAGE_SITE_CONTENT' ][ selectedLanguage ] }</Label>
                      <Input
                        type='text'
                        value={ formValues[ selectedComponentToEdit.children[0].dataMapIndex ][ 'validation' ][ currentLanguage ] }
                        onChange={ e => {
                          let tmpFormValues = _.cloneDeep( formValues )
                          tmpFormValues[ selectedComponentToEdit.children[0].dataMapIndex ][ 'validation' ][ currentLanguage ] = e.target.value
                          return onChangeFormBuilderState( 'formValues', tmpFormValues )
                        }} />
                    </FormGroup>
                    : <></>
                  }
                  <FormGroup>
                    <Label>{ Lang[ 'HIDE_IN_LANGUAGE' ][ selectedLanguage ] }</Label>
                    {
                      languages.map( lang => {
                        return (
                          <CustomInput
                            id={ lang }
                            type="checkbox"
                            label={ lang }
                            checked={ _.findIndex( formValues[ selectedComponentToEdit.children[0].dataMapIndex ][ 'hideInLanguage' ], item => item === lang ) > -1 }
                            onChange={ e => {
                              let tmpFormValues = _.cloneDeep( formValues )
                              if( e.target.checked ){
                                if( _.findIndex( tmpFormValues[ selectedComponentToEdit.children[0].dataMapIndex ][ 'hideInLanguage' ], item => item === lang ) === -1 ) {
                                  tmpFormValues[ selectedComponentToEdit.children[0].dataMapIndex ][ 'hideInLanguage' ] && tmpFormValues[ selectedComponentToEdit.children[0].dataMapIndex ][ 'hideInLanguage' ].length > 0 
                                    ? tmpFormValues[ selectedComponentToEdit.children[0].dataMapIndex ][ 'hideInLanguage' ].push( lang ) 
                                    : tmpFormValues[ selectedComponentToEdit.children[0].dataMapIndex ][ 'hideInLanguage' ] = [ lang ]
                                }
                              } else {
                                if( _.findIndex( tmpFormValues[ selectedComponentToEdit.children[0].dataMapIndex ][ 'hideInLanguage' ], item => item === lang ) > -1 ) {
                                  tmpFormValues[ selectedComponentToEdit.children[0].dataMapIndex ][ 'hideInLanguage' ].splice( tmpFormValues[ selectedComponentToEdit.children[0].dataMapIndex ][ 'hideInLanguage' ].indexOf( lang ), 1 )
                                }
                              }
                              return onChangeFormBuilderState( 'formValues', tmpFormValues )
                            }} />
                        )         
                      })
                    }    
                  </FormGroup>     
                </Col>
              )
            }
            {
              selectedComponentToEdit.children[0].type === 'TextField' && (
                <Col md={ 12 }>
                  <FormGroup>
                    <CustomInput
                      id='isEmailTextField'
                      type="checkbox"
                      label={ Lang[ 'IS_AN_EMAIL_TEXT_FIELD' ][ selectedLanguage ] }
                      checked={ formValues[ selectedComponentToEdit.children[0].dataMapIndex ][ 'isEmailTextField' ]
                        ? formValues[ selectedComponentToEdit.children[0].dataMapIndex ][ 'isEmailTextField' ]
                        : false }
                      onChange={ e => {
                        let tmpFormValues = _.cloneDeep( formValues )
                        tmpFormValues.map( ( formVal, index ) => {
                          if( formVal.type === 'textField' ){
                            if( index === parseInt( selectedComponentToEdit.children[0].dataMapIndex ) ){
                              formVal[ 'isEmailTextField' ] = e.target.checked
                            } else if( e.target.checked && index !== parseInt( selectedComponentToEdit.children[0].dataMapIndex ) ){
                              formVal[ 'isEmailTextField' ] = false
                            }
                          }
                        })
                        return onChangeFormBuilderState( 'formValues', tmpFormValues )
                      }} />
                    <Label style={{ fontSize: "12px" }}>{ Lang[ 'NOTE_EVERY_USER_WHO_PROVIDES' ][ selectedLanguage ] }</Label>
                  </FormGroup>
                </Col>
              )
            }
            { 
              ( selectedComponentToEdit.children[ 0 ].type === 'TextField' || selectedComponentToEdit.children[ 0 ].type === 'TextArea' ) && (
                <Col md={ 12 }>
                  <FormGroup>
                    <CustomInput
                      id='showQuickInfo'
                      type="checkbox"
                      label={ Lang[ 'SHOW_QUICK_INFO' ][ selectedLanguage ] }
                      checked={ 
                        formValues[ selectedComponentToEdit.children[0].dataMapIndex ][ 'showQuickInfo' ]
                        ? formValues[ selectedComponentToEdit.children[0].dataMapIndex ][ 'showQuickInfo' ]
                        : false 
                      }
                      onChange={ e => {
                        let tmpFormValues = _.cloneDeep( formValues )
                        tmpFormValues[ selectedComponentToEdit.children[0].dataMapIndex ][ 'showQuickInfo' ] = e.target.checked
                        return onChangeFormBuilderState( 'formValues', tmpFormValues )
                      }} />
                  </FormGroup>
                  {
                    formValues[ selectedComponentToEdit.children[0].dataMapIndex ][ 'showQuickInfo' ] ? (
                      <FormGroup>
                        <Label>{ Lang[ 'PREVIEW_TEXT' ][ selectedLanguage ] }</Label>
                        <Input
                          type="textarea"
                          rows="8"
                          value={ selectedComponentToEdit.children[0].preview_text }
                          placeholder={ Lang[ 'PREVIEW_TEXT_PLACEHOLDER' ][ selectedLanguage ] }
                          onChange={ e => {
                            let tmp = _.cloneDeep( selectedComponentToEdit )
                            tmp.children[0].preview_text = e.target.value
                            return updateSelectedComponentToEdit( 'children', tmp.children )
                          }} />
                      </FormGroup>
                    ) : <></>
                  }                  
                </Col>
              )
            }
            {
              selectedComponentToEdit.children[0].type === 'Image' && (
                <>
                  <Col md={ 12 }>
                    <FormGroup>
                      <Label>{ Lang[ 'SELECTED_IMAGE_URL' ][ selectedLanguage ] }</Label>
                      <Input
                        type="text"
                        value={ selectedComponentToEdit.children[0].url }
                        onChange={ e => {
                          let tmp = _.cloneDeep( selectedComponentToEdit )
                          tmp.children[0].url = e.target.value
                          return updateSelectedComponentToEdit( 'children', tmp.children )
                        }}
                        disabled={ true } />
                      <Button
                        color="primary"
                        onClick={() => {
                          toggleMediaDatabase( true )
                        }}>{ Lang[ 'SELECT_IMAGE' ][ selectedLanguage ] }</Button>
                    </FormGroup>
                  </Col>
                  <Col md={ 6 }>
                    <FormGroup>
                      <Label>{ Lang[ 'WIDTH' ][ selectedLanguage ] }</Label>
                      <Input
                        type="text"
                        value={ selectedComponentToEdit.children[0].width }
                        onChange={ e => {
                          let tmp = _.cloneDeep( selectedComponentToEdit )
                          tmp.children[0].width = e.target.value
                          return updateSelectedComponentToEdit( 'children', tmp.children )
                        }} />
                    </FormGroup>
                  </Col>
                  <Col md={ 6 }>
                    <FormGroup>
                      <Label>{ Lang[ 'HEIGHT' ][ selectedLanguage ] }</Label>
                      <Input
                        type="text"
                        value={ selectedComponentToEdit.children[0].height }
                        onChange={ e => {
                          let tmp = _.cloneDeep( selectedComponentToEdit )
                          tmp.children[0].height = e.target.value
                          return updateSelectedComponentToEdit( 'children', tmp.children )
                        }} />
                    </FormGroup>
                  </Col>
                </>
              )
            }
          </Row>
        )
      }
      {
        tab === 1 && (
          <Row>
            {
              selectedComponentToEdit.children[ 0 ].type !== 'Button' &&
              selectedComponentToEdit.children[ 0 ].type !== 'Text' &&
              selectedComponentToEdit.children[ 0 ].type !== 'Image' && (
                <Col md={ 6 }>
                  <FormGroup>
                    <Label>{ Lang[ 'FIELD_NAME' ][ selectedLanguage ] }</Label>
                    <Input
                      type="text"
                      value={ formValues[ selectedComponentToEdit.children[0].dataMapIndex ][ 'dataLabel' ] }
                      onChange={ e => {
                        let tmpFormValues = _.cloneDeep( formValues )
                        tmpFormValues[ selectedComponentToEdit.children[0].dataMapIndex ][ 'dataLabel' ] = e.target.value.replace( /\s/g, '' )
                        return onChangeFormBuilderState( 'formValues', tmpFormValues )
                      }} />
                    <span>{ Lang[ 'THIS_FIELD_NEEDS_TO_BE_FILLED' ][ selectedLanguage ] }</span>
                  </FormGroup>
                </Col>
              )
            }
            {
              selectedComponentToEdit.children[0].type.indexOf( 'Radio' ) !== -1 &&
              (
                <Col md={ 12 }>
                  <p>{ Lang[ 'SELECTION' ][ selectedLanguage ] }</p>
                  <Row>
                    {
                      selectedComponentToEdit.children[0].selections.map(( item, index ) => {
                        return (
                          <Col md={ 6 }>
                            <Card key={ item.id } className="mt-3">
                              <CardBody>
                                <FormGroup>
                                  <Label>{ Lang[ 'LABEL' ][ selectedLanguage ] }</Label>
                                  <Input
                                    type="text"
                                    value={ item.label[ currentLanguage ] }
                                    onChange={ e => {
                                      let tmp = _.cloneDeep( selectedComponentToEdit )
                                      tmp.children[0].selections[ index ][ 'label' ][ currentLanguage ] = e.target.value
                                      return updateSelectedComponentToEdit( 'children', tmp.children )
                                    }}
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <Label>{ Lang[ 'FIELD_NAME' ][ selectedLanguage ] }</Label>
                                  <Input
                                    type="text"
                                    value={ item.value }
                                    onChange={ e => {
                                      let tmp = _.cloneDeep( selectedComponentToEdit )
                                      tmp.children[0].selections[ index ][ 'value' ] = e.target.value.replace( /\s/g, '' )
                                      return updateSelectedComponentToEdit( 'children', tmp.children )
                                    }}
                                  />
                                  <span>{ Lang[ 'THIS_FIELD_NEEDS_TO_BE_FILLED' ][ selectedLanguage ] }</span>
                                </FormGroup>
                              </CardBody>
                            </Card>
                          </Col>
                        )
                      })
                    }
                    </Row>
                    <Button
                      color="primary"
                      className="mt-3"
                      onClick={() => {
                        let tmp = _.cloneDeep( selectedComponentToEdit )
                        tmp.children[0].selections.push({
                          label: {
                            "en": "<p>Your text here</p>",
                            "de": "<p>Ihr Text hier</p>",
                            "nl": "<p>Uw tekst hier</p>",
                            "it": "<p>Il tuo testo qui</p>"
                          },
                          value: uniqid()
                        })
                        return updateSelectedComponentToEdit( 'children', tmp.children )
                      }}>{ Lang[ 'ADD_SELECTION' ][ selectedLanguage ] }</Button>
                    <Button
                      color={ selectedComponentToEdit.children[ 0 ].type.indexOf('Flexible') === -1 ? "success" : "danger" }
                      className="mt-3 ml-2"
                      onClick={ () => {
                        let tmp = _.cloneDeep( selectedComponentToEdit )
                        if( selectedComponentToEdit.children[0].type.indexOf('Flexible') === -1 ){
                          tmp.children[0].type = tmp.children[0].type + 'Flexible'
                        } else {
                          tmp.children[0].type = tmp.children[0].type.replace( 'Flexible', '' )
                        }
                        return updateSelectedComponentToEdit( 'children', tmp.children ) }}>
                      { selectedComponentToEdit.children[ 0 ].type.indexOf('Flexible') === -1
                        ? Lang[ 'ENABLE_FLEXIBLE_OPTION' ][ selectedLanguage ]
                        : Lang[ 'DISABLE_FLEXIBLE_OPTION' ][ selectedLanguage ] }</Button>
                  </Col>
              )
            }
            {
              selectedComponentToEdit.children[0].type.indexOf( 'Checkbox' ) !== -1 &&
              (
                <Col md={ 12 }>
                  <p>{ Lang[ 'SELECTION' ][ selectedLanguage ] }</p>
                  <Row>
                    {
                      selectedComponentToEdit.children[0].selections.map(( item, index ) => {
                        return (
                          <Col md={ 6 }>
                            <Card key={ item.id } className="mt-3">
                              <CardBody>
                                <FormGroup>
                                  <Label>{ Lang[ 'LABEL' ][ selectedLanguage ] }</Label>
                                  <Input
                                    type="text"
                                    value={ item.label[ currentLanguage ] }
                                    onChange={ e => {
                                      let tmp = _.cloneDeep( selectedComponentToEdit )
                                      tmp.children[0].selections[ index ][ 'label' ][ currentLanguage ] = e.target.value
                                      return updateSelectedComponentToEdit( 'children', tmp.children )
                                    }}
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <Label>{ Lang[ 'FIELD_NAME' ][ selectedLanguage ] }</Label>
                                  <Input
                                    type="text"
                                    value={ item.value }
                                    onChange={ e => {
                                      let tmp = _.cloneDeep( selectedComponentToEdit )
                                      tmp.children[0].selections[ index ][ 'value' ] = e.target.value.replace( /\s/g, '' )
                                      return updateSelectedComponentToEdit( 'children', tmp.children )
                                    }}
                                  />
                                  <span>{ Lang[ 'THIS_FIELD_NEEDS_TO_BE_FILLED' ][ selectedLanguage ] }</span>
                                </FormGroup>
                                {
                                  item.nestedSelections && item.nestedSelections.length > 0 && item.nestedSelections.map(( selection, nestedIndex ) => {
                                    return (
                                      <div style={{ borderTop: '1px solid #BBC4C2', paddingTop: '20px' }}>
                                        <FormGroup>
                                          <Label>{ Lang[ 'NESTED_LABEL' ][ selectedLanguage ] }</Label>
                                          <Input
                                            type="text"
                                            value={ selection.label[ currentLanguage ] }
                                            onChange={ e => {
                                              let tmp = _.cloneDeep( selectedComponentToEdit )
                                              tmp.children[0].selections[ index ][ 'nestedSelections' ][ nestedIndex ][ 'label' ][ currentLanguage ] = e.target.value
                                              return updateSelectedComponentToEdit( 'children', tmp.children )
                                            }}
                                          />
                                        </FormGroup>
                                        <FormGroup>
                                          <Label>{ Lang[ 'NESTED_FIELD_NAME' ][ selectedLanguage ] }</Label>
                                          <Input
                                            type="text"
                                            value={ selection.value }
                                            onChange={ e => {
                                              let tmp = _.cloneDeep( selectedComponentToEdit )
                                              tmp.children[0].selections[ index ][ 'nestedSelections' ][ nestedIndex ][ 'value' ] = e.target.value.replace( /\s/g, '' )
                                              return updateSelectedComponentToEdit( 'children', tmp.children )
                                            }}
                                          />
                                        </FormGroup>
                                      </div>
                                    )
                                  })
                                }
                                <Button
                                  color="primary"
                                  className="mt-3"
                                  onClick={ () => {
                                    let tmp = _.cloneDeep( selectedComponentToEdit )
                                    tmp.children[0].selections[index].nestedSelections.push({
                                      label: {
                                        "en": "<p>Your text here</p>",
                                        "de": "<p>Ihr Text hier</p>",
                                        "nl": "<p>Uw tekst hier</p>",
                                        "it": "<p>Il tuo testo qui</p>"
                                      },
                                      value: uniqid(),
                                      nestedSelections: [
                                        {
                                          label: {
                                            "en": "<p>Selection 1</p>",
                                            "de": "<p>Selection 1</p>",
                                            "nl": "<p>Selection 1</p>",
                                            "it": "<p>Selection 1</p>"
                                          }
                                        }
                                      ]
                                    })
                                    return updateSelectedComponentToEdit( 'children', tmp.children )
                                  }}>{ Lang[ 'ADD_NESTED_SELECTION' ][ selectedLanguage ] }</Button>
                              </CardBody>
                            </Card>
                          </Col>
                        )
                      })
                    }
                    </Row>
                    <Button
                      color="primary"
                      className="mt-3"
                      onClick={() => {
                        let tmp = _.cloneDeep( selectedComponentToEdit )
                        tmp.children[0].selections.push({
                          label: {
                            "en": "<p>Your text here</p>",
                            "de": "<p>Ihr Text hier</p>",
                            "nl": "<p>Uw tekst hier</p>",
                            "it": "<p>Il tuo testo qui</p>"
                          },
                          value: uniqid(),
                          nestedSelections: []
                        })
                        return updateSelectedComponentToEdit( 'children', tmp.children )
                      }}>{ Lang[ 'ADD_SELECTION' ][ selectedLanguage ] }</Button>
                    <Button
                      color={ selectedComponentToEdit.children[0].type.indexOf('Flexible') === -1 ? "success" : "danger" }
                      className="mt-3 ml-2"
                      onClick={ () => {
                        let tmp = _.cloneDeep( selectedComponentToEdit )
                        if( selectedComponentToEdit.children[0].type.indexOf( 'Flexible' ) === -1 ){
                          tmp.children[0].type = tmp.children[0].type + 'Flexible'
                        } else {
                          tmp.children[0].type = tmp.children[0].type.replace( 'Flexible', '' )
                        }
                        return updateSelectedComponentToEdit( 'children', tmp.children ) }}>
                      { selectedComponentToEdit.children[0].type.indexOf('Flexible') === -1
                        ? Lang[ 'ENABLE_FLEXIBLE_OPTION' ][ selectedLanguage ]
                        : Lang[ 'DISABLE_FLEXIBLE_OPTION' ][ selectedLanguage ] }
                    </Button>
                </Col>
              )
            }
            {
              selectedComponentToEdit.children[0].type.indexOf( 'Dropdown' ) !== -1 && <DropdownEditor 
                selectedComponentToEdit={ selectedComponentToEdit }
                selectedLanguage={ selectedLanguage }
                updateSelectedComponentToEdit={ updateSelectedComponentToEdit } />
            }
          </Row>
        )
      }
    </Form>
  )

  return (
    <Modal
      isOpen={ showUpdateColumnModal }
      size={ 'lg' }
      toggle={ () => {
        onChangeFormBuilderState( 'showUpdateColumnModal', false )
      }}>
      <ModalHeader toggle={ () => onChangeFormBuilderState( 'showUpdateColumnModal', false )}>{ Lang[ 'UPDATE_COLUMN' ][ selectedLanguage ] }</ModalHeader>
      <ModalBody>
        {
          !_.isEmpty( selectedComponentToEdit ) && selectedComponentToEdit.type !== 'FormFrame' ? (
            <>
              <Tabs
                items={
                  ( selectedComponentToEdit.children[0].type === 'Image' || selectedComponentToEdit.children[0].type === 'Text' ) ? ([
                    {
                      key: 0,
                      title: Lang[ 'FIELD_SETTINGS' ][ selectedLanguage ],
                      getContent: () => renderColumnTab()
                    }
                  ]) : ([
                    {
                      key: 0,
                      title: Lang[ 'FIELD_SETTINGS' ][ selectedLanguage ],
                      getContent: () => renderColumnTab()
                    },
                    {
                      key: 1,
                      title: Lang[ 'FIELD_VALUES' ][ selectedLanguage ],
                      getContent: () => renderColumnTab()
                    }
                  ])
                }
                transformWidth='400px'
                selectedTabKey={ tab }
                onChange={ val => updateTab( val ) } />
            </>
          ) : (
            <p>{ Lang[ 'NO_CONTENT_FOUND_PLEASE_SELECT_A_COMPONENT' ][ selectedLanguage ] }</p>
          )
        }
        <MediaDatabase
          site={ site }
          onSelectedImage={ ( param ) => {
            let tmp = _.cloneDeep( selectedComponentToEdit )
            tmp.children[0].url = param
            updateSelectedComponentToEdit( 'children', tmp.children )
            toggleMediaDatabase( false )
          }}
          selectedLanguage={ selectedLanguage }
          allowed={[ 'jpg', 'jpeg', 'png' ]} />
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={ async () => {
            let tmp = _.cloneDeep( selectedComponentToEdit )
            let tmpDOM = _.cloneDeep( dom )
            findObjectAndPatch( tmpDOM, tmp )
            await onChangeFormBuilderState( 'dom', tmpDOM )
            await onChangeFormBuilderState( 'showUpdateColumnModal', false )
          }}>{ Lang[ 'SUBMIT' ][ selectedLanguage ] }</Button>
      </ModalFooter>
    </Modal>
  )
}

const mapStateToProps = state => ({ data: state })

export default connect( mapStateToProps, {
  toggleMediaDatabase
})( UpdateColumnModal )
