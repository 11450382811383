import React, { useEffect } from 'react'
import {
  Card, CardBody, CardHeader, UncontrolledTooltip, CardFooter, Label, Input,
  CardTitle, Container, Row, Col, Button, FormGroup
} from 'reactstrap'
import { connect } from 'react-redux'
import { compose } from 'redux'
import _ from 'lodash'
import { convertObjToBase64 } from 'utils/objToBase64'

import PageTitle from 'components/PageTitle'
import ARKTable from 'components/Table'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import FunnelScenarioHOC from './actions'
import CreateScenarioForm from './Create'
import UpdateScenarioForm from './Update'

import Lang from 'Lang/General'

const FunnelScenario = props => {

  useEffect( () => {
    let tmp = {
      page: 1,
      is_paginated: true
    }
    props.getAllScenarios( convertObjToBase64( tmp ) )
  }, [])

  const handleKeyPress = event => {
    if( event.key === 'Enter' ){
      let tmp = {
        page: 1,
        is_paginated: true,
        filter: {
          name: {
            $like: `%${ props.name }%`
          }
        }
      }
      props.getAllScenarios( convertObjToBase64( tmp ) )
    }
  }

  const handleSecurityModulesCreate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Marketing Funnels' })
      return tmpModules.is_create
    }
  }

  const handleSecurityModulesUpdate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Marketing Funnels' })
      return tmpModules.is_update
    }
  }

  return (
    <>
      <PageTitle
        heading={ Lang[ 'FUNNEL_SCENARIOS' ][ props.data.languageReducer.lang ] }
        icon="pe-7s-lintern icon-gradient bg-happy-fisher"
        buttons={[
          {
            buttonText: Lang[ 'CREATE_SCENARIO' ][ props.data.languageReducer.lang ],
            onClick: () => {
              // let tmp = {
              //   page: props.consultants.meta ? props.consultants.meta.current_page : 1,
              //   is_paginated: true
              // }
              props.onChangeScenarioHOC( 'showCreateScenario', true )
            },
            display: handleSecurityModulesCreate()
          }
        ]} />
      <Card className="mb-3">
        <CardHeader>{ Lang[ 'SEARCH' ][ props.data.languageReducer.lang ] }</CardHeader>
        <CardBody>
          <FormGroup>
            <Label>{ Lang[ 'NAME' ][ props.data.languageReducer.lang ] }</Label>
            <Input
              name="campaign-type" id="campaign-type"
              placeholder={ Lang[ 'SEARCH_WITH_NAME' ][ props.data.languageReducer.lang ] }
              value={ props.name }
              onKeyDown={ handleKeyPress }
              onChange={ e => props.onChangeScenarioHOC( 'name', e.target.value ) }/>
          </FormGroup>
        </CardBody>
        <CardFooter>
          <Button
            color="primary"
            className="ml-auto"
            onClick={ () => {
              let tmp = {
                page: 1,
                is_paginated: true,
                filter: {
                  name: {
                    $like: `%${ props.name }%`
                  }
                }
              }
              props.getAllScenarios( convertObjToBase64( tmp ) )
            }}>{ Lang[ 'SEARCH' ][ props.data.languageReducer.lang ] }</Button>
          <Button
            color="danger"
            onClick={ () => {
              let tmp = {
                page: 1,
                is_paginated: true
              }
              props.onChangeScenarioHOC( 'name', '' )
              return props.getAllScenarios( convertObjToBase64( tmp ) )
            }}>{ Lang[ 'RESET' ][ props.data.languageReducer.lang ] }</Button>
        </CardFooter>
      </Card>
      <ARKTable
        data={ props.allScenarios.data }
        columns={[
          {
            Header: Lang[ 'NAME' ][ props.data.languageReducer.lang ],
            accessor: 'name',
            width: 600,
            Cell: ({value} ) => (
              <>
                <span>
                { value[ props.data.languageReducer.lang ] }
                </span>
              </>
            )
          },
          {
            Header: Lang[ 'ACTION' ][ props.data.languageReducer.lang ],
            accessor: 'id',
            Cell: ({value}) => (
              <div>
                {
                  handleSecurityModulesUpdate() && (
                    <>
                      <Button
                        id={ `scenario-${ value }` }
                        className="mb-2 mr-2 btn-icon btn-icon-only"
                        color="primary"
                        onClick={ () => {
                          props.getSelectedScenario( value )
                          props.onChangeScenarioHOC( 'showUpdateForm', true )
                        }}>
                        <i className="pe-7s-note"> </i>
                      </Button>
                      <UncontrolledTooltip
                        target={ `scenario-${ value }` }
                        placement="top">{ Lang[ 'EDIT' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                    </>
                  )
                }
              </div>
            )
          }
        ]}
        totalPages={ props.totalPages }
        page={ 1 }
        searchComponent={ false }
        showPagination={ false } />
      <CreateScenarioForm
        { ...props }
        selectedLanguage={ props.data.languageReducer.lang } />
      <UpdateScenarioForm
        { ...props }
        selectedLanguage={ props.data.languageReducer.lang } />
      { ( props.data.ajaxReducer.ajaxCallProgress > 0 || props.onLoadScenario ) && <LoadingOverlay /> }
    </>
  )
}

const mapStateToProps = state => ({ data: state })

export default compose(
  FunnelScenarioHOC,
  connect( mapStateToProps )
)( FunnelScenario )
