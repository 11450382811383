import React, { Component } from 'react'
import { isEmpty, reduce } from 'lodash'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get, Put } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state = {
      loading: false,
      emailSettings: [],
      emailSettingsPages: [],
      selectedEmailSetting: {},
      allCenters: [],
      searchParams: {
        center: {}
      },
      emailSettingsErrors: {}
    }

    onChangeEmailSettingsHOC = ( key, value ) => this.setState({ [ key ]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => {
      toast.success( success )
      this.setState({ emailSettingsErrors: {} })
    }
    requestError = error => {
      typeof error === 'string'
        ? toast.error( error )
        : Object.values( error ).map( err => toast.error( err.replaceAll( '_', ' ' ) ) )
      typeof error !== 'string' && this.setState({ 
        emailSettingsErrors: reduce( 
          error,  
          ( resError, errVal, errKey ) => (
            { 
              ...resError, 
              [ errKey ] : errVal.replaceAll( '_', ' ' )
            }
          ),
          {}
        )
      })
    }

    getEmailSettings = ( page, isReset ) => {
      let tmpSearchParams = {
        page: page ? page : 1,
        is_paginated: true
      }
      if( !isReset && !isEmpty( this.state.searchParams.center ) ){
        tmpSearchParams.filter = { center_id: this.state.searchParams.center.id }
      }
      let tmpSearchString = convertObjToBase64( tmpSearchParams )

      Get(
        `/api/v1/domains?query=${ tmpSearchString }`,
        payload => this.getEmailSettingsSuccess( payload, isReset ),
        this.getEmailSettingsError,
        this.load
      )
    }
    getEmailSettingsSuccess = ( payload, isReset ) => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      this.setState({ 
        emailSettings: payload, 
        emailSettingsPages: tmpTotalPages,
        searchParams: isReset 
          ? { center: {} } 
          : this.state.searchParams
      })
    }
    getEmailSettingsError = error => this.requestError( error )

    getSelectedEmailSetting = center_id => Get(
      `/api/v1/centers/${ center_id }/mail_quota`,
      payload => this.getSelectedEmailSettingSuccess( payload, center_id ),
      this.getSelectedEmailSettingError,
      this.load
    )
    getSelectedEmailSettingSuccess = ( payload, center_id ) => this.setState({ 
      selectedEmailSetting: { center_id: center_id, ...payload } 
    })
    getSelectedEmailSettingError = error => this.requestError( error )

    updateEmailSetting = data => Put(
      `/api/v1/centers/${ data.center_id }/update_mail_quota/${ data.monthly_quota }`,
      {},
      this.updateEmailSettingSuccess,
      this.updateEmailSettingError,
      this.load
    )
    updateEmailSettingSuccess = () => {
      this.setState({ selectedEmailSetting: {} })
      this.requestSuccess( Lang[ 'EMAIL_SETTING_IS_SUCCESSFULLY_UPDATED' ][ this.props.data.languageReducer.lang ] )
    }
    updateEmailSettingError = error => this.requestError( error )

    getCenters = () => Get(
      `/api/v1/centers?query=${ convertObjToBase64({ is_paginated: false }) }`,
      this.getCentersSuccess,
      this.getCentersError,
      this.load
    )
    getCentersSuccess = payload => {
      let defaultCenter = { id: -1, label: '', value: '' } 
      let centerOptions = [ defaultCenter, ...payload.data.map( item => ({ id: item.id, label: item.center_name, value: item.center_name }) ) ]
      this.setState({ centers: centerOptions })
    }
    getCentersError = () => {}

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          { ...this.state }
          onLoadEmailSettingsHOC={ this.state.loading }
          onChangeEmailSettingsHOC={ this.onChangeEmailSettingsHOC }
          getEmailSettings={ this.getEmailSettings }
          getSelectedEmailSetting={ this.getSelectedEmailSetting }
          updateEmailSetting={ this.updateEmailSetting }
          getCenters={ this.getCenters }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
