import React, { Component } from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Label,
  Input,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Container,
  UncontrolledTooltip
} from 'reactstrap'
import _ from 'lodash'
import { compose } from 'redux'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PageTitle from 'components/PageTitle'
import ARKTable from 'components/Table'
import CreateCampaign from './Create/index'
import UpdateCampaign from './Update'
import DeleteConfirmation from 'components/Modal/delete'

import WithCampaigns from './actions'
import WithCenterCountries from './actions/CenterCountries'
import WithCenterGroups from './actions/CenterGroups'
import WithCampaignTypes from './actions/CampaignTypes'
import WithCenters from '../../Center/actions'
import { convertObjToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'
import ViewCampaign from './View'

const bookable_months = [
  {
    value: 'January',
    label: 1
  },
  {
    value: 'February',
    label: 2
  },
  {
    value: 'March',
    label: 3
  },
  {
    value: 'April',
    label: 4
  },
  {
    value: 'May',
    label: 5
  },
  {
    value: 'June',
    label: 6
  },
  {
    value: 'July',
    label: 7
  },
  {
    value: 'August',
    label: 8
  },
  {
    value: 'September',
    label: 9
  },
  {
    value: 'October',
    label: 10
  },
  {
    value: 'November',
    label: 11
  },
  {
    value: 'December',
    label: 12
  }
]

const bookable_countries = [
  'Austria',
  'Belgium', 
  'France',
  'Germany',
  'Italy',
  'Netherlands', 
  'Spain', 
  'Switzerland'
]

class CampaignListings extends Component {

  componentDidMount = () => {
    let tmp = {
      page: 1,
      is_paginated: true,
      filter: {
        status: 'ACTIVE'
      }
    }
    this.props.getOLSCampaignListings( convertObjToBase64( tmp ) )
    this.props.getCenterCountries()
    this.props.getCenterGroups()
    this.props.getCampaignTypes()
    this.props.getCenters({})
  }

  componentDidUpdate = pp => {
    if( pp.archivedCampaignModal && !this.props.archivedCampaignModal ){
      let tmp = {
        page: 1,
        is_paginated: false,
        filter: {
          status: 'ACTIVE'
        }
      }
      this.props.getOLSCampaignListings( convertObjToBase64( tmp ) )
    }

    if( ( pp.showCreateCampaign && !this.props.showCreateCampaign ) || ( pp.showUpdateCampaign && !this.props.showUpdateCampaign ) ) {
      this.props.onChangeCampaignListingHOC( 'campaignErrors', {} )
    }
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'OLS' })
      return tmpModules.is_create
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'OLS' })
      return tmpModules.is_update
    }
  }

  handleSecurityModulesDelete = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'OLS' })
      return tmpModules.is_delete
    }
  }

  handleSecurityModulesRead = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'OLS' })
      return tmpModules.is_read
    }
  }

  renderArchivedCampaignModal = () => {
    return (
      <Modal
        toggle={ () => {
          Promise.all([
            this.props.onChangeCampaignListingHOC( 'archivedCampaignModal', false )
          ]).then( () => this.props.onChangeCampaignListingHOC( 'archivedCampaignSearchTitle', '' ) )
        }}
        isOpen={ this.props.archivedCampaignModal }
        size="xl">
        <ModalHeader
          toggle={ () => {
            Promise.all([
              this.props.onChangeCampaignListingHOC( 'archivedCampaignModal', false )
            ]).then( () => this.props.onChangeCampaignListingHOC( 'archivedCampaignSearchTitle', '' ) )
          }}>{ Lang[ 'ARCHIVED_CAMPAIGN' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <Container style={{ margin: 0 }}>
            <Card className="mb-3">
            <CardHeader>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }</CardHeader>
              <CardBody>
                <FormGroup>
                  <Label>{ Lang[ 'TITLE' ][ this.props.data.languageReducer.lang ] }</Label>
                  <Input
                    name="campaign-title"
                    placeholder={ Lang[ 'SEARCH_BY_TITLE' ][ this.props.data.languageReducer.lang ] }
                    value={ this.props.archivedCampaignSearchTitle }
                    onChange={ e => this.props.onChangeCampaignListingHOC( 'archivedCampaignSearchTitle', e.target.value ) }/>
                </FormGroup>
              </CardBody>
              <CardFooter>
                <Button
                  color="primary"
                  className="ml-auto"
                  onClick={ () => {
                    let tmp = {
                      page: 1,
                      is_paginated: true,
                      filter: {
                        title: {
                          $like: `%${ this.props.archivedCampaignSearchTitle }%`
                        },
                        status: 'INACTIVE'
                      }
                    }
                    this.props.getOLSCampaignListings( convertObjToBase64( tmp ) )
                  }}>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }</Button>
                <Button
                  color="danger"
                  onClick={ () => {
                    let tmp = {
                      page: 1,
                      is_paginated: true,
                      filter: {
                        status: 'INACTIVE'
                      }
                    }
                    this.props.onChangeCampaignListingHOC( 'archivedCampaignSearchTitle', '' )
                    return this.props.getOLSCampaignListings( convertObjToBase64( tmp ) )
                  }}>{ Lang[ 'RESET' ][ this.props.data.languageReducer.lang ] }</Button>
              </CardFooter>
            </Card>
            <ARKTable
              data={ this.props.archivedCampaigns.data }
              columns={[
                {
                  Header: Lang[ 'TITLE' ][ this.props.data.languageReducer.lang ],
                  accessor: "title",
                  style: { "line-break": "anywhere" }
                },
                {
                  Header: Lang[ 'COUNTRIES' ][ this.props.data.languageReducer.lang ],
                  accessor: "bookable_countries",
                  Cell: ({ value }) => (
                    <ul>
                    {
                      value && value.map( item => (
                        <li key={ item }>{ item }</li>
                      ))
                    }
                    </ul>
                  )
                },
                {
                  Header: Lang[ 'CAMPAIGN_TYPES' ][ this.props.data.languageReducer.lang ],
                  accessor: 'types',
                  Cell: ({ value }) => (
                    <ul>
                    {
                      value && value.map( item => (
                        <li key={ item.id }>{ item.title }</li>
                      ))
                    }
                    </ul>
                  )
                },
                {
                  Header: Lang[ 'BOOKABLE_MONTHS' ][ this.props.data.languageReducer.lang ],
                  accessor: 'bookable_months',
                  Cell: ({ value }) => (
                    <div>
                    {
                      value && value.map( item => {
                        let month = _.filter( bookable_months, { value: item } )
                        return (
                          month && month.length > 0 && month.map( month => {
                            return month.label
                          })
                        )
                      }).join(', ')
                    }
                    </div>
                  )
                },
                {
                  Header: Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ],
                  accessor: 'status',
                  className: "d-flex flex-row align-items-center justify-content-center",
                  Cell: ({ value }) => Lang[ value ][ this.props.data.languageReducer.lang ]
                },
                {
                  Header: Lang[ 'IS_FOR_CENTER' ][ this.props.data.languageReducer.lang ],
                  accessor: 'is_for_center',
                  className: "d-flex flex-row align-items-center justify-content-center",
                  Cell: ({ value }) => Lang[ value === true ? 'YES' : 'NO' ][ this.props.data.languageReducer.lang ]
                },
                {
                  Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
                  accessor: "id",
                  className: "d-flex flex-row align-items-center justify-content-center",
                  Cell: ({value}) => (
                    <div>
                      {
                        this.handleSecurityModulesUpdate() && <>
                          <Button
                            id={ `Campaign-listings-${ value }-update-campaign` }
                            className="mb-2 mr-2 p-2 btn-icon btn-icon-only"
                            color="primary"
                            onClick={ () => {
                              this.props.getSelectedOLSCampaign( value, 'edit' )
                              this.props.onChangeCampaignListingHOC( 'mode', 'archived' )
                            }}>
                            <i className="pe-7s-note" style={{ fontSize: '13px' }}> </i>
                          </Button>
                          <UncontrolledTooltip
                            target={ `Campaign-listings-${ value }-update-campaign` }
                            placement="top">{ Lang[ 'UPDATE_CAMPAIGN' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                        </>
                      }
                      {
                        !this.handleSecurityModulesUpdate() && this.handleSecurityModulesRead() && <>
                          <Button
                            id={ `Campaign-listings-${ value }-view-campaign` }
                            className="mb-2 mr-2 p-2 btn-icon btn-icon-only"
                            color="primary"
                            onClick={ () => {
                              this.props.getSelectedOLSCampaign( value, 'view' )
                              this.props.onChangeCampaignListingHOC( 'mode', 'archived' )
                            }}>
                            <i className="pe-7s-look" style={{ fontSize: '13px' }}> </i>
                          </Button>
                          <UncontrolledTooltip
                            target={ `Campaign-listings-${ value }-view-campaign` }
                            placement="top">{ Lang[ 'VIEW_CAMPAIGN' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                        </>
                      }
                      {/*<Button
                        id="btn-set-inactive"
                        className="mb-2 mr-2 p-1 btn-icon btn-icon-only"
                        color="danger"
                        onClick={ () => {
                          this.props.getSelectedOLSCampaign( value, 'status' )
                        }}>
                        <i className="pe-7s-close" style={{ fontSize: '20px' }}> </i>
                      </Button>*/}
                    </div>
                  )
                }
              ]}
              page={ this.props.archivedCampaigns.meta ? this.props.archivedCampaigns.meta.current_page : 1 }
              totalPages={ this.props.archivedCampaignTotalPages }
              onChangePage={ pg => {
                let tmp = {
                  page: pg,
                  is_paginated: true,
                  filter: {
                    status: 'INACTIVE'
                  }
                }
                this.props.getOLSCampaignListings( convertObjToBase64( tmp ) )
              }}
              showPagination={ false } />
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            className="mb-2 mr-2"
            color="primary"
            onClick={ () => {
              Promise.all([
                this.props.onChangeCampaignListingHOC( 'archivedCampaignModal', false )
              ]).then(
                () => {
                  this.props.onChangeCampaignListingHOC( 'archivedCampaignSearchTitle', '' )
                  this.props.getOLSCampaignListings( convertObjToBase64({
                    page: 1,
                    is_paginated: true,
                    filter: {
                      status: 'ACTIVE'
                    }
                  }) )
                }
              )
            }}>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  render = () => {
    return (
      <>
        <PageTitle
          heading={ Lang[ 'CAMPAIGN_LISTINGS' ][ this.props.data.languageReducer.lang ] }
          icon="pe-7s-ticket icon-gradient bg-happy-fisher"
          buttons={[
            {
              buttonText: Lang[ 'ARCHIVED_CAMPAIGN' ][ this.props.data.languageReducer.lang ],
              onClick: () => {
                let tmp = {
                  page: 1,
                  is_paginated: true,
                  filter: {
                    status: 'INACTIVE'
                  }
                }
                this.props.getOLSCampaignListings( convertObjToBase64( tmp ) )
                this.props.onChangeCampaignListingHOC( 'archivedCampaignModal', true )
              },
              display: true
            },
            {
              buttonText: Lang[ 'CREATE_CAMPAIGN' ][ this.props.data.languageReducer.lang ],
              onClick: () => this.props.onChangeCampaignListingHOC( 'showCreateCampaign', true ),
              display: this.handleSecurityModulesCreate()
            }
          ]} />
        <Card className="mb-3">
          <CardHeader>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }</CardHeader>
          <CardBody>
            <FormGroup>
              <Label>{ Lang[ 'TITLE' ][ this.props.data.languageReducer.lang ] }</Label>
              <Input
                name="campaign-title"
                placeholder={ Lang[ 'SEARCH_BY_TITLE' ][ this.props.data.languageReducer.lang ] }
                value={ this.props.searchTitle }
                onChange={ e => this.props.onChangeCampaignListingHOC( 'searchTitle', e.target.value ) }/>
            </FormGroup>
          </CardBody>
          <CardFooter>
            <Button
              color="primary"
              className="ml-auto mr-2"
              onClick={ () => {
                let tmp = {
                  page: 1,
                  is_paginated: true,
                  filter: {
                    title: {
                      $like: `%${ this.props.searchTitle }%`
                    },
                    $or: [
                      { status: 'ACTIVE' },
                      { status: 'INACTIVE' }
                    ]
                  }
                }
                this.props.getOLSCampaignListings( convertObjToBase64( tmp ) )
              }}>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }</Button>
            <Button
              color="danger"
              onClick={ () => {
                let tmp = {
                  page: 1,
                  is_paginated: true,
                  filter: {
                    $or: [
                      { status: 'ACTIVE' },
                      { status: 'INACTIVE' }
                    ]
                  }
                }
                this.props.onChangeCampaignListingHOC( 'searchTitle', '' )
                return this.props.getOLSCampaignListings( convertObjToBase64( tmp ) )
              }}>{ Lang[ 'RESET' ][ this.props.data.languageReducer.lang ] }</Button>
          </CardFooter>
        </Card>
        <ARKTable
          data={ this.props.campaigns.data }
          columns={[
            {
              Header: Lang[ 'TITLE' ][ this.props.data.languageReducer.lang ],
              accessor: "title",
              style: { "line-break": "anywhere" }
            },
            {
              Header: Lang[ 'COUNTRIES' ][ this.props.data.languageReducer.lang ],
              accessor: "bookable_countries",
              Cell: ({ value }) => (
                <ul>
                {
                  value && value.map( item => (
                    <li key={ item }>{ item }</li>
                  ))
                }
                </ul>
              )
            },
            {
              Header: Lang[ 'CAMPAIGN_TYPES' ][ this.props.data.languageReducer.lang ],
              accessor: 'types',
              Cell: ({ value }) => (
                <ul>
                {
                  value && value.map( item => (
                    <li key={ item.id }>{ item.title }</li>
                  ))
                }
                </ul>
              )
            },
            {
              Header: Lang[ 'BOOKABLE_MONTHS' ][ this.props.data.languageReducer.lang ],
              accessor: 'bookable_months',
              Cell: ({ value }) => (
                <div>
                {
                  value && value.map( item => {
                    let month = _.filter( bookable_months, { value: item } )
                    return (
                      month && month.length > 0 && month.map( month => {
                        return month.label
                      })
                    )
                  }).join(', ')
                }
                </div>
              )
            },
            {
              Header: Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ],
              accessor: 'status',
              className: "d-flex flex-row align-items-center justify-content-center",
              Cell: ({ value }) => Lang[ value ][ this.props.data.languageReducer.lang ]
            },
            {
              Header: Lang[ 'IS_FOR_CENTER' ][ this.props.data.languageReducer.lang ],
              accessor: 'is_for_center',
              className: "d-flex flex-row align-items-center justify-content-center",
              Cell: ({ value }) => Lang[ value === true ? 'YES' : 'NO' ][ this.props.data.languageReducer.lang ]
            },
            {
              Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
              accessor: "id",
              className: "d-flex flex-row align-items-center justify-content-center",
              Cell: ({value}) => (
                <div>
                  {
                    this.handleSecurityModulesUpdate() && <>
                      <Button
                        id={ `Campaign-listings-${ value }-update-campaign` }
                        className="mb-2 mr-2 p-2 btn-icon btn-icon-only"
                        color="primary"
                        onClick={ () => {
                          this.props.getSelectedOLSCampaign( value, 'edit' )
                        }}>
                        <i className="pe-7s-note" style={{ fontSize: '13px' }}> </i>
                      </Button>
                      <UncontrolledTooltip
                        target={ `Campaign-listings-${ value }-update-campaign` }
                        placement="top">{ Lang[ 'UPDATE_CAMPAIGN' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                      <Button
                        id={ `Campaign-listings-${ value }-set-campaign-inactive` }
                        className="mb-2 mr-2 p-2 btn-icon btn-icon-only"
                        color="danger"
                        onClick={ () => {
                          this.props.getSelectedOLSCampaign( value, 'status' )
                        }}>
                        <i className="pe-7s-close" style={{ fontSize: '20px' }}> </i>
                      </Button>
                      <UncontrolledTooltip
                        target={ `Campaign-listings-${ value }-set-campaign-inactive` }
                        placement="top">{ Lang[ 'SET_INTO_INACTIVE' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                    </>
                  }
                  {
                    !this.handleSecurityModulesUpdate() && this.handleSecurityModulesRead() && <>
                      <Button
                        id={ `Campaign-listings-${ value }-view-campaign` }
                        className="mb-2 mr-2 p-2 btn-icon btn-icon-only"
                        color="primary"
                        onClick={ () => {
                          this.props.getSelectedOLSCampaign( value, 'view' )
                        }}>
                        <i className="pe-7s-look" style={{ fontSize: '13px' }}> </i>
                      </Button>
                      <UncontrolledTooltip
                        target={ `Campaign-listings-${ value }-view-campaign` }
                        placement="top">{ Lang[ 'VIEW_CAMPAIGN' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                    </>
                  }
                </div>
              )
            }
          ]}
          page={ this.props.campaigns.meta.current_page }
          totalPages={ this.props.campaignTotalPages }
          onChangePage={ pg => {
            let tmp = {
              page: pg,
              is_paginated: true,
              filter: {
                status: 'ACTIVE'
              }
            }
            this.props.getOLSCampaignListings( convertObjToBase64( tmp ) )
          }}
          showPagination={ false } />
        <CreateCampaign 
          { ...this.props }
          bookableCountries={ bookable_countries } />
        <UpdateCampaign 
          { ...this.props }
          bookableCountries={ bookable_countries } />
        <ViewCampaign 
          { ...this.props } />
        { this.renderArchivedCampaignModal() }
        {
          this.props.showDeleteCampaignConfirmation && (
            <DeleteConfirmation
              handleModalClose={ () => this.props.onChangeCampaignListingHOC( 'showDeleteCampaignConfirmation', false ) }
              deleteOpen={ this.props.showDeleteCampaignConfirmation }
              confirmAction={ () => {
                this.props.onChangeCampaignListingHOC( 'showDeleteCampaignConfirmation', false )
                this.props.updateOLSCampaign(
                  {
                    ...this.props.selectedCampaign,
                    status: 'INACTIVE'
                  },
                  {
                    page: this.props.campaigns.meta.current_page,
                    is_paginated: true,
                    filter: {
                      status: 'ACTIVE'
                    }
                  }
                )
              }}
              content={ Lang[ 'SET_INACTIVE_CONFIRMATION_MESSAGE' ][ this.props.data.languageReducer.lang ] }
              lang={ this.props.data.languageReducer.lang } />
          )
        }
        { this.props.onLoadCampaignListings && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose(
  WithCenterCountries,
  WithCampaigns,
  WithCenterGroups,
  WithCampaignTypes,
  WithCenters
)( CampaignListings )
