import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { convertObjToBase64 } from 'utils/objToBase64'
import _ from 'lodash'

import { Get, Put, Post, Delete } from 'utils/axios-template'
import { production_url } from '../../TemplateEditor/config'

import Lang from 'Lang/General'

const SiteSectionHOC = ( WrappedComponent ) => {
  class SiteSectionWrappedComponent extends Component{
    state={
      loading: false,
      sections: [],
      filteredSections: [],
      selectedSection: {},
      assignCategoryData: {
        section_id: '',
        category_id: 0
      },
      showAssignCategoriesModal: false,
      searchSectionName: '',
      searchTemplate: '',
      section_categories: []
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    onChangeSectionHOC = ( key, value ) => this.setState({ [ key ]: value })

    getSiteSection = query => Get(
      `${ production_url }/sections?filters[query]=${ convertObjToBase64( query ) }`,
      this.getSiteSectionSuccess,
      this.getSiteSectionError,
      this.load
    )
    getSiteSectionSuccess = payload => this.setState({ 
      sections: _.sortBy( _.uniqBy( payload, 'id' ), 'name' ),
      filteredSections: _.sortBy( _.uniqBy( payload, 'id' ), 'name' )
    })
    getSiteSectionError = error => this.requestError( error )

    getSelectedSiteSection = id => Get(
      `${ production_url }/sections/${ id }`,
      this.getSelectedSiteSectionSuccess,
      this.getSelectedSiteSectionError,
      this.load
    )
    getSelectedSiteSectionSuccess = payload => this.setState({ selectedSection: payload })
    getSelectedSiteSectionError = error => this.requestError( error )

    updateSelectedSectionName = ( name ) => Put(
      `${ production_url }/sections/${ this.state.selectedSection.id }`,
      { name: name },
      this.updateSelectedSectionNameSuccess,
      this.updateSelectedSectionNameError,
      this.load
    )
    updateSelectedSectionNameSuccess = payload => {
      this.requestSuccess(payload.message)
    }
    updateSelectedSectionNameError = error => this.requestError( error )

    postSectionCategory = data => Post(
      `${ production_url }/section-categories`,
      data,
      this.postSectionCategorySuccess,
      this.postSectionCategoryError,
      this.load
    )
    postSectionCategorySuccess = payload => {
      this.requestSuccess( Lang[ 'SECTION_CATEGORY_ASSIGN_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getSelectedSiteSection( this.state.selectedSection.id )
    }
    postSectionCategoryError = error => this.setState({ errorMessage: error }, () => this.requestError( Lang[ 'ASSIGN_SECTION_CATEGORY_FAILED' ][ this.props.data.languageReducer.lang ] ) )

    deleteSectionCategory = id => Delete(
      `${ production_url }/section-categories/${ id }`,
      this.deleteSectionCategorySuccess,
      this.deleteSectionCategoryError,
      this.load
    )
    deleteSectionCategorySuccess = payload => {
      this.requestSuccess( Lang[ 'SECTION_CATEGORY_REMOVE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getSelectedSiteSection( this.state.selectedSection.id )
    }
    deleteSectionCategoryError = error => this.setState({ errorMessage: error }, () => this.requestError( Lang[ 'DELETE_SECTION_CATEGORY_FAIL' ][ this.props.data.languageReducer.lang ] ) )

    updateSelectedSection = ( data ) => Put(
      `${ production_url }/sections/${ this.state.selectedSection.id }`,
      data,
      ()=>this.updateSelectedSectionSuccess(data.content.id),
      this.updateSelectedSectionError,
      this.load
    )
    updateSelectedSectionSuccess = ( id ) => {
      this.getSelectedSiteSection( id )
      this.requestSuccess( Lang[ 'SECTION_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
    }
    updateSelectedSectionError = error => this.requestError( error )

    render = () => {
      return(
        <Fragment>
          <WrappedComponent
            { ...this.props }
            filteredSections={ this.state.filteredSections }
            sections={ this.state.sections }
            searchTemplate={ this.state.searchTemplate }
            getSiteSection={ this.getSiteSection }
            getSelectedSiteSection={ this.getSelectedSiteSection }
            selectedSection={ this.state.selectedSection }
            selectedSectionName={ this.state.selectedSectionName }
            updateSelectedSectionName={ this.updateSelectedSectionName }
            updateSelectedSection={ this.updateSelectedSection }
            postSectionCategory={ this.postSectionCategory }
            deleteSectionCategory={ this.deleteSectionCategory }
            onChangeSectionHOC={ this.onChangeSectionHOC }
            assignCategoryData={ this.state.assignCategoryData }
            showAssignCategoriesModal={ this.state.showAssignCategoriesModal }
            searchSectionName={ this.state.searchSectionName }
            section_categories={ this.state.section_categories }
            onLoadSiteSection={ this.state.loading } />
        </Fragment>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( SiteSectionWrappedComponent )
}

export default SiteSectionHOC
