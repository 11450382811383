import React, { Component } from 'react'
import _ from 'lodash'
import uniqid from 'uniqid'

import CoreRenderFunction from '../../core/render'

class HeaderMenuDesktop extends Component {

  patchObjectID = param => {
    param.id = uniqid()
    if( param && param.children ) {
      param.children.map( item => {
        this.patchObjectID( item )
      })
    }
  }

  render = () => {
    let {
      updateMainContainerState,
      languages,
      selectedLanguageShortname
    } = this.props
    let desktopBlueprint = _.find( this.props.templateHeadersBlueprints, obj => {
      if( obj.name.indexOf( 'Desktop' ) > -1 ) {
        return obj
      }
    })
    if( this.props.templateHeaders && this.props.templateHeaders.content ) {
      return this.props.templateHeaders.content.map( item => {
        // level 1
        let desktopBlueprintContent = _.cloneDeep( desktopBlueprint.content )
        let computedDOM = _.cloneDeep( desktopBlueprintContent )
        this.patchObjectID( computedDOM )
        computedDOM.children[ 0 ].content = item.content
        computedDOM.children.pop()
        if( item.children ) {
          computedDOM.children.push( desktopBlueprintContent.children[ 1 ] )
          computedDOM.children[ 1 ].children = []
          item.children.map(( child, index ) => {
            // level 2
            let tmp = _.cloneDeep( desktopBlueprint.content.children[ 1 ].children[ 0 ] )
            tmp.content = child.content
            computedDOM.children[ 1 ].children.push( tmp )
            if( child.children ) {
              let desktopBlueprintContent = _.cloneDeep( desktopBlueprint.content )
              child.children.map(( grandchild ) => {
                // level 3
                let tmp = _.cloneDeep( desktopBlueprintContent.children[ 1 ].children[ 1 ].children[ 0 ] )
                tmp.content = grandchild.content
                computedDOM.children[ 1 ].children.push( tmp )
              })
            }
          })
        }
        this.patchObjectID( computedDOM )
        return (
          <CoreRenderFunction
            item={ computedDOM }
            updateMainContainerState={ updateMainContainerState }
            languages={ languages }
            selectedLanguageShortname={ selectedLanguageShortname } />
        )
      })
    } else {
      return <div></div>
    }
  }
}

export default HeaderMenuDesktop