import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get, Post, outdatedToken, Put, Delete } from 'utils/axios'
import { getRefreshToken } from 'actions/token'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const CategoryHOC = ( WrappedComponent ) => {
  class CategoryWrappedComponent extends Component{
    state = {
      loading: false,
      categories: [],
      categoryModal: false,
      categoryData: {
        name: {
          en: '',
          de: '',
          it: '',
          nl: ''
        },
        description: {
          en: '',
          de: '',
          it: '',
          nl: ''
        },
        status: "ACTIVE"
      },
      selectedCategory: {},
      categoryMode: '',
      errorMessage: {},
      searchName: '',
      totalPages: [],
      deleteModal: false
    }

    load = param => this.setState({ loading: param })
    updateLoad = param => this.setState({ updateLoading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    ajaxError = ( error ) => {
      outdatedToken( error, this.props.history )
      this.requestError( error )
    }

    handleCategoryModal = ( param, mode ) => this.setState({ categoryModal: param, categoryMode: mode, errorMessage: {}, selectedCategory: {} })


    onChangeCategoryData = ( key, value ) => {
      let tmp = {}
      if( this.state.selectedCategory.name )
      {
        tmp = _.cloneDeep( this.state.selectedCategory )
        tmp[key] = value
        this.setState({ selectedCategory: tmp })
      }
      else{
        tmp = _.cloneDeep( this.state.categoryData )
        tmp[key] = value
        this.setState({ categoryData: tmp })
      }
    }

    onChangeCategoryHOC = ( key, value ) => this.setState({ [key]: value })

    getAllCategory = ( query, searchParams ) => Get(
      `/api/v1/content_categories?query=${ query }`,
      this.getAllCategorySuccess,
      this.getAllCategoryError,
      this.load
    )
    getAllCategorySuccess = payload => {
      let tmpPayload = []
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      else{
        this.requestError( Lang[ 'NO_RESULT_FOUND' ][ this.props.data.languageReducer.lang ] )
      }
      this.setState({ categories: payload, totalPages: tmpTotalPages })
    }
    getAllCategoryError = error => this.ajaxError( error )

    getSelectedCategory = id => Get(
      `/api/v1/content_categories/${ id }`,
      this.getSelectedCategorySuccess,
      this.getSelectedCategoryError,
      this.load
    )
    getSelectedCategorySuccess = payload => this.setState({ selectedCategory: payload })
    getSelectedCategoryError = error => this.ajaxError( error )

    postContentCategory = data => Post(
      `/api/v1/content_categories`,
      data,
      this.postContentCategorySuccess,
      this.postContentCategoryError,
      this.load
    )
    postContentCategorySuccess = payload => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'CATEGORY_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.handleCategoryModal( false )
      this.getAllCategory( convertObjToBase64( tmp ) )
    }
    postContentCategoryError = error => this.setState({ errorMessage: error }, () => this.ajaxError( 'Create category failed' ) )

    updateContentCategory = (id, data) => Put(
      `/api/v1/content_categories/${ id }`,
      data,
      this.updateContentCategorySuccess,
      this.updateContentCategoryError,
      this.load
    )
    updateContentCategorySuccess = payload => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'CATEGORY_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getAllCategory( convertObjToBase64( tmp ) )
      this.handleCategoryModal( false )
    }
    updateContentCategoryError = error => this.setState({ errorMessage: error }, () => this.ajaxError( 'Create category failed' ) )

    deleteCategory = id => Delete(
      `/api/v1/content_categories/${ id }`,
      this.deleteCategorySuccess,
      this.deleteCategoryError,
      this.load
    )
    deleteCategorySuccess = payload => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'CATEGORY_REMOVE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getAllCategory( convertObjToBase64( tmp ) )
    }
    deleteCategoryError = error => this.ajaxError( error )

    render = () => {
      return(
        <>
          <WrappedComponent
            { ...this.props }
            onLoadCategory={ this.state.loading }
            categories={ this.state.categories }
            handleCategoryModal={ this.handleCategoryModal }
            categoryModal={ this.state.categoryModal }
            getAllCategory={ this.getAllCategory }
            postContentCategory={ this.postContentCategory }
            onChangeCategoryData={ this.onChangeCategoryData }
            categoryData={ this.state.categoryData }
            selectedCategory={ this.state.selectedCategory }
            getSelectedCategory={ this.getSelectedCategory }
            updateContentCategory={ this.updateContentCategory }
            mode={ this.state.categoryMode }
            errorMessage={ this.state.errorMessage }
            searchName={ this.state.searchName }
            totalPages={ this.state.totalPages }
            onChangeCategoryHOC={ this.onChangeCategoryHOC }
            deleteCategory={ this.deleteCategory }
            deleteModal={ this.state.deleteModal }/>
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    getRefreshToken
  } )( CategoryWrappedComponent )
}

export default CategoryHOC
