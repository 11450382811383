import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { toast } from 'react-toastify'

import { Get, outdatedToken } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'
import { getRefreshToken } from 'actions/token'

const MailingsHOC = ( WrappedComponent ) => {
  class MailingsWrappedComponent extends Component {
    state = {
      loading: false,
      showStatusModal: false,
      statusModalType: '',
      onClickStatusModalButton: () => {},
      statusModalMessage: '',
      systemMailings: [],
      mailings: [
        {
          "mail_id": 1,
          "send_email_days": 1,
          "time_to_send": "2021-02-02T00:00:00Z"
        }
      ]
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    ajaxError = ( error ) => {
      outdatedToken( error, this.props.history )
      this.requestError( error )
    }

    getSystemMailing = () => Get(
      `/api/v1/system_mailings?query=${ convertObjToBase64({ is_paginated: false, filter: { status: 'ACTIVE' } }) }`,
      this.getSystemMailingSuccess,
      this.getSystemMailingError,
      this.load
    )
    getSystemMailingSuccess = payload => {
      let tmpTotalPages = []
      for( let i = 1; i <= payload.meta.last_page; i++ ) {
        tmpTotalPages.push( i )
      }
      this.setState({ systemMailings: payload, totalPagesSystemMailing: tmpTotalPages })
    }
    getSystemMailingError = error => this.requestError(error)

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            { ...this.props }
            onLoadMailings={ this.state.loading }
            mailings={ this.state.mailings }
            systemMailings={ this.state.systemMailings }
            getSystemMailing={ this.getSystemMailing } />
        </Fragment>
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    getRefreshToken
  } )( MailingsWrappedComponent )
}

export default MailingsHOC
