import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Get, Put } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      archivedContents: {
        data: [],
        meta: {
          total_pages: []
        }
      },
      loading: false,
      showArchivedModal: false,
      searchParamsforArchived: [
        {
          name: 'title',
          value: ''
        }
      ]
    }

    onChangeArchivedContentHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      zIndex: 1000
    })
    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      zIndex: 1000
    })

    getArchivedContents = ( query ) => Get(
      `/api/v1/contents?query=${ convertObjToBase64( query ) }`,
      this.getArchivedContentsSuccess,
      this.getArchivedContentsError,
      this.load
    )
    getArchivedContentsSuccess = payload => {
      let tmp = []
      if( payload && payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmp.push( i )
        }
      }
      this.setState({ archivedContents: {
        ...payload,
        meta: {
          ...payload.meta,
          total_pages: tmp
        }
      }, showArchivedModal: true })
    }
    getArchivedContentsError = error => this.requestError( error )

    handleUnarchiveContent = ( id, data ) => {
      Put(
        `/api/v1/contents/${ id }`,
        data,
        this.handleUnarchiveContentSuccess,
        this.handleUnarchiveContentError,
        this.load
      )
    }
    handleUnarchiveContentSuccess = payload => {
      this.requestSuccess( 'Content updated sucessfully' )
      this.getArchivedContents({
        page: 1,
        is_paginated: true,
        filter: {
          status: 'ARCHIVED'
        }
      })
      this.props.getAllContentService( convertObjToBase64({
         page: 1,
         is_paginated: true,
         filter: {
           $or: [
             { status: 'INACTIVE' },
             { status: 'ACTIVE' }
           ]
         }
      }))
    }
    handleUnarchiveContentError = error => this.requestError( 'Content updated failed' )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            getArchivedContents={ this.getArchivedContents }
            onChangeArchivedContentHOC={ this.onChangeArchivedContentHOC }
            archivedContents={ this.state.archivedContents }
            showArchivedModal={ this.state.showArchivedModal }
            onLoadArchivedContents={ this.state.loading }
            handleUnarchiveContent={ this.handleUnarchiveContent }
            searchParamsforArchived={ this.state.searchParamsforArchived } />
        </>
      )
    }
  }
  return WithHOC
}

export default HOC
