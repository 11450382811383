import React from 'react'
import {
  Container, Row, Col, Card, CardBody, Button
} from 'reactstrap'
import { FaFolder } from 'react-icons/fa'
import _ from 'lodash'
import { connect } from 'react-redux'

import CreateMedia from './createMedia'

import Lang from 'Lang/General'

const UploadFiles = ({
  path,
  getSelectedDriveFolder,
  mediaDrives,
  onSelectedImage,
  onChangeMediaDriveHOC,
  parentFolder,
  showCreateModal,
  newFolderFile,
  files,
  createMedia,
  createMediaFolder,
  displayFileTypeImage,
  onLoadMediaDrive,
  selectedLanguage
}) => {
  return(
    <Container>
      <Row>
        <div className="mb-2 p-1 d-flex">
          {
            path.map( ( item, index ) => {
              return(
                <>
                  <Button
                    color="link"
                    onClick={ item.nav }>
                    { item.label }
                  </Button>
                  {
                    index < path.length - 1 && (
                      <p
                        style={{
                          margin: '0px',
                          padding: '5px 0',
                          color: '#545CD8' }} >{' >> '}</p>
                    )
                  }
                </>
              )
            })
          }
        </div>
        <Col md={ 12 }>
          <Card>
            <CardBody>
              <Row>
                <Col md={ 4 } style={{ border: '1px solid #eee', padding: '15px' }}>
                  <h4 style={{ fontWeight: '300' }}>{ Lang[ 'CURRENT_FOLDER' ][ selectedLanguage ] }</h4>
                  <Button color="light" className="d-flex w-100 align-items-center mb-2">
                    <FaFolder size={ '24px' } className="mr-2" />
                    { parentFolder.name }
                  </Button>
                </Col>
                <Col md={ 8 } style={{ border: '1px solid #eee', padding: '15px' }}>
                  <h4 style={{ fontWeight: '300' }}>{ Lang[ 'MEDIA_INSIDE_FOLDER' ][ selectedLanguage ] }</h4>
                  <Row>
                    <Col md={ 12 }>
                      <Button
                        color='primary'
                        className="d-flex w-100 align-items-center justify-content-center mb-2"
                        onClick={ () => onChangeMediaDriveHOC( 'showCreateModal', true) }>
                        <div className="ml-3">
                          <p style={{ marginBottom: '1px', textAlign: 'left' }}>{ Lang[ 'ADD_FILE_OR_FOLDER' ][ selectedLanguage ] }</p>
                        </div>
                      </Button>
                    </Col>
                    {
                      mediaDrives.items && mediaDrives.items.length > 0 && mediaDrives.items.map( item => {
                        if( item.type !== 'Folder' ) {
                          if( item.thumb_url ) {
                            return(
                              <Col md={ 12 } className="mb-1">
                                <Button color="light" className="d-flex w-100 align-items-center">
                                  <img src={ item.thumb_url } style={{ width: '50px' }} />
                                  <div className="ml-2">
                                    <p style={{ marginBottom: '1px', textAlign: 'left' }}>{ item.name }</p>
                                  </div>
                                  <div className="ml-auto pl-2 d-flex">
                                    <Button
                                      color="primary"
                                      className="btn-icon mr-1"
                                      onClick={ () => onSelectedImage( item.original_url ) }>{ Lang[ 'SELECT' ][ selectedLanguage ] }
                                    </Button>
                                  </div>
                                </Button>
                              </Col>
                            )
                          } else {
                            return(
                              <Col md={ 12 } className="mb-1">
                                <Button color="light" className="d-flex w-100 align-items-center">
                                { displayFileTypeImage( item.original_url ) }
                                <div className="ml-2">
                                  <p style={{ marginBottom: '1px', textAlign: 'left' }}>{ item.name }</p>
                                </div>
                                <div className="ml-auto pl-2 d-flex">
                                  <Button
                                    color="primary"
                                    className="btn-icon mr-1"
                                    onClick={ () => onSelectedImage( item.original_url ) }>{ Lang[ 'SELECT_FILE' ][ selectedLanguage ] }
                                  </Button>
                                </div>
                                </Button>
                              </Col>
                            )
                          }
                        }
                        if( item.type === 'Folder' ){
                          return(
                            <Col md={ 12 } className="mb-1">
                              <Button
                                color="light"
                                className="d-flex w-100 align-items-center"
                                onClick={ () => {
                                  let tmp = _.cloneDeep( path )
                                  getSelectedDriveFolder( item.id )
                                  tmp.push({
                                    label: item.name,
                                    nav: () => {
                                      getSelectedDriveFolder( item.id )
                                      let foundIndex = _.findIndex( path, { label: item.name } )
                                      let tmp = _.slice( path, 0, foundIndex + 1 )
                                      onChangeMediaDriveHOC( 'path', tmp )
                                      onChangeMediaDriveHOC( 'parentFolder', { id: item.id, name: item.name } )
                                    }
                                  })
                                  onChangeMediaDriveHOC( 'path', tmp )
                                  onChangeMediaDriveHOC( 'parentFolder', { id: item.id, name: item.name } )
                                }}>
                                <FaFolder size={ '35px' } />
                                <div className="ml-3">
                                  <p style={{ marginBottom: '1px', textAlign: 'left' }}>{ item.name }</p>
                                </div>
                              </Button>
                            </Col>
                          )
                        }
                      })
                    }
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <CreateMedia
        showCreateModal={ showCreateModal }
        newFolderFile={ newFolderFile }
        onChangeMediaDriveHOC={ onChangeMediaDriveHOC }
        files={ files }
        parentFolder={ parentFolder }
        createMedia={ createMedia }
        createMediaFolder={ createMediaFolder }
        onLoadMediaDrive={ onLoadMediaDrive }
        selectedLanguage={ selectedLanguage } />
    </Container>
  )
}
const mapStateToProps = state => ({
  languageReducer: state.languageReducer
})

export default connect( mapStateToProps )( UploadFiles )
