import React from 'react'
import {
  FormGroup, Label, Input, CustomInput,  Button, UncontrolledTooltip,
  Row, Col, 
  Card, CardBody, CardHeader
} from 'reactstrap'
import uniqid from 'uniqid'
import _ from 'lodash'

import MultiLangInput from 'components/Input/MultiLang'

import Lang from 'Lang/General'

const FieldValue = ( props ) => {
  const renderAddDependentFieldSection = ({ selection, selectionIndex }) => {
    return (
      <>
        {
          props.selectedComponentToEdit.children[ 0 ].type.indexOf( 'Checkbox' ) !== -1 && (
            <FormGroup>
              <CustomInput
                id={ `isRequiredField-${ selectionIndex }` }
                type='checkbox'
                label={ Lang[ 'IS_A_REQUIRED_FIELD' ][ props.lang ] }
                checked={ props.formValuesCache[ props.selectedComponentToEdit.children[ 0 ].dataMapIndex ][ 'requiredSelections' ]?.indexOf( selection.value ) > -1 }
                onChange={ e => setIsRequiredSelection(
                  props,
                  e.target.checked ? 'add' : 'remove',
                  selection.value
                )} />
            </FormGroup>
          )
        }
        <FormGroup>
          <CustomInput
            id={ `add-dependent-field-selection-${ selectionIndex }` }
            type="checkbox"
            label={ Lang[ 'HAS_DEPENDENT_FIELD' ][ props.lang ] }
            checked={ ( selection.dependentField && selection.dependentField.id ) || false }
            onChange={ async(e) => {
              let tmpDom = _.cloneDeep( props.selectedComponentToEdit )
              let tmpFormVals = _.cloneDeep( props.formValuesCache )

              if ( e.target.checked ) {
                const id = uniqid()
                tmpFormVals.push({
                  id: id,
                  dataLabel: id,
                  value: '',
                  type: 'textField',
                  page: props.currentPage
                })
                tmpDom.children[0].selections[selectionIndex].dependentField = { 
                  id: id,
                  type: 'TextField',
                  label: Lang[ 'TEXT_FIELD_LABEL' ],
                  dataMapIndex: _.findIndex( tmpFormVals, { id: id }),
                  fontSize: '12px',
                  padding: '8px'
                }
              } else {
                if (
                  tmpDom.children[ 0 ].selections[ selectionIndex ].dependentField && 
                  tmpDom.children[ 0 ].selections[ selectionIndex ].dependentField.id
                ) {
                  let dependentFieldId = tmpDom.children[ 0 ].selections[ selectionIndex ].dependentField.id
                  let dependentFieldIndex =  _.findIndex( tmpFormVals, { id: dependentFieldId } )
                  tmpFormVals.splice( dependentFieldIndex, 1 )
                  tmpDom.children[ 0 ].selections[ selectionIndex ].dependentField = {}
                }
              }
              props.updateFormValuesCache( tmpFormVals )
              return props.updateSelectedComponentToEdit( 'children', tmpDom.children )
            }} 
          />
        </FormGroup>
        { 
          selection.dependentField && selection.dependentField.id && (
            <Card className='mb-2'>
              <CardHeader>{ Lang[ 'DEPENDENT_FIELD_SETTINGS' ][props.lang ] }</CardHeader>
              <CardBody>
                <FormGroup>
                  <Label>{ Lang[ 'SELECTED_COMPONENT' ][props.lang ] }</Label>
                  <Input
                    type="select"
                    value={ selection.dependentField.type }
                    onChange={ async e => {
                      let tmpDom = _.cloneDeep( props.selectedComponentToEdit )
                      let tmpFormVals = _.cloneDeep( props.formValuesCache )

                      let dependentFieldId = selection.dependentField.id
                      let dependentFieldIndex =  _.findIndex( tmpFormVals, { id: dependentFieldId})
                      
                      if ( e.target.value === 'TextField' ) {
                        tmpDom.children[0].selections[selectionIndex].dependentField.type = 'TextField'
                        tmpFormVals[dependentFieldIndex].type = 'textField'
                      } else if ( e.target.value === 'TextArea' ) {
                        tmpDom.children[0].selections[selectionIndex].dependentField.type = 'TextArea'
                        tmpFormVals[dependentFieldIndex].type = 'textarea'
                        tmpDom.children[0].selections[selectionIndex].dependentField.rows = 8
                        tmpDom.children[0].selections[selectionIndex].dependentField.resizable = 'None'
                      } else if ( e.target.value === 'InputFile' ) {
                        tmpDom.children[0].selections[selectionIndex].dependentField.type = 'InputFile'
                        tmpFormVals[dependentFieldIndex].type = 'inputFile'
                      }
                      props.updateFormValuesCache( tmpFormVals )
                      return props.updateSelectedComponentToEdit( 'children', tmpDom.children )
                    }}>
                    <option value={ 'TextField' }>{ Lang[ 'TEXT_FIELD' ][props.lang ] }</option>
                    <option value={ 'TextArea' }>{ Lang[ 'TEXT_AREA' ][props.lang ] }</option>
                    <option value={ 'InputFile' }>{ Lang[ 'INPUT_FILE' ][props.lang ] }</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                <MultiLangInput
                  label={ Lang['LABEL'][props.lang] }
                  value={ selection.dependentField.label }
                  onChange={ val => { 
                    let tmp = _.cloneDeep(props.selectedComponentToEdit )
                    tmp.children[0].selections[selectionIndex].dependentField.label = val
                    return props.updateSelectedComponentToEdit( 'children', tmp.children )
                  }}
                  selectedLanguage={props.lang } />
                </FormGroup>
                <FormGroup>
                  <Label>{ Lang[ 'FIELD_NAME' ][props.lang ] }</Label>
                  <Input
                    type="text"
                    value={ props.formValuesCache[ selection.dependentField.dataMapIndex ] ? props.formValuesCache[ selection.dependentField.dataMapIndex ][ 'dataLabel' ]: '' } 
                    onChange={ e => {
                      let tmpFormValues = _.cloneDeep( props.formValuesCache )
                      tmpFormValues[ selection.dependentField.dataMapIndex ][ 'dataLabel' ] = e.target.value.replace(/\s/g, '') 
                      props.updateFormValuesCache( tmpFormValues )
                    }}
                  />
                  <span>{ Lang[ 'FIELD_DESCRIPTION' ][props.lang ] }</span>
                </FormGroup>
                <FormGroup>
                  <CustomInput
                    id={ `isRequiredField-${ selection.dependentField.id }` }
                    type='checkbox'
                    label={ Lang[ 'IS_A_REQUIRED_FIELD' ][ props.lang ] }
                    checked={ props.formValuesCache[ selection.dependentField.dataMapIndex ]?.[ 'isRequired' ] || false }
                    onChange={ e => {
                      let tmpFormValues = _.cloneDeep( props.formValuesCache )
                      tmpFormValues[ selection.dependentField.dataMapIndex ][ 'isRequired' ] = e.target.checked
                      if ( !e.target.checked ) {
                        tmpFormValues[ selection.dependentField.dataMapIndex ][ 'validation' ] = Lang[ 'BLANK' ]
                      } else {
                        tmpFormValues[ selection.dependentField.dataMapIndex ][ 'validation' ] = Lang[ 'FIELD_IS_REQUIRED' ]
                      }
                      tmpFormValues[ selection.dependentField.dataMapIndex ][ 'isDependentFieldRequired' ] = false
                      props.updateFormValuesCache( tmpFormValues )
                    }} />
                </FormGroup>
                {
                  props.formValuesCache[ selection.dependentField.dataMapIndex ]?.[ 'isRequired' ] && (
                    <MultiLangInput
                      label={ Lang[ 'ERROR_MESSAGE_LABEL' ][ props.lang ] }
                      value={ props.formValuesCache[ selection.dependentField.dataMapIndex ][ 'validation' ] }
                      onChange={ val => { 
                        let tmpFormValues = _.cloneDeep( props.formValuesCache )
                        tmpFormValues[ selection.dependentField.dataMapIndex ][ 'validation' ] = val
                        props.updateFormValuesCache( tmpFormValues )
                      }}
                      selectedLanguage={ props.lang } />
                  )
                }
              </CardBody>
            </Card>
          )  
        }
      </>
    )
  }

  return (
    <Row>
      {
        props.selectedComponentToEdit.children[ 0 ].type !== 'Button' && 
        props.selectedComponentToEdit.children[ 0 ].type !== 'Text' &&
        props.selectedComponentToEdit.children[ 0 ].type !== 'Image' && (
          <Col md={ 6 }>
            <FormGroup>
              <Label>{ Lang[ 'FIELD_NAME' ][ props.lang ] }</Label>
              <Input
                type="text"
                value={ props.formValuesCache[ props.selectedComponentToEdit.children[0].dataMapIndex ][ 'dataLabel' ] }
                onChange={ e => {
                  let tmpFormValues = _.cloneDeep( props.formValuesCache )
                  tmpFormValues[ props.selectedComponentToEdit.children[0].dataMapIndex ][ 'dataLabel' ] = e.target.value.replace(/\s/g, '')
                  props.updateFormValuesCache( tmpFormValues )
                }} />
              <span>{ Lang[ 'FIELD_DESCRIPTION' ][ props.lang ] }</span>
            </FormGroup>
          </Col>
        )
      }
      {
        props.selectedComponentToEdit.children[ 0 ].type.indexOf( 'Radio' ) !== -1 && (
          <Col md={ 12 }>
            <Row>
              {
                props.selectedComponentToEdit.children[0].selections.map(( item, index ) => {
                  return (
                    <Col md={ 6 }>
                      <Card key={ item.id } className="mt-3">
                        <CardHeader className='d-flex justify-content-between' >
                          <span>{ Lang[ 'SELECTION' ][ props.lang ] } { index + 1 }</span>
                          {
                            props.selectedComponentToEdit.children[ 0 ].selections.length > 1 && <>
                              <i 
                                id={ `nested-delete-btn-${ item.id }` }
                                className="pe-7s-trash" 
                                style={{ fontSize: '23px', color: '#dc3545' }} 
                                onClick={ () => {
                                  let tmp = _.cloneDeep( props.selectedComponentToEdit )
                                  let tmpFormVals = _.cloneDeep( props.formValuesCache )

                                  if ( 
                                    tmpFormVals[ props.selectedComponentToEdit.children[0].dataMapIndex ].value === 
                                    item.value 
                                  ) {
                                    tmpFormVals[ props.selectedComponentToEdit.children[0].dataMapIndex ].value = ''
                                  }

                                  if (
                                    item.dependentField && 
                                    item.dependentField.id
                                  ) {
                                    let dependentFieldId = item.dependentField.id
                                    let dependentFieldIndex =  _.findIndex( tmpFormVals, { id: dependentFieldId } )
                                    tmpFormVals.splice( dependentFieldIndex, 1 )
                                  }

                                  tmp.children[ 0 ].selections.splice( index, 1 )

                                  props.updateFormValuesCache( tmpFormVals )
                                  return props.updateSelectedComponentToEdit( 'children', tmp.children ) 
                                }}
                              />
                              <UncontrolledTooltip
                                target={ `nested-delete-btn-${ item.id }` }
                                placement="top" >
                                { Lang[ 'DELETE' ][ props.lang ] }
                              </UncontrolledTooltip>
                            </>
                          }
                        </CardHeader>
                        <CardBody>
                          <FormGroup>
                            <MultiLangInput
                              label={ Lang[ 'LABEL' ][ props.lang ] }
                              value={ item.label }
                              onChange={ val => { 
                                let tmp = _.cloneDeep( props.selectedComponentToEdit )
                                tmp.children[0].selections[ index ][ 'label' ] = val
                                return props.updateSelectedComponentToEdit( 'children', tmp.children )
                              }}
                              selectedLanguage={ props.lang } />
                          </FormGroup>
                          <FormGroup>
                            <Label>{ Lang[ 'FIELD_NAME' ][ props.lang ] }</Label>
                            <Input
                              type="text"
                              value={ item.value }
                              onChange={ e => {
                                let tmp = _.cloneDeep( props.selectedComponentToEdit )
                                
                                tmp.children[0].selections[ index ][ 'value' ] = e.target.value.replace( /\s/g, '' )
                                if ( 
                                  props.formValuesCache[ props.selectedComponentToEdit.children[0].dataMapIndex ].value ===
                                  item.value
                                ) {
                                  let tmpFormVals = _.cloneDeep( props.formValuesCache )
                                  tmpFormVals[ props.selectedComponentToEdit.children[0].dataMapIndex ].value = ''
                                  props.updateFormValuesCache( tmpFormVals )
                                }

                                return props.updateSelectedComponentToEdit( 'children', tmp.children )
                              }}
                            />
                            <span>{ Lang[ 'FIELD_DESCRIPTION' ][ props.lang ] }</span>
                          </FormGroup>
                          {
                            renderAddDependentFieldSection({
                              selection: item,
                              selectionIndex: index
                            })
                          }
                        </CardBody>
                      </Card>
                    </Col>
                  )
                })
              }
              </Row>
              <Button
                color="primary"
                className="mt-3"
                onClick={() => {
                  let tmp = _.cloneDeep( props.selectedComponentToEdit )
                  tmp.children[0].selections.push({
                    label: Lang[ 'SELECTION_LABEL' ],
                    value: uniqid()
                  })
                  return props.updateSelectedComponentToEdit( 'children', tmp.children )
                }}>{ Lang[ 'ADD_SELECTION' ][ props.lang ] }</Button>
              <Button
                color={ props.selectedComponentToEdit.children[ 0 ].type.indexOf('Flexible') === -1 ? "success" : "danger" }
                className="mt-3 ml-2"
                onClick={ () => { 
                  let tmp = _.cloneDeep( props.selectedComponentToEdit )
                  if( props.selectedComponentToEdit.children[ 0 ].type.indexOf('Flexible') === -1 ){
                    tmp.children[0].type = tmp.children[0].type + 'Flexible'
                  } else {
                    tmp.children[0].type = tmp.children[0].type.replace( 'Flexible', '' )
                  }
                  return props.updateSelectedComponentToEdit( 'children', tmp.children ) }}>
                { props.selectedComponentToEdit.children[ 0 ].type.indexOf('Flexible') === -1 
                  ? Lang[ 'ENABLE_FLEXIBLE_OPTION' ][ props.lang ]
                  : Lang[ 'DISABLE_FLEXIBLE_OPTION' ][ props.lang ] }</Button>
            </Col>
        )
      }
      {
        props.selectedComponentToEdit.children[ 0 ].type.indexOf( 'Checkbox' ) !== -1 && (
          <Col md={ 12 }>
            <Row>
              {
                props.selectedComponentToEdit.children[ 0 ].selections.map(( item, index ) => {
                  return (
                    <Col md={ 6 }>
                      <Card key={ item.id } className="mt-3">
                        <CardHeader className='d-flex justify-content-between' >
                          <span>{ Lang[ 'SELECTION' ][ props.lang ] } { index + 1 }</span>
                          {
                            props.selectedComponentToEdit.children[ 0 ].selections.length > 1 && <>
                              <i 
                                id={ `nested-delete-btn-${ item.id }` }
                                className="pe-7s-trash" 
                                style={{ fontSize: '23px', color: '#dc3545' }} 
                                onClick={ () => {
                                  let tmp = _.cloneDeep( props.selectedComponentToEdit )
                                  tmp.children[ 0 ].selections.splice( index, 1 )
                                  setIsRequiredSelection(
                                    props,
                                    'delete',
                                    item
                                  )
                                  if ( 
                                    props.formValuesCache[ props.selectedComponentToEdit.children[0].dataMapIndex ].value.indexOf( item.value ) > -1
                                  ) {
                                    let tmpFormVals = _.cloneDeep( props.formValuesCache )
                                    tmpFormVals[ props.selectedComponentToEdit.children[0].dataMapIndex ].value = []
                                    props.updateFormValuesCache( tmpFormVals )
                                  }

                                  return props.updateSelectedComponentToEdit( 'children', tmp.children ) 
                                }}
                              />
                              <UncontrolledTooltip
                                target={ `nested-delete-btn-${ item.id }` }
                                placement="top" >
                                { Lang[ 'DELETE' ][ props.lang ] }
                              </UncontrolledTooltip>
                            </>
                          }
                        </CardHeader>
                        <CardBody>
                          <FormGroup>
                            <MultiLangInput
                              label={ Lang['LABEL'][props.lang] }
                              value={ item.label }
                              onChange={ val => {
                                let tmp = _.cloneDeep( props.selectedComponentToEdit )
                                tmp.children[0].selections[ index ][ 'label' ] = val
                                return props.updateSelectedComponentToEdit( 'children', tmp.children )
                              }}
                              selectedLanguage={ props.lang } />
                          </FormGroup>
                          <FormGroup>
                            <Label>{ Lang[ 'FIELD_NAME' ][ props.lang ] }</Label>
                            <Input
                              type="text"
                              value={ item.value }
                              onChange={ e => {
                                let tmp = _.cloneDeep( props.selectedComponentToEdit )
                                tmp.children[0].selections[ index ][ 'value' ] = e.target.value.replace(/\s/g, '')
                                setIsRequiredSelection(
                                  props,
                                  'replace',
                                  item.value,
                                  e.target.value.replace( /\s/g, '' )
                                )
                                if ( 
                                  props.formValuesCache[ props.selectedComponentToEdit.children[0].dataMapIndex ].value.indexOf( item.value ) > -1
                                ) {
                                  let tmpFormVals = _.cloneDeep( props.formValuesCache )
                                  tmpFormVals[ props.selectedComponentToEdit.children[0].dataMapIndex ].value = []
                                  props.updateFormValuesCache( tmpFormVals )
                                }

                                return props.updateSelectedComponentToEdit( 'children', tmp.children )
                              }}
                            />
                            <span>{ Lang[ 'FIELD_DESCRIPTION' ][ props.lang ] }</span>
                          </FormGroup>
                          {
                            props.selectedComponentToEdit.children[ 0 ].mapWith !== 'page-selector' && (
                              renderAddDependentFieldSection({
                                selection: item,
                                selectionIndex: index
                              })
                            )
                          }
                          {
                            item.nestedSelections && item.nestedSelections.length > 0 && item.nestedSelections.map(( selection, nestedIndex ) => {
                              return (
                                <Card className='mb-2' >
                                  <CardHeader className='d-flex justify-content-between' >
                                    <span>{ Lang['NESTED_SELECTION'][ props.lang ] } { nestedIndex + 1 }</span>
                                    <i 
                                      id={ `nested-delete-btn-${ nestedIndex }` }
                                      className="pe-7s-trash" 
                                      style={{ fontSize: '23px', color: '#dc3545' }} 
                                      onClick={ () => {
                                        let tmp = _.cloneDeep( props.selectedComponentToEdit )
                                        tmp.children[0].selections[ index ].nestedSelections.splice( nestedIndex, 1 )
                                        setIsRequiredSelection(
                                          props,
                                          'delete',
                                          selection
                                        )
                                        if ( 
                                          props.formValuesCache[ props.selectedComponentToEdit.children[0].dataMapIndex ].value.indexOf( selection.value ) > -1
                                        ) {
                                          let tmpFormVals = _.cloneDeep( props.formValuesCache )
                                          tmpFormVals[ props.selectedComponentToEdit.children[0].dataMapIndex ].value = []
                                          props.updateFormValuesCache( tmpFormVals )
                                        }
                                        return props.updateSelectedComponentToEdit( 'children', tmp.children ) 
                                      }}
                                    />
                                    <UncontrolledTooltip
                                      target={ `nested-delete-btn-${ nestedIndex }` }
                                      placement="top">
                                      { Lang[ 'DELETE' ][ props.lang ] }
                                    </UncontrolledTooltip>
                                  </CardHeader>
                                  <CardBody>
                                    <FormGroup>
                                      <MultiLangInput
                                        label={ Lang['NESTED_LABEL'][ props.lang ] }
                                        value={ selection.label }
                                        onChange={ val => {
                                          let tmp = _.cloneDeep( props.selectedComponentToEdit )
                                          tmp.children[0].selections[ index ][ 'nestedSelections' ][ nestedIndex ][ 'label' ] = val
                                          return props.updateSelectedComponentToEdit( 'children', tmp.children )
                                        }}
                                        selectedLanguage={ props.lang } />
                                    </FormGroup>
                                    <FormGroup>
                                      <Label>{ Lang[ 'NESTED_FIELD_NAME' ][ props.lang ] }</Label>
                                      <Input
                                        type="text"
                                        value={ selection.value }
                                        onChange={ e => {
                                          let tmp = _.cloneDeep( props.selectedComponentToEdit )
                                          tmp.children[0].selections[ index ][ 'nestedSelections' ][ nestedIndex ][ 'value' ] = e.target.value.replace(/\s/g, '')
                                          setIsRequiredSelection(
                                            props,
                                            'replace',
                                            selection.value,
                                            e.target.value.replace( /\s/g, '' )
                                          )
                                          if ( 
                                            props.formValuesCache[ props.selectedComponentToEdit.children[0].dataMapIndex ].value.indexOf( selection.value ) > -1
                                          ) {
                                            let tmpFormVals = _.cloneDeep( props.formValuesCache )
                                            tmpFormVals[ props.selectedComponentToEdit.children[0].dataMapIndex ].value = []
                                            props.updateFormValuesCache( tmpFormVals )
                                          }
                                          return props.updateSelectedComponentToEdit( 'children', tmp.children )
                                        }}
                                      />
                                      <span>{ Lang[ 'FIELD_DESCRIPTION' ][ props.lang ] }</span>
                                    </FormGroup>
                                    <FormGroup>
                                      <CustomInput
                                        id={ `isRequiredField-${ index }-${ nestedIndex }` }
                                        type='checkbox'
                                        label={ Lang[ 'IS_A_REQUIRED_FIELD' ][ props.lang ] }
                                        checked={ props.formValuesCache[ props.selectedComponentToEdit.children[ 0 ].dataMapIndex ][ 'requiredSelections' ]?.indexOf( selection.value ) > -1 }
                                        onChange={ e => setIsRequiredSelection(
                                          props,
                                          e.target.checked ? 'add' : 'remove',
                                          selection.value
                                        )} />
                                    </FormGroup>
                                    {
                                      selection.subCheckboxes && selection.subCheckboxes.length > 0 && selection.subCheckboxes.map(( subCheckbox, subCheckboxIndex ) => {
                                        return (
                                          <Card className='mb-2'>
                                            <CardHeader className='d-flex justify-content-between' >
                                              <span>{ Lang[ 'SUBCHECKBOX_FIELD' ][ props.lang ] } { subCheckboxIndex + 1 }</span>
                                              <i 
                                                id={ `nested-delete-btn-${ nestedIndex }-${ subCheckboxIndex }` }
                                                className="pe-7s-trash" 
                                                style={{ fontSize: '23px', color: '#dc3545' }} 
                                                onClick={ () => {
                                                  let tmp = _.cloneDeep( props.selectedComponentToEdit )
                                                  tmp.children[ 0 ].selections[ index ].nestedSelections[ nestedIndex ].subCheckboxes.splice( subCheckboxIndex, 1 )
                                                  setIsRequiredSelection(
                                                    props,
                                                    'delete',
                                                    subCheckbox
                                                  )
                                                  if ( 
                                                    props.formValuesCache[ props.selectedComponentToEdit.children[0].dataMapIndex ].value.indexOf( subCheckbox.value ) > -1
                                                  ) {
                                                    let tmpFormVals = _.cloneDeep( props.formValuesCache )
                                                    tmpFormVals[ props.selectedComponentToEdit.children[0].dataMapIndex ].value = []
                                                    props.updateFormValuesCache( tmpFormVals )
                                                  }
                                                  return props.updateSelectedComponentToEdit( 'children', tmp.children ) 
                                                }}
                                              />
                                              <UncontrolledTooltip
                                                target={ `nested-delete-btn-${ nestedIndex }-${ subCheckboxIndex }` }
                                                placement="top" >
                                                { Lang[ 'DELETE' ][ props.lang ] }
                                              </UncontrolledTooltip>
                                            </CardHeader>
                                            <CardBody>
                                              <FormGroup>
                                                <MultiLangInput
                                                  label={ Lang[ 'LABEL' ][ props.lang ] }
                                                  value={ subCheckbox.label }
                                                  onChange={ val => {
                                                    let tmp = _.cloneDeep( props.selectedComponentToEdit )
                                                    tmp.children[0].selections[ index ][ 'nestedSelections' ][ nestedIndex ][ 'subCheckboxes' ][ subCheckboxIndex ][ 'label' ] = val
                                                    return props.updateSelectedComponentToEdit( 'children', tmp.children )
                                                  }}
                                                  selectedLanguage={ props.lang } />
                                              </FormGroup>
                                              <FormGroup>
                                                <Label>{ Lang[ 'FIELD_NAME' ][ props.lang ] }</Label>
                                                <Input
                                                  type="text"
                                                  value={ subCheckbox.value }
                                                  onChange={ e => {
                                                    let tmp = _.cloneDeep( props.selectedComponentToEdit )
                                                    tmp.children[0].selections[ index ][ 'nestedSelections' ][ nestedIndex ][ 'subCheckboxes' ][ subCheckboxIndex ][ 'value' ] = e.target.value.replace(/\s/g, '')
                                                    setIsRequiredSelection(
                                                      props,
                                                      'replace',
                                                      subCheckbox.value,
                                                      e.target.value.replace( /\s/g, '' )
                                                    )
                                                    if ( 
                                                      props.formValuesCache[ props.selectedComponentToEdit.children[0].dataMapIndex ].value.indexOf( subCheckbox.value ) > -1
                                                    ) {
                                                      let tmpFormVals = _.cloneDeep( props.formValuesCache )
                                                      tmpFormVals[ props.selectedComponentToEdit.children[0].dataMapIndex ].value = []
                                                      props.updateFormValuesCache( tmpFormVals )
                                                    }
                                                    return props.updateSelectedComponentToEdit( 'children', tmp.children )
                                                  }}
                                                />
                                                <span>{ Lang[ 'FIELD_DESCRIPTION' ][ props.lang ] }</span>
                                              </FormGroup>
                                              <FormGroup>
                                                <CustomInput
                                                  id={ `isRequiredField-${ index }-${ nestedIndex }-${ subCheckboxIndex }` }
                                                  type='checkbox'
                                                  label={ Lang[ 'IS_A_REQUIRED_FIELD' ][ props.lang ] }
                                                  checked={ props.formValuesCache[ props.selectedComponentToEdit.children[ 0 ].dataMapIndex ][ 'requiredSelections' ]?.indexOf( subCheckbox.value ) > -1 }
                                                  onChange={ e => setIsRequiredSelection(
                                                    props,
                                                    e.target.checked ? 'add' : 'remove',
                                                    subCheckbox.value
                                                  )} />
                                              </FormGroup>
                                            </CardBody>
                                          </Card>
                                        )
                                      })
                                    }
                                    <Button
                                      color="secondary"
                                      className="my-3"
                                      onClick={() => {
                                        let tmp = _.cloneDeep( props.selectedComponentToEdit )
                                        tmp.children[0].selections[ index ].nestedSelections[ nestedIndex ].subCheckboxes.push({
                                          label: Lang[ 'SELECTION_LABEL' ],
                                          value: uniqid()
                                        })
                                        return props.updateSelectedComponentToEdit( 'children', tmp.children )
                                      }}>{ Lang[ 'ADD_SUBCHECKBOX' ][ props.lang ] }
                                    </Button>
                                  </CardBody>
                                </Card>
                              )
                            })
                          }
                          {
                            props.selectedComponentToEdit.children[ 0 ].mapWith !== 'page-selector' && (
                              <Button
                                color="primary"
                                className="mt-3"
                                onClick={() => {
                                  let tmp = _.cloneDeep( props.selectedComponentToEdit )
                                  tmp.children[0].selections[index].nestedSelections.push({
                                    label: Lang[ 'SELECTION_LABEL' ],
                                    value: uniqid(),
                                    subCheckboxes: []
                                  })
                                  return props.updateSelectedComponentToEdit( 'children', tmp.children )
                                }}>{ Lang[ 'ADD_NESTED_SELECTION' ][ props.lang ] }</Button>
                            )
                          }
                        </CardBody>
                      </Card>
                    </Col>
                  )
                })
              }
              </Row>
              {
                props.selectedComponentToEdit.children[ 0 ].mapWith !== 'page-selector' && (
                  <>
                    <Button
                      color="primary"
                      className="mt-3"
                      onClick={() => {
                        let tmp = _.cloneDeep( props.selectedComponentToEdit )
                        tmp.children[0].selections.push({
                          label: Lang[ 'SELECTION_LABEL' ],
                          value: uniqid(),
                          nestedSelections: []
                        })
                        return props.updateSelectedComponentToEdit( 'children', tmp.children )
                      }}>{ Lang[ 'ADD_SELECTION' ][ props.lang ] }</Button>
                    <Button
                      color={ props.selectedComponentToEdit.children[ 0 ].type.indexOf('Flexible') === -1 ? "success" : "danger" }
                      className="mt-3 ml-2"
                      onClick={ () => { 
                        let tmp = _.cloneDeep( props.selectedComponentToEdit )
                        if( props.selectedComponentToEdit.children[ 0 ].type.indexOf('Flexible') === -1 ){
                          tmp.children[0].type = tmp.children[0].type + 'Flexible'
                        } else {
                          tmp.children[0].type = tmp.children[0].type.replace( 'Flexible', '' )
                        }
                        return props.updateSelectedComponentToEdit( 'children', tmp.children ) }}>
                      { props.selectedComponentToEdit.children[ 0 ].type.indexOf('Flexible') === -1 
                        ? Lang[ 'ENABLE_FLEXIBLE_OPTION' ][ props.lang ]
                        : Lang[ 'DISABLE_FLEXIBLE_OPTION' ][ props.lang ] }
                    </Button>
                  </>
                )
              }
          </Col>
        )
      }
    </Row>
  )
} 

const setIsRequiredSelection = ( props, mode, targetValue, replaceValue ) => {
  let tmpFormVals = _.cloneDeep( props.formValuesCache )
  let tmpRequiredSelections = _.cloneDeep( tmpFormVals[ props.selectedComponentToEdit.children[ 0 ].dataMapIndex ][ 'requiredSelections' ] || [] )
  let dependentFieldIndex = -1

  switch( mode ) {
    case 'add':
      tmpRequiredSelections.push( targetValue )
      break
    case 'remove':
      tmpRequiredSelections = _.difference( tmpRequiredSelections, [ targetValue ] )
      break
    case 'delete':
      let tmpSelectionsToRemove = [ targetValue.value ]
      targetValue.nestedSelections && targetValue.nestedSelections.map( nestedSelection => {
        tmpSelectionsToRemove.push( nestedSelection.value )
        nestedSelection.subCheckboxes && nestedSelection.subCheckboxes.map( subCheckbox => {
          tmpSelectionsToRemove.push( subCheckbox.value )
        })
      })
      targetValue.subCheckboxes && targetValue.subCheckboxes.map( subCheckbox => {
        tmpSelectionsToRemove.push( subCheckbox.value )
      })
      if ( targetValue.dependentField?.id ) {
        dependentFieldIndex = _.findIndex( tmpFormVals, { id: targetValue.dependentField.id } )
      }
      tmpRequiredSelections = _.difference( tmpRequiredSelections, tmpSelectionsToRemove )
      break
    case 'replace':
      let tmpIndex = tmpRequiredSelections.indexOf( targetValue )
      if( tmpIndex > -1 ){
        tmpRequiredSelections[ tmpIndex ] = replaceValue
      }
      break
  }
  tmpFormVals[ props.selectedComponentToEdit.children[ 0 ].dataMapIndex ][ 'requiredSelections' ] = tmpRequiredSelections
  if ( dependentFieldIndex > -1 ) {
    tmpFormVals.splice( dependentFieldIndex, 1 )
  }
  props.updateFormValuesCache( tmpFormVals )
}

export default FieldValue