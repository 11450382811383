import React from 'react'
import Radium from 'proofpoint-radium'

import CoreRenderFunction from '../core/render'

const Footer = ({
  children,
  id,
  style,
  content,
  className,
  updateMainContainerState,
  selectedSectionID,
  languages,
  selectedLanguageShortname,
  templateFooterBlueprints
}) => {
  return (
    <div
      id={ id }
      style={{
        ...style,
        outline: selectedSectionID === id ? '2px solid #03a9f3' : 'none'
      }}
      onClick={() => updateMainContainerState( 'selectedSectionID', id )}>
      <div className={ className }>
      {
        children && children.length > 0 ? children.map( item => {
          return (
            <CoreRenderFunction
              item={ item }
              updateMainContainerState={ updateMainContainerState }
              selectedSectionID={ selectedSectionID }
              languages={ languages }
              selectedLanguageShortname={ selectedLanguageShortname }
              templateFooterBlueprints={ templateFooterBlueprints } />
          )
        }) : <div>{ content && content[ selectedLanguageShortname ] ? content[ selectedLanguageShortname ] : content[ 'en' ] }</div>
      }
      </div>
    </div>
  )
}

export default Radium( Footer )