import React, { Component } from 'react'
import {
  Form, FormGroup,
  Col, Button, Label, Input
} from 'reactstrap'
import ReactHTMLParser from 'react-html-parser'
import Moment from 'moment'
import _ from 'lodash'

import Lang from 'Lang/General'

class EditForm extends Component {
  render = () => {

    return(
      <>
        <Form onSubmit={ e => e.preventDefault() }>
          <FormGroup row>
            <Label sm={ 3 }>{ Lang[ 'REQUESTED_ON' ][ this.props.language ] }</Label>
            <Col sm={ 9 }>
              <Input
                disabled
                value={ Moment( this.props.selectedProductRequest.created_at ).format( 'DD-MM-YYYY h:mm:ss a' ) } />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={ 3 }>{ Lang[ 'CLIENT' ][ this.props.language ] }</Label>
            <Col sm={ 9 }>
              <div style={{
                background: '#e9ecef',
                padding: '15px',
                borderRadius: '5px',
                border: '1px solid #ced4da'
              }}>
              {
                !_.isEmpty( this.props.selectedProductRequest.client ) && <>
                  <p>
                    {
                      this.props.selectedProductRequest.client.company_name
                    }
                  </p>
                  <p>
                    {
                      `${this.props.selectedProductRequest.client.street} ${ this.props.selectedProductRequest.client.house_number}`
                    }
                  </p>
                  <p>
                    {
                      `${this.props.selectedProductRequest.client.postcode} ${ this.props.selectedProductRequest.client.state}`
                    }
                  </p>
                </>
              }
              </div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={ 3 }>{ Lang[ 'PRODUCT' ][ this.props.language ] }</Label>
            <Col sm={ 9 } col>
              <div style={{
                background: '#e9ecef',
                padding: '15px',
                borderRadius: '5px',
                border: '1px solid #ced4da'
              }}>
              {
                !_.isEmpty( this.props.selectedProductRequest.product ) &&
                  <>
                    <p>
                    {
                      this.props.selectedProductRequest.product.name[ this.props.language ]
                    }
                    </p>
                    {/*<p>
                    {
                      `${ Lang[ 'STATUS' ][ this.props.language ]}: ${ this.props.selectedProductRequest.status.name[ this.props.language ] }`
                    }
                    </p>*/}
                    <p>
                    {
                      ReactHTMLParser(this.props.selectedProductRequest.product.description[ this.props.language ] )
                    }
                    </p>
                  </>
              }
              </div>
              <Button
                color="primary"
                onClick={ () => {
                  this.props.history.push( {
                    pathname: '/dashboard/settings/products',
                    state: {
                      showUpdateProduct: !this.props.showUpdateProduct,
                      selectedProduct: this.props.selectedProduct
                    }
                  })
                }}>
                { Lang[ 'TO_THE_PRODUCT' ][ this.props.language ] }
              </Button>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={ 3 }>{ Lang[ 'STATUS' ][ this.props.language ] }</Label>
            <Col sm={ 9 }>
              <Input
                type="select"
                value={ this.props.selectedProductRequest.status_id }
                onChange={ e => {
                  let tmpData = _.cloneDeep( this.props.selectedProductRequest )
                  tmpData[ 'status_id' ] = parseInt( e.target.value )
                  this.props.onChangeProductRequestHOC( 'selectedProductRequest',  tmpData )
                }}>
                <option>{ Lang[ 'PLEASE_SELECT_ONE' ][ this.props.language ] }</option>
                {
                  this.props.requestStatus.length > 0 && this.props.requestStatus.map( item => {
                    if( item.id < 8 ) {
                      return(
                        <option key={ item.id } value={ item.id }>{ item.name[ this.props.language ] }</option>
                      )
                    }
                  })
                }
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={ 3 }>{ Lang[ 'BOOKED_FROM' ][ this.props.language ] }</Label>
            <Col sm={ 9 }>
              <Input
                type="date"
                min={ Moment( new Date() ).format( 'YYYY-MM-DD' ) }
                value={ Moment( this.props.selectedProductRequest.from_date ).format( 'YYYY-MM-DD' ) }
                onChange={ e => {
                  let tmpData = _.cloneDeep( this.props.selectedProductRequest )
                  tmpData[ 'from_date' ] = Moment( e.target.value ).format( 'YYYY-MM-DD' )
                  this.props.onChangeProductRequestHOC( 'selectedProductRequest',  tmpData )
                } }/>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={ 3 }>{ Lang[ 'BOOKED_UNTIL' ][ this.props.language ] }</Label>
            <Col sm={ 9 }>
              <Input
                type="date"
                min={ Moment( this.props.selectedProductRequest.from_date ).format( 'YYYY-MM-DD' ) }
                value={ Moment( this.props.selectedProductRequest.to_date ).format( 'YYYY-MM-DD' ) }
                onChange={ e => {
                  let tmpData = _.cloneDeep( this.props.selectedProductRequest )
                  tmpData[ 'to_date' ] = Moment( e.target.value ).format( 'YYYY-MM-DD' )
                  this.props.onChangeProductRequestHOC( 'selectedProductRequest',  tmpData )
                } }/>
            </Col>
          </FormGroup>
        </Form>
      </>
    )
  }
}
export default EditForm
