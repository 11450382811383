import React, { Component } from 'react'
import _ from 'lodash'

import Div from '../LibComponentBlocks/Div/index'
import Header from '../LibComponentBlocks/Header/index'
import HeaderDropdown from '../LibComponentBlocks/Header/HeaderDropdown'
import HeaderItem from '../LibComponentBlocks/Header/HeaderItem'
import HeaderMenu from '../LibComponentBlocks/HeaderMenu'
import Footer from '../LibComponentBlocks/Footer'
import Section from '../LibComponentBlocks/Section/index'
import TestSection from '../LibComponentBlocks/Section/testComponent'
import Paragraph from '../LibComponentBlocks/Text/Paragraph'
import Span from '../LibComponentBlocks/Text/Span'
import H1 from '../LibComponentBlocks/Text/H1'
import H2 from '../LibComponentBlocks/Text/H2'
import H3 from '../LibComponentBlocks/Text/H3'
import H4 from '../LibComponentBlocks/Text/H4'
import Icon from '../LibComponentBlocks/Icon'
import Image from '../LibComponentBlocks/Image'
import Video from '../LibComponentBlocks/Video'
import Vid from '../LibComponentBlocks/Vid'
import Link from '../LibComponentBlocks/Link'
import Button from '../LibComponentBlocks/Button'
import Hr from '../LibComponentBlocks/Hr'
import Carousel from '../LibComponentBlocks/Carousel/index'
import CarouselImageBlock from '../LibComponentBlocks/Carousel-Image-Block'
import UL from '../LibComponentBlocks/Lists/Ul'
import LI from '../LibComponentBlocks/Lists/Li'
import Mediathek from '../LibComponentBlocks/Mediathek'
import CustomAccordion from '../LibComponentBlocks/Accordion'
import Heading from '../LibComponentBlocks/Heading'
import FooterV2 from '../LibComponentBlocks/FooterV2'
import PrivacyPolicy from '../LibComponentBlocks/PrivacyPolicy'
import Countdown from '../LibComponentBlocks/Countdown'
import Team from '../LibComponentBlocks/Team'
import TeamSearchBar from '../LibComponentBlocks/Team/TeamSearchBar'
import TeamSearchTag from '../LibComponentBlocks/Team/TeamSearchTag'
import PdfViewer from '../LibComponentBlocks/PdfViewer'

import WebForm from '../LibComponentBlocks/Form/webForm'
import Form from '../LibComponentBlocks/Form/form'
import TemplateForm from '../LibComponentBlocks/Form/templateForm'
import Input from '../LibComponentBlocks/Form/input'
import Select from '../LibComponentBlocks/Form/select'
import TextArea from '../LibComponentBlocks/Form/textarea'
import FormFrame from '../LibComponentBlocks/Form/formFrame'
import Container from '../LibComponentBlocks/Form/container'
import CustomRow from '../LibComponentBlocks/Form/row'
import Column from '../LibComponentBlocks/Form/column'
import TextField from '../LibComponentBlocks/Form/textField'
import RadioButton from '../LibComponentBlocks/Form/radioButton'
import TextComponent from '../LibComponentBlocks/Form/text'
import CheckboxComponent from  '../LibComponentBlocks/Form/checkbox'
import InputFile from '../LibComponentBlocks/Form/inputFile'
import DateField from '../LibComponentBlocks/Form/dateField'
import ImageComponent from '../LibComponentBlocks/Form/image'
import DropdownComponent from '../LibComponentBlocks/Form/dropdown'

import HeaderMenuDesktop from '../ExternalLibPluginComponentBlocks/Header/HeaderMenuDesktop'
import HeaderMenuMobile from '../ExternalLibPluginComponentBlocks/Header/HeaderMenuMobile'
import FooterDesktop from '../ExternalLibPluginComponentBlocks/Footer/FooterDesktop'
import FooterMobile from '../ExternalLibPluginComponentBlocks/Footer/FooterMobile'
import GoogleMap from '../ExternalLibPluginComponentBlocks/GoogleMap'
import GalleryMain from '../ExternalLibPluginComponentBlocks/Gallery'
import GalleryContent from '../ExternalLibPluginComponentBlocks/Gallery/Content'
import GalleryCategory from '../ExternalLibPluginComponentBlocks/Gallery/Category'
import LanguageDropdown from "../ExternalLibPluginComponentBlocks/LanguageDropdown"
import CourseplanCalendar from '../ExternalLibPluginComponentBlocks/CourseplanCalendar'
import StyleSheet from '../ExternalLibPluginComponentBlocks/Styles'
import TennisShop from '../ExternalLibPluginComponentBlocks/TennisShop'

class CoreRenderFunction extends Component {
  render = () => {
    let { item } = this.props
    switch( item.type ) {
      case 'style':
        return (
          <style key={ item.id }>
            { item.css }
          </style>
        )
      case 'header':
        return (
          <Header
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'footer':
        return (
          <Footer
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'carousel':
        return (
          <Carousel
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'section':
        return (
          <Section
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'test-section':
        return (
          <TestSection
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'div':
        return (
          <Div
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'a':
        return (
          <Link
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'span':
        return (
          <Span
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'p':
        return (
          <Paragraph
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'h1':
        return (
          <H1
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'h2':
        return (
          <H2
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'h3':
        return (
          <H3
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'h4':
        return (
          <H4
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'ul':
        return (
          <UL
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'li':
        return (
          <LI
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'i':
        return (
          <Icon
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'img':
        return (
          <Image
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'vid':
        return (
          <Vid
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'video':
        return (
          <Video
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'button':
        return (
          <Button
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'hr':
        return (
          <Hr
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'webForm':
        return (
          <WebForm
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'form':
        return (
          <Form
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'input':
        return (
          <Input
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'select':
        return (
          <Select
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'TextArea':
        return (
          <TextArea
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'google-map':
        return (
          <GoogleMap
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'policy':
        return (
          <PrivacyPolicy
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      // external modules
      case 'header-menu-modules':
        return (
          <HeaderMenuDesktop
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'header-menu-modules-mobile':
        return (
          <HeaderMenuMobile
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'footer-desktop':
        let tmp = {}
        if( this.props.templateFooterBlueprints && this.props.templateFooterBlueprints.length > 0 ) {
          _.find( this.props.templateFooterBlueprints, obj => {
            if( obj.name.indexOf( 'Desktop' ) > -1 ) {
              tmp = obj.content
            }
          })
        }
        return (
          <FooterDesktop
            key={ item.id }
            { ...item }
            { ...this.props }
            templateFooterBlueprints={ tmp } />
        )
      case 'footer-mobile':
        let tmpMobile = {}
        if( this.props.templateFooterBlueprints && this.props.templateFooterBlueprints.length > 0 ) {
          _.find( this.props.templateFooterBlueprints, obj => {
            if( obj.name.indexOf( 'Mobile' ) > -1 ) {
              tmpMobile = obj.content
            }
          })
        }
        return (
          <FooterMobile
            key={ item.id }
            { ...item }
            { ...this.props }
            templateFooterBlueprints={ tmpMobile } />
        )
      case 'footerv2': 
        return (
          <FooterV2
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'gallery-main':
        return (
          <GalleryMain
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'gallery-content':
        return (
          <GalleryContent
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'gallery-category':
        return (
          <GalleryCategory
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'language-dropdown':
        return (
          <LanguageDropdown 
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'courseplan-calendar':
        return (
          <CourseplanCalendar
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'tennis-shop': 
        return (
          <TennisShop
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'stylesheet':
        return (
          <StyleSheet 
            key={ item.id }
            { ...item }
            { ...this.props } />
        )
      case 'header-menu':
          return(
            <HeaderMenu
              key={ item.id }
              { ...item }
              { ...this.props } />
          )
        case 'header-dropdown':
          return(
            <HeaderDropdown
              key={ item.id }
              { ...item }
              { ...this.props } />
          )
        case 'header-item':
          return(
            <HeaderItem
              key={ item.id }
              { ...item }
              { ...this.props } />
          )
        case 'mediathek':
          return(
            <Mediathek
              key={ item.id }
              { ...item }
              { ...this.props } />
          )
        case 'carousel-image-block':
          return (
            <CarouselImageBlock
              key={ item.id }
              { ...item }
              { ...this.props } />
          )
        case 'accordion':
          return (
            <CustomAccordion
              key={ item.id }
              { ...item }
              { ...this.props } />
          )
        case 'heading':
          return (
            <Heading
              key={ item.id }
              { ...item }
              { ...this.props } />
          )
        case 'templateForm': 
          return (
            <TemplateForm
              key={ item.id }
              { ...item }
              { ...this.props } /> 
          )
        case 'FormFrame': 
          return (
            <FormFrame
              key={ item.id }
              { ...this.props } /> 
          )
        case 'Container': 
          return (
            <Container
              key={ item.id }
              { ...item }
              { ...this.props } /> 
          )
        case 'Row': 
          return (
            <CustomRow
              key={ item.id }
              { ...item }
              { ...this.props } /> 
          )
        case 'Col': 
          return (
            <Column
              key={ item.id }
              { ...item }
              { ...this.props } /> 
          )
        case 'TextField':
          return (
            <TextField
              key={ item.id }
              { ...item }
              { ...this.props } /> 
          )
        case 'Radio':
          return (
            <RadioButton
              key={ item.id }
              { ...item }
              { ...this.props } /> 
          )
        case 'RadioFlexible':
          return (
            <RadioButton
              key={ item.id }
              { ...item }
              { ...this.props } /> 
          )
        case 'Text':
          return (
            <TextComponent
              key={ item.id }
              { ...item }
              { ...this.props } /> 
          )
        case 'Checkbox': 
          return (
            <CheckboxComponent
              key={ item.id }
              { ...item }
              { ...this.props } /> 
          )
        case 'CheckboxFlexible': 
          return (
            <CheckboxComponent
              key={ item.id }
              { ...item }
              { ...this.props } /> 
          )
        case 'DateField': 
          return (
            <DateField
              key={ item.id }
              { ...item }
              { ...this.props } /> 
          )
        case 'Dropdown': 
          return (
            <DropdownComponent
              key={ item.id }
              { ...item }
              { ...this.props } /> 
          )
        case 'Image':
          return (
            <ImageComponent
              key={ item.id }
              { ...item }
              { ...this.props } />
          )
        case 'InputFile': 
          return (
            <InputFile
              key={ item.id }
              { ...item }
              { ...this.props } /> 
          )
        case 'countdown':
          return (
            <Countdown
              key={ item.id }
              { ...item }
              { ...this.props } />
          )
        case 'team':
          return (
            <Team
              key={ item.id }
              { ...this.props } />
          )
        case 'team-searchbar':
          return (
            <TeamSearchBar
              key={ item.id }
              { ...this.props } />
          )
        case 'team-searchtag':
          return (
            <TeamSearchTag
              key={ item.id }
              { ...this.props } />
          )
        case 'pdf-viewer':
          return (
            <PdfViewer
              key={ item.id }
              { ...this.props } />
          )
      
      default: return <div></div>
    }
  }
}

export default CoreRenderFunction