import React, { Component } from 'react'
import moment from 'moment'
import { compose } from 'redux'
import _ from 'lodash'
import {
  Row, Col, CustomInput,
  Button, Table, UncontrolledTooltip,
  Modal, ModalBody, ModalHeader, ModalFooter
} from 'reactstrap'

import PageTitle from 'components/PageTitle'
import ARKTable from 'components/Table'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import CreateOrderLoader from './CreateOrderLoader'
import CreateOrder from './Create'
import UpdateOrder from './Update'

import ConsultantHOC from 'actions/consultant'
import CenterHOC from './actions/Centers'
import SiteSettingsHOC from './actions/SiteSettings'
import OrderHOC from './actions'
import ModuleHOC from './actions/Modules'
import WithPackages from './actions/Packages'

import Lang from 'Lang/General'

let TmpOrderNumber = window.location.host.indexOf( 'aciso' ) > -1 ? Lang[ 'ACISO_ORDER_NUMBER' ] : Lang[ 'ORDER_NUMBER' ]

class OrderConfiguration extends Component {
  state = {
    searchColumn: [
      {
        name: TmpOrderNumber[ this.props.data.languageReducer.lang ],
        val: 'aciso_order_num',
        type: 'text'
      },
      {
        name: Lang[ 'CENTER_NAME' ][ this.props.data.languageReducer.lang ],
        val: 'center_name',
        type: 'text'
      },
      {
        name: Lang[ 'CLIENT_NAME' ][ this.props.data.languageReducer.lang ],
        val: 'client_name',
        type: 'text'
      },
      {
        name: Lang[ 'CONSULTANT_NAME' ][ this.props.data.languageReducer.lang ],
        val: 'consultant_name',
        type: 'text'
      }
    ],
    selectedColumn: [],
    open: false,
    activated_modules: [],
    nonActivatedModules: [],
    modules_ids: []
  }

  componentDidMount = () => {
    let tmp = {
        page: 1,
        is_paginated: true
    }
    let tmpQ = {
        page: 1,
        is_paginated: false,
        filter: {
          $or: [
            { status: 'ACTIVE' }
          ]
        }
    }
    this.props.getConsultants( tmpQ )
    this.props.getCenters( tmpQ )
    this.props.getOrders( tmp )
    this.props.getModules( tmpQ )
    this.props.getPackages()
  }

  componentDidUpdate = pp => {
    if( pp.showNewOrderModal && !this.props.showNewOrderModal ){
      this.props.onChangeOrderHOC( 'errorMessage', {} )
      this.setState({ modules_ids: [] })
    }
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Order' })
      return tmpModules.is_create
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Order' })
      return tmpModules.is_update
    }
  }

  handleAddModule = item => {
    let tmpActivated = this.state.activated_modules
    let tmpNonActivated = this.state.nonActivatedModules
    let tmpid = this.state.modules_ids
    _.remove( tmpNonActivated, item )
    tmpActivated.push( item )
    tmpid.push( item.id )
    this.props.onChange( 'activated_modules', tmpid )
    return this.setState({ modules_ids: tmpid, activated_modules: tmpActivated, nonActivatedModules: tmpNonActivated })
  }

  handleRemoveModule = item => {
    let tmpActivated = this.state.activated_modules
    let tmpNonActivated = this.state.nonActivatedModules
    _.remove( tmpActivated, item )
    let tmpid = this.state.modules_ids
    tmpNonActivated.push( item )
    tmpNonActivated = _.sortBy( tmpNonActivated, ['moduleName'] )

    let index = tmpid.indexOf( item.id );
    if (index > -1) {
      tmpid.splice(index, 1);
    }

    this.props.onChange( 'activated_modules', tmpid )
    return this.setState({ modules_ids: tmpid, activated_modules: tmpActivated, nonActivatedModules: tmpNonActivated })
  }

  handleActivatedModules = () => {
    this.props.onChangeOrderHOC( 'showNewOrderModal', true )
  }

  renderViewDetails = () => {
    return(
      <span className="d-inline-block mb-2 mr-2">
        <Modal isOpen={ this.props.showViewOrderModal } size="lg">
          <ModalHeader>{ Lang[ 'ORDER_DETAILS' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
          <ModalBody>
            <div className="scroll-area-lg">
              <Table className="mb-0" bordered>
                <tbody>
                  <tr>
                    <th scope="row">{ TmpOrderNumber[ this.props.data.languageReducer.lang ] }</th>
                    <td>{ this.props.selectedOrder.aciso_order_num }</td>
                  </tr>
                  <tr>
                    <th scope="row">{ Lang[ 'CENTER_NAME' ][ this.props.data.languageReducer.lang ] }</th>
                    <td>{ this.props.selectedOrder.center_name }</td>
                  </tr>
                  <tr>
                    <th scope="row">{ Lang[ 'CLIENT_NAME' ][ this.props.data.languageReducer.lang ] }</th>
                    <td>{ this.props.selectedOrder.client_name }</td>
                  </tr>
                  <tr>
                    <th scope="row">{ Lang[ 'CONSULTANT_NAME' ][ this.props.data.languageReducer.lang ] }</th>
                    <td>{ this.props.selectedOrder.consultant_name }</td>
                  </tr>
                  <tr>
                    <th scope="row">{ Lang[ 'GDPR_SETTINGS' ][ this.props.data.languageReducer.lang ] }</th>
                    <td>
                      <Row>
                        <Col sm={ 3 } className="d-flex flex-column justify-content-center" >
                          <CustomInput
                            disabled
                            type="checkbox"
                            id="is_show_cookie_banner"
                            checked={ this.props.siteSettings.is_show_cookie_banner }
                            label={ Lang[ 'SHOW_COOKIE_BANNER' ][ this.props.data.languageReducer.lang ] }
                          /> 
                        </Col>
                        <Col sm={ 3 } className="d-flex flex-column justify-content-center" >
                          <CustomInput
                            disabled
                            type="checkbox"
                            id="is_show_content_overlay"
                            checked={ this.props.siteSettings.is_show_content_overlay }
                            label={ Lang[ 'SHOW_CONTENT_OVERLAY' ][ this.props.data.languageReducer.lang ] }
                          /> 
                        </Col>
                        <Col sm={ 4 } className="d-flex flex-column justify-content-center" >
                          <CustomInput
                            disabled
                            type="checkbox"
                            id="is_show_data_protection_agreement"
                            checked={ this.props.siteSettings.is_show_data_protection_agreement }
                            label={ Lang[ 'SHOW_DATA_PROTECTION_AGREEMENT' ][ this.props.data.languageReducer.lang ] }
                          /> 
                        </Col>
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">{ Lang[ 'ACTIVATED_MODULES' ][ this.props.data.languageReducer.lang ] }</th>
                    <td>
                      <ul>
                      {
                        this.props.selectedOrder.modules && this.props.selectedOrder.modules.length > 0 && this.props.selectedOrder.modules.map( item => {
                          return(
                            <li>{ item.name }</li>
                          )
                        })
                      }
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">{ Lang[ 'REMARK' ][ this.props.data.languageReducer.lang ] }</th>
                    <td>{ this.props.selectedOrder.remark }</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={ () => this.props.onChangeOrderHOC( 'showViewOrderModal', false ) }>OK</Button>
          </ModalFooter>
        </Modal>
      </span>
    )
  }

  render = () => {
    return(
      <>
        <div>
          <PageTitle
            heading={ Lang[ 'ORDERS' ][ this.props.data.languageReducer.lang ] }
            icon="pe-7s-users icon-gradient bg-happy-fisher"
            buttons={[
              {
                buttonText: Lang[ 'NEW_ORDER' ][ this.props.data.languageReducer.lang ],
                onClick: () => this.handleActivatedModules(),
                display: this.handleSecurityModulesCreate()
              }
            ]} />
          <ARKTable
            data={ this.props.orders.data }
            columns={[
              {
                Header: TmpOrderNumber[ this.props.data.languageReducer.lang ],
                accessor: "aciso_order_num",
                style: {
                  "line-break": "anywhere"
                }
              },
              {
                Header: Lang[ 'CENTER_NAME' ][ this.props.data.languageReducer.lang ],
                accessor: "center_name"
              },
              {
                Header: Lang[ 'CLIENT_NAME' ][ this.props.data.languageReducer.lang ],
                accessor: "client_name"
              },
              {
                Header: Lang[ 'CONSULTANT_NAME' ][ this.props.data.languageReducer.lang ],
                accessor: "consultant_name"
              },
              {
                Header: Lang[ 'CREATED_AT' ][ this.props.data.languageReducer.lang ],
                Cell: row => moment( row.original.created_at ).format( 'YYYY-MM-DD HH:mm' )
              },
              {
                Header: Lang[ 'START_DATE' ][ this.props.data.languageReducer.lang ],
                accessor: 'start_date',
                Cell: row => moment( row.original.start_date ).format( 'YYYY-MM-DD' )
              },
              {
                Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
                accessor: "id",
                style: {
                  "justify-content": "center"
                },
                Cell: row => (
                  <>
                    <Button 
                      id={ `Order-${ row.original.id }-view-order` }
                      className="mb-2 mr-2 btn-icon btn-icon-only" 
                      color="primary" 
                      onClick={ () => this.props.getSelectedOrder( row.original.id, 'view' ) }>
                      <i className="pe-7s-look"/>
                    </Button>
                    <UncontrolledTooltip
                      target={ `Order-${ row.original.id }-view-order` }
                      placement="top">{ Lang[ 'VIEW_ORDER' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                    {
                      this.handleSecurityModulesUpdate() && <>
                        <Button 
                          id={ `Order-${ row.original.id }-update-order` }
                          className="mb-2 mr-2 btn-icon btn-icon-only" 
                          color="primary" 
                          onClick={ () => this.props.getSelectedOrder( row.original.id, 'edit' ) }>
                          <i className="pe-7s-pen"/>
                        </Button>
                        <UncontrolledTooltip
                          target={ `Order-${ row.original.id }-update-order` }
                          placement="top">{ Lang[ 'UPDATE_ORDER' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                      </>
                    }
                  </>
                )
              }
            ]}
            totalPages={ this.props.orderTotalPages }
            page={ this.props.orders.meta ? this.props.orders.meta.current_page : 1 }
            onChangePage={ pg => {
              let tmp = {
                page: pg,
                is_paginated: true
              }
              this.props.getOrders( tmp )
            }}
            onClickReset={ () => {
              let tmp = {
                page: this.props.orders.meta ? this.props.orders.meta.current_page : 1,
                is_paginated: true
              }
              this.props.getOrders( tmp )
            }}
            initialSearchType={ 'Advanced Search' }
            initialSelectedSearchColumn={[
              {
                name: TmpOrderNumber[ this.props.data.languageReducer.lang ],
                val: 'aciso_order_num',
                type: 'text',
                searchValue: ''
              },
              {
                name: Lang[ 'CENTER_NAME' ][ this.props.data.languageReducer.lang ],
                val: 'center_name',
                type: 'text',
                searchValue: ''
              }
            ]}
            onSearch={ this.props.getOrdersSearch }
            searchLabel={ Lang[ 'SEARCH_WITH_ORDER_NUMBER' ][ this.props.data.languageReducer.lang ] }
            searchComponent={ true }
            searchColumn={ this.state.searchColumn }
            showPagination={ false } />
          <CreateOrder { ...this.props } />
          <UpdateOrder { ...this.props } />
          { this.renderViewDetails() }
          {
            ( 
              this.props.data.ajaxReducer.ajaxCallProgress > 0 || 
              this.props.onLoadOrders || 
              this.props.onLoadModules ||
              this.props.onLoadSiteSettings
            ) && <LoadingOverlay />
          }
          {
            this.props.onLoadCreateOrder && <CreateOrderLoader 
              onLoadCreateOrder={ this.props.onLoadCreateOrder }
              data={ this.props.data } />
          }
        </div>
      </>
    )
  }
}
export default compose(
  CenterHOC,
  ConsultantHOC,
  SiteSettingsHOC,
  OrderHOC,
  ModuleHOC,
  WithPackages
)( OrderConfiguration )
