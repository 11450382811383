import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get, Post } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const PlatformHOC = ( WrappedComponent ) => {
  class PlatformWrappedComponent extends Component {
    state={
      createPlatformModal: false,
      newPlatformData: {
        name: '',
        modules: []
      },
      editPlatformModal: false,
      selectedPlatformData: {
        name: '',
        modules: []
      },
      platforms: [],
      loading: false,
      platformTotalPages: [],
      page: 1,
      moduleName: ''
    }
    onChangePlatformHOC = ( key, value ) => this.setState({ [key]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    getAllPlatform = query => Get(
      `/api/v1/security_platforms?query=${ query }`,
      this.getAllPlatformSuccess,
      this.getAllPlatformError,
      this.load
    )
    getAllPlatformSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      else{
        this.requestError( Lang[ 'NO_RESULT_FOUND' ][ this.props.data.languageReducer.lang ] )
      }
      this.setState({ platforms: payload, platformTotalPages: tmpTotalPages })
    }
    getAllPlatformError = error => this.requestError( error )

    postPlatform = data => Post(
      `/api/v1/security_platforms`,
      data,
      this.postPlatformSuccess,
      this.postPlatformError,
      this.load
    )
    postPlatformSuccess = payload => {
      let tmp = {
        page: this.state.page,
        is_paginated: true
      }
      this.setState({
        createPlatformModal: false,
        newPlatformData: {
          name: '',
          modules: ''
        }
      })
      this.requestSuccess( 'Created success' )
      this.getAllPlatform( convertObjToBase64( tmp ) )
    }
    postPlatformError = error => this.requestError( error.name )

    getSelectedPlatform = id => Get(
      `/api/v1/security_platforms/${ id }`,
      this.getSelectedPlatformSuccess,
      this.getSelectedPlatformError,
      this.load
    )
    getSelectedPlatformSuccess = payload => this.setState({ selectedPlatformData: payload })
    getSelectedPlatformError = error => this.requestError( error )

    postModule = ( data, id ) => Post(
      `/api/v1/security_platforms/${ id }/security_modules`,
      data,
      this.postModuleSuccess,
      this.postModuleError,
      this.load
    )
    postModuleSuccess = payload => {
      let tmp = {
        page: this.state.page,
        is_paginated: true
      }
      this.setState({
        moduleName: ''
      })
      this.requestSuccess( 'Created success' )
      this.getSelectedPlatform( this.state.selectedPlatformData.id )
    }
    postModuleError = error => console.log( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          createPlatformModal={ this.state.createPlatformModal }
          onChangePlatformHOC={ this.onChangePlatformHOC }
          newPlatformData={ this.state.newPlatformData }
          editPlatformModal={ this.state.editPlatformModal }
          selectedPlatformData={ this.state.selectedPlatformData }
          platforms={ this.state.platforms }
          getAllPlatform={ this.getAllPlatform }
          platformTotalPages={ this.state.platformTotalPages }
          page={ this.state.page }
          postPlatform={ this.postPlatform }
          getSelectedPlatform={ this.getSelectedPlatform }
          postModule={ this.postModule }
          moduleName={ this.state.moduleName }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( PlatformWrappedComponent )
}
export default PlatformHOC
