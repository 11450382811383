import React, { Component } from 'react'
import _ from 'lodash'
import uniqid from 'uniqid'

const PatchJSON = ( WrappedComponent ) => {
  class PatchJSONWrappedComponent extends Component {

    patchJSONDOM = ( updatedJSON, currentJSON ) => {
      currentJSON && currentJSON.children && currentJSON.children.map( item => {
        if( updatedJSON.id === item.id ) {
          item = updatedJSON
          let tmpIndex = _.findIndex( currentJSON.children, { id: item.id } )
          currentJSON.children.splice( tmpIndex, 1, item )
          return currentJSON
        } else {
          this.patchJSONDOM( updatedJSON, item )
        }
      })
    }

    patchIDsDOM = ( targetDOM ) => {
      targetDOM && targetDOM.children && targetDOM.children.map( item => {
        item.id = uniqid()
        if( item.children ) {
          this.patchIDsDOM( item )
        }
      })
    }

    patchJSONOnly = ( updatedJSON, currentJSON ) => {
      currentJSON && currentJSON.children && currentJSON.children.map( item => {
        if( updatedJSON.id === item.id ) {
          item = updatedJSON
        } else {
          this.patchJSONDOM( updatedJSON, item )
        }
      })
    }

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          patchJSONDOM={ this.patchJSONDOM }
          patchIDsDOM={ this.patchIDsDOM }
          patchJSONOnly={ this.patchJSONOnly } />
      )
    }
  }
  return PatchJSONWrappedComponent
}

export default PatchJSON