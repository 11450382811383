import React from 'react'
import {
  Row, Col,
  Button, UncontrolledTooltip
} from 'reactstrap'
import { MdEdit, MdCheckBox, MdIndeterminateCheckBox, MdContentCopy } from 'react-icons/md'

import Lang from 'Lang/General'

const TableOfContents = ( props ) => {
  return (
    <Col md={ 12 }>
      {
        props.dom[0].children
        .filter( pageDom => !pageDom.mapWith || pageDom.mapWith === "form" )
        .map((pageDom) => {
          return (
            <Row className="justify-content-between align-items-center position-relative my-2">
              <Col md={ 5 }>
                <div className='form-content-item'>
                  {
                    pageDom.settings.thumbnailImg && pageDom.settings.thumbnailImg.length > 0 ? (
                      <img 
                        className='col-5 page-thumbnail-img'
                        src={ pageDom.settings.thumbnailImg }
                        alt=""
                      />
                    ) : (
                      <div className='col-5 d-flex'>
                        <i className='pe-7s-news-paper pe-5x page-thumbnail-img m-auto'></i>
                      </div>
                    )
                  }
                  <div className='col-6'>
                    <span className='page-number'>
                      { Lang[ 'PAGE' ][ props.currentLanguage ] } { pageDom.page }
                    </span>
                    <span className='page-title'>
                      { pageDom.name[ props.currentLanguage ] }
                    </span>
                  </div>
                </div>
              </Col>
              <Col md={ 5 } >
                <button 
                  className='form-page-link-btn'
                  onClick={ () => props.onChangeFormBuilderState( 'currentPage', parseInt( pageDom.page ) ) }>
                  { Lang[ 'EDIT_ORDER_DATA' ][ props.currentLanguage ] }
                </button>
              </Col>

              {/* ---- Edit buttons ---- */}
              <div style={{ position: 'absolute', top: 5, right: 5 }}>
                <Button 
                  style={{ marginRight: '3px' }}
                  onClick={ async() => {
                    await props.onChangeFormBuilderState( 'selectedComponentToEdit', {
                      ...pageDom
                    })
                    await props.onChangeFormBuilderState( 'showUpdatePageSettingsModal', true )
                  }}>
                  <MdEdit color="#fff" size={ 16 } />
                </Button>
                <Button
                  id={ `page-selector-btn-${ pageDom.page }` }
                  color="info"
                  style={{ marginRight: '3px' }}
                  onClick={ async() => {
                    props.togglePageSelectable( parseInt( pageDom.page ) )
                  }}>
                  { props.dom[ 0 ].children[ parseInt( pageDom.page ) - 1 ].selectable
                    ? <MdIndeterminateCheckBox color="#fff" size={ 16 } />
                    : <MdCheckBox color="#fff" size={ 16 } />
                  }
                  <UncontrolledTooltip
                    target={ `page-selector-btn-${ pageDom.page }` }
                    placement="top">
                    { props.dom[ 0 ].children[ parseInt( pageDom.page ) - 1 ].selectable
                      ? Lang[ 'SET_PAGE_UNSELECTABLE' ][ props.currentLanguage ]
                      : Lang[ 'SET_PAGE_SELECTABLE' ][ props.currentLanguage ] 
                    }
                  </UncontrolledTooltip>
                </Button>
                <Button
                  id={ `page-duplicate-btn-${ pageDom.page }` }
                  color="primary"
                  onClick={ async() => {
                    props.duplicatePage( parseInt( pageDom.page ) )
                  }}>
                    <MdContentCopy color="#fff" size={ 16 } />
                  <UncontrolledTooltip
                    target={ `page-duplicate-btn-${ pageDom.page }` }
                    placement="top">
                    { Lang[ 'DUPLICATE_PAGE' ][ props.currentLanguage ] }
                  </UncontrolledTooltip>
                </Button>
              </div>
            </Row>
          )
        })
      }
    </Col>

  )
}

export default TableOfContents