export let ComponentTypes = [
  {
    id: 'text',
    name: {
      "en": "Text",
      "de": "Text",
      "it": "Testo",
      "nl": "Tekst"
    },
    value: {
      id: 'k66esv5l',
      type: 'div',
      variant: 'text',
      content: {
        "en": "<p>Double click here to edit the text</p>",
        "de": "<p>Doppelclick hier zum editieren des Textes</p>",
        "it": "<p>Doppio clic qui per modificare il testo</p>",
        "nl": "<p>Dubbelklik hier om de tekst te bewerken</p>"
      }
    }
  },
  {
    id: 'img',
    name: {
      "en": "Image",
      "de": "Bild",
      "it": "Immagine",
      "nl": "Beeld"
    },
    value: {
      id: 'k6111yju',
      type: 'img',
      style: { width: '90%' },
      width: '90%',
      isBackgroundImage: false,
      source: "https://s3-eu-central-1.amazonaws.com/ark.staging.assets/shared_media_drive/961eafa0db0a644557638db1166d7f0f/original/placeholder.png"
    }
  },
  {
    id: 'mediathek',
    name: {
      "en": "Media Library",
      "de": "Mediathek",
      "it": "Mediateca",
      "nl": "Mediabibliotheek"
    },
    value:{
      id: 'ksy7dnnz',
      type: 'mediathek',
      style: { padding: "20px", position: "relative" },
      source: "",
      video: {
        id: "ksuiiwu7",
        style:{},
        source: "",
      },
      content: {
        "de": "<div></div>",
        "en": "<div></div>",
        "it": "<div></div>",
        "nl": "<div></div>"
      }
    }
  },
  {
    id: 'btn',
    name: {
      "en": "Button",
      "de": "Bild",
      "it": "Immagine",
      "nl": "Beeld"
    },
    value: {
      id: 'kqlzjceb',
      type: 'button',
      className: 'btn btn-primary',
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: `0 0 0 0`
      },
      children: [
        {
          id: 'kqlzk8xp',
          type: 'div',
          variant: 'text',
          content: {
            "en": "<p style=\"margin-bottom: 0;\">Button text</p>",
            "de": "<p style=\"margin-bottom: 0;\">Button text</p>",
            "it": "<p style=\"margin-bottom: 0;\">Button text</p>",
            "nl": "<p style=\"margin-bottom: 0;\">Button text</p>"
          }
        }
      ],
      navTo: '',
      variant: 'redirect',
      isContentCenter: false,
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0
    }
  },
  {
    id: 'form',
    name: {
      "en": "Site Forms",
      "de": "Formulare",
      "it": "Moduli del sito",
      "nl": "Site formulieren"
    },
    value: JSON.parse("[{\"id\":\"kdmm1yn6\",\"type\":\"FormFrame\",\"content\":{\"en\":\"Test title form frame\",\"de\":\"Test Titelformular-Rahmen\",\"nl\":\"Testtitel kader\",\"it\":\"Telaio del titolo del test\"},\"title\":{\"fontSize\":20,\"color\":\"#eee\",\"backgroundColor\":\"#515151\",\"fontWeight\":\"300\",\"textAlign\":\"center\",\"padding\":\"15px\"},\"container\":{\"borderWidth\":1,\"borderColor\":\"#515151\"},\"totalPages\":1,\"children\":[{\"id\":\"kdplhieb\",\"page\":1,\"type\":\"Container\",\"children\":[{\"id\":\"kdn9iwjz\",\"type\":\"Row\",\"mapWith\":\"form-content\",\"children\":[{\"id\":\"kdq9jkzq\",\"type\":\"Col\",\"grid\":12,\"children\":[{\"id\":\"kmlz4iww\",\"type\":\"Checkbox\",\"label\":{\"en\":\"Your new checkbox here\",\"de\":\"Ihre neue Checkbox hier\",\"nl\":\"Uw nieuwe selectievakje hier\",\"it\":\"La tua nuova casella di controllo qui\"},\"dataMapIndex\":0,\"selections\":[{\"label\":{\"en\":\"<p>Selection 1</p>\",\"de\":\"<p>Selection 1</p>\",\"nl\":\"<p>Selection 1</p>\",\"it\":\"<p>Selection 1</p>\"},\"value\":\"selection-1\"},{\"label\":{\"en\":\"<p>Selection 2</p>\",\"de\":\"<p>Selection 2</p>\",\"nl\":\"<p>Selection 2</p>\",\"it\":\"<p>Selection 2</p>\"},\"value\":\"selection-2\"}],\"padding\":\"15px\",\"children\":[]}]},{\"id\":\"kmlz4ke4\",\"type\":\"Col\",\"grid\":12,\"children\":[{\"id\":\"kmlz4n0r\",\"type\":\"Text\",\"style\":{\"fontSize\":\"16px\",\"fontWeight\":\"600\",\"marginBottom\":0},\"label\":{\"en\":\"<p>Your text here</p>\",\"de\":\"<p>Ihr Text hier</p>\",\"nl\":\"<p>Uw tekst hier</p>\",\"it\":\"<p>Il tuo testo qui</p>\"},\"dataMapIndex\":1,\"children\":[]}]}]}]}]}]")
  }
]  