import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state={
      loading: false,
      requestStatus: [],
      selectedRequestStatus: {},
      showCreateStatus: false,
      showEditStatus: false
    }
    onChangeRequestStatusHOC = ( key, value ) => this.setState({ [key]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    getAllRequestStatus = () => Get(
      `/api/v1/admin_panel_product_request_status`,
      this.getAllRequestStatusStatus,
      this.getAllRequestStatusError,
      this.load
    )
    getAllRequestStatusStatus = payload => {
      this.setState({ requestStatus: payload })
    }
    getAllRequestStatusError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          getAllRequestStatus={ this.getAllRequestStatus }
          requestStatus={ this.state.requestStatus }
          onChangeRequestStatusHOC={ this.onChangeRequestStatusHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}
export default HOC
