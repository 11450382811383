import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { toast } from 'react-toastify'

import { convertObjToBase64 } from "utils/objToBase64"
import { Get, Put } from "utils/axios"

import Lang from 'Lang/General'

const PagesHOC = ( WrappedComponent ) => {
  class PagesWrappedComponent extends Component {
    state = {
      loading: false,
      updateLoading: false,
      showStatusModal: false,
      statusModalType: '',
      onClickStatusModalButton: () => {},
      statusModalMessage: '',
      pages: [],
      selectedPage: {},
      showPageCreator: false,
      showPageEdit: false
    }

    load = ( param ) => this.setState({ loading: param })

    requestSuccess = ( success ) => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    requestError = ( error ) => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    onChangePageHOC = ( key, value ) => this.setState({ [ key ]: value })

    getPages = ( query, searchParam ) => Get(
      `/api/v1/ols_landing_page_templates?${ searchParam }query=${ convertObjToBase64( query )}`,
      this.getPagesSuccess,
      this.getPagesError,
      this.load
    )
    getPagesSuccess = ( payload ) => this.setState({ pages: payload.data })
    getPagesError = ( error ) => this.requestError( error )

    getSelectedPage = ( page_id ) => Get(
      `/api/v1/ols_landing_page_templates/${ page_id }`,
      this.getSelectedPageSuccess,
      this.getSelectedPageError,
      this.load
    )
    getSelectedPageSuccess = ( payload ) => {
      this.setState({ selectedPage: {} }, () => this.setState({ selectedPage: { ...payload, isLandingPage: true }}))
    }
    getSelectedPageError = ( error ) => this.requestError( error )

    updateSelectedPage = ( page_id, data, successAction ) => Put(
      `/api/v1/ols_landing_page_templates/${ page_id }`,
      data,
      payload => this.updateSelectedPageSuccess( payload, successAction ),
      this.updateSelectedPageError,
      this.load
    )
    updateSelectedPageSuccess = ( payload, successAction ) => {
      this.setState({ selectedPage: { ...payload, isLandingPage: true } })
      successAction()
      this.requestSuccess( Lang[ 'PAGE_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
    }
    updateSelectedPageError = ( error ) => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            getPages={ this.getPages }
            getSelectedPage={ this.getSelectedPage }
            updateSelectedPage={ this.updateSelectedPage }
            onChangePageHOC={ this.onChangePageHOC }
            pages={ this.state.pages }
            selectedPage={ this.state.selectedPage }
            onLoadPages={ this.state.loading }
            showPageCreator={ this.state.showPageCreator }
            showPageEdit={ this.state.showPageEdit }
          />
        </>
      )
    }
  }

  const mapStateToProps = state => ({ data: state })

  return connect( mapStateToProps )( PagesWrappedComponent )
}

export default PagesHOC
