import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'

import MediaItem from './mediaItem'
import Lang from 'Lang/General'

class UploadImage extends Component{
  render = () => {
    let {
      onUploadFiles,
      postMedia,
      mediaTags,
      testItems,
      uploadItem,
      onAddItem,
      handleChangeImageName,
      handleSelectTag,
      selectedLanguage,
      updateSelectedLanguage,
      onRemoveItem,
      updateType
    } = this.props
    return(
      <Fragment>
        {
          uploadItem && uploadItem.map( (item, index) => {
            return (
              <MediaItem
                index={ index }
                item={ item }
                onUploadFiles={ onUploadFiles }
                handleChangeImageName={ handleChangeImageName }
                handleSelectTag={ handleSelectTag }
                mediaTags={ mediaTags }
                testItems={ testItems }
                selectedLanguage={ selectedLanguage }
                updateSelectedLanguage={ updateSelectedLanguage }
                onRemoveItem={ onRemoveItem }
                updateType={ updateType } />
            )
          })
        }
        <Button
          className="mb-2 mr-2 btn-icon"
          color="primary"
          style={{ float: 'right', marginTop: '20px' }}
          onClick={ onAddItem }>{ Lang[ 'ADD_MORE_PICTURES' ][ this.props.data.languageReducer.lang ] }</Button>
        <Button
          className="mb-2 mr-2 btn-icon"
          style={{ float: 'right', marginTop: '20px' }}
          color="alternate"
          onClick={ () => postMedia( uploadItem ) }>{ Lang[ 'UPLOAD_ALL' ][ this.props.data.languageReducer.lang ] }</Button>
      </Fragment>
    )

  }
}
const mapStateToProps = state => ({ data: state })
export default connect( mapStateToProps )( UploadImage )
