import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'
import{
  Modal, ModalBody, ModalFooter, ModalHeader,
  Button, UncontrolledTooltip, Table
} from 'reactstrap'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PageTitle from 'components/PageTitle'
import ARKTable from 'components/Table'
import DeleteConfirmationDialog from 'components/Modal/delete'
import ArticleForm from './components/articleForm'
import ArticleFourteenHOC from './actions'

import Lang from 'Lang/General'

class ArticleFourteen extends Component {
  
  componentDidMount = () => {
    this.props.getAllArticles({
      page: 1,
      is_paginated: true
    })
  }

  componentDidUpdate = pp => {
    if ( pp.showCreateArticle !== this.props.showCreateArticle ) {
      this.props.onChangeArticleFourteenHOC( 'newArticle', {
        topic: {
          en: '',
          de: '',
          nl: '',
          it: ''
        },
        content: {
          en: '',
          de: '',
          nl: '',
          it: ''
        },
        display_order: 1
      })
    }
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' } )
    let tmpModules = {}
    if ( tmp ) {
      tmpModules = _.find( tmp.modules, { name: 'GDPR Center' } )
      return tmpModules.is_create
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' } )
    let tmpModules = {}
    if ( tmp ) {
      tmpModules = _.find( tmp.modules, { name: 'GDPR Center' } )
      return tmpModules.is_update
    }
  }

  handleSecurityModulesDelete = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ) {
      tmpModules = _.find( tmp.modules, { name: 'GDPR Center' } )
      return tmpModules.is_delete
    }
  }

  renderViewModal = () => {
    return(
      <Modal isOpen={ this.props.showViewArticle } size="lg">
        <ModalHeader toggle={ () => this.props.onChangeArticleFourteenHOC( 'showViewArticle', false ) }>
          { Lang[ 'VIEW_ARTICLE' ][ this.props.data.languageReducer.lang ] }
        </ModalHeader>
        <ModalBody>
          <div className="scroll-area-lg">
            <Table className="mb-0" bordered>
              {
                !_.isEmpty( this.props.selectedArticle ) && (
                  <tbody>
                    <tr>
                      <th scope="row">{ Lang[ 'TOPIC' ][ this.props.data.languageReducer.lang ] }</th>
                      <td>{ this.props.selectedArticle.topic[ this.props.data.languageReducer.lang ] }</td>
                    </tr>
                    <tr>
                      <th scope="row">{ Lang[ 'CONTENT' ][ this.props.data.languageReducer.lang ] }</th>
                      <td>{ ReactHtmlParser( this.props.selectedArticle.content[ this.props.data.languageReducer.lang ] ) }</td>
                    </tr>
                  </tbody>
                )
              } 
            </Table>
          </div>
          { this.props.onLoadArticles && <LoadingOverlay /> }
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={ () => this.props.onChangeArticleFourteenHOC( 'showViewArticle', false ) }>
            { Lang[ 'CLOSE' ][ this.props.data.languageReducer.lang ] }
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderCreateModal = () => {
    return(
      <Modal isOpen={ this.props.showCreateArticle } size="lg">
        <ModalHeader toggle={ () => this.props.onChangeArticleFourteenHOC( 'showCreateArticle', false ) }>
          { Lang[ 'CREATE_ARTICLE' ][ this.props.data.languageReducer.lang ] }
        </ModalHeader>
        <ModalBody>
          <ArticleForm 
            currentArticle={ this.props.newArticle }
            articleType={ 'newArticle' }
            onChangeArticleFourteenHOC={ this.props.onChangeArticleFourteenHOC }
            lang={ this.props.data.languageReducer.lang } />
          { this.props.onLoadArticles && <LoadingOverlay /> }
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={ () => this.props.postArticle( this.props.newArticle ) }>
            { Lang[ 'SUBMIT' ][ this.props.data.languageReducer.lang ] }
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderEditModal = () => {
    return(
      <Modal isOpen={ this.props.showEditArticle } size="lg">
        <ModalHeader toggle={ () => this.props.onChangeArticleFourteenHOC( 'showEditArticle', false ) }>
          { Lang[ 'EDIT_ARTICLE' ][ this.props.data.languageReducer.lang ] }
        </ModalHeader>
        <ModalBody>
          <ArticleForm 
            currentArticle={ this.props.selectedArticle }
            articleType={ 'selectedArticle' }
            onChangeArticleFourteenHOC={ this.props.onChangeArticleFourteenHOC }
            lang={ this.props.data.languageReducer.lang } />
          { this.props.onLoadArticles && <LoadingOverlay /> }
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={ () => this.props.updateArticle( this.props.selectedArticle.id, this.props.selectedArticle ) }>
            { Lang[ 'SUBMIT' ][ this.props.data.languageReducer.lang ] }
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
  
  render = () => {
    return(
      <>
        <div>
          <PageTitle
            heading={ Lang[ 'ARTICLE_14' ][ this.props.data.languageReducer.lang ] }
            icon="pe-7s-note icon-gradient bg-happy-fisher"
            buttons={[
              {
                buttonText: Lang[ 'CREATE_ARTICLE' ][ this.props.data.languageReducer.lang ],
                onClick: () => this.props.onChangeArticleFourteenHOC( 'showCreateArticle', true ),
                display: this.handleSecurityModulesCreate()
              }
            ]}/>
          <ARKTable
            data={ this.props.articles.data }
            columns={[
              {
                Header: Lang[ 'SORT_ORDER' ][ this.props.data.languageReducer.lang ],
                accessor: 'display_order'
              },
              {
                Header: Lang[ 'TOPIC' ][ this.props.data.languageReducer.lang ],
                accessor: 'topic',
                Cell: ({ value }) => !_.isEmpty( value ) && value[ this.props.data.languageReducer.lang ] ? value[ this.props.data.languageReducer.lang ] : ''
              },
              {
                Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
                accessor: "id",
                Cell: ({ value }) => (
                  <>
                    <Button
                      id={ `Article-${ value }-view-article` }
                      className="mb-2 mr-2 btn-icon btn-icon-only"
                      color="primary"
                      onClick={ () => {
                        this.props.getSelectedArticle( value )
                        this.props.onChangeArticleFourteenHOC( 'showViewArticle', true )
                      }}>
                      <i className="pe-7s-look"> </i>
                    </Button>
                    <UncontrolledTooltip
                      target={ `Article-${ value }-view-article` }
                      placement="top">{ Lang[ 'VIEW_ARTICLE' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                    {
                      this.handleSecurityModulesUpdate() &&
                        <>
                          <Button
                            id={ `Article-${ value }-edit-article` }
                            className="mb-2 mr-2 btn-icon btn-icon-only"
                            color="primary"
                            onClick={ () => this.props.getSelectedArticle( value, 'edit' ) }>
                            <i className="pe-7s-note"> </i>
                          </Button>
                          <UncontrolledTooltip
                            target={ `Article-${ value }-edit-article` }
                            placement="top">{ Lang[ 'EDIT_ARTICLE' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                        </>
                    }
                    {
                      this.handleSecurityModulesDelete() &&
                        <>
                          <Button
                            id={ `Article-${ value }-remove-article` }
                            className="mb-2 mr-2 btn-icon btn-icon-only"
                            color="danger"
                            onClick={ () => {
                              this.props.getSelectedArticle( value )
                              this.props.onChangeArticleFourteenHOC( 'showDeleteArticle', true )
                            }}>
                            <i className="pe-7s-trash"> </i>
                          </Button>
                          <UncontrolledTooltip
                            target={ `Article-${ value }-remove-article` }
                            placement="top">{ Lang[ 'DELETE_ARTICLE' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                        </>
                    }
                  </>
                )
              }
            ]}
            totalPages={ this.props.articleTotalPages }
            page={ this.props.articles.meta ? this.props.articles.meta.current_page : 1 }
            onChangePage={ pg => {
              let tmp = {
                page: pg,
                is_paginated: true
              }
              return this.props.getAllArticles( tmp )
            }}
            showPagination={ false } />
            { this.renderViewModal() }
            { this.renderCreateModal() }
            { this.renderEditModal() }
            {
              this.props.showDeleteArticle && (
                <DeleteConfirmationDialog
                  handleModalClose={ () => this.props.onChangeArticleFourteenHOC( 'showDeleteArticle', false ) }
                  deleteOpen={ this.props.showDeleteArticle }
                  confirmAction={ () => this.props.deleteArticle( this.props.selectedArticle.id ) }
                  content={ Lang[ 'DELETE_CONFIRMATION_ARTICLE' ][ this.props.data.languageReducer.lang ] }
                  lang={ this.props.data.languageReducer.lang } />
              )
            }
        </div>
        { this.props.onLoadArticles && <LoadingOverlay /> }
      </>
    )
  }
}

export default ArticleFourteenHOC( ArticleFourteen )
