import React, { Component } from 'react'
import {
  Modal, ModalBody, ModalFooter, ModalHeader,
  Button, UncontrolledTooltip, Table
} from 'reactstrap'
import ReactHtmlParser from 'react-html-parser'
import _ from 'lodash'
import Moment from 'moment'

import ARKTable from 'components/Table'
import AVContractTemplateHOC from './actions/contractTemplate'
import TemplateForm from './components/templateForm'
import LoadingModal from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'

class ContractTemplate extends Component {
  componentDidMount = () => {
    this.props.getAllContractTemplates({
      page: 1,
      is_paginated: true
    })
  }
  
  componentDidUpdate = pp => {
    if ( pp.showCreateTemplate !== this.props.showCreateTemplate ) {
      this.props.onChangeAVContractTemplateHOC( 'newTemplate', {
        name: {
          en: '',
          de: '',
          nl: '',
          it: ''
        },
        html: {
          en: '',
          de: '',
          nl: '',
          it: ''
        },
        version: null,
        variable_fields: {
          auth_person: '',
          company_name_address: '',
          agreement_number: ''
        }
      })
    }

    if ( !_.isEmpty( this.props.selectedTemplate ) && pp.selectedTemplate.id !== this.props.selectedTemplate.id ) {
      let tmpSelectedFaq = _.cloneDeep( this.props.selectedTemplate )
      this.props.onChangeAVContractTemplateHOC( 'selectedTemplate', tmpSelectedFaq )
    }
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' } )
    let tmpModules = {}
    if ( tmp ) {
      tmpModules = _.find( tmp.modules, { name: 'GDPR Center' } )
      return tmpModules.is_create
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' } )
    let tmpModules = {}
    if ( tmp ) {
      tmpModules = _.find( tmp.modules, { name: 'GDPR Center' } )
      return tmpModules.is_update
    }
  }
  
  renderCreateModal = () => {
    return(
      <Modal isOpen={ this.props.showCreateTemplate } size="lg">
        <ModalHeader toggle={ () => this.props.onChangeAVContractTemplateHOC( 'showCreateTemplate', false ) }>
          { Lang[ 'CREATE_CONTRACT_TEMPLATE' ][ this.props.selectedLanguage ] }
        </ModalHeader>
        <ModalBody>
          <TemplateForm 
            currentTemplate={ this.props.duplicateTemplate > 0 ? this.props.selectedTemplate : this.props.newTemplate }
            templateType={ this.props.duplicateTemplate > 0 ? 'selectedTemplate' : 'newTemplate'  }
            onChangeAVContractTemplateHOC={ this.props.onChangeAVContractTemplateHOC }
            lang={ this.props.selectedLanguage } />
          { this.props.onLoadAVContractTemplates && <LoadingModal /> }
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={ () => this.props.postTemplate( this.props.duplicateTemplate > 0 ? this.props.selectedTemplate : this.props.newTemplate ) }>
            { Lang[ 'SUBMIT' ][ this.props.selectedLanguage ] }
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderEditModal = () => {
    return(
      <Modal isOpen={ this.props.showEditTemplate } size="lg">
        <ModalHeader toggle={ () => this.props.onChangeAVContractTemplateHOC( 'showEditTemplate', false ) }>
          { Lang[ 'EDIT_CONTRACT_TEMPLATE' ][ this.props.selectedLanguage ] }
        </ModalHeader>
        <ModalBody>
          {
            !_.isEmpty( this.props.selectedTemplate ) && (
              <TemplateForm 
                currentTemplate={ this.props.selectedTemplate }
                templateType={ 'selectedTemplate' }
                onChangeAVContractTemplateHOC={ this.props.onChangeAVContractTemplateHOC }
                lang={ this.props.selectedLanguage } />
            )
          }
          { this.props.onLoadAVContractTemplates && <LoadingModal /> }
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={ () => this.props.updateTemplate( this.props.selectedTemplate.id, this.props.selectedTemplate ) }>
            { Lang[ 'SUBMIT' ][ this.props.selectedLanguage ] }
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderViewModal = () => {
    return(
      <Modal isOpen={ this.props.showViewTemplate } size="lg">
        <ModalHeader toggle={ () => this.props.onChangeAVContractTemplateHOC( 'showViewTemplate', false ) }>
          { Lang[ 'VIEW_CONTRACT_TEMPLATE' ][ this.props.data.languageReducer.lang ] }
        </ModalHeader>
        <ModalBody>
          <div className="scroll-area-lg">
            <Table className="mb-0" bordered>
              {
                !_.isEmpty( this.props.selectedTemplate ) && (
                  <tbody>
                    <tr>
                      <th scope="row">{ Lang[ 'TITLE' ][ this.props.data.languageReducer.lang ] }</th>
                      <td>{ this.props.selectedTemplate.name[ this.props.data.languageReducer.lang ] }</td>
                    </tr>
                    <tr>
                      <th scope="row">{ Lang[ 'VERSION' ][ this.props.data.languageReducer.lang ] }</th>
                      <td>{ this.props.selectedTemplate.version }</td>
                    </tr>
                    <tr>
                      <th scope="row">{ Lang[ 'CONTENT' ][ this.props.data.languageReducer.lang ] }</th>
                      <td>{ ReactHtmlParser( this.props.selectedTemplate.html[ this.props.data.languageReducer.lang ] ) }</td>
                    </tr>
                  </tbody>
                )
              }
            </Table>
          </div>
          { this.props.onLoadAVContractTemplates && <LoadingModal /> }
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={ () => this.props.onChangeAVContractTemplateHOC( 'showViewTemplate', false ) }>
            { Lang[ 'CLOSE' ][ this.props.data.languageReducer.lang ] }
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
  
  render = () => {
    return (
      <>
        <div>
          {
            this.handleSecurityModulesCreate() && (
              <Button
                color="primary"
                style={{ display: 'block' }}
                className="ml-auto m-3"
                onClick={ () => this.props.onChangeAVContractTemplateHOC( 'showCreateTemplate', true ) }>
                { Lang[ 'CREATE_CONTRACT_TEMPLATE' ][ this.props.selectedLanguage ] }
              </Button>
            )
          }
        </div> 
        <ARKTable
          data={ this.props.contractTemplates.data }
          columns={[
            {
              Header: Lang[ 'TITLE' ][ this.props.selectedLanguage ],
              accessor: "name",
              Cell: ({ value }) => value[ this.props.selectedLanguage ] ? value[ this.props.selectedLanguage ] : ''
            },
            {
              Header: Lang[ 'VERSION' ][ this.props.selectedLanguage ],
              accessor: "version"
            },
            {
              Header: Lang[ 'CREATED_ON' ][ this.props.selectedLanguage ],
              accessor: "created_at",
              Cell: ({ value }) => Moment( value ).format( 'DD.MM.YYYY ' )
            },
            {
              Header: Lang[ 'UPDATED_ON' ][ this.props.selectedLanguage ],
              accessor: "updated_at",
              Cell: ({ value }) => Moment( value ).format( 'DD.MM.YYYY ' )
            },
            {
              Header: Lang[ 'ACTION' ][ this.props.selectedLanguage ],
              accessor: "id",
              Cell: ({ value }) => (
                <>
                  <Button
                    id={ `Contracts-${ value }-view-contract-template` }
                    className="mb-2 mr-2 btn-icon btn-icon-only"
                    color="primary"
                    onClick={ () => {
                      this.props.getSelectedTemplate( value )
                      this.props.onChangeAVContractTemplateHOC( 'showViewTemplate', true )
                    }}>
                    <i className="pe-7s-look"> </i>
                  </Button>
                  <UncontrolledTooltip
                    target={ `Contracts-${ value }-view-contract-template` }
                    placement="top">{ Lang[ 'VIEW_CONTRACT_TEMPLATE' ][ this.props.selectedLanguage ] }</UncontrolledTooltip>
                  {
                    this.handleSecurityModulesUpdate() &&
                      <>
                        <Button
                          id={ `Contracts-${ value }-edit-contract-template` }
                          className="mb-2 mr-2 btn-icon btn-icon-only"
                          color="primary"
                          onClick={ () => {
                            this.props.getSelectedTemplate( value )
                            this.props.onChangeAVContractTemplateHOC( 'showEditTemplate', true )
                          }}>
                          <i className="pe-7s-note"> </i>
                        </Button>
                        <UncontrolledTooltip
                          target={ `Contracts-${ value }-edit-contract-template` }
                          placement="top">{ Lang[ 'EDIT_CONTRACT_TEMPLATE' ][ this.props.selectedLanguage ] }</UncontrolledTooltip>
                      </>
                  }
                  { 
                    this.handleSecurityModulesCreate() && <>
                      <Button 
                        id={ `Contracts-${ value }-duplicate-template` }
                        className="mb-2 mr-2 btn-icon btn-icon-only"
                        color='primary' 
                        onClick={ () => {
                          this.props.getSelectedTemplate( value )
                          this.props.onChangeAVContractTemplateHOC( 'duplicateTemplate', value )
                          this.props.onChangeAVContractTemplateHOC( 'showCreateTemplate', true )
                        }} >
                        <i className="pe-7s-copy-file" />
                      </Button> 
                      <UncontrolledTooltip
                        target={ `Contracts-${ value }-duplicate-template` }
                        placement="top">
                        { Lang[ 'DUPLICATE' ][ this.props.selectedLanguage ] }
                      </UncontrolledTooltip>
                    </>
                  }
                </>
              )
            }
          ]}
          totalPages={ this.props.contractTemplateTotalPages ? this.props.contractTemplateTotalPages : [] }
          page={ this.props.contractTemplates.meta ? this.props.contractTemplates.meta.current_page : 1 }
          onChangePage={ pg => {
            let tmp = {
              page: pg,
              is_paginated: true
            }
            return this.props.getAllContractTemplates( tmp )
          }}
          showPagination={ false } />
        { this.renderCreateModal() }
        { this.renderEditModal() }
        { this.renderViewModal() }
        { this.props.onLoadAVContractTemplates && <LoadingModal /> }
      </>
    )
  }
}

export default AVContractTemplateHOC( ContractTemplate )
