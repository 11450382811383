import React, { useRef, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import _ from 'lodash'
import Tabs from 'react-responsive-tabs'

import UpdateUserForm from './UpdateUser'
import UpdateCentersForm from './UpdateCenters'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'

const TabsConfig = data => ({
  activeTab: '1',
  showMore: true,
  transform: true,
  showInkBar: true,
  items: [
    { title: Lang[ 'GENERAL' ][ data.languageReducer.lang ] },
    { title: Lang[ 'CENTER_CONFIGURATION' ][ data.languageReducer.lang ] }
  ],
  selectedTabKey: 0,
  transformWidth: 400,
})

const Update = ({
  data,
  showUpdate,
  onChangeUsersHOC,
  selectedUser,
  roles,
  errorMessage,
  updateUser,
  getSelectedUser,
  onLoadUsers
}) => {
  const [ currentTab, updateCurrentTab ] = useState( 0 )

  let fileInput = useRef( null )

  const fileInputRef = element => {
    fileInput = element
  }

  const onClickUploadImage = () => {
    focusFileInput();
  }

  const focusFileInput = () => {
    if( fileInput )
      fileInput.click();
  }

  const onChange = ( key, value ) => {
    let tmp = _.cloneDeep( selectedUser )
    tmp[key] = value
    return onChangeUsersHOC( 'selectedUser', tmp )
  }

  const onChangeConsultant = ( key, value ) => {
    let tmp = _.cloneDeep( selectedUser )
    tmp[ 'consultant' ][ key ] = value
    return onChangeUsersHOC( 'selectedUser', tmp )
  }

  const onUploadImage = ( event ) => {
    if( event.target.files[0] ) {
      let reader = new FileReader();
      let filename = event.target.files[0].name
      reader.onload = e => {
        let tmp = _.cloneDeep( selectedUser )
        tmp[ 'photo_raw' ] = e.target.result
        tmp[ 'photo_file_name' ] = filename
        return onChangeUsersHOC( 'selectedUser', tmp )
      };
      reader.readAsDataURL( event.target.files[0] );
    }
  }

  return(
    <Modal
      isOpen={ showUpdate }
      toggle={ () => {
        onChangeUsersHOC( 'showUpdate', false )
      }}
      size="lg">
      <ModalHeader
        toggle={ () => {
          onChangeUsersHOC( 'showUpdate', false )
        }}>{ Lang[ 'UPDATE_USER' ][ data.languageReducer.lang ] }</ModalHeader>
      <ModalBody>
        { <style>{ '.RRT__container { padding: 0; } .RRT__panel { opacity: 0; height: 0; padding: 0; }' }</style> }
        <Tabs
          tabsWrapperClass="body-tabs"
          { ...TabsConfig( data ) }
          selectedTabKey={ currentTab }
          onChange={ val => updateCurrentTab( val ) } />
        {
          currentTab === 0 && (
            <UpdateUserForm
              showUpdate={ showUpdate }
              dataReducer={ data }
              selectedUser={ selectedUser }
              errorMessage={ errorMessage }
              onChange={ onChange }
              onChangeConsultant={ onChangeConsultant }
              onChangeUsersHOC={ onChangeUsersHOC }
              onUploadImage={ onUploadImage }
              onClickUploadImage={ onClickUploadImage }
              fileInputRef={ fileInputRef }
              roles={ roles }
              statusValues={ data.dictionaryReducer.dictionary.status }
              currentUser={ data.profileReducer.profile } />
          )
        }
        {
          currentTab === 1 && (
            <UpdateCentersForm
              selectedUser={ selectedUser }
              getSelectedUser={ getSelectedUser }
              lang={ data.languageReducer.lang } />
          )
        }
        { onLoadUsers && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        {
          currentTab === 0 && <Button color="primary" onClick={ updateUser }>{ Lang[ 'SUBMIT' ][ data.languageReducer.lang ] }</Button>
        }
        {
          currentTab === 1 && <Button color="primary" onClick={ () => { onChangeUsersHOC( 'showUpdate', false ) } }>{ Lang[ 'SUBMIT' ][ data.languageReducer.lang ] }</Button>
        }
      </ModalFooter>
    </Modal>
  )
}

export default Update
