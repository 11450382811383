import React from 'react'
import { connect } from 'react-redux'
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup,
  Label, Input, Button
} from 'reactstrap'
import _ from 'lodash'

import MultiLangInput from 'components/Input/MultiLang'
import { toggleMediaDatabase } from 'actions/MediaDatabaseToggle'

import Lang from 'Lang/General'

const PageSettingsModal = (props) => {
  return (
    <>
      <Modal
        isOpen={ props.showUpdatePageSettingsModal }
        size={ 'xl' }
        toggle={() => {
          props.onChangeFormBuilderState( 'showUpdatePageSettingsModal', false )
        }}>
        <ModalHeader toggle={() => props.onChangeFormBuilderState( 'showUpdatePageSettingsModal', false )}>
          { Lang[ 'UPDATE_PAGE' ][ props.lang ] }</ModalHeader>
        <ModalBody>
          <Form onSubmit={ e => e.preventDefault()}>
            <FormGroup>
              <MultiLangInput
                label={ Lang[ 'PAGE_NAME' ][ props.lang ] }
                value={ props.selectedComponentToEdit.name }
                onChange={ val => { 
                  let tmpDOM = _.cloneDeep( props.dom )
                  let tmp = _.cloneDeep( props.selectedComponentToEdit )
                  tmp[ 'name' ] = val
                  props.findObjectAndPatch( tmpDOM, tmp )
                  props.onChangeFormBuilderState( 'selectedComponentToEdit', tmp )
                }}
                selectedLanguage={ props.lang } 
              />
            </FormGroup>
            <FormGroup>
              <Label>{ Lang[ 'THUMBNAIL_IMAGE' ][ props.lang ] }</Label>
              <Input
                type="text"
                value={ props.selectedComponentToEdit.settings ? props.selectedComponentToEdit.settings.thumbnailImg : "" }
                disabled={ true } 
              />
              <Button 
                color="primary"
                className="mt-2"
                onClick={ async () => {
                  await props.onChangeFormBuilderState( 'editingImageType', 'PAGE_THUMBNAIL' )
                  props.toggleMediaDatabase( true )
                }}>
                { Lang[ 'SELECT_FILE' ][ props.lang ] }
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={ async () => {
              let tmp = _.cloneDeep( props.selectedComponentToEdit )
              let tmpDOM = _.cloneDeep( props.dom )
              props.findObjectAndPatch( tmpDOM, tmp )
              await props.onChangeFormBuilderState( 'dom', tmpDOM )
              await props.onChangeFormBuilderState( 'showUpdatePageSettingsModal', false )
            }}>
              { Lang[ 'SUBMIT' ][ props.lang ] }
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({ data: state })

export default connect( mapStateToProps, {
  toggleMediaDatabase
})( PageSettingsModal )
