import React, { useState } from 'react'
import {
  Label, Button, UncontrolledTooltip,
  Row, Col,
  Form, FormGroup
} from 'reactstrap'
import ReactSelect from 'react-select'
import ReactTable from 'react-table'
import _ from 'lodash'

import Lang from 'Lang/General'

const CenterProgramTemplateForm = ({
  selectedCenter,
  programTemplates,
  createCenterProgramTemplate,
  updateCenterProgramTemplate,
  lang
}) => {
  const [ selectedProgramTemplate, updateSelectedProgramTemplate ] = useState( {} )
  return(
    <Form style={{ paddingLeft: "15px", paddingRight: "15px" }} >
      <FormGroup>
        <Label>{ Lang[ 'ASSIGN_PURCHASED_PROGRAM_TEMPLATE' ][ lang ] }</Label>
        <Row className='w-100 m-0 align-items-center' >
          <Col sm={ 10 } className='pl-0 pr-1 mb-2' style={{ zIndex: 999 }}>
            <ReactSelect
              isMulti={ false }
              options={ 
                _.filter( 
                  programTemplates, 
                  progTemplate => _.findIndex( selectedCenter.program_templates, { am_program_template_id: progTemplate.id } ) === -1 
                ) 
              }
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder={ Lang[ 'SELECT_PLACEHOLDER' ][ lang ] }
              onChange={ ( value, action ) => updateSelectedProgramTemplate( value ) }
              value={ selectedProgramTemplate } />
          </Col>
          <Col sm={ 2 } className='pl-0 pr-0 mb-2' >
            <Button
              className='w-100'
              color='primary'
              onClick={ () => {
                updateSelectedProgramTemplate( {} )
                createCenterProgramTemplate( selectedCenter.id, selectedProgramTemplate.id ) 
              }}>
              { Lang[ 'ASSIGN' ][ lang ] }</Button>
          </Col>
        </Row>
      </FormGroup>
      <ReactTable
        showPagination={ true }
        pageSize={ 10 }
        data={ selectedCenter.program_templates }
        columns={[
          {
            Header: Lang[ 'TITLE' ][ lang ],
            accessor: 'title',
            Cell: ({ value }) => value[ lang ]
          },
          {
            Header: Lang[ 'STATUS' ][ lang ],
            accessor: 'status',
            Cell: ({ value }) => Lang[ value ][ lang ],
          },
          {
            Header: Lang[ 'ACTIONS' ][ lang ],
            accessor: 'id',
            Cell: row => (
              <div className='d-flex justify-content-center' >
                <Button
                  id={ `ProgramTemplate-${ row.original.id }-update` }
                  className='mb-2 mr-2 btn-icon btn-icon-only'
                  color={ row.original.status === 'ACTIVE' ? 'warning' : 'primary' }
                  onClick={ () => updateCenterProgramTemplate(
                    selectedCenter.id,
                    row.original.am_program_template_id,
                    row.original.status === 'ACTIVE' ? 'ARCHIVED' : 'ACTIVE'
                  )}>
                  <i className={ row.original.status === 'ACTIVE' ? 'pe-7s-notebook' : 'pe-7s-back' } /> 
                </Button>
                <UncontrolledTooltip
                  target={ `ProgramTemplate-${ row.original.id }-update` }
                  placement='top'>
                  { Lang[ row.original.status === 'ACTIVE' ? 'ARCHIVE' : 'UNARCHIVE' ][ lang ] }
                </UncontrolledTooltip>
              </div>
            )
          }
        ]} />
    </Form>
  )
}

export default CenterProgramTemplateForm