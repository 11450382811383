import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { Post, Get, Put, Delete } from 'utils/axios'
import Lang from 'Lang/General'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state={ 
      loading: false,
      roles: [],
      newRole: {},
      selectedRole: {},
      showCreateRole: false,
      showUpdateRole: false,
      showDeleteRoleId: 0
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( err => toast.error( err.replaceAll( '_', ' ' ) ) )

    onChangeRolesHOC = ( key, value ) => this.setState({ [ key ] : value })

    createRole = data => Post(
      `/api/v1/ark_member_area/ark_member_roles`,
      data,
      this.createRoleSuccess,
      this.createRoleError,
      this.load
    )
    createRoleSuccess = () => {
      this.getRoles()
      this.requestSuccess( Lang[ 'ROLE_IS_SUCCESSFULLY_CREATED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showCreateRole: false })
    }
    createRoleError = error => {
      this.requestError( error )
      this.setState({ showCreateRole: false })
    }

    getRoles = () => Get(
      `/api/v1/ark_member_area/ark_member_roles`,
      this.getRolesSuccess,
      this.getRolesError,
      this.load
    )
    getRolesSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      this.setState({ roles: payload, rolesPages: tmpTotalPages })
    }
    getRolesError = error => this.requestError( error )

    getSelectedRole = role_id => Get(
      `/api/v1/ark_member_area/ark_member_roles/${ role_id }`,
      this.getSelectedRoleSuccess,
      this.getSelectedRoleError,
      this.load
    )
    getSelectedRoleSuccess = payload => this.setState({ selectedRole: payload, showUpdateRole: true })
    getSelectedRoleError = error => this.requestError( error )

    updateRole = data => Put(
      `/api/v1/ark_member_area/ark_member_roles/${ data.id }`,
      data,
      this.updateRoleSuccess,
      this.updateRoleError,
      this.load
    )
    updateRoleSuccess = () => {
      this.getRoles()
      this.requestSuccess( Lang[ 'ROLE_IS_SUCCESSFULLY_UPDATED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showUpdateRole: false })
    }
    updateRoleError = error => this.requestError( error )

    removeRole = role_id => Delete(
      `/api/v1/ark_member_area/ark_member_roles/${ role_id }`,
      this.removeRoleSuccess,
      this.removeRoleError,
      this.load
    )
    removeRoleSuccess = () => {
      this.getRoles()
      this.requestSuccess( Lang[ 'ROLE_IS_SUCCESSFULLY_REMOVED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showDeleteRoleId: 0 })
    }
    removeRoleError = error => {
      this.requestError( error )
      this.setState({ showDeleteRoleId: 0 })
    }

    render = () => {
      return(
        <WrappedComponent
          { ...this.props } 
          onLoadRolesHOC={ this.state.loading }
          roles={ this.state.roles }
          newRole={ this.state.newRole }
          selectedRole={ this.state.selectedRole }
          showCreateRole={ this.state.showCreateRole }
          showUpdateRole={ this.state.showUpdateRole }
          showDeleteRoleId={ this.state.showDeleteRoleId }
          onChangeRolesHOC={ this.onChangeRolesHOC }
          createRole={ this.createRole }
          getRoles={ this.getRoles }
          getSelectedRole={ this.getSelectedRole }
          updateRole={ this.updateRole }
          removeRole={ this.removeRole }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC