import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Get, Put } from 'utils/axios'

import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      emailTemplate: { content_jsonb: {} }
    }
    onChangeEmailTemplateHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error !== 'string' 
      ? Object.values( error ).map( val => toast.error( val ) )
      : toast.error( error )

    getEmailTemplate = () => Get(
      `/api/v1/email_templates`,
      this.getEmailTemplateSuccess,
      this.getEmailTemplateError,
      this.load
    )
    getEmailTemplateSuccess = payload => this.setState({ emailTemplate: payload })
    getEmailTemplateError = error => this.requestError( error )

    updateEmailTemplate = ( data ) => Put(
      `/api/v1/email_templates`,
      data,
      this.updateEmailTemplateSuccess,
      this.updateEmailTemplateError,
      this.load
    )
    updateEmailTemplateSuccess = () => {
      this.requestSuccess( Lang[ 'EMAIL_TEMPLATE_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getEmailTemplate()
    }
    updateEmailTemplateError = error => this.requestError( error )

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          onLoadEmailTemplate={ this.state.loading }
          emailTemplate={ this.state.emailTemplate }
          getEmailTemplate={ this.getEmailTemplate }
          updateEmailTemplate={ this.updateEmailTemplate }
          onChangeEmailTemplateHOC={ this.onChangeEmailTemplateHOC }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC