import React, { Component } from 'react'
import _ from 'lodash'
import uniqid from 'uniqid'

import CoreRenderFunction from '../../core/render'

class HeaderMenuMobile extends Component {

  patchObjectID = param => {
    param.id = uniqid()
    if( param && param.children ) {
      param.children.map( item => {
        this.patchObjectID( item )
      })
    }
  }

  render = () => {
    let {
      updateMainContainerState,
      languages,
      selectedLanguageShortname,
      templateHeaders
    } = this.props
    let mobileBlueprint = _.find( this.props.templateHeadersBlueprints, obj => {
      if( obj.name.indexOf( 'Mobile' ) > -1 ) {
        return obj
      }
    })
    if( templateHeaders ) {
      return templateHeaders.content.map( item => {
        let mobileBlueprintContent = _.cloneDeep( mobileBlueprint.content )
        let computedDOM = _.cloneDeep( mobileBlueprintContent )
        this.patchObjectID( computedDOM )
        computedDOM.children[ 0 ].children[ 0 ].content = item.content
        computedDOM.children.pop()
        computedDOM.children.pop()
        if( item.children ) {
          item.children.map( child => {
            // level 2
            let tmp = _.cloneDeep( mobileBlueprintContent.children[ 1 ] )
            computedDOM.children.push( tmp )
            computedDOM.children[ computedDOM.children.length - 1 ].children[ 0 ].content = child.content
            if( child.children ) {
              child.children.map( dom => {
                // level 3
                let tmp = _.cloneDeep( mobileBlueprintContent.children[ 2 ] )
                computedDOM.children.push( tmp )
                computedDOM.children[ computedDOM.children.length - 1 ].children[ 0 ].content = dom.content
              })
            }
          })
        }
        this.patchObjectID( computedDOM )
        return (
          <CoreRenderFunction
            item={ computedDOM }
            updateMainContainerState={ updateMainContainerState }
            languages={ languages }
            selectedLanguageShortname={ selectedLanguageShortname } />
        )
      })
    } else {
      return <div></div>
    }
  }
}

export default HeaderMenuMobile