import React from 'react'
import { Button, FormGroup } from 'reactstrap'
import ReactHTMLParser from 'react-html-parser'

const ButtonComponent = ({
  onClickSubmitForm,
  content,
  mode,
  currentLanguage
}) => {
  return (
    <FormGroup 
      style={{ padding: content.padding ? content.padding : '0' }}>
      <Button 
        color={ content.color }
        onClick={ mode === 'edit' ? content.onClickEditor : onClickSubmitForm }
        style={ content.style }>
        { ReactHTMLParser( content.content[ currentLanguage ? currentLanguage : 'en' ] ) }
      </Button>
    </FormGroup>
  )
}

export default ButtonComponent