import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { Get, Post, Put, Delete } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      news: {
        data: [],
        meta: {
          currentPage: 1
        }
      },
      newNews: {
        name: {
          "en": "",
          "de": "",
          "nl": "",
          "it": "",
          "fr": ""
        },
        description: {
          "en": "",
          "de": "",
          "nl": "",
          "it": "",
          "fr": ""
        },
        image_url: '',
        redirect_url: "",
        publish_from: "",
        publish_to: "",
        countries: [],
        status: "ACTIVE",
        center_group_ids: [],
        has_expiry_date: false
      },
      showCreateNews: false,
      showUpdateNews: false,
      selectedNews: {
        name: {
          "en": "",
          "de": "",
          "nl": "",
          "it": "",
          "fr": ""
        },
        description: {
          "en": "",
          "de": "",
          "nl": "",
          "it": "",
          "fr": ""
        },
        image_url: '',
        redirect_url: "",
        publish_from: "",
        publish_to: "",
        countries: [],
        status: "ACTIVE",
        center_group_ids: [],
        has_expiry_date: false
      }
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      zIndex: 1000
    })
    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      zIndex: 1000
    })

    onChangeNewsHOC = ( key, val ) => this.setState({ [key]: val })

    getNews = ( param ) => Get(
      `/api/v1/admin_panel/news?query=${ convertObjToBase64( param ) }`,
      this.getNewsSuccess,
      this.getNewsError,
      this.load
    )
    getNewsSuccess = payload => this.setState({ news: payload })
    getNewsError = error => this.requestError( error )

    getSelectedNews = id => Get(
      `/api/v1/admin_panel/news/${ id }`,
      this.getSelectedNewsSuccess,
      this.getSelectedNewsError,
      this.load
    )
    getSelectedNewsSuccess = payload => {
      this.setState({ selectedNews: {
        ...payload,
        center_group_ids: payload.center_group_ids ? payload.center_group_ids : [],
        publish_to: payload.has_expiry_date ? payload.publish_to : ''
      }, showUpdateNews: true })
    }
    getSelectedNewsError = error => this.requestError( error )

    createNews = data => Post(
      `/api/v1/admin_panel/news`,
      data,
      this.createNewsSuccess,
      this.createNewsError,
      this.load
    )
    createNewsSuccess = payload => {
      this.setState({ 
        showCreateNews: false,
        newNews: {
          name: {
            "en": "",
            "de": "",
            "nl": "",
            "it": "",
            "fr": ""
          },
          description: {
            "en": "",
            "de": "",
            "nl": "",
            "it": "",
            "fr": ""
          },
          image_url: '',
          redirect_url: "",
          publish_from: "",
          publish_to: "",
          countries: [],
          status: "ACTIVE",
          center_group_ids: [],
          has_expiry_date: false
        }
      })
      this.requestSuccess( Lang[ 'NEWS_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getNews({
        page: 1,
        is_paginated: true,
        filter: {
          status: 'ACTIVE'
        }
      })
    }
    createNewsError = error => this.requestError( error )

    updateNews = data => Put(
      `/api/v1/admin_panel/news/${ data.id }`,
      data,
      this.updateNewsSuccess,
      this.updateNewsError,
      this.load
    )
    updateNewsSuccess = payload => {
      this.setState({ 
        showUpdateNews: false,
        selectedNews: {
          name: {
            "en": "",
            "de": "",
            "nl": "",
            "it": "",
            "fr": ""
          },
          description: {
            "en": "",
            "de": "",
            "nl": "",
            "it": "",
            "fr": ""
          },
          image_url: '',
          redirect_url: "",
          publish_from: "",
          publish_to: "",
          countries: [],
          status: "ACTIVE",
          center_group_ids: [],
          has_expiry_date: false
        }
      })
      this.requestSuccess( Lang[ 'NEWS_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getNews({
        page: 1,
        is_paginated: true,
        filter: {
          status: 'ACTIVE'
        }
      })
    }
    updateNewsError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadNews={ this.state.loading }
          news={ this.state.news }
          showCreateNews={ this.state.showCreateNews }
          newNews={ this.state.newNews }
          showUpdateNews={ this.state.showUpdateNews }
          selectedNews={ this.state.selectedNews }
          onChangeNewsHOC={ this.onChangeNewsHOC }
          getNews={ this.getNews }
          getSelectedNews={ this.getSelectedNews }
          createNews={ this.createNews }
          updateNews={ this.updateNews } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC