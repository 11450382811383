import React, { Component } from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup
} from 'reactstrap'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import _ from 'lodash'

import MultiLangInput from 'components/Input/MultiLang'
import MultilingualRTE from 'components/RTE/MultiLang'

import Lang from 'Lang/General'

class FaqCategoryForm extends Component {

  updateFaqCategory = ( key, value ) => {
    let tmp = _.cloneDeep( this.props.currentFaqCategory )
    tmp[ key ] = value
    this.props.onChangeFaqCategoriesHOC( this.props.faqCategoryType, tmp )
  }

  render = () => {
    let {
      selectedLanguageShortname,
      currentFaqCategory
    } = this.props

    return(
      <>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Container fluid>
            <Row>
              <Col md={ 8 }>
                <Form>
                  <FormGroup>
                    <MultiLangInput
                      label={ Lang[ 'NAME' ][ selectedLanguageShortname ] }
                      value={ currentFaqCategory.name }
                      onChange={ val => this.updateFaqCategory( 'name', val )}
                      selectedLanguage={ selectedLanguageShortname }
                    />
                  </FormGroup>
                  <FormGroup>
                    <MultilingualRTE
                      label={ Lang[ 'DESCRIPTION' ][ selectedLanguageShortname ] }
                      value={ currentFaqCategory.description }
                      onChange={ data => this.updateFaqCategory( 'description', data ) }
                      selectedLanguage={ selectedLanguageShortname }
                    />
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </Container>
        </ReactCSSTransitionGroup>
      </>
    )
  }
}

export default FaqCategoryForm