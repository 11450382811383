import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Get } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      newCampaigns: {},
      campaigns: [],
      selectedCampaign: {},
      showViewModal: false,
      showBookModal: false,
      showChangeModal: false,
      budgetData: {
        campaign_id: 1,
        remark: '',
      	internal_remark: 'internal remark 2',
      	fa_budget: 0,
        ga_budget: 0,
      	start_date: "2020-01-01T01:01:01Z",
      	status: "NEW"
      },
      selectedBookedCampaign: {
        fa_budget: 0,
      	ga_budget: 0,
      },
      presentBookedCampaign: false
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    onChangeCampaignHOC = ( key, value ) => this.setState({ [key]: value })

    getCampaigns = () => Get(
      `/api/v1/ols_campaigns?query=${ convertObjToBase64({
        is_paginated: false,
        filter: {
          status: 'ACTIVE'
        }
      }) }`,
      this.getCampaignsSuccess,
      this.getCampaignsError,
      this.load
    )
    getCampaignsSuccess = payload => {
      let tmp = _.cloneDeep( payload )
      if( _.find( tmp, { is_booked: true }) ) {
        this.setState({ presentBookedCampaign: true })
      }
      this.setState({ 
        campaigns: {
          ...payload,
          data: payload.data.map( campaign => ({
            ...campaign,
            value: campaign.id,
            label: campaign.title
          }) )
        }
      })
    }
    getCampaignsError = error => this.requestError( error )

    getSelectedCampaign = id => Get(
      `/api/v1/ols_campaigns/${ id }`,
      this.getSelectedCampaignSuccess,
      this.getSelectedCampaignError,
      this.load
    )
    getSelectedCampaignSuccess = payload => this.setState({ selectedCampaign: payload })
    getSelectedCampaignError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadOLSCampaigns={ this.state.loading }
          campaigns={ this.state.campaigns }
          getCampaigns={ this.getCampaigns }
          getSelectedCampaign={ this.getSelectedCampaign }
          selectedCampaign={ this.state.selectedCampaign }
          onChangeCampaignHOC={ this.onChangeCampaignHOC }
          showViewModal={ this.state.showViewModal }
          showBookModal={ this.state.showBookModal }
          budgetData={ this.state.budgetData }
          createBookedCampaign={ this.createBookedCampaign }
          showChangeModal={ this.state.showChangeModal }
          updateBudget={ this.updateBudget }
          getCampaignsCenter={ this.getCampaignsCenter } 
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
