import React, { useState, useEffect } from 'react'
import { Carousel } from 'react-bootstrap'
import Radium from 'proofpoint-radium'
import ReactHTMLParser from 'react-html-parser'
import { Background, Parallax } from 'react-parallax'
import { isMobile } from 'react-device-detect'
import _ from 'lodash'

import { ApplyColor } from 'utils/apply-color'
import Lang from "Lang/General"

import CoreRenderFunction from '../../core/render'

const ArkCarousel = props => {
  let {
    indicators,
    arrows,
    carouselItems,
    id,
    style,
    className,
    languages,
    selectedLanguageShortname,
    onUpdateCarouselPage,
    carouselPage,
    mode,
    interval,
    arrowPrev,
    arrowNext,
    styleComponents,
    onChangeSectionEditorState,
    selectedEditableComponent,
    isShowCarousel
  } = props

  const [ viewModeCarouselPage, updateViewModeCarouselPage ] = useState( 0 )
  const [ parallaxParent, setParallaxParent ] = useState( document )
  const makeCaptionCenter = originalStyles => {
    let tmp = _.cloneDeep( originalStyles )
    delete tmp.top
    delete tmp.bottom
    delete tmp.left
    delete tmp.transform
    tmp.position = "relative"
    tmp.textAlign = "center"
    tmp.margin = "auto"
    tmp.zIndex = 10
    return tmp
  }
  const getStyleDisplayValue = () => {
    return isShowCarousel === false
      ? mode === 'section-display-edit' || mode === 'edit'
        ? 'block'
        : 'none'
      : 'block'
  }

  useEffect( () => {
    if( mode !== 'view' ){
      let tmpWindow = document.getElementById( 'editor-site-renderer' ).contentWindow
      if( tmpWindow.getComputedStyle( tmpWindow.document.body.parentElement ).overflowX === "hidden" ){
        setParallaxParent( tmpWindow.document.body )
      } else {
        setParallaxParent( tmpWindow.document )
      }
    } else {
      if( window.getComputedStyle( document.body.parentElement ).overflowX === "hidden" ){
        setParallaxParent( document.body )
      } else {
        setParallaxParent( document )
      }
    }
  }, [])

  return (
    <>
      <div
        id={ id }
        style={ ApplyColor( { ...style, display: getStyleDisplayValue() } ) }
        className={ className }
        onClick={ () => {
          if(
            !isMobile && mode !== 'edit' && carouselItems &&
            carouselItems.length > 1 && window && window.innerWidth <= 768
          ){
            if( viewModeCarouselPage < carouselItems.length - 1 ){
              updateViewModeCarouselPage( viewModeCarouselPage + 1 )
            } else {
              updateViewModeCarouselPage( 0 )
            }
          }
        }}>
        {
          styleComponents && styleComponents.length > 0 && styleComponents.map( item => {
            return(
              <CoreRenderFunction
                { ...props }
                item={ item } />
            )
          })
        }
        <style>
          {`
            @media(max-width:768px){ #${ id } .carousel-control-prev { display: none !important; } }
            @media(max-width:768px){ #${ id } .carousel-control-next { display: none !important; } }
            .carousel-item { transition: transform 1s ease-in-out !important }
          `}
        </style>
        {
          isShowCarousel === false && (
            <div
              style={{
                color: 'rgb( 255, 255, 255 )',
                background: 'rgb(4, 37, 78, 0.7 )',
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: '11',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <h2
                style={{
                  textAlign: 'center',
                  padding: '150px',
                  wordBreak: 'break-word'
                }}
              >
                { Lang[ 'THE_SLIDER_IS_HIDDEN_YOU_CAN_REACTIVATE_IT_IN_THIS_SECTION_OPTIONS' ][ selectedLanguageShortname ] }
              </h2>
            </div>
          )
        }
        <Carousel
          activeIndex={ carouselPage ? carouselPage : viewModeCarouselPage }
          interval={ mode === 'edit'
            ? null
            : interval
              ? interval === 0
                ? null
                : interval
              : 5000 }
          wrap={ true }
          indicators={ indicators ? indicators : false }
          controls={ arrows }
          prevIcon={ arrowPrev && arrows
            ? ReactHTMLParser( arrowPrev )
            : arrows
              ? <span aria-hidden="true" className="carousel-control-prev-icon" />
              : <span></span> }
          nextIcon={ arrowNext && arrows
            ? ReactHTMLParser( arrowNext )
            : arrows
              ? <span aria-hidden="true" className="carousel-control-next-icon" />
              : <span></span> }
          onSelect={( eventKey, direction, event ) => mode === 'edit'
            ? onUpdateCarouselPage( eventKey )
            : updateViewModeCarouselPage( eventKey ) }>
          {
            carouselItems && carouselItems.length > 0 && carouselItems.map(( item, index ) => {
              if( mode === 'section-display-edit' && item.style ){
                item.style = {
                  ...item.style,
                  "@media (min-width: 576px)": !_.isEmpty( item.style[ '@media (min-width: 576px)' ] ) ? item.style[ '@media (min-width: 576px)' ] : {},
                  "@media (min-width: 768px)": !_.isEmpty( item.style[ '@media (min-width: 768px)' ] ) ? item.style[ '@media (min-width: 768px)' ] : {}
                }
              }
              return (
                <Carousel.Item key={ item.id }>
                  {
                    item.bgImg && (
                      <div
                        alt={ item.bgImgAlt ? item.bgImgAlt : '' }
                        title={ item.bgImgTitle ? item.bgImgTitle : '' }
                        style={{
                          position: 'relative',
                          width: '100%',
                          height: '100%'
                        }}>
                        {
                          item.isParallax ? (
                            <Parallax
                              strength={ 400 }
                              parent={ parallaxParent }>
                              <Background>
                                <div
                                  style={
                                    item.style
                                      ? ( item.style.height
                                        ? {
                                          ...item.style,
                                          width: '100vw',
                                          height: `calc( ${ item.style.height } + 100px )`,
                                          minHeight: '500px',
                                          backgroundSize: item.style.backgroundSize ? item.style.backgroundSize : 'cover',
                                          backgroundPosition: item.style.backgroundPosition ? item.style.backgroundPosition : "top center",
                                          backgroundRepeat: 'no-repeat',
                                          backgroundImage: `url(${ item.bgImg })`,
                                        } : {
                                          ...item.style,
                                          width: '100vw',
                                          height: '500px',
                                          backgroundSize: item.style.backgroundSize ? item.style.backgroundSize : 'cover',
                                          backgroundPosition: item.style.backgroundPosition? item.style.backgroundPosition : "top center",
                                          backgroundRepeat: 'no-repeat',
                                          backgroundImage: `url(${ item.bgImg })`,
                                          "@media (min-width: 768px)": {
                                            ...item.style[ "@media (min-width: 768px)" ],
                                            height: "700px"
                                          },
                                          "@media (min-width: 576px)": {
                                            ...item.style[ "@media (min-width: 576px)" ],
                                            height: "650px"
                                          }
                                        }
                                      ) : {
                                        width: '100vw',
                                        height: '500px',
                                        backgroundSize: 'cover',
                                        backgroundPosition: "top center",
                                        backgroundRepeat: 'no-repeat',
                                        backgroundImage: `url(${ item.bgImg })`,
                                        "@media (min-width: 768px)": {
                                          height: "700px"
                                        },
                                        "@media (min-width: 576px)": {
                                          height: "650px"
                                        }
                                      }
                                  }
                                />
                              </Background>
                              <div
                                style={
                                  item.style?.height
                                    ? {
                                      height: mode === 'edit' ? '100vh' : item.style.height
                                    } : {
                                      height: '400px',
                                      "@media (min-width: 768px)": {
                                        height: "600px"
                                      },
                                      "@media (min-width: 576px)": {
                                        height: "550px"
                                      }
                                    }
                                }/>
                            </Parallax>
                          ) : (
                            <div
                              className={ item.className ? item.className : '' }
                              style={
                                item.style
                                  ? ( item.style.height
                                    ? {
                                      ...item.style,
                                      display: "block",
                                      position: 'relative',
                                      width: '100vw',
                                      height: mode === 'edit' ? '100vh' : item.style.height,
                                      top: 0,
                                      backgroundSize: item.style.backgroundSize ? item.style.backgroundSize : 'cover',
                                      backgroundPosition: item.style.backgroundPosition ? item.style.backgroundPosition : "top center",
                                      backgroundRepeat: 'no-repeat',
                                      backgroundImage: `url(${ item.bgImg })`
                                    } : {
                                      ...item.style,
                                      display: "block",
                                      position: 'relative',
                                      width: '100vw',
                                      height: '400px',
                                      top: 0,
                                      backgroundSize: item.style.backgroundSize ? item.style.backgroundSize : 'cover',
                                      backgroundPosition: item.style.backgroundPosition? item.style.backgroundPosition : "top center",
                                      backgroundRepeat: 'no-repeat',
                                      backgroundImage: `url(${ item.bgImg })`,
                                      "@media (min-width: 768px)": {
                                        ...item.style[ "@media (min-width: 768px)" ],
                                        height: "600px"
                                      },
                                      "@media (min-width: 576px)": {
                                        ...item.style[ "@media (min-width: 576px)" ],
                                        height: "550px"
                                      }
                                    }
                                  ) : {
                                    display: "block",
                                    position: 'relative',
                                    width: '100vw',
                                    height: '400px',
                                    top: 0,
                                    backgroundSize: 'cover',
                                    backgroundPosition: "top center",
                                    backgroundRepeat: 'no-repeat',
                                    backgroundImage: `url(${ item.bgImg })`,
                                    "@media (min-width: 768px)": {
                                      height: "600px"
                                    },
                                    "@media (min-width: 576px)": {
                                      height: "550px"
                                    }
                                  }
                              }
                            />
                          )
                        }
                      </div>
                    )
                  }
                  {
                    item.dom && (
                      <CoreRenderFunction
                        { ...props }
                        id={ item.dom.id }
                        className={ item.dom.className ? item.dom.className : '' }
                        style={ item.dom.style
                          ? item.style.height
                            ? { ...item.dom.style, height: item.style.height }
                            : item.dom.style
                          : item.style.height
                            ? { height: item.style.height }
                            : {}
                        }
                        item={ item.dom } />
                    )
                  }
                  {
                    item.caption && item.caption.isCaptionCentered
                      ? (
                        <div style={{ width: "100%", color:"#ffffff", zIndex:10, position:'absolute', left:'50%', bottom:'50%', transform: 'translate(-50%, 50%)' }}>
                          <div
                            className={ item.caption.className ? item.caption.className : '' }
                            style={ ApplyColor( makeCaptionCenter( item.caption.style ) )}>
                            {
                              item.caption.header && (
                                <CoreRenderFunction
                                  item={ item.caption.header }
                                  languages={ languages }
                                  selectedLanguageShortname={ selectedLanguageShortname }
                                  mode={ mode }
                                  onChangeSectionEditorState={ onChangeSectionEditorState }
                                  selectedEditableComponent={ selectedEditableComponent } />
                              )
                            }
                            <h2
                              id={ `h2-slide#${ index }-${ id }` }
                              style={ ApplyColor( item.caption.title.style ) }>
                              { ReactHTMLParser( ApplyColor( item.caption.title[ selectedLanguageShortname ], true ) ) }
                            </h2>
                            <p
                              id={ `p-slide#${ index }-${ id }` }
                              style={ ApplyColor( item.caption.description.style ) }>
                              { ReactHTMLParser( ApplyColor( item.caption.description[ selectedLanguageShortname ], true ) ) }
                            </p>
                            {
                              item.caption.children && item.caption.children.length > 0 && item.caption.children.map( child => {
                                return child.showButton === false ? (
                                  <></>
                                ) : (
                                  <CoreRenderFunction
                                    item={ child }
                                    languages={ languages }
                                    selectedLanguageShortname={ selectedLanguageShortname }
                                    mode={ mode } />
                                )
                              })
                            }
                          </div>
                        </div>
                      ) : (
                        <Carousel.Caption>
                          <div
                            className={ item.caption.className ? item.caption.className: '' }
                            style={ ApplyColor( item.caption.style ) }>
                            {
                              item.caption.header && (
                                <CoreRenderFunction
                                  item={ item.caption.header }
                                  languages={ languages }
                                  selectedLanguageShortname={ selectedLanguageShortname }
                                  mode={ mode }
                                  onChangeSectionEditorState={ onChangeSectionEditorState }
                                  selectedEditableComponent={ selectedEditableComponent } />
                              )
                            }
                            <h2
                              id={ `h2-slide#${ index }-${ id }` }
                              style={ ApplyColor( item.caption.title.style ) }>
                              { ReactHTMLParser( ApplyColor( item.caption.title[ selectedLanguageShortname ], true ) ) }
                            </h2>
                            <p
                              id={ `p-slide#${ index }-${ id }` }
                              style={ ApplyColor( item.caption.description.style ) }>
                              { ReactHTMLParser( ApplyColor( item.caption.description[ selectedLanguageShortname ], true ) ) }
                            </p>
                            {
                              item.caption.children && item.caption.children.length > 0 && item.caption.children.map( child => {
                                return child.showButton === false ? (
                                  <></>
                                ) : (
                                  <CoreRenderFunction
                                    item={ child }
                                    languages={ languages }
                                    selectedLanguageShortname={ selectedLanguageShortname }
                                    mode={ mode } />
                                )
                              })
                            }
                          </div>
                        </Carousel.Caption>
                      )
                  }
                </Carousel.Item>
              )
            })
          }
        </Carousel>
      </div>
    </>
  )
}

export default Radium( ArkCarousel )