import React, { useEffect } from 'react'
import {
  Label, Button,
  Form, FormGroup,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'
import { cloneDeep, isEmpty } from 'lodash'
import ReactSelect from 'react-select'

import Lang from 'Lang/General'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import "react-datepicker/dist/react-datepicker.css"

const Create = props => {
  useEffect( () => {
    if ( !props.showCreateBookedCampaign ) {
      props.onChangeBookedCampaignHOC(
        'newBookedCampaign',
        {
          ols_campaign_id: 0,
          remark: '',
          internal_remark: '',
          fa_budget: 0,
          ga_budget: 0,
          start_date: null,
          status: "NEW",
          end_date: null,
          priority: 0,
          client_id: 0,
          client: null,
          campaign: null
        }
      )
      props.onChangeBookedCampaignHOC( 'newBookedCampaignError', {} )
    } else if ( 
      props.clients.length === 0 &&
      props.showCreateBookedCampaign
    ) {
      props.getClients()
      props.getCampaigns()
    }
  }, [ props.showCreateBookedCampaign ] )

  useEffect( () => {
    !isEmpty( props.selectedCampaign ) && props.onChangeBookedCampaignHOC( 
      'newBookedCampaign',
      {
        ...props.selectedCampaign,
        ...props.newBookedCampaign,
        ols_campaign_id: props.selectedCampaign.id,
        submitted_form_content: props.selectedCampaign.content,
        campaign: { 
          ...props.selectedCampaign,
          value: props.selectedCampaign.id,
          label: props.selectedCampaign.title
        }
      }
    )
  }, [ props.selectedCampaign ] )

  return(
    <Modal
      size="md"
      isOpen={ props.showCreateBookedCampaign } >
      <ModalHeader
        toggle={ () => props.onChangeBookedCampaignHOC( 'showCreateBookedCampaign', false ) }>
        { Lang[ 'CREATE_ONE_TIMER' ][ props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup className="position-relative" style={{ zIndex: 2 }} >
            <Label>{ Lang[ 'CLIENT' ][ props.data.languageReducer.lang ] }</Label>
            <ReactSelect
              name="centers"
              classNamePrefix="select"
              className="basic-multi-select mr-4 w-100"
              placeholder={ Lang[ 'SELECT_PLACEHOLDER' ][ props.data.languageReducer.lang ] }
              options={ props.clients }
              value={ props.newBookedCampaign.client }
              onChange={ e => props.onChangeBookedCampaignHOC( 
                'newBookedCampaign',
                {
                  ...props.newBookedCampaign,
                  client: e,
                  client_id: e.value
                }
              )}
            />
          </FormGroup>
          <FormGroup className="position-relative" style={{ zIndex: 1 }} >
            <Label>{ Lang[ 'CAMPAIGN' ][ props.data.languageReducer.lang ] }</Label>
            <ReactSelect
              name="centers"
              classNamePrefix="select"
              className="basic-multi-select mr-4 w-100"
              placeholder={ Lang[ 'SELECT_PLACEHOLDER' ][ props.data.languageReducer.lang ] }
              options={ props.campaigns?.data }
              value={ props.newBookedCampaign.campaign } 
              onChange={ e => props.getSelectedCampaign( e.value ) }
            />
          </FormGroup>
        </Form>
        { 
          ( 
            props.onLoadBookedCampaign ||
            props.onLoadOLSCampaigns ||
            props.onLoadClientsHOC
          ) && <LoadingOverlay /> 
        }
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary" 
          onClick={ () => {
            let tmpNewBookedCampaign = cloneDeep( props.newBookedCampaign )
            tmpNewBookedCampaign.updated_by = tmpNewBookedCampaign.updated_by.id
            tmpNewBookedCampaign.created_by = tmpNewBookedCampaign.created_by.id
            props.createBookedCampaign( tmpNewBookedCampaign )
          }}>
          { Lang[ 'SUBMIT' ][ props.data.languageReducer.lang ] }
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default Create
