import React, { useState, createRef, useEffect } from "react";
import { Accordion, Card } from "react-bootstrap";
import Radium from "proofpoint-radium";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import ReactHTMLParser from "react-html-parser";

import CoreRenderFunction from "../core/render";

const CustomAccordion = (props) => {
	let {
		id,
		item,
		style,
		activeColor,
		inActiveColor,
		className,
		children,
		selectedLanguageShortname,
		mode,
		defaultActiveKey,
		selectedContainer,
		onChangeSectionEditorState,
		hoveredComponentId,
	} = props;

	const [activeKey, setActiveKey] = useState("");
	const activeItem =
		mode === "edit"
			? ""
			: children.reduce((acc, value) => {
					acc[value.id] = createRef();
					return acc;
			  }, {});
	const locateItem = (id) => {
		if (activeItem)
			activeItem[id].current.scrollIntoView({
				behavior: "smooth",
				block: "center",
			});
	};

	useEffect(() => setActiveKey(defaultActiveKey), []);

	return (
		<>
			<style>{`#${id} p { margin: 0; }`}</style>
			<style>{`#${id} h2 { margin: 0; }`}</style>
			<style>{`#${id} h3 { margin: 0; }`}</style>
			<style>{`#${id} h4 { margin: 0; }`}</style>
			<div
				id={id}
				style={{
					...style,
					border:
						(selectedContainer &&
							props.mode === "edit" &&
							selectedContainer.id === id) ||
						(mode === "edit" && hoveredComponentId === id)
							? "5px solid #28a745"
							: "none",
				}}
				onMouseEnter={() =>
					mode === "edit" &&
					onChangeSectionEditorState &&
					onChangeSectionEditorState("hoveredComponentId", id)
				}
				onMouseLeave={() =>
					mode === "edit" &&
					onChangeSectionEditorState &&
					onChangeSectionEditorState("hoveredComponentId", "")
				}
				onClick={() => {
					if (mode === "edit") {
						if (onChangeSectionEditorState) {
							onChangeSectionEditorState("selectedContainer", item);
						}
					}
				}}
				className={className}>
				<Accordion
					onSelect={(e) => setActiveKey(e)}
					defaultActiveKey={defaultActiveKey ? defaultActiveKey : ""}>
					{children &&
						children.length > 0 &&
						children.map((item, index) => {
							return (
								<Card ref={activeItem[item.id]} style={item.style}>
									<Accordion.Toggle
										as={Card.Header}
										eventKey={item.id}
										style={{
											...item.children[0].style,
											color:
												activeKey === item.id
													? activeColor
														? activeColor
														: inActiveColor
														? inActiveColor
														: "black"
													: inActiveColor
													? inActiveColor
													: "black",
										}}
										onClick={() => locateItem(item.id)}>
										{item.children[0].content &&
											ReactHTMLParser(
												item.children[0].content[selectedLanguageShortname]
											)}
										{item.children[0].children && (
											<CoreRenderFunction
												item={item.children[0]}
												mode={mode}
												selectedLanguageShortname={selectedLanguageShortname}
												onChangeSectionEditorState={() => {}}
												onChangeSectionHOC={() => {}}
											/>
										)}

										<div style={{ alignSelf: "center", marginLeft: "auto" }}>
											{activeKey === item.id ? (
												<AiOutlineMinus
													style={item.children[0].iconStyle}
													size={24}
												/>
											) : (
												<AiOutlinePlus
													style={item.children[0].iconStyle}
													size={24}
												/>
											)}
										</div>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey={item.id}>
										<Card.Body
											style={{
												...item.children[1].style,
												borderTopColor: activeColor
													? activeColor
													: inActiveColor
													? inActiveColor
													: "black",
											}}>
											{
												<CoreRenderFunction
													item={item.children[1]}
													mode={mode}
													selectedLanguageShortname={selectedLanguageShortname}
													onChangeSectionEditorState={() => {}}
													onChangeSectionHOC={() => {}}
												/>
											}
										</Card.Body>
									</Accordion.Collapse>
								</Card>
							);
						})}
				</Accordion>
			</div>
		</>
	);
};

export default Radium(CustomAccordion);
