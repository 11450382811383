import React from 'react'
import {
  DropdownToggle, DropdownMenu,
  Nav, Button, NavItem,
  UncontrolledButtonDropdown
} from 'reactstrap'
import { connect } from 'react-redux'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { purgeStoredState } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { clearItem } from 'utils/token'
import { clearProfile } from 'actions/profile'
import { clearSelectedRole } from 'actions/role'
import { updateUserSetting } from 'actions/language'

import avatar1 from 'assets/TemplateImages/avatars/1.jpg'
import city3 from 'assets/TemplateImages/dropdown-header/city3.jpg'

import Lang from 'Lang/General'

const Userbox = ({
  history,
  onClickToggleProfileModal,
  profile,
  data,
  clearProfile,
  clearSelectedRole,
  updateUserSetting
}) => {
  const onClickSignOut = () => {
    purgeStoredState({
      key: 'proofpoint-ark-management',
      storage
    }).then(() => {
      clearItem( 'PROOFPOINT_ARK_MANAGEMENT' )
      history.push( '/' )
    })
  }
  return (
    <div className="header-btn-lg pr-0 mr-20">
      <div className="d-flex">
        <span>{ profile.first_name } { profile.last_name } | </span>
        <div>
          <select
            style={{
              padding: "2px 5px",
              border: 'none',
              outline: 'none'
            }}
            value={ data.languageReducer.lang }
            onChange={ e => {
              updateUserSetting({ admin_panel_language: e.target.value })
            }}>
            <option value={ 'en' }>{ 'EN' }</option>
            <option value={ 'de' }>{ 'DE' }</option>
            <option value={ 'it' }>{ 'IT' }</option>
            <option value={ 'nl' }>{ 'NL' }</option>
          </select>
        { /*data.languageReducer.lang.toUpperCase() */}
        </div>
        <span> | { Lang[ 'LOGOUT' ][ data.languageReducer.lang ] } </span>
      </div>
      <i
        className="pe-7s-power"
        onClick = { () => {
          onClickSignOut()
          clearProfile()
          clearSelectedRole()
        }}
        style={{ fontSize: '30px', cursor: 'pointer' }} />

            {/*
            <UncontrolledButtonDropdown>
              <DropdownToggle color="link" className="p-0">
                <img width={ 42 } height={ 42 } className="rounded-circle" src={ profile.photo_url } alt="" />
                <FontAwesomeIcon className="ml-2 opacity-8" icon={ faAngleDown } />
              </DropdownToggle>
              <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                <div className="dropdown-menu-header">
                  <div className="dropdown-menu-header-inner bg-info">
                    <div className="menu-header-image opacity-2" style={{ backgroundImage: 'url(' + city3 + ')' }} />
                    <div className="menu-header-content text-left">
                      <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                          <div className="widget-content-left mr-3">
                            <img width={ 42 } height={ 42 } className="rounded-circle" src={ profile.photo_url } alt="" />
                          </div>
                          <div className="widget-content-left">
                            <div className="widget-heading">{ `${ profile.first_name } ${ profile.last_name }` }</div>
                            <div className="widget-subheading opacity-8">{ "Admin" }</div>
                          </div>
                          <div className="widget-content-right mr-2">
                            <Button
                              className="btn-pill btn-shadow btn-shine"
                              color="focus"
                              onClick = { () => {
                                onClickSignOut()
                                clearProfile()
                                clearSelectedRole()
                              }}>Logout</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="scroll-area-sm" style={{ padding: '30px 20px' }}>
                    <div className="scrollbar-container ps ps--active-y">
                      <ul className="rm-list-borders rm-list-borders-scroll list-group list-group-flush">
                        <li className="nav-item-header nav-item">
                          <div className="widget-content p-0">
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left">
                                <div className="widget-heading">First name</div>
                              </div>
                              <div className="widget-content-right">
                                <div className="opacity-5 pr-1">{ profile.first_name }</div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="nav-item-header nav-item">
                          <div className="widget-content p-0">
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left">
                                <div className="widget-heading">Last name</div>
                              </div>
                              <div className="widget-content-right">
                                <div className="opacity-5 pr-1">{ profile.last_name }</div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="nav-item-header nav-item">
                          <div className="widget-content p-0">
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left">
                                <div className="widget-heading">Username</div>
                              </div>
                              <div className="widget-content-right">
                                <div className="opacity-5 pr-1">{ profile.username }</div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="nav-item-header nav-item">
                          <div className="widget-content p-0">
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left">
                                <div className="widget-heading">Email</div>
                              </div>
                              <div className="widget-content-right">
                                <div className="opacity-5 pr-1">{ profile.email }</div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="scroll-area-xs" style={{ height: '150px' }}>
                    <Nav vertical>
                      <NavItem className="p-3">
                        <Button onClick={ () => console.log( profile ) } block className="mb-2 mr-2 btn-shadow btn-gradient-primary">My Profile</Button>
                      </NavItem>
                    </Nav>
                  </div>
                </div>
              </DropdownMenu>
            </UncontrolledButtonDropdown>*/}
          {/* </div>
        </div>
      </div> */}
    </div>
  )
}
const mapStateToProps = state => ({ data: state })
export default connect( mapStateToProps, {
  clearProfile,
  clearSelectedRole,
  updateUserSetting
})( Userbox )
