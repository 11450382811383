import React, { useState, useEffect } from 'react'
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button
} from 'reactstrap'
import _ from 'lodash'
import Tabs from 'react-responsive-tabs'
import { connect } from 'react-redux'

import { toggleMediaDatabase } from 'actions/MediaDatabaseToggle'

import FieldSettings from './FieldSettings'
import FieldValue from './FieldValue'

import Lang from 'Lang/General'

const TabsConfig = {
  activeTab: '0',
  showMore: true,
  transform: true,
  showInkBar: true,
  selectedTabKey: 0,
  transformWidth: 400,
}

const UpdateColumnModal = ({
  showUpdateColumnModal,
  selectedComponentToEdit,
  onChangeFormBuilderState,
  findObjectAndPatch,
  updateColumn,
  dom,
  formValues,
  currentPage,
  toggleMediaDatabase,
  lang
}) => {
  const [ tab, updateTab ] = useState( 0 )
  const [ totalTabs, updateTotalTabs ] = useState( 2 )
  const [ formValuesCache, updateFormValuesCache ] = useState()

  useEffect(() => {
    if( showUpdateColumnModal && !_.isEmpty( selectedComponentToEdit ) ) {
      if( selectedComponentToEdit.children[ 0 ].type === 'Image' || selectedComponentToEdit.children[ 0 ].type === 'Text' ) {
        updateTab( 0 )
        updateTotalTabs( 1 )
      } else {
        updateTab( 0 )
        updateTotalTabs( 2 )
      }

      // ---- this part is for backward compatability (form before padding style changes) ----
      if ( !selectedComponentToEdit.children[ 0 ].style || !selectedComponentToEdit.children[ 0 ].style.paddingTop ) {
        let tmpChildren = _.cloneDeep( selectedComponentToEdit.children )
        let tmpDiv = document.createElement("div")
        tmpDiv.style.padding = tmpChildren[0].padding
        const paddingStyles = {
          paddingTop: tmpDiv.style.paddingTop ,
          paddingRight: tmpDiv.style.paddingRight ,
          paddingBottom: tmpDiv.style. paddingBottom,
          paddingLeft: tmpDiv.style.paddingLeft 
        }
        if ( selectedComponentToEdit.children[ 0 ].style ) {
          tmpChildren[0]['style'] = {
            ...paddingStyles,
            ...tmpChildren[0]['style']
          }
        } else {
          tmpChildren[0]['style'] = paddingStyles
        }
        updateSelectedComponentToEdit( "children", tmpChildren )
      } 
      // ---- end of code for backward compatability ----
    }

  }, [ showUpdateColumnModal, selectedComponentToEdit.id ])

  useEffect( () => {
    if( formValues.length > 0 ){
      updateFormValuesCache( formValues )
    }
  }, [ formValues ] )

  const updateSelectedComponentToEdit = async ( key, val ) => {
    let tmpDOM = _.cloneDeep( dom )
    let tmp = _.cloneDeep( selectedComponentToEdit )
    tmp[ key ] = val
    findObjectAndPatch( tmpDOM, tmp )
    await onChangeFormBuilderState( 'selectedComponentToEdit', tmp )
  }

  return (
    <Modal
      isOpen={ showUpdateColumnModal }
      size={ 'lg' }
      toggle={() => {
        onChangeFormBuilderState( 'showUpdateColumnModal', false )
      }}>
      <ModalHeader toggle={() => onChangeFormBuilderState( 'showUpdateColumnModal', false )}>{ Lang[ 'UPDATE_COLUMN' ][ lang ] }</ModalHeader>
      <ModalBody>
        {
          !_.isEmpty( selectedComponentToEdit ) && selectedComponentToEdit.type !== 'FormFrame' ? (
            <>
              <Tabs
                tabsWrapperClass="body-tabs"
                { ...TabsConfig }
                items={[
                  { 
                    key: 0,
                    title: Lang[ 'FIELD_SETTINGS' ][ lang ],
                    getContent: () => <FieldSettings 
                      selectedComponentToEdit={ selectedComponentToEdit }
                      toggleMediaDatabase={ toggleMediaDatabase }
                      onChangeFormBuilderState={ onChangeFormBuilderState }
                      updateSelectedComponentToEdit={ updateSelectedComponentToEdit }
                      formValuesCache={ formValuesCache }
                      updateFormValuesCache={ updateFormValuesCache }
                      lang={ lang }
                    />
                  },
                  { 
                    key: 1, 
                    title: Lang[ 'FIELD_VALUES' ][ lang ],
                    getContent: () => <FieldValue 
                      selectedComponentToEdit={ selectedComponentToEdit }
                      updateSelectedComponentToEdit={ updateSelectedComponentToEdit }
                      formValuesCache={ formValuesCache }
                      currentPage={ currentPage }
                      updateFormValuesCache={ updateFormValuesCache }
                      lang={ lang }
                    />
                  }
                ].slice( 0, totalTabs ) }
                selectedTabKey={ tab }
                onChange={ val => updateTab( val ) } />
            </>
          ) : (
            <p>{ Lang[ 'NO_CONTENT_FOUND' ][ lang ] }</p>
          )
        }
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={ async () => {
            let tmp = _.cloneDeep( selectedComponentToEdit )
            let tmpDOM = _.cloneDeep( dom )
            findObjectAndPatch( tmpDOM, tmp )
            formValuesCache.map( item => {
              if ( item?.requiredSelections?.length > 0 ) {
                item.isRequired = true
                if ( !item.validation ) {
                  item.validation = Lang[ 'FIELD_IS_REQUIRED' ]
                }
              }
            })
            updateColumn( tmpDOM, formValuesCache )
          }}>{ Lang[ 'SUBMIT' ][ lang ] }</Button>
      </ModalFooter>
    </Modal>
  )
}

const mapStateToProps = state => ({ data: state })

export default connect( mapStateToProps, {
  toggleMediaDatabase
})( UpdateColumnModal )