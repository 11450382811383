import React, { useState, useEffect } from "react"
import _ from "lodash"
import { compose } from "redux"
import ReactSelect from "react-select"
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Button,
  UncontrolledTooltip,
  CustomInput
} from "reactstrap"
import ARKTable from "components/Table"
import { IoMdAdd } from "react-icons/io"

import { CountryList } from 'assets/CountryLists'
import LandingPageHOC from "./actions"
import TemplateHOC from "./actions/template"
import CenterGroupHOC from "actions/centerGroup"
import CenterHOC from "./actions/center"
import PageTitle from "components/PageTitle"
import LoadingOverlay from "components/Indicator/LoadingOverlay"
import CreateModal from "./Create"
import UpdateModal from "./Update"
import DeleteModal from "components/Modal/delete"

import Lang from "Lang/General"

const LandingPageList = props => {
  const [ countriesOptions, updateCountryOptions ] = useState([])
  const [ searchType, updateSearchType ] = useState( 'normal' )
  const [ searchBy, updateSearchBy ] = useState( 'center' )
  const [ searchParam, updateSearchParam ] = useState({
    name: "",
    center_ids: [],
    center_group_ids: [],
    countries: []
  })

  useEffect(() => {
    props.getLandingPage(
      {
        page: 1,
        is_paginated: true
      }
    )
    props.getSiteTemplate()
    props.getCenterGroupsForSelect({
      page: 1,
      is_paginated: false,
    })
    props.getCenters()

    let tmp = []
    CountryList.map( item => {
      tmp.push({
        label: item.name,
        value: item.code,
      })
    })
    updateCountryOptions( tmp )
  }, [])

  useEffect(() => {
    updateSearchParam({
      name: "",
      center_ids: [],
      center_group_ids: [],
      countries: []
    })
  }, [ searchBy, searchType ])

  useEffect(() => {
    if( !props.showCreateModal ) {
      props.onChangeLandingPageHOC(
        'newPage',
        {
          name: Lang[ "BLANK" ],
          description: Lang[ "BLANK" ],
          url: "",
          status: "inactive",
          content: {},
          center_group_ids: [],
          countries: [],
          center_ids: [],
          is_hide_footer: false
        }
      )
    }
  }, [ props.showCreateModal ] )

  const handleSecurityModules = type => {
    let tmp = _.find( props.data.roleReducer.role.platforms, {
      name: "Ark Management",
    })
    let tmpModules = {}
    if ( tmp ) {
      tmpModules = _.find( tmp.modules, { name: "OLS" } )
      return tmpModules[ type ]
    }
  }

  const handleSearchParam = () => {
    let tmpFilter = {}
    if( !_.isEmpty( searchParam.name )){
      tmpFilter.name = {
        $like: `%${ searchParam.name }%`
      }
    }
    if( searchType === 'advanced' ){
      if( searchBy === 'center' && !_.isEmpty( searchParam.center_ids ) ){
        tmpFilter = {
          ...tmpFilter,
          $or: searchParam.center_ids.map( center => ({ center_id: center.id }) )
        }
      } else {
        tmpFilter.$and = []
        if( !_.isEmpty( searchParam.center_group_ids )) {
          tmpFilter.$and.push({
            $or: searchParam.center_group_ids.map( center_group => ({ center_group_id: center_group.id }) )
          })
        }
        if( !_.isEmpty( searchParam.countries )) {
          tmpFilter.$and.push({
            $or: searchParam.countries.map( country => ({ country: country.value }) )
          })
        }
        tmpFilter.$and.length === 0 && delete tmpFilter.$and
      }
    }
    return tmpFilter
  }

  return (
    <>
      <PageTitle
        heading={ Lang[ "LANDING_PAGE_LISTING" ][ props.data.languageReducer.lang ] }
        icon="pe-7s-display2 icon-gradient bg-happy-fisher"
        buttons={[
          {
            buttonIcon: <IoMdAdd fontSize="24px"/>,
            buttonText: Lang[ "ADD_PAGE" ][ props.data.languageReducer.lang ],
            onClick: () => props.onChangeLandingPageHOC( "showCreateModal", true ),
            display: handleSecurityModules( "is_create" ),
          },
        ]}
      />
      <Card className="main-card mb-3">
        <CardHeader>{ Lang[ "SEARCH" ][ props.data.languageReducer.lang ] }</CardHeader>
        <CardBody>
          <Container style={{ margin: 0 }}>
            <div className="mb-2">
              <Input
                type="select"
                value={ searchType }
                onChange={ e => updateSearchType( e.target.value )} >
                  <option value={ 'normal' }>{ Lang[ "NORMAL_SEARCH" ][ props.data.languageReducer.lang ] }</option>
                  <option value={ 'advanced' }>{ Lang[ "ADVANCED_SEARCH" ][ props.data.languageReducer.lang ] }</option>
              </Input>
              {
                searchType === 'advanced' && (
                  <>
                    <CustomInput
                      id='center-filter'
                      inline
                      type="radio"
                      label={ Lang[ "CENTER" ][ props.data.languageReducer.lang ] }
                      checked={ searchBy === 'center' }
                      onChange={ () => updateSearchBy( 'center' )}/>
                    <CustomInput
                      id='center-group-filter'
                      inline
                      type="radio"
                      label={ `${ Lang[ "CENTER_GROUP" ][ props.data.languageReducer.lang ]} & ${ Lang[ "COUNTRY" ][ props.data.languageReducer.lang ]} ` }
                      checked={ searchBy === 'center-group' }
                      onChange={ () => updateSearchBy( 'center-group' )}/>
                  </>
                )
              }
            </div>
            <Row>
              <Col md={ searchType === 'advanced' ? searchBy === 'center' ? 6 : 4 : 12 }>
                <FormGroup>
                  <Label>{ Lang[ "NAME" ][ props.data.languageReducer.lang ] }</Label>
                  <Input
                    name="name"
                    id="name"
                    placeholder={ Lang[ "SEARCH_BY_PAGE_NAME" ][ props.data.languageReducer.lang ] }
                    value={ searchParam.name }
                    onChange={ e =>
                      updateSearchParam({
                        ...searchParam,
                        name: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
              {
                searchType === 'advanced' 
                  ? searchBy === 'center' 
                    ? (
                      <Col md={6}>
                        <FormGroup>
                          <Label>
                            { Lang[ "CENTER" ][ props.data.languageReducer.lang ] }
                          </Label>
                          <ReactSelect
                            isMulti
                            options={ props.centers ? props.centers : [] }
                            classNamePrefix="select"
                            placeholder={ Lang[ 'SELECT_CENTER_GROUP' ][ props.data.languageReducer.lang ] }
                            onChange={ val => updateSearchParam({ ...searchParam, center_ids: val }) }
                            value={ searchParam.center_ids } 
                          />
                        </FormGroup>
                      </Col>
                    ) : (
                      <>
                        <Col md={4}>
                          <FormGroup>
                            <Label>
                              { Lang[ "CENTER_GROUP" ][ props.data.languageReducer.lang ] }
                            </Label>
                            <ReactSelect
                              isMulti
                              options={ props.centerGroupForSelect ? props.centerGroupForSelect : [] }
                              classNamePrefix="select"
                              placeholder={ Lang[ 'SELECT_CENTER_GROUP' ][ props.data.languageReducer.lang ] }
                              onChange={ val => updateSearchParam({ ...searchParam, center_group_ids: val }) }
                              value={ searchParam.center_group_ids } 
                            />
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup>
                            <Label>{ Lang[ "COUNTRY" ][ props.data.languageReducer.lang ] }</Label>
                            <ReactSelect
                              isMulti
                              options={ countriesOptions }
                              classNamePrefix="select"
                              placeholder={ Lang[ "SELECT_COUNTRY" ][ props.data.languageReducer.lang ] }
                              onChange={val => updateSearchParam({ ...searchParam, countries: val })}
                              value={ searchParam.countries }
                            />
                          </FormGroup>
                        </Col>
                      </>
                    )
                  : <></>
              }
            </Row>
          </Container>
        </CardBody>
        <CardFooter>
          <Button
            color="primary"
            className="ml-auto"
            onClick={ () => {
              let tmp = { 
                page: 1, 
                is_paginated: true,
                filter: handleSearchParam()
              }
              return props.getLandingPage( tmp )
            }}
          >
            { Lang[ "SEARCH" ][ props.data.languageReducer.lang ] }
          </Button>
          <Button
            color="danger"
            onClick={ () => {
              let tmp = { page: 1, is_paginated: true }
              updateSearchParam({
                name:"",
                center_ids: [],
                center_group_ids: [],
                countries: []
              })
              return props.getLandingPage( tmp )
            }}
          >
            { Lang[ "RESET" ][ props.data.languageReducer.lang ] }
          </Button>
        </CardFooter>
      </Card>
      <ARKTable
        data={ props.landingPage.data }
        columns={[
          {
            Header: Lang[ "NAME" ][ props.data.languageReducer.lang ],
            accessor: "name",
            Cell: ({ value }) => (
              <span>
                { 
                  value[ props.data.languageReducer.lang] 
                    ? value[ props.data.languageReducer.lang ] 
                    : value[ "de" ] 
                      ? value[ "de" ]
                      : value[ "en" ]
                }
              </span>
            ),
          },
          {
            Header: Lang[ "URL" ][ props.data.languageReducer.lang ],
            accessor: "url",
          },
          {
            Header: Lang[ "STATUS" ][ props.data.languageReducer.lang ],
            accessor: "status",
            Cell: ({ value }) => Lang[ value.toUpperCase() ][ props.data.languageReducer.lang ]
          },
          {
            Header: Lang[ 'CENTER' ][ props.data.languageReducer.lang ],
            accessor: 'center_ids',
            Cell: ({ value }) => {
              if( value && value.length > 0 ) {
                return (
                  <ul>
                    { value.map( value => {
                      let center = _.find( props.centers, { id: value } )
                      if( center ) {
                        return <li>{ center.label }</li>
                      } else {
                        return null
                      }
                    })}
                  </ul>
                )
              } else {
                return null
              }
            }
          },
          {
            Header: Lang[ "CENTER_GROUP" ][ props.data.languageReducer.lang ],
            accessor: "center_group_ids",
            Cell: ({ value }) => {
              if ( value && value.length > 0 ) {
                return (
                  <ul>
                    { value.map( id => {
                      let tmp = _.find( props.centerGroupForSelect, { id: id } )
                      if ( tmp ) {
                        return <li>{ tmp.label }</li>
                      } else {
                        return null
                      }
                    })}
                  </ul>
                )
              } else {
                return null
              }
            },
          },
          {
            Header: Lang[ "ACTION" ][ props.data.languageReducer.lang ],
            accessor: "id",
            width: 150,
            Cell: row => (
              <>
                { 
                  handleSecurityModules( "is_update" ) && (
                    <>
                      <Button
                        id={ `Page-${ row.original.id }-edit` }
                        className="mb-2 mr-2 btn-icon btn-icon-only"
                        color="primary"
                        onClick={ () => window.location.href = `${ window.location.origin }/#/landing-page-editor/${ row.original.id }` }
                      >
                        <i className="pe-7s-note" />
                      </Button>
                      <UncontrolledTooltip target={ `Page-${ row.original.id }-edit` } fade={ false } placement="top">
                        { Lang[ "EDIT_PAGE" ][ props.data.languageReducer.lang ] }
                      </UncontrolledTooltip>
                    </>
                  )
                }
                {
                  handleSecurityModules("is_update") && (
                    <>
                      <Button
                        id={ `Page-${ row.original.id }-edit-info` }
                        className="mb-2 mr-2 btn-icon btn-icon-only"
                        color="secondary"
                        onClick={ () => {
                          let tmp = _.cloneDeep( row.original )
                          tmp.center_ids = row.original.center_ids.length > 0 ? _.filter( props.center, e => row.original.center_ids.includes( e.id ) ) : []
                          tmp.center_group_ids = row.original.center_group_ids.length > 0 ? _.filter( props.centerGroupForSelect, e => row.original.center_group_ids.includes( e.id ) ) : []
                          tmp.countries = row.original.countries.length > 0 ? _.filter( countriesOptions, e => row.original.countries.includes( e.value )) : []
                          tmp.url = tmp.url.substring( 1 )
                          tmp.center_ids = _.filter( props.centers, e => row.original.center_ids.includes( e.id ) )
                          props.onChangeLandingPageHOC( "showUpdateModal", true )
                          props.onChangeLandingPageHOC( "selectedTemplatePage", tmp )
                        }}
                      >
                        <i className="pe-7s-info" />
                      </Button>
                      <UncontrolledTooltip target={ `Page-${ row.original.id }-edit-info` } fade={ false } placement="top">
                        { Lang[ "EDIT_PAGE_INFORMATION" ][ props.data.languageReducer.lang ] }
                      </UncontrolledTooltip>
                    </>
                  )
                }
                {
                  handleSecurityModules( "is_delete" ) && (
                    <>
                      <Button
                        id={ `Page-${ row.original.id }-delete` }
                        className="mb-2 mr-2 btn-icon btn-icon-only"
                        color="danger"
                        onClick={() => {
                          props.onChangeLandingPageHOC( "showDeleteModal", true )
                          props.onChangeLandingPageHOC( "selectedTemplatePage", row.original )
                        }}
                      >
                        <i className="pe-7s-trash" />
                      </Button>
                      <UncontrolledTooltip target={ `Page-${ row.original.id }-delete` } fade={ false } placement="top">
                        { Lang[ "DELETE_PAGE" ][ props.data.languageReducer.lang ] }
                      </UncontrolledTooltip>
                    </>
                  )
                }
              </>
            ),
          },
        ]}
        totalPages={ props.landingPage.meta ? [ ...Array( props.landingPage.meta.last_page ).keys() ].map( i => i + 1 ) : [1] }
        page={ props.landingPage.meta ? props.landingPage.meta.current_page : 1 }
        onChangePage={pg => {
          let tmp = { 
            page: pg, 
            is_paginated: true
          }
          let tmpFilter = handleSearchParam()
          if( !_.isEmpty( tmpFilter ) ){
            tmp.filter = tmpFilter
          }
          return props.getLandingPage( tmp )
        }}
        showPagination={ false }
      />
      <CreateModal { ...props } countriesOptions={ countriesOptions } />
      { props.showUpdateModal && <UpdateModal { ...props } countriesOptions={ countriesOptions } /> }
      <DeleteModal
        deleteOpen={ props.showDeleteModal }
        handleModalClose={ () => props.onChangeLandingPageHOC( "showDeleteModal", false ) }
        content={ Lang[ "DELETE_PAGE_CONFIRMATION" ][ props.data.languageReducer.lang ] }
        lang={ props.data.languageReducer.lang }
        confirmAction={ () => props.deleteLandingPage( props.selectedTemplatePage.id ) }
      />
      {( props.data.ajaxReducer.ajaxCallProgress > 0 || props.onLoadLandingTemplate || props.onLoadSiteTemplate || props.onLoadCenters || props.onLoadCenterGroups ) && (
        <LoadingOverlay />
      )}
    </>
  )
}

export default compose(
  LandingPageHOC, 
  TemplateHOC, 
  CenterGroupHOC,
  CenterHOC
)( LandingPageList )
