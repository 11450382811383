import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Button,
  Label
} from 'reactstrap'

import Lang from 'Lang/General'

const UpdateLeadConnect = ({
  showUpdateLeadConnect,
  onChangeCenterHOC,
  updateLeadConnect,
  leadID,
  data
}) => {
  return (
    <Modal
      isOpen={ showUpdateLeadConnect }
      size={ 'md' }
      toggle={() => onChangeCenterHOC( 'showUpdateLeadConnect', false )}>
      <ModalHeader toggle={() => onChangeCenterHOC( 'showUpdateLeadConnect', false )}>{ Lang[ 'UPDATE_LEAD_CONNECT_INFO' ][ data.languageReducer.lang ] }</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault() }>
          <FormGroup>
            <Label>{ Lang[ 'LEAD_CONNECT_ID' ][ data.languageReducer.lang ] }</Label>
            <Input
              type="text"
              value={ leadID }
              onChange={ e => onChangeCenterHOC( 'leadID', e.target.value ) } />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={ updateLeadConnect }>
          { Lang[ 'SUBMIT' ][ data.languageReducer.lang ] }
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default UpdateLeadConnect
