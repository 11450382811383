import React, { Component } from 'react'
import { Calendar, Views } from 'react-big-calendar'
import { Button } from 'reactstrap'
import { FaSquareFull } from 'react-icons/fa'
import localizer from 'react-big-calendar/lib/localizers/globalize'
import globalize from 'globalize'
import moment from 'moment'
import _ from 'lodash'

import events from './events'
import rooms from './rooms'
import categories from './categories'
import * as dates from './utils/dates'
import * as courseplanHeaderText from './utils/courseplanHeaderText'

import CourseplanToolbar from './components/courseplanToolbar'
import CourseplanEventModal from './components/courseplanEventModal'
import RoomsDropdown from './components/roomsDropdown'
import 'react-big-calendar/lib/css/react-big-calendar.css'

class CourseplanCalendar extends Component {
  state = { 
    isOpen: false,
    isRoomDropdownOpen: false,
    tempEvent: {},
    currentRoom: {
      roomName: {
        en: 'Select room',
        de: 'Raum auswählen',
        nl: 'Kies een kamer',
        it: 'Seleziona la camera'
      }
    },
    hoveredRoom: '',
    hoveredCategory: '',
    hoveredEvent: ''
  }

  globalizeLocalizer = localizer( globalize )
  
  Event = ( eventItem, selectedLanguageShortname ) => eventItem.event.title[selectedLanguageShortname] 

  customDayPropGetter = date => {
    let currentDate = new Date( Date.now() )
    if ( date.getDate() === currentDate.getDate() && date.getMonth() === currentDate.getMonth() && date.getYear() === currentDate.getYear() ){
      if ( date.getHours() < 9 ){
        return { style: {
          fontSize: "14px",
          fontWeight: "200",
          textTransform: "uppercase",
          textAlign: "center",
          background: '#333333',
          color: '#ffffff',
          border: "none",
          padding: "20px" }}
      }
      else { return { style: { backgroundColor: '#fcf8e3' } }}
    }
    else if ( date.getHours() < 7 ){
      return { style: {
        fontSize: "14px",
        fontWeight: "200",
        textTransform: "uppercase",
        textAlign: "center",
        background: '#333333',
        color: '#ffffff',
        border: "none",
        padding: "20px" }}
    }
    else return {}
  }

  customSlotPropGetter = date => {
    if ( date.getHours() === 8 && date.getMinutes() < 15 )
      return {
        style: {
          fontWeight: '600',
          backgroundColor: '#efefef',
        },
      }
    else return {}
  }

  customEventPropGetter = ( event, selectedLanguageShortname) => {
    return {
      style:{
        backgroundColor: event.color,
        border: "2px solid #d4d4d4",
        color: "#000000",
        opacity: _.isEmpty( this.state.hoveredEvent ) ? "1" : this.state.hoveredEvent === event.title[ selectedLanguageShortname ] ? "1" : "0.33" ,
        zIndex: _.isEmpty( this.state.hoveredEvent ) ? "100" : this.state.hoveredEvent === event.title[ selectedLanguageShortname ] ? "100" : "99" ,
        borderRadius: "3px",
        fontSize: ".85em",
        fontWeight: "normal"
      }
    }
  }

  formats = {
    dayFormat: (date, _, localizer) => dates.weekDays[date.getDay()][this.props.selectedLanguageShortname],
    dayHeaderFormat: (date, _, localizer) => dates.weekDaysAbbr[date.getDay()] + " " + moment(date).format('DD.MM.YYYY'),
    timeGutterFormat: (date, _, localizer) => dates.convertMeridiem(date),
    eventTimeRangeFormat: ({ start, end }, _, localizer) => dates.convertRange(start, end)
  }
  
  handleEventClick = toggleBool => this.setState({ isOpen: toggleBool }) 

  handleEventHover = ( eventWrapperProps, selectedLanguageShortname ) => this.setState({ hoveredEvent: eventWrapperProps.event.title[ selectedLanguageShortname ] })

  handleEventHoverOut = () => this.setState({ hoveredEvent: '' })
  
  updateEventWrapperProps = event => this.setState({ tempEvent: event }) 

  handleRoomsDropdownClick = () => this.setState({ isRoomDropdownOpen: !this.state.isRoomDropdownOpen }) 

  handleRoomHover = val => this.setState({ hoveredRoom: val })

  handleCategoryHover = val => this.setState({ hoveredCategory: val })

  handleRoomSelection = room => { 
    this.setState({ 
      currentRoom: room,
      isRoomDropdownOpen: !this.state.isRoomDropdownOpen
    }) 
  }

  render() { 
    let {
      selectedLanguageShortname
    } = this.props

    return ( 
      <div 
        className= "container" 
        style={{ 
          height: "100%", 
          width: "100%",
          padding: "200px 0" }}>
        <div 
          className="row"
          style={{
            paddingBottom: "20px",
            justifyContent: "flex-end" }}>
          <Button
            style={{ 
              color: "#dedbdb",
              fontSize: "18px",
              border: "none",
              background: "none" }}>
            { courseplanHeaderText.printCourseplan[selectedLanguageShortname] }</Button>  
        </div>
        <div className="row" style={{ paddingBottom: "60px" }}>
          <div className="col-md-6">
            <p 
              style={{ 
                color: "#141414",
                fontSize: "30px",
                margin: "0",
                paddingBottom: "20px" }}>
              { courseplanHeaderText.courseplanTitle[selectedLanguageShortname] }
              { this.state.currentRoom.roomName['en'] !== 'Select room' 
                && this.state.currentRoom.roomName['en'] !== 'All rooms' ? 
                this.state.currentRoom.roomName[selectedLanguageShortname] : '' }</p>
            <RoomsDropdown
              isRoomDropdownOpen={ this.state.isRoomDropdownOpen }
              handleRoomsDropdownClick={ () => this.handleRoomsDropdownClick() }
              handleRoomHover={ this.handleRoomHover }
              handleRoomSelection={ this.handleRoomSelection }
              currentRoom={ this.state.currentRoom }
              hoveredRoom={ this.state.hoveredRoom }
              rooms={ rooms }
              selectedLanguageShortname={ selectedLanguageShortname } 
            />
          </div>
          <div className="col-md-6">
            <div className="row" style={{ paddingRight: "12px" }}>
              <p style={{ 
                width: "100%",
                margin: "0 0 2px 0",
                fontSize: "16px",
                fontWeight: "bold",
                textAlign: "right"}}>
                { courseplanHeaderText.allCategories[selectedLanguageShortname] }</p>
                { 
                  categories.map((category) => {
                    return <p 
                      style={{ 
                        width: "100%",
                        margin: "0 0 2px 0",
                        fontWeight: "bold",
                        color: category.categoryName['en'] === this.state.hoveredCategory ? "#000000" : "#868686",
                        textAlign: "right" }}
                      onMouseEnter={ () => this.handleCategoryHover(category.categoryName['en']) }
                      onMouseLeave={ () => this.handleCategoryHover('') }>
                      { category.categoryName[selectedLanguageShortname] }
                      { <FaSquareFull style={{ 
                        border: "1px solid #000000",
                        marginLeft: "5px",
                        color: category.color  }}/> }</p>
                  }) 
                }
            </div>
          </div>
        </div>
        <p style={{ 
          margin: "0",
          paddingBottom: "5px",
          color: "#dedbdb",
          fontSize: "16px" }} >
          { courseplanHeaderText.upperLetter[selectedLanguageShortname] }</p>
        <Calendar
          events={ events }
          defaultView= { window.innerWidth > 1000 ? Views.WEEK : Views.DAY }
          views={[ Views.WEEK, Views.DAY ]}
          step={ 60 }
          timeslots={ 1 }
          formats= { this.formats }
          showMultiDayTimes
          min={ dates.add(dates.startOf(new Date(Date.now()), 'day'), +8, 'hours') }
          max={ dates.add(dates.endOf(new Date(Date.now()), 'day'), -2, 'hours') }
          tooltipAccessor={ null }
          defaultDate={ new Date(Date.now()) }
          dayPropGetter={ this.customDayPropGetter }
          slotPropGetter={ this.customSlotPropGetter }
          eventPropGetter= { event => this.customEventPropGetter( event, selectedLanguageShortname ) }
          onSelectEvent={ event =>{
            this.handleEventClick(true) 
            this.updateEventWrapperProps(event) }}
          components={{ 
            toolbar: val => <CourseplanToolbar { ...val } selectedLanguageShortname={ selectedLanguageShortname }/>,
            eventWrapper: eventWrapperProps => { 
              return <div 
                onMouseEnter={ () => this.handleEventHover(eventWrapperProps, selectedLanguageShortname) } 
                onMouseLeave={ this.handleEventHoverOut }>
                { eventWrapperProps.children }</div> 
            }, 
            event: event => this.Event( event, selectedLanguageShortname )
          }}
          localizer={ this.globalizeLocalizer }
        />
        { 
          !_.isEmpty( this.state.tempEvent ) && <CourseplanEventModal
            isEventModalOpen={ this.state.isOpen }
            event={ this.state.tempEvent }
            handleEventClick={ this.handleEventClick }
            selectedLanguageShortname={ selectedLanguageShortname } /> 
        }
        <p style={{ 
          width: "100%",
          margin: "0",
          paddingTop: "5px",
          textAlign: "right",
          color: "#dedbdb",
          fontSize: "16px" }} >
          { courseplanHeaderText.lowerLetter[selectedLanguageShortname] }</p>
      </div>
    );
  }
}
 

export default CourseplanCalendar