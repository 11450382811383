const now = new Date(Date.now())
const year = now.getFullYear()
const date = now.getDate()
const month = now.getMonth()

export default [
  {
    id: 0,
    img: 'testPic0',
    start: new Date(year, month, date, 9, 0, 0),
    end: new Date(year, month, date, 10, 0, 0),
    title: { 
      en: 'FUNCTIONAL training',
      de: 'FUNKTIONELLE Ausbildung',
      nl: 'FUNCTIONEELE INSTRUCTIES',
      it: 'Formazione FUNZIONALE'
    },
    desc: {
      en: 'MOVEMENT for more movement!',
      de: 'MOVEMENT für mehr Bewegung!',
      nl: 'MOVEMENT voor meer beweging!',
      it: 'MOVIMENTO per più movimento!'
    },
    color: "#a3ff64"
  },
  {
    id: 24,
    img: 'testPic24',
    start: new Date(year, month, date, 9, 0, 0),
    end: new Date(year, month, date, 10, 0, 0),
    title: {
      en: 'IRON TOTAL BODY',
      de: 'EISERNER GESAMTKÖRPER',
      nl: 'IJZEREN TOTAAL LICHAAM',
      it: 'CORPO TOTALE IN FERRO'
    },
    desc: {
      en: 'A full body workout with a barbell and the focus on strength endurance.',
      de: 'Ein Ganzkörpertraining mit einer Langhantel und dem Schwerpunkt Kraftausdauer.',
      nl: 'Een volledige lichaamstraining met een barbell en de focus op kracht en uithoudingsvermogen.',
      it: 'Un allenamento per tutto il corpo con un bilanciere e l\'attenzione alla resistenza della forza.'
    },
    trainer: 'Mirjam',
    color: "#f6ed66"
  },
  {
    id: 25,
    img: 'testPic25',
    start: new Date(year, month, date, 9, 15, 0),
    end: new Date(year, month, date, 10, 0, 0),
    title: {
      en: 'SPINE',
      de:'SPINN',
      nl: 'SPINE',
      it: 'SPINE'
    },
    desc: {
      en: 'An ideal balance to sedentary everyday work',
      de: 'Ein idealer Ausgleich zur sitzenden Alltagsarbeit',
      nl: 'Een ideale balans voor sedentair dagelijks werk',
      it: 'Un equilibrio ideale per il lavoro quotidiano sedentario'
    },
    color: "#f6ed66"
  },
  {
    id: 26,
    img: 'testPic26',
    start: new Date(year, month, date, 9, 0, 0),
    end: new Date(year, month, date, 10, 0, 0),
    title: { 
      en: 'FUNCTIONAL training',
      de: 'FUNKTIONELLE Ausbildung',
      nl: 'FUNCTIONEELE INSTRUCTIES',
      it: 'Formazione FUNZIONALE'
    },
    desc: {
      en: 'MOVEMENT for more movement!',
      de: 'MOVEMENT für mehr Bewegung!',
      nl: 'MOVEMENT voor meer beweging!',
      it: 'MOVIMENTO per più movimento!'
    },
    color: "#a3ff64"
  },
  {
    id: 27,
    img: 'testPic27',
    start: new Date(year, month, date, 10, 0, 0),
    end: new Date(year, month, date, 22, 0, 0),
    title: {
      en: 'OPEN GYM (new)',
      de: 'OPEN GYM (neu)',
      nl: 'OPEN GYM (nieuw)',
      it: 'PALESTRA APERTA (nuovo)'
    },
    desc: {
      en: 'OPEN GYM (new)',
      de: 'OPEN GYM (neu)',
      nl: 'OPEN GYM (nieuw)',
      it: 'PALESTRA APERTA (nuovo)'
    },
    trainer: 'FT Coaches',
    color: "#4e5453"
  },
  {
    id: 28,
    img: 'testPic28',
    start: new Date(year, month, date, 10, 15, 0),
    end: new Date(year, month, date, 11, 0, 0),
    title: {
      en: 'FUNCTIONAL WORKOUT',
      de: 'FUNKTIONELLES TRAINING',
      nl: 'FUNCTIONELE TRAINING',
      it: 'ALLENAMENTO FUNZIONALE'
    },
    desc: {
      en: 'A functional whole body workout',
      de: 'Ein funktionelles Ganzkörpertraining',
      nl: 'Een functionele training voor het hele lichaam',
      it: 'Un allenamento funzionale per tutto il corpo'
    },
    trainer: 'Mirjam',
    color: "#71f4fc"
  }
]
