import React, { Component } from 'react'
import { Button, Tooltip } from '@material-ui/core'
import { FaFileMedical, FaPencilAlt, FaTrashAlt } from 'react-icons/fa'
import _ from 'lodash'

import './index.scss'
import Lang from 'Lang/General'

class Header extends Component {
  render = () => {
    return (
      <div className="header">
        <div className="container" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <img 
            src={
              !_.isEmpty( this.props.data.platformConfigReducer.platformConfig ) 
              && _.find( this.props.data.platformConfigReducer.platformConfig, { key: 'PLATFORM_ICON' } ) 
                ? _.find( this.props.data.platformConfigReducer.platformConfig, { key: 'PLATFORM_ICON' } ).value
                : ''
            }
            style={{ width: 100, marginRight: "15px" }}/>
          <input
            className="form-control"
            value={ this.props.selectedTemplateName }
            style={{
              width: '150px',
              height: '40px',
              display: 'inline-block',
              marginRight: '10px'
            }}
            disabled={ true } />
          <select
            className="form-control"
            style={{
              width: '200px',
              height: '40px',
              display: 'inline-block',
              marginRight: '10px'
            }}
            value={ this.props.selectedPageID }
            onChange={ e => {
              this.props.updateSelectedPageID( e.target.value )
              this.props.getSelectedPage( this.props.template_id, e.target.value )
            }}>
            {
              this.props.pages && this.props.pages.length > 0 && this.props.pages.map( item => {
                return (
                  <option key={ item.id } value={ item.id }>{ item.name }</option>
                )
              })
            }
          </select>
          <input
            className="form-control"
            value={ this.props.selectedPage && this.props.selectedPage.url }
            style={{
              width: '150px',
              height: '40px',
              display: 'inline-block',
              marginRight: '10px'
            }}
            disabled={ true } />
          <div className="btn-add-page">
            <Tooltip title={ Lang[ 'EDIT_PAGE' ][ this.props.lang ] }>
              <Button
                classes={{ root: 'btn btn-secondary' }}
                variant={ 'contained' }
                onClick={() => this.props.onChangeShowPageEdit( true )}>
                <FaPencilAlt className="icon" />
              </Button>
            </Tooltip>
          </div>
          <div className="btn-add-page">
            <Tooltip title={ Lang[ 'ADD_PAGE' ][ this.props.lang ] }>
              <Button
                classes={{ root: 'btn btn-primary' }}
                variant={ 'contained' }
                onClick={() => this.props.onChangeShowPageCreator( true )}>
                <FaFileMedical className="icon" />
              </Button>
            </Tooltip>
          </div>
          <div className="btn-add-page">
            <Tooltip title={ Lang[ 'REMOVE_PAGE' ][ this.props.lang ] }>
              <Button
                classes={{ root: 'btn btn-danger' }}
                variant={ 'contained' }
                disabled={ this.props.selectedPage.name === "Courseplan" ? true: false }
                onClick={() => this.props.onChangeShowConfirmRemovePage( true )}>
                <FaTrashAlt className="icon" />
              </Button>
            </Tooltip>
          </div>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 'auto', height: '40px' }}
            onClick={() => {
              this.props.updateSelectedPage( this.props.template_id, this.props.selectedPage.id, {
                content: this.props.selectedPage.content
              }, "" )
            }}>{ Lang[ 'SAVE_PAGE' ][ this.props.lang ] }</button>
          <button
            className="btn btn-secondary"
            style={{ marginLeft: '10px', height: '40px' }}
            onClick={() => {}}>{ Lang[ 'PUBLISH_PAGE' ][ this.props.lang ] }</button>
        </div>
      </div>
    )
  }
}

export default Header
