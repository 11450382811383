import React, { Component } from 'react'
import Radium from 'proofpoint-radium'

import { Get, Put, Post, Delete } from 'utils/axios-template'
import { production_url } from '../../config'

import Lang from 'Lang/General'

const SectionHOC = ( WrappedComponent ) => {
  class SectionWrappedComponent extends Component {
    state = {
      selectedSection: {},
      loading: false,
      updateLoading: false,
      showStatusModal: false,
      statusModalType: '',
      onClickStatusModalButton: () => {},
      statusModalMessage: ''
    }

    load = param => this.setState({ loading: param })
    updateLoad = param => this.setState({ updateLoading: param })

    requestError = error => this.setState({
      showStatusModal: true,
      statusModalType: 'warning-custom',
      onClickStatusModalButton: () => this.setState({ showStatusModal: false }),
      statusModalMessage: error
    })
    requestSuccess = success => this.setState({
      showStatusModal: true,
      statusModalType: 'positive',
      onClickStatusModalButton: () => this.setState({ showStatusModal: false }),
      statusModalMessage: success
    })

    getSelectedSection = id => Get(
      `${ production_url }/sections/${ id }`,
      this.getSelectedSectionSuccess,
      this.getSelectedSectionError,
      this.load
    )
    getSelectedSectionSuccess = payload => {
      this.setState({ selectedSection: payload.content })
    }
    getSelectedSectionError = error => this.requestError( error )

    updateSelectedSection = data => Put(
      `${ production_url }/sections/${ data.id }`,
      data,
      () => this.updateSelectedSectionSuccess( data.id ),
      this.updateSelectedSectionError,
      this.updateLoad
    )
    updateSelectedSectionSuccess = id => {
      this.requestSuccess( Lang[ 'HEADER_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      window.location.reload()
      // this.getSelectedSection( id )
      // this.props.getSelectedPage( this.props.template_id, this.props.selectedPage.id ) 
    }
    updateSelectedSectionError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadSection={ this.state.loading }
          onLoadSectionUpdate={ this.state.updateLoading }
          getSelectedSection={ this.getSelectedSection }
          selectedSection={ this.state.selectedSection } 
          updateSelectedSection={ this.updateSelectedSection }/>
      )
    }
  }
  return Radium( SectionWrappedComponent )
}

export default SectionHOC