import Axios from 'axios'
import {
  GET_PROFILE,
  CLEAR_PROFILE
} from './type'
import {
  beginAjaxCall,
  ajaxCallSuccess,
  ajaxCallError
} from './ajax'
import { getItem, clearItem } from 'utils/tokenStore'
import getDomainURL from 'utils/api'

export const outdatedToken = ( error, history ) => {
  if( error === 'Token is expired' ) {
    clearItem( 'PROOFPOINT_ARK_MANAGEMENT' )
    history.push( '/' )
  }
}

export const getUserProfile = () => {
  Axios.defaults.headers = {
    common: {
      Authorization: `Bearer ${ getItem( 'PROOFPOINT_ARK_MANAGEMENT' ) }`
    }
  }
  return dispatch => {
    dispatch( beginAjaxCall() )
    Axios.get(
      `${ getDomainURL() }/api/v1/profiles/me`
    ).then( response => {
      dispatch( getProfileSuccess( response.data ) )
      dispatch( ajaxCallSuccess() )
    }).catch( error => {
      dispatch( ajaxCallError( error ) )
    })
  }
}

const getProfileSuccess = payload => {
  return {
    type: GET_PROFILE,
    payload
  }
}

export const updateProfile = data => {
  Axios.defaults.headers = {
    common: {
      Authorization: `Bearer ${ getItem( 'PROOFPOINT_ARK_MANAGEMENT' ) }`
    }
  }
  return dispatch => {
    dispatch( beginAjaxCall() )
    Axios.put(
      `${ getDomainURL() }/api/v1/profiles/me`,
      data
    ).then( response => {
      dispatch( ajaxCallSuccess( 'update successfully') )
      dispatch( getUserProfile() )
    }).catch( error => {
      dispatch( ajaxCallError( error ) )
    })
  }
}

export const clearProfile = () => ({
  type: CLEAR_PROFILE
})