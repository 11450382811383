import React, { useState, useEffect } from 'react'
import {
  Button,
  Form, Label, Input, FormGroup,
  Card, CardBody, CardFooter
} from 'reactstrap'
import ReactTable from 'react-table'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginImagePreview from "filepond-plugin-image-preview"  
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import DeleteConfirmation from 'components/Modal/delete'  
import _ from 'lodash'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import FileHOC from './actions/files'
import Lang from 'Lang/General'

registerPlugin( FilePondPluginImagePreview, FilePondPluginFileValidateType )

const Files = ({
  handleSecurityModulesCreate,
  handleSecurityModulesDelete,
  showDeleteFileModal,
  showKnowledgeFilesId,
  lang,
  knowledgeFiles = [],
  onLoadFile,
  
  createFileValue,
  createFileTitle,

  removeKnowledgeFiles,
  createKnowledgeFile,
  onChangeFileHOC,
  getKnowledgeFiles
}) => {

  const [ tempDeleteFile, setDeleteFile ] = useState( null )

  useEffect(() => {
    getKnowledgeFiles( showKnowledgeFilesId )
  }, [])

  return (
    <>
      {
        handleSecurityModulesCreate && (
          <Card>
            <CardBody>
              <Form>
                <FormGroup>
                  <Label>
                    { Lang[ 'UPLOAD_PDF' ][ lang ] }
                    <span className="text-danger">{ ` *`}</span>
                  </Label>
                  <FilePond
                    files={ createFileValue }
                    allowMultiple={ false }
                    allowFileTypeValidation={ true }
                    allowImagePreview={ true }
                    onremovefile={ () => onChangeFileHOC( 'createFileValue', null )}
                    onupdatefiles={ fileItems => {
                      fileItems.length > 0 && fileItems[ 0 ].file && 
                      onChangeFileHOC( 'createFileValue', fileItems )
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>{ Lang[ 'TITLE' ][ lang ] }</Label>
                  <Input
                    type={ 'text' }
                    value={ createFileTitle }
                    onChange={ e => onChangeFileHOC( 'createFileTitle', e.target.value )}
                  />
                </FormGroup>
              </Form>
            </CardBody>
            <CardFooter>
              <Button 
                color={ 'primary' } 
                disabled={ !createFileValue }
                onClick={ () => { 
                  const reader = new FileReader()
                  reader.onload = e => {
                    let temp =  {
                      raw: e.target.result,
                      filename: createFileValue[0].file.name
                    }
                    createKnowledgeFile( 
                      showKnowledgeFilesId, 
                      { 
                        file: temp,
                        title: createFileTitle || createFileValue[0].file.name.split( '.' )[0]
                      }
                    )
                  }
                  reader.readAsDataURL( createFileValue[0].file )
                }}
              >
                {  Lang[ 'CREATE' ][ lang ] }
                </Button>
            </CardFooter>
          </Card>
        )
      }
      <hr />
      <ReactTable
        data={ knowledgeFiles }
        columns={[
          {
            Header: Lang[ 'TITLE' ][ lang ],
            accessor: 'id',
            Cell: row => row.original.title
          },
          {
            Header: Lang[ 'ACTIONS' ][ lang ],
            Cell: row => (
              <>
                {
                  row.original.file.url && <Button
                    className='mr-2'
                    color='primary'
                    onClick={ () => window.open( row.original.file.url ) }>
                    { Lang[ 'DOWNLOAD_PDF' ][ lang ] }
                  </Button>
                }
                {
                  handleSecurityModulesDelete && (
                    <Button
                      color='danger'
                      onClick={ () => {
                        onChangeFileHOC( 'showDeleteFileModal', true )
                        setDeleteFile( row.original.id )
                      } }>
                      { Lang[ 'DELETE' ][ lang ] }
                    </Button>
                  )
                }
              </>
            )
          }
        ]}
        pageSize={ 5 }
        showPagination={ true } 
      />
      <DeleteConfirmation
        handleModalClose={ () => onChangeFileHOC( 'showDeleteFileModal', false ) }
        deleteOpen={ showDeleteFileModal }
        confirmAction={ () => {
          removeKnowledgeFiles( showKnowledgeFilesId, tempDeleteFile )
        }}
        content={ Lang[ 'DELETE_CONFIRMATION_FILE' ][ lang ] }
        lang={ lang } />
      { onLoadFile && <LoadingOverlay/> }
    </>
  )
}

export default FileHOC( Files );
