import React from 'react'
import ReactHTMLParser from 'react-html-parser'
import { FormGroup } from 'reactstrap'

const TextComponent = ({
  content,
  selectedLanguageShortname 
}) => {
  return (
    <FormGroup style={{ padding: content.padding, marginBottom: "0" }} >
      <p 
        id={ content.id }
        style={{
          ...content.style,
          padding: "0",
          fontSize: content.fontSize
        }}>
        { ReactHTMLParser( content.label[ selectedLanguageShortname  ] ) }
      </p>
    </FormGroup>
  )
}

export default TextComponent