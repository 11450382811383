import React, { useState, useEffect } from "react"
import { FormGroup, Label } from "reactstrap"
import { FilePond, registerPlugin } from "react-filepond"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size"
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
import _ from "lodash"
import ReactHTMLParser from "react-html-parser"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
import "filepond/dist/filepond.min.css"

registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
)

const InputFile = ({
  content,
  formValues,
  mode,
  selectedLanguageShortname,
}) => {
  const [ labelStr, setLabelStr ] = useState("")
  useEffect(() => {
    let labelText =
      content.label[
        selectedLanguageShortname ? selectedLanguageShortname : "en"
      ]

    if ( labelText.length !== 0 ) {
      if ( typeof ReactHTMLParser( labelText )[0] === "object" ) {
        let labelTag = ReactHTMLParser( labelText )[0].type
        let closingTag = "</" + labelTag + ">"
        labelText = labelText.slice( 0, -1 * closingTag.length )
        if ( formValues[ content.dataMapIndex ][ "isRequired" ]){
          labelText = labelText + "<span style='color: #ff0000;'> * </span>"
        }
        labelText = labelText + closingTag
      } else {
        labelText = labelText + "<span style='color: #ff0000;'> * </span>"
      }
    }
    setLabelStr( labelText )
  }, [ content, formValues, selectedLanguageShortname ])

  return (
    <FormGroup
      tag="fieldset"
      id={ content.id }
      style={{ padding: content.padding, marginBottom: 0 }}
    >
      <Label style={{ fontSize: content.fontSize }}>
        { ReactHTMLParser( labelStr )}
      </Label>
      { mode === "preview" || mode === "section-display-edit" ? (
        <div
          className="filepond--root filepond--hopper"
          data-style-button-remove-item-position="left"
          data-style-button-process-item-position="right"
          data-style-load-indicator-position="right"
          data-style-progress-indicator-position="right"
          data-style-button-remove-item-align="false"
          style={{ height: "67px" }}
        >
          <input
            disabled
            className="filepond--browser"
            type="file"
            id="filepond--browser-860x8cmnx"
            name="filepond"
            aria-controls="filepond--assistant-860x8cmnx"
            aria-labelledby="filepond--drop-label-860x8cmnx"
            accept="application/pdf,image/png,image/jpeg,image/jpg"
            multiple=""
          />
          <div
            className="filepond--drop-label"
            style={{ transform: "translate3d(0px, 0px, 0px)", opacity: "1" }}
          >
            <label
              for="filepond--browser-860x8cmnx"
              id="filepond--drop-label-860x8cmnx"
              aria-hidden="true"
            >
              Drag &amp; Drop your files or{" "}
              <span className="filepond--label-action" tabindex="0">
                Browse
              </span>
            </label>
          </div>
          <div
            className="filepond--list-scroller"
            style={{ transform: "translate3d(0px, 53px, 0px)" }}
          >
            <ul className="filepond--list" role="list"></ul>
          </div>
          <div
            className="filepond--panel filepond--panel-root"
            data-scalable="true"
          >
            <div className="filepond--panel-top filepond--panel-root"></div>
            <div
              className="filepond--panel-center filepond--panel-root"
              style={{
                transform: "translate3d(0px, 7px, 0px) scale3d(1, 0.53, 1)",
              }}
            ></div>
            <div
              className="filepond--panel-bottom filepond--panel-root"
              style={{ transform: "translate3d(0px, 60px, 0px)" }}
            ></div>
          </div>
          <span
            className="filepond--assistant"
            id="filepond--assistant-860x8cmnx"
            role="status"
            aria-live="polite"
            aria-relevant="additions"
          ></span>
          <fieldset className="filepond--data"></fieldset>
          <div className="filepond--drip"></div>
        </div>
      ) : (
        <FilePond
          allowMultiple={ true }
          allowFileTypeValidation={ true }
          allowImagePreview={ true }
          acceptedFileTypes={[
            "application/pdf",
            "image/png",
            "image/jpeg",
            "image/jpg",
          ]}
          allowFileSizeValidation={ true }
          maxFileSize={ "5MB" }
        />
      )}
    </FormGroup>
  )
}

export default InputFile
