import React, { Component } from 'react'
import { compose } from 'redux'
import{
  Modal, ModalBody, ModalFooter, ModalHeader, Button,
  Container, Row, Col, FormGroup, Input, Label, Card,
  CardHeader, CardBody, CardFooter, UncontrolledTooltip
} from 'reactstrap'
import _ from 'lodash'

import Wrapper from '../../Wrapper'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PageTitle from 'components/PageTitle'
import ARKTable from 'components/Table'
import CampaignTypeForm from './components/campaignTypeForm'
import DeleteConfirmationModal from 'components/Modal/delete'

import CampaignTypeHOC from './actions/type'
import Lang from 'Lang/General'

import { convertObjToBase64 } from 'utils/objToBase64'

class CampaignTypeManagement extends Component {
  state = {
    status: ''
  }

  componentDidMount = () => {
    let tmp = {
      page: 1,
      is_paginated: true,
      filter: {
        $or: [
          { status: 'ACTIVE' },
          { status: 'INACTIVE' }
        ]
      }
    }
    this.props.getAllCampaignTypes( convertObjToBase64( tmp ) )
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'OLS' })
      return tmpModules.is_create
    }
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'OLS' })
      return tmpModules.is_update
    }
  }

  handleSecurityModulesDelete = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'OLS' })
      return tmpModules.is_delete
    }
  }

  renderNewCampaignTypeModal = () => {
    return (
      <Modal
        isOpen={ this.props.typeModal }
        size="xl"
        toggle={ () => this.props.onChangeCampaignTypeHOC( 'typeModal', false ) }>
        <ModalHeader toggle={ () => this.props.onChangeCampaignTypeHOC( 'typeModal', false ) }>{ Lang[ 'CREATE_CAMPAIGN_TYPE' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <CampaignTypeForm
            onChangeCampaignTypeData={ this.props.onChangeCampaignTypeData }
            data={ this.props.newCampaignType }
            statusValues={ this.props.data.dictionaryReducer.dictionary.status }
            errorMessage={ this.props.errorMessage } />
          <Button
            className="mb-2 mr-2"
            color="info" style={{ float: 'right' }}
            onClick={ () => this.props.postCampaignType( this.props.newCampaignType )}>{ Lang[ 'SUBMIT' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.onChangeCampaignTypeHOC( 'typeModal', false ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderEditCampaignTypeModal = () => {
    return(
      <Modal
        isOpen={ this.props.typeModal }
        size="xl"
        toggle={ () => this.props.onChangeCampaignTypeHOC( 'typeModal', false ) }>
        <ModalHeader toggle={ () => this.props.onChangeCampaignTypeHOC( 'typeModal', false ) }>{ Lang[ 'UPDATE_CAMPAIGN_TYPE' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <CampaignTypeForm
            onChangeCampaignTypeData={ this.props.onChangeCampaignTypeData }
            data={ this.props.selectedCampaignType }
            statusValues={ this.props.data.dictionaryReducer.dictionary.status }
            errorMessage={ this.props.errorMessage } />
          <Button
            className="mb-2 mr-2"
            color="info"
            style={{ float: 'right' }}
            onClick={ () => this.props.updateCampaignType( this.props.selectedCampaignType.id, this.props.selectedCampaignType, {
              page: 1,
              is_paginated: true,
              filter: {
                $or: [
                  { status: 'ACTIVE' },
                  { status: 'INACTIVE' }
                ]
              }
            } ) }>{ Lang[ 'SUBMIT' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.onChangeCampaignTypeHOC( 'typeModal', false ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderArchivedcampaignTypeModal = () => {
    return (
      <Modal
        toggle={ () => {
          Promise.all([
            this.props.onChangeCampaignTypeHOC( 'archivedModal', false )
          ]).then( () => this.props.onChangeCampaignTypeHOC( 'archivedSearchTitle', '' ) )
        }}
        isOpen={ this.props.archivedModal }
        size="xl">
        <ModalHeader
          toggle={ () => {
            Promise.all([
              this.props.onChangeCampaignTypeHOC( 'archivedModal', false )
            ]).then( () => this.props.onChangeCampaignTypeHOC( 'archivedSearchTitle', '' ) )
          }}>{ Lang[ 'ARCHIVED_CAMPAIGN_TYPE' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <Container style={{ margin: 0 }}>
            <Row>
              <Col md={ 6 }>
                <FormGroup>
                  <Label for="campaign-type" sm={ 2 }>{ Lang[ 'TITLE' ][ this.props.data.languageReducer.lang ] }</Label>
                  <Input
                    name="campaign-type" id="campaign-type"
                    placeholder={ Lang[ 'SEARCH_BY_CAMPAIGN_TITLE' ][ this.props.data.languageReducer.lang ] }
                    value={ this.props.archivedSearchTitle }
                    onChange={ e => this.props.onChangeCampaignTypeHOC( 'archivedSearchTitle', e.target.value ) }/>
                </FormGroup>
              </Col>
              <Col md={ 6 } className="d-flex pt-1" style={{ alignItems: 'flex-end' }}>
                <FormGroup>
                  <Button
                    color="primary"
                    onClick={ () => {
                      let tmp = {
                        page: 1,
                        is_paginated: true,
                        filter: {
                          title: {
                            $like: `%${ this.props.archivedSearchTitle }%`
                          },
                          status: 'ARCHIVED'
                        }
                      }
                      return this.props.getArchivedCampaignTypes( convertObjToBase64( tmp ) )
                    }}>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }</Button>
                </FormGroup>
                <FormGroup>
                  <Button
                    color="danger"
                    onClick={ () => {
                      let tmp = {
                        page: 1,
                        is_paginated: true,
                        filter: {
                          status: 'ARCHIVED'
                        }
                      }
                      return this.props.getArchivedCampaignTypes( convertObjToBase64( tmp ) )
                    }}>{ Lang[ 'RESET' ][ this.props.data.languageReducer.lang ] }</Button>
                </FormGroup>
              </Col>
            </Row>
            <ARKTable
              data={ this.props.archivedCampaignTypes.data }
              columns={[
                {
                  Header: Lang[ 'TITLE' ][ this.props.data.languageReducer.lang ],
                  accessor: "title"
                },
                {
                  Header: Lang[ 'DESCRIPTION' ][ this.props.data.languageReducer.lang ],
                  accessor: "description"
                },
                {
                  Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
                  accessor: "id",
                  show: this.handleSecurityModulesDelete() || this.handleSecurityModulesUpdate(),
                  Cell: ({value}) => (
                    <div>
                      {
                        this.handleSecurityModulesUpdate() && (
                          <>
                            <Button
                              id={ `Campaign-types-${ value }-update-campaign-type` }
                              className="mb-2 mr-2 btn-icon btn-icon-only"
                              color="primary"
                              onClick={ () => {
                                this.props.getSelectedCampaignType( value )
                                this.props.onChangeCampaignTypeHOC( 'archivedEditModal', true )
                              }}>
                                <i className="pe-7s-note"> </i>
                            </Button>
                            <UncontrolledTooltip
                              target={ `Campaign-types-${ value }-update-campaign-type` }
                              placement="top">{ Lang[ 'UPDATE_CAMPAIGN_TYPE_SEN_CASE' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                          </>
                        )
                      }
                      {
                        this.handleSecurityModulesDelete() && (
                          <>
                            <Button
                              id={ `Campaign-types-${ value }-delete-campaign-type` }
                              className="mb-2 mr-2 btn-icon btn-icon-only"
                              color="danger"
                              onClick={ () => {
                                this.props.getSelectedCampaignType( value )
                                this.props.onChangeCampaignTypeHOC( 'deleteModal', true )
                              }}>
                                <i className="pe-7s-trash"> </i>
                            </Button>
                            <UncontrolledTooltip
                              target={ `Campaign-types-${ value }-delete-campaign-type` }
                              placement="top">{ Lang[ 'DELETE_CAMPAIGN_TYPE' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                          </>
                        )
                      }
                    </div>
                  )
                }
              ]}
              page={ this.props.archivedCampaignTypes.meta ? this.props.archivedCampaignTypes.meta.current_page : 1 }
              totalPages={ this.props.archivedTotalPages }
              onChangePage={ pg => {
                let tmp = {}
                if( this.props.searchTitle !== '' ){
                  tmp = {
                    page: pg,
                    is_paginated: true,
                    filter: {
                      title: {
                        $like: `%${ this.props.archivedSearchTitle }%`
                      },
                      status: 'ARCHIVED'
                    }
                  }
                } else{
                  tmp = {
                    page: pg,
                    is_paginated: true,
                    filter: {
                      status: 'ARCHIVED'
                    }
                  }
                }
                this.props.getArchivedCampaignTypes( convertObjToBase64( tmp ) )
              }}
              showPagination={ false } />
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            className="mb-2 mr-2"
            color="primary"
            onClick={ () => {
              Promise.all([
                this.props.onChangeCampaignTypeHOC( 'archivedModal', false )
              ]).then( () => this.props.onChangeCampaignTypeHOC( 'archivedSearchTitle', '' ) )
            }}>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderArchivedEditCampaignTypeModal = () => {
    return(
      <Modal
        isOpen={ this.props.archivedEditModal }
        size="xl"
        toggle={ () => this.props.onChangeCampaignTypeHOC( 'archivedEditModal', false ) }>
        <ModalHeader toggle={ () => this.props.onChangeCampaignTypeHOC( 'archivedEditModal', false ) }>{ Lang[ 'UPDATE_ARCHIVED_CAMPAIGN_TYPE' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <CampaignTypeForm
            onChangeCampaignTypeData={ this.props.onChangeCampaignTypeData }
            data={ this.props.selectedCampaignType }
            statusValues={ this.props.data.dictionaryReducer.dictionary.status }
            errorMessage={ this.props.errorMessage } />
          <Button
            className="mb-2 mr-2"
            color="info"
            style={{ float: 'right' }}
            onClick={ () => this.props.updateArchivedCampaignType( this.props.selectedCampaignType.id, this.props.selectedCampaignType, {
              page: 1,
              is_paginated: true,
              filter: {
                status: 'ARCHIVED'
              }
            } ) } >{ Lang[ 'SUBMIT' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.onChangeCampaignTypeHOC( 'archivedEditModal', false ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderDeleteConfirmationModal = () => {
    return(
      <DeleteConfirmationModal
        deleteOpen={ this.props.deleteModal }
        handleModalClose={ () => this.props.onChangeCampaignTypeHOC( 'deleteModal', false ) }
        confirmAction={ () => {
          if( this.props.selectedCampaignType.id ){
            this.props.removeCampaignType( this.props.selectedCampaignType.id )
          }
          this.props.onChangeCampaignTypeHOC( 'deleteModal', false )
        }}
        content={ Lang[ 'DEL_CONFIRMATION' ][ this.props.data.languageReducer.lang ] }
        lang={ this.props.data.languageReducer.lang } />
    )
  }

  render = () => {
    return(
      <>
        <PageTitle
          heading={ Lang[ 'CAMPAIGN_TYPE' ][ this.props.data.languageReducer.lang ] }
          icon="pe-7s-ticket icon-gradient bg-happy-fisher"
          buttons={[
            {
              buttonText: Lang[ 'ARCHIVED_CAMPAIGN_TYPE' ][ this.props.data.languageReducer.lang ],
              onClick: () => {
                let tmp = {
                  page: 1,
                  is_paginated: true,
                  filter: {
                    status: 'ARCHIVED'
                  }
                }
                this.props.onChangeCampaignTypeHOC( 'archivedModal', true )
                this.props.getArchivedCampaignTypes( convertObjToBase64( tmp ) )
              },
              display: true
            },
            {
              buttonText: Lang[ 'CREATE_CAMPAIGN_TYPE' ][ this.props.data.languageReducer.lang ],
              onClick: () => this.props.handleTypeModal( true, 'create' ),
              display: this.handleSecurityModulesCreate()
            }
          ]}/>
        <Card className="mb-3">
          <CardHeader>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }</CardHeader>
          <CardBody>
            <FormGroup>
              <Label>{ Lang[ 'TITLE' ][ this.props.data.languageReducer.lang ] }</Label>
              <Input
                name="campaign-type" id="campaign-type"
                placeholder={ Lang[ 'SEARCH_BY_CAMPAIGN_TITLE' ][ this.props.data.languageReducer.lang ] }
                value={ this.props.searchTitle }
                onChange={ e => this.props.onChangeCampaignTypeHOC( 'searchTitle', e.target.value ) }/>
            </FormGroup>
          </CardBody>
          <CardFooter>
            <Button
              color="primary"
              className="ml-auto"
              onClick={ () => {
                let tmp = {
                  page: 1,
                  is_paginated: true,
                  filter: {
                    title: {
                      $like: `%${ this.props.searchTitle }%`
                    },
                    $or: [
                      { status: 'ACTIVE' },
                      { status: 'INACTIVE' }
                    ]
                  }
                }
                this.props.getAllCampaignTypes( convertObjToBase64( tmp ) )
              }}>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }</Button>
            <Button
              color="danger"
              onClick={ () => {
                let tmp = {
                  page: 1,
                  is_paginated: true,
                  filter: {
                    $or: [
                      { status: 'ACTIVE' },
                      { status: 'INACTIVE' }
                    ]
                  }
                }
                this.props.onChangeCampaignTypeHOC( 'searchTitle', '' )
                return this.props.getAllCampaignTypes( convertObjToBase64( tmp ) )
              }}>{ Lang[ 'RESET' ][ this.props.data.languageReducer.lang ] }</Button>
          </CardFooter>
        </Card>
        <ARKTable
          data={ this.props.campaignTypes.data }
          columns={[
            {
              Header: Lang[ 'TITLE' ][ this.props.data.languageReducer.lang ],
              accessor: "title"
            },
            {
              Header: Lang[ 'DESCRIPTION' ][ this.props.data.languageReducer.lang ],
              accessor: "description"
            },
            {
              Header: Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ],
              accessor: 'status'
            },
            {
              Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
              accessor: "id",
              show: this.handleSecurityModulesDelete() || this.handleSecurityModulesUpdate(),
              Cell: ({value}) => (
                <div>
                  {
                     this.handleSecurityModulesUpdate() && (
                       <>
                        <Button
                          id={ `Campaign-types-${ value }-update-campaign-type` }
                          className="mb-2 mr-2 btn-icon btn-icon-only"
                          color="primary"
                          onClick={ () => {
                            this.props.getSelectedCampaignType( value )
                            this.props.handleTypeModal( true, 'edit' )
                          }}>
                            <i className="pe-7s-note"> </i>
                        </Button>
                        <UncontrolledTooltip
                          target={ `Campaign-types-${ value }-update-campaign-type` }
                          placement="top">{ Lang[ 'UPDATE_CAMPAIGN_TYPE_SEN_CASE' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                       </>
                     )
                  }
                  {
                    this.handleSecurityModulesDelete() && (
                      <>
                        <Button
                          id={ `Campaign-types-${ value }-delete-campaign-type` }
                          className="mb-2 mr-2 btn-icon btn-icon-only"
                          color="danger"
                          onClick={ () => {
                            this.props.getSelectedCampaignType( value )
                            this.props.onChangeCampaignTypeHOC( 'deleteModal', true )
                          }}>
                            <i className="pe-7s-trash"> </i>
                        </Button>
                        <UncontrolledTooltip
                          target={ `Campaign-types-${ value }-delete-campaign-type` }
                          placement="top">{ Lang[ 'DELETE_CAMPAIGN_TYPE' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                      </>
                    )
                  }
                </div>
              )
            }
          ]}
          page={ this.props.campaignTypes.meta ? this.props.campaignTypes.meta.current_page : 1 }
          totalPages={ this.props.totalPages }
          onChangePage={ pg => {
            let tmp = {}
            if( this.props.searchTitle !== '' ){
              tmp = {
                page: pg,
                is_paginated: true,
                filter: {
                  title: {
                    $like: `%${ this.props.searchTitle }%`
                  },
                  $or: [
                    { status: 'ACTIVE' },
                    { status: 'INACTIVE' }
                  ]
                }
              }
            } else{
              tmp = {
                page: pg,
                is_paginated: true,
                filter: {
                  $or: [
                    { status: 'ACTIVE' },
                    { status: 'INACTIVE' }
                  ]
                }
              }
            }
            this.props.getAllCampaignTypes( convertObjToBase64( tmp ) )
          }}
          showPagination={ false } />
        { this.props.typeMode === 'create' && this.renderNewCampaignTypeModal() }
        { this.props.typeMode === 'edit' && this.renderEditCampaignTypeModal() }
        { this.renderArchivedcampaignTypeModal() }
        { this.props.archivedEditModal && this.renderArchivedEditCampaignTypeModal() }
        { this.props.deleteModal && this.renderDeleteConfirmationModal() }
        { ( this.props.data.ajaxReducer.ajaxCallProgress > 0 || this.props.onLoadCampaignType ) && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose(
  CampaignTypeHOC
)( CampaignTypeManagement )
