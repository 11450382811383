import React, { useState, useEffect } from "react"
import ReactSelect from "react-select"
import uniqid from "uniqid"
import { 
  Modal, ModalHeader, ModalBody, ModalFooter, 
  Form, FormGroup, FormText, 
  Button, Label, Input, CustomInput
} from "reactstrap"
import _ from "lodash"

import MultilingualInput from "components/Input/MultiLang"
import Lang from "Lang/General"

const CreateModal = props => {
  const [ errorMessage, updateErrorMessage ] = useState({})
  const [ mode, updateMode ] = useState( '' )

  useEffect(() => {
    if( !props.showCreateModal ) {
      updateMode( '' )
    } else {
      props.getAllLandingPage()
    }
  }, [ props.showCreateModal ])

  const createPage = async () => {
    let tmpError = {}
    let tmp = _.cloneDeep( props.newPage )
    let keys = [ "url" ]
    mode === 'center-group' ? keys.push( 'center_group_ids' ) : keys.push( 'center_ids' )
    keys.map( ( key ) => {
      if ( _.isEmpty( props.newPage[ key ] ) ) {
        tmpError[ key ] = Lang[ "FIELD_IS_REQUIRED" ][ props.data.languageReducer.lang ]
      }
    })
    if ( Object.keys( props.newPage.name ).every( key => _.isEmpty( props.newPage.name[ key ] ))) {
      tmpError.name = Lang[ "FIELD_IS_REQUIRED" ][ props.data.languageReducer.lang ]
    }
    if ( mode === '' ) {
      tmpError.mode = Lang[ "FIELD_IS_REQUIRED" ][ props.data.languageReducer.lang ]
    }
    updateErrorMessage( tmpError )
    
    if ( _.isEmpty( tmpError ) ) {
      tmp.url = "/" + tmp.url
      if ( _.isEmpty( tmp.content ) ) {
        tmp.content = {
          id: uniqid(),
          type: "div",
          style: {
            width: "100%",
          },
          children: [
            {
              id: uniqid(),
              type: "div",
              style: {
                width: "100vw",
                marginTop: "0",
              },
              variant: "sections-container",
              children: [],
            }
          ],
        }
      }
      if( mode === 'center-group' ) {
        tmp.center_group_ids = _.map( props.newPage.center_group_ids, 'id' )
        tmp.countries = _.map( props.newPage.countries, 'value' )
        tmp.center_ids = []
      } else {
        tmp.center_ids = _.map( props.newPage.center_ids, 'id' )
        tmp.center_group_ids = []
        tmp.countries = []
      }
      props.createLandingPage( tmp )
    }
  }

  const updatePageInfo = ( key, value ) => {
    let tmp = _.cloneDeep( props.newPage )
    tmp[ key ] = value
    props.onChangeLandingPageHOC( "newPage", tmp )
  }

  return (
    <>
      <Modal size="md" isOpen={ props.showCreateModal }>
        <ModalHeader toggle={ () => props.onChangeLandingPageHOC( "showCreateModal", !props.showCreateModal )}>
          { Lang[ "ADD" ][ props.data.languageReducer.lang ] }
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <MultilingualInput
                label={ 
                  `${ Lang[ "PAGE_TITLE" ][ props.data.languageReducer.lang ] } (${ Lang[ "MAXIMUM" ][ props.data.languageReducer.lang ] } 55 ${ Lang[ "CHARACTERS" ][ props.data.languageReducer.lang ] })` 
                }
                selectedLanguage={ props.data.languageReducer.lang }
                value={ props.newPage.name }
                onChange={ val => updatePageInfo( "name", val ) }
              />
              <FormText color="danger">{ errorMessage.name ? errorMessage.name : "" }</FormText>
            </FormGroup>
            <FormGroup>
              <MultilingualInput
                label={ 
                  `${ Lang[ "DESCRIPTION" ][ props.data.languageReducer.lang ] } (${ Lang[ "MAXIMUM" ][ props.data.languageReducer.lang ] } 139 ${ Lang[ "CHARACTERS" ][ props.data.languageReducer.lang ] })` 
                }
                selectedLanguage={ props.data.languageReducer.lang }
                value={ props.newPage.description }
                onChange={ val => updatePageInfo( "description", val ) }
              />
            </FormGroup>
            <FormGroup>
              <Label>
                { Lang[ "URL" ][ props.data.languageReducer.lang ] }
                <span className="text-danger">*</span>
              </Label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                }}
              >
                <Label
                  style={{
                    fontSize: "18px",
                    paddingLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                  {" "} / {" "}
                </Label>
                <Input type="text" value={ props.newPage.url } onChange={ e => updatePageInfo( "url", e.target.value ) }></Input>
              </div>
              <FormText color="danger">{ errorMessage.url ? errorMessage.url : "" }</FormText>
            </FormGroup>
            <FormGroup>
              <CustomInput
                id='is_hide_footer'
                type='checkbox'
                label={ Lang[ 'HIDE_FOOTER_FOR_LANDING_PAGE_CREATED' ][ props.data.languageReducer.lang ] }
                checked={ props.newPage.is_hide_footer }
                onChange={ e => updatePageInfo( 'is_hide_footer', e.target.checked ) } />
            </FormGroup>
            <FormGroup>
              <Label>
                { Lang[ 'LANDING_PAGE_FOR' ][ props.data.languageReducer.lang ] }
                <span className="text-danger">*</span>
              </Label>
              <CustomInput
                id='center'
                type='radio'
                label={ Lang[ 'CENTER' ][ props.data.languageReducer.lang ] }
                checked={ mode === 'center' }
                onChange={ e => updateMode( 'center' ) } />
              <CustomInput
                id='center-group'
                type='radio'
                label={ Lang[ 'CENTER_GROUP' ][ props.data.languageReducer.lang ] }
                checked={ mode === 'center-group' }
                onChange={ e => updateMode( 'center-group' ) } />
              <FormText color='danger'>{ errorMessage.mode ? errorMessage.mode : '' }</FormText>
            </FormGroup>
            {
              mode === 'center' && (
                <FormGroup>
                  <Label>
                    { Lang[ 'CENTER' ][ props.data.languageReducer.lang ] }
                    <span className="text-danger">*</span>
                  </Label>
                  <ReactSelect
                    isMulti
                    options={ props.centers }
                    className='basic-multi-select'
                    classNamePrefix='select'
                    onChange={ val => updatePageInfo( 'center_ids', val ) }
                    value={ props.newPage.center_ids } />
                  <FormText color='danger'>{ errorMessage.center_ids ? errorMessage.center_ids : '' }</FormText>
                </FormGroup>
              )
            }
            {
              mode === 'center-group' && (
                <>
                  <FormGroup>
                    <Label>
                      { Lang[ "CENTER_GROUP" ][ props.data.languageReducer.lang ] }
                      <span className="text-danger">*</span>
                    </Label>
                    <ReactSelect
                      isMulti
                      options={ props.centerGroupForSelect }
                      classNamePrefix="select"
                      onChange={ val => updatePageInfo( "center_group_ids", val ) }
                      value={ props.newPage.center_group_ids }
                    />
                    <FormText color="danger">{ errorMessage.center_group_ids ? errorMessage.center_group_ids : '' }</FormText>
                  </FormGroup>
                  <FormGroup>
                    <Label>{ Lang[ "COUNTRY" ][ props.data.languageReducer.lang ] }</Label>
                    <ReactSelect
                      isMulti
                      options={ props.countriesOptions }
                      classNamePrefix="select"
                      placeholder={ Lang[ "SELECT_PLACEHOLDER" ][ props.data.languageReducer.lang ] }
                      onChange={ val => updatePageInfo( "countries", val ) }
                      value={ props.newPage.countries }
                    />
                  </FormGroup>
                </>
              )
            }
            <FormGroup>
              <Label>{ Lang[ "SELECT_PAGE_TO_COPY" ][ props.data.languageReducer.lang ] }</Label>
              <Input
                type="select"
                onChange={ e => {
                  if ( e.target.value ) {
                    let tmpSection = _.find( props.allLandingPage, { id: parseInt( e.target.value ) })
                    let tmp = _.cloneDeep( props.newPage )
                    tmp.content = tmpSection.content
                    tmp.to_copy_page_id = parseInt( e.target.value )
                    props.onChangeLandingPageHOC( "newPage", tmp )
                  } else {
                    let tmp = _.cloneDeep( props.newPage )
                    tmp.content = {}
                    delete tmp.to_copy_page_id
                    props.onChangeLandingPageHOC( "newPage", tmp )
                  }
                }}
              >
                <option value={""}>{ Lang[ "CREATE_NEW_EMPTY_PAGE" ][ props.data.languageReducer.lang ] }</option>
                { 
                  props.allLandingPage.map( page => (
                    <option value={ page.id }>{ page.name[ props.data.languageReducer.lang ] }</option>
                  ))
                }
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={ createPage }>
            { Lang[ "SUBMIT" ][ props.data.languageReducer.lang ] }
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default CreateModal
