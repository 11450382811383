import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { Post, Get, Put, Delete } from 'utils/axios'
import Lang from 'Lang/General'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state={ 
      loading: false,
      typesSettings: [],
      newTypesSetting: {},
      selectedTypesSetting: {},
      showCreateTypesSetting: false,
      showUpdateTypesSetting: false,
      showDeleteTypesSettingId: 0
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( err => toast.error( err.replaceAll( '_', ' ' ) ) )

    onChangeTypesSettingsHOC = ( key, value ) => this.setState({ [ key ] : value })

    createTypesSetting = data => Post(
      `/api/v1/ark_member_area/am_event_types`,
      data,
      this.createTypesSettingSuccess,
      this.createTypesSettingError,
      this.load
    )
    createTypesSettingSuccess = () => {
      this.getTypesSettings()
      this.requestSuccess( Lang[ 'EVENT_TYPE_IS_SUCCESSFULLY_CREATED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showCreateTypesSetting: false })
    }
    createTypesSettingError = error => {
      this.requestError( error )
      this.setState({ showCreateTypesSetting: false })
    }

    getTypesSettings = () => Get(
      `/api/v1/ark_member_area/am_event_types`,
      this.getTypesSettingsSuccess,
      this.getTypesSettingsError,
      this.load
    )
    getTypesSettingsSuccess = payload => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      this.setState({ typesSettings: payload, typesSettingsPages: tmpTotalPages })
    }
    getTypesSettingsError = error => this.requestError( error )

    getSelectedTypesSetting = typesSetting_id => Get(
      `/api/v1/ark_member_area/am_event_types/${ typesSetting_id }`,
      this.getSelectedTypesSettingSuccess,
      this.getSelectedTypesSettingError,
      this.load
    )
    getSelectedTypesSettingSuccess = payload => this.setState({ selectedTypesSetting: payload, showUpdateTypesSetting: true })
    getSelectedTypesSettingError = error => this.requestError( error )

    updateTypesSetting = data => Put(
      `/api/v1/ark_member_area/am_event_types/${ data.id }`,
      data,
      this.updateTypesSettingSuccess,
      this.updateTypesSettingError,
      this.load
    )
    updateTypesSettingSuccess = () => {
      this.getTypesSettings()
      this.requestSuccess( Lang[ 'EVENT_TYPE_IS_SUCCESSFULLY_UPDATED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showUpdateTypesSetting: false })
    }
    updateTypesSettingError = error => this.requestError( error )

    removeTypesSetting = typesSetting_id => Delete(
      `/api/v1/ark_member_area/am_event_types/${ typesSetting_id }`,
      this.removeTypesSettingSuccess,
      this.removeTypesSettingError,
      this.load
    )
    removeTypesSettingSuccess = () => {
      this.getTypesSettings()
      this.requestSuccess( Lang[ 'EVENT_TYPE_IS_SUCCESSFULLY_REMOVED' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showDeleteTypesSettingId: 0 })
    }
    removeTypesSettingError = error => {
      this.requestError( error )
      this.setState({ showDeleteTypesSettingId: 0 })
    }

    render = () => {
      return(
        <WrappedComponent
          { ...this.props } 
          onLoadTypesSettingsHOC={ this.state.loading }
          typesSettings={ this.state.typesSettings }
          newTypesSetting={ this.state.newTypesSetting }
          selectedTypesSetting={ this.state.selectedTypesSetting }
          showCreateTypesSetting={ this.state.showCreateTypesSetting }
          showUpdateTypesSetting={ this.state.showUpdateTypesSetting }
          showDeleteTypesSettingId={ this.state.showDeleteTypesSettingId }
          onChangeTypesSettingsHOC={ this.onChangeTypesSettingsHOC }
          createTypesSetting={ this.createTypesSetting }
          getTypesSettings={ this.getTypesSettings }
          getSelectedTypesSetting={ this.getSelectedTypesSetting }
          updateTypesSetting={ this.updateTypesSetting }
          removeTypesSetting={ this.removeTypesSetting }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC