import React, { Component } from 'react'
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button, TabContent, TabPane, 
  Nav, NavItem, NavLink
} from 'reactstrap'
import { connect } from 'react-redux'
import { compose } from 'redux'
import _ from 'lodash'
import classnames from 'classnames'

import SharedFiles from './components/sharedFiles'
import UploadFiles from './components/uploadFiles'
import LoadingModal from 'components/Indicator/LoadingOverlay'

import { toggleMediaDatabase } from 'actions/MediaDatabaseToggle'
import WithSharedFiles from './actions'
import WithUploadFiles from './actions/uploadFiles'

import { convertObjToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

class MediaDatabase extends Component {

  componentDidMount = () => {
    this.props.getMediaDrive()
    this.props.getSharedMediaDrive( convertObjToBase64({
      page: 1,
      is_paginated: true
    }) )
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Media' })
      return tmpModules.is_create
    }
  }

  toggle = (tab) => {
    if (this.props.activeTab !== tab) {
      this.props.onChangeMediaDriveHOC( 'activeTab', tab )
    }
  }

  displayFileTypeImage = ( file ) => {
    if( file.split('.').pop() === 'pdf' ) {
      return <img src={ 'https://icon-library.com/images/pdf-download-icon-transparent-background/pdf-download-icon-transparent-background-16.jpg' } style={{ width: '50px' }} />
    } else if( file.split('.').pop() === 'doc' || file.split('.').pop() === 'docx' ) {
      return <img src={ 'https://i.pinimg.com/originals/e8/86/12/e88612aacf6e4f7a43679635b5104734.png' } style={{ width: '50px' }} />
    } else if( file.split('.').pop() === 'xls' || file.split('.').pop() === 'xlsx' ) {
      return <img src={ 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Microsoft_Office_Excel_%282019%E2%80%93present%29.svg/1200px-Microsoft_Office_Excel_%282019%E2%80%93present%29.svg.png' } style={{ width: '50px' }} />
    }
  }

  render = () => {
    return (
      <Modal
        isOpen={ this.props.data.MediaDatabaseToggle.open }
        size={ 'xl' }>
        <ModalHeader 
          toggle={() => {
            this.props.toggleMediaDatabase( false )
            this.props.onChangeMediaDriveHOC( 'activeTab', '1' )
          }}>{ Lang[ 'ARK_M_FILES' ][ this.props.data.languageReducer.lang ] }
        </ModalHeader>
        <ModalBody>
          <Nav tabs={true}>
            <NavItem>
              <NavLink href="javascript:void(0);"
                className={classnames({ active: this.props.activeTab === '1' })}
                onClick={() => {
                  this.toggle('1');
                }}>{ Lang[ 'SHARED_FILES' ][ this.props.data.languageReducer.lang ] }
              </NavLink>
            </NavItem>
            {
              this.handleSecurityModulesCreate() && (
                <NavItem>
                  <NavLink href="javascript:void(0);"
                    className={ classnames({ active: this.props.activeTab === '2' }) }
                    onClick={ () => {
                      this.toggle('2')
                    }}>{ Lang[ 'UPLOAD_FILES' ][ this.props.data.languageReducer.lang ] }
                  </NavLink>
                </NavItem>
              )
            }
          </Nav>
          <TabContent activeTab={ this.props.activeTab }>
            <TabPane tabId="1">
              <SharedFiles 
                { ...this.props }
                displayFileTypeImage={ this.displayFileTypeImage } />
            </TabPane>
            <TabPane tabId="2">
              <UploadFiles 
                { ...this.props }
                selectedLanguage={ this.props.data.languageReducer.lang }
                displayFileTypeImage={ this.displayFileTypeImage } />
            </TabPane>
          </TabContent>
          { this.props.onLoadImages && <LoadingModal /> }
        </ModalBody>
        <ModalFooter>
          <Button color="link" 
            onClick={() => {
              this.props.toggleMediaDatabase( false )
              this.props.onChangeMediaDriveHOC( 'activeTab', '1' )
            }}>{ Lang[ 'CLOSE' ][ this.props.data.languageReducer.lang ] }
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({ data: state })

export default compose(
  connect( mapStateToProps, {
    toggleMediaDatabase
  }),
  WithSharedFiles,
  WithUploadFiles
)( MediaDatabase )