export const printCourseplan = {
  en: 'Print Course Plan',
  de: 'Kursplan Drucken',
  nl: 'Cursusplan afdrukken',
  it: 'Stampa il piano dei corsi'
}

export const courseplanTitle = {
  en: 'COURSE SCHEDULE ',
  de: 'KURSPLAN ',
  nl: 'CURSUSSCHEMA',
  it: 'PROGRAMMA DEL CORSO'
}

export const allCategories = {
  en: 'All Categories',
  de: 'Alle Kategorien',
  nl: 'Alle categorieën',
  it: 'Tutte le categorie'
}

export const upperLetter = {
  en: 'Upper Letter',
  de: 'Oberer Buchstabe',
  nl: 'Bovenste brief',
  it: 'Lettera superiore'
}

export const lowerLetter = {
  en: 'Lower Letter',
  de: 'Unterer Buchstabe',
  nl: 'Onderste letter',
  it: 'Lettera inferiore'
}

export const Week = {
  en: 'Week',
  de: 'Woche',
  nl: 'Week',
  it: 'Settimana'
}

export const Day = {
  en: 'Day',
  de: 'Tag',
  nl: 'Dag',
  it: 'Giorno'
}

export const Today = {
  en: 'Today',
  de: 'Heute',
  nl: 'Vandaag',
  it: 'Oggi'
}

export const Details = {
  en: 'Details',
  de: 'Einzelheiten',
  nl: 'Details',
  it: 'Dettagli'
}
