import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import Moment from 'moment'
import _ from 'lodash'

import { Get, DownloadFile } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state={
      loading: false,
      omBillings: [],
      dateQueryString: Lang[ 'TOTAL' ][ this.props.data.languageReducer.lang ],
      omBillingPages: [],
      searchParams: {
        date: '',
        source: 'ONLINE_SHOP'
      }
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )

    onChangeOmBillingsHOC = ( key, value ) => this.setState({ [ key ]: value })

    getOmBillings = ( query, isReset ) => Get(
      `/api/v1/billing_reports?query=${ query }&source=${ this.state.searchParams.source }`,
      payload => this.getOmBillingsSuccess( payload, isReset ),
      this.getOmBillingsError,
      this.load
    )
    getOmBillingsSuccess = ( payload, isReset ) => {
      let tmpTotalPages = []
      if( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      this.setState({ 
        omBillings: payload,
        omBillingPages: tmpTotalPages,
        dateQueryString: !isReset && !_.isEmpty( this.state.searchParams.date )
          ? Moment( this.state.searchParams.date ).locale( this.props.data.languageReducer.lang ).format( 'MMMM YYYY' )
          : Lang[ 'TOTAL' ][ this.props.data.languageReducer.lang ]
      })
    }
    getOmBillingsError = error => toast.error( error )

    getOmBillingExcel = query => DownloadFile(
      !_.isEmpty( query )
        ? `/api/v1/billing_report_templates?query=${ convertObjToBase64( query ) }&source=${ this.state.searchParams.source }`
        : `/api/v1/billing_report_templates?source=${ this.state.searchParams.source }`,
      Lang[ 'BILLINGS' ][ this.props.data.languageReducer.lang ] + 
      ' - ' + 
      `${ 
        !_.isEmpty( query ) 
          ? Moment( query.filter.date.$gte ).locale( this.props.data.languageReducer.lang ).format( 'MMMM YYYY' ) 
          : Lang[ 'TOTAL' ][ this.props.data.languageReducer.lang ]
      }` + 
      '.xlsx',
      () => {},
      this.load
    )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadOmBillingsHOC={ this.state.loading }
          dateQueryString={ this.state.dateQueryString }
          omBillings={ this.state.omBillings }
          omBillingPages={ this.state.omBillingPages }
          searchParams={ this.state.searchParams }
          onChangeOmBillingsHOC={ this.onChangeOmBillingsHOC }
          getOmBillings={ this.getOmBillings }
          getOmBillingExcel={ this.getOmBillingExcel } />
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC