import React, { Component } from 'react'
import _ from 'lodash'

import FormEditorModal from './FormEditor'


class ModalFormView extends Component {
  render = () => {
    return (
      <FormEditorModal
        mode={ 'view' }
        digitalOrderForm={ this.props.selectedDigitalOrderForm }
        showFormEditor={ this.props.showViewFormModal }
        toggleFormEditor={ ( isShow ) => this.props.onChangeDigitalOrderFormHOC( 'showViewFormModal', isShow ) }
        { ...this.props }
      />
    )
  }
}

export default ModalFormView