import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get, Post, Put, Delete } from 'utils/axios'
import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showCreateCampaignTool: false,
      showUpdateCampaignTool: false,
      showDeleteCampaignTool: false,
      campaignTools: [],
      newCampaignTool: {
        title: '',
        center_groups: [],
        countries: [],
        status: 'ACTIVE',
        shop_tool_product_text: '',
        shop_tool_image_column_one: '',
        shop_tool_image_column_two: '',
        fb_post_image: '',
        fb_ads_image: '',
        insta_story_image: '',
        fb_ads_text: ''
      },
      selectedCampaignTool: {
        title: '',
        centerGroups: [],
        countries: [],
        status: 'ACTIVE',
        shop_tool_product_text: '',
        shop_tool_image_column_one: '',
        shop_tool_image_column_two: '',
        fb_post_image: '',
        fb_ads_image: '',
        insta_story_image: '',
        fb_ads_text: ''
      },
      errorMessage: {}
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    } )
    requestError = error => {
      toast.error( error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false
    } )}

    onChangeCampaignToolsHOC = ( key, val ) => this.setState({ [ key ] : val })

    createCampaignTool = data => Post(
      `/api/v1/online_membership_campaign_tools`,
      data,
      this.createCampaignToolSuccess,
      this.createCampaignToolError,
      this.load
    )
    createCampaignToolSuccess = () => {
      this.requestSuccess( Lang[ 'CAMPAIGN_TOOL_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getCampaignTools()
      this.setState({ showCreateCampaignTool: false })
    }
    createCampaignToolError = error => {
      this.setState({ errorMessage: error })
      this.requestError( Lang[ 'CAMPAIGN_TOOL_CREATE_UNSUCCESS' ][ this.props.data.languageReducer.lang ] )
    }

    getCampaignTools = () => Get(
      `/api/v1/online_membership_campaign_tools`,
      this.getCampaignToolsSuccess,
      this.getCampaignToolsError,
      this.load
    )
    getCampaignToolsSuccess = payload => this.setState({ campaignTools: payload })
    getCampaignToolsError = error => this.requestError( error )

    getSelectedCampaignTool = ( tool_id ) => Get(
      `/api/v1/online_membership_campaign_tools/${ tool_id }`,
      this.getSelectedCampaignToolSuccess,
      this.getSelectedCampaignToolError,
      this.load
    )
    getSelectedCampaignToolSuccess = payload => {
      let tmp = _.cloneDeep( payload )
      let tmpCountry = []
      let tmpCenterGroups = []
      payload.countries.length > 0 && payload.countries.map( country => {
        tmpCountry.push({
          label: country,
          value: country
        })
      })
      payload.center_groups.length > 0 && payload.center_groups.map( group => {
        tmpCenterGroups.push({
          id: group.id,
          value: group.name,
          label: group.name
        })
      })
      tmp['countries'] = tmpCountry
      tmp['center_groups'] = tmpCenterGroups
      this.setState({ selectedCampaignTool: tmp })
    }
    getSelectedCampaignToolError = error => this.requestError( error )

    updateCampaignTool = ( id, data ) => Put(
      `/api/v1/online_membership_campaign_tools/${ id }`,
      data,
      this.updateCampaignToolSuccess,
      this.updateCampaignToolError,
      this.load
    )
    updateCampaignToolSuccess = () => {
      this.requestSuccess( Lang[ 'CAMPAIGN_TOOL_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getCampaignTools()
      this.setState({ showUpdateCampaignTool: false })
    }
    updateCampaignToolError = error => {
      this.setState({ errorMessage: error })
      this.requestError( Lang[ 'CAMPAIGN_TOOL_UPDATE_UNSUCCESS' ][ this.props.data.languageReducer.lang ] )
    }

    removeCampaignTool = id => Delete(
      `/api/v1/online_membership_campaign_tools/${ id }`,
      this.removeCampaignToolSuccess,
      this.removeCampaignToolError,
      this.load
    )
    removeCampaignToolSuccess = () => {
      this.requestSuccess( Lang[ 'CAMPAIGN_TOOL_REMOVE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getCampaignTools()
    }
    removeCampaignToolError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            onLoadCampaignTools={ this.state.loading }
            onChangeCampaignToolsHOC={ this.onChangeCampaignToolsHOC }
            campaignTools={ this.state.campaignTools }
            newCampaignTool={ this.state.newCampaignTool }
            selectedCampaignTool={ this.state.selectedCampaignTool }
            showCreateCampaignTool={ this.state.showCreateCampaignTool }
            showUpdateCampaignTool={ this.state.showUpdateCampaignTool }
            createCampaignTool={ this.createCampaignTool}
            getCampaignTools={ this.getCampaignTools }
            getSelectedCampaignTool={ this.getSelectedCampaignTool }
            updateCampaignTool={ this.updateCampaignTool }
            removeCampaignTool={ this.removeCampaignTool }
            errorMessage={ this.state.errorMessage }
            showDeleteCampaignTool={ this.state.showDeleteCampaignTool } />
        </>
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect ( mapStateToProps )( WithHOC )
}

export default HOC
