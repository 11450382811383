import React, { useState, useEffect } from "react"
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput
} from "reactstrap"
import _ from "lodash"

import Lang from "Lang/General"
import MultiLangRTE from 'components/RTE/MultiLang'
import { headingPattern } from "../LibComponentBlocks/Heading/headingPattern"
import { patchIdsChildren } from 'utils/uniqueIds'

const HeadingForm = ({
  lang,
  selectedSection,
  selectedEditableComponent,
  findComponentAndPatch,
  onChangeSectionEditorState,
  onChangeSectionHOC,
}) => {
  const [ textContainer, setTextContainer ] = useState({})
  useEffect(() => {
    selectedEditableComponent.children.map( item => {
      if ( item.type && item.type === "heading-content" ) {
        setTextContainer( item )
      }
    })
  }, [ selectedEditableComponent ])
  return (
    <Card className="mb-2">
      <CardHeader>{ Lang[ "HEADING_SETTING" ][ lang ]}</CardHeader>
      <CardBody>
        <Form>
          <FormGroup>
            <MultiLangRTE
              label={ Lang[ "CONTENT" ][ lang ] }
              value={ _.find( selectedEditableComponent.children, { type: 'heading-content' } ).content }
              selectedLanguage={ lang }
              onChange={ val => {
                let tmpEditable = _.cloneDeep( selectedEditableComponent )
                let tmpSection = _.cloneDeep( selectedSection )
                _.find( tmpEditable.children, { type: 'heading-content' } ).content = val
                findComponentAndPatch( tmpEditable , tmpSection )
                onChangeSectionEditorState( "selectedEditableComponent", tmpEditable )
                onChangeSectionEditorState( "selectedContainer", tmpEditable )
                onChangeSectionHOC( "selectedSection", tmpSection )
              }} />
          </FormGroup>
          <FormGroup>
            <Label>{ Lang[ "FONT_SIZE" ][ lang ]}</Label>
            <Input
              value={
                textContainer.style && textContainer.style.fontSize
                  ? textContainer.style.fontSize
                  : ""
              }
              disabled={ textContainer.generalH1 }
              onChange={ e => {
                let tmp = _.cloneDeep( textContainer )
                let tmpEditable = _.cloneDeep( selectedEditableComponent )
                let tmpSection = _.cloneDeep( selectedSection )
                tmp.style = { ...tmp.style, fontSize: e.target.value }
                setTextContainer( tmp )
                findComponentAndPatch( tmp, tmpEditable )
                findComponentAndPatch( tmp, tmpSection )
                onChangeSectionEditorState( "selectedEditableComponent", tmpEditable )
                onChangeSectionEditorState( "selectedContainer", tmpEditable )
                onChangeSectionHOC( "selectedSection", tmpSection )
              }}
            />
            <CustomInput
              id="heading-h1"
              type="checkbox"
              label= { Lang[ "USE_GENERAL_H1" ][ lang ] }
              checked={ textContainer.generalH1 }
              onChange={ e => {
                let tmp = _.cloneDeep( textContainer )
                let tmpEditable = _.cloneDeep( selectedEditableComponent )
                let tmpSection = _.cloneDeep( selectedSection )
                tmp.generalH1 = e.target.checked
                setTextContainer( tmp )
                findComponentAndPatch( tmp, tmpEditable )
                findComponentAndPatch( tmp, tmpSection )
                onChangeSectionEditorState( "selectedEditableComponent", tmpEditable )
                onChangeSectionEditorState( "selectedContainer", tmpEditable )
                onChangeSectionHOC( "selectedSection", tmpSection )
              }}/>
          </FormGroup>
          <FormGroup>
            <Label>{ Lang[ "HEADING_PATTERN" ][ lang ] }</Label>
            <Input
              type="select"
              value={ selectedEditableComponent.variant }
              onChange={ e => {
                let tmp = _.cloneDeep( selectedEditableComponent )
                let tmpContent = _.cloneDeep( textContainer )
                let tmpPattern = _.cloneDeep( _.find(headingPattern, { value: e.target.value } ))
                let tmpStyle = _.find( tmpPattern.content, { type: "heading-content" }).style
                let tmpSection = _.cloneDeep( selectedSection )
                tmp.variant = e.target.value
                tmpPattern.content.map( item=>patchIdsChildren( item ))
                tmpContent.style = { ...tmpContent.style, ...tmpStyle }
                tmpPattern.content.splice( _.findIndex( tmpPattern.content, { type:"heading-content" }), 1, tmpContent )
                tmp.children = tmpPattern.content
                findComponentAndPatch( tmp, tmpSection )
                onChangeSectionEditorState( "selectedEditableComponent", tmp )
                onChangeSectionEditorState( "selectedContainer", tmp )
                onChangeSectionHOC( "selectedSection", tmpSection )
              }}
            >
              <option></option>
              {
                headingPattern.map( pattern => (
                  <option value={ pattern.value }>{ Lang[ pattern.value ][ lang ] }</option>
                ))
              }
            </Input>
          </FormGroup>
        </Form>
      </CardBody>
    </Card>
  )
}

export default HeadingForm
