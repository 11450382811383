import Axios from 'axios'
import getDomainURL from 'utils/api'
import { getItem, clearItem } from 'utils/tokenStore'
import FileSaver from 'file-saver'

export const outdatedToken = ( error, history ) => {
  if( error === 'Token is expired' ) {
    clearItem( 'PROOFPOINT_ARK_MANAGEMENT' )
    history.push( '/' )
  }
}

export const Get = ( url, response, error, load, lang ) => {
  load( true )
  Axios.defaults.headers = {
    common: {
      Authorization: `Bearer ${ getItem( 'PROOFPOINT_ARK_MANAGEMENT' ) }`,
      'Accept-Language': lang ? lang : ''
    }
  }
  return Axios.get( `${ getDomainURL() }${ url }` ).then( async res => {
    await response( res.data )
    load( false )
  }).catch( err => {
    if( err && err.response && err.response.status ) {
      let tmp = err.response.status
      if( tmp === 422 || tmp === 401 ) {
        error( err.response.data.Error ? err.response.data.Error : err.response.data.message )
      }
    } else if( err && err.message ) {
      error( err.message )
    } else {
      error( 'You are disconnnected from the internet, please reconnect to use Ark. If problem persists, please contact the system admin.' )
    }
    load( false )
  })
}

export const Post = ( url, data, response, error, load, lang ) => {
  load( true )
  let token = getItem( 'PROOFPOINT_ARK_MANAGEMENT' )
  Axios.defaults.headers = {
    common: {
      'Authorization': `Bearer ${ token }`,
      'Accept-Language': lang ? lang : ''
    }
  }
  return Axios.post( `${ getDomainURL() }${ url }`, data ).then( async res => {
    await response( res.data )
    load( false )
  }).catch( err => {
    if( err && err.response && err.response.status ) {
      let tmp = err.response.status
      if( tmp === 422 || tmp === 401 ) {
        error( err.response.data.Errors ? err.response.data.Errors : err.response.data.Error )
      }
    } else if( err ) {
      error( err )
    } else {
      error( 'You are disconnnected from the internet, please reconnect to use Ark. If problem persists, please contact the system admin.' )
    }
    load( false )
  })
}

export const Put = ( url, data, response, error, load, lang ) => {
  load( true )
  let token = getItem( 'PROOFPOINT_ARK_MANAGEMENT' )
  Axios.defaults.headers = {
    common: {
      'Authorization': `Bearer ${ token }`,
      'Accept-Language': lang ? lang : ''
    }
  }
  return Axios.put( `${ getDomainURL() }${ url }`, data ).then( async res => {
    await response( res.data )
    load( false )
  }).catch( err => {
    if( err && err.response && err.response.status ) {
      let tmp = err.response.status
      if( tmp === 422 || tmp === 401 ) {
        if ( err.response.data.Errors ) {
          error( err.response.data.Errors )
        } else if( err.response.data.Error ) {
          error( err.response.data.Error )
        } else {
          error( err.response.data.message )
        }
      }
    } else if( err ) {
      error( err.message )
    } else {
      error( 'You are disconnnected from the internet, please reconnect to use Ark. If problem persists, please contact the system admin.' )
    }
    load( false )
  })
}

export const Patch = ( url, data, response, error, load, lang ) => {
  load( true )
  let token = getItem( 'PROOFPOINT_ARK_MANAGEMENT' )
  Axios.defaults.headers = {
    common: {
      'Authorization': `Bearer ${ token }`,
      'Accept-Language': lang ? lang : ''
    }
  }
  return Axios.patch( `${ getDomainURL() }${ url }`, data ).then( res => {
    response( res.data )
    load( false )
  }).catch( err => {
    if( err && err.response && err.response.status ) {
      let tmp = err.response.status
      if( tmp === 422 || tmp === 401 ) {
        if ( err.response.data.Errors ) {
          error( err.response.data.Errors )
        } else if( err.response.data.Error ) {
          error( err.response.data.Error )
        } else {
          error( err.response.data.message )
        }
      }
    } else if( err ) {
      error( err.message )
    } else {
      error( 'You are disconnnected from the internet, please reconnect to use Ark. If problem persists, please contact the system admin.' )
    }
    load( false )
  })
}

export const Delete = ( url, response, error, load, data, lang ) => {
  load( true )
  let token = getItem( 'PROOFPOINT_ARK_MANAGEMENT' )
  Axios.defaults.headers = {
    common: {
      'Authorization': `Bearer ${ token }`,
      'Accept-Language': lang ? lang : ''
    }
  }
  return Axios.delete( `${ getDomainURL() }${ url }`, { data: data } ).then( res => {
    response( res.data )
    load( false )
  }).catch( err => {
    if( err && err.response && err.response.status ) {
      let tmp = err.response.status
      if( tmp === 422 || tmp === 401 ) {
        if( err.response.data.message  ){
          error( err.response.data.message )
        } else {
          error( 
            err.response.data.Error || 
            err.response.data.Errors || 
            err.response.data 
          )
        }
      }
    } else if( err ) {
      error( err.message )
    } else {
      error( 'You are disconnnected from the internet, please reconnect to use Ark. If problem persists, please contact the system admin.' )
    }
    load( false )
  })
}

export const DownloadFile = ( url, savedFilename, error, load ) => {
  load( true )
  let token = getItem( 'PROOFPOINT_ARK_MANAGEMENT' )
  let headers = new Headers()
  headers.append( 'Authorization', `Bearer ${ token }` )
  return fetch( `${ getDomainURL() }${ url }`, { headers } ).then( res => res.blob() ).then( blobby => {
    FileSaver.saveAs( blobby, savedFilename )
    load( false )
  }).catch( err => {
    load( false )
    return error( err.message )
  })
}
