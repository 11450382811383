import React, { useEffect } from 'react'
import Radium from 'proofpoint-radium'
import { compose } from 'redux'
import _ from 'lodash'

import { ApplyColor } from 'utils/apply-color'

import CoreRenderFunction from '../../core/render'
import TemplateFormsHOC from './actions'
import LoadingIndicator from './loadingOverlay'

const TemplateForm = ({
  id,
  item,
  style,
  selectedLanguageShortname ,
  currentFormValues,
  dom,
  onLoadForms,
  getSelectedForm,
  mode,
  formId,
  onChangeSectionEditorState,
  hoveredComponentId,
  selectedEditableComponent
}) => {
  useEffect( () => {
    if( formId ){
      getSelectedForm( formId )
    }
  }, [ formId ])

  return(
    <>
      { 
        currentFormValues.length > 0 && <div
          id={ id }
          onMouseEnter={ () => mode === 'edit' && onChangeSectionEditorState && onChangeSectionEditorState( 'hoveredComponentId', id ) } 
          style={ 
            mode === 'edit'
              ? {
                  ...ApplyColor( style, false ),
                  ...(
                    ( ( selectedEditableComponent && selectedEditableComponent.id === id ) || hoveredComponentId === id  ) 
                      ? { 
                        borderTop: "5px solid #45818e",
                        borderRight: "5px solid #45818e",
                        borderBottom: "5px solid #45818e",
                        borderLeft: "5px solid #45818e"
                      } : {}
                  )
                }
              : ApplyColor( style, false )
          }
          onClick={ () => {
            if( mode === 'edit' ){
              onChangeSectionEditorState && onChangeSectionEditorState( 'selectedEditableComponent', item )
            }
          }}>
          { dom && dom.length > 0 && dom.map( ( item ) => {
            return(
              <div>
                <CoreRenderFunction
                  item={ item }
                  content={ item }
                  mode={ mode } 
                  formValues={ currentFormValues }
                  dom={ dom }
                  selectedLanguageShortname ={ selectedLanguageShortname  } />
              </div>
            )
          })}
        </div>
      }
      { onLoadForms && <LoadingIndicator/> }
    </>
  )
}

export default compose (
  TemplateFormsHOC
)( Radium( TemplateForm ) )
