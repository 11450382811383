import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  UncontrolledTooltip
} from 'reactstrap'
import _ from 'lodash'
import Moment from 'moment'

import ARKTable from 'components/Table'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'

const ArchivedModal = ({
  showArchivedModal,
  onChangeArchivedContentHOC,
  archivedContents,
  data,
  handleSecurityModulesUpdate,
  getArchivedContents,
  onLoadArchivedContents,
  getSelectedContent,
  handleShowContentInfoTable,
  handleUnarchiveContent,
  searchParamsforArchived
}) => {
  return (
    <Modal
      size={ 'xl' }
      isOpen={ showArchivedModal }>
      <ModalHeader toggle={() => onChangeArchivedContentHOC( 'showArchivedModal', false )}>{ Lang[ 'SHOW_ARCHIVED_CONTENT_SERVICE' ][ data.languageReducer.lang ] }</ModalHeader>
      <ModalBody>
        <Card>
          <CardBody>
            <Container>
              <Col md={ 12 }>
                <Row style={{ alignItems: 'center' }}>
                  <Col lg={ 5 }>
                    <FormGroup>
                      <Label>{ Lang[ 'TITLE' ][ data.languageReducer.lang ] }</Label>
                      <Input
                        name="title" id="title"
                        placeholder={ Lang[ 'SEARCH_BY_TITLE' ][ data.languageReducer.lang ] }
                        value={ searchParamsforArchived[ 0 ].value }
                        onChange={ e => {
                          let tmp = _.cloneDeep( searchParamsforArchived )
                          tmp[ 0 ][ 'value' ] = e.target.value
                          return onChangeArchivedContentHOC( 'searchParamsforArchived', tmp )
                        }}/>
                    </FormGroup>
                  </Col>
                  <Col lg={ 1.5 }>
                    <Button
                      className="mb-2 mr-2"
                      color="primary"
                      onClick={() => {
                        let tmpSearchParams = {
                          is_paginated: true,
                          page: 1,
                          filter: {
                            title: {
                              $like: `%${ searchParamsforArchived[ 0 ].value }%`
                            },
                            status: 'ARCHIVED'
                          }
                        }
                        return getArchivedContents( tmpSearchParams )
                      }}>{ Lang[ 'SEARCH' ][ data.languageReducer.lang ] }</Button>
                  </Col>
                  <Col lg={ 1.5 }>
                    <Button
                      className="mb-2 mr-2"
                      color="danger"
                      onClick={() => {
                        let tmp = { 
                          page: 1, 
                          is_paginated: true,
                          filter: {
                            status: 'ARCHIVED'
                          }
                        }
                        onChangeArchivedContentHOC( 
                          'searchParamsforArchived', 
                          [
                            {
                              name: 'title',
                              value: ''
                            }
                          ]
                        )
                        return getArchivedContents( tmp )
                      }}>{ Lang[ 'RESET' ][ data.languageReducer.lang ] }</Button>
                  </Col>
                </Row>
              </Col>
            </Container>
          </CardBody>
        </Card>
        <ARKTable
          data={ archivedContents.data }
          columns={[
            {
              Header: Lang[ 'TITLE' ][ data.languageReducer.lang ],
              accessor: 'title',
              width: 200,
              Cell: ({ value }) => value[ data.languageReducer.lang ]
            },
            {
              Header: Lang[ 'PUBLISHED_FROM' ][ data.languageReducer.lang ],
              accessor: 'start_at',
              Cell: ({ value }) => Moment( value ).format( 'DD.MM.YYYY HH:mm' )
            },
            {
              Header: Lang[ 'CATEGORIES' ][ data.languageReducer.lang ],
              accessor: 'categories',
              Cell: ({value}) => (
                <ul>
                {
                  value && value.map( item => (
                    <li key={ item }>{ item.name[ data.languageReducer.lang ] }</li>
                  ))
                }
                </ul>
              )
            },
            {
              Header: Lang[ 'ACTION' ][ data.languageReducer.lang ],
              accessor: "id",
              Cell: (row) => (
                <div>
                  <Button
                    id={ `Content-service-${ row.original.id  }-view-archived-content` }
                    className="mb-2 mr-2 btn-icon btn-icon-only"
                    color="primary"
                    onClick={() => {
                      getSelectedContent( row.original.id )
                      handleShowContentInfoTable( true )
                    }}>
                    <i className="pe-7s-look"> </i>
                  </Button>
                  <UncontrolledTooltip
                    target={ `Content-service-${ row.original.id  }-view-archived-content` }
                    placement="top">{ Lang[ 'VIEW_CONTENT' ][ data.languageReducer.lang ] }</UncontrolledTooltip>
                  {
                    handleSecurityModulesUpdate() && (
                      <>
                        <Button
                          id={ `Content-service-${ row.original.id  }-unarchive-content` }
                          className="mb-2 mr-2 btn-icon btn-icon-only"
                          color="danger"
                          onClick={ () => {
                            getSelectedContent( row.original.id )
                            handleUnarchiveContent( row.original.id, {
                              ...row.original,
                              status: 'ACTIVE'
                            })
                          }}>
                          <i className="pe-7s-back-2"> </i>
                        </Button>
                        <UncontrolledTooltip
                          target={ `Content-service-${ row.original.id  }-unarchive-content` }
                          placement="top">{ Lang[ 'UNARCHIVE_CONTENT' ][ data.languageReducer.lang ] }</UncontrolledTooltip>
                      </>
                    )
                  }
                </div>
              )
            }
          ]}
          page={ archivedContents.meta ? archivedContents.meta.current_page : 1 }
          totalPages={ archivedContents.meta.total_pages }
          onChangePage={ pg => {
            let tmp = {
              is_paginated: true,
              page: pg,
              filter: {
                title: {
                  $like: `%${ searchParamsforArchived[ 0 ].value }%`
                },
                status: 'ARCHIVED'
              }
            }
            getArchivedContents( tmp )
          }}
          showPagination={ false } />
        { onLoadArchivedContents && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => onChangeArchivedContentHOC( 'showArchivedModal', false )}>
          { Lang[ 'CANCEL' ][ data.languageReducer.lang ] }
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ArchivedModal
