import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get, Post, outdatedToken, Put } from 'utils/axios'
import { getRefreshToken } from 'actions/token'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const MailingTemplateHOC = ( WrappedComponent ) => {
  class MailingTemplatesWrappedComponent extends Component{
    state = {
      loading: false,
      mailingTemplates: [],
      totalPagesMailingTemplates: [],
      showCreateMailingTemplate: false,
      newMailingTemplateData: {
        name: '',
        status: 'ACTIVE',
        html_content: '',
        json_content: {},
        image: {
          raw: '',
        }
      },
      files: [],
      selectedMailingTemplate: {
        name: '',
        status: 'ACTIVE',
        html_content: '',
        json_content: {},
        image: {
          url: '',
          raw: ''
        }
      },
      showUpdateMailingTemplate: false,
      mailTemplateErrMsg: {},
      name: '',
      showArchivedMailingTemplates: false,
      mailingTemplateToArchive: {}
    }

    onChangeSystemMailingTemplateHOC = ( key, value ) => this.setState({ [key]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    ajaxError = ( error ) => {
      outdatedToken( error, this.props.history )
      this.requestError( error )
    }

    getAllMailingTemplates = ( page, isReset ) => {
      let tmpQuery = {
        page: page || 1 ,
        is_paginated: true,
        filter: {
          status: this.state.showArchivedMailingTemplates 
            ? 'ARCHIVED' : 
            { $neq: 'ARCHIVED' }
        }
      }

      if( !isReset && !_.isEmpty( this.state.name ) ){
        tmpQuery.filter.name = {
          $like: `%${ this.state.name }%`
        }
      }

      return Get(
        `/api/v1/funnel_mailing_templates?query=${ convertObjToBase64( tmpQuery ) }`,
        payload => this.getAllMailingTemplatesSuccess( payload, isReset ),
        this.getAllMailingTemplatesError,
        this.load
      )
    }
    getAllMailingTemplatesSuccess = ( payload, isReset ) => {
      let tmpTotalPages = []
      let tmpForSelect = []
      if( payload.data && payload.data.length > 0 ){
        payload.data.map( item => {
          tmpForSelect.push({
            ...item,
            label: item.name[this.props.data.languageReducer.lang],
            value: item.name[this.props.data.languageReducer.lang]
          })
        })
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      } else {
        toast.error( Lang['NO_RESULT_FOUND'][ this.props.data.languageReducer.lang ] )
      }
      return this.setState({ 
        mailingTemplates: payload, 
        totalPagesMailingTemplates: tmpTotalPages,
        name: isReset ? '' : this.state.name
      })
    }
    getAllMailingTemplatesError = error => this.requestError( error )

    createMailingTemplate = data => Post(
      `/api/v1/funnel_mailing_templates`,
      data,
      this.createMailingTemplateSuccess,
      this.createMailingTemplateError,
      this.load
    )
    createMailingTemplateSuccess = () => {
      this.setState({ showCreateMailingTemplate: false })
      this.getAllMailingTemplates( 1, true )
      this.requestSuccess( Lang['CREATE_SUCCESS_MAILING_TEMPLATE'][ this.props.data.languageReducer.lang ])
    }
    createMailingTemplateError = error => {
      this.requestError( error )
      this.setState({ mailTemplateErrMsg: error })
    }

    getSelectedMailingTemplate = ( id, isArchival ) => Get(
      `/api/v1/funnel_mailing_templates/${ id }`,
      payload => this.getSelectedMailingTemplateSuccess( payload, isArchival ),
      this.getSelectedMailingTemplateError,
      this.load
    )
    getSelectedMailingTemplateSuccess = ( payload, isArchival ) => this.setState(
      !isArchival ? ({ 
        selectedMailingTemplate: {
          ...payload,
          center_groups: payload.center_groups.map( item => ({ ...item, label: item.name, value: item.name }) )
        } 
      }) : ({
        mailingTemplateToArchive: payload
      })
    )
    getSelectedMailingTemplateError = error => this.requestError( error )

    updateMailingTemplate = data => Put(
      `/api/v1/funnel_mailing_templates/${ data.id }`,
      data,
      this.updateMailingTemplateSuccess,
      this.updateMailingTemplateError,
      this.load
    )
    updateMailingTemplateSuccess = async () => {
      await this.getAllMailingTemplates( 1, true )
      this.requestSuccess( Lang['UPDATE_SUCCESS_MAILING_TEMPLATE'][ this.props.data.languageReducer.lang ])
      this.setState({ showUpdateMailingTemplate: false, mailingTemplateToArchive: {} })
    }
    updateMailingTemplateError = error => {
      this.requestError( error )
      this.setState({ mailTemplateErrMsg: error })
    }

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          onLoadMailingTemplate={ this.state.loading }
          mailingTemplates={ this.state.mailingTemplates }
          totalPagesMailingTemplates={ this.state.totalPagesMailingTemplates }
          showCreateMailingTemplate={ this.state.showCreateMailingTemplate }
          newMailingTemplateData={ this.state.newMailingTemplateData }
          files={ this.state.files }
          selectedMailingTemplate={ this.state.selectedMailingTemplate }
          showUpdateMailingTemplate={ this.state.showUpdateMailingTemplate }
          mailTemplateErrMsg={ this.state.mailTemplateErrMsg }
          name={ this.state.name }
          showArchivedMailingTemplates={ this.state.showArchivedMailingTemplates }
          mailingTemplateToArchive={ this.state.mailingTemplateToArchive }
          onChangeSystemMailingTemplateHOC={ this.onChangeSystemMailingTemplateHOC }
          getAllMailingTemplates={ this.getAllMailingTemplates }
          createMailingTemplate={ this.createMailingTemplate }
          getSelectedMailingTemplate={ this.getSelectedMailingTemplate }
          updateMailingTemplate={ this.updateMailingTemplate }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    getRefreshToken
  } )( MailingTemplatesWrappedComponent )
}

export default MailingTemplateHOC
