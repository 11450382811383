import React, { Component, Fragment } from 'react'
import _ from 'lodash'

import { toast } from 'react-toastify'

import { Get, Put, Post, Delete } from 'utils/axios-template'
import { production_url } from '../config'

import Lang from 'Lang/General'

const TemplateHeaderBlueprintHOC = ( WrappedComponent ) => {
  class TemplateHeaderBlueprintWrappedComponent extends Component {
    state = {
      templateHeadersBlueprints: [],
      customHeaderBlueprints: [],
      selectedHeaderBlueprint: '',
      selectedHeaderBlueprintMenu: 'Desktop Menu',
      loading: false,
      updateLoading: false,
      showStatusModal: false,
      statusModalType: '',
      onClickStatusModalButton: () => {},
      statusModalMessage: '',
    }

    load = param => this.setState({ loading: param })
    updateLoad = param => this.setState({ updateLoading: param })
    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    onChangeSelectedHeaderBlueprints = ( param ) => this.setState({ selectedHeaderBlueprint: param })

    onSelectHeaderBlueprint = ( param ) => {
      this.setState({ selectedHeaderBlueprintMenu: param })
      if( param === 'Desktop Menu' ) {
        let tmpIndex = _.findIndex( this.state.templateHeadersBlueprints, obj => obj.name.indexOf( 'Desktop' ) > -1 )
        this.setState({ selectedHeaderBlueprint: this.state.customHeaderBlueprints[ tmpIndex ] })
      } else {
        let tmpIndex = _.findIndex( this.state.templateHeadersBlueprints, obj => obj.name.indexOf( 'Mobile' ) > -1 )
        this.setState({ selectedHeaderBlueprint: this.state.customHeaderBlueprints[ tmpIndex ] })
      }
    }

    getTemplateHeaderBlueprints = ( template_id ) => Get(
      `${production_url}/templates/${template_id}/template-header-blueprints`,
      this.getTemplateHeaderBlueprintsSuccess,
      this.getTemplateHeaderBlueprintsError,
      this.load
    )
    getTemplateHeaderBlueprintsSuccess = payload => {
      if( payload && payload.length > 0 ) {
        let tmpPayloadHeaderBlueprint = []
        payload.map( item => {
          let tmp = JSON.stringify( item.content, null, 2 )
          tmpPayloadHeaderBlueprint.push( tmp )
        })
        let tmpIndex = _.findIndex( payload, obj => obj.name.indexOf( 'Desktop' ) > -1 )
        this.setState({
          templateHeadersBlueprints: payload,
          customHeaderBlueprints: tmpPayloadHeaderBlueprint,
          selectedHeaderBlueprint: tmpPayloadHeaderBlueprint[ tmpIndex ]
        })
      }
    }
    getTemplateHeaderBlueprintsError = error => this.requestError( error )

    updateTemplateHeaderBlueprint = ( template_id ) => {
      let tmpIndex = this.state.selectedHeaderBlueprintMenu === 'Desktop Menu' ? _.findIndex( this.state.templateHeadersBlueprints, obj => obj.name.indexOf( 'Desktop' ) > -1 ) : _.findIndex( this.state.templateHeadersBlueprints, obj => obj.name.indexOf( 'Mobile' ) > -1 )
      let tmp = _.cloneDeep( this.state.templateHeadersBlueprints[ tmpIndex ] )
      tmp.content = JSON.parse( this.state.selectedHeaderBlueprint )
      Put(
        `${production_url}/templates/${template_id}/template-header-blueprints/${tmp.id}`,
        tmp,
        this.updateTemplateHeaderBlueprintSuccess,
        this.updateTemplateHeaderBlueprintError,
        this.updateLoad
      )
    }
    updateTemplateHeaderBlueprintSuccess = () => this.requestSuccess( Lang[ 'HEADER_BLUEPRINT_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
    updateTemplateHeaderBlueprintError = error => this.requestError( error )

    updateTemplateHeaderBlueprintItemColor = ( 
      template_id,
      headerBlueprintDesktop, 
      headerBlueprintMobile 
    ) => {
      Promise.all([
        Put(
          `${production_url}/templates/${template_id}/template-header-blueprints/${headerBlueprintDesktop.id}`,
          headerBlueprintDesktop,
          this.updateTemplateHeaderBlueprintItemColorSuccess,
          this.updateTemplateHeaderBlueprintItemColorError,
          this.updateLoad
        )
      ]).then(() => {
        Put(
          `${production_url}/templates/${template_id}/template-header-blueprints/${headerBlueprintMobile.id}`,
          headerBlueprintMobile,
          this.updateTemplateHeaderBlueprintItemColorSuccess,
          this.updateTemplateHeaderBlueprintItemColorError,
          this.updateLoad
        )
      })
    }
    updateTemplateHeaderBlueprintItemColorSuccess = () => this.requestSuccess( Lang[ 'HEADER_BLUEPRINT_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
    updateTemplateHeaderBlueprintItemColorError = error => this.requestError( error )

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            { ...this.props }
            getTemplateHeaderBlueprints={ this.getTemplateHeaderBlueprints }
            onChangeSelectedHeaderBlueprints={ this.onChangeSelectedHeaderBlueprints }
            onSelectHeaderBlueprint={ this.onSelectHeaderBlueprint }
            updateTemplateHeaderBlueprint={ this.updateTemplateHeaderBlueprint }
            updateTemplateHeaderBlueprintItemColor={ this.updateTemplateHeaderBlueprintItemColor }
            templateHeadersBlueprints={ this.state.templateHeadersBlueprints }
            customHeaderBlueprints={ this.state.customHeaderBlueprints }
            selectedHeaderBlueprint={ this.state.selectedHeaderBlueprint }
            onLoadTemplateHeaderBlueprints={ this.state.loading }
            onLoadHeaderBlueprintUpdate={ this.state.updateLoading } />
        </Fragment>
      )
    }
  }
  return TemplateHeaderBlueprintWrappedComponent
}

export default TemplateHeaderBlueprintHOC
