import React from 'react'

import RenderDOM from '../RenderDOM'

const RowComponent = ({
  content,
  mode,
  onChangeFormBuilderState,
  formValues,
  onPatchFormValues,
  onClickSubmitForm,
  createSubmittedFormMedia,
  dom,
  findObjectAndPatch,
  currentLanguage
}) => {
  return (
    <div className="row">
      {
        content.children && content.children.map(( item, index ) => {
          return RenderDOM( 
            { ...item, rowComponentIndex: index }, 
            onChangeFormBuilderState, 
            mode, 
            formValues,
            onPatchFormValues,
            onClickSubmitForm,
            createSubmittedFormMedia,
            dom,
            findObjectAndPatch,
            currentLanguage
          )
        })
      }
    </div>
  )
}

export default RowComponent