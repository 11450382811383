import React, { useState, useEffect } from 'react'
import { FormGroup, Label, Input } from 'reactstrap'
import _ from 'lodash'
import ReactHTMLParser from 'react-html-parser'


const CheckboxComponent = ({
  content,
  formValues,
  selectedLanguageShortname 
}) => {
  const [ labelStr, setLabelStr ] = useState("")

  useEffect(() => {
    let labelText = content.label[ selectedLanguageShortname  ? selectedLanguageShortname  : 'en' ]

    if ( labelText.length !== 0 ) {
      if ( typeof(ReactHTMLParser( labelText )[0] ) === 'object' ) {
        let labelTag = ReactHTMLParser( labelText )[0].type
        let closingTag = "</" + labelTag + ">"
        labelText = labelText.slice( 0, -1*closingTag.length )
        if ( formValues[ content.dataMapIndex ][ 'isRequired' ] ){
          labelText = labelText + "<span style='color: #ff0000;'> * </span>"
        }
        labelText = labelText + closingTag
      }
      else {
        labelText = labelText + "<span style='color: #ff0000;'> * </span>"
      }
    }
    setLabelStr( labelText )
  }, [ content, formValues ]);

  return (
    <FormGroup
      tag="fieldset"
      style={{ padding: content.padding ? content.padding : '0' }}>
      <Label>{ ReactHTMLParser( labelStr ) }</Label>
      {
        content.selections.map( ( item, index ) => {
          return (
            <FormGroup key={ item.id } check>
              <Label check>
                {
                  <Input
                    type="checkbox" 
                    name={ content.id } 
                    checked={ formValues[ content.dataMapIndex ][ 'value' ].indexOf( item.value.replace( / /g, '-' ) ) > -1 } />
                }
                { ' ' }
                { content.type.indexOf( 'Flexible' ) !== -1 && index === content.selections.length - 1
                  ? <Input
                      type='text'
                      value={ formValues[ content.dataMapIndex ][ 'value' ].indexOf( item.value ) > -1
                        ? ( new DOMParser ).parseFromString( item.label[ selectedLanguageShortname  ], "text/html" ).documentElement.textContent 
                        : '' }
                      readOnly= { formValues[ content.dataMapIndex ][ 'value' ].indexOf( item.value ) > -1 ? false : true }
                      style={{ 
                        height: "auto",
                        border: "none",
                        borderRadius: "0",
                        padding: "0",
                        fontSize: "14px",
                        borderBottom: "1px dotted #000000" }} />
                  : ReactHTMLParser( item.label[ selectedLanguageShortname  ] ) } 
              </Label>
            </FormGroup>
          )
        })
      }
    </FormGroup>
  )
}

export default CheckboxComponent
