import React from 'react'
import cx from 'classnames'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'

import HeaderLogo from './Logo'
import Userbox from './Userbox'

const Header = ({
  data,
  headerBackgroundColor,
  enableMobileMenuSmall,
  enableHeaderShadow,
  history,
  onClickToggleProfileModal,
  from,
  profile
}) => {
  return (
    <>
      <ReactCSSTransitionGroup
        component="div"
        className={ cx("app-header", headerBackgroundColor, {'header-shadow': enableHeaderShadow}) }
        transitionName="HeaderAnimation"
        transitionAppear={ true }
        transitionAppearTimeout={ 1500 }
        transitionEnter={ false }
        transitionLeave={ false }>
        <HeaderLogo data={ data } />
        <div className={ cx( "app-header__content", { 'header-mobile-open': enableMobileMenuSmall } ) }>
          <div className="app-header-left">

          </div>
          <div className="app-header-right">
            {
              from === 'editor'
                ? (
                  <>
                    <div className="header-dots">
                      <Button color="primary">
                        Save Page
                      </Button>
                    </div>
                  </>
                )
                : <Userbox
                    onClickToggleProfileModal={ onClickToggleProfileModal }
                    history={ history }
                    profile={ profile } />
            }
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </>
  )
}

const mapStateToProps = state => ({
  enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall
})

const mapDispatchToProps = dispatch => ({})

export default connect( mapStateToProps, mapDispatchToProps )( Header )
