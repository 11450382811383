import React from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"
import _ from 'lodash'

import Lang from "Lang/General"

const DuplicateForm = ( props ) => {
  return(
    <Modal isOpen={ props.showDuplicateModal } size="md">
      <ModalHeader
        toggle={ () =>
          props.onChangeFormsHOC( "showDuplicateModal", false )
        }
      >
        { Lang[ "DUPLICATE_FORM" ][ props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        { Lang[ "DUPLICATE_FORM_CONFIRMATION" ][ props.data.languageReducer.lang ] }
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary"
          onClick={ () => {
              if (!_.isEmpty( props.selectedFormOriginal )){
                let tmp = _.cloneDeep( props.selectedFormOriginal )
                delete tmp.created_at
                delete tmp.created_by
                delete tmp.id
                delete tmp.ms_center_id
                delete tmp.updated_by
                Object.keys( tmp.name ).map( key => {
                  if( tmp.name[ key ] ){
                    tmp.name[ key ] = tmp.name[ key ] + " (" + Lang[ "DUPLICATE" ][ key ] + ")"
                  }
                })
                props.createForm( tmp )
                props.onChangeFormsHOC( "showDuplicateModal", false )
              }
            }
          }>
          { Lang[ "DUPLICATE" ][ props.data.languageReducer.lang ] }
        </Button>
        <Button 
          color="danger"
          onClick={ 
            () => props.onChangeFormsHOC( "showDuplicateModal", false )
          }>
            { Lang[ "CANCEL" ][ props.data.languageReducer.lang ] }
          </Button>
      </ModalFooter>
    </Modal>
  )
}

export default DuplicateForm