import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import {
  Card, CardBody, Button,
  FormGroup, Label, Input
} from 'reactstrap'

import Lang from 'Lang/General'

const AssignCategoryForm = ({
  selectedSection,
  categoryForSelect,
  assignCategoryData,
  onChangeSectionHOC,
  changesMade,
  postSectionCategory,
  deleteSectionCategory,
  dataReducer
}) => {
  return (
    <>
      <FormGroup className="card p-3">
        <Label>{ Lang[ 'SELECT_CATEGORY' ][ dataReducer.languageReducer.lang ] }</Label>
        <Input
          type="select"
          value={ assignCategoryData.category_id > 0 && assignCategoryData.category_id }
          onChange={ e => {
            let tmp = _.cloneDeep( assignCategoryData )
            tmp.section_id = selectedSection.id
            tmp.category_id = parseInt( e.target.value, 10 )
            onChangeSectionHOC( 'assignCategoryData', tmp )
          }}>
          <option></option>
          {
            categoryForSelect.length > 0 && categoryForSelect.map( item => {
              let tmpIndex = _.findIndex( selectedSection.SectionCategories, { category_id: item.id })
              if( tmpIndex === -1 ) {
                return (
                  <option key={ item.id } value={ item.id }>{ item.text }</option>
                )
              }
            })
          }
        </Input>
        <Button
          className='mt-2' 
          color="primary"
          style={{width:"fit-content"}}
          onClick={ () => {
            if( assignCategoryData.category_id > 0 ) {
              postSectionCategory( assignCategoryData )
              changesMade()
            }
          }}>{ Lang[ 'ASSIGN' ][ dataReducer.languageReducer.lang ] }</Button>
      </FormGroup>
      <FormGroup>
        {
          selectedSection.SectionCategories && selectedSection.SectionCategories.length > 0 && selectedSection.SectionCategories.map( item => {
            let tmp = _.find( categoryForSelect, { id: item.category_id })
            return (
              <Card key={ item }>
                <CardBody>
                  { tmp ? tmp.text : '' }
                  <Button 
                    color="danger" 
                    onClick={() => {
                      let tmpSection = _.cloneDeep( selectedSection )
                      _.remove( tmpSection.SectionCategories, val => val === item )
                      deleteSectionCategory( item.id )
                      changesMade()
                    }}
                    className="ml-3">{ Lang[ 'DELETE' ][ dataReducer.languageReducer.lang ] }</Button>
                </CardBody>
              </Card>
            )
          })
        }
      </FormGroup>
    </>
  )
}
const mapStateToProps = state => ({ dataReducer: state })

export default connect( mapStateToProps )( AssignCategoryForm )
