import React, { Fragment, Component } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import ReactSelect from 'react-select'
import _ from 'lodash'
import { connect } from 'react-redux'
import { BeatLoader } from 'react-spinners'
import {
    Col, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input, FormText, Container, FormFeedback
} from 'reactstrap'
import Lang from 'Lang/General'

class CenterGroupForm extends Component {
  onChange = ( key, value ) => {
    let tmp = _.cloneDeep( this.props.data )
    let dataKey = ''
    tmp[key] = value
    if( this.props.mode === 'edit' ){
      dataKey = 'selectedCenterGroup'
    }else{
      dataKey = 'newCenterGroupData'
    }
    return this.props.onChangeCenterGroupHOC( dataKey, tmp )
  }
  render = () => {
    let {
      onChangeCenterGroupHOC,
      data,
      statusValues,
      errorMessage,
      mode,
      languageReducer
    } = this.props

    return(
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Container fluid>
            <Card className="main-card mb-3">
              <CardBody>
                <Form>
                  <FormGroup row>
                    <Label for="name" sm={ 2 }>{ Lang[ 'NAME' ][ languageReducer.lang ] }</Label>
                    <Col sm={ 10 }>
                      <Input
                        name="name" id="name"
                        placeholder={ Lang[ 'ENTER_CENTER_GROUP_NAME' ][ languageReducer.lang ] }
                        value={ data.name }
                        onChange={ e => this.onChange( 'name', e.target.value ) }
                        invalid={ errorMessage && errorMessage.name ? true : false }/>
                      <FormFeedback>{ errorMessage && errorMessage.name ? errorMessage.name : '' }</FormFeedback>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="description" sm={ 2 }>{ Lang[ 'DESCRIPTION' ][ languageReducer.lang ] }</Label>
                    <Col sm={ 10 }>
                      <Input
                        name="description" id="description"
                        placeholder={ Lang[ 'ENTER_DESCRIPTION' ][ languageReducer.lang ] }
                        value={ data.description }
                        onChange={ e => this.onChange( 'description', e.target.value ) }/>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="status" sm={ 2 }>{ Lang[ 'STATUS' ][ languageReducer.lang ] }</Label>
                    <Col sm={ 10 }>
                      <Input
                        type="select"
                        name="status"
                        id="status"
                        value={ data.status }
                        onChange={ e => this.onChange( 'status', e.target.value ) }>
                        {
                          statusValues && Object.keys( statusValues ).map( key => {
                            return (
                              <option key={ key }>{ statusValues[key] }</option>
                            )
                          })
                        }
                      </Input>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Container>
        </ReactCSSTransitionGroup>
      </Fragment>
    )
  }
}
const mapStateToProps = state => ({ languageReducer: state.languageReducer })

export default connect( mapStateToProps )( CenterGroupForm )
