import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import ReactSelect from 'react-select'
import {
  Form, FormGroup, FormFeedback, FormText,
  Col, Label, Input, Button
} from 'reactstrap'

import MediaDatabase from 'SharedModules/MediaDatabase'
import MultiLangInput from 'components/Input/MultiLang'
import MultiLangRTE from 'components/RTE/MultiLang'
import DomEditor from 'components/DomEditor'
import Lang from 'Lang/General'

const KnowledgeForm = ({
  knowledgeType,
  currentKnowledge,
  onChangeKnowledgesHOC,
  centerGroups,
  lang,
  categories,
  toggleMediaDatabase,
  knowledgeErrorMessage
}) => {
  const [ showDomEditor, updateShowDomEditor ] = useState( false )
  const [ showMediaDb, updateShowMediaDb ] = useState( false )

  useEffect( () => {
    onChangeKnowledgesHOC( 'knowledgeErrorMessage', {} )
  }, [] )

  const updateKnowledge = ( key, val ) => {
    let tmp = _.cloneDeep( currentKnowledge )
    if( key === 'description_type' ){
      tmp[ 'description' ] = { en: '', de: '', it: '', nl: '' }
    }
    tmp[ key ] = val
    onChangeKnowledgesHOC( knowledgeType, tmp )
  }

  return(
    <>
      <Form>
        <MultiLangInput
          label={ `${ Lang[ 'TITLE' ][ lang ] } ${ Lang[ 'TITLE_MAX_CHARACTERS' ][ lang ] }` }
          value={ currentKnowledge.title }
          onChange={ val => updateKnowledge( 'title', val ) }
          selectedLanguage={ lang }
          showCharCount
        />
        <MultiLangRTE
          label={ `${ Lang[ 'BRIEF_DESCRIPTION' ][ lang ] } ${ Lang[ 'DESCRIPTION_MAX_CHARACTERS' ][ lang ] }` }
          selectedLanguage={ lang }
          value={ currentKnowledge.description }
          onChange={ val => updateKnowledge( 'description', val ) }
          editorType='inline'
          showCharCount
        />
        <FormGroup>
          <Label>{ Lang[ 'CENTER_GROUP' ][ lang ] }</Label>
          <Col sm={ 12 } className='pl-0 pr-0' style={{ zIndex: 999 }}>
            <ReactSelect
              isMulti
              name="colors"
              options={ centerGroups }
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder={ Lang[ 'SELECT_PLACEHOLDER' ][ lang ] }
              onChange={ ( value, action ) => updateKnowledge( 'center_groups', value ) }
              value={ currentKnowledge.center_groups } />
            <FormText color="danger">{ knowledgeErrorMessage && knowledgeErrorMessage.center_groups ? knowledgeErrorMessage.center_groups : '' }</FormText>
          </Col>
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'CATEGORY' ][ lang ] }</Label>
          <Col sm={ 12 } className='pl-0 pr-0' style={{ zIndex: 998 }}>
            <ReactSelect
              isMulti={ false }
              name="colors"
              options={ categories }
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder={ Lang[ 'SELECT_PLACEHOLDER' ][ lang ] }
              onChange={ ( value, action ) => updateKnowledge( 'category_id', value ? value.id : 0 )}
              value={ _.find( categories, { id: currentKnowledge.category_id } ) || {} } 
            />
            <FormText color="danger">{ knowledgeErrorMessage && knowledgeErrorMessage.category_id ? knowledgeErrorMessage.category_id : '' }</FormText>
          </Col>
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'STATUS' ][ lang ] }</Label>
          <Input
            type='select'
            value={ currentKnowledge.status }
            onChange={ e => updateKnowledge( 'status', e.target.value ) }>
            <option value='ACTIVE' >{ Lang[ 'ACTIVE' ][ lang ] }</option>
            <option value='INACTIVE' >{ Lang[ 'INACTIVE' ][ lang ] }</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'IMAGE' ][ lang ] }</Label>
          <div className='d-flex flex-column w-100 mb-1' >
            <Input 
              disabled
              type={ 'text' } 
              value={ currentKnowledge.thumbnail_image_url } 
              invalid={ knowledgeErrorMessage.thumbnail_image_url }
              style={{ marginRight: '5px', borderRadius: "0.25rem 0.25rem 0 0" }} />
            <div 
              src={ currentKnowledge.thumbnail_image_url } 
              style={{
                width: "100%",
                height: "200px",
                backgroundColor: _.isEmpty( currentKnowledge.thumbnail_image_url ) ? "transparant" : "#000000",
                backgroundImage: _.isEmpty( currentKnowledge.thumbnail_image_url ) ? '' : `url(${ currentKnowledge.thumbnail_image_url })`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center center",
                border: _.isEmpty( currentKnowledge.thumbnail_image_url ) ? "1.7px solid #ced4da" : "0 solid #000000",
                borderTop: "0",
                borderRadius: "0 0 0.25rem 0.25rem"
              }} />
            <FormFeedback>{ knowledgeErrorMessage.thumbnail_image_url }</FormFeedback>
          </div>
          <Button
            style={{ width: "max-content" }}
            color='primary'
            onClick={ () => {
              toggleMediaDatabase( true ) 
              updateShowMediaDb( true )
            }}>
            { Lang[ 'SELECT_IMAGE' ][ lang ] }
          </Button>
        </FormGroup>
        <FormGroup>
          <Label className='mr-2' >{ Lang[ 'CONTENT' ][ lang ] }</Label>
          <Button
            color='primary'
            onClick={ () => updateShowDomEditor( true ) }>
            { Lang[ 'EDIT' ][ lang ] }
          </Button>
        </FormGroup>
      </Form>
      {
        !showDomEditor && showMediaDb && <MediaDatabase
          onSelectedImage={( param ) => {
            updateKnowledge( 'thumbnail_image_url', param )
            toggleMediaDatabase( false )
            updateShowMediaDb( false )
          }} 
        />
      }
      <DomEditor 
        selectedLanguage={ lang }
        showDomEditor={ showDomEditor }
        updateShowDomEditor={ updateShowDomEditor }
        parent={ currentKnowledge }
        updateParent={ updateKnowledge } 
        childrenKey='content' /> 
    </>
  )
}

export default KnowledgeForm