import React, { useState } from 'react'
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label, Input,
  Button,
  Card, CardHeader,
  ListGroup, ListGroupItem
} from 'reactstrap'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'

const Create = props => {
  const [ selectPackageID, updateSelectPackageID ] = useState( 0 )

  const onChangePackageData = ( key, val ) => {
    let tmp = _.cloneDeep( props.newPackage )
    tmp[ key ] = val
    return props.onChangePackagesHOC( 'newPackage', tmp )
  }

  return (
    <Modal
      isOpen={ props.showCreatePackage }
      size={ 'lg' }
      toggle={() => {}}>
      <ModalHeader toggle={() => props.onChangePackagesHOC( 'showCreatePackage', false )}>{ Lang[ 'CREATE_PACKAGE' ][ props.data.languageReducer.lang ] }</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <FormGroup>
            <Label>{ `${ Lang[ 'NAME' ][ props.data.languageReducer.lang ] }*` }</Label>
            <Input
              type="text"
              value={ props.newPackage.name }
              onChange={ e => {
                onChangePackageData( 'name', e.target.value )
              }}
              required={ true } />
          </FormGroup>
          <FormGroup>
            <Label>{ Lang[ 'ASSIGN_PACKAGE' ][ props.data.languageReducer.lang ] }</Label>
            <div className='d-flex flex-row' >
              <Input  
                type="select"
                className='mr-2'
                value={ selectPackageID }
                onChange={ e => {
                  updateSelectPackageID( parseInt( e.target.value, 10 ) )
                }}>
                <option></option>
                {
                  props.modules.data.map( item => {
                    if( _.findIndex( props.newPackage.modules, { id: item.id } ) < 0 ) {
                      return <option key={ item.id } value={ item.id }>{ item.name }</option>
                    }
                  })
                }
              </Input>
              <Button 
                color="primary" 
                onClick={() => {
                  let tmp = _.cloneDeep( props.newPackage.modules )
                  let tmpPackage = {}
                  props.modules.data.map( item => item.id === selectPackageID ? tmpPackage = item : '' )
                  if( tmpPackage ) {
                    tmp.push( tmpPackage )
                    onChangePackageData( 'modules', tmp )
                    updateSelectPackageID( 0 )
                  }
                }}>
                { Lang[ 'ASSIGN' ][ props.data.languageReducer.lang ] }
              </Button>
            </div>
          </FormGroup>
          <FormGroup>
            <Card>
              <CardHeader>{ Lang[ 'ASSIGNED_MODULES' ][ props.data.languageReducer.lang ] }</CardHeader>
              <ListGroup flush>
                {
                  props.newPackage.modules.map( item => {
                    return (
                      <ListGroupItem key={ item.id } className="d-flex align-items-center mb-0 justify-content-between">
                        <span className="mr-2">{ item.name }</span>
                        <Button 
                          color="danger"
                          className="btn-icon btn-icon-only"
                          onClick={() => {
                            let tmp = _.cloneDeep( props.newPackage.modules )
                            _.remove( tmp, { id: item.id } )
                            onChangePackageData( 'modules', tmp )
                          }}>
                          <i className="pe-7s-trash"/>
                        </Button>
                      </ListGroupItem>
                    )
                  })
                }
              </ListGroup>
            </Card>
          </FormGroup>
        </Form>
        { props.onLoadPackages && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            props.createPackage( props.newPackage )
          }}>
          { Lang[ 'SUBMIT' ][ props.data.languageReducer.lang ] }
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default Create