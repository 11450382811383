import React, { Component, Fragment } from 'react'
import {
  Container, Card, CardBody, FormGroup, Form,
  Label, Col, Input, CustomInput, FormFeedback, Button
} from 'reactstrap'
import Image from 'react-bootstrap/Image'
import { connect } from 'react-redux'
import { registerPlugin } from "react-filepond"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import ReactSelect from 'react-select'
import moment from 'moment'
import _ from 'lodash'
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
import "filepond/dist/filepond.min.css"

import { toggleMediaDatabase } from 'actions/MediaDatabaseToggle'
import MediaDatabase from 'SharedModules/MediaDatabase' 
import MultiLangInput from 'components/Input/MultiLang'
import DomEditor from 'components/DomEditor'
import Lang from 'Lang/General'

registerPlugin( FilePondPluginImagePreview, FilePondPluginFileValidateType )

class ContentForm extends Component {
  state = {
    dateTime: moment( new Date() ).format( 'YYYY-MM-DDTHH:mm' ),
    active: this.props.data.status === 'ACTIVE',
    showDomEditor: false
  }

  getValueForSelect = ( type ) => {
    switch ( type ) {
      case 'categories':
        let tmpCategories = []
        this.props.data.categories && this.props.data.categories.map( item => {
          if( item.label ){
            tmpCategories.push({
              id: item.id,
              label: item.label,
              value: item.label
            })
          } else{
            tmpCategories.push({
              id: item.id,
              label: item.name,
              value: item.name
            })
          }
        })
        return tmpCategories
      case 'center_groups':
        let tmpCenterGroup = []
        this.props.data.center_groups && this.props.data.center_groups.map( item => {
          if( item.label ){
            tmpCenterGroup.push({
              id: item.id,
              label: item.label,
              value: item.label
            })
          } else {
            tmpCenterGroup.push({
              id: item.id,
              label: item.name,
              value: item.name
            })
          }
        })
        return tmpCenterGroup
      default:
        return {}
    }
  }

  onChangeData = ( key, value ) => {
    let tmp = _.cloneDeep( this.props.data )
    let tmpCategoriesId = []
    let tmpCenterGroups = []
    let dataKey = ''
    tmp[key] = value

    if( key === 'categories' ){
      value && value.length > 0 && value.map( item => {
        tmpCategoriesId.push({
          id: item.id,
          label: item.label
        })
      })
      tmp[ 'categories' ] = tmpCategoriesId
    }

    if( key === 'center_groups' ){
      value && value.length > 0 && value.map( item => {
        tmpCenterGroups.push({
          id: item.id,
          label: item.label
        })
      })
      tmp[ 'center_groups' ] = tmpCenterGroups
    }

    if( this.props.contentMode === 'edit' ) {
      dataKey = 'selectedContent'
    } else{
      dataKey = 'newContentData'
    }
    return this.props.onChangeContentData( dataKey, tmp )
  }

  render = () => {
    let {
      categoriesList,
      contentMode,
      data,
      contentErrorMessage,
      centerGroupsList
    } = this.props
    return(
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Container fluid>
            <Card className="main-card mb-3">
              <CardBody>
                <Form>
                  <FormGroup row>
                    <Label for="title" sm={ 2 }>
                      { Lang[ 'TITLE' ][ this.props.dataReducer.languageReducer.lang ] }
                      <span className="text-danger">*</span>
                    </Label>
                    <Col sm={ 10 }>
                      <MultiLangInput
                        value={ data.title }
                        onChange={ val => this.onChangeData( 'title', val ) }
                        selectedLanguage={ this.props.dataReducer.languageReducer.lang }
                        invalid={ contentErrorMessage.title } />
                      <FormFeedback>{ contentErrorMessage.title ? contentErrorMessage.title : '' }</FormFeedback>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="description" sm={ 2 }>{ Lang[ 'DESCRIPTION' ][ this.props.dataReducer.languageReducer.lang ] }</Label>
                    <Col sm={ 10 }>
                      <MultiLangInput
                        value={ data.description }
                        onChange={ val => this.onChangeData( 'description', val ) }
                        selectedLanguage={ this.props.dataReducer.languageReducer.lang } />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="mini_text" sm={ 2 }>{ Lang[ 'MINI_TEXT' ][ this.props.dataReducer.languageReducer.lang ] }</Label>
                    <Col sm={ 10 }>
                      <MultiLangInput
                        value={ data.mini_text }
                        onChange={ val => this.onChangeData( 'mini_text', val ) }
                        selectedLanguage={ this.props.dataReducer.languageReducer.lang } />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="teaser_text" sm={ 2 }>{ Lang[ 'TEASER_TEXT' ][ this.props.dataReducer.languageReducer.lang ] }</Label>
                    <Col sm={ 10 }>
                      <MultiLangInput
                        value={ data.teaser_text }
                        onChange={ val => this.onChangeData( 'teaser_text', val ) }
                        selectedLanguage={ this.props.dataReducer.languageReducer.lang } />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="content" sm={ 2 }>{ Lang[ 'CONTENT' ][ this.props.dataReducer.languageReducer.lang ] }</Label>
                    <Col sm={ 10 }>
                      <Button
                        color='primary'
                        className='w-100'
                        style={{ height: '4rem' }}
                        onClick={ () => this.setState({ showDomEditor: true }) }>
                        <i className="pe-7s-pen btn-icon-wrapper mr-2" />
                        { Lang[ 'EDIT_CONTENT' ][ this.props.dataReducer.languageReducer.lang ] }
                      </Button>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="start_at" sm={ 2 }>{ Lang[ 'PUBLISHED_FROM' ][ this.props.dataReducer.languageReducer.lang ] }</Label>
                    <Col sm={ 10 }>
                      <Input
                        type="datetime-local"
                        min={ this.state.dateTime }
                        value={ moment( data.start_at ).format( 'YYYY-MM-DDTHH:mm' ) }
                        onChange={ e => {
                          this.setState({ dateTime: e.target.value })
                          this.onChangeData( 'start_at', moment( e.target.value ).utc().format() )
                      }}/>
                    </Col>
                  </FormGroup>
                  {
                    contentMode === 'edit' && (
                      <FormGroup row className='align-items-center'>
                        <Label for="active" sm={ 2 }>{ Lang[ 'ACTIVE' ][ this.props.dataReducer.languageReducer.lang ] }</Label>
                        <Col sm={ 10 }>
                          <CustomInput
                            id='contentServiceStatus'
                            type="checkbox"
                            checked={ this.props.data.status === 'ACTIVE' }
                            onChange={ e => this.onChangeData( 'status', e.target.checked ? 'ACTIVE' : 'INACTIVE' )} />
                        </Col>
                      </FormGroup>
                    )
                  }
                  <FormGroup row>
                    <Label for="language" sm={ 2 }>
                      { Lang[ 'CATEGORIES' ][ this.props.dataReducer.languageReducer.lang ] }
                      <span className="text-danger">*</span>
                    </Label>
                    <Col sm={ 10 } style={{ zIndex: 999 }}>
                      <ReactSelect
                        isMulti
                        name="colors"
                        options={ categoriesList }
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder={ Lang[ 'SELECT_PLACEHOLDER' ][ this.props.dataReducer.languageReducer.lang ] }
                        onChange={ ( value, action ) => this.onChangeData( 'categories', value ) }
                        value={ this.getValueForSelect( 'categories' ) } 
                        styles={
                          contentErrorMessage && contentErrorMessage.categories ? {
                            container: ( base ) => ({
                              ...base,
                              backgroundColor: '#ff0000',
                              padding: 1,
                              borderRadius: 5
                            })
                          } : {}
                        } />
                      {
                        contentErrorMessage.categories && (
                          <p className='mt-1' style={{ color: '#ff0000', fontSize: '12px' }}>{ contentErrorMessage.categories }</p>
                        ) 
                      }
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="language" sm={ 2 }>
                      { Lang[ 'CENTER_GROUP' ][ this.props.dataReducer.languageReducer.lang ] }
                      <span className="text-danger">*</span>
                    </Label>
                    <Col sm={ 10 } style={{ zIndex: 998 }}>
                      <ReactSelect
                        isMulti
                        name="colors"
                        options={ centerGroupsList }
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder={ Lang[ 'SELECT_PLACEHOLDER' ][ this.props.dataReducer.languageReducer.lang ] }
                        onChange={ ( value, action ) => this.onChangeData( 'center_groups', value ) }
                        value={ this.getValueForSelect( 'center_groups' ) }
                        styles={
                          contentErrorMessage && contentErrorMessage.center_groups ? {
                            container: ( base ) => ({
                              ...base,
                              backgroundColor: '#ff0000',
                              padding: 1,
                              borderRadius: 5
                            })
                          } : {}
                        } />
                      {
                        contentErrorMessage.center_groups && (
                          <p className='mt-1' style={{ color: '#ff0000', fontSize: '12px' }}>{ contentErrorMessage.center_groups }</p>
                        )
                      }
                    </Col>
                  </FormGroup>
                  {
                    contentMode === 'edit' && (
                      <FormGroup row>
                        <Label for="currentImage" sm={ 2 }>{ Lang[ 'CURRENT_WEBSITE_IMG' ][ this.props.dataReducer.languageReducer.lang ] }</Label>
                        <Col sm={ 10 }>
                          {
                            data.website_preview_img_url && <Image 
                              style={{ width: "300px", height: "300px" }}
                              src={ data.website_preview_img_url } />
                          }
                        </Col>
                      </FormGroup>
                    )
                  }
                  <FormGroup row>
                    <Label for="website_preview" sm={ 2 }>{ Lang[ 'WEBSITE_PREVIEW_IMG' ][ this.props.dataReducer.languageReducer.lang ] }</Label>
                    <Col sm={ 10 }>
                      <Input
                        type="text"
                        value={ data.website_preview_img_url }
                        onChange={ e => {
                          this.onChangeData( 'website_preview_img_url', e.target.value )
                        }}
                        disabled={ true } />
                      <Button 
                        color="primary"
                        className="mt-2"
                        onClick={() => {
                          this.props.toggleMediaDatabase( true )
                          this.props.onChangeContentData( 'imageKey', 'website_preview_img' )
                        }}>{ Lang[ 'SELECT_IMAGE' ][ this.props.dataReducer.languageReducer.lang ] }</Button>
                    </Col>
                  </FormGroup>
                  {
                    contentMode === 'edit' && (
                      <FormGroup row>
                        <Label for="currentImage" sm={ 2 }>{ Lang[ 'CURRENT_DETAILED_IMG' ][ this.props.dataReducer.languageReducer.lang ] }</Label>
                        <Col sm={ 10 }>
                          {
                            data.website_detailed_img_url && <Image 
                              style={{ width: "300px", height: "300px" }}
                              src={ data.website_detailed_img_url } />
                          }
                        </Col>
                      </FormGroup>
                    )
                  }
                  <FormGroup row>
                    <Label for="website_preview" sm={ 2 }>{ Lang[ 'WEBSITE_DETAILED_IMG' ][ this.props.dataReducer.languageReducer.lang ] }</Label>
                    <Col sm={ 10 }>
                      <Input
                        type="text"
                        value={ data.website_detailed_img_url }
                        onChange={ e => {
                          this.onChangeData( 'website_detailed_img_url', e.target.value )
                        }}
                        disabled={ true } />
                      <Button 
                        color="primary"
                        className="mt-2"
                        onClick={() => {
                          this.props.toggleMediaDatabase( true )
                          this.props.onChangeContentData( 'imageKey', 'website_detailed_img' )
                        }}>{ Lang[ 'SELECT_IMAGE' ][ this.props.dataReducer.languageReducer.lang ] }</Button>
                    </Col>
                  </FormGroup>
                  {
                    contentMode === 'edit' && (
                      <FormGroup row>
                        <Label for="currentImage" sm={ 2 }>{ Lang[ 'CURRENT_NEWSLETTER_IMG' ][ this.props.dataReducer.languageReducer.lang ] }</Label>
                        <Col sm={ 10 }>
                          {
                            data.newletter_img_url && <Image 
                              style={{ width: "300px", height: "300px" }}
                              src={ data.newletter_img_url } />
                          }
                        </Col>
                      </FormGroup>
                    )
                  }
                  <FormGroup row>
                    <Label for="website_preview" sm={ 2 }>{ Lang[ 'NEWSLETTER_IMG' ][ this.props.dataReducer.languageReducer.lang ] }</Label>
                    <Col sm={ 10 }>
                      <Input
                        type="text"
                        value={ data.newletter_img_url }
                        onChange={ e => {
                          this.onChangeData( 'newletter_img_url', e.target.value )
                        }}
                        disabled={ true } />
                      <Button 
                        color="primary"
                        className="mt-2"
                        onClick={() => {
                          this.props.toggleMediaDatabase( true )
                          this.props.onChangeContentData( 'imageKey', 'newletter_img' )
                        }}>{ Lang[ 'SELECT_IMAGE' ][ this.props.dataReducer.languageReducer.lang ] }</Button>
                    </Col>
                  </FormGroup>
                  {
                    contentMode === 'edit' && (
                      <FormGroup row>
                        <Label for="currentImage" sm={ 2 }>{ Lang[ 'CURRENT_FB_TEASER_IMG' ][ this.props.dataReducer.languageReducer.lang ] }</Label>
                        <Col sm={ 10 }>
                          {
                            data.fb_teaser_img_url && <Image 
                              style={{ width: "300px", height: "300px" }}
                              src={ data.fb_teaser_img_url } />
                          }
                        </Col>
                      </FormGroup>
                    )
                  }
                  <FormGroup row>
                    <Label for="website_preview" sm={ 2 }>{ Lang[ 'FACEBOOK_TEASER_IMG' ][ this.props.dataReducer.languageReducer.lang ] }</Label>
                    <Col sm={ 10 }>
                      <Input
                        type="text"
                        value={ data.fb_teaser_img_url }
                        onChange={ e => {
                          this.onChangeData( 'fb_teaser_img_url', e.target.value )
                        }}
                        disabled={ true } />
                      <Button 
                        color="primary"
                        className="mt-2"
                        onClick={() => {
                          this.props.toggleMediaDatabase( true )
                          this.props.onChangeContentData( 'imageKey', 'fb_teaser_img' )
                        }}>{ Lang[ 'SELECT_IMAGE' ][ this.props.dataReducer.languageReducer.lang ] }</Button>
                    </Col>
                  </FormGroup>
                </Form>
                <MediaDatabase
                  onSelectedImage={( param ) => {
                    if( this.props.imageKey === 'website_preview_img' ) {
                      this.onChangeData( 'website_preview_img_url', param )
                    } else if( this.props.imageKey === 'website_detailed_img' ) {
                      this.onChangeData( 'website_detailed_img_url', param )
                    } else if( this.props.imageKey === 'newletter_img' ) {
                      this.onChangeData( 'newletter_img_url', param )
                    } else if( this.props.imageKey === 'fb_teaser_img' ) {
                      this.onChangeData( 'fb_teaser_img_url', param )
                    }
                    this.props.toggleMediaDatabase( false )
                  }} 
                />
                <DomEditor 
                  selectedLanguage={ this.props.dataReducer.languageReducer.lang }
                  showDomEditor={ this.state.showDomEditor }
                  updateShowDomEditor={ e => this.setState({ showDomEditor: e }) }
                  parent={ data }
                  updateParent={ this.onChangeData } 
                  childrenKey='content' 
                /> 
              </CardBody>
            </Card>
          </Container>
        </ReactCSSTransitionGroup>
      </Fragment>
    )
  }
}
const mapStateToProps = state => ({ dataReducer: state })
export default connect( mapStateToProps, { 
  toggleMediaDatabase 
})( ContentForm )
