import React, { Component } from 'react'
import {
  Container, Col,
  Card, CardBody, 
  Form, FormGroup,
  Button, Input, Label
}from 'reactstrap'
import _ from 'lodash'

import Lang from 'Lang/General'

class EditRoleForm extends Component{
  state = {
    tmpPlatform: {},
    platformID: 0
  }

  onChangeRoleData = ( key, val ) => {
    let tmp = _.cloneDeep( this.props.selectedRolePermission )
    tmp[ key ] = val
    return this.props.onChangeRoleHOC( 'selectedRolePermission', tmp )
  }

  render = () => {
    return (
      <Container fluid>
        <Card className="main-card mb-3">
          <CardBody>
            <Form>
              <FormGroup>
                <Label for="company_name" sm={ 2 }  className="pl-1">{ Lang[ 'NAME' ][ this.props.lang ] }</Label>
                <Col sm={ 8 } className="pl-0">
                  <Input
                    name="platform_name" id="platform_name"
                    placeholder={ Lang[ 'ENTER_ROLE' ][ this.props.lang ] }
                    value={ this.props.selectedRolePermission.name }
                    onChange={ e => this.onChangeRoleData( 'name', e.target.value ) }
                    invalid={ false }
                    disabled={ true }
                  />
                </Col>
              </FormGroup>
              <FormGroup>
                <Label for="company_name" sm={ 2 }  className="pl-1">{ Lang[ 'ASSIGN_PLATFORM' ][ this.props.lang ] }</Label>
                <Col sm={ 8 } className="d-flex pl-0">
                  <Col sm={ 10 } className="p-0">
                    <Input
                      type="select"
                      name="platform_name" id="platform_name"
                      placeholder={ Lang[ 'ENTER_ROLE' ][ this.props.lang ] }
                      value={ this.state.tmpPlatform }
                      onChange={ e => this.setState({ tmpPlatform: e.target.value }) }
                      invalid={ false }>
                      <option value='' >{ Lang[ 'SELECT_PLACEHOLDER' ][ this.props.lang ] }</option>
                      {
                        _.filter(
                          this.props.platforms.data,
                          item => _.findIndex( this.props.selectedRolePermission.platforms, { id: item.id } ) === -1
                        ).map( platform => (
                          <option key={ platform.id } value={ platform.id }>{ platform.name }</option>
                        ))
                      }
                    </Input>
                  </Col>
                  <Col sm={ 2 } className="pl-4 pr-0">
                    <Button
                      color="primary"
                      className="mb-2 pl-3 pr-3"
                      style={{ marginLeft: '15px' }}
                      disabled={ _.isEmpty( this.state.tmpPlatform ) }
                      onClick={ () => {
                        this.props.getEditSelectedPlatform( this.state.tmpPlatform )
                        this.setState({ tmpPlatform: '' })
                      }} >
                      { Lang[ 'ASSIGN' ][ this.props.lang ] }
                    </Button>
                  </Col>
                </Col>
              </FormGroup>
              <FormGroup>
                <Label m={ 2 }  className="pl-1">{ Lang[ 'SELECTED_PLATFORM' ][ this.props.lang ] }</Label>
                <Col sm={ 8 } className="pl-0 d-flex flex-wrap">
                  {
                    this.props.selectedRolePermission.platforms?.map( item => {
                      return (
                        <div
                          style={{
                            background: '#eeeeee',
                            display: 'flex',
                            marginBottom: '20px',
                            marginRight: '15px',
                            borderRadius: '5px',
                            padding: ''
                          }}>
                          <div
                            style={{
                              background:` ${ item.id === this.state.platformID ? '#30b1ff' : '#eeeeee' }`,
                              padding: '3px 5px 0px 10px',
                            }}
                            onClick={ () => this.setState({ platformID: item.id }) }>
                            <Label className="mr-3 mb-1">{ item.name }</Label>
                          </div>
                          <Button
                            className="m-0 pt-1 pr-2 pb-1 pl-1"
                            style={{
                              border: 'none',
                              background: 'transparent',
                              color: '#000',
                              borderRadius: '0px',
                              background:` ${ item.id === this.state.platformID ? '#30b1ff' : '#eeeeee' }`
                            }}
                            onClick={ () => this.onChangeRoleData( 
                              'platforms', 
                              _.filter( this.props.selectedRolePermission.platforms, platform => platform.id !== item.id )
                            )}>
                            X
                          </Button>
                        </div>

                      )
                    })
                  }
                </Col>
              </FormGroup>
              <FormGroup>
                <Label m={ 2 }  className="pl-1">{ Lang[ 'PERMISSION_SETTING' ][ this.props.lang ] }</Label>
                <Col sm={ 9 } className="pl-0" style={{ overflow: 'auto' }}>
                  <table className="table table-bordered" style={{ textAlign: 'center' }}>
                    <thead>
                      <tr>
                        <th>{ Lang[ 'NAME' ][ this.props.lang ] }</th>
                        <th>{ Lang[ 'CREATE' ][ this.props.lang ] }</th>
                        <th>{ Lang[ 'READ' ][ this.props.lang ] }</th>
                        <th>{ Lang[ 'UPDATE' ][ this.props.lang ] }</th>
                        <th>{ Lang[ 'DELETE' ][ this.props.lang ] }</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.props.selectedRolePermission.platforms?.map( platform => {
                          return platform.modules && _.sortBy( platform.modules, [ 'name' ], [ 'asc' ] ).map( item => {
                            if( platform.id === this.state.platformID ){
                              return (
                                <tr key={ item.id }>
                                  <td>{ item.name }</td>
                                  <td>
                                    <Label>
                                      <Input
                                        type="checkbox"
                                        checked={ item.is_create }
                                        style={{
                                          margin: '0 auto'
                                        }}
                                        onChange={ () => {
                                          let tmpPlatformIndex = _.findIndex( this.props.selectedRolePermission.platforms, { id: platform.id } )
                                          let tmpPlatform = this.props.selectedRolePermission.platforms
                                          let tmpModules = tmpPlatform[tmpPlatformIndex].modules
                                          let tmp = _.find( tmpModules, { id: item.id } )
                                          let tmpModuleIndex = _.findIndex( tmpModules, { id: item.id } )
                                          tmp[ 'is_create' ] = !item.is_create
                                          tmpModules.splice( tmpModuleIndex, 1, tmp )
                                          tmpPlatform[tmpPlatformIndex][ 'modules' ] = tmpModules
                                          this.onChangeRoleData( 'platforms', tmpPlatform )
                                        }}/>{ '' }
                                    </Label>
                                  </td>
                                  <td>
                                    <Label>
                                      <Input
                                        type="checkbox"
                                        checked={ item.is_read }
                                        style={{
                                          margin: '0 auto'
                                        }}
                                        onChange={ () => {
                                          let tmpPlatformIndex = _.findIndex( this.props.selectedRolePermission.platforms, { id: platform.id } )
                                          let tmpPlatform = this.props.selectedRolePermission.platforms
                                          let tmpModules = tmpPlatform[tmpPlatformIndex].modules
                                          let tmp = _.find( tmpModules, { id: item.id } )
                                          let tmpModuleIndex = _.findIndex( tmpModules, { id: item.id } )
                                          tmp[ 'is_read' ] = !item.is_read
                                          tmpModules.splice( tmpModuleIndex, 1, tmp )
                                          tmpPlatform[tmpPlatformIndex][ 'modules' ] = tmpModules
                                          this.onChangeRoleData( 'platforms', tmpPlatform )
                                        }}/>{ '' }
                                    </Label>
                                  </td>
                                  <td>
                                    <Label>
                                      <Input
                                        type="checkbox"
                                        checked={ item.is_update }
                                        style={{
                                          margin: '0 auto'
                                        }}
                                        onChange={ () => {
                                          let tmpPlatformIndex = _.findIndex( this.props.selectedRolePermission.platforms, { id: platform.id } )
                                          let tmpPlatform = this.props.selectedRolePermission.platforms
                                          let tmpModules = tmpPlatform[tmpPlatformIndex].modules
                                          let tmp = _.find( tmpModules, { id: item.id } )
                                          let tmpModuleIndex = _.findIndex( tmpModules, { id: item.id } )
                                          tmp[ 'is_update' ] = !item.is_update
                                          tmpModules.splice( tmpModuleIndex, 1, tmp )
                                          tmpPlatform[tmpPlatformIndex][ 'modules' ] = tmpModules
                                          this.onChangeRoleData( 'platforms', tmpPlatform )
                                        }}/>{ '' }
                                    </Label>
                                  </td>
                                  <td>
                                    <Label>
                                      <Input
                                        type="checkbox"
                                        checked={ item.is_delete }
                                        style={{
                                          margin: '0 auto'
                                        }}
                                        onChange={ () => {
                                          let tmpPlatformIndex = _.findIndex( this.props.selectedRolePermission.platforms, { id: platform.id } )
                                          let tmpPlatform = this.props.selectedRolePermission.platforms
                                          let tmpModules = tmpPlatform[tmpPlatformIndex].modules
                                          let tmp = _.find( tmpModules, { id: item.id } )
                                          let tmpModuleIndex = _.findIndex( tmpModules, { id: item.id } )
                                          tmp[ 'is_delete' ] = !item.is_delete
                                          tmpModules.splice( tmpModuleIndex, 1, tmp )
                                          tmpPlatform[tmpPlatformIndex][ 'modules' ] = tmpModules
                                          this.onChangeRoleData( 'platforms', tmpPlatform )
                                        }}/>{ '' }
                                    </Label>
                                  </td>
                                </tr>
                              )
                            }
                          })
                        })
                      }
                    </tbody>
                  </table>
                </Col>
              </FormGroup>
              <Button 
                className="mb-2 mr-2 mt-5" 
                color="alternate" 
                onClick={ () => this.props.updateSelectedRole( this.props.selectedRolePermission ) } 
                style={{ float: 'right' }}>
                { Lang[ 'SAVE' ][ this.props.lang ] }
              </Button>
            </Form>
          </CardBody>
        </Card>
      </Container>
    )
  }
}

export default EditRoleForm
