import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import moment from 'moment'

import { convertObjToBase64 } from 'utils/objToBase64'

import { Get, Post, outdatedToken, Put, Delete } from 'utils/axios'
import { getRefreshToken } from 'actions/token'

import Lang from 'Lang/General'

const MediaDBHOC = ( WrappedComponent ) => {
  class MediaDBWrappedComponent extends Component {
    state = {
      loading: false,
      updateLoading: false,
      media: [],
      mediaTags: [],
      newUploadMedia: [],
      ids: {},
      checkedForFilter: [],
      tags: [],
      selectedImages: [],
      searchQuery: {},
      page: 1,
      totalPages: []
    }

    load = param => this.setState({ loading: param })
    updateLoad = param => this.setState({ updateLoading: param })
    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    ajaxError = ( error ) => {
      outdatedToken( error, this.props.history )
      this.requestError( error )
    }

    onChangeData = ( key, value ) => {
      this.setState({ [key]: value })
    }

    getMedia = ( query ) => Get(
      `/api/v1/shared_media?query=${ query }`,
      this.getMediaSuccess,
      this.getMediaError,
      this.load
    )

    getMediaSuccess = payload => {
      let tmpTotalPages = []
      if ( payload.data.length > 0 ){
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }else{
        this.requestError( Lang[ 'NO_RESULT_FOUND' ][ this.props.data.languageReducer.lang ] )
      }
      this.setState({ media: payload, totalPages: tmpTotalPages })
    }

    getMediaError = error => this.ajaxError( error )

    getNewUploadMedia = date => {
      let tmp = {
        'created_at': date
      }
      let queryJSON = JSON.stringify( tmp )
      let query64 = Buffer.from( queryJSON ).toString( 'base64' )
      Get(
        `/api/v1/shared_media?query=${ query64 }`,
        this.getNewUploadMediaSuccess,
        this.getNewUploadMediaError,
        this.load
      )
    }
    getNewUploadMediaSuccess = payload => this.setState({ newUploadMedia: payload })

    getNewUploadMediaError = error => this.ajaxError( error )

    getFilter = ( key, value ) => {
      let tmp = _.cloneDeep( this.state.searchQuery )
      let tmpQuery = {
        ...tmp,
        [key]: value,
        page: 1,
        is_paginated: true
      }
      this.setState({ searchQuery: tmpQuery })

      this.getMedia( convertObjToBase64( tmpQuery ) )
    }

    postMedia = ( data ) => {
      Post(
        `/api/v1/shared_media`,
        data,
        this.postMediaSuccess,
        this.postMediaError,
        this.load
      )
    }
    postMediaSuccess = payload => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'IMAGES_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getMedia( convertObjToBase64( tmp ) )
      this.getTags()
      this.getNewUploadMedia( moment( new Date() ).format( 'YYYY-MM-DD' ) )
    }
    postMediaError = error => this.ajaxError( error )

    updateMedia = data => Put(
      `/api/v1/shared_media/${ data.id }`,
      data,
      this.updateMediaSuccess,
      this.updateMediaError,
      this.load
    )
    updateMediaSuccess = payload => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'IMAGES_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getMedia( convertObjToBase64( tmp ) )
      this.getTags()
      this.getNewUploadMedia( moment( new Date() ).format( 'YYYY-MM-DD' ) )
    }
    updateMediaError = error => this.ajaxError( error )

    deleteMedia = ids => {
      Delete(
        `/api/v1/shared_media`,
        this.deleteMediaSuccess,
        this.deleteMediaError,
        this.load,
        ids
      )
    }
    deleteMediaSuccess = payload => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'IMAGES_REMOVE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getMedia( convertObjToBase64( tmp ) )
      this.getNewUploadMedia( moment( new Date() ).format( 'YYYY-MM-DD' ) )
      this.setState({ selectedImages: [] })
    }
    deleteMediaError = error => this.ajaxError( error )

    getTags = () => Get(
      `/api/v1/shared_media_tags`,
      this.getTagsSuccess,
      this.getTagsError,
      this.load
    )
    getTagsSuccess = payload => {
      if( payload && payload.length > 0 ) {
        let data = []
        payload.map( ( option ) => {
          let tmp = {
            id: option.id,
            key: option.id,
            label: option.name,
            value: option.name
          }
          data.push( tmp )
        })
        return this.setState({ mediaTags: data })
      }
    }
    getTagsError = error => this.ajaxError( error )

    updateTag = data => Put(
      `/api/v1/shared_media_tags/${ data.id }`,
      data,
      this.updateTagSuccess,
      this.updateTagError,
      this.load
    )
    updateTagSuccess = () => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'TAG_UPDATED_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getTags()
      this.getMedia( convertObjToBase64( tmp ) )
      this.getNewUploadMedia( moment( new Date() ).format( 'YYYY-MM-DD' ) )
      this.setState({ selectedImages: [] })
    }
    updateTagError = error => this.ajaxError( error )

    removeTag = id => Delete(
      `/api/v1/shared_media_tags/${ id }`,
      this.removeTagSuccess,
      this.removeTagError,
      this.load
    )
    removeTagSuccess = payload => {
      let tmp = {
        page: 1,
        is_paginated: true
      }
      this.requestSuccess( Lang[ 'TAG_REMOVED_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getTags()
      this.getMedia( convertObjToBase64( tmp ) )
      this.getNewUploadMedia( moment( new Date() ).format( 'YYYY-MM-DD' ) )
      this.setState({ selectedImages: [] })
    }
    removeTagError = error => this.ajaxError( error )

    render = () => {
      return(
        <>
          <WrappedComponent
            { ...this.props }
            getMedia={ this.getMedia }
            media={ this.state.media }
            newUploadMedia={ this.state.newUploadMedia }
            getNewUploadMedia={ this.getNewUploadMedia }
            onLoadMedia={ this.state.loading }
            onLoadMediaTags={ this.state.loading }
            getTags={ this.getTags }
            mediaTags={ this.state.mediaTags }
            postMedia={ this.postMedia }
            updateMedia={ this.updateMedia }
            deleteMedia={ this.deleteMedia }
            getFilter={ this.getFilter }
            ids={ this.state.ids }
            tags={ this.state.tags }
            totalPages={ this.state.totalPages }
            onChangeData={ this.onChangeData }
            searchQuery={ this.state.searchQuery }
            updateTag={ this.updateTag }
            removeTag={ this.removeTag }
            selectedImages={ this.state.selectedImages } />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    getRefreshToken
  } )( MediaDBWrappedComponent )
}

export default MediaDBHOC
