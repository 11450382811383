import React, { useEffect } from 'react'
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button, UncontrolledTooltip, Card
} from 'reactstrap'
import _ from 'lodash'
import ReactTable from 'react-table'

import PageTitle from 'components/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import DeleteConfirmation from 'components/Modal/delete'
import Lang from 'Lang/General'

import OnDemandCategoryForm from './Form'
import WithOnDemandCategoriesHOC from './actions.js'

const OnDemandCategories = props => {
  useEffect( () => {
    props.getOnDemandCategories()
  }, [] )

  useEffect( () => {
    if( !props.showCreateOnDemandCategory ){
      props.onChangeOnDemandCategoriesHOC( 
        'newOnDemandCategory', 
        { 
          name: { en: '', de: '', it: '', nl: '' }, 
          status: 'ACTIVE'
        } 
      )
    }
  }, [ props.showCreateOnDemandCategory ] )

  useEffect( () => {
    if( !props.showUpdateOnDemandCategory ){
      props.onChangeOnDemandCategoriesHOC( 'selectedOnDemandCategory', {} )
    }
  }, [ props.showUpdateOnDemandCategory ] )

  const handleSecurityModulesCreate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Member Area' })
      return tmpModules.is_create
    }
  }

  const handleSecurityModulesUpdate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Member Area' })
      return tmpModules.is_update
    }
  }

  const handleSecurityModulesDelete= () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Member Area' })
      return tmpModules.is_delete
    }
  }

  const renderCreateOnDemandCategoryModal = () => (
    <Modal size='md' isOpen={ props.showCreateOnDemandCategory } >
      <ModalHeader toggle={ () => props.onChangeOnDemandCategoriesHOC( 'showCreateOnDemandCategory', false ) } >
        { Lang[ 'CREATE' ][ props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        <OnDemandCategoryForm
          onDemandCategoryType='newOnDemandCategory'
          currentOnDemandCategory={ props.newOnDemandCategory }
          onChangeOnDemandCategoriesHOC={ props.onChangeOnDemandCategoriesHOC }
          lang={ props.data.languageReducer.lang }
        />
        { props.onLoadOnDemandCategoriesHOC && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => props.createOnDemandCategory( props.newOnDemandCategory ) }>
          { Lang[ 'SUBMIT' ][ props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )

  const renderUpdateOnDemandCategoryModal = () => (
    <Modal size='md' isOpen={ props.showUpdateOnDemandCategory } >
      <ModalHeader toggle={ () => props.onChangeOnDemandCategoriesHOC( 'showUpdateOnDemandCategory', false ) } >
        { Lang[ 'UPDATE' ][ props.data.languageReducer.lang ] }
      </ModalHeader>
      <ModalBody>
        <OnDemandCategoryForm
          onDemandCategoryType='selectedOnDemandCategory'
          currentOnDemandCategory={ props.selectedOnDemandCategory }
          onChangeOnDemandCategoriesHOC={ props.onChangeOnDemandCategoriesHOC }
          lang={ props.data.languageReducer.lang }
        />
        { props.onLoadOnDemandCategoriesHOC && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={ () => props.updateOnDemandCategory( props.selectedOnDemandCategory ) }>
          { Lang[ 'SUBMIT' ][ props.data.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )

  return(
    <>
      <PageTitle
        heading={ Lang[ 'ON_DEMAND_CATEGORIES' ][ props.data.languageReducer.lang ] }
        icon="pe-7s-users icon-gradient bg-happy-fisher"
        buttons={[
          {
            buttonText: Lang[ 'CREATE' ][ props.data.languageReducer.lang ],
            onClick: () => props.onChangeOnDemandCategoriesHOC( 'showCreateOnDemandCategory', true ),
            display: handleSecurityModulesCreate()
          }
        ]}/>
      <Card className='p-4'>
        <ReactTable
          showPagination
          pageSize={ 10 }
          data={ props.onDemandCategories }
          columns={[
            {
              Header: Lang[ 'NAME' ][ props.data.languageReducer.lang ],
              accessor: "name",
              Cell: ({ value }) => value[ props.data.languageReducer.lang ] 
            },
            {
              Header: Lang[ 'STATUS' ][ props.data.languageReducer.lang ],
              accessor: "status",
              Cell: ({ value }) => Lang[ value ][ props.data.languageReducer.lang ] 
            },
            {
              Header: Lang[ 'ACTIONS' ][ props.data.languageReducer.lang ],
              accessor: "id",
              Cell: ({ value }) => (
                <div className='d-flex w-100 justify-content-center' >
                  {
                    handleSecurityModulesUpdate() && (
                      <>
                        <Button
                          id={ `OnDemandCategory-${ value }-update` }
                          className="mb-2 mr-2 btn-icon btn-icon-only"
                          color="primary"
                          onClick={ () => props.getSelectedOnDemandCategory( value ) }>
                          <i className="pe-7s-note"> </i>
                        </Button>
                        <UncontrolledTooltip
                          target={ `OnDemandCategory-${ value }-update` }
                          placement="top">{ Lang[ 'EDIT' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                      </>
                    )
                  }
                  {
                    handleSecurityModulesDelete() && (
                      <>
                        <Button
                          id={ `OnDemandCategory-${ value }-archive-onDemandCategory` }
                          className="mb-2 mr-2 btn-icon btn-icon-only"
                          color="danger"
                          onClick={ () => props.onChangeOnDemandCategoriesHOC( 'showDeleteOnDemandCategoryId', parseInt( value ) ) }>
                          <i className="pe-7s-trash"> </i>
                        </Button>
                        <UncontrolledTooltip
                          target={ `OnDemandCategory-${ value }-archive-onDemandCategory` }
                          placement="top">{ Lang[ 'REMOVE' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                      </>
                    )
                  }
                </div>
              )
            }
          ]}
        />
      </Card>
      <DeleteConfirmation
        handleModalClose={ () => props.onChangeOnDemandCategoriesHOC( 'showDeleteOnDemandCategoryId', 0 ) }
        deleteOpen={ props.showDeleteOnDemandCategoryId > 0 }
        confirmAction={ () => props.removeOnDemandCategory( props.showDeleteOnDemandCategoryId ) }
        content={ Lang[ 'DELETE_CONFIRMATION_ON_DEMAND_CATEGORY' ][ props.data.languageReducer.lang ] }
        lang={ props.data.languageReducer.lang } />
      { renderCreateOnDemandCategoryModal() }
      { renderUpdateOnDemandCategoryModal() }
      { props.onLoadOnDemandCategoriesHOC && <LoadingOverlay/> }
    </>
  )
}

export default WithOnDemandCategoriesHOC( OnDemandCategories )