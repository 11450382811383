import React from 'react'
import Radium from 'proofpoint-radium'

import CoreRenderFunction from '../../core/render'

const UL = ({
  children,
  id,
  style,
  className,
  languages,
  selectedLanguageShortname,
  updateMainContainerState
}) => {
  return (
    <ul
      id={ id }
      style={ style }
      className={ className }>
      {
        children && children.length > 0 && children.map( item => {
          return (
            <CoreRenderFunction
              item={ item }
              updateMainContainerState={ updateMainContainerState }
              languages={ languages }
              selectedLanguageShortname={ selectedLanguageShortname } />
          )
        })
      }
    </ul>
  )
}

export default Radium( UL )