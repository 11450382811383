import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get, Post, Put, DownloadFile } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const AVContractHOC = ( WrappedComponent ) => {
  class AVContractWrappedComponent extends Component {
    state={
      loading: false,
      contracts: [],
      newContract: {
        client_id: 0,
        gdpr_template_id: 0,
        variable_fields: {
          auth_person: '',
          company_name_address: '',
          agreement_number: ''
        },
        is_new_contract: false,
        agreed_datetime: null,
        contract_pdf_base64: ''
      },
      selectedContract: {},
      showViewContract: false,
      showCreateContract: false,
      showEditContract: false,
      contractTotalPages: [],
      activeTab: 1,
      clients: [],
      clientOptions: [],
      searchParams: {}
    }

    onChangeAVContractHOC = ( key, value ) => this.setState({ [ key ]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( item => toast.error( item.replaceAll( '_', ' ' ) ) )

    getAllContracts = query => Get(
      `/api/v1/gdpr_av_contracts?query=${ convertObjToBase64( query ) }`,
      this.getAllContractsSuccess,
      this.getAllContractsError,
      this.load
    )
    getAllContractsSuccess = payload => {
      let tmpTotalPages = []
      if ( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      this.setState({ contracts: payload, contractTotalPages: tmpTotalPages })
    }
    getAllContractsError = error => this.requestError( error )

    postContract = data => Post(
      `/api/v1/gdpr_av_contracts`,
      data,
      this.postContractSuccess,
      this.postContractError,
      this.load
    )
    postContractSuccess = payload => {
      this.setState({ showCreateContract: false })
      this.requestSuccess( Lang[ 'CONTRACT_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getAllContracts({
        page: 1,
        is_paginated: true
      })
    }
    postContractError = error => this.requestError( error )

    getSelectedContract = id => Get(
      `/api/v1/gdpr_av_contracts/${ id }`,
      this.getSelectedContractSuccess,
      this.getSelectedContractError,
      this.load
    )
    getSelectedContractSuccess = payload => this.setState({ selectedContract: payload })
    getSelectedContractError = error => this.requestError( error )

    updateContract = ( id, data ) => Put(
      `/api/v1/gdpr_av_contracts/${ id }`,
      data,
      this.updateContractSuccess,
      this.updateContractError,
      this.load
    )
    updateContractSuccess = payload => {
      this.requestSuccess( Lang[ 'CONTRACT_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showEditContract: false })
      this.getAllContracts({
        page: 1,
        is_paginated: true
      })
    }
    updateContractError = error => this.requestError( error )

    downloadContractPDF = ( id, data ) => DownloadFile(
      `/api/v1/gdpr_av_contracts/${ id }/pdf`,
      data.client.name,
      this.downloadContractPDFError,
      this.load
    )
    downloadContractPDFError = error => this.requestError( error )

    getClients = query => Get(
      `/api/v1/clients?query=${ convertObjToBase64( query ) }`,
      this.getClientsSuccess,
      this.getClientsError,
      this.load
    )
    getClientsSuccess = payload => {
      let tmp = []
      payload.data && payload.data.length > 0 && payload.data.map( item => {
        tmp.push({
          id: item.id,
          label: `${ item.company_name } (${ item.house_number },${ item.street },${ item.city },${ item.country })`,
          value: `${ item.company_name } (${ item.house_number },${ item.street },${ item.city },${ item.country })`
        })
      })
      this.setState({ clients: payload.data, clientOptions: tmp })
    }
    getClientsError = error => this.requestError( error )

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            { ...this.props }
            activeTab={ this.state.activeTab }
            contracts={ this.state.contracts }
            newContract={ this.state.newContract }
            selectedContract={ this.state.selectedContract }
            showCreateContract={ this.state.showCreateContract }
            showEditContract={ this.state.showEditContract }
            showViewContract={ this.state.showViewContract }
            onChangeAVContractHOC={ this.onChangeAVContractHOC }
            contractTotalPages={ this.state.contractTotalPages }
            clients={ this.state.clients }
            clientOptions={ this.state.clientOptions }
            getAllContracts={ this.getAllContracts }
            postContract={ this.postContract }
            getSelectedContract={ this.getSelectedContract }
            updateContract={ this.updateContract }
            downloadContractPDF={ this.downloadContractPDF }
            getClients={ this.getClients }
            searchParams={ this.state.searchParams }
            onLoadAVContracts={ this.state.loading } />
        </Fragment>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( AVContractWrappedComponent )
}

export default AVContractHOC
