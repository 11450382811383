import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { convertObjToBase64 } from 'utils/objToBase64'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      sharedMediaDrives: [],
      sharedMediaDrivesPayload: [],
      mediaDrivesTotalPages: 0,
      sharedMediaPath: [
        {
          label: 'Home',
          nav: () => {
            this.getSharedMediaDrive( convertObjToBase64({
              page: 1,
              is_paginated: true
            }) )
            let foundIndex = _.findIndex( this.state.sharedMediaPath, { label: 'Home' } )
            let tmp = _.slice( this.state.sharedMediaPath, 0, foundIndex + 1 )
            this.setState({ sharedMediaPath: tmp, sharedMediaParentFolder: { id: 1, name: 'Home' } })
          }
        }
      ],
      sharedMediaParentFolder: {},
      name: ''
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( item => toast.error( item.replaceAll( '_', ' ' ) ) )

    onChangeSharedMediaHOC = ( key, value ) => this.setState({ [key]: value })

    getSharedMediaDrive = ( query ) => Get(
      `/api/v1/mfiles/drive?query=${ query }`,
      this.getSharedMediaDriveSuccess,
      this.getSharedMediaDriveError,
      this.load
    )
    getSharedMediaDriveSuccess = payload => {
      let tmp = {
        id: payload.data.parent_folder_id,
        name: payload.data.parent_folder_name
      }
      let tmpTotalPages = []
      if( payload.data.items && payload.data.items.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      this.setState({ sharedMediaDrives: payload.data, sharedMediaParentFolder: tmp, mediaDrivesTotalPages: tmpTotalPages, sharedMediaDrivesPayload: payload })
    }
    getSharedMediaDriveError = error => this.requestError( error )

    getSelectedSharedFolder = id => Get(
      `/api/v1/mfiles/drive/folders/${ id }`,
      this.getSelectedSharedFolderSuccess,
      this.getSelectedSharedFolderError,
      this.load
    )
    getSelectedSharedFolderSuccess = payload => this.setState({ sharedMediaDrives: payload })
    getSelectedSharedFolderError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            onLoadMediaDrive={ this.state.loading }
            onChangeSharedMediaHOC={ this.onChangeSharedMediaHOC }
            getSharedMediaDrive={ this.getSharedMediaDrive }
            getSelectedSharedFolder={ this.getSelectedSharedFolder }
            mediaDrivesTotalPages={ this.state.mediaDrivesTotalPages }
            sharedMediaDrives={ this.state.sharedMediaDrives }
            sharedMediaPath={ this.state.sharedMediaPath }
            sharedMediaParentFolder={ this.state.sharedMediaParentFolder }
            sharedMediaDrivesPayload={ this.state.sharedMediaDrivesPayload }
            name={ this.state.name } />
        </>
      )
    }
  }
  return WithHOC
}

export default HOC
