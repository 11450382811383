import { GET_SELECTED_ROLE, CLEAR_SELECTED_ROLE } from 'actions/type'

let initialState = {
  role: {}
}

export default ( state = initialState, action ) => {
  switch( action.type ) {
    case GET_SELECTED_ROLE:
      return {
        ...state,
        role: action.payload
      }
    case CLEAR_SELECTED_ROLE:
      return {
        ...state,
        role: {}
      }
    default: return state
  }
}
