import React, { useState, useEffect } from "react"
import _ from "lodash"
import { Input, Button, Label } from "reactstrap"

import Lang from "Lang/General"

const ImageDisplay = props => {
  let { 
    showMediaDatabase, 
    url, 
    lang,
    removeImage,
  } = props

  const [ actualImgDimension, setActualImgDimension ] = useState({})
  const [ isHover, setHover ] = useState( false )

  const getImgDimension = imgSrcURL => {
    let img = new Image()
    img.onload = () =>
      setActualImgDimension({
        width: img.width,
        height: img.height,
      })
    img.src = imgSrcURL.replace(/url\(|\)$|"/gi, "")
  }

  useEffect(() => {
    if ( url ) {
      getImgDimension( url )
    }
  }, [ url ])

  return (
    <>
      <Input
        disabled
        type="text"
        value={url}
        style={{
          borderRadius: "0.25rem 0.25rem 0 0",
        }}
      />
      <div
        onMouseEnter={ () => setHover( true ) }
        onMouseLeave={ () => setHover( false ) }
        style={{
          width: "100%",
          height: url ? "200px" : "0",
          backgroundImage: _.isEmpty( url ) ? "" : `url(${ url })`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center center",
          border: "1px solid #bdbdbd",
          borderTopWidth: "0",
          borderRadius: "0 0 0.25rem 0.25rem",
          transition: "all 0.25s ease-in"
        }}
      >
        {
          url && (
            <div 
              style={{
                display: isHover ? "block" : "none",
                backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0))",
                padding: "10px 10px 20px"
              }}>
              <Label style={{ color: "#fff" }}>
                <span style={{ display: "block" }}>
                  {`${ Lang[ "FILE_NAME" ][ lang ] }: `}
                  { url.substring( url.lastIndexOf('/')+1 ) }
                </span>
                {`${ Lang[ "ACTUAL_IMAGE_SIZE" ][ lang ] }: `}
                {
                  actualImgDimension.width && actualImgDimension.height
                    ? `${ actualImgDimension.width }*${ actualImgDimension.height } px`
                    : "-"
                }
              </Label>
            </div>
          )
        }
      </div>
      <Button color="primary" style={{ margin: "10px 5px 5px 0" }} onClick={ showMediaDatabase }>
        { Lang[ "SELECT_IMAGE" ][ lang ] }
      </Button>
      {
        typeof removeImage !== "undefined" && 
        <Button color="danger" disabled={ !url } style={{ margin: "10px 5px 5px 0" }} onClick={ removeImage }>
          { Lang[ "REMOVE_IMAGE" ][ lang ] }
        </Button>
      }
    </>
  )
}

export default ImageDisplay
