import React from 'react'
import {
  FormGroup,
  Label,
  Input,
  UncontrolledTooltip
} from 'reactstrap'
import _ from 'lodash'
import ReactHTMLParser from 'react-html-parser'

import RenderDOM from '../RenderDOM'
import { generateFieldLabelStr } from '../utils'

const RadioButton = ({
  content,
  onChangeFormBuilderState,
  mode,
  formValues,
  onPatchFormValues,
  dom,
  findObjectAndPatch,
  isInputDisabled,
  currentLanguage
}) => {

  return (
    <FormGroup 
      tag="fieldset"
      style={{ padding: content.padding, ...content.style }}>
      <Label className="ck-content" style={{ fontSize: content.fontSize, width: "100%" }}>
        { ReactHTMLParser( generateFieldLabelStr(content, formValues, currentLanguage) ) }  
        {
          content.showQuickInfo && 
          document.getElementById(`quick-info-tooltip-${content.id}`) && (
            <UncontrolledTooltip
              target={`quick-info-tooltip-${content.id}`} 
              placement="top">
              { content.preview_text }
            </UncontrolledTooltip>
          )
        }
      </Label>
      {
        content.selections.map( ( item, index ) => {
          return (
            <>
            <FormGroup 
              key={ item.id }
              check
              onClick={ async e => {
                const previousSelection = _.find(content.selections, { value: formValues[ content.dataMapIndex ][ 'value' ] })
                if( previousSelection && previousSelection.value === e.target.value ){
                  await onPatchFormValues( content.dataMapIndex, 'value', '' )
                }
              }}
              onChange={ async e => {
                const previousSelection = _.find(content.selections, { value: formValues[ content.dataMapIndex ][ 'value' ] })
                await onPatchFormValues( content.dataMapIndex, 'value', e.target.value )
                if ( previousSelection && previousSelection.dependentField && previousSelection.dependentField.id ) {
                  onPatchFormValues( previousSelection.dependentField.dataMapIndex, 'value', '' )
                }
              }}
              value={ formValues[ content.dataMapIndex ][ 'value' ] }>
              <Label check>
                <Input 
                  type="radio" 
                  disabled={ isInputDisabled }
                  name={ content.id } 
                  value={ item.value }
                  checked={ item.value === formValues[ content.dataMapIndex ][ 'value' ] } />
                {' '}
                { content.type.indexOf( 'Flexible' ) !== -1 && index === content.selections.length - 1
                  ? <Input
                      type='text'
                      disabled={ isInputDisabled }
                      value={ item.value === formValues[ content.dataMapIndex ][ 'value' ] ? item.label[ currentLanguage ] : '' }
                      readOnly= { formValues[ content.dataMapIndex ][ 'value' ].indexOf( item.value ) > -1 ? false : true }
                      style={{ 
                        height: "auto",
                        border: "none",
                        borderRadius: "0",
                        padding: "0",
                        fontSize: "14px",
                        borderBottom: "1px dotted #000000" }}
                      onChange={ async e => {
                        let tmpFormValues = _.cloneDeep( formValues )
                        let tmpDOM = _.cloneDeep( dom )
                        let tmpContent = _.cloneDeep( content )
                        let tmpValue = _.cloneDeep( formValues[ content.dataMapIndex ][ 'value' ] )

                        tmpValue = e.target.value.replaceAll( ' ', '-' )
                        tmpFormValues[ content.dataMapIndex ][ 'value' ] = tmpValue

                        tmpContent.selections[ index ][ 'value' ] = e.target.value.replaceAll( ' ', '-' )
                        tmpContent.selections[ index ][ 'label' ][ currentLanguage ] = e.target.value

                        await findObjectAndPatch( tmpDOM, tmpContent )
                        Promise.all([
                          onChangeFormBuilderState( 'dom', tmpDOM ),
                          onChangeFormBuilderState( 'formValues', tmpFormValues )
                        ])
                      }}/>
                  : item.label[ currentLanguage ] }
              </Label>
            </FormGroup>
            {
              item.value === formValues[ content.dataMapIndex ][ 'value' ]
              && item.dependentField
              && item.dependentField.id 
              && <RenderDOM                       
                  content={ item.dependentField }
                  onChangeFormBuilderState={ onChangeFormBuilderState }
                  mode={ mode }
                  formValues={ formValues }
                  onPatchFormValues={ onPatchFormValues }
                  dom={ dom }
                  findObjectAndPatch={ findObjectAndPatch }
                  isInputDisabled={ isInputDisabled }
                  currentLanguage={ currentLanguage }
                />
            }
            </>
          )
        })
      }
      
    </FormGroup>
  )
}

export default RadioButton