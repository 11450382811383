import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { GetWithNonce } from 'utils/axios-nonce'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      countries: [],
    }

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    } )
    requestError = error => {
      toast.error( error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false
    } )}

    getCountries = query => GetWithNonce(
      `/api/v1/countries?region=Europe&language=DEU`,
      this.getCountriesSuccess,
      this.getCountriesError,
      this.load
    )
    getCountriesSuccess = payload => {
      let tmp = _.cloneDeep( this.state.countries )
      payload.length > 0 && payload.map( item => {
        tmp.push({
          id: item.id,
          label: item.name,
          value: item.name
        })
      })
      return this.setState({ countries: tmp })
    }
    getCountriesError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            onLoadCountries={ this.state.loading }
            countries={ this.state.countries }
            getCountries={ this.getCountries } />
        </>
      )
    }
  }

  const mapStateToProps = state => ({ data: state })
  return connect ( mapStateToProps )( WithHOC )
}

export default HOC
