import React, { Component } from 'react'
import Radium from 'proofpoint-radium'

import { Get, Put, Post, Delete } from 'utils/axios-template'
import { Get as ArkGet } from 'utils/axios'
import { production_url } from '../../../config'

const SectionHOC = ( WrappedComponent ) => {
  class SectionWrappedComponent extends Component {
    state = {
      selectedSection: {},
      loading: false,
      showStatusModal: false,
      statusModalType: '',
      onClickStatusModalButton: () => {},
      statusModalMessage: ''
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showStatusModal: true,
      statusModalType: 'warning-custom',
      onClickStatusModalButton: () => this.setState({ showStatusModal: false }),
      statusModalMessage: error
    })
    requestSuccess = success => this.setState({
      showStatusModal: true,
      statusModalType: 'positive',
      onClickStatusModalButton: () => this.setState({ showStatusModal: false }),
      statusModalMessage: success
    })

    getSelectedSection = id => Get(
      `${ production_url }/sections/${ id }`,
      this.getSelectedSectionSuccess,
      this.getSelectedSectionError,
      this.load
    )
    getSelectedSectionSuccess = payload => {
      this.setState({ selectedSection: payload.content })
    }
    getSelectedSectionError = error => this.requestError( error )

    getSelectedLandingPageSection = ( page_id, id ) =>
      ArkGet(
        `/api/v1/pages/${ page_id }/ols_sections/${ id }`,
        this.getSelectedLandingPageSectionSuccess,
        this.getSelectedLandingPageSectionError,
        this.load
      )
    getSelectedLandingPageSectionSuccess = payload => this.setState({ selectedSection: payload.content })
    getSelectedLandingPageSectionError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadSection={ this.state.loading }
          getSelectedSection={ this.getSelectedSection }
          getSelectedLandingPageSection= { this.getSelectedLandingPageSection }
          selectedSection={ this.state.selectedSection } />
      )
    }
  }
  return Radium( SectionWrappedComponent )
}

export default SectionHOC