import Radium from 'proofpoint-radium'
import CoreRenderFunction from "../../core/render"
import React from 'react'
import _ from 'lodash'

const FooterImage = props => {
  return (
    <div
      className={ props.item.className }
      style={{
        ...props.item.style, 
        order: props.item.mobileArrangement,
        '@media ( min-width: 768px )': {
          order: props.item.desktopArrangement
        }
      }}>
      {
        props.item.children.map( child => {
          let tmpChild = _.cloneDeep( child )
          tmpChild.type = "img"
          return (
            <CoreRenderFunction
              item={ tmpChild }
              mode={ props.mode }
              selectedLanguageShortname={ props.selectedLanguageShortname }
            />
          ) 
        })
      }
    </div>
  )
}

export default Radium( FooterImage )