import React, { useState } from 'react'
import { Card, CardHeader, CardBody, Button } from 'reactstrap'
import Lang from 'Lang/General'

const PackageSelect = props => {
  const [ open, toggleOpen ] = useState( false )
  return (
    <Card>
      <CardHeader className='d-flex justify-content-between flex-wrap' style={{ height: "fit-content" }} >
        <span className="mr-2">{ props.item.name }</span>
        <div style={{ minWidth: "fit-content" }} >
          <Button 
            color="primary"
            className="btn-icon btn-icon-only mr-2"
            onClick={() => {
              toggleOpen( !open )
            }}>
            <i className="pe-7s-angle-down"></i>
          </Button>
          {
            props.value === props.item.id 
              ? (
                <>
                  <Button 
                    color="success"
                    className='mr-2'
                    onClick={ () => {} }>
                    { Lang[ 'SELECTED' ][ props.lang ] }
                  </Button>
                  <Button 
                    color="danger"
                    onClick={ props.onRemove }>
                    { Lang[ 'REMOVE' ][ props.lang ] }
                  </Button>
                </>
              ) : (
                <Button 
                  color="primary"
                  className='m-0'
                  onClick={ props.onSelect }>
                  { Lang[ 'SELECT_BUTTON' ][ props.lang ] }
                </Button>
              )
          }
        </div>
      </CardHeader>
      {
        open && (
          <CardBody>
            <ul>
            {
              props.item.modules.map( mod => {
                return (
                  <li key={ mod.id }>{ mod.name }</li>
                )
              })
            }
            </ul>
          </CardBody>
        )
      }
    </Card>
  )
}

export default PackageSelect