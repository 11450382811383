import React, { Component } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'


class LanguageDropdown extends Component {
  state = { 
    dropdownHeader: { id: 0, displayVal: "EN" , updateVal: "en" }, 
    isOpen: false,
    selectedLanguageVal: ''
  }

  handleChange = item => { 
    this.setState({ 
      dropdownHeader: item,
      isOpen: !this.state.isOpen
    }) 
    this.props.updateMainContainerState( "selectedLanguageShortname", item.updateVal )
  }

  handleClick = () => { this.setState({ isOpen: !this.state.isOpen }) }

  render = () => { 
    const languageList = [
      {
        id: 0,
        displayVal: "EN",
        updateVal: "en",
      },
      {
        id: 1,
        displayVal: "DE",
        updateVal: "de"
      },
      {
        id: 2,
        displayVal: "NL",
        updateVal: "nl"
      },
      {
        id: 3,
        displayVal: "IT",
        updateVal: "it"
      },
    ]
    return ( 
      <div style={{
          width: "100%",
          height: "22px",
          position: "relative",
          border: "1px solid #d4d4d4",
          marginLeft: "15px",
          zIndex: "1000"
        }}>
        <button 
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            display: "flex",
            padding: "3px 7px",
            border: "none",
            background: "none",
            justifyContent: "space-between",
            alignItems: "center",
            color: "inherit"
          }}
          onClick= { this.handleClick }>
          <span style={{ paddingRight: "22px" }}>{ this.state.dropdownHeader.displayVal }</span>
          { this.state.isOpen? <FaChevronUp/> : <FaChevronDown/> }
        </button>
        { this.state.isOpen && <div style={{ 
            top: "24px",
            position: "absolute",
            border: "1px solid #d4d4d4"
          }}>
          {
            languageList.map( item => {
              return <button 
                style={{ 
                  width: "100%",
                  height: "22px",
                  border: this.state.selectedLanguageVal === item.displayVal ? "1px solid #d4d4d4": "none",
                  color: "inherit",
                  background: "#ffffff"
                }}
                onClick={ () => this.handleChange(item) }
                onMouseEnter={() => { this.setState({ selectedLanguageVal: item.displayVal }) }}>
                { item.displayVal }</button>
            })
          }
        </div> }
      </div>

    )
  }
}
 
export default LanguageDropdown;