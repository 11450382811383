import React, { Component } from 'react'
import Frame from 'react-frame-component'
import Radium, { StyleRoot } from 'proofpoint-radium'

import CoreRenderFunction from '../core/render'

class HeaderMenuViewer extends Component {
  render = () => {
    const {
      templateStylesheets,
      selectedHeaderBlueprint,
      languages,
      selectedLanguageShortname
    } = this.props
    let tmp = [ JSON.parse( selectedHeaderBlueprint ) ]
    return (
      <Frame
        style={{
          width: '100%',
          height: '100%'
        }}>
        <StyleRoot>
          {
            templateStylesheets && templateStylesheets.length > 0 && templateStylesheets.map( item => {
              if( item.type === 'URL' ) {
                return <link key={ item.id } rel="stylesheet" href={ item.content }></link>
              } else {
                return <style key={ item.id }>{ item.content }</style>
              }
            })
          }
          {
            tmp &&
            tmp.length > 0 &&
            tmp.map( item => {
              return (
                <CoreRenderFunction
                  item={ item }
                  languages={ languages }
                  selectedLanguageShortname={ selectedLanguageShortname } />
              )
            })
          }
        </StyleRoot>
      </Frame>
    )
  }
}

export default Radium( HeaderMenuViewer )