import React, { useEffect } from 'react'
import { compose } from 'redux'
import ReactTable from 'react-table'
import {
  Card,
  CardBody,
  Button,
  UncontrolledTooltip
} from 'reactstrap'
import _ from 'lodash'

import PageTitle from 'components/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Pagination from 'components/Pagination/New'
import CreateProduct from './Create'
import UpdateProduct from './Update'
import ViewProduct from './View'

import WithProducts from './actions'
import WithCountries from 'actions/country'
import WithCenterGroups from 'actions/centerGroup'

import Lang from 'Lang/General'

const ProductSettings = props => {

  useEffect(() => {
    props.getProducts({
      page: 1,
      is_paginated: true
    })
    props.getCountries()
    props.getCenterGroupsForSelect({ is_paginated: false })
  }, [])

  useEffect( () => {
    if( props.location.state && props.location.state.showUpdateProduct ){
      Promise.all([
        props.onChangeProductsHOC( 'showUpdateProduct', props.location.state.showUpdateProduct )
      ]).then(
        props.onChangeProductsHOC( 'selectedProduct', props.location.state.selectedProduct )
      )
    }
  }, [])

  const handleSecurityModulesRead = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'News and Products' })
      return tmpModules.is_read
    }
  }

  const handleSecurityModulesCreate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'News and Products' })
      return tmpModules.is_create
    }
  }

  const handleSecurityModulesUpdate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'News and Products' })
      return tmpModules.is_update
    }
  }

  return (
    <>
      <PageTitle
        heading={ Lang[ 'ADMIN_PANEL_PRODUCTS' ][ props.data.languageReducer.lang ] }
        icon="pe-7s-cart icon-gradient bg-happy-fisher"
        buttons={[
          handleSecurityModulesCreate() && {
            buttonText: Lang[ 'CREATE_PRODUCTS' ][ props.data.languageReducer.lang ],
            onClick: () => {
              props.onChangeProductsHOC( 'showCreateProduct', true )
            },
            display: true
          }
        ]}/>
      <Card>
        <CardBody>
          <ReactTable
            data={ props.products.data }
            NoDataComponent={ () => <span></span> }
            columns={[
              {
                Header: Lang[ 'NAME' ][ props.data.languageReducer.lang ],
                Cell: ( row ) => (
                  <>
                    {
                      Object.keys( row.original.name ).map( key => {
                        if( key === props.data.languageReducer.lang ) {
                          return (
                            <span key={ `name-${ key }` }>{ row.original.name[ key ] }</span>
                          )
                        }
                      })
                    }
                  </>
                )
              },
              {
                Header: Lang[ 'PRICE_LINE_1' ][ props.data.languageReducer.lang ],
                Cell: ( row ) => (
                  <>
                    {
                      Object.keys( row.original.currency_price_1 ).map( key => {
                        if( key === props.data.languageReducer.lang ) {
                          return (
                            <span key={ `name-${ key }` }>{ row.original.currency_price_1[ key ] }</span>
                          )
                        }
                      })
                    }
                  </>
                )
              },
              {
                Header: Lang[ 'PRICE_LINE_2' ][ props.data.languageReducer.lang ],
                Cell: ( row ) => (
                  <>
                    {
                      Object.keys( row.original.currency_price_2 ).map( key => {
                        if( key === props.data.languageReducer.lang ) {
                          return (
                            <span key={ `name-${ key }` }>{ row.original.currency_price_2[ key ] }</span>
                          )
                        } 
                      })
                    }
                  </>
                )
              },
              {
                Header: Lang[ 'COUNTRIES' ][ props.data.languageReducer.lang ],
                Cell: ( row ) => (
                  <ul>
                    {
                      row.original.countries && row.original.countries.length > 0 && row.original.countries.map( item => {
                        return <li key={ item.id }>{ item.name }</li>
                      })
                    }
                  </ul>
                )
              },
              {
                Header: Lang[ 'CENTER_GROUPS' ][ props.data.languageReducer.lang ],
                Cell: ( row ) => (
                  <ul>
                    {
                      row.original.center_groups && row.original.center_groups.length > 0 && row.original.center_groups.map( item => {
                        return <li key={ item.id }>{ item.name }</li>
                      })
                    }
                  </ul>
                )
              },
              {
                Header: Lang[ 'IS_NEW' ][ props.data.languageReducer.lang ],
                Cell: ( row ) => (
                  <div className="w-100" style={{ textAlign: 'center' }}>
                    { row.original.is_new ? 'Yes' : 'No' }
                  </div>
                )
              },
              {
                Header: Lang[ 'ACTION' ][ props.data.languageReducer.lang ],
                Cell: ( row ) => (
                  <div className="w-100" style={{ textAlign: 'center' }}>
                    {
                      handleSecurityModulesUpdate() &&
                      <>
                        <Button
                          id={ `Products-${ row.original.id }-update-product` }
                          color="primary"
                          disabled={ !handleSecurityModulesUpdate() }
                          onClick={ () => {
                            props.getSelectedProduct( row.original.id, 'edit' )
                          }}>
                          <i className="pe-7s-pen"> </i>
                        </Button>
                        <UncontrolledTooltip
                          target={ `Products-${ row.original.id }-update-product` }
                          placement="top">{ Lang[ 'UPDATE_PRODUCT' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                      </>
                    }
                    {
                      !handleSecurityModulesUpdate() && handleSecurityModulesRead() &&
                      <>
                        <Button
                          id={ `Products-${ row.original.id }-view-product` }
                          color="primary"
                          onClick={ () => {
                            props.getSelectedProduct( row.original.id, 'view' )
                          }}>
                          <i className="pe-7s-look"> </i>
                        </Button>
                        <UncontrolledTooltip
                          target={ `Products-${ row.original.id }-view-product` }
                          placement="top">{ Lang[ 'VIEW_PRODUCT' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                      </>
                    }
                  </div>
                  
                )
              }
            ]}
            showPagination={ false } />
          <Pagination
            meta={ props.products.meta }
            onChangePage={ pg => {

            }} />
        </CardBody>
      </Card>
      <CreateProduct { ...props } />
      <UpdateProduct { ...props } />
      <ViewProduct { ...props } />
      { ( props.onLoadProducts || props.onLoadCountries || props.onLoadCenterGroups ) && <LoadingOverlay /> }
    </>
  )
}

export default compose(
  WithCenterGroups,
  WithProducts,
  WithCountries
)( ProductSettings )
