import React, { Component } from 'react'
import{
  Modal, ModalBody, ModalFooter, ModalHeader,
  Button, UncontrolledTooltip
} from 'reactstrap'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PageTitle from 'components/PageTitle'
import ARKTable from 'components/Table'
import RequestStatusHOC from './actions'
import Create from './Create'
import Edit from './Edit'

import Lang from 'Lang/General'

class RequestStatus extends Component{

  componentDidMount = () => this.props.getAllRequestStatus()

  renderCreateModal = () => {
    return(
      <Modal
        toggle={ () => this.props.onChangeRequestStatusHOC( 'showCreateStatus', false ) }
        isOpen={ this.props.showCreateStatus }
        size="xl">
        <ModalHeader toggle={ () => this.props.onChangeRequestStatusHOC( 'showCreateStatus', false ) }>{ Lang[ 'CREATE_STATUS' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <Create
            language={ this.props.data.languageReducer.lang }
            onChangeRequestStatusHOC={ this.props.onChangeRequestStatusHOC }
            newStatusData={ this.props.newStatusData }/>
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.createStatus() }>{ Lang[ 'SUBMIT' ][ this.props.data.languageReducer.lang ] }</Button>
          <Button className="mb-2 mr-2" color="secondary" onClick={ () => this.props.onChangeRequestStatusHOC( 'showCreateStatus', false ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderEditStatusModal = () => {
    return(
      <Modal
        toggle={ () => this.props.onChangeRequestStatusHOC( 'showEditStatus', false ) }
        isOpen={ this.props.showEditStatus }
        size="xl">
        <ModalHeader toggle={ () => this.props.onChangeRequestStatusHOC( 'showEditStatus', false ) }>{ Lang[ 'EDIT_STATUS' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <Edit
            language={ this.props.data.languageReducer.lang }
            onChangeRequestStatusHOC={ this.props.onChangeRequestStatusHOC }
            selectedRequestStatus={ this.props.selectedRequestStatus }/>
        </ModalBody>
        <ModalFooter>
          <Button className="mb-2 mr-2" color="primary" onClick={ () => this.props.updateStatus() }>{ Lang[ 'SUBMIT' ][ this.props.data.languageReducer.lang ] }</Button>
          <Button className="mb-2 mr-2" color="secondary" onClick={ () => this.props.onChangeRequestStatusHOC( 'showEditStatus', false ) }>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  render = () => {
    return(
      <>
        <div>
          <PageTitle
            heading={ Lang[ 'PRODUCT_REQUEST_STATUS' ][ this.props.data.languageReducer.lang ] }
            icon="pe-7s-paint-bucket icon-gradient bg-happy-fisher"
            buttons={[
              {
                buttonText: Lang[ 'CREATE_STATUS' ][ this.props.data.languageReducer.lang ],
                onClick: () => this.props.onChangeRequestStatusHOC( 'showCreateStatus', true ),
                display: this.props.data.roleReducer.role.id === 1 ? true : false
              }
            ]}/>
          <ARKTable
            data={ this.props.requestStatus }
            columns={[
              {
                Header: Lang[ 'NAME' ][ this.props.data.languageReducer.lang ],
                accessor: `name[${ this.props.data.languageReducer.lang }]`
              },
              {
                Header: Lang[ 'COLOR' ][ this.props.data.languageReducer.lang ],
                accessor: "color",
                width: 100,
                Cell: ({ value }) => (
                  <div style={{ background: value, width: '15px', height: '15px', border: 'solid #000 .5px' }}>
                  </div>
                )
              },
              this.props.data.roleReducer.role.id === 1 && 
              {
                Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
                accessor: 'id',
                Cell: row => (
                  <>
                    <Button
                      id={ `product-request-status-${row.original.id}` }
                      className="mr-2 btn-icon btn-icon-only"
                      color="primary"
                      onClick={ () => {
                        this.props.onChangeRequestStatusHOC( 'showEditStatus', true )
                        this.props.getSelectedRequestStatus( row.original.id )
                      }}>
                        <i className="pe-7s-note"> </i>
                    </Button>
                    <UncontrolledTooltip
                      target={ `product-request-status-${row.original.id}` }
                      placement="top">{ Lang[ 'UPDATE' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                  </>
                )
              }
            ]}
            totalPages={ [] }
            page={ 1 }
            onChangePage={ pg => {
              let tmp = {
                page: pg,
                is_paginated: true
              }
            }}
            onClickReset={ () => {
              let tmp = {
                page: 1,
                is_paginated: true
              }
            }}
            onSearch={ () => {} }
            searchLabel={ `Search with title` }
            searchComponent={ false }
            showPagination={ false } />
            { this.renderCreateModal() }
            { this.renderEditStatusModal() }
        </div>
        { ( this.props.data.ajaxReducer.ajaxCallProgress > 0 || this.props.onLoadRequestStatus ) && <LoadingOverlay /> }
      </>
    )
  }
}

export default RequestStatusHOC( RequestStatus )
