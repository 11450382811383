import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { ClockLoader } from 'react-spinners'

import Lang from 'Lang/General'

const CreateOrderLoader = ({
  onLoadCreateOrder,
  data
}) => {
  return (
    <Modal
      size={ 'lg' }
      isOpen={ onLoadCreateOrder }>
      <ModalBody>
        <div
          style={{
            height: '90vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            flexDirection: 'column'
          }}>
          <ClockLoader size={ 150 } />
          <br />
          <h4>{ Lang[ 'CREATE_ORDER_LOADING' ][ data.languageReducer.lang ] }</h4>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default CreateOrderLoader