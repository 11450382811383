import React from 'react'
import _ from 'lodash'
import {
  Form, FormGroup,
  Label, Input
} from 'reactstrap'

import MultiLangInput from 'components/Input/MultiLang'
import Lang from 'Lang/General'

const ProgramModuleForm = ({
  programModuleType,
  currentProgramModule,
  onChangeProgramModulesHOC,
  lang
}) => {
  const updateProgramModule = ( key, val ) => {
    let tmp = _.cloneDeep( currentProgramModule )
    tmp[ key ] = val
    onChangeProgramModulesHOC( programModuleType, tmp )
  }
  return(
    <>
      <Form>
        <MultiLangInput
          label={ Lang[ 'NAME' ][ lang ] }
          value={ currentProgramModule.name }
          onChange={ val => updateProgramModule( 'name', val ) }
          selectedLanguage={ lang } />
        <MultiLangInput
          label={ Lang[ 'DESCRIPTION' ][ lang ] }
          value={ currentProgramModule.description }
          onChange={ val => updateProgramModule( 'description', val ) }
          selectedLanguage={ lang } />
        <FormGroup>
          <Label>{ Lang[ 'TYPE' ][ lang ] }</Label>
          <Input
            type='select'
            value={ currentProgramModule.type }
            onChange={ e => updateProgramModule( 'type', e.target.value ) } >
            <option value='events' >{ Lang[ 'EVENTS' ][ lang ] }</option>
            <option value='system' >{ Lang[ 'SYSTEM' ][ lang ] }</option>
          </Input>
        </FormGroup>
      </Form>
    </>
  )
}

export default ProgramModuleForm