import React, { useState } from 'react'
import {
  Row, Col, Label,
  FormGroup, FormFeedback
} from 'reactstrap'
import _ from 'lodash'
import CKEditor from './ckeditor5'
import uniqid from 'uniqid'

import LanguageSelector from 'components/LanguageSelector'

const MultilingualInput = ({
  label,
  value,
  onChange,
  selectedLanguage,
  invalid,
  editorType,
  maxLength,
  showCharCount
}) => {
  const id = uniqid()
  const [ buttonLang, updateButtonLang ] = useState( selectedLanguage ? selectedLanguage : 'en' )

  const updateContent = val => {
    let tmpValue = _.cloneDeep( value )
    tmpValue[ buttonLang ] = val
    return onChange( tmpValue )
  }

  return <>
    { invalid && <style>{ `#${ id } .ck-content { border: 1px solid #dc3545 !important; }` }</style> }
    <FormGroup id={ id } >
      <Row 
        className='mb-2 d-flex align-items-center'
        style={{ justifyContent: label ? "space-between" : "flex-end" }} >
        { 
          label && <Col>
            <Label className='mb-0'>{ label }</Label> 
          </Col>
        }
        <LanguageSelector 
          currentLang = { buttonLang }
          updateLang = { updateButtonLang }
        />
      </Row>
      <CKEditor
        key={ buttonLang }
        editorType={ editorType }
        data={ value[ buttonLang ] }
        onChange={ ( event, editor ) => updateContent( editor.getData() ) } 
        maxLength={ maxLength }
        showCharCount={ showCharCount }
        buttonLang={ buttonLang }
        selectedLanguage={ selectedLanguage }
      />
      <FormFeedback className={ `${ invalid && 'd-block' }` } >{ invalid }</FormFeedback>
    </FormGroup>
  </>
}

export default MultilingualInput