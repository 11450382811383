import React, { useState, useEffect } from 'react'
import {
  Card, CardHeader, CardBody,
  Button, Input, FormGroup, Label
} from 'reactstrap'
import { FaPlus } from 'react-icons/fa'
import uniqid from 'uniqid'
import _ from 'lodash'

import Lang from 'Lang/General'
import ButtonStylings from './ButtonStylings'

const NewComponent = ( props ) => {
  const [ mode, updateMode ] = useState( '' )
  const [ newButton, updateNewButton ] = useState({
    type: 'basic-button',
    style: 'primary_style',
    dom: {}
  })

  useEffect( () => {
    if( mode === 'button' ){
      updateNewButton({
        type: 'basic-button',
        style: 'primary_style',
        dom: {
          id: uniqid(),
          type: 'button',
          style: {
            cursor: 'pointer',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '15px',
            transition: 'all 0.3s linear',
            background: '#000000',
            color: '#ffffff',
            borderTop: '1px solid #000000',
            borderRight: '1px solid #000000',
            borderLeft: '1px solid #000000',
            borderBottom: '1px solid #000000',
            primaryColor: [ 'background', 'borderTop', 'borderRight', 'borderLeft', 'borderBottom' ],
            ':hover': {
              background: '#ffffff',
              color: '#000000',
              borderTop: '1px solid #000000',
              borderRight: '1px solid #000000',
              borderLeft: '1px solid #000000',
              borderBottom: '1px solid #000000',
              primaryColor: [ 'color', 'borderTop', 'borderRight', 'borderLeft', 'borderBottom' ]
            }
          },
          children: [
            {
              id: uniqid(),
              type: 'div',
              variant: 'text',
              style: {
                position: "relative",
                zIndex: "2",
              },
              content: {
                "en": "<div>Button text</div>",
                "de": "<div>Button text</div>",
                "it": "<div>Button text</div>",
                "nl": "<div>Button text</div>"
              }
            }
          ],
          className: 'basic-button',
          navTo: '',
          variant: 'redirect',
          isContentCenter: false,
          paddingTop: 0,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0
        }
      })
    } else if ( mode === 'forms' ) {
      props.getForms({ page: 1, is_paginated: false })
    }
  }, [ mode ] )

  const onChangeNewButton = ( type, style ) => {
    let tmpNewButton = _.cloneDeep( newButton )
    tmpNewButton.type = type
    tmpNewButton.style = style
    tmpNewButton.dom.className = type
    tmpNewButton.dom.style = ButtonStylings[ type ][ style ]
    tmpNewButton.dom.children[ 0 ].style = ButtonStylings[ type ][ 'text_style' ]
    updateNewButton( tmpNewButton )
  }

  const renderColorSelectionButton = ( background, style ) => (
    <div className='col-md-3' >
      <button
        key={ style }
        className='w-100'
        style={{
          border: style === newButton.style ? '5px solid #5cb85c' : 'none',
          marginRight: '15px',
          height: "100px",
          background: background
        }}
        onClick={ () => onChangeNewButton( newButton.type, style ) }/>
    </div>
  )

  const createComponent = component => {
    let tmpColumn = _.cloneDeep( props.selectedCol )
    tmpColumn.children.push( component )
    props.updateSelectedCol( tmpColumn )
    let tmpContainer = _.cloneDeep( props.dom )
    tmpContainer = props.FindComponentAndPatch( tmpContainer, tmpColumn )
    props.updateDom( tmpContainer )
    setTimeout(
      () => {
        let tmpNewComponent =  document.querySelectorAll( 
          `[data-rbd-drag-handle-draggable-id=${ tmpColumn.id }]` 
        )[ 0 ]
        tmpNewComponent && tmpNewComponent.scrollIntoView({ behavior: 'smooth', block: 'end' })
      },
      500
    )
  }

  return (
    <Card className="mb-3">
      <CardHeader>{ Lang[ 'NEW_COMPONENT' ][ props.selectedLanguage ] }</CardHeader>
      <CardBody className='d-flex'>
        <Button 
          color="primary" 
          className='d-flex justify-content-center align-items-center'
          style={{ marginRight: '10px' }}
          onClick={ () => createComponent({
            id: uniqid(),
            type: 'div',
            variant: 'text',
            content: Lang[ 'CREATED_TEXT' ]
          }) }>
          <FaPlus className='mr-2' />
          { Lang[ 'TEXT' ][ props.selectedLanguage ] }
        </Button>
        <Button 
          color="primary" 
          className='d-flex justify-content-center align-items-center'
          style={{ marginRight: '10px' }}
          onClick={ () => createComponent({
            id: uniqid(),
            type: 'img',
            style: { width: '90%' },
            width: '90%',
            isBackgroundImage: false,
            source: "https://s3-eu-central-1.amazonaws.com/ark.staging.assets/shared_media_drive/961eafa0db0a644557638db1166d7f0f/original/placeholder.png"
          }) }>
          <FaPlus className='mr-2' />
          { Lang[ 'IMAGE_PAGE_EDITOR' ][ props.selectedLanguage ] }
        </Button>
        <Button 
          color="primary" 
          className='d-flex justify-content-center align-items-center'
          style={{ marginRight: '10px' }}
          onClick={ () => createComponent({
            id: uniqid(),
            type: 'mediathek',
            style: { 
              position: 'relative'
            },
            video: {
              id: uniqid(),
              style: {},
              source: 'https://www.youtube.com/embed/'
            },
            source: '',
            content: {
              en: '<div></div>',
              de: '<div></div>',
              nl: '<div></div>',
              it: '<div></div>'
            }
          }) }>
          <FaPlus className='mr-2' />
          { Lang[ 'VIDEO' ][ props.selectedLanguage ] }
        </Button>
        {
          props.availableComponent && props.availableComponent.includes( "btn" ) && (
            <Button
              color="primary"
              style={{ marginRight: '10px' }}
              onClick={ () => updateMode( 'button' ) }>
              <FaPlus className='mr-2' />
              { Lang[ 'BUTTON' ][ props.selectedLanguage ] }
            </Button>
          )
        }
        {
          props.availableComponent && props.availableComponent.includes( "form" ) && (
            <Button 
              color="primary" 
              className='d-flex justify-content-center align-items-center'
              style={{ marginRight: '10px' }}
              onClick={() => {
                updateMode( 'forms' )
              }}>
              <FaPlus className='mr-2' />
              { Lang[ 'FORMS' ][ props.selectedLanguage ] }
            </Button>
          )
        }
        {
          mode === 'forms' && (
            <CardBody>
              <FormGroup>
                <Label>{ Lang[ 'SELECT_FORM' ][ props.selectedLanguage ] }</Label>
                {
                  props.onLoadForms
                    ? <p>{ Lang[ 'LOADING_SITE_FORMS' ][ props.selectedLanguage ] }</p>
                    : (
                      <Input 
                        type="select"
                        value={ props.selectedForm.id } 
                        onChange={ e => props.onChangeFormsHOC( 'selectedForm', _.find( props.forms.data, { id: parseInt( e.target.value ) } ) ) }>
                        <option></option>
                        {
                          props.forms && props.forms.data.map( item => {
                            return (
                              <option key={ item.id } value={ item.id }>{ item.name[ props.selectedLanguage ] }</option>
                            )
                          })
                        }
                      </Input>
                    )
                }
              </FormGroup>
              <Button 
                disabled={ _.isEmpty( props.selectedForm ) }
                color="primary"
                onClick={ () => {
                  createComponent({
                    id: uniqid(),
                    type: "templateForm",
                    formId: props.selectedForm.id,
                    name: props.selectedForm.name
                  })
                }}>
                { Lang[ 'SUBMIT' ][ props.selectedLanguage ] }
                </Button>
            </CardBody>
          )
        }
        {
          mode === 'button' && (
            <CardBody>
              <FormGroup>
                <Label>{ Lang[ 'BUTTON_TYPE' ][ props.selectedLanguage ] }</Label>
                <div className='row' >
                  { 
                    _.map( ButtonStylings, ( value, key ) => (
                      <div className='col-md-4' >
                        <button 
                          key={ key }
                          className='w-100'
                          style={{
                            border: key === newButton.type ? '5px solid #5cb85c' : 'none',
                            marginRight: '15px',
                            height: "100px",
                            backgroundImage: `url(${ value.img })`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center"
                          }}
                          onClick={ () => onChangeNewButton( key, newButton.style ) }/>
                      </div>
                    ))
                  }
                </div>
              </FormGroup>
              <FormGroup>
                <Label>{ Lang[ 'BUTTON_COLOR' ][ props.selectedLanguage ] }</Label>
                <div className='row' >
                  { renderColorSelectionButton( '#0d6efd', 'primary_style' ) }
                  { renderColorSelectionButton( '#6c757d', 'secondary_style' ) }
                  { renderColorSelectionButton( '#d9534f', 'danger_style' ) }
                  { renderColorSelectionButton( '#000000', 'button_style' ) }
                </div>
              </FormGroup>
              <Button 
                color="primary"
                onClick={ () => createComponent( newButton.dom ) }>
                { Lang[ 'SUBMIT' ][ props.selectedLanguage ] }
              </Button>
            </CardBody>
          )
        }
      </CardBody>
    </Card>
  )
}

export default NewComponent