import React, { Component } from 'react'
import _ from 'lodash'

import { Get } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const HOC = WrappedComponent => {
  class WithHOC extends Component {
    state={ 
      loading: false,
      designers: [],
      designerRoleIds: []
    }

    load = param => this.setState({ loading: param })

    getDesignerRoleIds = () => Get(
      `/api/v1/security_roles?query=${ convertObjToBase64({
        page: 1,
        is_paginated: false
      }) }`,
      this.getDesignerRoleIdsSuccess,
      this.getDesignerRoleIdsError,
      this.load
    )
    getDesignerRoleIdsSuccess = async( payload ) => {
      let tmpDesignerRoleIds = _.filter( payload.data, item => item.name.toLowerCase().indexOf( 'designer' ) > -1 )
      this.setState({ designerRoleIds: tmpDesignerRoleIds})
      await this.getDesigners( tmpDesignerRoleIds )
    }
    getDesignerRoleIdsError = () => {}

    getDesigners = designerRoleIds => {
      if( designerRoleIds.length > 0 ){
        let tmpSearchParams = {
          page: 1,
          is_paginated: false,
          filter: {
            $or: designerRoleIds.map( designerId => ({ role_id: designerId.id }) )
          }
        }
        let tmpSearchString = convertObjToBase64( tmpSearchParams )
        return Get(
          `/api/v1/users?query=${ tmpSearchString }`,
          this.getDesignersSuccess,
          this.getDesignersError,
          this.load
        )
      }
      return
    }
    getDesignersSuccess = payload => {
      payload.data = payload.data.map( designer => {
        let tmpRole = Lang[ designer.role.replace( /\s/g, '_' ).toUpperCase() ] ? (
            Lang[ designer.role.replace( /\s/g, '_' ).toUpperCase() ][ this.props.data.languageReducer.lang ]
          ) : (
            designer.role
          )
        let label = `${ designer.first_name } ${ designer.last_name } (${ tmpRole })`
        return {
          id: designer.id,
          value: label,
          label: label
        }
      }) 
      this.setState({ designers: payload.data })
    }
    getDesignersError = () => {}

    render = () => {
      return(
        <WrappedComponent
          { ...this.props } 
          onLoadDesignersHOC={ this.state.loading }
          designers={ this.state.designers }
          getDesignerRoleIds={ this.getDesignerRoleIds } />
      )
    }
  }
  return WithHOC
}

export default HOC