import React, { useState, useEffect } from "react"
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
} from "reactstrap"
import { SortableContainer, SortableElement } from "react-sortable-hoc"
import uniqid from "uniqid"
import _ from "lodash"

import Lang from "Lang/General"
import DomEditor from "components/DomEditor"
import { findIdAndAssign } from "../utility/ObjectPatcher"

const reorder = ( list, startIndex, endIndex ) => {
  const result = Array.from( list )
  const [ removed ] = result.splice( startIndex, 1 )
  result.splice( endIndex, 0, removed )
  return result
}

const AccordionForm = ({
  selectedLanguageShortname,
  selectedSection,
  selectedContainer,
  findComponentAndPatch,
  onChangeSectionEditorState,
  onChangeSectionHOC,
}) => {
  const [ showArrangement, updateShowArrangement ] = useState( false )
  const [ selectedAccordion, setAccordion ] = useState({})
  const [ selectedItem, setSelectedItem ] = useState(0)
  const [ showDomEditor, updateShowDomEditor ] = useState( false )
  const [ selectedAccordionContent, setContent ] = useState({})

  useEffect(() => {
    if ( selectedContainer && selectedContainer.type === "accordion" ) {
      setAccordion( selectedContainer )
    } else {
      setAccordion( selectedSection )
    }
  }, [])

  useEffect(() => {
    if ( selectedContainer && selectedContainer.type === "accordion" ) {
      setAccordion( selectedContainer )
    } else {
      setAccordion( selectedSection )
    }
  }, [ selectedContainer, selectedSection ])

  useEffect(() => {
    if (
      selectedAccordion &&
      selectedAccordion.children &&
      !_.isEmpty( selectedAccordion.children[ selectedItem ])
    ) {
      setContent({
        content: parseContent(
          selectedAccordion.children[ selectedItem ].children[1]
        ),
      })
    }
  }, [ selectedAccordion, selectedItem ])

  const addItem = ( section ) => {
    let tmpSection = _.cloneDeep( selectedSection )
    let tmpAccordion = _.cloneDeep( selectedAccordion )
    let newItem = {
      ...section.children[ section.children.length - 1 ],
      id: "",
      children: [
        {
          ...section.children[ section.children.length - 1 ].children[0],
          id: "",
          content: {
            de: "Bereichsüberschrift",
            en: "Section heading",
            it: "Titolo dell'area",
            nl: "Rubriek",
          },
        },
        _.cloneDeep( section.children[section.children.length - 1 ].children[1] ),
      ],
    }
    findIdAndAssign( newItem )
    
    tmpAccordion.children.push( newItem )
    findComponentAndPatch( tmpAccordion, tmpSection )

    if( selectedSection.id === tmpAccordion.id ){
      onChangeSectionHOC( "selectedSection", tmpAccordion )
    } else {
      onChangeSectionEditorState( "selectedContainer", tmpAccordion )
      onChangeSectionHOC( "selectedSection", tmpSection )
    }
  }

  const removeItem = () => {
    let tmp = _.clone( selectedAccordion )
    let tmpSec = _.clone( selectedSection )
    tmp.children.splice( selectedItem, 1 )
    if ( selectedItem === tmp.children.length ){
      setSelectedItem( tmp.children.length - 1 )
    }
    else {
      setSelectedItem( selectedItem )
    }
    findComponentAndPatch( tmp, tmpSec )
    onChangeSectionHOC(
      "selectedSection",
      tmp.id === tmpSec.id ? tmp : tmpSec
    )
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let tmp = _.cloneDeep( selectedAccordion )
    let tmpSec = _.cloneDeep( selectedSection )
    tmp.children = reorder( tmp.children, oldIndex, newIndex )
    if ( tmp.id !== tmpSec.id ){
      findComponentAndPatch( tmp, tmpSec )
      onChangeSectionEditorState( "selectedContainer", tmp )
      onChangeSectionHOC( "selectedSection", tmpSec )
    } else {
      onChangeSectionHOC( "selectedSection", tmp )
    }
    setSelectedItem( newIndex )
  }

  const SortableItem = SortableElement(({ row }) => {
    return (
      <div
        className="mr-2"
        style={{
          display: "block",
          cursor: "pointer",
          border: selectedItem === row ? "#6c757d" : "1px solid #6c757d",
          borderRadius: "0.25rem",
          textAlign: "center",
          alignItems: "center",
          width: "50px",
          height: "fit-content",
          background: selectedItem === row ? "#6c757d" : "none",
          color: selectedItem === row ? "#ffffff" : "#000000",
          zIndex: "9999",
        }}
      >
        { row + 1 }
      </div>
    )
  })

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div className="row m-0">
        {
          items.map(( pg, index ) => (
            <SortableItem key={ `row-${index}` } index={ index } row={ index } />
          ))
        }
      </div>
    )
  })

  const parseContent = ( currentContent ) => {
    if ( currentContent.variant && currentContent.variant === "text" ) {
      let tmp = {
        id: currentContent.id,
        type: "div",
        own_created_section: true,
        children: [
          {
            id: uniqid(),
            type: "div",
            className: "row",
            style: {
              position: "relative",
            },
            children: [
              {
                id: uniqid(),
                children: [
                  {
                    ...currentContent,
                    id: uniqid(),
                  },
                ],
                type: "div",
                className: "col-sm-12",
                col: 12,
                style: {
                  minHeight: "100px",
                  position: "relative",
                  padding: "0px 0px 0px 0px",
                  margin: "0px 0px 0px 0px",
                  top: 0,
                  left: 0,
                  backgroundColor: "",
                },
                minHeight: 100,
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                marginTop: "0px",
                marginRight: "0px",
                marginBottom: "0px",
                marginLeft: "0px",
                top: 0,
                left: 0,
                isContentCenter: false,
                isColumnContainer: true,
                isFloat: false,
              },
            ],
          },
        ],
      }
      return tmp
    }
    return currentContent
  }

  const findObjectAndPatch = ( data, target ) => {
    if ( data.id && data.id === target.id ) {
      return target
    } else if (
      _.filter( data, ( value ) => typeof value === "object" ).length > 0
    ) {
      return Array.isArray( data )
        ? data.map(( item ) => findObjectAndPatch( item, target ))
        : _.reduce(
            data,
            ( res, value, key ) => {
              return {
                ...res,
                [ key ]:
                  typeof value === "object"
                    ? findObjectAndPatch( value, target )
                    : value,
              }
            },
            data
          )
    } else {
      return data
    }
  }

  const updateCurrentContent = ( key, val ) => {
    const tmp = findObjectAndPatch( selectedSection, val )
    let tmpAccordion = _.cloneDeep( selectedAccordion )
    tmpAccordion.children[ selectedItem ].children[1] = val
    onChangeSectionHOC( "selectedSection", tmp )
    setContent({ content: val })
    setAccordion( tmpAccordion )
  }

  return (
    <Card className="mb-2">
      <CardHeader>
        { Lang[ "ACCORDION_SETTING" ][ selectedLanguageShortname ]}
      </CardHeader>
      <CardBody>
        <Form>
          <FormGroup className="d-flex align-items-center">
            <span className="mr-2">
              { Lang[ "ROW" ][ selectedLanguageShortname ]}:
            </span>
            {
              showArrangement 
                ? (
                  <SortableList
                    items={ selectedAccordion.children }
                    onSortEnd={ onSortEnd }
                    axis="xy"
                  />
                ) : (
                  selectedAccordion.children &&
                  selectedAccordion.children.map(( item, index ) => (
                    <Button
                      className="mr-2"
                      color={
                        parseInt( selectedItem ) === index ? "success" : "primary"
                      }
                      onClick={() => setSelectedItem( index )}
                    >
                      { index + 1 }
                    </Button>
                  ))
                )
            }
            {
              !showArrangement && (
                <Button
                  className="mr-2"
                  color="primary"
                  onClick={() => addItem( selectedAccordion )}
                >
                  +
                </Button>
              )
            }
          </FormGroup>
          <FormGroup>
            <Button
              className="mr-2"
              color={ showArrangement ? "danger" : "primary" }
              onClick={ () => updateShowArrangement( !showArrangement )}
            >
              {
                showArrangement
                  ? Lang[ "STOP_ROW_REARRANGEMENT" ][ selectedLanguageShortname ]
                  : Lang[ "REARRANGE_ROW" ][ selectedLanguageShortname ]
              }
            </Button>
            <Button
              color="danger"
              onClick={ () => removeItem() }
              className="mr-2"
              disabled={
                selectedAccordion.children &&
                selectedAccordion.children.length === 1
              }
            >
              { Lang[ "REMOVE_CURRENT_ROW" ][ selectedLanguageShortname ] }
            </Button>
            <Button
              color={
                selectedAccordion.children &&
                selectedAccordion.children[ selectedItem ].id === selectedAccordion.defaultActiveKey
                  ? "danger"
                  : "primary"
              }
              onClick={() => {
                let tmp = _.cloneDeep( selectedAccordion )
                let tmpSec = _.cloneDeep( selectedSection )
                if( tmp.children[ selectedItem ].id === selectedAccordion.defaultActiveKey ){
                  tmp.defaultActiveKey = ""
                } else {
                  tmp.defaultActiveKey = tmp.children[ selectedItem ].id
                }
                findComponentAndPatch( tmp, tmpSec )
                if ( tmp && tmp.id !== tmpSec.id && tmp.type === "accordion" ){
                  onChangeSectionEditorState( "selectedContainer", tmp )
                }
                onChangeSectionHOC(
                  "selectedSection",
                  tmp.id === tmpSec.id ? tmp : tmpSec
                )
              }}
            >
              {
                selectedAccordion.children &&
                selectedAccordion.children[ selectedItem ].id === selectedAccordion.defaultActiveKey
                  ? Lang[ "DEFAULT_OPEN" ][ selectedLanguageShortname ]
                  : Lang[ "DEFAULT_CLOSE" ][ selectedLanguageShortname ]
              }
            </Button>
          </FormGroup>
          <FormGroup>
            <Label className="w-100">
              { Lang[ "TITLE" ][ selectedLanguageShortname ] }
            </Label>
            <Button
              color="primary"
              className="mr-2"
              onClick={() => {
                onChangeSectionEditorState( "selectedEditableComponent", selectedAccordion.children[ selectedItem ].children[0] )
                onChangeSectionEditorState( "showCodeTagEditor", true )
              }}
            >
              { Lang[ "EDIT_TEXT" ][selectedLanguageShortname ]}
            </Button>
          </FormGroup>
          <FormGroup>
            <Label className="w-100">
              { Lang[ "CONTENT" ][ selectedLanguageShortname ]}
            </Label>
            <Button
              color="primary"
              className="mr-2"
              onClick={() => {
                updateShowDomEditor( true )
              }}
            >
              { Lang[ "EDIT_CONTENT_HERE" ][ selectedLanguageShortname ]}
            </Button>
          </FormGroup>
        </Form>
        <DomEditor
          selectedLanguage={ selectedLanguageShortname }
          showDomEditor={ showDomEditor }
          showButtonConfig={ true }
          updateShowDomEditor={ updateShowDomEditor }
          parent={ selectedAccordionContent }
          updateParent={ updateCurrentContent }
          childrenKey="content"
        />
      </CardBody>
    </Card>
  )
}

export default AccordionForm
