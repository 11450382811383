export const OlsTeamMainNav = [
  {
    icon: 'pe-7s-speaker',
    label: 'CAMPAIGNS',
    secLabel: 'Campaign',
    to: '#/dashboard/ols-booked-campaign'
  },
  {
    icon: 'pe-7s-users',
    label: 'CLIENT',
    secLabel: 'Client',
    to: '#/dashboard/ols-team-client'
  }
]
