import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import StatusModal from 'components/Modal/status'

import { Get, Post, outdatedToken, Put, Delete } from 'utils/axios'
import { GetWithNonce } from 'utils/axios-nonce'
import { getRefreshToken } from 'actions/token'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const CenterHOC = ( WrappedComponent ) => {
  class CenterWrappedComponent extends Component {
    state={
      centers: {},
      selectedCenter: {},
      newCenterData: {},
      showNewCenterModal: false,
      showViewCenterModal: false,
      showEditCenterModal: false,
      showDeleteConfirmation: false,
      showArchivedCenterModal: false,
      showUpdateLeadConnect: false,
      loading: false,
      showStatusModal: false,
      statusModalType: '',
      onClickStatusModalButton: () => {},
      statusModalMessage: '',
      errorMessage: {},
      archivedCenters: {},
      centerTotalPage: [],
      archivedCenterTotalPage: [],
      toArchiveCenterid: 0,
      unarchiveCenterID: 0,
      confirmUnarchiveModal: false,
      leadID: '',
      centerID: 0,
      centerLocations: [],
      deleteLocationConfirmation: false,
      addItemModal: false,
      showOpeningHourModal: false,
      openingHours: [],
      deleteCenterId: 0,
    }

    onChangeCenterHOC = (key, value) => this.setState({ [key]: value })

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showStatusModal: true,
      statusModalType: 'negative',
      onClickStatusModalButton: () => this.setState({ showStatusModal: false }),
      statusModalMessage: typeof error === 'string'
        ? error
        : error
          ? Object.values( error ).map( ( item, index ) => index === 0
            ? item.replaceAll( '_', ' ' )
            : `, ${ item.replaceAll( '_', ' ' ) }` )
          : ''
    })
    requestSuccess = success => this.setState({
      showStatusModal: true,
      statusModalType: 'positive',
      onClickStatusModalButton: () => this.setState({
        showStatusModal: false,
        showNewCenterModal: false,
        errorMessage: {}
      }),
      statusModalMessage: success
    })
    ajaxError = ( error ) => {
      outdatedToken( error, this.props.history )
      this.requestError( error )
    }

    getCenters = query => Get(
      `/api/v1/centers?query=${ convertObjToBase64( query ) }`,
      this.getCentersSuccess,
      this.getCentersError,
      this.load
    )
    getCentersSuccess = payload => {
      let tmpTotalPages = []
      let tmpCenters = []
      if( payload.data && payload.data.length > 0 ) {
        payload.data.map( item => {
          tmpCenters.push({
            ...item,
            fullname: `${ item.first_name } ${ item.last_name }`
          })
        })
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      let tmp = {
        data: tmpCenters,
        meta: payload.meta
      }
      this.setState({ centers: tmp, centerTotalPage: tmpTotalPages })
    }

    getCentersError = error => this.ajaxError( error )

    getSelectedCenterView = id => this.getSelectedCenter( id, 'view' )
    getSelectedCenterEdit = id => this.getSelectedCenter( id, 'edit' )

    getSelectedCenter = ( id, type ) => GetWithNonce(
      `/api/v1/centers/${ id }`,
      ( payload ) => this.getSelectedCenterSuccess( payload, type ),
      this.getSelectedCenterError,
      this.load
    )
    getSelectedCenterSuccess = ( payload, type ) => {
      let tmpCenters = {
        ...payload,
        id: payload.id,
        center_name: payload.center_name,
        client_name: payload.client.user.first_name,
        client_id: payload.client_id,
        consultant_name: payload.consultant.user.first_name,
        consultant_id: payload.consultant_id,
        email: payload.email,
        first_name: payload.first_name,
        is_female_center: payload.is_female_center,
        language: payload.language,
        last_name: payload.last_name,
        manager: payload.manager,
        salutation: payload.salutation,
        status: payload.status,
        locations: payload.locations
      }
      return this.setState(
        { centerLocations: [] },
        () => {
          if (type === 'view'){
            let tmp = {
              ...tmpCenters,
              center_groups_id: payload.center_groups && payload.center_groups.length > 0 && payload.center_groups.map( item => {
                let tmpCenterGroup = {
                  id: item.id,
                  name: item.name
                }
                return tmpCenterGroup
              })
            }
            this.onChangeCenterHOC( 'showViewCenterModal', true )
            payload.locations.map( item => {
              this.getCenterLocation( payload.id, item.id )
            })
            return this.setState({ selectedCenter: tmp })
          }
          else{
            let tmp = {
              ...tmpCenters,
              center_groups_id: payload.center_groups && payload.center_groups.length > 0
                ? payload.center_groups.map( item => item.id )
                : []
            }
            this.onChangeCenterHOC( 'showEditCenterModal', true )
            this.getAllOpeningHour( payload.id )
    
            payload.locations.map( item => {
              this.getCenterLocation( payload.id, item.id )
            })
            return this.setState({ selectedCenter: tmp })
          }
        }
      )
    }
    getSelectedCenterError = error => this.ajaxError( error )

    postCenter = () => Post(
      `/api/v1/centers`,
      this.state.newCenterData,
      this.postCenterSuccess,
      this.postCenterError,
      this.load
    )
    postCenterSuccess = () => {
      let tmp = {
        page: 1,
        is_paginated: true,
        filter: {
          $or: [
            { status: 'ACTIVE' },
            { status: 'TRIAL' }
          ]
        }
      }
      this.requestSuccess( Lang[ 'CENTER_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getCenters( tmp )
    }
    postCenterError = error => this.ajaxError( error )

    updateCenter = ( id, data ) => {
      Put(
        `/api/v1/centers/${ id }`,
        data,
        this.updateCenterSuccess,
        this.updateCenterError,
        this.load
      )
    }
    updateCenterSuccess = async payload => {
      this.setState({ addItemModal: false, showEditCenterModal: false, centerLocations: [] })
      if ( payload.status !== 'INACTIVE' ) {
        this.getCenters({
          page: 1,
          is_paginated: true,
          filter: {
            $or: [
              { status: 'ACTIVE' },
              { status: 'TRIAL' }
            ]
          }
        })
        this.getArchivedCenter({
          page: 1,
          is_paginated: true
        })
        this.requestSuccess( Lang[ 'CENTER_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      } else {
        return await this.deactivateCenter( payload.id )
      }
    }
    updateCenterError = error => this.ajaxError( error )

    getCenterLocation = ( center_id, location_id ) => GetWithNonce(
      `/api/v1/centers/${ center_id }/locations/${ location_id }`,
      this.getCenterLocationSuccess,
      this.getCenterLocationError,
      this.load
    )
    getCenterLocationSuccess = payload => {
      let tmp = _.cloneDeep( this.state.centerLocations )
      tmp.push({
        ...payload,
        calling_code: payload.phone_number.substring( 0, 2 ),
        area_code: payload.phone_number.substring( 3, payload.phone_number.indexOf( '-', 3 ) ),
        number: payload.phone_number.substring( payload.phone_number.indexOf( '-', 3 ) + 1 )
      })
      this.setState({ centerLocations: tmp })
    }
    getCenterLocationError = error => this.requestError( error )

    createCenterLocation = ( center_id, data ) => Post(
      `/api/v1/centers/${ center_id }/locations`,
      data,
      () => this.createCenterLocationSuccess( center_id ),
      this.createCenterLocationError,
      this.load
    )
    createCenterLocationSuccess = center_id => {
      this.requestSuccess( Lang[ 'CENTER_LOCATION_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.setState({ addItemModal: false, showEditCenterModal: true, centerLocations: [] })
      this.getSelectedCenter( center_id, 'edit' )
      this.getCenters({ 
        page: 1, 
        is_paginated: true,
        filter: {
          $or: [
            { status: 'ACTIVE' },
            { status: 'TRIAL' }
          ]
        }
      })
    }
    createCenterLocationError = error => this.requestError( error )

    updateCenterLocation = ( center_id, location_id, data ) => Put(
      `/api/v1/centers/${ center_id }/locations/${ location_id }`,
      data,
      this.updateCenterLocationSuccess,
      this.updateCenterLocationError,
      this.load
    )
    updateCenterLocationSuccess = () => {
      let tmp = {
        page: 1,
        is_paginated: true,
        filter: {
          $or: [
            { status: 'ACTIVE' },
            { status: 'TRIAL' }
          ]
        }
      }
      this.requestSuccess( Lang[ 'CENTER_LOCATION_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.onChangeCenterHOC( 'showEditCenterModal', false )
      this.setState({ centerLocations: [] })
      this.getCenters( tmp )
    }
    updateCenterLocationError = error => this.requestError({ ...error })

    deleteCenterLocations = ( center_id, location_id ) => Delete(
      `/api/v1/centers/${ center_id }/locations/${ location_id }`,
      () => this.deleteCenterLocationsSuccess( center_id ),
      this.deleteCenterLocationsError,
      this.load
    )
    deleteCenterLocationsSuccess = center_id => {
      this.setState({ showEditCenterModal: true, deleteLocationConfirmation: false, centerLocations: [] })
      this.requestSuccess( Lang[ 'CENTER_LOCATION_REMOVE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getSelectedCenter( center_id, 'edit' )
      this.getCenters({ 
        page: 1, 
        is_paginated: true,
        filter: {
          $or: [
            { status: 'ACTIVE' },
            { status: 'TRIAL' }
          ]
        }
      })
    }
    deleteCenterLocationsError = error => this.requestError( error )

    getArchivedCenter = ( query, type, searchParams, searchVal ) => {
      query = {
        ...query,
        filter: {
          status: 'INACTIVE'
        }
      }
      if( type === 'Normal Search' ){
        query = {
          ...query,
          filter: {
            center_name: {
                $like: `%${ searchVal.toLowerCase() }%`
            },
            status: 'INACTIVE'
          }
        }
      }
      if( type === 'Advanced Search' ){
        let tmpCenters = {}
        let tmpClient = {}
        searchParams.map( item => {
          tmpCenters[ item.val ] = {
            $like: `%${item.searchValue.toLowerCase()}%`,
          }
        })
        query = {
          ...query,
          filter: {
            ...tmpCenters,
            ...tmpClient,
            status: 'INACTIVE'
          }
        }
      }
      Get(
        `/api/v1/centers?query=${ convertObjToBase64( query ) }`,
        this.getArchivedCentersSuccess,
        this.getArchivedCentersError,
        this.load
      )
    }
    getArchivedCentersSuccess = payload => {
      let tmpTotalPages = []
      let tmpCenters = []
      if( payload.data.length > 0 ){
        payload.data.map( item => {
          tmpCenters.push({
            ...item,
            fullname: `${ item.first_name } ${ item.last_name }`
          })
        })
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      let tmp = {
        data: tmpCenters,
        meta: payload.meta
      }
      this.setState({ archivedCenters: tmp, archivedCenterTotalPage: tmpTotalPages })
    }
    getArchivedCentersError = error => this.ajaxError( error )

    getCentersSearch = ( query, type, searchParams, searchVal ) => {
      if( type === 'Normal Search' ){
        query = {
          ...query,
          filter: {
            center_name: {
              $like: `%${ searchVal.toLowerCase() }%`
            },
            $or: [
              { status: 'ACTIVE' },
              { status: 'TRIAL' }
            ]
          }
        }
      }
      if( type === 'Advanced Search' ){
        let tmpClient = {}
        let tmpCenter = {}
        searchParams.map( item => {
          if ( [ 'status', 'consultant_id', 'client_id', 'center_group_id', 'is_live_site' ].indexOf( item.val ) < 0 ) {
            tmpCenter[ item.val ] = {
              $like: `%${ item.searchValue.toLowerCase() }%`,
            }
          } else if ( item.val === 'status' ) {
            tmpCenter[ item.val ] = item.searchValue
          } else if ( ['consultant_id', 'client_id', 'center_group_id' ].indexOf( item.val ) > -1 ) {
            tmpCenter[ item.val ] = item.searchValue.id
          } else if ( item.val === 'is_live_site' && (item.searchValue === 'true' || item.searchValue === 'false') ) {
            tmpCenter[ item.val ] = ( item.searchValue === 'true' )
          }
        })

        if( tmpCenter.status ){
          query = {
            ...query,
            filter: {
              ...tmpCenter,
              ...tmpClient,
              $or: [
                { has_consultant: true },
                { has_consultant: false }
              ]
            }
          }
        } else {
          query = {
            ...query,
            filter: {
              ...tmpCenter,
              ...tmpClient,
              $or: [
                { status: 'ACTIVE' },
                { has_consultant: true },
                { has_consultant: false },
                { status: 'TRIAL' }
              ]
            }
          }
        }
      }
      Get(
        `/api/v1/centers?query=${ convertObjToBase64( query ) }`,
        this.getCentersSearchSuccess,
        this.getCentersSearchError,
        this.load
      )
    }
    getCentersSearchSuccess = payload => {
      let tmpTotalPages = []
      let tmpCenters = []
      this.setState({ centers: payload })
      if( payload.data.length > 0 ){
        payload.data.map( item => {
          tmpCenters.push({
            ...item,
            fullname: `${ item.first_name } ${ item.last_name }`
          })
        })
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
        this.setState({
          centers: { data: tmpCenters, meta: payload.meta },
          centerTotalPage: tmpTotalPages
        })
      }else{
        this.ajaxError( Lang[ 'NO_RESULT_FOUND' ][ this.props.data.languageReducer.lang ] )
      }

    }
    getCentersSearchError = error => this.ajaxError( error )

    getCenterLeadConnectID = center_id => Get(
      `/api/v1/centers/${ center_id }/lead_connect_id`,
      payload => this.getCenterLeadConnectIDSuccess( payload, center_id ),
      this.getCenterLeadConnectIDError,
      this.load
    )
    getCenterLeadConnectIDSuccess = (payload, center_id) => this.setState({ leadID: payload.lead_connect_id, centerID: center_id })
    getCenterLeadConnectIDError = error => this.ajaxError( error )

    updateLeadConnect = () => Put(
      `/api/v1/centers/${ this.state.centerID }/lead_connect_id`,
      { lead_connect_id: this.state.leadID },
      this.updateLeadConnectSuccess,
      this.updateLeadConnectError,
      this.load
    )
    updateLeadConnectSuccess = () => {
      let tmp = {
        page: 1,
        is_paginated: true,
        filter: {
          $or: [
            { status: 'ACTIVE' },
            { status: 'TRIAL' }
          ]
        }
      }
      this.requestSuccess( Lang[ 'LEAD_CONNECT_ID_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getCenters( tmp )
      this.setState({ showUpdateLeadConnect: false })
    }
    updateLeadConnectError = error => this.ajaxError( error )

    getAllOpeningHour = ( center_id ) => GetWithNonce(
      `/api/v1/centers/${ center_id }/opening_hours`,
      this.getAllOpeningHourSuccess,
      this.getAllOpeningHourError,
      this.load
    )
    getAllOpeningHourSuccess = payload => this.setState({ openingHours: payload })
    getAllOpeningHourError = error => this.requestError( error )

    deactivateCenter = center_id => Put(
      `/api/v1/centers/${ center_id }/deactivate`,
      {},
      this.deactivateCenterSuccess,
      this.deactivateCenterError,
      this.load
    )
    deactivateCenterSuccess = () => {
      this.requestSuccess( `${ Lang[ 'CENTER' ][ this.props.data.languageReducer.lang ] } ${ Lang[ 'HAS_BEEN_SET_INTO_INACTIVE' ][ this.props.data.languageReducer.lang ] }` )
      this.getCenters({
        page: 1,
        is_paginated: true,
        filter: {
          $or: [
            { status: 'ACTIVE' },
            { status: 'TRIAL' }
          ]
        }
      })
      this.getArchivedCenter({
        page: 1,
        is_paginated: true
      })
    }
    deactivateCenterError = error => this.requestError( error )

    activateCenter = center_id => Put(
      `/api/v1/centers/${ center_id }/activate`,
      {},
      this.activateCenterSuccess,
      this.activateCenterError,
      this.load
    )
    activateCenterSuccess = () => {
      this.requestSuccess( `${ Lang[ 'CENTER' ][ this.props.data.languageReducer.lang ] } ${ Lang[ 'WAS_ACTIVATED' ][ this.props.data.languageReducer.lang ] }` )
      this.getCenters({
        page: 1,
        is_paginated: true,
        filter: {
          $or: [
            { status: 'ACTIVE' },
            { status: 'TRIAL' }
          ]
        }
      })
      this.getArchivedCenter({
        page: 1,
        is_paginated: true
      })
    }
    activateCenterError = error => this.requestError( error )

    deleteCenter = () => Delete(
      `/api/v1/centers/${ this.state.deleteCenterId }`,
      this.deleteCenterSuccess,
      this.deleteCenterError,
      this.load
    )
    deleteCenterSuccess = () => {
      this.requestSuccess( Lang[ 'CENTER_IS_SUCCESSFULLY_DELETED' ][ this.props.data.languageReducer.lang ] )
      this.getArchivedCenter({
        page: 1,
        is_paginated: true
      })
      this.setState({ deleteCenterId: 0 })
    }
    deleteCenterError = error => this.requestError( error )

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            { ...this.props }
            centers={ this.state.centers }
            getCenters={ this.getCenters }
            getSelectedCenterView={ this.getSelectedCenterView }
            getSelectedCenterEdit={ this.getSelectedCenterEdit }
            onLoadCenters={ this.state.loading }
            showNewCenterModal={ this.state.showNewCenterModal }
            showViewCenterModal={ this.state.showViewCenterModal }
            showEditCenterModal={ this.state.showEditCenterModal }
            showDeleteConfirmation={ this.state.showDeleteConfirmation }
            showUpdateLeadConnect={ this.state.showUpdateLeadConnect }
            newCenterData={ this.state.newCenterData }
            postCenter={ this.postCenter }
            updateCenter={ this.updateCenter }
            selectedCenter={ this.state.selectedCenter }
            archivedCenters={ this.state.archivedCenters }
            showArchivedCenterModal={ this.state.showArchivedCenterModal }
            getArchivedCenter={ this.getArchivedCenter }
            getSelectedCenterArchived={ this.getSelectedCenterArchived }
            errorMessage={ this.state.errorMessage }
            getCentersSearch={ this.getCentersSearch }
            centerTotalPage={ this.state.centerTotalPage }
            archivedCenterTotalPage={ this.state.archivedCenterTotalPage }
            onChangeCenterHOC={ this.onChangeCenterHOC }
            toArchiveCenterid={ this.state.toArchiveCenterid }
            confirmUnarchiveModal={ this.state.confirmUnarchiveModal }
            unarchiveCenterID={ this.state.unarchiveCenterID }
            getCenterLeadConnectID={ this.getCenterLeadConnectID }
            leadID={ this.state.leadID }
            updateLeadConnect={ this.updateLeadConnect }
            centerLocations={ this.state.centerLocations }
            updateCenterLocation={ this.updateCenterLocation }
            deleteCenterLocations={ this.deleteCenterLocations }
            createCenterLocation={ this.createCenterLocation }
            deleteLocationConfirmation={ this.state.deleteLocationConfirmation }
            addItemModal={ this.state.addItemModal }
            showOpeningHourModal={ this.state.showOpeningHourModal }
            openingHours={ this.state.openingHours }
            deactivateCenter={ this.deactivateCenter }
            activateCenter={ this.activateCenter }
            deleteCenterId={ this.state.deleteCenterId }
            deleteCenter={ this.deleteCenter } />
          <StatusModal
            isOpen={ this.state.showStatusModal }
            type={ this.state.statusModalType }
            message={ this.state.statusModalMessage }
            onClick={ this.state.onClickStatusModalButton } />
        </Fragment>
      )
    }

  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    getRefreshToken
  } )( CenterWrappedComponent )
}

export default CenterHOC
