import React from 'react'
import { Label, Row, Col, Input }  from 'reactstrap'
import _ from 'lodash'

import Lang from 'Lang/General'

const ListStyle = ( props ) => {
  const {
    lang,
    isShowListStyle,
    listStyle,
    updateListStyle
  } = props

  const colorOptions = [
    {
      key: 'white',
      label: Lang[ 'WHITE' ][ lang ],
      value: '#ffffff'
    },
    {
      key: 'black',
      label: Lang[ 'BLACK' ][ lang ],
      value: '#000000'
    },
    {
      key: 'primary_color',
      label: Lang[ 'PRIMARY_COLOR' ][ lang ],
      value: '#0d6efd'
    },
    {
      key: 'secondary-color',
      label: Lang[ 'SECONDARY_COLOR' ][ lang ],
      value: '#6c757d'
    }
  ]

  const iconOptions = [
    {
      key: 'bullet-point',
      label: Lang[ 'BULLET_POINT' ][ lang ],
      value: 'none'
    },
    {
      key: 'arrow-right-long',
      label: Lang[ 'ARROW_RIGHT_LONG' ][ lang ],
      value: '\\f178'
    },
    {
      key: 'right-long',
      label: Lang[ 'RIGHT_LONG' ][ lang ],
      value: '\\f30b'
    }
  ]

  return isShowListStyle ? (
    <Row style={{ paddingBottom: '50px' }}>
      <Col md={ 6 }>
        <Label>{ Lang[ 'BULLET_POINT_COLOR' ][ lang ] }</Label>
        <Input
          type="select"
          value={ listStyle && listStyle.bulletPointColor }
          className="basic-select"
          onChange={ e => {
            updateListStyle({
              ...listStyle,
              bulletPointColor: e.target.value === 'none' ? null : e.target.value
            })
          }}
        >
          <option value={ "none" }>{ Lang[ 'SELECT_ONE' ][ lang ] }</option>
          {
           colorOptions.map( color => {
              return(
                <option key={ color.key } value={ color.value }>{ color.label }</option>
              )
            })
          }
        </Input>
      </Col>
      <Col md={ 6 }>
        <Label>{ Lang[ 'BULLET_POINT_ICON' ][ lang ] }</Label>
        <Input
          type="select"
          value={ listStyle && listStyle.bulletPointIcon }
          className="basic-select"
          onChange={ e => {
            updateListStyle({
              ...listStyle,
              bulletPointIcon: e.target.value === 'none' ? null : e.target.value
            })
          }}
        >
          {
           iconOptions.map( icon => {
              return(
                <option key={ icon.key } value={ icon.value }>{ icon.label }</option>
              )
            })
          }
        </Input>
      </Col>
    </Row>
  ) : (
    <></>
  )
}
export default ListStyle