import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Button
} from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import Lang from 'Lang/General'

const ConfirmationModal = props => {
  return(
    <Modal
      isOpen={ props.deleteOpen }
      size="lg">
      <ModalHeader toggle={ props.handleModalClose }>{ Lang['NOTIFICATION'][ props.lang ] }</ModalHeader>
      <ModalBody>
        <p>{ Lang[ 'DELETE_CONFIRMATION_CONSULTANT' ][ props.lang ] }</p>
        <br />
        {
          !_.isEmpty( props.assignedCenters ) && (
            <>
              <p>{ Lang['CENTER_ASSIGNED'][ props.lang ] }</p>
              <ReactTable
                data={ props.assignedCenters }
                columns={[
                  {
                    Header: Lang[ 'CENTER_NAME' ][ props.lang ],
                    accessor: 'center_name'
                  },
                  {
                    Header: Lang[ 'FIRST_NAME' ][ props.lang ],
                    accessor: 'first_name'
                  },
                  {
                    Header: Lang[ 'LAST_NAME' ][ props.lang ],
                    accessor: 'last_name'
                  },
                  {
                    Header: Lang[ 'EMAIL' ][ props.lang ],
                    accessor: 'email'
                  },
                ]}
                pageSize={ props.assignedCenters.length }
                showPagination={ false } />
              <Button
                className="mt-5"
                color="primary"
                onClick={ () => props.downloadAssignedCenters( props.toArchiveConsultantId )}>{ Lang[ 'DOWNLOAD_PDF' ][ props.lang ] }</Button>
            </>
          )
        }


      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={ props.handleModalClose }>{ Lang[ 'CANCEL' ][ props.lang ] }</Button>
        <Button color="primary" onClick={ props.confirmAction }>{ Lang[ 'ARCHIVE' ][ props.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ConfirmationModal
