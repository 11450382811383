import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get, Post, Put } from 'utils/axios'

import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state={
      loading: false,
      requestStatus: [],
      selectedRequestStatus: {
        name: {
          en: '',
          de: '',
          it: '',
          nl: ''
        },
        color: '',
        position: 0
      },
      showCreateStatus: false,
      showEditStatus: false,
      newStatusData: {
        name: {
          en: '',
          de: '',
          it: '',
          nl: ''
        },
        color: '#fff',
        postion: 0
      },
    }
    onChangeRequestStatusHOC = ( key, value ) => this.setState({ [key]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    getAllRequestStatus = () => Get(
      `/api/v1/admin_panel_product_request_status`,
      this.getAllRequestStatusStatus,
      this.getAllRequestStatusError,
      this.load
    )
    getAllRequestStatusStatus = payload => {
      this.setState({ requestStatus: payload })
    }
    getAllRequestStatusError = error => this.requestError( error )

    getSelectedRequestStatus = id => Get(
      `/api/v1/admin_panel_product_request_status`,
      payload => this.getSelectedRequestStatusSuccess( id, payload ),
      this.getSelectedRequestStatusError,
      this.load
    )
    getSelectedRequestStatusSuccess = (id, payload) => {
      let tmp = _.find( payload, { id: id } )
      this.setState({ selectedRequestStatus: tmp })
    }
    getSelectedRequestStatusError = error => this.requestError( error )

    createStatus = () => Post(
      `/api/v1/admin_panel_product_request_status`,
      this.state.newStatusData,
      this.createStatusSuccess,
      this.createStatusError,
      this.load
    )
    createStatusSuccess = payload => {
      this.requestSuccess( Lang[ 'SUCCESS_CREATE' ][ this.props.data.languageReducer.lang ] )
      this.getAllRequestStatus()
      this.setState({ showCreateStatus: false })
    }
    createStatusError = error => {
      if( error.name ) {
        this.requestError( error.name )
      }
    }

    updateStatus = () => Put(
      `/api/v1/admin_panel_product_request_status/${ this.state.selectedRequestStatus.id }`,
      this.state.selectedRequestStatus,
      this.updateStatusSuccess,
      this.updateStatusError,
      this.load
    )
    updateStatusSuccess = payload => {
      this.setState({ showEditStatus: false })
      this.requestSuccess( Lang[ 'SUCCESS_UPDATE' ][ this.props.data.languageReducer.lang ] )
      this.getAllRequestStatus()
    }
    updateStatusError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          getAllRequestStatus={ this.getAllRequestStatus }
          requestStatus={ this.state.requestStatus }
          onChangeRequestStatusHOC={ this.onChangeRequestStatusHOC }
          requestEditModal={ this.state.requestEditModal }
          getSelectedRequestStatus={ this.getSelectedRequestStatus }
          selectedRequestStatus={ this.state.selectedRequestStatus }
          showCreateStatus={ this.state.showCreateStatus }
          showEditStatus={ this.state.showEditStatus }
          newStatusData={ this.state.newStatusData }
          createStatus={ this.createStatus }
          updateStatus={ this.updateStatus }
          onLoadRequestStatus={ this.state.loading }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}
export default HOC
