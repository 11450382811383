import React, { useState, useEffect } from 'react'
import _ from 'lodash'

import RenderDOM from '../RenderDOM'
import Lang from 'Lang/General'

const FormFrame = ({
  content,
  onChangeFormBuilderState,
  mode,
  formValues,
  onPatchFormValues,
  onClickSubmitForm,
  createSubmittedFormMedia,
  dom,
  findObjectAndPatch,
  currentLanguage
}) => {
  const [ isTitleShown, setTitleShown ] = useState(true)
  useEffect(()=>{
    if( content.title.display ){
      setTitleShown( content.title.display !== "none" )
    } else {
      setTitleShown( true )
    }
  }, [ content ])

  return (
    <>
      <div
        style={{
          border: `${ content.container.borderWidth }px solid ${ content.container.borderColor }`,
          minHeight: 'fit-content',
          cursor: 'pointer'
        }}>
        <div
          style={{
            ...content.title,
            minHeight: "50px",
            padding: isTitleShown ? content.title.padding : "20px",
            fontSize: isTitleShown ? content.title.fontSize : "20px",
            backgroundColor: isTitleShown ? content.title.backgroundColor : "#bbb",
            display: "block"
          }}
          onClick={() => {
            onChangeFormBuilderState( 'selectedComponentToEdit', { ...content })
            onChangeFormBuilderState( 'showUpdateFormFrame', true )
          }}>
          { 
            isTitleShown
              ? content.content[ currentLanguage ? currentLanguage : 'en' ] 
              : Lang[ 'THIS_BAR_IS_HIDDEN' ][ currentLanguage ? currentLanguage : 'en' ]
          }
        </div>
        {
          content.children && content.children.map( item => {
            return RenderDOM( 
              item, 
              onChangeFormBuilderState, 
              mode, 
              formValues,
              onPatchFormValues,
              onClickSubmitForm,
              createSubmittedFormMedia,
              dom,
              findObjectAndPatch,
              currentLanguage
            )
          })
        }
      </div>
    </>
  )
}

export default FormFrame