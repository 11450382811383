import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import {
  Form, FormGroup, 
  Label, Input, Container, CustomInput, Button,
  Card, CardBody
} from 'reactstrap'
import Moment from 'moment'
import ReactSelect from 'react-select'
import { toast } from 'react-toastify'

import Lang from 'Lang/General'

const countryOptions = [
  'Austria',
  'Belgium',
  'France',
  'Germany',
  'Italy',
  'Netherlands',
  'Spain',
  'Switzerland',
]

const FunnelGeneral = ( props ) => {
  const langButton = [ 'en', 'de', 'es', 'it', 'fr', 'nl' ]
  const [ selectedTitleLang, setLangTitle ] = useState( 'en' )
  const [ selectedDescLang, setLangDescription ] = useState( 'en' )

  useEffect( () => {
    setLangTitle( props.selectedLanguage )
    setLangDescription( props.selectedLanguage )
  }, [] )

  const onChange = ( key, val ) => {
    if( props.data.id ) {
      let tmp = _.cloneDeep( props.data )
      tmp[key] = val
      props.onChangeSystemFunnelsHOC( 'selectedFunnel', tmp )
    } else {
      let tmp = _.cloneDeep( props.data )
      tmp[key] = val
      props.onChangeSystemFunnelsHOC( 'newFunnelData', tmp )
    }
  }

  return(
    <Container fluid className="mt-3">
      <Card className="main-card mb-3">
        <CardBody>
          <Form>
            <table style={{ tableLayout: "auto", width: "100%", borderCollapse: "separate", borderSpacing: "0 10px" }}>
              <tr>
                <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                  <Label for="funnel_title" sm={ 2 }>{ Lang['TITLE'][ props.selectedLanguage ] } <span style={{ color: 'red'}}>*</span></Label>
                </td>
                <td>
                  <div className="d-flex pt-1">
                    {
                      langButton.map( button => {
                        return(
                          <Button
                            className="btn-square btn-wide mr-0" size="sm"
                            color={ `${ selectedTitleLang === button ? 'primary' : 'light' }` }
                            onClick={ () => setLangTitle( button ) }>{ button }</Button>
                        )
                      })
                    }
                  </div>
                  <FormGroup className='m-0'>
                    <Input
                      name="funnel_title" id="funnel_title"
                      placeholder={ Lang[ 'ENTER_FUNNEL_TITLE' ][ props.selectedLanguage ] }
                      value={ props.data.title[selectedTitleLang] }
                      onChange={ e => {
                        let tmp = _.cloneDeep( props.data.title )
                        tmp[ selectedTitleLang ] = e.target.value
                        onChange( 'title', tmp )
                      }} />
                  </FormGroup>
                  {
                    props.systemFunnelErrorMsg.title && (
                      <span style={{ color: 'red' }}>{ props.systemFunnelErrorMsg.title }</span>
                    )
                  }
                </td>
                <td>
                  <FormGroup className="mb-0" style={{ marginLeft: '25px' }}>
                    <Label
                      className="ml-1 mb-1">
                      <Input
                        type="checkbox" name="radio1"
                        checked={ props.data.center_editable_config[0].is_editable }
                        onChange={ (e) => {
                          let tmpEditableConfig = _.cloneDeep( props.data.center_editable_config )
                          tmpEditableConfig[0]['is_editable'] = e.target.checked
                          onChange( 'center_editable_config', tmpEditableConfig)
                        }}/>{' '}
                      { Lang['EDITABLE_BY_CENTER_ADMIN'][ props.selectedLanguage ] }
                    </Label>
                  </FormGroup>
                </td>
              </tr>
              <tr>
                <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                  <Label for="funnel_title" sm={ 2 }>{ Lang['DESCRIPTION'][ props.selectedLanguage ] }<span style={{ color: 'red'}}>*</span></Label>
                </td>
                <td>
                  <div className="d-flex pt-1">
                    {
                      langButton.map( button => {
                        return(
                          <Button
                            className="btn-square btn-wide mr-0" size="sm"
                            color={ `${ selectedDescLang === button ? 'primary' : 'light' }` }
                            onClick={ () => setLangDescription( button ) }>{ button }</Button>
                        )
                      })
                    }
                  </div>
                  <FormGroup className='m-0'>
                    <Input
                      type="textarea"
                      name="funnel_title" id="funnel_title"
                      placeholder={ Lang[ 'ENTER_FUNNEL_DESC' ][ props.selectedLanguage ] }
                      value={ props.data.description[selectedDescLang] }
                      onChange={ e => {
                        let tmp = _.cloneDeep( props.data.description )
                        tmp[ selectedDescLang ] = e.target.value
                        onChange( 'description', tmp )
                      }} />
                  </FormGroup>
                  {
                    props.systemFunnelErrorMsg.description && (
                      <span style={{ color: 'red', marginBottom: '15px' }}>{ props.systemFunnelErrorMsg.description }</span>
                    )
                  }
                </td>
                <td>
                  <FormGroup className="mb-0" style={{ marginLeft: '25px' }}>
                    <Label
                      className="ml-1 mb-1">
                      <Input
                        type="checkbox" name="radio1"
                        checked={ props.data.center_editable_config[1].is_editable }
                        onChange={ (e) => {
                          let tmpEditableConfig = _.cloneDeep( props.data.center_editable_config )
                          tmpEditableConfig[1]['is_editable'] = e.target.checked
                          onChange( 'center_editable_config', tmpEditableConfig)
                        }}/>{' '}
                      { Lang['EDITABLE_BY_CENTER_ADMIN'][ props.selectedLanguage ] }
                    </Label>
                  </FormGroup>
                </td>
              </tr>
              <tr>
                <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                  <Label for="funnel_title" sm={ 2 }>{ '' }</Label>
                </td>
                <td>
                  <FormGroup className='m-0 ml-3 mt-2'>
                    <CustomInput
                      id="radio-start-if-triggered"
                      type="radio"
                      label={ Lang['START_IF_TRIGGERED'][ props.selectedLanguage ] }
                      checked={ props.data.start_type === 'start_if_triggered' }
                      onChange={ () => {
                        onChange('start_type', 'start_if_triggered')
                      }} />
                    <CustomInput
                      id="radio-start-on-fixed-date"
                      type="radio"
                      label={ Lang['START_ON_FIX_DAY'][ props.selectedLanguage ] }
                      checked={ props.data.start_type === 'start_on_fixed_date' }
                      onChange={ () => {
                        onChange('start_type', 'start_on_fixed_date')
                      }} />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="mb-0" style={{ marginLeft: '25px' }}>
                    <Label
                      className="ml-1 mb-1">
                      <Input
                        type="checkbox"
                        checked={ props.data.center_editable_config[2].is_editable }
                        onChange={ (e) => {
                          let tmpEditableConfig = _.cloneDeep( props.data.center_editable_config )
                          tmpEditableConfig[2]['is_editable'] = e.target.checked
                          onChange( 'center_editable_config', tmpEditableConfig)
                        }}/>{' '}
                      { Lang['EDITABLE_BY_CENTER_ADMIN'][ props.selectedLanguage ] }
                    </Label>
                  </FormGroup>
                </td>
              </tr>
              <tr>
                <td>
                </td>
                <td>
                {
                  props.data.start_type === 'start_on_fixed_date' && (
                    <FormGroup>
                      <Input
                        type="date"
                        value={ props.data.start_fix_date }
                        min={ Moment().format( 'YYYY-MM-DD' ) }
                        onChange={ e => onChange( 'start_fix_date', e.target.value ) } 
                      />
                    </FormGroup>
                  )
                }
                </td>
                <td>
                {
                  props.data.start_type === 'start_on_fixed_date' && (
                    <FormGroup className="mb-0" style={{ marginLeft: '25px' }}>
                      <Label
                        className="ml-1 mb-1">
                        <Input
                          type="checkbox"
                          checked={ props.data.center_editable_config[3].is_editable }
                          onChange={ (e) => {
                            let tmpEditableConfig = _.cloneDeep( props.data.center_editable_config )
                            tmpEditableConfig[3]['is_editable'] = e.target.checked
                            onChange( 'center_editable_config', tmpEditableConfig)
                          }}/>{' '}
                        { Lang['EDITABLE_BY_CENTER_ADMIN'][ props.selectedLanguage ] }
                      </Label>
                    </FormGroup>
                  )
                }
                </td>
              </tr>
              <tr>
                <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                  <Label for="funnel_center_group" sm={ 2 }>{ Lang['CENTER_GROUPS'][ props.selectedLanguage ] }<span style={{ color: 'red'}}>*</span></Label>
                </td>
                <td>
                  <ReactSelect
                    isMulti
                    name="center-groups"
                    value={ props.data.center_groups }
                    options={ props.centerGroups }
                    onChange={ (value, action) => {
                      onChange( 'center_groups', value )
                    }}/>
                  {
                    props.systemFunnelErrorMsg.center_groups && (
                      <span style={{ color: 'red', marginBottom: '15px' }}>{ props.systemFunnelErrorMsg.center_groups }</span>
                    )
                  }
                </td>
                <td>
                  <FormGroup className="mb-0" style={{ marginLeft: '25px' }}>
                    <Label
                      className="ml-1 mb-1">
                      <Input
                        type="checkbox"
                        checked={ props.data.center_editable_config[7].is_editable }
                        onChange={ (e) => {
                          let tmpEditableConfig = _.cloneDeep( props.data.center_editable_config )
                          tmpEditableConfig[7]['is_editable'] = e.target.checked
                          onChange( 'center_editable_config', tmpEditableConfig)
                        }}/>{' '}
                      { Lang['EDITABLE_BY_CENTER_ADMIN'][ props.selectedLanguage ] }
                    </Label>
                  </FormGroup>
                </td>
              </tr>
              <tr>
                <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                  <Label for="funnel_center_group" sm={ 2 }>{ Lang['COUNTRY'][ props.selectedLanguage ] }<span style={{ color: 'red'}}>*</span></Label>
                </td>
                <td>
                  <ReactSelect
                    isMulti
                    name="country"
                    value={  
                      _.filter( 
                        props.countries, 
                        country => _.findIndex( props.data.countries, item => item.id === country.id ) > -1 
                      ).map( item => ({ ...item, label: item.name, value: item.name }) )
                    }
                    options={ 
                      _.filter( 
                        props.countries, 
                        country => _.findIndex( countryOptions, item => item === country.name ) > -1 
                      ).map( item => ({ ...item, label: item.name, value: item.name }) )
                    }
                    onChange={ (value, action) => {
                      onChange('countries', value )
                    }}/>
                  {
                    props.systemFunnelErrorMsg.countries && (
                      <span style={{ color: 'red', marginBottom: '15px' }}>{ props.systemFunnelErrorMsg.countries }</span>
                    )
                  }
                </td>
                <td>
                  <FormGroup className="mb-0" style={{ marginLeft: '25px' }}>
                    <Label
                      className="ml-1 mb-1">
                      <Input
                        type="checkbox"
                        checked={ props.data.center_editable_config[8].is_editable }
                        onChange={ (e) => {
                          let tmpEditableConfig = _.cloneDeep( props.data.center_editable_config )
                          tmpEditableConfig[8]['is_editable'] = e.target.checked
                          onChange( 'center_editable_config', tmpEditableConfig)
                        }}/>{' '}
                      { Lang['EDITABLE_BY_CENTER_ADMIN'][ props.selectedLanguage ] }
                    </Label>
                  </FormGroup>
                </td>
              </tr>
              <tr>
                <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                  <Label for="funnel_scenario" sm={ 2 }>{ Lang['SCENARIO'][ props.selectedLanguage ] }<span style={{ color: 'red'}}>*</span></Label>
                </td>
                <td>
                  <ReactSelect
                    name="scenario"
                    value={ _.find( props.scenariosForSelect, { id: props.data.scenario_id } ) }
                    options={ props.scenariosForSelect }
                    onChange={ (value, action) => {
                      onChange('scenario_id', value.id )
                    }}/>
                  {
                    props.systemFunnelErrorMsg.scenario_id && (
                      <span style={{ color: 'red', marginBottom: '15px' }}>{ props.systemFunnelErrorMsg.scenario_id }</span>
                    )
                  }
                </td>
                <td>
                  <FormGroup className="mb-0" style={{ marginLeft: '25px' }}>
                    <Label
                      className="ml-1 mb-1">
                      <Input
                        type="checkbox"
                        checked={ props.data.center_editable_config[9].is_editable }
                        onChange={ (e) => {
                          let tmpEditableConfig = _.cloneDeep( props.data.center_editable_config )
                          tmpEditableConfig[9]['is_editable'] = e.target.checked
                          onChange( 'center_editable_config', tmpEditableConfig)
                        }}/>{' '}
                      { Lang['EDITABLE_BY_CENTER_ADMIN'][ props.selectedLanguage ] }
                    </Label>
                  </FormGroup>
                </td>
              </tr>
              <tr>
                <td colSpan={ 3 }><p style={{ width: 'initial', paddingLeft: '15px' }}>{ Lang['START_END_DATE_TITLE'][ props.selectedLanguage ] }</p></td>
              </tr>
              <tr>
                <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                  <Label for="funnel_start_date" sm={ 2 }>{ Lang['START_DATE'][ props.selectedLanguage ] }</Label>
                </td>
                <td>
                  <FormGroup className='m-0'>
                    <Input
                      type="date"
                      value={ Moment( props.data.start_date ).format( 'YYYY-MM-DD' ) }
                      min={ Moment( new Date() ).format( 'YYYY-MM-DD' ) }
                      max={ Moment( props.data.end_date ).format( 'YYYY-MM-DD' ) }
                      onChange={ e => {
                        let tmp = Moment( e.target.value ).utc().format()
                        onChange( 'start_date', tmp )
                      }} />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="mb-0" style={{ marginLeft: '25px' }}>
                    <Label
                      className="ml-1 mb-1">
                      <Input
                        type="checkbox"
                        checked={ props.data.center_editable_config[4].is_editable }
                        onChange={ (e) => {
                          let tmpEditableConfig = _.cloneDeep( props.data.center_editable_config )
                          tmpEditableConfig[4]['is_editable'] = e.target.checked
                          onChange( 'center_editable_config', tmpEditableConfig)
                        }}/>{' '}
                      { Lang['EDITABLE_BY_CENTER_ADMIN'][ props.selectedLanguage ] }
                    </Label>
                  </FormGroup>
                </td>
              </tr>
              {/*<tr>
                <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                  <Label for="funnel_title" sm={ 2 }>{ '' }</Label>
                </td>
                <td>
                  <FormGroup className='m-0 ml-3'>
                    <Label
                      check={ props.data.has_expiry_date }
                      className="ml-1 mb-1">
                      <Input
                        type="checkbox" name="radio1"
                        onChange={ (e) => {
                          onChange( 'has_expiry_date', e.target.checked )
                        }}/>{' '}
                      { Lang['HAS_EXPIRY_DATE'][ props.selectedLanguage ] }
                    </Label>
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="mb-0" style={{ marginLeft: '25px' }}>
                    <Label
                      className="ml-1 mb-1">
                      <Input
                        type="checkbox"
                        checked={ props.data.center_editable_config[6].is_editable }
                        onChange={ (e) => {
                          let tmpEditableConfig = _.cloneDeep( props.data.center_editable_config )
                          tmpEditableConfig[6]['is_editable'] = e.target.checked
                          onChange( 'center_editable_config', tmpEditableConfig)
                        }}/>{' '}
                      { Lang['EDITABLE_BY_CENTER_ADMIN'][ props.selectedLanguage ] }
                    </Label>
                  </FormGroup>
                </td>
              </tr>*/}
              <tr>
                <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                  <Label for="funnel_end_date" sm={ 2 }>{ Lang['END_DATE'][ props.selectedLanguage ] }</Label>
                </td>
                <td>
                <FormGroup className='m-0'>
                  <Input
                    type="date"
                    value={ Moment( props.data.end_date ).format( 'YYYY-MM-DD' ) }
                    min={ Moment( props.data.start_date ).format( 'YYYY-MM-DD' ) }
                    onChange={ e => {
                      let tmp = Moment( e.target.value ).utc().format()
                      onChange( 'end_date', tmp )
                    }} />
                </FormGroup>
                </td>
                <td>
                  <FormGroup className="mb-0" style={{ marginLeft: '25px' }}>
                    <Label
                      className="ml-1 mb-1">
                      <Input
                        type="checkbox"
                        checked={ props.data.center_editable_config[5].is_editable }
                        onChange={ (e) => {
                          let tmpEditableConfig = _.cloneDeep( props.data.center_editable_config )
                          tmpEditableConfig[5]['is_editable'] = e.target.checked
                          onChange( 'center_editable_config', tmpEditableConfig)
                        }}/>{' '}
                      { Lang['EDITABLE_BY_CENTER_ADMIN'][ props.selectedLanguage ] }
                    </Label>
                  </FormGroup>
                </td>
              </tr>
              <tr>
                <td style={{ width: "1px", whiteSpace: "nowrap", paddingRight: "20px" }}>
                  <Label for="funnel_end_date" sm={ 2 }>{ Lang['STATUS'][ props.selectedLanguage ] }</Label>
                </td>
                <td>
                  <FormGroup className='m-0'>
                    <Input
                      type="select"
                      value={ props.data.status }
                      onChange={ e => onChange( 'end_date', e.target.value ) } >
                      <option value='ACTIVE' >{ Lang[ 'ACTIVE' ][ props.selectedLanguage ] }</option>
                      <option value='INACTIVE' >{ Lang[ 'INACTIVE' ][ props.selectedLanguage ] }</option>
                      <option value='ARCHIVED' >{ Lang[ 'ARCHIVED' ][ props.selectedLanguage ] }</option>
                    </Input>
                  </FormGroup>
                </td>
              </tr>
            </table>
            {
              props.data.id && (
                <FormGroup className='mb-0 mt-2 mr-0' style={{ marginLeft: '30px' }}>
                  <Label
                    className="ml-2 mb-1">
                    <Input
                      type="checkbox"
                      checked={ props.data.center_editable_config[10].is_editable }
                      onChange={ (e) => {
                        let tmpEditableConfig = _.cloneDeep( props.data.center_editable_config )
                        tmpEditableConfig[10]['is_editable'] = e.target.checked
                        onChange( 'center_editable_config', tmpEditableConfig)
                      }}/>{' '}
                    { Lang['CAN_CENTER_ADMIN_EDIT'][ props.selectedLanguage ] }
                  </Label>
                </FormGroup>
              )
            }
          </Form>
        </CardBody>
      </Card>
      <Button
        color="primary"
        style={{ float: 'right' }}
        onClick={ () => {
          if( (props.data.start_date === null && props.data.end_date === null) || ( (props.data.start_date !== null && props.data.end_date !== null) ) ){
            let tmp = _.cloneDeep( props.data )
            tmp.start_fix_date = Moment( tmp.start_fix_date ).utc().format()
            tmp.mailings.map( mailing => {
              mailing.time_to_send = Moment( mailing.time_to_send, 'HH:mm' ).utc().format()
            })
            if( props.data.id ) {
              props.updateSystemFunnel( tmp, props.data.id )
            } else {
              props.postSystemFunnel( tmp )
            }
          } else {
            toast.error( Lang['END_DATE_REQUIRED'][ props.selectedLanguage ] )
          }
        }}>{ Lang['SUBMIT'][ props.selectedLanguage ] }
      </Button>
    </Container>
  )
}

export default FunnelGeneral
