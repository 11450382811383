import React, { useState , useEffect} from 'react'
import {
  Row, Col,
  Input, Label, Button,
  Card, CardHeader, CardBody,
  Form, FormGroup, FormFeedback,
  Modal, ModalHeader, ModalBody, ModalFooter,
  TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap'
import { registerPlugin } from "react-filepond"
import { connect } from 'react-redux'
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import CKEditor5 from 'components/RTE/ckeditor5'
import classnames from 'classnames'
import Moment from 'moment'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import MultiLangInput from 'components/Input/MultiLang'
import MediaDatabase from 'SharedModules/MediaDatabase' 
import FormEditor from '../Form'
import { toggleMediaDatabase } from 'actions/MediaDatabaseToggle'

import Lang from 'Lang/General'

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
import "filepond/dist/filepond.min.css"

registerPlugin( FilePondPluginImagePreview )

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const Create = props => {
  let {
    onChangeCampaignListingHOC,
    newCampaignListing,
    showCreateCampaign,
    data,
    createOLSCampaign,
    campaignErrors,
    onLoadCampaignListings,
    bookableCountries,
    centerGroups,
    campaignTypes,
    toggleMediaDatabase,
    imageKey
  } = props
  const [ activeTab, setActiveTab ] = useState( 1 )

  useEffect( () => {
    setActiveTab( 1 )
  }, [] )

  const updateCampaignListingData = ( key, val ) => {
    let tmp = _.cloneDeep( newCampaignListing )
    tmp[ key ] = val
    return onChangeCampaignListingHOC( 'newCampaignListing', tmp )
  }

  return (
    <Modal
      isOpen={ showCreateCampaign }
      size={ 'xl' }
      toggle={() => onChangeCampaignListingHOC( 'showCreateCampaign', false )}>
      <ModalHeader toggle={() => onChangeCampaignListingHOC( 'showCreateCampaign', false )}>{ Lang[ 'CREATE_CAMPAIGN' ][ data.languageReducer.lang ] }</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault() }>
          <Nav tabs={ true } className='mb-4' >
            <NavItem>
              <NavLink
                href="javascript:void(0);"
                className={ classnames({ active: activeTab === 1 }) }
                onClick={ () => setActiveTab( 1 ) } >
                { Lang[ 'GENERAL' ][ data.languageReducer.lang ] }
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={ classnames({ active: activeTab === 2 }) }
                onClick={ () => setActiveTab( 2 ) } >
                { Lang[ 'BOOKING_FORM' ][ data.languageReducer.lang ] }
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={ activeTab }>
            <TabPane tabId={ 1 } >
              <Row>
                <Col md={ 8 }>
                  <FormGroup>
                    <Label>{ Lang[ 'NAME' ][ data.languageReducer.lang ] }</Label>
                    <Input
                      type={ 'text' }
                      value={ newCampaignListing.title }
                      onChange={ e => {
                        updateCampaignListingData( 'title', e.target.value ) 
                      }}
                      invalid={ campaignErrors.title && _.isEmpty( newCampaignListing.title ) } />
                    <FormFeedback>{ campaignErrors.title }</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label>{ Lang[ 'UPLOAD_TEASER_IMAGE' ][ data.languageReducer.lang ] }</Label>
                    <div className="d-flex flex-row flex-nowrap" >
                      <div className="d-flex flex-column flex-grow-1 mr-2" >
                        <Input
                          type="text"
                          value={ newCampaignListing.teaser_image_url }
                          onChange={ e => updateCampaignListingData( 'teaser_image_url', e.target.value ) }
                          disabled={ true }
                          invalid={ campaignErrors.teaser_image_url }
                          style={{ borderRadius: "0.25rem 0.25rem 0 0" }} 
                        />
                        <img 
                          src={ newCampaignListing.teaser_image_url || null }
                          style={{
                            width: "100%",
                            maxWidth: "100%",
                            height: "100px",
                            objectFit: "contain",
                            backgroundColor: "#ffffff",
                            border: "1px solid #ced4da",
                            borderTop: "0",
                            borderRadius: "0 0 0.25rem 0.25rem",
                            marginBottom: "8px" 
                          }} 
                        />
                        <FormFeedback>{ campaignErrors.teaser_image_url }</FormFeedback>
                      </div>
                      <Button
                        color="primary"
                        style={{ whiteSpace: 'nowrap', height: 'fit-content' }}
                        onClick={() => {
                          toggleMediaDatabase( true )
                          onChangeCampaignListingHOC( 'imageKey', 'teaser_image_url' )  
                        }}>
                        { Lang[ 'SELECT_IMAGE' ][ data.languageReducer.lang ] }</Button>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label>{ Lang[ 'DESCRIPTION' ][ data.languageReducer.lang ] }</Label>
                    <CKEditor5
                      disabledAutoInline
                      data={ newCampaignListing.description }
                      onChange={ ( event, editor ) => {
                        updateCampaignListingData( 'description', editor.getData() )
                      }}
                      toolbar={[ 
                        "heading", "|", "bold", "italic", "indent", "outdent", "numberedList", "bulletedList", "|", 
                        "blockQuote", "selectAll", "undo", "redo", "|", 
                        "insertTable", "tableColumn", "tableRow", "mergeTableCells", "fontFamily"
                      ]}
                      invalid={ _.isEmpty( newCampaignListing.description ) && campaignErrors.description } />
                  </FormGroup>
                  <FormGroup>
                    <Label>{ Lang[ 'UPLOAD_INFO_IMAGE' ][ data.languageReducer.lang ] }</Label>
                    <div className="d-flex flex-row flex-nowrap" >
                      <div className="d-flex flex-column flex-grow-1 mr-2" >
                        <Input
                          type="text"
                          value={ newCampaignListing.info_image_url }
                          onChange={ e => updateCampaignListingData( 'info_image_url', e.target.value ) }
                          disabled={ true }
                          invalid={ campaignErrors.info_image_url }
                          style={{ borderRadius: "0.25rem 0.25rem 0 0" }} 
                        />
                        <img 
                          src={ newCampaignListing.info_image_url || null }
                          style={{
                            width: "100%",
                            maxWidth: "100%",
                            height: "100px",
                            objectFit: "contain",
                            backgroundColor: "#ffffff",
                            border: "1px solid #ced4da",
                            borderTop: "0",
                            borderRadius: "0 0 0.25rem 0.25rem",
                            marginBottom: "8px" 
                          }} 
                        />
                        <FormFeedback>{ campaignErrors.info_image_url }</FormFeedback>
                      </div>
                      <Button
                        color="primary"
                        style={{ whiteSpace: 'nowrap', height: 'fit-content' }}
                        onClick={() => {
                          toggleMediaDatabase( true )
                          onChangeCampaignListingHOC( 'imageKey', 'info_image_url' )  
                        }}>
                        { Lang[ 'SELECT_IMAGE' ][ data.languageReducer.lang ] }</Button>
                    </div>
                  </FormGroup>
                  <MultiLangInput
                    label={ Lang[ 'PREVIEW_TEXT' ][ data.languageReducer.lang ] }
                    value={ newCampaignListing.preview_text }
                    onChange={ val => updateCampaignListingData( 'preview_text', val ) }
                    selectedLanguage={ data.languageReducer.lang } />
                  <FormGroup>
                    <Label>{ Lang[ 'UPLOAD_PREVIEW_IMAGE' ][ data.languageReducer.lang ] }</Label>
                    <div className="d-flex flex-row flex-nowrap" >
                      <div className="d-flex flex-column flex-grow-1 mr-2" >
                        <Input
                          type="text"
                          value={ newCampaignListing.preview_image_url }
                          onChange={ e => updateCampaignListingData( 'preview_image_url', e.target.value ) }
                          disabled={ true }
                          invalid={ campaignErrors.preview_image_url }
                          style={{ borderRadius: "0.25rem 0.25rem 0 0" }} 
                        />
                        <img 
                          src={ newCampaignListing.preview_image_url || null }
                          style={{
                            width: "100%",
                            maxWidth: "100%",
                            height: "100px",
                            objectFit: "contain",
                            backgroundColor: "#ffffff",
                            border: "1px solid #ced4da",
                            borderTop: "0",
                            borderRadius: "0 0 0.25rem 0.25rem",
                            marginBottom: "8px" 
                          }} 
                        />
                        <FormFeedback>{ campaignErrors.preview_image_url }</FormFeedback>
                      </div>
                      <Button
                        color="primary"
                        style={{ whiteSpace: 'nowrap', height: 'fit-content' }}
                        onClick={() => {
                          toggleMediaDatabase( true )
                          onChangeCampaignListingHOC( 'imageKey', 'preview_image_url' )  
                        }}>
                        { Lang[ 'SELECT_IMAGE' ][ data.languageReducer.lang ] }</Button>
                    </div>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      type="checkbox"
                      checked={ newCampaignListing.is_new }
                      onChange={ e => {
                        updateCampaignListingData( 'is_new', e.target.checked )
                      }} />
                    { Lang[ 'IS_THIS_NEW' ][ data.languageReducer.lang ] }
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      type="checkbox"
                      checked={ newCampaignListing.display_to_one_timer }
                      onChange={ e => {
                        updateCampaignListingData( 'display_to_one_timer', e.target.checked )
                      }} />
                    { Lang[ 'VIEW_CAMPAIGN_IN_THE_PUBLIC_SECTION' ][ data.languageReducer.lang ] }
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      type="checkbox"
                      checked={ newCampaignListing.is_app_campaign }
                      onChange={ e => {
                        updateCampaignListingData( 'is_app_campaign', e.target.checked )
                      }} />
                    { Lang[ 'APP_CAMPAIGN' ][ data.languageReducer.lang ] }
                  </FormGroup>
                  {
                    newCampaignListing.is_app_campaign && (
                      <Card className="mt-3">
                        <CardHeader>{ Lang[ 'THREE_SIX_ZERO_APP_INFO' ][ data.languageReducer.lang ] }</CardHeader>
                        <CardBody>
                          <Row>
                            <Col md={ 6 }>
                              <FormGroup>
                                <Label>{ Lang[ 'THREE_SIX_ZERO_TITLE' ][ data.languageReducer.lang ] }</Label>
                                <Input
                                  type="text"
                                  value={ newCampaignListing[ '360_app_campaign' ][ 'title' ] }
                                  onChange={ e => {
                                    updateCampaignListingData( '360_app_campaign', {
                                      ...newCampaignListing[ '360_app_campaign' ],
                                      title: e.target.value
                                    })
                                  }}
                                  invalid={ campaignErrors.title && _.isEmpty( newCampaignListing[ '360_app_campaign' ][ 'title' ] ) } />
                                <FormFeedback>{ campaignErrors.title }</FormFeedback>
                              </FormGroup>
                              <FormGroup>
                                <Label>{ Lang[ 'THREE_SIX_ZERO_DESC' ][ data.languageReducer.lang ] }</Label>
                                <Input
                                  type="text"
                                  value={ newCampaignListing[ '360_app_campaign' ][ 'description' ] }
                                  onChange={ e => {
                                    updateCampaignListingData( '360_app_campaign', {
                                      ...newCampaignListing[ '360_app_campaign' ],
                                      description: e.target.value
                                    })
                                  }}
                                  invalid={ campaignErrors.description && _.isEmpty( newCampaignListing[ '360_app_campaign' ][ 'description' ] ) } />
                                <FormFeedback>{ campaignErrors.description }</FormFeedback>
                              </FormGroup>
                              <FormGroup>
                                <Label>{ Lang[ 'THREE_SIX_ZERO_CLAIM' ][ data.languageReducer.lang ] }</Label>
                                <Input
                                  type="text"
                                  value={ newCampaignListing[ '360_app_campaign' ][ 'claim' ] }
                                  onChange={ e => {
                                    updateCampaignListingData( '360_app_campaign', {
                                      ...newCampaignListing[ '360_app_campaign' ],
                                      claim: e.target.value
                                    })
                                  }}
                                  invalid={ campaignErrors.claim } />
                                <FormFeedback>{ campaignErrors.claim }</FormFeedback>
                              </FormGroup>
                              <FormGroup check>
                                <Input
                                  type="checkbox"
                                  checked={ newCampaignListing[ '360_app_campaign' ][ 'is_standard_app_campaign_for_aciso' ] }
                                  onChange={ e => {
                                    updateCampaignListingData( '360_app_campaign', {
                                      ...newCampaignListing[ '360_app_campaign' ],
                                      is_standard_app_campaign_for_aciso: e.target.checked
                                    })
                                  }} />
                                { Lang[ 'IS_STANDARD_APP_ACISO' ][ data.languageReducer.lang ] }
                              </FormGroup>
                              <FormGroup check>
                                <Input
                                  type="checkbox"
                                  checked={ newCampaignListing[ '360_app_campaign' ][ 'is_standard_app_campaign_for_injoy' ] }
                                  onChange={ e => {
                                    updateCampaignListingData( '360_app_campaign', {
                                      ...newCampaignListing[ '360_app_campaign' ],
                                      is_standard_app_campaign_for_injoy: e.target.checked
                                    })
                                  }} />
                                { Lang[ 'IS_STANDARD_APP_INJOY' ][ data.languageReducer.lang ] }
                              </FormGroup>
                            </Col>
                            <Col md={ 6 }>
                              <FormGroup>
                                <Label>{ Lang[ 'THREE_SIX_ZERO_STANDARD_IMAGE' ][ data.languageReducer.lang ] }</Label>
                                <Input
                                  type="text"
                                  value={ newCampaignListing[ '360_app_campaign' ][ 'image_url' ]}
                                  onChange={ e => {
                                    let tmp = _.cloneDeep( newCampaignListing[ '360_app_campaign' ] )
                                    tmp.image_url = e.target.value
                                    updateCampaignListingData( '360_app_campaign', tmp )
                                  }}
                                  disabled={ true } />
                                <Label>{ Lang[ 'ADDITIONAL_TEXT' ][ data.languageReducer.lang ] }</Label>
                                <div>
                                  <Button 
                                    color="primary"
                                    onClick={() => {
                                      toggleMediaDatabase( true )
                                      onChangeCampaignListingHOC( 'imageKey', '360_app_campaign' ) 
                                    }}>
                                    { Lang[ 'SELECT_IMAGE' ][ data.languageReducer.lang ] }</Button>
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                          {
                            ( newCampaignListing[ '360_app_campaign' ][ 'is_standard_app_campaign_for_aciso' ] || newCampaignListing[ '360_app_campaign' ][ 'is_standard_app_campaign_for_injoy' ] ) && (
                              <Row className="mt-4">
                                <Col md={ 6 }>
                                  <FormGroup>
                                    <Label>{ Lang[ 'LANDING_PAGE_URL' ][ data.languageReducer.lang ] }</Label>
                                    <Input
                                      type="text"
                                      value={ newCampaignListing[ '360_app_campaign' ][ 'landing_page_url' ] }
                                      onChange={ e => {
                                        updateCampaignListingData( '360_app_campaign', {
                                          ...newCampaignListing[ '360_app_campaign' ],
                                          landing_page_url: e.target.value
                                        })
                                      }}
                                      invalid={ campaignErrors.landing_page_url } />
                                    <FormFeedback>{ campaignErrors.landing_page_url }</FormFeedback>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label>{ Lang[ 'PRIORITY' ][ data.languageReducer.lang ] }</Label>
                                    <Input
                                      type="number"
                                      min="1"
                                      value={ newCampaignListing[ '360_app_campaign' ][ 'priority' ] }
                                      onChange={ e => {
                                        updateCampaignListingData( '360_app_campaign', {
                                          ...newCampaignListing[ '360_app_campaign' ],
                                          priority: parseInt( e.target.value )
                                        })
                                      }} />
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <FormGroup>
                                    <Label>{ Lang[ 'START_DATE' ][ data.languageReducer.lang ] }</Label>
                                    <Input
                                      type="date"
                                      value={ Moment( newCampaignListing[ '360_app_campaign' ][ 'start_date' ] ).format( 'YYYY-MM-DD' ) }
                                      onChange={ e => {
                                        updateCampaignListingData( '360_app_campaign', {
                                          ...newCampaignListing[ '360_app_campaign' ],
                                          start_date: Moment.utc( e.target.value ).format()
                                        })
                                      }} />
                                  </FormGroup>
                                  <FormGroup>
                                    <Label>{ Lang[ 'END_DATE' ][ data.languageReducer.lang ] }</Label>
                                    <Input
                                      type="date"
                                      value={ Moment( newCampaignListing[ '360_app_campaign' ][ 'end_date' ] ).format( 'YYYY-MM-DD' ) }
                                      onChange={ e => {
                                        updateCampaignListingData( '360_app_campaign', {
                                          ...newCampaignListing[ '360_app_campaign' ],
                                          end_date: Moment.utc( e.target.value ).format()
                                        })
                                      }} />
                                  </FormGroup>
                                </Col>
                              </Row>
                            )
                          }
                        </CardBody>
                      </Card>
                    )
                  }
                </Col>
                <Col md={ 4 }>
                  <FormGroup>
                    <Label>{ Lang[ 'BOOKABLE_MONTHS' ][ data.languageReducer.lang ] }</Label>
                    <FormGroup check>
                      <Input
                        type="checkbox"
                        checked={ newCampaignListing.bookable_months.length === 12 }
                        onChange={ e => {
                          let tmp = _.cloneDeep( newCampaignListing.bookable_months )
                          if( tmp.length === 12 ) {
                            tmp = []
                          } else {
                            tmp = []
                            months.map( item => {
                              tmp.push( item )
                            })
                          }
                          updateCampaignListingData( 'bookable_months', tmp )
                        }} />
                      { Lang[ 'ALL_MONTHS' ][ data.languageReducer.lang ] }
                    </FormGroup>
                    {
                      months && months.map( item => {
                        return (
                          <FormGroup key={ item } check>
                            <Input
                              type="checkbox"
                              checked={ newCampaignListing.bookable_months.indexOf( item ) > -1 }
                              onChange={ e => {
                                let tmp = _.cloneDeep( newCampaignListing.bookable_months )
                                if( tmp.indexOf( item ) > -1 ) {
                                  tmp.splice( tmp.indexOf( item ), 1 )
                                } else {
                                  tmp.push( item )
                                }
                                updateCampaignListingData( 'bookable_months', tmp )
                              }} />
                            { item }
                          </FormGroup>
                        )
                      })
                    }
                    <FormFeedback className={ campaignErrors.bookable_months && 'd-block' }>
                      { campaignErrors.bookable_months }
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label>{ Lang[ 'CAMPAIGN_TYPES' ][ data.languageReducer.lang ] }</Label>
                    {
                      campaignTypes && campaignTypes.map( item => {
                        return (
                          <FormGroup key={ item.id } check>
                            <Label check>
                              <Input
                                type="radio"
                                name="campaign-types"
                                value={ newCampaignListing.types.indexOf( item ) > -1 }
                                onChange={ e => {
                                  let tmp = _.cloneDeep( newCampaignListing.types )
                                  tmp.pop()
                                  tmp.push( item )
                                  updateCampaignListingData( 'types', tmp )
                                }} />{' '}
                              { item.title }
                            </Label>
                          </FormGroup>
                        )
                      })
                    }
                    <FormFeedback className={ campaignErrors.types && 'd-block' }>
                      { campaignErrors.types }
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label>{ Lang[ 'TARGET_CENTER_GROUPS' ][ data.languageReducer.lang ] }</Label>
                    {
                      centerGroups && centerGroups.map( item => {
                        return (
                          <FormGroup key={ item.id } check>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={ _.findIndex( newCampaignListing.target_center_groups, { id: item.id } ) > -1 }
                                onChange={ e => {
                                  let tmp = _.cloneDeep( newCampaignListing.target_center_groups )
                                  let tmpIndex = _.findIndex( tmp, { id: item.id } )
                                  if( tmpIndex > -1 ) {
                                    tmp.splice( tmpIndex, 1 )
                                  } else {
                                    tmp.push( item )
                                  }
                                  updateCampaignListingData( 'target_center_groups', tmp )
                                }} />{' '}
                              { item.name }
                            </Label>
                          </FormGroup>
                        )
                      })
                    }
                    <FormFeedback className={ campaignErrors.target_center_groups && 'd-block' }>
                      { campaignErrors.target_center_groups }
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label>{ Lang[ 'CAMPAIGN_CAN_BE_BOOKED_IN' ][ data.languageReducer.lang ] }</Label>
                    {
                      bookableCountries && bookableCountries.map( item => {
                        return (
                          <FormGroup key={ item } check>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={ newCampaignListing.bookable_countries.indexOf( item ) > -1 }
                                onChange={ e => {
                                  let tmp = _.cloneDeep( newCampaignListing.bookable_countries )
                                  if( tmp.indexOf( item ) > -1 ) {
                                    tmp.splice( tmp.indexOf( item ), 1 )
                                  } else {
                                    tmp.push( item )
                                  }
                                  updateCampaignListingData( 'bookable_countries', tmp )
                                }} />{' '}
                              { item }
                            </Label>
                          </FormGroup>
                        )
                      })
                    }
                    <FormFeedback className={ campaignErrors.bookable_countries && 'd-block' }>
                      { campaignErrors.bookable_countries }
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId={ 2 } >
              <FormEditor
                { ...props }
                mode='edit'
                olsCampaignType='newCampaignListing'
                currentOlsCampaign={ newCampaignListing }
              />
            </TabPane>
          </TabContent>
        </Form>
        { onLoadCampaignListings && <LoadingOverlay /> }
        {
          activeTab === 1 && (
            <MediaDatabase
              onSelectedImage={( param ) => {
                if ( imageKey === '360_app_campaign' ) {
                  let tmp = _.cloneDeep( newCampaignListing[ '360_app_campaign' ] )
                  tmp.image_url = param
                  updateCampaignListingData( '360_app_campaign', tmp )
                } else {
                  updateCampaignListingData( imageKey, param )
                }
                toggleMediaDatabase( false )
              }} 
            />
          )
        }
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            let tmp = _.cloneDeep( newCampaignListing )
            createOLSCampaign( tmp )
          }}>
          { Lang[ 'SUBMIT' ][ data.languageReducer.lang ] }
        </Button>
        <Button
          color="primary"
          onClick={() => onChangeCampaignListingHOC( 'showCreateCampaign', false )}>
          { Lang[ 'CANCEL' ][ data.languageReducer.lang ] }
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const mapStateToProps = state => ({ data: state })

export default connect( mapStateToProps, {
  toggleMediaDatabase
})( Create )
