import React, { Fragment } from 'react'
import {
  Container, Card, CardBody, Button, Form, FormGroup, 
  Col, Input, Label, UncontrolledTooltip
} from 'reactstrap'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import ReactTable from "react-table"
import _ from 'lodash'

import UpdateModule from '../UpdateModule'

import WithModules from '../actions/Modules'

import Lang from 'Lang/General'
import { Row } from 'react-bootstrap'

const PlatformForm = ({
  mode,
  language,
  data,
  onChangePlatformHOC,
  selectedModuleData,
  onSubmit,
  onCreateModule,
  moduleName,
  onChangeModuleHOC,
  showUpdateModule,
  selectedModule,
  updateModule
}) => {
  const {
    name,
    modules
  } = data

  const onChange = ( key, val ) => {
    let tmp = data
    let dataKey = ''
    tmp[ key ] = val
    if( mode === 'edit' ){
      dataKey = 'selectedPlatformData'
    }else{
      dataKey = 'newPlatformData'
    }
    return onChangePlatformHOC( dataKey, tmp )
  }

  return (
    <>
      <Container fluid>
        <Card className="main-card mb-3">
          <CardBody>
            <Form>
              <FormGroup>
                <Label for="company_name" sm={ 2 }  className="pl-1">{ Lang[ 'NAME' ][ language ] }</Label>
                <Col sm={ 8 } className="pl-0">
                  <Input
                    name="platform_name" id="platform_name"
                    placeholder={ Lang[ 'ENTER_PLATFORM_NAME' ][ language ] }
                    value={ name }
                    onChange={ e => onChange( 'name', e.target.value ) }
                    invalid={ false }/>
                </Col>
              </FormGroup>
              {
                mode === 'edit' && (
                  <>
                    <Label className="pl-1">{ Lang[ 'MODULES' ][ language ] }</Label>
                    <FormGroup row>
                      <Col sm={ 6 }>
                        <Input
                          name="module" id="module"
                          placeholder={ Lang[ 'ENTER_MODULE_NAME' ][ language ] }
                          value={ moduleName }
                          onChange={ e => onChangePlatformHOC( 'moduleName', e.target.value ) }
                          invalid={ false }/>
                      </Col>
                      <Col sm={ 5 }>
                        <Button
                          className={ 'mb-2 mr-2' }
                          color="primary"
                          onClick={ onCreateModule }>
                          { Lang[ 'CREATE_MODULE' ][ language ] }
                        </Button>
                      </Col>
                    </FormGroup>
                    <ReactTable
                      NoDataComponent={ () => <span></span> }
                      data={ _.sortBy( modules, [ 'name' ], [ 'asc' ] ) }
                      columns={[
                        {
                          Header: Lang[ 'NAME' ][ language ],
                          accessor: "name"
                        },
                        {
                          Header: Lang[ 'ACTION' ][ language ],
                          accessor: "id",
                          width: 60,
                          Cell: ( row ) => (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Button
                                id={ `Platform-${ row.original.id }-update-module` }
                                className="mb-2 mr-2 btn-icon btn-icon-only"
                                color="primary"
                                onClick={ () => {
                                  console.log( row )
                                  console.log( data )
                                  Promise.all([
                                    onChangeModuleHOC( 'selectedModule', {
                                      ...row.original,
                                      platform_id: data.id
                                    })
                                  ]).then(() => {
                                    onChangeModuleHOC( 'showUpdateModule', true )
                                  })
                                }}>
                                <i className="pe-7s-note"> </i>
                              </Button>
                              <UncontrolledTooltip
                                target={ `Platform-${ row.original.id }-update-module` }
                                placement="top">{ Lang[ 'UPDATE_MODULE' ][ language ] }</UncontrolledTooltip>
                            </div>
                          )
                        }
                      ]}
                      defaultPageSize={ 10 } />
                  </>
                )
              }
              <Button className="mb-2 mr-2 mt-5" color="alternate" onClick={ onSubmit } style={{ float: 'right' }}>{ Lang[ 'SAVE' ][ language ] }</Button>
            </Form>
          </CardBody>
        </Card>
      </Container>
      <UpdateModule 
        showUpdateModule={ showUpdateModule } 
        selectedModule={ selectedModule }
        updateModule={ updateModule }
        onChangeModuleHOC={ onChangeModuleHOC }
        lang={ language } />
    </>
  )
}

export default WithModules( PlatformForm )
