import React, { Component } from "react"
import { ToastContainer } from 'react-toastify'
import { compose } from "redux"
import { connect } from "react-redux"
import _ from "lodash"

import Header from "./Header/index"
import Renderer from "./Renderer/index"
import SectionPickerDialog from "./SectionPicker"
import LoadingModal from "./components/loading"
import ConfirmDeleteDialog from "./components/confirmModal"
import SectionEditor from "./SectionEditor"
import PageJSONEditorDialog from "./PageJSONEditor"
import LanguageDialog from "./LanguageDialog"
import RendererViewportAdjust from "./RendererViewportAdjust"
import LandingPageSection from "./actions/landingPageSection"

import PageHOC from "./actions/pages"

import WithPatchJSON from "./utility/patchJSON"
import Lang from "Lang/General"

import "./index.scss"

class LandingPageEditor extends Component {
  state = {
    templateName: "",
    showSectionPicker: false,
    showTemplateStylesheetDialog: false,
    showTemplateJSDialog: false,
    showPageJSONEditorDialog: false,
    showHeaderEditorDialog: false,
    showConfirmRemoveDialog: false,
    showLanguageDialog: false,
    showStylingDialog: false,
    showRendererViewportAdjust: false,
    showFooterEditorDialog: false,
    showSectionEditor: false,
    selectedSectionID: "",
    selectedSectionVariant: "",
    selectedLanguageShortname: "en",
    selectedComponent: {},
    selectedViewportRenderer: "desktop",
    hoveredSectionID: "",
  }

  componentDidMount = () => {
    this.props.getPages({ is_paginated: false, page: 1 }, "")
    this.props.getSelectedPage( this.props.match.params.landing_page_id )
  }

  componentDidUpdate = ( pp ) => {
    if ( this.props.match.params.landing_page_id !== pp.match.params.landing_page_id ) {
      this.props.getSelectedPage( this.props.match.params.landing_page_id )
    }
  }

  updateMainContainerState = ( key, param ) => this.setState({ [key]: param })

  confirmRemovePageSection = () => {
    let tmpPage = _.cloneDeep( this.props.selectedPage )
    let tmpSectionContainer = _.find( tmpPage.content.children, { variant: "sections-container" } )
    tmpSectionContainer.children.splice( _.findIndex( tmpSectionContainer.children, { id: this.state.selectedSectionID } ), 1 )
    this.props.deleteLandingPageSections( tmpPage, this.state.selectedSectionID, this.updateMainContainerState )
  }

  render = () => {
    return (
      <div className="landing-page-editor">
        <Header
          data={ this.props.data }
          history={ this.props.history }
          updateMainContainerState={ this.updateMainContainerState }
          selectedTemplateName={ this.props.match.params.template_name }
          template_id={ this.props.match.params.template_id }
          onChangeShowPageCreator={ this.props.onChangeShowPageCreator }
          pages={ this.props.pages }
          selectedPageID={ this.props.selectedPageID }
          updateSelectedPage={ this.props.updateSelectedPage }
          updateSelectedPageID={ this.props.updateSelectedPageID }
          selectedPage={ this.props.selectedPage }
          selectedTemplate={ this.props.selectedTemplate }
          updateSiteTemplate={ this.props.updateSiteTemplate }
          getSelectedPage={ this.props.getSelectedPage }
          onChangeShowConfirmRemovePage={ this.props.onChangeShowConfirmRemovePage }
          lang={ this.props.data.languageReducer.lang }
          selectedViewportRenderer={ this.state.selectedViewportRenderer }
        />
        <Renderer
          updateMainContainerState={ this.updateMainContainerState }
          selectedSectionID={ this.state.selectedSectionID }
          selectedComponent={ this.state.selectedComponent }
          selectedLanguageShortname={ this.state.selectedLanguageShortname }
          selectedViewportRenderer={ this.state.selectedViewportRenderer }
          onChangeSelectedPage={ this.props.onChangeSelectedPage }
          selectedPage={ this.props.selectedPage }
          templateStylesheets={ this.props.templateStylesheets }
          templateJavascripts={ this.props.templateJavascripts }
          languages={ this.props.languages }
          templateHeaders={ this.props.templateHeaders }
          templateHeadersBlueprints={ this.props.templateHeadersBlueprints }
          templateFooterBlueprints={ this.props.templateFooterBlueprints }
          headerMenuContent={ this.props.headerMenuContent }
          lang={ this.props.data.languageReducer.lang }
          hoveredSectionID={ this.state.hoveredSectionID }
          updateSelectedPage={ this.props.updateSelectedPage }
        />
        {
          this.state.selectedSectionID !== "" && (
            <SectionEditor
              isOpen={ this.state.showSectionEditor }
              templateStylesheets={ this.props.templateStylesheets }
              templateJavascripts={ this.props.templateJavascripts }
              selectedSectionID={ this.state.selectedSectionID }
              selectedSectionVariant={ this.state.selectedSectionVariant }
              showSectionEditor={ this.state.showSectionEditor }
              languages={ this.props.languages }
              selectedLanguageShortname={ this.state.selectedLanguageShortname }
              updateMainContainerState={ this.updateMainContainerState }
              pages={ this.props.pages }
              lang={ this.props.data.languageReducer.lang }
              refreshSelectedPage={ () => this.props.getSelectedPage( this.props.match.params.landing_page_id ) }
              getSelectedLandingPageSection={ this.props.getSelectedLandingPageSection }
              updateLandingPageSections={ this.props.updateLandingPageSections }
              selectedLandingPageSection={ this.props.selectedLandingPageSection }
              onChangeLandingPageHOC={ this.props.onChangeLandingPageHOC }
              selectedSection={ this.props.selectedSection }
              onLoadLandingTemplate={ this.props.onLoadLandingTemplate }
              selectedPage={ this.props.selectedPage }
            />
          )
        }
        <SectionPickerDialog
          showSectionPicker={ this.state.showSectionPicker }
          onChangePageHOC={ this.props.onChangePageHOC }
          updateMainContainerState={ this.updateMainContainerState }
          templateStylesheets={ this.props.templateStylesheets }
          templateJavascripts={ this.props.templateJavascripts }
          selectedPage={ this.props.selectedPage }
          selectedSectionID={ this.state.selectedSectionID }
          updateSelectedPage={ this.props.updateSelectedPage }
          createLandingPageSections={ this.props.createLandingPageSections }
          onLoadLandingTemplate={ this.props.onLoadLandingTemplate }
          onChangeLandingPageHOC={ this.props.onChangeLandingPageHOC }
          newSection={ this.props.newSection }
          languages={ this.props.languages }
          selectedLanguageShortname={ this.state.selectedLanguageShortname }
          lang={ this.props.data.languageReducer.lang }
        />
        <PageJSONEditorDialog
          showPageJSONEditorDialog={ this.state.showPageJSONEditorDialog }
          updateMainContainerState={ this.updateMainContainerState }
          selectedPage={ this.props.selectedPage }
          onChangeSelectedPage={ this.props.onChangeSelectedPage }
          updateSelectedPage={ this.props.updateSelectedPage }
          template_id={ this.props.match.params.template_id }
          onLoadPages={ this.props.onLoadPages }
          onLoadPagesUpdate={ this.props.onLoadPagesUpdate }
          onSearchPageJSON={ this.props.onSearchPageJSON }
          lang={ this.props.data.languageReducer.lang }
        />
        <LanguageDialog
          showLanguageDialog={ this.state.showLanguageDialog }
          selectedLanguageShortname={ this.state.selectedLanguageShortname }
          updateMainContainerState={ this.updateMainContainerState }
          languages={ this.props.languages }
          createLanguage={ this.props.createLanguage }
          removeLanguage={ this.props.removeLanguage }
          updateLanguage={ this.props.updateLanguage }
          onChangeSelectedLanguage={ this.props.onChangeSelectedLanguage }
          template_id={ this.props.selectedPage.template_id }
          onLoadLanguagesUpdate={ this.props.onLoadLanguagesUpdate }
          lang={ this.props.data.languageReducer.lang }
        />
        <RendererViewportAdjust
          showRendererViewportAdjust={ this.state.showRendererViewportAdjust }
          updateMainContainerState={ this.updateMainContainerState }
          selectedViewportRenderer={ this.state.selectedViewportRenderer }
          lang={ this.props.data.languageReducer.lang }
        />
        <LoadingModal
          show={
            this.props.onLoadPages ||
            this.props.onLoadTemplateStylesheet ||
            this.props.onLoadTemplateJS ||
            this.props.onLoadLanguages ||
            this.props.onLoadLandingTemplate
          }
        />
        <ConfirmDeleteDialog
          open={ this.state.showConfirmRemoveDialog }
          content={ Lang[ "DELETE_CONFIRMATION_SECTION" ][ this.props.data.languageReducer.lang ] }
          confirmAction={ () => this.confirmRemovePageSection() }
          handleModalClose={ () => this.setState({ showConfirmRemoveDialog: false }) }
          lang={ this.props.data.languageReducer.lang }
        />
        <ToastContainer/>
      </div>
    )
  }
}

const mapStateToProps = state => ({ data: state })

export default compose(
  PageHOC,
  LandingPageSection,
  connect( mapStateToProps ), 
  WithPatchJSON
)( LandingPageEditor )
