import React, { Component, Fragment } from 'react'

import { toast } from 'react-toastify'

import { Get, Put, Post, Delete } from 'utils/axios-template'
import { production_url } from '../config'

import Lang from 'Lang/General'

const TemplateFooterBlueprintHOC = ( WrappedComponent ) => {
  class TemplateFooterBlueprintWrappedComponent extends Component {
    state = {
      loading: false,
      updateLoading: false,
      showStatusModal: false,
      statusModalType: '',
      onClickStatusModalButton: () => {},
      statusModalMessage: ''
    }

    load = param => this.setState({ loading: param })
    updateLoad = param => this.setState({ updateLoading: param })
    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    getTemplateFooterBlueprints = ( template_id ) => Get(
      `${production_url}/templates/${template_id}/template-footer-blueprints`,
      this.getTemplateFooterBlueprintsSuccess,
      this.getTemplateFooterBlueprintsError,
      this.load
    )
    getTemplateFooterBlueprintsSuccess = payload => {
      if( payload && payload.length > 0 ) {
        let tmpPayloadFooterBlueprint = []
        payload.map( item => {
          let tmp = JSON.stringify( item.content, null, 2 )
          let tmpItem = {
            ...item,
            stringifyContent: tmp
          }
          tmpPayloadFooterBlueprint.push( tmpItem )
        })
        this.setState({ templateFooterBlueprints: tmpPayloadFooterBlueprint })
      }
    }
    getTemplateFooterBlueprintsError = error => this.requestError( error )

    updateTemplateFooterBlueprint = ( template_id, data ) => Put(
      `${ production_url }/templates/${ template_id }/template-footer-blueprints/${ data.id }`,
      data,
      () => this.updateTemplateFooterBlueprintSuccess( template_id ),
      this.updateTemplateFooterBlueprintError,
      this.updateLoad
    )
    updateTemplateFooterBlueprintSuccess = template_id => {
      this.getTemplateFooterBlueprints( template_id )
      this.requestSuccess( Lang[ 'FOOTER_BLUEPRINT_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
    }
    updateTemplateFooterBlueprintError = error => this.requestError( error )

    updateTemplateFooterBlueprintStyling = ( 
      template_id,
      footerBlueprintDesktop, 
      footerBlueprintMobile 
    ) => {
      Promise.all([
        Put(
          `${production_url}/templates/${template_id}/template-footer-blueprints/${footerBlueprintDesktop.id}`,
          footerBlueprintDesktop,
          () => this.updateTemplateFooterBlueprintStylingSuccess( template_id ),
          this.updateTemplateFooterBlueprintStylingError,
          this.updateLoad
        )
      ]).then(() => { 
        Put(
          `${production_url}/templates/${template_id}/template-footer-blueprints/${footerBlueprintMobile.id}`,
          footerBlueprintMobile,
          () => this.updateTemplateFooterBlueprintStylingSuccess( template_id ),
          this.updateTemplateFooterBlueprintStylingError,
          this.updateLoad
        )
      })
    }
    updateTemplateFooterBlueprintStylingSuccess = template_id => {
      this.requestSuccess( Lang[ 'FOOTER_BLUEPRINT_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getTemplateFooterBlueprints( template_id )
    }
    updateTemplateFooterBlueprintStylingError = error => this.requestError( error )

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            { ...this.props }
            getTemplateFooterBlueprints={ this.getTemplateFooterBlueprints }
            updateTemplateFooterBlueprint={ this.updateTemplateFooterBlueprint }
            updateTemplateFooterBlueprintStyling={ this.updateTemplateFooterBlueprintStyling }
            templateFooterBlueprints={ this.state.templateFooterBlueprints }
            onLoadTemplateFooterBlueprints={ this.state.loading }
            onLoadFooterBlueprintsUpdate={ this.state.updateLoading } />
        </Fragment>
      )
    }
  }
  return TemplateFooterBlueprintWrappedComponent
}

export default TemplateFooterBlueprintHOC
