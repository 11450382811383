import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { GetWithNonce } from 'utils/axios-nonce'

const HOC = WrappedComponent => {
  class WithHOC extends Component{
    state={ 
      loading: false,
      leadhubCategoriesOptions: []
    }

    load = param => this.setState({ loading: param })

    getLeadhubCategories = () => GetWithNonce(
      `/api/v1/leadhub_categories`,
      this.getLeadhubCategoriesSuccess,
      this.getLeadhubCategoriesError,
      this.load
    )
    getLeadhubCategoriesSuccess = payload => {
      let tmp = []
      tmp = payload && payload.data && payload.data.map( item => ({
        id: item.key,
        key: item.key,
        label: item.value,
        value: item.value
      }) )
      this.setState({ leadhubCategoriesOptions: tmp })
    }
    getLeadhubCategoriesError = error => toast.error( error )

    render = () => {
      return(
        <WrappedComponent
          { ...this.props }
          onLoadLeadhubCategories={ this.state.loading }
          leadhubCategoriesOptions={ this.state.leadhubCategoriesOptions }
          getLeadhubCategories={ this.getLeadhubCategories } />
      )
    }
  }

  return WithHOC
}

export default HOC
