import React, { useRef } from 'react'
import {
  Form, Label, Button, FormText, Row, Col, FormGroup, Input
} from 'reactstrap'
import Image from 'react-bootstrap/Image'
import { connect } from 'react-redux'

import DefaultImage from 'assets/images/no-pic.jpg'
import Lang from 'Lang/General'

const ARKUpload = ({
  onUploadImage,
  imageUrl,
  photo_url,
  photo_file_name,
  errorMessage,
  onDeleteImage,
  data,
  photo_raw
}) => {
  let fileInput = useRef( null )
  const fileInputRef = element => {
    fileInput = element
  }
  const onClickUploadImage = () => {
    focusFileInput();
  }

  const focusFileInput = () => {
    if( fileInput )
      fileInput.click();
  }
  return (
    <Form>
      <Label>{ Lang[ 'CLICK_TO_UPLOAD' ][ data.languageReducer.lang ] }</Label>
      <FormText color="danger">{ errorMessage && errorMessage.length > 0 ? errorMessage : '' }</FormText>
      <Row>
        <Col md={ 3 }>
          <Image
            src={ photo_raw && photo_raw !== '' ? photo_raw : photo_url !== '' ? photo_url : DefaultImage }
            onClick={ onClickUploadImage }
            style={{ width: '100%', height: '100%', cursor: 'pointer' }}/>
          <Button
            className="mb-2 mr-2 btn-icon btn-icon-only"
            style={{
              borderRadius: '50%',
              position: 'absolute',
              bottom: '0%',
              right: '8%',
              minHeight: '30px',
              minWidth: '30px',
              padding: '5px'
            }}
            color="danger"
            onClick={ onDeleteImage }>
            <i className="pe-7s-trash btn-icon-wrapper" style={{ fontSize: '23px' }}> </i>
          </Button>
        </Col>
      </Row>
      <FormGroup>
        <input
          type="file"
          ref={ fileInputRef }
          style={{ display: 'none' }}
          onChange={ event => onUploadImage( event ) }
          accept={ 'image/png,image/jpg,image/jpeg' }/>
      </FormGroup>
    </Form>
  )
}
const mapStateToProps = state => ({ data: state })

export default connect( mapStateToProps )( ARKUpload )
