import React, { useEffect } from 'react'
import {
  Col, Row, FormGroup, Label,
  Modal, ModalBody, ModalFooter, ModalHeader,
  Card, CardHeader, CardBody, CardFooter,
  Button, UncontrolledTooltip, Table
} from 'reactstrap'
import ReactHtmlParser from 'react-html-parser'
import ReactSelect from 'react-select'
import { toast } from 'react-toastify'
import _ from 'lodash'

import PageTitle from 'components/PageTitle'
import ARKTable from 'components/Table'
import LoadingModal from 'components/Indicator/LoadingOverlay'
import ImprintForm from './components/imprintForm'
import ImprintHOC from './actions'

import Lang from 'Lang/General'

const Imprint = props => {

  useEffect( () => {
    props.getAllImprints({
      page: 1,
      is_paginated: true
    })
    props.getCenters({ is_paginated: false })
  }, [] )

  const handleSecurityModulesUpdate = () => {
    let tmp = _.find( props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'GDPR Center' })
      return tmpModules.is_update
    }
  }

  const renderEditModal = () => {
    return (
      <Modal isOpen={ props.showEditImprint } size="lg">
        <ModalHeader toggle={ () => props.onChangeImprintHOC( 'showEditImprint', false ) }>
          { Lang[ 'EDIT_IMPRINT' ][ props.data.languageReducer.lang ] }
        </ModalHeader>
        <ModalBody>
          {
            !_.isEmpty( props.selectedImprint ) && (
              <ImprintForm 
                selectedImprint={ props.selectedImprint }
                onChangeImprintHOC={ props.onChangeImprintHOC }
                lang={ props.data.languageReducer.lang } />
            )
          }
          { props.onLoadImprint && <LoadingModal /> }
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={ () => {
              if ( _.isEmpty( props.selectedImprint.content ) ) {
                toast.error( Lang[ 'CONTENT_VALIDATION_MESSAGE' ][ props.data.languageReducer.lang ] )
              } else {
                props.updateImprint( props.selectedImprint.center_id, props.selectedImprint ) 
              }
            }}>
            { Lang[ 'SUBMIT' ][ props.data.languageReducer.lang ] }
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  const renderViewModal = () => {
    return (
      <Modal isOpen={ props.showViewImprint } size="lg">
        <ModalHeader toggle={ () => props.onChangeImprintHOC( 'showViewImprint', false ) }>
          { Lang[ 'VIEW_IMPRINT' ][ props.data.languageReducer.lang ] }
        </ModalHeader>
        <ModalBody>
          {
            !_.isEmpty( props.selectedImprint ) && (
              <Table className="mb-0" bordered>
                <tbody>
                  <tr>
                    <th scope="row">{ Lang[ 'CENTER' ][ props.data.languageReducer.lang ] }</th>
                    <td>{ props.selectedImprint.center_name }</td>
                  </tr>
                  <tr>
                    <th scope="row">{ Lang[ 'CONTENT' ][ props.data.languageReducer.lang ] }</th>
                    <td>{ ReactHtmlParser( props.selectedImprint.content[ props.data.languageReducer.lang ] ) }</td>
                  </tr>
                </tbody>
              </Table>
            )
          }
          { props.onLoadImprint && <LoadingModal /> }
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={ () => props.onChangeImprintHOC( 'showViewImprint', false ) }>
            { Lang[ 'CLOSE' ][ props.data.languageReducer.lang ] }
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
  
  return (
    <>
      <div>
        <PageTitle
          heading={ Lang[ 'IMPRINT' ][ props.data.languageReducer.lang ] }
          icon="pe-7s-file icon-gradient bg-happy-fisher" />
        <Card className="mb-3">
          <CardHeader>{ Lang[ 'SEARCH' ][ props.data.languageReducer.lang ] }</CardHeader>
          <CardBody>
            <Row>
              <Col md={ 12 }>
                <FormGroup>
                  <Label>{ Lang[ 'CENTER' ][ props.data.languageReducer.lang ] }</Label>
                  <ReactSelect
                    options={ props.centers }
                    className="basic-multi-select"
                    placeholder={ Lang[ 'SELECT_PLACEHOLDER' ][ props.data.languageReducer.lang ] }
                    onChange={ value => props.onChangeImprintHOC( 'searchCenter', value ) }
                    value={ !_.isEmpty( props.searchCenter ) && props.searchCenter } />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button
              color="primary"
              className="ml-auto"
              onClick={ () => {
                props.getAllImprints({
                  page: 1,
                  is_paginated: true,
                  filter: {
                    center_id: props.searchCenter.id
                  }
                })
              }}>{ Lang[ 'SEARCH' ][ props.data.languageReducer.lang ] }</Button>
            <Button
              color="danger"
              onClick={ () => {
                props.onChangeImprintHOC( 'searchCenter', {} )
                props.getAllImprints({ page: 1, is_paginated: true })
              }}>{ Lang[ 'RESET' ][ props.data.languageReducer.lang ] }</Button>
          </CardFooter>
        </Card>
        <ARKTable
          data={ props.imprints.data }
          columns={[
            {
              Header: Lang[ 'CENTER' ][ props.data.languageReducer.lang ],
              accessor: 'center_name'
            },
            {
              Header: Lang[ 'ACTION' ][ props.data.languageReducer.lang ],
              accessor: "id",
              Cell: ( row ) => (
                <div className="d-flex flex-row align-items-center justify-content-center w-100">
                  <Button
                    id={ `Imprint-${ row.original.id }-view-imprint` }
                    className="mb-2 mr-2 btn-icon btn-icon-only"
                    color="primary"
                    onClick={ () => props.getSelectedImprint( row.original.center_id, 'view' ) }>
                    <i className="pe-7s-look"> </i>
                  </Button>
                  <UncontrolledTooltip
                    target={ `Imprint-${ row.original.id }-view-imprint` }
                    placement="top">{ Lang[ 'VIEW_IMPRINT' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                  {
                    handleSecurityModulesUpdate() && !row.original.is_signed_imprint && (
                      <>
                        <Button
                          id={ `Imprint-${ row.original.id }-edit-imprint` }
                          className="mb-2 mr-2 btn-icon btn-icon-only"
                          color="primary"
                          onClick={ () => props.getSelectedImprint( row.original.center_id, 'edit' ) }>
                          <i className="pe-7s-note"> </i>
                        </Button>
                        <UncontrolledTooltip
                          target={ `Imprint-${ row.original.id }-edit-imprint` }
                          placement="top">{ Lang[ 'EDIT_IMPRINT' ][ props.data.languageReducer.lang ] }</UncontrolledTooltip>
                      </>
                    )
                  }
                </div>
              )
            }
          ]}
          totalPages={ props.imprintTotalPages }
          page={ props.imprints.meta ? props.imprints.meta.current_page : 1 }
          onChangePage={ pg => props.getAllImprints({ page: pg, is_paginated: true }) }
          showPagination={ false } />
        { renderEditModal() }
        { renderViewModal() }
      </div>
      { props.onLoadImprint && <LoadingModal /> }
    </>
  )
}

export default ImprintHOC( Imprint )
