import React, { Component } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import _ from 'lodash'
import {
    Form,
    FormGroup, Label,
    Input, FormText,
    Row, Col,
    Card, CardBody,
    CardHeader, Container, Button
} from 'reactstrap'
import { connect } from 'react-redux'

import Lang from 'Lang/General'
import NormalSearch from './NormalSearch'
import AdvancedSearch from './AdvancedSearch'

class SearchComponent extends Component {
  handleKeyPress = event => {
    if( event.key === 'Enter' ){
      let query = {
        page: 1,
        is_paginated: true
      }
      this.props.onClickSearch( query, this.props.searchType, this.props.selectedSearchColumn, this.props.normalSearchValue )
    }
  }
  render = () => {
    return(
      <>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Card className="main-card mb-3">
            <CardHeader>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }</CardHeader>
            <CardBody>
              <Form onSubmit={ e => e.preventDefault() }>
                <FormGroup>
                  <Input
                    type="select"
                    name="select"
                    id="select"
                    value={ this.props.searchType }
                    onChange={ e => this.props.onChange( 'searchType', e.target.value ) }>
                    <option value={ 'Normal Search' }>{ Lang[ 'NORMAL_SEARCH' ][ this.props.data.languageReducer.lang ] }</option>
                    <option value={ 'Advanced Search' }>{ Lang[ 'ADVANCED_SEARCH' ][ this.props.data.languageReducer.lang ] }</option>
                  </Input>
                </FormGroup>
                {
                  this.props.searchType === 'Normal Search'
                    ? <NormalSearch
                        normalSearchValue={ this.props.normalSearchValue }
                        updateNormalSearchValue={ this.props.updateNormalSearchValue }
                        searchLabel={ this.props.searchLabel }
                        handleKeyPress={ this.handleKeyPress } />
                    : <AdvancedSearch
                        searchColumn={ this.props.searchColumn }
                        selectedSearchColumn={ this.props.selectedSearchColumn }
                        updateSelectedSearchColumn={ this.props.updateSelectedSearchColumn }
                        onChange={ this.props.onChange }
                        types={ this.props.types }
                        modulesUsed={ this.props.modulesUsed } />
                }
                <Button
                  color="danger"
                  className="mt-1"
                  style={{ float: 'right' }}
                  onClick={ () => {
                    Promise.all([
                      this.props.onChange( 'searchType', this.props.initialSearchType || 'Normal Search' ),
                      this.props.onChange( 'normalSearchValue', '' )
                    ]).then(
                      this.props.onChange( 'selectedSearchColumn', this.props.initialSelectedSearchColumn || [] )
                    )
                    this.props.onClickReset()
                  }}>{ Lang[ 'RESET' ][ this.props.data.languageReducer.lang ] }</Button>
                <Button
                  color="primary"
                  className="mt-1"
                  style={{ float: 'right', marginRight: '30px' }}
                  onClick={ () => {
                    let query = {
                      page: 1,
                      is_paginated: true
                    }
                    this.props.onClickSearch( query, this.props.searchType, this.props.selectedSearchColumn, this.props.normalSearchValue )
                  }}>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }</Button>
                {
                  this.props.queryActionButtons && this.props.queryActionButtons.map(( item, index ) => {
                    return (
                      <Button
                        key={ index }
                        color={ item.color }
                        style={ item.style }
                        onClick={ item.onClick }>
                        { item.content }
                      </Button>
                    )
                  })
                }
              </Form>
            </CardBody>
          </Card>
        </ReactCSSTransitionGroup>
      </>
    )
  }
}

const mapStateToProps = state => ({ data: state })

export default connect( mapStateToProps )( SearchComponent )
