import React from 'react'
import { HashRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

import KWSMainRouter from './router'
import configureStore from './store/config'
import AxiosInterceptors from 'utils/axios-interceptors' // please do not remove this

const store = configureStore();
const persistor = persistStore( store )

function App() {
  return (
    <Provider store={ store }>
      <PersistGate loading={ null } persistor={ persistor }>
        <HashRouter>
          <KWSMainRouter />
        </HashRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
