import React, { useEffect } from 'react'
import {
  Form, FormGroup, 
  Label, Button, Input,
  Row
} from 'reactstrap'
import { connect } from 'react-redux'
import _ from 'lodash'
import Loader from 'react-loaders'

import LinkInput from 'components/LinkInput'
import ColorPickerInput from 'components/ColorPicker'
import MultiLangInput from "components/Input/MultiLang"
import { toggleMediaDatabase } from 'actions/MediaDatabaseToggle'
import { convertObjToBase64 } from "utils/objToBase64"
import ButtonStylings from './ButtonStylings'
import Lang from 'Lang/General'

const ButtonConfig = ( props ) => {
  useEffect( () => {
    if( 
      props.components.length === 0 || 
      ( props.components.length > 0 && props.components[ 0 ].category !== 'button' ) 
    ){
      props.getComponents( 
        convertObjToBase64({ "filter": { "$or": [ { "category": "button" } ] } })
      )
    }
  } , [] ) 
  const updateButtonPadding = ( type, val ) => {
    let child = _.cloneDeep( props.selectedComponent )
    if( type === 'paddingTop' ) {
      child.paddingTop = val
    } else if( type === 'paddingRight' ) {
      child.paddingRight = val
    } else if( type === 'paddingBottom' ) {
      child.paddingBottom = val
    } else if( type === 'paddingLeft' ) {
      child.paddingLeft = val
    }
    child.style = { ...child.style, padding: `${ child.paddingTop }px ${ child.paddingRight }px ${ child.paddingBottom }px ${ child.paddingLeft }px` }
    props.updateSelectedComponent( child )
    let tmpColumn = _.cloneDeep( props.selectedCol )
    tmpColumn = props.FindComponentAndPatch( tmpColumn, child )
    props.updateSelectedCol( tmpColumn )
    let tmpContainer = _.cloneDeep( props.dom )
    tmpContainer = props.FindComponentAndPatch( tmpContainer, tmpColumn )
    props.updateDom( tmpContainer )
  }
  const updateButtonStyle = ( key, val, isHover ) => {
    let child = _.cloneDeep( props.selectedComponent )
    if( isHover ){
      child[ 'style' ][ ':hover' ][ key ] = val
    } else {
      child[ 'style' ][ key ] = val
    }
    props.updateSelectedComponent( child )
    let tmpColumn = _.cloneDeep( props.selectedCol )
    tmpColumn = props.FindComponentAndPatch( tmpColumn, child )
    props.updateSelectedCol( tmpColumn )
    let tmpContainer = _.cloneDeep( props.dom )
    tmpContainer = props.FindComponentAndPatch( tmpContainer, tmpColumn )
    props.updateDom( tmpContainer )
  }
  const updateButtonColorType = ( type, key, isHover ) => {
    let child = _.cloneDeep( props.selectedComponent )
    let tmpStyle = _.cloneDeep( isHover ? child[ 'style' ][ ':hover' ] : child[ 'style' ] )
    tmpStyle = getModifiedColorTypeStyling( type, tmpStyle, key )
    if( isHover ){
      child[ 'style' ][ ':hover' ] = tmpStyle
    } else {
      child[ 'style' ] = tmpStyle
    }
    props.updateSelectedComponent( child )
    let tmpColumn = _.cloneDeep( props.selectedCol )
    tmpColumn = props.FindComponentAndPatch( tmpColumn, child )
    props.updateSelectedCol( tmpColumn )
    let tmpContainer = _.cloneDeep( props.dom )
    tmpContainer = props.FindComponentAndPatch( tmpContainer, tmpColumn )
    props.updateDom( tmpContainer )
  }
  const getButtonColorType = ( style, key ) => {
    let colorType = 'custom'
    if( style[ 'primaryColor' ] && style[ 'primaryColor' ].indexOf( key ) > -1 ){
      colorType = 'primaryColor'
    }
    if( style[ 'secondaryColor' ] && style[ 'secondaryColor' ].indexOf( key ) > -1 ){
      colorType = 'secondaryColor'
    }
    return colorType
  }
  const getModifiedColorTypeStyling = ( type, style, key ) => {
    let tmpStyle = _.cloneDeep( style )
    switch( type ){
      case 'custom' :
        if( tmpStyle[ 'primaryColor' ] && tmpStyle[ 'primaryColor' ].indexOf( key ) > -1 ){
          tmpStyle[ 'primaryColor' ].splice( tmpStyle[ 'primaryColor' ].indexOf( key ), 1 )
        }
        if( tmpStyle[ 'secondaryColor' ] && tmpStyle[ 'secondaryColor' ].indexOf( key ) > -1 ){
          tmpStyle[ 'secondaryColor' ].splice( tmpStyle[ 'secondaryColor' ].indexOf( key ), 1 )
        }
        break
      case 'primaryColor' :
        if( !tmpStyle[ 'primaryColor' ] ){
          tmpStyle[ 'primaryColor' ] = []
        }
        if( tmpStyle[ 'primaryColor' ] && tmpStyle[ 'primaryColor' ].indexOf( key ) === -1 ){
          tmpStyle[ 'primaryColor' ].push( key )
        }
        if( tmpStyle[ 'secondaryColor' ] && tmpStyle[ 'secondaryColor' ].indexOf( key ) > -1 ){
          tmpStyle[ 'secondaryColor' ].splice( tmpStyle[ 'secondaryColor' ].indexOf( key ), 1 )
        }
        break
      case 'secondaryColor' :
        if( tmpStyle[ 'primaryColor' ] && tmpStyle[ 'primaryColor' ].indexOf( key ) > -1 ){
          tmpStyle[ 'primaryColor' ].splice( tmpStyle[ 'primaryColor' ].indexOf( key ), 1 )
        }
        if( !tmpStyle[ 'secondaryColor' ] ){
          tmpStyle[ 'secondaryColor' ] = []
        }
        if( tmpStyle[ 'secondaryColor' ] && tmpStyle[ 'secondaryColor' ].indexOf( key ) === -1 ){
          tmpStyle[ 'secondaryColor' ].push( key )
        }
        break
    }
    return tmpStyle
  }
  const patchColumnContainer = ( tmpComponent ) => {
    let tmpColumn = _.cloneDeep( props.selectedCol )
    tmpColumn = props.FindComponentAndPatch( tmpColumn, tmpComponent )
    props.updateSelectedCol( tmpColumn )
    let tmpContainer = _.cloneDeep( props.dom )
    tmpContainer = props.FindComponentAndPatch( tmpContainer, tmpColumn )
    props.updateDom( tmpContainer )
  }
  const getBorderStyling = style => (
    _.reduce(
      style, 
      ( result, value, key ) => ({ 
        ...result, 
        ...( key.indexOf( 'border' ) > -1 && value.indexOf( '#' ) > -1 ? { [ key ]: value } : {} ) 
      }), 
      {}
    )
  )
  
  return (
    <Form 
      onSubmit={ e => e.preventDefault()}
      >
      <FormGroup>
        <Label>{ Lang[ 'UPDATE_TEXT' ][ props.selectedLanguage ] }</Label>
        <MultiLangInput
          selectedLanguage={ props.selectedLanguage }
          value={ props.selectedComponent.children[0].content }
          onChange={ val => {
            let tmpComponent = _.cloneDeep( props.selectedComponent )
            tmpComponent.children[0].content = val
            props.updateSelectedComponent( tmpComponent )
            patchColumnContainer( tmpComponent )
          }} />
      </FormGroup>
      <FormGroup>
        <Label>{ Lang[ 'ADJUST_PADDING' ][ props.selectedLanguage ] }</Label><br/>
        <span>Top</span>
        <div className="d-flex">
          <Button 
            color="primary"
            className="mr-1"
            disabled={ props.selectedComponent.paddingTop === 0 }
            onClick={() => {
              updateButtonPadding( 'paddingTop', props.selectedComponent.paddingTop - 1 )
            }}>
            -
          </Button>
          <Input
            type="number"
            className="mr-1"
            value={ props.selectedComponent.paddingTop }
            onChange={ e => {
              updateButtonPadding( 'paddingTop', parseInt( e.target.value, 10 ) )
            }} />
          <Button 
            color="primary"
            onClick={() => {
              updateButtonPadding( 'paddingTop', props.selectedComponent.paddingTop + 1 )
            }}>
            +
          </Button>
        </div>
        <span>Left</span>
        <div className="d-flex">
          <Button 
            color="primary"
            className="mr-1"
            disabled={ props.selectedComponent.paddingLeft === 0 }
            onClick={ () => {
              updateButtonPadding( 'paddingLeft', props.selectedComponent.paddingLeft - 1 )
            }}>
            -
          </Button>
          <Input
            type="number"
            className="mr-1"
            value={ props.selectedComponent.paddingLeft }
            onChange={ ( e ) => {
              updateButtonPadding( 'paddingLeft', parseInt( e.target.value, 10 ) )
            }} />
          <Button 
            color="primary"
            onClick={ () => {
              updateButtonPadding( 'paddingLeft', props.selectedComponent.paddingLeft + 1 )
            }}>
            +
          </Button>
        </div>
        <span>Right</span>
        <div className="d-flex">
          <Button 
            color="primary"
            className="mr-1"
            disabled={ props.selectedComponent.paddingRight === 0 }
            onClick={ () => {
              updateButtonPadding( 'paddingRight', props.selectedComponent.paddingRight - 1 )
            }}>
            -
          </Button>
          <Input
            type="number"
            className="mr-1"
            value={ props.selectedComponent.paddingRight }
            onChange={ ( e ) => {
              updateButtonPadding( 'paddingRight', parseInt( e.target.value, 10 ) )
            }} />
          <Button 
            color="primary"
            onClick={ () => {
              updateButtonPadding( 'paddingRight', props.selectedComponent.paddingRight + 1 )
            }}>
            +
          </Button>
        </div>
        <span>Bottom</span>
        <div className="d-flex">
          <Button 
            color="primary"
            className="mr-1"
            disabled={ props.selectedComponent.paddingBottom === 0 }
            onClick={ () => {
              updateButtonPadding( 'paddingBottom', props.selectedComponent.paddingBottom - 1 )
            }}>
            -
          </Button>
          <Input
            type="number"
            className="mr-1"
            value={ props.selectedComponent.paddingBottom }
            onChange={ ( e ) => {
              updateButtonPadding( 'paddingBottom', parseInt( e.target.value, 10 ) )
            }} />
          <Button 
            color="primary"
            onClick={ () => {
              updateButtonPadding( 'paddingBottom', props.selectedComponent.paddingBottom + 1 )
            }}>
            +
          </Button>
        </div>
      </FormGroup>
      <LinkInput 
        selectedContainer={ props.selectedComponent }
        onUpdate={ ( updatedDom ) => {
          props.updateSelectedComponent( updatedDom )
          patchColumnContainer( updatedDom )
        }}
        setMediaDatabase={ ( isOpen ) => { props.toggleMediaDatabase( isOpen ) } }
        anchorLinks={ props.anchorLinks }
        pages={ props.pages }
        site={ props.site }
        selectedLanguage={ props.selectedLanguage }
      />
      {
        props.onLoadComponents ? (
          <div className="text-center col-12 pt-3">
            <Loader type="ball-pulse-rise"/>
          </div>
        ) : ( 
          <FormGroup>
            <Label>{ Lang[ 'BUTTON_TYPE' ][ props.selectedLanguage ] }</Label>
            <Input  
              type='select'
              value={ props.selectedComponent.className || '' }
              onChange={ ( e ) => {
                if( !_.isEmpty( e.target.value ) ){
                  let child = _.cloneDeep( props.selectedComponent )
                  if( [ 'basic-button', 'outlined-button', 'slanted-button' ].indexOf( e.target.value ) > -1 ){
                    child.className = e.target.value
                    child.style = ButtonStylings[ e.target.value ][ 'button_style' ]
                    child.children[0].style = ButtonStylings[ e.target.value ][ 'text_style' ]
                  } else {
                    let buttonTemplate = _.find( props.components, item => item.content.className === e.target.value )
                    if( buttonTemplate ){
                      child.className = buttonTemplate.content.className
                      child.style = buttonTemplate.content.style
                      child.children[0].style = buttonTemplate.content.children[ 0 ].style
                    }
                  }
                  props.updateSelectedComponent( child )
                  let tmpColumn = _.cloneDeep( props.selectedCol )
                  tmpColumn = props.FindComponentAndPatch( tmpColumn, child )
                  props.updateSelectedCol( tmpColumn )
                  let tmpContainer = _.cloneDeep( props.dom )
                  tmpContainer = props.FindComponentAndPatch( tmpContainer, tmpColumn )
                  props.updateDom( tmpContainer )
                }
              }}>
              <option value=''></option>
              <option value='basic-button'>{ Lang[ 'BASIC' ][ props.selectedLanguage ] }</option>
              <option value='outlined-button'>{ Lang[ 'OUTLINED' ][ props.selectedLanguage ] }</option>
              <option value='slanted-button'>{ Lang[ 'SLANTED' ][ props.selectedLanguage ] }</option>
              <option value='border-top-animated-button'>{ Lang[ 'BORDER_TO_TOP_ANIMATION' ][ props.selectedLanguage ] }</option>
              <option value='left-right-reverse-animated-button-v2'>{ Lang[ 'BACKGROUND_SHRINKS_ANIMATION' ][ props.selectedLanguage ] }</option>
              <option value='left-right-animated-button-v2'>{ Lang[ 'BACKGROUND_SPREAD_ANIMATION' ][ props.selectedLanguage ] }</option>
              <option value='outline-animated-button'>{ Lang[ 'OUTLINE_ANIMATION' ][ props.selectedLanguage ] }</option>
              <option value='slant-animated-button'>{ Lang[ 'SLANTED_ANIMATION' ][ props.selectedLanguage ] }</option>
            </Input>
          </FormGroup>
        )
      }
      <FormGroup>
        <Label>{ Lang[ 'BUTTON_COLOR' ][ props.selectedLanguage ] }</Label>
        <Row>
          {
            props.selectedComponent.style && _.map(
              _.reduce( 
                props.selectedComponent.style, 
                ( result, value, key ) => ({ 
                  ...result,
                  ...( typeof value === 'string' && value.indexOf( '#' ) > -1 && key.indexOf( 'border' ) === -1
                    ? { [ key ] : value } 
                    : {} 
                  )
                }),
                {}
              ), 
              ( value, key ) => {
                return <ColorPickerInput
                  id={ `${ props.selectedComponent.id }-${ key }-` }
                  size={ 12 }
                  label={ Lang[ key.toUpperCase() ][ props.selectedLanguage ] }
                  color={ value.match( /#[^ ]*\b/g )[0] || '#ffffff' }
                  colorType={ getButtonColorType( props.selectedComponent.style, key ) }
                  selectedLanguage={ props.selectedLanguage }
                  onChange={ ( col ) => updateButtonStyle( key, value.replace( /#[^ ]*\b/g, col.hex ), false ) }
                  onChangeColorType={ type => updateButtonColorType( type, key, false ) } 
                />
              }
            )
          }
          {
            props.selectedComponent.style &&
            !_.isEmpty( getBorderStyling( props.selectedComponent.style ) ) && (
              <ColorPickerInput
                id={ `${ props.selectedComponent.id }-border-` }
                size={ 12 }
                label={ Lang[ 'BORDER' ][ props.selectedLanguage ] }
                color={ 
                  Object.values( getBorderStyling( props.selectedComponent.style ) )[0].match( /#[^ ]*\b/g )[ 0 ] || 
                  '#ffffff' 
                }
                colorType={ 
                  getButtonColorType( 
                    props.selectedComponent.style, 
                    Object.keys( getBorderStyling( props.selectedComponent.style ) )[0] 
                  ) 
                }
                selectedLanguage={ props.selectedLanguage }
                onChange={ ( col ) => {
                  let child = _.cloneDeep( props.selectedComponent )
                  _.map( getBorderStyling( child[ 'style' ] ), ( value, key ) => {
                    child[ 'style' ][ key ] = value.replace( /#[^ ]*\b/g, col.hex )
                  })
                  props.updateSelectedComponent( child )
                  let tmpColumn = _.cloneDeep( props.selectedCol )
                  tmpColumn = props.FindComponentAndPatch( tmpColumn, child )
                  props.updateSelectedCol( tmpColumn )
                  let tmpContainer = _.cloneDeep( props.dom )
                  tmpContainer = props.FindComponentAndPatch( tmpContainer, tmpColumn )
                  props.updateDom( tmpContainer )
                }} 
                onChangeColorType={ ( type ) => {
                  let child = _.cloneDeep( props.selectedComponent )
                  let tmpStyle = _.cloneDeep( child[ 'style' ] )
                  _.map( getBorderStyling( child[ 'style' ] ), ( value, key ) => {
                    tmpStyle = getModifiedColorTypeStyling( type, tmpStyle, key )
                  })
                  child[ 'style' ] = tmpStyle
                  props.updateSelectedComponent( child )
                  let tmpColumn = _.cloneDeep( props.selectedCol )
                  tmpColumn = props.FindComponentAndPatch( tmpColumn, child )
                  props.updateSelectedCol( tmpColumn )
                  let tmpContainer = _.cloneDeep( props.dom )
                  tmpContainer = props.FindComponentAndPatch( tmpContainer, tmpColumn )
                  props.updateDom( tmpContainer )
                }} 
              />
            )
          }
        </Row>
      </FormGroup>
      <FormGroup>
        <Label>{ Lang[ 'BUTTON_HOVER_COLOR' ][ props.selectedLanguage ] }</Label>
        <Row>
          {
            props.selectedComponent.style && props.selectedComponent.style[ ':hover' ] && _.map(
              _.reduce( 
                props.selectedComponent.style[ ':hover' ], 
                ( result, value, key ) => ({ 
                  ...result,
                  ...( typeof value === 'string' && value.indexOf( '#' ) > -1 && key.indexOf( 'border' ) === -1
                    ? { [ key ] : value } 
                    : {} 
                  )
                }),
                {}
              ), 
              ( value, key ) => {
                return <ColorPickerInput
                  id={ `${ props.selectedComponent.id }-${ key }-hover-` }
                  size={ 12 }
                  label={ Lang[ key.toUpperCase() ][ props.selectedLanguage ] }
                  color={ value.match( /#[^ ]*\b/g )[0] || '#ffffff' }
                  colorType={ getButtonColorType( props.selectedComponent.style[ ':hover' ], key ) }
                  selectedLanguage={ props.selectedLanguage }
                  onChange={ col => updateButtonStyle( key, value.replace( /#[^ ]*\b/g, col.hex ), true ) }
                  onChangeColorType={ type => updateButtonColorType( type, key, true ) } 
                />
              }
            )
          }
          {
            props.selectedComponent.style 
            && props.selectedComponent.style[ ':hover' ] &&
            !_.isEmpty( getBorderStyling( props.selectedComponent.style[ ':hover' ] ) ) && (
              <ColorPickerInput
                id={ `${ props.selectedComponent.id }-border-hover-` }
                size={ 12 }
                label={ Lang[ 'BORDER' ][ props.selectedLanguage ] }
                color={ 
                  Object.values( getBorderStyling( props.selectedComponent.style[ ':hover' ] ) )[0].match( /#[^ ]*\b/g )[0] || 
                  '#ffffff' 
                }
                colorType={ 
                  getButtonColorType( 
                    props.selectedComponent.style[ ':hover' ], 
                    Object.keys( getBorderStyling( props.selectedComponent.style[ ':hover' ] ) )[0] 
                  ) 
                }
                selectedLanguage={ props.selectedLanguage }
                onChange={ ( col ) => {
                  let child = _.cloneDeep( props.selectedComponent )
                  _.map( getBorderStyling( child[ 'style' ][ ':hover' ] ), ( value, key ) => {
                    child[ 'style' ][ ':hover' ][ key ] = value.replace( /#[^ ]*\b/g, col.hex )
                  })
                  props.updateSelectedComponent( child )
                  let tmpColumn = _.cloneDeep( props.selectedCol )
                  tmpColumn = props.FindComponentAndPatch( tmpColumn, child )
                  props.updateSelectedCol( tmpColumn )
                  let tmpContainer = _.cloneDeep( props.dom )
                  tmpContainer = props.FindComponentAndPatch( tmpContainer, tmpColumn )
                  props.updateDom( tmpContainer )
                }} 
                onChangeColorType={ ( type ) => {
                  let child = _.cloneDeep( props.selectedComponent )
                  let tmpStyle = _.cloneDeep( child[ 'style' ][ ':hover' ] )
                  _.map( getBorderStyling( child[ 'style' ][ ':hover' ] ), ( value, key ) => {
                    tmpStyle = getModifiedColorTypeStyling( type, tmpStyle, key )
                  })
                  child[ 'style' ][ ':hover' ] = tmpStyle
                  props.updateSelectedComponent( child )
                  let tmpColumn = _.cloneDeep( props.selectedCol )
                  tmpColumn = props.FindComponentAndPatch( tmpColumn, child )
                  props.updateSelectedCol( tmpColumn )
                  let tmpContainer = _.cloneDeep( props.dom )
                  tmpContainer = props.FindComponentAndPatch( tmpContainer, tmpColumn )
                  props.updateDom( tmpContainer )
                }} 
              />
            )
          }
        </Row>
      </FormGroup>
      <FormGroup>
        <Button 
          color="danger"
          onClick={ () => {
            let tmpColumn = _.cloneDeep( props.selectedCol )
            tmpColumn = props.FindComponentAndPatch( tmpColumn, props.selectedComponent, true )
            props.updateSelectedCol( tmpColumn )
            let tmpContainer = _.cloneDeep( props.dom )
            tmpContainer = props.FindComponentAndPatch( tmpContainer, props.selectedComponent, true )
            props.updateDom( tmpContainer )
            props.updateSelectedComponent( {} )
          }}>
          { Lang[ 'REMOVE_COMPONENT' ][ props.selectedLanguage ] }
        </Button>
      </FormGroup>
    </Form>
  )
}

const mapStateToProps = state => ({ data: state })

export default connect ( mapStateToProps, { toggleMediaDatabase })( ButtonConfig )