import React, { useState, Fragment } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import {
  Form, FormGroup, Label, Button, Input
} from 'reactstrap'

import { convertObjToBase64 } from 'utils/objToBase64'
import Lang from 'Lang/General'

const FilteringImage = ({
  getFilter,
  selectedImages,
  handleMode,
  mediaTags,
  getMedia,
  handleSecurityModulesUpdate,
  handleSecurityModulesDelete,
  data
}) => {
  const [ searchText, updateSearchText ] = useState( '' )
  const [ selectedTags, updateSelectedTags ] = useState( [] )
  const orientation = [ "PORTRAIT", "LANDSCAPE", "SQUARE" ]
  const [ selectedOrientation, updateSelectedOrientation ] = useState( [] )
  return(
    <Fragment>
      <Form>
        <FormGroup>
          <Label for="description" sm={ 12 }>{ Lang[ 'NAME' ][ data.languageReducer.lang ] }</Label>
          <Input
            name="description" id="description"
            placeholder={ Lang[ 'SEARCH_BY_IMG_NAME' ][ data.languageReducer.lang ] }
            onChange={ e => updateSearchText( e.target.value ) }/>
        </FormGroup>
        <Button className="mb-2 mr-2" color="primary" onClick={ () => getFilter( 'short_name', searchText ) }>{ Lang[ 'SEARCH' ][ data.languageReducer.lang ] }</Button>
        <Button
          className="mb-2 mr-2"
          color="danger"
          onClick={ () => {
            let tmp = {
              page: 1,
              is_paginated: true
            }
            getMedia( convertObjToBase64( tmp ) ) }}>{ Lang[ 'RESET' ][ data.languageReducer.lang ] }</Button>
        <div className="d-flex pt-1">
          {
            orientation.map( item => {
              return(
                <FormGroup style={{ margin: '0 15px' }}>
                  <Label>
                    <Input
                      type="checkbox"
                      value={ item }
                      onChange={ e => {
                        let tmp = _.cloneDeep( selectedOrientation )
                        let tmpFoundIndex = selectedOrientation.indexOf( e.target.value )
                        if( tmpFoundIndex !== -1 ) {
                          tmp.splice( tmpFoundIndex, 1 )
                        } else {
                          tmp.push( item )
                        }

                        updateSelectedOrientation( tmp )
                        getFilter( 'orientation', tmp )
                      }}/>
                    { item }
                  </Label>
                </FormGroup>
              )
            })
          }
        </div>
        {
          selectedImages && selectedImages.length > 0 && (
            <div className="d-flex pt-1">
              {
                handleSecurityModulesUpdate() && (
                  <Button className="mb-2 mr-2" color="primary" onClick={ () => handleMode( 'edit' ) }>{ Lang[ 'EDIT_PICTURES' ][ data.languageReducer.lang ] }</Button>
                )
              }
              {
                handleSecurityModulesDelete() && (
                  <Button className="mb-2 mr-2" color="danger" onClick={ () => handleMode( 'delete' ) }>{ Lang[ 'DELETE_PICTURES' ][ data.languageReducer.lang ] }</Button>
                )
              }
            </div>
          )
        }
        <hr />
        {
          mediaTags && mediaTags.map( item => {
            return(
              <Button
                className="mb-2 mr-2 btn-pill"
                color="light"
                value={ item.id }
                onClick={ e => {
                  let tmp = _.cloneDeep( selectedTags )
                  let tmpFoundIndex = selectedTags.indexOf( parseInt( e.target.value ) )
                  if( tmpFoundIndex !== -1 ) {
                    tmp.splice( tmpFoundIndex, 1 )
                  } else {
                    let tmpFound = _.find( mediaTags, { id: parseInt( e.target.value ) })
                    if( tmpFound ) {
                      tmp.push( tmpFound.id )
                    }
                  }

                  updateSelectedTags( tmp )
                  getFilter( 'tags', tmp )
                }}>
                <FormGroup style={{ marginBottom: 0, paddingLeft: '20px' }}>
                  <Label style={{ marginBottom: 0, cursor: 'pointer' }}>
                    <Input
                      type="checkbox"
                      value={ item.id }
                      checked={ selectedTags.indexOf( item.id ) !== -1 ? true : false }
                      onChange={ e => {
                        let tmp = _.cloneDeep( selectedTags )
                        let tmpFoundIndex = selectedTags.indexOf( parseInt( e.target.value ) )
                        if( tmpFoundIndex !== -1 ) {
                          tmp.splice( tmpFoundIndex, 1 )
                        } else {
                          let tmpFound = _.find( mediaTags, { id: parseInt( e.target.value ) })
                          tmp.push( tmpFound.id )
                        }

                        updateSelectedTags( tmp )
                        getFilter( 'tags', tmp )
                      }}/>
                    { item.label }
                  </Label>
                </FormGroup>
              </Button>
            )
          })
        }
      </Form>
    </Fragment>
  )
}
const mapStateToProps = state => ({ data: state })
export default connect( mapStateToProps )( FilteringImage )
