import React from 'react'
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'
import _ from 'lodash'
import { registerPlugin } from 'react-filepond'
import FilePondPluginImagePreview from "filepond-plugin-image-preview"

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'

import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

registerPlugin(FilePondPluginImagePreview)

const Update = ({
  showEditModal,
  selectedFileFolder,
  onChangeArkMFilesHOC,
  updateFile,
  updateFolder,
  onLoadMediaDrive,
  selectedLanguage
}) => {
  return (
    <Modal
      size={ 'md' }
      toggle={ () => onChangeArkMFilesHOC( 'showEditModal', false ) }
      isOpen={ showEditModal }>
      <ModalHeader toggle={ () => onChangeArkMFilesHOC( 'showEditModal', false ) }>{ Lang[ 'UPDATE_FOLDER_FILE' ][ selectedLanguage ] }</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <FormGroup>
            <Label>{ Lang[ 'NAME' ][ selectedLanguage ] }</Label>
            <Input
              type="text"
              value={ selectedFileFolder.file_name || selectedFileFolder.folder_name }
              onChange={ e => {
                let tmp = _.cloneDeep( selectedFileFolder )
                if( selectedFileFolder.type ) {
                  tmp[ 'file_name' ] = e.target.value
                } else{
                  tmp[ 'folder_name' ] = e.target.value
                }
                return onChangeArkMFilesHOC( 'selectedFileFolder', tmp )

              }} />
          </FormGroup>
          <FormGroup>
            {
              selectedFileFolder.thumb_url && (
                <>
                  <Label md={ 12 } className="pl-0">{ Lang[ 'CURRENT_UPLOADED_FILES' ][ selectedLanguage ] }</Label>
                  <img src={ selectedFileFolder.original_url } style={{ width: '30%' }} />
                </>
              )
            }
          </FormGroup>
        </Form>
        { onLoadMediaDrive && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        {
          !selectedFileFolder.folder_name && (
            <Button
              color="primary"
              onClick={() => {
                let tmpTags = []
                selectedFileFolder.tags.map( item => {
                  tmpTags.push({
                    id: item.id,
                    name: item.name,
                    center_id: item.center_id
                  })
                })
                let tmp = {
                  id: selectedFileFolder.id,
                  file_name: selectedFileFolder.file_name,
                  tags: tmpTags,
                  parent_folder_id: selectedFileFolder.parent_folder_id
                }
                updateFile( selectedFileFolder.id, tmp )
              }}>{ Lang[ 'SUBMIT' ][ selectedLanguage ] }</Button>
          )
        }
        {
          selectedFileFolder.folder_name && (
            <Button
              color="primary"
              onClick={() => {
                updateFolder( selectedFileFolder.id, selectedFileFolder )
              }}>{ Lang[ 'SUBMIT' ][ selectedLanguage ] }</Button>
          )
        }
      </ModalFooter>
    </Modal>
  )
}

export default Update