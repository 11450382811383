import React, { Component, Fragment, useState } from 'react'
import {
  Container, Card, CardBody, Button, Form, FormGroup, Col, Input, Label, Table, FormFeedback
}from 'reactstrap'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import ReactTable, { usePagination } from "react-table"
import _ from 'lodash'
import Lang from 'Lang/General'

class CreateRoleForm extends Component{
  state = {
    tmpPlatform: {},
    platformID: 0
  }

  onChangeRoleData = ( key, val ) => {
    let tmp = _.cloneDeep( this.props.newRolesPermission )
    tmp[ key ] = val
    return this.props.onChangeRoleHOC( 'newRolesPermission', tmp )
  }

  render = () => {
    return (
      <>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Container fluid>
            <Card className="main-card mb-3">
              <CardBody>
                <Form>
                  <FormGroup>
                    <Label for="company_name" sm={ 2 }  className="pl-1">{ Lang[ 'NAME' ][ this.props.lang ] }</Label>
                    <Col sm={ 8 } className="pl-0">
                      <Input
                        name="platform_name" id="platform_name"
                        placeholder={ Lang[ 'ENTER_ROLE' ][ this.props.lang ] }
                        value={ this.props.newRolesPermission.name }
                        onChange={ e => this.onChangeRoleData( 'name', e.target.value ) }
                        invalid={ this.props.errorMessage.name ? true : false }/>
                      <FormFeedback>{( this.props.errorMessage && this.props.errorMessage.name ) ? this.props.errorMessage.name : '' }</FormFeedback>
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Label for="company_name" sm={ 2 }  className="pl-1">{ Lang[ 'ASSIGN_PLATFORM' ][ this.props.lang ] }</Label>
                    <Col sm={ 8 } className="d-flex pl-0">
                      <Col sm={ 10 } className="p-0">
                        <Input
                          type="select"
                          name="platform_name" id="platform_name"
                          placeholder={ Lang[ 'ENTER_ROLE' ][ this.props.lang ] }
                          value={ this.state.tmpPlatform }
                          onChange={ e => {
                            this.setState({ tmpPlatform: e.target.value })
                          }}
                          invalid={ false }>
                          <option value={ `` }>{ Lang[ 'SELECT_PLACEHOLDER' ][ this.props.lang ] }</option>
                          {
                            this.props.platforms.data && this.props.platforms.data.map( item => {
                              return (
                                <option key={ item.id } value={ item.id }>{ item.name }</option>
                              )
                            })
                          }
                        </Input>
                      </Col>
                      <Col sm={ 2 } className="pl-4 pr-0">
                        <Button
                          className="mb-2 pl-3 pr-3"
                          color="primary"
                          onClick={ () => {
                            this.props.getSelectedPlatform( this.state.tmpPlatform )
                          }}
                          style={{ float: 'right', width: '100%' }}>{ Lang[ 'ASSIGN' ][ this.props.lang ] }</Button>
                      </Col>
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Label m={ 2 }  className="pl-1">{ Lang[ 'SELECTED_PLATFORM' ][ this.props.lang ] }</Label>
                    <Col sm={ 8 } className="pl-0 flex-wrap">
                      {
                        this.props.newRolesPermission && this.props.newRolesPermission.platforms && this.props.newRolesPermission.platforms.map( item => {
                          return (
                            <div
                              style={{
                                background:` ${ item.id === this.state.platformID ? '#30b1ff' : '#eeeeee' }`,
                                padding: '3px 5px 3px 10px',
                                display: 'inline-block',
                                marginBottom: '20px',
                                marginRight: '15px',
                                borderRadius: '10px' }}
                              onClick={ () => this.setState({ platformID: item.id }) }>
                              <Label className="mr-3 mb-1">{ item.name }</Label>
                              <Button
                                className="m-0 p-1 mr-1"
                                style={{
                                  border: 'none',
                                  background: 'transparent',
                                  color: '#000'
                                }}
                                onClick={() => {
                                  let tmp = _.cloneDeep( this.props.platforms )
                                  tmp.push( item )
                                  let tmpRolesPermissions = _.cloneDeep( this.props.newRolesPermission )
                                  let tmpRolesPermIndex = _.findIndex( tmpRolesPermissions.platforms, { id: item.id })
                                  tmpRolesPermissions.platforms.splice( tmpRolesPermIndex, 1 )
                                  Promise.all([
                                    this.props.onChangePlatformHOC( 'platforms', tmp )
                                  ]).then(() => {
                                    this.onChangeRoleData( 'platforms', tmpRolesPermissions.platforms )
                                  })
                                }}>X</Button>
                            </div>
                          )
                        })
                      }
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Label m={ 2 }  className="pl-1">{ Lang[ 'PERMISSION_SETTING' ][ this.props.lang ] }</Label>
                    <Col sm={ 9 } className="pl-0" style={{ overflow: 'auto' }}>
                      <table className="table table-bordered" style={{ textAlign: 'center' }}>
                        <thead>
                          <tr>
                            <th>{ Lang[ 'NAME' ][ this.props.lang ] }</th>
                            <th>{ Lang[ 'CREATE' ][ this.props.lang ] }</th>
                            <th>{ Lang[ 'READ' ][ this.props.lang ] }</th>
                            <th>{ Lang[ 'UPDATE' ][ this.props.lang ] }</th>
                            <th>{ Lang[ 'DELETE' ][ this.props.lang ] }</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.props.newRolesPermission.platforms 
                            && _.sortBy( this.props.newRolesPermission.platforms.modules, [ 'name' ], [ 'asc' ] ).map( platform => {
                              return platform.modules && platform.modules.map( item => {
                                if( platform.id === this.state.platformID ){
                                  return (
                                    <tr key={ item.id }>
                                      <td>{ item.name }</td>
                                      <td>
                                        <Label>
                                          <Input
                                            type="checkbox"
                                            checked={ item.is_create }
                                            style={{
                                              margin: '0 auto'
                                            }}
                                            onChange={ () => {
                                              let tmpPlatformIndex = _.findIndex( this.props.newRolesPermission.platforms, { id: platform.id } )
                                              let tmpPlatform = this.props.newRolesPermission.platforms
                                              let tmpModules = tmpPlatform[tmpPlatformIndex].modules
                                              let tmp = _.find( tmpModules, { id: item.id } )
                                              let tmpModuleIndex = _.findIndex( tmpModules, { id: item.id } )
                                              tmp[ 'is_create' ] = !item.is_create
                                              tmpModules.splice( tmpModuleIndex, 1, tmp )
                                              tmpPlatform[tmpPlatformIndex][ 'modules' ] = tmpModules
                                              this.onChangeRoleData( 'platforms', tmpPlatform )
                                            }}/>{ '' }
                                        </Label>
                                      </td>
                                      <td>
                                        <Label>
                                          <Input
                                            type="checkbox"
                                            checked={ item.is_read }
                                            style={{
                                              margin: '0 auto'
                                            }}
                                            onChange={ () => {
                                              let tmpPlatformIndex = _.findIndex( this.props.newRolesPermission.platforms, { id: platform.id } )
                                              let tmpPlatform = this.props.newRolesPermission.platforms
                                              let tmpModules = tmpPlatform[tmpPlatformIndex].modules
                                              let tmp = _.find( tmpModules, { id: item.id } )
                                              let tmpModuleIndex = _.findIndex( tmpModules, { id: item.id } )
                                              tmp[ 'is_read' ] = !item.is_read
                                              tmpModules.splice( tmpModuleIndex, 1, tmp )
                                              tmpPlatform[tmpPlatformIndex][ 'modules' ] = tmpModules
                                              this.onChangeRoleData( 'platforms', tmpPlatform )
                                            }}/>{ '' }
                                        </Label>
                                      </td>
                                      <td>
                                        <Label>
                                          <Input
                                            type="checkbox"
                                            checked={ item.is_update }
                                            style={{
                                              margin: '0 auto'
                                            }}
                                            onChange={ () => {
                                              let tmpPlatformIndex = _.findIndex( this.props.newRolesPermission.platforms, { id: platform.id } )
                                              let tmpPlatform = this.props.newRolesPermission.platforms
                                              let tmpModules = tmpPlatform[tmpPlatformIndex].modules
                                              let tmp = _.find( tmpModules, { id: item.id } )
                                              let tmpModuleIndex = _.findIndex( tmpModules, { id: item.id } )
                                              tmp[ 'is_update' ] = !item.is_update
                                              tmpModules.splice( tmpModuleIndex, 1, tmp )
                                              tmpPlatform[tmpPlatformIndex][ 'modules' ] = tmpModules
                                              this.onChangeRoleData( 'platforms', tmpPlatform )
                                            }}/>{ '' }
                                        </Label>
                                      </td>
                                      <td>
                                        <Label>
                                          <Input
                                            type="checkbox"
                                            checked={ item.is_delete }
                                            style={{
                                              margin: '0 auto'
                                            }}
                                            onChange={ () => {
                                              let tmpPlatformIndex = _.findIndex( this.props.newRolesPermission.platforms, { id: platform.id } )
                                              let tmpPlatform = this.props.newRolesPermission.platforms
                                              let tmpModules = tmpPlatform[tmpPlatformIndex].modules
                                              let tmp = _.find( tmpModules, { id: item.id } )
                                              let tmpModuleIndex = _.findIndex( tmpModules, { id: item.id } )
                                              tmp[ 'is_delete' ] = !item.is_delete
                                              tmpModules.splice( tmpModuleIndex, 1, tmp )
                                              tmpPlatform[tmpPlatformIndex][ 'modules' ] = tmpModules
                                              this.onChangeRoleData( 'platforms', tmpPlatform )
                                            }}/>{ '' }
                                        </Label>
                                      </td>
                                    </tr>
                                  )
                                }
                              })
                            })
                          }
                        </tbody>
                      </table>
                    </Col>
                  </FormGroup>
                  <Button className="mb-2 mr-2 mt-5" color="alternate" onClick={ () => this.props.createRole( this.props.newRolesPermission ) } style={{ float: 'right' }}>{ Lang[ 'SAVE' ][ this.props.lang ] }</Button>
                </Form>
              </CardBody>
            </Card>
          </Container>
        </ReactCSSTransitionGroup>
      </>
    )
  }
}

export default CreateRoleForm
