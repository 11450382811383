import React, { Component } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from 'reactstrap'
import ARKTable from 'components/Table'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Lang from 'Lang/General'

class ModalFormArchived extends Component {
  componentDidUpdate = pp => {
    if( pp.showArchivedForms && !this.props.showArchivedForms ){
      this.props.getDigitalOrderForms( { 
        page: 1, 
        is_paginated: true,
        filter: {
          $or: [
            { status: 'ACTIVE' },
            { status: 'INACTIVE' }
          ]
        } 
      })
    }
  }

  render = () => {
    let {
      digitalOrderForms,
      showArchivedForms,
      onChangeDigitalOrderFormHOC,
      onLoadDigitalOrderForms,
      unarchiveDigitalOrderForm,
      data,
      formsTotalPages
    } = this.props

    return(
      <>
        <Modal isOpen={ showArchivedForms } size='xl' >
          <ModalHeader toggle={ () => onChangeDigitalOrderFormHOC( 'showArchivedForms', false ) } >
            { Lang[ 'ARCHIVED_FORMS' ][ data.languageReducer.lang ] }</ModalHeader>
          <ModalBody>
            <ARKTable
              data={ digitalOrderForms.data }
              columns={[
                {
                  Header: Lang[ 'NAME' ][ data.languageReducer.lang ],
                  accessor: 'name.' + data.languageReducer.lang
                },
                {
                  Header: Lang[ 'STATUS' ][ data.languageReducer.lang ],
                  accessor: 'status',
                  className: "d-flex flex-row align-items-center justify-content-center",
                  Cell: ({ value }) => Lang[ value ][ data.languageReducer.lang ]
                },
                {
                  Header: Lang[ 'ACTION' ][ data.languageReducer.lang ],
                  Cell: ( row ) => (
                    <div className="d-flex flex-row align-items-center justify-content-center w-100">
                      <Button
                        color='warning'
                        className='mr-2'
                        onClick={ () => unarchiveDigitalOrderForm( row.original.id ) }>
                        { Lang[ 'UNARCHIVED' ][ data.languageReducer.lang ] } </Button>
                    </div>
                  )
                }
              ]}
              totalPages={ formsTotalPages }
              page={ digitalOrderForms.meta ? digitalOrderForms.meta.current_page : 1 }
              onChangePage={ pg => {
                this.props.getDigitalOrderForms( { 
                  page: pg, 
                  is_paginated: true,
                  filter: {
                    status: 'ARCHIVED'
                  } 
                })
              }}
              showPagination={ false }/>
          </ModalBody>
          <ModalFooter>
            <Button
              color='link'
              onClick={ () => onChangeDigitalOrderFormHOC( 'showArchivedForms', false ) }>
              { Lang[ 'CLOSE' ][ data.languageReducer.lang ] } </Button>
          </ModalFooter>
        </Modal>
        { onLoadDigitalOrderForms && <LoadingOverlay/> }
      </>
    )
  }
}

export default ModalFormArchived