import React from 'react'
import ReactPlayer from 'react-player/lazy'

const Video = ({
  url
}) => {
  return(
    <ReactPlayer
      playing
      loop
      muted
      url={ url }
      width='100%'
      height='100%' />
  )
}

export default Video