import React, { Fragment } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { connect } from 'react-redux'
import {
  Container, Card, CardBody, 
  FormGroup, Form, Label, 
  Col, Input, FormFeedback
} from 'reactstrap'
import MultiLangInput from 'components/Input/MultiLang'

import Lang from 'Lang/General'

const CategoryForm = ({
  onChangeCategoryData,
  data,
  errorMessage,
  dataReducer
}) => {
  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={ true }
        transitionAppearTimeout={ 0 }
        transitionEnter={ false }
        transitionLeave={ false }>
        <Container>
          <Card>
            <CardBody>
              <Form>
                <FormGroup>
                  <MultiLangInput
                    label={ Lang[ 'ENTER_NAME' ][ dataReducer.languageReducer.lang ] }
                    selectedLanguage={ dataReducer.languageReducer.lang }
                    value={ data && data.name }
                    type={ 'text' }  
                    onChange={ val => {
                      onChangeCategoryData( 'name', val )
                    }}/>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}
const mapStateToProps = state => ({ dataReducer: state })

export default connect( mapStateToProps )( CategoryForm )
