import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import Moment from 'moment'

import { Get, Post, Put } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      showCreateCampaign: false,
      showUpdateCampaign: false,
      showViewCampaign: false,
      showDeleteCampaignConfirmation: false,
      archivedCampaignModal: false,
      loading: false,
      newCampaignListing: {
        title: '',
        description: '',
        is_app_campaign: false,
        is_new: false,
        status: 'ACTIVE',
        preview_image_url: '',
        preview_image_raw: '',
        previewImageFile: [],
        bookable_months: [],
        types: [],
        target_center_groups: [],
        bookable_countries: [],
        display_to_one_timer: true,
        info_image_url: '',
        teaser_image_url: '',
        preview_text: {
          en: '',
          de: '',
          nl: '',
          it: '',
          fr: ''
        },
        remark_label: {},
        is_remark_required: false,
        content: [],
        values: [],
        start_date_hint: {
          en: "Note: The campaign duration is always 8 weeks",
          de: "Hinweis: Die Kampagnendauer beträgt immer 8 Wochen",
          it: "Nota: la durata della campagna è sempre di 8 settimane",
          nl: "Opmerking: De duur van de campagne is altijd 8 weken"
        },
        "360_app_campaign": {
          "title": "",
          "description": "",
          "claim": "",
          "image_url": "",
          image_raw: '',
          previewImageFile: [],
          "is_standard_app_campaign_for_injoy": false,
          "is_standard_app_campaign_for_aciso": false,
          landing_page_url: '',
          start_date: Moment().format(),
          end_date: null,
          priority: 1
        }
      },
      campaigns: {
        data: [],
        meta: {}
      },
      campaignTotalPages: [],
      archivedCampaignTotalPages: [],
      selectedCampaign: {
        title: '',
        is_app_campaign: false,
        is_new: false,
        status: 'ACTIVE',
        preview_image_url: '',
        preview_image_raw: '',
        previewImageFile: [],
        bookable_months: [],
        types: [],
        target_center_groups: [],
        bookable_countries: [],
        display_to_one_timer: true,
        info_image_url: '',
        teaser_image_url: '',
        preview_text: {
          en: '',
          de: '',
          nl: '',
          it: '',
          fr: ''
        },
        remark_label: {},
        is_remark_required: false,
        content: [],
        values: [],
        start_date_hint: {
          en: "Note: The campaign duration is always 8 weeks",
          de: "Hinweis: Die Kampagnendauer beträgt immer 8 Wochen",
          it: "Nota: la durata della campagna è sempre di 8 settimane",
          nl: "Opmerking: De duur van de campagne is altijd 8 weken"
        },
        "360_app_campaign": {
          "title": "",
          "description": "",
          "claim": "",
          "image_url": "",
          image_raw: '',
          previewImageFile: [],
          "is_standard_app_campaign_for_injoy": false,
          "is_standard_app_campaign_for_aciso": false,
          landing_page_url: '',
          start_date: '',
          end_date: '',
          priority: 1
        }
      },
      toRemoveID: '',
      showPromptModal: false,
      campaignErrors: {},
      searchTitle: '',
      archivedCampaigns: [],
      archivedCampaignSearchTitle: '',
      mode: '',
      imageKey: ''
    }

    onChangeCampaignListingHOC = ( key, val ) => this.setState({ [ key ]: val })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => {
      if( typeof error === 'string' ) {
        toast.error( error )
      } else {
        let tmp = _.reduce(
          error,
          ( res, val, key ) => ({ ...res, [ key ]: val.replaceAll( '_', ' ' ) }),
          {}
        )
        this.setState({ campaignErrors: tmp })
      }
    }

    getOLSCampaignListings = ( query ) => Get(
      `/api/v1/ols_campaigns?query=${ query }`,
      this.getOLSCampaignListingsSuccess,
      this.getOLSCampaignListingsError,
      this.load
    )
    getOLSCampaignListingsSuccess = payload => {
      let tmp = []
      if( payload.data && payload.data.length > 0 ) {
        if( _.find( payload.data, { status: 'ACTIVE' }) ) {
          for( let i = 1; i <= payload.meta.last_page; i++ ) {
            tmp.push( i )
          }
          this.setState({ campaignTotalPages: tmp, campaigns: payload })
        } else {
          for( let i = 1; i <= payload.meta.last_page; i++ ) {
            tmp.push( i )
          }
          this.setState({ archivedCampaigns: payload, archivedCampaignTotalPages: tmp })
        }

      }
      if( !this.state.showCreateCampaign ) {
        this.setState({
          newCampaignListing: {
            title: '',
            description: '',
            is_app_campaign: false,
            is_new: false,
            status: 'ACTIVE',
            preview_image_url: '',
            preview_image_raw: '',
            previewImageFile: [],
            bookable_months: [],
            types: [],
            target_center_groups: [],
            bookable_countries: [],
            display_to_one_timer: true,
            info_image_url: '',
            teaser_image_url: '',
            preview_text: {
              en: '',
              de: '',
              nl: '',
              it: '',
              fr: ''
            },
            remark_label: {},
            is_remark_required: false,
            content: [],
            values: [],
            start_date_hint: {
              en: "Note: The campaign duration is always 8 weeks",
              de: "Hinweis: Die Kampagnendauer beträgt immer 8 Wochen",
              it: "Nota: la durata della campagna è sempre di 8 settimane",
              nl: "Opmerking: De duur van de campagne is altijd 8 weken"
            },
            "360_app_campaign": {
              "title": "",
              "description": "",
              "claim": "",
              "image": "",
              image_raw: '',
              previewImageFile: [],
              "is_standard_app_campaign_for_injoy": false,
              "is_standard_app_campaign_for_aciso": false,
              landing_page_url: '',
              start_date: Moment().format(),
              end_date: null,
              priority: 1
            }
          },
          campaignErrors: {}
        })
      }
    }
    getOLSCampaignListingsError = error => this.requestError( error )

    getSelectedOLSCampaign = ( id, flag ) => Get(
      `/api/v1/ols_campaigns/${ id }`,
      payload => this.getSelectedOLSCampaignSuccess( payload, flag ),
      this.getSelectedOLSCampaignError,
      this.load
    )
    getSelectedOLSCampaignSuccess = ( payload, flag ) => {
      let tmp = {
        ...payload,
        "360_app_campaign": payload.app
      }
      this.setState(
        { selectedCampaign: tmp }, 
        () => {
          if( flag === 'edit' ) {
            this.setState({ showUpdateCampaign: true })
          }
          if( flag === 'status' ) {
            this.setState({ showDeleteCampaignConfirmation: true })
          }
          if( flag === 'view' ) {
            this.setState({ showViewCampaign: true })
          }
        }
      )
    }
    getSelectedOLSCampaignError = error => this.requestError( error )

    createOLSCampaign = data => Post(
      `/api/v1/ols_campaigns`,
      data,
      this.createOLSCampaignSuccess,
      this.createOLSCampaignError,
      this.load
    )
    createOLSCampaignSuccess = () => {
      let tmp = {
        page: 1,
        is_paginated: true,
        filter: {
          status: 'ACTIVE'
        }
      }
      this.requestSuccess( Lang[ 'OLS_CAMPAIGN_CREATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showCreateCampaign: false })
      this.getOLSCampaignListings( convertObjToBase64( tmp ) )
    }
    createOLSCampaignError = error => {
      toast.error( Lang[ 'OLS_CAMPAIGN_CREATE_UNSUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.requestError( error )
    }

    updateOLSCampaign = ( data, query ) => {
      let tmp = _.cloneDeep( data )
      if( !tmp.is_app_campaign ) {
        tmp[ '360_app_campaign' ] = null
      }
      Put(
        `/api/v1/ols_campaigns/${ tmp.id }`,
        tmp,
        () => this.updateOLSCampaignSuccess( query ),
        this.updateOLSCampaignError,
        this.load
      )
    }
    updateOLSCampaignSuccess = query => {
      this.requestSuccess( Lang[ 'OLS_CAMPAIGN_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.getOLSCampaignListings( convertObjToBase64( query ) )
      this.setState({ showUpdateCampaign: false })
    }
    updateOLSCampaignError = error => {
      toast.error( Lang[ 'OLS_CAMPAIGN_UPDATE_UNSUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.requestError( error )
    }

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            onChangeCampaignListingHOC={ this.onChangeCampaignListingHOC }
            getOLSCampaignListings={ this.getOLSCampaignListings }
            getSelectedOLSCampaign={ this.getSelectedOLSCampaign }
            createOLSCampaign={ this.createOLSCampaign }
            updateOLSCampaign={ this.updateOLSCampaign }
            showCreateCampaign={ this.state.showCreateCampaign }
            showUpdateCampaign={ this.state.showUpdateCampaign }
            showViewCampaign={ this.state.showViewCampaign }
            onLoadCampaignListings={ this.state.loading }
            newCampaignListing={ this.state.newCampaignListing }
            selectedCampaign={ this.state.selectedCampaign }
            campaigns={ this.state.campaigns }
            campaignTotalPages={ this.state.campaignTotalPages }
            campaignErrors={ this.state.campaignErrors }
            showDeleteCampaignConfirmation={ this.state.showDeleteCampaignConfirmation }
            searchTitle={ this.state.searchTitle }
            archivedCampaigns={ this.state.archivedCampaigns }
            archivedCampaignModal={ this.state.archivedCampaignModal }
            archivedCampaignTotalPages={ this.state.archivedCampaignTotalPages }
            archivedCampaignSearchTitle={ this.state.archivedCampaignSearchTitle }
            mode={ this.state.mode }
            imageKey={ this.state.imageKey } />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC
