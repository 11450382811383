import React, { useState, useEffect, Component } from 'react'
import {
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  Card,
  CardBody
} from 'reactstrap'
import _ from 'lodash'
import uniqid from 'uniqid'

import Lang from 'Lang/General'

import 'react-responsive-tabs/styles.css'

const DropdownEditor = props => {
  return (
    <Col md={ 12 }>
      <p>{ Lang[ 'SELECTION' ][ props.selectedLanguage ] }</p>
      <Row>
        {
          props.selectedComponentToEdit.children[0].selections.map(( item, index ) => {
            return (
              <Col md={ 6 }>
                <Card key={ item.id } className="mt-3">
                  <CardBody>
                    <FormGroup>
                      <Label>{ Lang[ 'LABEL' ][ props.selectedLanguage ] }</Label>
                      <Input
                        type="text"
                        value={ item.label[ props.selectedLanguage ] }
                        onChange={ e => {
                          let tmp = _.cloneDeep( props.selectedComponentToEdit )
                          tmp.children[0].selections[ index ][ 'label' ][ props.selectedLanguage ] = e.target.value
                          return props.updateSelectedComponentToEdit( 'children', tmp.children )
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>{ Lang[ 'FIELD_NAME' ][ props.selectedLanguage ] }</Label>
                      <Input
                        type="text"
                        value={ item.value }
                        onChange={ e => {
                          let tmp = _.cloneDeep( props.selectedComponentToEdit )
                          tmp.children[0].selections[ index ][ 'value' ] = e.target.value.replace( /\s/g, '' )
                          return props.updateSelectedComponentToEdit( 'children', tmp.children )
                        }}
                      />
                      <span>{ Lang[ 'THIS_FIELD_NEEDS_TO_BE_FILLED' ][ props.selectedLanguage ] }</span>
                    </FormGroup>
                    <Button
                      color="danger"
                      onClick={ () => {
                        let tmp = _.cloneDeep( props.selectedComponentToEdit )
                        tmp.children[0].selections.splice( index, 1 )
                        return props.updateSelectedComponentToEdit( 'children', tmp.children )
                      }}>
                      <i className="pe-7s-trash" />
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            )
          })
        }
        </Row>
        <Button
          color="primary"
          className="mt-3"
          onClick={ () => {
            let tmp = _.cloneDeep( props.selectedComponentToEdit )
            tmp.children[0].selections.push({
              label: {
                "en": "Your text here",
                "de": "Ihr Text hier",
                "nl": "Uw tekst hier",
                "it": "Il tuo testo qui"
              },
              value: uniqid()
            })
            return props.updateSelectedComponentToEdit( 'children', tmp.children )
          }}>{ Lang[ 'ADD_SELECTION' ][ props.selectedLanguage ] }</Button>
      </Col>
  )
}

export default DropdownEditor