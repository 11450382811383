import { combineReducers } from "redux";

import ajaxReducer from "./ajax";
import dictionaryReducer from './dictionary'
import profileReducer from './profile'
import centerReducer from './center'
import languageReducer from './language'
import roleReducer from './role'
import ThemeOptions from './ThemeOptions'
import MediaDatabaseToggle from './MediaDatabaseToggle'
import sidemenuReducer from './sidemenu'
import platformConfigReducer from './platformConfig'

export default combineReducers({
  ajaxReducer,
  dictionaryReducer,
  profileReducer,
  centerReducer,
  languageReducer,
  roleReducer,
  ThemeOptions,
  MediaDatabaseToggle,
  sidemenuReducer,
  platformConfigReducer
});
