import React, { Component } from 'react'
import { compose } from 'redux'
import{
  Modal, ModalBody, ModalFooter, ModalHeader, Button, UncontrolledTooltip
} from 'reactstrap'
import _ from 'lodash'

import Wrapper from '../Wrapper'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PageTitle from 'components/PageTitle'
import ARKTable from 'components/Table'
import RoleHOC from './actions'
import CreateRoleForm from './Create'
import EditRoleForm from './Edit'
import PlatformHOC from './actions/Platforms'

import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

class RoleManagement extends Component {
  state = {
    processedPlatforms: []
  }

  componentDidMount = () => {
    this.props.getRoles( convertObjToBase64({
      page: 1,
      is_paginated: true
    }))
    this.props.getPlatforms(convertObjToBase64({
      is_paginated: false
    }))
  }

  renderCreateModal = () => {
    return (
      <Modal
        toggle={ () => {
          Promise.all([
            this.props.onChangeRoleHOC( 'newRolesPermission', { name: '', platforms: [] } ),
            this.props.onChangeRoleHOC( 'errorMessage', {} )
          ]).then( () => {
            this.props.onChangeRoleHOC( 'createRoleModal', false )
          })
        }}
        isOpen={ this.props.createRoleModal }
        size="xl">
        <ModalHeader
          toggle={ () => {
            Promise.all([
              this.props.onChangeRoleHOC( 'newRolesPermission', { name: '', platforms: [] } ),
              this.props.onChangeRoleHOC( 'errorMessage', {} )
            ]).then( () => {
              this.props.onChangeRoleHOC( 'createRoleModal', false )
            })
          }}>{ Lang[ 'CREATE_ROLE' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <CreateRoleForm
            onChangeRoleHOC={ this.props.onChangeRoleHOC }
            newRolesPermission={ this.props.newRolesPermission }
            platforms={ this.props.platforms }
            getSelectedPlatform={ this.props.getSelectedPlatform }
            selectedPlatform={ this.props.selectedPlatform }
            onChangePlatformHOC={ this.props.onChangePlatformHOC }
            createRole={ this.props.createRole }
            errorMessage={ this.props.errorMessage }
            lang={ this.props.data.languageReducer.lang }/>
        </ModalBody>
        <ModalFooter>
          <Button
            className="mb-2 mr-2"
            color="primary"
            onClick={ () => {
              Promise.all([
                this.props.onChangeRoleHOC( 'newRolesPermission', { name: '', platforms: [] } ),
                this.props.onChangeRoleHOC( 'errorMessage', {} )
              ]).then( () => {
                this.props.onChangeRoleHOC( 'createRoleModal', false )
              })
            }}>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderEditModal = () => {
    return (
      <Modal
        toggle={ () => {
          Promise.all([
            this.props.onChangeRoleHOC( 'selectedPlatform', {} ),
            this.props.onChangeRoleHOC( 'errorMessage', {} )
          ]).then( () => {
            this.props.onChangeRoleHOC( 'editRoleModal', false )
          })
        }}
        isOpen={ this.props.editRoleModal }
        size="xl">
        <ModalHeader
          toggle={ () => {
            Promise.all([
              this.props.onChangeRoleHOC( 'selectedPlatform', {} ),
              this.props.onChangeRoleHOC( 'errorMessage', {} )
            ]).then( () => {
              this.props.onChangeRoleHOC( 'editRoleModal', false )
            })
          }}>{ Lang[ 'EDIT_ROLE' ][ this.props.data.languageReducer.lang ] }</ModalHeader>
        <ModalBody>
          <EditRoleForm
            onChangeRoleHOC={ this.props.onChangeRoleHOC }
            selectedRolePermission={ this.props.selectedRolePermission }
            platforms={ this.props.platforms }
            getEditSelectedPlatform={ this.props.getEditSelectedPlatform }
            selectedPlatform={ this.props.selectedPlatform }
            onChangePlatformHOC={ this.props.onChangePlatformHOC }
            updateSelectedRole={ this.props.updateSelectedRole }
            lang={ this.props.data.languageReducer.lang }/>
        </ModalBody>
        <ModalFooter>
          <Button
            className="mb-2 mr-2"
            color="primary"
            onClick={ () => {
              Promise.all([
                this.props.onChangeRoleHOC( 'selectedPlatform', {} ),
                this.props.onChangeRoleHOC( 'errorMessage', {} )
              ]).then( () => {
                this.props.onChangeRoleHOC( 'editRoleModal', false )
              })
            }}>{ Lang[ 'CANCEL' ][ this.props.data.languageReducer.lang ] }</Button>
        </ModalFooter>
      </Modal>
    )
  }

  render = () => {
    return(
      <>
        <div>
          <PageTitle
            heading={ Lang[ 'ROLE_AND_PERMISSIONS' ][ this.props.data.languageReducer.lang ] }
            icon="pe-7s-flag icon-gradient bg-happy-fisher"
            buttons={[
              {
                buttonText: Lang[ 'CREATE_ROLE' ][ this.props.data.languageReducer.lang ],
                onClick: () => {
                  this.props.getPlatforms( convertObjToBase64({ is_paginated: false }) )
                  this.props.onChangeRoleHOC( 'createRoleModal', true )
                },
                display: false
              }
            ]}/>
          <ARKTable
            data={ this.props.roles.data }
            columns={[
              {
                Header: 'ID',
                accessor: 'id'
              },
              {
                Header: Lang[ 'NAME' ][ this.props.data.languageReducer.lang ],
                accessor: "name",
                Cell: ({ value }) => Lang[ value.replace( /\s/g, '_' ).toUpperCase() ]
                  ? Lang[ value.replace( /\s/g, '_' ).toUpperCase() ][ this.props.data.languageReducer.lang ]
                  : value
              },
              {
                Header: Lang[ 'PLATFORM' ][ this.props.data.languageReducer.lang ],
                accessor: "platforms",
                Cell: (row) => (
                  <ul>
                  {
                    row.original.platforms && row.original.platforms.map( item => {
                      return <li>{ item.name }</li>
                    })
                  }
                  </ul>
                )
              },
              {
                Header: Lang[ 'ACTION' ][ this.props.data.languageReducer.lang ],
                accessor: "id",
                Cell: ({value}) => (
                  <>
                    <Button
                      id={ `Role-permission-${ value }-edit-role` }
                      className="mb-2 mr-2 btn-icon btn-icon-only"
                      color="primary"
                      onClick={ () => {
                        this.props.getPlatforms( convertObjToBase64({ is_paginated: false }) )
                        this.props.getSelectedRole( value )
                        this.props.onChangeRoleHOC( 'editRoleModal', true )
                      }}>
                      <i className="pe-7s-note"></i>
                    </Button>
                    <UncontrolledTooltip
                      target={ `Role-permission-${ value }-edit-role` }
                      placement="top">{ Lang[ 'EDIT_ROLE' ][ this.props.data.languageReducer.lang ] }</UncontrolledTooltip>
                  </>
                )
              }
            ]}
            totalPages={ this.props.rolesTotalPages }
            page={ this.props.roles.meta ? this.props.roles.meta.current_page : 1 }
            onChangePage={ pg => {
              let tmp = {
                page: pg,
                is_paginated: true
              }
              return this.props.getRoles( convertObjToBase64( tmp ) )
            }}
            onClickReset={ () => {
              let tmp = {
                page: 1,
                is_paginated: true
              }
            }}
            onSearch={ () => {} }
            searchLabel={ `Search with title` }
            searchComponent={ false }
            showPagination={ false } />
            { this.renderCreateModal() }
            { this.renderEditModal() }
        </div>
        { ( this.props.data.ajaxReducer.ajaxCallProgress > 0 || this.props.onLoadRole ) && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose(
  RoleHOC,
  PlatformHOC
)( RoleManagement )
