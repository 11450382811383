import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import _ from 'lodash'

import { GetWithNonce } from 'utils/axios-nonce'
import { Get, Put } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const ImprintHOC = ( WrappedComponent ) => {
  class ImprintWrappedComponent extends Component {
    state = {
      loading: false,
      imprints: [],
      selectedImprint: {},
      imprintTotalPages: [],
      centers: [],
      showViewImprint: false,
      showEditImprint: false,
      searchCenter: {}
    }

    onChangeImprintHOC = ( key, value ) => this.setState({ [ key ]: value })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => typeof error === 'string'
      ? toast.error( error )
      : Object.values( error ).map( item => toast.error( item.replaceAll( '_', ' ' ) ) )

    getAllImprints = query => GetWithNonce(
      `/api/v1/gdpr/imprints?query=${ convertObjToBase64( query ) }`,
      this.getAllImprintsSuccess,
      this.getAllImprintsError,
      this.load
    )
    getAllImprintsSuccess = payload => {
      let tmpTotalPages = []
      if ( payload.data && payload.data.length > 0 ) {
        for( let i = 1; i <= payload.meta.last_page; i++ ) {
          tmpTotalPages.push( i )
        }
      }
      this.setState({ imprints: payload, imprintTotalPages: tmpTotalPages })
    }
    getAllImprintsError = error => this.requestError( error )

    getSelectedImprint = ( center_id, type ) => GetWithNonce(
      `/api/v1/gdpr/${ center_id }/imprints`,
      ( payload ) => this.getSelectedImprintSuccess( payload, type ),
      this.getSelectedImprintError,
      this.load
    )
    getSelectedImprintSuccess = ( payload, type ) => type === 'view' 
      ? this.setState({ selectedImprint: payload, showViewImprint: true }) 
      : this.setState({ selectedImprint: payload, showEditImprint: true })
    getSelectedImprintError = error => this.requestError( error )

    updateImprint = ( center_id, data ) => Put(
      `/api/v1/gdpr/${ center_id }/imprints/${ data.id }`,
      data,
      this.updateImprintSuccess,
      this.updateImprintError,
      this.load
    )
    updateImprintSuccess = payload => {
      this.requestSuccess( Lang[ 'IMPRINT_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.setState({ showEditImprint: false })
    }
    updateImprintError = error => this.requestError( error )

    getCenters = query => Get(
      `/api/v1/centers?query=${ query }`,
      this.getCentersSuccess,
      this.getCentersError,
      this.load
    )
    getCentersSuccess = payload => {
      let centerOptions = []
      payload.data && payload.data.length > 0 && payload.data.map( item => {
        centerOptions.push({
          id: item.id, 
          label: item.center_name, 
          value: item.center_name
        })
      })
      this.setState({ centers: centerOptions })
    }
    getCentersError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props }
            imprints={ this.state.imprints }
            selectedImprint={ this.state.selectedImprint }
            imprintTotalPages={ this.state.imprintTotalPages }
            onChangeImprintHOC={ this.onChangeImprintHOC }
            getAllImprints={ this.getAllImprints }
            getSelectedImprint={ this.getSelectedImprint }
            updateImprint={ this.updateImprint }
            getCenters={ this.getCenters }
            centers={ this.state.centers }
            showViewImprint={ this.state.showViewImprint }
            showEditImprint={ this.state.showEditImprint }
            searchCenter={ this.state.searchCenter }
            onLoadImprint={ this.state.loading } />
        </>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( ImprintWrappedComponent )
}

export default ImprintHOC
