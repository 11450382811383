import React from 'react'
import {
  FormGroup, Label, Input, Button, CustomInput, 
  Row, Col
} from 'reactstrap'
import _ from 'lodash'
import { SketchPicker } from 'react-color'

import CKEditor from 'components/RTE/MultiLang'
import MultiLangInput from 'components/Input/MultiLang'

import Lang from 'Lang/General'

const FieldSettings = ( props ) => {

  const updateStyle = ( key, value ) => {
    let tmp = _.cloneDeep( props.selectedComponentToEdit )
    tmp.children[0].style[ key ] = value
    return props.updateSelectedComponentToEdit( 'children', tmp.children )
  }

  return (  
    <Row>
      <Col md={ 6 }>
        <FormGroup>
          <Label>{ Lang[ 'SELECTED_COMPONENT' ][ props.lang ] }</Label>
          <Input
            type="select"
            value={ !_.isEmpty( props.selectedComponentToEdit ) ? props.selectedComponentToEdit.children[ 0 ].type : '' }
            disabled={ true }>
            <option value={ '' }>{ '' }</option>
            <option value={ 'TextField' }>{ Lang[ 'TEXT_FIELD' ][ props.lang ] }</option>
            <option value={ 'TextArea' }>{ Lang[ 'TEXT_AREA' ][ props.lang ] }</option>
            <option value={ 'Radio' }>{ Lang[ 'RADIO' ][ props.lang ] }</option>
            <option value={ 'Text' }>{ Lang[ 'TEXT' ][ props.lang ] }</option>
            <option value={ 'Checkbox' }>{ Lang[ 'CHECKBOX' ][ props.lang ] }</option>
            <option value={ 'InputFile' }>{ Lang[ 'INPUT_FILE' ][ props.lang ] }</option>
            <option value={ 'Image' }>{ Lang[ 'IMAGE' ][ props.lang ] }</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label>{ Lang[ 'COLUMN_WIDTH' ][ props.lang ] }</Label>
          <Input
            type="select"
            value={ props.selectedComponentToEdit.grid }
            onChange={ async e => {
              await props.updateSelectedComponentToEdit( 'grid', parseInt( e.target.value, 10 ) )
            }}>
            <option value={ 3 }>25%</option>
            <option value={ 4 }>33%</option>
            <option value={ 6 }>50%</option>
            <option value={ 8 }>67%</option>
            <option value={ 9 }>75%</option>
            <option value={ 12 }>100%</option>
          </Input>
        </FormGroup>
        <Label>{ Lang[ 'PADDING' ][ props.lang ] }</Label>
        <FormGroup className="row pr-3">
          <Label className="col-sm-3 col-form-label">{ Lang[ 'TOP' ][ props.lang ] }</Label>
          <Input
            className="col-sm-9"
            type="text"
            value={ props.selectedComponentToEdit.children[ 0 ].style.paddingTop }
            onChange={ e => updateStyle( 'paddingTop', e.target.value ) } />
        </FormGroup>
        <FormGroup className="row pr-3">
          <Label className="col-sm-3 col-form-label">{ Lang[ 'BOTTOM' ][ props.lang ] }</Label>
          <Input
            className="col-sm-9"
            type="text"
            value={ props.selectedComponentToEdit.children[ 0 ].style.paddingBottom }
            onChange={ e => updateStyle( 'paddingBottom', e.target.value ) } />
        </FormGroup>
        <FormGroup className="row pr-3">
          <Label className="col-sm-3 col-form-label">{ Lang[ 'LEFT' ][ props.lang ] }</Label>
          <Input
            className="col-sm-9"
            type="text"
            value={ props.selectedComponentToEdit.children[ 0 ].style.paddingLeft }
            onChange={ e => updateStyle( 'paddingLeft', e.target.value ) } />
        </FormGroup>
        <FormGroup className="row pr-3">
          <Label className="col-sm-3 col-form-label">{ Lang[ 'RIGHT' ][ props.lang ] }</Label>
          <Input
            className="col-sm-9"
            type="text"
            value={ props.selectedComponentToEdit.children[ 0 ].style.paddingRight }
            onChange={ e => updateStyle( 'paddingRight', e.target.value ) } />
        </FormGroup>
      </Col>
      <Col md={ 6 }>
        <FormGroup>
          <Label>{ Lang[ 'BACKGROUND_COLOR' ][ props.lang ] }</Label>
          <SketchPicker
            disableAlpha={ true }
            width={ 185 }
            color={ props.selectedComponentToEdit.backgroundColor ? props.selectedComponentToEdit.backgroundColor : '' }
            onChange={ (color, event ) => {
              return props.updateSelectedComponentToEdit( 'backgroundColor', color.hex )
          }}/>
        </FormGroup>
      </Col>
      {
        props.selectedComponentToEdit.children[ 0 ].type === 'Text' && (
          <Col md={ 6 }>
            <FormGroup>
              <Label>{ Lang[ 'FONT_SIZE' ][ props.lang ] }</Label>
              <Input
                type="text"
                value={ props.selectedComponentToEdit.children[ 0 ].fontSize }
                onChange={ e => {
                  let tmp = _.cloneDeep( props.selectedComponentToEdit )
                  tmp.children[0].fontSize = e.target.value || "12px"
                  return props.updateSelectedComponentToEdit( 'children', tmp.children )
                }} />
            </FormGroup>
          </Col>
        )
      }
      {
        props.selectedComponentToEdit.children[ 0 ].type !== 'Image' && (
          <Col md={ 12 }>
            <FormGroup>
              <CKEditor
                label={ Lang[ 'FIELD_LABEL' ][ props.lang ] }
                value={ 
                  props.selectedComponentToEdit.children[0].type === 'Button' 
                    ? props.selectedComponentToEdit.children[ 0 ].content
                    : props.selectedComponentToEdit.children[ 0 ].label
                }
                onChange={ ( data ) => {
                  let tmp = _.cloneDeep( props.selectedComponentToEdit )
                  if( tmp.children[0].type === 'Button' ) { 
                    tmp.children[0].content = data 
                  } else {
                    tmp.children[0].label = data
                  }
                  return props.updateSelectedComponentToEdit( 'children', tmp.children )
                }}
                selectedLanguage={ props.lang } />
            </FormGroup>
          </Col>
        )
      }
      {
        props.selectedComponentToEdit.children[ 0 ].type === 'Image' && (
          <>
            <Col md={ 12 }>
              <FormGroup>
                <Label>{ Lang[ 'SELECTED_IMAGE_URL' ][ props.lang ] }</Label>
                <Input
                  type="text"
                  value={ props.selectedComponentToEdit.children[ 0 ].url }
                  onChange={ e => {
                    let tmp = _.cloneDeep( props.selectedComponentToEdit )
                    tmp.children[0].url = e.target.value
                    return props.updateSelectedComponentToEdit( 'children', tmp.children )
                  }}
                  disabled={ true } />
                <Button 
                  color="primary"
                  className="mt-2"
                  onClick={async () => {
                    await props.onChangeFormBuilderState( 'editingImageType', 'COMPONENT' )
                    props.toggleMediaDatabase( true )
                  }}>{ Lang[ 'SELECT_IMAGE' ][ props.lang ] }</Button>
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <Label>{ Lang[ 'WIDTH' ][ props.lang ] }</Label>
                <Input
                  type="text"
                  value={ props.selectedComponentToEdit.children[ 0 ].width }
                  onChange={ e => {
                    let tmp = _.cloneDeep( props.selectedComponentToEdit )
                    tmp.children[0].width = e.target.value
                    return props.updateSelectedComponentToEdit( 'children', tmp.children )
                  }} />
              </FormGroup>
            </Col>
            <Col md={ 12 }>
              <FormGroup>
                <CustomInput
                  id='enlargeImageField'
                  type="checkbox"
                  label={ Lang[ 'ENLARGE_IMAGE_ON_HOVER' ][ props.lang ] }
                  checked={ 
                    props.selectedComponentToEdit.children[ 0 ].is_enlarge 
                    ? props.selectedComponentToEdit.children[ 0 ].is_enlarge 
                    : false
                  }
                  onChange={ e => {
                    let tmp = _.cloneDeep( props.selectedComponentToEdit )
                    tmp.children[0].is_enlarge = e.target.checked
                    return props.updateSelectedComponentToEdit( 'children', tmp.children )
                  }} />
              </FormGroup>
            </Col>
            {
              props.selectedComponentToEdit.children[ 0 ].is_enlarge ? (
                <>
                  <Col md={ 6 }>
                    <FormGroup>
                      <Label>{ Lang[ 'ENLARGE_IMAGE_SIZE' ][ props.lang ] }</Label>
                      <Input
                        type="select"
                        value={ props.selectedComponentToEdit.children[ 0 ].enlarge_image_size }
                        onChange={ e => {
                          let tmp = _.cloneDeep( props.selectedComponentToEdit )
                          tmp.children[0].enlarge_image_size = e.target.value
                          return props.updateSelectedComponentToEdit( 'children', tmp.children )
                        }}>
                        <option value={ '25%' }>25%</option>
                        <option value={ '50%' }>50%</option>
                        <option value={ '75%' }>75%</option>
                        <option value={ '100%' }>100%</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </>
              ) : <></>
            }                  
          </>
        )
      }
      {
        props.selectedComponentToEdit.children[ 0 ].type === 'InputFile' && (
          <>
            <Col md={ 12 }>
              <FormGroup>
                <Label>{ Lang[ 'SELECTED_FILE_URL' ][ props.lang ] }</Label>
                <Input
                  type="text"
                  value={ props.selectedComponentToEdit.children[ 0 ].url }
                  onChange={ e => {
                    let tmp = _.cloneDeep( props.selectedComponentToEdit )
                    tmp.children[0].url = e.target.value
                    return props.updateSelectedComponentToEdit( 'children', tmp.children )
                  }}
                  disabled={ true } />
                <Button 
                  color="primary"
                  className="mt-2"
                  onClick={async () => {
                    await props.onChangeFormBuilderState( 'editingImageType', 'COMPONENT' )
                    props.toggleMediaDatabase( true )
                  }}>{ Lang[ 'SELECT_FILE' ][ props.lang ] }</Button>
              </FormGroup>
            </Col>
          </>
        )
      }
      {
        props.selectedComponentToEdit.children[ 0 ].type === 'TextArea' && (
          <Col md={ 6 }>
            <FormGroup>
              <Label>{ Lang[ 'NO_OF_ROWS_IN_TEXT_AREA' ][ props.lang ] }</Label>
              <Input
                type="text"
                value={ props.selectedComponentToEdit.children[ 0 ].rows }
                onChange={ e => {
                  let tmp = _.cloneDeep( props.selectedComponentToEdit )
                  tmp.children[0].rows = e.target.value
                  return props.updateSelectedComponentToEdit( 'children', tmp.children )
                }} />
            </FormGroup>
          </Col>
        )
      }
      {
        props.selectedComponentToEdit.children[ 0 ].type !== 'Image' &&
        props.selectedComponentToEdit.children[ 0 ].type !== 'Text' && 
        props.selectedComponentToEdit.children[ 0 ].mapWith !== 'page-selector' && (
          <Col md={ 12 } >
            <FormGroup>
              <CustomInput
                id='isRequiredField'
                type="checkbox"
                label={ Lang[ 'IS_A_REQUIRED_FIELD' ][ props.lang ] }
                checked={ props.formValuesCache[ props.selectedComponentToEdit.children[0].dataMapIndex ][ 'isRequired' ]
                  ? props.formValuesCache[ props.selectedComponentToEdit.children[0].dataMapIndex ][ 'isRequired' ]
                  : false }
                onChange={ e => {
                  let tmpFormValues = _.cloneDeep( props.formValuesCache )
                  tmpFormValues[ props.selectedComponentToEdit.children[0].dataMapIndex ][ 'isRequired' ] = e.target.checked
                  if (e.target.checked == false) {
                    tmpFormValues[ props.selectedComponentToEdit.children[0].dataMapIndex ][ 'validation' ] = Lang['BLANK']
                  }
                  else {
                    tmpFormValues[ props.selectedComponentToEdit.children[0].dataMapIndex ][ 'validation' ] = Lang['FIELD_IS_REQUIRED']
                  }
                  props.updateFormValuesCache( tmpFormValues )
                }} />
            </FormGroup>
            {
              props.formValuesCache[ props.selectedComponentToEdit.children[0].dataMapIndex ][ 'isRequired' ] ? (
                <MultiLangInput
                  label={ Lang[ 'ERROR_MESSAGE' ][ props.lang ] }
                  value={ props.formValuesCache[ props.selectedComponentToEdit.children[0].dataMapIndex ][ 'validation' ] }
                  onChange={ val => { 
                    let tmpFormValues = _.cloneDeep( props.formValuesCache )
                    tmpFormValues[ props.selectedComponentToEdit.children[0].dataMapIndex ][ 'validation' ] = val
                    props.updateFormValuesCache( tmpFormValues )
                  }}
                  selectedLanguage={ props.lang } />
              ) : <></>
            }                  
          </Col>
        )
      }
      {
        ( props.selectedComponentToEdit.children[ 0 ].type === 'TextField' || props.selectedComponentToEdit.children[ 0 ].type === 'TextArea' ) && (
          <Col md={ 12 } >
            <FormGroup>
              <CustomInput
                id='isPrefilled'
                type="checkbox"
                label={ Lang[ 'IS_PREFILLED' ][ props.lang ] }
                checked={ 
                  props.formValuesCache[ props.selectedComponentToEdit.children[0].dataMapIndex ][ 'isPrefilled' ] &&
                  !_.isEmpty( props.formValuesCache[ props.selectedComponentToEdit.children[0].dataMapIndex ][ 'isPrefilled' ] )
                }
                onChange={ e => {
                  let tmpFormValues = _.cloneDeep( props.formValuesCache )
                  if ( e.target.checked ) {
                    tmpFormValues[ props.selectedComponentToEdit.children[0].dataMapIndex ][ 'isPrefilled' ] = 'CENTER_NAME'
                  } else {
                    tmpFormValues[ props.selectedComponentToEdit.children[0].dataMapIndex ][ 'isPrefilled' ] = ''
                  }
                  props.updateFormValuesCache( tmpFormValues )
                }} />
            </FormGroup>   
            { 
              props.formValuesCache[ props.selectedComponentToEdit.children[0].dataMapIndex ][ 'isPrefilled' ] &&
              !_.isEmpty( props.formValuesCache[ props.selectedComponentToEdit.children[0].dataMapIndex ][ 'isPrefilled' ] ) && <FormGroup>
                <Label>{ Lang[ 'PREFILLED_WITH' ][ props.lang ] }</Label>
                <Input
                  type='select'
                  value={ props.formValuesCache[ props.selectedComponentToEdit.children[0].dataMapIndex ][ 'isPrefilled' ] }
                  onChange={ e => {
                    let tmpFormValues = _.cloneDeep( props.formValuesCache )
                    tmpFormValues[ props.selectedComponentToEdit.children[0].dataMapIndex ][ 'isPrefilled' ] = e.target.value
                    props.updateFormValuesCache( tmpFormValues )
                  }} >
                  <option value='CENTER_NAME' >{ Lang[ 'CENTER_NAME' ][ props.lang ] }</option>
                  <option value='CONSULTANT_NAME' >{ Lang[ 'CONSULTANT_NAME' ][ props.lang ] }</option>
                </Input>
              </FormGroup>
            }               
          </Col>
        )
      }
      {
        props.selectedComponentToEdit.children[ 0 ].type !== 'Button' && (
          <Col md={ 12 } >
            <FormGroup>
              <CustomInput
                id='showQuickInfo'
                type="checkbox"
                label={ Lang[ 'SHOW_QUICK_INFO' ][ props.lang ] }
                checked={ props.selectedComponentToEdit.children[ 0 ][ 'showQuickInfo' ] }
                onChange={ e => {
                  let tmp = _.cloneDeep( props.selectedComponentToEdit )
                  tmp.children[0].showQuickInfo = e.target.checked
                  return props.updateSelectedComponentToEdit( 'children', tmp.children )
                }} />
            </FormGroup>
            {
              props.selectedComponentToEdit.children[ 0 ][ 'showQuickInfo' ] ? (
                <FormGroup>
                  <Label>{ Lang[ 'PREVIEW_TEXT' ][ props.lang ] }</Label>
                  <Input
                    type="textarea"
                    rows="8"
                    value={ props.selectedComponentToEdit.children[ 0 ].preview_text }
                    placeholder={ Lang[ 'PREVIEW_TEXT_PLACEHOLDER' ][ props.lang ] }
                    onChange={ e => {
                      let tmp = _.cloneDeep( props.selectedComponentToEdit )
                      tmp.children[0].preview_text = e.target.value
                      return props.updateSelectedComponentToEdit( 'children', tmp.children )
                    }} />
                </FormGroup>
              ) : <></>
            }                  
          </Col>
        )
      }
      {
        props.selectedComponentToEdit.children[ 0 ].type === 'TextField' && (
          <Col md={ 12 } >
            <FormGroup className="d-flex flex-row flex-nowrap align-items-center" >
              <CustomInput
                id='hasMaxLength'
                type="checkbox"
                className='mr-2'
                label={ Lang[ 'MAXIMUM_CHARACTERS' ][ props.lang ] }
                checked={ props.selectedComponentToEdit.children[ 0 ][ 'hasMaxLength' ] }
                onChange={ e => {
                  let tmp = _.cloneDeep( props.selectedComponentToEdit )
                  tmp.children[0].hasMaxLength = e.target.checked
                  return props.updateSelectedComponentToEdit( 'children', tmp.children )
                }} 
              />
              {
                props.selectedComponentToEdit.children[ 0 ][ 'hasMaxLength' ] && (
                  <Input
                    step='1'
                    type='number'
                    style={{ width: "fit-content" }}
                    value={ props.selectedComponentToEdit.children[ 0 ].maxLength }
                    onChange={ e => {
                      let tmp = _.cloneDeep( props.selectedComponentToEdit )
                      tmp.children[0].maxLength = e.target.value
                      return props.updateSelectedComponentToEdit( 'children', tmp.children )
                    }} 
                  />
                )
              }
            </FormGroup>
          </Col>
        )
      }
    </Row>
  )
} 

export default FieldSettings