import React, { useState } from 'react'
import {
  Input, InputGroup, InputGroupAddon,
  FormGroup, Label, CustomInput, Button
} from 'reactstrap'
import _ from 'lodash'

import MediaDatabase from 'SharedModules/MediaDatabase' 
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Lang from 'Lang/General'

const LinkInput = props => {
  let {
    selectedContainer,
    onUpdate,
    setMediaDatabase,
    selectedLanguage
  } = props

  const [ isMediaDatabaseOpen, setIsMediaDatabaseOpen ] = useState( false )

  const renderClickableLinkInput = () => {
    if( selectedContainer.redirectType && selectedContainer.redirectType !== '' ) {
      if( selectedContainer.redirectType === 'external-link' ) {
        return <Input
          type='text'
          className='mb-2'
          style={{ minWidth: '50%' }}
          value={ selectedContainer.navTo 
            ? selectedContainer.navTo.replace( 'mailto:', '' ).replace( 'tel:', '' ) 
            : '' }
          onChange={ e => {
            let tmp = _.cloneDeep( selectedContainer )
            if( tmp[ 'navTo' ] ){
              tmp[ 'navTo' ] = e.target.value
            }
            if( tmp.type === 'a' ){
              tmp[ 'href' ] = e.target.value
            } 
            if( tmp.type === 'button' ){
              !_.isEmpty( e.target.value )
                ? tmp[ 'variant' ] = 'redirect'
                : delete tmp[ 'variant' ]
            }
            onUpdate( tmp )
          }}
        />
      } else if( selectedContainer.redirectType === 'email-link' ) {
        return <Input
          type='text'
          className='mb-2'
          style={{ minWidth: '50%' }}
          value={ selectedContainer.navTo 
            ? selectedContainer.navTo.replace( 'mailto:', '' ).replace( 'tel:', '' ) 
            : '' }
          onChange={ e => {
            let tmp = _.cloneDeep( selectedContainer )
            if( tmp[ 'navTo' ] ){
              tmp[ 'navTo' ] = 'mailto:' + e.target.value
            }
            if( tmp.type === 'a' ){
              if( tmp[ 'href' ] ){
                tmp[ 'href' ] = 'mailto:' + e.target.value
              }
            } 
            if( tmp.type === 'button' ){
              !_.isEmpty( e.target.value )
                ? tmp[ 'variant' ] = 'redirect'
                : delete tmp[ 'variant' ]
            }
            onUpdate( tmp )
          }}
        />
      } else if( selectedContainer.redirectType === 'tel-link' ) {
        return <Input
          type='text'
          className='mb-2'
          style={{ minWidth: '50%' }}
          value={ selectedContainer.navTo 
            ? selectedContainer.navTo.replace( 'mailto:', '' ).replace( 'tel:', '' ) 
            : '' }
          onChange={ e => {
            let tmp = _.cloneDeep( selectedContainer )
            if( tmp[ 'navTo' ] ){
              tmp[ 'navTo' ] = 'tel:' + e.target.value
            }
            if( tmp.type === 'a' ){
              if( tmp[ 'href' ] ){
                tmp[ 'href' ] = 'tel:' + e.target.value
              }
            } 
            if( tmp.type === 'button' ){
              !_.isEmpty( e.target.value )
                ? tmp[ 'variant' ] = 'redirect'
                : delete tmp[ 'variant' ]
            }
            onUpdate( tmp )
          }}
        />
      } else if( selectedContainer.redirectType === 'download-file' ) {
        return <Input
          type='text'
          className='mb-2'
          style={{ minWidth: '50%' }}
          value={ selectedContainer.navTo 
            ? selectedContainer.navTo.replace( 'mailto:', '' ).replace( 'tel:', '' ) 
            : '' } 
          disabled
        />
      }
    } else {
      return (
        <Input
          type='text'
          className='mb-2'
          value=''
          disabled>
        </Input>
      )
    }
  }

  return (
    <>
      <FormGroup>
        <Label>{ Lang[ 'LINK' ][ selectedLanguage ] }</Label>
        <InputGroup>
          <InputGroupAddon>
            <Input 
              type='select'
              value={
                selectedContainer.redirectType ? selectedContainer.redirectType : ''
              }
              style={{ borderRight: "0", borderRadius: "0.25rem 0 0 0.25rem" }}
              onChange={ e => {
                let tmp = _.cloneDeep( selectedContainer )
                delete tmp.isPublicFileUrl
                switch( e.target.value ){
                  case 'external-link':
                    tmp[ 'navTo' ] = 'https://'
                    tmp[ 'href' ] = 'https://'
                    tmp[ 'redirectType' ] = 'external-link'
                    break
                  case 'email-link':
                    tmp[ 'navTo' ] = 'mailto:'
                    tmp[ 'href' ] = 'mailto:'
                    tmp[ 'redirectType' ] = 'email-link'
                    break
                  case 'tel-link':
                    tmp[ 'navTo' ] = 'tel:'
                    tmp[ 'href' ] = 'tel:'
                    tmp[ 'redirectType' ] = 'tel-link'
                    break
                  case 'download-file':
                    tmp[ 'isPublicFileUrl' ] = true
                    tmp[ 'navTo' ] = ''
                    tmp[ 'redirectType' ] = 'download-file'
                    break
                  case '':
                    delete tmp[ 'navTo' ]
                    delete tmp[ 'href' ]
                    delete tmp[ 'redirectType' ]
                    break
                }
                tmp.type === 'button'
                  ? tmp[ 'variant' ] = 'redirect'
                  : delete tmp[ 'variant' ]
                onUpdate( tmp )
              }}>
              <option value=''>{ Lang[ 'NONE' ][ selectedLanguage ] }</option>
              <option value='external-link'>{ Lang[ 'EXTERNAL' ][ selectedLanguage ] }</option>
              <option value='email-link'>{ Lang[ 'EMAIL' ][ selectedLanguage ] }</option>
              <option value='tel-link'>{ Lang[ 'TEL' ][ selectedLanguage ] }</option>
              <option value='download-file'>{ Lang[ 'DOWNLOAD_FILE' ][ selectedLanguage ] }</option>
            </Input>
          </InputGroupAddon>
          { renderClickableLinkInput() }
        </InputGroup>
        {
          ( 
            selectedContainer.redirectType && selectedContainer.redirectType === 'external-link'
          ) && (
            <CustomInput
              id={'linkInNewTab'+selectedContainer.id}
              type='checkbox'
              label={ Lang[ 'OPEN_LINK_IN_NEW_TAB' ][ selectedLanguage ] }
              checked={ selectedContainer.newTab ? selectedContainer.newTab : false }
              onChange={ e => {
                let tmp = _.cloneDeep( selectedContainer )
                tmp[ 'newTab' ] = e.target.checked
                onUpdate( tmp )
              }} />
          )
        }
        {
          selectedContainer.isPublicFileUrl && <Button 
            color="primary"
            onClick={ () => {
              setMediaDatabase( true )
              setIsMediaDatabaseOpen(true) 
            }}>
            { Lang[ 'PUBLIC_FILE' ][ selectedLanguage ] }
          </Button>
        }
      </FormGroup>
      {
        isMediaDatabaseOpen &&
        <MediaDatabase
          onSelectedImage={ param => {
            // used for public files for now
            let tmp = _.cloneDeep( selectedContainer )
            tmp[ 'navTo' ] = param
            tmp[ 'href' ] = param
            tmp.type === 'button'
              ? tmp[ 'variant' ] = 'redirect'
              : delete tmp[ 'variant' ]
            Promise.all([
              onUpdate( tmp )
            ]).then(() => {
              setMediaDatabase( false )
              setIsMediaDatabaseOpen( false )
            })
          }}
          selectedLanguage={ selectedLanguage }
          allowed={[ 'pdf' ]} />
      }
      { props.onLoadPagesHOC && <LoadingOverlay/> }
    </>                    
  )
}

export default LinkInput