import React, { useRef, useEffect } from 'react'
import {
  Button, Form, FormGroup, Input, Label,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Col, FormFeedback, FormText
} from 'reactstrap'
import _ from 'lodash'
import Image from 'react-bootstrap/Image'
import { BeatLoader } from 'react-spinners'
import ReactSelect from 'react-select'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Lang from 'Lang/General'
import WithCountries from 'actions/country'
import NoPicture from 'assets/images/no-pic.jpg'

const CreateUser = ({
  dataReducer,
  showCreate,
  onChangeUsersHOC,
  newUser,
  roles,
  errorMessage,
  createUser,
  statusValues,
  onLoadUsers,
  currentUser,
  getStates,
  onLoadCountries,
  getCountries,
  countries,
  states
}) => {
  let fileInput = useRef( null )

  useEffect(() => {
    if( showCreate ) {
      getCountries()
    }
  }, [ showCreate ])

  const fileInputRef = element => fileInput = element

  const onClickUploadImage = () => focusFileInput()

  const focusFileInput = () => {
    if( fileInput )
      fileInput.click();
  }

  const onChange = ( key, value ) => {
    let tmp = _.cloneDeep( newUser )
    tmp[key] = value
    return onChangeUsersHOC( 'newUser', tmp )
  }

  const onChangeConsultant = ( key, value ) => {
    let tmp = _.cloneDeep( newUser )
    tmp[ 'consultant' ][key] = value
    return onChangeUsersHOC( 'newUser', tmp )
  }

  const onUploadImage = ( event ) => {
    if( event.target.files[0] ) {
      let reader = new FileReader();
      let filename = event.target.files[0].name
      reader.onload = e => {
        let tmp = _.cloneDeep( newUser )
        tmp[ 'photo_raw' ] = e.target.result
        tmp[ 'photo_file_name' ] = filename
        return onChangeUsersHOC( 'newUser', tmp )
      };
      reader.readAsDataURL( event.target.files[0] );
    }
  }

  const getValueForSelect = ( type ) => {
    switch ( type ) {
      case 'state':
        return {
          label: newUser.client.state,
          value: newUser.client.state
        }
      case 'country':
        return {
          label: newUser.client.country,
          value: newUser.client.country
        }
      default:
        return {}
    }
  }

  let countriesOptions = []
  countries && countries.map( item => {
    countriesOptions.push({
      id: item.id,
      label: item.name,
      value: item.name
    })
  })

  let statesOption = []
  states && states.map( item => {
    statesOption.push({
      id: item.id,
      label: item.name,
      value: item.name
    })
  })

  return (
    <Modal
      isOpen={ showCreate }
      toggle={ () => {
        onChangeUsersHOC( 'showCreate', false )
      }}
      size="lg">
      <ModalHeader
        toggle={ () => {
          onChangeUsersHOC( 'showCreate', false )
        }}>{ Lang[ 'CREATE_USER' ][ dataReducer.languageReducer.lang ] }</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row>
            <Label sm={ 2 }>{ Lang[ 'FIRST_NAME' ][ dataReducer.languageReducer.lang ] }</Label>
            <Col sm={ 10 }>
              <Input
                placeholder={ Lang[ 'FIRST_NAME' ][ dataReducer.languageReducer.lang ] }
                value={ newUser.first_name }
                onChange={ e => onChange( 'first_name', e.target.value ) }
                invalid={ errorMessage && errorMessage.first_name ? true : false }/>
              <FormFeedback>{ errorMessage && errorMessage.first_name ? errorMessage.first_name : '' }</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={ 2 }>{ Lang[ 'LAST_NAME' ][ dataReducer.languageReducer.lang ] }</Label>
            <Col sm={ 10 }>
              <Input
                placeholder={ Lang[ 'LAST_NAME' ][ dataReducer.languageReducer.lang ] }
                value={ newUser.last_name }
                onChange={ e => onChange( 'last_name', e.target.value ) }
                invalid={ errorMessage && errorMessage.last_name ? true : false }/>
              <FormFeedback>{ errorMessage && errorMessage.last_name ? errorMessage.last_name : '' }</FormFeedback>
            </Col>
          </FormGroup>
          {/*<FormGroup row>
            <Label sm={ 2 }>{ Lang[ 'USERNAME' ][ dataReducer.languageReducer.lang ] }</Label>
            <Col sm={ 10 }>
              <Input
                placeholder={ Lang[ 'USERNAME' ][ dataReducer.languageReducer.lang ] }
                value={ newUser.username }
                onChange={ e => onChange( 'username', e.target.value ) }
                invalid={ errorMessage && errorMessage.username ? true : false }/>
              <FormFeedback>{ errorMessage && errorMessage.username ? errorMessage.first_name : '' }</FormFeedback>
            </Col>
          </FormGroup>*/}
          <FormGroup row>
            <Label sm={ 2 }>{ Lang[ 'EMAIL' ][ dataReducer.languageReducer.lang ] }</Label>
            <Col sm={ 10 }>
              <Input
                placeholder={ Lang[ 'EMAIL' ][ dataReducer.languageReducer.lang ] }
                value={ newUser.email }
                onChange={ e => {
                  let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i )

                  if( !pattern.test( e.target.value ) ){
                    errorMessage["email"] = "Please enter valid email address."
                  } else {
                    errorMessage["email"] = ""
                  }
                  onChange( 'email', e.target.value )
                }}
                invalid={ errorMessage && errorMessage.email ? true : false }/>
              <FormFeedback>{ errorMessage && errorMessage.email ? errorMessage.email : '' }</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={ 2 }>{ Lang[ 'PASSWORD' ][ dataReducer.languageReducer.lang ] }</Label>
            <Col sm={ 10 }>
              <Input
                type="password"
                autoComplete='new-password'
                placeholder={ Lang[ 'PASSWORD' ][ dataReducer.languageReducer.lang ] }
                value={ newUser.password }
                onChange={ e => onChange( 'password', e.target.value ) }
                invalid={ newUser.password && newUser.password.length > 0 && newUser.password.length < 8 ? true : false }/>
              <FormFeedback>{ newUser.password.length > 0 && newUser.password.length < 8 ? 'Password must more than 8 characters' : '' }</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={ 2 }>{ Lang[ 'CONFIRM_PASSWORD' ][ dataReducer.languageReducer.lang ] }</Label>
            <Col sm={ 10 }>
              <Input
                type="password"
                placeholder={ Lang[ 'CONFIRM_PASSWORD' ][ dataReducer.languageReducer.lang ] }
                value={ newUser.password_confirmation }
                onChange={ e => onChange( 'password_confirmation', e.target.value ) }
                invalid={ newUser.password_confirmation.length > 0 && newUser.password_confirmation !== newUser.password ? true : false }/>
              <FormFeedback>{ newUser.password && newUser.password.length > 0 && newUser.password_confirmation.length > 0 && newUser.password_confirmation !== newUser.password ? 'Password not same' : '' }</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={ 2 }>{ Lang[ 'PHOTO' ][ dataReducer.languageReducer.lang ] }</Label>
            <Col sm={ 10 } style={{ height: '200px', maxWidth: '200px' }}>
              {
                errorMessage.photo_raw && (
                  <FormFeedback className="d-block">{ 'photo is required' }</FormFeedback>
                )
              }
              <Image
                src={ newUser.photo_raw !== '' ? newUser.photo_raw : NoPicture }
                onClick={ onClickUploadImage }
                style={{ height: '100%' }}
                roundedCircle={ newUser.photo_raw !== '' ? true : false }/>
              <input
                type="file"
                ref={ fileInputRef }
                style={{ display: 'none' }}
                onChange={ event => onUploadImage( event ) }
                accept={ 'image/png,image/jpg,image/jpeg' }/>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="role" sm={ 2 }>{ Lang[ 'ROLE' ][ dataReducer.languageReducer.lang ] }</Label>
            <Col sm={ 10 }>
              <Input
                type="select"
                name="role"
                id="role"
                value={ newUser.role_id }
                onChange={ e => {
                  let tmpRole = _.find( roles.data, { id: parseInt( e.target.value ) } )
                  let tmpUser = _.cloneDeep( newUser )
                  tmpUser.role_id = parseInt( e.target.value )
                  if( tmpRole.name === 'Consultant' ){
                    tmpUser.consultant = {
                      phone_number: '',
                      mobile_number: '',
                      best_contact_time: '',
                      fax_number: '',
                      remark: '',
                      status: 'ACTIVE'
                    }
                  } else if( tmpRole.name === 'Client' ) {
                    tmpUser.client = {
                      city: '',
                      company_name: '',
                      country: '',
                      house_number: '',
                      phone_number: '',
                      postcode: '',
                      salutation: '',
                      state: '',
                      street: '',
                      status: 'ACTIVE'
                    }
                  } else {
                    delete tmpUser.client
                    delete tmpUser.consultant
                  }
                  onChangeUsersHOC( 'newUser', tmpUser )
                }}>
                {
                  roles.data && roles.data.map( item => {
                    if( item.id >= currentUser.role_id ) {
                      return(
                        <option value={ item.id } key={ item.id }>{ item.name }</option>
                      )
                    }
                  })
                }
              </Input>
            </Col>
          </FormGroup>
          {
            _.find( roles.data, { id: parseInt( newUser.role_id ) } ) && _.find( roles.data, { id: parseInt( newUser.role_id ) } ).name === 'Consultant' && <>
              <FormGroup row>
                <Label for="phone_number" sm={ 2 }>{ Lang[ 'PHONE_NUMBER' ][ dataReducer.languageReducer.lang ] }</Label>
                <Col sm={ 10 }>
                  <Input
                    name="phone_number" id="phone_number"
                    placeholder={ Lang[ 'ENTER_PHONE_NUMBER' ][ dataReducer.languageReducer.lang ] }
                    value={ newUser.phone_number }
                    onChange={ e => onChangeConsultant( 'phone_number', e.target.value ) }
                    invalid={ errorMessage && errorMessage.phone_number ? true : false }/>
                  <FormFeedback>{ errorMessage && errorMessage.phone_number ? errorMessage.phone_number : '' }</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="mobile_number" sm={ 2 }>{ Lang[ 'MOBILE_NUMBER' ][ dataReducer.languageReducer.lang ] }</Label>
                <Col sm={ 10 }>
                  <Input
                    name="mobile_number" id="mobile_number"
                    placeholder={ Lang[ 'ENTER_MOBILE_NUMBER' ][ dataReducer.languageReducer.lang ] }
                    value={ newUser.mobile_number }
                    onChange={ e => onChangeConsultant( 'mobile_number', e.target.value ) }
                    invalid={ errorMessage && errorMessage.mobile_number ? true : false }/>
                  <FormFeedback>{ errorMessage && errorMessage.mobile_number ? errorMessage.mobile_number : '' }</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="fax_number" sm={ 2 }>{ Lang[ 'FAX_NUMBER' ][ dataReducer.languageReducer.lang ] }</Label>
                <Col sm={ 10 }>
                  <Input
                    name="fax_number" id="fax_number"
                    placeholder={ Lang[ 'ENTER_FAX_NUMBER' ][ dataReducer.languageReducer.lang ] }
                    value={ newUser.fax_number }
                    onChange={ e => onChangeConsultant( 'fax_number', e.target.value ) }
                    invalid={ errorMessage && errorMessage.fax_number ? true : false }/>
                  <FormFeedback>{ errorMessage && errorMessage.fax_number ? errorMessage.fax_number : '' }</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="best_contact_time" sm={ 2 }>{ Lang[ 'BEST_CONTACT_TIME' ][ dataReducer.languageReducer.lang ] }</Label>
                <Col sm={ 10 }>
                  <Input
                    name="best_contact_time" id="best_contact_time"
                    placeholder={ Lang[ 'ENTER_BEST_CONTACT_TIME' ][ dataReducer.languageReducer.lang ] }
                    value={ newUser.best_contact_time }
                    onChange={ e => onChangeConsultant( 'best_contact_time', e.target.value ) }
                    invalid={ errorMessage && errorMessage.best_contact_time ? true : false }/>
                  <FormFeedback>{ errorMessage && errorMessage.best_contact_time ? errorMessage.best_contact_time : '' }</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="remark" sm={ 2 }>{ Lang[ 'REMARK' ][ dataReducer.languageReducer.lang ] }</Label>
                <Col sm={ 10 }>
                  <Input
                    name="remark" id="remark"
                    placeholder={ Lang[ 'ENTER_REMARK' ][ dataReducer.languageReducer.lang ] }
                    value={ newUser.remark }
                    onChange={ e => onChangeConsultant( 'remark', e.target.value ) }
                    invalid={ errorMessage && errorMessage.remark ? true : false }/>
                  <FormFeedback>{ errorMessage && errorMessage.remark ? errorMessage.remark : '' }</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="role" sm={ 2 }>{ Lang[ 'STATUS' ][ dataReducer.languageReducer.lang ] }</Label>
                <Col sm={ 10 }>
                  <Input
                    type="select"
                    name="status"
                    id="status"
                    value={ newUser.status }
                    onChange={ e => onChangeConsultant( 'status', e.target.value ) }>
                    {
                      statusValues && Object.keys( statusValues ).map( key => {
                        return(
                          <option value={ statusValues[key] } key={ key }>{ statusValues[key] }</option>
                        )
                      })
                    }
                  </Input>
                </Col>
              </FormGroup>
            </>
          }
          {
            _.find( roles.data, { id: parseInt( newUser.role_id ) } ) && _.find( roles.data, { id: parseInt( newUser.role_id ) } ).name === 'Client' && (
              <>
                <FormGroup row>
                  <Label for="company_name" sm={ 2 }>{ Lang[ 'NAME' ][ dataReducer.languageReducer.lang ] }</Label>
                  <Col sm={ 10 }>
                    <Input
                      name="company" id="company_name"
                      placeholder={ Lang[ 'ENTER_NAME' ][ dataReducer.languageReducer.lang ] }
                      value={ newUser.client.company_name }
                      onChange={ e => {
                        let tmp = _.cloneDeep( newUser )
                        tmp[ 'client' ][ 'company_name' ] = e.target.value
                        onChangeUsersHOC( 'newUser', tmp )
                      }}
                      invalid={ errorMessage && errorMessage.company_name ? true : false }/>
                    <FormFeedback>{ errorMessage && errorMessage.company_name ? errorMessage.company_name : '' }</FormFeedback>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="salutation" sm={ 2 }>{ Lang[ 'SALUTATION' ][ dataReducer.languageReducer.lang ] }</Label>
                  <Col sm={ 10 }>
                    <Input
                      type="select"
                      name="salutation"
                      id="salutation"
                      error={ errorMessage && errorMessage.salutation ? true : false }
                      value={ newUser.client.salutation }
                      onChange={ e => {
                        let tmp = _.cloneDeep( newUser )
                        tmp[ 'client' ][ 'salutation' ] = e.target.value
                        onChangeUsersHOC( 'newUser', tmp )
                      }}>
                      <option>{ Lang['SELECT_A_SALUTATION'][ dataReducer.languageReducer.lang ] }</option>
                      <option value={ 'Herr' }>{ Lang['MR'][ dataReducer.languageReducer.lang ] }</option>
                      <option value={ 'Frau' }>{ Lang['MRS'][ dataReducer.languageReducer.lang ] }</option>
                    </Input>
                    <FormFeedback>{ errorMessage && errorMessage.salutation ? errorMessage.salutation : '' }</FormFeedback>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="street" sm={ 2 }>{ Lang[ 'STREET' ][ dataReducer.languageReducer.lang ] }</Label>
                  <Col sm={ 10 }>
                    <Input
                      name="street" id="street"
                      placeholder={ Lang[ 'ENTER_STREET_NAME' ][ dataReducer.languageReducer.lang ] }
                      value={ newUser.client.street }
                      onChange={ e => {
                        let tmp = _.cloneDeep( newUser )
                        tmp[ 'client' ][ 'street' ] = e.target.value
                        onChangeUsersHOC( 'newUser', tmp )
                      }}
                      invalid={ errorMessage && errorMessage.street ? true : false }/>
                    <FormFeedback>{ errorMessage && errorMessage.street ? errorMessage.street : '' }</FormFeedback>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="house_number" sm={ 2 }>{ Lang[ 'HOUSE_NUMBER' ][ dataReducer.languageReducer.lang ] }</Label>
                  <Col sm={ 10 }>
                    <Input
                      name="house_number" id="house_number"
                      placeholder={ Lang[ 'ENTER_HOUSE_NUMBER' ][ dataReducer.languageReducer.lang ] }
                      value={ newUser.client.house_number }
                      onChange={ e => {
                        let tmp = _.cloneDeep( newUser )
                        tmp[ 'client' ][ 'house_number' ] = e.target.value
                        onChangeUsersHOC( 'newUser', tmp )
                      }}
                      invalid={ errorMessage && errorMessage.house_number ? true : false }/>
                    <FormFeedback>{ errorMessage && errorMessage.house_number ? errorMessage.house_number : '' }</FormFeedback>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="postcode" sm={ 2 }>{ Lang[ 'POSTCODE' ][ dataReducer.languageReducer.lang ] }</Label>
                  <Col sm={ 10 }>
                    <Input
                      name="postcode" id="postcode"
                      placeholder={ Lang[ 'ENTER_POSTCODE' ][ dataReducer.languageReducer.lang ] }
                      value={ newUser.client.postcode }
                      onChange={ e => {
                        let tmp = _.cloneDeep( newUser )
                        tmp[ 'client' ][ 'postcode' ] = e.target.value
                        onChangeUsersHOC( 'newUser', tmp )
                      }}
                      invalid={ errorMessage && errorMessage.postcode ? true : false }/>
                    <FormFeedback>{ errorMessage && errorMessage.postcode ? errorMessage.postcode : '' }</FormFeedback>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="city" sm={ 2 }>{ Lang[ 'CITY' ][ dataReducer.languageReducer.lang ] }</Label>
                  <Col sm={ 10 }>
                    <Input
                      name="city" id="city"
                      placeholder={ Lang[ 'ENTER_CITY' ][ dataReducer.languageReducer.lang ] }
                      value={ newUser.client.city }
                      onChange={ e => {
                        let tmp = _.cloneDeep( newUser )
                        tmp[ 'client' ][ 'city' ] = e.target.value
                        onChangeUsersHOC( 'newUser', tmp )
                      }}
                      invalid={ errorMessage && errorMessage.city ? true : false }/>
                    <FormFeedback>{ errorMessage && errorMessage.city ? errorMessage.city : '' }</FormFeedback>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="country" sm={ 2 }>{ Lang[ 'COUNTRY' ][ dataReducer.languageReducer.lang ] }</Label>
                  <Col sm={ 10 }>
                    {
                      countriesOptions.length > 0
                        ? (
                          <ReactSelect
                            options={ countriesOptions }
                            onChange={ v => {
                              let tmp = _.cloneDeep( newUser )
                              tmp[ 'client' ][ 'country' ] = v.value
                              onChangeUsersHOC( 'newUser', tmp )
                              let tmpCountry = _.find( countriesOptions, { value: v.value })
                              if( tmpCountry ){
                                getStates( tmpCountry.id )
                              }
                            }}
                            value={ getValueForSelect( 'country' ) }
                            placeholder={ Lang[ 'SELECT_COUNTRY' ][ dataReducer.languageReducer.lang ] }/>
                        ) : <p>{ Lang[ 'COUNTRY_NOT_FOUND' ][ dataReducer.languageReducer.lang ] }</p>
                    }
                    <FormText color="danger">{ errorMessage && errorMessage.country ? errorMessage.country : '' }</FormText>
                  </Col>
                </FormGroup>
                {
                  onLoadCountries && <BeatLoader
                    sizeUnit={"px"}
                    size={30}
                    color={'#123abc'}
                    loading={true}
                  />
                }
                {
                  newUser.client.country && (
                    <FormGroup row>
                      <Label for="country" sm={ 2 }>STATE{ Lang[ 'STATE' ][ dataReducer.languageReducer.lang ] }</Label>
                      <Col sm={ 10 }>
                        <ReactSelect
                          options={ statesOption }
                          onChange={ v => {
                            let tmp = _.cloneDeep( newUser )
                            tmp[ 'client' ][ 'state' ] = v.value
                            onChangeUsersHOC( 'newUser', tmp )
                          }}
                          value={ getValueForSelect( 'state' ) }
                          placeholder={ Lang[ 'SELECT_STATE' ][ dataReducer.languageReducer.lang ] }/>
                        <FormText color="danger">{ errorMessage && errorMessage.state ? errorMessage.state : '' }</FormText>
                      </Col>
                    </FormGroup>
                  )
                }
                <FormGroup row>
                  <Label for="phone_number" sm={ 2 }>{ Lang[ 'PHONE_NUMBER' ][ dataReducer.languageReducer.lang ] }</Label>
                  <Col sm={ 10 }>
                    <Input
                      name="phone_number" id="phone_number"
                      placeholder={ Lang[ 'ENTER_PHONE_NUMBER' ][ dataReducer.languageReducer.lang ] }
                      value={ newUser.client.phone_number }
                      onChange={ e => {
                        let tmp = _.cloneDeep( newUser )
                        tmp[ 'client' ][ 'phone_number' ] = e.target.value
                        onChangeUsersHOC( 'newUser', tmp )
                      }}
                      invalid={ errorMessage && errorMessage.phone_number ? true : false }/>
                    <FormFeedback>{ errorMessage && errorMessage.phone_number ? errorMessage.phone_number : '' }</FormFeedback>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="fax_number" sm={ 2 }>{ Lang[ 'FAX_NUMBER' ][ dataReducer.languageReducer.lang ] }</Label>
                  <Col sm={ 10 }>
                    <Input
                      name="fax_number" id="fax_number"
                      placeholder={ Lang[ 'ENTER_FAX_NUMBER' ][ dataReducer.languageReducer.lang ] }
                      value={ newUser.client.fax_number }
                      onChange={ e => {
                        let tmp = _.cloneDeep( newUser )
                        tmp[ 'client' ][ 'fax_number' ] = e.target.value
                        onChangeUsersHOC( 'newUser', tmp )
                      }}
                      invalid={ errorMessage && errorMessage.fax_number ? true : false }/>
                    <FormFeedback>{ errorMessage && errorMessage.fax_number ? errorMessage.fax_number : '' }</FormFeedback>
                  </Col>
                </FormGroup>
              </>
            )
          }
          <FormGroup row>
            <Label for="language" sm={ 2 }>{ Lang[ 'DEFAULT_LANGUAGE' ][ dataReducer.languageReducer.lang ] }</Label>
            <Col sm={ 10 }>
              <Input
                type={ 'select' }
                value={ newUser.admin_panel_language }
                onChange={ e => onChange( 'admin_panel_language', e.target.value ) } >
                <option value={ 'en' }>{ Lang[ 'ENGLISH' ][ dataReducer.languageReducer.lang ] }</option>
                <option value={ 'de' }>{ Lang[ 'GERMAN' ][ dataReducer.languageReducer.lang ] }</option>
                <option value={ 'it' }>{ Lang[ 'ITALIAN' ][ dataReducer.languageReducer.lang ] }</option>
                <option value={ 'nl' }>{ Lang[ 'DUTCH' ][ dataReducer.languageReducer.lang ] }</option>
              </Input>
            </Col>
          </FormGroup>
        </Form>
        { onLoadUsers && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={ createUser }>{ Lang[ 'SUBMIT' ][ dataReducer.languageReducer.lang ] }</Button>
      </ModalFooter>
    </Modal>
  )
}

export default WithCountries( CreateUser )
