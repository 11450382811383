import React, { useState, useEffect } from 'react'
import { Col, Button, UncontrolledTooltip } from 'reactstrap'
import { MdEdit, MdCheckBox, MdIndeterminateCheckBox } from 'react-icons/md'
import { FaRegTrashAlt } from 'react-icons/fa'
import { Draggable } from "react-beautiful-dnd"

import RenderDOM from '../RenderDOM'

import Lang from 'Lang/General'

const getItemStyle = (isDragging, draggableStyle, content) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // change background colour if dragging
  background: isDragging ? "lightgreen" : content.backgroundColor,
  // styles we need to apply on draggables
  ...draggableStyle
})

const ColumnComponent = ({
  content,
  formValues,
  mode,
  onChangeFormBuilderState,
  onPatchFormValues,
  onClickSubmitForm,
  createSubmittedFormMedia,
  currentPage,
  dom,
  findObjectAndPatch,
  data,
  isInputDisabled,
  togglePageSelection,
  togglePageSelectable,
  duplicatePage,
  currentLanguage
}) => {
  const [ isHovered, setHovered ] = useState( false )
  const [ isDragDisabled, setIsDragDisabled ] = useState( false )

  useEffect( () => {
    setIsDragDisabled(
      content.children && (
        content.children[ 0 ].mapWith && 
        content.children[ 0 ].mapWith.toLowerCase().indexOf( 'page' ) > -1 &&
        content.children[ 0 ].mapWith !== 'page-selector' 
      ) ||
      content.children[ 0 ].type === "TableOfContents"
    )
  }, []) 

  return (
    <>
      {
        mode === 'edit' ? (
          <Draggable
            key={ content.id } 
            draggableId={ content.id } 
            index={ content.rowComponentIndex }
            isDragDisabled={ isDragDisabled } >
            {( provided, snapshot ) => (
              <div 
                ref={ provided.innerRef }
                { ...provided.draggableProps }
                { ...provided.dragHandleProps }
                className={ `col-md-${ content.grid }` } 
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                style={{  
                  padding: '5px', 
                  position: 'relative',
                  border: isHovered && !isDragDisabled? 'solid 2.5px lightgreen': 'none',
                  borderRadius: '5px',
                  background: content.backgroundColor,
                  ...getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style,
                    content
                  )
                }}>
                {
                  content.children && content.children.map( item => {
                    return <RenderDOM                       
                      content={ item }
                      onChangeFormBuilderState={ onChangeFormBuilderState }
                      mode={ mode }
                      formValues={ formValues }
                      onPatchFormValues={ onPatchFormValues }
                      onClickSubmitForm={ onClickSubmitForm }
                      createSubmittedFormMedia={ createSubmittedFormMedia }
                      currentPage={ currentPage }
                      dom={ dom }
                      findObjectAndPatch={ findObjectAndPatch }
                      data={ data }
                      isInputDisabled={ isInputDisabled }
                      togglePageSelection={ togglePageSelection }
                      currentLanguage={ currentLanguage }
                      showPageRearrangement={ null }
                      togglePageSelectable={ togglePageSelectable }
                      duplicatePage={ duplicatePage }
                    />
                  })
                }
                {
                  !content.children && (
                    <div>
                      <span>{ Lang[ 'PUT_SOMETHING_HERE' ][ currentLanguage ] }</span>
                    </div>
                  )
                }
                <div style={{ position: 'absolute', top: 5, right: 5 }}>
                  {
                    content.children[ 0 ].type !== 'Button' && 
                    content.children[ 0 ].type !== 'TableOfContents' && (
                      <Button 
                        style={{ float: 'right', marginLeft: '3px' }}
                        disabled={ content.children[ 0 ].type === 'Button' }
                        onClick={ async() => {
                          await onChangeFormBuilderState( 'selectedComponentToEdit', {
                            ...content
                          })
                          await onChangeFormBuilderState( 'showUpdateColumnModal', true )
                        }}>
                        <MdEdit color="#fff" size={ 16 } />
                      </Button>
                    )
                  }
                  {
                    ( 
                      content.children[ 0 ].type !== 'TableOfContents' && 
                      (
                        !content.children || 
                        ( content.children && !content.children[ 0 ].mapWith ) ||
                        ( content.children && content.children[ 0 ].mapWith && content.children[ 0 ].mapWith.toLowerCase().indexOf( 'page' ) === -1 ) 
                      )
                    ) && <Button
                      color="danger"
                      style={{ float: 'right' }}
                      onClick={ async() => {
                        await onChangeFormBuilderState( 'selectedComponentToRemove', content )
                        await onChangeFormBuilderState( 'showConfirmDelete', true )
                      }}>
                      <FaRegTrashAlt color="#fff" size={ 16 } />
                    </Button>
                  }
                </div>
              </div>
            )}
          </Draggable>
        ) : (
          <div 
            className={ `col-md-${ content.grid }` } 
            style={{ padding: '5px', position: 'relative' }}>
            {
              content.children && content.children.map( item => {
                return <RenderDOM                       
                  content={ item }
                  onChangeFormBuilderState={ onChangeFormBuilderState }
                  mode={ mode }
                  formValues={ formValues }
                  onPatchFormValues={ onPatchFormValues }
                  onClickSubmitForm={ onClickSubmitForm }
                  createSubmittedFormMedia={ createSubmittedFormMedia }
                  currentPage={ currentPage }
                  dom={ dom }
                  findObjectAndPatch={ findObjectAndPatch }
                  data={ data }
                  isInputDisabled={ false }
                  currentLanguage={ currentLanguage }
                  showPageRearrangement={ null }
                  togglePageSelectable={ togglePageSelectable }
                />
              })
            }
            {
              !content.children && (
                <div>
                  <span>{ Lang[ 'PUT_SOMETHING_HERE' ][ data.languageReducer.lang ] }</span>
                </div>
              )
            }
            {
              mode === 'edit' && <Button 
                style={{ position: 'absolute', top: 5, right: 5 }}
                onClick={ async() => {
                  await onChangeFormBuilderState( 'selectedComponentToEdit', {
                    ...content
                  })
                  await onChangeFormBuilderState( 'showUpdateColumnModal', true )
                }}>
                <MdEdit color="#fff" size={ 16 } />
              </Button>
            }
            {
              mode === 'edit' && <Button
                color="danger"
                style={{ position: 'absolute', top: 5, right: 50 }}
                onClick={ async() => {
                  await onChangeFormBuilderState( 'selectedComponentToRemove', content )
                  await onChangeFormBuilderState( 'showConfirmDelete', true )
                }}>
                <FaRegTrashAlt color="#fff" size={ 16 } />
              </Button>
            }
          </div>
        )
      }
    </>
  )
}

export default ColumnComponent