import React from 'react'
import {
  Card, CardBody,
  TabContent, TabPane,
  Nav, NavItem, NavLink
} from 'reactstrap'
import _ from 'lodash'
import classnames from 'classnames'

import Listings from './contract.js'
import ContractTemplates from './template.js'
import AVContractHOC from './actions'
import LoadingModal from 'components/Indicator/LoadingOverlay'
import PageTitle from 'components/PageTitle'

import Lang from 'Lang/General'

const AVContract = props => {

  return (
    <>
      <PageTitle
        heading={ Lang[ 'AV_CONTRACT' ][ props.data.languageReducer.lang ] }
        icon="pe-7s-copy-file icon-gradient bg-happy-fisher" />
      <Card className="main-card mb-3">
        <CardBody>
          <Nav tabs={ true }>
            <NavItem>
              <NavLink href="javascript:void(0);"
                className={ classnames({ active: props.activeTab === 1 }) }
                onClick={ () => {
                  props.onChangeAVContractHOC( 'activeTab', 1 )
                }}>{ Lang[ 'CONTRACTS' ][ props.data.languageReducer.lang ] }
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="javascript:void(0);"
                className={ classnames({ active: props.activeTab === 2 }) }
                onClick={ () => {
                  props.onChangeAVContractHOC( 'activeTab', 2 )
                }}>{ Lang[ 'TEMPLATES' ][ props.data.languageReducer.lang ] }
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={ props.activeTab }>
            <TabPane tabId={ 1 }>
              <Listings 
                { ...props }
                selectedLanguage={ props.data.languageReducer.lang } />
            </TabPane>
            <TabPane tabId={ 2 }>
              <ContractTemplates 
                { ...props }
                selectedLanguage={ props.data.languageReducer.lang } />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
      { props.onLoadAVContracts && <LoadingModal /> }
    </>
  )
}

export default AVContractHOC( AVContract )
