import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  Button,
  Label,
  Table,
  Row, Col
} from 'reactstrap'
import _ from 'lodash'

import DeleteConfirmation from 'components/Modal/delete'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Lang from 'Lang/General'

const UpdateDomain = ({
  showUpdateDomainModal,
  onChangeDomainHOC,
  selectedCenterDomains,
  createCenterDomain,
  updateCenterDomain,
  removeCenterDomain,
  onLoadCenterDomains,
  showConfirmDeleteDomainModal,
  toRemoveDomain,
  restartNginxProgress,
  data
}) => {
  return (
    <Modal
      size={ 'xl' }
      isOpen={ showUpdateDomainModal }
      toggle={() => onChangeDomainHOC( 'showUpdateDomainModal', false )}>
      <ModalHeader toggle={() => onChangeDomainHOC( 'showUpdateDomainModal', false )}>{ Lang[ 'UPDATE_DOMAIN' ][ data.languageReducer.lang ] }</ModalHeader>
      <ModalBody>
        <Button
          color="primary"
          className="mb-3"
          onClick={() => {
            let tmp = _.cloneDeep( selectedCenterDomains )
            tmp.center_domains.push({
              id: 0,
              redirect_to: '',
              is_test_domain_needed: false,
              site_domain: ''
            })
            let tmpCenterDomains = {
              ...tmp,
              center_domains: _.reverse( tmp.center_domains )
            }
            onChangeDomainHOC( 'selectedCenterDomains', tmpCenterDomains )
          }}>
          { Lang[ 'CREATE' ][ data.languageReducer.lang ] }
        </Button>
        <Table>
          <tbody>
            {
              selectedCenterDomains.center_domains.map(( item, index ) => {
                return (
                  <>
                    <tr key={ item.id }>
                      <td>
                        <Row>
                          <Col md={ 6 }>
                            <FormGroup>
                              <Label>{ Lang[ 'SITE_DOMAIN' ][ data.languageReducer.lang ] } (URL)</Label>
                              <Input
                                type={ 'text' }
                                value={ item.site_domain }
                                onChange={ e => {
                                  let tmp = _.cloneDeep( selectedCenterDomains )
                                  tmp[ 'center_domains' ][ index ][ 'site_domain' ] = e.target.value
                                  return onChangeDomainHOC( 'selectedCenterDomains', tmp )
                                }} />
                            </FormGroup>
                            <FormGroup>
                              <Label>{ Lang[ 'REDIRECT_TO' ][ data.languageReducer.lang ] }</Label>
                              <Input
                                rows="5"
                                type={ 'textarea' }
                                value={ item.redirect_to }
                                onChange={ e => {
                                  let tmp = _.cloneDeep( selectedCenterDomains )
                                  tmp[ 'center_domains' ][ index ][ 'redirect_to' ] = e.target.value
                                  return onChangeDomainHOC( 'selectedCenterDomains', tmp )
                                }} />
                            </FormGroup>
                          </Col>
                          <Col md={ 3 }>
                            <FormGroup>
                              <Input type="text" disabled={ true } value={ item.status } />
                            </FormGroup>
                            <FormGroup check>
                              <Input
                                type="checkbox"
                                checked={ item.is_test_domain_needed }
                                onChange={ e => {
                                  let tmp = _.cloneDeep( selectedCenterDomains )
                                  tmp[ 'center_domains' ][ index ][ 'is_test_domain_needed' ] = e.target.checked
                                  return onChangeDomainHOC( 'selectedCenterDomains', tmp )
                                }} />
                              { Lang[ 'NEED_TEST_DOMAIN' ][ data.languageReducer.lang ] }
                            </FormGroup>
                          </Col>
                          <Col md={ 3 } className="mt-2">
                            <div>
                              {
                                !item.has_config_issue && <Button
                                  color='primary'
                                  className="mr-2 btn-icon btn-icon-only"
                                  onClick={() => {
                                    if( item.id < 1 ) {
                                      createCenterDomain( item )
                                    } else {
                                      updateCenterDomain( item )
                                    }
                                  }}>
                                  <i className="pe-7s-check" style={{ fontSize: '24px' }}> </i>
                                </Button>
                              }
                              {
                                !item.is_default_domain && (
                                  <Button
                                    color='danger'
                                    className="btn-icon btn-icon-only"
                                    onClick={() => {
                                      if( item.id < 1 ) {
                                        let tmp = _.cloneDeep( selectedCenterDomains )
                                        tmp[ 'center_domains' ].splice( index, 1 )
                                        onChangeDomainHOC( 'selectedCenterDomains', tmp )
                                      } else {
                                        Promise.all([
                                          onChangeDomainHOC( 'toRemoveDomain', item.id )
                                        ]).then(() => {
                                          onChangeDomainHOC( 'showConfirmDeleteDomainModal', true )
                                        })
                                      }
                                    }}>
                                    <i className="pe-7s-trash" style={{ fontSize: '24px' }}> </i>
                                  </Button>
                                )
                              }
                            </div>
                            {
                              item.id > 0 && item.is_default_domain && (
                                <Button
                                  color='success'
                                  className="btn-icon btn-icon-only mt-2">
                                  { Lang[ 'DEFAULT_DOMAIN' ][ data.languageReducer.lang ] }
                                </Button>
                              )
                            }
                            {
                              item.id > 0 && !item.is_default_domain && !item.has_config_issue && (
                                <Button
                                  color='primary'
                                  className="btn-icon btn-icon-only mt-2"
                                  onClick={() => {
                                    updateCenterDomain({
                                      ...item,
                                      is_default_domain: true
                                    })
                                  }}>
                                  { Lang[ 'SET_DEFAULT' ][ data.languageReducer.lang ] }
                                </Button>
                              )
                            }
                            {
                              item.id > 0 && item.has_config_issue && (
                                <Button
                                  color='warning'
                                  className='mt-2'
                                  onClick={ () => restartNginxProgress( item ) }>
                                  { Lang[ 'NGINX_CONFIG_REGENERATED' ][ data.languageReducer.lang ] }</Button>
                              )
                            }
                          </Col>
                          {
                            item.id > 0 && item.redirect_type_id !== undefined && (
                              <Col md={ 12 }>
                                <FormGroup 
                                  value={ item.redirect_type_id } 
                                  onChange={ e => {
                                    let tmp = _.cloneDeep( selectedCenterDomains )
                                    tmp[ 'center_domains' ][ index ][ 'redirect_type_id' ] = parseInt( e.target.value, 10 )
                                    return onChangeDomainHOC( 'selectedCenterDomains', tmp )
                                  }}>
                                  <Label>SEO redirect</Label>
                                  <FormGroup check>
                                    <Label check>
                                      <Input 
                                        type="radio" 
                                        value={ 0 }
                                        checked={ item.redirect_type_id === 0 }
                                      />{' '}
                                      No redirect
                                    </Label>
                                  </FormGroup>
                                  <FormGroup check>
                                    <Label check>
                                      <Input 
                                        type="radio" 
                                        value={ 1 }
                                        checked={ item.redirect_type_id === 1 }
                                      />{' '}
                                      {'domain.tld => www.domain.tld'}
                                    </Label>
                                  </FormGroup>
                                  <FormGroup check>
                                    <Label check>
                                      <Input 
                                        type="radio" 
                                        value={ 2 }
                                        checked={ item.redirect_type_id === 2 }
                                      />{' '}
                                      {'www.domain.tld => domain.tld'}
                                    </Label>
                                  </FormGroup>
                                </FormGroup>
                              </Col>
                            )
                          }
                        </Row>
                      </td>
                    </tr>
                  </>
                )
              })
            }
          </tbody>
        </Table>
        <FormGroup>
          <Label>{ Lang[ 'PROCESS_LOGS' ][ data.languageReducer.lang ] }</Label>
          <Input
            type={ 'textarea' }
            value={ selectedCenterDomains.logs }
            onChange={ e => {} }
            disabled={ true }
            style={{
              background: '#000',
              color: '#eee',
              padding: '15px',
              width: '100%',
              height: '500px'
            }} />
        </FormGroup>
        {
          showConfirmDeleteDomainModal && (
            <DeleteConfirmation
              handleModalClose={ () => onChangeDomainHOC( 'showConfirmDeleteDomainModal', false ) }
              deleteOpen={ showConfirmDeleteDomainModal }
              confirmAction={ () => {
                Promise.all([
                  onChangeDomainHOC( 'showConfirmDeleteDomainModal', false )
                ]).then(() => {
                  removeCenterDomain( toRemoveDomain )
                })
              }}
              content={ Lang[ 'CONFIRMATION_REMOVE_TEXT' ][ data.languageReducer.lang ] }
              lang={ data.languageReducer.lang } />
          )
        }
        { onLoadCenterDomains && <LoadingOverlay /> }
      </ModalBody>
    </Modal>
  )
}

export default UpdateDomain
