import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get, Post, outdatedToken, Put, Delete } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'

import Lang from 'Lang/General'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      selectedModule: {},
      showUpdateModule: false
    }

    onChangeModuleHOC = ( key, val ) => this.setState({ [ key ]: val })

    load = param => this.setState({ loading: param })
    requestSuccess = success => toast.success( success )
    requestError = error => toast.error( error )

    getSelectedModule = ( platform_id, module_id ) => Get(
      `/api/v1/security_platforms/${ platform_id }/security_modules/${ module_id }`,
      this.getSelectedModuleSuccess,
      this.getSelectedModuleError,
      this.load
    )
    getSelectedModuleSuccess = payload => this.setState({ selectedModule: payload })
    getSelectedModuleError = error => this.requestError( error )

    updateModule = data => Put(
      `/api/v1/security_platforms/${ data.platform_id }/security_modules/${ data.id }`,
      data,
      () => this.updateModuleSuccess( data.platform_id ),
      this.updateModuleError,
      this.load
    )
    updateModuleSuccess = platform_id => {
      this.requestSuccess( Lang[ 'MODULE_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
      this.props.getSelectedPlatform( platform_id )
      this.setState({ showUpdateModule: false })
    }
    updateModuleError = error => this.requestError( error )
    
    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          selectedModule={ this.state.selectedModule }
          onLoadModules={ this.state.loading }
          showUpdateModule={ this.state.showUpdateModule }
          onChangeModuleHOC={ this.onChangeModuleHOC }
          getSelectedModule={ this.getSelectedModule }
          updateModule={ this.updateModule } />
      )
    }
  }
  return WithHOC
}

export default HOC