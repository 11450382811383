import React, { Component, Fragment } from 'react'
import _ from 'lodash'

import { toast } from 'react-toastify'

import { Get, Put, Post, Delete } from 'utils/axios-template'
import { production_url } from '../config'

import Lang from 'Lang/General'

const TemplateHeaderHOC = ( WrappedComponent ) => {
  class TemplateHeaderWrappedComponent extends Component {
    state = {
      templateHeaders: [],
      loading: false,
      updateLoading: false,
      showStatusModal: false,
      statusModalType: '',
      onClickStatusModalButton: () => {},
      statusModalMessage: '',
    }

    load = param => this.setState({ loading: param })
    updateLoad = param => this.setState({ updateLoading: param })
    requestSuccess = success => toast.success( success, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    requestError = error => toast.error( error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    getTemplateHeaders = ( template_id ) => Get(
      `${production_url}/templates/${template_id}/template-headers`,
      this.getTemplateHeadersSuccess,
      this.getTemplateHeadersError,
      this.load
    )
    getTemplateHeadersSuccess = payload => this.setState({ templateHeaders: payload })
    getTemplateHeadersError = error => this.requestError( error )

    updateTemplateHeader = ( template_id, data ) => Put(
      `${production_url}/templates/${template_id}/template-headers/${data.id}`,
      data,
      () => this.updateTemplateHeaderSuccess( template_id ),
      this.updateTemplateHeaderError,
      this.updateLoad
    )
    updateTemplateHeaderSuccess = template_id => {
      this.getTemplateHeaders( template_id )
      this.requestSuccess( Lang[ 'TEMPLATE_HEADER_UPDATE_SUCCESS' ][ this.props.data.languageReducer.lang ] )
    }
    updateTemplateHeaderError = error => this.requestError( error )

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            { ...this.props }
            getTemplateHeaders={ this.getTemplateHeaders }
            updateTemplateHeader={ this.updateTemplateHeader }
            templateHeaders={ this.state.templateHeaders }
            onLoadTemplateHeaders={ this.state.loading }
            onLoadHeaderUpdate={ this.state.updateLoading } />
        </Fragment>
      )
    }
  }
  return TemplateHeaderWrappedComponent
}

export default TemplateHeaderHOC
