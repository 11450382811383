import React, { Component } from 'react'
import Lang from 'Lang/General'

class FooterBlueprint extends Component {
  state = {
    selectedMenu: 'Desktop'
  }

  render = () => {
    return (
      <div className="row">
        <div className="col-md-12" style={{ paddingBottom: '15px' }}>
          <button
            className="btn btn-primary"
            onClick={() => this.setState({ selectedMenu: 'Desktop' }, () => this.props.onSelectFooterBlueprint( this.state.selectedMenu ) )}>
            { Lang[ 'DESKTOP_VERSION' ][ this.props.lang ] }
          </button>
          <button
            className="btn btn-success"
            onClick={() => this.setState({ selectedMenu: 'Mobile' }, () => this.props.onSelectFooterBlueprint( this.state.selectedMenu ))}>
            { Lang[ 'MOBILE_VERSION' ][ this.props.lang ] }
          </button>
        </div>
        <div className="col-md-6">
          <textarea
            style={{ width: '100%' }}
            rows={ 20 }
            value={ this.props.selectedFooterBlueprint }
            onChange={ e => {
              this.props.onChangeSelectedFooterBlueprint( e.target.value )
            }} />
        </div>
        <div className="col-md-6">

        </div>
      </div>
    )
  }
}

export default FooterBlueprint