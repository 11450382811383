import React, { Component } from 'react'

import { Get, Post, Put, Delete } from 'utils/axios'
import { convertObjToBase64 } from 'utils/objToBase64'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      modules: {
        data: [],
        meta: {}
      }
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showStatusModal: true,
      statusModalType: 'negative',
      onClickStatusModalButton: () => this.setState({ showStatusModal: false }),
      statusModalMessage: error
    })
    requestSuccess = success => this.setState({
      showStatusModal: true,
      statusModalType: 'positive',
      onClickStatusModalButton: () => this.setState({
        showStatusModal: false,
        errorMessage: {}
      }),
      statusModalMessage: success
    })

    getModules = ( query ) => Get(
      `/api/v1/modules?query=${ convertObjToBase64( query ) }`,
      this.getModulesSuccess,
      this.getModulesError,
      this.load
    )
    getModulesSuccess = payload => this.setState({ modules: payload })
    getModulesError = error => this.requestError( error )
    
    render = () => {
      return (
        <WrappedComponent 
          { ...this.props }
          getModules={ this.getModules }
          modules={ this.state.modules }
          onLoadModules={ this.state.loading } />
      )
    }
  }
  return WithHOC
}

export default HOC