import React, { useState, useEffect } from "react"
import ReactSelect from "react-select"
import { 
  Modal, ModalHeader, ModalBody, ModalFooter, 
  Form, FormGroup, FormText,
  Button, Label, Input, CustomInput 
} from "reactstrap"
import _ from "lodash"

import MultilingualInput from "components/Input/MultiLang"
import Lang from "Lang/General"

const UpdateModal = props => {
  const [ errorMessage, updateErrorMessage ] = useState({})
  const [ typeMode, updateTypeMode ] = useState( 'center' )

  useEffect( () => {
    if( props.showUpdateModal ){
      updateTypeMode( props.selectedTemplatePage.center_group_ids.length > 0 ? 'center-group' : 'center' )
    }
  }, [ props.showUpdateModal ])

  const updatePage = async () => {
    let tmpError = {}
    let tmp = _.cloneDeep( props.selectedTemplatePage )
    let keys = [ "url" ]
    typeMode === 'center' ? keys.push( 'center_ids' ) : keys.push( 'center_group_ids' )
    keys.map( key => {
      if ( _.isEmpty( props.selectedTemplatePage[ key ] ) ) {
        tmpError[ key ] = Lang[ "FIELD_IS_REQUIRED" ][ props.data.languageReducer.lang ]
      }
    })
    if ( Object.keys( props.selectedTemplatePage.name ).every( key => _.isEmpty( props.selectedTemplatePage.name[ key ] ))) {
      tmpError.name = Lang[ "FIELD_IS_REQUIRED" ][ props.data.languageReducer.lang ]
    }
    updateErrorMessage( tmpError )

    if ( _.isEmpty( tmpError ) ) {
      tmp.url = "/" + tmp.url
      tmp.template_id = parseInt( tmp.template_id )
      if( typeMode === 'center-group' ) {
        tmp.center_group_ids = _.map( props.selectedTemplatePage.center_group_ids, 'id' )
        tmp.countries = _.map( props.selectedTemplatePage.countries, 'value' )
        tmp.center_ids = []
      } else {
        tmp.center_ids = _.map( props.selectedTemplatePage.center_ids, 'id' )
        tmp.center_group_ids = []
        tmp.countries = []
      }
      props.updateLandingPage( tmp )
    }
  }

  const updatePageInfo = ( key, value ) => {
    let tmp = _.cloneDeep( props.selectedTemplatePage )
    tmp[ key ] = value
    props.onChangeLandingPageHOC( "selectedTemplatePage", tmp )
  }

  return (
    <>
      <Modal size="md" isOpen={ props.showUpdateModal }>
        <ModalHeader toggle={ () => props.onChangeLandingPageHOC( "showUpdateModal", !props.showUpdateModal ) }>
          { Lang[ "UPDATE" ][ props.data.languageReducer.lang ] }
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <MultilingualInput
                label={ `${ Lang[ "PAGE_TITLE" ][ props.data.languageReducer.lang ] } (${ Lang[ "MAXIMUM" ][ props.data.languageReducer.lang ] } 55 ${ Lang[ "CHARACTERS" ][ props.data.languageReducer.lang ] })` }
                selectedLanguage={ props.data.languageReducer.lang }
                value={ props.selectedTemplatePage.name }
                onChange={ val => updatePageInfo( "name", val ) }
              />
              <FormText color="danger">{ errorMessage.name ? errorMessage.name : "" }</FormText>
            </FormGroup>
            <FormGroup>
              <MultilingualInput
                label={ `${ Lang[ "DESCRIPTION" ][ props.data.languageReducer.lang ] } (${ Lang[ "MAXIMUM" ][ props.data.languageReducer.lang ] } 139 ${ Lang[ "CHARACTERS" ][ props.data.languageReducer.lang ] })` }
                selectedLanguage={ props.data.languageReducer.lang }
                value={ props.selectedTemplatePage.description }
                onChange={ val => updatePageInfo( "description", val ) }
              />
            </FormGroup>
            <FormGroup>
              <Label>
                { Lang[ "URL" ][ props.data.languageReducer.lang ]}
                <span className="text-danger">*</span>
              </Label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                }}
              >
                <Label
                  style={{
                    fontSize: "18px",
                    paddingLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                  {" "} / {" "}
                </Label>
                <Input
                  type="text"
                  value={ props.selectedTemplatePage.url }
                  onChange={ e => updatePageInfo( "url", e.target.value ) }
                ></Input>
              </div>
              <FormText color="danger">{ errorMessage.url ? errorMessage.url : "" }</FormText>
            </FormGroup>
            <FormGroup>
              <CustomInput
                id='is_hide_footer'
                type='checkbox'
                label={ Lang[ 'HIDE_FOOTER_FOR_LANDING_PAGE_CREATED' ][ props.data.languageReducer.lang ] }
                checked={ props.selectedTemplatePage.is_hide_footer }
                onChange={ e => updatePageInfo( 'is_hide_footer', e.target.checked ) } />
            </FormGroup>
            <FormGroup>
              <Label>
                { Lang[ 'LANDING_PAGE_FOR' ][ props.data.languageReducer.lang ] }
                <span className="text-danger">*</span>
              </Label>
              <CustomInput
                id='center'
                type='radio'
                label={ Lang[ 'CENTER' ][ props.data.languageReducer.lang ] }
                checked={ typeMode === 'center' }
                onChange={ e => updateTypeMode( 'center' ) } />
              <CustomInput
                id='center-group'
                type='radio'
                label={ Lang[ 'CENTER_GROUP' ][ props.data.languageReducer.lang ] }
                checked={ typeMode === 'center-group' }
                onChange={ e => updateTypeMode( 'center-group' ) } />
              <FormText color='danger'>{ errorMessage.typeMode ? errorMessage.typeMode : '' }</FormText>
            </FormGroup>
            {
              typeMode === 'center' ? (
                <FormGroup>
                  <Label>
                    { Lang[ 'CENTER' ][ props.data.languageReducer.lang ] }
                    <span className="text-danger">*</span>
                  </Label>
                  <ReactSelect
                    isMulti
                    options={ props.centers }
                    className='basic-multi-select'
                    classNamePrefix='select'
                    onChange={ val => updatePageInfo( 'center_ids', val ) }
                    value={ props.selectedTemplatePage.center_ids } />
                  <FormText color="danger">{ errorMessage.center_ids ? errorMessage.center_ids : '' }</FormText>
                </FormGroup>
              ) : (
                <>
                  <FormGroup>
                    <Label>
                      { Lang[ "CENTER_GROUP" ][ props.data.languageReducer.lang ] }
                      <span className="text-danger">*</span>
                    </Label>
                    <ReactSelect
                      isMulti
                      options={ props.centerGroupForSelect }
                      classNamePrefix="select"
                      placeholder={ Lang[ "SELECT_PLACEHOLDER" ][ props.data.languageReducer.lang ] }
                      onChange={ val => updatePageInfo( "center_group_ids", val ) }
                      value={ props.selectedTemplatePage.center_group_ids }
                    />
                    <FormText color="danger">{ errorMessage.center_group_ids ? errorMessage.center_group_ids : "" }</FormText>
                  </FormGroup>
                  <FormGroup>
                    <Label>{ Lang[ "COUNTRY" ][ props.data.languageReducer.lang ] }</Label>
                    <ReactSelect
                      isMulti
                      options={ props.countriesOptions }
                      classNamePrefix="select"
                      placeholder={ Lang[ "SELECT_PLACEHOLDER" ][ props.data.languageReducer.lang ] }
                      onChange={ val => updatePageInfo( "countries", val ) }
                      value={ props.selectedTemplatePage.countries }
                    />
                  </FormGroup>
                </>
              )
            }
            <FormGroup>
              <Label>{ Lang[ "STATUS" ][ props.data.languageReducer.lang ] }</Label>
              <Input
                type="select"
                value={ props.selectedTemplatePage.status }
                onChange={ e => updatePageInfo( "status", e.target.value ) }
              >
                <option value={ "active" }>{ Lang[ "ACTIVE" ][ props.data.languageReducer.lang ] }</option>
                <option value={ "inactive" }>{ Lang[ "INACTIVE" ][ props.data.languageReducer.lang ] }</option>
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={ updatePage }>
            { Lang[ "SUBMIT" ][ props.data.languageReducer.lang ] }
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default UpdateModal
