import React, { Component } from 'react'
import {
  Row, Col, Button, Container, UncontrolledTooltip,
  Card, CardBody, CardHeader, CardFooter,
  FormGroup, Input, Label

} from 'reactstrap'
import { compose } from 'redux'
import { toast } from 'react-toastify'
import _ from 'lodash'

import ARKTable from 'components/Table'
import PageTitle from 'components/PageTitle'
import ModalFormBuilder from './FormBuilder/Create'
import ModalFormUpdate from './FormBuilder/Update'
import ModalFormView from './FormBuilder/ViewForm'
import ModalFormArchived from './FormBuilder/Archived'
import ModalFormViewResults from './FormBuilder/ViewResult'

import WithDigitalOrderForms from './actions'
import WithCenterGroups from './actions/centerGroup'
import WithDownloadFormData from './actions/downloadFormData'
import { convertObjToBase64 } from 'utils/objToBase64'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Lang from 'Lang/General'

class Listings extends Component {
  state = {
    mode: '',
    hasAccessRight: false,
    hasArchiveFormResultRight: false
  }

  componentDidMount = () => {
    this.props.getDigitalOrderForms( { 
      page: 1, 
      is_paginated: true,
      filter: {
        $or: [
          { status: 'ACTIVE' },
          { status: 'INACTIVE' }
        ]
      } 
    })
    this.props.getCenterGroups( convertObjToBase64({
      is_paginated: false,
      filter: {
        status: 'ACTIVE'
    }}))
    this.props.getAllFormCategories( convertObjToBase64({
      is_paginated: false
    }))
    this.handleSecurityModulesAccess()
    this.handleSecurityModuleArchiveFormResult()
  }

  handleSecurityModulesAccess = () => {
    const allowedRoles = [ "Art Director", "Super Admin",  "Management Admin" ]
    const isAllowed = allowedRoles.indexOf(this.props.data.roleReducer.role.name) !== -1
    this.setState({ hasAccessRight: isAllowed }) 
  }

  handleSecurityModuleArchiveFormResult = () => {
    const allowedRoles = [ "Production", "Super Admin", "Management Admin" ]
    const isAllowed = allowedRoles.indexOf(this.props.data.roleReducer.role.name) !== -1
    this.setState({ hasArchiveFormResultRight: isAllowed }) 
  }

  handleSecurityModulesUpdate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Digital Order Form' })
      return tmpModules.is_update
    }
  }

  handleSecurityModulesDelete = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Digital Order Form' })
      return tmpModules.is_delete
    }
  }

  handleSecurityModulesCreate = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Digital Order Form' })
      return tmpModules.is_create
    }
  }

  handleSecurityModulesRead = () => {
    let tmp = _.find( this.props.data.roleReducer.role.platforms, { name: 'Ark Management' })
    let tmpModules = {}
    if ( tmp ){
      tmpModules = _.find( tmp.modules, { name: 'Digital Order Form' })
      return tmpModules.is_read
    }
  }

  onChangeFormBuilderListings = ( key, val ) => this.setState({ [ key ]: val })

  render = () => {
    return (
      <>
        <PageTitle
          heading={ Lang[ 'HEADING' ][ this.props.data.languageReducer.lang ] }
          subheading={ Lang[ 'SUBHEADING' ][ this.props.data.languageReducer.lang ] }
          icon="pe-7s-note2 icon-gradient bg-happy-itmeo"
          buttons={[
            {
              buttonText: Lang[ 'CREATE_NEW_FORM' ][ this.props.data.languageReducer.lang ],
              onClick: () => {
                this.props.onChangeDigitalOrderFormHOC( 'showFormBuilder', true )
              },
              display: this.state.hasAccessRight
            },
            {
              buttonText: Lang[ 'ARCHIVED_FORM' ][ this.props.data.languageReducer.lang ],
              onClick: () => {
                this.props.onChangeDigitalOrderFormHOC( 'showArchivedForms', true )
                this.props.getDigitalOrderForms( { 
                  page: 1, 
                  is_paginated: true,
                  filter: {
                    status: 'ARCHIVED'
                  } 
                })
              },
              display: this.state.hasAccessRight
            }
          ]} />
          <Card className="main-card mb-3">
            <CardHeader>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }</CardHeader>
            <CardBody>
              <Row>
                <Col md={ 6 }>
                  <FormGroup>
                    <Label for="content">{ Lang[ 'NAME' ][ this.props.data.languageReducer.lang ] }</Label>
                    <Input
                      type="text"
                      value={ this.props.searchParams.name }
                      onChange={ e => {
                        let tmp = _.cloneDeep( this.props.searchParams )
                        tmp[ 'name' ] = e.target.value
                        this.props.onChangeDigitalOrderFormHOC( 'searchParams', tmp )
                      }}>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={ 6 }>
                  <FormGroup>
                    <Label for="content">{ Lang[ 'CATEGORY' ][ this.props.data.languageReducer.lang ] }</Label>
                    <Input
                      type="select"
                      value={ this.props.searchParams.categoryId }
                      onChange={ e => {
                        let tmp = _.cloneDeep( this.props.searchParams )
                        tmp[ 'categoryId' ] = parseInt( e.target.value )
                        this.props.onChangeDigitalOrderFormHOC( 'searchParams', tmp )
                      }}>
                      <option value={0}>{ Lang[ 'PLEASE_SELECT_ONE' ][ this.props.data.languageReducer.lang ] }</option>
                      {
                        this.props.allFormCategories.length > 0 && this.props.allFormCategories.map( item => {
                          return(
                            <option key={ item.id } value={ item.id }>{ item.name[ this.props.data.languageReducer.lang ] }</option>
                          )
                        })
                      }
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Button
                color="primary"
                onClick={ () => {
                  this.props.getDigitalOrderForms( { 
                    page: 1, 
                    is_paginated: true,
                    filter: {
                      name: { $like: `%${ this.props.searchParams.name }%` },
                      ... this.props.searchParams.categoryId !== -1 && {
                        category: this.props.searchParams.categoryId
                      },
                      $or: [
                        { status: 'ACTIVE' },
                        { status: 'INACTIVE' }
                      ]
                    } 
                  })
                }}>{ Lang[ 'SEARCH' ][ this.props.data.languageReducer.lang ] }
              </Button>
              <Button
                color="danger"
                onClick={ () => {
                  this.props.getDigitalOrderForms( { 
                    page: 1, 
                    is_paginated: true,
                    filter: {
                      $or: [
                        { status: 'ACTIVE' },
                        { status: 'INACTIVE' }
                      ]
                    } 
                  })
              this.props.resetSearchParams()
                }}>{ Lang[ 'RESET' ][ this.props.data.languageReducer.lang ] }
              </Button>
            </CardFooter>
          </Card>
          <ARKTable                      
            data={ this.props.digitalOrderForms.data }
            columns={[
              {
                Header: Lang[ 'NAME' ][ this.props.data.languageReducer.lang ],
                accessor: 'name.' + this.props.data.languageReducer.lang
              },
              {
                Header: Lang[ 'DESCRIPTION' ][ this.props.data.languageReducer.lang ],
                accessor: 'description.' + this.props.data.languageReducer.lang
              },
              {
                Header: Lang[ 'STATUS' ][ this.props.data.languageReducer.lang ],
                accessor: 'status',
                className: "d-flex flex-row align-items-center justify-content-center",
                width: 110,
                Cell: ({ value }) => Lang[ value ][ this.props.data.languageReducer.lang ]
              },
              {
                Header: Lang[ 'CATEGORIES' ][ this.props.data.languageReducer.lang ],
                accessor: 'categories',
                className: "d-flex flex-row align-items-center justify-content-start",
                Cell: ({ value }) => (
                  <ul>
                    {
                      value && value.map( item => {
                        return (
                          <li>{ `${ item.name[ this.props.data.languageReducer.lang ] }`}</li>
                        )
                      })
                    }
                  </ul>
                )
              },
              {
                Header: Lang[ 'ACTIONS' ][ this.props.data.languageReducer.lang ],
                Cell: ( row ) => (
                  <div className="d-flex flex-row align-items-center justify-content-center flex-wrap">
                    {
                      this.state.hasAccessRight === true
                      ? <>
                            {
                            this.handleSecurityModulesUpdate() && (
                              <>
                                <Button 
                                  id={ `form-builder-${row.original.id}-update` }
                                  className="mr-2 my-1 btn-icon btn-icon-only"
                                  color="primary"
                                  onClick={() => {
                                    this.setState({ mode: 'update' }, () => {
                                      this.props.getSelectedDigitalOrderForm( row.original.id )
                                      this.props.onChangeDigitalOrderFormHOC( 'showUpdateModal', true )
                                    })
                                  }}>{ Lang[ 'CONFIGURE_NOW' ][ this.props.data.languageReducer.lang ] }
                                </Button>
                              </>
                            )
                          }
                          {
                            this.handleSecurityModulesDelete() && (
                              <>
                                <Button 
                                  color="danger" 
                                  id={ `form-builder-${row.original.id}-delete-btn` }
                                  className="mr-2 my-1 btn-icon btn-icon-only"
                                  onClick={() => {
                                    Promise.all([
                                      this.props.onChangeDigitalOrderFormHOC( 'toRemoveID', row.original.id )
                                    ]).then(() => {
                                      this.props.onChangeDigitalOrderFormHOC( 'showPromptModal', true )
                                    })
                                  }}><i className="pe-7s-trash"> </i>
                                </Button>
                                <UncontrolledTooltip
                                  target={ `form-builder-${row.original.id}-delete-btn` }
                                  placement="top">{ Lang[ 'DELETE' ][ this.props.data.languageReducer.lang ] }
                                </UncontrolledTooltip>
                              </>
                            )
                          }
                          {
                            this.handleSecurityModulesCreate() && (
                              <>
                                <Button 
                                  color="info"
                                  id={ `form-builder-${row.original.id}-copy-btn` }
                                  className="mr-2 my-1 btn-icon btn-icon-only"
                                  onClick={ () => { 
                                    this.setState({ mode: 'copy' }, () => {
                                      this.props.getSelectedDigitalOrderForm( row.original.id )
                                      this.props.onChangeDigitalOrderFormHOC( 'showUpdateModal', true )
                                    })
                                  }}><i className="pe-7s-copy-file"> </i> 
                                </Button>
                                <UncontrolledTooltip
                                  target={ `form-builder-${row.original.id}-copy-btn` }
                                  placement="top">{ Lang[ 'COPY' ][ this.props.data.languageReducer.lang ] }
                                </UncontrolledTooltip>
                              </>
                            )
                          }
                        </>
                      : <>
                          {
                            this.handleSecurityModulesRead() && ( 
                              <>
                                <Button 
                                  color="primary"
                                  id={ `form-builder-${row.original.id}-view-form-btn` }
                                  className="mr-2 my-1 btn-icon btn-icon-only"
                                  onClick={ async() => {
                                    await this.props.getSelectedDigitalOrderForm( row.original.id )
                                    await this.props.onChangeDigitalOrderFormHOC( 'showViewFormModal', true )
                                  }}><i className="pe-7s-look"></i>
                                </Button>
                                <UncontrolledTooltip
                                  target={ `form-builder-${row.original.id}-view-form-btn` }
                                  placement="top">{ Lang[ 'VIEW_FORM'  ][ this.props.data.languageReducer.lang ] }
                                </UncontrolledTooltip>
                              </>
                            )
                          }
                        </>
                    }
                    {
                      this.handleSecurityModulesRead() && (
                        <>
                          <Button 
                            color="success"
                            id={ `form-builder-${row.original.id}-view-btn` }
                            className="mr-2 my-1 btn-icon btn-icon-only"
                            onClick={ async() => {
                              const tmp = {
                                page: 1, 
                                is_paginated: true,
                                filter: {
                                  status_id: {
                                    $neq: 7
                                  }
                                }
                              }
                              await this.props.getSelectedDigitalOrderFormResults( row.original.id, convertObjToBase64(tmp) )
                              if( this.props.selectedDigitalOrderFormResults.data.length < 1 ){
                                toast.success( Lang[ 'NO_SUBMISSION_SELECTED_FORM' ][ this.props.data.languageReducer.lang ] )
                              } 
                            }}><i className="pe-7s-drawer"></i>
                          </Button>
                          <UncontrolledTooltip
                            target={ `form-builder-${row.original.id}-view-btn` }
                            placement="top">{ Lang[ 'VIEW_SUBMITTED_DATA'  ][ this.props.data.languageReducer.lang ] }
                          </UncontrolledTooltip>
                          <Button 
                            color="success"
                            id={ `form-builder-${row.original.id}-copy-url-btn` }
                            className="mr-2 my-1 btn-icon btn-icon-only"
                            onClick={ async() => {
                              let tmp = ''
                              if( window.location.host.indexOf( 'localhost' ) !== -1 ) {
                                tmp = 'cockpit-staging.proof-point-suite.com'
                              } else if( window.location.host.indexOf( 'staging' ) !== -1 ) {
                                tmp = 'cockpit-staging.proof-point-suite.com'
                              } else if( window.location.host.indexOf( 'aciso' ) !== -1 ) {
                                tmp = 'adminportal.aciso-suite.com'
                              } else if( window.location.host.indexOf( 'tennis' ) !== -1 ) {
                                tmp = 'adminportal.tennis-suite.com'
                              }
                              await navigator.clipboard.writeText( `https://${ tmp }/#/digital-order-form/external/${ row.original.id }` )
                              toast.success( Lang[ 'COPY_URL_SUCCESS' ][ this.props.data.languageReducer.lang ] )
                            }}><i className="pe-7s-photo-gallery"></i>
                          </Button>
                          <UncontrolledTooltip
                            target={ `form-builder-${row.original.id}-copy-url-btn` }
                            placement="top">{ Lang[ 'COPY_URL'  ][ this.props.data.languageReducer.lang ] }
                          </UncontrolledTooltip>
                        </>
                      )
                    }
                  </div>
                )
              }
            ]} 
            totalPages={ this.props.formsTotalPages }
            page={ this.props.digitalOrderForms.meta ? this.props.digitalOrderForms.meta.current_page : 1 }
            onChangePage={ pg => {
              let tmp = {
                page: pg,
                is_paginated: true,
                filter: {
                  name: { $like: `%${ this.props.searchParams.name }%` },
                  ... this.props.searchParams.categoryId !== -1 && {
                    category: this.props.searchParams.categoryId
                  },
                  $or: [
                    { status: 'ACTIVE' },
                    { status: 'INACTIVE' }
                  ]
                } 
              }
              this.props.getDigitalOrderForms( tmp )
            }}
            showPagination={ false } />
        { 
          this.props.showFormBuilder
          && <ModalFormBuilder { ...this.props }/> 
        }
        { 
          !_.isEmpty( this.props.selectedDigitalOrderForm ) 
          && this.props.showUpdateModal 
          && <ModalFormUpdate 
            { ...this.props }
            mode={ this.state.mode } /> 
        }
        { 
          !_.isEmpty( this.props.selectedDigitalOrderForm ) 
          && this.props.showViewFormModal 
          && <ModalFormView 
            { ...this.props } /> 
        }
        <ModalFormArchived { ...this.props } />
        { 
          !_.isEmpty( this.props.selectedDigitalOrderFormResults ) 
          && this.props.showViewModalResults 
          && <ModalFormViewResults 
            { ...this.props } 
            hasArchiveRight={ this.state.hasArchiveFormResultRight }
          />
        }
        { ( this.props.data.ajaxReducer.ajaxCallProgress > 0 || this.props.onLoadCenterGroups || this.props.onLoadDigitalOrderForms ) && <LoadingOverlay /> }
      </>
    )
  }
}

export default compose(
  WithCenterGroups,
  WithDigitalOrderForms,
  WithDownloadFormData
)( Listings )
