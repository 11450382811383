import Axios from 'axios'

import {
  beginAjaxCall,
  ajaxCallError,
  ajaxCallSuccess
} from './ajax';
import getDomainURL from 'utils/api'
import { getItem } from 'utils/tokenStore'
import { GET_DICTIONARY } from './type'

export const getDictionary = () => {
  Axios.defaults.headers = {
    common: {
      Authorization: `Bearer ${ getItem( 'PROOFPOINT_ARK_MANAGEMENT' ) }`
    }
  }
  return dispatch => {
    dispatch( beginAjaxCall() );
    Axios.get(
      `${ getDomainURL() }/api/v1/dictionaries`
    ).then( res => {
      dispatch( ajaxCallSuccess( '' ) )
      dispatch( getDictionarySuccess( res.data ) )
    }).catch( error => {
      if( error && error.response ) {
        dispatch( ajaxCallError( error.response.data.message ) )
      } else {
        dispatch( ajaxCallError( 'Disconnected from internet, please connect to the internet and retry again.' ) );
      }
    })
  }
}

const getDictionarySuccess = payload => ({ type: GET_DICTIONARY, payload })
